import { OrganizationDocument, Platforms } from '../_dependencies';

/** TODO replace isActivated with flag from stripe. Ex: If no requirements ? true : false */
export function checkAccountActivation(organization: OrganizationDocument): boolean {
  const { features } = organization.flags;
  // Just return true if the organization does not have the capability to sell.
  if (!features.bookings && !features.giftcards && !features.offers) {
    return true;
  }
  return (
    !Platforms.features.accountsCanBeActivatedFromDashboard ||
    !!(organization.isVerified && organization.agreement && organization.isActivated)
  );
}
