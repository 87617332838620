import { observable } from 'mobx';
import * as React from 'react';
import {
  ConfirmationButton,
  Consume,
  Form,
  LocaleContext,
  MobxComponent,
  SubmitButton,
  MarketplaceLibrary,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { PaymentTotalComponent } from './paymentTotalComponent';

export interface ManualPaymentComponentProps {
  totalPrice: number;
  amountPayed: number;
  onSubmit: (resolve, reject) => void;
  cart: MarketplaceLibrary.CartItem[];
}
export class ManualPaymentComponent extends MobxComponent<ManualPaymentComponentProps> {
  @observable private _isDisabled: boolean;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  private onSubmit = (_, resolve, reject) => {
    this._isDisabled = true;
    this.props.onSubmit(resolve, reject);
  };

  render() {
    const { t } = this._locale;
    return (
      <Form onSubmit={this.onSubmit} style={{ marginBottom: 0 }}>
        <h4 className="ui header">
          {t('components.payment.manualPaymentComponent.headerOne')} {this.props.totalPrice - this.props.amountPayed}{' '}
          {t('SEK')}
          <div className="sub header">{t('components.payment.manualPaymentComponent.headerThree')}</div>
        </h4>
        <div style={{ height: '1em' }} />
        <PaymentTotalComponent items={this.props.cart} />
        <ConfirmationButton
          disabled={this._isDisabled}
          loading={this._isDisabled}
          timeout={2000}
          className="fluid green"
          label={t('components.payment.manualPaymentComponent.labelOne')}
          activeButton={<SubmitButton className="fluid green">{t('Are you really sure?')}</SubmitButton>}
        />
      </Form>
    );
  }
}
