import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import { LocationDocument } from '../../_dependencies';
import { useLocale } from '../../_locales';
interface CoordinateSelectorProps {
  location: LocationDocument | undefined;
  onChange: (latLng: LocationDocument) => void;
}
const CoordinateSelectorComponent = (props: CoordinateSelectorProps) => {
  const { t } = useLocale();
  const [newPos, setNewPos] = useState<LocationDocument>();
  const searchBoxRef = useRef<SearchBox | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const mapRef = useRef<GoogleMap | null>(null);
  const mapEvents = useRef<google.maps.MapsEventListener | null>(null);

  const onPlacesChanged = () => {
    if (!searchBoxRef.current || !mapRef.current) return;
    const places = searchBoxRef.current.getPlaces();
    if (!places[0].geometry) return;

    const placeData = places[0].geometry;

    // Move map bounds to new location
    if (placeData.viewport) {
      mapRef.current.fitBounds(placeData.viewport);
    }

    // Generate marker location
    const { lat, lng } = placeData.location;
    const newLat = lat();
    const newLng = lng();

    // Set new marker location
    setNewPos({ lat: newLat, lng: newLng });
    props.onChange({ lat: newLat, lng: newLng });
  };

  useEffect(() => {
    if (!props.location) {
      setNewPos(undefined);
      return;
    }
    setNewPos(props.location);
  }, [props.location]);

  useEffect(() => {
    if (searchInputRef.current) {
      mapEvents.current = google.maps.event.addDomListener(searchInputRef.current, 'keydown', (e: KeyboardEvent) => {
        if (e.code === 'Enter') {
          e.stopPropagation();
        }
      });
    }
    return () => {
      if (!mapEvents.current) return;
      google.maps.event.removeListener(mapEvents.current);
    };
  }, [searchInputRef.current]);

  return (
    <GoogleMap
      ref={(ref) => (mapRef.current = ref)}
      zoom={newPos ? 15 : 4}
      center={newPos ? newPos : { lat: 57.721001, lng: 12.94025 }}
      onClick={(e) => {
        const position = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        };
        setNewPos(position);
        props.onChange(position);
      }}
      options={{
        streetViewControl: false,
        scrollwheel: false,
      }}
    >
      <SearchBox
        ref={(ref) => (searchBoxRef.current = ref)}
        controlPosition={google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          ref={(ref) => (searchInputRef.current = ref)}
          placeholder={t('Search...')}
          style={{
            display: 'hidden',
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            marginTop: '10px',
            height: `40px`,
            padding: `0 17px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox>
      <Marker position={newPos} visible={!!newPos} />
    </GoogleMap>
  );
};

export const CoordinateSelector = withScriptjs(withGoogleMap(CoordinateSelectorComponent));
