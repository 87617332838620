import Moment from 'moment-timezone';
import React from 'react';
import Shortid from 'shortid';
import {
  ActivityOccuranceDocument,
  BookingDocument,
  Component,
  Consume,
  getBasePrice,
  LocaleContext,
  PlaceDocument,
  Platforms,
  PropertyDocument,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';

export class BookingInfo extends Component<{ booking: BookingDocument }> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get visitorList() {
    const { t, tt } = this._locale;
    return (
      <div className="ui list" style={{ paddingTop: '5px' }}>
        {this.props.booking.priceCategories.map((priceCategory) => {
          if (!priceCategory.tickets) return null;
          const { name, groupMultiplier, tickets } = priceCategory;
          return (
            <div className="item" style={{ marginLeft: '10px' }} key={`price-category-${Shortid.generate()}`}>
              {tickets} {t('pcs')} {tt(name)}&nbsp;&nbsp;
              {groupMultiplier > 0 ? (
                <i>
                  ({t('valid for')} {groupMultiplier} {t('people')})
                </i>
              ) : (
                <i>({t('Accessories').toLocaleLowerCase()})</i>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  private routeList(route: PlaceDocument[]) {
    const { tt } = this._locale;
    return (
      <div className="ui list" style={{ paddingTop: '5px' }}>
        {route.map((place, index) => {
          return (
            <div className="item" style={{ marginLeft: '10px' }} key={`price-category-${Shortid.generate()}`}>
              <i
                className={
                  index === 0 ? 'flag outline icon' : index + 1 === route.length ? 'flag checkered icon' : 'point icon'
                }
              />
              {tt(place.name)}
            </div>
          );
        })}
      </div>
    );
  }

  private get basePriceInfo() {
    const { t } = this._locale;
    const basePrice = getBasePrice(this.props.booking.priceCategories);

    return (
      basePrice && (
        <div className="item">
          <i className="tag icon" />
          <div className="content">
            {t('base-price')} {basePrice.price} {Platforms.currencyISO}{' '}
          </div>
        </div>
      )
    );
  }

  render() {
    const booking = this.props.booking;
    const occurance = booking.occurance as any as ActivityOccuranceDocument;
    const property = occurance ? (occurance.property as any as PropertyDocument) : undefined;
    const route = occurance.route as unknown as PlaceDocument[];
    const { t, tt } = this._locale;
    const firstDay = Moment(occurance.start);
    const lastDay = Moment(occurance.end);
    const lengthInDays = Math.abs(firstDay.diff(lastDay, 'days')) + 1;

    return (
      <div className="ui list">
        {
          /** If occurance is absent, hide its title */
          occurance ? (
            <div className="item">
              <i className="star outline icon" />
              <div className="content">
                <b>{tt(occurance.title)}</b>
              </div>
            </div>
          ) : null
        }
        {
          /** If property is absent, hide its name */
          property ? (
            <div className="item">
              <i className="map pin icon" />
              <div className="content">
                {t('components.dashboard.bookings.bookingItem.bookingInfo.contentOne')} {tt(property.name)}
              </div>
            </div>
          ) : null
        }
        {
          /** If occurance is absent, hide its starttime */
          occurance ? (
            <div className="item">
              <i className="wait icon" />
              <div className="content">
                {t('Start time')} {Moment(occurance.start).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}
              </div>
            </div>
          ) : null
        }
        <div className="item">
          <i className="add to calendar icon" />
          <div className="content">
            {t('components.dashboard.bookings.bookingItem.bookingInfo.contentThree')}{' '}
            {Moment(booking.createdAt).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}
          </div>
        </div>
        {/* Only show updated if updated */}
        {Moment(booking.createdAt).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm') !=
        Moment(booking.updatedAt).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm') ? (
          <div className="item">
            <i className="checked calendar icon" />
            <div className="content">
              {t('components.dashboard.bookings.bookingItem.bookingInfo.contentFour')}{' '}
              {Moment(booking.updatedAt).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="item">
          <i className="money icon" />
          <div className="content">
            {t('Total price')} {booking.totalPrice} {Platforms.currencyISO}
          </div>
        </div>
        {this.basePriceInfo}
        <div className="item">
          <i className="ticket icon" />
          <div className="content">
            {t('Amount')}
            <br />
            {this.visitorList}
          </div>
        </div>
        {route && route.length ? (
          <div className="item">
            <i className="Map outline icon" />
            <div className="content">
              {`${t('Route')}: ${lengthInDays == 1 ? `${lengthInDays} ${t('day')}` : `${lengthInDays} ${t('days')}`}`}
              <br />
              {this.routeList(route)}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
