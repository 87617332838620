import { Types } from 'mongoose';
import * as React from 'react';
import { ActivityTypeDocument, ActivityTypeResource, ShowcaseMap, ShowcaseString } from '../../../_dependencies';
import {
  KosmicComponent,
  DestinationResource,
  Alert,
  LocaleContext,
  Consume,
  Dropdown,
  DropdownItem,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';

interface State {
  /** All initialy selectable activity types when building the widget */
  allActivityTypes: Array<ActivityTypeDocument>;

  /** The selected acitivity types to show in the resulting widget */
  selectedActivityTypes: ShowcaseMap;

  /** Background color to use on the widget */
  bgColor: string;
}

/**
 * The showcase widget selection view allows the
 * current user to create a showcase widget that displays differently from the standard
 * widget, first showing some different activites that the visitor can choose from.
 */
export class ShowcaseWidgetSelectionView extends KosmicComponent<{}, State> {
  state: State = {
    allActivityTypes: [],
    selectedActivityTypes: new Map(),
    bgColor: '#fcfcfc', // NOTE: The default bg colors for new widgets are set to the standard color on Adventure Hero
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  async componentDidMount() {
    /** On mount load all acitivty types in the current destination, or the all activity types in the current organizaiton if not cross selling */
    const resource = new DestinationResource();
    const isCrosSelling = await new DestinationResource().isPartOfDestination(
      this.globals.session.currentOrganizationId,
    );

    if (isCrosSelling) {
      const allActivityTypes = await resource.getActivityTypesInDestination(this.globals.session.currentOrganizationId);
      this.setState({ allActivityTypes });
    } else {
      const allActivityTypes = await new ActivityTypeResource().find({
        organization: this.globals.session.currentOrganizationId,
        isArchived: { $ne: true },
      });
      this.setState({ allActivityTypes });
    }
  }

  /** Generates the template that the user should copy to the head of the same page as the widget is displayed on */
  private createHeadTemplate(): string {
    return `<script src="${window.location.origin}/v3/widget"></script>`;
  }

  /** Generates the template that the user should copy to his/her own webpage where the widget should be displayed */
  private createWidgetTemplate(): string {
    const { selectedActivityTypes, bgColor } = this.state;
    const widgetColor = !bgColor.length ? '' : `bg-color="${bgColor}" `;

    // Get the currently selected showcase and stringify them
    // for the web component
    const showcaseItems: string[] = [];

    for (const [id, data] of selectedActivityTypes) {
      showcaseItems.push(`${id}:${data.included.join(',')}`);
    }

    const showcaseString: ShowcaseString = showcaseItems.join(';');

    return `<adventurehero-widget ${widgetColor}showcase="${showcaseString}"/>`;
  }

  /** returns the possible selectable activity types with selected and included activty types removed */
  private getAvailableActivityTypesForSelection() {
    const { allActivityTypes, selectedActivityTypes } = this.state;

    // Create a map with ids of all "included" offers as keys
    const activityTypesIncludedInOtherSelections: Map<string, any> = new Map();

    for (const data of Array.from(selectedActivityTypes.values())) {
      for (const includedId of data.included) {
        activityTypesIncludedInOtherSelections.set(includedId.toString(), 1);
      }
    }

    // filter possible selections
    return allActivityTypes.filter(
      (offer) => !selectedActivityTypes.has(offer.id) && !activityTypesIncludedInOtherSelections.has(offer.id),
    );
  }

  private handleSelectionOfActivityType(offer: ActivityTypeDocument) {
    const { selectedActivityTypes } = this.state;

    if (selectedActivityTypes.has(offer.id)) {
      selectedActivityTypes.delete(offer.id);
    } else {
      selectedActivityTypes.set(offer.id, {
        primaryActivity: offer,
        included: [],
      });
    }
    this.setState({ selectedActivityTypes });
  }

  private renderActivityTypeSelection() {
    const { allActivityTypes, selectedActivityTypes } = this.state;
    const availableActivityTypes = this.getAvailableActivityTypesForSelection();
    const { t, tt } = this._locale;

    let grayBackground = false;

    return allActivityTypes.map((offer) => {
      grayBackground = !grayBackground;
      const isSelected = selectedActivityTypes.has(offer.id);

      const data = isSelected ? selectedActivityTypes.get(offer.id)! : undefined;

      return (
        <div
          key={offer.id}
          className="item"
          style={{
            backgroundColor: grayBackground ? '#f0f0f0' : undefined,
            padding: '1em',
            border: '1px solid lightgray',
            marginTop: '-1px',
            borderCollapse: 'collapse',
          }}
        >
          <div className="content">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p className="header">{tt(offer.title)}</p>
              <button
                onClick={this.handleSelectionOfActivityType.bind(this, offer)}
                className={isSelected ? 'ui red small button' : 'ui green small button'}
              >
                {isSelected ? t('Remove') : t('Add to widget')}
              </button>
            </div>
            {isSelected && data ? (
              <Dropdown
                name="includedActivityTypes"
                fluid
                style={{ marginTop: '1em', marginBottom: '1em' }}
                multiple
                searchable
                placeholder={t('dashboard-widget-showcase-includedActivityType')}
                value={data.included.join(',')}
                onChange={(commaSeparatedIds) => {
                  data.included = commaSeparatedIds.split(',').map((stringId) => new Types.ObjectId(stringId));
                  selectedActivityTypes.set(offer.id, data);
                  this.setState({ selectedActivityTypes });
                }}
              >
                {allActivityTypes.map((availableOffer) => {
                  if (availableOffer.id == offer.id) {
                    return null;
                  }
                  return (
                    <DropdownItem key={availableOffer.id} value={availableOffer.id}>
                      {tt(availableOffer.title)}
                    </DropdownItem>
                  );
                })}
              </Dropdown>
            ) : null}
          </div>
        </div>
      );
    });
  }

  private renderEditor(content: string) {
    const { t } = this._locale;
    const onClick = () => {
      try {
        const tempInput = $('<input>');
        $('body').append(tempInput);
        tempInput.val(content).select();
        document.execCommand('copy');
        tempInput.remove();
        Alert.show(t('copy-successful'));
      } catch (err) {
        Alert.show(t('copy-error'), '', 'error');
      }
    };
    return (
      <div
        id="editor"
        style={{
          marginTop: '1rem',
          marginBottom: '2rem',
          backgroundColor: '#EAEAEA',
          display: 'flex',
        }}
      >
        <div className="ui icon message" style={{ cursor: 'pointer' }} onClick={onClick}>
          <i className="copy icon"></i>
          <div className="content" style={{ maxWidth: 'calc(100% - 67px)' }}>
            <div className="header">{t('components.dashboard.widgets.modernSelectionView.contentHeader')}</div>
            <p
              style={{ wordWrap: 'break-word', cursor: 'text', whiteSpace: 'pre-line' }}
              onClick={(e) => e.stopPropagation()}
            >
              {content}
            </p>
          </div>
        </div>
      </div>
    );
  }

  public render() {
    const {} = this.state;
    const { t } = this._locale;

    return (
      <div className="ui basic segment" style={{ padding: '2em', paddingTop: 0 }}>
        <p
          style={{
            whiteSpace: 'pre-wrap',
            lineHeight: 1.5,
            fontSize: '1.2em',
          }}
        >
          {t('Use this widget to let your visitors choose...')}
        </p>

        <h4 className="ui header" style={{ marginBottom: '0.5em' }}>
          {t('Select activities:')}
        </h4>
        <div className="ui celled list">{this.renderActivityTypeSelection()}</div>
        <div style={{ marginBottom: '1em' }} />
        <h5 className="ui header" style={{ marginBottom: '0.5em' }}>
          {t('Widget Background Color')}
        </h5>
        <p>{t('Choose the background color of the widget')}</p>
        <input
          name="bgColor"
          type="color"
          value={this.state.bgColor}
          onChange={(e) => this.setState({ bgColor: e.target.value })}
        />
        <div style={{ height: '2em' }} />
        <div>
          <div style={{ marginBottom: '0.5em' }} />
          <p>{t('components.dashboard.widgets.modernSelectionView.headElement')}</p>
          {this.renderEditor(this.createHeadTemplate())}
          <p>{t('components.dashboard.widgets.modernSelectionView.widgetElement')}</p>
          {this.renderEditor(this.createWidgetTemplate())}
        </div>
      </div>
    );
  }
}
