import React from 'react';
import { Field, Input, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface IMaximumNumberOfBookingsFieldProps {
  bookingCapacity?: number;
  setOfferBookingCapacity: (key: 'bookingCapacity', value?: number) => void;
}

export const MaximumNumberOfBookingsField = ({
  bookingCapacity,
  setOfferBookingCapacity,
}: IMaximumNumberOfBookingsFieldProps) => {
  const { t } = useLocale();
  const capacity = bookingCapacity ? String(bookingCapacity) : '';

  const updateOfferBookingCapacity = (value?: string) => {
    if (!value) return setOfferBookingCapacity('bookingCapacity', undefined);
    if (parseInt(value) === 0) return setOfferBookingCapacity('bookingCapacity', undefined);
    return setOfferBookingCapacity('bookingCapacity', parseInt(value));
  };

  return (
    <Field label={t('Max number of bookings')}>
      <Input
        name="bookingCapacity"
        icon="ticket"
        defaultValue={capacity}
        onChange={updateOfferBookingCapacity}
        placeholder={t('0 or leave empty for no limit')}
        rules={[Rules.IsEmptyOrInteger()]}
      />
    </Field>
  );
};
