import * as React from 'react';
import {
  Consume,
  Field,
  Form,
  Input,
  KosmicComponent,
  LocaleContext,
  OrganizationResource,
  Rules,
  SubmitButton,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';

interface Props {
  onDone: () => void;
}
interface State {
  errorText: string;
}

// TODO: Implement "magic link" instead of copying the verification code from the email (why? because: simpler UX)
export class VerificationForm extends KosmicComponent<Props, State> {
  state = {
    errorText: '',
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private onSubmit = async (values, resolve, reject) => {
    const { t } = this._locale;
    try {
      const { session } = this.globals;
      const orgResource = new OrganizationResource();
      const orgId = session.currentOrganizationId;
      const agreement = await orgResource.verifyAccount(orgId, values['verificationCode']);

      if (this.globals.session.currentUser) {
        this.globals.session.currentUser.organization.agreement = agreement;
        this.globals.session.currentUser.organization.isVerified = true;
      }
      this.globals.session.forceUpdate();
      this.props.onDone();
      resolve();
    } catch (error) {
      if (error.message === 'invalid_code') {
        this.setState({
          errorText: t('verification-code.not-valid'),
        });
      } else {
        this.setState({
          errorText: t('unexpected-error'),
        });
      }
      reject(error);
    }
  };

  private get errorMessage() {
    if (this.state.errorText) {
      return <p style={{ textAlign: 'center', margin: '1em', color: 'red' }}>{this.state.errorText}</p>;
    }
  }

  render() {
    const { t } = this._locale;
    return (
      <>
        <div className="ui icon warning large message">
          <i className="mail icon"></i>
          <div className="content">
            <div className="header">{t('Verify your account')}</div>
            {t('In order to activate your account you first need to verify your email...')}
          </div>
        </div>

        <Form onSubmit={this.onSubmit}>
          <Field>
            <Input
              className="large"
              name="verificationCode"
              placeholder={t('Verification code')}
              rules={[Rules.NotEmpty()]}
            />
          </Field>

          {this.errorMessage}

          <SubmitButton className="large fluid primary" style={{ marginTop: '2em' }}>
            {t('continue')}
            <i className="arrow right icon" />
          </SubmitButton>
        </Form>
      </>
    );
  }
}
