import { observable } from 'mobx';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Consume, LocaleContext, MobxComponent, NavLink, UserDocument, UserResource } from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { LanguageToggleIcons } from '../languageToggleIcons';
import { Maybe } from '../maybe';

interface State {
  imgSrc: string;
}
class CurrentUserSidebarItem extends MobxComponent<RouteComponentProps<{}>> {
  state: State = {
    imgSrc: (this.globals.session.currentUser as UserDocument).imageUrl,
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @observable _isLoading = false;

  componentDidMount(): void {
    if (this.globals.session.currentUser) {
      new UserResource().find({ _id: String(this.globals.session.currentUser._id) }).then((user) => {
        this.setState({ imgSrc: user[0].imageUrl });
      });
    }
  }

  private get systemOwnerButton() {
    if (this.props.children) {
      return <div className="item">{this.props.children}</div>;
    }
    const { t } = this._locale;

    return (
      <Maybe if="system.owner">
        <div className="item">
          <NavLink to="/admin" className="ui fluid tiny inverted basic button" style={{ padding: '10px' }}>
            <i className="settings icon" />
            {t('Admin view')}
          </NavLink>
        </div>
      </Maybe>
    );
  }

  private get userPage() {
    // TODO: How can match be null at first but not on rerenders?
    return `${this.props.match?.url}/user`;
  }

  private onLogoutButtonClicked() {
    this._isLoading = true;
    this.globals.session.deauthenticate('/');
  }

  render() {
    const { t } = this._locale;
    const isMobile = this.globals.device.isMobileSize;

    return isMobile ? (
      <div style={{ borderTop: '1px solid rgb(255,255,255,.08)' }}>
        <div className="ui container" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <div className="item" style={{ width: '50%' }}>
            <NavLink to={this.userPage} activeClassName="active">
              <img className="ui tiny circular image" src={this.state.imgSrc} style={{ margin: '0 auto 0 auto' }} />
            </NavLink>
          </div>
          <div style={{ width: '50%' }}>
            <h4 style={{ margin: '0.5em', color: 'white' }}>{(this.globals.session.currentUser as any).firstname}</h4>
            {this.systemOwnerButton}
          </div>
        </div>
        <div className="item">
          <LanguageToggleIcons />
          <div style={{ marginBottom: '1em' }} />
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <NavLink to="/" className="ui fluid tiny green button" activeClassName="active" style={{ padding: '10px' }}>
              <i className="home icon" />
              {t('Homepage')}
            </NavLink>
            <button
              className={'ui fluid tiny negative button ' + (this._isLoading && 'loading disabled')}
              onClick={this.onLogoutButtonClicked.bind(this)}
              style={{ padding: '10px' }}
            >
              <i className="sign out icon" />
              {t('Log out')}
            </button>
          </div>
        </div>
      </div>
    ) : (
      <div style={{ borderTop: '1px solid rgb(255,255,255,.08)', position: 'relative' }}>
        <NavLink to={this.userPage} activeClassName="active" className="item">
          <img
            className="ui circular image"
            src={this.state.imgSrc}
            style={{
              margin: '0 auto',
              width: '5rem',
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              padding: '.5rem',
            }}
          >
            <h4 style={{ background: 'black' }}>{(this.globals.session.currentUser as any).firstname}</h4>
          </div>
        </NavLink>
        <div style={{ padding: '.5rem' }}>
          <NavLink to="/" className="ui fluid tiny green button" activeClassName="active" style={{ padding: '10px' }}>
            <i className="home icon" />
            {t('Homepage')}
          </NavLink>
          <NavLink
            to="/"
            className="ui fluid tiny negative button"
            onClick={this.onLogoutButtonClicked.bind(this)}
            style={{ padding: '10px', marginTop: '10px' }}
          >
            <i className="sign out icon" />
            {t('Log out')}
          </NavLink>
        </div>
        {this.systemOwnerButton}
      </div>
    );
  }
}

export default withRouter(CurrentUserSidebarItem);
