import * as React from 'react';
import { BadgeDocument } from '../../_dependencies';

export function Badge({ badge }: { badge: BadgeDocument }) {
  return (
    <div
      style={{
        width: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      data-tooltip={badge.description}
      data-position="top center"
    >
      <img src={badge.imageUrl} className="ui circular tiny image" />
      <strong style={{ textAlign: 'center', marginTop: '0.3em' }}>{badge.label}</strong>
    </div>
  );
}

export function MiniBadge({ badge }: { badge: BadgeDocument }) {
  return (
    <div style={{ display: 'inline-block' }} data-tooltip={badge.description} data-position="top right">
      <img className="ui avatar image" src={badge.imageUrl} />
    </div>
  );
}
