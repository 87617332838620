import { Types } from 'mongoose';
import * as React from 'react';
import {
  InventoryDocument,
  Consume,
  Dropdown,
  DropdownItem,
  InventoryResource,
  LocaleContext,
  MobxComponent,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';

interface Props {
  inventory?: InventoryDocument[];
  priceCategoryInventory?: string;
  onChange: (inventory: InventoryDocument) => void;
}
interface State {
  inventories: InventoryDocument[];
  loading: boolean;
}
export class InventoryDropdown extends MobxComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      inventories: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getInventories();
  }

  private getInventories = async () => {
    const { currentOrganizationId } = this.globals.session;
    const inventories = await new InventoryResource().find({ organization: currentOrganizationId });
    this.setState({ inventories, loading: false });
  };

  private onChange = (id: string) => {
    const inventory = this.state.inventories.find((i) => i.id == id);
    if (inventory) {
      this.props.onChange(inventory);
    }
  };

  render() {
    const { t } = this._locale;
    const { inventory, priceCategoryInventory } = this.props;
    const defaultValue = inventory && inventory[0] ? inventory[0].id : 'nothing-selected';
    if (this.state.loading) return null;
    return (
      <Dropdown
        name="Inventory"
        fluid
        searchable
        value={priceCategoryInventory ? priceCategoryInventory : defaultValue}
        onChange={this.onChange}
      >
        <DropdownItem key={'empty'} value={'nothing-selected'}>
          {t('Nothing selected')}
        </DropdownItem>
        {this.state.inventories.map((inventory) => (
          <DropdownItem key={inventory.id} value={inventory.id}>
            {inventory.title} - {inventory.quantity}
          </DropdownItem>
        ))}
      </Dropdown>
    );
  }
}
