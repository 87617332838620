import * as React from 'react';
import { Marker, OverlayView } from 'react-google-maps';
import { ActivityTypeDocument, KosmicComponent, PropertyDocument } from '../../_dependencies';
import { ActivityMapCard } from '../customer/activities/activityMapCard';

interface Props {
  propertyActivityMap: { property: PropertyDocument; activityTypes: ActivityTypeDocument[] }[];
  selectedProperty: PropertyDocument | undefined;
  onOpenCard: (property: PropertyDocument) => void;
}

export class Markers extends KosmicComponent<Props> {
  props: Props;

  private getPixelPositionOffset = (width: number, height: number) => {
    return {
      x: -(width / 2),
      y: -(height + 65),
    };
  };

  private get propertiesWithLocation() {
    return this.props.propertyActivityMap.filter((v) => v.property.location);
  }

  private get markers() {
    return this.propertiesWithLocation
      .map((v) => {
        const position = { lat: v.property.location[1], lng: v.property.location[0] };
        if (!position.lat || !position.lng) return null;

        if (this.props.selectedProperty && this.props.selectedProperty.id === v.property.id) {
          return (
            <Marker
              key={v.property.id}
              zIndex={2}
              position={position}
              onClick={() => this.props.onOpenCard(v.property)}
            >
              <OverlayView
                key={`MapOverlay-gen.${Math.random()}`}
                position={position}
                mapPaneName={OverlayView.FLOAT_PANE}
                getPixelPositionOffset={this.getPixelPositionOffset}
              >
                <div className="bottom-arrow">
                  <div style={{ background: 'white', width: '25em', borderRadius: '10px' }}>
                    <ActivityMapCard property={v.property} activityTypes={v.activityTypes} />
                  </div>
                </div>
              </OverlayView>
            </Marker>
          );
        }
        return <Marker key={v.property.id} position={position} onClick={() => this.props.onOpenCard(v.property)} />;
      })
      .filter(Boolean);
  }

  public render() {
    return this.markers;
  }
}
