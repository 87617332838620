import Lodash from 'lodash';
import Moment from 'moment-timezone';
import * as React from 'react';
import { Consume, LocaleContext, MobxComponent, ActivityTypeSearchModes, DatePicker } from '../_dependencies';
import { AHLocaleContext } from '../_locales';

export type AdvancedDatePickerType = ActivityTypeSearchModes;

/** A really user friendly date picker which changes the calenderar layout via buttons  */
interface Props {
  date: Date;
  type: AdvancedDatePickerType;
  onChanged: (date: Date, type: AdvancedDatePickerType, selectionFinished: boolean) => void;
  enabledTypes: AdvancedDatePickerType[];
  onlyFutureDates?: boolean;
  autoSelectedNextType?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export class AdvancedDatePicker extends MobxComponent<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private isMinuteSelection = false;

  private get evenlyDivided() {
    const nrOfItems = this.props.enabledTypes.length;
    switch (nrOfItems) {
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';
    }
  }

  private get minDate() {
    if (this.props.minDate) {
      return this.props.minDate;
    }
    if (this.props.onlyFutureDates) {
      if (this.props.type == 'time') {
        return Moment(this.props.date).isAfter(new Date(), 'day') ? undefined : new Date();
      } else {
        return new Date();
      }
    }
  }

  private button = (type: AdvancedDatePickerType) => {
    const { t } = this._locale;
    let buttonText: string;
    switch (type) {
      case 'year':
        buttonText = t('Year');
        break;
      case 'month':
        buttonText = t('Month');
        break;
      case 'date':
        buttonText = t('Day');
        break;
      case 'time':
        buttonText = t('Time');
        break;
      default:
        buttonText = '';
        break;
    }
    return (
      <button
        key={type}
        className={'ui ' + (this.props.type == type ? 'active' : '') + ' button'}
        onClick={() => this.onTypeChanged(type)}
      >
        {buttonText}
      </button>
    );
  };

  private get periodButtons() {
    if (this.props.enabledTypes.length > 1) {
      return (
        <div>
          <div className={'ui ' + this.evenlyDivided + ' basic small buttons'}>
            {Lodash.map(this.props.enabledTypes, (type) => this.button(type))}
          </div>
          <div style={{ height: '1em' }} />
        </div>
      );
    }
  }

  private get datePicker() {
    const type = this.props.type;
    const startMode = type == 'date' ? 'day' : type == 'time' ? 'hour' : type;

    return (
      <DatePicker
        inline
        key={type}
        type={type}
        startMode={startMode}
        defaultValue={this.props.date}
        onChange={this.onDateChanged}
        minDate={this.minDate}
        maxDate={this.props.maxDate}
        name="advancedDatePicker"
        id="advancedDatePicker"
      />
    );
  }

  private get nextType() {
    switch (this.props.type) {
      case 'year':
        return 'month';
      case 'month':
        return 'date';
      case 'date':
        return 'time';
      default:
        return 'time';
    }
  }

  private isSelectionFinished = () => {
    if (!this.props.autoSelectedNextType && this.props.type != 'time') {
      return true;
    }

    const mode = this.domElement.find('#advancedDatePicker').calendar('get mode');
    if (mode == 'minute' && this.isMinuteSelection) {
      this.domElement.find('#advancedDatePicker').calendar('set mode', 'hour');
      this.isMinuteSelection = false;
      return true;
    } else {
      this.isMinuteSelection = mode == 'minute';
      return false;
    }
  };

  private onDateChanged = (date: Date) => {
    // Reset time picker
    const isSelectionFinished = this.isSelectionFinished();

    // onChange and possibly auto select next time
    if (this.props.autoSelectedNextType && this.props.type != 'time') {
      this.props.onChanged(date, this.nextType, false);
    } else {
      this.props.onChanged(date, this.props.type, isSelectionFinished);
    }
  };

  private onTypeChanged = (type: AdvancedDatePickerType) => {
    this.isMinuteSelection = false;
    this.props.onChanged(this.props.date, type, false);
  };

  public render() {
    return (
      <div>
        {this.periodButtons}
        {this.datePicker}
      </div>
    );
  }
}
