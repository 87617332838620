import * as React from 'react';
import { GlobalsContext } from '../../_dependencies';
import { useLocale } from '../../_locales';

export const FeatureLockIcon = (props: {
  feature: boolean;
  style?: React.CSSProperties;
  className?: string;
  id?: string;
}) => {
  const { t } = useLocale();
  const ref = React.useRef<HTMLElement>(null);
  const globals = React.useContext(GlobalsContext);
  const style = props.style || {};
  const className = props.className || '';

  React.useEffect(() => {
    if (ref.current == null) return;

    $(ref.current).popup({
      hoverable: true,
    });
  }, [ref]);

  const dataContent = props.feature ? t('feature-active') : t('feature-inactive');

  return (
    <i
      id={props.id || ''}
      data-content={dataContent}
      ref={ref}
      style={{ float: 'right', cursor: 'pointer', ...style }}
      className={[className, props.feature ? 'green unlock icon' : 'red lock icon'].join(' ')}
    />
  );
};
