import { action, computed, observable } from 'mobx';
import * as React from 'react';
import { Consume, GiftCardPreview, GiftCardTypeDocument, LocaleContext, MobxComponent } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { GiftCardForm } from './form';

interface Props {
  giftCard: GiftCardTypeDocument;
  isEditing?: boolean;
  onFinished?: Function;
  onEnded?: Function;
  onDeleteItem?: Function;
}

export class GiftCardDetails extends MobxComponent<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @observable private _isEditing = this.props.isEditing || false;

  @computed private get editButton() {
    const { t } = this._locale;
    if (this._isEditing) {
      return (
        <div className="ui segment fluid button" onClick={this.onEndedEditing}>
          <i className="remove icon" /> {t('Cancel')}
        </div>
      );
    }
    return (
      <div className="ui segment fluid button" onClick={() => (this._isEditing = true)}>
        <i className="write icon" /> {t('Edit')}
      </div>
    );
  }

  @computed private get content() {
    const { giftCard, onDeleteItem } = this.props;
    if (this._isEditing) {
      return (
        <div className="ui segment">
          <GiftCardForm giftCard={giftCard} finishedEditing={this.onFinishedEditing} onDeleteItem={onDeleteItem} />
        </div>
      );
    } else {
      return (
        <div className="ui segment" style={{ padding: '8px 8px 5px 8px' }}>
          <GiftCardPreview giftCard={giftCard} />
        </div>
      );
    }
  }

  @action private onFinishedEditing = () => {
    this._isEditing = false;
    if (this.props.onFinished) {
      this.props.onFinished();
    }
  };

  @action private onEndedEditing = () => {
    this._isEditing = false;
    if (this.props.onEnded) {
      this.props.onEnded();
    }
  };

  render() {
    return (
      <div className="ui container" style={{ margin: '0 !important' }}>
        <div className="ui fluid raised segments">
          {this.content}
          {this.editButton}
        </div>
      </div>
    );
  }
}
