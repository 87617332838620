import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocale } from '../../../../_locales';

import { Input, InventoryDocument, PlaceDocument, Rules } from '../../../../_dependencies';
import { AdvancedIventoriesTableRow } from './advancedOptionsTableRow';

interface Props {
  places?: PlaceDocument[];
  inventoryDocument: InventoryDocument;
  creatingNewInventory: boolean;
  onChange: (
    title: string,
    quantity: number,
    advancedOptions: boolean,
    multiplier: number,
    timeToAvailable: number,
  ) => void;
  onDelete: () => void;
}

export const InputTableRow = ({ places, inventoryDocument, onChange, creatingNewInventory, onDelete }: Props) => {
  const { t } = useLocale();
  const [title, setTitle] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [timeToAvailable, setTimeToAvailable] = useState(0);
  const [advancedOptions, setAdvancedOptions] = useState(false);

  useEffect(() => {
    const { title, quantity, usesAdvancedSetting, groupMultiplier, timeToAvailable, entities } = inventoryDocument;
    if (inventoryDocument) {
      setTitle(title);
      setQuantity(entities.length ? entities.length : quantity);
      setAdvancedOptions(usesAdvancedSetting);
      setMultiplier(groupMultiplier);
      setTimeToAvailable(timeToAvailable);
    }
  }, [inventoryDocument]);

  const handleOnSubmit = () => {
    onChange(title, quantity, advancedOptions, multiplier, timeToAvailable);
  };

  const changeTitle = (titleInput: string) => {
    setTitle(titleInput);
  };

  const changeQuantity = (quantityInput: number) => {
    setQuantity(quantityInput);
  };

  const setInventoryAmountIfAdvanced = (amount: number) => {
    setQuantity(amount);
  };

  const toggleAdvancedOptions = () => {
    return (
      <div className="ui toggle checkbox">
        <input
          type="checkbox"
          name="advanced options"
          checked={advancedOptions}
          onChange={() => {
            if (advancedOptions) {
              setAdvancedOptions(false);
            } else {
              setAdvancedOptions(true);
            }
          }}
        />
        <label></label>
      </div>
    );
  };

  return (
    <>
      <tr key={inventoryDocument.title} className="center aligned" style={{ backgroundColor: 'rgba(0,0,0,.05)' }}>
        <td className="left aligned">
          <Input
            fluid
            placeholder={t('Name')}
            name="title"
            onChange={(e) => changeTitle(e)}
            type="text"
            defaultValue=""
            value={title || ''}
          />
        </td>
        <td className="left aligned">
          <Input
            name="quantity"
            type="number"
            onChange={(e) => changeQuantity(Number(e))}
            className="left aligned"
            disabled={Boolean(inventoryDocument.entities.length) || advancedOptions}
            value={String(quantity)}
            rules={[Rules.IsAnInteger()]}
            rightLabel={t('pcs')}
            style={{ width: '4rem' }}
          />
        </td>
        <td className="left aligned">
          <Input
            name="multiplier"
            type="number"
            onChange={(e) => setMultiplier(Number(e))}
            className="left aligned"
            disabled={!creatingNewInventory}
            value={multiplier.toString()}
            rules={[Rules.IsAnInteger()]}
            rightLabel={<i className="ui male icon" style={{ margin: 0 }} />}
            style={{ width: '4rem' }}
          />
        </td>
        <td className="left aligned">
          <Input
            name="timeToAvailable"
            type="number"
            onChange={(e) => setTimeToAvailable(Number(e))}
            className="left aligned"
            disabled={!creatingNewInventory}
            value={timeToAvailable.toString()}
            rules={[Rules.IsAnInteger()]}
            rightLabel={'min'}
            style={{ width: '4rem' }}
          />
        </td>
        <td className="left aligned">
          <div>{toggleAdvancedOptions()}</div>
        </td>
        <td className="right aligned">
          <div className="ui icon buttons">
            <button onClick={onDelete} className="ui negative button">
              <i className="trash icon"></i>
              {/* {t("remove")} */}
            </button>
            <button className="ui positive button active" disabled={!title} onClick={handleOnSubmit}>
              <i className="save icon"></i>
              {/* {t("Save")} */}
            </button>
          </div>
        </td>
      </tr>
      {advancedOptions ? (
        <AdvancedIventoriesTableRow
          places={places}
          setInventoryAmount={setInventoryAmountIfAdvanced}
          inventory={inventoryDocument}
        />
      ) : null}
    </>
  );
};
