import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';

export interface AvailableOfferOnProperty extends MongooseDocument {
  employee: Types.ObjectId;
  property: Types.ObjectId;
  offer: Types.ObjectId;
}

/**
 * This is a special resources reads from a view in the database
 * with automatically calculatesd available offers on a property
 */
export class AvailableOfferOnPropertyResource extends MongooseResource<AvailableOfferOnProperty> {
  constructor() {
    super();

    // This name must manually match the view in the database without an s
    this.setName(SchemaNames.AvailableOfferOnProperty);

    this.setSchema({
      employee: { type: Schema.Types.ObjectId, ref: SchemaNames.Default, required: true },
      property: { type: Schema.Types.ObjectId, ref: SchemaNames.Property, required: true },
      offer: { type: Schema.Types.ObjectId, ref: SchemaNames.ActivityType, required: true },
    });
  }

  populateOffersOnAvailableOfferOnProperty(propertyId: string) {
    return this.sendRequest<AvailableOfferOnProperty>('/' + propertyId, 'get', {});
  }
}
