import { Schema } from 'mongoose';
import { TranslatableText } from './translatableText';

export interface AutomaticMailDocument {
  /** A custom message attached to the automatic mail */
  message?: TranslatableText;
  /** When the mail is set to be sent in minutes before start of activity */
  timeModifier: number;
  /** If automatic mail should be sent */
  shouldBeSent: boolean;
}

const AutomaticMail = new Schema(
  {
    message: { type: Schema.Types.Mixed, required: false, default: { sv: '' } },
    timeModifier: { type: Number, required: true, default: 0 },
    shouldBeSent: { type: Boolean, required: true, default: false },
  },
  { _id: false },
);

export const AutomaticMailSchema = AutomaticMail;
