import moment from 'moment-timezone';
import { default as React, useEffect, useState } from 'react';
import { AdvancedDatePicker } from '../../../advancedDatePicker';

interface Props {
  getSelectedDate: (date: Date) => void;
  mobile?: boolean;
}

export const CalendarDropdown = ({ getSelectedDate, mobile }: Props) => {
  const [date, setDate] = useState<any>(new Date());

  useEffect(() => {
    getSelectedDate(date);
  }, [date]);

  const onDateChanged = (date: Date) => {
    setDate(date);
  };

  return (
    <div className="ui compact fluid menu" style={{ marginRight: '1rem' }}>
      <div className={`ui simple fluid dropdown item`}>
        <i className="calendar grey icon" />
        <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
          {mobile ? moment(date).format('DD-MM-YY') : moment(date).format('MMMM Do')}
        </div>
        <i className="dropdown icon" />
        <div className="menu">
          <AdvancedDatePicker
            onlyFutureDates
            enabledTypes={['date']}
            type={'date'}
            date={date}
            onChanged={onDateChanged}
          />
        </div>
      </div>
    </div>
  );
};
