import Lodash from 'lodash';
import { action, computed } from 'mobx';
import Moment from 'moment-timezone';
import React from 'react';
import { View } from 'react-big-calendar';
import { ActivityOccuranceDocument, Consume, LocaleContext, MarketplaceProvider } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import CrossSellingIcon from '../crossSellingIcon';
import { CalendarEventComponentProperties, CalendarEventProperties } from '../utils/types';
import { BookingInfoItem } from '../views/bookingInfoItem';
import { BookingRequestedViews } from '../views/bookingRequestedViews';
import {
  ActivityEventComponent,
  ActivityEventComponentCollection,
  ActivityEventDetailsComponent,
  ActivityEventMonthComponent,
} from './activityEventComponentCollection';

export class BookedActivityEventComponentCollection extends ActivityEventComponentCollection {
  getEventProperties(event: ActivityOccuranceDocument, isSelected: boolean): CalendarEventProperties {
    return super.getEventProperties(event, isSelected);
  }

  getEventComponent(view: View, props: any) {
    switch (view) {
      case 'month':
        return <BookedActivityEventMonthComponent {...props} />;
      default:
        return <BookedActivityEventComponent {...props} />;
    }
  }

  getEventDetailsComponent(props: CalendarEventComponentProperties<any>) {
    return <BookedActivityEventDetailsComponent {...props} />;
  }
}

class BookedActivityEventComponent extends ActivityEventComponent {
  protected get color(): string {
    return this.props.selected ? 'orange' : 'green';
  }

  protected get crossSelling() {
    if (!this.props.event.bookings.some((b) => b.crossSellingProperty)) return null;
    return (
      <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
        <CrossSellingIcon event={this.props.event} />
      </span>
    );
  }
  protected get requested(): React.CSSProperties {
    if (this.props.event.hasRequestedBooking)
      return { background: 'repeating-linear-gradient(45deg, #0000, #0000 5px, #0001 5px, #0001 10px)' };
    return {};
  }

  protected get content() {
    const { event } = this.props;
    return (
      <LocaleContext.Consumer>
        {({ t, tt }: AHLocaleContext) => {
          return (
            <div className="ui content" style={{ textAlign: 'left', height: '100%', ...this.requested }}>
              <h5 className="ui inverted header">
                <div style={{ display: 'flex' }}>
                  <span style={{ fontSize: '0.75em', flex: 1, paddingRight: '4px', wordBreak: 'break-word' }}>
                    {tt(event.title)}
                  </span>
                  {this.crossSelling}
                </div>
                <div className="sub header" style={{ fontSize: '0.7em' }}>
                  {Moment(event.start).tz('Europe/Stockholm').format('HH:mm')} &mdash;{' '}
                  {Moment(event.end).tz('Europe/Stockholm').format('HH:mm')}
                </div>
              </h5>
              <p style={{ fontSize: '0.8em', margin: 0 }}>
                {event.availableVisitors} {t('Available (plural)').toLowerCase()}{' '}
              </p>{' '}
              <p style={{ fontSize: '0.8em', margin: 0 }}>
                {event.neededStaffing
                  ? `${event.workingStaff.length} / ${event.neededStaffing}`
                  : `${event.workingStaff.length}`}{' '}
                {t('planned employees')}{' '}
              </p>
              <p style={{ fontSize: '0.8em', margin: 0 }}>
                {event.bookings.length +
                  ' ' +
                  (event.bookings.length == 1 ? t('Booking') : t('Bookings')).toLowerCase()}
              </p>
            </div>
          );
        }}
      </LocaleContext.Consumer>
    );
  }
}

class BookedActivityEventMonthComponent extends ActivityEventMonthComponent {
  protected get color() {
    return this.props.selected ? 'orange' : 'green';
  }
}

class BookedActivityEventDetailsComponent extends ActivityEventDetailsComponent {
  @Consume(LocaleContext)
  protected _locale: AHLocaleContext;

  @action private onBeginMoveBookingProcess = (booking) => {
    this.props.store.beginMoveBookingProcess(booking);
  };

  @computed private get listOfBookings() {
    let bookings = this.props.event.bookings;

    if (!this.props.store.isUserAllowedToEditEvent()) {
      bookings = bookings.filter(
        (booking) => booking.crossSellingProperty?.organization === this.globals.session.currentOrganizationId,
      );
    }

    return Lodash.map(bookings, (booking) => (
      <BookingInfoItem
        store={this.props.store}
        key={booking.id}
        booking={booking}
        onMoveBooking={this.onBeginMoveBookingProcess}
      />
    ));
  }

  private crossSellingMessage = () => {
    const { t } = this._locale;
    if (!this.props.store.isUserAllowedToEditEvent()) {
      return (
        <div className="ui message">
          <div className="header">{t('bookinglist-is-limited.header')}</div>
          <p>{t('bookinglist-is-limited.message')}</p>
        </div>
      );
    }
  };

  render() {
    const { t } = this._locale;
    const { event, store } = this.props;
    if (store.isInMoveBookingProcess) {
      return <ActivityEventDetailsComponent {...(this.props as any)} />;
    }
    return (
      <div>
        <MarketplaceProvider>
          <ActivityEventDetailsComponent {...(this.props as any)}>
            {event.hasRequestedBooking || event.hasCustomerRequestedBooking ? (
              <BookingRequestedViews event={event} store={store} bookings={this.listOfBookings} />
            ) : (
              this.props.children
            )}
          </ActivityEventDetailsComponent>
        </MarketplaceProvider>

        {!this.props.event.hasRequestedBooking && (
          <div style={{ marginTop: '2rem' }}>
            <h4>{t('Bookings')}</h4>
            {this.crossSellingMessage()}
            <div className="ui doubling cards">{this.listOfBookings}</div>
          </div>
        )}
      </div>
    );
  }
}
