import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';

/** A service message  */
export interface ServiceMessageDocument extends MongooseDocument {
  /** The default HTML message to display in the message */
  html: string;
  /** The (optional) translated HTML messages to display in the message */
  translations?: {
    en?: string;
    sv?: string;
    de?: string;
  };
  /** Severity indiciates how important this message is: 0-3 = info, 5-7 = warning, 8-11 = error */
  severity: number;
  /**
   * Meant to be displayed only in the widget up to version X.
   *
   * Example:
   *  0:The message is displayed on our site aswell as all widgets.
   *  1:The message will be displayed in the iframe and the js widgets.
   *  2:The message will be displayed in all js widgets.
   *  3:The message will only be displayed in the webcomponent widget
   */
  widget: number;
  /** The message will not be displayed for any reason */
  hidden: boolean;
  /**
   * The message will only be displayed if all of the specified strings in this array matches:
   * A selected organisation id
   * A selected activity type id
   * A selected gift card type id
   * A selected browser platform (see device.context browsers)
   * A selected operating system (see device.context operating systems)
   * Mobile only, add "mobile"
   */
  when: string[];
}

export class ServiceMessageResource extends MongooseResource<ServiceMessageDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.ServiceMessage);

    this.setSchema({
      html: { type: String, required: true },
      translations: {
        en: { type: String, required: false },
        sv: { type: String, required: false },
        de: { type: String, required: false },
      },
      severity: { type: Number, required: true, default: 1 },
      hidden: { type: Boolean, required: false, default: false },
      widget: { type: Boolean, required: true },
      when: { type: [String], required: true, default: [] },
    });
  }
}
