import * as React from 'react';
import { BadgeProvider, IBadge } from '../../contexts';
import {
  BadgeDocument,
  BadgeImageResource,
  BadgeResource,
  Consume,
  Form,
  ImageUploadForm,
  Input,
  LocaleContext,
  MobxComponent,
  Rules,
  SubmitButton,
  TextArea,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';

interface IProps {
  badge: BadgeDocument;
  onSubmit?: () => BadgeDocument;
}

export class BadgeForm extends MobxComponent<IProps, any> {
  @Consume(BadgeProvider.Context)
  private badges: IBadge;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private onImageSaved(imageId, imageUrl, values, resolve, reject) {
    this.props.badge.imageId = imageId;
    this.props.badge.imageUrl = imageUrl;
    resolve();
  }

  private onSubmit = async (values, resolve, reject) => {
    const resource = new BadgeResource();
    const badge = this.props.badge;

    const label = values['label'];
    const description = values['description'];

    if (!label || !label.length || !description || !description.length || !badge.imageId) {
      return reject();
    }

    badge.label = label;
    badge.description = description;

    await this.badges.create(badge);
    resolve();
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
    this.globals.session.navigateTo('/admin/badges');
  };

  public render() {
    const badge = this.props.badge;
    const { t } = this._locale;
    return (
      <div className="ui grid">
        <div className="column">
          <div className="field" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ width: '300px' }}>
              <ImageUploadForm
                showPreview
                width={300}
                height={300}
                style={{ maxHeight: '200px' }}
                resource={new BadgeImageResource()}
                defaultImage={badge.imageUrl}
                onImagedSaved={this.onImageSaved.bind(this)}
                saveImageOnLastStep
              />
            </div>
          </div>
          <Form onSubmit={this.onSubmit}>
            <div className="field">
              <label>{t('Name')}</label>
              <Input rules={[Rules.NotEmpty()]} defaultValue={badge.label} name="label" />
            </div>
            <div className="field">
              <label>{t('Description')}</label>
              <TextArea
                rules={[Rules.NotEmpty()]}
                rows={5}
                resizable={false}
                defaultValue={badge.description}
                name="description"
              />
            </div>
            <div className="field">
              <SubmitButton className="fluid">{t('components.admin.badgeForm.saveBadge')}</SubmitButton>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
