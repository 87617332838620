import * as React from 'react';
import * as PropTypes from 'prop-types';
import { UserResource } from '../_dependencies';
import { DeviceManager } from '../stores/deviceManager';
import { SessionManager } from '../stores/sessionManager';

/** The React Component that needs to exist in the index file of the module */
export abstract class ReactModuleMainComponent extends React.Component<BrowserModuleSettings> {
  static contextTypes = { device: PropTypes.object, session: PropTypes.object };

  abstract getUserResource(): UserResource<any>;

  get globals() {
    return {
      session: this.context.session as any as SessionManager,
      device: this.context.device as any as DeviceManager,
    };
  }

  constructor(props, context) {
    super(props, context);

    if (!!context && context.session) {
      context.session.setCustomUserResource(this.getUserResource());
    }
  }

  abstract render(): JSX.Element;
}
