import { EventEmitter } from 'events';
import { join, map } from 'lodash';
import Moment from 'moment';
import * as React from 'react';
import {
  Accordion,
  Consume,
  LocaleContext,
  MobxComponent,
  PropertyDocument,
  ScheduleDocument,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { ScheduleConsumer } from './context';
import { ScheduleProps } from './context/interfaces';

interface State {
  /** Is mouse hovering over component */
  selected: boolean;
}
interface Props {
  // Schedule object
  schedule: ScheduleDocument;
  // Returns the clicked items rrule string
  onClick: (ScheduleDocument) => void;
}

// Used to communicate between instances
const emitter = new EventEmitter();
const clickedEvent = 'clicked';

/** @class ScheduleItem
 * A Schedule item represents one item from the schedula which contains
 * either a schedule template or a exceptions template */
@ScheduleConsumer
export class ScheduleItem extends MobxComponent<Props & ScheduleProps, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private accordion: Accordion | null = null;

  constructor(props) {
    super(props);
    this.state = { selected: false };
  }

  /** @method componentWillMount react lifecycle hook */
  public UNSAFE_componentWillMount() {
    emitter.addListener(clickedEvent, this.onClickEmitted.bind(this));
  }

  /** @method componentWillUnmount react lifecycle hook */
  public componentWillUnmount() {
    emitter.removeListener(clickedEvent, this.onClickEmitted.bind(this));
  }

  /**
   * @method onClickEmitted
   * This method is triggered on all instances when
   * one instance is registering an onclick event.
   * It's used to close all accordions except the one
   * that's supposed to be showing */
  private onClickEmitted(instance: ScheduleItem) {
    if (instance === this) {
      this.setState({ selected: !this.state.selected });
    } else {
      this.setState({ selected: false });
      this.accordion && this.accordion.close();
    }
  }

  /** @method toWeekday
   *  Takes a number and converts it to a day of the week string.
   *  Example: 0 == Monday, 1 == Tuesday etc */
  private toWeekday(weekday: number): string {
    return Moment.weekdays(weekday + 1);
  }

  /**
   * @method onClick
   * Used to handle when the item card is clicked */
  private onClick() {
    const { schedule, onClick } = this.props;
    emitter.emit(clickedEvent, this);
    onClick(this.accordion!.isOpen ? schedule : undefined);
  }

  /**
   * @prop title
   * Returns the title used in the accordion to trigger
   * opening and closing of accordion body */
  private get title(): JSX.Element {
    const { t, tt } = this._locale;
    const { byweekday, dtstart, until } = this.props.schedule.rRuleObject.options;
    return (
      <div onClick={this.onClick.bind(this)} style={{ margin: '-0.5em 0' }}>
        <h3 className="ui header">{t('components.dashboard.properties.employeeSchedule.scheduleItem.schedule')}</h3>
        {/* TODO: 
                    Endable editbooking and add this icon <i className="dropdown icon" style={{ float: "right" }}/> 
                */}
        <p style={{ marginBottom: 0 }}>
          <i className="ui clock icon" />
          <b>{t('components.dashboard.properties.employeeSchedule.scheduleItem.time')}</b>{' '}
          {Moment(dtstart).format('HH:mm')} - {Moment(this.props.schedule.endTime).format('HH:mm')}
        </p>
        <p style={{ marginBottom: 0 }}>
          <i className="ui calendar alternative icon" />
          <span>
            <b>{t('components.dashboard.properties.employeeSchedule.scheduleItem.repititionPeriod')}</b>{' '}
            {Moment(dtstart).format('YYYY-MM-DD')}
          </span>
          <span> - {until ? Moment(until).format('YYYY-MM-DD') : 'n/a'}</span>
        </p>
        <p style={{ marginBottom: 0 }}>
          <i className="ui calendar check icon" />
          <b>{t('components.dashboard.properties.employeeSchedule.scheduleItem.every')}</b>{' '}
          {join(map(byweekday, this.toWeekday), ', ')}
        </p>
        <p style={{ marginBottom: 0 }}>
          <i className="ui building outline icon" />
          <b>{t('Property') + ': '}</b> {tt(this.props.schedule.property.name)}
        </p>
      </div>
    );
  }

  // This will be used when editing scheudle is enabled
  private handleScheduleSaved() {
    this.accordion && this.accordion.close();
  }

  private get segmentStyle() {
    const style = { margin: '1em 0', cursor: 'pointer' } as React.CSSProperties;
    if (this.state.selected) {
      style.borderColor = 'lightgreen';
      style.boxShadow = '0px 0px 5px lightgreen';
    }
    return style;
  }

  private remove() {
    const status = this.props.scheduleContent!.removeSchedule(this.props.scheduleContent!.editSchedule!);
    if (status) {
      this.props.scheduleContent!.setEditSchedule(undefined);
    }
  }

  private conflictFeedback = (permissionToEdit: boolean) => {
    const { t } = this._locale;
    if (!permissionToEdit) {
      return (
        <h4 style={{ color: 'red', textAlign: 'center', marginTop: '1em' }}>
          {t('components.dashboard.properties.employeeSchedule.scheduleItem.notAuthorizedForRemoveSchedule')}
        </h4>
      );
    }
  };

  /** @method render React lifecycle hook */
  public render() {
    const { t } = this._locale;
    const { schedule } = this.props;
    const permissionToEdit =
      schedule.property.id == (this.props.scheduleContent!.originProperty as PropertyDocument).id ? true : false;

    return (
      <div className="ui column eight raised segment" style={this.segmentStyle}>
        <Accordion ref={(instance) => (this.accordion = instance)} title={this.title}>
          {this.conflictFeedback(permissionToEdit)}
          {/* TODO: Remove this button when scheduleCreator is rendered (AKA when editing schedule is enabled) */}
          <button
            className={'ui fluid red big button ' + (permissionToEdit ? '' : 'disabled')}
            style={{ marginTop: '16px' }}
            onClick={this.remove.bind(this)}
          >
            {t('remove')}
          </button>
          {/* TODO: Endable editbooking and add scheduleCreator 
                        <ScheduleCreator editRRule={schedule as ScheduleDocument} onSave={this.handleScheduleSaved.bind(this)}/> 
                    */}
        </Accordion>
      </div>
    );
  }
}
