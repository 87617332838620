import * as React from 'react';
import { observable } from 'mobx';

import { MobxComponent } from './abstractComponent';

export class ConfirmationButton extends MobxComponent<{
  label: React.ReactNode;
  timeout?: number;
  activeButton: React.ReactNode;
  stopPropagation?: boolean;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
}> {
  @observable private _triggered = false;
  private _animate = false;

  trigger = (e) => {
    this._animate = true;
    this._triggered = !this._triggered;
    if (this._triggered && this.props.timeout != undefined && this.props.timeout > 0) {
      setTimeout(this.trigger, this.props.timeout || 3000);
    }
    if (this.props.stopPropagation && e) {
      e.stopPropagation();
    }
  };

  componentDidUpdate() {
    if (this._animate && !this.props.disabled) {
      this.domElement.transition('jiggle');
    }
    this._animate = false;
  }

  render() {
    if (this._triggered) {
      return this.props.activeButton as any;
    } else
      return (
        <a
          className={
            'ui ' +
            (this.props.disabled ? 'disabled ' : '') +
            (this.props.loading ? 'loading ' : '') +
            'button ' +
            (this.props.className || '')
          }
          style={this.props.style}
          onClick={this.trigger}
        >
          {this.props.label}
        </a>
      );
  }
}
