import { autorun } from 'mobx';
import React from 'react';
import { Consume, Field, Input, LocaleContext, MobxComponent, Rules, SubmitButton } from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';

export class RefundComponent extends MobxComponent<{
  buttonText?: string;
  buttonClass?: string;
  totalRefundAmount: number;
  amountPayedOnline: number;
  onNewRefundAmount: (refundAmount: number) => void;
  hasAccesoriesChanged?: boolean;
  hasAccessories?: boolean;
}> {
  private _autorunDisposer: Function;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private onNewRefundAmount = (value: string) => {
    this.props.onNewRefundAmount(Number(value));
  };

  private get isRefundAmountMoreThanOnlinePayment() {
    return this.props.totalRefundAmount > this.props.amountPayedOnline;
  }

  private get isRefundNegative() {
    return this.props.totalRefundAmount < 0;
  }

  private get isRefundZero() {
    return this.props.totalRefundAmount == 0;
  }

  private get maxRefundAmount() {
    return this.isRefundAmountMoreThanOnlinePayment ? this.props.amountPayedOnline : this.props.totalRefundAmount;
  }

  private get refundAmountLabel() {
    const { t } = this._locale;
    return (
      <h4 className="ui header">
        <i className="undo icon" />
        <div className="content">
          {t('components.dashboard.bookings.bookingItem.refundComponent.refundKr')}{' '}
          {Math.max(this.props.totalRefundAmount, 0)} {t('SEK')}
        </div>
      </h4>
    );
  }

  private get refundFeeNotice(): React.ReactNode {
    const { t } = this._locale;
    if (this.props.totalRefundAmount <= 0) {
      return null;
    }
    return (
      <div className="ui orange message">
        <b>{t('Note that the service fee will not be refunded')}</b>
      </div>
    );
  }

  private get accessoriesFallbackNotice() {
    const { t } = this._locale;
    //When editing the amount of accessories
    if (this.props.hasAccesoriesChanged) {
      return (
        <div className="ui red message">
          <div className="header">{t('Temporary handling of accessories for accounting')}</div>
          <p>{t('When you change accessories for a booking, you...')}</p>
        </div>
      );
      //When removing the whole booking
    } else if (this.props.hasAccessories) {
      return (
        <div className="ui red message">
          <div className="header">{t('Temporary handling of accessories for accounting')}</div>
          <p>{t('When you delete a booking with accessories, you...')}</p>
        </div>
      );
    }
  }

  private get infoMessage() {
    const { t } = this._locale;
    if (this.isRefundAmountMoreThanOnlinePayment) {
      return (
        <div className="ui orange message">
          <div className="content">
            <b>{t('components.dashboard.bookings.bookingItem.refundComponent.refundOnlineOne')}</b>{' '}
            {t('components.dashboard.bookings.bookingItem.refundComponent.refundOmlineTwo')}{' '}
            <b>
              {this.props.amountPayedOnline}
              {t('SEK')}
            </b>
          </div>
        </div>
      );
    }
    return undefined;
  }

  private get buttonText() {
    const { t } = this._locale;
    if (this.props.buttonText) {
      return this.props.buttonText;
    }
    return this.isRefundNegative || this.isRefundZero ? t('Save') : t('Save and refund');
  }

  private get refundInput() {
    const { t } = this._locale;
    if (this.isRefundNegative || this.isRefundZero) {
      return undefined;
    }
    return (
      <Field label={t('components.dashboard.bookings.bookingItem.refundComponent.labelrefund')}>
        <Input
          name="RefundAmount"
          onChange={this.onNewRefundAmount}
          icon="money"
          rules={[
            Rules.NotEmpty(t('components.dashboard.bookings.bookingItem.refundComponent.inputFillin')),
            Rules.IsAnIntegerBetween(
              0,
              this.maxRefundAmount,
              t('components.dashboard.bookings.bookingItem.refundComponent.messageMaxRefund'),
            ),
          ]}
        />
      </Field>
    );
  }

  componentDidMount() {
    this._autorunDisposer = autorun(() => {
      // Whenever refund is negativ or zero we must update the refund amount to 0!
      if (this.isRefundNegative || this.isRefundZero) {
        this.props.onNewRefundAmount(0);
      }
    });
  }

  componentWillUnmount() {
    if (this._autorunDisposer) {
      this._autorunDisposer();
    }
  }

  render() {
    return (
      <div>
        {this.refundAmountLabel}
        {this.refundFeeNotice}
        {this.infoMessage}
        {this.accessoriesFallbackNotice}

        <div style={{ height: '1em' }} />
        {this.refundInput}
        <SubmitButton className={this.props.buttonClass ? this.props.buttonClass : 'fluid green'}>
          {this.buttonText}
        </SubmitButton>
      </div>
    );
  }
}
