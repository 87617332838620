import * as ReactDOM from 'react-dom';
import * as ReactGA from 'react-ga';
import * as Cookie from 'js-cookie';
import type { ReactModuleMainComponent } from './_mainComponent';
import { generateReactComponent } from './_generateReactComponent';

/** Utility method that renders the given react component when executed in the browser */
export const startReact = async (
  mainComponent: new (props: ReactModuleSettings, context: any) => ReactModuleMainComponent,
  availableTranslations: Record<string, any>,
) => {
  // Do not start the react module when running in node, i.e. server side rendering
  if (MODULE_ENVIRONMENT == 'node') {
    return;
  }

  // Load the settings for the current module
  const pathToSettings = '/module-settings.json';
  const settings: ReactModuleSettings = await (await fetch(pathToSettings)).json();

  // Get any pre-selected locale from cookies
  const initialLocale = Cookie.get('locale');

  // Get the correct device type from cookies
  const deviceType = Cookie.get('deviceType') || 'browser';

  // Get the initial time when the page was requested from the server if set
  const initialNow = new Date(Cookie.get('initialNow'));

  // Add Google Analytics
  let onPageUpdate: (() => void) | undefined = undefined;
  if (settings.googleAnalyticsId && settings.googleAnalyticsId.length) {
    // Initialize ReactGA
    ReactGA.initialize(settings.googleAnalyticsId, { debug: MODULE_RUNTIME_TIER != 'master' });

    // Create the pageUpdateMethod
    onPageUpdate = () => {
      ReactGA.set({ page: window.location.pathname });
      ReactGA.pageview(window.location.pathname);
    };
  }

  // Render
  ReactDOM.render(
    generateReactComponent(mainComponent, settings, {
      history: undefined, // "this is a hack"
      user: undefined,
      initialLocale,
      translations: availableTranslations,
      onPageUpdate,
      deviceType,
      initialNow,
    }),
    document.getElementById(settings.name),
  );
};
