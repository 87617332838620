import { computed, observable } from 'mobx';
import { FormMember } from './form';
import { FormatRule } from './rules';

/** Default properties for all changable components */
export interface ChangableComponentProps<ResultValue> {
  /** A unique name is required for all changable components */ name: string;
  /** The value set for when it hasn't been otherwise modified */ defaultValue?: ResultValue; // TODO: You should be able to create component that takes one or more types as input and transforms them to value type
  /** A prop to manually set the component value */ value?: ResultValue; // TODO: You should be able to create component that takes one or more types as input and transforms them to value type
  /** Called each time the component's value is changed */ onChange?: (
    value: ResultValue,
    element?: ChangableComponent<ResultValue, ChangableComponentProps<ResultValue>>,
  ) => any;
  /** A list of rules that applies to this component */ rules?: FormatRule[];
  /** Indicates if this component is currently performing an blocking action */ loading?: boolean;
  /** Indicates if this component is currently disabled from futher input */ disabled?: boolean;
  /** Indicates if the field is optional, this will override rules when empty */ optional?: boolean;
}

/**
 * An abstract base component for all components that can be modified by the user
 */
export abstract class ChangableComponent<
  /** The type of object that this component will create or modify */ ValueType,
  Props extends {},
> extends FormMember<ChangableComponentProps<ValueType> & Props> {
  /** A required lifecycle hook for changable components that should be called when it's value is changed by the user, implementation might vary */
  protected abstract updateComponentValue(newValue: ValueType): ValueType;

  /** The current value */
  @computed public get value(): ValueType {
    return this._value;
  }

  /** Actual changable value of this component */
  @observable private _value: ValueType;

  /** Sends the current value of the component to any exisiting form component, called everytime the value changes */
  private sendValueToFormComponent(value: ValueType) {
    if (this.form) {
      this.form.addValue(this.props.name, value);
    }
  }

  /** Deletes this component from any exisiting form component, called when the component unmounts */
  private removeValueFromFormComponent() {
    if (this.form) {
      this.form.removeValue(this.props.name);
    }
  }

  /** Sends any validation rules for this input to any existing form component, called when this component is mounted or updated */
  protected sendValidationRulesToFormComponent() {
    if (this.form && this.props.rules) {
      this.form.addValidationRules(this.props.name, this.props.rules as any, !!this.props.optional);
    }
  }

  /** Removes any validation rules for this input from any existing form component, called when this component will unmount */
  private removeValidationRulesFromFormComponent() {
    if (this.form && this.props.rules) {
      this.form.removeValidationRules(this.props.name);
    }
  }

  // On construction a changable component decorates it's lifecycle hooks to automaticly communicate with any existing form component higher up in the component tree
  constructor(props, context) {
    super(props, context);

    const originalUpdateComponentValue = this.updateComponentValue.bind(this);
    const originalComponentWillMount = this.UNSAFE_componentWillMount.bind(this);
    const originalComponentDidMount = this.componentDidMount.bind(this);
    const originalComponentDidUpdate = this.componentDidUpdate.bind(this);
    const originalComponentWillUnmount = this.componentWillUnmount.bind(this);
    const originalComponentWillReceiveProps = this.UNSAFE_componentWillReceiveProps.bind(this);

    this.updateComponentValue = (newValue: ValueType) => {
      if (newValue == this._value) {
        return newValue;
      }

      const value = originalUpdateComponentValue(newValue);
      this._value = value;
      this.sendValueToFormComponent(value);
      if (this.props.onChange) {
        this.props.onChange(value, this as any);
      }
      return value;
    };

    this.UNSAFE_componentWillMount = () => {
      this._value = originalUpdateComponentValue((this.props.value as any) || (this.props.defaultValue as any));
      this.sendValueToFormComponent(this._value);
      return originalComponentWillMount();
    };

    this.componentDidMount = () => {
      this.sendValidationRulesToFormComponent();
      this.sendValueToFormComponent(this._value);
      return originalComponentDidMount();
    };

    this.componentDidUpdate = (previousProps: Props & ChangableComponentProps<ValueType>) => {
      this.sendValidationRulesToFormComponent();
      return originalComponentDidUpdate(previousProps);
    };

    this.componentWillUnmount = () => {
      this.removeValueFromFormComponent();
      this.removeValidationRulesFromFormComponent();
      return originalComponentWillUnmount();
    };
    this.UNSAFE_componentWillReceiveProps = (nextProps: Props & ChangableComponentProps<ValueType>) => {
      if (!!nextProps.value && nextProps.value != this.value) {
        this.updateComponentValue(nextProps.value);
      } else if (
        nextProps.defaultValue != undefined &&
        nextProps.defaultValue != this.props.defaultValue &&
        nextProps.defaultValue != this.value
      ) {
        this.updateComponentValue(nextProps.defaultValue);
      }
      return originalComponentWillReceiveProps(nextProps);
    };
  }

  // Default lifecycle hooks required for changable components ~ can be overridden by each implementation
  UNSAFE_componentWillMount() {}
  componentDidMount() {}
  componentDidUpdate(previousProps) {}
  componentWillUnmount() {}
  UNSAFE_componentWillReceiveProps(nextProps) {}
}
