import * as React from 'react';
import { OffersContext } from '../../contexts/offers.context';
import { Consume, LocaleContext, MobxComponent, NavLink, Transition } from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { ErrorBoundary } from '../errorBoundary';
import { LanguageToggleIcons } from '../languageToggleIcons';
import { PropertyStore } from './properties/propertyStore';
interface LinkData {
  path: string;
  text: JSX.Element | string;
  icon?: string;
}
interface Props {
  title: JSX.Element | string;
  icon: string;
  tabs?: Array<LinkData>;
  rightTab?: LinkData;
  store?: PropertyStore;
}

interface State {
  currentLink: number;
}

export default class DashboardTemplate extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentLink: 0,
    };
  }

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(OffersContext)
  private _offers: OffersContext;

  clickedTab(index: number) {
    this.setState({
      currentLink: index,
    });
  }

  renderTabs() {
    const { isMobileSize } = this.globals.device;
    const { rightTab, tabs, icon } = this.props;
    const allTabs = [...(tabs || [])];

    if (isMobileSize && rightTab) {
      allTabs.push(rightTab);
    }

    return allTabs.map((tab, index) => {
      return (
        <NavLink
          exact
          key={index}
          to={tab.path}
          activeClassName="active"
          className="item"
          onClick={() => this.clickedTab(index)}
        >
          {!isMobileSize || this.state.currentLink === index ? (
            <span>
              <i className={tab.icon || icon} />
              {tab.text}
            </span>
          ) : (
            <i className={tab.icon || icon} />
          )}
        </NavLink>
      );
    });
  }

  renderRightMenuLink() {
    const { rightTab } = this.props;
    if (!rightTab) return null;
    return (
      <div className="right menu">
        <NavLink to={rightTab.path} activeClassName="active" className="item">
          {rightTab.text}
        </NavLink>
      </div>
    );
  }

  openTabs() {
    const { tt } = this._locale;
    const isOffer = this.globals.session.currentLocation.href.includes('/offers');
    if (isOffer) {
      return Array.from(this._offers.loadedOffers.values()).map((offer) => {
        return (
          <div key={offer.id} className="menu">
            <NavLink to={'/dashboard/offers/details/' + offer.id} activeClassName="active" className="item">
              {tt(offer.title)}
              &nbsp; &nbsp;
              <i
                className="close icon"
                onClick={(e) => {
                  this._offers.unloadOffer(offer.id, () => this.globals.session.navigateTo('/dashboard/offers'));
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </NavLink>
          </div>
        );
      });
    }
  }

  renderTopAttachedMenuTabs() {
    if (!this.props.tabs) return null;
    if (this.globals.device.isMobileSize)
      return (
        <div id="TabularMenu" className="ui top attached tabular menu" style={{ padding: 0 }}>
          {this.renderTabs()}
        </div>
      );
    return (
      <div id="TabularMenu" className="ui top attached tabular menu" style={{ padding: '0 1rem' }}>
        {this.renderTabs()}
        {this.openTabs()}
        {this.renderRightMenuLink()}
      </div>
    );
  }

  renderBottomTabSegment() {
    const { tabs } = this.props;
    const { isMobileSize } = this.globals.device;
    return (
      <div
        className="ui bottom attached active tab segment"
        style={{
          padding: '0',
          minHeight: '20em',
          display: 'block',
          marginTop: !tabs ? (isMobileSize ? '1rem' : '5rem') : undefined,
        }}
      >
        {this.props.children}
      </div>
    );
  }

  render() {
    const { isMobileSize } = this.globals.device;
    if (isMobileSize) {
      return (
        <ErrorBoundary>
          <div>
            <h1 style={{ padding: '1em 0', textAlign: 'center', visibility: 'hidden' }}>
              {this.props.title} <i className={this.props.icon} />
            </h1>
            {this.renderTopAttachedMenuTabs()}
            {this.renderBottomTabSegment()}
          </div>
        </ErrorBoundary>
      );
    }
    return (
      <ErrorBoundary>
        <div>
          <Transition in animation="fade" className={'no-print'}>
            <h1 style={{ padding: '1em 1em 0em 0em', float: 'right', visibility: 'hidden' }}>
              {this.props.title} <i className={this.props.icon} />
            </h1>
          </Transition>
          <LanguageToggleIcons style={{ margin: '1rem' }} />
          {this.renderTopAttachedMenuTabs()}
          {this.renderBottomTabSegment()}
        </div>
      </ErrorBoundary>
    );
  }
}
