import React from 'react';
import { InventoryEntityDocument, Table } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';

interface Props {
  inventoryEntities: InventoryEntityDocument[];
  renderInnerTableRow: (item: InventoryEntityDocument) => JSX.Element;
  isEditable?: Boolean;
}

export const InventoryInnerTable = ({ inventoryEntities, renderInnerTableRow, isEditable }: Props) => {
  const { t } = useLocale();

  return (
    <Table
      className={'ui striped unstackable selectable table'}
      minWidth={'10rem'}
      dataList={inventoryEntities}
      headers={[
        { title: t('Title') },
        { title: 'Status' },
        { title: t('Location') },
        isEditable ? { title: t('Edit'), float: 'right' } : { title: t('Booking number') },
      ]}
      renderRow={(item: InventoryEntityDocument) => {
        return renderInnerTableRow(item);
      }}
    />
  );
};
