import React from 'react';
import { Checkbox, PriceCategoryDocument } from '../../../_dependencies';
import { useLocale } from '../../../_locales';

interface Props {
  checked: boolean;
  onToggle: () => void;
  priceCategories: PriceCategoryDocument[];
  disabled: boolean;
  hide?: boolean;
}

export const IncludeBasePriceCheckbox = ({
  checked,
  onToggle,
  priceCategories,
  disabled,
  hide: isBookingRequest,
}: Props) => {
  const { t } = useLocale();

  const basePriceCategory = priceCategories?.find((categories) => categories?.basePrice);
  if (!basePriceCategory || isBookingRequest) return null;

  return (
    <div style={{ ...spaceBetweenStyle, marginTop: '.5rem' }}>
      <span>{t('Include baseprice')}</span>
      <Checkbox toggle disabled={disabled} checked={checked && !disabled} onCheckedOrUncheked={onToggle} />
    </div>
  );
};

const spaceBetweenStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
