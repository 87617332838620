import Fuse from 'fuse.js';
import { sumBy } from 'lodash';
import moment from 'moment-timezone';
import React, { Fragment, useEffect, useState } from 'react';
import {
  BookingDocument,
  BookingPriceCategoryDocument,
  Input,
  InventoryEntityDocument,
  InventoryStatusDocument,
  InventoryStatusResource,
  PlaceDocument,
  PriceCategoryDocument,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { renderPlace } from '../inventoryItemStatusForm';

interface Props {
  isTemporary?: boolean;
  places?: PlaceDocument[];
  entity: InventoryEntityDocument;
  bookings: BookingDocument[];
  currentBooking?: BookingDocument;
  onSelectBooking: (Booking: BookingDocument) => void;
}

/** Helper function to filter out price categories that are connected to an inventory */
function getInventoryCategories<T extends PriceCategoryDocument>(pcs?: T[], isTemporary?: boolean): T[] {
  if (!pcs) return [];
  return pcs.filter((pc) => (isTemporary ? pc.id == 'c7c6f5d6-d1bf-4e62-a6f2-b5da138a2e12' : pc.inventory));
}

export const BookingsSelectorSegment = ({
  bookings,
  onSelectBooking,
  currentBooking,
  entity,
  places,
  isTemporary,
}: Props) => {
  const [query, setQuery] = useState<string>();
  const [filteredBookings, setFilteredBookings] = useState<BookingDocument[]>();
  const [selectedBooking, setSelectedBooking] = useState<BookingDocument>();
  const [alreadyCheckedIn, setAlreadyCheckedIn] = useState<InventoryStatusDocument[]>();
  const inventoryStatusResource = new InventoryStatusResource();
  const { t, tt } = useLocale();

  //Filters the bookings by the attached activity start dates.
  //Overrides when query search is input
  const getBookingsForToday = () => {
    const today = moment();
    return bookings
      .filter((booking: any) => moment(booking.occurance.start).isSame(today, 'day'))
      .sort((b1: any, b2: any) => (b1.occurance.start > b2.occurance.start ? 1 : -1));
  };

  //Sets filtered bookings state based on query.
  //If no query, sorts by date and if no bookings from dates and there is a current booking, sets it to the current booking.
  useEffect(() => {
    if (query) {
      setFilteredBookings(filterBookingsByQuery(query));
    } else if (!selectedBooking) {
      setFilteredBookings(!getBookingsForToday().length ? bookings : getBookingsForToday());
    } else {
      setFilteredBookings([]);
    }
  }, [bookings, query, selectedBooking]);

  //If entity has a booking attached, automatically sets the selected booking to the current booking
  //But if the entity is available to be booked. No Booking is selected
  useEffect(() => {
    if (currentBooking) {
      if (entity.lastStatus.status === 'available' || entity.lastStatus.status === 'unavailable') return;
      onSelectBooking(currentBooking);
      setSelectedBooking(currentBooking);
    }
  }, [currentBooking]);

  useEffect(() => {
    if (selectedBooking) {
      (async () => {
        try {
          const checkedIn = await inventoryStatusResource.findAndPopulateInventoryEntities({
            booking: selectedBooking._id,
            status: isTemporary ? 'checked-in' : 'in-use',
          });
          setAlreadyCheckedIn(checkedIn);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [selectedBooking, currentBooking]);

  function filterBookingsByQuery(query: string) {
    const bookingsWithoutSelectedBooking = bookings.filter((b) => b.id !== selectedBooking?.id);
    const fuse = new Fuse(bookingsWithoutSelectedBooking, {
      threshold: 0.4,
      minMatchCharLength: 2,
      keys: [
        'message',
        'number',
        'notes',
        'totalPrice',
        'createdAt',
        'customer.firstname',
        'customer.lastname',
        'customer.businessName',
        'customer.email',
        'occurance.start',
        'occurance.title',
      ],
      getFn: (document, path: string[]) =>
        tt(path.reduce((object, path) => (object ? object[path] : object), document)),
    });

    return fuse.search(query).map((fuseDocument) => fuseDocument.item);
  }

  const handleRowClicked = (booking: BookingDocument) => {
    onSelectBooking(booking);
    setSelectedBooking(booking);
    setQuery('');
  };

  const checkIfActiveBooking = (booking: BookingDocument) => selectedBooking?.id === booking.id;

  const renderCheckedInList = (booking: BookingDocument) => {
    return alreadyCheckedIn?.map((item, index) => {
      const entity = item.inventoryEntity as unknown as InventoryEntityDocument;
      const canCheckIn = sumBy(
        getInventoryCategories(booking.priceCategories, isTemporary),
        (category: BookingPriceCategoryDocument) => category.tickets,
      );

      return (
        <tr
          id={item.id}
          className={checkIfActiveBooking(booking) ? 'positive' : ''}
          key={`invStat-${index}`}
          style={{ width: '100%' }}
        >
          <td style={{ fontSize: '0.7em', width: '1em', whiteSpace: 'nowrap' }}>
            <i className="icon green checkmark" />
            {entity.title} {`(${(index += 1)}/${canCheckIn})`}
          </td>
          <td className="center aligned" style={{ fontSize: '0.7em', width: '100%' }}>
            {t(item.status)}
          </td>
          <td style={{ fontSize: '0.7em', width: '100%', textAlign: 'right', whiteSpace: 'nowrap' }}>
            {tt(renderPlace(places, entity, item)?.name)}
          </td>
        </tr>
      );
    });
  };

  const renderDetailsRow = (booking: BookingDocument) => {
    return (
      <Fragment key={booking.id}>
        <tr
          className={checkIfActiveBooking(booking) ? 'positive' : ''}
          key={booking.id}
          onClick={() => handleRowClicked(booking)}
          style={{ width: '100%' }}
        >
          <td style={{ fontSize: '0.9em', width: '1em', whiteSpace: 'nowrap' }}>
            {checkIfActiveBooking(booking) ? <i className="icon green checkmark" /> : null} {booking.number}
          </td>
          <td className="center aligned" style={{ fontSize: '0.9em', width: '100%' }}>
            {booking.customer.firstname} {booking.customer.lastname}
          </td>
          <td style={{ fontSize: '0.9em', width: '100%', textAlign: 'right', whiteSpace: 'nowrap' }}>
            {moment((booking.occurance as any).start).format('DD-MM-YY HH:mm')}
          </td>
        </tr>
        {alreadyCheckedIn?.length && booking.id === selectedBooking?.id ? renderCheckedInList(booking) : null}
      </Fragment>
    );
  };

  return (
    <div style={{ marginBottom: '1rem', backgroundColor: '#FFF', borderRadius: '0.5em' }}>
      <div style={selectorContainer}>
        <div style={{ margin: '0.5rem 0' }}>
          <h4 style={{ color: 'black', paddingLeft: '0.8em' }}>
            <strong>{isTemporary ? t('Booking') : t('Select a booking')}</strong>
          </h4>
        </div>
        {isTemporary ? null : (
          <div className="ui item" style={{ margin: '0.5rem 0' }}>
            <Input
              name="search"
              value={query}
              onChange={(value) => setQuery(value)}
              style={{ paddingRight: '0.5em', borderRadius: '1em', width: '9em' }}
              type="text"
              placeholder={t('Search')}
              icon="search"
            />
          </div>
        )}
      </div>
      {!bookings || !filteredBookings ? (
        <div className="ui active inverted dimmer">
          <div className="ui loader"></div>
        </div>
      ) : (
        <table
          className="ui basic selectable unstackable table"
          style={{ border: 'none', marginTop: 0, height: '12rem' }}
          cellSpacing="0"
          cellPadding="0"
        >
          <thead style={{ display: 'none' }} />
          <tbody style={tableBodyStyle}>
            {selectedBooking && renderDetailsRow(selectedBooking)}
            {filteredBookings.map((booking) => renderDetailsRow(booking))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const selectorContainer: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: 'rgba(34, 36, 38, 0.1)',
};

const tableBodyStyle: React.CSSProperties = {
  display: 'block',
  height: '100%',
  width: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
};
