import React, { Component } from 'react';
import { AHLocaleContext } from '../../../../../_locales';
import {
  PriceCategoryDocument,
  Consume,
  LocaleContext,
  Rules,
  Input,
  DeviceProvider,
  IDevice,
  Field,
  TranslatableTextField,
  TranslatableText,
} from '../../../../../_dependencies';
import { InventoryDropdown } from '../availableVisitors/inventoryDropdown';
import { Types } from 'mongoose';

interface Props {
  priceCategory: PriceCategoryDocument;
  trueIndex: number;
  nrOfRows: number;
  rowNumber: number;
  changePriceCategory: (index: number, priceCategory: PriceCategoryDocument) => void;
  removePriceCategory: (index: number) => void;
  isUsingInventory?: boolean;
}

interface State {
  inventorySet: boolean;
}

export class PriceCategoryRow extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      inventorySet: false,
    };
  }

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(DeviceProvider.Context)
  private _device: IDevice;

  private get nameRules() {
    const { t } = this._locale;
    const startRow = this.props.rowNumber - 1;

    const rules = [Rules.NotEmpty(t('Name the category'))];
    for (let i = startRow; i >= 0; i--) {
      rules.push(Rules.NotTheSameAs('priceCategoryName' + i + '-sv'));
    }
    return rules;
  }

  componentDidMount() {
    this.setState({ inventorySet: Boolean(this.props.priceCategory.inventory) });
  }

  private get actionIcons() {
    const { t } = this._locale;
    const { changePriceCategory, removePriceCategory, priceCategory, trueIndex, isUsingInventory } = this.props;
    const removeIcon = (
      <i
        className="ui red trash icon"
        style={{ cursor: 'pointer', marginBottom: 4 }}
        onClick={() => {
          removePriceCategory(trueIndex);
        }}
      />
    );

    return (
      <div style={{ display: 'flex' }}>
        {this.renderLabel(' ')}
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '2.4rem' }}>
          {this.props.nrOfRows > 1 && removeIcon}
          <div
            data-tooltip={priceCategory.isHidden ? t('Only visible to you') : t('Visible to your customers')}
            data-position="left center"
          >
            <i
              className={`ui ${priceCategory.isHidden ? 'grey' : 'green'} eye icon`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const newPriceCategory = { ...priceCategory, isHidden: !priceCategory.isHidden };
                changePriceCategory(trueIndex, newPriceCategory);
              }}
            />
          </div>
        </div>
        {isUsingInventory && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: this.props.nrOfRows > 1 ? '1.2rem' : '2.4rem',
            }}
          >
            <div data-tooltip={t('Select inventory')} data-position="left center">
              <i
                className={`ui ${priceCategory.inventory ? 'blue' : 'grey'} cubes icon`}
                style={{ cursor: 'pointer' }}
                onClick={() => this.setState({ inventorySet: !this.state.inventorySet })}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  private get renderInvisibleLable() {
    const { priceCategory, isUsingInventory, rowNumber } = this.props;
    if (isUsingInventory && priceCategory.name == '') {
      return null;
    } else if (!this.state.inventorySet) {
      return null;
    } else if (rowNumber == 0 && priceCategory.name != undefined && this.state.inventorySet) {
      return <p style={{ marginBottom: '.5rem', visibility: 'hidden' }}>{'x'}</p>;
    }
  }

  private renderLabel(label: string) {
    const { rowNumber } = this.props;
    const { size } = this._device;

    if (rowNumber !== 0 && size !== 'mobile') return null;

    return <p style={{ marginBottom: '.5rem', visibility: label ? 'visible' : 'hidden' }}>{label || 'x'}</p>;
  }

  private manageInputChanges = (
    key: keyof PriceCategoryDocument,
    value: string | TranslatableText | Types.ObjectId,
    valueType: 'number' | 'string' | 'TranslatableText' | 'inventory',
  ) => {
    if (typeof value === 'string' && valueType === 'number' && typeof parseFloat(value) !== 'number') return;
    let v;
    if (typeof value === 'string' && valueType === 'number') {
      v = value ? parseFloat(value) : undefined;
    } else {
      v = value;
    }
    const { changePriceCategory, priceCategory, trueIndex } = this.props;
    const update = { ...priceCategory, [key]: v };
    changePriceCategory(trueIndex, update);
  };

  render() {
    const { t } = this._locale;
    const { priceCategory, isUsingInventory } = this.props;
    const { name, price, groupMultiplier, inventory } = priceCategory;
    const categoryPrice = String(price ?? '');
    const categoryGroup = groupMultiplier ? String(groupMultiplier) : '';

    return (
      <div className="row field" style={{ padding: 0, margin: 0, display: 'flex', columnGap: '.5rem' }}>
        {!isUsingInventory || this.state.inventorySet ? (
          <>
            <Field style={{ flex: 1 }}>
              {this.renderLabel(t('Name'))}
              <TranslatableTextField
                onChange={(value) => this.manageInputChanges('name', value, 'TranslatableText')}
                name={'priceCategoryName' + this.props.rowNumber}
                value={name}
                placeholder={t('Name')}
                rules={this.nameRules}
              />
            </Field>
            <div style={{ width: 'auto', flex: 1, display: 'flex', gap: '.5rem' }}>
              <Field style={{ flex: 4 }}>
                {this.renderLabel(t('Price'))}
                <Input
                  onChange={(value) => this.manageInputChanges('price', value, 'number')}
                  defaultValue=""
                  value={categoryPrice}
                  rules={[Rules.NotEmpty(t('Set a price')), Rules.IsANumber(false, t('Set the price in SEK'))]}
                  name={'priceCategoryPrice' + this.props.rowNumber}
                  placeholder={t('Price')}
                  rightLabel={t('kr-SEK')}
                  inputStyle={{ width: '6rem', flex: 1 }}
                />
              </Field>
              <Field style={{ flex: 1 }}>
                {this.renderLabel(t('Valid for'))}
                <Input
                  onChange={(value) => this.manageInputChanges('groupMultiplier', value, 'number')}
                  value={categoryGroup}
                  disabled={Boolean(priceCategory.inventory)}
                  rules={[
                    Rules.NotEmpty(),
                    Rules.IsANumber(false, t('Enter a number of how many...')),
                    Rules.IsIntegerOneOrHigherElseEmpty(t('Group number must be 1 or larger')),
                  ]}
                  name={'priceCategoryAmount' + this.props.rowNumber}
                  rightLabel={<i className="ui male icon" style={{ margin: 0 }} />}
                  inputStyle={{ width: '4rem', flex: 1 }}
                />
              </Field>
            </div>
          </>
        ) : (
          <Field width={9} style={{ flex: 1 }}>
            <InventoryDropdown
              priceCategoryInventory={inventory?.toString()}
              onChange={(value) => {
                this.manageInputChanges('inventory', value._id, 'inventory');
                this.manageInputChanges(
                  'name',
                  { sv: value.title, en: '', de: '' } as TranslatableText,
                  'TranslatableText',
                );
                this.manageInputChanges('groupMultiplier', value.groupMultiplier?.toString() || '1', 'number');
                this.setState({ inventorySet: true });
              }}
            />
          </Field>
        )}
        <Field>
          {this.renderInvisibleLable}
          {this.actionIcons}
        </Field>
      </div>
    );
  }
}
