import React from 'react';
import { CSSProperties } from 'react';
import { useLocale } from '../../_locales';

const AboutAHSection = () => {
  const { t } = useLocale();

  return (
    <div id="register-start" className="ui grid center aligned container" style={containerStyle}>
      <div className="ui stackable center aligned three column grid">
        <div className="column">
          <img className="ui centered medium image" src="/static/platform/img/what.png" style={{ width: '200px' }} />
          <h2>{t('What')}?</h2>
          <p>
            {t('AdventureHero is a modular business...')} <br />
            {t('But we are not just a platform...')}
          </p>
        </div>
        <div className="column">
          <img className="ui centered medium image" src="/static/platform/img/how.png" style={{ width: '175px' }} />
          <h2>{t('How')}?</h2>
          <p>
            {t('By gathering your administrative tasks...')} <br />
            {t('Create an account for free...')}
          </p>
        </div>
        <div className="column">
          <img className="ui centered medium image" src="/static/platform/img/why.png" style={{ width: '160px' }} />
          <h2>{t('Why')}?</h2>
          <p>{t('We believe in connecting...')}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutAHSection;

const containerStyle: CSSProperties = {
  margin: '6rem 0',
};
