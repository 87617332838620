import Lodash from 'lodash';
import { computed } from 'mobx';
import * as React from 'react';
import { ChangableComponent } from './changable';
import { Icon } from './icon';
import { TextFormatRule } from './rules';

/** Type declaring valid autoComplete values */
type ValidAutoCompleteValue =
  // Option A
  | 'name'
  | 'honorific-prefix'
  | 'given-name'
  | 'additional-name'
  | 'family-name'
  | 'honorific-suffix'
  | 'nickname'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'organization-title'
  | 'organization'
  | 'street-address'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'address-level4'
  | 'address-level3'
  | 'address-level2'
  | 'address-level1'
  | 'country'
  | 'country-name'
  | 'postal-code'
  | 'cc-name'
  | 'cc-given-name'
  | 'cc-additional-name'
  | 'cc-family-name'
  | 'cc-number'
  | 'cc-exp'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-csc'
  | 'cc-type'
  | 'transaction-currency'
  | 'transaction-amount'
  | 'language'
  | 'bday'
  | 'bday-day'
  | 'bday-month'
  | 'bday-year'
  | 'sex'
  | 'url'
  | 'photo'
  // Option B 1
  | 'tel'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-area-code'
  | 'tel-local'
  | 'tel-local-prefix'
  | 'tel-local-suffix'
  | 'tel-extension'
  // Option B 2
  | 'email'
  | 'impp'
  | 'home'
  | 'work'
  | 'mobile'
  | 'fax'
  | 'pager';

// Sourced from https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute

/**
 * An input is a versitile changable component for getting text strings from the user
 * It can be further extended to allow
 */
export class Input extends ChangableComponent<
  string,
  {
    /** ID */ id?: string;
    /** Style for inputfield */ inputStyle?: {};
    /** An icon to display inside the input */ icon?: Icon;
    /** Autocomplete field **/ autoComplete?: ValidAutoCompleteValue;
    /** A list of rules that applies to this component */ rules?: TextFormatRule[];
    /** A placeholder shown inside the input when otherwise empty */ placeholder?: string;
    /** Hide characters made to this input */ protectedCharacters?: boolean;
    /** Specifies type of the input field */ type?: string;
    /** Makes the input field take up the width of it's prarent container */ fluid?: boolean;
    /** Adds a prefix to the input */ label?: React.ReactNode;
    /** Label attached on the right side */ rightLabel?: string | React.ReactNode;
  }
> {
  updateComponentValue(value: string) {
    return value;
  }

  @computed private get inputProps() {
    // Set default properties
    const props = {
      guide: true,
      autoComplete: this.props.autoComplete,
      placeholderChar: '_',
      disabled: this.disabled || this.props.disabled,
      pattern: undefined as any,
      type: this.props.type || 'text',
      name: this.props.name,
      placeholder: undefined as any,
      maxLength: undefined as any,
      onChange: (event) => {
        this.updateComponentValue(event.target.value);
        this.forceUpdate(); //TODO: this should not be neccessary, why is the component not listening to this.value?
      },
    };

    // Load properties from input rules
    Lodash.forEach(this.props.rules || [], (rule: TextFormatRule) => {
      if (rule.keyboard) {
        props.type = rule.keyboard;
      }

      if (rule.placeholder) {
        props.placeholder = rule.placeholder;
      }

      if (rule.type.substring(0, 9) == 'maxLength' || rule.type.substring(0, 11) == 'exactLength') {
        props.maxLength = (rule.type.match(/\d+/) as any)[0];
      }
    });

    // Numeric keyboards are triggered by pattern and not type, so let's fix that
    if (props.type == 'integer') {
      props.type = 'text';
      props.pattern = '[0-9]*';
    }

    // Set the placeholder for the input
    props.placeholder = this.props.placeholder || props.placeholder;

    // Lastly and most important, we protect entered character for this input if this input is protected
    if (this.props.protectedCharacters) {
      props.type = 'password';
    }

    return props;
  }

  @computed private get inputComponent() {
    const props = this.inputProps;

    // Delete unknown and uneeded input props

    //@ts-ignore
    delete props.placeholderChar;
    //@ts-ignore
    delete props.guide;

    if (this.props.defaultValue) {
      // Make sure defaultValue is not an undefined string
      const defaultValue = this.props.defaultValue == 'undefined' ? undefined : this.props.defaultValue;
      return <input {...props} defaultValue={defaultValue || ''} style={this.props.inputStyle} key={this.props.name} />;
    } else {
      return <input {...props} value={this.value || ''} style={this.props.inputStyle} key={this.props.name} />;
    }
  }

  private get iconComponent() {
    if (this.props.icon) {
      return <i className={this.props.icon + ' ui icon'} />;
    }
    return undefined;
  }

  private get rightLabelComponent() {
    if (!this.props.rightLabel) return null;
    return <div className="ui basic label">{this.props.rightLabel}</div>;
  }

  render() {
    /* webkitTransform fixes a bug causing inputs to not show it's content correctly.. The bug is caused by an overflow scrolling parant contatiner */
    const style = this.props.type == 'hidden' ? { display: 'none' } : { WebkitTransform: 'translateZ(0px)' };
    const className =
      'ui input ' +
      (this.props.className ? this.props.className : '') +
      (this.loading || this.props.loading ? ' loading' : '') +
      (this.disabled || this.props.disabled ? ' disabled' : '') +
      (this.props.icon ? ' left icon' : '') +
      (this.props.fluid ? ' fluid' : '') +
      (this.props.label ? ' labeled' : '') +
      (this.props.rightLabel ? ' right labeled' : '');
    return (
      <div className={className} key={this.props.name} style={Lodash.merge(style, this.props.style)}>
        {this.props.label && <div className="ui label">{this.props.label}</div>}
        {this.iconComponent}
        {this.inputComponent}
        {this.rightLabelComponent}
      </div>
    );
  }
}
