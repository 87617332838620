import Lodash from 'lodash';
import { autorunAsync, computed, observable, toJS } from 'mobx';
import { Types } from 'mongoose';
import {
  Alert,
  DefaultPredicate,
  LegacyProviderClass,
  PropertyDocument,
  PropertyResource,
  SearchableListStore,
  Store,
  UserDocument,
  UserResource,
  UserRoleDocument,
} from '../../../_dependencies';
import { globalT } from '../../../_locales';

export class PropertyEmployeesListStore extends SearchableListStore<UserDocument> {
  private _allPredicate = (text, item) =>
    DefaultPredicate(text, item.firstname.toString() + item.lastname.toString() + item.username.toString());
  private _staffPredicate = (text, item: UserDocument) => {
    if (Lodash.includes(this.property.crew, item._id))
      return DefaultPredicate(text, item.firstname.toString() + item.lastname.toString() + item.username.toString());
  };
  private _managersPredicate = (text, item: UserDocument) =>
    DefaultPredicate(text, item.firstname.toString() + item.lastname.toString() + item.username.toString());

  allEmployees: UserDocument[];
  property: PropertyDocument;
  constructor(property: PropertyDocument) {
    super([]);
    this.predicate = this._staffPredicate as any;
    this.property = property;

    if (property) {
      new UserResource().find({ organization: property.organization }).then((results) => {
        this.items = results as UserDocument[];
      });
    }
  }

  ListStaff() {
    this.predicate = this._staffPredicate as any;
  }

  ListAll() {
    this.predicate = this._allPredicate;
  }
}

/** @depricated */
export class PropertyStore extends Store {
  private _originalPropertyWhenSet: PropertyDocument;
  private _employeesListStore: PropertyEmployeesListStore;
  private _resource = new PropertyResource();

  @observable private _property: PropertyDocument;
  @computed get property(): PropertyDocument {
    return this._property;
  }

  @computed get EmployeeList(): PropertyEmployeesListStore {
    this._employeesListStore = this._employeesListStore || new PropertyEmployeesListStore(this._property);
    return this._employeesListStore;
  }

  UpdateEmployeeList(): void {
    if (this.hasProperty()) {
      this._employeesListStore = new PropertyEmployeesListStore(this._property);
    }
  }

  constructor() {
    super();
    this._employeesListStore = new PropertyEmployeesListStore(undefined as any);

    autorunAsync(() => {
      if (this.hasProperty()) {
        const updatedProperty = toJS(this.property);

        if (Lodash.isEqual(updatedProperty, this._originalPropertyWhenSet)) {
          return;
        }

        const document = this._resource.createDocument(this.property);
        this._resource
          .updateDocument(document)
          .then(() => {
            // TODO: Let components subscribe to a function that is called on saved
          })
          .catch(() => {
            // If save failed reset the property and display a message
            this._property = Lodash.merge({}, this._originalPropertyWhenSet) as PropertyDocument;
            if (LegacyProviderClass.singleton) {
              Alert.show(
                globalT('components.dashboard.properties.propertyStore.saveErrorDetails'),
                globalT('components.dashboard.properties.propertyStore.saveErrorTitle'),
                'error',
              );
            } else {
              console.error('No locale provider mounted');
            }
          });
      }
    }, 1500); // TODO: what is the timer for?
  }

  public setProperty(value: PropertyDocument) {
    this._originalPropertyWhenSet = Lodash.merge({}, value) as PropertyDocument;
    this._property = value;
    this._employeesListStore.property = this._property;
  }

  public findProperty(id: Types.ObjectId | String) {
    if (this.hasProperty() && this.property._id == id) {
      return Promise.resolve();
    }
    return new PropertyResource().get(id).then((result) => this.setProperty(result));
  }

  public clearProperty(): void {
    this._property = undefined as any;
    this._originalPropertyWhenSet = undefined as any;
  }

  public hasProperty(): boolean {
    return !!this._property;
  }

  public isUserAllowedToEditProperty = (): boolean => {
    const role: UserRoleDocument = {
      type: 'property.manager',
      property: this.property._id,
      organization: this.property.organization,
    };
    return this.globals.session.userHasRole(role);
  };

  private static _instance: PropertyStore;
  public static get Instance(): PropertyStore {
    this._instance = this._instance || new PropertyStore();
    return this._instance;
  }
}
