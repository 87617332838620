import { isEqual } from 'lodash';
import { BookingPriceCategoryDocument, PriceCategoryDocument } from '../../_dependencies';

/**
 * Returns an array with the booked price categories combined with price categories that are not yet booked.
 * To make comparison easier the price is always presented per ticket (booked price category prices are divided with the number of tickets).
 * @param {PriceCategoryDocument[]} occurancePriceCategories - Price categories included in the occurence
 * @param {BookingPriceCategoryDocument[]} bookedPriceCategories - Booked price categories with number of tickets
 * @returns A combined list where the custom price category is not part of the list.
 */

export const combinePriceCategories = (
  occurancePriceCategories: PriceCategoryDocument[],
  bookedPriceCategories?: BookingPriceCategoryDocument[],
): BookingPriceCategoryDocument[] => {
  const allCategories = (bookedPriceCategories || [])
    .filter((c) => !c.isCustom)
    .map((c) => {
      //Adds availableInventory from occurance priceCategories to booked priceCategories
      const samePriceCategory = occurancePriceCategories.find((oc) => isEqual(oc.name, c.name));
      return { ...c, price: c.price / c.tickets, availableInventory: samePriceCategory?.availableInventory };
    });

  for (const c of occurancePriceCategories) {
    const exists = allCategories.some(
      ({ name, price, groupMultiplier }) =>
        isEqual(name, c.name) && price === c.price && groupMultiplier === c.groupMultiplier,
    );
    if (!exists) allCategories.push({ ...c, tickets: 0, availableInventory: c.availableInventory });
  }

  return allCategories;
};

/**
 * Multiplies the price for each price category with the number of tickets, to get a combined price to save in the database
 * @param {BookingPriceCategoryDocument[]} priceCategories - Booked price categories with number of tickets
 */

export const getPriceCategoriesWithTotalPrice = (priceCategories: BookingPriceCategoryDocument[]) => {
  const priceCategoriesWithTotalPrice: BookingPriceCategoryDocument[] = [];
  priceCategories.forEach((pc) => {
    if (pc.basePrice) {
      priceCategoriesWithTotalPrice.push(pc);
    } else {
      const totalPriceForCategory = pc.price * pc.tickets;
      const priceCategory = { ...pc, price: totalPriceForCategory };
      priceCategoriesWithTotalPrice.push(priceCategory);
    }
  });
  return priceCategoriesWithTotalPrice;
};
