import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';

export interface AvailableActivityTimeSlot extends MongooseDocument {
  employee: Types.ObjectId;
  property: Types.ObjectId;
  offer: Types.ObjectId;
  start: Date;
}

/**
 * This is a special resources that can read the magical victorious view in the database
 * That automatically calculates the needed fields and removes conflicting occurances
 *
 * This resource returns documents created in the database containing time slots for when
 * an employee can be assigned to new occurances using employee schedule bookings
 *
 * @author Albus Dumbledore
 */
export class AvailableActivityTimeSlotResource extends MongooseResource<AvailableActivityTimeSlot> {
  constructor() {
    super();

    // This name must manually match the view in the database without an s
    this.setName(SchemaNames.AvailableActivityTimeSlot);

    this.setSchema({
      employee: { type: Schema.Types.ObjectId, ref: SchemaNames.Default, required: true },
      property: { type: Schema.Types.ObjectId, ref: SchemaNames.Property, required: true },
      offer: { type: Schema.Types.ObjectId, ref: SchemaNames.ActivityType, required: true },
      start: { type: Date, required: true },
    });
  }

  // NOTE: This method is superslow, its currently not needed in the system
  datesWithTimeSlotsThatCanBeBooked(propertyId: string, offerId: string, bookingClosesBeforeEventInHours?: number) {
    return this.sendRequest<string[]>('/datesWithTimeSlotsThatCanBeBooked', 'post', {
      propertyId,
      offerId,
      bookingClosesBeforeEventInHours,
    });
  }
}
