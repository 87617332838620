import { action, observable, runInAction } from 'mobx';
import * as React from 'react';
import {
  Consume,
  Field,
  Form,
  Input,
  LocaleContext,
  MobxComponent,
  Platforms,
  Rules,
  SubmitButton,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { AdminStore } from './store';
import { UserFormContent } from './userFormContent';

export class NewOrganizationForm extends MobxComponent<{}> {
  @observable private _stripeMail: string;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  componentDidMount() {
    this.updateStripeMail();
  }

  componentDidUpdate() {
    if (AdminStore.isInitStoreNeeded) {
      runInAction(() => {
        AdminStore.initStore();
        this._stripeMail = '';
      });
    }
  }

  createOrganization = (values, reject, resolve) => {
    AdminStore.agreementDocument.stripeAccountMail = this._stripeMail;
    AdminStore.userDocument.organization = AdminStore.organizationDocument._id;
    AdminStore.userDocument.roles = [
      {
        type: 'organization.manager',
        organization: AdminStore.organizationDocument._id,
      },
    ];

    AdminStore.createNewOrganization(reject, resolve, values['Lösenord1']);
  };

  @action private handleOnOrgNameChanged = (value: string) => {
    AdminStore.organizationDocument.name = value;
    this.updateStripeMail();
  };

  private updateStripeMail = () => {
    if (!AdminStore.organizationDocument.name) {
      return;
    }

    const emailFriendlyOrgName = AdminStore.organizationDocument.name
      .replace(/[åä]/gi, 'a')
      .replace(/[ö]/gi, 'o')
      .replace(/[^a-z0-9]/gi, '_')
      .toLowerCase();
    this._stripeMail = `${emailFriendlyOrgName}@kund.${Platforms.mailDomain}`;
  };

  render() {
    if (AdminStore.isInitStoreNeeded) {
      return <span />;
    }

    const { t } = this._locale;

    return (
      <div style={{ padding: '2em' }}>
        <h2 className="ui header">
          <i className="icons" style={{ paddingTop: '0.1em' }}>
            <i className="university icon" />
            <i className="large corner plus icon" />
          </i>
          <div className="content">{t('Create new organization')}</div>
        </h2>
        <div className="ui basic segment" style={{ minWidth: '400px', maxWidth: '700px' }}>
          <div id="uploadFormTarget" />
          <Form onSubmit={this.createOrganization}>
            <div style={{ height: '1em' }} />
            <Field label={t('company name')}>
              <Input
                name="Organisationsnamn"
                value={AdminStore.organizationDocument.name}
                onChange={this.handleOnOrgNameChanged}
                rules={[Rules.NotEmpty()]}
                className="required"
              />
            </Field>
            <UserFormContent title={t('Details about organization manager')} uploadFormElementId="uploadFormTarget" />
            <SubmitButton className="fluid green">{t('Create an organization')}</SubmitButton>
          </Form>
        </div>
      </div>
    );
  }
}
