import React from 'react';
import { sumBy } from 'lodash';
import { useLocale } from '../../../_locales';
import {
  Field,
  Input,
  BookingPriceCategoryDocument,
  Rules,
  visitors,
  getBasePrice,
  TaxRate,
  getTickets,
  getRescheduleInsurance,
  AccessoryDocument,
  getPriceCategoriesWithoutRescheduleInsurance,
  getRescheduleFee,
  calculateTotalPrice,
} from '../../../_dependencies';

interface Props {
  useCustomPrice: boolean;
  selectedPriceCategories: BookingPriceCategoryDocument[];
  defaultPriceCategories?: BookingPriceCategoryDocument[];
  onCustomCategoryChange: (category: BookingPriceCategoryDocument) => void;
}

export const PriceDetails = ({
  useCustomPrice,
  selectedPriceCategories,
  defaultPriceCategories,
  onCustomCategoryChange,
}: Props) => {
  const { t } = useLocale();

  const basePriceCategory = getBasePrice(selectedPriceCategories);
  const rescheduleCategory = getRescheduleInsurance(selectedPriceCategories);
  const defaultPrice = sumBy(defaultPriceCategories, ({ price }) => price);
  const nrOfVisitors = sumBy(selectedPriceCategories, (pc) => visitors(pc));
  const nrOfTickets = sumBy(selectedPriceCategories, ({ tickets }) => tickets);
  const defaultNrOfVisitors = sumBy(defaultPriceCategories, (pc) => visitors(pc));
  const rescheduleFee = getRescheduleFee(selectedPriceCategories);
  const totalPrice = calculateTotalPrice(selectedPriceCategories, true);

  const handleCustomPriceChange = (price: string) => {
    createCustomCategory(Number(price), selectedPriceCategories[0]?.groupMultiplier || 0);
  };
  const handleCustomVisitorsChange = (visitors: string) => {
    createCustomCategory(selectedPriceCategories[0]?.price || 0, Number(visitors));
  };

  const createCustomCategory = (price: number, groupMultiplier: number) => {
    onCustomCategoryChange({
      name: { sv: 'Anpassat erbjudande', en: 'Special Offer', de: 'Sonderangebot' },
      tickets: 1,
      isCustom: true,
      price,
      groupMultiplier,
    });
  };

  const renderCustomPriceInputs = () => {
    return (
      <div className="ui two column grid" style={{ marginBottom: '.6rem' }}>
        <div className="row">
          <div className="column">
            <h3 className="ui header" style={{ marginTop: '.4rem' }}>
              {t('Total price')}:
            </h3>
          </div>
          <div className="column">
            <Field>
              <Input
                fluid
                name="Pris"
                placeholder={t('Total price')}
                rightLabel={t('SEK')}
                defaultValue={defaultPrice.toString()}
                rules={[Rules.NotEmpty(), Rules.IsANumber()]}
                onChange={handleCustomPriceChange}
              />
            </Field>
          </div>
        </div>
        <div className="row" style={{ paddingTop: 0 }}>
          <div className="column">
            <h5 className="ui header" style={{ marginTop: '.6rem' }}>
              {t('Number of visitors')}:
            </h5>
          </div>
          <div className="column">
            <Field>
              <Input
                fluid
                name="Antal"
                placeholder={t('Amount')}
                defaultValue={defaultNrOfVisitors.toString()}
                rules={[Rules.NotEmpty(), Rules.IsANumber()]}
                onChange={handleCustomVisitorsChange}
              />
            </Field>
          </div>
        </div>
      </div>
    );
  };

  const renderUpdatedPrice = () => {
    const previousRescheduleFee = getRescheduleFee(
      defaultPriceCategories as unknown as AccessoryDocument<BookingPriceCategoryDocument>[],
    );
    const previousPrice =
      sumBy(
        getPriceCategoriesWithoutRescheduleInsurance(
          defaultPriceCategories as unknown as AccessoryDocument<BookingPriceCategoryDocument>[],
        ),
        ({ price }) => price,
      ) + previousRescheduleFee;

    return (
      <div>
        <h5 className="ui header" style={{ marginBottom: '5px' }}>
          {t('Previous price')}: {previousPrice} {t('SEK')}
        </h5>
        <h5 className="ui header" style={{ marginTop: 0 }}>
          {t('New price')}: {totalPrice} {t('SEK')}
        </h5>
      </div>
    );
  };

  if (useCustomPrice || defaultPriceCategories) {
    return (
      <>
        {useCustomPrice && renderCustomPriceInputs()}
        {defaultPriceCategories && renderUpdatedPrice()}
      </>
    );
  }

  return (
    <h3 className="ui header" style={{ marginTop: 0 }}>
      {t('Total price')}: {totalPrice} {t('SEK')}
      <div className="sub header">
        {basePriceCategory && (
          <p style={{ marginBottom: '.1rem' }}>
            {t('The activity has a base price of')} {basePriceCategory.price} {t('SEK')}
          </p>
        )}
        {rescheduleCategory?.rescheduleInsurance.isFeePerPerson && (
          <p style={{ marginBottom: '.1rem' }}>
            {t('Reschedule insurance')}: {rescheduleFee} {t('SEK')}
          </p>
        )}
        <p style={{ marginBottom: '.1rem' }}>
          {t('Number of articles')}: {nrOfTickets}
        </p>
        <p>
          {t('Number of visitors')}: {nrOfVisitors}
        </p>
      </div>
    </h3>
  );
};
