import { BookingPriceCategoryDocument, CustomerDocument } from '../../../../_dependencies';
import {
  ConfirmedBookingRequestMetadata,
  CustomerUpdatedEventMetadata,
  MailCreationEventMetadata,
  MailType,
  PaymentEventMetadata,
  PaymentType,
  PriceCategoriesUpdatedEventMetadata,
  RejectedBookingRequestMetadata,
} from './_types';

// NOTE: This file contains undocumented utility methods for creating event metadata
// pretty useless, not use in all relevant instances because its locked to the adventure-hero module
// suggested action: Move to seperate module, integrate more closely with updateProduct in marketplace resouce

export const createPriceCategoriesUpdatedEvent = (
  by: string,
  from: BookingPriceCategoryDocument[],
  to: BookingPriceCategoryDocument[],
): PriceCategoriesUpdatedEventMetadata => ({
  key: 'price_categories_updated',
  by,
  from,
  to,
});

export const createPaymentEvent = (paymentOption: PaymentType): PaymentEventMetadata => ({
  key: 'payment_instance_created',
  paymentOption,
});

export const createCustomerUpdatedEvent = (
  by: string,
  from: CustomerDocument,
  to: CustomerDocument,
): CustomerUpdatedEventMetadata => ({
  key: 'customer_updated',
  from,
  to,
  by,
});

export const createdMailEvent = (
  by: string,
  to: string,
  success: boolean,
  mailType: MailType,
): MailCreationEventMetadata => ({
  key: 'created_mail',
  by,
  to,
  success,
  mailType,
});

export const createdBookingConfirmedEvent = (by: string, success: boolean): ConfirmedBookingRequestMetadata => ({
  key: 'booking_confirmed',
  by,
  success,
});

export const createdBookingRejectedEvent = (by: string, success: boolean): RejectedBookingRequestMetadata => ({
  key: 'booking_rejected',
  by,
  success,
});
