import { observable } from 'mobx';
import * as React from 'react';
import { Component, MobxComponent, IDevice, Consume, DeviceProvider } from '../../_dependencies';
import { LanguageToggleIcons } from '../languageToggleIcons';

export class AnchorLink extends Component<{ to: string; className?: string; activeClassName?: string } | any> {
  private READABILITY_OFFSET = 100;

  private targetDomElement;
  @observable private active = false;
  // TODO The component does not handle changed window height very well it seams?
  componentDidMount() {
    this.targetDomElement = $('[name="' + this.domElement.attr('href')?.substr(1) + '"]');
    if (!this.targetDomElement || !this.targetDomElement.length) {
      console.warn('Did not find an anchor element named ' + this.props.to);
      return;
    }

    this.targetDomElement.visibility({
      once: false,
      context: this.targetDomElement.closest('.pusher'),
      refreshOnResize: false,
      onUpdate: () => {
        if (this.targetIsVisible && !this.active) {
          this.active = true;
        } else if (this.active && !this.targetIsVisible) {
          this.active = false;
        }
      },
    });
  }

  private get targetIsVisible(): boolean {
    return this.targetDomElement.visibility('is on screen');
  }

  private get pusherDomElement() {
    return this.domElement.closest('.pusher');
  }

  componentWillUnmount() {
    this.targetDomElement.visibility('destroy');
  }

  scrollToTarget = (e) => {
    e.preventDefault();

    this.pusherDomElement.animate(
      {
        scrollTop: this.pusherDomElement.scrollTop() + this.targetDomElement.offset().top - this.READABILITY_OFFSET,
      },
      600,
    );
    return false;
  };

  render() {
    return (
      <a
        className={this.props.className + ' ' + (this.active ? this.props.activeClassName : '')}
        href={this.props.to}
        onClick={this.scrollToTarget}
      >
        {this.props.children}
      </a>
    );
  }
}

/** Top menu component */
export class CustomerTopMenu extends MobxComponent<{}> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  render() {
    const { pathname } = this.globals.session.currentLocation;
    const navigateToHome = () => {
      if (pathname.startsWith('/activities/search')) return;
      this.globals.session.navigateTo('/activities/search');
    };

    return (
      <div className="ui large borderless large inverted menu fixed" style={headerStyle}>
        {this.device.size === 'tablet' ? (
          <div style={tabletMenuContainerStyle}>
            <img src="/static/platform/img/logo_inverted.png" alt="Adventure Hero logo" style={logoStyle} />
            <div className="ui inverted segment right menu" style={{ background: '#0000' }}>
              {this.props.children}
            </div>
          </div>
        ) : (
          <>
            <div style={logoContainerStyle} onClick={navigateToHome}>
              <h1>ADVENTURE HERO</h1>
            </div>
            <div className="ui inverted segment right menu" style={{ background: '#0000' }}>
              {this.props.children}
            </div>
            <LanguageToggleIcons style={{ marginLeft: '2rem' }} />
          </>
        )}
      </div>
    );
  }
}

const headerStyle: React.CSSProperties = {
  backgroundColor: '#231F20CC',
  padding: '0 4%',
  alignItems: 'center',
};

const logoContainerStyle: React.CSSProperties = {
  cursor: 'pointer',
  color: 'white',
};

const tabletMenuContainerStyle: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
};

const logoStyle: React.CSSProperties = {
  height: '5rem',
  margin: '0.5rem 0',
};
