//  This file contains functions and objects for making sure text inputs and other changable components
//  are filled out correctly

import * as _ from 'lodash';
import Moment from 'moment-timezone';
// Import some predinfed masks from the text-mask library
// https://github.com/text-mask/text-mask/tree/master/addons
import { globalCurrentLocale } from '../../_locales';

/**
 * An format rule is used to validate changable components (inputs, selections, checkboxes) inside a form
 */
export interface FormatRule {
  /** A unique identifer for this rule, a Semantic UI form validation rule when applicable */ type: string;
  /** A prompt displayed when the input is invalid */ prompt?: string;
  /** A placeholder shown inside the input when otherwise empty */ placeholder?: string;
}

/**
 * An text format rule is used to validate changable components that accecpts text input
 */
export interface TextFormatRule extends FormatRule {
  /** The type of keyboard to use on devices with virtual keyboads */ keyboard?: 'text' | 'tel' | 'url' | 'integer'; // types email and number is not supported by the text-mask library & other con
}

// ----------------------------------
// Predefined format rules
// ----------------------------------

/** The value can not be empty */
export const NotEmpty = (prompt?: string): FormatRule & TextFormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'You need to enter a value';
        break;
      case 'de':
        prompt = 'Dieses Feld darf nicht leer sein';
        break;
      case 'sv':
        prompt = 'Du måste skriva in något här';
        break;
    }
  }
  return {
    type: 'empty',
    prompt: prompt,
  };
};

/** The value must be a valid as an url */
export const IsAnUrl = (prompt?: string): TextFormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter a working url';
        break;
      case 'de':
        prompt = 'Bitte geben Sie eine Arbeits-URL ein';
        break;
      case 'sv':
        prompt = 'Var snäll och ange en fungerande url';
        break;
    }
  }
  return {
    type: 'url',
    keyboard: 'url',
    prompt: prompt,
  };
};

/** The value must contains this string */
export const Containing = (value: string, caseSensitive?: boolean, prompt?: string): TextFormatRule => {
  return {
    type: 'contains' + (caseSensitive ? 'Exactly' : '') + '[' + value + ']',
    prompt: prompt || '',
  };
};

/** The value can't contain this string */
export const NotContaining = (value: string, caseSensitive?: boolean, prompt?: string): TextFormatRule => {
  return {
    type: 'doesntContain' + (caseSensitive ? 'Exactly' : '') + '[' + value + ']',
    prompt: prompt || '',
  };
};

/** The value must be exactly this string value */
export const Exactly = (value: string, caseSensitive?: boolean, prompt?: string): TextFormatRule => {
  return {
    type: 'is' + (caseSensitive ? 'Exactly' : '') + '[' + value + ']',
    prompt: prompt || '',
  };
};

/** The value must be different from this value */
export const NotExactly = (text: string, caseSensitive?: boolean, prompt?: string): TextFormatRule => {
  return {
    type: 'not' + (caseSensitive ? 'Exactly' : '') + '[' + text + ']',
    prompt: prompt || '',
  };
};

/** The value can't be longer than this length */
export const MaximumLength = (length: number, prompt?: string): TextFormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter less than ' + length + ' characters';
        break;
      case 'de':
        prompt = 'Bitte geben Sie weniger als ein ' + length + ' Zeichen';
        break;
      case 'sv':
        prompt = 'Var snäll och ange mindre än ' + length + ' tecken';
        break;
    }
  }
  return {
    type: 'maxLength[' + length + ']',
    prompt: prompt,
  };
};

/** The value can't be shorter than this length */
export const MinimumLength = (length: number, prompt?: string): TextFormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter at least ' + length + ' digits';
        break;
      case 'de':
        prompt = 'Bitte geben Sie mindestens an ' + length + ' ziffer';
        break;
      case 'sv':
        prompt = 'Var snäll och ange minst ' + length + ' siffror';
        break;
    }
  }
  return {
    type: 'minLength[' + length + ']',
    prompt: prompt,
  };
};

/** The value must have this length exactly */
export const ExactLength = (length: number, prompt?: string): TextFormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter ' + length + ' figures';
        break;
      case 'de':
        prompt = 'Bitte angeben ' + length + ' figuren';
        break;
      case 'sv':
        prompt = 'Var snäll och ange ' + length + ' siffror';
        break;
    }
  }
  return {
    type: 'exactLength[' + length + ']',
    prompt: prompt,
  };
};

/** The value must be an integer number */
export const IsAnInteger = (prompt?: string): TextFormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter a valid number';
        break;
      case 'de':
        prompt = 'Bitte geben Sie eine gültige Nummer ein';
        break;
      case 'sv':
        prompt = 'Var snäll och ange ett giltigt nummer';
        break;
    }
  }
  return {
    type: 'integer',
    keyboard: 'integer',
    prompt: prompt,
  };
};

/** The value must be an integer within this range */
export const IsAnIntegerBetween = (start: number, stop: number, prompt?: string): TextFormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter a valid number';
        break;
      case 'de':
        prompt = 'Bitte geben Sie eine gültige Nummer ein';
        break;
      case 'sv':
        prompt = 'Var snäll och ange ett giltigt nummer';
        break;
    }
  }
  return {
    type: 'integer[' + start + '..' + stop + ']',
    prompt: prompt,
  };
};

/** The value must be a decimal number */
export const IsADecimal = (allowNegative?: boolean, prompt?: string): TextFormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter a valid number';
        break;
      case 'de':
        prompt = 'Bitte geben Sie eine gültige Nummer ein';
        break;
      case 'sv':
        prompt = 'Var snäll och ange ett giltigt nummer';
        break;
    }
  }
  return {
    type: 'decimal',
    prompt: prompt,
  };
};

/** The value must be a number, either decimal or integer */
export const IsANumber = (allowNegative?: boolean, prompt?: string): TextFormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter a valid number';
        break;
      case 'de':
        prompt = 'Bitte geben Sie eine gültige Nummer ein';
        break;
      case 'sv':
        prompt = 'Var snäll och ange ett giltigt nummer';
        break;
    }
  }
  return {
    type: 'number',
    prompt: prompt,
  };
};

/** The value must match exactly the value of the input with this key */
export const TheSameAs = (key: string, prompt?: string): FormatRule => {
  return {
    type: 'match[' + key + ']',
    prompt: prompt || '',
  };
};

/** The value must be different from the value of the input with this key */
export const NotTheSameAs = (key: string, prompt?: string): FormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Content in the field cannot be the same as the content of another field';
        break;
      case 'de':
        prompt = 'Der Inhalt des Feldes darf nicht mit dem der anderen Felder identisch sein';
        break;
      case 'sv':
        prompt = 'Innehållet i fältet får inte vara samma som i något av de andra fälten';
        break;
    }
  }
  return {
    type: 'different[' + key + ']',
    prompt: prompt,
  };
};

/** Checkbox must be checked */
export const IsChecked = (prompt?: string): FormatRule => {
  return {
    type: 'checked',
    prompt: prompt || '',
  };
};

/** A multiple select field must contain at minimum this many selections */
export const MinimumNumberOfSelections = (count: number, prompt?: string): FormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'You must choose at least ' + count;
        break;
      case 'de':
        prompt = 'Sie müssen mindestens wählen ' + count;
        break;
      case 'sv':
        prompt = 'Du måste välja minst ' + count;
        break;
    }
  }
  return {
    type: 'minCount[' + count + ']',
    prompt: prompt,
  };
};

/** A multiple select field must contain exactly this many selections */
export const ExactNumberOfSelections = (count: number, prompt?: string): FormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'You must choose at least ' + count + ' pcs';
        break;
      case 'de':
        prompt = 'Bitte angeben ' + count + ' st';
        break;
      case 'sv':
        prompt = 'Var snäll och ange ' + count + ' st';
        break;
    }
  }
  return {
    type: 'exactCount[' + count + ']',
    prompt: prompt,
  };
};

/** A multiple select field can't contain more than this many selections */
export const MaximumNumberOfSelections = (count: number, prompt?: string): FormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'You can not choose more than ' + count;
        break;
      case 'de':
        prompt = 'Sie können nicht mehr als' + count + ' wählen';
        break;
      case 'sv':
        prompt = 'Du kan inte välja fler än ' + count;
        break;
    }
  }
  return {
    type: 'maxCount[' + count + ']',
    prompt: prompt,
  };
};

// Custom validation

/** This number keeps tracks of custom rules added to the window */
let _nrOfCustomRules = 0;

/** A custom form validation rule */
export const CustomRule = (
  options: {
    validator: (fieldValue: string) => boolean;
    prompt?: string;
    keyboard?: 'text' | 'tel' | 'url' | 'integer';
  },
  extendsRule?: FormatRule,
): FormatRule => {
  // Create a unique identifier and add it to the browser
  const identifier = (++_nrOfCustomRules).toString();

  // TODO: Fix typings form settings
  // @ts-ignore
  $.fn.form.settings.rules[identifier] = options.validator;

  // Create the rule object and return it
  const rule = {} as FormatRule;

  if (extendsRule) {
    _.assign(rule, extendsRule);
  }

  _.assign(rule, {
    type: identifier,
    prompt: options.prompt,
    keyboard: options.keyboard,
  });

  return rule;
};

/** The value must be a valid email address
 *  Custom regex validation on emails
 *  Regex from: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
 */
export const IsEmailOrEmpty = (prompt?: string): FormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter a correct email address';
        break;
      case 'de':
        prompt = 'Bitte geben Sie eine korrekte E-Mail-Adresse ein';
        break;
      case 'sv':
        prompt = 'Var snäll och ange en korrekt emailadress';
        break;
    }
  }
  return CustomRule({
    validator: (value) => !value || /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value),
    prompt: prompt,
    keyboard: 'text',
  });
};

// TODO: I made this which does not trigger when the value is empty (all our rules should behave like this)
export const IsIntegerOneOrHigherElseEmpty = (prompt?: string): FormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'You must enter an integer greater than 0';
        break;
      case 'de':
        prompt = 'Sie müssen eine Ganzzahl größer als 0 eingeben';
        break;
      case 'sv':
        prompt = 'Du måste ange ett heltal som är större än 0';
        break;
    }
  }
  return CustomRule({
    validator: (value) => !value || /^[1-9][0-9]*$/.test(value),
    prompt: prompt,
    keyboard: 'integer',
  });
};

/** The value may only contain digits or be empty */
export const IsOnlyDigits = (prompt?: string): FormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'You can only enter numbers';
        break;
      case 'de':
        prompt = 'Sie können nur Zahlen eingeben';
        break;
      case 'sv':
        prompt = 'Du får endast ange siffror';
        break;
    }
  }
  return CustomRule({
    validator: (value) => /^[0-9]*$/.test(value),
    prompt: prompt || 'Du får endast ange siffror',
    keyboard: 'integer',
  });
};

/** The value may only contain digits or be empty */
export const IsEmptyOrDecimal = (allowNegative?: boolean, prompt?: string): FormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter a decimal number';
        break;
      case 'de':
        prompt = 'Bitte geben Sie eine Dezimalzahl ein';
        break;
      case 'sv':
        prompt = 'Var snäll och ange ett decimal tal';
        break;
    }
  }
  return CustomRule({
    validator: (value) => !value || /^(0|[1-9]\d*)(\.\d+)?$/.test(value),
    prompt: prompt,
    keyboard: 'integer',
  });
};

/** The value may only contain digits or be empty */
export const IsEmptyOrInteger = (prompt?: string): FormatRule => {
  if (!prompt) {
    switch (globalCurrentLocale()) {
      case 'en':
        prompt = 'Please enter an integer ';
        break;
      case 'de':
        prompt = 'Bitte geben Sie eine ganze Zahl ein ';
        break;
      case 'sv':
        prompt = 'Var snäll och ange ett heltal';
        break;
    }
  }
  return CustomRule({
    validator: (value) => !value || /^[0-9][0-9]*$/.test(value),
    prompt: prompt,
    keyboard: 'integer',
  });
};

/** The value must be a valid date */
export const IsValidDate = (dateFormat: string): FormatRule => {
  let prompt = '';
  switch (globalCurrentLocale()) {
    case 'en':
      prompt = `Please enter a valid date (${dateFormat})`;
      break;
    case 'de':
      prompt = `Bitte geben Sie ein gültiges Datum ein (${dateFormat})`;
      break;
    case 'sv':
      prompt = `Var snäll och ange ett giltigt datum (${dateFormat})`;
      break;
  }
  return CustomRule({
    validator: (value) => !value || Moment(value, dateFormat, true).isValid(),
    prompt: prompt,
  });
};

/** Validates a string as a potential IBAN number */
export const IsIBAN = (): FormatRule => {
  let prompt = '';
  switch (globalCurrentLocale()) {
    case 'en':
      prompt = 'Please enter a valid iban';
      break;
    case 'de':
      prompt = 'Bitte geben Sie ein gültiges iban';
      break;
    case 'sv':
      prompt = 'Var snäll och ange ett giltigt iban';
      break;
  }
  return CustomRule({
    validator: (value) => !value || (/^[A-Z]{2}[0-9A-Z]*$/.test(value) && value.length > 14),
    prompt,
  });
};
