import React from 'react';
import { Checkbox } from '../../../_dependencies';
import { useLocale } from '../../../_locales';

interface Props {
  checked: boolean;
  onToggle: () => void;
  disabled: boolean;
}

export const SetVisitorsFreelyCheckbox = ({ checked, onToggle, disabled }: Props) => {
  const { t } = useLocale();

  return (
    <div style={{ ...spaceBetweenStyle, marginTop: '.5rem' }}>
      <span>{t('Book visitors freely')}</span>
      <Checkbox toggle disabled={disabled} checked={checked && !disabled} onCheckedOrUncheked={onToggle} />
    </div>
  );
};

const spaceBetweenStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
