import * as React from 'react';
import { Observer } from 'mobx-react';
import { MobxComponent } from '../components/legacy';

/**
 * Consume a context. Used as a decorator on
 * a component property to expose the context
 * to the class instance. Due to how the implementation
 * is written, the context is not available until the first
 * render. That means that the context is NOT available
 * in the constructor, but IS available in the componentDidMount
 * lifecycle hook
 *
 * @param context The context to consume
 */
export function Consume<Interface>(context: React.Context<Interface>) {
  return function (prototype: React.Component, key: string) {
    const render = prototype.render;
    const Consumer = context.Consumer;

    // For mobx components we want to wrap rendering inside an obersver component
    if (isMobxComponent(prototype)) {
      prototype.render = function (this: React.Component) {
        return (
          <Consumer>
            {(data) => (
              <Observer>
                {() => {
                  this[key] = data;
                  return render.call(this);
                }}
              </Observer>
            )}
          </Consumer>
        );
      };
    }
    // For all other components we just want to pass the provided data to the correct attribute
    else {
      prototype.render = function (this: React.Component) {
        return (
          <Consumer>
            {(data) => {
              this[key] = data;
              return render.call(this);
            }}
          </Consumer>
        );
      };
    }
  };
}

/**
 * Use is just a semanticly changed version of Consume
 * used when consuming Resources
 *
 * @param context The context to consume
 */
export function Use<Interface>(context: React.Context<Interface>) {
  return Consume<Interface>(context);
}

/** Typeguard for identifying mobx components contra kosmic components in Consume */
function isMobxComponent(prototype: any): prototype is MobxComponent<any> {
  return prototype.isAMobxComponent;
}
