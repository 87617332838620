import Lodash from 'lodash';
import Moment from 'moment-timezone';
import { GiftCardTypeDocument } from '.';
import { TranslatableText } from '../schemas';
import { AddressDocument } from '../schemas/address';
import { CustomerDocument } from '../schemas/customer';
import { BookingPriceCategoryDocument } from '../schemas/priceCategory';
import { tt } from '../utilities/translateText';
import { ActivityOccuranceDocument } from './activityOccurance.resource';
import { ActivityTypeDocument } from './activityType.resource';
import { BookingDocument } from './booking.resource';
import { OrganizationDocument } from './organization.resource';
import { Platforms } from './platforms';
import { PropertyDocument } from './property.resource';
import { UserDocument } from './user.resource';

export type PopulatedBooking = Omit<BookingDocument, 'occurance'> & {
  occurance: Omit<ActivityOccuranceDocument, 'originatingActivity property organization'> & {
    originatingActivity: ActivityTypeDocument;
    property: PropertyDocument;
    organization: OrganizationDocument;
  };
};

export type BookingForMailTemplate = Omit<BookingDocument, 'occurance'> & {
  occurance: Omit<ActivityOccuranceDocument, 'originatingActivity property'> & {
    originatingActivity: ActivityTypeDocument;
    property: PropertyDocument;
    organization: OrganizationDocument;
  };
};

export class MailTemplatesResource {
  private _locale: Locale;
  constructor(locale?: Locale) {
    this._locale = locale || 'en';
  }

  public getExampleProperty = () => {
    switch (this._locale) {
      case 'sv':
        return {
          name: `Tellus`,
          address: `Vintergatan 1, 12345 Universum`,
          priceCategories: `2 st Ordinarie\n1 st Barn\n`,
          price: `799`,
        };
      case 'de':
        return { name: `Name`, address: `Strasse 1, 12345 Ort `, priceCategories: `2 \n \n`, price: `200` };
      default:
      case 'en':
        return {
          name: `Name`,
          address: `Address 1, 12345 City`,
          priceCategories: `2 for ordinary \n \n`,
          price: `200`,
        };
    }
  };

  public getBasepriceCategoryRow = (priceCategory: BookingPriceCategoryDocument) => {
    const { price } = priceCategory;
    switch (this._locale) {
      case 'sv':
        return `1 st Grundpris à ${price} ${Platforms.currency}`;
      case 'de':
        return `1 st Grundpreis auf ${price} ${Platforms.currencyISO}`;
      default:
      case 'en':
        return `1 pcs Base price for ${price} ${Platforms.currencyISO}`;
    }
  };

  public getPriceCategoryRow = (priceCategory: BookingPriceCategoryDocument) => {
    const { name, price, groupMultiplier, tickets } = priceCategory;
    const isGroup = groupMultiplier && groupMultiplier > 1;
    switch (this._locale) {
      case 'sv':
        return `${tickets} st ${tt(name, 'sv')} à ${price / tickets} ${Platforms.currency} ${
          isGroup ? '(gäller för ' + groupMultiplier + ' personer)' : ''
        }`;
      case 'de':
        return `${tickets} st ${tt(name, 'de')} auf ${price / tickets} ${Platforms.currencyISO} ${
          isGroup ? '(gültig für ' + groupMultiplier + ' personen)' : ''
        }`;
      default:
      case 'en':
        return `${tickets} pcs ${tt(name, 'en')} for ${price / tickets} ${Platforms.currencyISO} ${
          isGroup ? '(valid for ' + groupMultiplier + ' people)' : ''
        }`;
    }
  };

  public getActivityInfo(
    eventTitle: string | TranslatableText,
    eventStartDate: Date,
    propertyName: string | TranslatableText,
    address?: AddressDocument,
    notes?: string,
  ): string {
    const addressString = address
      ? `${address.row1 || ''}${address.row2 ? '<br/>' + address.row2 + '<br/>' : ', '}${address.postNr || ''} ${
          address.postOrt || ''
        }`
      : ``;
    const eventStartString = Moment(eventStartDate).tz('Europe/Stockholm').format('dddd Do MMMM YYYY, HH:mm');
    const notesString = notes ? `<br/>${this.getNotes()}:<br/>${notes.replace(/(?:\r\n|\r|\n)/g, '<br />')}<br/>` : ''; // Make sure all newline characters are replaced with html

    switch (this._locale) {
      case 'sv':
        return `Aktivitet: ${tt(eventTitle, this._locale)}<br/>
                Tid: ${eventStartString}<br/>
                Anläggning: ${tt(propertyName, this._locale)}<br/>
                Adress: ${addressString}<br/>
                ${notesString}`;
      case 'de':
        return `Aktivität: ${tt(eventTitle, this._locale)}<br/>
                Datum und Zeit: ${eventStartString}<br/>
                Unternehmen: ${tt(propertyName, this._locale)}<br/>
                Adresse: ${addressString}<br/>
                ${notesString}`;
      default:
      case 'en':
        return `Activity: ${tt(eventTitle, this._locale)}<br/>
                Time: ${eventStartString}<br/>
                Location: ${tt(propertyName, this._locale)}<br/>
                Address: ${addressString}<br/>
                ${notesString}`;
    }
  }

  public getNotes = () => {
    switch (this._locale) {
      case 'sv':
        return `Anteckningar`;
      case 'de':
        return `Notizblock`;
      default:
      case 'en':
        return `Notes`;
    }
  };

  public getOrganizationGiftCardNotificationTemplate(
    giftCard: GiftCardTypeDocument,
    customer: CustomerDocument,
    price: number,
    payedAmount: number,
    customerMessage?: string,
    propertyName?: TranslatableText | string,
    eventStartDate?: Date,
    bookingPriceCategories?: BookingPriceCategoryDocument[],
  ) {
    let priceCategoriesString = '';
    if (bookingPriceCategories) {
      Lodash.each(bookingPriceCategories, (priceCategory) => {
        if (priceCategory.tickets > 0) {
          priceCategoriesString += `${this.getPriceCategoryRow(priceCategory)}<br/>`;
        }
      });
    }

    switch (this._locale) {
      case 'sv':
        return {
          subject: `${customer.fullname} har köpt ett presentkort för ${giftCard.value} ${Platforms.currency}`,
          text:
            `Köpt presentkort: ${tt(giftCard.title, this._locale)}\n` +
            (propertyName ? `Anläggning: ${tt(propertyName, this._locale)}\n` : '') +
            (eventStartDate
              ? `Tid: ${Moment(eventStartDate).tz('Europe/Stockholm').format('dddd Do MMMM YYYY, HH:mm')}\n`
              : '') +
            (priceCategoriesString ? `Antal:\n${priceCategoriesString}` : '') +
            `Pris: ${price} ${Platforms.currency}\n` +
            `Betalt: ${payedAmount} ${Platforms.currency}\n` +
            `\n` +
            `Kunduppgifter:\n` +
            `Namn: ${customer.fullname}\n` +
            `Telefon: ${customer.phoneNr}\n` +
            `Email: ${customer.email}\n` +
            (customer.address ? `Adress: ${customer.address.asString}` : '') +
            `\n` +
            (customerMessage
              ? `Kunden lämnade följande meddelande:\n${customerMessage}\n\n`
              : 'Kunden har inte lämnad något meddelande.'),
        };
      case 'de':
        return {
          subject: `${customer.fullname} habe eine Geschenkkarte für gekauft ${giftCard.value} ${Platforms.currency}`,
          text:
            `Geschenkkarte gekauft: ${tt(giftCard.title, this._locale)}\n` +
            (propertyName ? `Unternehmen: ${tt(propertyName, this._locale)}\n` : '') +
            (eventStartDate
              ? `Datum und Zeit: ${Moment(eventStartDate)
                  .locale('de')
                  .tz('Europe/Stockholm')
                  .format('dddd Do MMMM YYYY, HH:mm')}\n`
              : '') +
            (priceCategoriesString ? `Anzahl:\n${priceCategoriesString}` : '') +
            `Preis: ${price} ${Platforms.currencyISO}\n` +
            `Bezahlt: ${payedAmount} ${Platforms.currencyISO}\n` +
            `\n` +
            `Kundendaten:\n` +
            `Name: ${customer.fullname}\n` +
            `Telefon: ${customer.phoneNr}\n` +
            `Email: ${customer.email}\n` +
            (customer.address ? `Adresse: ${customer.address.asString}` : '') +
            `\n` +
            (customerMessage
              ? `Der Kunde hat die folgende Nachricht hinterlassen:\n${customerMessage}\n\n`
              : 'Kunde hat keine Nachricht gesendet.'),
        };
      default:
      case 'en':
        return {
          subject: `${customer.fullname} have bought a gift card for ${giftCard.value} ${Platforms.currencyISO}`,
          text:
            `Bought gift card: ${tt(giftCard.title, this._locale)}\n` +
            (propertyName ? `Location: ${tt(propertyName, this._locale)}\n` : '') +
            (eventStartDate
              ? `Time: ${Moment(eventStartDate)
                  .locale('en')
                  .tz('Europe/Stockholm')
                  .format('dddd Do MMMM YYYY, HH:mm')}\n`
              : '') +
            (priceCategoriesString ? `Ammount:\n${priceCategoriesString}` : '') +
            `Price: ${price} ${Platforms.currencyISO}\n` +
            `Paid: ${payedAmount} ${Platforms.currencyISO}\n` +
            `\n` +
            `Customer details:\n` +
            `Name: ${customer.fullname}\n` +
            `Phone: ${customer.phoneNr}\n` +
            `Email: ${customer.email}\n` +
            (customer.address ? `Address: ${customer.address.asString}` : '') +
            `\n` +
            (customerMessage
              ? `The customer left the following message:\n${customerMessage}\n\n`
              : 'The customer has not submitted a message...'),
        };
    }
  }

  public getChangedActivityForStaffNotificationTemplate(
    urlOrigin: string,
    originalActivity: ActivityOccuranceDocument,
    updatedActivity: ActivityOccuranceDocument,
    propertyName: TranslatableText | string,
    address?: AddressDocument,
  ) {
    const updatedActivityText = this.getActivityInfo(
      updatedActivity.title,
      updatedActivity.start,
      propertyName,
      address,
      updatedActivity.notes,
    );
    const originalActivityText = this.getActivityInfo(
      originalActivity.title,
      originalActivity.start,
      propertyName,
      address,
      originalActivity.notes,
    );

    switch (this._locale) {
      case 'sv':
        return {
          subject: 'Ändrad arbetstid eller arbetsinfo',
          title: `Uppdaterad information gällade ett av dina arbetspass`,
          buttonUrl: `${urlOrigin}/dashboard/schedule`,
          buttonLabel: 'Visa mitt schema',
          textContent: `
                <b>Updaterad information:</b><br/>${updatedActivityText}
                <br/><br/><b>Tidigare information:</b>
                <br/>${originalActivityText}`,
        };
      case 'de':
        return {
          subject: 'Geänderte Arbeitsstunden oder Arbeitsinformationen',
          title: `Aktualisierte Informationen wurden für eines Ihrer Trainings validiert`,
          buttonUrl: `${urlOrigin}/dashboard/schedule`,
          buttonLabel: 'Zeig meinen Zeitplan',
          textContent: `
                <b>Aktualisieren Sie Informationen:</b><br/>${updatedActivityText}
                <br/><br/><b>Vorherige Information:</b>
                <br/>${originalActivityText}`,
        };
      default:
      case 'en':
        return {
          subject: 'Changed scheduled hours or details',
          title: `Updated information regarding your scheduling`,
          buttonUrl: `${urlOrigin}/dashboard/schedule`,
          buttonLabel: 'Open my schedule',
          textContent: `
                <b>Updated details:</b><br/>${updatedActivityText}
                <br/><br/><b>Previous details:</b>
                <br/>${originalActivityText}`,
        };
    }
  }

  public getWorkInvitationMailTemplate(
    urlOrigin: string,
    employee: UserDocument,
    activity: ActivityOccuranceDocument,
    offer: ActivityTypeDocument,
    property: PropertyDocument,
    organization: string,
  ) {
    const acceptUrl = `${urlOrigin}/work/signup/${activity.id}/${employee.id}/accept`;
    const denyUrl = `${urlOrigin}/work/signup/${activity.id}/${employee.id}/deny`;
    switch (this._locale) {
      case 'sv':
        return {
          subject: `Uppdrag hos ${Platforms.platformName}`,
          title: `Nytt uppdrag hos ${organization}`,
          buttonUrl: acceptUrl,
          buttonLabel: 'Jag kan jobba',
          secondaryButtonUrl: denyUrl,
          secondaryButtonLabel: 'Jag kan inte jobba',
          textContent: `Hej ${employee.firstname}!<br/>
                              Vi vill att du kommer och jobbar hos oss, info om arbetspasset hittar du nedan.<br/>
                              <br/>
                              ${this.getActivityInfo(activity.title, activity.start, property.name, property.address)}
                              <br/>
                              ${offer.messageToEmployees ? offer.messageToEmployees + '<br/><br/>' : ''}
                              Klicka nedan för att ta arbetspasset, det är först till kvarn som gäller så vänta inte för länge.`,
        };
      case 'de':
        return {
          subject: `Stellenangebot bei ${Platforms.platformName}`,
          title: `Neuer Termin bei ${organization}`,
          buttonUrl: acceptUrl,
          buttonLabel: 'Ich möchte arbeiten',
          secondaryButtonUrl: denyUrl,
          secondaryButtonLabel: 'Ich kann nicht arbeiten',
          textContent: `Hallo ${employee.firstname}!<br/>
                              Wir möchten, dass du dir den neuen Termin schnappst. Alle Informationen dazu findest du in dieser E-Mail.<br/>
                              <br/>
                              ${this.getActivityInfo(activity.title, activity.start, property.name, property.address)}
                              <br/>
                              ${offer.messageToEmployees ? offer.messageToEmployees + '<br/><br/>' : ''}
                              Einfach den Button klicken, um den Termin zu übernehmen. Wer zuerst kommt, mahlt zuerst. Also warte nicht zu lang!`,
        };
      default:
      case 'en':
        return {
          subject: `New work opportunity at ${Platforms.platformName}`,
          title: `New appointment at ${organization}`,
          buttonUrl: acceptUrl,
          buttonLabel: 'Sign me up!',
          secondaryButtonUrl: denyUrl,
          secondaryButtonLabel: "I can't work",
          textContent: `Hi ${employee.firstname}!<br/>
                              We want you to catch the newest appointment. You can find all information about the scheduling below.<br/>
                              <br/>
                              ${this.getActivityInfo(activity.title, activity.start, property.name, property.address)}
                              <br/>
                              ${offer.messageToEmployees ? offer.messageToEmployees + '<br/><br/>' : ''}
                              Click below to claim the work shift, first one to cliam it will get it. So do not wait too long.`,
        };
    }
  }

  public getContactMailTemplate(
    urlOrigin: string,
    firstname: string,
    lastname: string,
    email: string,
    subject: string,
    message: string,
  ) {
    switch (this._locale) {
      case 'sv':
        return {
          subject: subject,
          title: `Kontaktformulär:`,
          textContent: `
                    <b>Från:</b> ${firstname} ${lastname}<br/>
                    <b>Email:</b> ${email}<br/>
                    <br/>
                    <b>Ämne:</b> ${subject}<br/>
                    <br/>
                    <b>Meddelande:</b><br/>
                    ${message}
                `,
        };
      case 'de':
        return {
          subject: subject,
          title: `Kontaktformular:`,
          textContent: `
                    <b>Von:</b> ${firstname} ${lastname}<br/>
                    <b>Email:</b> ${email}<br/>
                    <br/>
                    <b>Gegenstand:</b> ${subject}<br/>
                    <br/>
                    <b>Botschaft:</b><br/>
                    ${message}
                `,
        };
      default:
      case 'en':
        return {
          subject: subject,
          title: `Contact form message`,
          textContent: `
                    <b>From:</b> ${firstname} ${lastname}<br/>
                    <b>Email:</b> ${email}<br/>
                    <br/>
                    <b>Subject:</b> ${subject}<br/>
                    <br/>
                    <b>Message:</b><br/>
                    ${message}
                `,
        };
    }
  }

  public getEmployeeInvitationMailTemplate(urlOrigin: string, organization: string) {
    const shiftOrAppointment = 'shift';

    switch (this._locale) {
      case 'sv':
        return {
          subject: `Inbjudan till ${Platforms.platformName}`,
          title: `Inbjudan från ${organization}`,
          text: `Du har blivit inbjuden till ${Platforms.platformName}. Här kommer du bland annat att kunna se och välja vilka tider du ska jobba. Komma igång med ditt nya konto genom att följa länken nedan, superenkelt!<br/>
                        Om du tror att du fått det här meddelandet av misstag kan du ignorera det.`,
          buttonLabel: `Börja använda ${Platforms.platformName}`,
        };
      case 'de':
        return {
          subject: `Einladung an ${Platforms.platformName}`,
          title: `Einladung von ${organization}`,
          text: `Du wurdest zu ${Platforms.platformName} eingeladen. Hier kannst du deinen <br/> Terminkalender einsehen und bearbeiten. Klicke den Button, um <br/>ganz einfach zu deinem Konto zu gelangen.<br/>`,
          buttonLabel: `Beginnen Sie mit ${Platforms.platformName}`,
        };
      default:
      case 'en':
        return {
          subject: `Invitation to ${Platforms.platformName}`,
          title: `Invitation from ${organization}`,
          text: `You have been invited to ${Platforms.platformName}. Here you will be able to see and select what ${shiftOrAppointment}s you are scheduled for. Get started with your new account by following the link below, easy as that!<br/>
                        If you think you received this message by mistake, you can ignore it.`,
          buttonLabel: `Start using ${Platforms.platformName}`,
        };
    }
  }

  public getOrgWelcomeWithPasswordResetMailTemplate(urlOrigin: string, organization: string) {
    switch (this._locale) {
      case 'sv':
        return {
          subject: `Välkommen till ${Platforms.platformName}`,
          title: `Välkommen till ${Platforms.platformName}`,
          text: `Nu finns <i>${organization}</i> i ${Platforms.platformName} tillsammans med ditt nya adminkonto som du kan använda för att komma igång.<br/>
                   Lycka till och skicka gärna feedback och önskemål till oss!`,
          buttonLabel: `Börja använda ${Platforms.platformName}`,
        };
      case 'de':
        return {
          subject: `Willkommen zu ${Platforms.platformName}`,
          title: `Sehr willkommen zu ${Platforms.platformName}`,
          text: `Nun befindet sich <i>${organization}</i> in ${Platforms.platformName} zusammen mit Ihrem neuen Administratorkonto, mit dem Sie beginnen können.<br/>
                    Viel Glück und fühlen Sie sich frei, uns mit einem Feedback zu erreichen!`,
          buttonLabel: `Beginnen Sie zu verwenden ${Platforms.platformName}`,
        };
      default:
      case 'en':
        return {
          subject: `Welcome to ${Platforms.platformName}`,
          title: `Very welcome to ${Platforms.platformName}`,
          text: `Now, <i>${organization}</i> is in ${Platforms.platformName} along with your new administor account that you can use to get started.<br/>
                    Good luck and feel free to reach out to us with any feedback!`,
          buttonLabel: `Start using ${Platforms.platformName}`,
        };
    }
  }

  public getOrgWelcomeWithVerificationCodeMailTemplate(
    urlOrigin: string,
    organization: string,
    verificationCode: string,
  ) {
    switch (this._locale) {
      case 'sv':
        return {
          subject: `Välkommen till ${Platforms.platformName}`,
          title: `Välkommen till ${Platforms.platformName}`,
          textContent: `Nu finns <i>${organization}</i> i ${Platforms.platformName} tillsammans med ditt nya adminkonto som du kan använda för att komma igång.<br/>
                    Du behöver använda koden nedan för att låsa upp alla funktioner i platformen.<br/>
                    <br/>
                    <b>Verifikationskod: ${verificationCode}</b>`,
          buttonLabel: `Börja använda ${Platforms.platformName}`,
          buttonUrl: urlOrigin + '/dashboard',
        };
      case 'de':
        return {
          subject: `Willkommen bei ${Platforms.platformName}`,
          title: `Willkommen bei ${Platforms.platformName}`,
          textContent: `Nun befindet sich <i>${organization}</i> in ${Platforms.platformName} zusammen mit Ihrem neuen Benutzerkonto, mit dem Sie beginnen können.<br/>
                        Sie müssen den folgenden Code verwenden, um alle Funktionen der Plattform zu entsperren.<br/>
                    <br/>
                    <b>Überprüfung: ${verificationCode}</b>`,
          buttonLabel: `Beginnen Sie zu verwenden ${Platforms.platformName}`,
          buttonUrl: urlOrigin + '/dashboard',
        };
      default:
      case 'en':
        return {
          subject: `Welcome to ${Platforms.platformName}`,
          title: `Welcome to ${Platforms.platformName}`,
          textContent: `<i>${organization}</i> is now added to ${Platforms.platformName} along with your new admin account which you can use to get started.<br/>
                    In order to start using your account you need to verify your identity with the code below.<br/>
                    <br/>
                    <b>Verification code: ${verificationCode}</b>`,
          buttonLabel: `Go to ${Platforms.platformName}`,
          buttonUrl: urlOrigin + '/dashboard',
        };
    }
  }

  public getPasswordResetMailTemplate(urlOrigin: string, userId: string, passwordResetToken: string) {
    switch (this._locale) {
      case 'sv':
        return {
          subject: 'Återställ ditt lösenord',
          title: 'Välj ett nytt lösenord',
          text: `Följ länken nedanför för att välja ditt nya lösenord.<br/><br/>
                        Om du inte har begärt att få skapa ett nytt lösenord kan du ignorera det här meddelandet.`,
          buttonLabel: 'välj ditt nya lösenord',
        };
      case 'de':
        return {
          subject: 'Setze dein Passwort zurück',
          title: 'Passwort zurücksetzen',
          text: `Folge dem unten stehenden Link, um ein neues Passwort für dein Konto auszuwählen.<br/><br/>`,
          buttonLabel: 'Passwort zurücksetzen',
        };
      default:
      case 'en':
        return {
          subject: 'Reset your password',
          title: 'Resetting your password',
          text: `Follow the link below to select a new password for your account.<br/><br/>
                        If you have not requested to reset your password, you can ignore this message.`,
          buttonLabel: 'Choose your new password',
        };
    }
  }

  public getAddedStaffNotificationMailTemplate(
    urlOrigin: string,
    activity: ActivityOccuranceDocument,
    organization: string,
    propertyName: TranslatableText | string,
    address?: AddressDocument,
  ) {
    switch (this._locale) {
      case 'sv':
        return {
          subject: 'Ny arbetstid',
          title: `Du har blivit inbokad på ett arbetspass hos ${organization}`,
          textContent: this.getActivityInfo(activity.title, activity.start, propertyName, address, activity.notes),
          buttonUrl: `${urlOrigin}/dashboard`,
          buttonLabel: 'Visa mitt schema',
        };
      case 'de':
        return {
          subject: 'Neue Arbeitszeiten',
          title: `Ein neuer Termin wurde reserviert`,
          textContent: this.getActivityInfo(activity.title, activity.start, propertyName, address, activity.notes),
          buttonUrl: `${urlOrigin}/dashboard`,
          buttonLabel: 'Zeig meine Termine',
        };
      default:
      case 'en':
        return {
          subject: 'New working hours',
          title: `You have been added to new working hours at ${organization}`,
          textContent: this.getActivityInfo(activity.title, activity.start, propertyName, address, activity.notes),
          buttonUrl: `${urlOrigin}/dashboard`,
          buttonLabel: 'Show my schedule',
        };
    }
  }

  public getRemovedStaffNotificationMailTemplate(
    urlOrigin: string,
    activity: ActivityOccuranceDocument,
    organization: string,
    propertyName: TranslatableText | string,
    address?: AddressDocument,
  ) {
    const shiftOrAppointment = 'work shift';

    switch (this._locale) {
      case 'sv':
        return {
          subject: 'Borttagen arbetstid',
          title: `Du har blivit borttagen från ett arbetspass hos ${organization}`,
          buttonUrl: `${urlOrigin}/dashboard`,
          textContent: this.getActivityInfo(activity.title, activity.start, propertyName, address, activity.notes),
          buttonLabel: 'Visa mitt schema',
        };
      case 'de':
        return {
          subject: 'Annullierte Arbeitsstunden',
          title: `Du wurdest aus einer geplanten Schicht in${organization}`,
          buttonUrl: `${urlOrigin}/dashboard`,
          textContent: this.getActivityInfo(activity.title, activity.start, propertyName, address, activity.notes),
          buttonLabel: 'Zeig meinen Zeitplan',
        };
      default:
      case 'en':
        return {
          subject: 'Cancelled working hours',
          title: `You have been removed from a scheduled ${shiftOrAppointment} at ${organization}`,
          buttonUrl: `${urlOrigin}/dashboard`,
          textContent: this.getActivityInfo(activity.title, activity.start, propertyName, address, activity.notes),
          buttonLabel: 'Show my schedule',
        };
    }
  }

  public getStaffAcceptMailTemplate = (
    employee: UserDocument,
    activity: ActivityOccuranceDocument,
    offer: ActivityTypeDocument,
    property: PropertyDocument,
    urlOrigin: string,
  ) => {
    switch (this._locale) {
      case 'sv':
        return {
          subject: 'Accepterad jobbförfrågan',
          title: `Accepterad jobbförfrågan`,
          textContent: `${employee.firstname} ${employee.lastname} har accepterat en jobbförfrågan för: 
                                <br/>
                              ${this.getActivityInfo(
                                activity.title,
                                activity.start,
                                property.name,
                                offer.address || property.address,
                              )}`,
          buttonLabel: 'Visa schema',
          buttonUrl: `${urlOrigin}/dashboard/properties/details/${property.id}/schedule`,
        };
      case 'de':
        return {
          subject: 'Akzeptierte Arbeitsanfrage',
          title: `Akzeptierte Arbeitsanfrage`,
          textContent: `${employee.firstname} ${employee.lastname} nahm eine Arbeitsanfrage an: 
                                <br/>
                              ${this.getActivityInfo(
                                activity.title,
                                activity.start,
                                property.name,
                                offer.address || property.address,
                              )}`,
          buttonLabel: 'Zeitplan anzeigen',
          buttonUrl: `${urlOrigin}/dashboard/properties/details/${property.id}/schedule`,
        };
      default:
      case 'en':
        return {
          subject: `Work invitation accepted`,
          title: `Work invitation accepted`,
          textContent: `${employee.firstname} ${employee.lastname} accepted a work invitation at
                              <br/>
                              ${this.getActivityInfo(
                                activity.title,
                                activity.start,
                                property.name,
                                offer.address || property.address,
                              )}`,
          buttonLabel: 'Show schedule',
          buttonUrl: `${urlOrigin}/dashboard/properties/details/${property.id}/schedule`,
        };
    }
  };
}
