import { LegacyProviderClass } from '../provision/_legacyProvider';

export abstract class Store {
  protected get globals(): LegacyProviderClass {
    if (!LegacyProviderClass.singleton) {
      throw Error('The LegacyProvider singelton was missing when a Store needed it');
    }

    return LegacyProviderClass.singleton as any; // Use the latest constructed provider for accessing global stores, incredibly unsafe on the server
  }
}
