import { cardCreate, giftcardCreate, manualCreate } from './payments.create';
import { rollbackDelete } from './payments.remove';
import { discountDryrun, giftcardDryrun } from './payments.dryrun';
import { giftcardStatus } from './payments.status';
import {
  CreateCardParams,
  CreateManualParams,
  CreatePaymentParams,
  CreatePaymentFn,
  CreateGiftcardParams,
  PaymentStatusFn,
  DryrunPaymentFn,
  StatusParams,
  GiftcardStatusParams,
  DryrunParams,
  GiftcardDryrunParams,
  RollbackPaymentsParams,
  RemoveParams,
  RemovePaymentFn,
  DiscountDryrunParams,
} from './payments.types';
import { Fetcher } from './utilities';

export type PaymentApi = {
  /** Creates a new payment object. */
  create: CreatePaymentFn;
  /** Gets the status of a payment object */
  status: PaymentStatusFn;
  /**
   * Reviews/Dry runs a payment resource on an order, eg the applicable
   * products in an order given a limited giftcard
   */
  dryrun: <T extends keyof DryrunParams = keyof DryrunParams>(
    params: DryrunParams[T][0],
  ) => Promise<DryrunParams[T][1]>;
  /** Deletes a valid payment object. */
  remove: RemovePaymentFn;
};

export const Payment = (fetcher: Fetcher): PaymentApi => {
  const create = async <T extends keyof CreatePaymentParams = keyof CreatePaymentParams>(
    params: CreatePaymentParams[T][0],
  ): Promise<CreatePaymentParams[T][1]> => {
    switch (params.type) {
      case 'card':
        return cardCreate(fetcher)(params as CreateCardParams);
      case 'manual':
        return manualCreate(fetcher)(params as CreateManualParams);
      case 'giftcard':
        return giftcardCreate(fetcher)(params as CreateGiftcardParams);
    }
  };

  const status = async <T extends keyof StatusParams = keyof StatusParams>(
    params: StatusParams[T][0],
  ): Promise<StatusParams[T][1]> => {
    const type = params.type;
    switch (type) {
      case 'giftcard':
        return giftcardStatus(fetcher)(params as GiftcardStatusParams);
      case 'discount':
        throw new Error('Discount status fetcher not implemented');
      default:
        throw new Error(`Status for type: ${type} is not implemented`);
    }
  };

  const dryrun = async <T extends keyof DryrunParams = keyof DryrunParams>(
    params: DryrunParams[T][0],
  ): Promise<DryrunParams[T][1]> => {
    const type = params.type;
    switch (type) {
      case 'giftcard':
        return giftcardDryrun(fetcher)(params as GiftcardDryrunParams);
      case 'discount':
        return discountDryrun(fetcher)(params as DiscountDryrunParams);
      default:
        throw new Error(`Dry run for type: ${type} is not implemented`);
    }
  };

  const remove = async <T extends keyof RemoveParams = keyof RemoveParams>(
    params: RemoveParams[T][0],
  ): Promise<RemoveParams[T][1]> => {
    switch (params.type) {
      case 'rollback':
        return rollbackDelete(fetcher)(params as RollbackPaymentsParams);
      default:
        throw new Error(`Remove payment with type: ${params.type} is not implemented`);
    }
  };

  return {
    create,
    status,
    dryrun,
    remove,
  };
};
