import Fuse from 'fuse.js';
import moment from 'moment-timezone';
import { Types } from 'mongoose';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  ActivityTypeDocument,
  BookingDocument,
  BookingPriceCategoryDocument,
  BookingResource,
  CustomerDocument,
  DeviceProvider,
  GlobalsContext,
  PlaceDocument,
  PlaceResource,
  Table,
  TranslatableText,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { CalendarDropdown } from '../inputs /calendarDropdown';
import { InventoryCheckBoxes } from '../inputs /inventoryCheckBoxes';
import { InventorySearchComponent } from '../inputs /inventorySearchBar';
import { LocationDropdown } from '../inputs /locationDropdown';
import { InventoryLocationSubTable } from './inventoryLocationSubTable';

export type GroupedEntity = {
  id: string;
  title: TranslatableText | string;
  quantity: number;
  entityDetails: SubItem[];
  inventory: Types.ObjectId | undefined;
};

export type SubItem = {
  id: string;
  startPlace: PlaceDocument | undefined;
  endPlace: PlaceDocument | undefined;
  startTime: Date;
  endTime: Date;
  bookingNumber: string;
  bookingNotes: string;
  customer: CustomerDocument;
  quantity: number;
};

export const InventoryLocationList = () => {
  const { t, tt } = useLocale();
  const [outgoing, setOutgoing] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(new Date());
  const [searchQuery, setSearchQuery] = useState<string>();
  const [places, setPlaces] = useState<PlaceDocument[]>();
  const [selectedPlace, setSelectedPlace] = useState<PlaceDocument | null>(null);
  const [bookings, setBookings] = useState<BookingDocument[]>();
  const [bookingsFilteredByPlace, setBookingsFilteredByPlace] = useState<BookingDocument[]>();
  const [expandId, setExpandId] = useState<string>('');
  const globals = useContext(GlobalsContext);
  const [groupedByInventory, setGroupedByInventory] = useState<GroupedEntity[]>([]);
  const device = useContext(DeviceProvider.Context);
  const mobile = device.size === 'mobile';

  useEffect(() => {
    getPlaces();
    getBookings(outgoing);
  }, []);

  useEffect(() => {
    if (selectedPlace && bookingsFilteredByPlace) {
      groupByInventoryType(bookingsFilteredByPlace);
    } else if (bookings) {
      groupByInventoryType(bookings);
    }
  }, [bookings, bookingsFilteredByPlace]);

  useEffect(() => {
    if (bookings) {
      if (selectedPlace) {
        setBookingsFilteredByPlace(sortBookingsByPlace(selectedPlace.id, bookings));
      } else {
        getBookings(outgoing);
      }
    }
  }, [date, outgoing, selectedPlace]);

  const getPlaces = async () => {
    const places = await new PlaceResource().find({ organization: globals.session.currentOrganizationId });
    setPlaces(places);
  };

  const getBookings = async (travelStatus: boolean) => {
    const startDate = moment(date).startOf('day');
    const bookings = await new BookingResource().getBookingsByOccuranceDates(startDate, travelStatus);
    setBookings(bookings);
  };

  const sortBookingsByPlace = (placeId: string, bookings: BookingDocument[]) => {
    return bookings.filter((booking: any) => {
      const route = (booking.occurance as any).route;
      return route[outgoing ? 0 : route.length - 1] == placeId;
    });
  };

  const groupByInventoryType = (incomingBookings: BookingDocument[]) => {
    const getPlaceObject = (placeId: string) => {
      return places?.find((p) => String(p._id) === placeId);
    };

    const buildSubListItem = (
      category: BookingPriceCategoryDocument,
      booking: BookingDocument,
      index: number,
      route,
    ) => {
      const details: SubItem = {
        id: `detail-${booking._id}${index}`,
        startPlace: getPlaceObject(route[0]),
        endPlace: getPlaceObject(route[route.length - 1]),
        startTime: (booking.occurance as any).start,
        endTime: (booking.occurance as any).end,
        bookingNumber: booking.number,
        bookingNotes: booking.notes,
        customer: booking.customer,
        quantity: category.tickets,
      };
      return details;
    };

    if (bookings) {
      const entityGroups: GroupedEntity[] = [];

      for (const booking of incomingBookings) {
        const route = (booking.occurance as unknown as ActivityTypeDocument).route;
        booking.priceCategories.map((category: BookingPriceCategoryDocument, index) => {
          const isAlreadyInGroup = entityGroups.find((group) => String(group.inventory) == String(category.inventory));

          if (!isAlreadyInGroup) {
            const entityGroup: GroupedEntity = {
              id: `group-${booking._id}${index}`,
              title: category.name,
              quantity: category.tickets,
              entityDetails: [buildSubListItem(category, booking, index, route)],
              inventory: category.inventory,
            };

            entityGroups.push(entityGroup);
          } else {
            const addSublist = entityGroups.find((group) => group.inventory == isAlreadyInGroup.inventory);
            if (addSublist) {
              addSublist.entityDetails.push(buildSubListItem(category, booking, index, route));
            }

            isAlreadyInGroup.quantity += category.tickets;
          }
        });
      }
      setGroupedByInventory(entityGroups.filter((group) => group.inventory));
    }
  };

  const fuse = new Fuse(groupedByInventory, {
    threshold: 0.4,
    includeScore: true,
    keys: [
      'title.sv',
      'title.en',
      'title.de',
      'quantity',
      'entityDetails.bookingNumber',
      'entityDetails.startPlace.name.sv',
      'entityDetails.startPlace.name.en',
      'entityDetails.startPlace.name.de',
      'entityDetails.endPlace.name.sv',
      'entityDetails.endPlace.name.en',
      'entityDetails.endPlace.name.de',
      'entityDetails.customer.firstname',
      'entityDetails.customer.lastname',
      'entityDetails.startTime',
      'entityDetails.endTime',
    ],
  });

  const filteredGroupEntities = searchQuery ? fuse.search(searchQuery).map(({ item }) => item) : groupedByInventory;

  const listOptions = () => {
    return (
      <>
        <h2>
          {t('Currently booked inventories for')} {globals.session.currentOrganization.name}
        </h2>
        <InventorySearchComponent
          placeholder={t('Filter by inventory name, location or booking number...')}
          getSearchQuery={(query: string) => setSearchQuery(query)}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1.25em 0em 1.25em 0em',
          }}
        >
          <div style={listOptionsContainerStyle(mobile)}>
            <div style={mobile ? { display: 'flex', paddingBottom: '1em' } : { display: 'flex', flexDirection: 'row' }}>
              <CalendarDropdown mobile={mobile} getSelectedDate={(date: Date) => setDate(date)} />
              <LocationDropdown
                resetOption
                placeHolder={t('Location')}
                locations={places}
                setPlace={(place: PlaceDocument) => setSelectedPlace(place)}
              />
            </div>
            <InventoryCheckBoxes
              checkedValues={(incomingChecked: boolean) => {
                incomingChecked ? setOutgoing(false) : setOutgoing(true);
                setBookings([]);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div style={{ padding: '2em' }}>
      {listOptions()}
      <div style={{ margin: mobile ? '-0em -2em -0em -2em' : '' }}>
        {!bookings ? (
          <div className="ui active centered inline big text loader">{t('Loading')}</div>
        ) : (
          <Table
            className={'ui unstackable table'}
            minWidth={'10rem'}
            dataList={filteredGroupEntities}
            headers={[
              { title: t('Inventory'), sortBy: 'title' },
              { title: t('Quantity'), sortBy: 'quantity' },
              { title: t('Bookings') },
            ]}
            renderRow={(item: GroupedEntity) => {
              return item.id == expandId ? (
                <Fragment key={`fragment-${item.id}`}>
                  <InventoryLocationSubTable mobile={mobile} item={item} outgoing={outgoing} />
                </Fragment>
              ) : (
                <tr key={item.id} onClick={() => setExpandId(item.id)}>
                  <td>{tt(item.title)}</td>
                  <td>{item.quantity}</td>
                  <td>{item.entityDetails.length}</td>
                </tr>
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

const listOptionsContainerStyle = (mobile: boolean) => {
  const style: React.CSSProperties = {
    display: 'flex',
    justifyContent: mobile ? 'space-between' : 'space-evenly',
    alignItems: 'center',
    flexWrap: mobile ? 'wrap' : 'nowrap',
  };
  return style;
};
