import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AutomaticMailDocument,
  CustomerDocument,
  DeviceProvider,
  Field,
  Form,
  Input,
  PropertyDocument,
  Rules,
  TranslatableText,
  TranslatableTextField,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { OffersContext } from '../../../../contexts/offers.context';
import { Iframe } from '../../../iFrame';

const BASE_TRANSLATIONS = {
  sv: '',
  en: '',
  de: '',
};

const BASE_OPTIONS = {
  message: BASE_TRANSLATIONS,
  timeModifier: 0,
  shouldBeSent: false,
};

const WelcomeMessageForm = () => {
  const [updatingMessage, setUpdatingMessage] = useState(false);
  const [updatingModifier, setUpdatingModifier] = useState(false);
  const { selectedOffer, updateSelectedOffer, saveNewWelcomeMailMessage } = useContext(OffersContext);
  const messageTimer = useRef<NodeJS.Timeout | null>(null);
  const modifierTimer = useRef<NodeJS.Timeout | null>(null);
  const [time, setTime] = useState<number>(
    selectedOffer?.welcomeMail?.timeModifier ? selectedOffer.welcomeMail?.timeModifier * -1 : 0,
  );
  const [mailPreview, setMailPreview] = useState<{ subject: string; html: string }>({ subject: '', html: '' });
  const [locale, setLocale] = useState<Locale>('sv');
  const { t, tt } = useLocale();
  const device = useContext(DeviceProvider.Context);

  useEffect(() => {
    if (updatingMessage) return;
    (async () => {
      const customer = { firstname: 'Tindra', locale } as CustomerDocument;
      const number = 'abc123';

      const occurance = {
        property: { name: '[Din anläggning]', email: 'email@email.com', phoneNo: '123-123123123' } as PropertyDocument,
        originatingActivity: selectedOffer,
        start: moment(),
        end: moment().add(2, 'hours'),
      };

      try {
        const response = await fetch('/api/v1/mail/render', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            type: 'welcome',
            props: {
              booking: {
                number,
                customer,
                occurance,
              },
              strictLocale: true,
            },
          }),
        });
        const result = (await response.json()) as { subject: string; html: string };
        setMailPreview(result);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [updatingMessage, locale]);

  // Clear timer if demount
  useEffect(() => {
    return () => {
      if (messageTimer.current) {
        clearTimeout(messageTimer.current);
      }
      if (modifierTimer.current) {
        clearTimeout(modifierTimer.current);
      }
    };
  }, []);

  if (!selectedOffer) return null;

  const updateMessage = (message: TranslatableText) => {
    const base = selectedOffer.welcomeMail || BASE_OPTIONS;
    const options = { ...base, message: message };
    updateSelectedOfferInContext(options);

    if (messageTimer.current) {
      clearTimeout(messageTimer.current);
      messageTimer.current = null;
    }
    messageTimer.current = setTimeout(async () => {
      setUpdatingMessage(true);
      if (options) {
        await updateOfferInDatabase(options);
      }
      setUpdatingMessage(false);
    }, 1000);
  };

  const updateShouldBeSent = async () => {
    const base = selectedOffer.welcomeMail || BASE_OPTIONS;
    const options = { ...base, shouldBeSent: !base.shouldBeSent };
    updateSelectedOfferInContext(options);
    await updateOfferInDatabase(options);
  };

  const updateTimeModifier = async (value: string) => {
    // Make sure input is only digits, and is not greater than 168. return if not
    const onlyDigits = /^[0-9]*$/;
    if (!onlyDigits.test(value) || Number(value) > 168) {
      setTime(Number(value));
      if (modifierTimer.current) {
        clearTimeout(modifierTimer.current);
      }
      return;
    }
    setTime(Number(value));
    // Continue with updates
    const hours = parseInt(value) || 0;
    const base = selectedOffer.welcomeMail || BASE_OPTIONS;
    const options = { ...base, timeModifier: hours * -1 };
    updateSelectedOfferInContext(options);

    if (modifierTimer.current) {
      clearTimeout(modifierTimer.current);
      modifierTimer.current = null;
    }

    modifierTimer.current = setTimeout(async () => {
      setUpdatingModifier(true);
      if (options) {
        await updateOfferInDatabase(options);
      }
      setUpdatingModifier(false);
    }, 1000);
  };

  const updateSelectedOfferInContext = (options: AutomaticMailDocument) => {
    updateSelectedOffer('welcomeMail', options);
  };

  const updateOfferInDatabase = async (options: AutomaticMailDocument) => {
    return saveNewWelcomeMailMessage(options);
  };

  const timeFormatter = selectedOffer.welcomeMail?.timeModifier === -1 ? 'hour' : 'hours';

  const renderCheckbox = () => {
    return (
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
        <div style={{ float: 'right', marginRight: '3px' }} className="ui toggle checkbox">
          <input
            type="checkbox"
            checked={selectedOffer.welcomeMail?.shouldBeSent || false}
            name="public"
            onChange={updateShouldBeSent}
          />
          <label>{t('Should be sent')}</label>
        </div>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <div style={{ maxWidth: '10rem' }}>
        <Input
          disabled={!selectedOffer.welcomeMail?.shouldBeSent}
          name="Tid i timmar"
          icon="hourglass full"
          value={String(time ? time : '')}
          rules={[Rules.IsAnInteger(), Rules.IsAnIntegerBetween(0, 168, t('Hours can only be between 0 and 168'))]}
          placeholder={t('Time in hours')}
          type="integer"
          loading={updatingModifier}
          onChange={updateTimeModifier}
        />
      </div>
    );
  };

  const renderTimeDescription = () => {
    if (!selectedOffer.welcomeMail?.shouldBeSent) return '';
    if (time === 0) {
      return t('Mail is sent when the booking is starting');
    } else if (time > 0 && time <= 168) {
      return `${t('The mail is sent')} ${time} ${t(timeFormatter)} ${t('before start time')}`;
    } else if (time > 168) {
      return <div style={{ color: 'red' }}>{t('Hours can only be between 0 and 168')}</div>;
    }
  };

  const settingsDesktopLayout = () => {
    return (
      <div className="ui message" style={{ margin: '0 0 1em 0', display: 'flex' }}>
        <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
          {renderInput()}
          <span style={{ padding: '0 0 0 1em' }}>{renderTimeDescription()}</span>
        </div>
        <div style={{ display: 'flex', flexGrow: 1, margin: '1em 0' }}>{renderCheckbox()}</div>
      </div>
    );
  };

  const settingsMobileLayout = () => {
    return (
      <>
        <div style={{ display: 'flex', flexGrow: 1 }}>
          {renderInput()}
          {renderCheckbox()}
        </div>
        <p style={{ marginTop: '1em' }}>{renderTimeDescription()}</p>
      </>
    );
  };

  return (
    <Form>
      <Field>
        {device.size === 'mobile' ? settingsMobileLayout() : settingsDesktopLayout()}
        <p>{t('Edit your greetings to the customer')}</p>
        <div className={'ui basic ' + (updatingMessage ? 'loading' : '') + ' segment'} style={{ padding: 0 }}>
          {selectedOffer.welcomeMail?.message && (
            <TranslatableTextField
              textarea
              name="Hälsning till kunden"
              value={selectedOffer.welcomeMail.message}
              onChange={updateMessage}
              onLocaleChange={setLocale}
              placeholder={t('We´re looking forward to your visit.')}
            />
          )}
        </div>
      </Field>
      <Field>
        <p>{t('Preview of the welcome email')}</p>
        <div className="ui raised segment" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          <p>
            <b>
              {t('Subject')}: {mailPreview.subject}
            </b>
            <b style={{ float: 'right' }}>{t('From')} Adventure Hero</b>
          </p>
          <div className="ui fluid divider" />
          <Iframe content={mailPreview.html} />
        </div>
      </Field>
    </Form>
  );
};

export default WelcomeMessageForm;
