import * as React from 'react';
import { Consume, LocaleContext, MobxComponent } from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';

export class PulSegment extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  public render() {
    const { t } = this._locale;
    return (
      <div className="ui green segment">
        <h3 className="ui aligned header">
          {t('components.dashboard.home.segments.pulsegment.headerPluSegment')}
          <div className="sub header">{t('For personal data assistant agreements click here')}</div>
        </h3>
        <div
          className="ui content"
          style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'flex-start' }}
        >
          <a
            className="ui fluid tiny green button active"
            href="https://www.hiveandfive.se/policies/pulbitrade"
            target="_blank"
            style={{ padding: '10px' }}
            rel="noreferrer"
          >
            {t('The PUL Assistant Agreement')}
            <span></span>
          </a>
        </div>
      </div>
    );
  }
}
