import React, { useEffect, useState } from 'react';
import { Field, Input, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface IDurationFieldProps {
  initialDuration: number;
  setOfferDuration: (key: 'duration', value: number) => void;
}

// Initial component value for duration to avoid undefined in state
const DURATION_BEFORE_LOAD = {
  hours: 0,
  minutes: 0,
};

type TDuration = typeof DURATION_BEFORE_LOAD;

export const DurationFields = ({ initialDuration, setOfferDuration }: IDurationFieldProps) => {
  // Save away the duration in a format that is easy to control
  const [duration, setDuration] = useState<TDuration>(DURATION_BEFORE_LOAD);
  const { t } = useLocale();

  const updateDuration = (scope: 'hours' | 'minutes', value: number) => {
    const val = scope === 'minutes' && value > 59 ? 59 : value;
    setDuration((prev) => ({
      ...prev,
      [scope]: val || 0,
    }));
  };

  // Set the duration state to values based on the initial duration ONLY ON MOUNT
  // In order to setup changes from user
  useEffect(() => {
    const hours = Math.floor(initialDuration / 60);
    const minutes = initialDuration % 60;
    setDuration({ hours, minutes });
  }, []);

  // Whenever the duration is changed, except if the duration is the intital component value,
  // Update the selectedOffer for easy update on save
  useEffect(() => {
    if (duration === DURATION_BEFORE_LOAD) return;
    const newDuration = duration.hours * 60 + duration.minutes;
    setOfferDuration('duration', newDuration);
  }, [duration]);

  return (
    <div className="ui grid" style={{ marginTop: '1em', marginBottom: '1em' }}>
      <Field label={t('Time in hours')} width={8} style={{ padding: '0 1em', marginBottom: '0' }}>
        <Input
          name="Tid i timmar"
          icon="hourglass full"
          value={String(duration.hours)}
          onChange={(value: string) => updateDuration('hours', parseInt(value))}
          rules={[Rules.IsAnInteger()]}
          placeholder={t('Enter time in hours')}
        />
      </Field>
      <Field label={t('And minutes')} width={8} style={{ padding: '0 1em' }}>
        <Input
          name="Tid i minuter"
          icon="hourglass half"
          value={String(duration.minutes)}
          onChange={(value) => updateDuration('minutes', parseInt(value))}
          rules={[
            Rules.IsAnIntegerBetween(0, 59, t('You can input a number between 0 and 59')),
            Rules.MaximumLength(2),
          ]}
          placeholder={t('and minutes')}
        />
      </Field>
    </div>
  );
};
