import React from 'react';
import { BookingDocument } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { GetEventDetailsFor } from '../events';
import { FormattedEventData } from './handleBookingHistoryDataHelpers';

interface Props {
  history: FormattedEventData | undefined;
}

export const BookingHistory = ({ history }: Props) => {
  const { t } = useLocale();
  if (!history) return <div className={'ui active centered inline loader'} />;

  return (
    <div className="container" style={containerStyle}>
      {Object.keys(history).map((date, index) => (
        <div className="ui list" key={`${date}-${index}`} style={{ margin: '11px' }}>
          <div className="item" style={{ padding: '0px' }}>
            <div className="content">
              <i className="calendar outline icon" />
              <span>{date}</span>
            </div>
          </div>
          <div className="list">
            {history[date].map((event) => {
              const details = GetEventDetailsFor(t, event);
              return (
                <div
                  className="item"
                  key={`${date}-${event.time}-${event.key}`}
                  style={itemStyle(!!details.description)}
                >
                  <i className={`large middle aligned centered ${details.icon}`} />
                  <div className="content">
                    <p style={{ margin: 0 }}>
                      <strong>{details.title}</strong>
                      {' - '}
                      {event.time}
                    </p>
                    <div className="description">{details.description}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  maxHeight: '580px',
  overflowY: 'auto',
  fontSize: '.85em',
};

const itemStyle = (conditional: boolean): React.CSSProperties => ({
  visibility: 'visible',
  height: 'auto',
  borderRadius: '4px',
  backgroundColor: conditional ? '#FFFFFF' : 'none',
  margin: '3px',
  padding: '4px 4px 4px 8px',
});
