import React, { useEffect, useState } from 'react';
import { Checkbox } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';

interface Props {
  checkedValues: (incoming: boolean, outgoing: boolean) => void;
}

export const InventoryCheckBoxes = ({ checkedValues }: Props) => {
  const { t } = useLocale();
  const [incoming, setIncomingChecked] = useState<boolean>(false);
  const [outgoing, setOutgoingChecked] = useState<boolean>(true);

  useEffect(() => {
    checkedValues(incoming, outgoing);
  }, [incoming, outgoing]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        largeLabel
        checked={outgoing}
        style={{ padding: '0.5rem' }}
        onCheckedOrUncheked={() => {
          if (outgoing) {
            setOutgoingChecked(false);
            setIncomingChecked(true);
          } else {
            setOutgoingChecked(true);
            setIncomingChecked(false);
          }
        }}
      >
        {t('Outgoing')}
      </Checkbox>
      <Checkbox
        largeLabel
        checked={incoming}
        style={{ padding: '0.5rem' }}
        onCheckedOrUncheked={() => {
          if (incoming) {
            setIncomingChecked(false);
            setOutgoingChecked(true);
          } else {
            setIncomingChecked(true);
            setOutgoingChecked(false);
          }
        }}
      >
        {t('Incoming')}
      </Checkbox>
    </div>
  );
};
