import React from 'react';
import { ActivityOccurancesGeneratorForm } from './activityOccurancesGeneratorForm';
import { Accordion, ActivityTypeDocument, Consume, LocaleContext, MobxComponent } from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { checkAccountActivation } from '../../../activationHelper';

interface Props {
  /** The selected activity type */
  offer: ActivityTypeDocument;
  /** This is passed forward to the ActivityOccurancesGeneratorForm */
  periodGeneratingAction: ActivityOccurancesGeneratorForm['props']['onSubmit'];
}

class OfferSchedulingSection extends MobxComponent<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private renderHeader() {
    const { t } = this._locale;

    return (
      <Accordion
        fluid
        title={
          <span>
            <h3 className="ui header">
              {t('Schedule starting times')}
              <button style={{ float: 'right' }} className="ui compact tiny primary button">
                <i className="dropdown icon" /> {t('offers.details.addManualStarttimes')}
              </button>
              <div className="sub header" style={{ marginTop: '10px' }}>
                {t('offers.details.addMultiScheduleTimes')}
              </div>
            </h3>
          </span>
        }
      >
        <ActivityOccurancesGeneratorForm
          isTmeTicket={this.props.offer.isTmeTicket}
          onSubmit={this.props.periodGeneratingAction}
        />
      </Accordion>
    );
  }

  render() {
    const { t } = this._locale;
    // This component requires that the current organizations account is activated
    if (checkAccountActivation(this.globals.session.currentOrganization) == false) {
      return (
        <div className="ui icon warning message">
          <i className="exclamation circle icon"></i>
          <div className="content">
            <div className="header">{t('offers.details.notActivatedTitle')}</div>
            <p>
              {t('However, before you can create activities...')}
              <br />
              <br />
              <button
                className="ui orange right aligned button"
                onClick={() => this.globals.session.navigateTo('/dashboard')}
              >
                {t('Activate my account')}
              </button>
            </p>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.renderHeader()}
        <div className="ui basic segment">{this.props.children}</div>
      </React.Fragment>
    );
  }
}

export default OfferSchedulingSection;
