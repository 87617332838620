import { action, computed, observable, runInAction } from 'mobx';
import * as ShortId from 'shortid';
import {
  AgreementDocument,
  AgreementResource,
  Alert,
  OrganizationDocument,
  OrganizationResource,
  Platforms,
  UserDocument,
  UserResource,
} from '../../_dependencies';

/* TODO: Untranslated, Träffpunkt only uses swedish  */
export class AdminStoreSingleton {
  private _organizationResource = new OrganizationResource();
  private _agreementResource = new AgreementResource();
  private _userResource = new UserResource();

  @observable private _organizationDocument: OrganizationDocument;
  @observable private _agreementDocument: AgreementDocument;
  @observable private _userDocument: UserDocument;
  @observable public sendPasswordResetEmail: boolean;
  @observable public isInitStoreNeeded: boolean;

  @observable private _organizationsForPicker: OrganizationDocument[];
  @observable private _loadingOrganizations = true;
  public selectedOrganization: OrganizationDocument;

  constructor() {
    if (MODULE_ENVIRONMENT != 'browser') {
      return;
    } // TODO: workaround to not init on server side
    this.initStore();
  }

  @computed public get loadingOrganizations() {
    return this._loadingOrganizations;
  }

  @computed public get organizationsForPicker() {
    return this._organizationsForPicker;
  }

  @action public loadOrganizationsForPicker() {
    this._loadingOrganizations = true;

    new OrganizationResource()
      .find({})
      .then((organizations: OrganizationDocument[]) => {
        runInAction(() => {
          this._organizationsForPicker = organizations;
          this._loadingOrganizations = false;
        });
      })
      .catch((err) => {
        console.error(err);
        Alert.show('Kunde inte hämta organisationer', 'Oj då..', 'error');
      });
  }

  @computed public get organizationDocument() {
    return this._organizationDocument;
  }

  @computed public get agreementDocument() {
    return this._agreementDocument;
  }

  @computed public get userDocument() {
    return this._userDocument;
  }

  @action public initStore = () => {
    this._agreementDocument = this._agreementResource.createDocument({
      percentageCharge: 4,
      nonEuropeanPercentageCharge: 6,
      belowLimitCharge: 4,
      limit: 100,
    });
    this._userDocument = this._userResource.createDocument({});

    // Add extra features to accounts that can be self-activated
    if (Platforms.features.accountsCanBeActivatedFromDashboard) {
      this._organizationDocument = this._organizationResource.createDocument({
        admins: [this.userDocument._id],
        verificationCode: ShortId.generate(),
        isVerified: false,
        isActivated: false,
      });
    } else {
      this._organizationDocument = this._organizationResource.createDocument({
        agreement: this.agreementDocument._id,
        admins: [this.userDocument._id],
      });
    }

    this.sendPasswordResetEmail = true;
    this.isInitStoreNeeded = false;
  };

  @action private clearStore = () => {
    this._agreementDocument = undefined as any;
    this._userDocument = undefined as any;
    this._organizationDocument = undefined as any;
    this.isInitStoreNeeded = true;
  };

  public createNewOrganization(resolve, reject, userPassword: string) {
    const a = this._userResource
      .find({ username: this._userDocument.username })
      .then((results) => {
        // If user already exits then reject
        if (results && results.length) {
          // TODO: If the user exists within an other organization a new role for this organization should be added.
          return Promise.reject(new Error('user-exists'));
        }

        // Use the correct server side call for accounts that can be self activated
        if (Platforms.features.accountsCanBeActivatedFromDashboard) {
          return this._organizationResource
            .createNewAccount(this._organizationDocument, this._userDocument, userPassword, this.sendPasswordResetEmail)
            .then((isUserCreated) => {
              if (!isUserCreated) {
                throw new Error();
              }
              return true;
            });
        } else {
          throw new Error('No longer implemented');
        }
      })
      .then(() => {
        Alert.show('Organisationen har lagts till i systemt. 👊', 'Toppen!', 'success');
        this.clearStore();
        resolve();
      })
      .catch((err: Error) => {
        if (err.message == 'user-exists') {
          Alert.show(
            'Det finns redan en användare med den angivna mailadressen i systemet.',
            'Användaren finns redan 😲',
            'error',
          );
        } else {
          Alert.show('Det gick inte att skapa organisationen.', 'Fel', 'error');
        }
        reject(err);
      });
  }

  private static _instance: AdminStoreSingleton;
  public static get Instance() {
    if (!this._instance) {
      this._instance = new this();
    }
    return this._instance;
  }
}
export const AdminStore = new AdminStoreSingleton();
