import { Schema, Types } from 'mongoose';
import SchemaNames from './names';

export type UserRoleType =
  | 'system.owner'
  | 'organization.manager'
  | 'property.manager'
  | 'property.crew'
  | 'customer'
  | 'organization.employee'
  | 'organization.volunteer'
  | 'volunteer.receiver';

export interface UserRoleDocument {
  type: UserRoleType;
  organization?: Types.ObjectId;
  property?: Types.ObjectId;
  originalOrganization?: Types.ObjectId;
}

export var UserRoleSchema = new Schema(
  {
    type: { type: String, required: true },
    organization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: false },
    property: { type: Schema.Types.ObjectId, ref: SchemaNames.Property, required: false },
    originalOrganization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: false },
  },
  { _id: false },
);
