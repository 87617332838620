import React, { useContext } from 'react';
import { CSSProperties } from 'react';
import { useLocale } from '../../_locales';
import { DeviceProvider } from '../../_dependencies';

function OurPaymentsInfoSection() {
  const { t } = useLocale();
  const device = useContext(DeviceProvider.Context);

  return (
    <div className="ui container" style={containerStyle}>
      <div className="ui stackable two column grid middle aligned">
        <div className="column">
          <img
            className="ui centered medium image"
            style={imgStyle}
            src="/static/platform/img/stripe.png"
            alt="Stripe logo"
          />
        </div>
        <div className="column" style={{ padding: device.size === 'mobile' ? '3rem' : 0 }}>
          <h2
            className="ui green header"
            style={{
              fontSize: '2rem',
              padding: device.size === 'mobile' ? '0 3rem' : 0,
              textAlign: device.size === 'mobile' ? 'center' : 'left',
            }}
          >
            {t('Secure payments with Stripe')}
          </h2>
          <p
            style={{
              padding: device.size === 'mobile' ? '0 3rem' : 0,
              textAlign: device.size === 'mobile' ? 'center' : 'left',
            }}
          >
            {t('Payments for your products...')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurPaymentsInfoSection;

const containerStyle: CSSProperties = {
  margin: '2rem 0',
  padding: '2rem 0 10rem 0',
};

const imgStyle: CSSProperties = {
  borderRadius: '5rem',
};
