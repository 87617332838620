import Moment from 'moment';

type TimeSeries = TimeSerie[];

type TimeSerie = {
  validTime: Date;
  parameters: Parameters[];
};

type Parameters = {
  name: string;
  levelType: string;
  level: number;
  unit: string;
  values: number[];
};

/**
 * SHMHIWeatherConverter function converts a smhi forecast
 * response into more human readable data to be used in
 * the Bumblebook application stack.
 */
export const SHMHIWeatherConverter = (timeSeries: TimeSeries) => (target: Date) => {
  // Try to find a valid time for the target date.
  if (Moment(target).hour() == 0) {
    target = Moment(target).set('hours', 12).toDate();
  }
  let min = Moment(target).startOf('day');
  const ts = timeSeries.find((x) => {
    const isBetween = Moment(target).isBetween(min, Moment(x.validTime), 'hours', '[]');
    min = Moment(x.validTime);
    return isBetween;
  });

  // If no valid timeseries object was found,
  // return null
  if (ts == null) return null;

  // Find and return the temperature objects value
  // as well as the weathersymbol.
  const p = ts.parameters.find((x) => x.name == 't') as Parameters;
  const w = ts.parameters.find((x) => x.name == 'Wsymb2') as Parameters;
  return { temperature: p.values[0], symbol: w.values[0], validTime: ts.validTime };
};
