import { merge } from 'lodash';
import * as React from 'react';
import type Stripe from 'stripe';
import { getAllRequirements } from '..';
import {
  Alert,
  Checkbox,
  Consume,
  Field,
  Form,
  KosmicComponent,
  LocaleContext,
  OrganizationResource,
  SubmitButton,
  UserDocument,
} from '../../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../../_locales';
import { PrivacyInformationPopup } from '../privacyInformationPopup';
import { stripeErrorToComponent } from '../stripeErrorToComponent';
import { AddressFields } from './addressFields';
import { IdentificationFields } from './identificationFields';
import { PersonFields } from './personFields';
import { RelationshipFields } from './relationshipsFields';
import { RepresentativeFields } from './representativeFields';

export type PersonParams = (Stripe.PersonCreateParams | Stripe.PersonUpdateParams) & {
  /** Used to update a stripe person */ id?: string;
  /** Used to update a stripe person */ prevVerification?: Stripe.Person.Verification;
};

interface Props {
  nextStep: () => void;
  account: Stripe.Account;
}
interface State {
  persons: Array<PersonParams>;
  currentIndex: number;
  ownersDirectorsAndExecutivesProvided: boolean;
  loaded: boolean;
  relationshipsError?: string;
  documentsError?: string;
  errorsFromStripe?: JSX.Element[];
}

// TODO Field rules
export class PersonsForm extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;
  private form: Form;

  constructor(props: Props) {
    super(props);
    this.state = {
      loaded: false,
      persons: [],
      currentIndex: 0,
      ownersDirectorsAndExecutivesProvided: false,
    };
  }

  private static TabItem(props: { children: React.ReactNode; active?: boolean; onClick?: () => void }) {
    const active = props.active ? 'active' : '';
    const style: React.CSSProperties = { cursor: 'pointer' };
    return (
      <div style={style} className={`${active} item`} onClick={props.onClick}>
        {props.children}
      </div>
    );
  }

  private get hasErrors(): boolean {
    const { relationshipsError, documentsError, errorsFromStripe } = this.state;
    return (
      Boolean(relationshipsError) || Boolean(documentsError) || Boolean(errorsFromStripe && errorsFromStripe.length)
    );
  }

  private get needsDocument(): boolean {
    return getAllRequirements(this.props.account)
      .filter((x) => /person_|relationship/.test(x))
      .some((x) => /verification\.document/.test(x));
  }

  private constructPersonParams(person?: Stripe.Person, isFirst?: boolean): PersonParams {
    const { firstname, lastname, username, phoneNumber } = this.globals.session.currentUser as UserDocument;
    return {
      id: person?.id,
      first_name: person?.first_name || (isFirst ? firstname : undefined),
      last_name: person?.last_name || (isFirst ? lastname : undefined),
      email: person?.email || (isFirst ? username : undefined),
      phone: person?.phone || (isFirst ? phoneNumber : undefined),
      dob: {
        year: person?.dob?.year || NaN,
        month: person?.dob?.month || NaN,
        day: person?.dob?.day || NaN,
      },
      relationship: {
        title: person?.relationship?.title || undefined,
        representative: person?.relationship?.representative || isFirst,
        executive: person?.relationship?.executive || undefined,
        owner: person?.relationship?.owner || undefined,
        director: person?.relationship?.director || undefined,
      },
      address: {
        line1: person?.address?.line1 || undefined,
        city: person?.address?.city || undefined,
        country: person?.address?.country || undefined,
        postal_code: person?.address?.postal_code || undefined,
      },
      prevVerification: person?.verification,
    };
  }

  get representativeHasBeenAssigned() {
    return this.state.persons.findIndex((x) => x.relationship?.representative) != -1;
  }

  async componentDidMount() {
    const resource = new OrganizationResource();
    const persons = await resource.getStripePersonObjects();
    const newState = { loaded: true } as State;

    const mapper = (err) => {
      const { requirement } = err;
      if (!/person_|relationship/.test(requirement)) {
        return null;
      }
      return stripeErrorToComponent(err);
    };

    if (persons.length) {
      newState.persons = persons.map((p) => this.constructPersonParams(p));
      if (this.props.account.requirements && this.props.account.requirements.errors) {
        newState.errorsFromStripe = this.props.account.requirements.errors.map(mapper).filter(Boolean) as JSX.Element[];
      }
      // Make sure that only relationship requirements are handled here.
    } else {
      newState.persons = [this.constructPersonParams(undefined, true)];
    }
    this.setState(newState);
  }

  componentDidUpdate(_: Props, prevState: State) {
    if (this.state.loaded && !prevState.loaded) {
      const helpers = ['#identification-helper', '#roles-helper', '#additional-document-helper'];
      for (const id of helpers) {
        const element = this.domElement.find(id);
        element && element.popup();
      }
    }
  }

  async savePerson(person: PersonParams) {
    const resource = new OrganizationResource();
    const { id, prevVerification, ...updateParams } = person;
    if (id) {
      await resource.updatePerson(id, updateParams);
    } else {
      await resource.createPerson(updateParams);
    }
  }

  async saveCompanyInformation() {
    await new OrganizationResource().updateAccount({
      company: {
        owners_provided: true,
        directors_provided: true,
        executives_provided: true,
      },
    });
  }

  validateRelationships = (onlyRemoveMessages = false): boolean => {
    const { persons } = this.state;
    const { t } = this._locale;
    // Atleast one owner
    if (persons.find((x) => x.relationship!.owner) == null) {
      !onlyRemoveMessages && this.setState({ relationshipsError: t('Please register an owner and try again') });
      return false;
    }
    // A representative is needed
    const representative = this.state.persons.find((x) => x.relationship?.representative);
    if (representative == null) {
      !onlyRemoveMessages && this.setState({ relationshipsError: t('Please register a representative and try again') });
      return false;
    }
    // Representative also has to be an owner or executive
    if (representative && !representative.relationship?.executive && !representative.relationship?.owner) {
      !onlyRemoveMessages && this.setState({ relationshipsError: t('The representative also has to be...') });
      return false;
    }

    this.setState({ relationshipsError: undefined });
    return true;
  };

  /** Make sure that all documents have been uploaded correctly */
  validateDocuments = (onlyRemoveMessages = false): boolean => {
    const { persons } = this.state;
    const { t } = this._locale;

    const missingDocuments = (person: PersonParams) => {
      if (person.prevVerification?.status == 'verified') {
        return false;
      }
      if (!person.verification) return true;
      const { document, additional_document } = person.verification;
      return !document?.front || !additional_document?.front;
    };

    if (persons.find(missingDocuments) != null) {
      !onlyRemoveMessages && this.setState({ documentsError: t('All persons have to have valid documents') });
      return false;
    }

    this.setState({ documentsError: undefined });
    return true;
  };

  onSubmit(_, resolve, reject) {
    const { persons } = this.state;
    const { t } = this._locale;

    if (!this.validateRelationships()) {
      return reject('Relationships not valid');
    }

    if (this.needsDocument && !this.validateDocuments()) {
      return reject('Missing documents');
    }

    // Save all person objects
    Promise.all(persons.map(this.savePerson))
      .then(() => {
        // Save company information about directors/owners provider
        return this.saveCompanyInformation();
      })
      .then(() => {
        // Go to next step
        this.props.nextStep();
        resolve();
      })
      .catch((err) => {
        if (err.raw && err.raw.statusCode === 400) {
          Alert.show(
            <>
              {t('Please double check the information...')}&nbsp;<i>{err.raw.message}</i>
            </>,
            t('Information was not accepted'),
            'error',
          );
        } else {
          Alert.show(
            t('Please double check the information and try again'),
            t('An unexpected error has occurred'),
            'error',
          );
        }
        console.error(err);
        reject();
      });
  }

  async addPerson() {
    // Make sure that the current person is a valid object.
    // If this is not the case, a new person will not be created.
    if (await this.form.validate()) {
      const person = this.constructPersonParams();
      const persons = [...this.state.persons, person];
      this.setState({ persons, currentIndex: persons.length - 1 });
    }
  }

  private renderErrors() {
    const errors = [this.state.documentsError, this.state.relationshipsError, ...(this.state.errorsFromStripe || [])];
    return errors.filter(Boolean).map((x, i) => <li key={i}>{x}</li>);
  }

  private renderErrorMessage() {
    const { t } = this._locale;
    if (this.hasErrors) {
      return (
        <div className="ui visible error message">
          <header>{t('Information is missing or has to be updated')}</header>
          <ul className="list">{this.renderErrors()}</ul>
        </div>
      );
    }
  }

  render() {
    const { t } = this._locale;
    if (!this.state.loaded) {
      return (
        <div className="ui active inverted dimmer">
          <div className="ui text loader">{t('Loading content')}</div>
        </div>
      );
    }
    const { currentIndex, ownersDirectorsAndExecutivesProvided } = this.state;
    const persons = merge([], this.state.persons) as PersonParams[];
    const person = persons[currentIndex];

    return (
      <Form
        style={{ padding: 10 }}
        onSubmit={this.onSubmit.bind(this)}
        ref={(instance) => (this.form = instance as Form)}
      >
        <div>
          <h1>
            {t('People')}
            <PrivacyInformationPopup />
          </h1>
          {this.renderErrorMessage()}
          <div className="ui top attach tabular menu" style={{ marginBottom: 0 }}>
            {persons.map((_, i) => (
              <PersonsForm.TabItem
                key={i}
                active={this.state.currentIndex == i}
                onClick={async () => {
                  if (await this.form.validate()) this.setState({ currentIndex: i });
                }}
              >
                {t('Person')}
                {` ${i + 1}`}
              </PersonsForm.TabItem>
            ))}
            <PersonsForm.TabItem onClick={this.addPerson.bind(this)}>
              <i className="ui green add icon" />
            </PersonsForm.TabItem>
          </div>
          <div key={currentIndex} className="ui bottom attached active tab segment">
            <PersonFields
              person={person}
              onPersonChanged={(updatedPerson) => {
                Object.assign(person, updatedPerson);
                this.setState({ persons });
              }}
            />

            <RelationshipFields
              person={person}
              representativeHasBeenAssigned={this.representativeHasBeenAssigned}
              onRelationshipChanged={(relationship) => {
                person.relationship = relationship;
                this.setState({ persons }, () => {
                  this.validateRelationships(true);
                });
              }}
            />

            <RepresentativeFields
              person={person}
              onTitleChanged={(title) => {
                person.relationship!.title = title;
                this.setState({ persons });
              }}
              onPhoneChanged={(phone) => {
                person.phone = '+46' + phone;
                this.setState({ persons });
              }}
            />

            <AddressFields
              person={person}
              onUpdatedAddress={(address) => {
                person.address = address;
                this.setState({ persons });
              }}
            />

            {this.needsDocument && (
              <IdentificationFields
                person={person}
                onVerificationChanged={(verification) => {
                  person.verification = verification;
                  this.setState({ persons }, () => {
                    this.validateDocuments(true);
                  });
                }}
              />
            )}
          </div>
        </div>
        {this.renderErrorMessage()}
        <Field style={{ marginTop: '2rem' }}>
          <Checkbox
            defaultChecked={ownersDirectorsAndExecutivesProvided}
            onCheckedOrUncheked={(checked) => {
              this.setState({ ownersDirectorsAndExecutivesProvided: checked });
            }}
            label={t('I agree that no more owners, directors or...')}
          />
        </Field>
        <Field>
          <SubmitButton className="primary" disabled={!ownersDirectorsAndExecutivesProvided}>
            {t('Next step')}
          </SubmitButton>
        </Field>
      </Form>
    );
  }
}
