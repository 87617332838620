import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { ActivityOccuranceDocument } from './activityOccurance.resource';
import { ActivityTypeDocument } from './activityType.resource';
import { InventoryEntityDocument } from './inventoryEntity.resource';

export interface ActivityDeleteResponses {
  occurances?: ActivityOccuranceDocument[];
  activityTypes?: ActivityTypeDocument[];
}

export interface InventoryDocument extends MongooseDocument {
  organization: Types.ObjectId;
  title: string;
  quantity: number;
  groupMultiplier: number;
  timeToAvailable: number; // TODO: remove optional typing and migrade db.
  usesAdvancedSetting: boolean;
  /** VIRTUAL FIELD List of connected entities */ entities: InventoryEntityDocument[];
}

export class InventoryResource extends MongooseResource<InventoryDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Inventory);

    this.setSchema({
      organization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: true },
      title: { type: String, required: true },
      quantity: { type: Number, required: true },
      groupMultiplier: { type: Number, required: true, default: 1 },
      timeToAvailable: { type: Number, required: true, default: 0 },
      usesAdvancedSetting: { type: Boolean, required: true, default: false },
    });

    this.schema.virtual('entities', {
      ref: SchemaNames.InventoryEntity,
      localField: '_id',
      foreignField: 'inventory',
    });
  }

  checkAndDeleteInventory(inventoryId: string) {
    return this.sendRequest<ActivityDeleteResponses>('/checkAndDeleteInventory', 'post', { id: inventoryId });
  }

  getInventoryEntities(id: string) {
    return this.sendRequest<InventoryEntityDocument[]>(`/${id}/entities`, 'get', {});
  }

  findWithPopulatedEntities(query?: Object) {
    return this.sendRequest<InventoryDocument[]>(`/findWithPopulatedEntities`, 'post', { query });
  }

  findOccurranceInventories(query: Object) {
    return this.sendRequest<InventoryDocument[]>(`/findOccurranceInventories`, 'post', { query });
  }
}
