import { includes } from 'lodash';
import * as React from 'react';
import Stripe from 'stripe';
import { Field, OrganizationResource, DropzoneForImage, Consume, LocaleContext } from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';

type base64str = string;
type filename = string;
type FilePromise = Promise<[base64str, filename]>;
type Purpose =
  | 'additional_verification'
  | 'business_icon'
  | 'business_logo'
  | 'customer_signature'
  | 'dispute_evidence'
  | 'identity_document'
  | 'pci_document'
  | 'tax_document_user_upload';

interface Props {
  onUpload: (f: Stripe.File) => Promise<void>;
  current: Stripe.File | null | string;
  width?: 'sixteen' | 'twelve' | 'ten' | 'eight';
  noPadding?: boolean;
  purpose: Purpose;
}

interface State {
  loading: boolean;
  override: boolean;
}

export class FileUploadForm extends React.Component<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  state: State = {
    loading: false,
    override: false,
  };

  fieldStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: this.props.noPadding ? 0 : undefined,
  };

  uploadedStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    height: '100%',
    minHeight: 100,
  };

  read: (f: File) => FilePromise = (file: File) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve([reader.result as string, file.name]);
      reader.readAsDataURL(file);
    });

  onDrop = async (accepted: File[], _: File[]) => {
    this.setState({ loading: true });
    const mimetypes = ['image/png', 'image/jpeg', 'application/pdf'];
    const file = accepted.find((x) => includes(mimetypes, x.type));
    if (file == undefined) {
      throw new Error('File is of wrong type!');
    }
    const [data, name] = await this.read(file);
    const resource = new OrganizationResource();
    {
      const file = await resource.createStripeFile(data, name, this.props.purpose);
      await this.props.onUpload(file);
      this.setState({ loading: false, override: false });
    }
  };

  render() {
    const { t } = this._locale;
    const width = `${this.props.width || 'eight'} wide` as any;

    if (this.state.loading) {
      return (
        <Field style={this.fieldStyle} width={width} label="">
          <div className="ui grey message" style={this.uploadedStyle}>
            <div className="ui active loader" />
          </div>
        </Field>
      );
    }
    if (this.props.current && !this.state.override) {
      return (
        <Field style={this.fieldStyle} width={width} label="">
          <div className="ui grey message" style={this.uploadedStyle}>
            <h5>{t('Uploaded document')}</h5>
            <button className="ui primary button" onClick={(_) => this.setState({ override: true })}>
              {t('Upload a new document')}
            </button>
          </div>
        </Field>
      );
    }
    return (
      <Field style={this.fieldStyle} width={width} label="">
        <DropzoneForImage accept="image/*,application/pdf" onDrop={this.onDrop} noHeight />
      </Field>
    );
  }
}
