import React from 'react';
import { Dropdown, Field, Input, PriceCategoryDocument, Rules } from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import Shortid from 'shortid';
interface Props {
  name: string;
  selectedTickets: number;
  onChange: (tickets: string | number) => void;
  priceCategory: PriceCategoryDocument;
  isInceaseButtonEnabled: boolean;
  setVisitorsFreely: boolean;
  dropdownItems: JSX.Element[];
  disabled: boolean;
  /** Use null to remove subtitle label*/ overrideSubtitleLabel?: string | null;
}

export const DropdownField = ({
  name,
  selectedTickets,
  onChange,
  isInceaseButtonEnabled,
  setVisitorsFreely,
  priceCategory,
  dropdownItems,
  disabled,
  overrideSubtitleLabel,
}: Props) => {
  const { t, tt } = useLocale();

  const isEmptyOrIntegerRule = () => {
    return Rules.CustomRule({
      validator: (value) => value == '' || !isNaN(value as any),
      prompt: t('components.priceCategories.selectPriceCategoriesFields.promptOne'),
      keyboard: 'integer',
    });
  };

  const formElement = () => {
    if (setVisitorsFreely) {
      return (
        <Input
          disabled={disabled}
          name={name}
          value={String(selectedTickets)}
          onChange={onChange}
          rules={[isEmptyOrIntegerRule(), Rules.MaximumLength(4), Rules.IsANumber()]}
          style={{ width: '4rem' }}
        />
      );
    } else {
      return (
        <Dropdown
          disabled={disabled}
          name={name}
          value={String(selectedTickets)}
          onChange={onChange}
          style={{ minWidth: '4.5rem' }}
        >
          {dropdownItems}
        </Dropdown>
      );
    }
  };

  const increment = () => onChange(selectedTickets + 1);
  const decrement = () => onChange(selectedTickets - 1);

  return (
    <Field
      key={`drop-down-${Shortid.generate()}`}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '1rem 0',
      }}
      label={
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '1rem' }}>
          <b style={{ fontSize: '1.2rem' }}>
            {tt(priceCategory.name)}
            {priceCategory.price > 0 && '  -  ' + priceCategory.price + ' ' + t('SEK')}
          </b>
          <i style={{ fontSize: '0.8rem' }}>
            {priceCategory.groupMultiplier > 0 ? (
              <>
                {t('Valid for')} {priceCategory.groupMultiplier} {t('people')}.
              </>
            ) : overrideSubtitleLabel === null ? (
              ''
            ) : (
              overrideSubtitleLabel || t('Accessories')
            )}
          </i>
        </div>
      }
    >
      <div style={{ display: 'flex' }}>
        <button
          className={`ui primary ${!selectedTickets || disabled ? 'disabled' : ''} icon button`}
          style={{ marginRight: '0.5rem' }}
          type="button"
          onClick={decrement}
        >
          <i className="minus icon" />
        </button>
        {formElement()}
        <button
          className={`ui primary ${!isInceaseButtonEnabled || disabled ? 'disabled' : ''} icon button`}
          style={{ marginLeft: '0.5rem', marginRight: 0 }}
          type="button"
          onClick={increment}
        >
          <i className="plus icon" />
        </button>
      </div>
    </Field>
  );
};
