import Mongoose from 'mongoose';

import { UserDocument } from '../documents';
import { MongooseResource } from './_mongooseResource';

/** A user resource */
export abstract class UserResource<D extends UserDocument = UserDocument> extends MongooseResource<D> {
  /** Typeguard attribute */ protected userResourceIdentifier = true;
  /** The authentication roles this user resource has */ private roles: string[];

  constructor() {
    super();

    // Set a subpath
    this.addToPath('user');

    // Add schema fields
    this.schema.add({
      //_id : {type: String, unique: true, lowercase: true, required: true }, TODO: Not needed any more, should be safe to remove. Users use a standard ObjectId as _id key
      passwordResetToken: String,
      passwordResetDate: Date,
      forcePasswordReset: Boolean,
      roles: [Mongoose.Schema.Types.Mixed],
      facebookID: { type: String, required: false },
      username: { type: String, lowercase: true, trim: true },
      // nrOfTimesSignedIn: { type: Number, default: 0, required: true }
      // _email : {type: String, unique: true, lowercase: true, required: true, match: [/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Please fill a valid email address']}}) // EXAMPLE OF EMAIL VALIDATION
    });

    // Add virtual schema fields
    /*this.schema('_roles')
            .get(() => {
                return this.roles;
        });*/
  }

  /** Checks if this user resource has the asked for role, if strict then role types must match */
  public abstract documentHasRole(document: UserDocument, role: any, strict?: boolean): boolean;

  // Requests

  login(document: { username: string; password?: string }) {
    return this.sendRequest<D>('/login', 'post', {
      username: document.username,
      password: document.password,
    });
  }

  logout() {
    return this.sendRequest<boolean>('/logout', 'post', {});
  }

  updateDocument(document: D, loginAsUser = false) {
    return new Promise<string>((resolve, reject) => {
      this.validateDocument(document).then(
        () => resolve(this.sendRequest<string>('/update', 'post', { document: document, login: loginAsUser })),
        (err) => {
          reject(err);
        },
      );
    });
  }

  createResetToken(_id: Mongoose.Types.ObjectId): any {
    return this.sendRequest<boolean>('/createResetToken', 'post', { _id });
  }

  changePassword(_id: Mongoose.Types.ObjectId | string, newPassword: string, resetToken: string) {
    return this.sendRequest<any>('/changePassword', 'post', { _id, newPassword, resetToken });
  }

  validateAuthentication(role?: string, username?: string, password?: string) {
    return this.sendRequest<boolean>('/authenticate', 'post', { role, username, password });
  }

  isAuthenticated() {
    return this.sendRequest<boolean>('/isauthenticated', 'post', {});
  }
}
