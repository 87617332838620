import * as React from 'react';
import type Stripe from 'stripe';
import { getAllRequirements } from '.';
import {
  Alert,
  Button,
  Consume,
  Field,
  Fields,
  Form,
  Input,
  KosmicComponent,
  LocaleContext,
  OrganizationResource,
  Rules,
  SubmitButton,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';
import { FileUploadForm } from './fileUploadForm';
import { PrivacyInformationPopup } from './privacyInformationPopup';
import { stripeErrorToComponent } from './stripeErrorToComponent';

interface State {
  documentError?: string;
  verificationDocumentId?: string;
  errorsFromStripe?: JSX.Element[];
  reEnterTaxId: boolean;
}

interface Props {
  nextStep: () => void;
  account: Stripe.Account;
}
export class CompanyForm extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get hasErrors(): boolean {
    if (this.state.documentError) return true;
    if (this.state.errorsFromStripe && this.state.errorsFromStripe.length) return true;
    return false;
  }

  private get needsDocument(): boolean {
    const requirements = getAllRequirements(this.props.account);
    return Boolean(requirements.find((x) => /company\.verification\.document/.test(x)));
  }

  constructor(props: Props) {
    super(props);
    const state: State = { reEnterTaxId: false };
    if (props.account.company?.verification?.document.front) {
      state.verificationDocumentId = props.account.company.verification.document.front as string;
    }
    this.state = state;
  }

  initializeHelpers() {
    const helpers = ['#document-helper'];
    for (const id of helpers) {
      const element = this.domElement.find(id);
      element && element.popup();
    }
  }

  componentDidUpdate() {
    this.initializeHelpers();
  }

  componentDidMount() {
    this.initializeHelpers();

    const mapper = (err) => {
      const { requirement } = err;
      if (!/^company\./.test(requirement)) {
        return null;
      }
      return stripeErrorToComponent(err);
    };
    if (this.props.account.requirements && this.props.account.requirements.errors) {
      const errorsFromStripe = this.props.account.requirements.errors.map(mapper).filter(Boolean) as JSX.Element[];
      this.setState({ errorsFromStripe });
    }
  }

  // Called when the form is submitting its values.
  onSubmit = async (values, resolve, reject) => {
    const { t } = this._locale;
    const { props } = this;
    const { company: c } = props.account;
    if (this.needsDocument && !(c?.verification && c.verification.document && c.verification.document.front)) {
      this.setState({ documentError: t('Please upload your company registration document') });
      return reject();
    }
    const resource = new OrganizationResource();
    const company: Stripe.AccountCreateParams.Company = {
      name: values['name'],
      address: {
        line1: values['address.line1'],
        city: values['address.city'],
        postal_code: values['address.postal_code'],
      },
      phone: '+46' + values['phone'],
      tax_id: values['tax_id'] || undefined,
    };
    const business_profile: Stripe.AccountCreateParams.BusinessProfile = {
      name: this.globals.session.currentOrganization.name,
      mcc: '7032', // Sporting & Recreational Camps
      url: values['url'],
    };

    try {
      await resource.updateAccount({ company, business_profile });
      props.nextStep();
      resolve();
    } catch (err) {
      if (err.raw && err.raw.statusCode === 400) {
        Alert.show(
          <>
            {t('Please double check the information...')}&nbsp;<i>{err.raw.message}</i>
          </>,
          t('Information was not accepted'),
          'error',
        );
      } else {
        Alert.show(
          t('Please double check the information and try again'),
          t('An unexpected error has occurred'),
          'error',
        );
      }
      reject();
    }
  };

  private renderErrors() {
    if (!this.hasErrors) {
      return null;
    }

    const { t } = this._locale;

    const errors = [this.state.documentError, ...(this.state.errorsFromStripe || [])];

    return (
      <div className={`ui visible error message`}>
        <ul className="list">
          {errors.filter(Boolean).map((x, i) => (
            <li key={i}>{x}</li>
          ))}
        </ul>
        <p>
          <i>{t('If the entered information is changed...')}</i>
        </p>
      </div>
    );
  }

  render() {
    const { t } = this._locale;
    const {
      company: c = { address: {} } as Stripe.Account.Company,
      business_profile: bp = { url: '' } as Stripe.Account.BusinessProfile,
    } = this.props.account;
    const { address = {} as Stripe.AccountAddressParam } = c;

    return (
      <Form onSubmit={this.onSubmit}>
        <h1>
          {t('Company information')}
          <PrivacyInformationPopup />
        </h1>

        {this.renderErrors()}

        <Field label={t('Company name')}>
          <Input
            name="name"
            type="text"
            defaultValue={c.name || this.globals.session.currentOrganization.name}
            rules={[Rules.NotEmpty()]}
          />
        </Field>

        <Field label={t('Organization number')}>
          {c.tax_id_provided && !this.state.reEnterTaxId ? (
            <Button onClick={() => this.setState({ reEnterTaxId: true })}>{t('Update the organization number')}</Button>
          ) : (
            <Input name="tax_id" placeholder="XXXXXXXXXX" rules={[Rules.ExactLength(10), Rules.IsOnlyDigits()]} />
          )}
        </Field>

        <Field label={t('Street address')}>
          <Input name="address.line1" type="text" defaultValue={address.line1 || ''} rules={[Rules.NotEmpty()]} />
        </Field>

        <Fields>
          <Field width="eight wide" label={t('City')}>
            <Input name="address.city" type="text" defaultValue={address.city as string} rules={[Rules.NotEmpty()]} />
          </Field>
          <Field width="eight wide" label={t('Postal code')}>
            <Input
              name="address.postal_code"
              type="text"
              defaultValue={address.postal_code as string}
              rules={[Rules.NotEmpty()]}
            />
          </Field>
        </Fields>

        <Field label={t('Phone number')}>
          <Input
            name="phone"
            defaultValue={(c.phone || '').replace(/^\+46/, '')}
            label="+46"
            rules={[Rules.IsAnInteger()]}
          />
        </Field>

        <Field label={t('Website or social profile')}>
          <Input label="http://" name="url" defaultValue={bp!.url || ''} type="text" rules={[Rules.NotEmpty()]} />
        </Field>

        {this.needsDocument ? (
          <>
            <h3>
              {t('Company registration document')} <i id="document-helper" className="question circle outline icon" />
              <div className="ui popup">
                <div className="header">{t('Company registration document')}</div>
                <div className="content">{t('A picture of a document that confirms name...')}</div>
              </div>
            </h3>

            <Field width="sixteen wide">
              <FileUploadForm
                current={this.state.verificationDocumentId || null}
                purpose="additional_verification"
                onUpload={async (file) => {
                  const document = {
                    front: file.id,
                  } as Stripe.Account.Company.Verification.Document;
                  c.verification = { document };
                  this.setState({ verificationDocumentId: file.id });
                }}
                width="sixteen"
                noPadding
              />
            </Field>
          </>
        ) : undefined}

        <SubmitButton className="primary">{t('Next step')}</SubmitButton>
      </Form>
    );
  }
}
