import React, { useContext, useState } from 'react';
import { OffersContext } from '../../../../../contexts/offers.context';
import { useLocale } from '../../../../../_locales';
import { BasePriceFields } from '../createPriceCategories/basePriceFields';
import { CreateAccessoriesFields } from '../createPriceCategories/createAccessoriesFields';
import { CreatePriceCategoriesFields } from '../createPriceCategories/createPriceCategoriesFields';
import { RescheduleInsuranceFields } from '../createPriceCategories/rescheduleInsuranceFields';
import { TaxRateFields } from '../fields/taxRateFields';
import { OfferFormSubmitButton } from './formSubmitButton';

interface Props {
  onNavigateToTab: (path: string) => void;
}

export const PricingTab = ({ onNavigateToTab }: Props) => {
  const { t } = useLocale();
  const { selectedOffer, updateSelectedOffer } = useContext(OffersContext);
  if (!selectedOffer) return null;
  const isUsingInventory = selectedOffer.useInventory;

  return (
    <div>
      <TaxRateFields taxRate={selectedOffer.taxRate} setTaxRate={updateSelectedOffer} />
      <CreatePriceCategoriesFields
        categories={selectedOffer.priceCategories}
        onPriceCategoriesChange={updateSelectedOffer}
        isUsingInventory={isUsingInventory}
      />
      <BasePriceFields categories={selectedOffer.priceCategories} onPriceCategoriesChange={updateSelectedOffer} />
      <RescheduleInsuranceFields
        categories={selectedOffer.priceCategories}
        onPriceCategoriesChange={updateSelectedOffer}
      />
      <CreateAccessoriesFields
        categories={selectedOffer.priceCategories}
        onPriceCategoriesChange={updateSelectedOffer}
        defaultTaxRate={selectedOffer.taxRate}
      />
      <OfferFormSubmitButton
        onClick={() => onNavigateToTab('/settings')}
        isSubmit={!selectedOffer.isNew}
        nextTabName={t('Settings')}
      />
    </div>
  );
};
