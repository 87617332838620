import { Schema } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { TranslatableText } from '../schemas/translatableText';
import { LimitationsDocument, LimitationsSchema } from './../schemas/limitations';
import { PriceCategoryDocument, PriceCategorySchema } from './../schemas/priceCategory';
import { GiftCardImageResource } from './giftCardImage.resource';
import { Platforms, TaxRate } from './platforms';

export interface GiftCardTypeDocument extends MongooseDocument {
  title: string | TranslatableText;
  value?: number;
  priceCategoies?: PriceCategoryDocument[];
  nrOfUses?: number;
  validityDurationInDays: number;
  limitations: LimitationsDocument;
  mailMessage?: string | TranslatableText;
  taxRate: TaxRate;
  imageId: string; // TODO: OBJECT ID?
  thumbnailId?: string; // TODO: OBJECT ID?
  showOrganization?: boolean;
  /** VIRUAL FIELD */ imageUrl?: string;
}

export class GiftCardTypeResource extends MongooseResource<GiftCardTypeDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.GiftCardType);

    this.setSchema({
      title: { type: Schema.Types.Mixed, required: true },
      value: { type: Number, required: false },
      priceCategoies: [PriceCategorySchema],
      nrOfUses: { type: Number, required: false },
      validityDurationInDays: { type: Number, required: true },
      limitations: { type: LimitationsSchema, required: true },
      mailMessage: { type: Schema.Types.Mixed, required: false },
      imageId: { type: String, required: false },
      thumbnailId: { type: String, required: false },
      taxRate: { type: Number, required: true, default: Platforms.taxRates[0] },
      showOrganization: { type: Boolean, required: false },
    });

    this.addVirtualField('imageUrl', (document) =>
      document.imageId
        ? new GiftCardImageResource().fileUrl(document.imageId, 'jpg')
        : '/static/platform/img/default_giftcard.png',
    );
    this.addVirtualField('thumbnailUrl', (document) =>
      document.thumbnailId
        ? new GiftCardImageResource().fileUrl(document.thumbnailId, 'jpg')
        : '/static/platform/img/default_giftcard.png',
    );
  }

  findBasedOnLimitations(limitations: Partial<LimitationsDocument>) {
    return this.sendRequest<GiftCardTypeDocument[]>('/findBasedOnLimitations', 'post', { limitations: limitations });
  }

  findWithPopulatedLimitations() {
    return this.sendRequest<GiftCardTypeDocument[]>('/findWithPopulatedLimitations', 'get', {});
  }
}
