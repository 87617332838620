import * as React from 'react';
import { Consume, LocaleContext, MobxComponent, OrganizationDocument, UserRole } from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { Maybe } from '../../../maybe';
import { FeatureLockIcon } from '../../featureLockIcon';

export class HelpSegment extends MobxComponent<{}> {
  private get isUserOrgManager() {
    return this.globals.session.userHasRole({ type: 'organization.manager' } as UserRole);
  }

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  render() {
    const { t } = this._locale;
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { features } = organization.flags;

    return (
      <div className="ui green segment">
        <h2 className="ui center aligned header">{t('components.dashboard.home.segments.helpSegment.headerFour')}</h2>

        <Maybe if="property.manager">
          <h4 style={{ marginBottom: '0.3em' }}>
            <i className="calendar outline icon" />
            <FeatureLockIcon feature={features.scheduling && features.bookings} />
            {t('Schedules')}
          </h4>
          <p style={{ marginBottom: '1em' }}>{t('components.dashboard.home.segments.helpSegment.descriptionSix')}</p>
        </Maybe>

        <Maybe if="organization.manager">
          <h4 style={{ marginBottom: '0.3em' }}>
            <i className="star outline icon" />
            <FeatureLockIcon feature={features.offers} />
            {t('components.dashboard.home.segments.helpSegment.iconTwenty')}
          </h4>
          <p style={{ marginBottom: '1em' }}>{t('components.dashboard.home.segments.helpSegment.descriptionFour')}</p>
        </Maybe>

        <Maybe if="property.manager">
          <h4 style={{ marginBottom: '0.3em' }}>
            <i className="map pin icon" />
            <FeatureLockIcon feature={features.properties} />
            {t('components.dashboard.home.segments.helpSegment.iconNineteen')}
          </h4>
          <p style={{ marginBottom: '1em' }}>
            {this.isUserOrgManager
              ? t('components.dashboard.home.segments.helpSegment.descriptionTwo')
              : t('components.dashboard.home.segments.helpSegment.descriptionThree')}
          </p>
        </Maybe>

        <Maybe if="organization.manager">
          <h4 style={{ marginBottom: '0.3em' }}>
            <i className="users icon" />
            {t('components.dashboard.home.segments.helpSegment.iconEigtheen')}
          </h4>
          <p style={{ marginBottom: '1em' }}>{t('components.dashboard.home.segments.helpSegment.descriptionOne')}</p>
        </Maybe>

        <Maybe if="property.manager">
          <h4 style={{ marginBottom: '0.3em' }}>
            <i className="gift icon" />
            <FeatureLockIcon feature={features.giftcards} />
            {t('Sell a giftcard')}
          </h4>
          <p style={{ marginBottom: '1em' }}>{t('components.dashboard.home.segments.helpSegment.descriptionSeven')}</p>
        </Maybe>

        <Maybe if="property.manager">
          <h4 style={{ marginBottom: '0.3em' }}>
            <i className="ticket icon" />
            <FeatureLockIcon feature={features.bookings} />
            {t('components.dashboard.home.segments.helpSegment.iconTwentyone')}
          </h4>
          <p style={{ marginBottom: '1em' }}>{t('components.dashboard.home.segments.helpSegment.descriptionFive')}</p>
        </Maybe>

        <Maybe if="property.manager">
          <h4 style={{ marginBottom: '0.3em' }}>
            <i className="cube icon" />
            <FeatureLockIcon feature={features.widgets} />
            {t('Widgets')}
          </h4>
          <p style={{ marginBottom: '1em' }}>{t('components.dashboard.home.segments.helpSegment.descriptionEight')}</p>
        </Maybe>
      </div>
    );
  }
}
