import { uniq } from 'lodash';
import React from 'react';
import Stripe from 'stripe';
import { getAllRequirements } from '.';
import { useLocale } from '../../../../../_locales';

interface Props {
  account?: Stripe.Account;
}

const RequirementsInfo = (props: Props) => {
  const { t } = useLocale();
  if (!props.account) return null;

  const requirements = uniq(
    getAllRequirements(props.account)
      .filter(
        (r) =>
          !/company.directors_provided/.test(r) &&
          !/company.executives_provided/.test(r) &&
          !/company.owners_provided/.test(r),
      )
      .map((r) => r.split('.')[0]),
  );

  const translateRequirement = (requirement: string) => {
    switch (requirement) {
      case 'business_profile':
        return t('Business profile');
      case 'company':
        return t('Company');
      case 'directors':
        return t('Directors');
      case 'executives':
        return t('Executives');
      case 'external_account':
        return t('External bank account');
      case 'owners':
        return t('Owners');
      case 'representative':
        return t('Representative');
      case 'tos_acceptance':
        return t('Terms of Service acceptance');
      default:
        if (/person_/.test(requirement)) {
          return t('Personal data');
        }
        console.warn('Missing translation for requirement:', requirement);
        return requirement;
    }
  };

  return (
    <div className="ui message">
      <div className="header">{t('Please enter information about the following')}</div>
      <ul className="list">
        {requirements.map((requirement, index) => (
          <li key={index}>{translateRequirement(requirement)}</li>
        ))}
      </ul>
    </div>
  );
};

export default RequirementsInfo;
