import Fuse from 'fuse.js';
import React, { ChangeEventHandler, useContext, useEffect, useState } from 'react';
import {
  DeviceProvider,
  GlobalsContext,
  InventoryEntityDocument,
  InventoryEntityResource,
  PlaceDocument,
  Table,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { InventoryDetailsRow } from './inventoryDetailsRow';

interface Props {
  places?: PlaceDocument[];
  setEntities: (entities: InventoryEntityDocument[]) => void;
  getSelectedEntities: (selected: string[]) => void;
  searchQuery?: string;
}

const InventoryReadonlyList = ({ setEntities, getSelectedEntities, searchQuery, places }: Props) => {
  const { t, tt } = useLocale();
  const [tempInventoryEntities, setTempInventoryEntities] = useState<InventoryEntityDocument[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const device = useContext(DeviceProvider.Context);
  const mobile = device.size === 'mobile';
  const globals = useContext(GlobalsContext);

  const getInventoryEntities = async () => {
    const tempEntities = await new InventoryEntityResource().findWithPopulatedLastStatusLocation({
      organization: globals.session.currentOrganizationId,
    });

    const filteredEntities = tempEntities.filter((entity) => entity.isTemporary);

    setTempInventoryEntities(filteredEntities);
    setEntities(filteredEntities);
  };

  useEffect(() => {
    getSelectedEntities(selectedEntities);
  }, [selectedEntities]);

  useEffect(() => {
    getInventoryEntities();
  }, []);

  const fuse = new Fuse(tempInventoryEntities, {
    threshold: 0.4,
    keys: [
      { name: 'title', weight: 1 },
      { name: 'lastStatus.status', weight: 2 },
      { name: 'lastStatus.location.name', weight: 2 },
      { name: 'lastStatus.booking.number', weight: 2 },
    ],
    getFn: (document, path: string[]) => tt(path.reduce((object, path) => (object ? object[path] : object), document)),
  });

  const filteredInventoryEntities = searchQuery
    ? fuse.search(searchQuery).map(({ item }) => item)
    : tempInventoryEntities;

  const onToggleSelectItem = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedEntities((prev) => [...prev, id]);
      return;
    }
    if (!isChecked) {
      const updatedState = selectedEntities.filter((e) => e !== id);
      setSelectedEntities(updatedState);
    }
  };

  const selectAllItems: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      setSelectedEntities(filteredInventoryEntities.map((e) => e.id));
    } else {
      setSelectedEntities([]);
    }
  };

  return (
    <div style={mobile ? { margin: '-0em -2em -0em -2em' } : {}}>
      <Table<InventoryEntityDocument>
        className={'ui striped unstackable table'}
        minWidth={'10rem'}
        dataList={filteredInventoryEntities}
        selectedElements={selectedEntities}
        headers={[
          { title: t('Title'), sortBy: 'title' },
          { title: 'Status', sortBy: 'lastStatus.status' as any },
          { title: t('Location'), sortBy: 'lastStatus.location.name.sv' as any },
          { title: t('Booking'), sortBy: 'lastStatus.booking.number' as any },
          ...(!mobile ? [{ title: t('Notes') }] : []),
          { title: <input type="checkbox" onChange={selectAllItems} />, float: 'right' }, //Global checkbox for selecting / deselecting all items
        ]}
        renderRow={(item: InventoryEntityDocument, isSelected: boolean) => {
          return (
            <InventoryDetailsRow
              mobile={mobile}
              places={places}
              item={item}
              isSelected={isSelected}
              key={item.id}
              isReadonlyList
              isSelectable
              onToggleSelect={onToggleSelectItem}
            />
          );
        }}
      />
    </div>
  );
};

export default InventoryReadonlyList;
