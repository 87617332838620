import React, { CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocale } from '../../../../../_locales';

interface Props {
  onNavigateToTab: (path: string) => void;
}

export const FormTabsMenu = ({ onNavigateToTab }: Props) => {
  const { t } = useLocale();
  const { pathname } = useLocation();

  const mainActive = !pathname.endsWith('pricing') && !pathname.endsWith('settings') ? 'active' : '';
  const pricingActive = pathname.endsWith('pricing') ? 'active' : '';
  const settingsActive = pathname.endsWith('settings') ? 'active' : '';

  return (
    <div className="ui fluid mini unstackable steps">
      <div
        onClick={() => onNavigateToTab('')}
        className={`${mainActive} step`}
        style={{ padding: '1rem .5rem', cursor: 'pointer' }}
      >
        <div className="content" style={flexCenter}>
          <i className="large star icon"></i>
          <div className="title">{t('Offer')}</div>
        </div>
      </div>
      <div
        onClick={() => onNavigateToTab('/pricing')}
        className={`${pricingActive} step`}
        style={{ padding: '1rem .5rem', cursor: 'pointer' }}
      >
        <div className="content" style={flexCenter}>
          <i className="large payment icon"></i>
          <div className="title">{t('Pricing')}</div>
        </div>
      </div>
      <div
        onClick={() => onNavigateToTab('/settings')}
        className={`${settingsActive} step`}
        style={{ padding: '1rem .5rem', cursor: 'pointer' }}
      >
        <div className="content" style={flexCenter}>
          <i className="large settings icon"></i>
          <div className="title">{t('Settings')}</div>
        </div>
      </div>
    </div>
  );
};

const flexCenter: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};
