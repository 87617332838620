import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';

const TimeLimitsSchema = new Schema(
  {
    start: { type: Date, required: true },
    end: { type: Date, required: true },
  },
  { _id: false },
);

const OrderUsageSchema = new Schema(
  {
    orderId: { type: String, required: true },
    discountCode: { type: String, required: true },
  },
  { _id: false },
);

export type DiscountRules = {
  timeLimits: DiscountTimeLimit[];
  usageLimit?: number;
};
export type DiscountTimeLimit = {
  start: Date;
  end: Date;
};
export type DiscountUsages = {
  code: string;
  numberOfUses: number | undefined;
};

export type OrderUsage = {
  orderId: string;
  discountCode: string;
};
export interface DiscountDocument extends MongooseDocument {
  code: string;
  organization: Types.ObjectId;
  discountPercentage: number; //Percentage between 0 - 1
  usageLimit?: number;
  timeLimits: DiscountTimeLimit[];
  orderUsageLog: OrderUsage[];
  offerLimitations?: string[];
}
export interface CreateDiscountDocument extends MongooseDocument {
  code: string;
  organization: Types.ObjectId;
  discountPercentage: number; //Percentage between 0 - 1
  usageLimit?: number;
  timeLimits: DiscountTimeLimit[];
  offerLimitations?: string[];
}

export class DiscountResource extends MongooseResource<DiscountDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Discount);

    this.setSchema({
      organization: { type: Schema.Types.ObjectId, required: true },
      code: { type: String, required: true },
      discountPercentage: { type: Number, required: true },
      timeLimits: { type: [TimeLimitsSchema], required: true },
      usageLimit: { type: Number, required: false },
      orderUsageLog: { type: [OrderUsageSchema], default: [] },
      offerLimitations: { type: [String], required: false },
    });
  }

  async createDiscount(discount: CreateDiscountDocument): Promise<DiscountDocument | any> {
    const discountCode = await this.find({ code: discount.code, organization: discount.organization });
    if (discountCode.length > 0) {
      // If this org already has this code, throw error
      throw new Error('Discount code already exists');
    } else {
      // Else create document
      return this.createDocument({
        discount,
      });
    }
  }

  async updateDiscount(discount: DiscountDocument): Promise<DiscountDocument | any> {
    const discountCode = await this.find({ code: discount.code, organization: discount.organization });
    if (discountCode.length) {
      for (const existingDiscount of discountCode) {
        if (
          existingDiscount.code.toUpperCase() === discount.code.toUpperCase() &&
          discount.id !== existingDiscount.id
        ) {
          throw new Error('Discount code already exists');
        } else {
          return this.updateDocument(discount);
        }
      }
    } else {
      return this.updateDocument(discount);
    }
  }

  getDiscountByCode(code: string) {
    return this.sendRequest<DiscountDocument>('/' + code, 'get', {});
  }
}
