import React, { ReactNode } from 'react';
import ShortId from 'shortid';
import {
  ActivityOccuranceDocument,
  AddressFields,
  BookingDocument,
  BookingPriceCategoryDocument,
  BookingResource,
  Consume,
  CustomerDocument,
  DestinationDocument,
  DestinationResource,
  Field,
  Form,
  Input,
  LocaleContext,
  MobxComponent,
  PropertyResource,
  Rules,
  SelectPriceCategoriesFields,
  SubmitButton,
  TextArea,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { CalendarStore } from '../store';

interface Props {
  event: ActivityOccuranceDocument;
  store: CalendarStore;
  selectedCustomer?: CustomerDocument;
  submitButtonText: ReactNode;
  onSubmit: (document: BookingDocument, resolve?: any, reject?: any) => void;
  requested?: boolean;
}

interface State {
  showAddressFields: boolean;
  showMessageFields: boolean;
  selectedPriceCategories: BookingPriceCategoryDocument[];
}

export class ManualBookingForm extends MobxComponent<Props, State> {
  state: State = {
    showAddressFields: false,
    showMessageFields: false,
    selectedPriceCategories: [],
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private async getCrossSellingProperty() {
    if (this.props.store.isUserAllowedToEditEvent()) {
      return undefined;
    }
    try {
      const administratedProperties = await new PropertyResource().getAdministratedProperties();
      // TODO: Handle if an organisation has a property connected to multiple destinations.
      // Also if multiple properties are connected to the same destination a popup
      // has to be presented so the user can select which to book from!
      const query = { connectedProperties: { $in: administratedProperties } };
      const destination = (await new DestinationResource().find(query))[0] as DestinationDocument | undefined;
      const property = destination?.connectedProperties.find((cp) =>
        administratedProperties.some((ap) => ap.id === cp.id),
      );
      return property;
    } catch (err) {
      return undefined;
    }
  }
  private createBooking = async (values, resolve, reject) => {
    const addressFieldsHasVales = values['Adress 1'] || values['Adress 2'] || values['Postord'] || values['Postnummer'];

    const resource = new BookingResource();
    const document = resource.createDocument({
      priceCategories: this.state.selectedPriceCategories,
      occurance: this.props.event._id,
      organization: this.props.event.organization,
      customer: {
        firstname: values['Förnamn'],
        lastname: values['Efternamn'],
        businessName: values['Företagsnamn'],
        email: values['Email'],
        phoneNr: values['Telefon'],
        address: addressFieldsHasVales
          ? {
              row1: values['Adress 1'],
              row2: values['Adress 2'],
              postOrt: values['Postort'],
              postNr: values['Postnummer'],
              country: 'sweden',
            }
          : undefined,
        locale: this._locale.locale,
      },
      optionalReferenceNumber: values['OptRef'],
      number: ShortId.generate(),
      notes: values['Anteckningar'],
      messageToCustomer: values['Meddelande till kund'],
      crossSellingProperty: await this.getCrossSellingProperty(),
      requested: this.props.requested,
      refundableUntil: this.props.event.refundableUntil,
    });
    this.props.onSubmit(document, resolve, reject);
  };

  private get addressFields() {
    const { t } = this._locale;
    const toggleState = () => this.setState((prev) => ({ showAddressFields: !prev.showAddressFields }));

    const button = (
      <div className="ui fluid button" onClick={toggleState} style={{ marginBottom: '1rem' }}>
        {this.state.showAddressFields ? t('Remove address') : t('Add an address')}
      </div>
    );

    return (
      <div style={{ margin: '2rem 0' }}>
        {button}
        {this.state.showAddressFields && (
          <AddressFields
            address={this.props.selectedCustomer?.address}
            showLabel
            hideCountryField
            hideInputIcons
            makeAllFieldsOptional
          />
        )}
      </div>
    );
  }

  private get messageFields() {
    const { t } = this._locale;
    const toggleState = () => this.setState((prev) => ({ showMessageFields: !prev.showMessageFields }));

    const button = (
      <div className="ui fluid button" onClick={toggleState} style={{ marginBottom: '1rem' }}>
        {this.state.showMessageFields ? t('Remove notes') : t('Add notes to the booking')}
      </div>
    );

    return (
      <div style={{ margin: '2rem 0' }}>
        {button}
        {this.state.showMessageFields && (
          <div>
            <Field label={t('Notes')}>
              <TextArea name="Anteckningar" resizable={false} placeholder={t('Notes')} />
            </Field>
            <Field label={t('Message to the customer')}>
              <TextArea
                name="Meddelande till kund"
                resizable={false}
                placeholder={t('The message will be visible in the e-mail')}
              />
            </Field>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { t } = this._locale;
    const { selectedCustomer, event, children, submitButtonText, requested } = this.props;

    // TODO: Changing the state will also reset input fields to selectedCustomer values even if an
    // input field was changed manually.. Solution: Input values must be put into state. #1948
    return (
      <Form onSubmit={this.createBooking} style={{ margin: 0 }}>
        <Field label={t('First name')}>
          <Input name="Förnamn" value={selectedCustomer?.firstname} rules={[Rules.NotEmpty()]} />
        </Field>
        <Field label={t('Last name')}>
          <Input name="Efternamn" value={selectedCustomer?.lastname} rules={[Rules.NotEmpty()]} />
        </Field>
        <Field label={t('Business name')}>
          <Input name="Företagsnamn" value={selectedCustomer?.businessName} placeholder={t('(optional)')} />
        </Field>
        <Field label={t('Email')}>
          <Input
            name="Email"
            placeholder="mail@domain.com"
            value={selectedCustomer?.email}
            rules={[Rules.NotEmpty(), Rules.IsEmailOrEmpty()]}
          />
        </Field>
        <Field label={t('Phone')}>
          <Input name="Telefon" value={selectedCustomer?.phoneNr} placeholder={t('(optional)')} />
        </Field>
        <Field label={t('Reference number')}>
          <Input name="OptRef" placeholder={t('(optional)')} rules={[Rules.MaximumLength(20)]} />
        </Field>
        {this.addressFields}
        {this.messageFields}
        <SelectPriceCategoriesFields
          key={event.id}
          occurance={event}
          onChange={(selectedPriceCategories) => this.setState({ selectedPriceCategories })}
          isBookingRequest={requested}
        />

        {children}

        <SubmitButton className="fluid green" style={{ marginTop: '1rem' }}>
          {submitButtonText}
        </SubmitButton>
      </Form>
    );
  }
}
