import Lodash from 'lodash';
import Moment from 'moment-timezone';
import React from 'react';
import { AdvancedDatePicker, AdvancedDatePickerType } from '../../../components/advancedDatePicker';
import { CategoryCheckboxes } from '../../../components/customer/activities/categoryCheckboxes';
import {
  Accordion,
  Consume,
  DeviceProvider,
  IDevice,
  Input,
  Loader,
  LocaleContext,
  MobxComponent,
  Platforms,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { IActivitySearchData } from './activitySearchProvider';

interface Props {
  searchData: Readonly<IActivitySearchData>;
}

export class ActivitiesSearchBar extends MobxComponent<Props> {
  private timer: NodeJS.Timer;

  private autorunDisposer: Function;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private async onDateChanged(date: Date, type: AdvancedDatePickerType, isSelectionFinished: boolean) {
    await this.props.searchData.setState({ currentDate: date, datePickerType: type });
    if (isSelectionFinished) {
      $('#calendar-popup').popup('hide');
      $('.ui.accordion').accordion('close', 0);
    }
  }

  private get periodText() {
    const { currentDate, datePickerType } = this.props.searchData;
    switch (datePickerType) {
      case 'year':
        return Lodash.startCase(Moment(currentDate).tz('Europe/Stockholm').format('YYYY'));
      case 'month':
        return Lodash.startCase(Moment(currentDate).tz('Europe/Stockholm').format('MMMM'));
      case 'date':
        return Lodash.startCase(Moment(currentDate).tz('Europe/Stockholm').format('D MMMM '));
      case 'time':
      default:
        return Moment(currentDate)
          .tz('Europe/Stockholm')
          .format('D MMM HH:mm ')
          .split(' ')
          .map(Lodash.capitalize)
          .join(' ');
    }
  }

  private get searchFilter() {
    const { t } = this._locale;
    const { size } = this.device;
    const { isLoading } = this.props.searchData;

    const padding = size === 'mobile' ? { margin: '0.5rem 0' } : {};
    const iconStyle = { fontSize: '19px', margin: '0 1rem' };

    return (
      <div className="ui item" style={padding}>
        <i className="search centered grey icon" style={iconStyle} />
        <Input
          name=""
          className="transparent"
          loading={isLoading}
          defaultValue=""
          onChange={this.saveFreeText}
          style={{ paddingLeft: '0em' }}
          type="text"
          placeholder={t('Search for a place or activity')}
        />
      </div>
    );
  }

  private get header() {
    const { t } = this._locale;
    const headerStyle =
      this.device.size === 'mobile' ? { fontSize: '2.4em', color: '#111826' } : { fontSize: '3em', color: '#fff' };
    return (
      <h1 className="ui center aligned header" style={headerStyle}>
        {t('components.customer.activities.activitiesSearchBar.headerOne')}&nbsp;
        <span style={{ color: Platforms.primaryColor }}>
          {t('components.customer.activities.activitiesSearchBar.headerTwo')}
        </span>
      </h1>
    );
  }

  private saveFreeText = (value: string) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.searchData.setState({ freeText: value });
    }, 1000);
  };

  private get calendar() {
    const { currentDate, datePickerType } = this.props.searchData;
    return (
      <AdvancedDatePicker
        onlyFutureDates
        enabledTypes={this.enabledCalendarTypes}
        type={datePickerType}
        date={currentDate}
        onChanged={this.onDateChanged.bind(this)}
      />
    );
  }

  private get enabledCalendarTypes(): AdvancedDatePickerType[] {
    return ['month', 'date'];
  }

  private get calendarFilter() {
    if (this.device.size === 'mobile') {
      return (
        <Accordion
          fluid
          compact
          title={
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', height: '38px' }}>
              <i
                style={{ fontSize: '19px', marginLeft: '10px', marginRight: '10px' }}
                className="calendar grey icon"
              ></i>
              <span style={{ flexGrow: 1 }}>{this.periodText}</span>
              <i style={{ marginRight: '10px' }} className="dropdown icon"></i>
            </div>
          }
        >
          <div style={{ margin: '-1px' }}>{this.calendar}</div>
        </Accordion>
      );
    }

    return (
      <React.Fragment>
        <a
          id="calendar-popup"
          style={{ display: 'flex', backgroundColor: 'white' }}
          className="item fluid active visible"
        >
          <i style={{ fontSize: '19px', margin: '0 1rem' }} className="calendar grey icon"></i>
          <span style={{ flexGrow: 1 }}>{this.periodText}</span>
          <i style={{ height: '19px', marginRight: '10px' }} className="dropdown icon"></i>
        </a>
        <div className="ui popup bottom center" style={{ maxWidth: '30rem', width: '35%' }}>
          <div className="ui one column">
            <div className="column">{this.calendar}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  private get searchLoader() {
    const { isLoading } = this.props.searchData;
    return <Loader color="#3ab399" time={1500} nrOfDotts={25} isLoading={isLoading} />;
  }

  componentDidMount() {
    $('#calendar-popup').popup({
      on: 'click',
      popup: $('.popup.bottom.center'),
      position: 'bottom center',
    });

    $('.ui.accordion').accordion();

    this.domElement.find('.ui.fluid.left.labeled.button').transition({ animation: 'fade in', duration: 1000 });
    this.props.searchData.search();
  }

  componentWillUnmount() {
    if (this.autorunDisposer) {
      this.autorunDisposer();
    }
  }

  render() {
    const { size } = this.device;

    if (size === 'mobile') {
      return (
        <div style={containerStyleMobile}>
          {this.header}
          <CategoryCheckboxes />
          {this.searchFilter}
          {this.calendarFilter}
          {this.searchLoader}
        </div>
      );
    }

    return (
      <div style={containerStyle}>
        <div style={activitiesSearchBarContainerStyle}>
          <div style={fieldsStyle}>
            {this.header}
            <div className="ui two item menu" style={{ maxWidth: '60rem' }}>
              {this.searchFilter}
              {this.calendarFilter}
            </div>
          </div>
          <CategoryCheckboxes />
          <div style={loaderContainerStyle}>{this.searchLoader}</div>
        </div>
      </div>
    );
  }
}

const containerStyleMobile: React.CSSProperties = {
  width: '100%',
  padding: '2rem',
};
const containerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',

  position: 'absolute',
  top: 0,
};

const fieldsStyle: React.CSSProperties = {
  width: '100%',
  padding: '5rem 15%',
  background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 45%, rgba(0,0,0,0.5) 55%, rgba(0,0,0,0) 100%)',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const activitiesSearchBarContainerStyle: React.CSSProperties = {
  position: 'relative',

  width: '100%',
  height: '100%',
  paddingTop: '20rem',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const loaderContainerStyle: React.CSSProperties = {
  width: '50%',
  maxWidth: '40rem',
  paddingTop: '1rem',

  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%) translateY(100%)',
};
