import * as React from 'react';
import {
  BookingDocument,
  Consume,
  Form,
  getAccessories,
  LocaleContext,
  MobxComponent,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { RefundComponent } from './refundComponent';

interface Props {
  booking: BookingDocument;
  amountPayedOnline: number;
  onSubmit: (refundAmount: number, resolve, reject) => void;
}

export class DeleteBookingForm extends MobxComponent<Props> {
  private _amountToBeRefunded: number;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private onSubmit = (_, resolve, reject) => {
    const refundAmount = this._amountToBeRefunded || 0;
    this.props.onSubmit(refundAmount, resolve, reject);
  };

  private onNewRefundAmount = (refundAmount: number) => {
    this._amountToBeRefunded = refundAmount;
  };

  private hasAccessories = () => {
    return getAccessories(this.props.booking.priceCategories, true).length > 0;
  };

  render() {
    const { t } = this._locale;
    return (
      <Form onSubmit={this.onSubmit}>
        <h3 className="ui header">{t('Remove booking')}</h3>
        <RefundComponent
          buttonText={t('components.dashboard.bookings.bookingItem.deleteBookingForm.buttonOne')}
          buttonClass="fluid inverted red"
          totalRefundAmount={this.props.booking.totalPrice}
          amountPayedOnline={this.props.amountPayedOnline}
          onNewRefundAmount={this.onNewRefundAmount}
          hasAccessories={this.hasAccessories()}
        />
      </Form>
    );
  }
}
