export const getDiscountPercentage = (percentage: number) => {
  if (checkDiscountBoundaries(percentage) !== '?') {
    if (percentage === 1) return '100';
    return (percentage * 100).toPrecision(2).split('.')[0];
  } else {
    return '?';
  }
};

export const getDiscountUsageLimit = (usageLimit: number | undefined, numberOfUses: number | undefined) => {
  let result = '';

  if (usageLimit) {
    if (typeof numberOfUses === 'number') {
      result += numberOfUses.toString();
    } else {
      result = '?';
    }
    result += `/${usageLimit}`;
  } else {
    return undefined;
  }
  return result;
};

export const checkDiscountBoundaries = (discountPercentage: number | string) => {
  if (discountPercentage < 0) return '?';
  if (discountPercentage > 1) return '?';

  return discountPercentage;
};
