import Lodash from 'lodash';
import { Types } from 'mongoose';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  ActivityTypeDocument,
  Checkbox,
  DestinationDocument,
  Dropdown,
  DropdownItem,
  OrganizationResource,
  PropertyDocument,
  Rules,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import BetaFunctionalitySign from '../../../betaFunctionalitySign';

interface props {
  destinations: DestinationDocument[];
  isTmeTicket: boolean | undefined;
  activitiesBoundToTicket: Types.ObjectId[] | undefined;
  updateOffer: (key: TUpdateableProperties, value?: boolean | Types.ObjectId[] | number) => void;
}

type TUpdateableProperties = 'isTmeTicket' | 'associatedActivities' | 'visitorCapacity';

export const IsTicketField: React.FC<props> = ({ destinations, isTmeTicket, activitiesBoundToTicket, updateOffer }) => {
  const { t, tt } = useLocale();

  const [activitiesThatCanAssociateWithTickets, setActivitiesThatCanAssociateWithTickets] = useState<
    ActivityTypeDocument[] | undefined
  >(undefined);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);

  useEffect(() => {
    if (destinations.length > 0 && isTmeTicket) {
      updateAvailableActivities();
    }
  }, [destinations, isTmeTicket]);

  const updateAvailableActivities = async () => {
    setIsLoadingActivities(true);
    getAllActivitiesThatTicketCanAssociateWith(destinations)
      .then((res) => {
        setActivitiesThatCanAssociateWithTickets(res);
      })
      .finally(() => setIsLoadingActivities(false));
  };

  const updateTicketProperties = (key: TUpdateableProperties, value: boolean | string) => {
    switch (key) {
      case 'isTmeTicket':
        updateOffer('isTmeTicket', value as boolean);
        updateOffer('visitorCapacity', 10000);
        return;
      case 'associatedActivities':
        const activitiesToSave = (value as string).split(',').map((s) => Types.ObjectId(s));
        updateOffer('associatedActivities', activitiesToSave);
        return;
    }
  };

  return (
    <Accordion
      title={
        <h4 className="ui header">
          <i className="dropdown icon" /> {t('Ticket settings')}
          <BetaFunctionalitySign />
        </h4>
      }
    >
      <Checkbox
        disabled={activitiesThatCanAssociateWithTickets?.length === 0}
        checked={isTmeTicket}
        onCheckedOrUncheked={(val) => updateTicketProperties('isTmeTicket', val)}
        label={t('Make this offer a ticket')}
      />
      <br></br>
      {isLoadingActivities && <div className="ui mini loader active inline" />}
      {!isLoadingActivities && activitiesThatCanAssociateWithTickets !== undefined && isTmeTicket && (
        <Dropdown
          disabled={!isTmeTicket}
          fluid
          multiple
          onChange={(val) => updateTicketProperties('associatedActivities', val)}
          placeholder={t('Choose activities that should be included in ticket')}
          rules={[Rules.NotEmpty()]}
          value={activitiesBoundToTicket?.join(',') || ''}
          name="Activities"
        >
          {activitiesThatCanAssociateWithTickets?.map((a) => (
            <DropdownItem key={a.id} value={a.id}>
              {tt(a.title)}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Accordion>
  );
};

const getAllActivitiesThatTicketCanAssociateWith = async (destinationsAvailable: DestinationDocument[]) => {
  const allActivities: ActivityTypeDocument[] = await getAllActivitiesFromDestinations(destinationsAvailable);
  const activitiesThatAreNotTickets = allActivities.filter((a) => !a.isTmeTicket);
  return activitiesThatAreNotTickets;
};

const getAllActivitiesFromDestinations = async (destinations: DestinationDocument[]) => {
  const _organizationResource = new OrganizationResource();
  const propertiesFromDestinations: PropertyDocument[] = Lodash.uniq(
    Lodash.flatten(destinations.map((d) => d.connectedProperties.map((p) => p))),
  );
  const organizationsFromProperties: Types.ObjectId[] = Lodash.uniq(
    propertiesFromDestinations.map((p) => p.organization),
  );

  const activitiesFromOrganizations: ActivityTypeDocument[] = Lodash.flattenDeep(
    await Promise.all(organizationsFromProperties.map((o) => _organizationResource.getActivityTypesForOrganization(o))),
  );

  return activitiesFromOrganizations;
};
