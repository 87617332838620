import React from 'react';
import { ActivityOccuranceDocument } from '../../../_dependencies';
import { useLocale } from '../../../_locales';

interface Props {
  occurance: ActivityOccuranceDocument;
}

export const MinVisitorLabel = (props: Props) => {
  const { minVisitors } = props.occurance;
  const { t } = useLocale();

  if (!minVisitors) return null;

  return (
    <h5 className="ui header">
      {t('In order to book you must select at least')} {minVisitors} {t('visitors')}
    </h5>
  );
};
