import Moment from 'moment-timezone';
import React, { ReactNode, useContext, useState } from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  BookingDocument,
  BookingResource,
  Button,
  GlobalsContext,
  MarketplaceContext,
  NotificationResource,
  OrganizationDocument,
  PropertyDocument,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { DeleteButton } from '../../deleteButton';
import { CalendarStore } from '../store';

interface Props {
  event: ActivityOccuranceDocument;
  store: CalendarStore;
  bookings: ReactNode[];
}

export const BookingRequestedViews = ({ event, store, bookings }: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const globals = useContext(GlobalsContext);
  const marketplace = useContext(MarketplaceContext);
  const { t, tt } = useLocale();

  // Remove the buttons from the booking and instead use the buttons here to reject or deny a booking request.
  // We keep the old buttons as those can still be used to reject and resend booking request mail.

  const postAccept = async () => {
    try {
      const requestedBooking = event.bookings.find((b) => b.requested);
      if (requestedBooking == null) {
        throw new Error('No booking was found');
      }
      requestedBooking.requested = false;
      await createNotification('accept', requestedBooking);
      const marketplaceRef = await marketplace.createManualOrderWithBooking(
        requestedBooking,
        globals.session.currentOrganizationId.toString(),
      );

      const updatedBooking = new BookingResource().createDocument(requestedBooking);
      updatedBooking.marketplaceRef = marketplaceRef;
      await new BookingResource().updateDocument(updatedBooking);
      store.reload();
    } catch (err) {
      console.error(err);
    }
  };

  const createNotification = async (type: 'deny' | 'accept', booking: BookingDocument) => {
    const organization = globals.session.currentOrganization as OrganizationDocument;
    const occurance = await new ActivityOccuranceResource().getWithPopulatedProperty(booking.occurance);
    if (!occurance) {
      throw new Error(`No occurance was found that matches id ${booking.occurance}`);
    }

    const resource = new NotificationResource();
    const notification = resource.createNotification(
      booking.crossSellingProperty!._id,
      `/dashboard/schedule?date=${Moment(occurance.start).tz('Europe/Stockholm').format('YYMMDD')}&view=week`,
      {
        translationKey: type == 'accept' ? 'Booking request was accepted by' : 'Booking request was denied by',
        data: organization.name,
      },
      {
        icon: 'star outline icon',
        content: {
          translationKey: 'activity',
          data: tt(occurance.title),
        },
      },
      {
        icon: 'map pin icon',
        content: {
          translationKey: 'components.dashboard.bookings.bookingItem.bookingInfo.contentOne',
          data: tt(occurance.property.name),
        },
        link: `/dashboard/properties/details/${occurance.property._id}`,
      },
      {
        icon: 'wait icon',
        content: {
          translationKey: 'Start time',
          data: Moment(occurance.start).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm'),
        },
      },
      {
        icon: 'user icon',
        content: {
          translationKey: 'Customer',
          data: `${booking.customer.firstname} ${booking.customer.lastname}`,
        },
      },
    );
    await resource.updateDocument(notification);
  };

  const handleRequest = async (response: 'accept' | 'deny') => {
    try {
      setIsDeleting(true);
      const requestedBooking = event.bookings.find((b) => b.requested);

      if (response === 'accept' && requestedBooking?.requested) {
        await postAccept();
      }
      if (response === 'deny' && requestedBooking) {
        await createNotification(response, requestedBooking);
        await new BookingResource().delete(requestedBooking.id);
        await new ActivityOccuranceResource().delete(event.id);
        store.deselectEvent();
        store.removeFromDowloadedEvents(event);
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (store.isUserAllowedToEditEvent()) {
    return (
      <div>
        <h3>{t('booking-request')}</h3>
        {bookings}
        {!event.hasCustomerRequestedBooking && (
          <div style={{ marginTop: '2rem' }}>
            <Button
              className="small fluid primary"
              style={{ margin: '1rem 0' }}
              onClick={() => handleRequest('accept')}
            >
              {t('Accept')}
            </Button>
            <DeleteButton fluid deleting={isDeleting} onConfirmed={() => handleRequest('deny')}>
              {t('Deny')}
            </DeleteButton>
          </div>
        )}
      </div>
    );
  }

  const property = event.property as unknown as PropertyDocument;
  return (
    <div>
      <h4>
        {t('booking-request-is-placed')}&nbsp;
        {tt(property.name)}
      </h4>
      <DeleteButton fluid deleting={isDeleting} onConfirmed={() => handleRequest('deny')}>
        {t('remove')} {t('booking-request').toLowerCase()}
      </DeleteButton>
    </div>
  );
};
