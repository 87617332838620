import React from 'react';
import Lodash from 'lodash';
import Moment from 'moment-timezone';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import {
  ActivityOccuranceDocument,
  ActivityTypeDocument,
  BookingDocument,
  BookingResource,
  DestinationResource,
  KosmicComponent,
  OrganizationDocument,
  OrganizationResource,
  tt,
} from '../../../../_dependencies';
import { Consume, LocaleContext } from '../../../../_dependencies';
import { StatisticsRow } from './index';
import { AHLocaleContext } from '../../../../_locales';

interface OrganizationSegment {
  organization: OrganizationDocument;
  bookingGroups: BookingGroupSegment[];
}

interface BookingGroupSegment {
  activity: ActivityTypeDocument;
  statisticsRows: StatisticsRow[];
}

interface Props {
  bookings: BookingDocument[];
  fromDate?: Date;
  toDate?: Date;
  generateStatisticRows: (bookings: BookingDocument[]) => StatisticsRow[];
}

interface State {
  PDFlist: OrganizationSegment[];
}

export class PDFPreview extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  constructor(props: Props) {
    super(props);
    this.state = { PDFlist: [] };
  }

  componentDidMount() {
    this.generatePdfContent();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.bookings !== this.props.bookings) {
      this.generatePdfContent();
    }
  }

  private async generatePdfContent() {
    const destinationResource = new DestinationResource();
    const bookingResource = new BookingResource();

    const destinationOrganizations = await destinationResource.getOrganizationsInDestination(
      this.globals.session.currentOrganizationId,
    );

    // Get all crossSelling bookings
    const crossSellingBookings: BookingDocument[] = this.props.bookings.filter(
      (booking) => booking.crossSellingProperty,
    );

    // Gets all uniq organizations
    const filteredOrganizations = Lodash.uniq(
      crossSellingBookings.map((booking) => booking.crossSellingProperty!.organization),
    );

    // Gets all organizationSegments with populated Organizations
    const orgSegments: OrganizationSegment[] = await Promise.all(
      filteredOrganizations.map(async (orgId) => {
        const populatedOrg = destinationOrganizations.find((org) => org._id == orgId);

        if (populatedOrg) {
          return { organization: populatedOrg, bookingGroups: [] };
        } else {
          const orgResource = new OrganizationResource();
          const collectedOrg: OrganizationDocument = await orgResource.find({ _id: orgId })[0];
          return { organization: collectedOrg[0], bookingGroups: [] };
        }
      }),
    );

    // Bookings with occurance->activity populated
    const bookingsWithOccuranceAndActivity = await bookingResource.getBookingsWithOccuranceAndActivityPopulated(
      crossSellingBookings.map((booking) => booking._id),
      true,
    );

    // Formated with organizationsSegments->BookingGroupSegments
    const orgSegmentWithBookingGroups: OrganizationSegment[] = orgSegments.map((orgSegment) => {
      // Gets all bookings for current itterated organization
      const bookingsForOrg = bookingsWithOccuranceAndActivity.filter(
        (booking) => booking.crossSellingProperty?.organization == orgSegment.organization._id,
      );

      // Gets all activityTypes for itterated organization
      const activitiesForOrg: ActivityTypeDocument[] = bookingsForOrg.map((booking) => {
        const occurance = booking.occurance as any as ActivityOccuranceDocument;
        return occurance.originatingActivity! as any as ActivityTypeDocument;
      });

      // Gets uniq activityTypes
      const uniqActivitiesForOrg: ActivityTypeDocument[] = [];

      activitiesForOrg.forEach((newActivity) => {
        let isNew = true;
        uniqActivitiesForOrg.forEach((uniqActivity) => {
          if (newActivity.id == uniqActivity.id) {
            isNew = false;
          }
        });
        isNew && uniqActivitiesForOrg.push(newActivity);
      });

      // Gets all BookingGroupSegments for each activityType
      const BookingGroupSegment: BookingGroupSegment[] = uniqActivitiesForOrg.map((activity) => {
        // Filters all bookings with itterated activityType
        const bookingsWithActivityType = bookingsForOrg.filter((booking) => {
          const occurance = booking.occurance as any as ActivityOccuranceDocument;
          const bookingActivityType = occurance.originatingActivity! as any as ActivityTypeDocument;
          return activity._id == bookingActivityType._id;
        });

        // Generate statisticRow from bookingList
        const statisticsRows: StatisticsRow[] = this.props.generateStatisticRows(bookingsWithActivityType);
        return { statisticsRows, activity };
      });

      return { organization: orgSegment.organization, bookingGroups: BookingGroupSegment };
    });

    this.setState({
      PDFlist: orgSegmentWithBookingGroups,
    });
  }

  private renderSumRow(statisticsRows: StatisticsRow[]) {
    let totalPrice = Lodash.sumBy(statisticsRows, (row) => row.price);
    totalPrice = Lodash.round(totalPrice);

    let totalVat = Lodash.sumBy(statisticsRows, (row) => row.vatAmount);
    totalVat = Lodash.round(totalVat);

    const { t } = this._locale;

    return (
      <View style={{ ...styles.statisticRowContainer, ...styles.sumRowContainer }} wrap={false}>
        <View style={{ ...styles.statisticRowItem, ...styles.sumRow }}>
          <Text style={styles.sumText}>{t('Summation') + ':'}</Text>
        </View>
        <View style={{ ...styles.statisticRowItem, ...styles.sumRow }}>
          <Text></Text>
        </View>
        <View style={{ ...styles.statisticRowItem, ...styles.sumRow }}>
          <Text style={styles.sumText}>{totalPrice + ' ' + t('SEK')}</Text>
        </View>
        <View style={{ ...styles.statisticRowItem, ...styles.sumRow }}>
          <Text style={styles.sumText}>{totalVat + ' ' + t('SEK')}</Text>
        </View>
        <View style={{ ...styles.statisticRowItem, ...styles.sumRow }}>
          <Text></Text>
        </View>
      </View>
    );
  }

  private renderStatisticRows(statisticsRows: StatisticsRow[]) {
    const { t } = this._locale;

    return (
      <View style={styles.statisticView}>
        <View style={styles.headerRowContainer} wrap={false}>
          <View style={styles.headerRowItem}>
            <Text style={styles.headerRowText}>{t('Booking number')}</Text>
          </View>
          <View style={styles.headerRowItem}>
            <Text style={styles.headerRowText}>{t('Reference number')}</Text>
          </View>
          <View style={styles.headerRowItem}>
            <Text style={styles.headerRowText}>{t('Price')}</Text>
          </View>
          <View style={styles.headerRowItem}>
            <Text style={styles.headerRowText}>{t('VAT-Rate') + ' (' + statisticsRows[0].vatRate + '%' + ')'}</Text>
          </View>
          <View style={styles.headerRowItem}>
            <Text style={styles.headerRowText}>{t('Date')}</Text>
          </View>
        </View>
        {statisticsRows.map((statisticsRow) => {
          let { date, referenceNr } = statisticsRow;
          const createdAtString = date.getDate() + '/' + (date.getMonth() + 1) + '-' + date.getFullYear();
          referenceNr = referenceNr || '---';

          return (
            <View style={styles.statisticRowContainer} wrap={false} key={statisticsRow.bookingNumber}>
              <View style={styles.statisticRowItem}>
                <Text>{statisticsRow.bookingNumber}</Text>
              </View>
              <View style={styles.statisticRowItem}>
                <Text>{referenceNr}</Text>
              </View>
              <View style={styles.statisticRowItem}>
                <Text>{statisticsRow.price + ' ' + t('SEK')}</Text>
              </View>
              <View style={styles.statisticRowItem}>
                <Text>{statisticsRow.vatAmount.toFixed(2) + ' ' + t('SEK')}</Text>
              </View>
              <View style={styles.statisticRowItem}>
                <Text>{createdAtString}</Text>
              </View>
            </View>
          );
        })}
        {this.renderSumRow(statisticsRows)}
      </View>
    );
  }

  private renderActivitySegment(bookingGroups: BookingGroupSegment[]) {
    const { tt } = this._locale;

    return bookingGroups.map((bookingGorupSegment) => {
      return (
        <View style={styles.activityView} key={bookingGorupSegment.activity.id}>
          <Text key={bookingGorupSegment.activity.id} style={styles.activityText}>
            {tt(bookingGorupSegment.activity.title)}
          </Text>
          {this.renderStatisticRows(bookingGorupSegment.statisticsRows)}
        </View>
      );
    });
  }

  private get renderOrganizationSegments() {
    return this.state.PDFlist.map((orgSegment) => {
      return (
        <View style={styles.organizationView} key={orgSegment.organization.id}>
          <Text style={styles.organizationText} key={orgSegment.organization.id}>
            {orgSegment.organization.name}
          </Text>
          {this.renderActivitySegment(orgSegment.bookingGroups)}
        </View>
      );
    });
  }

  private get filterspan() {
    const { fromDate, toDate } = this.props;
    if (fromDate && toDate) {
      return (
        <Text style={styles.spanTimeText}>
          {Moment(fromDate).format('L')} - {Moment(toDate).format('L')}
        </Text>
      );
    }
  }

  private get generatePdf() {
    const { t } = this._locale;
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View>{this.filterspan}</View>
          <View>
            <Text style={styles.pageTitleText}>{t('Invoice documentation')}</Text>
          </View>

          {this.renderOrganizationSegments}
        </Page>
      </Document>
    );
  }

  public render() {
    if (this.state.PDFlist.length) {
      return (
        <div style={pdfContatiner}>
          <PDFViewer height="900" style={{ marginTop: '1em' }}>
            {this.generatePdf}
          </PDFViewer>
        </div>
      );
    }
    return <></>;
  }
}

const styles = StyleSheet.create({
  page: {
    padding: '30px',
  },
  pageTitleText: {
    fontSize: 25,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    marginBottom: '10px',
  },
  organizationText: {
    marginBottom: '5px',
    fontSize: 18,
    fontFamily: 'Times-Roman',
  },
  activityText: {
    marginBottom: '5px',
    fontSize: 14,
    fontFamily: 'Times-Roman',
  },
  statisticRowText: {
    marginBottom: '3px',
    fontSize: 10,
    fontFamily: 'Times-Roman',
  },
  spanTimeText: {
    fontSize: 10,
  },
  organizationView: {
    paddingTop: '7px',
    paddingBottom: '4px',
    borderTop: '1px solid grey',
    marginTop: '10px',
  },
  activityView: {
    paddingLeft: '10px',
    marginBottom: '7px',
    marginTop: '5px',
  },
  statisticView: {
    paddingLeft: '10px',
  },
  headerRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderBottom: '1px solid grey',
    fontSize: 9,
    paddingBottom: '2px',
    fontWeight: 'bold',
  },
  headerRowItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    textAlign: 'left',
  },
  headerRowText: {
    fontFamily: 'Times-Bold',
    fontWeight: 'heavy',
  },
  statisticRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderBottom: '1px solid grey',
    fontSize: 8,
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  statisticRowItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    textAlign: 'left',
  },
  sumRow: {
    marginTop: '2px',
  },
  sumRowContainer: {
    borderBottom: '0px',
    marginTop: '5px',
  },
  sumText: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'demibold',
  },
});

const pdfContatiner: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};
