import * as React from 'react';
import { ChangableComponent } from './changable';
import { Icon } from './icon';
import { TextFormatRule } from './rules';

declare let $: any;

export class Dropdown extends ChangableComponent<
  string,
  {
    /** A list of rules that applies to this component */ rules?: TextFormatRule[];
    /** An icon to display inside the input */ icon?: Icon;
    /** A placeholder shown inside the input when otherwise empty */ placeholder?: string;
    /** Makes the dropdown take up the whole width of it's parent container */ fluid?: boolean;
    /** Multiple items can be selected */ multiple?: boolean;
    /** if items can be filtered through search  */ searchable?: boolean;
    /** Override style properties */ style?: object;
  }
> {
  /** Shorthand for rendering a dropdown item */
  public static Item(props: { className?: string; value?: string | number }) {
    return <DropdownItem {...props} />;
  }

  private _preventOnChange = false;

  updateComponentValue(value: string) {
    return value;
  }

  private get inputProps() {
    // Set default properties
    const props = {
      disabled: this.disabled || this.props.disabled,
      type: 'hidden',
      name: this.props.name,
      placeholder: this.props.placeholder,
    };

    // TODO: Add support for more advanced rules

    return props;
  }

  private get iconComponent() {
    if (this.props.icon) {
      return <i className={this.props.icon + ' ui left icon'} />;
    }
    return undefined;
  }

  private syncValue() {
    if (this.props.value) {
      this._preventOnChange = true;
      (this.domElement as any).dropdown('refresh'); // Check for changed children
      (this.domElement as any).dropdown('set exactly', this.props.value.split(',')); // Set the correct value
      this._preventOnChange = false;
    }
  }

  componentDidMount() {
    (this.domElement as any).dropdown({
      onChange: (value) => {
        if (this._preventOnChange) return;
        this.updateComponentValue(value);
      },
    });
    this.syncValue();
    this.sendValidationRulesToFormComponent(); //TODO: Extending fat arrow methods does not work it seems, and having normal methods mount() is broken for some other reason
  }

  componentDidUpdate() {
    this.syncValue();
  }

  render() {
    const style = this.props.style || {};
    const className =
      'ui selection dropdown ' +
      (this.props.className || '') +
      (this.props.fluid ? 'fluid ' : '') +
      (this.props.multiple ? 'multiple ' : '') +
      (this.props.searchable ? 'search ' : '') +
      (this.loading || this.props.loading ? 'loading ' : '') +
      (this.disabled || this.props.disabled ? 'disabled ' : '');

    return (
      <div className={className} style={style}>
        <input {...this.inputProps} />
        {this.iconComponent}
        <i className="dropdown icon" />
        <div className="default text">{this.props.placeholder}</div>
        <div className="menu">{this.props.children}</div>
      </div>
    );
  }
}

export class DropdownItem extends React.Component<{ className?: string; value?: string | number }, any> {
  render() {
    return (
      <div className={'item ' + (this.props.className || '')} data-value={this.props.value || this.props.children}>
        {this.props.children || this.props.value}
      </div>
    );
  }
}
