import * as React from 'react';
import { ActivityOccuranceDocument } from '../../../_dependencies';
import { CalendarEventComponentProperties, CalendarEventProperties, View } from '../utils/types';
import BookingRequestForm from '../views/bookingRequestForm';
import { CreateNewActivityForm } from '../views/createNewActivityForm';
import {
  DefaultEventComponent,
  DefaultEventComponentCollection,
  DefaultEventDetailsComponent,
} from './defaultEventComponentCollection';

export class NewEventComponentCollection extends DefaultEventComponentCollection {
  getEventProperties(event: ActivityOccuranceDocument, isSelected: boolean): CalendarEventProperties {
    const results = super.getEventProperties(event, isSelected);

    results.className = 'ui raised loading card';
    results.style.background = 'darkorange';
    results.style.color = 'white';
    results.style.opacity = 0.7;
    results.style.marginTop = '0em !important';

    return results;
  }

  getEventComponent(view: View, props: any) {
    return <DefaultEventComponent {...props} />;
  }

  getEventDetailsComponent(props: CalendarEventComponentProperties<any>) {
    return <NewEventDetailsComponent {...props} />;
  }
}

class NewEventDetailsComponent extends DefaultEventDetailsComponent<ActivityOccuranceDocument> {
  render() {
    return (
      <DefaultEventDetailsComponent {...(this.props as any)}>
        {this.props.store.isUserAllowedToEditEvent() ? (
          <CreateNewActivityForm event={this.props.event} store={this.props.store} />
        ) : (
          <BookingRequestForm event={this.props.event} store={this.props.store} />
        )}
      </DefaultEventDetailsComponent>
    );
  }
}
