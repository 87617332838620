import { GiftcardStatusResponse, RetrieveGiftcardStatusFn } from './payments.types';
import { Fetcher } from './utilities';

export const giftcardStatus =
  (fetcher: Fetcher): RetrieveGiftcardStatusFn =>
  async ({ externalId }) => {
    const res = await fetcher(`/function/retrieve-giftcard-information?externalId=${externalId}`, {
      method: 'GET',
    });

    const data: GiftcardStatusResponse = await res.json();
    switch (res.status) {
      case 200:
        return data;
      default:
        throw data;
    }
  };
