import React from 'react';
import { TranslatableText, TranslatableTextField } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface IOfferDescriptionFieldProps {
  description: TranslatableText | string;
  setOfferDescription: (key: 'description', value: TranslatableText) => void;
}

export const OfferDescriptionField = ({ setOfferDescription, description }: IOfferDescriptionFieldProps) => {
  const { t } = useLocale();

  return (
    <TranslatableTextField
      textarea
      label={t('Description')}
      name="Beskrivning"
      value={description}
      placeholder={t('Description')}
      onChange={(value) => setOfferDescription('description', value)}
    />
  );
};
