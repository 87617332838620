import Lodash from 'lodash';
import Moment from 'moment-timezone';
import { observable, computed, action, ObservableMap } from 'mobx';
import { GiftCardDocument, MarketplaceResource, PaymentResource } from '../../../_dependencies';

export interface SoldGiftCardItem {
  /** The gift card */
  document: GiftCardDocument;
  /** Represents how much the customer has paid towards the value of the gift card (SEK) */
  paidAmount?: number;
  /**
   * The value of the gift card (SEK) that is still possible to spend, assuming there are uses left
   * For legacy cards this is the same as the giftCard.value, otherwise it is calculated and set
   * using marketplace function
   */
  usableValue?: number;
  /** If the gift card is usable (giltligt) */
  isUsable?: boolean;
  /** If the gift card is expired */
  isExpired?: boolean;
}

export class SoldGiftCardsStoreSingleton {
  @observable private _data: ObservableMap<SoldGiftCardItem> = new ObservableMap<SoldGiftCardItem>({});

  @computed public get giftCards(): GiftCardDocument[] {
    return this._data.values().map((value) => value.document);
  }

  @action public loadGiftCards = (giftCards: GiftCardDocument[]) => {
    this._data.clear();
    Lodash.each(giftCards, (giftCard) => this._data.set(giftCard.id, { document: giftCard, paidAmount: undefined }));
  };

  @action public removeLoadedGiftCard = (giftCardId: string) => {
    this._data.delete(giftCardId);
  };

  @action public getGiftCardInformation = async (giftCardId: string) => {
    const data = this._data.get(giftCardId) as SoldGiftCardItem;
    data.paidAmount = await this.getAmountPaidTowardsGiftCard(data.document);
    data.usableValue = await this.getUsableAmountLeftOnGiftCard(data.document);
    data.isExpired = Moment().isAfter(data.document.expirationDate, 'day');
    data.isUsable = !data.isExpired && !!data.document.usesLeft && data.usableValue > 0;

    return data;
  };

  public getData = (giftCardId: string) => {
    return this._data.get(giftCardId);
  };

  private async getAmountPaidTowardsGiftCard(giftCard: GiftCardDocument) {
    // Check the payment status in the marketplace
    const paymentStatus = await new MarketplaceResource().getPaidAmountForProduct(giftCard.id);

    // The gift card was not a marketplace product
    if (paymentStatus.legacy) {
      return new PaymentResource().getPayedAmountOfGiftCard(giftCard.id);
    }

    // If the gift card is a product in the marketplace, calculate the total amount payed towards it
    const totalAmount = [
      paymentStatus.amountPaidManually,
      paymentStatus.amountPaidWithStripe,
      paymentStatus.amountPaidWithGiftCard,
    ].reduce((sum, x) => sum + x, 0);

    return totalAmount;
  }

  private async getUsableAmountLeftOnGiftCard(giftCard: GiftCardDocument) {
    // Check the usage of the gift card in the marketplace
    // will throw if not used
    try {
      const usageAsPaymentMethodOfGiftcard = await new MarketplaceResource().getGiftcardPaymentStatus(giftCard.number);
      return (giftCard.value || 0) - usageAsPaymentMethodOfGiftcard.amountUsed / 100;
      // FIXME: if the marketplace throws for any other reason the value will be TO MUCH MAN
    } catch {
      // If no usage found in the marketplace the usable value is the same as the value stored in the
      // gift card document
      return giftCard.value || 0;
    }
  }

  private static _instance: SoldGiftCardsStoreSingleton;
  public static get Instance() {
    if (!this._instance) {
      this._instance = new this();
    }
    return this._instance;
  }
}
export const SoldGiftCardsStore = SoldGiftCardsStoreSingleton.Instance;
