import * as React from 'react';
import {
  BookingResource,
  Consume,
  CustomerDocument,
  Field,
  Form,
  Input,
  KosmicComponent,
  Loader,
  LocaleContext,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';

interface Props {
  onSelectedCustomer: (selectedCustomer: CustomerDocument) => void;
  onNewList?: (filteredCustomers: CustomerDocument[]) => void;
  contentUpdate?: () => void;
}

interface State {
  filteredCustomers: CustomerDocument[];
  queryString: string;
  isLoading: boolean;
}

export class CustomerSearchForm extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private searchTimer: ReturnType<typeof setTimeout> | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      filteredCustomers: [],
      queryString: '',
      isLoading: false,
    };
  }

  private startSearchCountdown = (query: string) => {
    this.setState({ queryString: query, isLoading: true }, () => {
      this.props.contentUpdate && this.props.contentUpdate();
    });

    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      this.findCustomers();
      this.searchTimer = undefined;
    }, 1000);
  };

  private findCustomers = async () => {
    const bookingResource = new BookingResource();
    const filteredCustomers = await bookingResource.getFilteredCustomerList(this.state.queryString);

    this.setState({ filteredCustomers, isLoading: false }, () => {
      this.props.onNewList && this.props.onNewList(this.state.filteredCustomers);
      this.props.contentUpdate && this.props.contentUpdate();
    });
  };

  private get customerList() {
    return this.state.filteredCustomers.map((customer) => {
      const key = [customer.firstname, customer.lastname, customer.businessName, customer.email].join();

      const adressExists =
        !!customer.address &&
        !!customer.address.row1.length &&
        !!customer.address.postNr.length &&
        !!customer.address.country.length;

      return (
        <div key={key} className="ui fluid card">
          <div style={{ display: 'flex' }} className="content">
            <div>
              <h3>
                {customer.firstname} {customer.lastname}{' '}
                {customer.businessName ? '(' + customer.businessName + ')' : ''}
              </h3>
              <div className="description">
                <div className="ui list">
                  <div className="item">
                    <i style={{ marginRight: '4px' }} className="icon envelope outline"></i>
                    {customer.email}
                  </div>
                  {customer.phoneNr && (
                    <div className="item">
                      <i style={{ marginRight: '4px' }} className="icon phone"></i>
                      {customer.phoneNr}
                    </div>
                  )}
                  {adressExists && (
                    <div className="item">
                      <i style={{ marginRight: '4px' }} className="icon home"></i>
                      {customer.address!.row1}, {customer.address!.postOrt}, {customer.address!.country}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
              <button className="ui primary button" onClick={() => this.props.onSelectedCustomer(customer)}>
                Välj
              </button>
            </div>
          </div>
        </div>
      );
    });
  }

  private get renderLoader() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Loader width="10em" color="black" nrOfDotts={10} alwaysLoad isLoading={true} time={1000}></Loader>
      </div>
    );
  }

  public render() {
    const { t } = this._locale;

    return (
      <div>
        <Form style={{ marginBottom: '2em' }}>
          <Field label={t('Customer information')}>
            <Input
              name="searchQuery"
              onChange={this.startSearchCountdown}
              value={this.state.queryString}
              placeholder={t('Search text')}
            ></Input>
          </Field>
        </Form>
        {this.state.isLoading ? this.renderLoader : this.customerList}
        {this.state.queryString.length && !this.state.isLoading && !this.state.filteredCustomers.length ? (
          <p>{t('No results...')}</p>
        ) : undefined}
      </div>
    );
  }
}
