import * as React from 'react';
import Moment from 'moment-timezone';
import {
  Provider,
  IStateSetter,
  BadgeDocument,
  BadgeResource,
  OrganizationDocument,
  OrganizationResource,
} from '../_dependencies';

/**
 * BadgeCache sets a max age that the cache
 * should be used for and distributes badges
 */
interface BadgeCache {
  maxage: Moment.Moment;
  badges: Array<BadgeDocument>;
}

/** Exported provider api */
export interface IBadge extends IStateSetter<IBadge> {
  /** Gets all badges */
  get: () => Promise<BadgeDocument[]>;
  /** Creates a badge */
  create: (badge: BadgeDocument) => Promise<string>;
  /** Assign badges to organization */
  assign: (organization: OrganizationDocument, badges: string[]) => Promise<string>;
  /** Filter out a single organizations badges */
  filter: (organization: OrganizationDocument) => BadgeDocument[];
  /** Remove a badge */
  remove: (badge: BadgeDocument) => Promise<boolean>;
}

/** BadgeProvider: Used as a single source of truth for badges with a timed cache*/
export class BadgeProvider extends Provider<IBadge> {
  /** Declares the context to use */
  public static Context = React.createContext<IBadge>({} as any);

  /** Cached badges */
  private _cache: BadgeCache;

  /** Exposes the context to use */
  protected use(): React.Context<IBadge> {
    return BadgeProvider.Context;
  }

  /** Declares the initial state */
  protected initialState(): IBadge {
    return {
      get: this.badges.bind(this),
      assign: this.assign.bind(this),
      filter: this.filter.bind(this),
      create: this.createBadge.bind(this),
      remove: this.removeBadge.bind(this),
      setState: this.setState.bind(this),
    };
  }
  /** Initialize provider */
  public async componentDidMount() {
    await this.createCache();
  }

  /** Creates a badge */
  private async createBadge(badge: BadgeDocument) {
    const id = await new BadgeResource().updateDocument(badge);
    await this.createCache();
    return id;
  }

  private async removeBadge(badge: BadgeDocument) {
    const status = await new BadgeResource().delete(badge.id);
    await this.createCache();
    return status;
  }

  /** Assign badges to organization */
  private async assign(org: OrganizationDocument, badges: string[]) {
    org.badges = badges;
    return await new OrganizationResource().updateDocument(org);
  }

  /** Filter by organization */
  private filter(org: OrganizationDocument) {
    if (!this._cache) {
      throw new Error('BadgeProvider: Cache does not exist');
    }
    if (!org.badges) {
      return [];
    }
    return this._cache.badges.filter((badge) => org.badges.indexOf(badge.id) !== -1);
  }

  /** Get all badges from provider */
  private async badges() {
    // Check if cache exists
    if (this._cache) {
      // Check age of cache
      if (Moment().isBefore(this._cache.maxage)) {
        // Cache is still valid
        return this._cache.badges;
      } else {
        // Cache needs to be updated
        const cache = await this.createCache();
        return cache.badges;
      }
    } else {
      // Create cache and return badges
      const cache = await this.createCache();
      return cache.badges;
    }
  }

  /** Creates a cache and returns it */
  private async createCache() {
    const resource = new BadgeResource();
    const badges = await resource.find({});
    const cache: BadgeCache = {
      maxage: Moment().add(5, 'minutes'),
      badges,
    };
    this._cache = cache;
    return cache;
  }
}
