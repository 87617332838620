import Lodash from 'lodash';
import { computed } from 'mobx';
import * as React from 'react';
import { ActivityOccuranceDocument, MobxComponent, UserDocument } from '../../../../_dependencies';
import { ListItem } from './listItem';

interface Props {
  emptyListMessage: string;
  employees: UserDocument[];
  event: ActivityOccuranceDocument;
  selected: UserDocument[];
  onSelected: (employee: UserDocument) => void;
  onDeselected: (employee: UserDocument) => void;
}
export class List extends MobxComponent<Props> {
  private onSelected = (employee: UserDocument) => this.props.onSelected(employee);
  private onDeselected = (employee: UserDocument) => this.props.onDeselected(employee);

  @computed private get sortedEmployeesList() {
    return Lodash.sortBy(this.props.employees, (employee) => employee.name);
  }

  @computed private get emptyListMessage() {
    return (
      <div className="ui center aligned basic segment">
        <h4 className="ui header">
          <div className="sub header">{this.props.emptyListMessage}</div>
        </h4>
      </div>
    );
  }

  @computed private get listItems() {
    return Lodash.map(this.sortedEmployeesList, (employee) => {
      const isSelected = Lodash.includes(this.props.selected, employee);
      return (
        <ListItem
          key={employee.id}
          event={this.props.event}
          employee={employee}
          isSelected={isSelected}
          onSelected={this.onSelected}
          onDeselected={this.onDeselected}
        />
      );
    });
  }

  render() {
    if (!this.props.employees.length) {
      return this.emptyListMessage;
    }

    return <div className="ui middle aligned animated selection list">{this.listItems}</div>;
  }
}
