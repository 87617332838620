import Moment from 'moment-timezone';
import * as React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  Alert,
  Consume,
  KosmicComponent,
  LocaleContext,
  Modal,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { OffersContext } from '../../../../contexts/offers.context';

interface Props {
  onAccept: () => Promise<void>;
}
interface State {
  isLoading: boolean;
  upcomingOccurances: ActivityOccuranceDocument[];
}

/**
 * Modal shown when an activity type is updated. It prompts the user
 * that all future acccurances will also be updated.
 */
export class UpdateModal extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(OffersContext)
  private _offers: OffersContext;

  public state: State = {
    isLoading: true,
    upcomingOccurances: [],
  };

  private modal: Modal;

  /** Show and initialize the modal */
  public showModal() {
    this.modal.show();
    this.loadScheduledOccurances();
  }

  componentDidMount() {
    this.loadScheduledOccurances();
  }

  public async loadScheduledOccurances() {
    try {
      await this.awaitState({ isLoading: true });
      // Load occurances
      const occurances = await new ActivityOccuranceResource().findWithPopulatedBookings({
        originatingActivity: this._offers.selectedOffer!.id,
      });
      this.setState({
        isLoading: false,
        upcomingOccurances: occurances.filter(({ start }) => Moment(start).isAfter(Moment())),
      });
    } catch (err) {
      const { t } = this._locale;
      console.error(err);
      const text = t('try-again-later');
      const title = t('oops');
      Alert.show(text, title, 'error');
    }
  }

  private accept = async () => {
    this.setState({ isLoading: true });
    await this.props.onAccept();
    this.setState({ isLoading: false });
    this.modal.hide();
  };

  private cancel = () => this.modal.hide();

  public render() {
    const { t, tt } = this._locale;
    const { isLoading, upcomingOccurances } = this.state;
    const { selectedOffer } = this._offers;
    if (!selectedOffer) return null;

    return (
      <Modal size="tiny" header={tt(selectedOffer.title)} ref={(instance) => (this.modal = instance!)}>
        <div className={`ui basic ${isLoading && 'loading'} segment`} style={containerStyle()}>
          <h4>
            {t('Upcoming activities')}: {upcomingOccurances.length} {t('pcs')}
          </h4>

          <p>{t('All upcoming activities that...')}</p>
          <p>{t('Its up to you to notify...')}</p>

          <div style={{ height: '1em' }} />
          <button className="ui fluid primary button" onClick={this.accept}>
            {t('Update offer and activities')}
          </button>
          <div style={{ height: '1em' }} />
          <button className="ui fluid button" onClick={this.cancel}>
            {t('Cancel')}
          </button>
        </div>
      </Modal>
    );
  }
}

const containerStyle = (): React.CSSProperties => {
  return {
    display: 'flex',
    flexDirection: 'column',
    padding: '0em 2em 2em 2em',
  };
};
