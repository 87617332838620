import Lodash from 'lodash';
import { toJS } from 'mobx';
import * as React from 'react';
import {
  AccessabilityResource,
  Alert,
  Checkbox,
  Consume,
  Field,
  Form,
  Input,
  KosmicComponent,
  LocaleContext,
  Rules,
  SubmitButton,
  TextArea,
  UserDocument,
  UserResource,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { ErrorMessage, ErrorType } from '../../customer/authenticationModal/modalError';
import Store from '../../customer/authenticationModal/store';
import { AvatarDropdownField } from './avatarDropdownField';

interface State {
  uploadedImageId?: string;
  showUploadForm: boolean;
  userHasChangedEmailAddress: boolean;
  note: string;
}
interface Props {
  employee: UserDocument;
  onSaved: () => void;
}
export class EmployeeForm extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private _sendEmailNotificationsChecked = this.props.employee.sendEmailNotifications;

  constructor(props: Props) {
    super(props);
    this.state = {
      uploadedImageId: undefined,
      showUploadForm: false,
      userHasChangedEmailAddress: false,
      note: props.employee.note || '',
    };
  }

  private get isEditing() {
    return !this.props.employee.isNew;
  }

  private get isCurrentUser() {
    return this.props.employee.id == this.globals.session.currentUser?.id;
  }

  private async handleOnSubmit(values, resolve, reject) {
    const { t } = this._locale;
    try {
      const userResource = new UserResource();
      // If new user and already exits then reject
      const collectedUser = await userResource.find({ username: values['E-post'] });
      if (!this.isEditing && collectedUser && collectedUser.length) {
        Store.addError({
          type: ErrorType.EmailInvalid,
          message: t('email.error.already-in-use'),
        });
        resolve();
        return Promise.reject(new Error('user-exists'));
      }

      // TODO: Known issue: When saving a session.currentUser a reload is needed for observable changes to work.
      // Only check authentication when username has been changed
      if (this.isCurrentUser && this.state.userHasChangedEmailAddress) {
        try {
          await this.globals.session.authenticate(this.props.employee.username, values.Password);
        } catch (err) {
          Alert.show(t('wrong-password'));
          resolve();
          return;
        }
      }

      // TODO: If the user exists within an other organization a new role for this organization should be added.
      const updatedUser = toJS(this.props.employee);
      updatedUser.firstname = values['Förnamn'];
      updatedUser.lastname = values['Efternamn'];
      updatedUser.username = values['E-post']; // why does this also edit this.props.employee.username???
      updatedUser.phoneNumber = values['Telefon'];
      updatedUser.note = this.state.note;
      updatedUser.avatar = values['Visningsbild'];
      updatedUser.imageId = values['Visningsbild'] != 'uploaded' ? '' : values['UploadedAvatarImageId'];

      // Update the document
      if (!this.isEditing) {
        updatedUser.sendEmailNotifications = true;
        updatedUser.organization = this.globals.session.currentOrganizationId;
        updatedUser.roles = [
          {
            type: 'organization.employee',
            organization: this.globals.session.currentOrganizationId,
          },
        ];
      } else {
        updatedUser.sendEmailNotifications = this._sendEmailNotificationsChecked;
      }

      // Create and update database document
      const document = userResource.createDocument(updatedUser);
      document.isNew = !this.isEditing;
      await userResource.updateDocument(document);

      // TODO: Why wont lodash.merge work??
      // Update clientSide document
      this.props.employee.firstname = updatedUser.firstname;
      this.props.employee.lastname = updatedUser.lastname;
      this.props.employee.username = updatedUser.username;
      this.props.employee.phoneNumber = updatedUser.phoneNumber;
      this.props.employee.note = updatedUser.note;
      this.props.employee.avatar = updatedUser.avatar;
      this.props.employee.imageId = updatedUser.imageId;
      this.props.employee.sendEmailNotifications = updatedUser.sendEmailNotifications;
      Lodash.merge(this.props.employee, {
        imageUrl: document.imageUrl,
        genetiveFirstname: document.genetiveFirstname,
        name: document.name,
      });

      // Only re-authenticate when username has been changed
      if (this.isCurrentUser && this.state.userHasChangedEmailAddress) {
        await this.globals.session.authenticate(updatedUser.username, values.Password);
      }

      if (!this.isEditing) {
        await new AccessabilityResource().inviteNewEmployee(
          this.props.employee.username,
          this.globals.session.currentOrganization,
        );
        Alert.show(
          this.props.employee.name + ' ' + t('components.dashboard.employees.form.availableEmployee'),
          t('components.dashboard.employees.form.ok'),
          'success',
        );
      }

      this.props.onSaved();
      resolve();
    } catch (err) {
      if (err.message == 'user-exists') {
        Alert.show(
          t('components.dashboard.employees.form.alreadyUserMail'),
          t('components.dashboard.employees.form.alreadyUser'),
          'error',
        );
      } else if (!this.isEditing) {
        Alert.show(t('not-possible-to-add'), t('Wrong'), 'error');
      } else {
        Alert.show(t('We were not able to save your changes...'), t('Wrong'), 'error');
      }
      reject(err);
    }
  }

  private get mailInfoMessage() {
    const { t } = this._locale;
    if (this.isEditing) {
      return <div style={{ height: '1em' }} />;
    }

    return (
      <div className="ui message" style={{ marginBottom: '1em' }}>
        <h4>{t('components.dashboard.employees.form.invitationMail')}</h4>
        <p>{t('components.dashboard.employees.form.invitationMessage')}</p>
      </div>
    );
  }

  private get mailNotificationCheckbox() {
    const { t } = this._locale;
    if (!this.isCurrentUser) {
      return undefined;
    }

    return (
      <Field label={t('Email notification')}>
        <Checkbox
          checked={this.props.employee.sendEmailNotifications}
          onCheckedOrUncheked={() => (this._sendEmailNotificationsChecked = !this._sendEmailNotificationsChecked)}
        >
          {t('components.dashboard.employees.form.changedWorkHours')}
        </Checkbox>
      </Field>
    );
  }

  private onEmailChanged(value: string) {
    Store.clearError(ErrorType.EmailInvalid);
    this.setState({
      userHasChangedEmailAddress: this.isCurrentUser && this.props.employee.username !== value,
    });
  }

  private get passwordInput() {
    const { t } = this._locale;
    if (this.state.userHasChangedEmailAddress) {
      return (
        <Field label={t('components.dashboard.employees.form.password')}>
          <Input
            name="Password"
            icon="lock"
            placeholder={t('components.dashboard.employees.form.enterPasswordPlaceholder')}
            protectedCharacters
            rules={[Rules.NotEmpty()]}
          />
        </Field>
      );
    } else {
      return null;
    }
  }

  componentWillUnmount() {
    Store.clearError(ErrorType.EmailInvalid);
  }

  render() {
    const { t } = this._locale;
    return (
      <div style={{ minWidth: '150px', maxWidth: '500px' }}>
        <div id="uploadFormTarget" />
        <Form onSubmit={this.handleOnSubmit.bind(this)}>
          <AvatarDropdownField user={this.props.employee} uploadFormElementId="uploadFormTarget" />
          <Field label={t('First name')}>
            <Input name="Förnamn" defaultValue={this.props.employee.firstname} rules={[Rules.NotEmpty()]} />
          </Field>
          <Field label={t('Last name')}>
            <Input name="Efternamn" defaultValue={this.props.employee.lastname} rules={[Rules.NotEmpty()]} />
          </Field>
          <Field label={t('Email')}>
            <Input
              name="E-post"
              icon="at"
              defaultValue={this.props.employee.username}
              onChange={this.onEmailChanged.bind(this)}
              rules={[Rules.NotEmpty(), Rules.IsEmailOrEmpty()]}
            />
            <ErrorMessage listenTo={ErrorType.EmailInvalid} />
          </Field>
          {this.passwordInput}
          <Field label={t('Phone')}>
            <Input
              name="Telefon"
              icon="call"
              defaultValue={this.props.employee.phoneNumber}
              placeholder={t('(optional)')}
            />
          </Field>
          <Field label={t('components.dashboard.employees.form.note')}>
            <TextArea
              name="Anteckning"
              value={this.state.note}
              onChange={(note) => this.setState({ note })}
              placeholder={t('(optional)')}
            />
          </Field>
          {this.mailNotificationCheckbox}
          {this.mailInfoMessage}
          <SubmitButton className="fluid green">{t('Save changes')}</SubmitButton>
        </Form>
      </div>
    );
  }
}
