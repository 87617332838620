import React from 'react';
import { TranslatableText, TranslatableTextField } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface IOfferTitleFieldProps {
  title: TranslatableText | string;
  setOfferTitle: (key: 'title', value: TranslatableText) => void;
}

export const OfferTitleField = ({ setOfferTitle, title }: IOfferTitleFieldProps) => {
  const { t } = useLocale();

  return (
    <TranslatableTextField
      name="Erbjudandets namn"
      label={t('Offer name')}
      value={title}
      onChange={(value) => setOfferTitle('title', value)}
      placeholder={t('Max 40 characters')}
    />
  );
};
