import React, { FC, useContext } from 'react';
import { SessionContext } from '../../../session.context';
import { ActivityOccuranceDocument, LocaleContext } from '../../../_dependencies';

interface Props {
  occurance: ActivityOccuranceDocument;
}

export const AdminSettingsBox: FC<Props> = ({ occurance, children }) => {
  const session = useContext(SessionContext);
  const { t } = useContext(LocaleContext);

  const { property, organization } = occurance;
  if (!session.userHasRole({ type: 'property.manager', property, organization })) {
    return null;
  }

  return (
    <div className="ui message">
      <div className="header" style={{ marginBottom: '1rem' }}>
        {t('Administrator settings')}
      </div>
      {children}
    </div>
  );
};
