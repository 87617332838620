import * as React from 'react';
import {
  BadgeDocument,
  BadgeResource,
  MobxComponent,
  Consume,
  NavLink,
  Switch,
  Route,
  LocaleContext,
} from '../../_dependencies';
import { BadgeProvider, IBadge } from '../../contexts/badge.context';
import { BadgeForm } from './badgeForm';
import { Badge } from '../../components/common';
import { AHLocaleContext } from '../../_locales';

interface IState {
  badges?: Array<BadgeDocument>;
}

export class BadgesView extends MobxComponent<any, any> {
  public state: IState = {};

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(BadgeProvider.Context)
  public badges: IBadge;

  public async componentDidMount() {
    const badges = await this.badges.get();
    this.setState({ badges });
  }

  private async removeBadge(badge: BadgeDocument) {
    await this.badges.remove(badge);
    const badges = await this.badges.get();
    this.setState({ badges });
  }

  private get badgeListView() {
    if (!this.state.badges) {
      return <div className="ui loader active"></div>;
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        {this.state.badges.map((badge) => (
          <div style={{ position: 'relative' }} key={badge.id}>
            <Badge badge={badge} />
            <button
              onClick={this.removeBadge.bind(this, badge)}
              className="ui red icon button"
              style={{ position: 'absolute', top: 0, left: 0 }}
            >
              <i className="trash icon" />
            </button>
          </div>
        ))}
      </div>
    );
  }

  private async onNewBadge() {
    const badges = await this.badges.get();
    this.setState({ badges });
  }

  private get newBadgeView() {
    const { t } = this._locale;
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <div style={{ flexGrow: 1, flexShrink: 0, maxWidth: '400px' }}>
          <h2 className="ui header" style={{ textAlign: 'center' }}>
            {t('Add a new badge')}
          </h2>
          <br />
          <BadgeForm onSubmit={this.onNewBadge.bind(this)} badge={new BadgeResource().createDocument({})} />
        </div>
      </div>
    );
  }

  public render() {
    const { t } = this._locale;
    return (
      <div style={{ padding: '1em' }}>
        <h1 style={{ padding: '1em 1em 0em 0em', float: 'right' }}>
          {t('Badges')}&nbsp;
          <i className="trophy icon" />
        </h1>
        <div className="ui top attached tabular menu" style={{ padding: '0em 1em' }}>
          <NavLink exact to={'/admin/badges'} activeClassName="active" className="item">
            {t('components.admin.badges.listBadges')}
          </NavLink>
          <div className="right menu">
            <NavLink exact to={'/admin/badges/new'} activeClassName="active" className="item">
              <i className="plus icon" />
              {t('Add a new badge')}
            </NavLink>
          </div>
        </div>

        <div className="ui content" style={{ paddingTop: '2em' }}>
          <Switch>
            <Route exact path="/admin/badges">
              <div className="ui cards" style={{ padding: '2em' }}>
                {this.badgeListView}
              </div>
            </Route>
            <Route exact path="/admin/badges/new">
              {this.newBadgeView}
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}
