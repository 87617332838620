import moment from 'moment-timezone';
import React, { useRef, useState } from 'react';
import { BookingDocument, InventoryTransferDocument, UserDocument } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';

interface Props {
  personnel?: UserDocument[];
  item: InventoryTransferDocument;
  /** Adds checkbox which can be tracked from the onToggleSelect function*/ isSelectable?: boolean;
  /** Will set the item as selected (checked) or not*/ isSelected?: boolean;
  /** Fired when item is selected or deselected*/ onToggleSelect?: (id: string, isChecked: boolean) => void;
  removeAssignedPerson: (person: UserDocument, transferId: string) => void;
}

export const InventoryTransferDetailsRow = ({
  item,
  isSelectable,
  onToggleSelect,
  removeAssignedPerson,
  isSelected,
}: Props) => {
  const { tt } = useLocale();
  const [removing, setRemoving] = useState<boolean>(false);
  const [thisPerson, setThisPerson] = useState<any>(undefined);
  const checkBoxRef = useRef<HTMLInputElement>(null);

  const onRowClicked = () => {
    if (isSelectable) checkBoxRef.current!.click();
  };

  return (
    <tr
      key={item.id}
      onClick={onRowClicked}
      style={{
        cursor: isSelectable ? 'pointer' : 'auto',
      }}
    >
      <td>
        <div className="print-font-size no-wrap ">
          {item.bookingId
            ? `${(item.bookingId as unknown as BookingDocument).customer.lastname}, ${
                (item.bookingId as unknown as BookingDocument).customer.firstname
              }`
            : '-'}
        </div>
      </td>
      <td>
        <div>{(item.bookingId as any).number}</div>
      </td>
      <td>
        <div>{item.entities.length}</div>
      </td>
      <td>
        <div>{moment(item.date).format('DD-MM-YY')}</div>
      </td>
      <td>
        <div>{tt(item.from.name)}</div>
      </td>
      <td>
        <div>{tt(item.to.name)}</div>
      </td>
      <td>
        <div>
          {item.assignedPickupers.length
            ? item.assignedPickupers
                .sort((a, b) => a.firstname.localeCompare(b.firstname))
                .map((person, i) => {
                  return (
                    <div
                      key={`userId-${i}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeAssignedPerson(person, item.id);
                      }}
                      onMouseOver={() => {
                        setRemoving(true);
                        setThisPerson(person);
                      }}
                      onMouseLeave={() => {
                        setRemoving(false);
                        setThisPerson(undefined);
                      }}
                      style={{
                        cursor: 'no-drop',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {person.firstname}
                      <i
                        style={{ visibility: removing && thisPerson == person ? 'visible' : 'hidden' }}
                        className="ui close red icon"
                      />
                    </div>
                  );
                })
            : '-'}
        </div>
      </td>
      {isSelectable && onToggleSelect && (
        <td>
          <input
            onClick={(e) => e.stopPropagation()}
            ref={checkBoxRef}
            onChange={(e) => onToggleSelect(item.id, e.target.checked)}
            checked={isSelected}
            type="checkbox"
          />
        </td>
      )}
    </tr>
  );
};
