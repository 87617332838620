import { Schema } from 'mongoose';

export interface SocialMediaDocument {
  facebook?: string;
  instagram?: string;
}

const SocialMediaSchemaa = new Schema(
  {
    facebook: { type: String, required: false },
    instagram: { type: String, required: false },
  },
  { _id: false },
);

export const SocialMediaSchema = SocialMediaSchemaa;
