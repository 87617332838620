import Lodash from 'lodash';
import * as React from 'react';
import * as ReactRouter from 'react-router';
import { Accordion, MobxComponent, ScheduleDocument, LocaleContext, Consume } from '../../../../_dependencies';
import { ScheduleConsumer } from './context';
import { ScheduleProps } from './context/interfaces';
import { ScheduleCreator } from './scheduleCreator';
import { ScheduleItem } from './scheduleItem';
import { ScheduleList } from './scheduleList';
import { AHLocaleContext } from '../../../../_locales';

interface State {
  selected: boolean;
  loading: boolean;
}

@ScheduleConsumer
export class EmployeeScheduleView extends MobxComponent<
  { match: ReactRouter.match<{ propertyId: string; userId: string }> } & ScheduleProps,
  State
> {
  state = {
    selected: false,
    loading: false,
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private templateCreator: Accordion | null = null;

  private get scheduleContent() {
    return this.props.scheduleContent!;
  }

  constructor(props) {
    super(props);
  }

  private get header() {
    const { t } = this._locale;
    if (this.scheduleContent.user && this.scheduleContent.originProperty) {
      const headerString = ' ' + this.scheduleContent.user.name + ' @ ' + this.scheduleContent.originProperty.name;
      return (
        <h2>
          {t('components.dashboard.properties.employeeSchedule.index.schedule')}
          {headerString}
        </h2>
      );
    } else {
      return <div className="ui active left inline loader"></div>;
    }
  }

  private setEditingSchedule(schedule?: ScheduleDocument, setNew?: boolean) {
    if (setNew && this.scheduleContent.newScheduleTemplate && this.templateCreator!.isOpen) {
      this.scheduleContent.setEditSchedule(this.scheduleContent.newScheduleTemplate, true);
    } else {
      this.scheduleContent.setEditSchedule(schedule, undefined, schedule ? schedule.id : undefined);
    }
    // Also highligt the box..
    this.setState({ selected: this.templateCreator!.isOpen });
  }

  /**
   * @prop templates
   * Used to create a rendered list of schedule templates */
  private get schedules() {
    const { t } = this._locale;
    const { schedules } = this.props.scheduleContent!;

    let schedulesExists = false;

    if (schedules && schedules.length) {
      schedules.forEach((template) => {
        if (!template.isException) {
          schedulesExists = true;
        }
      });
    }

    if (!schedulesExists) {
      return [
        <h4 style={{ textAlign: 'center' }}>
          {t('components.dashboard.properties.employeeSchedule.index.noScheduleTemplatesFound')}
        </h4>,
      ];
    }

    return Lodash.map(schedules, (template) => {
      if (!template.isException) {
        return (
          <ScheduleItem
            key={`Template ${template._id}`}
            schedule={template}
            onClick={this.setEditingSchedule.bind(this)}
          />
        );
      }
    });
  }

  private setLoading = (shouldLoad: boolean) => {
    this.setState({ loading: shouldLoad });
  };

  private get list(): JSX.Element | null {
    const { t } = this._locale;
    const { schedules, editSchedule } = this.props.scheduleContent!;
    if (!schedules.length && !editSchedule) {
      return (
        <h4 style={{ textAlign: 'center' }}>
          {t('components.dashboard.properties.employeeSchedule.index.noSchedulesFound')}
        </h4>
      );
    } else {
      return <ScheduleList setLoading={this.setLoading.bind(this)} />;
    }
  }

  private get createNewButtonTitle() {
    const { t } = this._locale;
    return (
      <span
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClick={() => this.setEditingSchedule(undefined, true)}
      >
        <h2 style={{ margin: 0 }}>{t('components.dashboard.properties.employeeSchedule.index.addNewSchedule')}</h2>
        <i className="ui plus large icon" style={{ margin: 0, marginLeft: '0.5em' }} />
      </span>
    );
  }

  private get segmentStyle() {
    const style = { margin: '1em 0', cursor: 'pointer' } as React.CSSProperties;
    if (this.state.selected) {
      style.borderColor = 'lightgreen';
      style.boxShadow = '0px 0px 5px lightgreen';
    }
    return style;
  }

  private handleOnSave() {
    this.templateCreator!.close();
    this.setState({ selected: false });
    this.scheduleContent.setEditSchedule(undefined);
  }

  private get loader() {
    const { t } = this._locale;
    if (this.state.loading) {
      return (
        <div className="ui active inverted dimmer" style={{ zIndex: 10, height: '100vh', position: 'fixed' }}>
          <div className="ui text loader">{t('Loading schedule...')}</div>
        </div>
      );
    }
  }

  render() {
    const { t } = this._locale;
    return (
      <div style={{ padding: '2em' }}>
        {this.header}
        <div className="ui two column stackable grid" style={{ paddingTop: '2em' }}>
          <div className="ui eight wide column">
            <h3 style={{ paddingBottom: '14px' }}>
              {t('components.dashboard.properties.employeeSchedule.index.scheduleTemplates')}
            </h3>
            <div className="ui column eight raised segment" style={this.segmentStyle}>
              <Accordion ref={(instance) => (this.templateCreator = instance)} title={this.createNewButtonTitle}>
                <ScheduleCreator onSave={this.handleOnSave.bind(this)} />
              </Accordion>
            </div>
            {this.schedules}
          </div>
          <div className="ui eight wide column">
            <div className="ui two column stackable grid" style={{ marginBottom: '1em' }}>
              <div className="ui column">
                <h3>{t('components.dashboard.properties.employeeSchedule.index.scheduleTimes')}</h3>
              </div>
              <div
                className="ui column"
                style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}
              >
                <span
                  style={{
                    width: '1em',
                    height: '1em',
                    marginRight: '0.5em',
                    backgroundColor: 'lightGreen',
                    border: '1px solid black',
                  }}
                ></span>
                {t('components.dashboard.properties.employeeSchedule.index.selectedOrAddingInstances')}
                <span
                  style={{
                    width: '1em',
                    height: '1em',
                    marginRight: '0.5em',
                    marginLeft: '1.5em',
                    backgroundColor: 'red',
                    border: '1px solid black',
                  }}
                ></span>
                {t('components.dashboard.properties.employeeSchedule.index.conflictingInstances')}
              </div>
            </div>
            {this.list}
          </div>
        </div>
        {this.loader}
      </div>
    );
  }
}
