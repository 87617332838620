import { isEqual } from 'lodash';
import React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  BookingDocument,
  BookingPriceCategoryDocument,
  calculateTotalPrice,
  Form,
  getAccessories,
  KosmicComponent,
  SelectPriceCategoriesFields,
} from '../../../../_dependencies';
import { RefundComponent } from '../../../../components/dashboard/bookings/bookingItem/refundComponent';

interface Props {
  booking: BookingDocument;
  amountPayedOnline: number;
  onSubmit: (
    selectedPriceCategories: BookingPriceCategoryDocument[],
    totalPrice: number,
    refundAmount: number,
    resolve: any,
    reject: any,
  ) => void;
}

interface State {
  totalRefundAmount: number;
  amountToBeRefunded?: number;
  newTotalPrice: number;
  selectedPriceCategories: BookingPriceCategoryDocument[];
  populatedOccurance?: ActivityOccuranceDocument;
}

export class EditBookingForm extends KosmicComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalRefundAmount: 0,
      newTotalPrice: props.booking.totalPrice || 0,
      selectedPriceCategories: props.booking.priceCategories,
    };
  }

  async componentDidMount() {
    // Get the occurance with bookings populated
    const occuranceId = (this.props.booking.occurance as any)._id;
    const occurance = await new ActivityOccuranceResource().getWithPopulatedBookings(occuranceId);
    if (occurance) {
      this.setState({ populatedOccurance: occurance });
    }
  }

  private onSubmit = async (_, resolve, reject) => {
    const { selectedPriceCategories, amountToBeRefunded = 0 } = this.state;
    this.props.onSubmit(selectedPriceCategories, this.state.newTotalPrice, amountToBeRefunded, resolve, reject);
  };

  private onPriceCategoriesChange = (selectedPriceCategories: BookingPriceCategoryDocument[]) => {
    const { totalPrice = 0 } = this.props.booking;
    const newTotalPrice = calculateTotalPrice(selectedPriceCategories);
    this.setState({
      totalRefundAmount: totalPrice - newTotalPrice,
      selectedPriceCategories,
      newTotalPrice,
    });
  };

  private onNewRefundAmount = (amountToBeRefunded: number) => {
    this.setState({ amountToBeRefunded });
  };

  private hasAccessoriesChanged = () => {
    const defaultAccessorys = getAccessories(this.props.booking.priceCategories, true);
    const newAccesorys = getAccessories(this.state.selectedPriceCategories, true);
    return !isEqual(defaultAccessorys, newAccesorys);
  };

  render() {
    if (!this.state.populatedOccurance) {
      return <div className="ui active centered inline loader" />;
    }
    return (
      <Form onSubmit={this.onSubmit}>
        <SelectPriceCategoriesFields
          key={this.props.booking.id}
          occurance={this.state.populatedOccurance}
          bookedPriceCategories={this.props.booking.priceCategories}
          onChange={this.onPriceCategoriesChange}
        />
        <div className="ui fluid divider" />
        <RefundComponent
          totalRefundAmount={this.state.totalRefundAmount}
          amountPayedOnline={this.props.amountPayedOnline}
          onNewRefundAmount={this.onNewRefundAmount}
          hasAccesoriesChanged={this.hasAccessoriesChanged()}
        />
      </Form>
    );
  }
}
