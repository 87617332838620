import * as React from 'react';
import { Component, DefaultComponentProps } from '../legacy';
import { Width, formatWidth } from './width';

/** Properties for field and fields */
export interface FieldProperties extends DefaultComponentProps {
  /** A label display inside the field */
  label?: React.ReactNode;

  /** Color for labeltext */
  textColor?: string;

  /** a width of the field/fields */
  width?: Width;

  /** Indicates that this field/fields should be shown inline */
  inline?: boolean;

  /** Larger font on the label */
  largeLabel?: boolean;
}

/** A field than can appear in forms */
export class Field extends Component<FieldProperties> {
  private get labelComponent() {
    const { largeLabel, label } = this.props;
    return (
      label && (
        <label
          style={{
            flexGrow: 1,
            flexBasis: '16em',
            minHeight: '1.5em',
            fontSize: largeLabel ? '1.5rem' : undefined,
            color: this.props.textColor,
          }}
        >
          {this.props.label}
        </label>
      )
    );
  }

  private get className() {
    return (
      'field ' +
      (this.props.className ? this.props.className + ' ' : '') +
      (this.props.width ? formatWidth(this.props.width) : '') +
      (this.props.inline ? ' inline' : '')
    );
  }

  render() {
    return (
      <div className={this.className} style={this.props.style}>
        {this.labelComponent}
        {this.props.children}
      </div>
    );
  }
}

/** A Collection of field components */
export class Fields extends Component<FieldProperties> {
  private get labelComponent() {
    return this.props.label ? <label>{this.props.label}</label> : undefined;
  }

  private get className() {
    return (
      'fields ' +
      (this.props.className ? this.props.className + ' ' : '') +
      (this.props.width ? formatWidth(this.props.width) : '') +
      (this.props.inline ? ' inline' : '')
    );
  }

  render() {
    return (
      <div className={this.className} style={this.props.style}>
        {this.labelComponent}
        {this.props.children}
      </div>
    );
  }
}
