import * as React from 'react';
import { RouteProps, withRouter, RouteComponentProps } from 'react-router';
import { Redirect, Route } from '../navigation';

type Props = RouteProps &
  RouteComponentProps<any> & {
    onEnter: (replace: (url: string) => void) => Promise<any> | any;
    onLeave?: () => void;
  };

type State = {
  resolved: boolean;
  replacedWithUrl?: string;
};

/**
 * Route with on Enter and on Leave
 * ----
 * This component exists to provide a convinence during a hurried refactoring for react router v4
 * in v3 a onEnter prop existed on routes, this component executes a given onEnter function when mounted
 * TODO: Remove sometime in the future
 */
class RouteWithHooksClass extends React.Component<Props, State> {
  state = {
    resolved: false,
    replacedWithUrl: undefined as string | undefined,
  };

  perform() {
    const { onEnter, path } = this.props;
    let urlToReplaceWith: string | undefined = undefined;
    Promise.resolve()
      .then(() =>
        onEnter((url) => {
          urlToReplaceWith = url;
        }),
      )
      .catch((err) => {
        // TODO: errr handling
        console.error('An error occured on the onEnter call of a route at:', path);
        console.error(err);
      })
      .then(
        () =>
          new Promise<void>((resolve) =>
            this.setState({ resolved: true, replacedWithUrl: urlToReplaceWith }, () => resolve()),
          ),
      )
      .catch((err) => console.error(err));
  }

  componentDidMount() {
    this.perform();
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState(
      {
        resolved: false,
        replacedWithUrl: undefined,
      },
      () => this.perform(),
    );
  }

  componentWillUnmount() {
    if (this.props.onLeave) {
      this.props.onLeave();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only re-render when successfully resolved
    return nextState.resolved;
  }

  render() {
    // Render a spinner before being resolved, or on serverside rendering
    if (MODULE_ENVIRONMENT == 'node' || !this.state.resolved) {
      return <div className="ui active loader" />;
    }

    // Here the route onEnter action has been resolved

    const routeProps = { ...(this.props as Omit<RouteProps, 'path'> & { path: string }), children: undefined };

    if (this.state.replacedWithUrl) {
      return <Redirect to={this.state.replacedWithUrl} {...routeProps} />;
    } else {
      return <Route {...routeProps}>{this.props.children}</Route>;
    }
  }
}

export const RouteWithHooks = withRouter(RouteWithHooksClass);
