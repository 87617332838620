import Lodash from 'lodash';
import { computed, observable } from 'mobx';
import Moment from 'moment-timezone';
import * as React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeResource,
  Alert,
  Consume,
  LocaleContext,
  MobxComponent,
  Modal,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { OffersContext } from '../../../../contexts/offers.context';
import { DeleteButton } from '../../../deleteButton';

export class DeleteModal extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(OffersContext)
  private _offers: OffersContext;

  private get confirmationText() {
    const { t } = this._locale;
    return t('Are you sure?');
  }

  private modal: Modal;
  @observable private isDeleting: boolean;
  @observable private isLoadingOccurances: boolean;
  @observable private occurances: ActivityOccuranceDocument[] = [];

  private async handleOnDeleteOffer() {
    const { t } = this._locale;
    const { selectedOffer, unloadOffer } = this._offers;
    try {
      this.isDeleting = true;

      if (!selectedOffer) throw Error('No selected offer');

      // Delete all non-booked activity occurances
      if (this.occurancesWithoutBookings.length) {
        await new ActivityOccuranceResource().deleteOccurances(
          Lodash.map(this.occurancesWithoutBookings, (occurance) => occurance.id),
        );
      }

      // Delete the offer or set isArchived flag which will let us populate originatingActivity for booked activities
      if (this.numberOfBookedOccurances > 0) {
        selectedOffer.isArchived = true;
        await new ActivityTypeResource().updateDocument(selectedOffer);
      } else {
        await new ActivityTypeResource().delete(selectedOffer.id);
      }

      this.isDeleting = false;
      this.hideModal();
      unloadOffer(selectedOffer.id, () => this.globals.session.navigateTo('/dashboard/offers'));
      Alert.show('', t('components.dashboard.offers.details.deleteModal.successMessage'), 'success');
    } catch (err) {
      this.isDeleting = false;
      this.hideModal();
      Alert.show('', t('Something went wrong, try again.'), 'error');
    }
  }

  @computed private get occurancesWithoutBookings() {
    return Lodash.filter(this.occurances, (occurance) => !occurance.bookings || !occurance.bookings.length);
  }

  @computed private get upcomingOccurances() {
    return Lodash.filter(this.occurances, (occurance) => Moment(occurance.start).isAfter(new Date()));
  }

  @computed private get numberOfBookedOccurances() {
    return this.occurances.length - this.occurancesWithoutBookings.length;
  }

  public hideModal() {
    this.modal.hide();
  }

  public showModal() {
    this.modal.show();
    this.loadScheduledOccurances();
  }

  public async loadScheduledOccurances() {
    const { selectedOffer } = this._offers;
    try {
      if (!selectedOffer) throw Error('No selected offer');
      this.isLoadingOccurances = true;
      this.occurances = await new ActivityOccuranceResource().findWithPopulatedBookings({
        originatingActivity: selectedOffer.id,
      });
      this.isLoadingOccurances = false;
    } catch (err) {
      console.error(err);
    }
  }

  public render() {
    const { t, tt } = this._locale;
    const { selectedOffer } = this._offers;
    return (
      <Modal size="tiny" header={t('Remove offer')} ref={(node) => (this.modal = node!)}>
        <div
          className={`ui basic ${this.isLoadingOccurances && 'loading'} segment`}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0em 2em 2em 2em',
          }}
        >
          <h4>{tt(selectedOffer?.title)}</h4>
          <p>
            {t('There are in total')}
            &nbsp;
            <b>{this.occurances.length}</b>&nbsp;
            {t('components.dashboard.offers.details.deleteModal.removeDescription2')}
            &nbsp;
            <b>{this.upcomingOccurances.length}</b>&nbsp;
            {t('components.dashboard.offers.details.deleteModal.removeDescription3')}
            &nbsp;
            <b>{this.numberOfBookedOccurances}</b>&nbsp;
            {t('components.dashboard.offers.details.deleteModal.removeDescription4')}
          </p>
          <p>
            <i>{t('components.dashboard.offers.details.deleteModal.bookedActivitiesInfo')}</i>
          </p>
          <div style={{ height: '1em' }} />

          <DeleteButton
            deleting={this.isDeleting}
            disabled={this.isDeleting || this.isLoadingOccurances}
            onConfirmed={this.handleOnDeleteOffer.bind(this)}
            confirmationText={this.confirmationText}
          >
            {t('components.dashboard.offers.details.deleteModal.removeOfferAnd')}{' '}
            {this.occurancesWithoutBookings.length} {t('components.dashboard.offers.details.deleteModal.activities')}
          </DeleteButton>
          <div style={{ height: '1em' }} />
          <button className={`ui primary button ${this.isDeleting && 'disabled'}`} onClick={this.hideModal.bind(this)}>
            {t('Cancel')}
          </button>
        </div>
      </Modal>
    );
  }
}
