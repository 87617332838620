import React from 'react';
import { Field, Input, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface IPersonnelFieldProps {
  neededStaffing: number | undefined;
  setOfferNeededStaffing: (key: 'neededStaffing', value: number) => void;
}

export const PersonnelFields = ({ neededStaffing, setOfferNeededStaffing }: IPersonnelFieldProps): JSX.Element => {
  const { t } = useLocale();
  return (
    <Field label={t('Personnel slots')}>
      <Input
        name="Personalantal"
        icon="users"
        value={String(neededStaffing || '')}
        rules={[Rules.IsEmptyOrInteger()]}
        onChange={(value) => setOfferNeededStaffing('neededStaffing', parseInt(value))}
        placeholder={t('Add a Number of employees under the offer (optional)')}
      />
    </Field>
  );
};
