import React from 'react';
import {
  Consume,
  LocaleContext,
  OrganizationDocument,
  DestinationResource,
  KosmicComponent,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import DashboardTemplate from '../dashboardTemplate';

export { BookingView } from './bookingView';
export { HandleBookingsComponent } from './handleBookings';

interface Props {}
interface State {
  isPartOfDestination: boolean;
}

export class BookingsTemplate extends KosmicComponent<Props, State> {
  state: State = { isPartOfDestination: false };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  async componentDidMount() {
    const orgId = this.globals.session.currentOrganization._id;
    const isPartOfDestination = await new DestinationResource().isPartOfDestination(orgId);
    this.setState({ isPartOfDestination });
  }

  render() {
    const { t } = this._locale;
    const links = [
      {
        path: '/dashboard/bookings',
        text: t('Manage your bookings'),
      },
    ];

    if (this.state.isPartOfDestination) {
      links.push({
        path: '/dashboard/bookings/outsideOrganization',
        text: t('Bookings at other partners'),
      });
    }
    const rightLink = {
      path: '/dashboard/bookings/sell',
      text: t('Create a booking'),
      icon: 'in cart icon',
    };

    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { features } = organization.flags;
    if (!features.bookings) {
      this.globals.session.navigateTo('/dashboard');
      return null;
    }

    return (
      <DashboardTemplate title={t('Bookings')} icon="ticket icon" tabs={links} rightTab={rightLink}>
        {this.props.children}
      </DashboardTemplate>
    );
  }
}
