import * as React from 'react';
import { useLocale } from '../_locales';

export function PageNotFound() {
  const { t } = useLocale();
  return (
    <div style={{ textAlign: 'center', minHeight: '300px', padding: '20%' }}>
      <h1 className="ui header">
        {t('components.pageNotFound.pageNotFoundHeader')}
        <div className="sub header">{t('components.pageNotFound.pageNotFoundExplaination')}</div>
      </h1>
    </div>
  );
}
