import React, { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../session.context';
import { CustomerDocument, Field, Form, Input, Rules, SubmitButton } from '../_dependencies';
import { useLocale } from '../_locales';
import { AddressFields } from './addressFields';

type Props = {
  customer?: CustomerDocument;
  header?: React.ReactNode;
  showMessageInput?: boolean;
  message?: string;
  submitButton?: JSX.Element;
  onSubmit?: (customer: CustomerDocument, message: string, resolve: Function, reject: Function) => void;
};

export const CustomerForm = ({
  header,
  showMessageInput,
  submitButton,
  onSubmit: onSubmitProp,
  customer: defaultCustomer,
  message: defaultMessage = '',
}: Props) => {
  const { t, locale } = useLocale();
  const session = useContext(SessionContext);

  const [message, setMessage] = useState<string>(defaultMessage);
  const [customer, setCustomer] = useState<CustomerDocument>({
    ...(defaultCustomer || {
      firstname: '',
      lastname: '',
      businessName: '',
      phoneNr: '',
      email: '',
      locale: 'sv',
    }),
    locale,
  });

  const { firstname, lastname, businessName, phoneNr, email, address } = customer;

  /* Save locale whenever the user changes it */
  useEffect(() => {
    setCustomer((prev) => ({ ...prev, locale }));
  }, [locale]);

  const onSubmit: Form['props']['onSubmit'] = (_, resolve, reject) => {
    if (onSubmitProp) {
      const _resolve = () => resolve && resolve();
      const _reject = (err: any) => reject && reject(err);
      onSubmitProp(customer, message || '', _resolve, _reject);
    }
  };

  const texts = {
    firstname: t('First name'),
    lastname: t('Last name'),
    businessName: t('Business name'),
    businessNamePlaceholder: t('(optional)'),
    phoneNr: t('Phone'),
    email: t('Email address'),
    message: t('Message for organizer'),
    messagePlaceholder: t('You can leave a private message (optional)'),
  } as const;

  return (
    <Form onSubmit={onSubmit}>
      {header}
      <div className="ui grid stackable" style={styles.container}>
        <Field label={texts.firstname} width={8}>
          <Input
            name="firstname"
            value={firstname}
            onChange={(firstname) => setCustomer((prev) => ({ ...prev, firstname }))}
            rules={[Rules.NotEmpty()]}
          />
        </Field>

        <Field label={texts.lastname} width={8}>
          <Input
            name="lastname"
            value={lastname}
            onChange={(lastname) => setCustomer((prev) => ({ ...prev, lastname }))}
            rules={[Rules.NotEmpty()]}
          />
        </Field>
        <Field label={texts.businessName} width={8}>
          <Input
            name="businessName"
            placeholder={texts.businessNamePlaceholder}
            value={businessName}
            onChange={(businessName) => setCustomer((prev) => ({ ...prev, businessName }))}
          />
        </Field>

        <Field label={texts.phoneNr} width={8}>
          <Input
            name="phoneNr"
            value={phoneNr}
            onChange={(phoneNr) => setCustomer((prev) => ({ ...prev, phoneNr }))}
            rules={[Rules.NotEmpty(), Rules.IsOnlyDigits(), Rules.MaximumLength(15), Rules.MinimumLength(7)]}
          />
        </Field>

        <Field label={texts.email} width={8}>
          <Input
            name="email"
            placeholder="mail@domain.com"
            value={email}
            onChange={(email) => setCustomer((prev) => ({ ...prev, email }))}
            rules={[Rules.NotEmpty(), Rules.IsEmailOrEmpty()]}
          />
        </Field>
      </div>

      {session.userHasRole('property.manager') && (
        <AddressFields
          address={address}
          onAddressChanged={(address) => setCustomer((prev) => ({ ...prev, address }))}
          showAlternativeAddressField
          hideCountryField
          showLabel
          hideInputIcons
          makeAllFieldsOptional
        />
      )}

      <div style={styles.divider} />

      {showMessageInput && (
        <Field label={texts.message}>
          <Input
            name="message"
            defaultValue=""
            value={message}
            onChange={setMessage}
            placeholder={texts.messagePlaceholder}
          />
        </Field>
      )}

      {submitButton || <SubmitButton className="fluid green">{t('Save changes')}</SubmitButton>}
    </Form>
  );
};

const styles = {
  container: {
    marginTop: 0,
    marginBottom: 0,
  },
  divider: {
    height: '1em',
  },
} as const;
