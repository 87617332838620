import * as React from 'react';
import { Consume, LocaleContext, MobxComponent } from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';

export class SkipPaymentComponent extends MobxComponent<{ onContinue: () => void }> {
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  render() {
    const { t } = this._locale;
    return (
      <React.Fragment>
        <h4 className="ui header">
          {t('components.payment.skipPaymentComponent.headerOne')}
          <div className="sub header">{t('components.payment.skipPaymentComponent.headerTwo')}</div>
        </h4>
        <div style={{ height: '1em' }} />
        <button className="ui fluid green button" onClick={this.props.onContinue}>
          {t('Book')}
        </button>
      </React.Fragment>
    );
  }
}
