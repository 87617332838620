import React, { useEffect } from 'react';
import shortid from 'shortid';
import { Field, getTickets as getTicketCategories, PriceCategoryDocument } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { PriceCategoryRow } from './priceCategoryRow';

interface Props {
  categories: PriceCategoryDocument[];
  onPriceCategoriesChange: (key: 'priceCategories', value: PriceCategoryDocument[]) => void;
  isUsingInventory?: boolean;
}

export const CreatePriceCategoriesFields = ({ categories, onPriceCategoriesChange, isUsingInventory }: Props) => {
  const { t } = useLocale();
  const relevantCategories = getTicketCategories(categories);

  useEffect(() => {
    if (categories.length) return;
    addPriceCategory({
      name: '',
      price: undefined as unknown as number,
      groupMultiplier: 1,
    });
  }, [categories]);

  const findCategoryIndex = (cat: PriceCategoryDocument) => categories.findIndex((category) => category == cat);

  const addPriceCategory = (newCategory: PriceCategoryDocument) => {
    const newCategories = [...categories, newCategory];
    onPriceCategoriesChange('priceCategories', newCategories);
  };

  const removePriceCategory = (index: number) => {
    if (index === -1) return;
    const nextCategories = [...categories];
    nextCategories.splice(index, 1);
    onPriceCategoriesChange('priceCategories', nextCategories);
  };

  const changePriceCategory = (index: number, priceCategory: PriceCategoryDocument) => {
    if (index === -1) return;
    const nextCategories = [...categories];
    nextCategories[index] = priceCategory;
    onPriceCategoriesChange('priceCategories', nextCategories);
  };

  return (
    <Field label={t('Price categories')}>
      <div className="ui column grid" style={{ margin: 0 }}>
        {relevantCategories.map((category, index) => {
          // Since price category documents does no have an id we generate
          // a temporary id so react knows how to handle deletes correctly.
          category.id = category.id || shortid.generate();
          return (
            <PriceCategoryRow
              key={category.id}
              priceCategory={category}
              trueIndex={findCategoryIndex(category)}
              nrOfRows={relevantCategories.length}
              rowNumber={index}
              changePriceCategory={changePriceCategory}
              removePriceCategory={removePriceCategory}
              isUsingInventory={isUsingInventory}
            />
          );
        })}
      </div>
      <div className="ui column">
        <div
          onClick={() =>
            addPriceCategory({
              name: { sv: '', en: '', de: '' },
              price: undefined as unknown as number,
              groupMultiplier: 1,
            })
          }
          className="ui basic fluid icon button"
        >
          <i className="plus icon" /> {t('Add a price category')}
        </div>
      </div>
    </Field>
  );
};
