import * as React from 'react';
import {
  Consume,
  KosmicComponent,
  MarketplaceLibrary as Lib,
  LocaleContext,
  MarketplaceResource,
  UserDocument,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { MarketplaceCartContext } from '../marketplace.cart.context';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';
import { OrderStepTemplate } from './orderStepTemplate';

interface State {
  loading: boolean;
  emailMessage?: JSX.Element;
}

export class ConfirmationStep extends KosmicComponent<{}, State> {
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  @Consume(WidgetDataProvider.Context)
  private widgetData: IWidgetData;

  @Consume(MarketplaceCartContext)
  private marketplaceCart: MarketplaceCartContext;

  public state: State = {
    loading: true,
  };

  private async completeBooking(
    booking: Lib.CartBooking,
  ): Promise<{ email: string | undefined; mailWasSentSuccessfully: boolean }> {
    const currentUser = (this.globals?.session?.currentUser || null) as UserDocument | null;
    let mailWasSentSuccessfully = true;
    let email: string | undefined = undefined;

    try {
      if (!this.marketplaceCart.customer)
        throw new Error('[Confirmation step] No customer data in cart when sending booking confirmation');

      email = await this.widgetData.sendBookingConfirmationEmail(booking, this.marketplaceCart.customer);
    } catch (error) {
      console.error('[Confirmation Step] Sending booking confirmation failed with error:', error);
      mailWasSentSuccessfully = false;
    } finally {
      // Create product events
      // NOTE: Events should be moved to a separate instance in the future to
      // enable all vital parts of AH to be able to create product events

      // Create a creation details event describing how this booking was made
      await new MarketplaceResource().updateProductUserMetadata(booking.id, 'Booking source information', {
        key: 'creation_details',
        userEmail: currentUser?.username || null,
        userName: currentUser ? `${currentUser.firstname} ${currentUser.lastname}` : null,
        address: !currentUser ? window?.location?.href : undefined,
      });

      // Create a mail-sent event in the marketplace
      await new MarketplaceResource().updateProductUserMetadata(booking.id, 'Booking created', {
        key: 'created_mail',
        by: currentUser?.id || 'non-session-user',
        to: this.marketplaceCart.customer?.email,
        success: mailWasSentSuccessfully,
        mailType: !this.widgetData.bookingRequest ? 'booking_confirmation' : 'booking_request',
      });
    }
    return { email, mailWasSentSuccessfully };
  }

  private async completeGiftcard(
    giftcard: Lib.CartGiftcard,
  ): Promise<{ email: string | undefined; mailWasSentSuccessfully: boolean }> {
    let mailWasSentSuccessfully = true;
    let email: string | undefined = undefined;
    try {
      if (!this.marketplaceCart.customer)
        throw new Error('[Confirmation step] No customer data in cart when sending booking confirmation');
      email = await this.widgetData.sendGiftCardConfirmationEmail(giftcard, this.marketplaceCart.customer);
    } catch (error) {
      console.error('[Confirmation Step] Sending giftcard confirmation failed with error:', error);
      mailWasSentSuccessfully = false;
    }
    return { email, mailWasSentSuccessfully };
  }

  private async completePurchase() {
    // Send confirmation mails for all items
    const { t } = this._locale;

    // Email will be overwritten on a per product basis,
    // but this should be fine for now as all emails are the same;
    // either undefined or the one submitted in customer info step
    let email: string | undefined = undefined;

    // We'll need to keep track of any mails that did not get sent and notify the customer
    let mailsWereSentSuccessfully = true;

    for (const item of this.marketplaceCart.purchasedItems) {
      if (this.marketplaceCart.cartItemIsCartBooking(item)) {
        const res = await this.completeBooking(item);
        email = res.email;
        // if at any point mail-sent is false, stop updating local let
        if (mailsWereSentSuccessfully) {
          mailsWereSentSuccessfully = res.mailWasSentSuccessfully;
        }
      } else {
        const res = await this.completeGiftcard(item);
        email = res.email;
        // if at any point mail-sent is false, stop updating local let
        if (mailsWereSentSuccessfully) {
          mailsWereSentSuccessfully = res.mailWasSentSuccessfully;
        }
      }
    }

    if (email && mailsWereSentSuccessfully) {
      this.setState({
        loading: false,
        emailMessage: this.isTMETicket() ? (
          <div>
            <p style={{ wordBreak: 'keep-all' }}>
              {t('Your ticket has been sent to the email address')}
              &nbsp;{email}. {t('Click on the link in the email to open the ticket in your browser...')}
            </p>
            {this.orderHasMoreThanOneTicket() && (
              <p>
                <strong>{t('Please note that you will receive one email per ticket.')}</strong>
              </p>
            )}
          </div>
        ) : this.widgetData.bookingRequest ? (
          <div>
            <p style={{ wordBreak: 'keep-all' }}>
              {t('Confirmation of your booking request has been sent to')} &nbsp;{email}
            </p>
            <p>{t('The organizers will get back to you shortly')}</p>
          </div>
        ) : (
          <div>
            <p style={{ wordBreak: 'keep-all' }}>
              {t('Confirmation of purchase been sent to the email address')}
              &nbsp;{email}
            </p>
            <p>{t('Hope you have a wonderful experience! 😊')}</p>
          </div>
        ),
      });
    } else {
      this.setState({
        loading: false,
        emailMessage: (
          <div className="ui compact warning message">{t('Unfortunately something went wrong when we tried...')}</div>
        ),
      });
    }
  }

  private orderHasMoreThanOneTicket() {
    return this.marketplaceCart.purchasedItems.length > 1;
  }

  private isTMETicket = () => {
    for (const item of this.marketplaceCart.purchasedItems) {
      if (this.marketplaceCart.cartItemIsCartBooking(item)) {
        if (item.occurance.originatingActivity.isTmeTicket) return true;
      }
    }
    return false;
  };

  public async componentDidMount() {
    this.widgetData.verifyStepNavigation('confirmation');
    this.completePurchase();
  }

  private renderPurchasedItems() {
    const bookings: Lib.CartBooking[] = [];
    const giftcards: Lib.CartGiftcard[] = [];

    for (const item of this.marketplaceCart.purchasedItems) {
      if (this.marketplaceCart.cartItemIsCartBooking(item)) {
        bookings.push(item);
      } else {
        giftcards.push(item);
      }
    }

    return (
      <>
        {bookings.length ? this.renderBookingsInformation(bookings) : null}
        {giftcards.length ? this.renderGiftcardsInformation(giftcards) : null}
      </>
    );
  }

  private renderBookingsInformation(bookings: Lib.CartBooking[]) {
    const { t, tt } = this._locale;
    const title = bookings.length > 1 ? t('These are your booking numbers') : t('Here is your booking number');
    return (
      <div style={{ margin: '0 0 1em 0' }}>
        <p style={{ margin: '0 0 .2rem 0' }}>{title}</p>
        {bookings.map((booking) => (
          <p style={{ wordBreak: 'keep-all', margin: '0 0 0 .5rem' }} key={booking.number}>
            {tt(booking.occurance.title)}: <strong>{booking.number}</strong>
          </p>
        ))}
      </div>
    );
  }

  private renderGiftcardsInformation(giftcards: Lib.CartGiftcard[]) {
    const { t, tt } = this._locale;
    const title = giftcards.length > 1 ? t('These are your giftcard numbers') : t('This is your giftcard number');
    return (
      <div style={{ margin: '0 0 1em 0' }}>
        <p style={{ margin: '0 0 .2rem 0' }}>{title}</p>
        {giftcards.map((giftcard) => (
          <p style={{ wordBreak: 'keep-all', margin: '0 0 0 .5rem' }} key={giftcard.number}>
            {tt(giftcard.title)}: <strong>{giftcard.number}</strong>
          </p>
        ))}
      </div>
    );
  }

  private reset() {
    location.reload();
  }

  render() {
    const { t } = this._locale;
    const { loading, emailMessage } = this.state;
    const { bookingRequest } = this.widgetData;

    return (
      <OrderStepTemplate items={this.marketplaceCart.purchasedItems}>
        <React.Fragment>
          <div className={`ui ${loading && 'loading'} segment`} style={{ marginTop: '40px' }}>
            <h3>{bookingRequest ? t('Booking request sent!') : t('Thank you so much for your purchase!')}</h3>
            {this.renderPurchasedItems()}
            {emailMessage}
          </div>
          <button className="ui fluid large blue button" onClick={this.reset.bind(this)}>
            <i className="undo icon" />
            {bookingRequest ? t('Make another booking request or purchase') : t('Make another purchase')}
          </button>
        </React.Fragment>
      </OrderStepTemplate>
    );
  }
}
