import Lodash from 'lodash';
import Moment from 'moment-timezone';
import * as React from 'react';
import RRule from 'rrule';
import {
  Consume,
  Decode,
  Encode,
  LocaleContext,
  MobxComponent,
  ScheduleDocument,
  ScheduleResource,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';
import { AdvancedDatePickerType } from '../../../../advancedDatePicker';
import { ScheduleConsumer } from '../context';
import { ScheduleProps } from '../context/interfaces';
import { CheckboxDayMonthFilter } from './checkboxPicker';
import { ScheduleDateTimePicker } from './dateTimePicker';

interface State {
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;

  startDateType: AdvancedDatePickerType;
  endDateType: AdvancedDatePickerType;

  // Index of object in weekDays and months corresponds to weekday or month number (0 for jan, 1 for feb, 0 for mon, 1 for tue and so on..)
  weekDays: { isChecked: boolean; isDisabled: boolean }[];
  months: { isChecked: boolean; isDisabled: boolean }[];
  weeks: number[];
  days: number[];
}
interface Props extends ScheduleProps {
  editRRule?: ScheduleDocument;
  onSave?: (ScheduleTemplateDocument) => void;
  isException?: boolean;
}

@ScheduleConsumer
export class ScheduleCreator extends MobxComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get scheduleContent() {
    return this.props.scheduleContent!;
  }

  constructor(props: Props) {
    super(props);

    if (!props.editRRule) {
      // Create new schedule template
      const defaultStartTime: Date = new Date(new Date().setHours(8, 0, 0, 0));
      const defaultEndTime: Date = new Date(new Date().setHours(17, 0, 0, 0));

      let defaultEndDate: Date = new Date();
      defaultEndDate = Moment(defaultEndTime).add(1, 'month').toDate();
      defaultEndDate.setHours(0, 0, 0, 0);

      this.state = {
        startDate: new Date(),
        endDate: defaultEndDate,
        startTime: defaultStartTime,
        endTime: defaultEndTime,
        startDateType: 'date',
        endDateType: 'date',
        weekDays: new Array(7).fill({ isChecked: true, isDisabled: false }),
        months: new Array(12).fill({ isChecked: true, isDisabled: false }),
        weeks: [],
        days: [],
      };
      this.scheduleContent.setNewScheduleTemplate(this.createNewSchedule());
    } else {
      // Edit existing schedule template
      const { rule, endTime } = Decode(props.editRRule.rRule);
      const { dtstart: startDate, byweekday, byweekno, bymonth: months, until } = rule.options;

      let formattedMonthList: { isChecked: boolean; isDisabled: boolean }[] = new Array(12);
      formattedMonthList = Lodash.map(formattedMonthList, (month, index) => {
        if (Lodash.includes(months, index + 1)) {
          return { isChecked: true, isDisabled: false };
        } else {
          return { isChecked: false, isDisabled: false };
        }
      });

      let formattedWeekDayList: { isChecked: boolean; isDisabled: boolean }[] = new Array(7);
      formattedWeekDayList = Lodash.map(formattedWeekDayList, (week, index) => {
        if (Lodash.includes(byweekday, index)) {
          return { isChecked: true, isDisabled: false };
        } else {
          return { isChecked: false, isDisabled: false };
        }
      });

      this.state = {
        startDate: startDate,
        endDate: until as Date,
        startTime: startDate,
        endTime,
        startDateType: 'date',
        endDateType: 'date',
        weekDays: formattedWeekDayList,
        months: formattedMonthList,
        weeks: byweekno,
        days: byweekday,
      };
    }
  }

  /** @method monthList
   *   Returns a list of all months that should be icluded in the recurstring
   */
  private get monthList() {
    const filteredMonths: number[] = [];
    Lodash.forEach(this.state.months, (month, index) => {
      if (month.isChecked && !month.isDisabled) {
        filteredMonths.push(index + 1);
      }
    });
    return filteredMonths;
  }

  /** @method dayList
   *   Returns a list of all days that should be icluded in the recurstring
   */
  private get dayList() {
    const filteredDays: number[] = [];
    Lodash.forEach(this.state.weekDays, (day, index) => {
      if (day.isChecked && !day.isDisabled) {
        filteredDays.push(index);
      }
    });
    return filteredDays;
  }

  private createNewSchedule() {
    const recurStart: Date = this.state.startDate;
    recurStart.setHours(this.state.startTime.getHours());
    recurStart.setMinutes(this.state.startTime.getMinutes());
    const rRuleSchedule = new RRule({
      freq: RRule.WEEKLY,
      dtstart: recurStart,
      until: this.state.endDate,
      bymonth: this.monthList,
      byweekday: this.dayList,
    });

    // Create template
    const document = new ScheduleResource().createDocument({
      user: this.scheduleContent!.user,
      property: this.scheduleContent.originProperty._id,
      date: recurStart,
      rRule: Encode({ rule: rRuleSchedule, endTime: this.state.endTime }),
      isException: this.props.isException,
    });

    return document;
  }

  private saveNewSchedule() {
    // Creating new schedule
    const schedule = this.createNewSchedule();

    // Add template to context
    this.props.scheduleContent!.addSchedule(schedule);

    // Close creator component
    this.props.onSave && this.props.onSave(schedule);
  }

  private updateProviderTempScheduleLists() {
    const newSchedule = this.scheduleContent.createNewSchedule(
      this.state.startDate,
      this.state.endDate,
      this.state.startTime,
      this.state.endTime,
      this.monthList,
      this.dayList,
    );
    if (!this.props.editRRule) {
      this.scheduleContent.setNewScheduleTemplate(newSchedule);
    }
    this.scheduleContent.setEditSchedule(
      newSchedule,
      this.scheduleContent.isCreatingNew,
      this.scheduleContent.editingScheduleId,
    );
  }

  /** @method updateCheckboxes
   *   Used for updating the state of each checkbox for filtering month and dayOfWeek depending on the range between startDate and endDate.
   */
  private updateCheckboxes = () => {
    const startDate = Moment(this.state.startDate);
    const endDate = Moment(this.state.endDate);

    const monthsNotToBeDisabled: Number[] = [];
    const WeekdaysNotToBeDisabled: Number[] = [];

    for (let month = Moment(startDate); month.isSameOrBefore(endDate, 'month'); month.add(1, 'month')) {
      monthsNotToBeDisabled.push(month.month());
      if (monthsNotToBeDisabled.length == 12) {
        break;
      }
    }

    for (let day = Moment(startDate); day.isSameOrBefore(endDate, 'days'); day.add(1, 'day')) {
      WeekdaysNotToBeDisabled.push(day.isoWeekday() - 1);
      if (WeekdaysNotToBeDisabled.length == 7) {
        break;
      }
    }

    const updatedMonths = Lodash.map(this.state.months, (month, index) => {
      if (Lodash.includes(monthsNotToBeDisabled, index)) {
        return { isChecked: month.isChecked, isDisabled: false };
      } else {
        return { isChecked: month.isChecked, isDisabled: true };
      }
    });

    const updatedWeekDays = Lodash.map(this.state.weekDays, (day, index) => {
      if (Lodash.includes(WeekdaysNotToBeDisabled, index)) {
        return { isChecked: day.isChecked, isDisabled: false };
      } else {
        return { isChecked: day.isChecked, isDisabled: true };
      }
    });

    this.setState({
      months: updatedMonths,
      weekDays: updatedWeekDays,
    });
  };

  private onStartDateChanged = (date: Date, type: AdvancedDatePickerType) => {
    if (type == 'time') {
      type = 'date';
    }
    this.setState(
      {
        startDateType: type,
        startDate: date,
      },
      () => {
        this.updateCheckboxes();
        this.updateProviderTempScheduleLists();
      },
    );
  };

  private onEndDateChanged = (date: Date, type: AdvancedDatePickerType) => {
    if (type == 'time') {
      type = 'date';
    }
    this.setState(
      {
        endDateType: type,
        endDate: date,
      },
      () => {
        this.updateCheckboxes();
        this.updateProviderTempScheduleLists();
      },
    );
  };

  private onStartTimeChange = (time: Date) => {
    this.setState(
      {
        startTime: time,
      },
      () => this.updateProviderTempScheduleLists(),
    );
  };

  private onEndTimeChange = (time: Date) => {
    this.setState(
      {
        endTime: time,
      },
      () => this.updateProviderTempScheduleLists(),
    );
  };

  /** @method onCheckboxChange
   *   Sets the selected month or weekDay in state for building recurstring.
   */
  private onCheckboxChange = (index: number, byMonth: boolean) => {
    if (byMonth) {
      let tempMonths = this.state.months;
      tempMonths = Lodash.map(tempMonths, (month, i) => {
        if (i == index) {
          return { isChecked: !month.isChecked, isDisabled: month.isDisabled };
        } else {
          return month;
        }
      });
      this.setState(
        {
          months: tempMonths,
        },
        () => this.updateProviderTempScheduleLists(),
      );
    } else {
      let tempWeekDays = this.state.weekDays;
      tempWeekDays = Lodash.map(tempWeekDays, (weekday, i) => {
        if (i == index) {
          return { isChecked: !weekday.isChecked, isDisabled: weekday.isDisabled };
        } else {
          return weekday;
        }
      });
      this.setState(
        {
          weekDays: tempWeekDays,
        },
        () => this.updateProviderTempScheduleLists(),
      );
    }
  };

  private addWeek = (weekToAdd: string) => {
    const updatedWeeks: number[] = this.state.weeks;
    updatedWeeks.push(Lodash.toNumber(weekToAdd));
    this.setState(
      {
        weeks: updatedWeeks,
      },
      () => this.updateProviderTempScheduleLists(),
    );
  };

  private removeWeek = (weekToRemove: number) => {
    const updatedWeeks: number[] = this.state.weeks;
    Lodash.remove(updatedWeeks, (week) => week == weekToRemove);
    this.setState(
      {
        weeks: updatedWeeks,
      },
      () => this.updateProviderTempScheduleLists(),
    );
  };

  private addDay = (dayToAdd: string) => {
    const updatedDays: number[] = this.state.days;
    updatedDays.push(Lodash.toNumber(dayToAdd));
    this.setState(
      {
        days: updatedDays,
      },
      () => this.updateProviderTempScheduleLists(),
    );
  };

  private removeDay = (dayToRemove: number) => {
    const updatedDays: number[] = this.state.days;
    Lodash.remove(updatedDays, (day) => day == dayToRemove);
    this.setState(
      {
        days: updatedDays,
      },
      () => this.updateProviderTempScheduleLists(),
    );
  };

  private get conflictFeedback() {
    const { t } = this._locale;
    if (this.scheduleContent.conflictExists) {
      return (
        <h4 style={{ color: 'red', textAlign: 'center' }}>
          {t('components.dashboard.properties.employeeSchedule.scheduleCreator.index.needToResolveConflicts')}
        </h4>
      );
    }
  }

  /**
   * @method remove
   * Removes the schedule template */
  private remove(): void {
    const { editRRule } = this.props;
    const status = this.props.scheduleContent!.removeSchedule(editRRule!);
    if (status) {
      this.props.scheduleContent!.setEditSchedule(undefined);
    } else {
      console.error('Noting to remove');
    }
  }

  componentDidMount() {
    $('#weekDropdown').dropdown({
      onAdd: this.addWeek,
      onRemove: this.removeWeek,
    });
    $('#dayDropdown').dropdown({
      onAdd: this.addDay,
      onRemove: this.removeDay,
    });
    this.updateCheckboxes();
  }

  render() {
    const { t } = this._locale;
    return (
      <React.Fragment>
        <div className="ui divider" />
        <div className="ui stackable grid">
          <div className="ui eight wide column">
            <h5 style={{ marginBottom: '3px' }}>{t('fromDate')}</h5>
            <ScheduleDateTimePicker
              onlyFutureDates
              date={this.state.startDate}
              type={this.state.startDateType}
              enabledTypes={['month', 'date'] as AdvancedDatePickerType[]}
              onChanged={this.onStartDateChanged}
            />
          </div>
          <div className="ui eight wide column">
            <h5 style={{ marginBottom: '3px' }}>{t('To date')}</h5>
            <ScheduleDateTimePicker
              onlyFutureDates
              date={this.state.endDate}
              type={this.state.endDateType}
              enabledTypes={['month', 'date'] as AdvancedDatePickerType[]}
              onChanged={this.onEndDateChanged}
            />
          </div>
        </div>
        <div className="ui stackable grid">
          <div className="ui eight wide column">
            <h5 style={{ marginBottom: '3px' }}>{t('Start time')}</h5>
            <ScheduleDateTimePicker
              onlyFutureDates={false}
              icon="clock"
              date={this.state.startTime}
              type="time"
              enabledTypes={['time'] as AdvancedDatePickerType[]}
              onChanged={this.onStartTimeChange}
            />
          </div>
          <div className="ui eight wide column">
            <h5 style={{ marginBottom: '3px' }}>
              {t('components.dashboard.properties.employeeSchedule.scheduleCreator.index.endTime')}
            </h5>
            <ScheduleDateTimePicker
              onlyFutureDates={false}
              icon="clock"
              date={this.state.endTime}
              type="time"
              enabledTypes={['time'] as AdvancedDatePickerType[]}
              onChanged={this.onEndTimeChange}
            />
          </div>
        </div>
        <div className="ui row" style={{ marginTop: '2em' }}>
          <CheckboxDayMonthFilter dataList={this.state.months} byMonth onChanged={this.onCheckboxChange} />
        </div>
        <div className="ui row" style={{ marginTop: '2em', marginBottom: '2em' }}>
          <CheckboxDayMonthFilter dataList={this.state.weekDays} onChanged={this.onCheckboxChange} />
        </div>

        {/* 
               // Is advanced settings
               // TODO: Fix that accordion doesn´t closes as soon as it opens
                <Accordion title={<span><i className="dropdown icon"></i>{t('components.dashboard.properties.employeeSchedule.scheduleCreator.index.advancedSettings')}</span>}>
                    <div className="ui row" style={{marginTop: ""}}>
                        {this.dayOfMonthPicker}
                    </div>
                    <div className="ui row" style={{marginTop: "2em"}}>
                        {this.weekNumberPicker}
                    </div>
                </Accordion> 
                */}

        {this.conflictFeedback}

        {this.props.editRRule ? (
          [
            <button
              className="ui fluid green big button"
              style={{ marginTop: '1.5em', marginBottom: '1em' }}
              onClick={() => null}
            >
              {t('Save changes')}
            </button>,
            <button
              className="ui fluid red big button"
              style={{ marginTop: '1.5em', marginBottom: '1em' }}
              onClick={this.remove.bind(this)}
            >
              {t('remove')}
            </button>,
          ]
        ) : (
          <button
            className={'ui fluid green big button ' + (this.scheduleContent.conflictExists ? 'disabled' : '')}
            style={{ marginTop: 0 }}
            onClick={this.saveNewSchedule.bind(this)}
          >
            {t('components.dashboard.properties.employeeSchedule.scheduleCreator.index.saveNewScheduleTemplate')}
          </button>
        )}
      </React.Fragment>
    );
  }
}
