import Moment from 'moment';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeResource,
  BookingDocument,
  BookingResource,
  CustomerDocument,
  DatalayerProvider,
  DeviceProvider,
  MarketplaceLibrary as Lib,
  MarketplaceContext,
  MarketplaceOrder,
  MarketplaceProvider,
  MarketplaceResource,
  PaymentComponent,
  PropertyDocument,
  PropertyResource,
  Redirect,
} from '../../_dependencies';
import { useLocale } from '../../_locales';
import { eventFetcher } from '../dashboard/bookings/bookingItem/handleBookingHistoryDataHelpers';
import { LanguageToggleIcons } from '../languageToggleIcons';

const eventTypes = ['booking_rejected', 'booking_confirmed'];

export const PaymentLinkComponent = () => {
  return (
    <div
      style={{
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundImage: 'url(/static/platform/img/new-login-image.jpg)',
        backgroundPosition: 'center',
      }}
    >
      <DatalayerProvider>
        <MarketplaceProvider>
          <PaymentLinkForm />
        </MarketplaceProvider>
      </DatalayerProvider>
    </div>
  );
};

const PaymentLinkForm = () => {
  const params = new URLSearchParams(location.search);
  const externalId = params.get('externalId');

  const { t, tt } = useLocale();
  const device = useContext(DeviceProvider.Context);
  const {
    alreadyPaid,
    completedRelevantPayments,
    placeOrderWithCardPayment,
    placeOrderWithoutPayment,
    setMarketplaceOrder,
  } = useContext(MarketplaceContext);
  const [paidAmount, setPaidAmount] = useState<number | null>(null);
  const [productInfo, setProductInfo] = useState<Lib.ProductInformationResponse | null>(null);
  const [booking, setBooking] = useState<Lib.CartBooking | null>(null);
  const [error, setError] = useState<any>(null);
  const [property, setProperty] = useState<PropertyDocument | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [discountAmount, setDiscountAmount] = useState<number>();
  const [rejectedBookingRequest, setRejectedBookingRequest] = useState<Boolean>(false);
  const bookingResource = new BookingResource();
  const occuranceResource = new ActivityOccuranceResource();
  const activityTypeResource = new ActivityTypeResource();
  const marketplaceResource = new MarketplaceResource();

  const _setProductInfo = (_productInfo: Lib.ProductInformationResponse) => {
    const productInfo = { ..._productInfo };
    const product = productInfo.product;
    Object.defineProperty(product, 'value', {
      get: function (this: typeof product): number {
        return this.events.reduce((acc, event) => acc + event.value, 0);
      },
    });
    productInfo.product = product;
    setProductInfo(productInfo);
  };

  useEffect(() => {
    if (!productInfo || !booking) return;
    for (const discount of productInfo.product.events) {
      if (discount.description === 'Discount') {
        setDiscountAmount(discount.value / 100);
      }
    }

    (async () => {
      const bookingRequestStatus = await eventFetcher(booking as unknown as BookingDocument, eventTypes);
      if (bookingRequestStatus.rejected) {
        setRejectedBookingRequest(bookingRequestStatus.rejected);
      }
    })();
  }, [productInfo, booking]);

  useEffect(() => {
    if (externalId === null) return;
    const getProductInfo = () =>
      marketplaceResource.getInformationForProduct(externalId).then(_setProductInfo).catch(setError);
    getProductInfo();
  }, [submitted]);

  /**
   * Generates a generic marketplace order from the product that is to be paid for
   */
  useEffect(() => {
    if (!productInfo) return;
    const placeholderOrder: MarketplaceOrder = {
      id: productInfo.orderId,
      products: [
        {
          id: productInfo.product._id,
          externalId: productInfo.product.externalId,
          value: productInfo.product.value,
          appliedGiftcards: [],
        },
      ],
      giftcards: [],
    };
    setMarketplaceOrder(placeholderOrder);
  }, [productInfo, discountAmount]);

  useEffect(() => {
    if (externalId === null) return;
    const getBooking = async () =>
      await bookingResource
        .getCustomerBooking(externalId)
        .then(async (res) => await attachPopulatedOccurence(res))
        .catch(setError);
    getBooking();
  }, []);

  async function attachActivityType(occ: ActivityOccuranceDocument) {
    if (!occ.originatingActivity) return;
    const activityType = await activityTypeResource
      .get(occ.originatingActivity)
      .then((res) => res)
      .catch(setError);
    if (!activityType) return;
    return { ...occ, originatingActivity: activityType };
  }

  async function attachPopulatedOccurence(booking: BookingDocument): Promise<Lib.CartBooking | undefined> {
    const occ = await occuranceResource
      .get(booking.occurance)
      .then((res) => attachActivityType(res))
      .catch(setError);
    if (!occ) return;
    setBookingToState({
      ...booking,
      occurance: occ,
    } as Lib.CartBooking);
  }

  function setBookingToState(booking: Lib.CartBooking) {
    setBooking(booking);
  }

  useEffect(() => {
    if (!booking || !booking.occurance.property) return;
    const getProperty = () => new PropertyResource().get(booking.occurance.property).then(setProperty).catch(setError);
    getProperty();
  }, [booking]);

  useEffect(() => {
    if (externalId === null) return;
    const getProductStatus = () =>
      new MarketplaceResource()
        .getPaidAmountForProduct(externalId)
        .then((x) => {
          setPaidAmount(x.amountPaidManually + x.amountPaidWithStripe + x.amountPaidWithGiftCard);
        })
        .then(setError);

    getProductStatus();
  }, [submitted]);

  if (externalId == null) {
    return <Redirect to="/" />;
  }

  if (error) {
    throw error;
  }

  if (
    paidAmount === null ||
    booking === null ||
    productInfo === null ||
    property === null ||
    booking.occurance.originatingActivity === null ||
    booking.customer === null
  ) {
    return (
      <div style={containerStyle}>
        <div className="ui segment" style={segmentStyle(device.size === 'mobile')}>
          <p>{'Preparing ...'}</p>
          <div className="ui active inverted dimmer">
            <div className="ui loader"></div>
          </div>
        </div>
      </div>
    );
  }

  const bookingInformation = (booking?: Lib.CartBooking) => {
    if (!booking) return null;

    const occurance = booking.occurance;
    const activity = occurance.originatingActivity;
    return (
      <div className="ui segment" style={{ flex: 1, margin: '.5rem' }}>
        <h3>{t('Booking')}</h3>
        <div style={{ display: 'flex' }}>
          <FlexList
            values={[
              iconized('star outline', tt(activity.title)),
              iconized('pin outline', tt(property.name)),
              iconized('calendar outline', Moment(occurance.start).format('LLLL')),
              ...booking.priceCategories
                .filter((c) => !c.basePrice)
                .map((x) => iconized('ticket', `${x.tickets} ${tt(x.name)}`)),
              iconized('money', `${booking.totalPrice} SEK`),
            ]}
          />
        </div>
      </div>
    );
  };
  const customerInformation = (customer?: CustomerDocument) => {
    if (!customer) return null;
    return (
      <div className="ui segment" style={{ flex: 1, margin: '.5rem' }}>
        <h3>{t('Customer information')}</h3>
        <div style={{ display: 'flex' }}>
          <FlexList
            values={[
              iconized('send outline', customer.email),
              iconized('user outline', `${customer.firstname} ${customer.lastname}`),
            ]}
          />
        </div>
      </div>
    );
  };

  const getProductsValue = () => productInfo.product.events.map((v) => v.value).reduce((a, b) => a + b, 0);

  const paymentInformation = (
    <div className="ui segment" style={{ flex: 1, margin: '.5rem' }}>
      <h3>{t('Previous payments')}</h3>
      {completedRelevantPayments.length ? (
        <FlexList
          values={completedRelevantPayments.map((x) => {
            const icon = (() => {
              switch (x.type) {
                case 'stripe':
                  return 'green credit card';
                case 'giftcard':
                  return 'green gift';
                case 'refund':
                  return 'yellow credit card';
                default:
                  return 'money';
              }
            })();

            const partition = x.partitions.find((x) => x.productId === productInfo.product._id);

            if (!partition) {
              console.error(
                '[PAYMENTLINK->paymentInformation] Couldnt find any partitions for product with id',
                productInfo.product._id,
              );
              return null;
            }

            return iconized(icon, `${(partition.value / 100).toFixed(2)} ${t('SEK')}`);
          })}
        />
      ) : null}
      {discountAmount ? iconized('cut green', `${discountAmount.toFixed(2)} ${t('SEK')}`) : null}
      <h5>
        <i className="money outline icon" />
        {`${(getProductsValue() / 100 - paidAmount).toFixed(2)} ${t('SEK')} ${t('left to be paid')}`}
      </h5>
    </div>
  );

  const onSaved = () => {
    setSubmitted(true);
  };

  return (
    <div style={containerStyle}>
      <div style={{ color: 'white' }}>
        <img className="ui centered small image" style={logoStyle} src="/static/platform/img/logo.png" />
        <h2 style={headerStyle(device.size === 'mobile')}>
          <span style={{ margin: '.5rem 0' }}>
            {productInfo.product.value / 100 - paidAmount > 0 && !rejectedBookingRequest
              ? t('Pay booking')
              : rejectedBookingRequest
              ? t('Reject booking request')
              : t('Booking has been paid')}
          </span>
          <LanguageToggleIcons style={{ marginRight: '-.4rem' }} />
        </h2>
        {rejectedBookingRequest ? (
          <div
            className="ui segment"
            style={{ width: '100%', textAlign: 'center', alignItems: 'center', padding: '1rem' }}
          >
            <h3>
              {t('It is not possible to pay for this...')} <br />
              {t('You can make another...')}
            </h3>
          </div>
        ) : (
          <div style={segmentStyle(device.size === 'mobile')}>
            <div style={informationStyle(device.size === 'mobile')}>
              {bookingInformation(booking)}
              {customerInformation(booking.customer)}
              {paymentInformation}
            </div>
            {booking.customer && productInfo.product.value / 100 - paidAmount && (
              <div style={{ flex: 1, margin: '.5rem' }}>
                <PaymentComponent
                  customer={booking.customer}
                  hideInfo
                  activePaymentMethods={['CreditCard', 'GiftCard']}
                  amountAlreadyPaid={alreadyPaid}
                  cart={[booking]}
                  onSaved={onSaved}
                  placeOrderWithoutPayment={placeOrderWithoutPayment}
                  placeOrderWithCardPayment={placeOrderWithCardPayment}
                  productSpecific={booking.marketplaceRef}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const iconized = (icon: string, title: string) => (
  <>
    <i className={`${icon} icon`} />
    {title}
  </>
);

const FlexList = ({ values }: { values: ReactNode[] }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {values.map((value, index) => (
        <span key={`${value?.toString()}-${index}`} style={{ flexBasis: '100%' }}>
          {value}
        </span>
      ))}
    </div>
  );
};

const headerStyle = (isMobile: boolean): React.CSSProperties => ({
  textShadow: '1px 1px 5px #444',
  margin: '0 .5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: isMobile ? 'column-reverse' : 'row',
  textAlign: 'center',
});

const informationStyle = (isMobile: boolean): React.CSSProperties => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const containerStyle: React.CSSProperties = {
  display: 'flex',
  minHeight: '16rem',
  minWidth: '16rem',
  maxWidth: '52rem',
  overflowY: 'auto',
  marginBottom: '2rem',
};

const segmentStyle = (isMobile: boolean): React.CSSProperties => ({
  flex: 1,
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
});

const logoStyle: React.CSSProperties = {
  margin: '2rem auto',
};
