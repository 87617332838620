import { flattenDeep, sumBy } from 'lodash';
import { DocumentToObjectOptions, Schema, Types } from 'mongoose';
import { TaxRate } from '..';
import { TranslatableText } from '../schemas/translatableText';
import SchemaNames from './names';
import { RescheduleInsuranceDocument, RescheduleInsuranceSchema } from './rescheduleInsurance';

export interface PriceCategoryDocument {
  /** Randomized short id so react knows which field to delete */ id?: string;
  name: TranslatableText | string;
  /** Represents unit price (price per ticket) when in a price category for an offer or occurence.
   * Also represents unit price in a booking that is being handled in the selectPriceCategoriesFields component,
   * but NOT when the price category comes directly from a booking or when saving a booking to the database.
   * Then a combined price (total price for all tickets) is used instead.
   *
   * This is because of a former bug that multiplied the price with tickets
   * before saving them in the database. There are plans to migrate the database
   * to always have unit price for price categories (in bookings as well), but until this is implemented
   * we have to be extra careful about which "price" we are dealing with.
   * */
  price: number;
  /**
   * Used to describe how many visitors one ticket is
   * valid for where value 0 represents an accessory.
   */
  groupMultiplier: number;
  /** Indicates that this price is to be included once for a booking */
  basePrice?: boolean;
  /** Lets managers choose to have a category hidden from online customers */
  isHidden?: boolean;
  /** Inventory group if advanced inventories are used */
  inventory?: Types.ObjectId;
  /** VIRTUAL FIELD Number of inventories availabile for price category */
  availableInventory?: number;
}

export interface BookingPriceCategoryDocument extends PriceCategoryDocument {
  /** Represent how many tickets the customer has bought in a booking */
  tickets: number;
  /** Indicates this was created on the fly by an admin when they created the booking */
  isCustom?: boolean;
}

export interface BookingRequestDocument {
  fixedBookingRequest: {
    active: boolean;
    message?: string;
  };
  openBookingRequest: {
    active: boolean;
    message: string;
  };
}

/** Helper type to make tax rate required which it should be for accessories */
export type AccessoryDocument<T extends PriceCategoryDocument> = T & {
  /** Used to set a separate tax rate for either a ticket or accessory */
  taxRate: TaxRate;
  /** Describes that this accessory is a reschedule insurance */
  rescheduleInsurance?: RescheduleInsuranceDocument;
};

export var PriceCategorySchema = new Schema(
  {
    /*Optional id to identify specific placeholder types of price categories (e.g. handleClientBelongings-bag)*/
    id: { type: Schema.Types.String, required: false },
    name: { type: Schema.Types.Mixed, required: true },
    price: { type: Number, required: true },
    groupMultiplier: { type: Number, required: true },
    taxRate: { type: Number, required: false },
    basePrice: { type: Boolean, required: false },
    isHidden: { type: Boolean, required: false },
    isCustom: { type: Boolean, required: false },
    tickets: { type: Number, required: false },
    rescheduleInsurance: { type: RescheduleInsuranceSchema, required: false },
    inventory: { type: Schema.Types.ObjectId, ref: SchemaNames.Inventory, required: false },
  },
  { _id: false },
);

const toObjectOptions: DocumentToObjectOptions = {
  virtuals: true,
  transform: function (doc: PriceCategoryDocument, ret: PriceCategoryDocument) {
    ret.availableInventory = doc.availableInventory;
    return ret;
  },
};

PriceCategorySchema.set('toObject', toObjectOptions);
PriceCategorySchema.set('toJSON', toObjectOptions);

/** Helper function to calculate number of visitors from a price category */
export function visitors(pc: Pick<BookingPriceCategoryDocument, 'tickets' | 'groupMultiplier'>) {
  if (!pc.tickets) return 0;
  if (pc.groupMultiplier === undefined) return pc.tickets;
  return pc.tickets * pc.groupMultiplier;
}

/** Helper function to filter out price categories that are tickets */
export function getTickets<T extends PriceCategoryDocument>(pcs?: T[], includeBasePrice?: boolean): T[] {
  if (!pcs) return [];
  return pcs.filter((pc) => pc.groupMultiplier !== 0 || (includeBasePrice && pc.basePrice));
}

/** Helper function to filter out price categories that are accessories */
export function getAccessories<T extends PriceCategoryDocument>(
  pcs?: T[],
  includeRescheduleInsurance?: boolean,
): AccessoryDocument<T>[] {
  if (!pcs) return [];
  return pcs.filter(
    (pc: AccessoryDocument<T>) =>
      pc.groupMultiplier === 0 &&
      !pc.basePrice &&
      pc.taxRate !== undefined &&
      (!pc.rescheduleInsurance || (includeRescheduleInsurance && pc.rescheduleInsurance)),
  ) as AccessoryDocument<T>[];
}

/** Returns the placeholder price category object that represents a temporary inventory*/
export function getClientBelongingsPriceCategory(): PriceCategoryDocument {
  return {
    name: { sv: 'Väska', en: 'Bag', de: 'Tasche' },
    groupMultiplier: 0,
    price: 0,
    id: 'c7c6f5d6-d1bf-4e62-a6f2-b5da138a2e12',
  };
}

export function findAndReturnClientBelongingPriceCategories(
  priceCategories: PriceCategoryDocument[],
): PriceCategoryDocument[] {
  return priceCategories.filter((c) => pricecategoryIsClientBelonging(c));
}

/** Checks if pricecategory array holds any clientBelongingPriceCategory*/
export function priceCategoriesHasClientBelongingPriceCategory(...priceCategories: PriceCategoryDocument[][]): boolean {
  let flattenedArray: PriceCategoryDocument[] = flattenDeep(priceCategories);
  for (const pC of flattenedArray) {
    if (pricecategoryIsClientBelonging(pC)) return true;
  }
  return false;
}

/** Checks if pricecategory is a client belonging*/
export function pricecategoryIsClientBelonging(priceCategory: PriceCategoryDocument): boolean {
  const clientBelongingId = getClientBelongingsPriceCategory().id;
  return Boolean(priceCategory.id && priceCategory.id === clientBelongingId);
}

/** Helper function to filter out the base price category */
export function getBasePrice<T extends PriceCategoryDocument>(pcs?: T[]): T | undefined {
  if (!pcs) return undefined;
  return pcs.find((pc) => pc.basePrice);
}

/** Helper function to filter out the reschedule insurance category */
export function getRescheduleInsurance<T extends PriceCategoryDocument>(
  pcs?: T[],
): Required<AccessoryDocument<T>> | undefined {
  if (!pcs) return undefined;
  return pcs.find((pc: AccessoryDocument<T>) => pc.rescheduleInsurance) as Required<AccessoryDocument<T>>;
}

export function getPriceCategoriesWithoutRescheduleInsurance(pcs: AccessoryDocument<BookingPriceCategoryDocument>[]) {
  if (!pcs) return [];
  return pcs.filter((pc) => !pc.rescheduleInsurance);
}

export function getRescheduleFee<T extends BookingPriceCategoryDocument>(pcs: (AccessoryDocument<T> | T)[]) {
  const rescheduleCategory = getRescheduleInsurance(pcs);
  const nrOfVisitors = sumBy(pcs, (pc) => visitors(pc));
  if (rescheduleCategory) {
    if ('rescheduleInsurance' in rescheduleCategory && rescheduleCategory.rescheduleInsurance?.isFeePerPerson) {
      return rescheduleCategory.price * (rescheduleCategory.rescheduleInsurance.personsPaidFor || nrOfVisitors);
    }
    return rescheduleCategory.price;
  }
  return 0;
}

/** Helper function to filter out price categories that are connected to an inventory */
export function getInventoryCategories<T extends PriceCategoryDocument>(pcs?: T[]): T[] {
  if (!pcs) return [];
  return pcs.filter((pc) => pc.inventory);
}
