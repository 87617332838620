import Lodash from 'lodash';
import Moment from 'moment-timezone';
import React from 'react';
import {
  Accordion,
  Checkbox,
  Consume,
  DeviceProvider,
  Dropdown,
  DropdownItem,
  IDevice,
  LocaleContext,
  OrganizationDocument,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { checkAccountActivation } from '../../components/activationHelper';
import { Overlay } from '../overlay';
import { PrintableDayView } from './print/printableDayView';
import { CalendarPeerComponent } from './utils/calendarPeerComponent';

interface Props {
  children?: any;
  canCreateNewOccurance?: boolean;
  showActivityFilter?: boolean;
  showCrossSellingFilter?: boolean;
}

export class CalendarSidebar extends CalendarPeerComponent<Props> {
  @Consume(LocaleContext)
  protected _locale: AHLocaleContext;
  @Consume(DeviceProvider.Context)
  protected device: IDevice;

  componentDidMount() {
    this.store.filterCalenderByActivity = 'all';
  }

  private onNewEvent = () => {
    const eventSlot = {
      start: this.store.date,
      end: this.store.date,
    };
    this.store.handleTimeSelection(eventSlot);
  };

  private onActivityDropdownChanged = (eventTitle: string) => {
    this.store.filterCalenderByActivity = eventTitle;
  };

  private get defaultContent(): React.ReactNode {
    const { t } = this._locale;
    let instructions: React.ReactNode = '';
    if (this.store.view == 'month') {
      instructions = t('components.calendar.sidebar.instructionOne');
    } else if (this.store.isTimeSelectable && (this.store.view == 'week' || this.store.view == 'day')) {
      instructions = (
        <span>
          {t('components.calendar.sidebar.instructionTwo')} <i className="hand rock icon" />{' '}
          {t('components.calendar.sidebar.instructionThree')}{' '}
        </span>
      );
    }

    // Add some space.. 🐯
    if (instructions) {
      instructions = (
        <span>
          <br />
          <br />
          {instructions}
        </span>
      );
    }

    // Verify that the organization is activated
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const isAccountActivated = checkAccountActivation(organization);

    if (isAccountActivated) {
      const content = (
        <div>
          {this.props.children}
          {this.props.showActivityFilter && this.filterByActivity}
          {this.props.showCrossSellingFilter && (
            <Checkbox
              style={{ marginBottom: '.5rem' }}
              toggle
              label={t('show-cross-selling-only')}
              checked={this.store.showCrossSellingActivitesOnly}
              onCheckedOrUncheked={(checked) => {
                this.store.showCrossSellingActivitesOnly = checked;
              }}
            />
          )}
          <Checkbox
            style={{ marginBottom: '.5rem' }}
            toggle
            label={t('components.calendar.sidebar.showBookingsOnly')}
            checked={this.store.showBookedActivitiesOnly}
            onCheckedOrUncheked={(checked) => {
              this.store.showBookedActivitiesOnly = checked;
            }}
          />
          <Checkbox
            toggle
            label={t('Only show booking requests')}
            checked={this.store.showOnlyRequestedBookings}
            onCheckedOrUncheked={(checked) => {
              this.store.showOnlyRequestedBookings = checked;
            }}
          />
          <p style={{ marginTop: '2rem' }}>
            {t('components.calendar.sidebar.instructionFour')}
            {instructions}
          </p>
        </div>
      );

      if (this.device.size !== 'desktop') {
        return (
          <div className="ui message" style={{ padding: '1em' }}>
            <Accordion titleIcon compact title={<b>{t('Filter settings')}</b>}>
              <div style={{ height: '.5rem' }} />
              {content}
            </Accordion>
            {this.buttons}
          </div>
        );
      }
      return (
        <div className="ui message" style={{ padding: '1em' }}>
          {content}
          {this.buttons}
        </div>
      );
    }
    // Not an activated account
    else {
      return (
        <div className="ui warning message">
          <div className="content">
            <div className="header">{t('components.calendar.sidebar.notActivatedTitle')}</div>
            <p>
              {t('However, before you can create activities...')}
              <br />
              <br />
              <button
                className="ui orange right aligned button"
                onClick={() => this.globals.session.navigateTo('/dashboard')}
              >
                {t('Activate my account')}
              </button>
            </p>
          </div>
        </div>
      );
    }
  }

  private get buttons() {
    const { t } = this._locale;
    const { canCreateNewOccurance } = this.props;
    const { view, date } = this.store;
    const isAgendaOrDayView = view === 'day' || view === 'agenda';

    const createButton = canCreateNewOccurance && isAgendaOrDayView && (
      <button className="ui fluid tiny orange icon button" onClick={this.onNewEvent}>
        <i className="plus icon" /> {t('components.calendar.sidebar.buttonOne')}{' '}
        {Moment(date).tz('Europe/Stockholm').format('Do MMM')}
      </button>
    );

    const printButton = isAgendaOrDayView && <PrintableDayView store={this.store} />;

    if (!createButton && !printButton) return null;

    return (
      <div style={{ marginTop: '1rem' }}>
        {createButton}
        {printButton}
      </div>
    );
  }

  private get middleArea() {
    const EventDetails = this.eventComponentFactory.selectedEventDetailsComponent;

    if (this.device.size === 'desktop') {
      if (this.store.selectedEvent) {
        return (
          <div className="ui raised segment">
            <EventDetails event={this.store.selectedEvent} />
          </div>
        );
      }
      return this.defaultContent;
    }

    return (
      <>
        {this.defaultContent}
        {this.store.selectedEvent && (
          <Overlay onHide={this.store.deselectEvent}>
            <EventDetails event={this.store.selectedEvent} />
          </Overlay>
        )}
      </>
    );
  }

  private get filterByActivity() {
    const { t, tt } = this._locale;
    const activitesDropdownItems = Lodash.map(
      Lodash.uniqBy(this.store._downloadedEvents, (e) => tt(e.title)),
      (event) => (
        <DropdownItem key={event.id} value={tt(event.title)}>
          {tt(event.title)}
        </DropdownItem>
      ),
    );

    return (
      <div style={{ marginBottom: '1rem' }}>
        <Dropdown
          fluid
          searchable
          name="activity"
          value={this.store.filterCalenderByActivity}
          onChange={this.onActivityDropdownChanged}
        >
          <DropdownItem value="all">{t('showing-all-activities')}</DropdownItem>
          {activitesDropdownItems}
        </Dropdown>
      </div>
    );
  }

  render() {
    const { t } = this._locale;
    return (
      <div className="ui sticky basic segment" style={{ overflowY: 'auto', maxHeight: '100vh' }}>
        <div style={{ height: '10rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {this.viewSelectionBar(t)}
          <div style={{ textAlign: 'center', paddingBottom: '0px' }}>{this.label(t)}</div>
          <div className="ui fluid small basic buttons">
            <div className="ui button" onClick={this.store.navigateBackward}>
              {'<'}
            </div>
            <div className="ui button" onClick={this.store.navigateToToday}>
              {t('components.calendar.store.today')}
            </div>
            <div className="ui button" onClick={this.store.navigateForward}>
              {'>'}
            </div>
          </div>
        </div>
        {this.middleArea}
      </div>
    );
  }
}
