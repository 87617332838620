import * as React from 'react';
import type Stripe from 'stripe';
import { Checkbox, Field } from '../../../../../../_dependencies';
import { useLocale } from '../../../../../../_locales';
import { PersonParams } from './personsForm';

interface Props {
  person: PersonParams;
  representativeHasBeenAssigned: boolean;
  onRelationshipChanged: (relationship: Stripe.PersonCreateParams.Relationship) => void;
  error?: JSX.Element;
}

export const RelationshipFields = (props: Props) => {
  const { relationship } = props.person;
  const { t } = useLocale();

  return (
    <div style={{ marginBottom: '2rem' }}>
      <h3>
        {t('Roles')} <i id="roles-helper" className="question circle outline icon" />
        <div className="ui popup">
          <div className="header">{t('Roles')}</div>
          <div className="content">{t('All owners and board members must be registered...')}</div>
        </div>
      </h3>
      <Field>
        <Checkbox
          checked={relationship?.representative}
          onCheckedOrUncheked={(checked) => {
            const updatedRelationship = { ...relationship, representative: checked };
            props.onRelationshipChanged(updatedRelationship);
          }}
          label={t('Representative')}
          disabled={!relationship?.representative && props.representativeHasBeenAssigned}
        />
      </Field>
      <Field>
        <Checkbox
          checked={relationship?.director}
          onCheckedOrUncheked={(checked) => {
            const updatedRelationship = { ...relationship, director: checked };
            props.onRelationshipChanged(updatedRelationship);
          }}
          label={t('Board Member')}
        />
      </Field>
      <Field>
        <Checkbox
          checked={relationship?.executive}
          onCheckedOrUncheked={(checked) => {
            const updatedRelationship = { ...relationship, executive: checked };
            props.onRelationshipChanged(updatedRelationship);
          }}
          label={t('CEO')}
        />
      </Field>
      <Field>
        <Checkbox
          checked={relationship?.owner}
          onCheckedOrUncheked={(checked) => {
            const updatedRelationship = { ...relationship, owner: checked };
            props.onRelationshipChanged(updatedRelationship);
          }}
          label={t('Owner')}
        />
      </Field>
      {props.error && <div className="ui negative message">{props.error}</div>}
    </div>
  );
};
