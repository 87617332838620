import { request } from 'express';
import { computed, observable } from 'mobx';
import React from 'react';
import ReactRouter, { RouteComponentProps } from 'react-router';
import { ActivityOccuranceResource, Consume, LocaleContext, MailResource, MobxComponent } from '../_dependencies';
import { AHLocaleContext } from '../_locales';

type Response = 'succeeded' | 'full' | 'already-assigned' | 'denied';

interface Params {
  activityId: string;
  employeeId: string;
  status: 'accept' | 'deny';
}
type Props = RouteComponentProps<Params>;

export class WorkSignupView extends MobxComponent<Props> {
  @observable private isLoading = true;
  private response: Response;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @computed private get message() {
    const { t } = this._locale;

    switch (this.response) {
      case 'succeeded':
        return (
          <div className="ui positive compact message">
            <h2>{t('You are now booked!')}</h2>
          </div>
        );
      case 'full':
        return (
          <div className="ui info compact message">
            <h2>{t('Glad you want to work...')}</h2>
          </div>
        );
      case 'already-assigned':
        return (
          <div className="ui info compact message">
            <h2>{t('You are already booked for this job')}</h2>
          </div>
        );
      case 'denied':
        return (
          <div className="ui info compact message">
            <h2>{t('Your answer has been registered...')}</h2>
          </div>
        );
      default: {
        return (
          <div className="ui negative compact message">
            <h2>{t('Sorry there was an error, please try again.')}</h2>
          </div>
        );
      }
    }
  }

  private get loader() {
    return <div className="ui active centered inline big loader" />;
  }

  async componentDidMount() {
    const { activityId, employeeId, status } = this.props.match.params;
    const activityResource = new ActivityOccuranceResource();
    if (status === 'accept') {
      const response = await activityResource.assignEmployeeToActivity(activityId, employeeId);
      this.response = response.message;
      if (this.response == 'succeeded') {
        new MailResource().sendWorkInvitationConfirmationEmail(employeeId, activityId);
      }
    } else {
      const activity = await activityResource.get(activityId);

      const alreadyInWorkingsStaff = activity.workingStaff.some((staff) => String(staff) === String(employeeId));

      if (alreadyInWorkingsStaff) {
        this.response = 'already-assigned';
      } else {
        activity.requestedStaff.forEach((request) => {
          if (String(request.user) == String(employeeId)) {
            request.status = 'denied';
          }
        });
        await activityResource.updateDocument(activity);
        this.response = 'denied';
      }
    }
    this.isLoading = false;
  }

  render() {
    return (
      <div className="ui one column centered grid">
        <div className="column" style={{ maxWidth: '700px' }}>
          <div
            className="ui basic segment"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2em', padding: '1em' }}
          >
            <img className="ui centered small image" src="/static/platform/img/logo.png" />
            <div style={{ height: '4em' }} />
            {this.isLoading ? this.loader : this.message}
          </div>
        </div>
      </div>
    );
  }
}
