import Lodash from 'lodash';
import * as React from 'react';

type SortDirection = 'asc' | 'desc';

interface Props<T> {
  /** Semantic table classnames - defaults to "ui striped table" */ className?: string;
  /** List of identifiers of the currently selected elements. When true, the data object in renderRow must contain an {id:string} 
        field that holds the identifier used in this property.*/ selectedElements?: string[];
  /** Use this render prop to define each table row. If selectable, the property "data" must 
        containt "id: string" which will act as an identifier tracked in the selectedElements array*/ renderRow: (
    data: T,
    isSelected?: boolean,
  ) => JSX.Element;
  /** The list to present in the table */ dataList: T[];
  /** Whether to sort on a column at mount */ initSortColumn?: keyof T & string;
  /** Which direction to sort initially */ initSortDirection?: SortDirection;
  /** To better control responsiveness, defaults to 50rem */ minWidth?: string;
  /** Headers for the table, including a sorting property */ headers: {
    /** Column name */ title: string | JSX.Element;
    /** Sort works with string & number and also nested objects 3 levels deep. use punctuations.*/ sortBy?: keyof T &
      string;
    /** How many columns this header should cover  */ colSpan?: number;
    /** Defaults to asc */ defaultSortDirection?: SortDirection;
    /** Specifies the float of the header. Can be left, right or center */ float?: string;
    /** Specify the width of a column with semantic names (four, six, eight, etc) */ width?: string;
  }[];
  /** footers for the table */ footers?: {
    /** Column name */ title: string | JSX.Element;
    /** How many columns this footer should cover  */ colSpan?: number;
    /** Specifies the float of the footer. Can be left, right or center */ float?: string;
    /** Specify the width of a column with semantic names (four, six, eight, etc) */ width?: string;
  }[];
}

interface State<T> {
  sortedData?: T[];
  sortDirection?: SortDirection;
  sortColumn?: string;
}

export class Table<T extends Object & { id?: string }> extends React.Component<Props<T>, State<T>> {
  public constructor(props: Props<T>) {
    super(props);

    if (props.initSortColumn && props.dataList && props.dataList.length) {
      this.state = this.getNextStateAfterSort(props.dataList, props.initSortColumn, props.initSortDirection);
    } else {
      this.state = { sortedData: this.props.dataList };
    }
  }

  public componentDidUpdate(prevProps: Props<T>, prevState: State<T>) {
    if (this.props.dataList === prevProps.dataList) return;

    if (prevState.sortColumn) {
      const header = this.props.headers.find((header) => header.sortBy == prevState.sortColumn);

      if (header) {
        let sortedData = this.sort(this.props.dataList, header.sortBy!, header.defaultSortDirection);
        if (prevState.sortDirection !== (header.defaultSortDirection || 'asc')) {
          sortedData = Lodash.reverse(sortedData);
        }
        this.setState({ sortedData });
      } else if (this.props.initSortColumn) {
        const state = this.getNextStateAfterSort(
          this.props.dataList,
          this.props.initSortColumn,
          this.props.initSortDirection,
          true,
        );
        this.setState({ ...state });
      } else {
        this.setState({ sortedData: this.props.dataList });
      }
    } else if (this.props.initSortColumn && !prevState.sortColumn) {
      this.setState(
        this.getNextStateAfterSort(this.props.dataList, this.props.initSortColumn, this.props.initSortDirection, true),
      );
    } else {
      this.setState({ sortedData: this.props.dataList });
    }
  }

  private getNextStateAfterSort(
    dataList: T[],
    column: string,
    defaultSortDirection?: SortDirection,
    preservSort?: boolean,
  ): State<T> {
    // Try to calculate initial sort direction
    const { headers } = this.props;
    defaultSortDirection =
      defaultSortDirection ||
      (function () {
        const c = headers.find((c) => c.sortBy == column);
        if (c == undefined) return undefined;
        return c.defaultSortDirection;
      })();

    if (!preservSort && this.state && column == this.state.sortColumn) {
      return {
        sortedData: Lodash.reverse(dataList),
        sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc',
        sortColumn: column,
      };
    }
    return {
      sortedData: this.sort(dataList, column, defaultSortDirection),
      sortDirection: defaultSortDirection || 'asc',
      sortColumn: column,
    };
  }

  private sort(dataList: T[], column: string, defaultSortDirection?: SortDirection): T[] {
    const stringToArr = column.split('.');

    const sortFunction = (data: T) => {
      if (stringToArr.length === 1 && typeof data[stringToArr[0]] === 'string') {
        return Lodash.toLower(data[stringToArr[0]]);
      } else if (stringToArr.length === 2 && data[stringToArr[0]][stringToArr[1]]) {
        return data[stringToArr[0]][stringToArr[1]];
      } else if (stringToArr.length === 3 && data[stringToArr[0]][stringToArr[1]]) {
        return data[stringToArr[0]][stringToArr[1]][stringToArr[2]];
      } else {
        return data[stringToArr[0]];
      }
    };

    return Lodash.orderBy(dataList, [sortFunction, 'id'], [defaultSortDirection || 'asc']);
  }

  private onHeaderClicked(column: string, defaultSortDirection?: SortDirection) {
    if (this.state.sortedData) {
      this.setState(this.getNextStateAfterSort(this.state.sortedData, column, defaultSortDirection));
    }
  }

  private getSortCaret(column: string) {
    if (this.state.sortColumn == column) {
      const direction = this.state.sortDirection === 'asc' ? 'up' : 'down';
      return <i className={'ui caret ' + direction + ' icon'} style={{ margin: 0, width: '17px' }} />;
    }
    // Prevents the jumping of columns
    return <div style={{ display: 'inline-block', width: '17px' }} />;
  }

  private get headers() {
    return this.props.headers.map((header, index) => {
      let className = ' print-font-size';
      if (header.float) className += `${header.float} aligned `;
      if (header.width) className += `${header.width} wide`;

      if (header.sortBy) {
        return (
          <th
            key={index}
            colSpan={header.colSpan}
            style={{ cursor: 'pointer' }}
            onClick={this.onHeaderClicked.bind(this, ...[header.sortBy, header.defaultSortDirection])}
            className={className}
          >
            {header.title} {this.getSortCaret(header.sortBy)}
          </th>
        );
      }
      return (
        <th className={className} key={index} colSpan={header.colSpan}>
          {header.title}
        </th>
      );
    });
  }

  private get footers() {
    return this.props.footers?.map((footer, index) => {
      let className = ' ';
      if (footer.float) className += `${footer.float} aligned `;
      if (footer.width) className += `${footer.width} wide`;

      return (
        <th className={className} key={index} colSpan={footer.colSpan}>
          {footer.title}
        </th>
      );
    });
  }

  private get activeLoader() {
    if (!this.state.sortedData) {
      return (
        <tr>
          <td colSpan={this.props.headers.length}>
            <div className="ui huge active centered inline text loader" style={{ margin: '2em 0' }} />
          </td>
        </tr>
      );
    }
  }

  public render() {
    return (
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <table
          className={this.props.className || 'ui striped unstackable table'}
          style={{ minWidth: this.props.minWidth || '50rem' }}
        >
          <thead>
            <tr>{this.headers}</tr>
          </thead>
          <tbody>
            {this.activeLoader}
            {this.state.sortedData &&
              this.state.sortedData.map((data) => {
                if (data.id) {
                  return this.props.renderRow(data, this.props.selectedElements?.includes(data.id));
                }
              })}
          </tbody>
          <tfoot>
            <tr>{this.footers}</tr>
          </tfoot>
        </table>
      </div>
    );
  }
}
