import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  InventoryEntityDocument,
  InventoryStatusDocument,
  InventoryStatusResource,
  PlaceDocument,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { renderPlace } from '../inventoryItemStatusForm';
import { smallerContainerStyle } from './destinationSegment';

interface Props {
  places?: PlaceDocument[];
  tempEntity: InventoryEntityDocument;
  updatedStatus?: InventoryStatusDocument;
}

export const ConfirmMultipleButtonSegment = ({ tempEntity, updatedStatus, places }: Props) => {
  const { t, tt } = useLocale();
  const [checked, setChecked] = useState<boolean>(false);
  const [statusesToUpdate, setStatusesToUpdate] = useState<InventoryStatusDocument[]>();
  const [statusesUpdated, setStatusesUpdated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const inventoryStatusResource = new InventoryStatusResource();

  useEffect(() => {
    if (tempEntity) {
      (async () => {
        try {
          const notCheckedIn = (await inventoryStatusResource.findAndPopulateInventoryEntities({
            booking: tempEntity.lastStatus?.booking?._id,
          })) as InventoryStatusDocument[];
          setStatusesToUpdate(
            notCheckedIn.filter(
              (status) =>
                (status.inventoryEntity as any)._id != tempEntity._id && (status.inventoryEntity as any).isTemporary,
            ),
          );
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [tempEntity, loading]);

  const checkInAllTempEntities = async () => {
    if (statusesToUpdate && updatedStatus) {
      for (const status of statusesToUpdate) {
        try {
          setLoading(true);

          status.location = updatedStatus.location;
          status.status = updatedStatus.status;

          await inventoryStatusResource.updateDocument(status);
        } catch (err) {
          console.log(err);
        }
      }
      setStatusesUpdated(true);
      setLoading(false);
    }
  };

  return (
    <div style={smallerContainerStyle}>
      {statusesUpdated ? (
        <h4 style={{ color: '#016936' }}>
          <i className="check icon green" />
          {t(`All bags updated!`)}
        </h4>
      ) : (
        <h4 style={{ color: 'black' }}>{t(`Bags attached to this booking`)}</h4>
      )}
      {statusesToUpdate?.map((status) => {
        return (
          <div key={status.id} style={{ color: 'black' }}>
            <div>
              <b>{(status.inventoryEntity as any).title}</b> - {t(status.status)} -{' '}
              {!status.location ? t('None') : tt(renderPlace(places, tempEntity, status)?.name)}
            </div>
          </div>
        );
      })}
      {!statusesUpdated ? (
        <>
          <Checkbox
            largeLabel
            checked={checked}
            style={{ padding: '1rem 0rem 1rem 0rem' }}
            onCheckedOrUncheked={() => (checked ? setChecked(false) : setChecked(true))}
          >
            {t('Verify items')}
          </Checkbox>
          <Button
            loading={loading}
            disabled={!checked}
            className={'fluid green'}
            style={{ padding: '1em' }}
            onClick={checkInAllTempEntities}
          >
            {t('Check in all as')} {updatedStatus ? t(updatedStatus.status) : t(tempEntity.lastStatus.status)}{' '}
            {t('to this booking?')}
          </Button>
        </>
      ) : null}
    </div>
  );
};
