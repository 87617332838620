import * as React from 'react';
import * as ShortId from 'shortid';
import * as Lodash from 'lodash';
import {
  DeviceProvider,
  IDevice,
  Alert,
  Consume,
  Field,
  Form,
  Input,
  KosmicComponent,
  LocaleContext,
  OrganizationResource,
  Rules,
  SubmitButton,
  UserResource,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { Link } from 'react-router-dom';

interface Props {
  titleColor?: string;
  textColor?: string;
  formLocation: 'Bottom' | 'Top';
}

interface State {
  stripeMail: string;
  organizationName: string;
}

export class SignupForm extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  private createOrganization = async (values, resolve, reject) => {
    const { t } = this._locale;

    try {
      const orgResource = new OrganizationResource();
      const userResource = new UserResource();

      const { organizationName, firstname, lastname, email } = values;

      // Check if user- or organizationname exists in database
      const existingStatus = await userResource.checkIfUserAndOrgExists(email, organizationName);
      if (existingStatus.userExists) {
        throw new Error('user-exists');
      } else if (existingStatus.orgExists) {
        throw new Error('org-exists');
      }

      // Create new user
      const newUser = userResource.createDocument({});

      // Creating new Organization
      const newOrganization = orgResource.createDocument({
        name: organizationName,
        admins: [newUser._id],
        verificationCode: ShortId.generate(),
        isVerified: false,
        isActivated: false,
      });

      // Setting values to User and binding it to new Organization
      newUser.username = email;
      newUser.firstname = firstname;
      newUser.lastname = lastname;
      newUser.roles = [
        {
          type: 'organization.manager',
          organization: newOrganization._id,
        },
      ];

      // Create new account
      const isUserCreated = await orgResource.createNewAccount(
        newOrganization,
        newUser,
        this.generateRandomString(8),
        true,
      );

      // Check if account has been created
      if (!isUserCreated) {
        throw new Error();
      }

      Alert.show(t('See your email to complete the registration of your account'), t('Great'), 'success');

      resolve();
    } catch (err) {
      if (err.message == 'user-exists') {
        Alert.show(t('`A user with the given name already exists in the system'), t('User already exists'), 'error');
      } else if (err.message == 'org-exists') {
        Alert.show(
          t('An organization with the given name already exists in the system'),
          t('Organization already exists'),
          'error',
        );
      } else {
        Alert.show(t('Please try again or contact support...'), t('The organization could not be created'), 'error');
      }
      reject(err);
    }
  };

  private generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private get styles() {
    const bottomStyle = this.props.formLocation === 'Bottom';
    const mobileStyle = this.device.size === 'mobile';
    return generateStyles(mobileStyle, bottomStyle, this.props.titleColor);
  }

  render() {
    const { t } = this._locale;
    const { container, createAccountTitle, innerContainer, logoStyle, form, submitButton } = this.styles;
    /** Textcomponents for labels and placeholders */
    const textComponents = {
      organizationName: t('company name'),
      firstname: Lodash.capitalize(t('First name')),
      lastname: Lodash.capitalize(t('Last name')),
      email: t('Email'),
    };

    return (
      <div className="ui basic segment" style={container}>
        {this.props.formLocation === 'Top' ? (
          <Link style={{ cursor: 'pointer', color: '#29BB9C', textDecoration: 'none' }} to="/activities">
            <img className="ui centered small image" style={logoStyle} src="/static/platform/img/logo.png" />
          </Link>
        ) : undefined}
        <div className="ui sixteen wide column" style={innerContainer}>
          <h2 style={createAccountTitle}>{t('Create account')}</h2>
        </div>
        <Form onSubmit={this.createOrganization} style={form}>
          <Field label={textComponents.organizationName} textColor={this.props.textColor}>
            <Input
              placeholder={textComponents.organizationName}
              name="organizationName"
              value=""
              onChange={(value) => this.setState({ organizationName: value })}
              rules={[Rules.NotEmpty()]}
              className="required"
            />
          </Field>
          <div>
            <Field label={textComponents.firstname} textColor={this.props.textColor}>
              <Input
                placeholder={textComponents.firstname}
                name="firstname"
                value=""
                onChange={() => undefined}
                rules={[Rules.NotEmpty()]}
              />
            </Field>
            <Field label={textComponents.lastname} textColor={this.props.textColor}>
              <Input
                placeholder={textComponents.lastname}
                name="lastname"
                value=""
                onChange={() => undefined}
                rules={[Rules.NotEmpty()]}
              />
            </Field>
            <Field label={textComponents.email} textColor={this.props.textColor} style={this.styles.bottomFieldPadding}>
              <Input
                placeholder={textComponents.email}
                name="email"
                value=""
                onChange={() => undefined}
                icon="at"
                rules={[Rules.NotEmpty(), Rules.IsEmailOrEmpty()]}
              />
            </Field>
          </div>
          {this.device.size != 'mobile' ? (
            <p style={createAccountTitle}>{t('Register for free and get to know the platform...o')}</p>
          ) : null}
          <SubmitButton className="fluid green" style={submitButton}>
            {t('Create account')}
          </SubmitButton>
        </Form>
      </div>
    );
  }
}

const generateStyles = (mobile: boolean, bottom: boolean, titleColor?: string) =>
  ({
    container: {
      width: '100%',
      margin: 0,
      top: !bottom && '20%',
    } as React.CSSProperties,
    logoStyle: {
      marginTop: mobile ? '-3.5rem' : '-10rem',
      marginBottom: mobile && '1em',
      height: 'auto',
      width: mobile && '30%',
    } as React.CSSProperties,
    titleColor: {
      color: titleColor,
    } as React.CSSProperties,
    innerContainer: {
      textAlign: 'center',
      marginBottom: mobile ? '0.5em' : '1em',
    } as React.CSSProperties,
    form: {
      width: '100%',
    } as React.CSSProperties,
    createAccountTitle: {
      color: titleColor,
      fontSize: mobile && '1rem',
    } as React.CSSProperties,
    bottomFieldPadding: {
      paddingBottom: mobile && '2.2em',
    } as React.CSSProperties,
    submitButton: {
      backgroundColor: '#29BB9C',
    } as React.CSSProperties,
  } as const);
