import Fuse from 'fuse.js';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Alert,
  DeviceProvider,
  DiscountDocument,
  DiscountResource,
  DiscountTimeLimit,
  DiscountUsages,
  GlobalsContext,
  Input,
  LocaleContext,
  MarketplaceResource,
} from '../../../_dependencies';
import { DiscountDetails } from './details';
import { DiscountForm } from './discountForm';

export const HandleDiscounts = () => {
  const { t } = useContext(LocaleContext);
  const globals = useContext(GlobalsContext);
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [createDiscount, setCreateDiscount] = useState<boolean>(false);
  const [discounts, setDiscounts] = useState<DiscountDocument[]>();
  const [expiredDiscounts, setExpiredDiscounts] = useState<DiscountDocument[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const device = React.useContext(DeviceProvider.Context);
  const mobile = device.size === 'mobile';
  const createDiscountDocument = new DiscountResource().createDocument({});
  const market = new MarketplaceResource();
  const organizationID = globals.session.currentOrganizationId;
  const [discountUsages, setDiscountUsages] = useState<DiscountUsages[]>([]);

  const getDiscountsUsageNumbers = async (discounts: DiscountDocument[] | undefined, organizationID: string) => {
    if (!discounts) return;

    const result = await Promise.all(
      discounts.map(async (d) => {
        return new Promise<DiscountUsages>((res, rej) => {
          market
            .getOrganizationsDiscountUsage(organizationID, d.code)
            .then((r) => {
              res({ code: d.code, numberOfUses: r.numberOfUses });
            })
            .catch((err) => {
              res({ code: '', numberOfUses: undefined });
            });
        });
      }),
    );
    setDiscountUsages(result);
  };

  const deleteDiscount = async (discountId: string) => {
    try {
      const resource = new DiscountResource();
      await resource.delete(discountId);
      Alert.show(t('Discount deleted'), t('components.dashboard.employees.form.ok'), 'success');
      setDeleting(true);
      setDeleting(false);
    } catch (err) {
      Alert.show('', t('Something went wrong, try again.'), 'error');
    }
  };

  useEffect(() => {
    const getAllDiscounts = async () => {
      let today = new Date();

      await new DiscountResource()
        .find({ organization: globals.session.currentOrganizationId, 'timeLimits.end': { $gt: today } })
        .then((results) => {
          if (!query || !filterDiscounts(results).length) {
            setDiscounts(results);
          } else {
            setIsLoading(true);
            setDiscounts(filterDiscounts(results));
            setIsLoading(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getAllDiscounts();
  }, [createDiscount, canEdit, query, deleting]);

  const getExpiredDiscounts = async () => {
    let today = new Date();

    await new DiscountResource()
      .find({ organization: globals.session.currentOrganizationId, 'timeLimits.end': { $lt: today } })
      .then((results) => {
        if (!query || !filterDiscounts(results).length) {
          setExpiredDiscounts(results);
        } else {
          setIsLoading(true);
          setExpiredDiscounts(filterDiscounts(results));
          setIsLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDiscountsUsageNumbers(discounts, String(organizationID));
  }, [discounts]);

  const cancelCreate = () => {
    setCreateDiscount(false);
    setCanEdit(true);
  };

  const filterDiscounts = (results: DiscountDocument[]) => {
    const fuseOptions: Fuse.IFuseOptions<DiscountDocument> = {
      threshold: 0.2,
      minMatchCharLength: 2,
      keys: ['code'],
    };
    const filteredDiscounts = new Fuse(results as DiscountDocument[], fuseOptions);
    return filteredDiscounts.search(query as string).map((fuseDocument) => {
      return fuseDocument.item;
    });
  };

  const searchBar = () => {
    return (
      <div className="ui item" style={{ margin: '0.5rem 0' }}>
        <Input
          name="search"
          loading={isLoading}
          onChange={(value) => setQuery(value)}
          style={{ paddingLeft: '0em' }}
          type="text"
          placeholder={t('Search discounts')}
          icon="search"
          disabled={!canEdit}
        />
      </div>
    );
  };

  const addButton = () => {
    return (
      <div
        className={!canEdit ? 'ui button disabled' : 'ui button'}
        style={{ width: 'auto' }}
        onClick={() => {
          setCreateDiscount(true);
          setCanEdit(false);
        }}
      >
        <i className="plus icon" style={{ margin: 'auto' }} /> {!mobile ? t('Add discount code') : ''}
      </div>
    );
  };

  const getExpiredDiscountsButton = () => {
    return (
      <div
        className={'ui button'}
        style={{ width: 'auto' }}
        onClick={() => {
          getExpiredDiscounts();
        }}
      >
        <i className="history icon" style={{ margin: 'auto' }} /> {!mobile ? t('Expired discount codes') : ''}
      </div>
    );
  };

  const handleDates = (timeLimits: DiscountTimeLimit[], key: string) => {
    if (!timeLimits.length) return;
    return new Date(timeLimits[0][key]).toLocaleDateString();
  };

  //checks usages and dates then returns string: green | red | orange
  const getDiscountStatus = (discount: DiscountDocument) => {
    let status_flag = 'red';

    //SPECIAL CASES WHERE WE RETURN RED FLAG INSTANTLY
    const discountWithUsages = discountUsages.find((usage) => usage.code === discount.code); //getting applied code usages

    // if numberof uses is not a number - the status flag is red
    if (typeof discountWithUsages?.numberOfUses !== 'number') return (status_flag = 'red');

    //if usage limit is set and order usages exceed usageLimit - status flag is red
    if (discount.usageLimit && discountWithUsages.numberOfUses >= discount.usageLimit) return (status_flag = 'red');

    //if discount is out of bounds
    if (discount.discountPercentage > 1 || discount.discountPercentage < 0) return (status_flag = 'red');
    //SPECIAL CASES ENDS HERE

    //TIME CHECKS
    discount.timeLimits.forEach((timeLimit) => {
      // if NOW is less than end date && NOW is greater than start date
      if (new Date() <= new Date(timeLimit.end) && new Date() >= new Date(timeLimit.start)) {
        return (status_flag = 'green');
      }
      //if NOW is less than start date
      if (new Date() < new Date(timeLimit.start)) {
        return (status_flag = 'yellow');
      }
    });
    return status_flag;
  };

  const headers = () => {
    if (mobile) {
      return (
        <tr>
          <th>
            <h5 className="ui header left aligned">{t('Discount code')}</h5>
          </th>
          <th>
            <h5 className="ui header center aligned">{t('Discount')}</h5>
          </th>
          <th>
            <h5 className="ui header center aligned">{t('Valid until')}</h5>
          </th>
          <th />
        </tr>
      );
    }
    return (
      <tr>
        <th>
          <h3 className="ui header">{t('Code')}</h3>
        </th>
        <th>
          <h3 className="ui header">{t('Discount')}</h3>
        </th>
        <th>
          <h3 className="ui header">{t('Uses')}</h3>
        </th>
        <th>
          <h3 className="ui header">{t('Valid until')}</h3>
        </th>
        <th>
          <h3 className="ui header">{t('Status')}</h3>
        </th>
      </tr>
    );
  };

  return (
    <div style={{ padding: mobile ? '0.5em' : '2em', width: '100%' }}>
      {mobile ? (
        <h3>{t('All discounts for') + ' ' + globals.session.currentOrganization.name}</h3>
      ) : (
        <h2>{t('All discounts for') + ' ' + globals.session.currentOrganization.name}</h2>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {searchBar()}
        {getExpiredDiscountsButton()}
        {addButton()}
      </div>
      <div className={'ui basic segment ' + (loading ? 'loading' : '')} style={{ padding: '0em' }}>
        <table className="ui basic selectable unstackable padded table" style={{ border: 'none' }}>
          <thead>{headers()}</thead>
          <tbody>
            {createDiscount ? (
              <DiscountForm
                discount={createDiscountDocument}
                finishedEditing={cancelCreate}
                creatingDiscount={createDiscount}
                handleDates={handleDates}
                getDiscountStatus={getDiscountStatus}
                deleteDiscount={deleteDiscount}
              />
            ) : null}
            {!discounts
              ? null
              : discounts.map((discount: DiscountDocument) => (
                  <DiscountDetails
                    key={discount.id}
                    discount={discount}
                    canEdit={canEdit}
                    handleDates={handleDates}
                    getDiscountStatus={getDiscountStatus}
                    deleteDiscount={deleteDiscount}
                    discountUsage={discountUsages.find((usage) => usage.code === discount.code)}
                  />
                ))}
            {!expiredDiscounts
              ? null
              : expiredDiscounts.map((discount: DiscountDocument) => (
                  <DiscountDetails
                    key={discount.id}
                    discount={discount}
                    canEdit={canEdit}
                    handleDates={handleDates}
                    getDiscountStatus={getDiscountStatus}
                    deleteDiscount={deleteDiscount}
                    discountUsage={discountUsages.find((usage) => usage.code === discount.code)}
                  />
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
