import { Platforms, visitors } from '../../../../_dependencies';
import { Tfunc } from '../../../../_locales';
import { ValidField, getValueForPath, recursiveDiff, translateCustomerDocumentField, validFields } from './_helpers';
import {
  ConfirmedBookingRequestMetadata,
  CreationDetailsEventMetadata,
  CustomerUpdatedEventMetadata,
  DiscountEventMetadata,
  EventDetails,
  EventType,
  MailCreationEventMetadata,
  PaymentEventMetadata,
  PriceCategoriesUpdatedEventMetadata,
  RejectedBookingRequestMetadata,
} from './_types';

/** Returns the EventDetails for a given event */
export const GetEventDetailsFor = (t: Tfunc, event: EventType): EventDetails => {
  try {
    // Creation event
    if (!event.metadata && /created/i.test(event.description)) {
      return creationEvent(t);
    }
    // Other events
    switch (event.metadata.key) {
      case 'customer_updated':
        return customerUpdatedDetails(t, event as EventType<CustomerUpdatedEventMetadata>);
      case 'price_categories_updated':
        return priceCategoryDetails(t, event as EventType<PriceCategoriesUpdatedEventMetadata>);
      case 'payment_instance_created':
        return paymentInstanceDetails(t, event as EventType<PaymentEventMetadata>);
      case 'created_mail':
        return createdMailDetails(t, event as EventType<MailCreationEventMetadata>);
      case 'creation_details':
        return creationDetailsEvent(t, event as EventType<CreationDetailsEventMetadata>);
      case 'discount-applied':
        return discountEvent(t, event as EventType<DiscountEventMetadata>);
      case 'booking_rejected':
        return bookingWasRejected(t, event as EventType<RejectedBookingRequestMetadata>);
      case 'booking_confirmed':
        return bookingWasConfirmed(t, event as EventType<ConfirmedBookingRequestMetadata>);
      default:
        console.warn('Was unable to determine the type of event when getting event details, for:', event);
        throw 'Unable to determine the type of event';
    }
  } catch {
    // Return details for "unknown event" on any failure
    return unknownEvent(t);
  }
};

// NOTE: Long list of undocumented internal functions

const unknownEvent = (t: Tfunc): EventDetails => ({
  icon: 'question circle outline icon',
  title: t('Unknown event'),
});

// Creation events

const creationEvent = (t: Tfunc): EventDetails => ({
  icon: 'calendar check outline icon',
  title: t('Booking was created'),
});

const bookingWasConfirmed = (t: Tfunc, event: EventType<ConfirmedBookingRequestMetadata>): EventDetails => ({
  icon: 'check icon',
  title: t('Booking request confirmed'),
});

const bookingWasRejected = (t: Tfunc, event: EventType<RejectedBookingRequestMetadata>): EventDetails => ({
  icon: 'close icon',
  title: t('Booking request rejected'),
});

const creationDetailsEvent = (t: Tfunc, event: EventType<CreationDetailsEventMetadata>): EventDetails => ({
  icon: event.metadata.userName ? 'user icon' : 'certificate icon',
  title: event.metadata.userName ? t('Created by') + ' ' + event.metadata.userName : t('The booking was made online'),
  description: event.metadata.address ? t('Address') + ': ' + event.metadata.address : undefined,
});

const discountEvent = (t: Tfunc, event: EventType<DiscountEventMetadata>): EventDetails => ({
  icon: 'cut green icon',
  title: `${t('Discount code')}: ${event.metadata.applied_discount_code} ${t('added')}`,
  description: `${t('A discount of')} ${Number(event.metadata.applied_discount_percentage) * 100}% ${t(
    'was processed',
  )}`,
});

// Payment events

const paymentInstanceDetails = (t: Tfunc, event: EventType<PaymentEventMetadata>): EventDetails => {
  const type = event.metadata;
  switch (type.paymentOption) {
    case 'stripe':
      return cardPayment(t, event.value);
    case 'giftcard':
      return giftcardPayment(t, event.value);
    case 'refund':
      return refundPayment(t, event.value);
    case 'manual':
      return manualPayment(t, event.value);
    default:
      return defaultPayment(t, event.value);
  }
};

const defaultPayment = (t: Tfunc, value: number): EventDetails => ({
  icon: 'dollar sign',
  title: t('A payment was added'),
  description: `${t('A payment of')} ${value / 100} ${Platforms.currencyISO} ${t('was processed')}`,
});
const manualPayment = (t: Tfunc, value: number): EventDetails => ({
  icon: 'money bill alternate green icon',
  title: t('Manual payment added'),
  description: `${t('A payment of')} ${value / 100} ${Platforms.currencyISO} ${t('was processed')}`,
});
const giftcardPayment = (t: Tfunc, value: number): EventDetails => ({
  icon: 'gift green icon',
  title: t('Voucher payment added'),
  description: `${t('A payment of')} ${value / 100} ${Platforms.currencyISO} ${t('was processed')}`,
});
const refundPayment = (t: Tfunc, value: number): EventDetails => ({
  icon: 'credit card outline red icon',
  title: t('Refund made'),
  description: `${t('A refund of')} ${Math.abs(value / 100)} ${Platforms.currencyISO} ${t('was processed')}`,
});
const cardPayment = (t: Tfunc, value: number): EventDetails => ({
  icon: 'credit card outline green icon',
  title: t('Card payment added'),
  description: `${t('A payment of')} ${value / 100} ${Platforms.currencyISO} ${t('was processed')}`,
});

// Booking information events

const customerUpdatedDetails = (t: Tfunc, event: EventType<CustomerUpdatedEventMetadata>): EventDetails => {
  const type = event.metadata;
  const changedFields = ((): string[] => {
    return recursiveDiff(type.from, type.to).filter((x) => validFields.includes(x as ValidField));
  })();

  // Check address fields

  if (changedFields.length > 1) return changedMultipleFields(t, changedFields);
  const field = changedFields[0];
  return changedOneField(t, field, getValueForPath(field, type.from), getValueForPath(field, type.to));
};

const priceCategoryDetails = (t: Tfunc, event: EventType<PriceCategoriesUpdatedEventMetadata>): EventDetails => {
  const type = event.metadata;
  const fromVisitors = type.from.reduce((sum, pc) => sum + visitors(pc), 0);
  const toVisitors = type.to.reduce((sum, pc) => sum + visitors(pc), 0);
  switch (true) {
    case fromVisitors > toVisitors:
      return removedCustomersEvent(t, fromVisitors - toVisitors, event.value);
    case fromVisitors < toVisitors:
      return addedCustomersEvent(t, toVisitors - fromVisitors, event.value);
    default:
      return defaultCustomerEvent(t);
  }
};

const changedMultipleFields = (t: Tfunc, fields: string[]): EventDetails => ({
  icon: 'address book outline icon',
  title: t('Booking information was changed'),
  description: `${t('Following fields were changed')}: ${fields
    .map((field) => translateCustomerDocumentField(t, field))
    .filter((x): x is string => !!x)
    .join(', ')}`,
});

const changedOneField = (t: Tfunc, field: string, from?: string, to?: string): EventDetails => {
  const valueOrEmpty = (v?: string) => {
    if (v === undefined) {
      return t('an empty field');
    }
    return `"${v}"`;
  };
  return {
    icon: 'address book outline icon',
    title: t('Booking information was changed'),
    description: `${t('Changed field')} "${translateCustomerDocumentField(t, field)}": ${t('from')} ${valueOrEmpty(
      from,
    )} ${t('to')} ${valueOrEmpty(to)}`,
  };
};

// Pricing events

const addedCustomersEvent = (t: Tfunc, difference: number, value: number): EventDetails => ({
  icon: 'calendar plus outline orange icon',
  title: `${t('Increased the number of visitors by')}: ${difference}`,
  description: `${t('Total increased by')}: ${value / 100} ${Platforms.currencyISO}`,
});

const removedCustomersEvent = (t: Tfunc, difference: number, value: number): EventDetails => ({
  icon: 'calendar minus outline orange icon',
  title: `${t('Decreased the number of visitors by')}: ${difference}`,
  description: `${t('Total decreased by')}: ${Math.abs(value / 100)} ${Platforms.currencyISO}`,
});

const defaultCustomerEvent = (t: Tfunc): EventDetails => ({
  icon: 'calendar outline icon',
  title: t('No changes were made to the number of visitors'),
  description: t('No value was changed'),
});

// Mail events

const successMail = (t: Tfunc, metadata: MailCreationEventMetadata): EventDetails => ({
  icon: 'send outline grey icon',
  title: `${t('Successfully sent')} ${t(metadata.mailType)} ${t('to')}: ${metadata.to}`,
});

const failedMail = (t: Tfunc, data: MailCreationEventMetadata): EventDetails => ({
  icon: 'send outline red icon',
  title: `${t('Failed to send')} ${t(data.mailType)} ${t('to')}: ${data.to}`,
});

const createdMailDetails = (t: Tfunc, event: EventType<MailCreationEventMetadata>): EventDetails => {
  const type = event.metadata;
  switch (type.success) {
    case true:
      return successMail(t, type);
    case false:
      return failedMail(t, type);
    default:
      return unknownEvent(t);
  }
};
