import * as React from 'react';
import { Consume, KosmicComponent, LocaleContext } from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'adventurehero-widget': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & { locale: string },
        HTMLElement
      >;
    }
  }
}

interface Props {}
interface State {
  hasLoaded: boolean;
}

export class WidgetTestingPage extends KosmicComponent<Props, State> {
  state: State = { hasLoaded: false };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  componentDidMount() {
    const script = document.createElement('script');
    script.src = `/v3/widget`;
    script.async = true;
    script.onload = () => {
      this.domElement
        .find('#widget-container')
        .append('<adventurehero-widget property="5f8d657cf24e3100002c8329" locale="sv"/>');
      this.domElement
        .find('#giftcard-widget-container')
        .append('<adventurehero-widget type="giftcards" property="5f8d657cf24e3100002c8329" locale="sv"/>');
      this.setState({ hasLoaded: true });
    };

    document.body.appendChild(script);
  }

  public render() {
    const { t } = this._locale;
    return (
      <div style={{ height: '100%', padding: '1em' }}>
        <h1 style={{ position: 'absolute', padding: '1em 1.5em 0em 0em', right: '0' }}>
          {t('Test the Widget')}&nbsp;
          <i className="cube icon" />
        </h1>
        <h2>{t('Common widget')}&nbsp;</h2>
        <div
          id="widget-container"
          className={`ui basic ${this.state.hasLoaded ? '' : 'loading'} container segment`}
          style={{ marginTop: '5rem' }}
        >
          {/* web component injected on mount */}
        </div>
        <h2>{t('Giftcard')}&nbsp;</h2>
        <div
          id="giftcard-widget-container"
          className={`ui basic ${this.state.hasLoaded ? '' : 'loading'} container segment`}
          style={{ marginTop: '5rem' }}
        >
          {/* web component injected on mount */}
        </div>
      </div>
    );
  }
}
