import { TranslatableText } from '..';

/**
 * Translate Text
 * Used to dynamically translate a TranslatableText object into a string based on the locale.
 * It even handles string and undefined values and will return an empty string if undefined was passed.
 *
 * @param strict Prevents fallback to other languages when translations are missing
 **/
export const tt = (text: string | undefined | TranslatableText, locale: Locale, strict?: boolean): string => {
  if (!text) return '';
  if (typeof text === 'string') return text;
  if (strict) return text[locale] || '';
  return text[locale] || text['en'] || text['sv'];
};
