import * as _ from 'lodash';
import * as React from 'react';
import { ChangableComponent } from './changable';
import { TextFormatRule } from './rules';

/**
 * An textarea is an input that accecpts multiple lines, it is not as versitile as an input components
 * but sometimes all you need is a big chunk of text!
 */
export class TextArea extends ChangableComponent<
  string,
  {
    /** A list of rules that applies to this component */ rules?: TextFormatRule[];
    /** Toggles a grabber in te lower right corner of the textarea that can be used to resize it, true by default */ resizable?: boolean;
    /** The number of rows the textarea should display */ rows?: number;
    /** A placeholder shown inside the input when otherwise empty */ placeholder?: string;
    /** Styleobject for textarea tag */ style?: {};
  }
> {
  updateComponentValue(value: string) {
    return value;
  }

  private get computedStyle() {
    const style: React.CSSProperties = this.props.resizable === false ? { resize: 'none' } : {};
    _.extend(style, this.props.style);
    return style;
  }

  private get computedProperties() {
    // Set default properties
    const props = {
      name: this.props.name,
      placeholder: undefined as any,
      onChange: (event) => {
        this.updateComponentValue(event.target.value);
      },
    };

    // Load properties from input rules
    _.forEach(this.props.rules || [], (rule: TextFormatRule) => {
      if (rule.placeholder) {
        props.placeholder = rule.placeholder;
      }
    });

    // Set the placeholder for the input
    props.placeholder = this.props.placeholder || props.placeholder;

    return props;
  }

  render() {
    const style = this.computedStyle;
    const props = this.computedProperties;
    const className =
      'ui textarea' +
      (this.props.className ? this.props.className : '') +
      (this.loading || this.props.loading ? ' loading' : '') +
      (this.disabled || this.props.disabled ? ' disabled' : '');
    return (
      <textarea
        value={this.value}
        defaultValue={this.props.defaultValue}
        rows={this.props.rows}
        style={style}
        className={className}
        disabled={this.disabled || this.props.disabled}
        {...props}
      />
    );
  }
}
