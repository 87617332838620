import { action, observable } from 'mobx';
import * as React from 'react';
import { MobxComponent } from './abstractComponent';

export class InlineErrorMessage extends MobxComponent<{}> {
  @observable private _header: React.ReactNode;
  @observable private _content: React.ReactNode | undefined;
  private _visible: boolean;

  @action public show = (header: React.ReactNode, content?: React.ReactNode) => {
    this._header = header;
    this._content = content;
    if (!this._visible) {
      this.domElement.transition({ animation: 'fade up in' });
    }
    this._visible = true;
  };

  @action public hide = () => {
    if (this._visible) {
      this.domElement.transition({ animation: 'fade up out' });
    }
    this._visible = false;
  };

  render() {
    return (
      <div className="ui error floating message transition hidden">
        <i className="close icon" onClick={this.hide}></i>
        <div className="header">{this._header}</div>
        <p>{this._content}</p>
        {this.props.children}
      </div>
    );
  }
}
