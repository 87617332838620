import { RollbackPaymentsFn, RollbackPaymentsResponse } from './payments.types';
import { Fetcher } from './utilities';

export const rollbackDelete =
  (fetcher: Fetcher): RollbackPaymentsFn =>
  async (params) => {
    const ids = params.paymentIds.map((id) => `&paymentIds[]=${id}`).join('');
    const res = await fetcher(`/function/remove-payments?orderId=${params.orderId}${ids}`, {
      method: 'DELETE',
    });
    const data: RollbackPaymentsResponse = await res.json();
    switch (res.status) {
      case 200:
        return data;
      default:
        // TODO: THIS NEEDS TO HAVE MORE EXPLICIT ERRORS
        throw data;
    }
  };
