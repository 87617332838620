import * as React from 'react';
import { Component } from './abstractComponent';

/** Semantic UI Modal */
export class Modal extends Component<{
  className?: string;

  /** Header content displayed at the top of the model */ header?: React.ReactNode;
  /** Action content displayed at the bottom of the accordion */ actions?: React.ReactNode;
  /** Toggle the X icon button on the modal */ closable?: React.ReactNode;
  /** Toggles the simpler style of the model */ basic?: boolean;
  /** An accordion can take up the width of the screen */ fullscreen?: boolean;
  /** The width of the modal */ size?: 'mini' | 'tiny' | 'small' | 'large';

  /** Is called after a positive, approve or ok button is pressed. If the function returns false, the modal will not hide. */
  onApprove?: () => boolean | void;
  /** Is called after a negative, deny or cancel button is pressed. If the function returns false the modal will not hide. */
  onDeny?: () => boolean | void;
  /** Is called when the modal starts the hide animation */
  onHide?: () => boolean | void;
  /** Is called when the modal has finished the hide animation */
  onHidden?: () => boolean | void;
}> {
  public componentDidMount() {
    this.domElement.modal({
      closable: this.props.closable || this.props.closable == undefined,
      onApprove: this.props.onApprove,
      onDeny: this.props.onDeny,
      onHide: this.props.onHide,
      onHidden: this.props.onHidden,
    } as any);
  }

  public show = () => {
    this.domElement.modal('show');
  };

  public hide = () => {
    this.domElement.modal('hide');
  };

  public refresh = () => {
    this.domElement.modal('refresh');
  };

  render() {
    const className =
      'ui modal ' +
      (this.props.basic ? ' basic ' : '') +
      (this.props.fullscreen ? ' fullscreen ' : '') +
      this.props.size;

    const closeIcon = this.props.closable ? (
      <i
        style={{ float: 'right', cursor: 'pointer' }}
        onClick={() => this.domElement.modal('hide')}
        className="close red icon"
      />
    ) : undefined;

    return (
      <div className={className}>
        {this.props.header && (
          <div className="header">
            {this.props.header}
            {closeIcon}
          </div>
        )}
        {this.props.children}
        {this.props.actions && <div className="actions">{this.props.actions}</div>}
      </div>
    );
  }
}
