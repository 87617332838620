import React, { useContext, useState } from 'react';
import { Checkbox, GiftCardPreview, MarketplaceLibrary as Lib } from '../_dependencies';
import { useLocale } from '../_locales';
import { DatalayerContext } from '../datalayer.context';
import { MarketplaceCartContext } from '../marketplace.cart.context';
import { WidgetDataContext } from '../widgetDataProvider';
interface Props {
  giftcard: Lib.CartGiftcard;
  translatableDate: (date: Date, format: string) => string;
  renderIcon: (icon: string) => JSX.Element;
  showToggle: boolean;
}

/**
 * This presents a giftcard as a product in the widget purchase process
 */
export const GiftcardItemTable = ({ giftcard, translatableDate, renderIcon, showToggle }: Props) => {
  const { t, tt } = useLocale();
  const { toggleHideGiftcardValue, removeItemFromCart } = useContext(MarketplaceCartContext);
  const { selectedStepId } = useContext(WidgetDataContext);
  const { sendRemoveFromCartEvent } = useContext(DatalayerContext);
  const [showPreview, setShowPreview] = useState(false);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const renderhideGiftcardValueToggle = () => {
    if (!showToggle) return null;
    return (
      <Checkbox
        style={{ fontSize: '1em' }}
        checked={giftcard.hideValue}
        label={t('Hide value')}
        onCheckedOrUncheked={() => toggleHideGiftcardValue(giftcard)}
      />
    );
  };

  if (showPreview) {
    return (
      <div
        style={{
          position: 'relative',
          border: '1px solid rgba(34,36,38,.1)',
          borderRadius: '.2em',
          marginBottom: '1rem',
        }}
      >
        <GiftCardPreview giftCard={giftcard} />
        <div
          style={{
            position: 'absolute',
            top: '.2rem',
            right: '.2rem',
            margin: '.5em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <a
            style={{
              backgroundColor: '#FFF',
              cursor: 'pointer',
              padding: '.3em',
              borderRadius: '.2em',
              marginBottom: '.5em',
            }}
            onClick={togglePreview}
          >
            {t('Close preview')}
            <i className="close icon" style={{ paddingLeft: '.5em' }} />
          </a>
          <div style={{ backgroundColor: '#FFF', padding: '.3em', borderRadius: '.2em' }}>
            {renderhideGiftcardValueToggle()}
          </div>
        </div>
      </div>
    );
  }

  const renderRemoveItem = () => {
    const { selectedOrganization } = useContext(WidgetDataContext);

    if (selectedStepId !== 'customerInfo') return null;
    const removeFromCart = () => {
      sendRemoveFromCartEvent(giftcard, selectedOrganization);
      removeItemFromCart(giftcard);
    };
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => removeFromCart()}>
        <a>{t('Remove')}</a>
        <i className="trash icon" style={{ padding: '0 .5em' }} />
      </div>
    );
  };

  return (
    <div style={{ marginBottom: '1rem', backgroundColor: '#FFF' }}>
      <table
        className="ui very basic unstackable celled table"
        style={{ border: '1px solid rgba(34,36,38,.1)', padding: '0 .5rem 0 0' }}
      >
        <tbody>
          <tr>
            {renderIcon('gift')}
            <td>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong style={{ fontSize: '1.03em' }}>
                  {t('Giftcard')}: {tt(giftcard.title)}
                </strong>
                <a onClick={togglePreview} style={{ cursor: 'pointer' }}>
                  {t('See preview')}
                </a>
              </div>
            </td>
          </tr>
          <tr>
            {renderIcon('wait')}
            <td>
              <strong>{t('Expiration date')}</strong>
              <br />
              {translatableDate(giftcard.expirationDate, 'DD MMM YYYY')}
            </td>
          </tr>
          <tr>
            {renderIcon('ticket')}
            <td>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>{t('Giftcard value')}</strong>
                {renderhideGiftcardValueToggle()}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{`${giftcard.value} ${t('SEK')}`}</span>
                <em style={{ fontSize: '.9em' }}>
                  {giftcard.hideValue
                    ? t('The value will not be presented to the recipient')
                    : t('The value will be displayed to the recipient')}
                </em>
              </div>
            </td>
          </tr>
          <tr>
            {renderIcon('money')}
            <td style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>{`${t('Unit price')}: `}</strong>
                {`${giftcard.value} ${t('SEK')}`}
              </div>
              {renderRemoveItem()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
