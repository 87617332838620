import Lodash from 'lodash';
import { action, autorun, computed, observable } from 'mobx';
import React, { CSSProperties } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { Form } from '.';
import { Consume } from '../../contexts/consume';
import { GridFsDocument, GridFsResource, LocaleContext } from '../../_dependencies';
import { KosmicLocaleContext } from '../../_locales';
import { MobxComponent, Slider } from '../legacy';
import { FileUploadForm } from './fileForm';

export type imageUploadingStep = 'select' | 'crop' | 'done';

// TODO: rewrite this component so that it doesn't use ref since it causes multiple function calls, etc...
export class ImageUploadForm extends MobxComponent<{
  /** The grid fs resource to upload the file to */ resource: GridFsResource<GridFsDocument>;
  /** Specifies the width of the drop and cropping container */ width: number;
  /** Specifies the height of the drop and cropping container */ height: number;
  /** Passes the style down to FileUploadForm */ uploadFromStyle?: any;
  /** Sets platform specific colors to slider and buttons */ specifiedStyle?: any;
  /** The step that the this component wills start at */ startingStep?: imageUploadingStep;
  /** Disables the cropping feature */ disableCropping?: boolean;
  /** Event that is triggered when the user goes to the next step */ onNewStep?: (
    step: imageUploadingStep,
    imageUrl: string,
  ) => void;
  /** Use this submit function to save additional data when the file has been saved */ onImagedSaved?: (
    imageId,
    imageUrl,
    values,
    resolve,
    reject,
  ) => void;
  /** Displays the element or text pragraph at the top of this component */ helpText?: any;
  /** Saves the image on the last step, this wont work with form content, like input fields... */ saveImageOnLastStep?: boolean;
  /** Visible on select step, takes user to done step */ showSkipButton?: boolean;
  /** URL of the default image, this is used to show a preview if starting step is done */ defaultImage?: string;
  /** Hides all the UI */ hideUI?: boolean;
  /** A ref to the form that can be used to trigger functions like validate and more */ formRef?: React.Ref<Form>;
  /** Displays a preview at of the image at the last step */ showPreview?: boolean;
  /** Sets the maximun width of the preview */ previewMaxWidth?: CSSProperties['maxWidth'];
  /** Setgs the flex direction of the preview*/ previewFlexDirection?: CSSProperties['flexDirection'];
}> {
  @observable public _currentStep: imageUploadingStep = this.props.startingStep || 'select';
  @observable private _isDraggingImage = false;
  @observable private _loadedDropzoneFile: any;
  @observable private _scale = 1;
  @observable private _fileToUpload: { fileName: string; blob: Blob; fileURL: string };

  @Consume(LocaleContext)
  private _locale: KosmicLocaleContext;

  private _fileUploadForm: FileUploadForm;
  private _avatarEditor: AvatarEditor;
  private _disposer: Function;

  private defaultStyle: this['props']['specifiedStyle'] = {
    submitButtonColor: '',
    sliderColor: '',
  };

  get specifiedStyle(): this['props']['specifiedStyle'] {
    return Lodash.merge(this.defaultStyle, this.props.specifiedStyle || {});
  }

  componentDidMount() {
    this._disposer = autorun(() => {
      if (this.props.onNewStep) {
        this.props.onNewStep(this._currentStep, this.fileUrl);
      }
    });
  }

  componentWillUnmount() {
    this._disposer();
  }

  /** CSS wrapper styling to fix aspectRatio */
  private get fixedAspectRatioWrapper(): CSSProperties {
    return {
      width: '100%',
      paddingBottom: `${(this.props.height / this.props.width) * 100}%` /* add padding of the ratio */,
      position: 'relative',
    };
  }

  /** CSS wrapper styling to fix aspectRatio */
  private get fixedAspectRatioWrapperContent(): CSSProperties {
    return {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
  }

  @action onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      this._isDraggingImage = false;
      this._loadedDropzoneFile = acceptedFiles[0];

      // Save image info to the _fileToUpload object
      const reader = new FileReader();
      reader.onload = () => {
        this._fileToUpload = {
          fileName: this._loadedDropzoneFile.name,
          fileURL: reader.result as string,
          blob: this.dataURItoBlob(reader.result),
        };
        this._currentStep = this.props.disableCropping ? 'done' : 'crop';
      };
      reader.readAsDataURL(this._loadedDropzoneFile);
    } else {
      this.showErrorMessage();
    }
  };

  @computed public get fileUrl() {
    return this._fileToUpload ? this._fileToUpload.fileURL : '';
  }

  private showErrorMessage = () => {
    this.domElement.find('.activated.content').transition({
      animation: 'fade down out',
      duration: 50,
      onComplete: () => {
        this.domElement.find('.faulty.content').transition({
          animation: 'fade up in',
          duration: 300,
          onComplete: () => {
            setTimeout(() => {
              this.domElement.find('.faulty.content').transition({
                animation: 'fade down out',
                duration: 50,
                onComplete: () => {
                  this.domElement.find('.default.content').transition({
                    animation: 'fade up in',
                    duration: 300,
                    onComplete: () => {
                      this._isDraggingImage = false;
                    },
                  });
                },
              });
            }, 1300);
          },
        });
      },
    });
  };

  private onImageSaved = (imageId, values, resolve, reject) => {
    if (this.props.onImagedSaved) {
      this.props.onImagedSaved(imageId, this.fileUrl, values, resolve, reject);
    }
  };

  private onSelectNewImage = () => {
    this._currentStep = 'select';
  };

  private onSkip = () => {
    this._currentStep = 'done';
  };

  private onCroppingFinished = () => {
    const fileURL = this._avatarEditor.getImageScaledToCanvas().toDataURL('image/jpeg', 0.8);
    this._fileToUpload = {
      fileName: this._loadedDropzoneFile.name,
      blob: this.dataURItoBlob(fileURL),
      fileURL: fileURL,
    };
    this._currentStep = 'done';
  };

  private get selectImageContent() {
    return (
      <div style={this.fixedAspectRatioWrapper}>
        <div style={this.fixedAspectRatioWrapperContent}>
          <DropzoneForImage onDrop={this.onDrop} />
        </div>
      </div>
    );
  }

  private updateScaleValue = (value) => {
    this._scale = value;
  };

  private dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  @computed private get croppingContent() {
    return (
      <div>
        <div style={this.fixedAspectRatioWrapper}>
          <div style={this.fixedAspectRatioWrapperContent}>
            <AvatarEditor
              image={this._loadedDropzoneFile ? this._loadedDropzoneFile.preview : this.props.defaultImage}
              width={this.props.width}
              height={this.props.height}
              border={0}
              ref={(self) => (this._avatarEditor = self)}
              color={[255, 255, 255, 0.6]}
              scale={this._scale}
              style={{ width: '100%', cursor: 'move', borderRadius: '5px' }}
            />
          </div>
        </div>
        <div style={{ height: '1em' }} />
        <Slider max={5} min={1} step={0.01} defaultValue={this._scale} onChange={this.updateScaleValue}>
          <div
            className="circular ui tiny blue icon button"
            style={{ backgroundColor: this.specifiedStyle.sliderColor, marginRight: 0 }}
          >
            <i className="circle icon" />
          </div>
        </Slider>
        <div className="ui large divider" style={{ borderWidth: '2px' }} />
      </div>
    );
  }

  @computed private get showPreview() {
    const { showPreview, previewMaxWidth, defaultImage } = this.props;
    if (showPreview && this._currentStep == 'done') {
      return (
        <img
          className="ui fluid image"
          src={this._fileToUpload?.fileURL || defaultImage}
          style={{ maxWidth: previewMaxWidth }}
        />
      );
    }
  }

  private get buttons() {
    const { t } = this._locale;
    let newImageButton: any;
    let doneButton: any;

    if (this._currentStep != 'select') {
      newImageButton = (
        <button type="button" className="ui left floated basic button" onClick={this.onSelectNewImage}>
          {t('kosmic.components.legacy.form.imageForm.selectImage')}
        </button>
      );
    }
    if (this._currentStep == 'select' && this.props.showSkipButton) {
      newImageButton = (
        <button type="button" className="ui left floated basic button" onClick={this.onSkip}>
          {t('Cancel')}
        </button>
      );
    }
    if (this._currentStep == 'crop') {
      const buttonContent = this.props.saveImageOnLastStep ? (
        t('Save')
      ) : (
        <p>
          {t('kosmic.components.legacy.form.imageForm.continue')}
          <i className="arrow circle right icon" />
        </p>
      );
      doneButton = (
        <button
          type="button"
          className="ui right floated blue button"
          style={{ backgroundColor: this.specifiedStyle.submitButtonColor }}
          onClick={this.onCroppingFinished}
        >
          {buttonContent}
        </button>
      );
    }

    return (
      <div>
        {doneButton}
        {newImageButton}
      </div>
    );
  }

  @computed private get content() {
    let content;
    if (this._currentStep == 'select') {
      content = this.selectImageContent;
    } else if (this._currentStep == 'crop') {
      content = this.croppingContent;
    } else {
      content = this.showPreview;
    }
    return content;
  }

  render() {
    const { helpText, saveImageOnLastStep, formRef, uploadFromStyle, resource, previewFlexDirection, hideUI } =
      this.props;
    const helpTextString = !helpText || this._currentStep == 'done' ? undefined : helpText;
    const flexDirection = this._currentStep == 'done' ? previewFlexDirection || 'column' : 'column';
    return (
      <FileUploadForm
        formRef={formRef}
        style={uploadFromStyle}
        resource={resource}
        onFileSaved={this.onImageSaved}
        fileToUpload={this._fileToUpload}
        submitAndUploadImage={saveImageOnLastStep && this._currentStep == 'done'}
      >
        {hideUI ? (
          this.props.children
        ) : (
          <div>
            {helpTextString}
            <div style={{ display: 'flex', flexDirection, gap: this._currentStep !== 'select' ? '1rem' : undefined }}>
              {this.content}
              {this.buttons}
            </div>
            {this._currentStep == 'done' && this.props.children}
          </div>
        )}
      </FileUploadForm>
    );
  }
}

interface ImageDropzoneProps {
  onDrop?: (acceptedFiles: any[], rejectedFiles: any[]) => void;
  onImageAsURL?: (url: string, name: string, type: string) => void;
  noHeight?: boolean;
  accept?: string;
}
export class DropzoneForImage extends MobxComponent<ImageDropzoneProps> {
  private dropzone: Dropzone;
  private isDraggingImage: boolean;
  private previousImage: any;
  @observable private dropzonImage: any;

  @Consume(LocaleContext)
  private _locale: KosmicLocaleContext;

  private openFileSelection = () => this.dropzone.open();

  private onDragEnter = () => {
    if (this.isDraggingImage) {
      return;
    }
    this.isDraggingImage = true;
    this.domElement.find('.default.content').transition({
      animation: 'fade down out',
      duration: 50,
      onComplete: () => {
        this.domElement.find('.activated.content').transition({ animation: 'fade up in', duration: 300 });
      },
    });
  };

  private onDragLeave = () => {
    this.isDraggingImage = false;
    this.domElement.find('.activated.content').transition({
      animation: 'fade up out',
      onComplete: () => {
        this.domElement.find('.default.content').transition({ animation: 'fade down in' });
      },
    });
  };

  private onDrop = (accepted, rejected) => {
    if (this.props.onDrop) {
      this.props.onDrop(accepted, rejected);
    }

    if (accepted.length) {
      this.dropzonImage = accepted[0];

      if (this.props.onImageAsURL) {
        const reader = new FileReader();
        reader.onload = () => {
          this.props.onImageAsURL!(reader.result as string, this.dropzonImage.name, this.dropzonImage.type);
        };
        reader.readAsDataURL(this.dropzonImage);
      }
    }
  };

  private get dropzoneStyle(): React.CSSProperties {
    return {
      width: '100%',
      height: this.props.noHeight ? undefined : '100%',
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center' /* align horizontal */,
      alignItems: 'center' /* align vertical */,
    };
  }

  render() {
    const { t } = this._locale;

    return (
      <Dropzone
        ref={(node) => (this.dropzone = node)}
        onDrop={this.onDrop}
        accept={this.props.accept || 'image/*'}
        className="ui grey message"
        activeClassName="ui primary message"
        style={this.dropzoneStyle}
        disableClick
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
      >
        <div className="default content">
          <i className="huge icons">
            <i className="image icon" />
            <i className="corner plus icon" />
          </i>
          <br />

          <h3 style={{ marginTop: 0 }}>{t('kosmic.components.legacy.form.imageForm.dragAndDropImage')}</h3>
          <button type="button" className="ui blue button" onClick={this.openFileSelection}>
            {t('kosmic.components.legacy.form.imageForm.orClickHere')}
          </button>
        </div>
        <div className="activated content transition hidden">
          <i className="huge icons">
            <i className="image icon" />
            <i className="corner plus icon" />
          </i>
          <br />
          <h3 style={{ marginTop: 0 }}>{t('kosmic.components.legacy.form.imageForm.dropImage')}</h3>
        </div>
        <div className="faulty content transition hidden">
          <i className="huge red image icon" />
          <br />
          <h3 className="ui red header" style={{ marginTop: 0 }}>
            {t('kosmic.components.legacy.form.imageForm.notAnImage')}
          </h3>
        </div>
      </Dropzone>
    );
  }
}
