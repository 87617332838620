import Lodash from 'lodash';
import { action } from 'mobx';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AHLocaleContext } from '../../../_locales';
import {
  Alert,
  Consume,
  LocaleContext,
  ObservingComponent,
  SearchableList,
  SearchListBar,
  UserDocument,
  UserResource,
} from '../../../_dependencies';
import { Maybe } from '../../maybe';
import { EmployeeItem, EmployeeItemProps } from '../employees/employeeItem';
import { PropertyStore } from './propertyStore';

// TODO: Merge PropertyEmployeesView with PropertyEditEmployeesView
export class PropertyEmployeesView extends ObservingComponent<{}, PropertyStore> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get additionalTemplateProps() {
    const props = {
      store: this.store,
      isEditMode: false,
    } as EmployeeItemProps;

    return props;
  }

  componentDefaultStore() {
    return PropertyStore.Instance;
  }

  componentDidUpdate() {
    this.store.EmployeeList.ListStaff();
  }
  componentDidMount() {
    this.componentDidUpdate();
  }

  render() {
    const { t } = this._locale;
    return (
      <div className="ui basic segment" style={{ padding: '2em', paddingRight: '230px' }}>
        <h2 className="ui header">
          {t('Property personnel list')}
          <div className="sub header">{t('components.dashboard.properties.employeesView.personnelRoles')}</div>
        </h2>
        <div style={{ height: '2em' }} />
        <div
          className="ui right internal tiny attached rail"
          style={{
            width: '230px',
            padding: '1em',
            borderLeft: '1px solid #eaeaed',
          }}
        >
          <div className="ui fluid large secondary vertical menu">
            <div className="item">
              <div className="ui fluid small left action input">
                <div className="ui small icon button">
                  <i className="search icon" />
                </div>
                <SearchListBar placeholder={t('Find employees...')} store={this.store.EmployeeList} />
              </div>
            </div>
            <div className="item">
              <Link
                to={'/dashboard/properties/details/' + this.store.property.id + '/employees/edit'}
                className="ui fluid small primary button"
              >
                {t('Change personnel list')}
              </Link>
            </div>
          </div>
        </div>
        <SearchableList
          className="ui divided items"
          template={EmployeeItem}
          additionalTemplateProps={this.additionalTemplateProps}
          store={this.store.EmployeeList}
          showDefaultSearchBar={false}
        />
      </div>
    );
  }
}

// TODO: Merge PropertyEmployeesView with PropertyEditEmployeesView
export class PropertyEditEmployeesView extends ObservingComponent<{}, PropertyStore> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @action private addPropertyCrew = (employee: UserDocument): any => {
    this.store.property.crew.push(employee._id);
    employee.roles.push({
      type: 'property.crew',
      property: this.store.property._id,
      organization: this.store.property.organization,
    });
    this.saveEmployeeItem(employee);
  };

  @action private removePropertyCrew = (employee: UserDocument) => {
    Lodash.remove(this.store.property.crew, (crewId) => crewId == employee._id);
    Lodash.remove(employee.roles, (role) => role.type == 'property.crew' && role.property == this.store.property._id);
    this.saveEmployeeItem(employee);
  };

  @action private addPropertyManager = (employee: UserDocument) => {
    this.store.property.managers.push(employee._id);
    employee.roles.push({
      type: 'property.manager',
      property: this.store.property._id,
      organization: this.store.property.organization,
    });
    this.saveEmployeeItem(employee);
  };

  @action private removePropertyManager = (employee: UserDocument) => {
    Lodash.remove(this.store.property.managers, (managerId) => managerId == employee._id);
    Lodash.remove(
      employee.roles,
      (role) => role.type == 'property.manager' && role.property == this.store.property._id,
    );
    this.saveEmployeeItem(employee);
  };

  private saveEmployeeItem = (employee: UserDocument) => {
    const userResource = new UserResource();
    const { t } = this._locale;
    userResource.updateDocument(userResource.createDocument(employee)).catch(() => {
      Alert.show(
        t('components.dashboard.properties.employeesView.userNotSaved'),
        t('components.dashboard.properties.employeesView.oops'),
        'error',
      );
    });
  };

  private get additionalTemplateProps() {
    const props = {
      store: this.store,
      isEditMode: true,
      addPropertyCrew: this.addPropertyCrew,
      removePropertyCrew: this.removePropertyCrew,
      addPropertyManager: this.addPropertyManager,
      removePropertyManager: this.removePropertyManager,
    } as EmployeeItemProps;

    return props;
  }

  componentDefaultStore() {
    return PropertyStore.Instance;
  }

  componentDidUpdate() {
    this.store.EmployeeList.ListAll();
  }
  componentDidMount() {
    this.componentDidUpdate();
  }

  render() {
    const { t, tt } = this._locale;
    return (
      <div style={{ padding: '1em', paddingRight: '250px' }}>
        <div
          className="ui right internal tiny attached rail"
          style={{
            width: '230px',
            padding: '1em',
            borderLeft: '1px solid #eaeaed',
          }}
        >
          <div className="ui fluid large secondary vertical menu">
            <div className="item">
              <div className="ui fluid small left action input">
                <div className="ui small icon button">
                  <i className="search icon" />
                </div>
                <SearchListBar placeholder={t('Find employees...')} store={this.store.EmployeeList} />
              </div>
            </div>
            <div className="item">
              <Link
                to={'/dashboard/properties/details/' + this.store.property.id + '/employees'}
                className="ui fluid small labeled icon blue button"
              >
                <i className="checkmark icon" />
                {t('message-done')}
              </Link>
            </div>
            <Maybe if="organization.manager">
              <div className="item">
                <Link to={'/dashboard/employees/new'} className="ui fluid small labeled icon button">
                  <i className="add user icon" />
                  {t('components.dashboard.properties.employeesView.addEmployee')}
                </Link>
              </div>
            </Maybe>
          </div>
        </div>
        <div style={{ padding: '1em' }}>
          <h2 className="ui header" style={{ paddingTop: 0 }}>
            {t('Edit personnel list')}
            <div className="sub header">
              {t('components.dashboard.properties.employeesView.showAllEmployees')}{' '}
              <b>{this.globals.session.currentOrganization.name}</b>,{' '}
              {t('components.dashboard.properties.employeesView.chooseEmployees')}
              <b>{tt(this.store.property.name)}</b>
            </div>
          </h2>
          <div style={{ height: '2em' }} />
          <SearchableList
            className="ui divided items"
            template={EmployeeItem}
            additionalTemplateProps={this.additionalTemplateProps}
            store={this.store.EmployeeList}
            showDefaultSearchBar={false}
          />
        </div>
      </div>
    );
  }
}
