import React from 'react';
import { MobxComponent, OrganizationDocument, LocaleContext, Consume } from '../../../_dependencies';
import { ErrorBoundary } from '../../errorBoundary';
import { LanguageToggleIcons } from '../../languageToggleIcons';
import { Maybe } from '../../maybe';
import { AccountActivationSegment, BadgeSegment, HelpSegment, PayoutSegment, SettingsSegment } from './segments';
import { PulSegment } from './segments/pulsegment';
import { TmeTicketsStatsSegment } from './segments/tmeTicketsStatsSegment';
import ToggleCalendar from './segments/toggleCalendar';

interface State {
  calendarIsShowing: 'payouts' | 'statistics';
}

export class DashboardIndex extends MobxComponent<{}> {
  state: State = {
    calendarIsShowing: 'payouts',
  };

  toggleCalendarEventHandler = () => {
    this.setState(() => ({
      calendarIsShowing: this.state.calendarIsShowing === 'payouts' ? 'statistics' : 'payouts',
    }));
  };

  render() {
    const organization: OrganizationDocument = this.globals.session.currentOrganization;
    const { isMobileSize } = this.globals.device;
    const { features } = organization.flags;

    return (
      <div className="ui basic segment">
        {!isMobileSize && <LanguageToggleIcons style={{ position: 'absolute', zIndex: 100 }} />}
        <div className="ui one column centered grid">
          <div className="column">
            <h2 className="ui center aligned header" style={{ marginTop: '1rem' }}>
              {organization.name}
            </h2>
          </div>
        </div>
        <Maybe if="organization.manager" and={features.bookings || features.giftcards}>
          <div className="ui one column centered grid">
            <div className="column" style={{ maxWidth: '1000px' }}>
              <ErrorBoundary compact>
                <AccountActivationSegment />
              </ErrorBoundary>
            </div>
          </div>
        </Maybe>
        <div className="ui one column centered grid">
          <div className="column" style={{ maxWidth: '1000px' }}>
            <div className="ui stackable centered grid">
              <div className="ui eight wide column">
                <ErrorBoundary compact>
                  <HelpSegment />
                </ErrorBoundary>
                <ErrorBoundary compact>
                  <BadgeSegment />
                </ErrorBoundary>
              </div>
              <Maybe if="organization.manager">
                <div className="ui eight wide column">
                  <Maybe and={features.giftcards || features.bookings}>
                    <ErrorBoundary compact>
                      <SettingsSegment />
                    </ErrorBoundary>
                  </Maybe>
                  <Maybe and={features.bookings || features.giftcards}>
                    <ErrorBoundary compact>
                      <Maybe and={features.tmeTickets}>
                        <ToggleCalendar
                          calendarShowing={this.state.calendarIsShowing}
                          toggleCalendarEventHandler={this.toggleCalendarEventHandler}
                        />
                      </Maybe>
                      {this.state.calendarIsShowing === 'payouts' && <PayoutSegment />}
                      {this.state.calendarIsShowing === 'statistics' && features.tmeTickets && (
                        <TmeTicketsStatsSegment />
                      )}
                    </ErrorBoundary>
                  </Maybe>
                  <PulSegment />
                </div>
              </Maybe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
