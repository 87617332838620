import * as React from 'react';
import { observable } from 'mobx';
import { MobxComponent, PropertyResource } from '../../_dependencies';

export class PropertyLocationUpdater extends MobxComponent<any> {
  private nrOfUpdatedProperties: number;
  @observable private isUpdateComplete = false;
  @observable private didUpdateFail = false;

  private get message() {
    if (this.isUpdateComplete) {
      return `Update complete - ${this.nrOfUpdatedProperties} updates!`;
    } else if (this.didUpdateFail) {
      return 'Update failed';
    } else {
      return 'Updating property locations...';
    }
  }

  private get centeredContainerStyle(): React.CSSProperties {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    };
  }

  componentDidMount() {
    new PropertyResource()
      .updateAllLocations()
      .then((res) => {
        this.nrOfUpdatedProperties = res.count;
        this.isUpdateComplete = true;
      })
      .catch((err) => {
        this.didUpdateFail = true;
        console.error(err);
      });
  }
  render() {
    return (
      <div style={this.centeredContainerStyle}>
        <h1 style={{ fontSize: '10em' }}>🧚</h1>
        <p style={{ fontSize: '2em' }}>{this.message}</p>
      </div>
    );
  }
}
