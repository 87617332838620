import Lodash from 'lodash';
import Moment from 'moment';
import { Schema, Types } from 'mongoose';
import * as React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTagsFormatter,
  ActivityTypeDocument,
  ActivityTypeResource,
  ActivityTypeSearchSettings,
  AvailableActivityTimeSlotResource,
  Consume,
  IStateSetter,
  LocaleContext,
  OrganizationDocument,
  Platforms,
  PropertyDocument,
  PropertyResource,
  ResourceProvider,
  ScheduleDocument,
} from '../_dependencies';
import { AHLocaleContext } from '../_locales';
import { AdvancedDatePickerType } from '../components/advancedDatePicker';

/** Cache object */
class Cache<T> {
  /** Max age of cache */
  public maxAge = Moment();
  /** Set default value of cached data */
  constructor(public data: T) {}
  /** Checks if cache is valid */
  public isValid = () => this.maxAge.isAfter(Moment());
}

/** Results returned from a activity type search */
export interface ActivityTypeSearchResults {
  [propertyId: string]: {
    /** The property document for this id */ property: PropertyDocument;
    /** Map of bookable activity types at this property */ offers: {
      [activityTypeId: string]: {
        /** The originating activity type document */ offer: ActivityTypeDocument;
        /** List of max 1 bookable occurance for this activity type  */ occurances: ActivityOccuranceDocument[];
      };
    };
  };
}

/** Map with string keys */
interface IStringMap<T> {
  [key: string]: T | undefined;
}

/** ActivityStartTime interface */
interface IActivityStartTime {
  /** StartTimes associated with activity and schedule */
  startTimes: Moment.Moment[];
  /** Schedule object that sets the base for startTimes */
  originatingSchedule: ScheduleDocument;
  /** Acivity object that sets the base for startTimes */
  originatingActivity: ActivityTypeDocument;
}

/** Possible options passed to performActivityTypeSearch */
interface IActivityTypeSearchOptions {
  /** Earliest possible start time */
  searchStartTime: Date;
  /** Specified categories used as search params */
  categories: string[];
  /** City or zipcode used as search param */
  cityOrZipcode: string;
  /** Free text used as search param */
  freeText: string;
  /** The type of date picker used in search */
  pickerMode: AdvancedDatePickerType;
  /** Set to true if the search is for freelancers */
  isFreelance?: boolean;
  /** Set to get the search results only for a single property */
  propertyId?: Types.ObjectId;
  /** Set to true if organizations should be populated */
  populateOrganizations?: boolean;
}

/** Exported context variables and methods */
export interface IActivityStartTimes extends IStateSetter<IActivityStartTimes> {
  performActivityTypeSearch: (opts: IActivityTypeSearchOptions) => Promise<ActivityTypeSearchResults>;
}

/**
 * ActivityStartTimesContext
 * TODO: Save caches in local storage
 */
export class ActivityStartTimesContext extends ResourceProvider<IActivityStartTimes> {
  /** Declares the context to use */
  public static Context = React.createContext<IActivityStartTimes>({} as any);

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  /** Access value for activity type search method */
  private typeSearchBusy = false;

  /** Exposes the context to use */
  protected use(): React.Context<IActivityStartTimes> {
    return ActivityStartTimesContext.Context;
  }

  /** Declares the initial state */
  protected initialState(): IActivityStartTimes {
    return {
      performActivityTypeSearch: this.performActivityTypeSearch.bind(this),
      setState: this.setState.bind(this),
    };
  }

  /** Performs a busy check */
  private async busyCheck() {
    // Check if not busy
    if (!this.typeSearchBusy) {
      // Lock access
      this.typeSearchBusy = true;
      return;
    }
    // Define timer function
    const wait = () =>
      new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
    // Wait for timer
    await wait();
    // Recurse check
    return await this.busyCheck();
  }

  /**
   * Creates a search result containing properties and a single
   * occurance of each possible activity type that can be booked
   * as early as possible from the given start time.
   *
   * Calculates the possible start times by looking at employee
   * assignments to offers and the employees scheduling at each property.
   */
  public async performActivityTypeSearch(opts: IActivityTypeSearchOptions): Promise<ActivityTypeSearchResults> {
    const { searchStartTime, categories, cityOrZipcode, freeText, pickerMode, isFreelance = false } = opts;

    // Check feature flag
    if (!Platforms.features.offersCanBeAssignedToEmployees) {
      throw new Error('Tried to perform a search using available activity time slots on an unsuported platform');
    }

    console.time('performActivityTypeSearch');

    // Makes sure only on caller can execute function at a time
    await this.busyCheck();

    // Format start and end time
    const startAndEndtime = this.formatStartAndEndtime(opts.searchStartTime, opts.pickerMode);

    // Get the zipcode if possible
    const isZipcode = !!cityOrZipcode && !!cityOrZipcode.match(/^[0-9]{5}.*/);
    let cityBasedOnZipcode = '';
    if (isFreelance && isZipcode) {
      try {
        cityBasedOnZipcode = await this.sendRequest<string>('/getCityBasedOnZipcode', 'post', { cityOrZipcode });
      } catch (err) {
        console.error('Unable to dermine city based on zipcode');
        console.error(err);
      }
    }

    // Build search settings object
    const searchSettings: ActivityTypeSearchSettings = {
      searchStartTime,
      categories,
      pickerMode,
      freeText,
      startAfterDate: startAndEndtime.startAfterDate,
      startBeforeDate: startAndEndtime.startBeforeDate,
    };

    const unsortedCollectedTimeslots = await new AvailableActivityTimeSlotResource().find({
      $and: [{ start: { $gt: searchSettings.startAfterDate } }, { start: { $lt: searchSettings.startBeforeDate } }],
    });
    const collectedTimeslots = Lodash.sortBy(unsortedCollectedTimeslots, 'start');
    const uniqueOfferAndPropertyPair = Lodash(collectedTimeslots)
      .uniqBy(({ offer, property }) => property.toString() + offer.toString())
      .uniq()
      .value();
    const uniqueOffers = Lodash(collectedTimeslots)
      .map(({ offer }) => offer.toString())
      .uniq()
      .value();
    const uniqueProperties = Lodash(collectedTimeslots)
      .map(({ property }) => property.toString())
      .uniq()
      .value();

    const properties = await new PropertyResource().find({ _id: { $in: uniqueProperties } }, ['organization']); //DEPRECATED SO LEAVING THIS FOR NOW.
    const offers = await new ActivityTypeResource().find({
      _id: { $in: uniqueOffers },
    });

    const resultMap: ActivityTypeSearchResults = {};

    for (const property of properties) {
      const organization = property.organization as any as OrganizationDocument; // NOTE: assumes data is populated

      // Skip suspended organizations
      if (organization.isSuspended) {
        continue;
      }

      // Skip any saloon or freelance organizations based on search
      if (opts.isFreelance != organization.isFreelancer) {
        continue;
      }

      // Skip any property not matching the location based search
      if (!this.checkCityAndZipCodeMatch(property, cityOrZipcode, isZipcode, isFreelance, cityBasedOnZipcode)) {
        continue;
      }

      // Handle missing adresses
      if (!isFreelance && (!property.address || !property.address.postNr || !property.address.postOrt)) {
        continue;
      }

      const propertyOffers: ActivityTypeSearchResults['property']['offers'] = {};
      const propertyId = property._id;
      const offerIdsInThisProperty = Lodash(uniqueOfferAndPropertyPair)
        .filter(({ property }) => property == propertyId)
        .map(({ offer }) => offer)
        .value();

      // Add the offer to the property if its included
      for (const offer of offers) {
        // Skip this offer if it matches no searched for categories
        if (
          !(
            !opts.categories ||
            !opts.categories.length ||
            Lodash.intersection(offer.categoryTags || [], opts.categories).length > 0
          )
        ) {
          continue;
        }

        // Skip this offer based on the free text entered
        if (!this.checkFreetextMatch(property, freeText, offer)) {
          continue;
        }

        if (Lodash(offerIdsInThisProperty).includes(offer._id)) {
          // Filter out activities where booking has already closed and find the first available time slot
          const firstTimeSlot = Lodash.find(collectedTimeslots, (slot) => {
            if (slot.offer == offer._id && slot.property == propertyId) {
              if (
                offer.bookingClosesBeforeEventInHours &&
                Moment(slot.start)
                  .subtract(offer.bookingClosesBeforeEventInHours, 'hours')
                  .isSameOrBefore(searchSettings.startAfterDate)
              ) {
                return false;
              }
              return true;
            }
            return false;
          });

          // Skip this offer if no possible start time was found
          if (!firstTimeSlot) {
            continue;
          }

          // Create a occurance for this start time
          const start = firstTimeSlot.start;
          const end = Moment(start).add(offer.duration, 'minutes').toDate();
          const bookingClosesAtDate = Moment(start)
            .subtract(offer.bookingClosesBeforeEventInHours || 0, 'hours')
            .toDate();
          const refundableUntilInHours = offer.refundableUntilInHours;
          const preparationStart = Moment(start)
            .subtract(offer.preparationDuration || 0, 'minutes')
            .toDate();
          const cleanupEnd = Moment(end)
            .add(offer.cleanupDuration || 0, 'minutes')
            .toDate();
          const occurance = new ActivityOccuranceResource().createDocument({
            start,
            end,
            bookingClosesAtDate,
            refundableUntilInHours,
            preparationStart,
            cleanupEnd,
            title: offer.title,
            property: property._id,
            originatingActivity: offer._id,
            organization: property.organization,
            priceCategories: offer.priceCategories || [],
            visitorCapacity: offer.visitorCapacity || 0,
          });

          // Add populated fields
          occurance.set('property', property, Schema.Types.Mixed);
          occurance.set('originatingActivity', offer, Schema.Types.Mixed);
          occurance.set('organization', property.organization, Schema.Types.Mixed);

          // Push it
          propertyOffers[offer.id] = {
            offer,
            occurances: [occurance],
          };
        }
      }

      // Add the property to the result map
      resultMap[property.id] = {
        property: property,
        offers: propertyOffers,
      };
    }

    // Unlock access
    this.typeSearchBusy = false;

    //return the map
    return resultMap;
  }

  /** Return the correct start and end time of a search query depending on the mode of a date time picker */
  private formatStartAndEndtime(
    /** The search time */ searchStartTime: Date,
    /** The mode of the date time picker */ pickerMode: AdvancedDatePickerType,
  ) {
    const results = {
      startAfterDate: new Date(),
      startBeforeDate: new Date(),
    };

    // Handle possible errors
    if (!searchStartTime) {
      throw new Error('missing date and time for searching');
    }

    // Set period to use in the search
    switch (pickerMode) {
      case 'year':
        results.startAfterDate = Moment(searchStartTime).startOf('year').toDate();
        results.startBeforeDate = Moment(searchStartTime).endOf('year').toDate();
        break;
      case 'month':
        results.startAfterDate = Moment(searchStartTime).startOf('month').toDate();
        results.startBeforeDate = Moment(searchStartTime).endOf('month').toDate();
        break;
      case 'date':
        results.startAfterDate = Moment(searchStartTime).startOf('day').toDate();
        results.startBeforeDate = Moment(searchStartTime).endOf('day').toDate();
        break;
      default:
        results.startAfterDate = searchStartTime;
        results.startBeforeDate = Moment(searchStartTime).endOf('day').toDate();
        break;
    }

    // Check if startAfterDate is before current day and time. If it is, set startAfterDate to current date and time
    if (Moment(results.startAfterDate).isBefore(Moment())) {
      results.startAfterDate = new Date();
    }

    return results;
  }

  private checkFreetextMatch = (property: PropertyDocument, enteredFreeText: string, offer: ActivityTypeDocument) => {
    const { tt, locale } = this._locale;
    if (!enteredFreeText || !enteredFreeText.length) {
      return true;
    }

    const freeTextWords = Lodash.words(enteredFreeText);
    let matchFound = false;

    Lodash.each(freeTextWords, (word) => {
      if (
        (property.name && Lodash.includes(tt(property.name).toLowerCase(), word.toLowerCase())) ||
        (property.description && Lodash.includes(tt(property.description).toLowerCase(), word.toLowerCase())) ||
        (offer.title && Lodash.includes(tt(offer.title).toLowerCase(), word.toLowerCase())) ||
        (offer.description && Lodash.includes(tt(offer.description).toLowerCase(), word.toLowerCase()))
      ) {
        matchFound = true;
        return false;
      }
      if (offer.categoryTags) {
        Lodash.each(offer.categoryTags, (tag) => {
          if (Lodash.includes(ActivityTagsFormatter.getFormattedTag(tag, locale).toLowerCase(), word.toLowerCase())) {
            matchFound = true;
            return false;
          }
        });
      }
    });

    return matchFound;
  };

  private checkCityAndZipCodeMatch = (
    property: PropertyDocument,
    enteredCityOrZipcode: string,
    enteredStringIsZipcode: boolean,
    isFreelancer: boolean,
    cityBasedOnZipcode: string,
  ) => {
    // If no city or zipcode was entered we consider it a match.
    if (!enteredCityOrZipcode || !enteredCityOrZipcode.length) {
      return true;
    }
    enteredCityOrZipcode = enteredCityOrZipcode.toLowerCase();

    if (isFreelancer) {
      let areas = Lodash.map(property.freelancerAreas || [], (area) => area.city || area.zipCode || '');
      areas = Lodash.map(areas, (area) => area.toLowerCase());
      return Lodash.includes(areas, enteredCityOrZipcode) || Lodash.includes(areas, cityBasedOnZipcode.toLowerCase());
    }

    const activityCity = property.address!.postOrt.toLowerCase();
    const activityZipcode = property.address!.postNr;
    return enteredStringIsZipcode ? enteredCityOrZipcode === activityZipcode : enteredCityOrZipcode === activityCity;
  };
}
