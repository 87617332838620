/** Asserts that a definitions object has all correct values set. */
export const definitionsAreCorrect = (obj: any | ModuleDefinitions): obj is ModuleDefinitions =>
  typeof obj == 'object' &&
  'MODULE_ENVIRONMENT' in obj &&
  typeof obj.MODULE_ENVIRONMENT == 'string' &&
  'MODULE_IS_EMBEDDABLE' in obj &&
  typeof obj.MODULE_IS_EMBEDDABLE == 'boolean' &&
  'MODULE_RUNTIME_TIER' in obj &&
  typeof obj.MODULE_RUNTIME_TIER == 'string' &&
  'MODULE_PUBLIC_URL' in obj &&
  typeof obj.MODULE_PUBLIC_URL == 'string';
