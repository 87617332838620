import { Schema } from 'mongoose';

export interface TimePeriodDocument {
  start: Date;
  end: Date;
}

export var TimePeriodSchema = new Schema(
  {
    start: { type: Date, required: true },
    end: { type: Date, required: true },
  },
  { _id: false },
);
