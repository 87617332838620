import Lodash from 'lodash';
import Moment from 'moment-timezone';
import * as React from 'react';
import {
  Consume,
  Decode,
  DeviceProvider,
  IDevice,
  LocaleContext,
  MobxComponent,
  ScheduleDocument,
  ScheduleInstance,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';
import { ScheduleConsumer } from '.././context';
import { ScheduleContent, ScheduleProps } from '.././context/interfaces';
import { ScheduleItemRow } from './scheduleItemRow';

interface State {
  isLoading: boolean;
  formattedScheduleList: ScheduleInstance[];
  openedListItem?: ScheduleInstance;
}

interface Props extends ScheduleProps {
  setLoading: (shouldLoad: boolean) => void;
}

@ScheduleConsumer
export class ScheduleList extends MobxComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  constructor(props: Props) {
    super(props);
    this.state = {
      formattedScheduleList: [],
      isLoading: false,
    };
  }

  private get scheduleContent() {
    return this.props.scheduleContent!;
  }

  private currentId = 0;

  private async createFormattedScheduleList(nextScheduleContent?: ScheduleContent) {
    const scheduleContent = nextScheduleContent || this.scheduleContent;

    this.currentId = 0;

    const collectedSchedules: ScheduleDocument[] = [];
    const collectedExceptions: ScheduleDocument[] = [];
    let formattedScheduleList: ScheduleInstance[] = [];

    Lodash.forEach(scheduleContent.schedules, (schedule) => {
      if (schedule.isException) {
        collectedExceptions.push(schedule);
      } else {
        if (!scheduleContent.isCreatingNew && scheduleContent.editSchedule) {
          if (schedule.id != scheduleContent.editingScheduleId) {
            collectedSchedules.push(schedule);
          }
        } else {
          collectedSchedules.push(schedule);
        }
      }
    });

    // Feed schedules to formattedScheduleList
    collectedSchedules.forEach((collectedSchedule) => {
      Decode(collectedSchedule.rRule)
        .rule.all()
        .forEach((startTimeInput) => {
          const formattedStartTime = Moment(startTimeInput)
            .tz('Europe/Stocholm')
            .hour(Moment(collectedSchedule.date).hour())
            .minute(
              Moment(collectedSchedule.date).minute(),
            ) /* .add(!(Moment(collectedSchedule.date).isDST()) ? 1 : 0, 'hours') */
            .toDate();
          const formattedEndTime = Moment(startTimeInput)
            .tz('Europe/Stocholm')
            .hour(Moment(collectedSchedule.endTime).hour())
            .minute(Moment(collectedSchedule.endTime).minute())
            .toDate();
          formattedScheduleList.push({
            id: this.currentId++,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            property: collectedSchedule.property,
            originScheduleDocument: collectedSchedule,
          });
        });
    });

    if (scheduleContent.isCreatingNew) {
      // Feed exceptions to formattedScheduleList
      this.replaceScheduleInstancesWithExpection(collectedExceptions, formattedScheduleList);
      if (scheduleContent.editSchedule) {
        // Feed editSchedule to formattedScheduleList
        console.time('addEditScheduleInstances');
        this.addEditScheduleInstances(scheduleContent.editSchedule, formattedScheduleList, scheduleContent);
        console.timeEnd('addEditScheduleInstances');
      }
    } else {
      if (scheduleContent.editSchedule) {
        // Feed editSchedule to formattedScheduleList
        this.addEditScheduleInstances(scheduleContent.editSchedule, formattedScheduleList, scheduleContent);
      }
      // Feed exceptions to formattedScheduleList
      this.replaceScheduleInstancesWithExpection(collectedExceptions, formattedScheduleList);
    }

    // Find conflicts to prevent invalid removal of exceptions
    await this.setIfExceptionsCanBeDeleted(formattedScheduleList);

    formattedScheduleList = Lodash.sortBy(formattedScheduleList, (schedule) => {
      return schedule.startTime;
    });
    const conflictExists = Lodash.some(
      formattedScheduleList,
      (scheduleInstance) => scheduleInstance.isConflictingWithExistingTime == true,
    );

    await scheduleContent.setConflictExists(conflictExists);
    await this.setState({ formattedScheduleList });
  }

  //TODO: This takes time!!
  private addEditScheduleInstances(
    editSchedule: ScheduleDocument,
    formattedScheduleList: ScheduleInstance[],
    scheduleContent,
  ) {
    Decode(editSchedule.rRule)
      .rule.all()
      .forEach((editScheduleStartTime) => {
        let editScheduleEndTime: Date = editScheduleStartTime;
        editScheduleEndTime = Moment(editScheduleEndTime).hour(Moment(editSchedule.endTime).hour()).toDate();
        editScheduleEndTime = Moment(editScheduleEndTime).minute(Moment(editSchedule.endTime).minute()).toDate();

        let isConflicting = false;

        for (const existingSchedule of formattedScheduleList) {
          isConflicting =
            Moment(editScheduleStartTime).isBetween(existingSchedule.startTime, existingSchedule.endTime, 'minute') ||
            Moment(editScheduleEndTime).isBetween(existingSchedule.startTime, existingSchedule.endTime, 'minute') ||
            Moment(existingSchedule.startTime).isBetween(editScheduleStartTime, editScheduleEndTime, 'minute') ||
            Moment(existingSchedule.endTime).isBetween(editScheduleStartTime, editScheduleEndTime, 'minute') ||
            (Moment(editScheduleStartTime).isSame(existingSchedule.startTime, 'minute') &&
              Moment(editScheduleEndTime).isSame(existingSchedule.endTime, 'minute'));

          if (isConflicting) {
            if (existingSchedule.disableWholeScheuleInstance) {
              isConflicting = false;
            } else {
              break;
            }
          }
        }

        formattedScheduleList.push({
          id: this.currentId++,
          startTime: Moment(editScheduleStartTime).tz('Europe/Stockholm').toDate(),
          endTime: editScheduleEndTime,
          isEditing: true,
          isConflictingWithExistingTime: scheduleContent.isCreatingNew ? isConflicting : false,
          property: scheduleContent.isCreatingNew ? scheduleContent.originProperty : editSchedule.property,
          isNew: scheduleContent.isCreatingNew,
          originScheduleDocument: scheduleContent.isCreatingNew ? undefined : editSchedule,
        });
      });
  }

  private replaceScheduleInstancesWithExpection(
    collectedExceptions: ScheduleDocument[],
    formattedScheduleList: ScheduleInstance[],
  ) {
    collectedExceptions.forEach((collectedException) => {
      const formattedListFromCorrectScheduleTemplate: ScheduleInstance[] = Lodash.filter(
        formattedScheduleList,
        (scheduleInstance) => {
          return collectedException.originScheduleTemplate == scheduleInstance.originScheduleDocument!._id;
        },
      );

      Lodash.forEach(formattedListFromCorrectScheduleTemplate, (scheduleInstance) => {
        const decodedException = Decode(collectedException.rRule);
        const exceptionStartTime = decodedException.rule.options.dtstart;
        const exceptionEndTime = decodedException.endTime;

        const isExceptionMatchingSchedule =
          Moment(exceptionStartTime).isBetween(scheduleInstance.startTime, scheduleInstance.endTime, 'minute') ||
          Moment(exceptionEndTime).isBetween(scheduleInstance.startTime, scheduleInstance.endTime, 'minute') ||
          Moment(exceptionStartTime).isSame(scheduleInstance.startTime, 'minute') ||
          Moment(exceptionEndTime).isSame(scheduleInstance.endTime, 'minute') ||
          Moment(scheduleInstance.startTime).isBetween(exceptionStartTime, exceptionEndTime, 'minute') ||
          Moment(scheduleInstance.endTime).isBetween(exceptionStartTime, exceptionEndTime, 'minute');

        if (isExceptionMatchingSchedule && !scheduleInstance.isNew) {
          let disableWholeScheuleInstance = false;
          if (
            Moment(exceptionStartTime).isSame(scheduleInstance.startTime, 'minute') &&
            Moment(exceptionEndTime).isSame(scheduleInstance.startTime, 'minute')
          ) {
            disableWholeScheuleInstance = true;
          } else if (
            Moment(exceptionStartTime).isSame(scheduleInstance.endTime, 'minute') &&
            Moment(exceptionEndTime).isSame(scheduleInstance.endTime, 'minute')
          ) {
            disableWholeScheuleInstance = true;
          }

          const updatedSchedule: ScheduleInstance = {
            id: scheduleInstance.id,
            startTime: exceptionStartTime,
            endTime: decodedException.endTime,
            isEditing: scheduleInstance.isEditing,
            isConflictingWithExistingTime: scheduleInstance.isConflictingWithExistingTime,
            originScheduleInstance: scheduleInstance,
            exceptionScheduleDocument: collectedException,
            disableWholeScheuleInstance: disableWholeScheuleInstance,
            property: collectedException.property,
            originScheduleDocument: scheduleInstance.originScheduleDocument,
          };
          const index = Lodash.findIndex(formattedScheduleList, { id: scheduleInstance.id });
          formattedScheduleList.splice(index, 1, updatedSchedule);
          return false;
        }
      });
    });
  }

  private async setIfExceptionsCanBeDeleted(formattedScheduleList: ScheduleInstance[]) {
    // Mark all schedule instances as unmodifiable when an assigned activity occurance is intersecting with it
    // TODO: re add this to make sure employee schedules are in sync with existing bookings
    //await new ScheduleResource().findScheduleInstancesConflictingWithExisingOccurances(this.scheduleContent.user, formattedScheduleList);

    // Find out if this exception cant be removed due to conflicts with other schedule instances
    for (const scheduleInstance of formattedScheduleList) {
      if (scheduleInstance.isConflictingWithExistingOccurance) {
        continue;
      } // Skip those instances that already are unmodifiable due to exsting occurances

      if (scheduleInstance.exceptionScheduleDocument) {
        const schedulesOfSameDay: ScheduleInstance[] = Lodash.filter(
          formattedScheduleList,
          (scheduleInstanceToBeCompared: ScheduleInstance) => {
            return Moment(scheduleInstance.startTime).isSame(scheduleInstanceToBeCompared.startTime, 'date');
          },
        );
        Lodash.remove(schedulesOfSameDay, { id: scheduleInstance.id });
        schedulesOfSameDay.forEach((scheduleInstanceOfSameDay) => {
          if (
            !scheduleInstanceOfSameDay.disableWholeScheuleInstance &&
            scheduleInstance.id != scheduleInstanceOfSameDay.id &&
            !scheduleInstanceOfSameDay.isNew
          ) {
            const originScheduleStartTime = scheduleInstance.originScheduleInstance!.startTime;
            const originScheduleExceptionEndTime = scheduleInstance.originScheduleInstance!.endTime;
            const scheduleStartTimeOfSameDay = scheduleInstanceOfSameDay.startTime;
            const scheduleEndTimeOfSameDay = scheduleInstanceOfSameDay.endTime;

            const isConflicting =
              Moment(originScheduleStartTime).isBetween(
                scheduleStartTimeOfSameDay,
                scheduleEndTimeOfSameDay,
                'minute',
              ) ||
              Moment(originScheduleExceptionEndTime).isBetween(
                scheduleStartTimeOfSameDay,
                scheduleEndTimeOfSameDay,
                'minute',
              ) ||
              Moment(scheduleStartTimeOfSameDay).isBetween(
                originScheduleStartTime,
                originScheduleExceptionEndTime,
                'minute',
              ) ||
              Moment(scheduleEndTimeOfSameDay).isBetween(
                originScheduleStartTime,
                originScheduleExceptionEndTime,
                'minute',
              ) ||
              (Moment(originScheduleStartTime).isSame(scheduleStartTimeOfSameDay, 'minute') &&
                Moment(originScheduleExceptionEndTime).isSame(scheduleEndTimeOfSameDay, 'minute'));

            if (isConflicting) {
              scheduleInstance.isPossiblyConflictingWithExistingScheduleInstance = true;
            }
          }
        });
      }
    }
  }

  private setScheduleItemToActive = (schedule: ScheduleInstance) => {
    this.setState({
      openedListItem: schedule,
    });
  };

  private createException = (startTime: Date, endTime: Date, schedule: ScheduleInstance) => {
    const exception = this.scheduleContent.createNewSchedule(
      schedule.startTime,
      schedule.endTime,
      startTime,
      endTime,
      [],
      [],
      schedule.originScheduleDocument,
    );
    this.scheduleContent.addSchedule(exception);
    this.closeAccordion();
  };

  private removeExcepion = (schedule: ScheduleDocument) => {
    this.scheduleContent.removeSchedule(schedule);
    this.closeAccordion();
  };

  private closeAccordion() {
    Lodash.forEach(this.state.formattedScheduleList, (_, index) => {
      $('#scheduleListAccordion').accordion('close', index);
    });
    this.setState({
      openedListItem: undefined,
    });
  }

  private getPrevSchedule(index: number) {
    for (let i = index - 1; i >= 0; i--) {
      if (
        this.state.formattedScheduleList[i] &&
        !this.state.formattedScheduleList[i].isNew &&
        !this.state.formattedScheduleList[i].disableWholeScheuleInstance
      ) {
        return this.state.formattedScheduleList[i];
      }
    }
    return undefined;
  }

  private getNextSchedule(index: number) {
    for (let i = index + 1; i <= this.state.formattedScheduleList.length; i++) {
      if (
        this.state.formattedScheduleList[i] &&
        !this.state.formattedScheduleList[i].isNew &&
        !this.state.formattedScheduleList[i].disableWholeScheuleInstance
      ) {
        return this.state.formattedScheduleList[i];
      }
    }
    return undefined;
  }

  private get tableContent() {
    return Lodash.map(this.state.formattedScheduleList, (scheduleInstance, index) => {
      return (
        <ScheduleItemRow
          key={scheduleInstance.startTime.toString() + index}
          prevSchedule={this.getPrevSchedule(index)}
          nextSchedule={this.getNextSchedule(index)}
          schedule={scheduleInstance}
          openedListItem={this.state.openedListItem}
          setScheduleToActive={this.setScheduleItemToActive}
          createException={this.createException}
          removeException={this.removeExcepion}
          isCreatingNew={this.scheduleContent.isCreatingNew}
          originProperty={this.scheduleContent.originProperty}
        />
      );
    });
  }

  private get dateHeaders() {
    const { t } = this._locale;
    if (this.device.size === 'mobile') {
      return (
        <div className="ui column">
          <i className="ui icon calendar"></i>
          {t('Date')}
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="ui column">
          <i className="ui icon calendar"></i>
          {t('Year')}
        </div>
        <div className="ui column">
          <i className="ui icon calendar"></i>
          {t('Month')}
        </div>
        <div className="ui column">
          <i className="ui icon calendar"></i>
          {t('Day')}
        </div>
      </React.Fragment>
    );
  }

  UNSAFE_componentWillMount() {
    this.createFormattedScheduleList();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.scheduleContent!.editSchedule != this.props.scheduleContent!.editSchedule ||
      !Lodash.isEqual(nextProps.scheduleContent!.schedules, this.scheduleContent.schedules)
    ) {
      this.props.setLoading(true);
      setTimeout(() => {
        this.createFormattedScheduleList(nextProps.scheduleContent!).then((data) => {
          this.props.setLoading(false);
        });
      }, 100);
    }
  }

  componentDidMount() {
    const scheduleListAccordion = $('#scheduleListAccordion');
    scheduleListAccordion.accordion({
      animateChildren: false,
      onClose: () => this.setState({ openedListItem: undefined }),
    });
  }

  render() {
    const { t } = this._locale;
    return (
      <React.Fragment>
        <div id="scheduleListAccordion" className="ui styled fluid accordion">
          <div className="ui disabled title" style={{ backgroundColor: '#F0F0F0', color: 'black' }}>
            <div className="ui equal width grid">
              {this.dateHeaders}
              <div className="ui column">
                <i className="ui icon clock outline"></i>
                {t('Time')}
              </div>
              <div className="ui column">
                <i className="ui icon building outline"></i>
                {t('Property')}
              </div>
            </div>
          </div>
          <div className="ui content" style={{ height: '0px', padding: '0px' }} />
          {this.tableContent}
        </div>
      </React.Fragment>
    );
  }
}
