import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  ActivityOccuranceResource,
  Consume,
  DeviceProvider,
  IDevice,
  ObservingComponent,
} from '../../../_dependencies';
import { Calendar, CalendarSidebar, CalendarStore } from '../../calendar';
import { PropertyStore } from './propertyStore';

type Props = RouteComponentProps<{ id: string }>;

export class PropertyCalendarView extends ObservingComponent<Props, CalendarStore> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  componentDefaultStore() {
    return new CalendarStore({
      resource: new ActivityOccuranceResource(),
      property: PropertyStore.Instance.property,
      endpoint: new ActivityOccuranceResource().getPropertyOccurances,
      isTimeSelectable: this.device.size === 'desktop',
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // TODO: Efter flera misslyckade försök är nu det här lösningen
      // som får vara tillsvidare för att säkerställa att destinationer
      // får rätt upplevese när de bokar hos varandra. Planen är att
      // skriva om property-storen och annat i goodbye-mobx branchen.
      window.location.reload();
    }
    // if (this.props.match.params.id !== PropertyStore.Instance.property.id) return
    // this.store.setProperty(PropertyStore.Instance.property)
    // this.store.reload()
  }

  render() {
    const { size } = this.device;
    return (
      <div style={{ height: '-webkit-fit-content' }}>
        {size == 'desktop' ? (
          <div className="ui grid" style={{ margin: 0 }}>
            <div className="eleven wide column" style={{ paddingRight: 0 }}>
              <Calendar store={this.store} />
            </div>
            <div className="five wide column" style={{ padding: 0 }}>
              <CalendarSidebar showActivityFilter canCreateNewOccurance store={this.store} />
            </div>
          </div>
        ) : (
          <div className="ui grid" style={{ margin: 0 }}>
            <div className="sixteen wide column" style={{ padding: 0 }}>
              <CalendarSidebar showActivityFilter canCreateNewOccurance store={this.store} />
            </div>
            <div className="sixteen wide column" style={{ padding: size === 'mobile' ? 0 : undefined }}>
              <Calendar store={this.store} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
