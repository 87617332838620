import * as React from 'react';
import { Consume, LocaleContext, MobxComponent, UserDocument, UserResource } from '../_dependencies';
import { AHLocaleContext } from '../_locales';
import { EmployeeCard } from './dashboard/employees';
import { LanguageToggleIcons } from './languageToggleIcons';

interface State {
  user: UserDocument | undefined;
}
export class UserInfoView extends MobxComponent<{}> {
  state: State = {
    user: this.globals.session.currentUser as UserDocument,
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private onLogoutButtonClicked() {
    this.globals.session.deauthenticate('/');
  }

  componentDidMount(): void {
    if (this.globals.session.currentUser) {
      new UserResource().find({ _id: String(this.globals.session.currentUser._id) }).then((user) => {
        this.setState({ user: user[0] });
      });
    }
  }

  render() {
    const { t } = this._locale;
    return (
      <div className="ui basic segment" style={{ minHeight: '100%' }}>
        <LanguageToggleIcons />
        <div className="ui container" style={{ paddingTop: '2em' }}>
          <div className="ui one cards" style={{ marginBottom: '1rem' }}>
            <EmployeeCard item={this.state.user ? this.state.user : ({} as UserDocument)} maxWidth="400px" />
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="ui segment" style={{ width: '28.8rem', display: 'flex' }}>
              <button
                className="ui fluid tiny negative button"
                onClick={this.onLogoutButtonClicked.bind(this)}
                style={{ padding: '10px' }}
              >
                <i className="sign out icon" />
                {t('Log out')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
