import lodash from 'lodash';
import Moment from 'moment';
import { Types } from 'mongoose';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import {
  ActivityOccuranceResource,
  GlobalsContext,
  NotificationDocument,
  NotificationResource,
  OrganizationResource,
  PropertyDocument,
  PropertyResource,
} from '../_dependencies';
import { useLocale } from '../_locales';

export interface NotificationContext {
  isLoading: boolean;
  notifications: NotificationDocument[];
  createBookingRequestNotification: (
    crossSellingOrganisation: Types.ObjectId,
    bookingOccurance: Types.ObjectId,
  ) => Promise<void>;
  toggleReadUnread: (notification: NotificationDocument, explicit?: boolean) => Promise<void>;
  deleteNotification: (notification: NotificationDocument) => Promise<void>;
}

interface Props {
  children: React.ReactNode;
}

export const NotificationContext = createContext<NotificationContext>({} as any);

const NotificationProvider = (props: Props) => {
  const [notifications, setNotifications] = useState<NotificationDocument[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const globals = useContext(GlobalsContext);
  const { tt } = useLocale();

  const pollingRef = useRef<NodeJS.Timer | null>(null);

  const getNotifications = async () => {
    const administratedProperties = await new PropertyResource().getAdministratedProperties();
    const userNotifications = await new NotificationResource().find({ property: administratedProperties });
    setNotifications(userNotifications.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)));
    setIsLoading(false);
  };

  useEffect(() => {
    if (globals.session.currentUser) {
      getNotifications();
      pollingRef.current = setInterval(() => {
        getNotifications();
      }, 1_000 * 60);
    }
    return () => {
      if (globals.session.currentUser && pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, [globals.session.currentUser]);

  const getOrganizationDetails = async (id: Types.ObjectId) => {
    const organization = await new OrganizationResource().find({ _id: id });
    const firstOrg = organization[0];
    return firstOrg;
  };

  const getOccuranceDetails = async (id: Types.ObjectId) => {
    return await new ActivityOccuranceResource().getWithPopulatedProperty(id);
  };

  const createBookingRequestNotification = async (
    crossSellingOrganization: Types.ObjectId,
    bookingOccurance: Types.ObjectId,
  ) => {
    const organization = await getOrganizationDetails(crossSellingOrganization);
    const occurance = await getOccuranceDetails(bookingOccurance);
    const property = occurance.property as unknown as PropertyDocument;

    const notification = {
      read: false,
      title: {
        translationKey: 'New booking request from',
        data: organization.name,
      },
      body: [
        {
          icon: 'star outline icon',
          content: {
            translationKey: 'activity',
            data: tt(occurance.title),
          },
          link: `/dashboard/offers/details/${occurance.originatingActivity}`,
        },
        {
          icon: 'map pin icon',
          content: {
            translationKey: 'components.dashboard.bookings.bookingItem.bookingInfo.contentOne',
            data: tt(property.name),
          },
          link: `/dashboard/properties/details/${property._id}`,
        },
        {
          icon: 'wait icon',
          content: {
            translationKey: 'components.dashboard.bookings.bookingItem.bookingInfo.contentTwo',
            data: Moment(occurance.start).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm'),
          },
        },
      ],
      link: `/dashboard/schedule?date=${Moment(occurance.start).tz('Europe/Stockholm').format('YYMMDD')}&view=week`,
      property: property._id,
    };
    await new NotificationResource().updateDocument(new NotificationResource().createDocument(notification));
  };

  const toggleReadUnread = async (notification: NotificationDocument, explicit?: boolean) => {
    setIsLoading(true);
    const copy = lodash.cloneDeep(notification);
    copy.read = explicit === undefined ? !copy.read : explicit;
    await new NotificationResource().updateDocument(copy);
    getNotifications();
    setIsLoading(false);
  };

  const deleteNotification = async (notification: NotificationDocument) => {
    setIsLoading(true);
    await new NotificationResource().delete(notification._id);
    getNotifications();
    setIsLoading(false);
  };

  return (
    <NotificationContext.Provider
      value={{
        isLoading,
        notifications,
        createBookingRequestNotification,
        toggleReadUnread,
        deleteNotification,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
