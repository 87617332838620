import * as React from 'react';
import { WidgetLocaleContext } from '../_locales';
import { Platforms, MobxComponent, Consume, LocaleContext } from '../_dependencies';

export class LanguageToggleIcons extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  private onLangageIconClicked = (locale: Locale) => {
    const { setLocale } = this._locale;
    setLocale(locale);
  };

  public render() {
    return (
      <div>
        {Platforms.locales.map((locale) => (
          <img
            onClick={() => this.onLangageIconClicked(locale)}
            src={`${MODULE_PUBLIC_URL}/static/platform/img/flags/${locale}.png`}
            style={{
              padding: '0.3em',
              cursor: 'pointer',
              width: '3em',
              height: '2em',
              marginRight: '0.5em',
            }}
            alt=""
            key={locale}
          />
        ))}
      </div>
    );
  }
}
