import Lodash from 'lodash';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import Moment from 'moment-timezone';
import * as React from 'react';
import { CSVLink } from 'react-csv';
import * as xlsx from 'xlsx';
import { Alert, Consume, LocaleContext, MobxComponent, PaymentResource } from '../../../../_dependencies';
import { AHLocaleContext, globalCurrentLocale } from '../../../../_locales';
import { AdvancedDatePicker, AdvancedDatePickerType } from '../../../advancedDatePicker';
export class PayoutSegment extends MobxComponent<{}> {
  @observable private _type: AdvancedDatePickerType = 'month';
  @observable private _date: Date = Moment().startOf('month').toDate();
  @observable private _isLoading: boolean;
  @observable private _csvData: String[][];

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private _downloadAnchor: HTMLAnchorElement;

  @computed private get showMessage() {
    return this._csvData && this._csvData.length;
  }

  @computed private get fileName() {
    const prefix = ((locale) => {
      switch (locale) {
        case 'sv':
          return 'Inbetalningar';
        case 'de':
          return 'Zahlungen';
        case 'en':
        default:
          return 'Payments';
      }
    })(globalCurrentLocale());

    const fileEnding = '.xlsx';
    switch (this._type) {
      case 'year':
        return prefix + ' ' + Moment(this._date).tz('Europe/Stockholm').format('YYYY') + fileEnding;
      case 'month':
        return (
          prefix + ' ' + Lodash.startCase(Moment(this._date).tz('Europe/Stockholm').format('MMMM YYYY')) + fileEnding
        );
      case 'date':
        return (
          prefix + ' ' + Lodash.startCase(Moment(this._date).tz('Europe/Stockholm').format('D MMMM YYYY')) + fileEnding
        );
      default:
        return prefix + fileEnding;
    }
  }

  @action private onChanged = (date: Date, type: AdvancedDatePickerType) => {
    this._date = date;
    this._type = type;
    this._csvData = undefined as any;
  };

  @action private getPayoutInfoForCSV = () => {
    const { t } = this._locale;
    this._isLoading = true;
    new PaymentResource()
      .getPayoutInfoForCSV(Moment(this._date).tz('Europe/Stockholm').format('YYYY-MM-DD'), this._type as any)
      .then((result) => {
        runInAction(() => {
          this._isLoading = false;
          xlsx.writeFile(result, this.fileName!);
        });
      })
      .catch((err) => {
        Alert.show(
          t('components.dashboard.home.segments.payoutSegment.errorFile'),
          t('components.dashboard.home.segments.payoutSegment.oops'),
          'error',
        );
        this._isLoading = false;
      });
  };

  private get buttonText() {
    const { t } = this._locale;
    switch (this._type) {
      case 'year':
        return (
          <span>
            {t('Get payouts for')} {Moment(this._date).tz('Europe/Stockholm').format('YYYY')}
          </span>
        );
      case 'month':
        return (
          <span>
            {t('Get payouts for')} {Lodash.startCase(Moment(this._date).tz('Europe/Stockholm').format('MMMM YYYY'))}
          </span>
        );
      case 'date':
        return (
          <span>
            {t('Get payouts for')} {Lodash.startCase(Moment(this._date).tz('Europe/Stockholm').format('D MMMM YYYY'))}
          </span>
        );
    }
  }

  // TODO: for some reason this does not work and it does not support filename.. Remove packade completely?
  // @computed private get CSVDownload() {
  //     if (this._renderCsvDownload) {
  //         return <CSVDownload data={toJS(this._csvData)} filename={this.fileName}/>
  //     }
  //     return <span/>
  // }

  @computed private get message() {
    const { t } = this._locale;
    return (
      <div className="ui message" style={{ marginBottom: 0 }}>
        {t('components.dashboard.home.segments.payoutSegment.errorMessageFile')}
        <CSVLink data={toJS(this._csvData)} filename={this.fileName}>
          {' '}
          {t('here')}
        </CSVLink>{' '}
        {t('components.dashboard.home.segments.payoutSegment.alternativeRightClick')}
        <br />
      </div>
    );
  }

  @computed private get loader() {
    const { t } = this._locale;
    if (this._isLoading) {
      return (
        <div className="ui active inverted dimmer">
          <div className="ui indeterminate big text loader">
            {t('components.dashboard.home.segments.payoutSegment.buildGetFile')}
          </div>
        </div>
      );
    }
  }

  private get payoutSection() {
    const { t } = this._locale;
    return (
      <React.Fragment>
        <h3 className="ui header">
          {t('components.dashboard.home.segments.payoutSegment.payments')}
          <div className="sub header">{t('components.dashboard.home.segments.payoutSegment.getInfoPayments')}</div>
        </h3>
        <AdvancedDatePicker
          enabledTypes={['year', 'month', 'date']}
          date={this._date}
          onChanged={this.onChanged}
          type={this._type}
        />
        <div style={{ height: '1em' }} />
        <button className="ui fluid large basic grey labeled icon button" onClick={this.getPayoutInfoForCSV}>
          <i className="teal download icon" />
          {this.buttonText}
        </button>

        {this.showMessage ? this.message : <span />}
        {this.loader}
      </React.Fragment>
    );
  }

  public render() {
    return (
      <div className="ui green segment" style={{ marginTop: '2em' }}>
        {this.payoutSection}
        <br />
        {/* NOTE: The classic .csv files are disabled right now as they result in empty pages on production */}
        {/* <Accordion title={<span style={{color: "gray"}}><i className="dropdown icon"/>{this.specificHeaderForBbloom}</span>}>
                    <div>
                        <a className="ui fluid labeled basic grey icon button" target="_blank" href={`api/payment/statistics/${this.globals.session.currentOrganizationId}/bookings.csv?lang=${this.globals.locale.currentLocale}`}><i className="green file text outline icon"/>{t('components.dashboard.home.segments.payoutSegment.iconSixteen')}</a>
                        <br/>
                        <a className={ "ui fluid labeled basic grey icon button" } style={Platforms.platformId == "BB" ? {display: "none"} : {}} target="_blank" href={`api/payment/statistics/${this.globals.session.currentOrganizationId}/giftcards.csv?lang=${this.globals.locale.currentLocale}`}><i className="purple file text outline icon"/>{t('components.dashboard.home.segments.payoutSegment.iconSeventeen')}</a>
                    </div>
                </Accordion> */}
      </div>
    );
  }
}
