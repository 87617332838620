import * as React from 'react';
import * as PropTypes from 'prop-types';

/** Props of the Debug Provider */
interface DebugProviderProps {
  /** The current version of the running platform */
  release?: string;
}

/** State of the Debug Provider */
interface DebugProviderState {
  /** Indicates that LogRocket has been successfully initalized */ initialized: boolean;
  /** The URL for viewing the current session on LogRocket */ sessionURL?: string;
}

/** Available debug functionality */
export interface ProvisionedDebugFunctionality {
  /**
   * Manually reports an exception to LogRocket without throwing it
   * @param err The error to report
   * @param metadata= Additional arbitrary data associated with the current action or page
   * @param metadata= Any additional data to be grouped as "tags"
   */
  captureException: (err: Error, metadata?: { [key: string]: string }, tags?: { [key: string]: string }) => void;
  /**
   * Manually reports a message to LogRocket
   * @param err The message to report
   * @param metadata= Additional arbitrary data associated with the current action or page
   * @param metadata= Any additional data to be grouped as "tags"
   */
  captureMessage: (message: string, metadata?: { [key: string]: string }, tags?: { [key: string]: string }) => void;
  /**
   * Associates the current debug session with  given user information
   * @param uniqueId A unique identifier of the current user
   * @param traits= Optional metadata of the current user, name and email is used in LogRockets UI
   */
  identify: (uniqueId: string, traits?: { name?: string; email?: string } & { [key: string]: string }) => void;
  /** For general tracking of user events */ track: (message: string) => void;
  /** For general output of logging information */ log: (...messages: any[]) => void;
  /** Informative logging of information */ info: (...messages: any[]) => void;
  /** Outputs a warning message */ warn: (...messages: any[]) => void;
  /** For output of more detailed information  */ debug: (...messages: any[]) => void;
  /** Outputs an error message */ error: (...messages: any[]) => void;
  /** The URL for viewing the current session on LogRocket */ sessionURL: string;
  /** Headers to include in server requests to get better debugging results */ requestHeaders: object;
}

/**
 * Provides functionality for debugging a browser project.
 * Tightly connected to (LogRocket)[logrocket.com] at the moment but that might change in the future.
 */
export class DebugProvider extends React.Component<DebugProviderProps, DebugProviderState> {
  /** Makes this provider available for consumer components further down the component tree.  */
  private static childContextTypes = { debug: PropTypes.object };

  /**
   * Initializes log rocket according to the given provider props, can only be called in browsers.
   */
  private async _initializeLogRocket() {
    if (MODULE_ENVIRONMENT != 'browser') {
      return false;
    }

    // NOTE: log-rocket is removed, this component does next to nothing
  }

  /**
   * Returns provisioned collection of debug functionionality that will be available to consumer components.
   */
  public getProvisionedDebugFunctionality(): ProvisionedDebugFunctionality {
    // Create a default collection
    const functionality = {
      captureException: (err: Error, metadata?, tags?) => {
        console.log('exception:', err, metadata || '- no metadata.', tags || '- no tags.');
      },
      captureMessage: (message: string, metadata?, tags?) => {
        console.log('report:', message, metadata || '- no metadata.', tags || '- no tags.');
      },
      identify: (uniqueId: string, traits) => {},
      track: console.log,
      log: console.log,
      info: console.info,
      warn: console.warn,
      debug: console.debug,
      error: console.error,
      sessionURL: this.state.sessionURL || '',
      requestHeaders: { 'X-LogRocket-URL': this.state.sessionURL },
    };

    // NOTE: logrocket is removed, this provider does nothing

    // Return the collection
    return functionality;
  }

  /** Provider state */
  public state: DebugProviderState = { initialized: false };

  // React lifecycle methods

  componentDidMount() {
    if (MODULE_ENVIRONMENT == 'browser') {
      this._initializeLogRocket();
    }
  }

  getChildContext() {
    return { debug: this };
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}
