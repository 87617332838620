import * as React from 'react';
import * as PropTypes from 'prop-types';

import { SessionManager } from '../stores/sessionManager';
import { DeviceManager } from '../stores/deviceManager';
import { RouteComponentProps, withRouter } from 'react-router';

// TODO: document this
export interface ProviderProps extends RouteComponentProps {
  session: SessionManager;
  device: DeviceManager;
  onPageUpdate?: () => void;
}

// TODO: document this
// NOTE: Viktor skrev på stenen den 11 maj 1947
// NOTE: Andreas fick refaktorera bort gamla context apiet, men inte nu .. SEN
// NOTE: David undrar vad som händer.
// Note: Christian säger hej från PR review.
export class LegacyProviderClass extends React.Component<ProviderProps, {}> {
  static contextTypes = { router: PropTypes.object };
  static childContextTypes = { session: PropTypes.object, device: PropTypes.object, intl: PropTypes.object };

  public get session() {
    return this.props.session;
  }

  public get device() {
    return this.props.device;
  }

  getChildContext() {
    return { session: this.props.session, device: this.props.device };
  }

  // TODO: This prevents us from usin multiple legacy providers on in same project.
  /** The currently mounted LocaleProvider  */
  private static _instance: LegacyProviderClass | undefined;

  /* TODO: This ugly hack is only needed by for old stores like PropertyStore ( and AdminStore ) */
  public static get singleton() {
    if (!this._instance) {
      return undefined;
    }
    return this._instance;
  }

  constructor(props: ProviderProps, context: any) {
    super(props);

    // Inject the history object from react router if its available
    if (!props.session.currentHistory) {
      props.session.currentHistory = props.history || context?.router?.history;
    }
  }

  UNSAFE_componentWillMount() {
    LegacyProviderClass._instance = this;
  }

  componentDidMount() {
    // The page update method will be called everytime the page is changed, it will update reactGA
    if (this.props.onPageUpdate) {
      this.props.onPageUpdate();
    }
  }

  componentDidUpdate() {
    // The page update method will be called everytime the page is changed, it will update reactGA
    if (this.props.onPageUpdate) {
      this.props.onPageUpdate();
    }
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export const LegacyProvider = withRouter(LegacyProviderClass);
