import React from 'react';
import { CSSProperties } from 'react';
import { useLocale } from '../../_locales';

function Statistics() {
  const { t } = useLocale();

  const statisticsInfo = [
    {
      statistic: 11,
      symbol: 'k',
      description: t('Over 11 000 completed bookings'),
    },
    {
      statistic: '~82',
      symbol: '%',
      description: t('Of all bookings were made online'),
    },
    {
      statistic: 30,
      symbol: 'st',
      description: t('Over 30 registered partners'),
    },
  ];

  return (
    <div className="ui center aligned" style={img}>
      <div className="ui grid center aligned container">
        <div className="ui stackable center aligned three column grid" style={containerPadding}>
          <h2 style={headerStyle}>{t('This last year')}</h2>
          {statisticsInfo.map((s, index) => (
            <div key={`statistic-${index}`} className="column">
              <div style={{ padding: '3rem' }}>
                <div style={statisticsStyling}>
                  <h2 className="ui teal header" style={hFontSize}>
                    {s.statistic}
                  </h2>
                  <h2 className="ui teal header" style={{ fontSize: '3rem' }}>
                    {s.symbol}
                  </h2>
                </div>
                <p style={paragraphStyle}>{s.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Statistics;

const paragraphStyle: CSSProperties = {
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 'lighter',
};

const headerStyle: CSSProperties = {
  color: 'white',
  fontSize: '2rem',
  padding: '3rem 0 1rem 0',
};

const statisticsStyling: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

const containerPadding: CSSProperties = {
  padding: '5rem 0rem 3rem 0rem',
};

const hFontSize: CSSProperties = {
  fontSize: '8rem',
};

const img: CSSProperties = {
  width: '100%',
  marginBottom: '10rem',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundImage: 'url(/static/platform/img/camping-cut.jpg)',
  boxShadow: 'inset 0 0 0 100vmax rgba(0, 0, 0, .6)',
};
