import { useContext } from 'react';
import {
  ahWidgetCollection,
  generateTranslationCollection,
  generateTranslationsFunction,
  kosmicTranslationCollection,
  LocaleContext,
  LocaleContextState,
} from './_dependencies';

// Load moment locales
import 'moment/locale/de.js';
import 'moment/locale/sv.js';

const translations = generateTranslationsFunction<{
  sv: string;
  en: string | null;
  de: string | null;
}>();

export const { getTranslatedString, globalT, globalCurrentLocale, defaultLocale, collection, supportedLocales } =
  generateTranslationCollection({
    'Booking can no longer be edited': translations({
      sv: 'Bokningen kan inte längre redigeras',
      en: 'Booking can no longer be edited',
      de: 'Die Buchung kann nicht mehr bearbeitet werden',
    }),
    'Cancel no later than X hours before for refund': translations({
      sv: 'Avboka senast x timmar innan för full återbetalning',
      en: 'Cancel no later than X hours before for refund',
      de: 'Stornierung spätestens x Stunden vorher für volle Rückerstattung for',
    }),
    'Refundable until': translations({
      sv: 'Återbetalningsbar tills',
      en: 'Refundable until',
      de: 'Erstattungsfähig bis',
    }),
    'You are not able to move bookings...': translations({
      sv: 'Du kan inte flytta bokningar till aktiviteter som inte är dina.',
      en: 'You are not able to move bookings to activities not managed by you.',
      de: 'Sie können keine Buchungen in Aktivitäten verschieben, die nicht von Ihnen verwaltet werden.',
    }),
    'booking-request-creation.message.error': translations({
      sv: 'Bokningsförfrågan gick inte att genomföra, var vänlig kontakta support om problemet fortsätter.',
      en: 'Booking request did not go through, please contact support if the issue remains.',
      de: 'Die Buchungsanfrage wurde nicht bearbeitet. Bitte wenden Sie sich an den Support, wenn das Problem weiterhin besteht.',
    }),
    'booking-request': translations({
      sv: 'Bokningsförfrågan',
      en: 'Booking request',
      de: 'Buchungsanfrage',
    }),
    Confirmed: translations({
      sv: 'Bekräftad',
      en: 'Confirmed',
      de: 'Bestätigt',
    }),
    'Booking requested rejected': translations({
      sv: 'Bokningsbegäran avvisades',
      en: 'Booking requested rejected',
      de: 'Buchungsanfrage abgelehnt',
    }),
    'Booking requested confirmed': translations({
      sv: 'Begärd bokning bekräftad',
      en: 'Booking requested confirmed',
      de: 'Angeforderte Buchung bestätigt',
    }),
    Rejected: translations({
      sv: 'Avvisad',
      en: 'Rejected',
      de: 'Abgelehnt',
    }),
    'enter-booking-info': translations({
      sv: 'Ange bokningsinformationen',
      en: 'Enter booking info',
      de: 'Geben Sie die Buchungsinformationen ein',
    }),
    'booking-request-is-placed': translations({
      sv: 'En bokningsförfrågan är placerad på aktiviteten. Inväntar svar från',
      en: 'A booking request is placed on this activity. Awaiting response from',
      de: 'Für diese Aktivität wird eine Buchungsanfrage gestellt. Warte auf Antwort von',
    }),
    continue: translations({
      sv: 'gå vidare',
      en: 'continue',
      de: 'Weiter',
    }),
    'activity-request.title': translations({
      sv: 'Förfrågan skickad',
      en: 'Request sent',
      de: 'Anfrage gesendet',
    }),
    'activity-request.message': translations({
      sv: 'är förfrågad',
      en: 'is requested',
      de: 'wird angefordert',
    }),
    minutes: translations({
      sv: 'minuter',
      en: 'minutes',
      de: 'minuten',
    }),
    'create-booking-request': translations({
      sv: 'Skapa en bokningsförfrågan',
      en: 'Create a bookingrequest',
      de: 'Erstellen Sie eine Buchungsanfrage',
    }),
    'show-cross-selling-only': translations({
      sv: `Visa endast cross-selling`,
      en: `Only show cross-selling`,
      de: 'nur Cross-Verkauf anzeigen',
    }),
    'presented-by': translations({
      sv: 'Presenteras av',
      en: 'Presented by',
      de: 'Präsentiert von',
    }),
    'Reference number': translations({
      sv: 'Referensnummer',
      en: 'Reference number',
      de: 'Referenznummer',
    }),
    'Generate invoice documentation': translations({
      sv: 'Generera fakturaunderlag',
      en: 'Generate invoice documentation',
      de: 'Rechnungsdokumentation erstellen',
    }),
    'bookinglist-is-limited.header': translations({
      sv: 'Bokningslistan är begränsad',
      en: 'The booking list is limited',
      de: 'Die buchungsliste ist begrenzt',
    }),
    Publish: translations({
      sv: 'Publicera',
      en: 'Publish',
      de: 'Veröffentlichen',
    }),
    'Publish activity': translations({
      sv: 'Publicera aktiviteten',
      en: 'Publish activity',
      de: 'Aktivität veröffentlichen',
    }),
    'Publish marked times': translations({
      sv: 'Publicera markerade tider',
      en: 'Publish marked times',
      de: 'Markierte Zeiten rückgängig machen',
    }),
    'Unpublish marked times': translations({
      sv: 'Avpublicera markerade tider',
      en: 'Unpublish marked times',
      de: 'Veröffentlichung markierter Zeiten rückgängig machen',
    }),
    Published: translations({
      sv: 'Publicerad',
      en: 'Published',
      de: 'Veröffentlicht',
    }),
    Unpublish: translations({
      sv: 'Avpublicera',
      en: 'Unpublish',
      de: 'Unveröffentlicht',
    }),
    'bookinglist-is-limited.message': translations({
      sv: 'Du ser endast bokningar du själv har gjort eftersom aktiviteten tillhör en annan aktör i destinationen.',
      en: 'You can only see bookings done by you because the activity is managed by another organization in the destination',
      de: 'Sie können nur von Ihnen getätigte Buchungen sehen, da die Aktivität von einer anderen Organisation am Zielort verwaltet wird',
    }),
    destination: translations({
      sv: 'Destination',
      en: 'Destination',
      de: 'Zielort',
    }),
    Destinations: translations({
      sv: 'Destinationer',
      en: 'Destinations',
      de: 'Zielorte',
    }),
    'privacy-policy': translations({
      sv: 'Integritetspolicy',
      en: 'Privacy Policy',
      de: 'Datenschutz-Bestimmungen',
    }),
    'terms-of-service': translations({
      sv: 'Användarvillkor',
      en: 'Terms of Service',
      de: 'Nutzungsbedingungen',
    }),
    'sign-up-agreement': translations({
      sv: 'Genom att registrera mig accepterar jag ',
      en: 'By signing up I agree to the ',
      de: 'Mit der Anmeldung erkläre ich mich damit einverstanden',
    }),
    'monthly-newsletter.yes': translations({
      sv: 'Ja, jag vill ha månatliga nyhetsbrev',
      en: 'Yes, I want monthly newsletters',
      de: 'Ja, ich möchte monatliche Newsletter',
    }),
    'sign-up': translations({
      sv: `Registrera konto`,
      en: 'Sign up',
      de: 'Anmelden',
    }),
    'password.warning.perfect': translations({
      sv: 'Perfekt!',
      en: 'Perfect!',
      de: 'Perfekt!',
    }),
    'password.warning.safe': translations({
      sv: 'Säkert',
      en: 'Safe',
      de: 'Sicher',
    }),
    'password.warning.good': translations({
      sv: 'Bra',
      en: 'Good',
      de: 'Gut',
    }),
    'password.warning.too-weak': translations({
      sv: 'För svagt',
      en: 'Too weak',
      de: 'Zu schwach',
    }),
    'password.warning.unsafe': translations({
      sv: 'Inte säkert',
      en: 'Unsafe',
      de: 'Unsicher',
    }),
    'password.warning.too-short': translations({
      sv: 'För kort',
      en: 'Too short',
      de: 'Zu kurz',
    }),
    'Please fill in this field': translations({
      sv: 'Var god fyll i detta fält',
      en: 'Please fill in this field',
      de: 'Bitte füllen Sie dieses Feld aus',
    }),
    'email.already-in-use': translations({
      sv: 'Epost adressen du angivit är redan i bruk',
      en: 'Email address is already in use',
      de: 'E-Mail-Adresse wird bereits verwendet',
    }),
    'Find customer': translations({
      sv: 'Hitta kund',
      en: 'Find customer',
      de: 'Kunden finden',
    }),
    password: translations({
      sv: `Lösenord`,
      en: `Password`,
      de: `Passwort`,
    }),
    // components/client/client-settings/index
    'my-account': translations({
      sv: 'Mitt konto',
      en: 'My account',
      de: 'Mein Konto',
    }),
    // components/client/client-settings/clientImage
    'save-image': translations({
      sv: 'Spara bilden',
      en: 'Save image',
      de: 'Bild speichern',
    }),
    'Something went wrong': translations({
      sv: `Något gick fel 😬`,
      en: `Something went wrong 😬`,
      de: `Etwas ist schief gelaufen 😬`,
    }),
    'account-updated': translations({
      sv: 'Konto uppdaterat!',
      en: 'Account updated!',
      de: 'Konto aktualisiert!',
    }),
    'message-done': translations({
      sv: 'Klart',
      en: 'Done',
      de: 'Fertlig',
    }),
    'user-already-exists': translations({
      sv: 'Användaren finns redan',
      en: 'User already exists',
      de: 'Benutzer existiert bereits',
    }),
    Wrong: translations({
      sv: `Fel`,
      en: `Wrong`,
      de: `Falsch`,
    }),
    'Try again': translations({
      sv: `Försök igen`,
      en: `Try again`,
      de: `Versuche es nochmal!`,
    }),
    'We were not able to save your changes...': translations({
      sv: 'Det gick inte att spara ändringarna. Om du tror att det beror på ett problem med AdventureHero så var snäll och kontakta supporten: support@adventureheros.se',
      en: 'We were not able to save your changes. If you think the issue lies with AdventureHero please contact our support and report the problem: support@adventureheros.se',
      de: 'Ihre Änderungen konnten nicht gespeichert werden. Wenn Sie der Meinung sind, dass das Problem bei AdventureHero liegt, wenden Sie sich bitte an unseren Support und melden Sie das Problem:support@adventureheros.se',
    }),
    'user-already-exists.message': translations({
      sv: 'Det finns redan en användare med den angivna mailadressen i systemet. Var god ange en annan mailadress eller be användaren återställa sitt lösenord om denne inte kan logga in.',
      en: "There is already a user with that email adress in the system. Please enter another email or ask the user to reset their password if they can't login.",
      de: 'Es gibt bereits einen Benutzer mit dieser E-Mail-Adresse im System. Geben Sie eine andere E-Mail-Adresse ein oder bitten Sie den Benutzer, sein Passwort zurückzusetzen, wenn er sich nicht anmelden kann.',
    }),
    'wrong-password': translations({
      sv: `Fel lösenord.. Försök igen!`,
      en: `Password not valid. Try again!`,
      de: `Passwort ungültig. Versuche es nochmal!`,
    }),
    'invalid-credentials': translations({
      sv: 'Fel användarnamn eller lösenord',
      en: 'Wrong username or password',
      de: 'Benutzername oder Passwort falsch',
    }),
    'Forgot your password?': translations({
      sv: 'Glömt lösenordet?',
      en: 'Forgot your password?',
      de: 'Passwort vergessen?',
    }),
    'Log in': translations({
      sv: 'Logga in',
      en: 'Log in',
      de: 'Einloggen',
    }),
    'Send me an email': translations({
      sv: 'Skicka ett mail till mig',
      en: 'Send me an email',
      de: 'Schick mir eine E-mail',
    }),
    'reset-password.feedback-success': translations({
      sv: 'Vi har skickat dig ett mail med ytterligare instruktioner!',
      en: 'A mail has been sent to you with further instructions!',
      de: 'Eine E-Mail mit weiteren Anweisungen wurde Ihnen zugesandt!',
    }),
    'reset-password.feedback-failure': translations({
      sv: 'Ledsen, vi kunde inte hitta din emailadress! Försök gärna igen...',
      en: 'Sorry, the email address doesn´t exists in our database, please try again...',
      de: 'Entschuldigung, die E-Mail-Adresse existiert nicht in unserer Datenbank, bitte versuchen Sie es erneut...',
    }),
    'reset-password.description': translations({
      sv: 'Fyll i din emailadress så skickar vi en länk som hjälper dig att skapa ett nytt lösenord.',
      en: 'Enter your email address and we will send a mail that lets you change your password.',
      de: 'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen eine E-Mail, mit der Sie Ihr Passwort ändern können.',
    }),
    'facebook.signup': translations({
      sv: 'Skapa konto med facebook',
      en: 'Sign up with facebook',
      de: 'Mit Facebook anmelden',
    }),
    'facebook-login': translations({
      sv: 'Logga in med facebook',
      en: 'Log in with facebook',
      de: 'Mit Facebook einloggen',
    }),
    or: translations({
      sv: 'eller',
      en: 'or',
      de: 'oder',
    }),
    'new-activity': translations({
      sv: `Ny aktivitet`,
      en: `New activity`,
      de: `Neue aktivität`,
    }),
    //components/resetPasswordView
    'reset-password.unauthorized': translations({
      sv: 'Tyvärr är länken inte giltig för att den har gått ut eller redan använts. Du kan skaffa en ny länk via login sidan på AdventureHero 😇',
      en: 'Unfortunately, the link is not valid because it has expired or has already been used. You can get a new link via the login page on AdventureHero 😇',
      de: 'Leider ist der Link nicht gültig, da er abgelaufen ist oder bereits verwendet wurde. Einen neuen Link bekommt ihr über die Login-Seite auf AdventureHero 😇',
    }),
    //components/calendar/sidebar
    'filter-activities': translations({
      sv: 'Filtrera på aktivitet',
      en: 'Filter by activity',
      de: 'Nach Aktivität filtern',
    }),
    'showing-all-activities': translations({
      sv: 'Visar alla aktiviteter',
      en: 'Showing all activities',
      de: 'Alle Aktivitäten anzeigen',
    }),
    //components/calendar/store
    'notification.too-late': translations({
      sv: 'Attans. För sent ute 🙄',
      en: 'Darn. Too late 🙄',
      de: 'Verflixt. Zu spät 🙄',
    }),
    //components/calendar/views/submittableNotes
    'activity-was-saved-but-not-reported': translations({
      sv: 'Aktiviteten är uppdaterad och sparad, toppen! Tyvärr gick det inte att skicka ut mail om ändringen till den planerade personalen 😬, om problemet kvarstår var god kontakta supporten',
      en: 'The activity is updated and saved, great! Unfortunately it was not possible to send out an email about the change to the planned staff 😬, if the problem persists please contact our support',
      de: 'Die Aktivität wird aktualisiert und gespeichert, großartig! Leider war es nicht möglich, eine E-Mail über die Änderung an das geplante Personal zu senden 😬, sollte das Problem weiterhin bestehen, wenden Sie sich bitte an unseren Support',
    }),
    //components/client/clientMessages
    messages: translations({
      sv: 'meddelanden',
      en: 'messages',
      de: 'Mitteilungen',
    }),
    //components/customer/authenticationModal/index
    'set-up-business': translations({
      sv: 'Konfigurera mitt företag',
      en: 'Set up my business',
      de: 'Mein Geschäft einrichten',
    }),
    'sign-up-as-client': translations({
      sv: 'registrera dig som kund',
      en: 'sign up as a client',
      de: 'als Kunde anmelden',
    }),
    //components/customer/authenticationModal/memberSignup
    'accept-license-and-agreement': translations({
      sv: 'Du måste acceptera våra licenser och avtal',
      en: 'You need to accept the licenses and agreements',
      de: 'Sie müssen die Lizenzen und Vereinbarungen akzeptieren',
    }),
    'non-valid-password': translations({
      sv: 'Lösenordet är inte giltigt',
      en: 'Password is not valid',
      de: 'Passwort ist nicht gültig',
    }),
    'become-member': translations({
      sv: 'Bli medlem',
      en: 'Become a member',
      de: 'Mitglied werden',
    }),
    'company name': translations({
      sv: 'Organisationsnamn',
      en: 'Company name',
      de: 'Name der Firma',
    }),
    'contact-person': translations({
      sv: 'Kontaktperson',
      en: 'Contact person',
      de: 'Gesprächspartner',
    }),
    'phone-number': translations({
      sv: 'Telefonnummer',
      en: 'Phone number',
      de: 'Telefonnummer',
    }),
    accepting: translations({
      sv: 'Accepterar',
      en: 'Accepting',
      de: 'Akzeptieren',
    }),
    'sign-up-as-saloon': translations({
      sv: 'Registrera dig som salong',
      en: 'Sign up as saloon',
      de: 'Als Salon anmelden',
    }),
    'sign-up-as-freelancer': translations({
      sv: 'Registrera dig som frilansare',
      en: 'Sign up as freelancer',
      de: 'Als Freelancer registrieren',
    }),
    'destination-partner': translations({
      sv: 'Destinationsaktör',
      en: 'Destination partner',
      de: 'Zielortspartner',
    }),
    //client/clientBooking
    'refund-conditionss-first': translations({
      sv: 'Avbokningsavgiften för',
      en: 'The cancellation fee of',
      de: 'Die Stornogebühr von',
    }),
    'refund-conditionss-second': translations({
      sv: '€ kommer inte att vara includerad i återbetalningen',
      en: '€ will not be included in the refund.',
      de: '€ werden nicht zurückerstattet.',
    }),
    'time-past-to-cancel': translations({
      sv: 'Avbokning är inte tillåtet inom 24 timmar från starttiden',
      en: 'Cancellation is not allowed within 24 hours from the start time.',
      de: 'Eine Stornierung ist nicht innerhalb von 24 Stunden ab der Startzeit möglich',
    }),
    //client/clientBooking
    'delete-booking-success': translations({
      sv: 'Bokningen är nu borttagen',
      en: 'The booking is now removed',
      de: 'Die Buchung ist jetzt entfernt',
    }),
    'delete-booking-error': translations({
      sv: 'Det gick inte att ta bort bokningen, var god försök igen.',
      en: 'Could not remove booking, please try again.',
      de: 'Die Buchung konnte nicht entfernt werden. Bitte versuchen Sie es erneut.',
    }),
    'no-upcoming-bookings': translations({
      sv: 'Du har inga kommande bokningar 😢',
      en: 'You don´t have any upcoming bookings 😢',
      de: 'Du hast keine bevorstehenden Buchungen 😢',
    }),
    'no-historical-bookings': translations({
      sv: 'Du har inga tidigare bokningar 😢',
      en: 'You don´t have any historical bookings 😢',
      de: 'Sie haben keine historischen Buchungen 😢',
    }),
    'bookings.upcoming.message': translations({
      sv: 'Kommande bokningar',
      en: 'Upcoming bookings',
      de: 'Anstehende Buchungen',
    }),
    'bookings.historical.message': translations({
      sv: 'Bokningshistorik',
      en: 'Booking history',
      de: 'Buchungshistorie',
    }),
    'show-history': translations({
      sv: 'Visa historik',
      en: 'Show history',
      de: 'Zeige die Geschichte',
    }),
    'show-upcoming': translations({
      sv: 'Visa kommande',
      en: 'Show upcoming',
      de: 'Nächstes anzeigen',
    }),
    'no-bookings-found': translations({
      sv: 'Inga bokningar hittade',
      en: 'No bookings found',
      de: 'Keine Buchungen gefunden',
    }),
    //components/dashboard/home/segments/accountActivationSegment/verificationForm.tsx
    'verification-code.not-valid': translations({
      sv: 'Koden är inte giltig, ange koden som skickas till din mail.',
      en: 'Code is not valid, please enter the code sent to your email.',
      de: 'Code ist ungültig, bitte geben Sie den an Ihre E-Mail gesendeten Code ein.',
    }),
    'unexpected-error': translations({
      sv: 'Oväntat fel, försök gärna igen',
      en: 'Unexpected error, please try again',
      de: 'Unerwarteter Fehler, bitte versuchen Sie es erneut',
    }),
    //widgets/_propertySelectionView
    'copy-successful': translations({
      sv: 'Kopierat! 👍',
      en: 'Copied! 👍',
      de: 'Kopiert! 👍',
    }),
    'copy-error': translations({
      sv: 'Kunde inte kopiera automatiskt.. 😦',
      en: 'Could not automatically copy.. 😦',
      de: 'Konnte nicht automatisch kopiert werden..😦',
    }),
    'img-save-error': translations({
      sv: 'Något gick fel när anläggningsbilden skulle sparas. Om du tror att det beror på ett problem med AdventureHero så var snäll och kontakta supporten',
      en: "Something went wrong when saving the property image. If you think it's due to a problem with AdventureHero please contact the support.",
      de: 'Beim Speichern des Eigenschaftsbilds ist ein Fehler aufgetreten. Wenn Sie der Meinung sind, dass es an einem Problem mit AdventureHero liegt, wenden Sie sich bitte an den Support.',
    }),
    //employees/form
    'email.error.already-in-use': translations({
      sv: 'Mailadressen används redan',
      en: 'The email you have specified is already in use',
      de: 'Die von Ihnen angegebene E-Mail wird bereits verwendet',
    }),
    'not-possible-to-add': translations({
      sv: 'Det gick inte att lägga till personen. Om du tror att det beror på ett problem med AdventureHero så var snäll och kontakta supporten.',
      en: "Could not add the person. If you think it's due to a problem with AdventureHero please contact the support.",
      de: 'Die Person konnte nicht hinzugefügt werden. Wenn Sie der Meinung sind, dass es an einem Problem mit AdventureHero liegt, wenden Sie sich bitte an den Support.',
    }),
    //components/dashboard/featureLockIcon.tsx
    'feature-active': translations({
      sv: 'Funktionen är aktiverad!',
      en: 'The function is activated!',
      de: 'Die Funktion ist aktiviert!',
    }),
    'feature-inactive': translations({
      sv: 'Funktionen är inaktiv!',
      en: 'The function is inactive!',
      de: 'Die Funktion ist inaktiv!',
    }),
    //modules/browser/adventure-hero/components/dashboard/offers/details/updateModal.tsx
    'components.dashboard.offers.details.updateModal.successMessage': translations({
      sv: 'Uppkommande aktiviteter är nu uppdaterade',
      en: 'Upcoming activities are now updated',
      de: 'Anstehende Aktivitäten werden jetzt aktualisiert',
    }),
    //modules/browser/adventure-hero/components/dashboard/prompts/comingSoonPrompt.tsx
    'components.dashboard.prompts.comingSoonPrompt.message': translations({
      sv: 'Den här funktionaliteten är under utveckling och kommer vara tillgänglig inom kort',
      en: 'This functionality is under development and will be available shortly',
      de: 'Diese Funktionalität befindet sich in der Entwicklung und wird in Kürze verfügbar sein.',
    }),
    'Coming soon': translations({
      sv: 'Kommer snart',
      en: 'Coming soon',
      de: 'Kommt bald',
    }),
    //modules/browser/adventure-hero/components/dashboard/properties/propertyStore.ts
    'components.dashboard.properties.propertyStore.saveErrorDetails': translations({
      sv: 'Det gick inte att spara ändringarna för anläggningen.',
      en: 'Changes could not be saved for the property',
      de: 'Die Änderungen für die Einrichtung konnten nicht gespeichert werden.',
    }),
    'components.dashboard.properties.propertyStore.saveErrorTitle': translations({
      sv: 'Något gick snett',
      en: 'Something went wrong',
      de: 'Etwas ist schief gelaufen',
    }),
    'try-again-later': translations({
      sv: 'Något gick fel, Försök igen senare',
      en: 'An error has occured, please try again later!',
      de: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal!',
    }),
    'base-price': translations({
      sv: 'Grundpris',
      en: 'Base price',
      de: 'Grundpreis',
    }),
    'Lets your visitors indicate how many bags...': translations({
      sv: 'Låter dina besökare ange hur många väskor de har med sig på aktiviteten. Du kommer att kunna skriva ut QR-koder för varje väska och sedan uppdatera dess status och position under aktivitetens gång.',
      en: 'Lets your visitors indicate how many bags they have with them at the activity. You will be able to print QR codes for each bag and then update its status and position during the activity.',
      de: 'Lassen Sie Ihre Besucher angeben, wie viele Taschen sie bei der Aktivität dabei haben. Sie können QR-Codes für jede Tasche ausdrucken und dann ihren Status und ihre Position während der Aktivität aktualisieren.',
    }),
    'The base price is a fixed fee...': translations({
      sv: 'Grundpriset är en fast avgift på ditt erbjudande och är oberoende av hur många biljetter och tillbehör som har köpts. Grundpriset visas för dina kunder både under bokningsprocessen och i bekräftelsemailet.',
      en: 'The base price is a fixed fee for your offer and is independent of how many tickets and accessories that have been purchased. The base price is displayed to your customers both during the booking process and in the confirmation email.',
      de: 'Der Grundpreis ist ein Festpreis für Ihr Angebot und ist unabhängig davon, wie viele Tickets und Zubehör gekauft wurden. Der Grundpreis wird Ihren Kunden sowohl während des Buchungsvorgangs als auch in der Bestätigungs-E-Mail angezeigt.',
    }),
    oops: translations({
      sv: 'Oops..',
      en: 'Oops..',
      de: 'Oops..',
    }),
    // TODO: Översätt när/om vi bestämmer oss för att använda klientgränssnittet #1933
    'components.client.bookingItem.contactInfo': translations({
      sv: 'TEST',
      en: null,
      de: null,
    }),
    'components.client.bookingItem.paymentInfo': translations({
      sv: 'TEST',
      en: null,
      de: null,
    }),
    'components.client.bookingItem.paymentUnpaid': translations({
      sv: 'TEST',
      en: null,
      de: null,
    }),
    'components.client.bookingItem.paymentPaid': translations({
      sv: 'TEST',
      en: null,
      de: null,
    }),
    'components.client.bookingItem.paymentRefunded': translations({
      sv: 'TEST',
      en: null,
      de: null,
    }),
    'components.customer.authenticationModal.clientSignup.descriptionTwo': translations({
      sv: 'TEST',
      en: null,
      de: null,
    }),
    // Exported from database:
    Description: translations({
      sv: `Beskrivning`,
      en: `Description`,
      de: `Beschreibung`,
    }),
    'components.admin.newOrganizationForm.infoOne': translations({
      sv: `den mail som kopplas till kundens Stripe-konto (baseras på org-namnet)`,
      en: `the mail associated with the customer's Stripe account`,
      de: `die E-Mail-Adresse, die mit dem Stripe-Konto verknüpft ist`,
    }),
    'components.admin.organizations.tableRow.suspend': translations({
      sv: `Suspenderad`,
      en: `Suspended`,
      de: `Suspendiert`,
    }),
    Month: translations({
      sv: `Månad`,
      en: `Month`,
      de: `Monat`,
    }),
    'Add a new badge': translations({
      sv: `Lägg till ett nytt märke`,
      en: `Add a new badge`,
      de: `Neue Medaille hinzufügen`,
    }),
    'components.admin.index.title': translations({
      sv: `Vyer för systemägare`,
      en: `View for the system owner`,
      de: `Ansicht für den Systembesitzer`,
    }),
    'components.admin.index.underConstructionMessageTitle': translations({
      sv: `Denna sidan är till dig som plattformsägare.`,
      en: `This page is for you as a platform owner.`,
      de: `Diese Seite ist für Sie als Plattformbesitzer.`,
    }),
    'Create new organization': translations({
      sv: `Skapa en ny organisation`,
      en: `Create new organization`,
      de: `Neues Unternehmen erstellen`,
    }),
    Instruction: translations({
      sv: `Instruktion`,
      en: `Instruction`,
      de: `Anleitung`,
    }),
    Organization: translations({
      sv: `Organisation`,
      en: `Organization`,
      de: `Organisation`,
    }),
    'leave-empty-for-all': translations({
      sv: `lämna tomt för alla`,
      en: `leave empty for all`,
      de: `für alle leer lassen`,
    }),
    'components.admin.organizations.tableRow.noBadges': translations({
      sv: `Inga märken finns`,
      en: `No badges`,
      de: `Keine Medaillen`,
    }),
    Day: translations({
      sv: `Dag`,
      en: `Day`,
      de: `Tag`,
    }),
    Organizations: translations({
      sv: `Organisationer`,
      en: `Organizations`,
      de: `Organisationen`,
    }),
    'Activate account': translations({
      sv: `Aktivera kontot`,
      en: `Activate account`,
      de: `Konto aktivieren`,
    }),
    'Details about organization manager': translations({
      sv: `Fyll i uppgifter för organisationsansvarig`,
      en: `Details about organization manager`,
      de: `Informationen zur Firma`,
    }),
    'components.admin.organizations.tableRow.loginAs': translations({
      sv: `Logga in på kontot`,
      en: `Log in to account`,
      de: `Anmelden`,
    }),
    'components.admin.userFormContent.messageOne': translations({
      sv: `Lösenord via mail`,
      en: `Password by mail`,
      de: `Passwort per Mail`,
    }),
    'components.admin.index.navLink3': translations({
      sv: `Skapa ny organisation`,
      en: `Create new organization`,
      de: `Erstelle ein neues Unternehmen`,
    }),
    'components.admin.organizations.tableRow.suspendAccount': translations({
      sv: `Stäng av kontot`,
      en: `Turn off the account`,
      de: `Deaktivieren das Konto`,
    }),
    'components.admin.userFormContent.passwordInputTwo': translations({
      sv: `Lösenord1`,
      en: `Password1`,
      de: `Passwort1`,
    }),
    'components.admin.badges.listBadges': translations({
      sv: `Lista märken`,
      en: `List badges`,
      de: `Liste Medaillen`,
    }),
    'components.admin.index.navlink4': translations({
      sv: `Skapa ny användare`,
      en: `Create new user`,
      de: `Erstelle einen neuen Nutzer`,
    }),
    'components.admin.newletters.title': translations({
      sv: `Nyhetsbrev`,
      en: `Newsletter`,
      de: `Newsletter`,
    }),
    'components.admin.userFormContent.passwordInputOne': translations({
      sv: `Var snäll och ange ett giltigt lösenord på minst 6 tecken`,
      en: `Please enter a valid password with at least 6 characters`,
      de: `Bitte gib ein gültiges Passwort mit mindestens 6 Zeichen ein`,
    }),
    'components.admin.index.header': translations({
      sv: `SYSTEMÄGARE`,
      en: `SYSTEMOWNER`,
      de: `Systembesitzer`,
    }),
    'components.admin.newUser.message': translations({
      sv: `I denna vy kan du skapa en ny organisationsansvarig för vald organisation.`,
      en: `This is where you create a new organization manager for the selected organization.`,
      de: `Hier erstellen Sie einen neuen Organisationsmanager für die ausgewählte Organisation.`,
    }),
    'components.admin.newletters.copyButtonTextMembers': translations({
      sv: `Kopiera mailadresser för aktörer som vill ha nyhetsbrev`,
      en: `Copy email addresses to partners that wish to receive newsletters.`,
      de: `E-Mail Adressen von Partner, die Newsletter erhalten möchten, kopieren`,
    }),
    Badges: translations({
      sv: `Märken`,
      en: `Badges`,
      de: `Medaille`,
    }),
    'components.admin.index.navLink2': translations({
      sv: `Statistik`,
      en: `Statistics`,
      de: `Statistik`,
    }),
    'components.admin.organizations.tableRow.activated': translations({
      sv: `Aktiverat`,
      en: `Activated`,
      de: `Aktiviert`,
    }),
    'components.admin.userFormContent.checkbox': translations({
      sv: `Skapa ett lösenord nu`,
      en: `Create a password`,
      de: `Erstelle jetzt ein Passwort.`,
    }),
    'components.admin.newUser.buttonOne': translations({
      sv: `Spara användaren`,
      en: `Save user`,
      de: `Benutzer speichern`,
    }),
    'components.admin.organizations.tableRow.verification': translations({
      sv: `Verifikation`,
      en: `Verification`,
      de: `Verifikation`,
    }),
    Year: translations({
      sv: `År`,
      en: `Year`,
      de: `Jahr`,
    }),
    'components.admin.organizations.index.loginAccountWarning': translations({
      sv: `Någonting gick fel.. Kontakta supporten om problemet kvarstår`,
      en: `Something went wrong...Contact support if the problem persists`,
      de: `Etwas ist schief gelaufen. Bitte lade die Seite neu und versuche es erneut.`,
    }),
    'Create an organization': translations({
      sv: `Skapa organisationen`,
      en: `Create an organization`,
      de: `Unternehmen erstellen`,
    }),
    'components.admin.newUser.infoOne': translations({
      sv: `Välj en organisation`,
      en: `Select an organization`,
      de: `Wähle ein Organisation`,
    }),
    'components.admin.userFormContent.messageTwo': translations({
      sv: `Ett mail kommer att skickas ut till den angivna mailadressen där användaren själv får välja sitt lösenord`,
      en: `A message will be sent to the specified email address where they can choose their own password`,
      de: `Eine Nachricht wird an die angegebene E-Mail-Adresse gesendet, in der selbst ein Passwort erstellen werden kann.`,
    }),
    'components.admin.newOrganizationForm.labelOne': translations({
      sv: `Stripe epost`,
      en: `Stripe email`,
      de: `Stripe E-Mail`,
    }),
    'components.admin.organizations.tableRow.logoutAs': translations({
      sv: `Logga ut från kontot`,
      en: `Log out`,
      de: `Abmelden`,
    }),
    'components.admin.badgeForm.saveBadge': translations({
      sv: `Spara märke`,
      en: `Save badge`,
      de: `Medaillen speichern`,
    }),
    'components.admin.index.underConstructionMessageDescription': translations({
      sv: `Här kan du som ägare till plattformen hantera dina organisationer.`,
      en: `As an owner of the platform you will be able to administrate your organizations.`,
      de: `Hier können Sie als Inhaber der Plattform Ihre Organisationen verwalten.`,
    }),
    'components.admin.newletters.copyButtonTextClients': translations({
      sv: `Kopiera mailadresser för kunder som vill ha nyhetsbrev`,
      en: `Copy email addresses to customers that wish to recive newsletters.`,
      de: `E-Mail Adressen von Kunden, die Newsletter erhalten möchten, kopieren`,
    }),
    Activation: translations({
      sv: `Aktivering`,
      en: `Activation`,
      de: `Aktivierung`,
    }),
    Bookings: translations({
      sv: 'Bokningar',
      en: 'Bookings',
      de: 'Buchungen',
    }),
    Booking: translations({
      sv: 'Bokning',
      en: 'Booking',
      de: 'Buchungen',
    }),
    'components.calendar.store.week': translations({
      sv: `vecka`,
      en: `week`,
      de: `Woche`,
    }),
    'components.calendar.views.assignEmployees.propertyEmployeesList.iconFour': translations({
      sv: `Skicka jobbförfrågan till alla frivilliga`,
      en: `Send the job request to all volunteers`,
      de: `Terminanfrage an alle verfügbaren Mitarbeiter senden`,
    }),
    'components.calendar.views.assignEmployees.propertyEmployeesList.messageTwo': translations({
      sv: `Om du väljer att schemalägga markerad personal kommer antalet frivilliga att bli fler än vad som är planerat för den här aktiviteten.`,
      en: `If you choose to schedule selected personnel, the number of staff will be more than what is planned for this task.`,
      de: `Wenn Sie ausgewählte Mitarbeiter planen, ist die Anzahl des Personals für diese Anwendung höher als geplant.`,
    }),
    'components.calendar.views.editActivityForm.alertFour': translations({
      sv: `Aktiviteten är updaterad och sparade, toppen! Tyvärr gick det inte att skicka ut mail om ändringen till den planerade personalen 😬, om problemet kvarstår var god kontakta supporten`,
      en: `The activity is updated and saved, great! Unfortunately, we could not send a mail about the change to the scheduled staff. If the problem persists please contact support.`,
      de: `Die Anwendung wird aktualisiert und gespeichert, toll! Leider konnten wir keine E-Mails über die Änderung an die geplanten Mitarbeiter senden. Wenn das Problem weiterhin besteht, wende dich an den Support.`,
    }),
    'Time in minutes': translations({
      sv: `Tid i minuter`,
      en: `Time in minutes`,
      de: `Zeit in Minuten`,
    }),
    'Through www.adventurehero.se we display all...': translations({
      sv: `Via www.adventurehero.se marknadsför vi alla aktörer och gör det möjligt för äventyrare att hitta aktiviteter.`,
      en: `Through www.adventurehero.se we display all organizations and make it possible for adventurers to find activities.`,
      de: `Über www.adventurehero.se vermarkten wir alle Akteure und ermöglichen Abenteurern, Aktivitäten zu finden.`,
    }),
    'Search for a place or activity': translations({
      sv: `Sök efter en äventyrspark eller aktivitet`,
      en: `Search for a place or activity`,
      de: `Suche nach Park oder Aktivität`,
    }),
    'components.customer.giftcardShowcase.messageTwo': translations({
      sv: `Överraska någon du tycker om med ett`,
      en: `Surprise someone you care about with a`,
      de: `Überrasche jemanden mit einem`,
    }),
    'Add payment': translations({
      sv: `Lägg till betalning`,
      en: `Add payment`,
      de: `Zahlung hinzufügen`,
    }),
    'components.dashboard.employees.employeeCard.mailWorkHours': translations({
      sv: `Du får mail om ändrade arbetstider`,
      en: `You will recive email notifications for job times`,
      de: `Du bekommst E-Mail-Benachrichtigungen`,
    }),
    'components.dashboard.employees.form.password': translations({
      sv: `Aktuellt lösenord`,
      en: `Current password`,
      de: `Aktuelles Passwort`,
    }),
    'components.dashboard.employees.new.header': translations({
      sv: `Lägg till en ny anställd till`,
      en: `Add employee for`,
      de: `Neuen Mitarbeiter hinzufügen`,
    }),
    'components.dashboard.giftCards.form.pictureGiftcard': translations({
      sv: `(bild på presentkortet)`,
      en: `(giftcard image)`,
      de: `Bild Gutscheinkarte`,
    }),
    Customer: translations({
      sv: `Kund`,
      en: `Customer`,
      de: `Kunde`,
    }),
    'Remove giftcard': translations({
      sv: `Ta bort presentkortet`,
      en: `Remove giftcard`,
      de: `Gutschein entfernen`,
    }),
    'available slots': translations({ sv: `platser är lediga`, en: `available slots`, de: `verfügbare Plätze` }),
    'components.calendar.store.labelAlertMessage': translations({
      sv: `Det går tyvärr inte att skapa en starttid som skulle börjat `,
      en: `Unfortunately, it is not possible to create a start time that would begin `,
      de: `Leider ist es nicht möglich, eine Startzeit zu erstellen, die beginnen würde `,
    }),
    'Personnel list': translations({
      sv: `Personallista`,
      en: `Personnel list`,
      de: `Personalliste`,
    }),
    'Add personnel': translations({
      sv: `Lägg till personal`,
      en: `Add personnel`,
      de: `Personal hinzufügen`,
    }),
    'Choose a time in the schedule...': translations({
      sv: `Klicka på en aktivitet i schemat som du vill att bokningen ska flyttas till.`,
      en: `Choose a time in the schedule, in which the booking should be moved to.`,
      de: `Klicke im Terminplan auf eine Lücke, in die die Buchung verschoben werden soll.`,
    }),
    'Select an activity in the list...': translations({
      sv: `Välj på en aktivitet i listan som du vill att bokningen ska flyttas till.`,
      en: `Select an activity in the list, in which the booking should be moved to.`,
      de: `Wählen Sie eine Aktivität aus der Liste aus, in die die Buchung verschoben werden soll.`,
    }),
    'For Partners': translations({
      sv: `För aktörer`,
      en: `For Partners`,
      de: `Für Partners`,
    }),
    'The booking is old.': translations({
      sv: `Bokningen är gammal. 🎩`,
      en: `The booking is old. 🎩`,
      de: `Die Buchung ist alt. 🎩`,
    }),
    'Oops..': translations({
      sv: `Oj då.. 😯`,
      en: `Oops.. 😲`,
      de: `Oh dann.. 😯`,
    }),
    'Unfortunately it was not possible to send the e-mail...': translations({
      sv: `Tyvärr kunde mailet inte skickas, var god kolla så att mailadressen är giltig.`,
      en: `Unfortunately it was not possible to send the e-mail, please ensure that the address is correct.`,
      de: `Leider konnte die E-Mail nicht gesendet werden. Bitte überprüfe, ob die E-Mail-Adresse gültig ist.`,
    }),
    'You can search for and manage your bookings here...': translations({
      sv: `Här kan du söka efter och hantera dina bokningar. Tänk på att du kan kombinera flera sökord för att hitta rätt bokning.`,
      en: `You can search and manage your bookings here. Remember that you can combine multiple keywords to find the right booking.`,
      de: `Hier kannst du deine Buchungen suchen und verwalten. Denke daran, dass du mehrere Suchbegriffe kombinieren kannst, um die richtige Buchung zu finden.`,
    }),
    "You can search and manage bookings you've made...": translations({
      sv: 'Här kan du söka efter och hantera de bokningar du gjort hos andra aktörer inom din destination.',
      en: "You can search for and manage bookings you've made at properties within your destination here.",
      de: 'Hier können Sie nach Buchungen suchen und verwalten, die Sie in Unterkünften in Ihrem Reiseziel vorgenommen haben.',
    }),
    'Email notification': translations({
      sv: `Mailnotis`,
      en: `Email notification`,
      de: `E-Mail-Benachrichtigung`,
    }),
    'components.dashboard.employees.index.allEmployees': translations({
      sv: `Alla anställda på`,
      en: `All employees at`,
      de: `Alle Mitarbeiter von`,
    }),
    'components.dashboard.giftCards.form.giftcardName': translations({
      sv: `Presentkortets namn`,
      en: `Gift card name`,
      de: `Name der Gutscheinkarte`,
    }),
    'components.dashboard.giftCards.form.giftcardValue': translations({
      sv: `Ange presentkortets värde i kronor`,
      en: `Add gift card value`,
      de: `Gutscheinkartenwert hinzufügen`,
    }),
    'components.calendar.views.bookingInfoItem.infoFour': translations({
      sv: `kr kvar att betala`,
      en: `SEK left for payment`,
      de: `SEK Restbetrag`,
    }),
    'components.calendar.views.bookingInfoItem.infoTwo': translations({
      sv: `kr från presentkort`,
      en: `SEK from gift payment`,
      de: `SEK von Gutschrift`,
    }),
    'components.calendar.views.editActivityForm.alertTen': translations({
      sv: `Tyvärr kunde inte aktiviteten tas bort, var god kontakta supporten.`,
      en: `Unfortunately, the activity could not be removed, please contact support.`,
      de: `Leider konnte die Anwendung nicht entfernt werden. Bitte wende dich an den Support.`,
    }),
    'Fill in name of activity': translations({
      sv: `Ange ett namn på aktiviteten`,
      en: `Fill in name of activity`,
      de: `Benenne die Anwendung`,
    }),
    'components.calendar.views.submittableNotes.messageTwo': translations({
      sv: `Spara anteckningen`,
      en: `Save notes`,
      de: `Anmerkungen speichern`,
    }),
    'components.customer.aboutUs.descriptionThree': translations({
      sv: `Häng med i upplevelsevärlden på`,
      en: `Join the world of experience`,
      de: `Trete der Welt der Erfahrung bei`,
    }),
    'components.customer.activities.propertyShowcase.headerOne': translations({
      sv: `Utforska våra`,
      en: `Explore our`,
      de: `Entdecke unsere`,
    }),
    'components.customer.footer.itemTwo': translations({
      sv: `POSTADRESS`,
      en: `Postal address`,
      de: `Postanschrift`,
    }),
    'components.dashboard.bookings.bookingItem.bookingInfo.contentThree': translations({
      sv: `Skapad:`,
      en: `Created`,
      de: `Erstellt:`,
    }),
    'components.dashboard.bookings.bookingItem.bookingInfo.contentTwo': translations({
      sv: `Starttid`,
      en: `Start time`,
      de: `Startzeit`,
    }),
    'The booking you are going to edit has already taken place...': translations({
      sv: `Bokningen som du är på väg att redigera gäller för en aktivitet som redan har varit. Är du säker på att det här är rätt bokning?`,
      en: `The booking you are going to edit has already taken place. Are you sure it is the correct booking you have chosen?`,
      de: `Die Buchung, die du bearbeiten möchtest, hat bereits begonnen. Bist du sicher, dass es sich um die richtige Buchung handelt?`,
    }),
    'No refund has been created.': translations({
      sv: `Ingen återbetalning har gjorts.`,
      en: `No refund has been created.`,
      de: `Es wurde keine Rückerstattung vorgenommen.`,
    }),
    'Log out': translations({
      sv: `Logga ut`,
      en: `Log out`,
      de: `Abmelden`,
    }),
    'components.dashboard.giftCards.handleGiftCardTypes.notActivatedTitle': translations({
      sv: `Här kan du i vanliga fall skapa nya presentkort`,
      en: `From here you can usualy create gift cards`,
      de: `Hier kannst du normalerweise neue Gutscheinkarten erstellen`,
    }),
    'Not paid': translations({
      sv: `Ej betald`,
      en: `Not paid`,
      de: `Nicht bezahlt`,
    }),
    'However, before you can create activities...': translations({
      sv: `Men innan du kan skapa aktiviter och presentkort måste du aktivera ditt konto`,
      en: `However, before you can create activities and giftcards, you must activate your account`,
      de: `Bevor dies möglich ist, musst du allerdings dein Konto aktivieren!`,
    }),
    'Add booking': translations({
      sv: `Lägg till bokningen`,
      en: `Add booking`,
      de: `Buchung hinzufügen`,
    }),
    'Minimum number of visitors': translations({
      sv: `Minsta antal besökande`,
      en: `Minimum number of visitors`,
      de: `Mindestanzahl von Kunden`,
    }),
    'components.calendar.views.submittableNotes.messageOne': translations({
      sv: `Skriv vad som är bokat här..`,
      en: `Write the down booking information here...`,
      de: `Buchungsinformationen hier eintragen`,
    }),
    'components.customer.aboutUs.description': translations({
      sv: `För upplevelse- och turistbranschen har vi skapat en digital plattform där aktörer som levererar upplevelser kan möta sina kunder som vill boka och planera aktiviteter. Vi tror att nya upplevelser berikar livet. Vi tror även att vi skapar en mer hållbar värld genom att vi uppmanar och engagerar människor till att uppleva mer. Genom att lyfta fram och göra det enklare att hitta och boka aktiviteter så skapar vi en bättre och mer händelserik vardag för alla.`,
      en: `For the adventure industry, we have created a digital platform where organisations who deliver experiences can meet their customers who want to book and plan activities. We believe that new experiences enrich life. We also believe that we create a more sustainable world by encouraging and engaging people to experience more. By highlighting and making it easier to find and book activities, we create a better and more happier everyday life for everyone.`,
      de: `Für die Erlebnis- und Tourismusbranche haben wir eine digitale Plattform geschaffen, auf der Organisationen die Erfahrungen liefern, ihre Kunden treffen können, die Aktivitäten buchen möchten. Wir glauben, dass neue Erfahrungen das Leben bereichern. Wir glauben auch, dass wir eine nachhaltigere Welt schaffen, indem wir Menschen dazu ermutigen, mehr zu erfahren. Durch das Hervorheben und Erleichtern des Auffindens und Buchens von Aktivitäten schaffen wir einen besseren und ereignisreicheren Alltag für alle.`,
    }),
    'Send job opportunities and schedule employees.': translations({
      sv: `Skicka jobberbjudande till timanställda och planera in personal på aktiviteter.`,
      en: `Send job opportunities and schedule employees.`,
      de: `Senden Sie ein Stellenangebot an stundenweise Beschäftigte und planen Sie Mitarbeiter für Aktivitäten.`,
    }),
    'Employee management': translations({
      sv: `Personalhantering`,
      en: `Employee management`,
      de: `Personal-Management`,
    }),
    'components.customer.activities.activitiesShowcase.textOne': translations({
      sv: `Inga aktiviteter hittades`,
      en: `No activities found`,
      de: `Keine Aktivitäten gefunden`,
    }),
    'components.customer.activities.searchDescription.fromCategories': translations({
      sv: `från kategorierna`,
      en: `from categories`,
      de: `aus den Kategorien`,
    }),
    'About us': translations({
      sv: `Om oss`,
      en: `About us`,
      de: `Über uns`,
    }),
    'Send confirmation to the customer.': translations({
      sv: `Skicka bekräftelse till kund`,
      en: `Send confirmation to the customer.`,
      de: `Bestätigung an den Kunden senden`,
    }),
    'Max 40 characters': translations({
      sv: `max 40 tecken`,
      en: `Max 40 characters`,
      de: `Max 40 Zeichen`,
    }),
    'components.dashboard.giftCards.form.questionExpireDate': translations({
      sv: `Hur länge skall presentkortet vara giltigt efter köp?`,
      en: `How long should the gift card be valid after purchase?`,
      de: `Wie lange sollte die Gutscheinkarte nach dem Kauf gültig sein?`,
    }),
    'components.dashboard.giftCards.handleGiftCardTypes.headerOne': translations({
      sv: `Tillgängliga presentkort hos`,
      en: `Available giftcards from`,
      de: `Verfügbare Gutscheinkarten bei`,
    }),
    'Sold by:': translations({
      sv: `Sålt av:`,
      en: `Sold by:`,
      de: `Verkauft von:`,
    }),
    'components.calendar.sidebar.buttonOne': translations({
      sv: `Skapa en aktivitet den`,
      en: `Create an activity at`,
      de: `Erstelle ein Angebot in`,
    }),
    'components.calendar.store.day': translations({
      sv: `dag`,
      en: `day`,
      de: `Tag`,
    }),
    'Send job request to selected personnel': translations({
      sv: `Skicka jobbförfrågan till markerad personal`,
      en: `Send job request to selected personnel`,
      de: `Jobanfrage an ausgewähltes Personal senden`,
    }),
    'If you schedule the selected personnel...': translations({
      sv: `Om du väljer att schemalägga markerad personal kommer antalet personal att bli fler än vad som är planerat för den här aktiviteten.`,
      en: `If you schedule the selected personnel, the number of personnel for this activity will be more than planned.`,
      de: `Wenn du bestimmte Personal einplanst, ist die Anzahl der Personal für diese Anwenung höher als ursprünglich geplant.`,
    }),
    Notes: translations({
      sv: `Anteckningar`,
      en: `Notes`,
      de: `Anmerkungen`,
    }),
    'Message to the customer': translations({
      sv: `Meddelande till kunden`,
      en: `Message to the customer`,
      de: `Nachricht an den Kunden`,
    }),
    'Message from the customer': translations({
      sv: `Meddelande från kunden`,
      en: `Message from the customer`,
      de: `Nachricht vom Kunden`,
    }),
    'It almost worked!': translations({
      sv: `Det gick nästan!`,
      en: `It almost worked!`,
      de: `Hat fast geklappt!`,
    }),
    'components.calendar.views.editActivityForm.buttonOne': translations({
      sv: `Ta bort aktiviteten`,
      en: `Remove activity`,
      de: `Aktivität entfernen`,
    }),
    'components.calendar.views.editActivityForm.messageOne': translations({
      sv: `Redigeringen är begränsad eftersom aktiviteten har en bokning`,
      en: `Editing is limited because the activity has a reservation`,
      de: `Die Bearbeitung ist eingeschränkt, da die Anwendung schon eine Buchung hat.`,
    }),
    'components.customer.template.linkSeven': translations({
      sv: `Överraska`,
      en: `Surprise`,
      de: `Überraschung`,
    }),
    'components.customer.template.linkSix': translations({
      sv: `Utforska`,
      en: `Explore`,
      de: `Suche`,
    }),
    'components.dashboard.bookings.bookingItem.statusInfo.payLeft': translations({
      sv: `kvar att betala`,
      en: `left to pay`,
      de: `Restbetrag`,
    }),
    'components.dashboard.bookings.bookingItem.statusInfo.payOnline': translations({
      sv: `från onlinebetalning`,
      en: `from online payment`,
      de: `von der Online-Zahlung`,
    }),
    'components.dashboard.employees.avatarDropdownField.avatarLabel': translations({
      sv: `Visningsbild`,
      en: `Image`,
      de: `Bild`,
    }),
    'components.dashboard.giftCards.form.updateDocumentAlertButton': translations({
      sv: `Grymt, tack så mycket.`,
      en: `Awesome, thank you.`,
      de: `Wunderbar, vielen Dank!`,
    }),
    'Are you sure?': translations({
      sv: `Är du säker?`,
      en: `Are you sure?`,
      de: `Bist du dir sicher?`,
    }),
    'Mark as used': translations({
      sv: `Markera som använt`,
      en: `Mark as used`,
      de: `Als genutzt markieren`,
    }),
    'components.calendar.store.today': translations({
      sv: `idag`,
      en: `today`,
      de: `Heute`,
    }),
    'components.calendar.views.createManualBookingForm.alertOne': translations({
      sv: `Bokningen är skapad.`,
      en: `Created booking`,
      de: `Die Buchung wurde erstellt.`,
    }),
    'Send confirmation mail to customer': translations({
      sv: `Skicka bekräftelsemail till kund`,
      en: `Send confirmation mail to customer`,
      de: `Bestätigungs-E-Mail an den Kunden senden`,
    }),
    'Include a payment link in the mail': translations({
      sv: `Inkludera en betallänk i mailet`,
      en: `Include a payment link in the mail`,
      de: `Fügen Sie einen Zahlungslink in die E-Mail ein`,
    }),
    Error: translations({
      sv: `Fel`,
      en: `Error`,
      de: `Fehler`,
    }),
    'Number of slots for visitors': translations({
      sv: `Antal platser för besökande`,
      en: `Number of slots for visitors`,
      de: `Anzahl der Plätze für Kunden`,
    }),
    'components.customer.activities.activitiesSearchBar.locationFreelancePlaceholder': translations({
      sv: `Fyll i ditt postnummer.`,
      en: `City or zipcode`,
      de: `Ort oder Postleitzahl`,
    }),
    'components.customer.footer.headerOne': translations({
      sv: `Vi tror på att koppla ihop, skapa och leverera kul aktiviteter.`,
      en: `We believe in connecting people with fun activities.`,
      de: `Wir glauben daran, lustige Aktivitäten zusammenzuführen, zu erstellen und zu liefern.`,
    }),
    'components.dashboard.bookings.bookingItem.deleteBookingForm.buttonOne': translations({
      sv: `Ta bort och återbetala`,
      en: `Remove and refund payment`,
      de: `Entfernen und zurückerstatten`,
    }),
    'I´m sure!': translations({
      sv: `Jag är säker!`,
      en: `I´m sure!`,
      de: `Ich bin mir sicher!`,
    }),
    'Change customer contact information.': translations({
      sv: `Ändra kunduppgifterna`,
      en: `Change customer contact information.`,
      de: `Kundeninformationen ändern`,
    }),
    'This booking is deleted': translations({
      sv: `Bokningen är borttagen`,
      en: `This booking is deleted`,
      de: `Diese Buchung ist gelöscht`,
    }),
    'Show deleted bookings': translations({
      sv: `Visa borttagna bokningar`,
      en: `Show deleted bookings`,
      de: `Gelöschte Buchungen anzeigen`,
    }),
    'Show booking requests': translations({
      sv: `Visa bokningsförfrågningar`,
      en: `Show booking requests`,
      de: `Buchungsanfragen anzeigen`,
    }),
    'Show uninvoiced bookings': translations({
      sv: `Visa obefakturerade bokningar`,
      en: `Show uninvoiced bookings`,
      de: `Zeige nicht in Rechnung gestellte Buchungen`,
    }),
    'Add to widget': translations({
      sv: `Lägg till i widget`,
      en: `Add to widget`,
      de: `Zum Widget hinzufügen`,
    }),
    'components.dashboard.bookings.bookingItem.refundComponent.inputFillin': translations({
      sv: `Du måste fylla i en summa att återbetala`,
      en: `You must fill in a sum to for refund payment`,
      de: `Du musst eine Summe zur Rückzahlung eingeben`,
    }),
    Summation: translations({
      sv: `Summering`,
      en: `Summation`,
      de: `Summes`,
    }),
    'components.dashboard.employees.form.changedWorkHours': translations({
      sv: `Få mail om ändrade arbetstider`,
      en: `Recive emails for changed jobs`,
      de: `E-Mail-Benachrichtigungen erhalten`,
    }),
    'components.dashboard.employees.form.invitationMessage': translations({
      sv: `En inbjudan kommer att skickas ut till den angivna mailadressen där den anställde själv får aktivera sitt konto`,
      en: `An invitation will be sent to the users email address and they will have to finish the registration`,
      de: `Eine Bestätigungs-E-Mail wird an die E-Mail-Adresse des Benutzers gesendet`,
    }),
    'Payment status': translations({
      sv: `Betalstatus`,
      en: `Payment status`,
      de: `Zahlungsstatus`,
    }),
    'Current card value': translations({
      sv: `Kvar på presentkortet`,
      en: `Current card value`,
      de: `Restbetrag`,
    }),
    'Booking closes before scheduled appointment, hours': translations({
      sv: `Bokning stängs före aktivitet i timmar`,
      en: `Booking closes before scheduled appointment, hours`,
      de: `Spät möglichste Buchung vor Beginn des Termins in Stunden.`,
    }),
    'Borås app tickets': translations({
      sv: `Borås-app biljetter`,
      en: `Borås app tickets`,
      de: `Tickets für die Borås-App`,
    }),
    'components.calendar.views.moveBooking.messageFive': translations({
      sv: `Flytta bokning`,
      en: `Move booking`,
      de: `Buchung verschieben`,
    }),
    'components.calendar.views.moveBooking.messageTwo': translations({
      sv: `Gammal aktivitet`,
      en: `Old activity`,
      de: `Alte Buchung`,
    }),
    'components.customer.activities.activitiesSearchBar.headerTwo': translations({
      sv: `upplevelse`,
      en: `adventure`,
      de: `Abenteuer`,
    }),
    'components.customer.activities.activitiesShowcase.loadMoreCardsButton': translations({
      sv: `Ladda fler aktiviteter`,
      en: `Load more activity cards`,
      de: `Mehr Karten laden`,
    }),
    'components.customer.footer.descriptionOne': translations({
      sv: `Tack för att du är en aktör hos oss på Adventure Hero och bidrar till nordens häftigaste mötesplats för äventyrare ❤️`,
      en: `Thank you for being part of AdventureHero ❤️`,
      de: `Danke, dass du ein Teil von AdventureHero bist ❤️`,
    }),
    'Unfortunately it was not possible to save the booking.': translations({
      sv: `Tyvärr gick det inte att spara bokningen. 😲`,
      en: `Unfortunately it was not possible to save the booking. 😲`,
      de: `Leider war es nicht möglich, die Buchung zu speichern. 😲`,
    }),
    'Admin view': translations({
      sv: `Ägarvy`,
      en: `Admin`,
      de: `Besitzer`,
    }),
    'No unread notifications': translations({
      sv: `Inga nya notiser`,
      en: `No unread notifications`,
      de: `Keine neuen Benachrichtigungen`,
    }),
    'Relax and enjoy a cup of koffee': translations({
      sv: `Koppla av och njut av en kopp kaffe`,
      en: `Relax and enjoy a cup of coffee`,
      de: `Entspannen Sie sich und genießen Sie eine Tasse Kaffee`,
    }),
    'components.dashboard.employees.avatarDropdownField.pleaseFinishUploading': translations({
      sv: `Du måste spara ditt val av avatar`,
      en: `You must save your choice of avatar`,
      de: `Sie müssen Ihre Wahl speichern`,
    }),

    'components.dashboard.giftCards.form.emailMessage': translations({
      sv: `Skicka email`,
      en: `Send email message`,
      de: `E-Mail senden`,
    }),
    'components.dashboard.giftCards.handleGiftCardTypes.inputOne': translations({
      sv: `Hitta ett presentkort...`,
      en: `Find a giftcard`,
      de: `Finde eine Gutscheinkarte ...`,
    }),
    'Search for giftcard numbers, customers or card names': translations({
      sv: `Sök efter presentkortsnummer, kund eller kortnamn`,
      en: `Search for giftcard numbers, customers or card names`,
      de: `Suche nach Gutscheinkartennummer, Kunden- oder Kartennamen`,
    }),
    of: translations({
      sv: `av`,
      en: `of`,
      de: `von`,
    }),
    'components.calendar.sidebar.instructionOne': translations({
      sv: `Klicka på en dag för att visa mer detaljer`,
      en: `Click on a day to see more details`,
      de: `Klicke auf einen Tag, um weitere Details anzuzeigen.`,
    }),
    'components.calendar.sidebar.instructionThree': translations({
      sv: `i kalendern för att lägga till nya starttider.`,
      en: `in the calendar to add new start times.`,
      de: `im Kalender, um neue Termine hinzuzufügen.`,
    }),
    'Send job request to all personnel': translations({
      sv: `Skicka jobbförfrågan till all personal`,
      en: `Send job request to all personnel`,
      de: `Terminanfrage an alle Personal senden`,
    }),
    'must-select-offer': translations({
      sv: `Du måste välja ett erbjudande`,
      en: `You must choose an offer`,
      de: `Du musst eine Anwendung auswählen`,
    }),
    'components.calendar.views.editActivityForm.alertEleven': translations({
      sv: `Kunde inte ta bort`,
      en: `Not possible to remove`,
      de: `Kann nicht entfernt werden.`,
    }),
    'components.calendar.views.editActivityForm.alertTwo': translations({
      sv: `Ändringarna sparades 👍`,
      en: `Changes was saved.👍`,
      de: `Die Änderungen wurden gespeichert. 👍`,
    }),
    'components.calendar.views.moveBooking.alertThree': translations({
      sv: `Tyvärr gick det inte att flytta bokningen, var god försök igen.`,
      en: `The booking could not be postponed, please try again.`,
      de: `Die Buchung konnte nicht verschoben werden, bitte versuche es erneut.`,
    }),
    'components.customer.activities.activitiesSearchBar.locationPlaceholder': translations({
      sv: `Stad eller postnr`,
      en: `Enter city or zipcode`,
      de: `Geben Sie die Stadt oder Postleitzahl ein`,
    }),
    'components.customer.activities.propertyShowcase.headerTwo': translations({
      sv: `Välj en anläggning från listan nedan för att se vilka aktiviteter som finns.`,
      en: `Choose a AdventurePartner from the list below in order to view available activties.`,
      de: `Wähle eine Unternehme aus der Liste unten, um zu sehen, welche Aktivitäten verfügbar sind.`,
    }),
    'components.dashboard.bookings.bookingItem.bookingInfo.contentFour': translations({
      sv: `Uppdaterad:`,
      en: `Updated:`,
      de: `Aktualisiert:`,
    }),
    'The mail has been sent to the customer.': translations({
      sv: `Mailet är nu skickat till kunden!`,
      en: `The mail has been sent to the customer.`,
      de: `Die Mail wurde jetzt an den Kunden gesendet!`,
    }),
    'Booking has been removed': translations({
      sv: `Bokningen är nu borttagen 👍`,
      en: `Booking has been removed 👍`,
      de: `Die Buchung wurde entfernt 👍`,
    }),
    'Sorry it was not possible...': translations({
      sv: `Tyvärr gick det inte att spara de nya kunduppgifterna. 😲`,
      en: `Sorry it was not possible to save the new information`,
      de: `Leider konnten die neuen Kundeninformationen nicht gespeichert werden. 😲`,
    }),
    'Booking has been saved': translations({
      sv: `Bokningen är nu sparad 👍`,
      en: `Booking has been saved 👍`,
      de: `Die Buchung ist jetzt gespeichert.`,
    }),
    'Unfortunately it was not possible to send the e-mail to the customer.': translations({
      sv: `Tyvärr gick det inte att skicka mail till kunden.`,
      en: `Unfortunately it was not possible to send the e-mail to the customer.`,
      de: `Leider war es nicht möglich, eine E-Mail an den Kunden zu senden.`,
    }),
    'Confirm and send payment link': translations({
      sv: `Bekräfta och skicka betalningslänk`,
      en: `Confirm and send payment link`,
      de: `Bestätigen und Zahlungslink senden`,
    }),
    'Resend confirmation': translations({
      sv: `Skicka om bekräftelse`,
      en: 'Resend confirmation',
      de: `Bestätigung erneut senden`,
    }),
    'Resend confirmation and payment link': translations({
      sv: `Skicka bekräftelse och betalningslänk igen`,
      en: 'Resend confirmation and payment link',
      de: `Bestätigung und Zahlungslink erneut senden`,
    }),
    'Reject booking request': translations({
      sv: `Avvisa bokningsförfrågan`,
      en: 'Reject booking request',
      de: `Buchungsanfrage ablehnen`,
    }),
    'The booking has been rejected': translations({
      sv: `Bokningen har avvisats`,
      en: 'The booking has been rejected',
      de: `Die Buchung wurde abgelehnt`,
    }),
    'Resend rejection mail': translations({
      sv: `Skicka avslagsmeddelande igen`,
      en: 'Resend rejection mail',
      de: `Ablehnungsmail erneut senden`,
    }),
    'components.dashboard.bookings.bookingItem.refundComponent.refundOnlineOne': translations({
      sv: `Utebliven återbetalning:`,
      en: `Unpaid Refund:`,
      de: `Unbezahlte Rückerstattung:`,
    }),
    'components.dashboard.bookings.bookingView.messageOne': translations({
      sv: `Här kan du lägga till en bokning manuellt från samma bokningsvy som dina kunder ser.`,
      en: `Here you can manually add a booking from the same booking view that your customers see.`,
      de: `Hier kannst du manuell eine Buchung hinzufügen..`,
    }),
    'components.dashboard.bookings.handleBookings.headerThree': translations({
      sv: `Sök efter bokning, kund, aktivtet, anläggning...`,
      en: `Search for booking, customer, activity, saloon ...`,
      de: `Suche nach Buchungen, Kunden, Angeboten, Salons ...`,
    }),
    Status: translations({
      sv: `Status`,
      en: `Status`,
      de: `Status`,
    }),
    'components.dashboard.employees.form.ok': translations({
      sv: `Ok 👍`,
      en: `Ok 👍`,
      de: `Ok 👍`,
    }),
    'Giftcard deleted': translations({
      sv: `Det gick utmärkt att ta bort presentkortet`,
      en: `Giftcard deleted`,
      de: `Gutscheinkarte entfernt`,
    }),
    'All discounts for': translations({
      sv: `Alla rabattkoder för`,
      en: `All discounts for`,
      de: `Alle Rabattcodes für`,
    }),
    'Discount deleted': translations({
      sv: `Rabattkoden har tagits bort`,
      en: `Discount deleted`,
      de: `Gutscheinkarte entfernt`,
    }),
    'Show options': translations({
      sv: `Visa alternativ`,
      en: `Show options`,
      de: `Optionen anzeigen`,
    }),
    'components.calendar.store.labelAlertHeader': translations({
      sv: `Attans. För sent ute 🙄`,
      en: `Too late 🙄`,
      de: `Zu spät!`,
    }),
    'components.calendar.store.month': translations({
      sv: `månad`,
      en: `month`,
      de: `Monat`,
    }),
    'components.customer.activities.searchDescription.showingActivities': translations({
      sv: `Visar aktiviteter under`,
      en: `Show activities from`,
      de: `Termine anzeigen`,
    }),
    'components.customer.footer.headerFour': translations({
      sv: `HITTA OSS`,
      en: `FIND US`,
      de: `FINDE UNS`,
    }),
    'components.customer.footer.itemEtt': translations({
      sv: `Besöksadress`,
      en: `Visiting address`,
      de: `Büro-Adresse`,
    }),
    'components.customer.footer.blog': translations({
      sv: `Blogg`,
      en: `Blog`,
      de: `Bloggen`,
    }),
    'Unfortunately somthing went wrong when the booking was removed.': translations({
      sv: `Tyvärr gick något fel när bokningen skulle tas bort`,
      en: `Unfortunately somthing went wrong when the booking was removed.`,
      de: `Entschuldigung, etwas ist schief gelaufen, als die Buchung entfernt wurde.`,
    }),
    'Partly paid': translations({
      sv: `Delvis betald`,
      en: `Partly paid`,
      de: `Teilweise bezahlt`,
    }),
    'components.dashboard.employees.avatarDropdownField.noOwnPicture': translations({
      sv: `Använd ingen egen visningsbild`,
      en: `Dont use image`,
      de: `Kein Bild`,
    }),
    'components.dashboard.employees.employeeItem.personnelList': translations({
      sv: `I personallistan`,
      en: `In personnel list`,
      de: `In der Personalliste`,
    }),
    'components.dashboard.employees.index.addEmployee': translations({
      sv: `Lägg till en ny anställd`,
      en: `Add new employee`,
      de: `Neue Mitarbeiter hinzufügen`,
    }),
    'components.dashboard.giftCards.form.failedUpdateDocumentAlertTitle': translations({
      sv: `Det gick inte att spara presentkortet 🤕`,
      en: `The giftcard could not be saved`,
      de: `Gutscheinkarte konnte nicht gespeichert werden.`,
    }),
    'Could not save discount': translations({
      sv: `Det gick inte att spara rabattkoden 🤕`,
      en: `Could not save discount`,
      de: `Gutscheinkarte konnte nicht gespeichert werden.`,
    }),
    'components.dashboard.giftCards.handleGiftCardTypes.headerTwo': translations({
      sv: `På denna sida visas alla presentkort som besökare kan köpa när dom besöker din sida. Om du redigerar ett presentkort påverkar det inte redan sålda presentkort.`,
      en: `This page displays all the gift cards that customers can buy when they visit your website. Editing a gift card does not affect previously sold vouchers.`,
      de: `Auf dieser Seite werden alle Gutscheinkarten angezeigt, die Kunden kaufen können, wenn sie deine Webseite besuchen. Das Bearbeiten einer Gutscheinkarte hat keine Auswirkungen auf bereits verkaufte Gutscheine.`,
    }),
    'Send a giftcard to customer': translations({
      sv: `Skicka presentkort till kund`,
      en: `Send a giftcard to customer`,
      de: `Gutschein an Kunden senden`,
    }),
    'planned employees': translations({
      sv: `planerad personal`,
      en: `planned employees`,
      de: `geplante Mitarbeiter`,
    }),
    'components.calendar.sidebar.instructionTwo': translations({
      sv: `Klicka och dra`,
      en: `Click and drag`,
      de: `Klicke und ziehe`,
    }),
    Date: translations({
      sv: `Datum`,
      en: `Date`,
      de: `Datum`,
    }),
    'components.calendar.views.assignEmployees.assignedEmployeesList.instructionOne': translations({
      sv: `Ta bort markerade från arbetslistan`,
      en: `Remove selected from list`,
      de: `Auswahl entfernen`,
    }),
    'components.calendar.views.bookingInfoItem.iconTwo': translations({
      sv: `Kontaktuppgifter:`,
      en: `Contact:`,
      de: `Kontakt:`,
    }),
    'Welcome to': translations({
      sv: `Välkommen till`,
      en: `Welcome to`,
      de: `Willkommen bei`,
    }),
    'components.customer.footer.descriptionSix': translations({
      sv: `En produktion från`,
      en: `A production from`,
      de: `Eine Produktion von`,
    }),
    'Edit customer information': translations({
      sv: `Redigera kundinformation`,
      en: `Edit customer information`,
      de: `Kundeninformationen bearbeiten`,
    }),
    'components.dashboard.bookings.bookingItem.refundComponent.messageMaxRefund': translations({
      sv: `Summan måste vara lika med eller mindre än det maximala återbetalningsbeloppet`,
      en: `The sum must be equal to or less than the maximum refund amount`,
      de: `Die Summe muss gleich oder geringer als der maximale Rückerstattungsbetrag sein`,
    }),
    'components.dashboard.bookings.bookingItem.refundComponent.refundKr': translations({
      sv: `Återbetalningsbelopp:`,
      en: `Refund amount:`,
      de: `Rückerstattungsbetrag: €`,
    }),
    'components.dashboard.bookings.bookingItem.statusInfo.payManual': translations({
      sv: `från manuell betalning`,
      en: `from manual payment`,
      de: `von der manuelle Zahlung`,
    }),
    'From discount code': translations({
      sv: `från rabattkod`,
      en: `from discount code`,
      de: `vom Rabattcode`,
    }),
    'Create a booking': translations({
      sv: `Skapa en bokning`,
      en: `Create a booking`,
      de: `Erstelle eine Buchung`,
    }),
    'Bookings at other partners': translations({
      sv: 'Bokningar hos andra aktörer',
      en: 'Bookings at other partners',
      de: 'Buchungen bei anderen Partnern',
    }),
    'components.dashboard.employees.avatarDropdownField.uploadPictureHeader': translations({
      sv: `Ladda upp en egen visningsbild`,
      en: `Upload your image`,
      de: `Laden Sie ein Anzeigebild hoch`,
    }),
    'components.dashboard.employees.employeeItem.manageSchedule': translations({
      sv: `Hantera schema`,
      en: `Manage schedule`,
      de: `Zeitplan verwalten`,
    }),
    'components.dashboard.giftCards.form.expireDays': translations({
      sv: `Giltighetstid i dagar`,
      en: `Valid for (number of days)`,
      de: `Gültig für (Anzahl der Tage)`,
    }),
    'components.dashboard.giftCards.form.pictureSizeMessage': translations({
      sv: `Rekommenderad bildstorlek för presentkortsbilden är 668x302 pixlar (72dpi), png eller jpg.`,
      en: `Recomended image size is 668x302 pixels`,
      de: `Die empfohlene Bildgröße beträgt 668x302 Pixel.`,
    }),
    'Activate my account': translations({
      sv: `Aktivera mitt konto`,
      en: `Activate my account`,
      de: `Konto aktivieren`,
    }),
    'components.calendar.store.toOccurrences': translations({
      sv: `till händelser`,
      en: `to occurrences`,
      de: `zu ereignissen`,
    }),
    'components.calendar.utils.calendarPeerComponent.list': translations({
      sv: `Lista från den`,
      en: `List from`,
      de: `Buchungen vom`,
    }),
    'choose-type-of-offer': translations({
      sv: `Välj typ av erbjudande`,
      en: `Choose type of offer`,
      de: `Wähle die Art der Anwendung`,
    }),
    'components.customer.aboutUs.buttonOne': translations({
      sv: `Klicka här för att bli aktör`,
      en: `Click here to subscribe to our platform`,
      de: `Klicken Sie hier, um ein Teil von AdventureHero zu werden`,
    }),
    'components.customer.aboutUs.uniktid': translations({
      sv: `Hero`,
      en: `Hero`,
      de: `Hero`,
    }),
    'components.customer.activities.activitiesShowcase.textTwo': translations({
      sv: `Ändra din sökning och försök igen 😇`,
      en: `Please change your search and try again`,
      de: `Bitte ändere deine Suche und versuche es erneut`,
    }),
    'components.customer.footer.headerFive': translations({
      sv: `VÅRT NYHETSBREV`,
      en: `OUR NEWSLETTER`,
      de: `UNSER NEWSLETTER`,
    }),
    'components.dashboard.bookings.bookingItem.bookingInfo.contentOne': translations({
      sv: `Anläggning`,
      en: `Business`,
      de: `Unternehme`,
    }),
    activity: translations({
      sv: `Aktivitet`,
      en: `Activity`,
      de: `Aktivität`,
    }),
    'Remove booking': translations({
      sv: `Ta bort bokningen`,
      en: `Remove booking`,
      de: `Buchung entfernen`,
    }),
    'components.dashboard.bookings.bookingItem.refundComponent.labelrefund': translations({
      sv: `Välj summa att återbetala`,
      en: `Fill in a sum to be refunded`,
      de: `Wähle den zurückzuzahlenden Betrag aus`,
    }),
    'Invoice documentation': translations({
      sv: `Fakturaunderlag`,
      en: `Invoice documentation`,
      de: `Rechnungsdokumentation`,
    }),
    'components.dashboard.bookings.bookingItem.statusInfo.personalNote': translations({
      sv: `Personalanteckningar:`,
      en: `Private message`,
      de: `Persönliche Notizen:`,
    }),
    'components.dashboard.employees.form.invitationMail': translations({
      sv: `Inbjudan via mail`,
      en: `Invitation by mail`,
      de: `Einladung per E-Mail`,
    }),
    'Something went wrong when removing the giftcard': translations({
      sv: `Tyvärr gick något fel när presentkortet skulle tas bort`,
      en: `Something went wrong when removing the giftcard`,
      de: `Beim Löschen der Gutscheinkarte ist ein Fehler aufgetreten.`,
    }),
    'Something went wrong when removing the discount': translations({
      sv: `Tyvärr gick något fel när rabattkoden skulle tas bort`,
      en: `Something went wrong when removing the discount`,
      de: `Beim Entfernen des Rabatts ist ein Fehler aufgetreten`,
    }),
    'components.calendar.store.agenda': translations({
      sv: `lista`,
      en: `list`,
      de: `Liste`,
    }),
    'components.calendar.utils.calendarPeerComponent.week': translations({
      sv: `Vecka`,
      en: `Week`,
      de: `Woche`,
    }),
    'Save activity': translations({
      sv: `Spara aktiviteten`,
      en: `Save activity`,
      de: `Anwendung speichern`,
    }),
    'The time must be specified in minutes': translations({
      sv: `Tiden måste anges i hela minuter`,
      en: `The time must be specified in minutes`,
      de: `Die Zeit muss in Minuten angegeben werden.`,
    }),
    'components.calendar.views.moveBooking.alertFour': translations({
      sv: `Oj då.. 😲`,
      en: `Oops .. 😲`,
      de: `Oops .. 😲`,
    }),
    'components.calendar.views.moveBooking.alertOne': translations({
      sv: `Bokning är nu flyttat till vald aktivitet 🐝`,
      en: `The booking is now moved to chosen activity.`,
      de: `Die Buchung wurde erfolgreich bearbeitet.`,
    }),
    'components.calendar.views.moveBooking.messageThree': translations({
      sv: `Var god välj en framtida aktivitet.`,
      en: `Please choose a future activity`,
      de: `Bitte wähle eine zukünftige Buchung`,
    }),
    'Not enough available slots': translations({
      sv: `Inte tillräckligt med tillgängliga platser`,
      en: `Not enough available slots`,
      de: `Nicht genügend verfügbare Slots`,
    }),
    'If you choose to move the booking...': translations({
      sv: `Om du väljer att flytta på bokningen till den här aktiviteten kommer antalet tillgängliga platser att överstigas`,
      en: `If you choose to move the booking to this activity, the number of available slots will be exceeded`,
      de: `Wenn Sie die Buchung auf diese Aktivität verschieben, wird die Anzahl der Sitzplätze überschritten`,
    }),
    'Slots left on activity': translations({
      sv: `Platser kvar på aktiviteten: `,
      en: `Slots left on activity: `,
      de: `Verbleibende Slots für Aktivitäten: `,
    }),
    'Select an activity': translations({
      sv: `Välj en aktivitet`,
      en: `Select an activity`,
      de: `Wählen Sie eine Aktivität aus`,
    }),
    'components.customer.aboutUs.descriptionFour': translations({
      sv: `VI ARBETAR FÖR DIG I UPPLEVELSEBRANSCHEN`,
      en: `WE WORK FOR YOU IN THE ADVENTURE BUSINESS`,
      de: `WIR ARBEITEN FÜR SIE IN DER EXPERIENCE UNTERNEHMEN`,
    }),
    'FOR MEMBERS': translations({
      sv: `FÖR AKTÖRER`,
      en: `FOR MEMBERS`,
      de: `Für Member`,
    }),
    'components.dashboard.bookings.bookingItem.index.alertSeven': translations({
      sv: `Bokningen är nu betald 👍`,
      en: `The booking is paid. 👍`,
      de: `Die Buchung ist jetzt bezahlt. 👍`,
    }),
    'Change number': translations({
      sv: `Ändra antal`,
      en: `Change number of visitors`,
      de: `Anzahl der Kunden ändern`,
    }),
    'Show booking history': translations({
      sv: 'Visa bokningshistorik',
      en: 'Show booking history',
      de: 'Buchungsverlauf anzeigen',
    }),
    'Hide booking history': translations({
      sv: 'Göm bokningshistorik',
      en: 'Hide booking history',
      de: 'Buchungsverlauf ausblenden',
    }),
    'components.dashboard.bookings.bookingItem.refundComponent.CostKrOne': translations({
      sv: `Den ökade kostnaden`,
      en: `The increased cost`,
      de: `Die erhöhten Kosten`,
    }),
    'Save and refund': translations({
      sv: `Spara och återbetala`,
      en: `Save and refund`,
      de: `Speichern und eine Rückerstattung durchführen`,
    }),
    'from voucher': translations({
      sv: `från värdebevis`,
      en: `from voucher`,
      de: `von Gutschein`,
    }),
    'components.dashboard.employees.avatarDropdownField.avatarSelect': translations({
      sv: `välj en avatar`,
      en: `Choose a image`,
      de: `Wähle ein Bild`,
    }),
    'components.dashboard.employees.employeeCard.examine': translations({
      sv: `Granska`,
      en: `Review`,
      de: `Ansicht`,
    }),
    'Filter settings': translations({
      sv: `Filterinställningar`,
      en: `Filter settings`,
      de: `Filtereinstellungen`,
    }),
    'Add a booking': translations({
      sv: `Lägg till en bokning`,
      en: `Add a booking`,
      de: `Fügen Sie eine Buchung hinzu`,
    }),
    'Manage personnel': translations({
      sv: `Hantera personal`,
      en: `Manage personnel`,
      de: `Personal verwalten`,
    }),
    'Scheduled personnel': translations({
      sv: `Schemalagd personal`,
      en: `Scheduled personnel`,
      de: `Geplantes Personal`,
    }),
    'components.calendar.views.bookingInfoItem.buttonOne': translations({
      sv: `Flytta bokningen`,
      en: `Shift schedule`,
      de: `Termin verschieben`,
    }),
    'The message will be visible in the e-mail': translations({
      sv: `Meddelandet kommer att synas i mailet`,
      en: `The message will be visible in the e-mail`,
      de: `Die Nachricht wird in der E-Mail angezeigt`,
    }),
    'is once again scheduled.': translations({
      sv: `är återigen schemalagd.`,
      en: `is once again scheduled.`,
      de: `ist wieder erstellt.`,
    }),
    'components.customer.activities.activitiesSearchBar.headerOne': translations({
      sv: `Hitta din`,
      en: `Find your`,
      de: `Finden dein`,
    }),
    'components.customer.activities.searchDescription.fourCategories': translations({
      sv: `kategorier`,
      en: `categories`,
      de: `Kategorien`,
    }),
    'components.customer.footer.descriptionTwo': translations({
      sv: `Administrera dina erbjudanden`,
      en: `Manage your offers`,
      de: `Verwalte deine Angebote`,
    }),
    'Oops, something went wrong': translations({
      sv: `Ojsan, något har gått fel..`,
      en: `Oops, something went wrong`,
      de: `Hoppla, etwas ist schiefgelaufen.`,
    }),
    'components.dashboard.bookings.bookingItem.refundComponent.costKrThree': translations({
      sv: `kan bara betalas manuellt för tillfället, hoppas det går bra!`,
      en: `could only be paid manually at the moment, hope it's fine!`,
      de: `können im Moment nur manuell bezahlt werden.`,
    }),
    'components.dashboard.bookings.bookingItem.refundComponent.refundOmlineTwo': translations({
      sv: `Det går endast att återbetala pengarna som har betalts online. Du kan maximalt betala tillbaka`,
      en: `It is only possible to refund the money paid online. You can pay back in full`,
      de: `Es ist ausschließlich möglich eine Online-Zahlung rückzuerstatten. Diese kannst du in voller Höhe zurückzahlen.`,
    }),
    'Booking number': translations({
      sv: `Bokningsnummer`,
      en: `Booking number`,
      de: `Buchungsnummer`,
    }),
    'Booking-request': translations({
      sv: `Bokningsförfrågan`,
      en: `Booking request`,
      de: `Buchungsanfrage`,
    }),
    Homepage: translations({
      sv: `Kundsidan`,
      en: `Homepage`,
      de: `Homepage`,
    }),
    'components.dashboard.giftCards.form.chooseGiftcardPicture': translations({
      sv: `Välj presentkortsbild`,
      en: `Choose gift card image`,
      de: `Wähle ein Gutscheinkartenbild`,
    }),
    'components.dashboard.giftCards.form.updateDocumentAlertTitle': translations({
      sv: `Presentkortet har sparats`,
      en: `Gift card saved`,
      de: `Gutscheinkarte gespeichert.`,
    }),
    'Discount saved': translations({
      sv: `Rabattkoden har sparats`,
      en: `Discount saved`,
      de: `Der Rabattcode wurde gespeichert`,
    }),
    'Paid online': translations({
      sv: `Betald online`,
      en: `Paid online`,
      de: `Online bezahlt`,
    }),
    'Giftcard was sent to customers email': translations({
      sv: `Presentkortet är nu skickat till kundens mail!`,
      en: `Giftcard was sent to customers email.`,
      de: `Die Gutscheinkarte wurde jetzt an den Kunden verschickt!`,
    }),
    'Personnel list is empty': translations({
      sv: `Personallistan är tom`,
      en: `Personnel list is empty`,
      de: `Die Personalliste ist leer`,
    }),
    'components.calendar.views.bookingInfoItem.infoOne': translations({
      sv: `kr från onlinebetalning`,
      en: `SEK from online payment`,
      de: `SEK von Online-Zahlung`,
    }),
    'components.calendar.views.bookingInfoItem.infoThree': translations({
      sv: `kr från manuell betalning`,
      en: `SEK from manual payment`,
      de: `SEK von der manuellen Zahlung`,
    }),
    'booking-creation.error.message': translations({
      sv: `Det gick inte att lägga till bokningen. Om du tror att det beror på ett problem med sidan så var snäll och kontakta supporten.`,
      en: `Could not add booking. If you think it is due to a problem with the page, please contact support.`,
      de: `Buchung konnte nicht hinzugefügt werden. Wenn du der Meinung bist, dass ein Problem mit der Seite vorliegt, wende dich bitte an den Support.`,
    }),
    'Could not add activity. If you think it is...': translations({
      sv: `Det gick inte att lägga till aktiviteten. Om du tror att det beror på ett problem med sidan så var snäll och kontakta supporten.`,
      en: `Could not add activity. If you think it is due to a problem with the page, please contact support.`,
      de: `Aktivität konnte nicht hinzugefügt werden. Wenn du der Meinung bist, dass ein Problem mit der Seite vorliegt, wende dich bitte an den Support.`,
    }),
    'Create new offer': translations({
      sv: `Skapa nytt erbjudande`,
      en: `Create new offer`,
      de: `Neues Angebot erstellen`,
    }),
    'activity-created.title': translations({
      sv: `Aktiviten är skapad 👍`,
      en: `Activity saved.`,
      de: `Die Anwendung wurde erstellt. 👍`,
    }),
    'is scheduled. Remember to publish...': translations({
      sv: `är schemalagt. Tänk på att publicera aktiviten om du vill att dina kunder ska kunna boka den.`,
      en: `is scheduled. Remember to publish the activity if you want your customers to be able to book it.`,
      de: `ist erstellt. Denken Sie daran, die Aktivität zu veröffentlichen, wenn Sie möchten, dass Ihre Kunden sie buchen können.`,
    }),
    'components.calendar.views.editActivityForm.alertSeven': translations({
      sv: `Det gick inte att spara dina ändringar. Om du tror att det beror på ett problem med sidan så var snäll och kontakta supporten.`,
      en: `Could not save your changes. If you think it is due to a problem with the page, please contact support.`,
      de: `Die Änderungen konnten nicht gespeichert werden. Wenn du der Meinung bist, dass ein Problem mit der Seite vorliegt, wende dich bitte an den Support.`,
    }),
    'components.calendar.views.editActivityForm.instructionOne': translations({
      sv: `Välj en starttid`,
      en: `Choose a start time`,
      de: `Wähle einen Termin`,
    }),
    'Booking system': translations({
      sv: `Bokningssystem`,
      en: `Booking system`,
      de: `Buchungssystem`,
    }),
    'The confirmation is sent.': translations({
      sv: `Bekräftelsen är skickad 🙏🏼`,
      en: `The confirmation is sent. 🙏🏼`,
      de: `Bestätigung gesendet .🙏`,
    }),
    'components.dashboard.bookings.bookingItem.refundComponent.messageCost': translations({
      sv: `Höjd kostnad:`,
      en: `New price`,
      de: `Neuer Preis!`,
    }),
    'Fully paid': translations({
      sv: `Fullt betald`,
      en: `Fully paid`,
      de: `Voll bezahlt`,
    }),
    Paid: translations({
      sv: `Betald`,
      en: `Paid`,
      de: `Bezahlt`,
    }),
    'components.dashboard.employees.avatarDropdownField.newCustomAvatar': translations({
      sv: `Ladda upp en visningsbild`,
      en: `Upload image`,
      de: `Laden Sie ein Anzeigebild hoch`,
    }),
    'components.dashboard.employees.employeeItem.makeResponsible': translations({
      sv: `Gör till platsansvarig`,
      en: `Upgrade to manager`,
      de: `Als Manager hinzufügen`,
    }),
    'components.dashboard.employees.form.availableEmployee': translations({
      sv: `finns nu tillgänglig som anställd.`,
      en: `Is now available as employee`,
      de: `ist jetzt als Mitarbeiter verfügbar`,
    }),
    'components.dashboard.employees.form.enterPasswordPlaceholder': translations({
      sv: `Ange ditt lösenord`,
      en: `Enter your password`,
      de: `Geben Sie Ihr Passwort ein`,
    }),
    'components.dashboard.employees.index.findEmployee': translations({
      sv: `Hitta en anställd...`,
      en: `Find a employee`,
      de: `Einen Mitarbeiter auswählen`,
    }),
    'Mark as paid': translations({
      sv: `Markera som betald`,
      en: `Mark as paid`,
      de: `Als bezahlt markieren`,
    }),
    'components.calendar.sidebar.iconOne': translations({
      sv: `Fler visningsalternativ`,
      en: `More views`,
      de: `Weitere Anzeigeoptionen`,
    }),
    'components.calendar.sidebar.instructionFour': translations({
      sv: `Klicka på en aktivitet för att visa mer information.`,
      en: `Click an activity to see more information.`,
      de: `Klicke auf einen Termin, um zusätzliche Informationen anzusehen.`,
    }),
    'components.calendar.sidebar.notActivatedTitle': translations({
      sv: `Här kan du i vanliga fall skapa aktiviteter genom att dra i kalendern`,
      en: `Here you can usually create activities by dragging in the calendar`,
      de: `Hier kannst du normalerweise Termine erstellen, indem du diese einfach in den Kalender ziehst.`,
    }),
    'components.calendar.views.assignEmployees.assignedEmployeesList.messageOne': translations({
      sv: `Ingen schemalagd personal`,
      en: `No scheduled staff`,
      de: `Keine Mitarbeiter gewählt`,
    }),
    // NOTE: What has happened here? vvv
    'components.calendar.views.assignEmployees.index.alertOne': translations({
      sv: `Något gick fel när den arbetande personalen skulle sparas eller så gick det inte att skickade mail till personalen. Var god kontakta supporten om problemet kvarstår.", "Oj då..", "error"`,
      en: `Something went wrong when the working staff were to be saved or unable to send mail to the staff. Please contact support if the problem persists. "," Well then .. "," error "`,
      de: `Etwas schief gelaufen ist, wenn das Arbeitspersonal gerettet werden oder nicht in der Lage sein sollte, Post an die Mitarbeiter zu senden. Bitte kontaktieren Sie den Support, wenn das Problem weiterhin besteht. "," Na dann .. "," Fehler "`,
    }),
    Participants: translations({
      sv: `Deltagare`,
      en: `Participants`,
      de: `Teilnehmer`,
    }),
    'No message written': translations({
      sv: `Inget meddelande lämnat`,
      en: `No message written`,
      de: `Keine Nachricht vorhanden`,
    }),
    'Choose offer': translations({
      sv: `Välj erbjudande`,
      en: `Choose offer`,
      de: `Wähle anwendung`,
    }),
    'per booking (optional)': translations({
      sv: `per bokning (valfritt)`,
      en: `per booking (optional)`,
      de: `pro Buchung (optional)`,
    }),
    'components.calendar.views.moveBooking.alertTwo': translations({
      sv: `Klart! 👍`,
      en: `Check! 👍`,
      de: `Hat geklappt! 👍`,
    }),
    'components.calendar.views.moveBooking.messageFour': translations({
      sv: `Flytta bokningen till den här aktiviteten`,
      en: `Move booking to this activity`,
      de: `Buchung dorthin verschieben`,
    }),
    'Schedule and plan your activities. Get...': translations({
      sv: `Schemalägg och planera era aktiviteter. Få betalt i förskott.`,
      en: `Schedule and plan your activities. Get paid in advance.`,
      de: `Erstelle und plane deine Anwendungen.`,
    }),
    Activities: translations({
      sv: `Aktiviteter`,
      en: `Activities`,
      de: `Termine`,
    }),
    'has been refunded to customer.': translations({
      sv: `har återbetalats till kunden.`,
      en: `has been refunded to customer.`,
      de: `wurde dem Kunden zurückerstattet.`,
    }),
    'components.dashboard.employees.avatarDropdownField.myCustomAvatar': translations({
      sv: `Uppladdad visningsbild`,
      en: `Uploaded Image`,
      de: `Hochgeladene Bild`,
    }),
    'components.dashboard.employees.employeeCard.noMailWorkHours': translations({
      sv: `Du får inte mail om ändrade arbetstider`,
      en: `You will not recive email notifications for job times`,
      de: `Du bekommst keine E-Mail-Benachrichtigungen`,
    }),
    'Add to personnel list': translations({
      sv: `Lägg till i personallistan`,
      en: `Add to personnel list`,
      de: `Zur Personalliste hinzufügen`,
    }),
    'components.dashboard.employees.form.alreadyUser': translations({
      sv: `Användaren finns redan 😲`,
      en: `User allready exists`,
      de: `Benutzer existiert bereits`,
    }),
    'components.dashboard.giftCards.form.choosePicture': translations({
      sv: `Du kan även välja vår bild nedan 🙃`,
      en: `You can choose to use our image below`,
      de: `Du kannst dir aussuchen, ob du unser Bild unten verwenden möchtest.`,
    }),
    'This page gives you an overview of giftcards sold...': translations({
      sv: `Denna sida ger dig en överblick över presentkort som sålts till kunder, och låter dig se användningstatistik`,
      en: `This page gives you an overview of giftcards sold to customers, and lets you see usage statistics`,
      de: `Auf dieser Seite erhältst du einen Überblick über die an Kunden verkauften Gutscheinkarten und siehst die Nutzungsstatistiken.`,
    }),
    'Sent!': translations({
      sv: `Skickat! 🙏🏼`,
      en: `Sent!`,
      de: `Gesendet`,
    }),
    'components.admin.userFormContent.passwordInputThree': translations({
      sv: `Lösenorden matchar inte varandra`,
      en: `Passwords don´t match`,
      de: `Die Passwörter stimmen nicht überein`,
    }),
    'components.calendar.store.allDay': translations({
      sv: `heldag`,
      en: `full day`,
      de: `Ganzer tag`,
    }),
    'Schedule selected personnel': translations({
      sv: `Schemalägg markerad personal`,
      en: `Schedule selected personnel`,
      de: `Planen Sie ausgewählte Personal`,
    }),
    'components.calendar.views.bookingInfoItem.messageSeven': translations({
      sv: `Anteckningar här är synliga för all personal på anläggningen`,
      en: `Notes are shown to all personnel`,
      de: `Hinweise hier sind für alle Mitarbeiter des Unternehmens sichtbar.`,
    }),
    MARKETING: translations({
      sv: `Marknadsföring`,
      en: `MARKETING`,
      de: `MARKETING`,
    }),
    'components.customer.footer.headerTwo': translations({
      sv: `Låt äventyret börja!`,
      en: `Let the adventure begin!`,
      de: `Viel Spaß!`,
    }),
    'components.dashboard.bookings.bookingItem.bookingInfo.contentFive': translations({
      sv: `Totalpris:`,
      en: `Total price`,
      de: `Gesamtpreis:`,
    }),
    'Back to booking list': translations({
      sv: `Tillbaka till bokningslistan`,
      en: `Back to booking list`,
      de: `Zurück zur Buchungsliste`,
    }),
    'Select property': translations({
      sv: `Välj anläggning`,
      en: `Select property`,
      de: `Unternehmen wählen`,
    }),
    'Created by': translations({
      sv: `Skapad av`,
      en: `Created by`,
      de: `Erstellt über`,
    }),
    showOnlyCrossSelling: translations({
      sv: `Visa endast bokningar gjorda av en destinationsaktör`,
      en: `Show only bookings made by a destination partner`,
      de: `Nur Buchungen anzeigen, die von einem Zielpartner vorgenommen wurden`,
    }),
    filter: translations({
      sv: `Filtrering`,
      en: `Filter`,
      de: `Filtration`,
    }),
    'components.dashboard.employees.employeeItem.placeResponsible': translations({
      sv: `Platsansvarig`,
      en: `Manager`,
      de: `Manager`,
    }),
    'components.dashboard.employees.employeeItem.responsibleProperty': translations({
      sv: `Platsansvarig för`,
      en: `Manager`,
      de: `Manager`,
    }),
    'components.dashboard.employees.form.alreadyUserMail': translations({
      sv: `Det finns redan en användare med den angivna mailadressen i systemet. Var god ange en annan mailadress eller be användaren återställa sitt lösenord om denne inte kan logga in.`,
      en: `There is already a user with this e-mail address`,
      de: `Es gibt bereits einen Benutzer mit dieser E-Mail-Adresse.`,
    }),
    'components.dashboard.giftCards.form.updateDocumentAlertMessage': translations({
      sv: `Skrolla ner i listan om du inte ser det 😊`,
      en: `Scroll down the list if you cant see it`,
      de: `Scrolle in der Liste nach unten.`,
    }),
    'Scroll down the list if you cant see it': translations({
      sv: `Skrolla ner i listan om du inte ser den 😊`,
      en: `Scroll down the list if you cant see it`,
      de: `Scrolle in der Liste nach unten.`,
    }),
    'components.dashboard.giftCards.form.valueLabel': translations({
      sv: `Värde`,
      en: `Value`,
      de: `Wert`,
    }),
    'Handled manually': translations({
      sv: `Manuellt hanterad`,
      en: `Handled manually`,
      de: `manuelle Bedienung`,
    }),
    'Get payouts for': translations({
      sv: `Hämta utbetalningar för`,
      en: `Get payout for`,
      de: `Lassen Sie sich bezahlen`,
    }),
    'Get statistics for': translations({
      sv: `Hämta statistik för`,
      en: `Get statistics for`,
      de: `Erhalten Sie Statistiken für`,
    }),
    'You must enter a number': translations({
      sv: `Du måste fylla i ett nummer`,
      en: `You must enter a number`,
      de: `Du musst eine Nummer eingeben!`,
    }),
    'activityOccurancesGeneratorForm.schedulePeriod': translations({
      sv: `Schemalägg denna period`,
      en: `Plan this period`,
      de: `Termine hinzufügen`,
    }),
    'components.dashboard.offers.details.deleteModal.removeDescription4': translations({
      sv: `är bokade.`,
      en: `are booked.`,
      de: `sind gebucht`,
    }),
    'components.dashboard.offers.details.employeeAssignmentForm.employeeAssignmentEmpty': translations({
      sv: `Erbjudandet kan bokas av all personal`,
      en: `The offer can be booked by all employees`,
      de: `Aktivitäten kann bei allen Mitarbeitern zugeordnet werden.`,
    }),
    'components.dashboard.offers.details.employeeAssignmentForm.working': translations({
      sv: `Var snäll vänta...`,
      en: `Please wait`,
      de: `Bitte warten`,
    }),
    'Offer name': translations({
      sv: `Erbjudandets namn`,
      en: `Offer name`,
      de: `Name der Anwendung`,
    }),
    'Offer was saved': translations({
      sv: `Erbjudandet sparades`,
      en: `Offer was saved`,
      de: `Das Angebot wurde gespeichert.`,
    }),
    'Both the offer and all...': translations({
      sv: `Både erbjudandet och alla kommande aktiviteter har uppdaterats.`,
      en: `Both the offer and all upcoming activities has been updated.`,
      de: `Sowohl das Angebot als auch alle anstehenden Aktivitäten wurden aktualisiert.`,
    }),
    'components.dashboard.properties.booking.messageWebb': translations({
      sv: `Tyvärr har din webbläsare har inte stöd för att visa bokningsvyn`,
      en: `Unfortunately your browser does not support our booking view.`,
      de: `Leider unterstützt dein Browser das Anzeigen des Online-Terminplans nicht.`,
    }),
    'components.dashboard.properties.details.overviewOver': translations({
      sv: `Översikt över`,
      en: `Overview of`,
      de: `Überblick über`,
    }),
    'components.dashboard.properties.employeeSchedule.index.noScheduleTemplatesFound': translations({
      sv: `Inga schemaläggningar inlaggda`,
      en: `No schedules inserted`,
      de: `Keine Zeitpläne eingefügt`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleCreator.index.chooseDayInMonths': translations({
      sv: `Välj dagar i månaden`,
      en: `Select days in the month`,
      de: `Wähle die Tage im Monat`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleItem.repititionPeriod': translations({
      sv: `Period:`,
      en: `Period:`,
      de: `Jahr:`,
    }),
    'components.dashboard.properties.form.FirstChoosePropertyPicture': translations({
      sv: `Först måste du välja en anläggningsbild!`,
      en: `First you must choose a establishemt image!`,
      de: `Zuerst musst du ein Bild wählen.`,
    }),
    'components.dashboard.properties.form.badAddressMessage': translations({
      sv: `Se efter om addressen är korrekt angiven`,
      en: `Check if the address is correctly written`,
      de: `Überprüfen Sie, ob die Adresse korrekt angegeben ist`,
    }),
    'components.dashboard.subscription.index.subscription': translations({
      sv: `Prenumeration`,
      en: `Subscription Plan`,
      de: `Abonnement`,
    }),
    'components.loginPage.forgetPassword': translations({
      sv: `Jag har glömt mitt lösenord 😇`,
      en: `I forgot my password`,
      de: `Ich habe mein Passwort vergessen`,
    }),
    'components.dashboard.home.segments.helpSegment.iconEigtheen': translations({
      sv: `Lägg till dina anställda`,
      en: `Add your employees`,
      de: `Mitarbeiter hinzufügen`,
    }),
    'components.dashboard.home.segments.helpSegment.iconTwenty': translations({
      sv: `Skapa dina erbjudanden`,
      en: `Create your offers`,
      de: `Erstelle deine Angebote`,
    }),
    here: translations({
      sv: `här`,
      en: `here`,
      de: `hier`,
    }),
    'components.dashboard.index.loggedInAsWarning': translations({
      sv: `Du är inloggad på ett annat konto. Gå till organisationer i ägarvyn för att logga ut.`,
      en: `You are logged in to another account. Go to organizations in the ownership view to log out.`,
      de: `Sie sind bei einem anderen Konto angemeldet. Wechseln Sie zu Organisationen in der Eigentümeransicht, um sich abzumelden.`,
    }),
    'activityOccurancesGeneratorForm.start': translations({
      sv: `Period start`,
      en: `Period start`,
      de: `Ab`,
    }),
    'Hi, thank you for you booking...': translations({
      sv: `Hej tack för din bokning...`,
      en: `Hi, thank you for you booking...`,
      de: `Hallo Danke für deine Buchung...`,
    }),
    'We would like you to know the following...': translations({
      sv: `Vi skulle vilja meddela dig om följande...`,
      en: `We would like you to know the following...`,
      de: `Wir möchten Sie über folgendes informieren...`,
    }),
    'components.dashboard.offers.details.bookingRulesForm.editBookingRules': translations({
      sv: `Redigera bokningsvillkoren`,
      en: `Edit booking conditions`,
      de: `Buchungsbedingungen bearbeiten`,
    }),
    'components.dashboard.offers.details.termsOfUseForm.editTermsOfUse': translations({
      sv: `Redigera användarvillkoren`,
      en: `Edit terms of use`,
      de: `Nutzungsbedingungen bearbeiten`,
    }),
    'components.dashboard.offers.details.deleteModal.removeOfferAnd': translations({
      sv: `Ta bort erbjudandet och`,
      en: `Remove offer and`,
      de: `Angebot entfernen und`,
    }),
    'offers.details.numberVolonteer': translations({
      sv: `Frivilliga platser:`,
      en: `Number of Volunteers`,
      de: `Anzahl der Mitarbeiter`,
    }),
    'offers.details.place': translations({
      sv: `Plats:`,
      en: `Location:`,
      de: `Ort:`,
    }),
    'components.dashboard.offers.form.addAddressMessage': translations({
      sv: `Här kan du lägga till en adress för erbjudandet. Om ingen adress är vald kommer anläggningens adress att användas.`,
      en: `Here you can add an address for the offer. If no address is selected, the address of the business will be used.`,
      de: `Hier können Sie eine Adresse für das Angebot hinzufügen. Wenn keine Adresse ausgewählt ist, wird die Adresse der Einrichtung verwendet.`,
    }),
    'Preparation time': translations({
      sv: `Förberedelsetid`,
      en: `Preparation time`,
      de: `Vorbereitungszeit`,
    }),
    'components.dashboard.properties.booking.messageBooking': translations({
      sv: `Här kan du lägga till en bokning manuellt från samma bokninsvy som dina kunder ser. Här finns också instruktioner för hur du lägger till bokningsvyn på din egna hemsida`,
      en: `From here you can add bookings manually. Here is also instructions on how to add the booking wiev to your own website.`,
      de: `Hier kannst du manuell eine Buchung hinzufügen.  Außerdem findest du hier Anweisungen, um den Online-Terminplan auf deiner eigenen Webseite zu installieren.`,
    }),
    'components.dashboard.properties.details.descriptionMissing': translations({
      sv: `Anläggningen saknar en beskrivning. Lägg till en beskrivning så besökande äventyrare får en bättre förståelse för vad ni erbjuder.`,
      en: `Business missing description. Please add one so that clients know what to expect.`,
      de: `Eine Beschreibung des Unternehmens fehlt. Füge jetzt eine Beschreibung hinzu, damit Kunden mehr über dein Unternehmen erfahren.`,
    }),
    'components.dashboard.properties.employeeSchedule.index.scheduleTemplates': translations({
      sv: `Schemaläggningar`,
      en: `Schedules`,
      de: `Zeitplan`,
    }),
    'components.dashboard.properties.employeeSchedule.index.selectedOrAddingInstances': translations({
      sv: `Vald`,
      en: `Selected`,
      de: `Ausgewählt`,
    }),
    'Crop start and end time:': translations({
      sv: `Beskär start och sluttid:`,
      en: `Crop start and end time:`,
      de: `Anfangs- und Endzeit der Ernte:`,
    }),
    'components.dashboard.properties.employeesView.checkReady': translations({
      sv: `Klar`,
      en: `Done`,
      de: `Fertig`,
    }),
    'components.dashboard.properties.employeesView.personnelRoles': translations({
      sv: `Personalen som visas här kan skapa bokningar. Platsansvariga kan planera in aktiviteter på arbetsplatsen.`,
      en: `The employees shown here can create bookings. Site managers can plan activities in the schedule.`,
      de: `Die hier gezeigten Mitarbeiter haben die Möglichkeit Buchungen zu erstellen. Manager können Angebote für das Unternehmen erstellen.`,
    }),
    'components.dashboard.properties.form.areaHolder': translations({
      sv: `Fyll i ditt område.`,
      en: `Enter your area`,
      de: `Geben Sie Ihren Bereich ein`,
    }),
    'components.dashboard.properties.form.choosePropertyPicture': translations({
      sv: `Välj anläggningens bild`,
      en: `Select image`,
      de: `Wähle ein Bild aus`,
    }),
    'components.dashboard.properties.form.locationMessage': translations({
      sv: `Koordinater hämtas automatiskt baserat på den angivna addressen. De används för att visa anläggningens position på en karta. Om du upplever problem med de automatiskt hämtade koordinaterna kan du manuellt placera ut anläggningens position på kartan. Vill du istället återställa koordinaterna kan du klicka på "Återställ" så hämtas de hem på nytt.`,
      en: `Coordinates are downloaded automatically based on the specified address. They are used to display the location of the property on a map. If you experience problems with the automatically retrieved coordinates, you can manually pin point the property's position on the map. If you want to restore the coordinates instead, click "Reset" and retrieve them.`,
      de: `Koordinaten werden automatisch basierend auf der angegebenen Adresse abgerufen. Sie werden verwendet, um die Position der Anlage auf einer Karte anzuzeigen. Wenn Sie Probleme mit den automatisch abgerufenen Koordinaten haben, können Sie die Position der Einrichtung manuell auf der Karte platzieren. Wenn Sie stattdessen die Koordinaten wiederherstellen möchten, können Sie auf "Zurücksetzen" klicken und sie werden wieder nach Hause gebracht.`,
    }),
    'components.dashboard.schedule.headerOne': translations({
      sv: `Visa aktiviteter för`,
      en: `Show activities for`,
      de: `Termine anzeigen für`,
    }),
    'components.loginPage.messageSent': translations({
      sv: `Mailet har skickats till dig.`,
      en: `Mail has been sent to you.`,
      de: `Die E-Mail wurde an Sie gesendet.`,
    }),
    'components.dashboard.home.segments.payoutSegment.alternativeRightClick': translations({
      sv: `alternativt högerklicka och välja "hämta länkad fil".`,
      en: `or right click and choose "download linked file".`,
      de: `oder klicke mit der rechten Maustaste und wähle "Link-Datei herunterladen".`,
    }),
    'components.dashboard.home.segments.tmeTicketsStatsSegment.alternativeRightClick': translations({
      sv: `alternativt högerklicka och välja "hämta länkad fil".`,
      en: `or right click and choose "download linked file".`,
      de: `oder klicke mit der rechten Maustaste und wähle "Link-Datei herunterladen".`,
    }),
    'Booking confirmation': translations({
      sv: `Bokningsbekräftelse`,
      en: `Booking confirmation`,
      de: `Buchungsbestätigung`,
    }),
    For: translations({
      sv: `För`,
      en: `For`,
      de: `Zum`,
    }),
    'You can choose how your offers booking...': translations({
      sv: `Du kan välja hur erbjudandets bokningsbekräftelse skall se ut`,
      en: `You can choose how your offers booking confirmation should look like`,
      de: `Hier kannst du auswählen, wie die Buchbestätigung der Anwendung aussehen soll.`,
    }),
    'offers.details.newStartTimes': translations({
      sv: `st nya starttider har schemalagts i kalendern mellan den`,
      en: `new start times added to schedule`,
      de: `Neue Termine zum Zeitplan hinzugefügt!`,
    }),
    'listOfOfferOccurances.oopsMessage': translations({
      sv: `Oj då! 🙃`,
      en: `Oops 🙃`,
      de: `Oops 🙃`,
    }),
    'listOfOfferOccurances.couldNotPublish': translations({
      sv: `Det det gick inte att publicera tiderna, var god försök igen.`,
      en: `It was not possible to publish the times, please try again.`,
      de: 'Es war nicht möglich die Zeiten zu veröffentlichen, bitte versuchen Sie es erneut',
    }),
    'Activity categories': translations({
      sv: `Aktivitetskategorier`,
      en: `Activity categories`,
      de: `Anwendungskategorie`,
    }),
    Inventory: translations({
      sv: `Inventarie`,
      en: `Inventory`,
      de: `Inventar`,
    }),
    'Select inventory': translations({
      sv: `Välj inventarie`,
      en: `Select inventory`,
      de: `Inventar auswählen`,
    }),
    'Use inventory': translations({
      sv: `Använd inventarier`,
      en: `Use inventory`,
      de: `Inventar benutzt`,
    }),
    "Don't use inventory": translations({
      sv: `Använd inte inventarie`,
      en: `Don't use inventory`,
      de: `Inventar nicht benutzt`,
    }),
    'Handle inventories': translations({
      sv: `Hantera inventarier`,
      en: `Handle inventories`,
      de: `Inventar verwalten`,
    }),
    'Inventory list': translations({
      sv: `Inventarielista`,
      en: `Inventory list`,
      de: `Inventarliste`,
    }),
    'Nothing selected': translations({
      sv: `Inget valt`,
      en: `Nothing selected`,
      de: `Nein ausgewählt`,
    }),
    'and minutes': translations({
      sv: `och minuter`,
      en: `and minutes`,
      de: `und Minuten`,
    }),
    'components.dashboard.properties.details.headerOne': translations({
      sv: `Anläggningsbeskrivning`,
      en: `Business description`,
      de: `Objektbeschreibung`,
    }),
    fromDate: translations({
      sv: `Från datum`,
      en: `From date`,
      de: `Ab datum`,
    }),
    'components.dashboard.properties.employeesView.chooseEmployees': translations({
      sv: `välj vilka som ska vara tillgängliga på`,
      en: `Choose who should be available at`,
      de: `Wähle hier, welche verfügbar sein sollen für`,
    }),
    'components.dashboard.properties.form.propertyName': translations({
      sv: `Anläggningens namn`,
      en: `Business name`,
      de: `Name des Unternehmens`,
    }),
    'components.dashboard.properties.form.updateCoordinates': translations({
      sv: `Återställ`,
      en: `Reset`,
      de: `Zurücksetzen`,
    }),
    'components.dashboard.properties.form.xxxxxx': translations({
      sv: `XXXXX`,
      en: `XXXXX`,
      de: `XXXXX`,
    }),
    'components.dashboard.home.segments.badgeSegment.subHeaderBadges': translations({
      sv: `Märken är bevis vad du har åstakommit på plattformen`,
      en: `The badges are proof of what you have achived on AdventureHero!`,
      de: `Die Medaillen sind ein Beweis für alles, was du auf AdventureHero erreicht hast.`,
    }),
    'components.dashboard.home.segments.helpSegment.descriptionFour': translations({
      sv: `Innan du kan börja schemalägga aktiviteter måste du skapa erbjudanden. Ett erbjudande beskriver en aktivitet och används när en aktivitet skall schemaläggas.`,
      en: `Before you can start scheduling your actvitities you need to create an offer. An offer describes your activity and is used when a actvitity is scheduled.`,
      de: `Bevor du mit der Planung deiner Termine beginnen kannst, musst du deine Angebote erstellen. Ein Angebot beschreibt deine Anwendung genauer und unterstützt so deine Kunden bei der Buchung.`,
    }),
    'components.dashboard.home.segments.helpSegment.descriptionSeven': translations({
      sv: `Du kan lägga upp skräddarsydda presentkort för försäljning. Presentkorten kan sedan användas när en aktivitet skall bokas.`,
      en: `You can add your own gift cards to sell. Bought giftcards can be used as payment when booking an activity.`,
      de: `Du kannst verschiedene Gutscheinkarten zum Verkauf hinzufügen. Diese kann der Kunde dann bei Terminbuchungen verwenden.`,
    }),
    'Sell a giftcard': translations({
      sv: `Sälj ett presentkort`,
      en: `Sell a giftcard`,
      de: `Verkaufe eine Gutscheinkarte`,
    }),
    'components.dashboard.home.segments.payoutSegment.errorMessageFile': translations({
      sv: `Om filen inte hämtades kan du göra det genom att klicka`,
      en: `If the file wasn't downloaded, you can manually download it by clicking`,
      de: `Wenn die Datei nicht heruntergeladen wurde, kannst du sie manuell herunterladen, indem du auf klickst`,
    }),
    'components.dashboard.home.segments.payoutSegment.payments': translations({
      sv: `Utbetalningar`,
      en: `Payouts`,
      de: `Auszahlungen`,
    }),
    'components.dashboard.home.segments.tmeTicketsStatsSegment.errorMessageFile': translations({
      sv: `Om filen inte hämtades kan du göra det genom att klicka`,
      en: `If the file wasn't downloaded, you can manually download it by clicking`,
      de: `Wenn die Datei nicht heruntergeladen wurde, kannst du sie manuell herunterladen, indem du auf klickst`,
    }),
    'components.dashboard.home.segments.tmeTicketsStatsSegment.statistics': translations({
      sv: `Borås-biljetter`,
      en: `Borås Tickets`,
      de: `Borås-Tickets`,
    }),
    'components.dashboard.index.loggedInAsWarningHeader': translations({
      sv: `Inloggad på `,
      en: `Logged in at `,
      de: `Angemeldet bei `,
    }),
    'components.dashboard.offers.details.bookingConfirmationForm.descriptionText': translations({
      sv: `Så här kommer ett bekräftelsemail se ut när det skickas till kunden`,
      en: `Here's how a confirmation email will appear when sent to the customer`,
      de: `So sieht deine Bestätigungs-E-Mail aus, wenn sie an den Kunden geschickt wird!`,
    }),
    'Edit your greetings to the customer': translations({
      sv: `Redigera din hälsning till kunden`,
      en: `Edit your greetings to the customer`,
      de: `Bearbeite deine Grüße an den Kunden`,
    }),
    'Min visitors per booking': translations({
      sv: `Minsta bokningsantal`,
      en: `Min visitors per booking`,
      de: `Mindestanzahl an Kunden`,
    }),
    'Calculated based of connected inventory': translations({
      sv: `Beräknas utifrån kopplat inventarie`,
      en: `Calculated based of connected inventory`,
      de: `Berechnet basierend auf dem verbundenen Inventar`,
    }),
    'offers.details.valueFive': translations({
      sv: `timmar före aktivitetsstart`,
      en: `hours before activity`,
      de: `Stunden bis zur Anwendung`,
    }),
    'components.dashboard.properties.details.buttonOne': translations({
      sv: `Redigera informationen`,
      en: `Edit information`,
      de: `Informationen bearbeiten`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleItem.notAuthorizedForRemoveSchedule': translations({
      sv: `Du har inte rättigheter att ta bort schemaläggning hos `,
      en: `You do not have rights to remove scheduling from`,
      de: `Sie haben keine Rechte zum Entfernen der Planung aus`,
    }),
    Property: translations({
      sv: `Anläggning`,
      en: `Property`,
      de: `Anlage`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleItem.time': translations({
      sv: `Dagstid:`,
      en: `Daytime:`,
      de: `von Tag:`,
    }),
    'components.dashboard.properties.form.findCoordinatesInfo': translations({
      sv: `Här finner du information om hur du hittar GPS-koordinater för en address`,
      en: `Here you will find information on how to find GPS coordinates for an address`,
      de: `Hier finden Sie Informationen zum Finden von GPS-Koordinaten für eine Adresse`,
    }),
    'components.dashboard.properties.form.successLocationUpdateMessage': translations({
      sv: `Koordinaterna återställda!`,
      en: `Coordinates restored!`,
      de: `Koordinaten wiederhergestellt!`,
    }),
    'components.dashboard.properties.new.showProperty': translations({
      sv: `visa min nya anläggning`,
      en: `Show my new property`,
      de: `Mein neues Unternehmen zeigen`,
    }),
    'components.loginPage.alertOne': translations({
      sv: `Tyvärr finns inte den angivna epostadressen i vårat system, var god kolla att du har skrivit in rätt adress`,
      en: `Unfortunately, the specified e-mail address is not registered in our system. Please make sure you have entered the correct address`,
      de: `Leider ist die angegebene E-Mail-Adresse in unserem System nicht registriert. Bitte stelle sicher, dass du die korrekte Adresse eingegeben hast.`,
    }),
    'Schedule starting times': translations({
      sv: `Schemalägg starttider`,
      en: `Schedule starting times`,
      de: `Planen Sie Startzeiten`,
    }),
    'Recommended size for the image is 668x302 pixels': translations({
      sv: `Rekommenderad bildstorlek för erbjudandets bild är 668x302 pixlar (72dpi), png eller jpg.`,
      en: `Recommended size for image is 668x302 pixels`,
      de: `Die empfohlene Größe für das Bild beträgt 668x302 Pixel`,
    }),
    'components.dashboard.properties.details.messageErrorSave': translations({
      sv: `Det gick inte att spara anläggningsbilden 🤕`,
      en: `The image could not be saved`,
      de: `Das Bild konnte nicht gespeichert werden.`,
    }),
    'components.dashboard.properties.employeeSchedule.index.addNewSchedule': translations({
      sv: `Lägg till ny schemaläggning`,
      en: `Add new scheduling`,
      de: `Neue Terminplanung hinzufügen`,
    }),
    'components.dashboard.properties.employeeSchedule.index.noSchedulesFound': translations({
      sv: `Det finns inga schemalagda tider`,
      en: `There are no scheduled times`,
      de: `Es gibt keine geplanten Zeiten`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleItem.schedule': translations({
      sv: `Schemaläggning`,
      en: `Scheduling`,
      de: `Ablaufplanung`,
    }),
    'components.dashboard.properties.form.Instagram': translations({
      sv: `Länk till Instagram`,
      en: `Link to Instagram`,
      de: `Link zu Instagram`,
    }),
    'components.dashboard.properties.form.latitud': translations({
      sv: `Latitud`,
      en: `Latitude`,
      de: `Breitengrad`,
    }),
    'components.dashboard.properties.form.longitud': translations({
      sv: `Longitud`,
      en: `Longitude`,
      de: `Längengrad`,
    }),
    'components.dashboard.properties.form.sucessUpdateCoordinateMessage': translations({
      sv: `👊  = )`,
      en: `👊  = )`,
      de: `👊  = )`,
    }),
    'Your properties': translations({
      sv: `Dina anläggningar`,
      en: `Your properties`,
      de: `Ihre Einrichtungen`,
    }),
    Places: translations({
      sv: `Platser`,
      en: `Places`,
      de: `Setzt`,
    }),
    'components.dashboard.properties.index.chooseProperty': translations({
      sv: `Välj den anläggning du vill jobba med från listan till vänster.`,
      en: `Select the business you want to work with from the list on the left.`,
      de: `Wähle aus der Liste auf der linken Seite das gewünschte Unternehmen aus.`,
    }),
    'components.dashboard.subscription.edit.noSource': translations({
      sv: `Du faktureras enligt avtal.`,
      en: `You are billed according to agreement.`,
      de: `Es wurden keine Zahlungsinformationen registriert`,
    }),
    remove: translations({
      sv: `Ta bort`,
      en: `Remove`,
      de: `Entfernen`,
    }),
    'components.dashboard.home.segments.payoutSegment.iconSeventeen': translations({
      sv: `CSV fil med presentkortsköp`,
      en: `CSV file with gift card purchases`,
      de: `CSV-Datei mit Gutscheinkäufen`,
    }),
    'Max visitors': translations({
      sv: `Max antal`,
      en: `Max visitors`,
      de: `Maximale anzahl`,
    }),
    'offers.details.notActivatedTitle': translations({
      sv: `Här kan du i vanliga fall skapa aktiviteter`,
      en: `Here you can usually create activities`,
      de: `Hier kannst du in der Regel Angebote erstellen`,
    }),
    'listOfOfferOccurances.greatMessage': translations({
      sv: `Toppen 🐝🎉`,
      en: `Great 🐝🎉`,
      de: `Top 🐝🎉`,
    }),
    'Could not be created': translations({
      sv: `Något gick fel när erbjudandet skulle skapas. Var snäll och kontakta supporten för hjälp`,
      en: `Could not be created`,
      de: `Angebot konnte nicht erstellt werden`,
    }),
    'Could not be saved': translations({
      sv: `Det gick inte att spara erbjudandet 🤕`,
      en: `Could not be saved`,
      de: `Angebot konnte nicht gespeichert werden.`,
    }),
    'components.dashboard.properties.details.editPropertyInfo': translations({
      sv: `Här kommer du kunna redigera anläggningens information och inställningar`,
      en: `Here you can edit property information`,
      de: `Informationen des Unternehmens hier bearbeiten`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleCreator.index.advancedSettings': translations({
      sv: `Avancerade inställningar`,
      en: `Advanced settings`,
      de: `Erweiterte Einstellungen`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleCreator.index.chooseWeek': translations({
      sv: `Välj veckor`,
      en: `Choose weeks`,
      de: `Wochen wählen`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleCreator.index.saveNewScheduleTemplate': translations({
      sv: `Spara ny schemaläggning`,
      en: `Save new scheduling`,
      de: `Neue Terminplanung speichern`,
    }),
    'components.dashboard.properties.form.country': translations({
      sv: `Land`,
      en: `Country`,
      de: `Land`,
    }),
    'components.dashboard.schedule.overviewOfPersonnels': translations({
      sv: `Översikt över personalens schema`,
      en: `Overview of employee calendars`,
      de: `Übersicht der Mitarbeiterkalender`,
    }),
    'components.dashboard.subscription.edit.nextPayment': translations({
      sv: `Nästa betalning`,
      en: `Next payment`,
      de: `Nächste Zahlung`,
    }),
    'components.dashboard.home.segments.helpSegment.headerFour': translations({
      sv: `Introduktion`,
      en: `Introduction`,
      de: `Wegweiser`,
    }),
    'activityOccurancesGeneratorForm.fillInTime': translations({
      sv: `Fyll i hur länge aktiviteten pågår`,
      en: `Fill in the necessary time for the appointment`,
      de: `Gib an wie lange die Anwendung dauert`,
    }),
    'components.dashboard.offers.details.bookingConfirmationForm.preshowBooking': translations({
      sv: `Förhandsvisning av bokningsbekräftelsen`,
      en: `Preview of the booking confirmation`,
      de: `Vorschau der Buchungsbestätigung`,
    }),
    'components.dashboard.offers.details.deleteModal.successMessage': translations({
      sv: `Erbjudandet har tagits bort`,
      en: `The offer has been removed.`,
      de: `Das Angebot wurde entfernt.`,
    }),
    'offers.details.scheduled': translations({
      sv: `Schemalagt och klart!`,
      en: `Done scheduling`,
      de: `Fertig geplant!`,
    }),
    'Fill in the number of slots available for visitors': translations({
      sv: `Fyll i hur många platser det skall finnas för besökare`,
      en: `Fill in the number of slots available for visitors`,
      de: `Füge die Anzahl der Kunden für dieses Angebot hinzu`,
    }),
    'components.dashboard.properties.details.propertyPicture': translations({
      sv: `Anläggningsbild`,
      en: `Property image`,
      de: `Bild des Unternehmens`,
    }),
    'Stop editing': translations({
      sv: `Avsluta redigering`,
      en: `Stop editing`,
      de: `Bearbeitung beenden`,
    }),
    'components.dashboard.properties.new.errorCreateProperty': translations({
      sv: `Något gick fel när anläggningen skulle skapas. Om du tror att det beror på ett problem med AdventureHero så var snäll och kontakta supporten`,
      en: `Property could not be created`,
      de: `Das Unternehmen konnte nicht erstellt werden.`,
    }),
    'components.dashboard.properties.new.propertyNotSaved': translations({
      sv: `Det gick inte att spara anläggningen`,
      en: `Property could not be saved`,
      de: `Das Unternehmen konnte nicht gespeichert werden.`,
    }),
    Schedule: translations({
      sv: `Schema`,
      en: `Schedule`,
      de: `Terminkalender`,
    }),
    Personnel: translations({
      sv: `Personal`,
      en: `Personnel`,
      de: `Personal`,
    }),
    Deals: translations({
      sv: `Extrapris`,
      en: `Deals`,
      de: `Deals`,
    }),
    Vouchers: translations({
      sv: `Värdebevis`,
      en: `Vouchers`,
      de: `Gutscheine`,
    }),
    Uses: translations({
      sv: `Användningar`,
      en: `Uses`,
      de: `Verbraucht`,
    }),
    'login-as-member': translations({
      sv: `Logga in som aktör`,
      en: `Log in as a member`,
      de: `Melde dich als Partner an`,
    }),
    'components.loginPage.contact': translations({
      sv: `Kontakta`,
      en: `Contact`,
      de: `Kontakt`,
    }),
    'components.dashboard.giftCards.sell.webbReaderNotSupported': translations({
      sv: `Tyvärr har din webbläsare har inte stöd för att visa presentkortsvyn`,
      en: `Unfortunately your browser does not support our gift card view.`,
      de: `Leider unterstützt Ihr Browser die Anzeige der Gutscheinkartenansicht nicht!`,
    }),
    'components.dashboard.home.segments.badgeSegment.headerBadges': translations({
      sv: `Mina märken`,
      en: `My badges`,
      de: `Meine Medaillen`,
    }),
    'components.dashboard.home.segments.helpSegment.iconTwentyone': translations({
      sv: `Visa och hantera bokningar`,
      en: `View and manage bookings`,
      de: `Anzeigen und Verwalten von Buchungen`,
    }),
    'components.dashboard.home.segments.payoutSegment.getInfoPayments': translations({
      sv: `Här kan du hämta detaljerad information om utbetalningar gjorda till ditt bankkonto.`,
      en: `Here you can find detailed information about payouts sent to your bank account`,
      de: `Hier findest du detallierte Informationen zu deinen monatlichen Auszahlungen.`,
    }),
    'components.dashboard.home.segments.tmeTicketsStatsSegment.getInfoPayments': translations({
      sv: `Här kan du hämta ut statistik över hur Borås-biljetterna använts av besökarna.`,
      en: `Here you can retrieve statistics on how the Borås tickets were used by the visitors.`,
      de: `Hier können Sie Statistiken darüber abrufen, wie die Borås-Tickets von den Besuchern verwendet wurden.`,
    }),
    'activityOccurancesGeneratorForm.end': translations({
      sv: `Period slut`,
      en: `Period end`,
      de: `Bis`,
    }),
    'components.dashboard.offers.details.deleteModal.bookedActivitiesInfo': translations({
      sv: `Bokade aktiviteter kommer inte att tas bort.`,
      en: `Activities with bookings will not be removed.`,
      de: `Anwendungen mit Buchungen können nicht entfernt werden.`,
    }),
    'Something went wrong, try again.': translations({
      sv: `Något gick fel, var god försök igen.`,
      en: `Something went wrong, try again.`,
      de: `Etwas ist schief gelaufen. Versuchen es nochmal.`,
    }),
    'Bookings and slots': translations({
      sv: `Bokningar och platser`,
      en: `Bookings and slots`,
      de: `Buchungen und Plätze`,
    }),
    'Start time': translations({
      sv: `Starttid`,
      en: `Start time`,
      de: `Startzeit`,
    }),
    'And minutes': translations({
      sv: `Plus minuter`,
      en: `And minutes`,
      de: `und Minuten`,
    }),
    'components.dashboard.offers.index.allOffers': translations({
      sv: `Alla erbjudanden för`,
      en: `All offers for`,
      de: `Alle Angebote für`,
    }),
    'all-inventories': translations({
      sv: `Alla inventarier för`,
      en: `All inventories for`,
      de: `Alle Vorräte für`,
    }),
    'Here u can create inventories...': translations({
      sv: `Här kan du skapa inventarier för att senare använda i dina erbjudanden.`,
      en: `Here u can create inventories to later be added in your offers.`,
      de: `Hier können Sie Inventare erstellen, die später in Ihre Angebote aufgenommen werden.`,
    }),
    'The system will calculate...': translations({
      sv: `Systemet kommer att räkna ut antal tillgängliga inventarier.`,
      en: `The system will calculate available amounts of inventory`,
      de: `Das System berechnet die verfügbaren Mengen des Inventars`,
    }),
    'New inventory': translations({
      sv: `Nytt inventarie`,
      en: `New inventory`,
      de: `Neues Inventar`,
    }),
    'New (alt)': translations({
      sv: `Nytt`,
      en: `New`,
      de: `Neues`,
    }),
    'Reset time': translations({
      sv: `Återställningstid`,
      en: `Reset time`,
      de: `Wiederherstellungszeit`,
    }),
    'components.dashboard.properties.details.facebookMissing': translations({
      sv: `Facebook saknas`,
      en: `Facebook is missing`,
      de: `Facebook fehlt`,
    }),
    'components.dashboard.properties.details.infoMissing': translations({
      sv: `Viktig information saknas`,
      en: `Important information missing`,
      de: `Wichtige Informationen fehlen`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleCreator.index.allDays': translations({
      sv: `Alla dagar`,
      en: `Every day`,
      de: `Alle Tage`,
    }),
    'components.dashboard.properties.form.areasLabel': translations({
      sv: `Områden`,
      en: `Areas`,
      de: `Zone`,
    }),
    'components.dashboard.properties.form.recommendedPicSize': translations({
      sv: `Rekommenderad bildstorlek för anläggningens bild är 650x400 pixlar (72dpi), png eller jpg.`,
      en: `Recomended image size is 650x400 pixels.`,
      de: `Die empfohlene Bildgröße beträgt 650x400 Pixel.`,
    }),
    'Properties that belongs to': translations({
      sv: `Anläggningar tillhörande`,
      en: `Properties that belongs to`,
      de: `Anlagen von`,
    }),
    'Sold giftcards': translations({
      sv: `Sålda presentkort`,
      en: `Sold giftcards`,
      de: `Verkaufte Gutscheinkarten`,
    }),
    'components.dashboard.giftCards.sell.sellGiftcard': translations({
      sv: `Sälj eller ge bort ett presentkort`,
      en: `Sell or gift a gift card`,
      de: `Eine Gutscheinkarte verkaufen`,
    }),
    'components.dashboard.home.segments.helpSegment.descriptionSix': translations({
      sv: `Via schemat kan du se alla aktiviteter och planera din personal. Dina anställda får mail om ändrade arbetstider och kan själva logga in och se sitt schema.`,
      en: `In the schedule you'll be able to view all actvitities and plan your employees. Your employees will receive a mail about changes in their work assignments and are able to log in and view them as well.`,
      de: `Über den Terminplan werden alle Termine angezeigt. Auch deine Mitarbeiter kannst du dort planen. Diese erhalten dann E-Mails zu geänderten Arbeitszeiten und können sich einloggen, um ihren persönlichen Terminplan einzusehen.`,
    }),
    'components.dashboard.home.segments.helpSegment.descriptionTwo': translations({
      sv: `När du har skapat din första anläggning kan du se och ändra anläggningens schema och personallista. Det går även att välja vem eller vilka som skall ansvara för anläggningen.`,
      en: `When you've created your first business you can view and edit the schedule and personnel. You can also select which personnel that should be responsible for the business.`,
      de: `Nachdem du ein oder mehrere Unternehmen hinzugefügt hast, kannst du Terminpläne und Personallisten einsehen und bearbeiten. Außerdem kannst du einen Manager auswählen.`,
    }),
    'components.dashboard.home.segments.helpSegment.iconNineteen': translations({
      sv: `Hantera dina anläggningar`,
      en: `Manage your properties`,
      de: `Deine Anlagen verwalten`,
    }),
    'The new appointments will be scheduled with the following values:': translations({
      sv: `Dom nya aktiviterna kommer schemaläggas med följande värden:`,
      en: `The new appointments will be scheduled with the following values:`,
      de: `Die neuen Anwendungen werden mit den folgenden Details geplant:`,
    }),
    'components.dashboard.offers.details.deleteModal.activities': translations({
      sv: `aktiviteter`,
      en: `activties`,
      de: `Aktivitäten`,
    }),
    'offers.details.tabForEmployeeScheduling': translations({
      sv: `Personaltilldelning`,
      en: `Add employees`,
      de: `Mitarbeiter hinzufügen`,
    }),
    'There are no planned start times for': translations({
      sv: `Det finns inga planerade starttider av`,
      en: `There are no planned start times for`,
      de: `Es gibt keine geplanten Startzeiten`,
    }),
    'Fill in a name for offer': translations({
      sv: `Fyll i ett namn för erbjudandet"`,
      en: `Fill in a name for offer`,
      de: `Gib einen Namen für das Angebot an`,
    }),
    'components.dashboard.offers.new.addOffer': translations({
      sv: `Lägg till ett erbjudande för`,
      en: `Add offer for`,
      de: `Angebote hinzufügen für`,
    }),
    'components.dashboard.properties.details.mailMissing': translations({
      sv: `Email saknas`,
      en: `Email missing`,
      de: `E-Mail fehlt`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleCreator.checkboxPicker.chooseWeekDays': translations({
      sv: `Välj veckodagar`,
      en: `Select weekdays`,
      de: `Wochentage auswählen`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.endTime': translations({
      sv: `Ny sluttid`,
      en: `New end time`,
      de: `Neue Endzeit`,
    }),
    'components.dashboard.properties.employeesView.showAllEmployees': translations({
      sv: `Här visas alla anställda på`,
      en: `Here we show employees at`,
      de: `Hier sind alle Mitarbeiter von`,
    }),
    'components.dashboard.properties.form.areasInfo': translations({
      sv: `Fyll i de områden som du jobbar i. Som ett exempel kan du addera en eller flera städer "London, Birmingham". Eller en eller flera postnummer "72312, 72365". Eller en kombination av båda "London, 72312, 72365". Multipla områden måste separeras med ett kommatecken.`,
      en: `Enter the areas you cover. As an example you could add one or multiple cities e.g "London, Birmingham". Or one or multiple zipcodes e.g "72312, 72365". Or even combination of both e.g "London, 72312, 72365". Multiple areas must be separated with a comma`,
      de: `Geben Sie die Bereiche ein, die Sie abdecken. Som eksemple kan du tilføje et eller fere byer f.eks. "London, Birmingham". Oder eine oder mehrere Postleitzahlen, zum Beispiel "72312, 72365". Oder sogar eine Kombination von beiden, zum Beispiel "London, 72312, 72365". Mehrere Bereiche müssen durch ein Komma getrennt sein`,
    }),
    'components.dashboard.properties.form.webbplats': translations({
      sv: `Webbplats`,
      en: `Website`,
      de: `Webseite`,
    }),
    'components.loginPage.promptTwo': translations({
      sv: `Var snäll och ange en giltig emailadress`,
      en: `Please use a correct email address`,
      de: `Bitte gib eine gültige E-Mail-Adresse ein`,
    }),
    'components.dashboard.home.segments.badgeSegment.noBadges': translations({
      sv: `Du har inga märken ännu`,
      en: `You dont have any badges`,
      de: `Du hast keine Medaillen`,
    }),
    'components.dashboard.home.segments.tmeTicketsStatsSegment.buildGetFile': translations({
      sv: `Skapar & hämtar fil`,
      en: `Creating & downloading file`,
      de: `Datei erstellen und herunterladen`,
    }),
    'components.dashboard.home.segments.payoutSegment.buildGetFile': translations({
      sv: `Skapar & hämtar fil`,
      en: `Creating & downloading file`,
      de: `Datei erstellen und herunterladen`,
    }),
    'components.dashboard.index.changeOrganizations': translations({
      sv: `Byt organisation`,
      en: `Change organization`,
      de: `Unternehmen`,
    }),
    'offers.details.and': translations({
      sv: `och`,
      en: `and`,
      de: `und`,
    }),
    'offers.details.great': translations({
      sv: `toppen!`,
      en: `great!`,
      de: `Super!`,
    }),
    'Enter time in hours': translations({
      sv: `Ange tid i timmar`,
      en: `Enter time in hours`,
      de: `Zeit in Stunden`,
    }),
    'Amount available': translations({
      sv: `Antal tillgängliga`,
      en: `Amount available`,
      de: `Nummer zur Verfügung`,
    }),
    'selected inventory quantity': translations({
      sv: 'vald lagerkvantitet',
      en: 'selected inventory quantity',
      de: 'ausgewählte Inventarmenge',
    }),
    "This value can't be greater than": translations({
      sv: 'Värdet kan inte överstiga',
      en: "This value can't be greater than",
      de: 'Dieser Wert darf nicht größer sein als',
    }),
    'components.dashboard.properties.details.pageMissing': translations({
      sv: `Hemsida saknas`,
      en: `Website missing`,
      de: `Webseite fehlt`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleCreator.checkboxPicker.chooseMonths': translations({
      sv: `Välj månader`,
      en: `Select months`,
      de: `Wähle Monate`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.bookingConflicts': translations({
      sv: `Det finns en bokning under denna tid, tiden får ej ändras`,
      en: `There is a booking during this time, the time cannot be changed`,
      de: `Es gibt eine Buchung während dieser Zeit, die Zeit kann nicht geändert werden`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.removeButton': translations({
      sv: `Ta bort undantag`,
      en: `Remove exceptions`,
      de: `Ausnahmen entfernen`,
    }),
    'Find employees...': translations({
      sv: `Hitta anställd...`,
      en: `Find employees...`,
      de: `Mitarbeiter finden...`,
    }),
    'components.dashboard.properties.form.propertyNameExists': translations({
      sv: `Anläggningsnamnet är tyvärr upptaget för följande språk:`,
      en: `The business names are already in use for the following languages:`,
      de: `Der Firmenname ist für die folgenden Sprachen besetzt:`,
    }),
    'components.dashboard.subscription.edit.lastPayment': translations({
      sv: `Sista betalningen`,
      en: `Last payment`,
      de: `Letzte Zahlung`,
    }),
    'components.dashboard.giftCards.sell.sameViewHomePage': translations({
      sv: `Här kan du använda samma vy som dina kunder kan se för att sälja eller ge bort ett presentkort. Här finns också instruktioner för hur du lägger till presentkortsvyn på din egna hemsida.`,
      en: `From here you can use the same view as your customers see to sell or gift a gift card. From here you can also find instructions on how to add the gift card view to your own website.`,
      de: `Hier kannst du dieselbe Ansicht verwenden, die deine Kunden sehen können, um eine Gutscheinkarte zu kaufen oder zu verschenken. Hier finden Sie auch Anweisungen zum hinzufügen der Gutscheinkartenansichten auf deiner eigenen Webseite.`,
    }),
    'activityOccurancesGeneratorForm.chooseDatesAndTime': translations({
      sv: `Välj dagar och tider`,
      en: `Choose day and`,
      de: `Wähle Tage und Uhrzeiten`,
    }),
    'activityOccurancesGeneratorForm.chooseDays': translations({
      sv: `Välj dagar`,
      en: `Choose days`,
      de: `Wähle Tage`,
    }),
    'Choose date': translations({
      sv: `Välj datum`,
      en: `Choose date`,
      de: `Datum auswählen`,
    }),
    'activityOccurancesGeneratorForm.everySecondWeek': translations({
      sv: `Varannan vecka`,
      en: `Every other week`,
      de: `Jede zweite Woche`,
    }),
    'Personnel slots': translations({
      sv: `Antal personalplatser`,
      en: `Personnel slots`,
      de: `Anzahl Mitarbeiterplätze`,
    }),
    'components.dashboard.offers.details.deleteModal.removeDescription2': translations({
      sv: `schemalagda aktiviteter för det här erbjudandet varav`,
      en: `scheduled activities for this offer`,
      de: `geplante Aktivitäten für dieses Angebot`,
    }),
    'components.dashboard.offers.details.deleteModal.removeDescription3': translations({
      sv: `är uppkommande och`,
      en: `are emerging and`,
      de: `entstehen und`,
    }),
    'offers.details.addManualStarttimes': translations({
      sv: `lägg till tider`,
      en: `Add times`,
      de: `Liste`,
    }),
    'offers.details.removeOffer': translations({
      sv: `Ta bort erbjudandet`,
      en: `Remove offer`,
      de: `Angebot entfernen`,
    }),
    'listOfOfferOccurances.removexTimes': translations({
      sv: `Ta bort markerade tider`,
      en: `Delete marked times`,
      de: `Lösche die gekennzeichneten Termine`,
    }),
    'components.dashboard.offers.form.numberVolonteer': translations({
      sv: `Antal frivilligaplatser`,
      en: `number of volunteers`,
      de: `Anzahl der Freiwilligen`,
    }),
    Offer: translations({
      sv: `Erbjudande`,
      en: `Offer`,
      de: `Angebot`,
    }),
    Schedules: translations({
      sv: `Scheman`,
      en: `Schedules`,
      de: `Terminplan`,
    }),
    'activityOccurancesGeneratorForm.emptyMessage': translations({
      sv: `Du måste välja minst en anläggning`,
      en: `You must select at least one property`,
      de: `Du musst mindestens ein Unternehmen auswählen.`,
    }),
    'activityOccurancesGeneratorForm.fillTimeLength': translations({
      sv: `Fyll i en giltlig tidslängd`,
      en: `Please enter a valid length of time`,
      de: `Bitte gib eine gültige Länge der Zeit ein!`,
    }),
    'There are in total': translations({
      sv: `Det finns totalt`,
      en: `There are in total`,
      de: `Es gibt insgesamt`,
    }),
    'Upcoming activities': translations({
      sv: `Kommande aktiviteter`,
      en: `Upcoming activities`,
      de: `Anstehende Aktivitäten`,
    }),
    'All upcoming activities that...': translations({
      sv: `Alla kommande aktiviteter som har schemalagts kommer också att uppdateras i samband med erbjudandet.`,
      en: `All upcoming activities that has been scheduled will also be updated when updating the offer.`,
      de: `Alle anstehenden geplanten Aktivitäten werden bei der Aktualisierung des Angebots ebenfalls aktualisiert.`,
    }),
    'Its up to you to notify...': translations({
      sv: `Det är upp till dig att meddela dina kunder om ändringar som kommer att påverka dem.`,
      en: `It's up to you to notify your customers about changes that will affect them.`,
      de: `Es liegt an Ihnen, Ihre Kunden über Änderungen zu informieren, die sie betreffen.`,
    }),
    Edit: translations({
      sv: `Redigera`,
      en: `Edit`,
      de: `Bearbeiten`,
    }),
    'offers.details.wrongStartTimes': translations({
      sv: `Något gick fel med att skapa dom nya starttiderna. Var snäll och kontakta supporten för hjälp.`,
      en: `Could not create with new start times.`,
      de: `Die neuen Termine konnten nicht erstellt werden.`,
    }),
    'offers.details.notPossibleToSave': translations({
      sv: `Det gick inte att spara dom nya starttiderna 🤕`,
      en: `Could not save the new start times`,
      de: `Die neuen Termine konnten nicht gespeichert werden.`,
    }),
    'listOfOfferOccurances.removedTimes': translations({
      sv: `Tiderna är nu borttagna!`,
      en: `Times are now removed!`,
      de: `Termine sind jetzt entfernt!`,
    }),
    'slots left': translations({
      sv: `platser kvar`,
      en: `slots left`,
      de: `Plätze übrig`,
    }),
    left: translations({
      sv: `kvar`,
      en: `left`,
      de: `übrig`,
    }),
    'Add a Number of employees under the offer (optional)': translations({
      sv: `Fyll i antal ansvariga under erbjudandet (valfritt)`,
      en: `Add a Number of employees under the offer (optional)`,
      de: `Füge die Anzahl von Mitarbeitern für dieses Angebot hinzu (optional)`,
    }),
    'You can choose a maximum of 3 categories': translations({
      sv: `Du kan max välja tre stycken kategorier`,
      en: `You can choose a maximum of 3 categories`,
      de: `Du kannst bis zu drei Kategorien auswählen.`,
    }),
    'You can input a number between 0 and 59': translations({
      sv: `Minuter kan bara vara mellan 0 och 59`,
      en: `You can input a number between 0 and 59`,
      de: `Minuten können nur zwischen 0 und 59 Minuten liegen`,
    }),
    'Hours can only be between 0 and 168': translations({
      sv: `Timmar kan bara vara mellan 0 och 168`,
      en: `Hours can only be between 0 and 168`,
      de: `Stunden können zwischen 0 und 168 liegen`,
    }),
    'components.dashboard.home.segments.payoutSegment.oops': translations({
      sv: `Ops.. 😲`,
      en: `Oops..`,
      de: `Ups..`,
    }),
    'components.dashboard.home.segments.tmeTicketsStatsSegment.oops': translations({
      sv: `Ops.. 😲`,
      en: `Oops..`,
      de: `Ups..`,
    }),
    Properties: translations({
      sv: `Anläggningar`,
      en: `Properties`,
      de: `Anlagen`,
    }),
    'activityOccurancesGeneratorForm.chooseStartDate': translations({
      sv: `Välj ett startdatum`,
      en: `Choose a start time`,
      de: `Wähle ein Startdatum aus`,
    }),
    'Thank you for the booking.': translations({
      sv: `Tack för din bokning!`,
      en: `Thank you for the booking.`,
      de: `Vielen Dank für Ihre Buchung!`,
    }),
    'listOfOfferOccurances.noTimes': translations({
      sv: `Det gick tyvärr inte att ta bort några tider.`,
      en: `Sorry, it was not possible to delete any time.`,
      de: `Entschuldigung, es war nicht möglich die Termine zu löschen.`,
    }),
    'Upcoming start times': translations({
      sv: `Kommande starttider`,
      en: `Upcoming start times`,
      de: `Kommende Startzeiten`,
    }),
    'components.dashboard.offers.index.numberOfPlaces': translations({
      sv: `Antal platser`,
      en: `Number of slots`,
      de: `Anzahl der Plätze`,
    }),
    'components.dashboard.properties.details.addressMissing': translations({
      sv: `Adressuppgifter saknas`,
      en: `Address is missing`,
      de: `Adresse fehlerhaft`,
    }),
    'components.dashboard.home.segments.helpSegment.descriptionThree': translations({
      sv: `Inne på en anläggning kan du se och ändra anläggningens schema och personallista. Via schemat kan du skapa och hantera aktiveter.`,
      en: `Under the business tab you can view and change it's schedule and personnel. Via the schedule your can create and edit your activities.`,
      de: `Unter der Ansicht der einzelnen Unternehmen kannst du die jeweiligen Terminpläne und die Mitarbeiter einsehen und ändern. Über den Terminplan kannst du deine Anwendungen erstellen und bearbeiten.`,
    }),
    'components.dashboard.home.segments.settingsSegment.subHeaderTwo': translations({
      sv: `Svar på utskickade mail skickas till den angivna adressen nedan. Om fältet lämnas tomt skickas mailen till dig som är organisationsansvarig.`,
      en: `Replies to sent mails are sent to the entered email address below. If the field is empty replies will be sent to you the organization owner.`,
      de: `Antworten auf gesendete E-Mails werden an die unten angegebene E-Mail-Adresse gesendet. Wenn das Feld leer ist, werden die Antworten an den Manager gesendet.`,
    }),
    'activityOccurancesGeneratorForm.ChooseActivtyPark': translations({
      sv: `Välj anläggningar som starttiderna ska schemaläggas för`,
      en: `Select a property for which start times are scheduled`,
      de: `Wähle eine Anlagen aus, für das die Termine bereits erstellt sind.`,
    }),
    'activityOccurancesGeneratorForm.chooseAtleastOneTime': translations({
      sv: `Du måste ange minst en tid på valfri veckodag`,
      en: `You must enter at least once on each day of the week`,
      de: `Du musst mindestens einen Termin an jedem Wochentag eingeben.`,
    }),
    'activityOccurancesGeneratorForm.publishHeader': translations({
      sv: `Publicera tider i samband med schemaläggningen`,
      en: `Publish times when scheduling`,
      de: `Veröffentlichen Sie Zeiten bei der Planung`,
    }),
    'Published times are available for your customers...': translations({
      sv: `Publicerade tider är synliga för dina kunder att boka online. Det går även bra att publicera tider i efterhand.`,
      en: `Published times are available for your customers to book online. It's also possible to publish times later.`,
      de: `Veröffentlichte Zeiten stehen Ihren Kunden zur Online-Buchung zur Verfügung. Es ist auch möglich, Zeiten später zu veröffentlichen.`,
    }),
    'activityOccurancesGeneratorForm.publishCheckbox': translations({
      sv: `Gör tiderna bokningsbara`,
      en: `Make the times bookable`,
      de: `Machen Sie die Zeiten buchbar`,
    }),
    'activityOccurancesGeneratorForm.everyWeek': translations({
      sv: `Varje vecka`,
      en: `Every week`,
      de: `Jede Woche`,
    }),
    'activityOccurancesGeneratorForm.fillInResponsible': translations({
      sv: `Fyll i antal ansvariga under erbjudandet`,
      en: `Fill in the number of personnel needed for the appointment`,
      de: `Gib die Anzahl der Mitarbeiter für diese Anwendung ein`,
    }),
    'activityOccurancesGeneratorForm.repetition': translations({
      sv: `Repetition`,
      en: `Repeat`,
      de: `Wiederholung`,
    }),
    'Search text': translations({
      sv: `Söktext`,
      en: `Search text`,
      de: `Suchtext`,
    }),
    'No results...': translations({
      sv: `Inga resultat...`,
      en: `No results...`,
      de: `Keine Ergebnisse`,
    }),
    'Customer information': translations({
      sv: `Kundinformation`,
      en: `Customer information`,
      de: `Kundeninformation`,
    }),
    'offers.details.assignEmployeesDescription': translations({
      sv: `Besökare kan välja en av följande anställda när dom bokar, givet att dom är tillgängliga.`,
      en: `Visitors can choose one of the following employees when booking, provided they are available.`,
      de: `Besucher können bei der Buchung einen der folgenden Mitarbeiter auswählen, sofern diese verfügbar sind.`,
    }),
    'offers.details.personnelNumber': translations({
      sv: `Personalplatser:`,
      en: `Personnel`,
      de: `Mitarbeiter Anzahl`,
    }),
    'Clean up time': translations({
      sv: `Uppstädningstid`,
      en: `Clean up time`,
      de: `Putzzeit`,
    }),
    'components.dashboard.home.segments.helpSegment.descriptionOne': translations({
      sv: `Om du vill administrera din personal är det en bra idé att lägga till dom redan från början.`,
      en: `If you want to administrate your personnel it's a good idea to add them right from the start.`,
      de: `Füge deine Mitarbeiter jetzt hinzu, das erleichtert die Personalplanung.`,
    }),
    'components.dashboard.offers.details.bookingConfirmationForm.messageWillToCustomer': translations({
      sv: `Meddelanden som du skriver här kommer skickas till kunden på mail när hon har bokat erbjudandet`,
      en: `Messages you write here will be sent to the customer by email when they have booked an appointment.`,
      de: `Deine Grüße werden per E-Mail an den Kunden gesendet, nachdem er die Anwendung gebucht hat.`,
    }),
    'components.dashboard.offers.details.bookingRulesForm.descriptionRules': translations({
      sv: `Om du skriver något här så kommer det visas för kund vid bokning, och kunden måste gå med på villkoren för att kunna fortsätta.`,
      en: `If you write something here, it will be shown to the customer during booking process and the customer must agree on the terms in order to proceed with booking.`,
      de: `Wenn du hier etwas schreibst, wird es dem Kunden bei der Buchung angezeigt. Der Kunde muss die Bedingungen akzeptieren, um fortzufahren.`,
    }),
    'offers.details.addMultiScheduleTimes': translations({
      sv: `Här kan du schemalägga många starttider på samma gång. Välj en period och vilka dagar och tider erbjudandet ska starta.`,
      en: `Here you can schedule multiple starting times at once. Choose period, day and times you want the activities to start.`,
      de: `Hier kannst du mehrere Termine gleichzeitig planen. Wähle den Zeitraum, den Tag und die Uhrzeit aus, zu denen die Anwendungen beginnen sollen.`,
    }),
    'offers.details.bookingClose': translations({
      sv: `Bokning stänger:`,
      en: `Booking closes`,
      de: `Buchung schließt`,
    }),
    'offers.details.bookingTime': translations({
      sv: `Tid per bokning:`,
      en: `Time per reservation:`,
      de: `Zeit pro Anwendung`,
    }),
    'offers.details.specialRulesAdd': translations({
      sv: `Du kan ange speciella villkor som en kund måste godkänna vid bokning av erbjudandet`,
      en: `You can add special rules which a client has to accept during the booking process`,
      de: `Du kannst spezielle Bedingungen angeben, die ein Kunde bei der Buchung der Anwendung akzeptieren muss.`,
    }),
    'offers.details.termsOfUse': translations({
      sv: `Användarvillkor`,
      en: `Terms of use`,
      de: `Nutzungsbedingungen`,
    }),
    'Describe your offer to customers using the booking view': translations({
      sv: `Beskriv erbjudandet för besökarna till bokningssidan`,
      en: `Describe your offer to customers using the booking view`,
      de: `Beschreibe deine Anwendung für den Kunden`,
    }),
    'Minimum amount that must be booked': translations({
      sv: `Minsta antal som måste bokas`,
      en: `Minimum amount that must be booked`,
      de: `Mindestanzahl`,
    }),
    'components.dashboard.home.segments.helpSegment.descriptionFive': translations({
      sv: `När en aktivitet har blivit bokad hos dig kan du söka fram och se information om bokningen. Du kan dessutom redigera och ta bort bokningar, inklusive återbetala pengarna!`,
      en: `When a activity has been booked, you can search and view the information about that booking. You can also edit or remove bookings as well as cancel and refund bookings.`,
      de: `Sobald ein Termin gebucht wurde, kannst du alle Informationen dieser Buchung einsehen. Außerdem kannst du Buchungen auch bearbeiten, löschen und Geld zurückerstatten!`,
    }),
    'components.dashboard.home.segments.payoutSegment.errorFile': translations({
      sv: `Något gick fel när filen skulle skapas, var god försök igen.`,
      en: `Something went wrong during the creation of the file, please try again.`,
      de: `Beim Erstellen der Datei ist ein Fehler aufgetreten. Bitte versuche es erneut.`,
    }),
    'components.dashboard.home.segments.payoutSegment.iconSixteen': translations({
      sv: `CSV fil med bokningar`,
      en: `CSV file with bookings`,
      de: `CSV-Datei mit buchungen`,
    }),
    'components.dashboard.home.segments.tmeTicketsStatsSegment.errorFile': translations({
      sv: `Något gick fel när filen skulle skapas, var god försök igen.`,
      en: `Something went wrong during the creation of the file, please try again.`,
      de: `Beim Erstellen der Datei ist ein Fehler aufgetreten. Bitte versuche es erneut.`,
    }),
    'components.dashboard.home.segments.tmeTicketsStatsSegment.noTickets': translations({
      sv: `Det fanns inga tillgängliga biljetter för den angivna perioden. Var god försök igen.`,
      en: `There were no tickets available for the indicated period. Please try again.`,
      de: `Für den angegebenen Zeitraum waren keine Tickets verfügbar. Bitte versuche es erneut.`,
    }),
    'components.dashboard.home.segments.tmeTicketsStatsSegment.iconSixteen': translations({
      sv: `CSV fil med bokningar`,
      en: `CSV file with bookings`,
      de: `CSV-Datei mit buchungen`,
    }),
    'components.dashboard.home.segments.settingsSegment.headerTwo': translations({
      sv: `Svarsmail`,
      en: `Mail answer`,
      de: `E-Mail Antwort`,
    }),
    'components.dashboard.home.segments.settingsSegment.subHeaderOne': translations({
      sv: `Vid köp skickas ett mail med info om köpet till den angivna adressen nedan. Funktionen kan t.ex. användas som fakturerings- eller bokföringsunderlag`,
      en: `When a purchase is made a mail with information about the purchase is sent to the address below. As an example, this information can be used as a basis for invoices or accounting.`,
      de: `Nach der Buchung wird eine E-Mail mit allen Informationen zu deinem Termin an die unten angegebene E-Mail-Adresse gesendet.`,
    }),
    'activityOccurancesGeneratorForm.chooseEndDate': translations({
      sv: `Välj ett slutdatum`,
      en: `Choose a stop time`,
      de: `Wähle ein Enddatum aus`,
    }),
    'Choose activities that should be included in ticket': translations({
      sv: `Välj aktiviteter som ska ingå i biljett`,
      en: `Choose activities that should be included in ticket`,
      de: `Wählen Sie Aktivitäten aus, die im Ticket enthalten sein sollen`,
    }),
    'Choose a category for your offer': translations({
      sv: `Välj kategorier som matchar ditt erbjudande`,
      en: `Choose a category for your offer`,
      de: `Wähle eine Kategorie für dein Angebot`,
    }),
    'Choose an image for your offer': translations({
      sv: `Välj erbjudandets bild`,
      en: `Choose an image for your offer`,
      de: `Wähle ein Bild für dein Angebot`,
    }),
    'Time in hours': translations({
      sv: `Tid i timmar`,
      en: `Time in hours`,
      de: `Zeit in Stunden`,
    }),
    'components.dashboard.prompts.accountActivationPrompt.title': translations({
      sv: `Nästan klar!`,
      en: `Almost done!`,
      de: `Fast fertig!`,
    }),
    'components.dashboard.subscription.index.mySubscription': translations({
      sv: `Min prenumeration`,
      en: `My subscription`,
      de: `Mein Abonnement`,
    }),
    'Loading schedule...': translations({
      sv: `Laddar schema...`,
      en: `Loading schedule ...`,
      de: `Zeitplan wird geladen..`,
    }),
    'components.dashboard.properties.employeesView.addEmployee': translations({
      sv: `Lägg till anställd`,
      en: `Add employee`,
      de: `Mitarbeiter hinzufügen`,
    }),
    'components.dashboard.properties.form.facebook': translations({
      sv: `Länk till Facebook`,
      en: `Link to Facebook`,
      de: `Link zu Facebook`,
    }),
    'Save property': translations({
      sv: `Spara anläggningen`,
      en: `Save property`,
      de: `Unternehmen speichern`,
    }),
    'Save place': translations({
      sv: `Spara platsen`,
      en: `Save place`,
      de: `Unternehmen speichern`,
    }),
    'components.dashboard.properties.new.uniktid': translations({
      sv: `Anläggningen sparades 👍`,
      en: `Business saved`,
      de: `Das Unternehmen wurde gespeichert.`,
    }),
    'components.dashboard.properties.details.overview': translations({
      sv: `Översikt`,
      en: `Overview`,
      de: `Übersicht`,
    }),
    'To date': translations({
      sv: `Till datum`,
      en: `To date`,
      de: `Bis heute`,
    }),
    'Change personnel list': translations({
      sv: `Ändra personallistan`,
      en: `Change personnel list`,
      de: `Ändern Sie die Personalliste`,
    }),
    'components.dashboard.properties.index.uniktid': translations({
      sv: `Om du vill skapa en ny anläggning så kan du`,
      en: `If you want to create a new business, you can`,
      de: `Wenn du ein neues Unternehmen erstellen möchtest, dann kannst du das hier tun.`,
    }),
    'components.loginPage.buttonTwo': translations({
      sv: `Skicka ett mail till mig`,
      en: `Send an e-mail to me`,
      de: `Schick mir eine E-Mail`,
    }),
    'components.dashboard.properties.details.frontPage': translations({
      sv: `framsidan och är synlig för alla äventyrare.`,
      en: `front page and is visible to all clients.`,
      de: `sichtbar`,
    }),
    'components.dashboard.properties.details.instagramMissing': translations({
      sv: `Instagram saknas`,
      en: `Instagram is missing`,
      de: `Instagram fehlt`,
    }),
    'components.dashboard.properties.employeeSchedule.index.schedule': translations({
      sv: `Schemalägg`,
      en: `Schedule`,
      de: `Zeitplan`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleCreator.index.needToResolveConflicts': translations({
      sv: `Vänligen lös alla konflikter innan ny schemaläggning sparas`,
      en: `Please resolve all conflicts before saving new scheduling`,
      de: `Bitte lösen Sie alle Konflikte, bevor Sie eine neue Planung speichern`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.startTime': translations({
      sv: `Ny starttid`,
      en: `New start time`,
      de: `Neue Startzeit`,
    }),
    'Edit personnel list': translations({
      sv: `Redigera personallistan`,
      en: `Edit personnel list`,
      de: `Personalliste bearbeiten`,
    }),
    'Describe your property for customers': translations({
      sv: `Beskriv anläggningen för besökarna på bokningssidan`,
      en: `Describe your property to your customers`,
      de: `Beschreibe dein Unternehmen für Kunden`,
    }),
    'Describe this place to your customers': translations({
      sv: `Beskriv platsen för dina kunder`,
      en: `Describe your property to your customers`,
      de: `Beschreiben Sie Ihren Kunden den Standort`,
    }),
    'components.dashboard.properties.form.holderCountry': translations({
      sv: `Tyskland`,
      en: `German`,
      de: `Deutschland`,
    }),
    'Add a property': translations({
      sv: `Lägg till en anläggning`,
      en: `Add a property`,
      de: `Eigenschaft hinzufügen`,
    }),
    'Add a place': translations({
      sv: `Lägg till en plats`,
      en: `Add a place`,
      de: `Füge einen Ort hinzu`,
    }),
    'Update place': translations({
      sv: `Ändra platsen`,
      en: `Update place`,
      de: `Den Ort wechseln`,
    }),
    'components.dashboard.schedule.headerTwo': translations({
      sv: `Visa alla aktiviteter`,
      en: `Show all activities`,
      de: `Alle Termine`,
    }),
    'components.loginPage.messageFollow': translations({
      sv: `Nu är det bara att följa länken i mailet, lycka till så ses vi inne på www.adventurehero.se`,
      en: `Now just click the link in the email we sent you, see you inside www.adventurehero.se`,
      de: `Jetzt folge einfach dem Link in der E-Mail. www.adventurehero.se`,
    }),
    'components.dashboard.properties.details.phoneMissing': translations({
      sv: `Telefonnummer saknas`,
      en: `Phone number missing`,
      de: `Telefonnummer fehlt`,
    }),
    'components.dashboard.properties.employeeSchedule.index.scheduleTimes': translations({
      sv: `Scheemaläggningstider`,
      en: `Scheduling times`,
      de: `Planungszeiten`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleCreator.index.endTime': translations({
      sv: `Sluttid`,
      en: `End Time`,
      de: `Endzeit`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.removeScheduleInstance':
      translations({
        sv: `Ta bort tid`,
        en: `Remove time`,
        de: `Zeit mitnehmen`,
      }),
    'components.dashboard.properties.employeesView.userNotSaved': translations({
      sv: `Tyvärr kunde användaren inte sparas`,
      en: `User could not be saved`,
      de: `Leider konnte der Benutzer nicht gespeichert werden.`,
    }),
    'components.dashboard.properties.form.badAddressDescription': translations({
      sv: `Kunde inte återställa koordinaterna! =(`,
      en: `Could not restore coordinates! = (`,
      de: `Koordinaten konnten nicht wiederhergestellt werden! = (`,
    }),
    'components.dashboard.properties.form.holderCity': translations({
      sv: `Ort`,
      en: `City`,
      de: `Stadt`,
    }),
    'components.dashboard.subscription.edit.fetchError': translations({
      sv: `Något gick fel.. Försök igen senare. 😢`,
      en: `Something went wrong .. Try again later. 😢`,
      de: `Etwas ist schief gelaufen. Versuchen Sie es später erneut. 😢`,
    }),
    'components.loginPage.headerFive': translations({
      sv: `VILL DU BLI AKTÖR?`,
      en: `Be a member?`,
      de: `Bereit loszulegen?`,
    }),
    'components.loginPage.promptOne': translations({
      sv: `Du måste ange din epostadress`,
      en: `You must enter your email address`,
      de: `Du musst deine E-Mail-Adresse eingeben`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleItem.every': translations({
      sv: `Varje`,
      en: `Each`,
      de: `jeder`,
    }),
    'components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.willGenerateConflicts': translations(
      {
        sv: `Tas undantaget bort kommer konflikter skapas`,
        en: `If the exception is removed, conflicts will be created`,
        de: `Wenn die Ausnahme entfernt wird, werden Konflikte erstellt`,
      },
    ),
    'components.dashboard.properties.employeesView.oops': translations({
      sv: `Ops`,
      en: `Oops`,
      de: `Ups`,
    }),
    'components.dashboard.properties.form.pictureView': translations({
      sv: `Bilden kommer att vara synlig utåt, välj därför en beskrivande och tilltalade bild.`,
      en: `The image will be show to customers.`,
      de: `Das Bild wird den Kunden angezeigt.`,
    }),
    'components.dashboard.properties.details.infoPicture': translations({
      sv: `Informationen och bilden som visas på den här sidan visas också på`,
      en: `This information and image is also shown at`,
      de: `Die Informationen und das Bild dieser Seite sind für alle Kunden von`,
    }),
    'components.dashboard.properties.employeeSchedule.index.conflictingInstances': translations({
      sv: `Konflikt`,
      en: `Conflict`,
      de: `Konflikt`,
    }),
    'Property personnel list': translations({
      sv: `Anläggningens personallista`,
      en: `Property personnel list`,
      de: `Personalliste des Anlage`,
    }),
    'components.resetPasswordView.buttonSaveNewPassword': translations({
      sv: `Spara lösenordet`,
      en: `Save password`,
      de: `Speichern Passwort`,
    }),
    'components.customer.activities.propertyDetailsView.CategoryTitle': translations({
      sv: `Välj en kategori`,
      en: `Choose a Category`,
      de: `Kategorie auswählen`,
    }),
    'components.customer.activities.propertyDetailsView.AktivityTitleOne': translations({
      sv: `Välj en aktivitet`,
      en: `Select activity`,
      de: `Aktivität auswählen`,
    }),
    'Select activities:': translations({
      sv: `Välj aktiviteter`,
      en: `Select activities:`,
      de: `Aktivitäten auswählen`,
    }),
    'You are now booked!': translations({
      sv: `Du är nu inbokad på passet!`,
      en: `You are now booked!`,
      de: `Du bist jetzt gebucht!`,
    }),
    'Your answer has been registered...': translations({
      sv: `Ditt svar är nu registrerat, hoppas du kan jobba nästa gång.`,
      en: `Your answer has been registered, hope you can work next time.`,
      de: `Ihre Antwort ist jetzt registriert, ich hoffe, Sie können das nächste Mal arbeiten.`,
    }),
    'components.resetPasswordView.messgageSix': translations({
      sv: `Det nya lösenordet är nu sparat, gå till`,
      en: `Your new password is saved, go to`,
      de: `Das neue Passwort ist jetzt gespeichert, gehe zu`,
    }),
    'components.customer.activities.propertyDetailsView.AktivityTitleTwo': translations({
      sv: `Välj personal`,
      en: `Choose Employee`,
      de: `Mitarbeiter auswählen`,
    }),
    'Glad you want to work...': translations({
      sv: `Kul att du vill jobba men passet är tyvärr redan fyllt, tacka gärna ja till ett annat pass! 🙏`,
      en: `Glad you want to work but this job is unfortunately already filled, Hope you get the next one 🙏`,
      de: `Es freut mich, dass du den Termin übernehmen wolltest, aber dieser Job ist leider schon besetzt. Ich hoffe du bekommst den nächsten! 🙏`,
    }),
    'components.pageNotFound.pageNotFoundExplaination': translations({
      sv: `Du kan ha skrivit fel adress i webbläsaren, eller så har något funnits här förut i forna dar.`,
      en: `You may have typed a wrong address in your browser, or something has existed here before in ancient history.`,
      de: `Möglicherweise hast du in deinem Browser eine falsche Adresse eingegeben, oder etwas existierte schon früher hier.`,
    }),
    'components.customer.custom.ekerum.goBack': translations({
      sv: `Gå tillbaka`,
      en: `Go back`,
      de: `Geh zurück`,
    }),
    'components.resetPasswordView.messageThree': translations({
      sv: `Skapa ett nytt lösenord`,
      en: `Create new password`,
      de: `Erstelle ein neues Passwort.`,
    }),
    'components.traffpunkt.template.headerAssocitation': translations({
      sv: `Föreningar`,
      en: `Association`,
      de: `Organisation`,
    }),
    'More settings': translations({
      sv: `Fler inställningar`,
      en: `More settings`,
      de: `Mehr Einstellungen`,
    }),
    'to the customer': translations({
      sv: `till kunden`,
      en: `to the customer`,
      de: `an den Kunden`,
    }),
    'components.resetPasswordView.alertThree': translations({
      sv: `Tyvärr gick något fel. Om du tror att det beror på adventurehero, kontakta gärna supporten`,
      en: `Sorry, something went wrong. Please contact the support.`,
      de: `Sorry, etwas ist schiefgelaufen.Bitte wende dich an den Support.`,
    }),
    'components.resetPasswordView.messageFive': translations({
      sv: `Lösenord sparat`,
      en: `Password saved`,
      de: `Passwort gespeichert`,
    }),
    'components.resetPasswordView.messageOne': translations({
      sv: `Hej, du måste skapa ett nytt lösenord`,
      en: `You must enter a new password.`,
      de: `Bitte erstelle ein neues Passwort.`,
    }),
    'components.traffpunkt.template.headerVolonteer': translations({
      sv: `Frivilligcentralen`,
      en: `Volunteer Central`,
      de: 'Freiwilligenzentrale',
    }),
    'It has been refunded': translations({
      sv: `Det har återbetalts`,
      en: `It has been refunded`,
      de: `Es wurde zurückerstattet`,
    }),
    all: translations({
      sv: `Alla`,
      en: `All`,
      de: `Alle`,
    }),
    'components.customer.footer.itemGDPRLink': translations({
      sv: `Hantering av personuppgifter`,
      en: `Personal data handling`,
      de: `Datenschützerklärung`,
    }),
    'components.resetPasswordView.alertFour': translations({
      sv: `Tekniskt fel`,
      en: `Technical error`,
      de: `Technischer Fehler`,
    }),
    'components.resetPasswordView.messageTwo': translations({
      sv: `Innan du kan logga in i igen måste du skapa ett nytt lösenord. Anledning är att vi vill öka säkerheten på ditt konto, välj gärna ett så säkert lösenord som möjligt. Med vänliga hälsningar, utvecklingsteamet 🙏🏼`,
      en: `Before you can sign in again, you will need to create a new password. Please choose the strongest possible password to increase the security of your account.
Sincerely, the development team 🙏`,
      de: `Bevor du dich erneut anmelden kannst, musst du ein neues Kennwort erstellen. Bitte wähle ein möglichst sicheres Passwort, um die Sicherheit deines Kontos zu erhöhen. Mit freundlichen Grüßen, das Entwicklungsteam 🙏`,
    }),
    'components.resetPasswordView.repeatPassword': translations({
      sv: `Upprepa lösenord`,
      en: `Repeat password`,
      de: `Passwort wiederholen`,
    }),
    'Sorry there was an error, please try again.': translations({
      sv: `Tyvärr har ett fel har uppstått, var god försök igen.`,
      en: `Sorry there was an error, please try again.`,
      de: `Entschuldigung, es ist ein Fehler aufgetreten.`,
    }),
    'Remember to charge for the increased cost': translations({
      sv: `Kom ihåg att ta betalt för den ökade kostnaden`,
      en: `Remember to charge for the increased cost`,
      de: `Vergessen Sie nicht, die erhöhten Kosten in Rechnung zu stellen`,
    }),
    '(Optional)': translations({
      sv: `(Valfritt)`,
      en: `(Optional)`,
      de: `(Optional)`,
    }),
    'components.resetPasswordView.newPassword': translations({
      sv: `Nytt lösenord`,
      en: `New password`,
      de: `Neues Passwort`,
    }),
    'components.pageNotFound.pageNotFoundHeader': translations({
      sv: `Sidan du sökte hittades inte`,
      en: `The page you were looking for could not be found`,
      de: `Die von dir gesuchte Seite konnte nicht gefunden werden.`,
    }),
    'components.resetPasswordView.messageFour': translations({
      sv: `Det enda du behöver göra nu är att fylla i ditt nya lösenord och tycka på spara, sen blir du inloggad.`,
      en: `Now just enter your new password and save, then you will automatically be logged in.`,
      de: `Jetzt nur noch dein neues Passwort eingeben und speichern, dann wirst du automatisch eingeloggt.`,
    }),
    'components.traffpunkt.helperFunctions.applicant': translations({
      sv: `Ansökande`,
      en: `Applicant`,
      de: `Interessent`,
    }),
    'components.resetPasswordView.alertTwo': translations({
      sv: `Ogiltig länk`,
      en: `Link not available`,
      de: `Ungültiger Link`,
    }),
    'components.traffpunkt.template.headerFind': translations({
      sv: `Hitta aktiviteter`,
      en: `Find activities`,
      de: `Anwendungen finden`,
    }),
    'components.customer.footer.itemGDPR': translations({
      sv: `GDPR`,
      en: `GDPR`,
      de: `GDPR`,
    }),
    'components.resetPasswordView.messgageSixEnd': translations({
      sv: `för att logga in i systemet`,
      en: `to log in to the system`,
      de: `sich in das System einloggen`,
    }),
    'components.dashboard.widgets.classicWidgetSelectionView.startInGiftCards': translations({
      sv: `Börja med att visa presentkort?`,
      en: `Start by showing gift cards?`,
      de: `Beginnen Sie mit dem Vorzeigen von Geschenkkarten?`,
    }),
    'components.dashboard.offers.index.listOfOffers': translations({
      sv: `Lista erbjudanden`,
      en: `List of offers`,
      de: 'Angebote auflisten',
    }),
    inventories: translations({
      sv: `Inventarier`,
      en: `Inventories`,
      de: `Vorräte`,
    }),
    'Reduction count': translations({
      sv: `Avräkning`,
      en: `Reduction count`,
      de: 'Reduktionsanzahl',
    }),
    'Per booking': translations({
      sv: `Per bokning`,
      en: `Per booking`,
      de: 'Pro Buchung',
    }),
    'Per visitor': translations({
      sv: `Per besökare`,
      en: `Per visitor`,
      de: 'Pro Besucher',
    }),
    'Classic Widget (only for platform owner)': translations({
      sv: `Klassisk widget (endast för superadmin)`,
      en: `Classic Widget (only for platform owner)`,
      de: 'Klassisches Widget (only for platform owner)',
    }),
    'Widget for your property': translations({
      sv: `Widget för din anläggning`,
      en: `Widget for your property`,
      de: 'Widget für Ihre Einrichtung',
    }),
    'Widget showcasing your offers': translations({
      sv: `Widget som visar dina erbjudanen`,
      en: `Widget showcasing your offers`,
      de: 'Widget, das Ihre Angebote anzeigt',
    }),
    'valid for': translations({
      sv: `gäller för`,
      en: `valid for`,
      de: 'Gültig für',
    }),
    'components.dashboard.widgets.widgetsSection.createWidget': translations({
      sv: `Lägg till bokningsvyn på din egna hemsida`,
      en: `Add the booking view to your website`,
      de: `Online-Terminplan deiner Webseite hinzufügen`,
    }),
    'components.dashboard.widgets.widgetsSection.createWidgetDescription': translations({
      sv: `Här kan du skapa 'widgets' för att integrera AdventureHero-funktioner på din egna hemsida...`,
      en: `Here you can create 'widgets' to integrate Adventure Hero features to your own webpage.`,
      de: 'Hier können Sie Widgets erstellen, um Adventure Hero-Funktionen in Ihre eigene Webseite zu integrieren.',
    }),
    'components.dashboard.giftCards.index.handleGiftCards': translations({
      sv: `Hantera dina presentkort`,
      en: `Handle your gift cards`,
      de: 'Behandeln Sie Ihre Geschenkkarten',
    }),
    Widgets: translations({
      sv: `Widgets`,
      en: `Widgets`,
      de: `Widgets`,
    }),
    'components.dashboard.properties.details.createBooking': translations({
      sv: `Skapa bokning`,
      en: `Create booking`,
      de: 'Buchung erstellen',
    }),
    'components.dashboard.offers.index.createOffer': translations({
      sv: `Skapa ett nytt erbjudande`,
      en: `Create a new offer`,
      de: 'Neues Angebot erstellen',
    }),
    'components.dashboard.bookings.index.handleBookings': translations({
      sv: `Hantera bokningar`,
      en: `Handle bookings`,
      de: 'Buchungen verwalten',
    }),
    'components.dashboard.widgets.classicWidgetSelectionView.copyToClipboard': translations({
      sv: `Kopiera kod till urklipp`,
      en: `Copy code to clipboard`,
      de: `Kopieren Sie den Code`,
    }),
    Offers: translations({
      sv: `Erbjudanden`,
      en: `Offers`,
      de: 'Angeboten',
    }),
    'components.customer.footer.headerFlags': translations({
      sv: `BYT SPRÅK`,
      en: `SWITCH LANGUAGE`,
      de: `SPRACHE WECHSELN`,
    }),
    'VAT-Rate': translations({
      sv: `Momssats`,
      en: `VAT-Rate`,
      de: 'MwSt.-Satz',
    }),
    'You are already booked for this job': translations({
      sv: `Du är redan inbokad på passet`,
      en: `You are already booked for this job`,
      de: `Du bist bereits für diesen Termin gebucht`,
    }),
    'dashboard-widget-showcase-includedActivityType': translations({
      sv: `inkludera samtidigt dessa erbjudanden...`,
      en: `also include these offers...`,
      de: `beinhaltet auch...`,
    }),
    'Only update the offer': translations({
      sv: `Uppdatera endast erbjudandet`,
      en: `Only update the offer`,
      de: 'Nur das Angebot aktualisieren',
    }),
    'components.dashboard.widgets.modernSelectionView.contentHeader': translations({
      sv: `Tryck för att kopiera`,
      en: `Click to copy`,
      de: 'Zum Kopieren anklicken',
    }),
    'A picture of a document that confirms name...': translations({
      sv: `En bild på ett dokument som bekräftar namn, adress och organisationsnummer.`,
      en: `A picture of a document that confirms name, address and organization number.`,
      de: 'Ein Bild eines Dokuments, das Name, Adresse und Organisationsnummer enthält.',
    }),
    'Latest time for free cancellation': translations({
      sv: `Senast tid för fri avbokning`,
      en: `Latest time for free cancellation`,
      de: `Spätester Zeitpunkt für kostenlose Stornierung`,
    }),
    People: translations({
      sv: `Personer`,
      en: `People`,
      de: 'Personen',
    }),
    'missing title': translations({
      sv: `saknar titel`,
      en: `missing title`,
      de: 'fehlender Titel',
    }),
    'For example an invoice from your electricity company...': translations({
      sv: `Exempelvis en faktura från ditt elbolag som bekräftar addressen som du har angett.`,
      en: `For example an invoice from your electricity company that confirms the address that you've entered.`,
      de: 'Zum Beispiel eine Rechnung Ihres Stromversorgers, die die von Ihnen eingegebene Adresse bestätigt',
    }),
    'components.dashboard.home.segments.helpSegment.descriptionEight': translations({
      sv: `Under WIDGET i sidomenyn hämtar du kod för att lägga in bokning och presentkortsköp från din hemsida för respektive anläggning.`,
      en: `From WIDGET in the sidebar you can find the code you need to add to your website in order for it to handle bookings and giftcard purchases.`,
      de: 'Im WIDGET in der Seitenmenü finden Sie den Code, den Sie zu Ihrer Website hinzufügen müssen, damit sie Buchungen und Geschenkkartenkäufe verarbeiten kann.',
    }),
    'Please double check the information and try again': translations({
      sv: `Var god och korrigera angivet innehåll och försök igen`,
      en: `Please double check the information and try again`,
      de: 'Bitte korrigieren Sie den Inhalt und versuchen Sie es erneut',
    }),
    'Postal code': translations({
      sv: `Postnummer`,
      en: `Postal code`,
      de: 'PLZ',
    }),
    'The document has expired...': translations({
      sv: `Dokumentets utgångsdatum har passerats. Var god ladda upp ett giltigt dokument.`,
      en: `The document has expired. Please upload a valid document.`,
      de: 'Das Dokument ist abgelaufen. Bitte laden Sie ein gültiges Dokument hoch.',
    }),
    'The document is not valid in Sweden...': translations({
      sv: `Dokumentet är inte godkänt i det angivna landet. Var god ladda upp ett giltigt dokument.`,
      en: `The document is not valid in Sweden. Please upload a valid document.`,
      de: 'Das Dokument ist in Schweden nicht gültig. Bitte laden Sie ein gültiges Dokument hoch.',
    }),
    'Name of account holder': translations({
      sv: `Kontoinnehavarens namn`,
      en: `Name of account holder`,
      de: 'Der Name des Kontoinhabers',
    }),
    Owner: translations({
      sv: `Ägare`,
      en: `Owner`,
      de: 'Inhaber',
    }),
    'Street address': translations({
      sv: `Gata`,
      en: `Street address`,
      de: 'Straßenadresse',
    }),
    "Adventure Hero's terms of service": translations({
      sv: `Adventure Hero's villkor`,
      en: `Adventure Hero's terms of service`,
      de: 'Nutzungsbedingungen von Adventure Hero',
    }),
    'Please double check the information...': translations({
      sv: `Var god och korrigera angivet innehåll och försök igen. Felkod:`,
      en: `Please double check the information and try again. Error code:`,
      de: 'Bitte überprüfen Sie die Informationen und versuchen Sie es erneut. Fehlercode:',
    }),
    'Please upload your company registration document': translations({
      sv: `Var god och ladda upp ett registreringsbevis för din organisation`,
      en: `Please upload your company registration document`,
      de: 'Bitte laden Sie eine Registrierungsbescheinigung für Ihre Organisation hoch',
    }),
    'Your passport, drivers license or other ID...': translations({
      sv: `Ditt pass, körkort eller liknande. Dokumenten skickas direkt och med säkerhet till Stripe.`,
      en: `Your passport, drivers license or other ID. documents are securely sent to Stripe.`,
      de: 'Ihr Reisepass, Führerschein oder ein anderer Ausweis Dokumente werden sicher an Stripe gesendet.',
    }),
    'Please register a representative and try again': translations({
      sv: `Du måste ha en registrerad representant`,
      en: `Please register a representative and try again`,
      de: 'Bitte registrieren Sie einen Vertreter und versuchen Sie es erneut',
    }),
    'Your account is activated!': translations({
      sv: `Ditt konto är aktiverat!`,
      en: `Your account is activated!`,
      de: 'Ihr Konto ist aktiviert!',
    }),
    'Company registration document': translations({
      sv: `Organisationens registreringsbevis`,
      en: `Company registration document`,
      de: 'Organisationens Registrierungsbevis',
    }),
    'Upload a new document': translations({
      sv: `Ladda upp igen`,
      en: `Upload a new document`,
      de: 'Laden Sie ein neues Dokument hoch',
    }),
    'Identification document': translations({
      sv: `Identifikationsdokument`,
      en: `Identification document`,
      de: 'Ausweis',
    }),
    Representative: translations({
      sv: `Representant`,
      en: `Representative`,
      de: 'Vertreterin',
    }),
    'Additional information': translations({
      sv: `Extra information`,
      en: `Additional information`,
      de: 'Zusätzliche Information',
    }),
    'Business profile': translations({
      sv: `Företagsprofilen`,
      en: `Business profile`,
      de: 'Business profile',
    }),
    Company: translations({
      sv: `Företaget`,
      en: `Company`,
      de: 'Company',
    }),
    Directors: translations({
      sv: `Direktörer`,
      en: `Directors`,
      de: 'Directors',
    }),
    Executives: translations({
      sv: `Verkställande direktörer (VD)`,
      en: `Executives`,
      de: 'Executives',
    }),
    'External bank account': translations({
      sv: `Externt bankkonto`,
      en: `External bank account`,
      de: 'External bank account',
    }),
    Owners: translations({
      sv: `Ägare`,
      en: `Owners`,
      de: 'Owners',
    }),
    'Please enter information about the following': translations({
      sv: `Du behöver ange information om följande`,
      en: `Please enter information about the following`,
      de: 'Please enter information about the following',
    }),
    'Terms of Service acceptance': translations({
      sv: `Godkännande av servicevillkor`,
      en: `Terms of Service acceptance`,
      de: 'Terms of Service acceptance',
    }),
    'Verification documents': translations({
      sv: 'Verifikationsdokument',
      en: 'Verification documents',
      de: 'Verification documents',
    }),
    'Personal data': translations({
      sv: 'Personuppgifter',
      en: 'Personal data',
      de: 'Personal data',
    }),
    'Update the organization number': translations({
      sv: `Uppdatera organisationsnumret`,
      en: `Update the organization number`,
      de: 'Aktualisieren Sie die Organisationsnummer',
    }),
    'Test the Widget': translations({
      sv: `Testa Widgeten`,
      en: `Test the Widget`,
      de: 'Testen Sie das Widget',
    }),
    'Update offer and activities': translations({
      sv: `Uppdatera erbjudandet och aktiviteterna`,
      en: `Update offer and activities`,
      de: 'Aktualisieren Sie das Angebot und die Aktivitäten',
    }),
    'Loading content': translations({
      sv: `Laddar innehåll`,
      en: `Loading content`,
      de: 'Inhalt wird geladen',
    }),
    'I agree that no more owners, directors or...': translations({
      sv: `Jag godkänner att inga fler ägare, styrelseledamöter eller verkställande direktörer behöver registreras och att angiven information stämmer.`,
      en: `I agree that no more owners, directors or executives need to be registered and all of the entered information is correct.`,
      de: 'Ich stimme zu, dass keine weiteren Eigentümer, Direktoren oder Vorsitzender registriert werden müssen und alle eingegebenen Informationen korrekt sind.',
    }),
    'The representative also has to be...': translations({
      sv: `Representaten måste vara en VD eller en ägare.`,
      en: `The representative also has to be either the CEO or an owner.`,
      de: 'Der Vertreter muss auch entweder der CEO oder ein Eigentümer sein.',
    }),
    'steps left to activate your account': translations({
      sv: `steg kvar för att aktivera ditt konto.`,
      en: `steps left to activate your account.`,
      de: 'Schritte, um Ihr Konto zu aktivieren.',
    }),
    Consumed: translations({
      sv: `Förbrukad`,
      en: `Consumed`,
      de: 'Verbraucht',
    }),
    'components.dashboard.widgets.modernSelectionView.widgetElement': translations({
      sv: `Placera sedan koden nedan där du vill placera widgeten på sidan`,
      en: `Then place the code below into the desired place on your page`,
      de: 'Dann platziere den untenstehenden Code an der gewünschten Stelle auf deiner Seite`',
    }),
    '0 or leave empty for no limit': translations({
      sv: `0 eller tom för ingen begränsning`,
      en: `0 or leave empty for no limit`,
      de: '0 oder leer lassen für kein Limit',
    }),
    'Company name': translations({
      sv: `Företagsnamn`,
      en: `Company name`,
      de: 'Name der Firma',
    }),
    'The information that your have entered does not match...': translations({
      sv: `Informationen du angett stämmer inte överens med informationen i dokumentet. Var god se över informationen och försök igen.`,
      en: `The information that your have entered does not match the information in the uploaded document. Please check the information and try again.`,
      de: 'Die von Ihnen eingegebenen Informationen stimmen nicht mit den Informationen im hochgeladenen Dokument überein. Bitte überprüfen Sie die Informationen und versuchen Sie es erneut.',
    }),
    'The document could not be read by Stripe...': translations({
      sv: `Dokumentet du försökt ladda upp uppfyller inte kraven från Stripe. Var god ladda upp ett dokument i färg, där all information är synlig och bilden är orienterad rätt.`,
      en: `The document could not be read by Stripe. Please upload a document in color, where all information is visible and where the document is correctly oriented.`,
      de: 'Das Dokument konnte von Stripe nicht gelesen werden. Bitte laden Sie ein Dokument in Farbe hoch, in dem alle Informationen sichtbar sind und das Dokument richtig ausgerichtet ist.',
    }),
    'Bank account': translations({
      sv: `Bankkonto`,
      en: `Bank account`,
      de: 'Bankkonto',
    }),
    'All owners and board members must be registered...': translations({
      sv: `Samtliga ägare och styrelseledamöter behöver anges. Representaten måste även vara antingen ägare eller VD.`,
      en: `All owners and board members must be registered. The representative also has to be either the CEO or an owner.`,
      de: 'Alle Eigentümer und Vorstandsmitglieder müssen registriert sein. Der Vertreter muss auch entweder der CEO oder ein Eigentümer sein.',
    }),
    'Confirming identification': translations({
      sv: `Bekräftande identifikation`,
      en: `Confirming identification`,
      de: 'Bestätigung der Identifizierung',
    }),
    'More information is needed in order...': translations({
      sv: `Ditt konto kräver mer information för att kunna hantera betalningar.`,
      en: `More information is needed in order for your account to be able to process payments.`,
      de: 'Damit Ihr Konto Zahlungen verarbeiten kann, sind weitere Informationen erforderlich.',
    }),
    'By activating your account you agree...': translations({
      sv: `Genom att aktivera ditt konto på Adventure Hero godkänner du våra och Stripes villkor.`,
      en: `By activating your account you agree to the terms of service from both Adventure Hero and Stripe.`,
      de: 'Durch die Aktivierung Ihres Kontos stimmen Sie den Nutzungsbedingungen von Adventure Hero und Stripe zu.',
    }),
    "Stripe's terms of service": translations({
      sv: `Stripes villkor`,
      en: `Stripe's terms of service`,
      de: 'Nutzungsbedingungen von Stripe',
    }),
    'Terms of service': translations({
      sv: `Användarvillkor`,
      en: `Terms of service`,
      de: 'Nutzungsbedingungen',
    }),
    'Proof of address': translations({
      sv: `Bevis på adress`,
      en: `Proof of address`,
      de: 'Nachweis der Adresse',
    }),
    'Remove address': translations({
      sv: `Ta bort adressen`,
      en: `Remove address`,
      de: 'Adresse entfernen',
    }),
    Expired: translations({
      sv: `Förfallen`,
      en: `Expired`,
      de: 'Abgelaufen',
    }),
    'components.dashboard.widgets.modernSelectionView.headElement': translations({
      sv: `Placera följande kod i sidans huvud (head) för att göra widgeten tillgänglig`,
      en: `Place the following code our your page head tag to fetch the widget`,
      de: 'Platzieren Sie den folgenden Code in unserem your page head-Tag, um das Widget abzurufen`',
    }),
    'Max number of bookings': translations({
      sv: `Max antal bokningar`,
      en: `Max number of bookings`,
      de: 'Maximale Anzahl an Buchungen',
    }),
    'The name on the document does not match the name...': translations({
      sv: `Namnet på dokumentet stämmer inte överens med namnet angett i formuläret. Var god se över informationen och försök igen.`,
      en: `The name on the document does not match the name entered in the form. Please check the information and try again.`,
      de: 'Der Name auf dem Dokument stimmt nicht mit dem im Formular eingegebenen Namen überein. Bitte überprüfen Sie die Informationen und versuchen Sie es erneut.',
    }),
    Identification: translations({
      sv: `Identifikation`,
      en: `Identification`,
      de: 'Identifizierung',
    }),
    'Verification code': translations({
      sv: `Verifikationskod`,
      en: `Verification code`,
      de: 'Verifizierungs-Schlüssel',
    }),
    'No thanks': translations({
      sv: `Nej tack`,
      en: `No thanks`,
      de: 'Nein danke',
    }),
    Accept: translations({
      sv: `Godkänn`,
      en: `Accept`,
      de: 'Akzeptieren',
    }),
    Deny: translations({
      sv: `Avböj`,
      en: `Deny`,
      de: 'Verweigern',
    }),
    'components.dashboard.giftCards.form.showOrganization': translations({
      sv: `Visa organisationsnamn`,
      en: `Show name of organization`,
      de: 'Name der Organisation anzeigen',
    }),
    'Next step': translations({
      sv: `Nästa steg`,
      en: `Next step`,
      de: 'Nächster Schritt',
    }),
    'The request to collect information...': translations({
      sv: `Efterfrågan om uppgifter kommer ifrån Stripe och krävs av lagliga skäl för att kunna aktivera ditt konto med online-betalningar. Uppgifterna skickas direkt till Stripe och kommer inte att visas på Adventure Heros plattform.`,
      en: `The request to collect information comes from Stripe and is needed for legal reasons in order to activate your account for online payments. All information is sent directly to Stripe and will not be presented on the Adventure Hero plattform.`,
      de: 'Die Anfrage zum Sammeln von Informationen kommt von Stripe und ist aus rechtlichen Gründen erforderlich, um Ihr Konto für Online-Zahlungen zu aktivieren. Alle Informationen werden direkt an Stripe gesendet und nicht auf der Adventure Hero Plattform präsentiert.',
    }),
    Roles: translations({
      sv: `Roller`,
      en: `Roles`,
      de: 'Rollen',
    }),
    'Working title': translations({
      sv: `Arbetstitel`,
      en: `Working title`,
      de: 'Berufsbezeichnung',
    }),
    'Unknown error. Please contact Adventure Hero': translations({
      sv: `Okänt fel. Kontakta AdventureHero.`,
      en: `Unknown error. Please contact Adventure Hero.`,
      de: 'Unbekannter Fehler. Bitte kontaktieren Sie Adventure Hero.',
    }),
    'If the entered information is changed...': translations({
      sv: `Om informationen i formuläret ändras så måste även uppladdat dokument laddas upp på nytt för att bekräfta den nya informationen`,
      en: `If the entered information is changed you have to reupload the document to verify the new information.`,
      de: 'Wenn sich die eingegebenen Informationen ändern, müssen Sie das Dokument erneut hochladen, um die neuen Informationen zu überprüfen.',
    }),
    'Do you want to share your location?': translations({
      sv: `Vill du dela din nuvarande position? Det ger dig en bättre upplevelse av kartfunktionen.`,
      en: `Do you want to share your location? It gives you a better experience of the map function`,
      de: 'Möchten Sie Ihren Standort teilen? Es gibt Ihnen eine bessere Erfahrung mit der Kartenfunktion.',
    }),
    'components.dashboard.employees.form.note': translations({
      sv: `Anteckning`,
      en: `Note`,
      de: 'Hinweis',
    }),
    'Could not verify the document...': translations({
      sv: `Kunde inte verifiera dokumentet. Var god prova med ett annat dokument eller uppdatera informationen i formuläret.`,
      en: `Could not verify the document. Please try with another document or update the information in the form.`,
      de: 'Das Dokument konnte nicht überprüft werden. Bitte versuchen Sie es mit einem anderen Dokument oder aktualisieren Sie die Informationen im Formular.',
    }),
    'Getting ready': translations({
      sv: `Förbereder`,
      en: `Getting ready`,
      de: 'Vorbereitung',
    }),
    Person: translations({
      sv: `Person`,
      en: `Person`,
      de: 'Person',
    }),
    'Verify your account': translations({
      sv: `Verifiera ditt konto`,
      en: `Verify your account`,
      de: 'Bestätige dein Konto',
    }),
    'is booked and': translations({
      sv: `bokade och`,
      en: `is booked and`,
      de: 'ist gebucht und',
    }),
    'components.admin.organizations.tableRow.no-agreement': translations({
      sv: `Inget Avtal`,
      en: `No Agreement`,
      de: 'Keine Einigung',
    }),
    'Processing of information': translations({
      sv: `Uppgiftshantering`,
      en: `Processing of information`,
      de: 'Verarbeitung von Informationen',
    }),
    'Organization number': translations({
      sv: `Organisationsnummer`,
      en: `Organization number`,
      de: 'Organisationsnummer',
    }),
    'The document has beed modified in some way...': translations({
      sv: `Dokumentet har blivit modifierat på något sätt. Kontakta AdventureHero.`,
      en: `The document has beed modified in some way. Please contanct Adventure Hero.`,
      de: 'Das Dokument wurde in irgendeiner Weise modifiziert. Bitte kontaktieren Sie Adventure Hero.',
    }),
    'Phone number': translations({
      sv: `Telefonnummer`,
      en: `Phone number`,
      de: 'Telefonnummer',
    }),
    'Information is missing': translations({
      sv: `Information saknas`,
      en: `Information is missing`,
      de: 'Informationen fehlen',
    }),
    'missing phone number': translations({
      sv: `saknar telefonnummer`,
      en: `missing phone number`,
      de: 'fehlende Telefonnummer',
    }),
    'components.calendar.sidebar.showBookingsOnly': translations({
      sv: `Visa endast bokade aktiviteter`,
      en: `Only show booked activities`,
      de: 'Nur gebuchte Aktivitäten anzeigen',
    }),
    'Only show booking requests': translations({
      sv: `Visa endast bokningsförfrågningar`,
      en: `Only show booking requests`,
      de: 'Nur Buchungsanfragen anzeigen',
    }),
    'Website or social profile': translations({
      sv: `Hemsida eller social profil`,
      en: `Website or social profile`,
      de: 'Website oder soziales Profil',
    }),
    'Uploaded document': translations({
      sv: `Dokument uppladdat`,
      en: `Uploaded document`,
      de: 'Dokument hochgeladen',
    }),
    'Please register an owner and try again': translations({
      sv: `Du måste ha en registrerad ägare`,
      en: `Please register an owner and try again`,
      de: 'Bitte registrieren Sie einen Besitzer und versuchen Sie es erneut.',
    }),
    'Edit message to staff': translations({
      sv: `Redigera meddelande till personalen`,
      en: `Edit message to staff`,
      de: `Nachricht an Mitarbeiter bearbeiten`,
    }),
    'offers.details.messageToEmployees': translations({
      sv: `Meddelande till personalen`,
      en: `Message to the personnel`,
      de: `Nachricht an das Personal`,
    }),
    'components.admin.organizations.tableRow.requirements': translations({
      sv: `Krav`,
      en: `Requirements`,
      de: 'Anforderungen',
    }),
    'An unexpected error has occurred': translations({
      sv: `Ett ovanäntat fel har uppstått`,
      en: `An unexpected error has occurred`,
      de: 'Ein unerwarteter Fehler ist aufgetreten',
    }),
    'Date of birth': translations({
      sv: `Födelsedatum`,
      en: `Date of birth`,
      de: 'Geburtsdatum',
    }),
    'All persons have to have valid documents': translations({
      sv: `Samtliga personer måste ha valida verifikationsdokument.`,
      en: `All persons have to have valid documents`,
      de: 'Alle Personen müssen gültige Dokumente vorlegen',
    }),
    'I accept the terms of service': translations({
      sv: `Jag godkänner villkoren`,
      en: `I accept the terms of service`,
      de: 'Ich akzeptiere die Servicebedingungen',
    }),
    'Add an address': translations({
      sv: `Lägg till en adress`,
      en: `Add an address`,
      de: 'Füge eine Adresse hinzu',
    }),
    'Add notes to the booking': translations({
      sv: `Lägg till anteckningar`,
      en: `Add notes to the booking`,
      de: 'Notizen hinzufügen',
    }),
    'Remove notes': translations({
      sv: `Ta bort anteckningar`,
      en: `Remove notes`,
      de: 'Notizen löschen',
    }),
    'Company information': translations({
      sv: `Företagsinformation`,
      en: `Company information`,
      de: 'Geschäftsinformationen',
    }),
    "The person's address": translations({
      sv: `Personens adress`,
      en: `The person's address`,
      de: 'Die Adresse der Person',
    }),
    'Fetching account information...': translations({
      sv: `Hämtar kontoinformation...`,
      en: `Fetching account information...`,
      de: 'Kontoinformationen werden abgerufen...',
    }),
    'components.weather.weatherContext.weatherHeader': translations({
      sv: `Väderprognos`,
      en: `Weather forcast`,
      de: 'Wettervorhersage',
    }),
    'Owner Share (%)': translations({
      sv: `Ägarskap (procent)`,
      en: `Owner Share (%)`,
      de: 'Eigentümeranteil (%)',
    }),
    'Information was not accepted': translations({
      sv: `Information godtogs inte`,
      en: `Information was not accepted`,
      de: 'Informationen wurden nicht akzeptiert',
    }),
    'IBAN number': translations({
      sv: `IBAN-nummer`,
      en: `IBAN number`,
      de: 'IBAN-Nummer',
    }),
    CEO: translations({
      sv: `Verkställande direktör (VD)`,
      en: `CEO`,
      de: 'Geschäftsführer',
    }),
    'Personal identification': translations({
      sv: `Personlig identifikation`,
      en: `Personal identification`,
      de: 'Personenidentifikation',
    }),
    'Information is missing or has to be updated': translations({
      sv: `Information saknas eller behöver korrigeras.`,
      en: `Information is missing or has to be updated.`,
      de: 'Informationen fehlen oder müssen aktualisiert werden.',
    }),
    'missing email address': translations({
      sv: `saknar emailadress`,
      en: `missing email address`,
      de: 'fehlende E-Mail-Adresse',
    }),
    'In order to activate your account you first need to verify your email...': translations({
      sv: `För att aktivera ditt konto måste du först verifiera din email. Ange verifieringskoden i fältet nedan, koden hittar du i välkomstmailet.`,
      en: `In order to activate your account you first need to verify your email. Enter the verification code sent to your email in the field below.`,
      de: 'Um Ihr Konto zu aktivieren, müssen Sie zunächst Ihre E-Mail-Adresse bestätigen. Geben Sie den Bestätigungscode, der an Ihre E-Mail gesendet wurde, in das Feld unten ein.',
    }),
    'components.giftCardPreview.nameOrganization': translations({
      sv: `Presentkortet gäller hos `,
      en: `Giftcard is valid at`,
      de: 'Geschenkkarte ist gültig am',
    }),
    Valid: translations({
      sv: `Giltig`,
      en: `Valid`,
      de: 'Gültig',
    }),
    'The docuemnt is missing important information...': translations({
      sv: `Dokumentet saknar viktig information. Var god ladda upp ett annat dokument som innehåller namn, id och adress.`,
      en: `The docuemnt is missing important information. Please upload a document containing name, id and address.`,
      de: 'Dem Dokument fehlen wichtige Informationen. Bitte laden Sie ein Dokument mit Name, ID und Adresse hoch.',
    }),
    'You have to confirm that all owners and board members...': translations({
      sv: `Du måste godkänna att du fyllt i samtliga ägare och styrelseledamöter.`,
      en: `You have to confirm that all owners and board members has been addded.`,
      de: 'Sie müssen bestätigen, dass alle Eigentümer und Vorstandsmitglieder hinzugefügt wurden',
    }),
    'Board Member': translations({
      sv: `Styrelseledamot`,
      en: `Board Member`,
      de: 'Vorstandsmitglied',
    }),
    'You can specify a message that is...': translations({
      sv: `Du kan ange ett meddelande som skickas ut i mailet om jobbförfrågan till personalen`,
      en: `You can specify a message that is sent out in the email about the job request to the staff.`,
      de: `Sie können eine Nachricht angeben, die in der E-Mail über die Jobanfrage an die Mitarbeiter gesendet wird.`,
    }),
    'Unfortunately, the specified email...': translations({
      sv: `Tyvärr finns inte den angivna epostadressen i vårt system, var god kolla att du har skrivit in rätt adress.`,
      en: `Unfortunately, the specified email address is not in our system, please check that you have entered the correct address.`,
      de: `Leider befindet sich die angegebene E-Mail-Adresse nicht in unserem System. Bitte überprüfen Sie, ob Sie die richtige Adresse eingegeben haben`,
    }),
    'User does not exist': translations({
      sv: `Användaren finns inte`,
      en: `User does not exist`,
      de: `Der Benutzer existiert nicht`,
    }),
    'Make your activities visible': translations({
      sv: `Synliggör dina aktiviteter`,
      en: `Make your activities visible`,
      de: `Machen Sie Ihre Aktivitäten sichtbar`,
    }),
    'Both on adventurehero.se and on your own website.': translations({
      sv: `Både på adventurehero.se och på din egna hemsida.`,
      en: `Both on adventurehero.se and on your own website.`,
      de: `Sowohl auf abenteuerhero.se als auch auf Ihrer eigenen Website.`,
    }),
    'Receive bookings': translations({
      sv: `Ta emot bokningar`,
      en: `Receive bookings`,
      de: `Buchungen erhalten`,
    }),
    'Let your customers book themselves. Get paid right away.': translations({
      sv: `Låt dina kunder boka själva. Få betalt direkt.`,
      en: `Let your customers book themselves. Get paid right away.`,
      de: `Lassen Sie Ihre Kunden selbst buchen. Werde sofort bezahlt.`,
    }),
    'Schedule your staff': translations({
      sv: `Schemalägg din personal`,
      en: `Schedule your staff`,
      de: `Planen Sie Ihre Mitarbeiter`,
    }),
    'Plan what activities your staff will work on.': translations({
      sv: `Planera vilka aktiviteter din personal ska arbeta på.`,
      en: `Plan what activities your staff will work on.`,
      de: `Planen Sie, an welchen Aktivitäten Ihre Mitarbeiter arbeiten werden.`,
    }),
    'See terms': translations({
      sv: `Se villkor`,
      en: `See terms`,
      de: `Siehe Begriffe`,
    }),
    'Mark bookings as invoiced': translations({
      sv: `Markera bokningar som fakturerade`,
      en: `Mark bookings as invoiced`,
      de: `Buchungen als in Rechnung gestellt markieren`,
    }),
    'All bookings invoiced': translations({
      sv: `Alla bokningar är fakturerade`,
      en: `All bookings is invoiced`,
      de: `Alle Buchungen werden in Rechnung gestellt`,
    }),
    'Mark as invoiced': translations({
      sv: `Markera som fakturerad`,
      en: `Mark as invoiced`,
      de: `Als in Rechnung gestellt markieren`,
    }),
    'Mark as uninvoiced': translations({
      sv: `Markera som ofakturerad`,
      en: `Mark as uninvoiced`,
      de: `Als nicht in Rechnung gestellt markieren`,
    }),
    Invoice: translations({
      sv: `Faktura`,
      en: `Invoice`,
      de: `Rechnung`,
    }),
    Sent: translations({
      sv: `Skickad`,
      en: `Sent`,
      de: `Geschickt`,
    }),
    'Not sent': translations({
      sv: `Ej skickad`,
      en: `Not sent`,
      de: `Nicht gesendet`,
    }),
    'Not invoiced': translations({
      sv: `Ej fakturerad`,
      en: `Not invoiced`,
      de: `Nicht in Rechnung gestellt`,
    }),
    'The classic widget that works...': translations({
      sv: `Den klassiska widgeten som fungerar på de flesta webbsidor och som funnits tillgänglig sen tidigare`,
      en: `The classic widget that works on most web pages and has been available in the past`,
      de: 'Das klassische Widget, das auf den meisten Webseiten funktioniert und in der Vergangenheit verfügbar war',
    }),
    'iFrame technology is used to...': translations({
      sv: `iFrame teknik används för att enkelt kunna visa bokningsvyn från Adventure Hero på valfri plats på er sida, dock har vissa mobiler problem med att rendera iFrames. Därför har vi utvecklat den moderna widgeten som inte använder sig av en iFrame.`,
      en: `iFrame technology is used to easily display the booking view from Adventure Hero in any place on your site however, some mobiles have problems rendering iFrames. Thats why we have developed the modern widget that doesn't rely on an iFrame.`,
      de: 'Die iFrame-Technologie wird verwendet, um die Buchungsansicht von Adventure Hero an jedem Ort Ihrer Website anzuzeigen. Einige Handys haben jedoch Probleme beim Rendern von iFrames. Deshalb haben wir das moderne Widget entwickelt, das nicht auf einen iFrame angewiesen ist.',
    }),
    'Use this widget to display all available...': translations({
      sv: `Använd denna widget för att låta användaren boka de aktiviteter som är schemalagda på din anläggning, besökaren kommer kunna välja mellan alla olika erbjudanden som finns schemalagda och betala för dessa online på din egna hemsida.`,
      en: `Use this widget to display all available activities on the selected property! The visitor will be able to select an appropiate start time from a calendar and pay for the booking directly on your webpage.`,
      de: `Verwenden Sie dieses Widget, damit der Benutzer die in Ihrer Einrichtung geplanten Aktivitäten buchen kann. Der Besucher kann zwischen all den verschiedenen geplanten Angeboten wählen.`,
    }),
    'Use this widget to let your visitors choose...': translations({
      sv: `Denna widget använder du om du vill låta dina besökare först välja vilket erbjudande den är intresserad av.\nValda aktiviteter visas som snygga kort innan möjliga starttider kan väljas.\n\nNär ett kort är valt kommer dessutom de "inkluderade" aktiviterna att vara bokningsbara av besökaren.\n\nDu kan välja mellan alla tillgängliga aktiviter i din destination/organisation.`,
      en: `Use this widget to let your visitors choose which offer they are interested in first before continuing the booking process.\nSelected activities are displayed as cards before possible start times can be selected.\n\nWhen a card is selected, the "included" activities will also be bookable by the visitor.\n\nYou can choose from all the available activities in your destination or organization.`,
      de: `Verwenden Sie dieses Widget, damit Ihre Besucher zuerst auswählen können, für welches Angebot sie sich interessieren des Besuchers.\n\nSie können aus allen verfügbaren Aktivitäten in Ihrem Reiseziel / Ihrer Organisation wählen.`,
    }),
    'You must enter your email': translations({
      sv: `Du måste ange din email`,
      en: `You must enter your email`,
      de: `Sie müssen Ihre E-Mail eingeben`,
    }),
    'Please enter a valid email': translations({
      sv: `Var snäll och ange en giltig email`,
      en: `Please enter a valid email`,
      de: `Bitte geben Sie eine gültige E-Mail ein`,
    }),
    'You must enter your password': translations({
      sv: `Du måste ange ditt lösenord`,
      en: `You must enter your password`,
      de: `Sie müssen Ihr Passwort eingeben`,
    }),
    'Your password must be at least six letters long': translations({
      sv: `Ditt lösenord måste vara minst sex bokstäver långt`,
      en: `Your password must be at least six letters long`,
      de: `Ihr Passwort muss mindestens sechs Buchstaben lang sein`,
    }),
    'Please enter your email': translations({
      sv: `Var snäll och ange din email`,
      en: `Please enter your email`,
      de: `Bitte geben Sie ihre E-Mail ein`,
    }),
    'Fill in your email address and we will send...': translations({
      sv: `Fyll i din emailadress så skickar vi en länk som hjälper dig att skapa ett nytt lösenord.`,
      en: `Fill in your email address and we will send you a link to help you create a new password`,
      de: 'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link, der Ihnen bei der Erstellung eines neuen Passworts hilft',
    }),
    'Email has been sent': translations({
      sv: `Mailet har skickats.`,
      en: `Email has been sent.`,
      de: `Die Email wurde verschickt.`,
    }),
    'Now just follow the link in the email to reset your password': translations({
      sv: `Nu är det bara att följa länken i mailet för att återställa dit lösenord.`,
      en: `Now just follow the link in the email to reset your password.`,
      de: `Folgen Sie nun einfach dem Link in der E-Mail, um Ihr Passwort zurückzusetzen`,
    }),
    'See your email to complete the registration of your account': translations({
      sv: `Vänligen se din mail för att slutföra registreringen av ditt konto.`,
      en: `See your email to complete the registration of your account.`,
      de: `Sehen Sie sich Ihre E-Mail an, um die Registrierung Ihres Kontos abzuschließen`,
    }),
    Great: translations({
      sv: `Toppen!`,
      en: `Great!`,
      de: `Groß!`,
    }),
    '`A user with the given name already exists in the system': translations({
      sv: `Det finns redan en användare med den angivna mailadressen i systemet.`,
      en: `A user with the given name already exists in the system.`,
      de: 'Ein Benutzer mit dem angegebenen Namen existiert bereits im System.',
    }),
    'User already exists': translations({
      sv: `Användaren finns redan.`,
      en: `User already exists.`,
      de: `Benutzer existiert bereits.`,
    }),
    'An organization with the given name already exists in the system': translations({
      sv: `Det finns redan en organisation med det angivna namnet i systemet.`,
      en: `An organization with the given name already exists in the system.`,
      de: 'Eine Organisation mit dem angegebenen Namen existiert bereits im System.',
    }),
    'Organization already exists': translations({
      sv: `Organisation finns redan.`,
      en: `Organization already exists.`,
      de: `Organisation existiert bereits`,
    }),
    'Please try again or contact support...': translations({
      sv: `Försök igen eller kontakta supporten (se kontaktinfo längst ner på startsidan).`,
      en: `Please try again or contact support (see contact information at the bottom of the home page).`,
      de: `Bitte versuchen Sie es erneut oder wenden Sie sich an den Support (siehe Kontaktinformationen unten auf der Startseite).`,
    }),
    'The organization could not be created': translations({
      sv: `Det gick inte att skapa organisationen.`,
      en: `The organization could not be created.`,
      de: `Die Organisation konnte nicht erstellt werden.`,
    }),
    'Monthly fee': translations({
      sv: `Månadsavgift 795 SEK/mån. Bli en trogen kund och betala 5000 kr/år för tvåårsabonnemang. Kontakta info@adventurehero.se`,
      en: `Monthly fee 795 SEK/month. Possibillity to be a loyal customer with yearly fee 5000 SEK/year for two years. Contact info@adventurehero.se`,
      de: `Monatliche Gebühr 795 SEK / Monat. Werden Sie treuer Kunde und zahlen Sie 5.000 SEK / Jahr für ein zweijähriges Abonnement. Kontakt info@adventurehero.se`,
    }),
    'Service fee for payment...': translations({
      sv: `Serviceavgift vid betalning 4 %* av totala bokningsbeloppet.`,
      en: `Service fee for payment 4% * of the total booking amount.`,
      de: 'Servicegebühr bei Zahlung 4% * des gesamten Buchungsbetrages.',
    }),
    "The prices above include all the platform's...": translations({
      sv: `I ovan priser ingår plattformens alla tjänster, bankavgifter och teknisk support. Samtliga priser ex moms.`,
      en: `The prices above include all the platform's services, bank fees and technical support. All prices ex VAT.`,
      de: 'Die oben genannten Preise beinhalten alle Dienstleistungen der Plattform, Bankgebühren und technischen Support. Alle Preise zzgl. MwSt.',
    }),
    'Read more about everything that is included...': translations({
      sv: `Läs mer om allt som ingår i vår plattform och priser här. Vill du bli Månadskund eller Trogen Kund så kontakta`,
      en: `Read more about everything that is included in our platform and prices here. If you want to become a Monthly Customer or a Loyal Customer, please contact`,
      de: 'Lesen Sie hier mehr über alles, was in unserer Plattform enthalten ist und zu den Preisen. Wenn Sie ein monatlicher Kunde oder ein treuer Kunde werden möchten, wenden Sie sich bitte an',
    }),
    'and we will help you': translations({
      sv: `så hjälper vi dig.`,
      en: `and we will help you.`,
      de: `und wir werden Ihnen helfen.`,
    }),
    '* Applies to cards within Europe...': translations({
      sv: `* Gäller kort inom Europa, 6 % AMEX/Internationella kort.`,
      en: `* Applies to cards within Europe, 6% AMEX / International cards.`,
      de: '* Gilt für Karten innerhalb Europas, 6% AMEX / Internationale Karten.',
    }),
    'Register for free and get to know the platform...o': translations({
      sv: `Registrera dig utan kostnad och lär känna plattformen. Inga avgifter förrän du aktiverat ditt konto.`,
      en: `Register for free and get to know the platform. No fees until you activate your account.`,
      de: 'Registrieren Sie sich kostenlos und lernen Sie die Plattform kennen. Keine Gebühren, bis Sie Ihr Konto aktivieren.',
    }),
    'Create account': translations({
      sv: `Skapa konto`,
      en: `Create account`,
      de: `Benutzerkonto erstellen`,
    }),
    'components.dashboard.home.segments.pulsegment.headerPluSegment': translations({
      sv: `PUL-Avtal`,
      en: `PUL agreement`,
      de: `PUL Vereinbarung`,
    }),
    'Valid during the period:': translations({
      sv: `Giltig under perioden:`,
      en: `Valid during the period:`,
      de: `Gültig im Zeitraum:`,
    }),
    'Unfortunately, you cannot check in the desired activity, please check the validity of the ticket. Also note that each activity can only be checked in once.':
      translations({
        sv: `Du kan tyvärr inte checka in önskad aktivitet, vänligen kontrollera biljettens giltighet. Observera även att varje aktivitet endast kan checkas in en gång.`,
        en: `Unfortunately, you cannot check in the desired activity, please check the validity of the ticket. Also note that each activity can only be checked in once.`,
        de: `Leider können Sie die gewünschte Aktivität nicht einchecken, bitte überprüfen Sie die Gültigkeit des Tickets. Beachten Sie auch, dass jede Aktivität nur einmal eingecheckt werden kann.`,
      }),
    'Check-in at': translations({
      sv: `Incheckning på`,
      en: `Check-in at`,
      de: `Check-in um`,
    }),
    'Use ticket': translations({
      sv: `Använd biljett`,
      en: `Use ticket`,
      de: `Ticket verwenden`,
    }),
    'Check-in cannot be undone.': translations({
      sv: `Incheckningen kan inte ångras. Biljetten kommer då att vara förbrukad.`,
      en: `Check-in cannot be undone. The ticket will then be consumed.`,
      de: `Der Check-in kann nicht rückgängig gemacht werden. Das Ticket wird dann verbraucht.`,
    }),
    'NOTE: Each entry must be checked in...': translations({
      sv: `OBS: Varje entré ska checkas in av personal på aktiviteten. Incheckningen kan inte ångras.`,
      en: `NOTE: Each entry must be checked in by staff at the activity. Check-in cannot be undone.`,
      de: `NOTIZ. Jeder Eintrag muss vom Personal der Aktivität eingecheckt werden. Der Check-in kann nicht rückgängig gemacht werden.`,
    }),
    'NOTE: The entry must be checked in by staff at the activity.': translations({
      sv: `OBS: Entrén ska checkas in av personal på aktiviteten.`,
      en: `NOTE: The entry must be checked in by staff at the activity.`,
      de: `NOTIZ. Der Eintrag muss vom Personal der Aktivität eingecheckt werden. `,
    }),
    'Check-in completed.': translations({
      sv: `Incheckning slutförd.`,
      en: `Check-in completed.`,
      de: `Einchecken abgeschlossen.`,
    }),
    'For personal data assistant agreements click here': translations({
      sv: `För personuppgiftsbiträdesavtal klicka här`,
      en: `For personal data assistant agreements click here`,
      de: `Für Vereinbarungen mit Assistenten für personenbezogene Daten klicken Sie hier`,
    }),
    'The PUL Assistant Agreement': translations({
      sv: `PUL-biträdesavtalet`,
      en: `PUL agreement`,
      de: `PUL Vereinbarung`,
    }),
    'By activating your account, you understand and agree to the following conditions': translations({
      sv: 'Genom att aktivera ditt konto så förstår du och godkänner följande villkor',
      en: 'By activating your account, you understand and agree to the following conditions',
      de: 'Durch die Aktivierung Ihres Kontos verstehen Sie Folgendes und stimmen diesem zu',
    }),
    'You are entitled to a free trial period which...': translations({
      sv: 'Du är berättigad en kostnadsfri testperiod vilken automatiskt påbörjas från dess att du aktiverar ditt konto och är giltig en månad framåt',
      en: 'You are entitled to a free trial period which starts automatically from the moment you activate your account and is valid for one month',
      de: 'Sie haben Anspruch auf eine kostenlose Testphase, die automatisch ab dem Zeitpunkt der Aktivierung Ihres Kontos beginnt und einen Monat lang gültig ist',
    }),
    'When the test period has expired, contractual...': translations({
      sv: 'När testperioden utlöpt faktureras avtalsenliga avgifter vid fortsatt användande av plattformen',
      en: 'When the test period has expired, contractual fees are invoiced for continued use of the platform',
      de: 'Nach Ablauf des Testzeitraums werden Vertragsgebühren für die weitere Nutzung der Plattform in Rechnung gestellt',
    }),
    'Sales of bookings and gift cards made via our...': translations({
      sv: 'Försäljning av bokningar och presentkort som genomförs via vår plattform och dess verktyg belastas serviceavgifter oberoende från ovan beskriven testperiod',
      en: 'Sales of bookings and gift cards made via our platform and its tools are charged service fees regardless of the trial period described above',
      de: 'Für Buchungen, die über unsere Plattform und ihre Tools getätigt werden, werden unabhängig vom oben beschriebenen Testzeitraum Servicegebühren berechnet',
    }),
    'To see more about handling personal data, please press': translations({
      sv: 'För att se mer om hantering av persondata vänligen tryck',
      en: 'To see more about handling personal data, please press',
      de: 'Um mehr über den Umgang mit personenbezogenen Daten zu erfahren, drücken Sie bitte',
    }),
    'Show more': translations({
      sv: `Visa fler`,
      en: `Show more`,
      de: `Zeig mehr`,
    }),
    Settings: translations({
      sv: `Inställningar`,
      en: `Settings`,
      de: `Die Einstellungen`,
    }),
    'Here you can view other account settings': translations({
      sv: `Här kan du se andra kontoinställningar.`,
      en: `Here you can view other account settings.`,
      de: `Hier sehen Sie andere Kontoeinstellungen.`,
    }),
    'Hide the weather segment of the widget': translations({
      sv: `Dölj väder segmentet i widgeten`,
      en: `Hide the weather segment of the widget`,
      de: `Blenden Sie das Wettersegment im Widget aus`,
    }),
    'Use your own organizations name as sender in emails': translations({
      sv: `Använd din egna organisations namn som avsändare i email`,
      en: `Use your own organizations name as sender in emails`,
      de: `Verwenden Sie den Namen Ihrer eigenen Organisation als Absender in E-Mails`,
    }),
    'Ticket settings': translations({
      sv: `Biljettinställningar`,
      en: `Ticket settings`,
      de: `Ticketeinstellungen`,
    }),
    'Make this offer a ticket': translations({
      sv: `Gör det här erbjudandet till en biljett`,
      en: `Make this offer a ticket`,
      de: `Diese Angebot zu einem Ticket machen`,
    }),
    'Common widget': translations({
      sv: `Vanlig widget`,
      en: `Common widget`,
      de: `Allgemeines Widget`,
    }),
    'Choose widget type': translations({
      sv: `Välj typ av widget`,
      en: `Choose widget type`,
      de: `Wählen Widget-Typ`,
    }),
    'Choose what type of widget you want to create': translations({
      sv: `Välj vilken typ av widget du vill skapa. 'Standard' ger tillgång till alla funktioner i widgeten.`,
      en: `Choose what type of widget you want to create. "Standard" gives you access to all widget functionality.`,
      de: "Wählen Sie aus, welche Art von Widget Sie erstellen möchten. 'Standard' gibt Ihnen Zugriff auf alle Widget-Funktionalitäten.",
    }),
    'Widget Background Color': translations({
      sv: `Bakgrundsfärg`,
      en: `Background color`,
      de: `Hintergrundfarbe`,
    }),
    'Choose the background color of the widget': translations({
      sv: `Om du vill kan du välja en egen bakgrundsfärg för widgeten`,
      en: `If you want you can select a custom background color for the widget`,
      de: 'Wenn Sie möchten, können Sie Ihre eigene Hintergrundfarbe für das Widget auswählen',
    }),
    Standard: translations({
      sv: 'Standard',
      en: 'Standard',
      de: 'Standard',
    }),
    Giftcards: translations({
      sv: `Presentkort`,
      en: `Giftcards`,
      de: `Gutscheinkarte`,
    }),
    Handle: translations({
      sv: `Hantera`,
      en: 'Handle',
      de: 'Handhaben',
    }),
    Close: translations({
      sv: 'Stäng',
      en: 'Close',
      de: 'Schließen',
    }),
    'This choice will doublebook an employee': translations({
      sv: `Det här valet kommer orsaka dubbelbokning av personal`,
      en: 'This choice will doublebook an employee',
      de: `Durch diese Auswahl wird ein Mitarbeiter doppelt gebucht`,
    }),
    'New booking request from': translations({
      sv: `Ny bokningsförfrågan från`,
      en: 'New booking request from',
      de: `Neue Buchungsanfrage von`,
    }),
    Notifications: translations({
      sv: `Notifieringar`,
      en: 'Notifications',
      de: `Benachrichtigungen`,
    }),
    Read: translations({
      sv: `Läst`,
      en: 'Read',
      de: `Lesen`,
    }),
    'Already read notifications': translations({
      sv: 'Redan lästa notiser',
      en: 'Already read notifications',
      de: 'Benachrichtigungen bereits gelesen',
    }),
    Unread: translations({
      sv: `Oläst`,
      en: 'Unread',
      de: `Ungelesen`,
    }),
    'Mark as read': translations({
      sv: `Markera som läst`,
      en: 'Mark as read',
      de: `Als gelesen markieren`,
    }),
    'Mark as unread': translations({
      sv: `Markera som oläst`,
      en: 'Mark as unread',
      de: `Als ungelesen markieren`,
    }),
    'Go to calendar': translations({
      sv: `Gå till kalender`,
      en: 'Go to calendar',
      de: `Gehe zum Kalender`,
    }),
    'No notifications': translations({
      sv: `Du har inga notifieringar`,
      en: 'You have no notifications',
      de: `Sie haben keine Benachrichtigungen`,
    }),
    'Unknown notification': translations({
      sv: 'Okänd notifieringstyp från',
      en: 'Unknown notificationtype from',
      de: 'Unbekannter Benachrichtigungstyp von',
    }),
    'Booking request was accepted by': translations({
      sv: 'Bokningsförfrågan accepterades av',
      en: 'Booking request was accepted by',
      de: 'Buchungsanfrage wurde akzeptiert von',
    }),
    'Booking request was denied by': translations({
      sv: 'Bokningsförfrågan nekads av',
      en: 'Booking request was denied by',
      de: 'Buchungsanfrage wurde abgelehnt von',
    }),
    'Total increased by': translations({
      sv: 'Värdet ökade med',
      en: 'Total increased by',
      de: 'Der wert erhöhte sich um',
    }),
    'Total decreased by': translations({
      sv: 'Värdet minskade med',
      en: 'Total decreased by',
      de: 'Der wert verringerte sich um',
    }),
    'No value was changed': translations({
      sv: 'Ingen värdeförändring',
      en: 'No value was changed',
      de: 'Keine wertänderung',
    }),
    'Booking was created': translations({
      sv: 'Bokningen skapades',
      en: 'Booking was created',
      de: 'Die Buchung wurde erstellt',
    }),
    'Booking request confirmed': translations({
      sv: 'Bokningsförfrågan bekräftad',
      en: 'Booking request confirmed',
      de: 'Buchungsanfrage bestätigt',
    }),
    'Booking request rejected': translations({
      sv: 'Bokningsförfrågan avvisades',
      en: 'Booking request rejected',
      de: 'Buchungsanfrage abgelehnt',
    }),
    'Increased the number of visitors by': translations({
      sv: 'Ökade antalet besökare med',
      en: 'Increased the number of visitors by',
      de: 'Erhöhte die besucherzahl um',
    }),
    'Decreased the number of visitors by': translations({
      sv: 'Minskade antalet besökare med',
      en: 'Decreased the number of visitors by',
      de: 'Reduzierte die besucherzahl um',
    }),
    'No changes were made to the number of visitors': translations({
      sv: 'Inga förändringar gjordes to besökarantalet',
      en: 'No changes were made to the number of visitors',
      de: 'Die anzahl der besucher wurde nicht geändert',
    }),
    'Booking information was changed': translations({
      sv: 'Bokningsinformationen ändrades',
      en: 'Booking information was changed',
      de: 'Buchungsinformationen geändert',
    }),
    'Following fields were changed': translations({
      sv: 'Följande fält ändrades',
      en: 'The following fields were changed',
      de: 'Die folgenden Felder wurden geändert',
    }),
    'Changed field': translations({
      sv: 'Ändrade fält',
      en: 'Changed field',
      de: 'Feld geändert',
    }),
    // These are fetched from variable states and should not be modified
    firstname: translations({
      sv: 'förnamn',
      en: 'first name',
      de: 'Vorname',
    }),
    lastname: translations({
      sv: 'efternamn',
      en: 'last name',
      de: 'Nachname',
    }),
    email: translations({
      sv: 'email',
      en: 'email',
      de: 'E-Post',
    }),
    phoneNr: translations({
      sv: 'telefonnummer',
      en: 'phone number',
      de: 'Telefonnummer',
    }),
    address: translations({
      sv: 'adress',
      en: 'address',
      de: 'Adresse',
    }),
    // These are fetched from variable states and should not be modified
    'Unknown event': translations({
      sv: 'Okänd händelse',
      en: 'Unknown event',
      de: 'Unbekanntes Ereignis',
    }),
    'Card payment added': translations({
      sv: 'Kortbetalning tillagd',
      en: 'Card payment added',
      de: 'Kartenzahlung hinzugefügt',
    }),
    'Refund made': translations({
      sv: 'Återbetalning genomförd',
      en: 'Refund made',
      de: 'Rückerstattung gemacht',
    }),
    'Voucher payment added': translations({
      sv: 'Betalning med värdebevis tillagd',
      en: 'Voucher payment added',
      de: 'Gutscheinzahlung hinzugefügt',
    }),
    'Manual payment added': translations({
      sv: 'Manuell betalning tillagd',
      en: 'Manual payment added',
      de: 'Manuelle Zahlung hinzugefügt',
    }),
    'A payment was added': translations({
      sv: 'En betalning blev tillagd',
      en: 'A payment was added',
      de: 'Eine Zahlung wurde hinzugefügt',
    }),
    'A payment of': translations({
      sv: 'En betalning på',
      en: 'A payment of',
      de: 'Eine Zahlung von',
    }),
    'A refund of': translations({
      sv: 'En återbetalning på',
      en: 'A refund of',
      de: 'eine Rückerstattung von',
    }),
    'A discount of': translations({
      sv: 'en rabattkod på',
      en: 'A discount of',
      de: 'ein Rabattcode von',
    }),
    added: translations({
      sv: 'lades till',
      en: 'added',
      de: 'wurde hinzugefügt',
    }),
    'was processed': translations({
      sv: 'has behandlats',
      en: 'was processed',
      de: 'wurde verarbeitet',
    }),
    // These are fetched from variable states and should not be modified
    'address.row1': translations({
      sv: 'adress',
      en: 'address',
      de: 'straße',
    }),
    'address.row2': translations({
      sv: 'alt. adress',
      en: 'alt. address',
      de: 'alt. Adresse',
    }),
    'address.postOrt': translations({
      sv: 'stad',
      en: 'city',
      de: 'stadt',
    }),
    'address.postNr': translations({
      sv: 'postnummer',
      en: 'zip code',
      de: 'PLZ',
    }),
    'address.country': translations({
      sv: 'land',
      en: 'country',
      de: 'Land',
    }),
    // These are fetched from variable states and should not be modified
    'an empty field': translations({
      sv: 'ett tomt fält',
      en: 'an empty field',
      de: 'ein leeres Feld',
    }),
    'This booking is old': translations({
      sv: 'Denna bokning är en bokning gjord med en äldre version av AdventureHero och saknar information som bokningshistorik mm.',
      en: 'This booking was made using an older version of AdventureHero and is missing information such as booking history etc.',
      de: 'Bei dieser Buchung handelt es sich um eine Buchung mit einer älteren Version von AdventureHero, bei der Informationen wie der Buchungsverlauf usw. fehlen.',
    }),
    'Create payment link': translations({
      sv: 'Skapa betalningslänk',
      en: 'Create payment link',
      de: 'Zahlungslink erstellen',
    }),
    name: translations({
      sv: 'namn',
      en: 'name',
      de: 'Name',
    }),
    'left to be paid': translations({
      sv: 'kvar att betala',
      en: 'left to be paid',
      de: 'noch zu bezahlen',
    }),
    'Previous payments': translations({
      sv: 'Tidigare betalt',
      en: 'Previous payments',
      de: 'Frühere Zahlungen',
    }),
    'Pay booking': translations({
      sv: 'Betala bokning',
      en: 'Pay booking',
      de: 'Buchung bezahlen',
    }),
    'Booking has been paid': translations({
      sv: 'Bokningen är betald',
      en: 'Booking has been paid',
      de: 'Die Buchung ist bezahlt',
    }),
    'It is not possible to pay for this...': translations({
      sv: 'Det är inte möjligt att betala för denna bokning eftersom den har blivit avvisad av arrangörerna. ',
      en: 'It is not possible to pay for this booking as it has been rejected by the organizers.',
      de: 'Es ist nicht möglich, diese Buchung zu bezahlen, da sie von den Organisatoren abgelehnt wurde.',
    }),
    'You can make another...': translations({
      sv: 'Du kan göra en annan bokningsförfrågan eller boka en av de schemalagda tiderna för aktiviteten. ',
      en: 'You can make another booking request or book one of the scheduled times for the activity.',
      de: 'Du kannst eine andere Buchungsanfrage stellen oder eine der geplanten Zeiten für die Aktivität buchen.',
    }),
    'The payment link was sent': translations({
      sv: 'Betalningslänken skickades',
      en: 'The payment link was sent',
      de: 'Der Zahlungslink wurde gesendet',
    }),
    'Do you want to send a payment link': translations({
      sv: 'Vill du skicka ut en betalningslänk till kunden?',
      en: 'Do you want to send a payment link to the customer?',
      de: 'Möchten Sie dem Kunden einen Zahlungslink zusenden?',
    }),
    "Don't send": translations({
      sv: 'Skicka inte',
      en: "Don't send",
      de: 'Nicht senden',
    }),
    'Send payment link': translations({
      sv: 'Skicka betalningslänk',
      en: 'Send payment link',
      de: 'Der Zahlungslink wurde gesendet',
    }),
    'Payment link description': translations({
      sv: 'En betalningslänk kommer att skickas till kundens angivna mailaddress som tillåter kunden att betala resterande belopp på bokningen.',
      en: 'A payment link will be sent to the customers email address that allows the customer to pay the remaning booking amount.',
      de: 'Ein Zahlungslink wird an die E-Mail-Adresse des Kunden gesendet, über den der Kunde den Restbetrag der Buchung bezahlen kann.',
    }),
    'Successfully sent': translations({
      sv: 'Skickade',
      en: 'Successfully sent',
      de: 'Erfolgreich gesendet',
    }),
    'Failed to send': translations({
      sv: 'Misslyckades att skicka',
      en: 'Failed to send',
      de: 'Senden fehlgeschlagen',
    }),
    // These are fetched from variable states and should not be modified
    booking_confirmation: translations({
      sv: 'bokningsbekräftelse',
      en: 'booking confirmation',
      de: 'Buchungsbestätigung',
    }),
    changed_booking: translations({
      sv: 'bokningsuppdatering',
      en: 'booking update',
      de: 'Buchungsaktualisierung',
    }),
    payment_link: translations({
      sv: 'betalningslänk',
      en: 'payment link',
      de: 'Zahlungslink',
    }),
    // These are fetched from variable states and should not be modified
    'Manage your bookings': translations({
      sv: 'Hantera dina bokningar',
      en: 'Manage your bookings',
      de: 'Verwalten Sie Ihre Buchungen',
    }),
    welcome_mail: translations({
      sv: 'välkomstmail',
      en: 'welcome mail',
      de: 'Willkommens-E-Mail',
    }),
    thankyou_mail: translations({
      sv: 'tackmail',
      en: 'thank you mail',
      de: 'Dankes-E-Mail',
    }),
    booking_request: translations({
      sv: 'bokningsförfrågan mail',
      en: 'booking request mail',
      de: 'buchungsanfrage mail',
    }),
    booking_rejected: translations({
      sv: 'bokningsavvisningsmail',
      en: 'booking rejection mail',
      de: 'Buchungsablehnungsmail',
    }),
    'Manage bookings you have made at a partner': translations({
      sv: 'Hantera bokningar du har gjort hos en annan aktör',
      en: 'Manage bookings you have made at a partner',
      de: 'Verwalten Sie Buchungen, die Sie bei einem Partner vorgenommen haben',
    }),
    'Welcome mail sent': translations({
      sv: 'Välkomstmail skickat!',
      en: 'Welcome mail sent!',
      de: 'Willkommensmail gesendet',
    }),
    'Thank you mail sent': translations({
      sv: 'Tackmail skickat!',
      en: 'Thank you mail sent!',
      de: 'Danke Mail gesendet',
    }),
    'Manage booking': translations({
      sv: 'Hantera bokning',
      en: 'Manage booking',
      de: 'Buchung verwalten',
    }),
    'Manage mailings': translations({
      sv: 'Hantera utskick',
      en: 'Manage mailings',
      de: 'Mailings verwalten',
    }),
    'Send welcome mail': translations({
      sv: 'Skicka välkomstmail',
      en: 'Send welcome mail',
      de: 'Willkommens-Mail senden',
    }),
    'Send thank you mail': translations({
      sv: 'Skicka tackmail',
      en: 'Send thank you mail',
      de: 'Danke Mail senden',
    }),
    'Welcome message': translations({
      sv: 'Välkomstmeddelande',
      en: 'Welcome message',
      de: 'Willkommensnachricht',
    }),
    'You can choose how your offers welcome email will look like': translations({
      sv: `Du kan välja hur erbjudandets välkomstmail skall se ut`,
      en: `You can choose how your offers welcome email will look like`,
      de: `Sie können wählen, wie Ihre Willkommens-E-Mail für Angebote aussehen soll`,
    }),
    'Thank you message': translations({
      sv: 'Tackmeddelande',
      en: 'Thank you message',
      de: 'Dankenachricht',
    }),
    'You can choose how your offers thank you email will look like': translations({
      sv: `Du kan välja hur erbjudandets tackmail skall se ut`,
      en: `You can choose how your offers thank you email will look like`,
      de: `Sie können wählen, wie Ihre Dankes-E-Mail mit Angeboten aussehen soll`,
    }),
    'Messages you write here will be sent to the customer by email 24hrs before activity start': translations({
      sv: `Meddelanden som du skriver här kommer skickas till kunden på mail 24 timmar innan aktivitetens starttid.`,
      en: `Messages you write here will be sent to the customer by email 24hrs before activity start.`,
      de: `Nachrichten, die Sie hier schreiben, werden 24 Stunden vor Aktivitätsbeginn per E-Mail an den Kunden gesendet.`,
    }),
    'Messages you write here will be sent to the customer by email after activity ends': translations({
      sv: `Meddelanden som du skriver här kommer skickas till kunden efter att aktiviteten har avslutats.`,
      en: `Messages you write here will be sent to the customer by email after activity ends".`,
      de: `Nachrichten, die Sie hier schreiben, werden nach Beendigung der Aktivität per E-Mail an den Kunden gesendet.`,
    }),
    'Preview of the welcome email': translations({
      sv: `Förhandsvisning av välkomstmail`,
      en: `Preview of the welcome email`,
      de: `Vorschau der Willkommens-E-Mail`,
    }),
    'Preview of the "Thank you" email': translations({
      sv: `Förhandsvisning av ert tackmail`,
      en: `Preview of the "Thank you" email`,
      de: `Vorschau Ihrer Dankes-E-Mail`,
    }),
    "Here's how a welcome email will appear when sent to the customer": translations({
      sv: `Så här kommer ett välkomstmail se ut när det skickas till kunden`,
      en: `Here's how a welcome email will appear when sent to the customer`,
      de: `So sieht deine Willkommens-E-Mail aus, wenn sie an den Kunden geschickt wird!`,
    }),
    Welcome: translations({
      sv: `Välkommen`,
      en: `Welcome`,
      de: `Willkommen`,
    }),
    Subject: translations({
      sv: 'Ämne',
      en: 'Subject',
      de: 'Betreff',
    }),
    'Thank you for your visit': translations({
      sv: 'Tack för senast',
      en: 'Thank you for your visit',
      de: 'Thank you for your visit',
    }),
    '(Will not be sent)': translations({
      sv: '(Skickas inte)',
      en: '(Will not be sent)',
      de: '(Wird nicht gesendet)',
    }),
    '(Will be sent automatically)': translations({
      sv: '(Skickas automatiskt)',
      en: '(Will be sent automatically)',
      de: '(Wird automatisch versendet)',
    }),
    'Should be sent': translations({
      sv: 'Ska skickas',
      en: 'Should be sent',
      de: 'Wird gesendet werden',
    }),
    'Mail is sent as soon as booking has ended': translations({
      sv: 'Mailet skickas så snart bokningen avslutats',
      en: 'Mail is sent as soon as booking has ended',
      de: 'E-Mail wird versendet, sobald die Buchung abgeschlossen ist',
    }),
    'Mail is sent when the booking is starting': translations({
      sv: 'Mailet skickas ut när bokningen börjar',
      en: 'Mail is sent when the booking is starting',
      de: 'E-Mail wird versendet, wenn die Buchung beginnt',
    }),
    'The mail is sent': translations({
      sv: 'Mailet skickas automatiskt',
      en: 'The mail is sent',
      de: 'Die E-Mail wird automatisch',
    }),
    hour: translations({
      sv: 'timme',
      en: 'hour',
      de: 'Stunde',
    }),
    hours: translations({
      sv: 'timmar',
      en: 'hours',
      de: 'Stunden',
    }),
    'after end time': translations({
      sv: 'efter sluttid',
      en: 'after end time',
      de: 'nach der Endzeit gesendet',
    }),
    'before end time': translations({
      sv: 'innan sluttid',
      en: 'before end time',
      de: 'vor der Endzeit gesendet',
    }),
    'after start time': translations({
      sv: 'efter starttid',
      en: 'after start time',
      de: 'nach der Startzeit',
    }),
    'before start time': translations({
      sv: 'innan starttid',
      en: 'before start time',
      de: 'vor der Startzeit',
    }),
    'We hope that you had a wonderful experience at...': translations({
      sv: 'Vi hoppas att du haft en underbar upplevelse hos...',
      en: 'We hope that you had a wonderful experience at...',
      de: 'We hope that you had a wonderful experience at...',
    }),
    'We´re looking forward to your visit.': translations({
      sv: 'Vi ser fram emot ditt besök.',
      en: 'We´re looking forward to your visit.',
      de: 'Wir freuen uns auf Ihren Besuch.',
    }),
    'A warm welcome to': translations({
      sv: 'Varmt välkommen till',
      en: 'A warm welcome to',
      de: 'Herzlich willkommen bei',
    }),
    'The booking was made online': translations({
      sv: 'Bokningen gjordes online',
      en: 'The booking was made online',
      de: 'Die Buchung erfolgte online',
    }),
    'Booking was removed by': translations({
      sv: 'Bokningen togs bort av',
      en: 'Booking was removed by',
      de: 'Die Buchung wurde storniert',
    }),
    'Booking was created by': translations({
      sv: 'Bokning skapad av',
      en: 'Booking was created by',
      de: 'Buchung erstellt von',
    }),
    'Booking request was created by one of your customers': translations({
      sv: 'Bokningsförfrågan skapad av en kund',
      en: 'Booking request was created by one of your customers',
      de: 'Die Buchungsanfrage wurde von einem Ihrer Kunden erstellt',
    }),
    'Booking created': translations({
      sv: 'Bokning skapad',
      en: 'Booking created',
      de: 'Buchung erstellt',
    }),
    'is now booked at the': translations({
      sv: 'är nu inbokad den',
      en: 'is now booked at the',
      de: 'ist jetzt gebucht',
    }),
    'Booking amount of visitors was changed by': translations({
      sv: 'Bokningens antal besökare blev ändrat av',
      en: 'Booking amount of visitors was changed by',
      de: 'Die Anzahl der Besucher der Buchung wurde geändert um',
    }),
    'Change:': translations({
      sv: 'Ändring:',
      en: 'Change:',
      de: 'Veränderung:',
    }),
    '(automatic service)': translations({
      sv: '(automatisk service)',
      en: '(automatic service)',
      de: '(automatischer Dienst)',
    }),
    '(manually)': translations({
      sv: '(manuellt)',
      en: '(manually)',
      de: '(manuell)',
    }),
    'Geographical location': translations({
      sv: 'Geografisk position',
      en: 'Geographical position',
      de: 'Geografischen Position',
    }),
    'Adjust geographical location': translations({
      sv: 'Finjustera geografisk position',
      en: 'Adjust geographical position',
      de: 'Feinabstimmung der geografischen Position',
    }),
    'Add routes': translations({
      sv: 'Lägg till rutter',
      en: 'Add routes',
      de: 'Strecken hinzufügen',
    }),
    'Give your employees more control over your inventories by adding a route...': translations({
      sv: `Ge dina anställda mer kontroll över dina inventarier genom att lägga till en rutt. Under fliken Erbjudanden > 'Bokad inventarierlista'
        i dashboarden kommer du sedan få tillgång till mer detaljerad information om var dina uthyrda inventarier ska lämnas eller hämtas.`,
      en: `Give your employees more control over your inventories by adding a route. Under the tab Offers > 'Booked inventory list' in the dashboard, you will then have access to more detailed information about where your rented inventory should be left or picked up.`,
      de: `Geben Sie Ihren Mitarbeitern mehr Kontrolle über Ihre Bestände, indem Sie eine Route hinzufügen. Unter dem Reiter Angebote > 'Gebuchte Inventarliste' im Dashboard erhalten Sie dann nähere Informationen darüber, wo Ihr gemietetes Inventar hinterlegt bzw. abgeholt werden soll.`,
    }),
    'Add location': translations({
      sv: 'Lägg till specifik position',
      en: 'Add a specific location',
      de: 'Fügen Sie eine bestimmte Position hinzu',
    }),
    'Enable client belonging tracking': translations({
      sv: 'Enable client belonging tracking',
      en: 'Enable client belonging tracking',
      de: 'Enable client belonging tracking',
    }),
    'Add address': translations({
      sv: 'Lägg till en bestämd adress',
      en: 'Add a custom address',
      de: 'Fügen Sie eine bestimmte Adresse hinzu',
    }),
    'Here you can add an exact...': translations({
      sv: 'Här kan du kan lägga till en exakt geografisk position på ditt erbjudande. När detta väljs visas en länk i bokningsbekräftelsen och välkomstmailet som hänsivasar användaren till platsen.',
      en: 'Here you can add an exact geographical location to your offer. When this is selected, a link is displayed in the booking confirmation and the welcome email that refers the user to the location.',
      de: 'Hier können Sie Ihrem Angebot eine genaue geografische Position hinzufügen. Bei dieser Auswahl wird in der Buchungsbestätigung und der Willkommens-E-Mail ein Link angezeigt, der den Nutzer auf den Ort verweist.',
    }),
    'You can also add an alternative...': translations({
      sv: 'Du kan även lägga till en alternativ adress på ditt erbjudande. Adressen kommer att visas för dina kunder i bokningsflödet och i mailen där anläggnigens adress normalt sett visas.',
      en: "You can also add an alternative address to your offer. The address will be displayed to your customers in the booking flow and in the emails where the property's address is normally displayed.",
      de: 'Sie können Ihrem Angebot auch eine alternative Adresse hinzufügen. Die Adresse wird Ihren Kunden im Buchungsablauf und in der E-Mail angezeigt, in der normalerweise die Adresse der Einrichtung angezeigt wird.',
    }),
    'Remove the set location': translations({
      sv: 'Ta bort den angivna positionen',
      en: 'Remove the set location',
      de: 'Position löschen',
    }),
    'Search...': translations({
      sv: 'Sök...',
      en: 'Search...',
      de: 'Suche...',
    }),
    Locationsettings: translations({
      sv: 'Platsinställningar',
      en: 'Location settings',
      de: 'Ortseinstellungen',
    }),
    'The activity you are about to change has bookings': translations({
      sv: 'Aktiviteten som du är på väg att ändra har bokningar knutna till sig. Om du väljer att gå vidare kommer ett mail att skickas ut till samtliga kunder som bokat med information om förändringen. Är du säker på att du vill gå vidare?',
      en: 'The activity that you are about to change has bookings attached to it. If you choose to proceed, an email will be sent to all customers who have booked with information about the change. Are you sure you want to proceed?',
      de: 'Mit der Aktivität, die Sie ändern möchten, sind Buchungen verknüpft. Wenn Sie fortfahren möchten, wird eine E-Mail mit Informationen zur Änderung an alle Kunden gesendet, die gebucht haben. Sind Sie sicher, dass Sie fortfahren möchten?',
    }),
    'Not published': translations({
      sv: 'Ej publicerad',
      en: 'Not published',
      de: 'Nicht veröffentlicht',
    }),
    'Click anywhere in the view to close it': translations({
      sv: 'Klicka varsomhelst i vyn för att stänga den',
      en: 'Click anywhere in the view to close it',
      de: 'Klicken Sie auf eine beliebige Stelle in der Ansicht, um sie zu schließen',
    }),
    'Note that the service fee will not be refunded': translations({
      sv: 'Observera att serviceavgiften inte kommer att återbetalas.',
      en: 'Note that the service fee will not be refunded.',
      de: 'Beachten Sie, dass die Servicegebühr nicht zurückerstattet wird.',
    }),
    'No available categories for this property': translations({
      sv: 'Inga tillgängliga kategorier för denna anläggning',
      en: 'No available categories for this property',
      de: 'Keine verfügbaren Kategorien für diese Unterkunft',
    }),
    Expand: translations({
      sv: 'Expandera',
      en: 'Expand',
      de: 'Expand',
    }),
    'Year excl. VAT': translations({
      sv: 'år ex. moms',
      en: 'year excl. VAT',
      de: 'year excl. VAT',
    }),
    'No fixed fees': translations({
      sv: 'Inga fasta avgifter',
      en: 'No fixed fees',
      de: 'No fixed fees',
    }),
    'fee per online booking': translations({
      sv: 'avgift per onlinebokning',
      en: 'fee per online booking',
      de: 'fee per online booking',
    }),
    'This plan is suitable for... up to': translations({
      sv: 'Det här paket lämpar sig för dig som har ett företag med en omsättning på upp till',
      en: 'This plan is suitable for you that owns a company that has a turnover of up to',
      de: 'This plan is suitable for you that owns a company that has a turnover of up to',
    }),
    'This plan is suitable for... more than': translations({
      sv: 'Det här paket lämpar sig för dig som har ett företag med en omsättning på mer än',
      en: 'This plan is suitable for you that owns a company that has a turnover of more than',
      de: 'This plan is suitable for you that owns a company that has a turnover of more than',
    }),
    Includes: translations({
      sv: 'I priset ingår',
      en: 'Includes',
      de: 'Includes',
    }),
    'Bookings and payments online': translations({
      sv: 'Bokning och betalning online.',
      en: 'Bookings and payments online.',
      de: 'Bookings and payments online.',
    }),
    'Personnel management tools': translations({
      sv: 'Personalhanteringsverktyg.',
      en: 'Personnel management tools.',
      de: 'Personnel management tools.',
    }),
    'Selling and using own gift cards': translations({
      sv: 'Försäljning och nyttjande av egna presentkort.',
      en: 'Selling and using own gift cards.',
      de: 'Selling and using own gift cards.',
    }),
    'Marketing through AdventureHero.se...': translations({
      sv: 'Marknadsföring via AdventureHero.se och övriga tillgängliga kanaler.',
      en: 'Marketing through AdventureHero.se and any other available channels.',
      de: 'Marketing through AdventureHero.se and any other available channels.',
    }),
    'Technical support and data backups': translations({
      sv: 'Teknisk support och säkerhetskopiering av data.',
      en: 'Technical support and data backups.',
      de: 'Technical support and data backups.',
    }),
    'Technical support': translations({
      sv: 'Teknisk support.',
      en: 'Technical support.',
      de: 'Technical support.',
    }),
    'Data backups': translations({
      sv: 'Säkerhetskopiering av data.',
      en: 'Data backups.',
      de: 'Data backups.',
    }),
    'Card purchases below SEK 100...': translations({
      sv: '*Kortköp under 100 SEK har en fast avgift på 10 SEK ex. moms. För alla Amex och internationella Visa/Mastercard kortköp så tillkommer 2% på serviceavgiften.',
      en: '*Card purchases below SEK 100 have a fixed fee of SEK 10 excl. VAT. For All Amex and international Visa / Mastercard card purchases there is an additional fee of 2%.',
      de: '*Card purchases below SEK 100 have a fixed fee of SEK 10 excl. VAT. For All Amex and international Visa / Mastercard card purchases there is an additional fee of 2%..',
    }),
    'Other fees may apply from the payment service.': translations({
      sv: '*Inkluderar ej eventuella avgifter från betalningstjänsten.',
      en: '*Does not include fees applied by the payment service.',
      de: '*Does not include fees applied by the payment service.',
    }),
    'Bookings and gift cards directly...': translations({
      sv: 'Bokningar och presentkort direkt på din hemsida',
      en: 'Bookings and gift cards directly on your website',
      de: 'Bookings and gift cards directly on your website',
    }),
    'Thanks to our purchasing widget...': translations({
      sv: 'Med hjälp av vår Boknings- och presentkortswidget så äger du hela kundprocessen på din hemsida. Från presentation av dina aktiviteter och presentkort till genomförda och betalda bokningar, allt kopplas ihop automatiskt och kontrolleras via bokningssystemet. Slipp långa emailkonversationer och en ständigt ringande telefon!',
      en: 'Thanks to our purchasing widget you own the entire customer process on your website. From presentation of your activities and gift cards to completed and paid bookings, everything is connected automatically and controlled through the booking system. Avoid long email conversations and a constantly ringing phone!',
      de: 'Thanks to our purchasing widget you own the entire customer process on your website. From presentation of your activities and gift cards to completed and paid bookings, everything is connected automatically and controlled through the booking system. Avoid long email conversations and a constantly ringing phone!',
    }),
    What: translations({
      sv: 'Vad',
      en: 'What',
      de: 'What',
    }),
    'AdventureHero is a modular business...': translations({
      sv: 'AdventureHero är ett modulärt affärssystem som tillåter dig att administrera bokningar, aktiviteter och personal helt utefter dina behov.',
      en: 'AdventureHero is a modular business system that allows you to manage bookings, activities and staff completely according to your needs.',
      de: 'AdventureHero is a modular business system that allows you to manage bookings, activities and staff completely according to your needs.',
    }),
    'But we are not just a platform...': translations({
      sv: 'Men vi är inte bara en plattform – som kund hos oss så har du möjlighet att regelbundet ta kontakt med oss och dela dina personliga krav och önskemål för hur du vill att din upplevelse hos oss ska se ut.',
      en: 'But we are not just a platform - as a customer with us, you have the opportunity to contact us regularly and share your personal requirements and wishes for how you want your experience with us to look like.',
      de: 'But we are not just a platform - as a customer with us, you have the opportunity to contact us regularly and share your personal requirements and wishes for how you want your experience with us to look like.',
    }),
    How: translations({
      sv: 'Hur',
      en: 'How',
      de: 'How',
    }),
    'By gathering your administrative tasks...': translations({
      sv: 'Genom att samla dina administrativa sysslor på en och samma plattform så hoppas vi frigöra vi mer tid för dig, så att du kan fokusera mer kraft på dina kunder. Planera arbetsdagen, hantera era bokningar och schemalägg aktiviteter tillsammans med din personal.',
      en: 'By gathering your administrative tasks on one platform, we hope to free up more time for you, so that you can focus more on your customers. Plan your work day, manage your bookings and schedule activities together with your staff.',
      de: 'By gathering your administrative tasks on one platform, we hope to free up more time for you, so that you can focus more on your customers. Plan your work day, manage your bookings and schedule activities together with your staff.',
    }),
    'Create an account for free...': translations({
      sv: 'Skapa ett konto kostnadsfritt hos AdventureHero och testa vårt system idag!',
      en: 'Create an account for free with AdventureHero and test our system today!',
      de: 'Create an account for free with AdventureHero and test our system today!',
    }),
    Why: translations({
      sv: 'Varför',
      en: 'Why',
      de: 'Why',
    }),
    'We believe in connecting...': translations({
      sv: 'Vi tror på att koppla ihop, skapa och leverera kul aktiviteter i samarbete med din organisation. Utöver att vara med på din resa och hjälpa dig att växa så vill vi underlätta processen för personer att hitta nya, gemensamma intressen samt knyta nya band, och vi hoppas att du vill detta också!',
      en: 'We believe in connecting, creating and delivering fun activities in collaboration with your organization. In addition to being part of your journey and helping you grow, we want to make the process easier for people to find new, common interests and form new ties, and we hope you do too!',
      de: 'We believe in connecting, creating and delivering fun activities in collaboration with your organization. In addition to being part of your journey and helping you grow, we want to make the process easier for people to find new, common interests and form new ties, and we hope you do too!',
    }),
    'Secure payments with Stripe': translations({
      sv: 'Betala säkert med Stripe',
      en: 'Secure payments with Stripe',
      de: 'Secure payments with Stripe',
    }),
    'Payments for your products...': translations({
      sv: 'Betalning för dina produkter genomförs tryggt och säkert med Stripe, en av de ledande aktörerna inom onlinebetalningar. Betalningsalternativ innefattar kort- och presentkortsbetalning med fler alternativ på väg!',
      en: 'Payments for your products are made safely and securely with Stripe, one of the leading players in online payments. Payment options include card and gift card payments with more options on the way!',
      de: 'Payments for your products are made safely and securely with Stripe, one of the leading players in online payments. Payment options include card and gift card payments with more options on the way!',
    }),
    'This last year': translations({
      sv: 'Det senaste året',
      en: 'This last year',
      de: 'This last year',
    }),
    'Over 11 000 completed bookings': translations({
      sv: 'Över 11 000 genomförda bokningar.',
      en: 'Over 11 000 completed bookings.',
      de: 'Over 11 000 completed bookings.',
    }),
    'Of all bookings were made online': translations({
      sv: 'Av alla bokningar genomfördes online.',
      en: 'Of all bookings were made online.',
      de: 'Of all bookings were made online.',
    }),
    'Over 30 registered partners': translations({
      sv: 'Över 30 st registrerade aktörer.',
      en: 'Over 30 registered partners.',
      de: 'Over 30 registered partners.',
    }),
    'Get started today': translations({
      sv: 'Kom igång idag',
      en: 'Get started today',
      de: 'Get started today',
    }),
    'Read more about us': translations({
      sv: 'Läs mer om oss',
      en: 'Read more about us',
      de: 'Lesen Sie mehr über uns',
    }),
    'Join us': translations({
      sv: 'Bli aktör',
      en: 'Join us',
      de: 'Begleiten Sie uns',
    }),
    'Go to dashboard': translations({
      sv: 'Till dashboarden',
      en: 'Go to dashboard',
      de: 'Gehe zum Dashboard',
    }),
    'Read more about our service': translations({
      sv: 'Läs mer om vår tjänst',
      en: 'Read more about our service',
      de: 'Read more about our service',
    }),
    'Try for free for a month...': translations({
      sv: 'Prova gratis i en månad utan några fasta avgifter',
      en: 'Try for free for a month without any fixed fees',
      de: 'Try for free for a month',
    }),
    Plans: translations({
      sv: 'Priser',
      en: 'Plans',
      de: 'Plans',
    }),
    'Number of visitors who can book the activity': translations({
      sv: 'Antal besökare som kan boka aktiviteten',
      en: 'Number of visitors who can book the activity',
      de: 'Anzahl der Besucher, die die Aktivität buchen können',
    }),
    'Handle discounts': translations({
      sv: 'Hantera rabattkoder',
      en: 'Handle discounts',
      de: 'Rabatte behandeln',
    }),
    'Discount code': translations({
      sv: 'Rabattkod',
      en: 'Discount code',
      de: 'Rabattcode',
    }),
    'Discount percentage': translations({
      sv: 'Procentvärde',
      en: 'Discount percentage',
      de: 'Rabattprozentsatz',
    }),
    'Valid from': translations({
      sv: 'Giltig från',
      en: 'Valid from',
      de: 'Valid from',
    }),
    Code: translations({
      sv: 'Kod',
      en: 'Code',
      de: 'Code',
    }),
    Discounts: translations({
      sv: 'Rabattkoder',
      en: 'Discounts',
      de: 'Rabatte',
    }),
    'Search discounts': translations({
      sv: `Sök rabatter`,
      en: `Search discounts`,
      de: `Suche rabatte`,
    }),
    'Add discount code': translations({
      sv: `Lägg till rabattkod`,
      en: `Add discount code`,
      de: `Rabatt hinzufügen`,
    }),
    'Expired discount codes': translations({
      sv: `Utgångna rabattkoder`,
      en: `Expired discount codes`,
      de: `Abgelaufene Rabattcodes`,
    }),
    'Delete discount code': translations({
      sv: `Ta bort rabattkod`,
      en: `Delete discount code`,
      de: `Rabattcode löschen`,
    }),
    'Valid until': translations({
      sv: `Giltig till`,
      en: `Valid until`,
      de: `Gültig bis`,
    }),
    'Discount Information': translations({
      sv: `Rabattinformation`,
      en: `Discount Information`,
      de: `Rabattinformationen`,
    }),
    'Use limit (optional)': translations({
      sv: `Max antal förbrukningar (frivilligt)`,
      en: `Use limit (optional)`,
      de: `Limit verwenden (freiwillig)`,
    }),
    'Limit discount (optional)': translations({
      sv: `Limitera rabatkod (frivilligt)`,
      en: `Limit discount (optional)`,
      de: `Rabattcode begrenzen (freiwillig)`,
    }),
    'If no offer selected, discount code can be applied to all offers': translations({
      sv: `Om inget erbjudande valts, kan rabattkoden tillämpas på alla erbjudanden`,
      en: `If no offer selected, the discount code can be applied to all offers`,
      de: `Wenn kein angebot ausgewählt ist, kann der rabattcode auf alle angebote angewendet werden`,
    }),
    'Copy Code': translations({
      sv: `Kopiera kod`,
      en: `Copy code`,
      de: `Code kopieren`,
    }),
    Create: translations({
      sv: `Skapa`,
      en: `Create`,
      de: `Schaffen`,
    }),
    'Create discount': translations({
      sv: `Skapa rabattkod`,
      en: `Create discount`,
      de: `Rabatt erstellen`,
    }),
    'Discount code copied!': translations({
      sv: `Rabattkod kopierad! 👍`,
      en: `Discount code copied! 👍`,
      de: `In die Zwischenablage kopiert! 👍`,
    }),
    'Code already in use': translations({
      sv: `Koden används redan`,
      en: `Code already in use`,
      de: `Der Code wird bereits verwendet`,
    }),
    'Add a price category': translations({
      sv: 'Lägg till ett pris',
      en: 'Add a price',
      de: 'Add a price',
    }),
    'Add an accessory': translations({
      sv: 'Lägg till ett tillbehör',
      en: 'Add an accessory',
      de: 'Add an accessory',
    }),
    Pricing: translations({
      sv: 'Prissättning',
      en: 'Pricing',
      de: 'Preisgestaltung',
    }),
    'Save and continue to': translations({
      sv: 'Spara och gå vidare till',
      en: 'Save and continue to',
      de: 'Save and continue to',
    }),
    'Save offer': translations({
      sv: 'Spara erbjudandet',
      en: 'Save offer',
      de: 'Save offer',
    }),
    'When you change accessories for a booking, you...': translations({
      sv: 'När du ändrar en boknings tillbehör behöver du själv anteckna vad som har ändrats om tillbehöret har en annan momssats än erbjudandet. Anledningen är temporär men är relevant för att du ska kunna beräkna rätt moms till din bokföring. Vi arbetar med en lösning så att det här sker automatiskt för framtida bokningar.',
      en: 'When you change accessories for a booking, you need to make a note of what has been changed if the accessory has a different VAT than the offer. The reason is temporary but is relevant for you to be able to correctly calculate the VAT when doing your accounting. We are working on a solution so that this happens automatically for future bookings.',
      de: 'Wenn Sie ein Buchungszubehör ändern, müssen Sie vermerken, was sich geändert hat, wenn das Zubehör einer anderen Mehrwertsteuer als das Angebot unterliegt. Der Grund ist vorübergehend, aber relevant, damit Sie Ihre Buchhaltung korrekt erstellen können. Wir arbeiten an einer Lösung, damit dies bei zukünftigen Buchungen automatisch geschieht.',
    }),
    'When you delete a booking with accessories, you...': translations({
      sv: 'När du raderar en bokning med tillbehör behöver du själv anteckna vad som har tagits bort om något tillbehör har en annan momssats än erbjudandet. Anledningen är temporär men är relevant för att du ska kunna beräkna rätt moms till din bokföring. Vi arbetar med en lösning så att det här sker automatiskt för framtida bokningar.',
      en: 'When you delete a booking with accessories, you need to make a note of what has been removed if any accessory has a different VAT than the offer. The reason is temporary but is relevant for you to be able to correctly calculate the VAT when doing your accounting. We are working on a solution so that this happens automatically for future bookings.',
      de: 'Wenn Sie eine Buchung mit Zubehör löschen, müssen Sie notieren, was entfernt wurde, wenn Zubehör mit Ausnahme des Angebots umsatzsteuerpflichtig war. Der Grund ist vorübergehend, aber relevant, damit Sie Ihre Buchhaltung korrekt erstellen können. Wir arbeiten an einer Lösung, damit dies bei zukünftigen Buchungen automatisch geschieht.',
    }),
    'Assign your inventory to pricecategories on...': translations({
      sv: 'Koppla dina inventarier till priskategorier på nästa flik, "Prissättning". Antal tillgängliga besökare på erbjudandet styrs av dina kopplade inventarier',
      en: 'Assign your inventory to pricecategories on the next tab, "Pricing". Amount of available visitors on the offer is controlled by the assigned inventory',
      de: 'Ordnen Sie Ihr Inventar auf der nächsten Registerkarte "Preisgestaltung" Preiskategorien zu. Die Anzahl der verfügbaren Besucher des Angebots wird durch das zugewiesene Inventar gesteuert',
    }),
    'Service fee': translations({
      sv: 'Serviceavgiften',
      en: 'Service fee',
      de: 'Servicegebühr',
    }),
    'Generated credit codes': translations({
      sv: 'Skapade tillgodokoder',
      en: 'Generated credit codes',
      de: 'Generierte Guthabencodes',
    }),
    'Credit codes': translations({
      sv: 'Tillgodokoder',
      en: 'Credit codes',
      de: 'Kreditcodes',
    }),
    'Credit code': translations({
      sv: 'Tillgodokod',
      en: 'Credit code',
      de: 'Kreditcode',
    }),
    Value: translations({
      sv: 'Värde',
      en: 'Value',
      de: 'Wert',
    }),
    'Value (left)': translations({
      sv: 'Värde (kvar)',
      en: 'Value (left)',
      de: 'Wert (übrig) ',
    }),
    'Below is a list of all credit codes...': translations({
      sv: 'Nedan listas alla tillgodokoder som har genererats när dina kunder har köpt en ombokningsförsäkring och påbörjat sin ombokning genom att avboka en aktivitet.',
      en: 'Below is a list of all credit codes that have been generated when your customers have purchased a rebooking insurance and started their rebooking by canceling an activity.',
      de: 'Nachfolgend finden Sie eine Liste aller Guthabencodes, die generiert wurden, wenn Ihre Kunden eine Umbuchungsversicherung abgeschlossen und ihre Umbuchung durch Stornierung einer Aktivität gestartet haben.',
    }),
    'Temporary handling of accessories for accounting': translations({
      sv: 'Tillfällig hantering av tillbehör för bokföring',
      en: 'Temporary handling of accessories for accounting',
      de: 'Temporary handling of accessories for accounting',
    }),
    'Add discount': translations({
      sv: `Lägg till rabatt`,
      en: `Add discount`,
      de: `Rabatt hinzufügen`,
    }),
    'This booking is not paid...': translations({
      sv: `Denna bokning är inte betald och kan därför inte ombokas`,
      en: `This booking is not paid for and can therefore not be rebooked`,
      de: `Diese Buchung ist nicht bezahlt und kann daher nicht umgebucht werden`,
    }),
    'in-use': translations({
      sv: `Används`,
      en: `In use`,
      de: `Gebraucht`,
    }),
    unavailable: translations({
      sv: `Inte tillgänglig`,
      en: `Unavailable`,
      de: `Nicht verfügbar`,
    }),
    available: translations({
      sv: `Ledig`,
      en: `Available`,
      de: `Freie`,
    }),
    'checked-in': translations({
      sv: `Incheckad`,
      en: `Checked in`,
      de: `Eingecheckt`,
    }),
    'checked-out': translations({
      sv: `Utcheckad`,
      en: `Checked out`,
      de: `Geprüft`,
    }),
    'Select a booking': translations({
      sv: `Välj en bokning`,
      en: `Select a booking`,
      de: `Buchung auswählen`,
    }),
    Search: translations({
      sv: `Sök`,
      en: `Search`,
      de: `Suche`,
    }),
    'All pricecategories must have an assigned inventory': translations({
      sv: 'Alla priskategorier måste ha ett kopplat inventarie',
      en: `All pricecategories must have an assigned inventory`,
      de: `Alle Preiskategorien müssen einen zugewiesenen Bestand haben`,
    }),
    'Edit status': translations({
      sv: 'Redigera status',
      en: `Edit status`,
      de: `Status bearbeiten`,
    }),
    'You do not have permission to': translations({
      sv: 'Du har inte behörighet att',
      en: `You do not have permission to`,
      de: `Sie haben keine berechtigung dazu`,
    }),
    "change this item's status": translations({
      sv: 'ändra status',
      en: `change this item's status`,
      de: `den status dieses elements ändern`,
    }),
    'Checked in': translations({
      sv: 'Incheckad',
      en: `Checked in`,
      de: `Eingecheckt`,
    }),
    'Filter by inventory name, location or booking number...': translations({
      sv: 'Filtrera på inventarienamn, plats eller bokningsnummer. Det går att använda flera sökord.',
      en: 'Filter by inventory name, location or booking number. You can use multiple queries.',
      de: 'Filtern Sie nach Inventarname, Ort oder Buchungsnummer. Sie können mehrere Schlüsselwörter verwenden.',
    }),
    'Remove offer': translations({
      sv: 'Ta bort erbjudandet',
      en: `Remove offer`,
      de: `Löschen sie das angebot`,
    }),
    'upcoming activities with this inventory': translations({
      sv: 'kommande aktiviteter med denna inventarie',
      en: `upcoming activities with this inventory`,
      de: `kommende aktivitäten mit diesem Inventar`,
    }),
    'do not have bookings': translations({
      sv: 'har inga bokningar',
      en: `do not have bookings`,
      de: `keine buchungen haben`,
    }),
    'are booked': translations({
      sv: 'är bokade',
      en: `are booked`,
      de: `gebucht sind`,
    }),
    'To delete this inventory...': translations({
      sv: `För att ta bort inventariet, behöver du ta bort alla framtida aktiviteter.
          Alternativt, vänta tills den sista aktiviteten har passerats för att radera
          inventariet. Den sista aktiviteten avslutas`,

      en: `To remove the inventory, you need to delete all future activities.
          Alternatively, wait until the last activity has passed to delete the
          inventory. The last activity ends`,

      de: `Um das Inventar zu entfernen, müssen Sie alle zukünftigen Aktivitäten löschen.
          Alternativ können Sie warten, bis die letzte Aktivität vergangen ist,
          um das Inventar zu löschen. Die letzte Aktivität endet`,
    }),
    "You can't delete this inventory": translations({
      sv: 'Du kan inte ta bort inventariet',
      en: `You can't delete this inventory`,
      de: `Sie können das inventar nicht entfernen`,
    }),
    'activities using this inventory': translations({
      sv: 'aktiviteter som använder denna inventarie',
      en: `activities using this inventory`,
      de: `aktivitäten die dieses inventar verwenden`,
    }),
    'to delete this inventory, you first need to remove or update the linked activities': translations({
      sv: 'för att ta bort inventeriet måste du först ta bort eller uppdatera de länkade aktiviteterna',
      en: `to delete this inventory, you first need to remove or update the linked activities`,
      de: `Um das Inventar zu löschen, müssen Sie zuerst die verknüpften Aktivitäten löschen oder aktualisieren`,
    }),
    'Linked activities': translations({
      sv: 'Länkad aktiviteter',
      en: `Linked activities`,
      de: `Verknüpfte aktivitäten`,
    }),
    Tag: translations({
      sv: 'Tagg',
      en: 'Tag',
      de: 'Tag',
    }),
    'Propeties are a central part of...': translations({
      sv: 'Anläggningar är en central del av Adventure Hero och du måste minst ha en anläggning för att kunna schemalägga dina aktiviteter.',
      en: 'Propeties are a central part of Adventure Hero and you must have at least one property in order to schedule your activities.',
      de: 'Einrichtungen sind ein zentraler Bestandteil von Adventure Hero und Sie müssen mindestens eine Einrichtung haben, um Ihre Aktivitäten planen zu können.',
    }),
    'In many cases, it is enough to...': translations({
      sv: 'I många fall räcker det att du skapar en anläggning men flera kan exempelvis användas för att administrera anläggningar på olika orter eller bara struktutera dina aktiviteter ytterligare om du har många aktiviteter.',
      en: 'In many cases, it is enough to create one property, but several can be used, for example to administer properties in different locations or just structure your activities further if you have many activities.',
      de: 'In vielen Fällen reicht es aus, eine Einrichtung anzulegen, aber es können auch mehrere verwendet werden, um beispielsweise Einrichtungen an verschiedenen Standorten zu verwalten oder Ihre Aktivitäten bei vielen Aktivitäten weiter zu strukturieren.',
    }),
    'Create places that are relevant to...': translations({
      sv: 'Skapa platser som är relevanta för dig och dina kunder och visa platserna i en karta på din hemsida. För att göra det så enkelt som möjligt för dig så har vi skapat ett Word Press plugin som du kan använda för att lägga till kartan på din hemsida.',
      en: 'Create places that are relevant to you and your customers and display the places in a map on your website. To make it as easy as possible for you, we have created a Word Press plugin that you can use to add the map to your website.',
      de: 'Erstellen Sie Standorte, die für Sie und Ihre Kunden relevant sind, und zeigen Sie die Standorte in einer Karte auf Ihrer Website an. Um es Ihnen so einfach wie möglich zu machen, haben wir ein WordPress-Plugin erstellt, mit dem Sie die Karte zu Ihrer Website hinzufügen können.',
    }),
    'Examples of places can be...': translations({
      sv: 'Exempel på platser kan vara sevärdigheter så som stränder och naturreservat eller liknande.',
      en: 'Examples of places can be places of interest such as beaches and nature reserves or the like.',
      de: 'Beispiele für Orte können Orte von Interesse wie Strände und Naturschutzgebiete oder dergleichen sein.',
    }),
    'Destinations is a feature that allows...': translations({
      sv: 'Destinationer är en funktion som gör att du och dina medakötrer i destinationen kan se varandras scheman och boka varandras aktiviteter. Ni har även möjligheten att lägga upp bokningsförfrågningar på önskade specialtider och när månaden är över så kan ni hämta ut ett faktureringsunderlag.',
      en: "Destinations is a feature that allows you and your colleagues in the destination to see each other's schedules and book each other's activities. You also have the option of posting booking requests at desired special times and when the month is over, you can collect an invoicing document.",
      de: 'Ziele ist eine Funktion, mit der Sie und Ihre Kollegen am Zielort die Zeitpläne der anderen sehen und die Aktivitäten der anderen buchen können. Außerdem haben Sie die Möglichkeit Buchungsanfragen zu gewünschten Sonderzeiten zu stellen und nach Monatsende einen Rechnungsbeleg abzuholen.',
    }),
    'Do you want to create or...': translations({
      sv: 'Vill du skapa eller vara med i en destination? Höra av dig till oss på Adventure Hero så hjälper vi dig att komma igång!',
      en: "Do you want to create or be part of a destination? Get in touch with us at Adventure Hero and we'll help you get started!",
      de: 'Möchten Sie bleiben oder Teil des Reiseziels sein? Kontaktieren Sie uns bei Adventure Hero und wir helfen Ihnen beim Einstieg!',
    }),
    'Choose place': translations({
      sv: 'Välj en plats',
      en: 'Choose a place',
      de: 'Wähle einen Ort',
    }),
    'The activity starts at': translations({
      sv: 'Aktiviteten startar här',
      en: 'The activity starts at',
      de: 'Die Aktivität beginnt um',
    }),
    'The activity ends at': translations({
      sv: 'Aktiviteten slutar här',
      en: 'The activity ends at',
      de: 'Die Aktivität endet um',
    }),
    'Booked inventory': translations({
      sv: 'Bokat inventarie',
      en: 'Booked inventory',
      de: 'gebuchtes Inventar',
    }),
    Quantity: translations({
      sv: 'Kvantitet',
      en: 'Quantity',
      de: 'Anzahl',
    }),
    Outgoing: translations({
      sv: 'Utgående',
      en: 'Outgoing',
      de: 'Ausgehende',
    }),
    Incoming: translations({
      sv: 'Inkommande',
      en: 'Incoming',
      de: 'Eingehende',
    }),
    'Booked inventories': translations({
      sv: 'Bokade inventarier',
      en: 'Booked inventories',
      de: 'Gebuchte Bestände',
    }),
    Reset: translations({
      sv: 'Återställa',
      en: 'Reset',
      de: 'Zurücksetzen',
    }),
    Loading: translations({
      sv: 'Laddar',
      en: 'Loading',
      de: 'Wird geladen',
    }),
    Night: translations({
      sv: 'Övernattning',
      en: 'Night',
      de: 'Übernachtung',
    }),
    Bags: translations({
      sv: 'Väskor',
      en: 'Bags',
      de: 'Taschen',
    }),
    Transfers: translations({
      sv: 'Transporter',
      en: 'Transfers',
      de: 'Überweisungen',
    }),
    'Bags attached to this booking': translations({
      sv: 'Väskor som tillhor bokningen',
      en: 'Bags attached to this booking',
      de: 'Zur buchung gehörende Taschen',
    }),
    'Verify items': translations({
      sv: 'Verifiera',
      en: 'Verify',
      de: 'Verifizieren',
    }),
    'Check in all as': translations({
      sv: 'Checka in alla som',
      en: 'Check in all as',
      de: 'Alles einchecken als',
    }),
    'to this booking?': translations({
      sv: 'till denna bokning',
      en: 'to this booking?',
      de: 'zu dieser buchung?',
    }),
    'Select place': translations({
      sv: 'Välj plats',
      en: 'Select place',
      de: 'Ort wählen',
    }),
    None: translations({
      sv: 'Ingen',
      en: 'None',
      de: 'Keiner',
    }),
    'All route points must be assigned': translations({
      sv: 'Alla ruttpunkter måste tilldelas',
      en: 'All route points must be assigned',
      de: 'Alle routenpunkte müssen zugewiesen werden',
    }),
    'Transfer list': translations({
      sv: 'Transportlista',
      en: 'Transfer list',
      de: 'Transferliste',
    }),
    'All bags updated!': translations({
      sv: 'Alla väskor uppdaterad!',
      en: 'All bags updated!',
      de: 'Alle Taschen aktualisiert!',
    }),
    At: translations({
      sv: 'Vid',
      en: 'At',
      de: 'Bei',
    }),
    'All transfers for': translations({
      sv: 'Alla transporter för',
      en: 'All transports for',
      de: 'Alle Transporte für',
    }),
    'Currently booked inventories for': translations({
      sv: 'Bokade inventarier för',
      en: 'Currently booked inventories for',
      de: 'Gebuchte Termine für',
    }),
    'Filter by transfer name, personnel, booking number, from and to dates...': translations({
      sv: 'Sök efter överföringsnamn, personal, bokningsnummer, från och till datum...',
      en: 'Filter by transfer name, personnel, booking number, from and to dates...',
      de: 'Filtern Sie nach Transfername, Personal, Buchungsnummer, Datum von und bis...',
    }),
    day: translations({
      sv: 'dag',
      en: 'day',
      de: 'tag',
    }),
    'Print List': translations({
      sv: 'Skriv ut listan',
      en: 'Print',
      de: 'Drucken',
    }),
    By: translations({
      sv: 'Om du',
      en: 'By',
      de: 'durch',
    }),
    'Click here': translations({
      sv: 'Klicka här',
      en: 'Click here',
      de: 'Klick hier',
    }),
    'if you want to get access to Borås ticket statistics.': translations({
      sv: 'om du vill få tillgång till Borås biljettstatistik.',
      en: 'if you want to get access to Borås ticket statistics.',
      de: 'wenn Sie Zugang zu Borås Ticketstatistiken erhalten möchten.',
    }),
    'to retrieve information about payouts sent to your bank account.': translations({
      sv: 'för att få tillgång till utbetalningar.',
      en: 'to retrieve information about payouts sent to your bank account.',
      de: 'um Informationen über Auszahlungen abzurufen, die an Ihr Bankkonto gesendet wurden.',
    }),
    'Enable the option for customers to make a booking request': translations({
      sv: 'Aktivera möjlighet för kund att göra en bokningsförfrågan',
      en: 'Enable the option for customers to make a booking request',
      de: 'Aktivieren Sie die Option für Kunden, eine Buchungsanfrage zu stellen',
    }),
    'Enter the minimum charge for a booking request': translations({
      sv: 'Ange minimidebitering för en bokningsförfrågan',
      en: 'Enter the minimum charge for a booking request',
      de: 'Geben Sie die Mindestgebühr für eine Buchungsanfrage ein',
    }),
    'Minimum charge at booking request': translations({
      sv: 'Minimidebitering vid bokningsförfrågan',
      en: 'Minimum charge at booking request',
      de: 'Mindestgebühr bei Buchungsanfrage',
    }),
    'By activating this, a customer can create a booking request...': translations({
      sv: 'Genom att aktivera detta kan en kund skapa en bokningsförfrågan och själv ange datum och tid som de önskar boka utanför schemalagda tider.',
      en: 'By activating this, a customer can create a booking request and enter the date and time they wish to book outside of scheduled times.',
      de: 'Durch die Aktivierung kann ein Kunde eine Buchungsanfrage erstellen und das Datum und die Uhrzeit eingeben, die er außerhalb der geplanten Zeiten buchen möchte.',
    }),
    'By activating this, the customers booking request is created using text only...': translations({
      sv: 'Genom att aktivera detta skapas kundens bokningsförfrågan endast genom fritext. Kunden väljer inga biljetter utan behöver ange all information i fritext. Tydliggör därför i textrutan nedan vilken information ni behöver från kunden för att kunna ta ställning till bokningsförfrågan.',
      en: "By activating this, the customer's booking request is created using text only. The customer does not select any tickets but needs to enter all information in text. Therefore, clarify in the text box below which information you need from the customer in order to handle the booking request.",
      de: 'Durch die Aktivierung wird die Buchungsanfrage des Kunden rein textuell erstellt. Der Kunde wählt keine Tickets aus, sondern muss alle Informationen in Textform eingeben. Klären Sie daher im Textfeld unten, welche Informationen Sie vom Kunden benötigen, um die Buchungsanfrage zu bearbeiten.',
    }),
    'Write a message to the customer': translations({
      sv: 'Skriv ett meddelande till kunden',
      en: 'Write a message to the customer',
      de: 'Schreiben Sie eine Nachricht an den Kunden',
    }),
    'Enable the option for customers to make a flexible booking request': translations({
      sv: 'Aktivera möjlighet för kund att göra en flexibel bokningsförfrågan',
      en: 'Enable the option for customers to make a flexible booking request',
      de: 'Aktivieren Sie die Option für Kunden, eine flexible Buchungsanfrage zu stellen',
    }),
    'Flexible booking request': translations({
      sv: 'Flexibel bokningsförfrågan',
      en: 'Flexible booking request',
      de: 'Flexible Buchungsanfrage',
    }),
    'Message to customer at flexible booking request': translations({
      sv: 'Meddelande till kund vid flexibel bokningsförfrågan',
      en: 'Message to customer at flexible booking request',
      de: 'Nachricht an den Kunden bei flexibler Buchungsanfrage',
    }),
    Activated: translations({
      sv: 'Aktiverad',
      en: 'Activated',
      de: 'Aktiviert',
    }),
    'Not activated': translations({
      sv: 'Inte aktiverad',
      en: 'Not activated',
      de: 'Nicht aktiviert',
    }),
    // Translations for other modules
    ...kosmicTranslationCollection,
    ...ahWidgetCollection,
  });

export type AHLocaleContext = LocaleContextState<typeof collection>;

export const useLocale = () => useContext<AHLocaleContext>(LocaleContext);
type CollectionKey = keyof typeof collection;

export const isKeyOfAHLocaleContext = (key: string | CollectionKey): key is CollectionKey => {
  return key in collection;
};

/** Translation function that can be passed to utility functions */
export type Tfunc = AHLocaleContext['t'];
