import { Types } from 'mongoose';
import React, { useContext, useEffect, useState } from 'react';
import { UpdateSelectedOfferFunction } from '../../../../../contexts/offers.context';
import {
  Accordion,
  ActivityTypeDocument,
  Field,
  getClientBelongingsPriceCategory,
  GlobalsContext,
  PlaceDocument,
  priceCategoriesHasClientBelongingPriceCategory,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { useIsFirstRender } from '../../../../hooks/useIsFirstRender';
import BetaFunctionalitySign from '../../../betaFunctionalitySign';
import { AddClientBelongingsField } from '../createPriceCategories/addClientBelongingsField';
import SelectRouteBreakPointPlaceInput from './selectRouteBreakpointPlaceInput';

interface Props {
  selectedOffer: ActivityTypeDocument;
  places: PlaceDocument[];
  updateSelectedOffer: UpdateSelectedOfferFunction;
}

export interface BreakPoint {
  place: PlaceDocument | undefined;
  id: Types.ObjectId;
}

const RouteCreatorField: React.FC<Props> = ({ places, updateSelectedOffer, selectedOffer }) => {
  const { t } = useLocale();
  const globalContext = useContext(GlobalsContext);
  const { features } = globalContext.session.currentOrganization.flags;
  const [routeBreakPoints, setRouteBreakPoints] = useState<BreakPoint[]>([]);
  const [shouldHandleClientBelongings, setShouldHandleClientBelongings] = useState<boolean>(false);
  const [addingRoutes, setAddingRoutes] = useState<boolean>(false);

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (selectedOffer?.route && selectedOffer?.route?.length > 1) {
      const breakPoints = buildBreakPointsFromRoute(selectedOffer?.route, places);
      setRouteBreakPoints(breakPoints);
      if (priceCategoriesHasClientBelongingPriceCategory(selectedOffer?.priceCategories)) {
        setShouldHandleClientBelongings(true);
      }
    } else {
      resetBreakPoints(shouldHandleClientBelongings);
    }
  }, [places]);

  useEffect(() => {
    if (isFirstRender) return;
    resetBreakPoints(shouldHandleClientBelongings);
  }, [shouldHandleClientBelongings]);

  useEffect(() => {
    if (isFirstRender) return;
    if (selectedOffer) {
      const itShould = priceCategoriesHasClientBelongingPriceCategory(selectedOffer?.priceCategories);
      setShouldHandleClientBelongings(itShould);
    }
  }, [selectedOffer?.priceCategories]);

  const resetBreakPoints = (shouldTrackClientBelongings: boolean) => {
    let nights = 0;
    if (shouldTrackClientBelongings) nights = getNumberOfNightsOnActivity(selectedOffer?.duration);
    if (!hasRightAmountOfRouteBreakPoints(nights, routeBreakPoints.length, shouldTrackClientBelongings)) {
      const newRouteBreakPoints = getRouteBreakPoints(nights);
      setRouteBreakPoints(newRouteBreakPoints);
    }
  };

  const updateBreakPointState = (indexPosition: number, id: string) => {
    const newPlace = places.find((p) => String(p._id) == String(id));
    if (newPlace) {
      const breakpointsClone = [...routeBreakPoints];
      breakpointsClone[indexPosition].place = newPlace;
      updateSelectedOffer(
        'route',
        breakpointsClone.map((i) => Types.ObjectId(i.place?.id)),
      );
      setRouteBreakPoints(breakpointsClone);
    }
  };

  const addOrRemoveCheckInLuggage = () => {
    if (!selectedOffer) return;
    if (!shouldHandleClientBelongings) {
      const updatedCategories = [...selectedOffer?.priceCategories, getClientBelongingsPriceCategory()];
      updateSelectedOffer('priceCategories', updatedCategories);
    } else {
      const updatedCategories = selectedOffer.priceCategories.filter(
        (c) => c.id !== getClientBelongingsPriceCategory().id,
      );
      updateSelectedOffer('priceCategories', updatedCategories);
    }
  };

  if (!selectedOffer) return null;

  return (
    <Accordion
      onStateChanged={(bool: boolean) => setAddingRoutes(bool)}
      title={
        <h4 className="ui header">
          <i className="dropdown icon" /> {t('Add routes')}
          <BetaFunctionalitySign />
        </h4>
      }
    >
      <Field>
        <div className="ui message" style={{ marginTop: 0 }}>
          <p className="content">{t('Give your employees more control over your inventories by adding a route...')}</p>
        </div>
      </Field>
      <br />
      <br />
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        {routeBreakPoints.map((breakPoint, index) => {
          // If start position
          if (index === 0) {
            return (
              <SelectRouteBreakPointPlaceInput
                icon="flag outline"
                breakPoint={breakPoint}
                key={breakPoint.id.toString()}
                indexPosition={index}
                onBreakPointPlaceChanged={updateBreakPointState}
                title={t('The activity starts at')}
                places={places}
                style={'primary'}
                addingRoutes={addingRoutes}
              />
            );
          }

          // If end position
          if (index === routeBreakPoints.length - 1) {
            return (
              <SelectRouteBreakPointPlaceInput
                icon="flag checkered"
                breakPoint={breakPoint}
                key={breakPoint.id.toString()}
                indexPosition={index}
                onBreakPointPlaceChanged={updateBreakPointState}
                title={t('The activity ends at')}
                places={places}
                style={'primary'}
                addingRoutes={addingRoutes}
              />
            );
          }

          // If night stop
          return (
            <SelectRouteBreakPointPlaceInput
              icon="moon"
              breakPoint={breakPoint}
              key={breakPoint.id.toString()}
              indexPosition={index}
              onBreakPointPlaceChanged={updateBreakPointState}
              title={`${t('Night')} ${index}`}
              places={places}
              style={'secondary'}
              addingRoutes={addingRoutes}
            />
          );
        })}
      </div>

      <br />

      {features.handleClientBelongings && (
        <AddClientBelongingsField
          checked={shouldHandleClientBelongings}
          {...{ addOrRemoveCheckInLuggage }}
          updateSelectedOffer={updateSelectedOffer}
        />
      )}
    </Accordion>
  );
};

export default RouteCreatorField;

const getRouteBreakPoints = (nights: number) => {
  const startStopAndNightPositions = nights + 2;
  const routeBreakPoints: BreakPoint[] = [];
  for (let index = 0; index < startStopAndNightPositions; index++) {
    routeBreakPoints.push({ id: Types.ObjectId(), place: undefined });
  }
  return routeBreakPoints;
};

const getNumberOfNightsOnActivity = (durationInMin: number | undefined) => {
  if (durationInMin) return Math.floor(durationInMin / 60 / 24);
  return 0;
};

function buildBreakPointsFromRoute(route: Types.ObjectId[], places: PlaceDocument[]) {
  return route.map((placeId) => ({ id: Types.ObjectId(), place: places.find((p) => p._id == placeId) }));
}

function hasRightAmountOfRouteBreakPoints(
  nights: number | undefined,
  routeBreakPoints: number,
  handleClientBelongings: boolean,
) {
  if (handleClientBelongings && nights) return nights + 1 === routeBreakPoints;
  if (!handleClientBelongings) return routeBreakPoints === 2;
}
