import React from 'react';
import { CSSProperties, useContext } from 'react';
import { SignupForm } from '../authentication/signupForm';
import { useLocale } from '../../_locales';
import { DeviceProvider } from '../../_dependencies';

function CreateAccountFooter() {
  const device = useContext(DeviceProvider.Context);
  const { t } = useLocale();

  return (
    <div style={containerStyle}>
      <div style={slantedDivStyle}>
        <div className=" ui container grid" style={{ transform: 'skewY(3deg)' }}>
          <div className="column" style={columnStyle}>
            {device.size != 'mobile' ? <h2 style={headerStyle}>{t('Get started today')}!</h2> : undefined}
            <div style={device.size == 'mobile' ? signupFormMobile : signupFormStyle}>
              <SignupForm
                textColor={device.size == 'mobile' ? 'white' : 'black'}
                titleColor={device.size == 'mobile' ? 'white' : 'black'}
                formLocation="Bottom"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAccountFooter;

const containerStyle: CSSProperties = {
  position: 'relative',
  overflow: 'visible',
  zIndex: 1,
  backgroundColor: '#444',
  backgroundImage: 'url(/static/platform/img/texture-bg.png)',
  marginTop: '10rem',
  padding: '8rem 0',
  height: '55rem',
};

const slantedDivStyle: CSSProperties = {
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
  background: 'inherit',
  zIndex: -1,
  top: 0,
  transformOrigin: 'left top',
  transform: 'skewY(-3deg)',
};

const headerStyle: CSSProperties = {
  color: 'white',
  margin: '5rem 0',
  width: '100%',
  textAlign: 'center',
};

const columnStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

const signupFormStyle: CSSProperties = {
  backgroundColor: 'FCFCFC',
  borderRadius: '0.5rem',
  display: 'flex',
  flexGrow: 1,
  width: '50rem',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '2rem',
};

const signupFormMobile: CSSProperties = {
  background: 'inherit',
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  flexDirection: 'column',
  padding: '5rem 2rem',
};
