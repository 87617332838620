import React from 'react';
import { BookingResource } from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { Header } from './Header';
import { BookingListFilter, SearchParams } from './filtering';
import { HandleBookingsComponent } from './handleBookings';

export const HandleDestinationBookingsPage = () => {
  const { t } = useLocale();
  const title = t('Manage bookings you have made at a partner');
  const sub = t("You can search and manage bookings you've made...");

  /** Collects all bookings from filter. If getDeletedBookings is true, only deleted bookings is collected */
  const fetchBookings = async (options: {
    fromDate?: Date;
    toDate?: Date;
    getDeletedBookings?: boolean;
    getRequestBookings?: boolean;
    getUninvoicedBookings?: boolean;
  }) => {
    console.log(options);
    return await new BookingResource().findCrossSellingBookings(options);
  };

  const renderBookingList = (
    setSearchParams: (searchParams: SearchParams) => void,
    setPeriod: (fromDate?: Date | undefined, toDate?: Date | undefined) => void,
    setShowOnlyDeletedBookings: (showOnlyDeletedBookings: boolean) => void,
    setShowRequestBookings: (showRequestBookings: boolean) => void,
    setShowUninvoicedBookings: (showUninvoicedBookings: boolean) => void,
    showRequestBookings?: boolean,
    showOnlyDeletedBookings?: boolean,
    showUninvoicedBookings?: boolean,
    toDate?: Date,
    fromDate?: Date,
  ) => {
    return (
      <BookingListFilter
        toDate={toDate}
        fromDate={fromDate}
        filter={setSearchParams}
        onNewPeriod={setPeriod}
        setShowOnlyDeletedBookings={setShowOnlyDeletedBookings}
        setShowRequestBookings={setShowRequestBookings}
        setShowUninvoicedBookings={setShowUninvoicedBookings}
        showUninvoicedBookings={showUninvoicedBookings}
        showRequestBookings={showRequestBookings}
        showOnlyDeletedBookings={showOnlyDeletedBookings}
        bookingsOnlyMadeOnOtherOrganizations
        hideAdditionalFilters
      />
    );
  };

  const renderHeader = () => <Header title={title} sub={sub} />;

  return (
    <HandleBookingsComponent
      findBookings={fetchBookings}
      renderBookingList={renderBookingList}
      renderHeader={renderHeader}
    />
  );
};
