import React, { CSSProperties, useEffect, useState } from 'react';
import { TaxRate, PaymentResource, Field, Platforms } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface Props {
  taxRate: TaxRate;
  setTaxRate: (key: 'taxRate', value: TaxRate) => void;
  /** Padding for each button */ padding?: CSSProperties['padding'];
  hideLabel?: boolean;
}

export const TaxRateFields = ({ taxRate, setTaxRate, padding, hideLabel }: Props) => {
  const { t } = useLocale();
  const availableTaxRates = Platforms.taxRates;
  const [selectedTaxRate, setSelectedTaxRate] = useState<TaxRate>(taxRate ?? availableTaxRates[0]);

  // Whenever the local state is updated, update the selected offer in context
  // This is so that the selected offer is always up to date.
  // I initially tried a more slim solution, but it wouldn't update context if user selected 0% VAT but as a sideeffect seems to work
  useEffect(() => {
    setTaxRate('taxRate', selectedTaxRate);
  }, [selectedTaxRate]);

  const buttons = (
    <div className="ui buttons">
      {availableTaxRates.map((rate: TaxRate) => (
        <div
          key={rate}
          className={'ui button ' + (selectedTaxRate === rate ? 'primary' : '')}
          onClick={() => setSelectedTaxRate(rate)}
          style={{ padding }}
        >
          {Math.round(rate * 100)}
          {!hideLabel && '%'}
        </div>
      ))}
    </div>
  );

  if (hideLabel) return buttons;
  return <Field label={t('VAT-Rate')}>{buttons}</Field>;
};
