import React from 'react';
import { BadgeProvider, IBadge } from '../../../contexts';
import {
  BadgeDocument,
  Consume,
  Dropdown,
  DropdownItem,
  LocaleContext,
  MobxComponent,
  OrganizationDocument,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';

interface IProps {
  organization: OrganizationDocument;
}
interface IState {
  badges?: BadgeDocument[];
}

export class BadgesDropdown extends MobxComponent<IProps, IState> {
  @Consume(BadgeProvider.Context)
  private badges: IBadge;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  public async componentDidMount() {
    const badges = await this.badges.get();
    this.setState({ badges });
  }

  private onChange = async (value: string) => {
    const badges = value.split(',');
    const organization = this.props.organization;
    await this.badges.assign(organization, badges);
  };

  private get value() {
    const organization = this.props.organization;
    if (!organization.badges) {
      return '';
    }
    return organization.badges.join(',');
  }

  public render() {
    const { t } = this._locale;
    if (!this.state || !this.state.badges) {
      return <div className="ui inline center loader active" />;
    }

    if (!this.state.badges.length) {
      return <div className="ui center">{t('components.admin.organizations.tableRow.noBadges')}</div>;
    }

    return (
      <Dropdown
        onChange={this.onChange}
        value={this.value}
        multiple
        name="badges"
        style={{ maxWidth: '230px', width: '230px' }}
      >
        {this.state.badges.map((badge) => (
          <DropdownItem value={badge.id} key={badge.id}>
            {/*<img className="ui avatar image" src={badge.imageUrl} />*/}
            {badge.label}
          </DropdownItem>
        ))}
      </Dropdown>
    );
  }
}
