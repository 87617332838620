import React from 'react';
import {
  InventoryStatusDocument,
  InventoryStatusType,
  TemporaryInventoryStatusType,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface Props {
  isTemporary?: boolean;
  currentStatus?: InventoryStatusDocument;
  onChange: (status: string) => void;
}

export const StatusButtonsSegment = ({ currentStatus, onChange, isTemporary }: Props) => {
  const { t } = useLocale();

  return (
    <div style={{ marginBottom: '1rem', backgroundColor: '#FFF', borderRadius: '0.5em' }}>
      <div style={statusButtonsStyle}>
        {getInventoryStatuses(isTemporary).map((status, i) => {
          return (
            <button
              key={`${status}-${i}`}
              style={buttonStyling}
              className={
                status == currentStatus?.status
                  ? 'ui fluid green labeled icon button'
                  : 'ui basic fluid labeled icon button'
              }
              onClick={() => onChange(status)}
            >
              <i className={`${setStatusColors(status, isTemporary)} circle icon`} />
              {t(status)}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const setStatusColors = (
  status: InventoryStatusType | TemporaryInventoryStatusType,
  isTemporary: boolean | undefined,
): string => {
  if (isTemporary) {
    return status === 'checked-in' ? 'olive ' : status === 'checked-out' ? 'yellow ' : 'white ';
  }
  return status === 'available'
    ? 'olive '
    : status === 'in-use'
    ? 'yellow '
    : status === 'unavailable'
    ? 'red '
    : 'white ';
};

function getInventoryStatuses(
  isTemporary: boolean | undefined,
): InventoryStatusType[] | TemporaryInventoryStatusType[] {
  return isTemporary ? ['checked-in', 'checked-out'] : ['available', 'in-use', 'unavailable'];
}

const statusButtonsStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '0.5em 1em',
  marginLeft: '',
};

const buttonStyling: React.CSSProperties = {
  margin: '0.5em 0em',
  padding: '1em',
  whiteSpace: 'nowrap',
  textAlign: 'center',
};
