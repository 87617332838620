import Lodash from 'lodash';
import { observable, runInAction } from 'mobx';
import React from 'react';
import Shortid from 'shortid';
import {
  ActivityTypeDocument,
  ActivityTypeResource,
  Consume,
  LocaleContext,
  MobxComponent,
  OrganizationDocument,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import DashboardTemplate from '../dashboardTemplate';

export { OfferDetails } from './details';
export { NewOfferView } from './new';

export class OffersIndex extends MobxComponent<{ addTabHandler: any }> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @observable private _loading = true;
  @observable private _activityTypes: ActivityTypeDocument[];

  private handleRowClicked = (offer: ActivityTypeDocument) => {
    this.globals.session.navigateTo('/dashboard/offers/details/' + offer.id);
  };

  componentDidMount() {
    const { tt } = this._locale;
    new ActivityTypeResource()
      .find({ organization: this.globals.session.currentOrganizationId, isArchived: { $ne: true } })
      .then((activityTypes) => {
        runInAction(() => {
          this._activityTypes = Lodash.sortBy(activityTypes, (activityType) => tt(activityType.title));
          this._loading = false;
        });
      });
  }

  render() {
    const { t, tt } = this._locale;
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { features } = organization.flags;
    if (!features.offers) {
      this.globals.session.navigateTo('/dashboard');
      return null;
    }
    return (
      <div style={{ padding: '2em' }}>
        <h2>
          {t('components.dashboard.offers.index.allOffers')} {this.globals.session.currentOrganization.name}
        </h2>
        <div
          className={'ui basic segment ' + (this._loading ? 'loading' : '')}
          style={{ padding: '0em', paddingTop: '1em' }}
        >
          <table className="ui basic selectable padded table" style={{ border: 'none' }}>
            <thead>
              <tr>
                <th>
                  <h3 className="ui header">{t('Offer')}</h3>
                </th>
                <th>
                  <h3 className="ui header">{t('components.dashboard.offers.index.numberOfPlaces')}</h3>
                </th>
                <th>
                  <h3 className="ui header">{t('Price categories')}</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              {Lodash.map(this._activityTypes, (activityType) => {
                return (
                  <tr
                    key={activityType.id}
                    className="center aligned"
                    onClick={() => this.handleRowClicked(activityType)}
                  >
                    <td className="left aligned">
                      <h4 className="ui header" style={{ cursor: 'pointer' }}>
                        {tt(activityType.title)}
                      </h4>
                    </td>
                    <td className="left aligned">{activityType.visitorCapacity}</td>
                    <td className="left aligned">
                      {Lodash.map(activityType.priceCategories || [], (priceCategory) => {
                        return (
                          <div
                            key={`price-category-${Shortid.generate()}`}
                            className=" single line"
                            style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '0.5em' }}
                          >
                            <span>{tt(priceCategory.name) + ':'}</span>
                            <span style={{ minWidth: '1em' }} />
                            <span>
                              {priceCategory.price} {t('SEK')}
                            </span>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export class OffersTemplate extends MobxComponent<any> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  render() {
    const { t } = this._locale;
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { features } = organization.flags;

    let links = [
      {
        path: '/dashboard/offers',
        text: t('components.dashboard.offers.index.listOfOffers'),
        icon: 'star icon',
      },
    ];

    links = [
      ...links,
      {
        path: '/dashboard/offers/inventories',
        text: t('Handle inventories'),
        icon: 'cubes icon',
      },
      ...(features.routes
        ? [
            {
              path: '/dashboard/offers/inventorylocationlist',
              text: t('Booked inventories'),
              icon: 'map pin icon',
            },
          ]
        : []),
      ...(features.handleClientBelongings
        ? [
            {
              path: '/dashboard/offers/transferlist',
              text: t('Transfer list'),
              icon: 'map outline icon',
            },
          ]
        : []),
    ];

    const rightLink = {
      path: '/dashboard/offers/new',
      text: t('components.dashboard.offers.index.createOffer'),
      icon: 'plus icon',
    };

    return (
      <DashboardTemplate title={t('Offers')} icon="star outline icon" tabs={links} rightTab={rightLink}>
        {this.props.children}
      </DashboardTemplate>
    );
  }
}
