import * as React from 'react';
import { PurchaseWidget, KosmicComponent, Consume, LocaleContext } from '../../../_dependencies';

import { PropertyDropdown } from '../../../components/common/property/propertydropdown';
import { AHLocaleContext } from '../../../_locales';

interface State {
  selectedPropertyID?: string;
}

export class BookingView extends KosmicComponent<{}, State> {
  public state: State = {};

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private onPropertyChanged = (selectedPropertyID: string) => this.setState({ selectedPropertyID });

  public render() {
    const { t } = this._locale;
    const { selectedPropertyID } = this.state;
    return (
      <div className="ui basic segment" style={{ padding: '2em' }}>
        <h2 className="ui header">
          {t('Create a booking')}
          <div className="sub header">{t('components.dashboard.bookings.bookingView.messageOne')}</div>
        </h2>
        <h4 className="ui header" style={{ marginBottom: '0.5em' }}>
          {t('Select property')}
        </h4>

        <PropertyDropdown onChange={this.onPropertyChanged} value={selectedPropertyID} />

        <div style={{ height: '1em' }} />
        {selectedPropertyID && (
          <PurchaseWidget key={selectedPropertyID} initalType="bookings" propertyId={selectedPropertyID} />
        )}
      </div>
    );
  }
}
