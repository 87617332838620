import { findIndex } from 'lodash';
import { observable } from 'mobx';
import { SingletonStore } from '../../singletonStore';
import { ErrorType, IError } from './modalError';

class AuthenticationModalStore extends SingletonStore {
  @observable public signUp: boolean;
  @observable public isSubscribedForCustomerNewsletters = false;
  @observable public errorMsg = '';
  @observable public modalErrors: Array<IError> = [];

  public addError = (err: IError) => {
    const index = findIndex(this.modalErrors, (error) => error.type === err.type);
    if (index === -1) return this.modalErrors.push(err);
    else return (this.modalErrors[index] = err);
  };

  public clearError = (type: ErrorType) => {
    const index = findIndex(this.modalErrors, (error) => error.type === type);
    if (index === -1) return;
    else return this.modalErrors.splice(index, 1);
  };

  public clearAllErrors = () => {
    this.modalErrors = [];
  };

  public openSignUpModal = () => {
    this.globals.session.isAuthenticated().then((status) => {
      if (!status) {
        this.signUp = true;
        $('.coupled.modal').modal({ allowMultiple: false });
        $('.first.modal').modal('show');
      }
    });
  };

  public openLogInModal = () => {
    this.globals.session.isAuthenticated().then((status) => {
      if (!status) {
        this.signUp = false;
        $('.ui.second.modal').modal('show');
      }
    });
  };

  public hideModal = () => {
    this.clearAllErrors();
    $('.ui.first.modal').modal('hide');
    $('.ui.second.modal').modal('hide');
    $('.ui.third.modal').modal('hide');
  };

  public refreshModal = () => {
    let interval: any;
    setTimeout(() => {
      clearInterval(interval);
    }, 1500);

    interval = setInterval(() => {
      $('.first.modal').modal('refresh');
      $('.second.modal').modal('refresh');
      $('.third.modal').modal('refresh');
    }, 10);
  };
}

export default AuthenticationModalStore.Instance as AuthenticationModalStore;
