import Lodash from 'lodash';

/** A simple static scroll helper... */
export class ScrollHelper {
  /* Next properties */
  private static targetId?: string;
  private static top?: number;
  private static offset?: number;
  private static duration?: number;
  private static delay?: number;

  public static get hasStoredValue() {
    return this.targetId || this.top;
  }

  /** Set the next scroll target, needs to be an html element ID, before navigating to a new page */
  public static setNextScrollTarget(targetId: string, offset?: number, duration?: number, delay?: number) {
    this.targetId = targetId;
    this.offset = offset;
    this.duration = duration;
    this.delay = delay;
  }

  /** Set the next scroll location before navigation to a new page */
  public static setNextScrollLocation(top: number, offset?: number, duration?: number) {
    this.top = top;
    this.offset = offset;
    this.duration = duration;
  }

  /** Scroll to stored location if any, then removes stored location. Useful in componentDidMount/componentDidUpdate */
  public static scrollToStoredLocation() {
    if (this.targetId !== undefined) {
      setTimeout(() => {
        const top = $('#' + this.targetId).offset()?.top || 0;
        const pushers: JQuery = $('.pusher');
        const pusherScrollTops: number[] = Lodash.map(pushers, (pusher: any) => pusher.scrollTop);
        const alreadyScrolled = Math.max(...pusherScrollTops, 0);
        this.scroll(top + alreadyScrolled, this.offset, this.duration);
        this.targetId = undefined;
      }, this.delay || 0);
    }
    if (this.top !== undefined) {
      this.scroll(this.top, this.offset, this.duration);
      this.top = undefined;
    }
  }

  public static scrollToTop(offset?: number, duration?: number) {
    this.scroll(0, offset, duration);
  }

  private static scroll(top: number, offset = 0, duration = 0) {
    $('.pusher').animate({ scrollTop: top + offset }, duration);
  }
}
