import * as React from 'react';
import { map } from 'lodash';
import {
  DropdownItem,
  Dropdown,
  PropertyDocument,
  PropertyResource,
  Consume,
  LocaleContext,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';

interface Props {
  value?: string;
  onChange: (pid: string) => void;
}

interface State {
  availableProperties: PropertyDocument[];
}

export class PropertyDropdown extends React.Component<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  state: State = {
    availableProperties: [],
  };

  public async componentDidMount() {
    const availableProperties = await new PropertyResource().getAdministratedProperties();
    await this.setState({ availableProperties });
  }

  public render() {
    const { availableProperties = [] } = this.state;
    const { tt } = this._locale;

    return (
      <Dropdown
        onChange={this.props.onChange}
        value={this.props.value || ''}
        loading={!this.state.availableProperties}
        name="Anläggning"
        icon="map pin"
      >
        {map(availableProperties, (property) => (
          <DropdownItem key={property.id} value={property.id}>
            {tt(property.name)}
          </DropdownItem>
        ))}
      </Dropdown>
    );
  }
}
