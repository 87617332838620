import React from 'react';
import { Checkbox } from '../../../_dependencies';
import { useLocale } from '../../../_locales';

interface Props {
  checked: boolean;
  onToggle: () => void;
  hide?: boolean;
}

export const CustomTotalPriceCheckbox = ({ checked, onToggle, hide: isBookingRequest }: Props) => {
  const { t } = useLocale();

  if (isBookingRequest) return null;

  return (
    <div style={{ ...spaceBetweenStyle, marginTop: '.5rem' }}>
      <span>{t('Custom total price')}</span>
      <Checkbox toggle checked={checked} onCheckedOrUncheked={onToggle} />
    </div>
  );
};

const spaceBetweenStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
