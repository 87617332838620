import SchemaNames from '../schemas/names';
import { AbstractUserDocument, Resource } from '../_dependencies';
import { OrganizationDocument } from './organization.resource';

export class AccessabilityResource extends Resource {
  constructor() {
    super();
    this.setName(SchemaNames.Accessability);
  }

  initiatePasswordReset(username: string) {
    return this.sendRequest<boolean>('/initiatePasswordReset', 'post', { username: username });
  }

  inviteNewEmployee(username: string, organizationDocument: OrganizationDocument) {
    return this.sendRequest<boolean>('/inviteNewEmployee', 'post', {
      username: username,
      organizationDocument: organizationDocument,
    });
  }

  createCustomer(userDocument: AbstractUserDocument, password: string) {
    return this.sendRequest<{ avatar: string; name: string }[]>('/createCustomer', 'post', { userDocument, password });
  }
}
