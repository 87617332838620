import Lodash from 'lodash';
import { action, observable } from 'mobx';
import * as React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityTypeDocument,
  MobxComponent,
  Platforms,
  PropertyDocument,
  tt,
} from '../../../_dependencies';
import { globalT, globalCurrentLocale } from '../../../_locales';

interface Props {
  property: PropertyDocument;
  activityTypes: ActivityTypeDocument[];
}

export class ActivityMapCard extends MobxComponent<Props> {
  @observable private currentActivityIndex: number;
  @observable private lastIndexOfActivities: number;

  private get selectedActivity() {
    return this.props.activityTypes[this.currentActivityIndex];
  }

  @action private nextActivity = () => {
    this.currentActivityIndex += 1;
    this.forceUpdate(); // TODO: Why doesn't mobx update?
  };

  @action private prevActivity = () => {
    this.currentActivityIndex -= 1;
    this.forceUpdate(); // TODO: Why doesn't mobx update?
  };

  private get imageUrl() {
    return this.selectedActivity.imageUrl;
  }

  private get price() {
    const categoryWithLowestPrice = Lodash.minBy(
      (this.selectedActivity as any as ActivityOccuranceDocument).priceCategories,
      ({ price }) => price,
    );

    if (!categoryWithLowestPrice) {
      return null;
    }

    return (
      <span>
        {globalT('From')} {categoryWithLowestPrice.price} {globalT('SEK')}
      </span>
    );
  }

  private get navigatePrevActivity() {
    if (this.props.activityTypes.length <= 1) return null;
    return (
      <div
        className={'ui button ' + (this.currentActivityIndex == 0 ? 'disabled' : '')}
        style={{
          backgroundColor: 'white',
          paddingRight: '.5em',
          paddingLeft: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={this.prevActivity}
      >
        <i className="ui chevron left icon" />
      </div>
    );
  }

  private get navigateNextActivity() {
    if (this.props.activityTypes.length <= 1) return null;
    return (
      <div
        className={'ui button ' + (this.currentActivityIndex == this.lastIndexOfActivities ? 'disabled' : '')}
        style={{
          backgroundColor: 'white',
          paddingRight: 0,
          paddingLeft: '.5em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={this.nextActivity}
      >
        <i className="ui chevron right icon" />
      </div>
    );
  }

  private navigateToPropertyView = () => {
    this.globals.session.navigateTo('/activities/booking/' + this.props.property.id + '/' + this.selectedActivity.id);
  };

  private initActivityPaging = (props: Props) => {
    this.currentActivityIndex = 0;
    this.lastIndexOfActivities = props.activityTypes.length - 1;
  };

  UNSAFE_componentWillMount() {
    this.initActivityPaging(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.initActivityPaging(nextProps);
  }

  render() {
    const title = tt(this.selectedActivity.title, globalCurrentLocale());

    return (
      <div className={'ui fluid card'}>
        <div className="image" style={{ minHeight: '169px', position: 'relative' }}>
          <img src={this.imageUrl} title={'Boka ' + title} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', paddingRight: 0, paddingLeft: 0, margin: '1em' }}>
          {this.navigatePrevActivity}
          <div style={{ width: '100%' }}>
            <div className="left floated header" style={{ margin: '0px', fontSize: '1em' }}>
              <h3 className="title" style={{ marginBottom: '7px' }}>
                {title}
              </h3>
              <br />
              <span className="left floated header price" style={{ marginTop: '0px' }}>
                {this.price}
              </span>
            </div>
          </div>
          {this.navigateNextActivity}
        </div>
        <div className="extra content">
          <div className="twelve wide column" style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.75em' }}>
            <span className="left floated header subtext">{`${globalT('presented-by')}: `}</span>
            <span className="left floated header property-name" style={{ marginLeft: '4px' }}>
              {`${tt(this.props.property.name, globalCurrentLocale())}`}
            </span>
          </div>
          <div className="ui grey fluid button" style={{ color: 'white' }} onClick={this.navigateToPropertyView}>
            {globalT('Book')}
          </div>
        </div>
      </div>
    );
  }
}
