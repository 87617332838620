import { Atom, computed } from 'mobx';

import { Store } from './_abstractStore';

export class DeviceManager extends Store {
  private _atom: Atom;
  private _width: number;
  private _height: number;
  private _userAgent: string | undefined;
  private _deviceType: 'wapp' | 'browser' = 'browser';

  constructor() {
    super();

    if (MODULE_ENVIRONMENT != 'browser') {
      this._atom = new Atom('DeviceSize');
      this._width = 0;
      this._height = 0;
      this._atom.reportChanged();
      return;
    }

    this._atom = new Atom('DeviceSize');
    this._width = this.actualWidth;
    this._height = this.actualHeight;

    $(window).resize(() => {
      this._width = this.actualWidth;
      this._height = this.actualHeight;

      this._atom.reportChanged();
    });
  }

  public set(width: number, height: number, userAgent?: string) {
    this._width = width;
    this._height = height;
    this._userAgent = userAgent;
    this._atom.reportChanged();
  }

  public set type(value: 'wapp' | 'browser') {
    this._deviceType = value;
  }

  public get type() {
    return this._deviceType || 'browser';
  }

  private get actualWidth() {
    if (MODULE_ENVIRONMENT == 'node') {
      return this._width || 0;
    }

    if (window.innerWidth) {
      return window.innerWidth;
    } else if (document.documentElement && document.documentElement.clientWidth) {
      return document.documentElement.clientWidth;
    } else if (document.body && document.body.clientWidth) {
      return document.body.clientWidth;
    } else {
      return 0;
    }
  }

  private get actualHeight() {
    if (MODULE_ENVIRONMENT == 'node') {
      return this._height || 0;
    }

    if (window.innerHeight) {
      return window.innerHeight;
    } else if (document.documentElement && document.documentElement.clientHeight) {
      return document.documentElement.clientHeight;
    } else if (document.body && document.body.clientHeight) {
      return document.body.clientHeight;
    } else {
      return 0;
    }
  }

  public get userAgent() {
    return (
      this._userAgent ||
      (MODULE_ENVIRONMENT == 'browser'
        ? this._userAgent || navigator.userAgent || navigator.vendor || (window as any).opera
        : '')
    );
  }

  @computed public get width() {
    if (this._atom.reportObserved()) {
      return this._width;
    } else {
      return this.actualWidth;
    }
  }

  @computed public get height() {
    if (this._atom.reportObserved()) {
      return this._height;
    } else {
      return this.actualHeight;
    }
  }

  @computed private get size(): 'mobile' | 'tablet' | 'desktop' {
    if (this.width >= 1366) {
      // Based on this: https://www.w3schools.com/browsers/browsers_display.asp
      return 'desktop';
    }
    if (this.width >= 768) {
      return 'tablet';
    }
    return 'mobile';
  }

  /** Uses regex to determine if the device is a mobile device. Use for embedded code */
  @computed public get isMobileBrowser(): boolean {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        this.userAgent,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        this.userAgent.substr(0, 4),
      )
    ) {
      return true;
    }
    return false;
  }

  /** Determines if the device is a mobile based on the size of the screen */
  @computed public get isMobileSize(): boolean {
    return this.size == 'mobile';
  }
  /** Determines if the device is a tabled based on the size of the screen */
  @computed public get isTabletSize(): boolean {
    return this.size == 'tablet';
  }
  /** Determines if the device is a desktop based on the size of the screen */
  @computed public get isDesktopSize(): boolean {
    return this.size == 'desktop';
  }
}
