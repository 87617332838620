import Lodash from 'lodash';
import { computed, observable, ObservableMap } from 'mobx';
import Moment from 'moment-timezone';
import 'moment/locale/de';
import 'moment/locale/sv';
import * as React from 'react';
import {
  ChangableComponent,
  Consume,
  Field,
  Fields,
  Icon,
  LocaleContext,
  MobxComponent,
  Rules,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';

// NOTE: The FormDatePicker only extends some of the settings from the semantic-ui-calendar.
// You, yes you! can find all possible settings here: https://github.com/mdehoog/Semantic-UI-Calendar
export type CalendarType = 'datetime' | 'date' | 'time' | 'year' | 'month';
export type CalendarStartMode = 'year' | 'month' | 'day' | 'hour' | 'minute';

interface DatePickerProps {
  /** The type of the calendar */ type?: CalendarType;
  /** An icon to display inside the input */ icon?: Icon;
  /** A list of rules that applies to this component */ rules?: Rules.TextFormatRule[];
  /** Whenever to create the calendar inline instead of inside a popup */ inline?: boolean;
  /** Close the popup after selecting a date/time (default: true) */ closable?: boolean;
  /** A placeholder shown inside the input when otherwise empty */ placeholder?: string;
  /** Displays a today button */ showToday?: boolean;
  /** Minimum date/time that can be selected */ minDate?: Date;
  /** Maximum date/time that can be selected */ maxDate?: Date;
  /** Optional method for disabling specific dates */ isDateDisabled?: (
    date: Date,
    mode?: CalendarStartMode,
  ) => boolean;
  /** Specifies calendar mode at start */ startMode?: CalendarStartMode;
  /** Sets the default date value */ defaultValue?: Date;
  /** These props are used for the period picker */
  /** id of the calendar when creating a period picker */ id?: string; //TODO: this should be removed if possible
  /** Start calendar when creating a period picker */ startCalendarId?: string; //TODO: this should be removed if possible
  /** End calendar when creating a period picker */ endCalendarId?: string; //TODO: this should be removed if possible
  /** Where the popup should be displayed */ popupOptions?: any;
  /** Disabled year selection */ disableYear?: boolean;
  /** Use this to format text in the datepicker */ formatter?: Object;
}

export class DatePicker extends ChangableComponent<Date, DatePickerProps> {
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  updateComponentValue(value) {
    return value;
  }

  componentDidMount() {
    this.initCalendar(this.props);
    this.domElement.calendar('set date', this.value);
  }

  componentDidUpdate(previousProps: DatePickerProps) {
    if (previousProps.minDate != this.props.minDate || previousProps.maxDate != this.props.maxDate) {
      this.domElement.find('.calendar')[0].remove();
      this.initCalendar(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: DatePickerProps) {
    if (nextProps.minDate != this.props.minDate || nextProps.maxDate != this.props.maxDate) {
      this.domElement.find('.calendar')[0].remove();
      this.initCalendar(nextProps);
    }
  }

  private get textObject() {
    const { t } = this._locale;
    return {
      days: Lodash.map(Moment.weekdaysMin(), (weekday) => weekday[0]),
      months: Moment.months(),
      monthsShort: Moment.monthsShort(),
      today: t('Today'),
      now: t('Now'),
      am: 'AM',
      pm: 'PM',
    };
  }

  private initCalendar(props: DatePickerProps) {
    this.domElement.calendar({
      type: props.type,
      startMode: props.startMode,
      text: this.textObject,
      monthFirst: false,
      minDate: props.minDate,
      maxDate: props.maxDate,
      firstDayOfWeek: 1,
      ampm: false,
      closable: props.closable != undefined ? props.closable : true,
      inline: props.inline,
      initialDate: this.value,
      isDisabled: props.isDateDisabled,
      startCalendar: $('#' + props.startCalendarId),
      endCalendar: $('#' + props.endCalendarId),
      today: props.showToday,
      onChange: (date: Date) => {
        if (props.type == 'time') {
          this.domElement.calendar('set mode', 'minute');
        }
        this.updateComponentValue(date);
      },
      popupOptions: props.popupOptions,
      disableYear: props.disableYear,
      formatter: props.formatter,
    });
  }

  private get inputProps() {
    // Set default properties
    const props = {
      disabled: this.disabled,
      type: 'text',
      className: this.props.className,
      name: this.props.name,
      placeholder: this.props.placeholder,
      autoComplete: 'off',
    };

    return props;
  }

  private get iconComponent() {
    if (this.props.icon) {
      return <i className={this.props.icon + ' icon'} />;
    }
    return undefined;
  }

  private get input() {
    if (this.props.inline) {
      return <span />;
    }
    return (
      <div className={'ui input ' + (this.props.icon ? 'left icon' : '')} style={this.props.style}>
        {this.iconComponent}
        <input {...this.inputProps} />
      </div>
    );
  }

  render() {
    return (
      <div className={`ui calendar ${this.props.className || ''}`} id={this.props.id} style={this.props.style}>
        {this.input}
      </div>
    );
  }
}

export class PeriodPicker extends MobxComponent<{
  inline?: boolean;
  startName: string;
  endName: string;
  startId: string;
  endId: string;
  startPlaceHolder?: string;
  endPlaceHolder?: string;
  required?: boolean;
  type?: CalendarType;
  startMode?: CalendarStartMode;
  icon?: Icon;
  defaultStartValue?: Date;
  defaultEndValue?: Date;
  onChange?: (startDate: Date | undefined, endDate: Date | undefined) => void;
  minDate?: Date;
  maxDate?: Date;
  horizontal?: boolean;
  horizontalIcon?: Icon;
}> {
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  private get popupOptions() {
    return {
      position: 'bottom right',
      lastResort: 'bottom left',
      prefer: 'bottom right',
    };
  }

  private get simpleFormatter() {
    return {
      date: function (date, settings) {
        return Moment(date).tz('Europe/Stockholm').format('D MMMM');
      },
    };
  }

  private _startDate: Date | undefined = this.props.defaultStartValue;
  private _endDate: Date | undefined = this.props.defaultEndValue;

  private onStartChanged = (date: Date | undefined) => {
    this._startDate = date;
    if (this._startDate) {
      this._startDate.setHours(0, 0, 0, 0);
    }

    if (this.props.onChange) {
      this.props.onChange(this._startDate, this._endDate);
    }
  };

  private onEndChanged = (date: Date | undefined) => {
    this._endDate = date;
    if (this._endDate) {
      this._endDate.setHours(23, 59, 59, 999);
    }

    if (this.props.onChange) {
      this.props.onChange(this._startDate, this._endDate);
    }
  };

  @computed private get startDatePicker() {
    return (
      <DatePicker
        id={this.props.startId}
        inline={this.props.inline}
        endCalendarId={this.props.endId}
        name={this.props.startId}
        defaultValue={this.props.defaultStartValue}
        onChange={this.onStartChanged}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        icon={this.props.icon}
        type={this.props.type}
        startMode={this.props.startMode}
        rules={this.props.required ? [Rules.NotEmpty()] : []}
        placeholder={this.props.startPlaceHolder}
        formatter={this.simpleFormatter}
      />
    );
  }
  @computed private get endDatePicker() {
    return (
      <DatePicker
        id={this.props.endId || this.props.endName}
        inline={this.props.inline}
        startCalendarId={this.props.startId}
        name={this.props.endId}
        defaultValue={this.props.defaultEndValue}
        onChange={this.onEndChanged}
        maxDate={this.props.maxDate}
        icon={this.props.icon}
        type={this.props.type}
        startMode={this.props.startMode}
        rules={this.props.required ? [Rules.NotEmpty()] : []}
        placeholder={this.props.endPlaceHolder}
        popupOptions={this.popupOptions}
        formatter={this.simpleFormatter}
      />
    );
  }

  render() {
    const { t } = this._locale;

    if (this.props.inline) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ order: 2, flexGrow: 3, margin: '1.5em' }}>
            <p>
              <b>{t('From')}</b>
            </p>
            {this.startDatePicker}
          </div>
          <div style={{ order: 2, flexGrow: 3, margin: '1.5em' }}>
            <p>
              <b>{t('To')}</b>
            </p>
            {this.endDatePicker}
          </div>
        </div>
      );
    }

    const horizontalStyle: React.CSSProperties = {
      display: 'flex',
    };

    return (
      <Fields style={this.props.horizontal ? horizontalStyle : {}}>
        <Field label={this.props.startName} width={8} style={{ marginRight: this.props.horizontal ? '0.5em' : '' }}>
          <div style={{ marginBottom: '3px' }} />
          {this.startDatePicker}
        </Field>
        {this.props.horizontal && this.props.horizontalIcon && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '1em' }}>
            <i className={this.props.horizontalIcon + ' icon'} />
          </div>
        )}
        <Field label={this.props.endName} width={8} style={{ marginLeft: this.props.horizontal ? '0.5em' : '' }}>
          <div style={{ marginBottom: '3px' }} />
          {this.endDatePicker}
        </Field>
      </Fields>
    );
  }
}

export class DatePickerButton extends DatePicker {
  render() {
    return (
      <div className={`ui calendar ${this.props.className || ''}`}>
        <div className={'ui button ' + (this.props.className ? this.props.className : '')}>
          {this.props.children || <i className="calendar outline icon" />}
        </div>
      </div>
    );
  }
}

export class WeekDayTimePickerField extends ChangableComponent<string, { label?: string; onlyFullDay?: boolean }> {
  private _numberOfAddedTimes = 0;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  updateComponentValue(value) {
    return value;
  }

  onAddOrRemoveHandler = (valueAdded: boolean) => {
    valueAdded ? this._numberOfAddedTimes++ : this._numberOfAddedTimes--;
    const value = this._numberOfAddedTimes === 0 ? '' : 'There is atleast one value!!!';
    this.updateComponentValue(value);
    this.forceUpdate(); // TODO: Why does not mobx take care of this?
    $('#hiddenInput').focusout(); // updates the input field which removes the error message
  };

  render() {
    const { t } = this._locale;

    return (
      <Field label={this.props.label}>
        {this.props.onlyFullDay && (
          <div className="sub header" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            {t('This a multi activity ticket and can only be scheduled as a full day activity.')}
          </div>
        )}
        <div className="ui seven columns centered grid" style={{ marginTop: '10px' }}>
          <WeekdayTimeInstance
            onlyFullDay={this.props.onlyFullDay}
            name={this.props.name + '0'}
            label={t('kosmic.components.legacy.form.dateTimePickers.monday')}
            onAddOrRemove={this.onAddOrRemoveHandler}
          />
          <WeekdayTimeInstance
            onlyFullDay={this.props.onlyFullDay}
            name={this.props.name + '1'}
            label={t('kosmic.components.legacy.form.dateTimePickers.tuesday')}
            onAddOrRemove={this.onAddOrRemoveHandler}
          />
          <WeekdayTimeInstance
            onlyFullDay={this.props.onlyFullDay}
            name={this.props.name + '2'}
            label={t('kosmic.components.legacy.form.dateTimePickers.wednesday')}
            onAddOrRemove={this.onAddOrRemoveHandler}
          />
          <WeekdayTimeInstance
            onlyFullDay={this.props.onlyFullDay}
            name={this.props.name + '3'}
            label={t('kosmic.components.legacy.form.dateTimePickers.thirsday')}
            onAddOrRemove={this.onAddOrRemoveHandler}
          />
          <WeekdayTimeInstance
            onlyFullDay={this.props.onlyFullDay}
            name={this.props.name + '4'}
            label={t('kosmic.components.legacy.form.dateTimePickers.friday')}
            onAddOrRemove={this.onAddOrRemoveHandler}
          />
          <WeekdayTimeInstance
            onlyFullDay={this.props.onlyFullDay}
            name={this.props.name + '5'}
            label={t('kosmic.components.legacy.form.dateTimePickers.saturday')}
            onAddOrRemove={this.onAddOrRemoveHandler}
          />
          <WeekdayTimeInstance
            onlyFullDay={this.props.onlyFullDay}
            name={this.props.name + '6'}
            label={t('kosmic.components.legacy.form.dateTimePickers.sunday')}
            onAddOrRemove={this.onAddOrRemoveHandler}
          />
        </div>
        <input id="hiddenInput" type="hidden" name={this.props.name} value={this.value} />
      </Field>
    );
  }
}

class WeekdayTimeInstance extends ChangableComponent<
  string,
  { label: string; onlyFullDay?: boolean; onAddOrRemove: (valueAdded: boolean) => void }
> {
  @observable private _timeOccurances: ObservableMap<string> = new ObservableMap<string>({});
  private _index = 0;
  private _timeHasBeenAdded = false;
  private _currentId: string;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  private get textObject() {
    const { t } = this._locale;

    return {
      days: Lodash.map(Moment.weekdaysMin(), (weekday) => weekday[0]),
      months: Moment.months(),
      monthsShort: Moment.monthsShort(),
      today: t('Today'),
      now: t('Now'),
      am: 'AM',
      pm: 'PM',
    };
  }

  updateComponentValue(values) {
    return values;
  }

  addTime = (value) => {
    if (!this._timeHasBeenAdded) {
      // Add new time
      this._currentId = String(this.props.name + this._index++);
      this._timeHasBeenAdded = true;
      this.props.onAddOrRemove(true);
    }

    const id = this._currentId;
    this._timeOccurances.set(id, value);
    this.updateComponentValue(Lodash.join(this._timeOccurances.values(), ','));
    this.forceUpdate(); // TODO: Why does not mobx take care of this?
    this.initCalendar(id, (_, value) => this.changeTimeBegan(id, value), this.changeTimeFinnished);
  };

  /** NOTE
   * When timeOccurances are set the calendar picker disapears.
   * So to be able to set minutes in the second step, timeOccurances
   * are updated on the onHidden event instead of the onChange event
   * when changing an existing time.
   */
  private _tempId: string;
  private _tempValue: string;
  private _didChangeTime: boolean;
  changeTimeBegan = (id: string, value: string) => {
    this._tempId = id;
    this._tempValue = value;
    this._didChangeTime = true;
  };
  changeTimeFinnished = () => {
    if (this._didChangeTime) {
      const id = this._tempId;
      this._timeOccurances.set(id, this._tempValue);
      this.updateComponentValue(Lodash.join(this._timeOccurances.values(), ','));
      this.forceUpdate(); // TODO: Why does not mobx take care of this?
      this.initCalendar(id, (_, value) => this.changeTimeBegan(id, value), this.changeTimeFinnished);
    }
    this._didChangeTime = false;
  };

  removeTime = (id: string) => {
    this._timeOccurances.delete(id);
    this.updateComponentValue(Lodash.join(this._timeOccurances.values(), ','));
    this.forceUpdate(); // TODO: Why does not mobx take care of this?
    this.props.onAddOrRemove(false);
  };

  componentDidMount() {
    this.initCalendar(
      this.props.name,
      (_, value) => this.addTime(value),
      () => (this._timeHasBeenAdded = false),
    );
  }

  onClickDayEventHandler = (event) => {
    if (!this.props.onlyFullDay) return;

    switch (this._timeHasBeenAdded) {
      case false:
        this.addTime('00:00');
        break;
      case true: {
        this.removeTime(Object.keys(this._timeOccurances.toJS())[0]);
        this._timeHasBeenAdded = false;
        break;
      }
    }
  };

  initCalendar = (id: string, onChangeHandler?: Function, onHiddenHandler?: Function) => {
    if (!this.props.onlyFullDay) {
      this.domElement.find('#' + id).calendar({
        type: 'time',
        text: this.textObject,
        monthFirst: false,
        ampm: false,
        onChange: onChangeHandler,
        onHidden: onHiddenHandler,
      });
    }
  };

  render() {
    const occurances = [] as any;
    Lodash.each(this._timeOccurances.toJS(), (value, key) =>
      occurances.push({ key: key, time: value, date: Moment().add(Moment.duration(value)) }),
    );
    const sortedOccurances = this.props.onlyFullDay ? '' : Lodash.sortBy(occurances, 'date');
    return (
      <div className="column" style={{ marginBottom: '1em', paddingTop: 0 }}>
        <div className="ui calendar" id={this.props.name}>
          <div
            onMouseLeave={(e) => {
              e.currentTarget.blur();
            }}
            onClick={this.onClickDayEventHandler}
            className={
              'circular fluid icon vertical animated ui green button ' + (!this._timeOccurances.size ? 'inverted' : '')
            }
            tabIndex={0}
            style={{ paddingLeft: 0, height: '3.5em', width: '3.5em', textAlign: 'center', fontSize: '1.1em' }}
          >
            <div
              className="visible content"
              style={{ height: '3.5em', marginRight: 0, padding: 0, paddingTop: '0.4em' }}
            >
              {this.props.label}
            </div>
            <div className="hidden content" style={{ height: '3.5em', padding: 0, paddingTop: '0.1em' }}>
              {!this.props.onlyFullDay && <i className="plus icon" style={{ marginRight: 0 }} />}
              {this.props.onlyFullDay && !this._timeHasBeenAdded && (
                <i className="plus icon" style={{ marginRight: 0 }} />
              )}
              {this.props.onlyFullDay && this._timeHasBeenAdded && (
                <i className="minus icon" style={{ marginRight: 0 }} />
              )}
            </div>
          </div>
        </div>
        {Lodash.map(sortedOccurances, (occurance: { key: string; time: string }) => {
          return (
            <div key={occurance.key + occurance.time} style={{ width: '5em', display: 'flex' }}>
              <i
                className="red close icon"
                style={{ cursor: 'pointer', marginTop: '2px' }}
                onClick={(e) => {
                  this.removeTime(occurance.key);
                  e.preventDefault();
                }}
              />
              <div id={occurance.key} className="ui calendar">
                <div className="ui large transparent input">
                  <input type="text" defaultValue={occurance.time} style={{ width: '4em' }} readOnly />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
