import React, { useEffect, useRef } from 'react';

interface Props {
  content: string;
}

export const Iframe = (props: Props) => {
  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const frame = ref.current;
    if (!frame) return;
    const doc = frame.contentDocument;
    if (!doc) return;

    doc.open();
    doc.write(props.content);
    doc.close();

    const height = doc.body.scrollHeight || 600;
    frame.style.width = '100%';
    frame.style.height = `${height}px`;
  }, [props.content]);

  return <iframe src="about:blank" frameBorder="0" ref={ref} />;
};
