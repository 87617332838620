import { Types } from 'mongoose';
import { Document, Resource } from '../_dependencies';
import { BookingDocument } from './booking.resource';
import { GiftCardDocument } from './giftCard.resource';

import * as xlsx from 'xlsx';
import SchemaNames from '../schemas/names';

/** Options for when performing a payment for a booking or giftcard */
export interface DoPaymentOptions {
  /** Booking document to pay for */
  document: BookingDocument | GiftCardDocument;
  /** Stripe token genereted from used credit card */
  cardStripeToken: string;
  /** Country code for the used credit card */
  cardCountryCode: string;
  /** The Organization this payment belongs to */
  organizationId: Types.ObjectId | string;
  /** Neccessary fields for any associated occurance. needs to be passed if the payment is for a booking */
  occurance?: {
    title: string;
    id: Types.ObjectId | string;
    activityTypeId: Types.ObjectId | string;
  };
}

export type PaymentDocument = Document;

export class PaymentResource extends Resource {
  constructor() {
    super();
    this.setName(SchemaNames.Payment);
  }

  addManualPaymentToBooking(id: string, amount: number) {
    return this.sendRequest<boolean>('/addManualPaymentToBooking', 'post', { id, amount });
  }

  async getPaidAmountOfBooking(bookingId: string) {
    const data = await this.sendRequest<{
      amountPaidManually: number;
      amountPaidWithGiftCard: number;
      amountPaidWithStripe: number;
      application_fee: number;
    }>('/getPayedAmountOfBooking', 'post', { id: bookingId });
    return {
      ...data,
      legacy: true,
    };
  }

  getPayedAmountOfGiftCard(giftCardId: string) {
    return this.sendRequest<number>('/getPayedAmountOfGiftCard', 'post', { id: giftCardId });
  }

  getStripePublishableKey() {
    return this.sendRequest<{ key: string }>('/getStripePublishableKey', 'post', {});
  }

  getPayoutInfoForCSV(dateString: string, type: 'year' | 'month' | 'date') {
    return this.sendRequest<xlsx.WorkBook>('/getPayoutInfoForCSV', 'post', {
      dateString: dateString,
      type,
    });
  }
}
