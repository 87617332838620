import Lodash from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import Moment from 'moment-timezone';
import React from 'react';
import { DeleteButton } from '../../../components/deleteButton';
import { Maybe } from '../../../components/maybe';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeDocument,
  ActivityTypeResource,
  Alert,
  Consume,
  DatePicker,
  Field,
  Form,
  Input,
  LocaleContext,
  MailResource,
  MobxComponent,
  Modal,
  PriceCategoryDocument,
  Rules,
  SubmitButton,
  TranslatableText,
  TranslatableTextField,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { CalendarStore } from '../../calendar';
import { CreateAccessoriesFields } from '../../dashboard/offers/form/createPriceCategories/createAccessoriesFields';
import { CreatePriceCategoriesFields } from '../../dashboard/offers/form/createPriceCategories/createPriceCategoriesFields';

interface Props {
  event: ActivityOccuranceDocument;
  store: CalendarStore;
  /** This forces the component to resave the original event since this does not occure when we mutate the event */
  isPublished?: boolean;
}

interface State {
  priceCategories: PriceCategoryDocument[];
  activityType?: ActivityTypeDocument;
}

export class EditActivityForm extends MobxComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @observable private isDeleting = false;

  private _originalEvent?: ActivityOccuranceDocument;
  private confirmationModal: Modal | null = null;
  private preparedSubmission: Function = () => void {};
  private preparedRejection: Function = () => void {};

  constructor(props: Props) {
    super(props);
    this.state = { priceCategories: this.props.event.priceCategories.map((cat) => ({ ...cat })) };
  }

  @computed private get hasBookings() {
    return this.props.event.bookings && this.props.event.bookings.length;
  }

  @action
  saveActivityOccurance = (values: { [id: string]: any }, resolve, reject) => {
    const { t, tt } = this._locale;

    // Not a pretty solution, but fixes issue #2370.
    // Can hopefully be fixed when mobx is removed and prop manipulation is less of an issue
    let title: TranslatableText;
    if (typeof this.props.event.title === 'string') {
      title = {
        sv: this.props.event.title,
        en: '',
        de: '',
      };
    } else {
      title = this.props.event.title;
    }
    title = {
      sv: values['Erbjudandets namn-sv'] ?? title.sv,
      en: values['Erbjudandets namn-en'] ?? title.en,
      de: values['Erbjudandets namn-de'] ?? title.de,
    };

    // Save new values
    runInAction(() => {
      this.props.event.title = title;
      this.props.event.start = values['Start'];
      this.props.event.end = Moment(values['Start']).add(values['Tid i minuter'], 'minutes').toDate();
      this.props.event.neededStaffing = values['Personalantal'];
      this.props.event.visitorCapacity = values['Antal platser för besökande'];
      this.props.event.minVisitors = values['Minsta antal besökande'];
      this.props.event.priceCategories = this.state.priceCategories;
    });

    // Update the activity event
    let saved = false;
    const resource = new ActivityOccuranceResource();
    resource
      .updateDocument(resource.createDocument(this.props.event))
      .then(() => {
        if (this.changesAreRelevantForCustomerUpdate(values)) {
          return new MailResource().sendActivityHasBeenChangedToBookedCustomers(this.props.event.id);
        }
        return Promise.resolve(true);
      })
      .then(() => {
        saved = true;
        if (this.props.event.workingStaff.length && this._originalEvent) {
          return new MailResource().sendActivityChangedEmailToEmployees(this._originalEvent);
        }
        return Promise.resolve(true);
      })
      .then(() => {
        Alert.show(
          tt(this.props.event.title) + ' ' + t('is once again scheduled.'),
          t('components.calendar.views.editActivityForm.alertTwo'),
          'success',
        );
        this.props.store.reload();
        return resolve();
      })
      .catch((err) => {
        if (saved) {
          Alert.show(t('components.calendar.views.editActivityForm.alertFour'), t('It almost worked!'), 'warning');
        } else {
          Alert.show(t('components.calendar.views.editActivityForm.alertSeven'), t('Error'), 'error');
        }
        return reject(err);
      });
  };

  @action
  private handleUpdatedStartTime = (start: Date) => {
    const hour = Moment(start).hour();
    const minute = Moment(start).minute();

    this.props.event.end = Moment(start).add(this.props.event.duration, 'minutes').toDate();

    if (Moment(this.props.event.start).diff(start, 'minutes')) {
      // TODO: why is this needed, due to mobx?
      this.props.event.start = Moment(this.props.event.start).hour(hour).minute(minute).toDate();
    }
  };

  @action
  private handleUpdatedDuration = (duration: string) => {
    this.props.event.duration = Number(duration);
    const end = Moment(this.props.event.start).add(duration, 'minutes').toDate();
    this.props.event.end = end;
  };

  private handleOnDeleteOccurance = async () => {
    const { t } = this._locale;
    this.isDeleting = true;
    try {
      await new ActivityOccuranceResource().delete(this.props.event.id);
      this.props.store.deselectEvent();
      this.props.store.removeFromDowloadedEvents(this.props.event);
    } catch (error) {
      Alert.show(
        t('components.calendar.views.editActivityForm.alertTen'),
        t('components.calendar.views.editActivityForm.alertEleven'),
        'error',
      );
    }
    this.isDeleting = false;
  };

  private setPriceCategories = (key: 'priceCategories', value: PriceCategoryDocument[]) => {
    this.setState({ [key]: value });
  };

  private get isUsingInventory() {
    return this.state.priceCategories.some((pc) => pc.inventory);
  }

  private get additionalFields() {
    const { t } = this._locale;

    return (
      <div style={{ paddingBottom: '14px' }}>
        <div style={{ height: '2em' }} />
        <Field label={t('Start')}>
          <DatePicker
            name="Start"
            type="time"
            icon="wait"
            defaultValue={Moment(this.props.event.start).toDate()}
            rules={[Rules.NotEmpty(t('components.calendar.views.editActivityForm.instructionOne'))]}
            onChange={this.handleUpdatedStartTime}
            placeholder={t('Time')}
          />
        </Field>
        <TranslatableTextField
          name="Erbjudandets namn"
          label={t('Offer name')}
          value={this.props.event.title}
          onChange={(value) => (this.props.event.title = value)}
          placeholder={t('Max 40 characters')}
        />
        <Field label={t('Time in minutes')}>
          <Input
            name="Tid i minuter"
            key="Tid i minuter"
            rules={[Rules.NotEmpty(), Rules.IsAnInteger(t('The time must be specified in minutes'))]}
            defaultValue={String(this.props.event.duration)}
            onChange={this.handleUpdatedDuration}
            icon="hourglass full"
          />
        </Field>
        <CreatePriceCategoriesFields
          categories={this.state.priceCategories}
          onPriceCategoriesChange={this.setPriceCategories}
          isUsingInventory={this.state.activityType?.useInventory}
        />
        <CreateAccessoriesFields
          categories={this.state.priceCategories}
          onPriceCategoriesChange={this.setPriceCategories}
          defaultTaxRate={0.25}
        />
      </div>
    );
  }

  private get deleteButton() {
    const { t } = this._locale;
    if (this.hasBookings) {
      return undefined;
    }

    return (
      <Maybe if="organization.manager">
        <DeleteButton
          fluid
          deleting={this.isDeleting}
          onConfirmed={this.handleOnDeleteOccurance}
          confirmationText={t('Are you really sure?')}
        >
          {t('components.calendar.views.editActivityForm.buttonOne')}
        </DeleteButton>
      </Maybe>
    );
  }

  private saveOriginalEvent(event) {
    this._originalEvent = Lodash.clone(event);
  }

  private getActivityType = async () => {
    const activity = await new ActivityTypeResource().get(this.props.event.originatingActivity);
    this.setState({ activityType: activity });
  };

  componentDidMount() {
    this.saveOriginalEvent(this.props.event);
    this.getActivityType();
  }

  componentDidUpdate(prevProps: Props) {
    const { event, isPublished } = this.props;
    if (prevProps.event.id !== event.id || prevProps.isPublished !== isPublished) {
      this.saveOriginalEvent(this.props.event);
      this.getActivityType();
    }
  }

  componentWillUnmount() {
    Object.assign(this.props.event, this._originalEvent);
  }

  private changesAreRelevantForCustomerUpdate(values: { [key: string]: string }): boolean {
    if (!this.hasBookings) return false;

    const nextStart = Moment(values['Start']);
    const nextEnd = Moment(values['Start']).add(values['Tid i minuter'], 'minutes');
    const prevStart = Moment(this._originalEvent?.start);
    const prevEnd = Moment(this._originalEvent?.end);

    if (nextStart.hours() !== prevStart.hours() || nextStart.minutes() !== prevStart.minutes()) return true;

    if (nextEnd.hours() !== prevEnd.hours() || nextEnd.minutes() !== prevEnd.minutes()) return true;

    return false;
  }

  private onSubmit = (
    values: { [key: string]: string },
    resolve: (result?: any) => void,
    reject: (err?: any) => void,
  ) => {
    if (!this.changesAreRelevantForCustomerUpdate(values)) {
      return this.saveActivityOccurance(values, resolve, reject);
    }

    if (!this.confirmationModal) {
      throw new Error('Tried to open confirmationModal which was null');
    }
    this.preparedSubmission = this.saveActivityOccurance.bind(this, values, resolve, reject);
    this.preparedRejection = reject;
    this.confirmationModal.show();
  };

  render() {
    const { t } = this._locale;
    return (
      <div>
        <Modal size="tiny" ref={(ref) => (this.confirmationModal = ref)} header={t('Save activity')} closable>
          <div className="content">
            <div className="description">{t('The activity you are about to change has bookings')}</div>
            <div>
              <button
                style={{ marginTop: '1em', marginBottom: '.5em' }}
                className="ui fluid green button"
                onClick={() => {
                  this.confirmationModal?.hide();
                  this.preparedSubmission();
                }}
              >
                {t('Accept')}
              </button>
              <button
                className="ui fluid button"
                onClick={() => {
                  this.confirmationModal?.hide();
                  this.preparedRejection();
                }}
              >
                {t('Cancel')}
              </button>
            </div>
          </div>
        </Modal>
        <Form onSubmit={this.onSubmit}>
          {this.additionalFields}
          {!this.isUsingInventory && (
            <Field label={t('Number of slots for visitors')}>
              <Input
                disabled={this.isUsingInventory}
                name="Antal platser för besökande"
                defaultValue={String(this.props.event.visitorCapacity || 10)}
                rules={[Rules.NotEmpty(), Rules.IsAnInteger()]}
                icon="ticket"
              />
            </Field>
          )}
          <Field label={t('Minimum number of visitors')}>
            <Input
              name="Minsta antal besökande"
              placeholder={t('per booking (optional)')}
              icon="ticket"
              defaultValue={String(this.props.event.minVisitors || '')}
              rules={[Rules.IsIntegerOneOrHigherElseEmpty()]}
            />
          </Field>
          <Field label={t('Personnel slots')}>
            <Input
              name="Personalantal"
              icon="users"
              defaultValue={String(this.props.event.neededStaffing || '')}
              rules={[Rules.IsEmptyOrInteger()]}
            />
          </Field>
          <SubmitButton className={'fluid green'} style={{ marginTop: '2em' }}>
            {t('Save changes')}
          </SubmitButton>
          <div className="ui divider" />
        </Form>
        {this.deleteButton}
      </div>
    );
  }
}
