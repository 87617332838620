import * as React from 'react';
import { Consume, DeviceProvider, IDevice, Link, LocaleContext, MobxComponent, Platforms } from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';

/** Customer AboutUs Component */
export class AboutUs extends MobxComponent<{}> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  componentDidMount() {
    this.domElement.transition({ animation: 'fade in', duration: 1000 });
  }

  render() {
    const { t } = this._locale;
    const imageStyle = this.device.size === 'mobile' ? { marginLeft: '-1em', marginRight: '-1em' } : {};
    const containerStyle = this.device.size === 'mobile' ? { marginTop: '4rem' } : {};

    return (
      <div className="ui container" style={containerStyle}>
        <div className="ui basic segment" style={{ padding: 0 }}>
          <h1 className="ui header" style={{ textAlign: 'center' }}>
            {t('Welcome to')} Adventure
            <span style={{ color: Platforms.primaryColor }}> Hero</span>
          </h1>

          <p style={{ fontSize: '1.3em', textAlign: 'center' }}>{t('components.customer.aboutUs.description')}</p>
        </div>

        <div className="ui vertical stripe segment" style={{ paddingTop: '4em' }}>
          <div style={imageStyle}>
            <div className="ui fluid image">
              <img src="/static/platform/img/racing.jpg" />
            </div>
          </div>
          <h2 className="ui center aligned header">
            <div className="content" style={{ fontWeight: 400 }}>
              {t('components.customer.aboutUs.descriptionThree')}{' '}
              <a
                href="http://blogg.adventurehero.se"
                target="_blank"
                style={{ color: Platforms.primaryColor }}
                rel="noreferrer"
              >
                <b>Adventure Hero #GO</b>
              </a>
            </div>
          </h2>
        </div>

        <div className="ui vertical stripe segment">
          <div className="ui one column centered grid">
            <div className="column" style={{ maxWidth: '600px' }}>
              <h2>{t('components.customer.aboutUs.descriptionFour')}</h2>
              <div className="ui very relaxed list">
                <div className="item">
                  <i className="big calendar icon"></i>
                  <div className="content">
                    <p className="header">{t('Booking system')}</p>
                    <div className="description">{t('Schedule and plan your activities. Get...')}</div>
                  </div>
                </div>
                <div className="item">
                  <i className="big user icon"></i>
                  <div className="content">
                    <p className="header">{t('Employee management')}</p>
                    <div className="description">{t('Send job opportunities and schedule employees.')}</div>
                  </div>
                </div>
                <div className="item">
                  <i className="big announcement icon"></i>
                  <div className="content">
                    <p className="header">{t('MARKETING')}</p>
                    <div className="description">{t('Through www.adventurehero.se we display all...')}</div>
                  </div>
                </div>
              </div>
              <Link className="item" to="/sign-up">
                <button className="ui fluid large primary button">
                  <i className="user icon" />
                  {t('components.customer.aboutUs.buttonOne')}
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="ui vertical stripe segment">
          <div className="ui one column centered grid">
            <div className="column" style={{ maxWidth: '500px' }}>
              <img className="ui center aligned image" src="static/platform/img/team.png" />
              <a className="ui fluid black button" href="https://www.hiveandfive.se">
                {t('Read more about the team behind Adventure')}{' '}
                <span style={{ color: Platforms.primaryColor }}>{t('components.customer.aboutUs.uniktid')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
