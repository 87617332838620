import Lodash from 'lodash';
import * as React from 'react';
import {
  Consume,
  DeviceProvider,
  GiftCardPreview,
  GiftCardTypeDocument,
  GiftCardTypeResource,
  IDevice,
  Link,
  LocaleContext,
  MobxComponent,
  OrganizationDocument,
  Platforms,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';

/** Showcase of available properties  */
export class GiftCardShowcase extends MobxComponent<{ cards: GiftCardTypeDocument[] }> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  state = { cards: [] };

  componentDidMount() {
    this.domElement.find('.ui.center.aligned.header').transition({ animation: 'fade in', duration: 300 });
    this.updateProperties();
  }

  updateProperties() {
    new GiftCardTypeResource().findWithPopulatedLimitations().then((result) => {
      this.setState({
        cards: result,
      });
    });
  }

  componentDidUpdate() {
    this.domElement.find('.ui.fluid.link.card').transition({ animation: 'scale in' });
  }

  render() {
    const { t } = this._locale;
    const cards = Lodash.map(this.state.cards, (card: GiftCardTypeDocument) => {
      const organization = (card.limitations.organizations as any)[0] as any as OrganizationDocument;
      return (
        <div key={card.id} className="column">
          <Link
            to={`/purchase/giftcard/${organization.id}/${card.id}`}
            className="ui fluid image transition hidden link card"
          >
            <div className="image">
              <GiftCardPreview giftCard={card} withoutIdentification />
            </div>
          </Link>
        </div>
      );
    });

    const headerStyle = this.device.size === 'mobile' ? { fontSize: '1.8em' } : { fontSize: '2.2em' };
    const containerStyle =
      this.device.size === 'mobile' ? { marginTop: '4rem', minHeight: '50em' } : { minHeight: '50em' };
    return (
      <div className="ui container" style={containerStyle}>
        <h1 className="ui center aligned grey icon header" style={headerStyle}>
          <i className="circular gift icon" style={{ color: Platforms.primaryColor }} />
          <div className="content">
            {t('components.customer.giftcardShowcase.messageTwo')}{' '}
            <span style={{ color: Platforms.primaryColor }}>{t('Giftcard')}</span>
          </div>
        </h1>

        <div style={{ height: '2em' }} />
        <div className="ui stackable two column grid">{cards}</div>
        <div style={{ height: '4em' }} />
      </div>
    );
  }
}
