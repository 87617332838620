import Moment from 'moment-timezone';
import * as React from 'react';
import {
  Button,
  Consume,
  DeviceProvider,
  IDevice,
  LocaleContext,
  MobxComponent,
  PropertyDocument,
  ScheduleDocument,
  ScheduleInstance,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';
import { AdvancedDatePickerType } from '../../../../advancedDatePicker';
import { ScheduleDateTimePicker } from '../scheduleCreator/dateTimePicker';

interface State {
  startTime: Date;
  endTime: Date;
  disableWholeScheuleInstance: boolean;
}

interface Props {
  schedule: ScheduleInstance;
  openedListItem?: ScheduleInstance;
  prevSchedule?: ScheduleInstance;
  nextSchedule?: ScheduleInstance;
  isCreatingNew?: boolean;
  originProperty: PropertyDocument;
  setScheduleToActive: (schedule: ScheduleInstance) => void;
  createException: (startTime: Date, endTime: Date, schedule: ScheduleInstance) => void;
  removeException: (schedule: ScheduleDocument) => void;
}

export class ScheduleItemRow extends MobxComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  constructor(props) {
    super(props);
    this.state = {
      startTime: this.props.schedule.startTime,
      endTime: this.props.schedule.endTime,
      disableWholeScheuleInstance: false,
    };
  }

  private dateColumns(startTime: Date) {
    if (this.device.size === 'mobile') {
      return <div className="ui column">{this.formatDate(startTime, 'YYYY MMMM dddd')}</div>;
    }
    return (
      <React.Fragment>
        <div className="ui column">{this.formatDate(startTime, 'YYYY')}</div>
        <div className="ui column">{this.formatDate(startTime, 'MMMM')}</div>
        <div className="ui column">{this.formatDate(startTime, 'Do ddd')}</div>
      </React.Fragment>
    );
  }

  private onStartTimeChange = (startTime: Date) => {
    this.setState({
      startTime,
    });
  };

  private onEndTimeChange = (endTime: Date) => {
    this.setState({
      endTime,
    });
  };

  private onSave() {
    this.props.createException(
      this.state.disableWholeScheuleInstance ? this.props.schedule.startTime : this.state.startTime,
      this.state.disableWholeScheuleInstance ? this.props.schedule.startTime : this.state.endTime,
      this.props.schedule,
    );
    this.setState({
      disableWholeScheuleInstance: false,
    });
    $('#isFreeCheckbox').checkbox('uncheck');
  }

  private onRemove() {
    this.props.removeException(this.props.schedule.exceptionScheduleDocument!);
  }

  private get startMinDate() {
    const { prevSchedule } = this.props;
    if (
      !prevSchedule ||
      Moment(prevSchedule.startTime).dayOfYear() != Moment(this.props.schedule.startTime).dayOfYear()
    ) {
      const minDate = new Date();
      minDate.setFullYear(this.props.schedule.startTime.getFullYear());
      minDate.setDate(this.props.schedule.startTime.getDate());
      minDate.setHours(0, 0, 0, 0);
      return minDate;
    } else {
      return prevSchedule.endTime;
    }
  }

  private get endMaxDate() {
    const { nextSchedule } = this.props;
    if (
      !nextSchedule ||
      Moment(nextSchedule.startTime).dayOfYear() != Moment(this.props.schedule.startTime).dayOfYear()
    ) {
      const maxDate = new Date();
      maxDate.setFullYear(this.props.schedule.endTime.getFullYear());
      maxDate.setDate(this.props.schedule.endTime.getDate());
      maxDate.setHours(23, 59, 59, 59);
      return maxDate;
    } else {
      return nextSchedule.startTime;
    }
  }

  private get endMinDate() {
    if (Moment(this.state.startTime).isBefore(this.props.schedule.startTime)) {
      return this.props.schedule.startTime;
    } else {
      return this.state.startTime;
    }
  }

  private get startMaxDate() {
    if (Moment(this.state.endTime).isAfter(this.props.schedule.endTime)) {
      return this.props.schedule.endTime;
    } else {
      return this.state.endTime;
    }
  }

  private editScheduleTime(schedule: ScheduleInstance) {
    const { t } = this._locale;
    const { isPossiblyConflictingWithExistingScheduleInstance, isConflictingWithExistingOccurance } = schedule;

    if (this.props.isCreatingNew && schedule.isEditing) {
      return null;
    }
    if ((this.props.schedule.property as PropertyDocument).id != this.props.originProperty.id) {
      return null;
    }
    if (isConflictingWithExistingOccurance) {
      return (
        <div style={{ padding: '1em' }}>
          <h4 style={{ color: 'red', textAlign: 'center' }}>
            {t('components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.bookingConflicts')}
          </h4>
        </div>
      );
    }
    if (this.props.openedListItem && this.props.openedListItem.id == schedule.id) {
      if (schedule.originScheduleInstance) {
        return (
          <div style={{ padding: '1em' }}>
            {isPossiblyConflictingWithExistingScheduleInstance ? (
              <h4 style={{ color: 'red', textAlign: 'center' }}>
                {t(
                  'components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.willGenerateConflicts',
                )}
              </h4>
            ) : null}
            <Button
              className={'fluid red ' + (isPossiblyConflictingWithExistingScheduleInstance ? 'disabled' : '')}
              style={{ marginTop: '0' }}
              onClick={() => this.onRemove()}
            >
              {t('components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.removeButton')}
            </Button>
          </div>
        );
      }
      return (
        <div style={{ padding: '1em' }}>
          <div className="ui stackable equal width grid" style={{ paddingBottom: 0, marginTop: '2px' }}>
            <div className="ui column" style={{ paddingBottom: 0 }}>
              <h3>{t('Crop start and end time:')}</h3>
            </div>
            <div className="ui right aligned column" style={{ paddingBottom: 0, paddingRight: '2em' }}>
              <div id="isFreeCheckbox" className="ui slider checkbox">
                <input
                  type="checkbox"
                  name="makeFree"
                  onClick={() =>
                    this.setState({ disableWholeScheuleInstance: !this.state.disableWholeScheuleInstance })
                  }
                />
                <label style={{ color: 'black' }}>
                  {t(
                    'components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.removeScheduleInstance',
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="ui stackable grid">
            <div className="ui eight wide column">
              <h5 style={{ marginBottom: '3px' }}>
                {t('components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.startTime')}
              </h5>
              <ScheduleDateTimePicker
                onlyFutureDates={false}
                icon="clock"
                date={this.state.startTime}
                type="time"
                enabledTypes={['time'] as AdvancedDatePickerType[]}
                onChanged={this.onStartTimeChange}
                disabled={this.state.disableWholeScheuleInstance ? true : false}
                minDate={this.startMinDate}
                maxDate={this.startMaxDate}
              />
            </div>
            <div className="ui eight wide column">
              <h5 style={{ marginBottom: '3px' }}>
                {t('components.dashboard.properties.employeeSchedule.scheduleList.scheduleItemRow.endTime')}
              </h5>
              <ScheduleDateTimePicker
                onlyFutureDates={false}
                icon="clock"
                date={this.state.endTime}
                type="time"
                enabledTypes={['time'] as AdvancedDatePickerType[]}
                onChanged={this.onEndTimeChange}
                disabled={this.state.disableWholeScheuleInstance ? true : false}
                minDate={this.endMinDate}
                maxDate={this.endMaxDate}
              />
            </div>
            <Button className="fluid green" style={{ margin: '1em', marginTop: '0' }} onClick={() => this.onSave()}>
              {t('Save')}
            </Button>
          </div>
        </div>
      );
    }
  }

  private get scheduleDuration() {
    if (this.props.schedule.originScheduleInstance && !this.props.schedule.isNew) {
      const exceptionTime: JSX.Element = (
        <React.Fragment>
          <br />
          {this.formatDate(this.props.schedule.startTime, 'HH:mm')}
          &nbsp; - &nbsp;
          {this.formatDate(this.props.schedule.endTime, 'HH:mm')}
        </React.Fragment>
      );
      return (
        <div>
          <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '5px' }}>
            {this.formatDate(this.props.schedule.originScheduleInstance.startTime, 'HH:mm')}
            &nbsp; - &nbsp;
            {this.formatDate(this.props.schedule.originScheduleInstance.endTime, 'HH:mm')}
          </span>
          {this.props.schedule.disableWholeScheuleInstance ? null : exceptionTime}
        </div>
      );
    }
    return (
      <React.Fragment>
        {this.formatDate(this.props.schedule.startTime, 'HH:mm')}
        &nbsp; - &nbsp;
        {this.formatDate(this.props.schedule.endTime, 'HH:mm')}
      </React.Fragment>
    );
  }

  render() {
    const { schedule, setScheduleToActive, openedListItem } = this.props;
    const { tt } = this._locale;

    let isOpened = false;
    openedListItem ? (isOpened = Moment(openedListItem.startTime).isSame(schedule.startTime)) : null;

    return (
      <React.Fragment>
        <div
          className="ui title"
          style={{
            backgroundColor: schedule.isEditing
              ? schedule.isConflictingWithExistingTime
                ? 'red'
                : 'lightGreen'
              : isOpened
              ? '#f2f2f2'
              : '',
          }}
          onClick={() => setScheduleToActive(schedule)}
        >
          <div className="ui equal width grid" style={{ display: 'flex', alignItems: 'center' }}>
            {this.dateColumns(schedule.startTime)}
            <div className="ui column">{this.scheduleDuration}</div>
            <div className="ui column">{tt(schedule.property!.name)}</div>
          </div>
        </div>
        <div className="ui content" style={{ backgroundColor: '#f2f2f2', padding: 0 }}>
          {this.editScheduleTime(schedule)}
        </div>
      </React.Fragment>
    );
  }
}
