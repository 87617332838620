import * as React from 'react';
import {
  Consume,
  Field,
  Form,
  InlineErrorMessage,
  Input,
  LocaleContext,
  MobxComponent,
  Rules,
  SubmitButton,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';

export class PayWithGiftCardComponent extends MobxComponent<{
  propertyName: string;
  onSubmit: (giftCardNumber, resolve, reject, errorCallback) => void;
}> {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      inputValue: '',
    };
  }

  state = {
    isDisabled: true,
    inputValue: '',
  };

  private _errorComponent: InlineErrorMessage;
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  private handlePaymentError = (error) => {
    const { t } = this._locale;
    if (!error) {
      return;
    }

    switch (error.type) {
      // Cases for giftcards
      case 'invalid_voucher_number':

      case 'to_low_value':
        this._errorComponent.show(t('Not a valid voucher'), t('No voucher was found with the given code...'));
        this._errorComponent.show(
          t('Voucher value is too low'),
          <span>
            {t('Unfortunately, the value of the voucher is only')} {error.info}{' '}
            {t('Please try again with another or change your booking')}
          </span>,
        );
        break;

      case 'no_matching_organizations':
        this._errorComponent.show(
          t('Found no matching organizations'),
          <span>
            {t('Voucher not valid at')} {this.props.propertyName} {t('Please try again')}
          </span>,
        );
        break;

      case 'expired_voucher':
        this._errorComponent.show(
          t('Voucher is no longer valid'),
          t('Unfortunately, the voucher has expired and is no longer valid'),
        );
        break;

      case 'used_voucher':
        this._errorComponent.show(t('Used vouchers'), t('The voucher is already used and not valid.'));
        break;
      // Cases for discounts
      case 'no_matching_organization_for_discount_found':
        this._errorComponent.show(
          t('Found no matching organizations'),
          <span>
            {t('Discount not valid at')} {this.props.propertyName} {t('Please try again')}
          </span>,
        );
        break;

      case 'could_not_apply_discount':
        this._errorComponent.show(t('Could not apply discount'));
        break;

      case 'expired_discount':
        this._errorComponent.show(
          t('Discount code not valid at this time'),
          t('Unfortunately, the discount has expired and is no longer valid or is not valid at this time'),
        );
        break;

      case 'no_uses_left':
        this._errorComponent.show(
          t('Discount code is not valid'),
          t('Unfortunately, the discount has no more uses left'),
        );
        break;

      case 'discount_cant_verify_uses':
        this._errorComponent.show(t('Discount code cannot be verified'));
        break;

      case 'discount_not_found':
        this._errorComponent.show(t('Discount code was not found'), t('Unfortunately, the discount does not exist'));
        break;

      case 'discount_already_applied':
        this._errorComponent.show(t("Can't use more than one discount"));
        break;
      case 'no_order_created':
        this._errorComponent.show('No order has been created yet');
        break;

      default:
        this._errorComponent.show(t('Invalid voucher'), t('The voucher could not be used...'));
        break;
    }
  };

  private handleOnSubmit = (values, resolve, reject) => {
    this._errorComponent.hide();
    this.props.onSubmit(values['Värdebeviskod'], resolve, reject, (err) => this.handlePaymentError(err));
  };

  updateInputValue(evt) {
    const value = evt;
    this.setState({
      inputValue: value,
      isDisabled: false,
    });

    if (value.length > 0) {
      this.setState({
        isDisabled: false,
      });
    } else {
      this.setState({
        isDisabled: true,
      });
    }

    return this.state;
  }

  render() {
    const { t } = this._locale;
    return (
      <Form onSubmit={this.handleOnSubmit} style={{ marginBottom: 0 }}>
        <InlineErrorMessage
          ref={(component) => {
            this._errorComponent = component as any;
          }}
        />
        <Field label={t('Voucher code')}>
          <i style={{ fontSize: '0.8rem' }}>{t('A voucher can be a...')}</i>
          <Input
            name="Värdebeviskod"
            icon="gift"
            rules={[Rules.NotEmpty()]}
            placeholder={t('Fill in your voucher code')}
            onChange={(evt) => this.updateInputValue(evt)}
          />
        </Field>

        <SubmitButton className="fluid" disabled={this.state.isDisabled}>
          {t('Use voucher')}
        </SubmitButton>
      </Form>
    );
  }
}
