import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { BookingPriceCategoryDocument } from '../schemas/priceCategory';
import { TranslatableText } from '../schemas/translatableText';
import { ActivityOccuranceDocument } from './activityOccurance.resource';

export interface TicketDocument extends MongooseDocument {
  title: TranslatableText | string;
  paymentId: string;
  bookingId: string;
  orderId: string;
  /**Used in PurchasedTicketConfirmation to link external client-app with ticket*/
  externalAppDeeplinkBaseUrl: string;
  organizationId: Types.ObjectId;
  /**Points to the occurance that the ticket originally used when it was generated. Beware that in case a 
  booking linked to a ticket has been moved, this occurance is no longer valid. Always use the booking as 
  source of truth for fetching data on a ticket.*/
  occuranceId: ActivityOccuranceDocument | Types.ObjectId;
  validActivities: TicketValidActivities[];
  usageLog?: TicketUsageLog[];
}

export type TicketValidActivities = {
  activityId: Types.ObjectId;
  activityName: string | TranslatableText;
  organizationId: Types.ObjectId;
};

export type TicketUsageLog = {
  usedOn: Date;
  activityId: Types.ObjectId;
  activityName: TranslatableText | string;
};

const TicketValidActivitiesSchema = new Schema(
  {
    activityId: { type: Schema.Types.ObjectId, required: true },
    activityName: { type: Schema.Types.Mixed, required: true },
    organizationId: { type: Schema.Types.ObjectId, required: true },
  },
  { _id: false },
);

const TicketUsageLogSchema = new Schema({
  usedOn: { type: Schema.Types.Date, required: true },
  activityId: { type: Schema.Types.String, required: true },
  activityName: { type: Schema.Types.Mixed, required: true },
});

// Remapped model of TicketDocument that gets exposed to external clients
export type ExposableTicketDocument = Pick<TicketDocument, 'title' | 'usageLog'> & {
  priceCategories: Pick<BookingPriceCategoryDocument, 'name' | 'tickets'>[];
  validActivities: Pick<TicketValidActivities, 'activityId' | 'activityName'>[];
  ticketId: string;
  startTime: Date;
  endTime: Date;
};

// This type contains additional data to add to tme API payloads (requested by OnSpotStory 29/6-2022)
export type AdditionalTicketData = {
  ticketDescription: TranslatableText | string;
  bookingNumber: string;
};

export type TicketReturnPayload = ExposableTicketDocument & AdditionalTicketData;

export function remapTicket(
  ticket: ExposableTicketDocument,
  additionalData: AdditionalTicketData,
): TicketReturnPayload {
  const remappedTicket: ExposableTicketDocument = {
    title: ticket.title,
    priceCategories: ticket.priceCategories.map((t) => ({ name: t.name, tickets: t.tickets })),
    endTime: ticket.endTime,
    startTime: ticket.startTime,
    validActivities: ticket.validActivities.map((t) => ({ activityId: t.activityId, activityName: t.activityName })),
    ticketId: ticket.ticketId,
    usageLog: ticket.usageLog,
  };

  const payload: TicketReturnPayload = { ...remappedTicket, ...additionalData };

  return payload;
}

export class TicketResource extends MongooseResource<TicketDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Tickets);

    this.setSchema({
      title: { type: Schema.Types.Mixed },
      paymentId: { type: Schema.Types.String, required: true },
      bookingId: { type: Schema.Types.String, required: true },
      orderId: { type: Schema.Types.String, required: true },
      externalAppDeeplinkBaseUrl: { type: Schema.Types.String, required: true },
      organizationId: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: true },
      occuranceId: { type: Schema.Types.ObjectId, ref: SchemaNames.ActivityOccurance, required: true },
      validActivities: { type: [TicketValidActivitiesSchema], required: true },
      usageLog: { type: [TicketUsageLogSchema], default: [] },
    });
  }

  getTicket(data: object) {
    return this.sendRequest<ExposableTicketDocument>('/getTicket', 'post', { data });
  }

  useTicket(data: object) {
    return this.sendRequest<ExposableTicketDocument>('/useTicket', 'post', { data });
  }

  getTicketStats(data: object) {
    return this.sendRequest<ExposableTicketDocument>('/getTicketStats', 'post', { data });
  }
}
