import { computed } from 'mobx';
import * as React from 'react';
import { Consume, DeviceProvider, Icon, IDevice, MobxComponent, NavLink } from '../_dependencies';

export interface NavigationBarProps {
  /** Position for the Navigaton bar */ position: 'top' | 'right' | 'bottom' | 'left'; // TODO: testing this: only works with top and bottom for now..
  /** The items must be of the NavigationItem class */ items: JSX.Element[];
  /** Invert the colors or the bar and it's items */ inverted?: boolean;
  /** Show the navigation bar on mobile devices */ mobile?: boolean;
  /** Show the navigation bar on tablet devices */ tablet?: boolean;
  /** Show the navigation bar on desktop devices */ desktop?: boolean;
}

/**
 * This has to be the out most element so that it can push the other content in the right direction.
 * Add website/application content as chilren to the NavigationBar.
 */
export class NavigationBar extends MobxComponent<NavigationBarProps> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  //TODO: add checks for device orientation to position the bar to the left in landscape or at the bottom for portait
  //TODO: add checks for device size and size the bar accordingly (mini || large)

  @computed private get evenlyDivided() {
    const nrOfItems = this.props.items.length;
    switch (nrOfItems) {
      case 1:
        return 'one item';
      case 2:
        return 'two item';
      case 3:
        return 'three item';
      case 4:
        return 'four item';
      case 5:
        return 'five item';
      case 6:
        return 'six item';
      default:
        return '';
    }
  }

  @computed private get pusherStyle() {
    switch (this.props.position) {
      case 'top':
        return { top: '3.5em' };
      case 'right':
        return { right: '3.5em' };
      case 'bottom':
        return { bottom: '3.5em' };
      case 'left':
        return { left: '3.5em' };
    }
  }

  render() {
    if (
      (this.props.mobile && this.device.size === 'mobile') ||
      (this.props.tablet && this.device.size == 'tablet') ||
      (this.props.desktop && this.device.size === 'desktop')
    ) {
      return (
        <div>
          {/* 
                        TODO: The class 'pusher' below makes the scrollpaging for activitycards fail and removing it makes 'fixed' children to act wierd when '-webkit-overflow-scrolling' is set to 'touch' in parent element.
                     */}
          <div className="pusher" style={this.pusherStyle}>
            {' '}
            {/* TODO: bottom or left depending on device orientation */}
            {this.props.children}
          </div>
          <div
            className={`ui visible overlay ${this.props.position} sidebar labeled icon ${this.evenlyDivided} mini secondary menu`}
          >
            {this.props.items}
          </div>
        </div>
      );
    } else {
      return <div>{this.props.children}</div>;
    }
  }
}

export const NavigationItem = (props: { link: string; icon: Icon; title: string }): JSX.Element => {
  return (
    <NavLink
      to={props.link}
      activeClassName="secondary-active"
      className="item"
      style={{ paddingTop: '8px', paddingBottom: '8px' }}
    >
      <i className={`${props.icon} icon`} />
      <div className="content" style={{ marginTop: '-5px' }}>
        <b>{props.title}</b>
      </div>
    </NavLink>
  );
};
