import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';

export interface ScheduleTimeSlot extends MongooseDocument {
  /** The employee for this schedule  */
  employee: Types.ObjectId;
  /** The property for this schedule  */
  property: Types.ObjectId;
  /** Reference to the schedule this time slot was created from */
  schedule: Types.ObjectId;
  /** The time that will be visible for customers */
  start: Date;
}

export class ScheduleTimeSlotResource extends MongooseResource<ScheduleTimeSlot> {
  constructor() {
    super();

    this.setName(SchemaNames.ScheduledTimeSlot);

    this.setSchema({
      employee: { type: Schema.Types.ObjectId, ref: SchemaNames.Default, required: true },
      property: { type: Schema.Types.ObjectId, ref: SchemaNames.Property, required: true },
      schedule: { type: Schema.Types.ObjectId, ref: SchemaNames.Schedule, required: true },
      start: { type: Date, required: true },
    });
  }
}
