import Lodash from 'lodash';
import { action, computed, observable } from 'mobx';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Alert,
  Consume,
  DeviceProvider,
  IDevice,
  LocaleContext,
  ObservingComponent,
  OrganizationDocument,
  Platforms,
  PropertyDocument,
  PropertyImageResource,
  PropertyResource,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import DashboardTemplate from '../dashboardTemplate';
import { PropertyForm } from './form';
import { PropertiesContext, PropertiesContextValue } from './propertiesProvider';
import { PropertyStore } from './propertyStore';

export class PropertyDetailsView extends ObservingComponent<{}, PropertyStore> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @observable private _isDeleting = false;
  @observable private _isEditing = false;
  @observable private _myKey = 1; // hihi, fulhaxx
  private _propertyResource = new PropertyResource();

  componentDefaultStore() {
    return PropertyStore.Instance;
  }

  private onImageSaved = (imageId) => {
    const { t } = this._locale;
    this.store.property.imageId = imageId;
    this.store.property.imageUrl = new PropertyImageResource().fileUrl(imageId, 'jpg');

    const document = this._propertyResource.createDocument(this.store.property);
    this._propertyResource
      .updateDocument(document)
      .then((documentId) => {
        this._myKey++;
      })
      .catch((err) => {
        Alert.show(t('img-save-error'), t('components.dashboard.properties.details.messageErrorSave'), 'error');
      });
  };

  @action private onSubmitProperty = (property: PropertyDocument, resolve, reject) => {
    // This will update the property via autorun
    Lodash.merge(this.store.property, property);
    resolve();
    this._isEditing = false;
  };

  @computed private get isMissingImportantInformation() {
    const property = this.store.property;
    return !property.description || !property.email || !property.phoneNo || this.isMissingAdressInformation; // TODO: fix mobx update issues when image is set (!property.imageId)
  }

  @computed private get isMissingAdressInformation() {
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const address = this.store.property.address;
    return organization.isFreelancer
      ? false
      : !address || !address.row1 || !address.postNr || !address.postOrt || !address.country;
  }

  @computed private get addressItem() {
    const { t } = this._locale;
    const { address, freelancerAreas } = this.store.property;
    let content: JSX.Element | string;
    if (!(this.globals.session.currentOrganization as OrganizationDocument).isFreelancer) {
      if (!this.isMissingAdressInformation) {
        content = (
          <span>
            {address!.row1}
            <br />
            {address!.postNr + ' ' + Lodash.capitalize(address!.postOrt)}
            <br />
            {address!.country}
          </span>
        );
      } else {
        content = t('components.dashboard.properties.details.addressMissing');
      }
    } else {
      content = Lodash.map(freelancerAreas, (area) => area.city || area.zipCode || '').join(' - ');
    }

    return (
      <div className="item">
        <i className="map signs icon"></i>
        <div className="content">{content}</div>
      </div>
    );
  }

  private get socialMedia() {
    const { t } = this._locale;
    let facebook: string | undefined;
    let instagram: string | undefined;

    if (this.store.property.socialMedia) {
      facebook = this.store.property.socialMedia.facebook;
      instagram = this.store.property.socialMedia.instagram;
    }

    return (
      <React.Fragment>
        <div className="item">
          <i className="facebook icon"></i>
          <div className="content">
            {facebook ? facebook : t('components.dashboard.properties.details.facebookMissing')}
          </div>
        </div>
        <div className="item">
          <i className="instagram icon"></i>
          <div className="content">
            {instagram ? instagram : t('components.dashboard.properties.details.instagramMissing')}
          </div>
        </div>
      </React.Fragment>
    );
  }

  private get informationList() {
    const { t, tt } = this._locale;
    const address = this.store.property.address;
    const location = this.store.property.location;

    return (
      <div className="ui relaxed large list">
        <div className="item">
          <div className="content">
            <h3 className="ui header">{t('components.dashboard.properties.details.headerOne')}</h3>
            <p>
              {tt(this.store.property.description) || t('components.dashboard.properties.details.descriptionMissing')}
            </p>
          </div>
        </div>
        <br />
        <div className="item">
          <i className="phone icon"></i>
          <div className="content">
            {this.store.property.phoneNo || t('components.dashboard.properties.details.phoneMissing')}
          </div>
        </div>
        <div className="item">
          <i className="mail icon"></i>
          <div className="content">
            {this.store.property.email || t('components.dashboard.properties.details.mailMissing')}
          </div>
        </div>
        <div className="item">
          <i className="world icon"></i>
          <div className="content">
            {this.store.property.website || t('components.dashboard.properties.details.pageMissing')}
          </div>
        </div>
        {this.socialMedia}
        {this.addressItem}
        <br />
        {PropertyStore.Instance.isUserAllowedToEditProperty() && (
          <button className="ui basic button" onClick={() => (this._isEditing = true)}>
            {t('components.dashboard.properties.details.buttonOne')}
          </button>
        )}
      </div>
    );
  }

  @computed private get editForm() {
    const { t } = this._locale;
    return (
      <div className="ui raised segments">
        <div className="ui segment">
          <PropertyForm isEditing property={this.store.property} onSubmit={this.onSubmitProperty} />
        </div>
        <div className="ui segment fluid button" onClick={() => (this._isEditing = false)}>
          <i className="remove icon" /> {t('Stop editing')}
        </div>
      </div>
    );
  }

  @computed private get missingInformationLabel() {
    const { t } = this._locale;
    return this.isMissingImportantInformation ? (
      <div className="ui red small label">{t('components.dashboard.properties.details.infoMissing')}</div>
    ) : undefined;
  }

  render() {
    const { t, tt } = this._locale;
    // TODO: is there a better way to remove the padding on mobile?
    const marginStyle = this.device.size === 'mobile' ? { marginLeft: '-14px', marginRight: '-14px' } : {};

    return (
      <div style={{ padding: '1em' }} key={String(this._myKey)}>
        <div className="ui basic segment">
          <h2 className="ui header">
            {t('components.dashboard.properties.details.overviewOver')} {tt(this.store.property.name)}{' '}
            {this.missingInformationLabel}
            <div className="sub header">
              {t('components.dashboard.properties.details.infoPicture')} {Platforms.platformName}{' '}
              {t('components.dashboard.properties.details.frontPage')}
            </div>
          </h2>
          <div style={{ height: '1em' }} />
          <div className="ui stackable grid">
            <div className="eight wide column">
              <div style={marginStyle}>{this._isEditing ? this.editForm : this.informationList}</div>
            </div>
            <div className="eight wide column">
              {this._isEditing ? null : (
                <div style={marginStyle}>
                  <h3 className="ui header">{t('components.dashboard.properties.details.propertyPicture')}</h3>
                  <div className="ui segment" style={{ padding: '0.5em' }} key={String(this._myKey)}>
                    <div className="ui fluid image">
                      <img src={this.store.property.imageUrl} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* TODO: Softdeleting a property must also softdelete bookings, occurances and proably even giftcards */}
          {/*<Maybe if="organization.manager"> 
                        <div>
                            <div style={{height: "4em"}}/>
                            <div className="ui divider"/>
                            <div className="ui center aligned basic segment">
                                <DeleteButton deleting={this._deleting} onConfirmed={this.handleOnDeleteProperty} confirmationText="Är du verkligen säker?">Ta bort anläggningen</DeleteButton>
                            </div>
                        </div>
                    </Maybe>*/}
        </div>
      </div>
    );
  }
}

export class PropertySettingsView extends ObservingComponent<{}, PropertyStore> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  componentDefaultStore() {
    return PropertyStore.Instance;
  }

  render() {
    const { t } = this._locale;
    return (
      <div style={{ padding: '1em' }}>
        <div className="ui gray center aligned message">
          <h4>{t('Instruction')}</h4>
          <p>{t('components.dashboard.properties.details.editPropertyInfo')}</p>
        </div>
      </div>
    );
  }
}

type LinkItem = {
  path: string;
  text: string;
  icon?: string;
};

class PropertyDetailsTemplateClass extends ObservingComponent<RouteComponentProps<{ id: string }>, PropertyStore> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(PropertiesContext)
  private properties: PropertiesContextValue;

  componentDefaultStore() {
    return PropertyStore.Instance;
  }

  componentDidMount() {
    PropertyStore.Instance.findProperty(this.props.match.params.id);
    this.properties.setIsSidebarOpen(false);
  }

  componentDidUpdate() {
    const { id } = this.props.match.params;
    if (id && id !== PropertyStore.Instance.property?.id) {
      PropertyStore.Instance.findProperty(this.props.match.params.id);
    }
  }

  render() {
    const { t, tt } = this._locale;

    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { features } = organization.flags;

    const isLinkItem = (x: LinkItem | undefined): x is LinkItem => Boolean(x);

    if (!this.store.property) {
      return <span />;
    }

    const links: LinkItem[] = [
      {
        path: '/dashboard/properties/details/' + this.store.property.id,
        text: t('components.dashboard.properties.details.overview'),
      } as LinkItem,
      PropertyStore.Instance.isUserAllowedToEditProperty() &&
        ({
          path: '/dashboard/properties/details/' + this.store.property.id + '/employees',
          text: t('Personnel'),
          icon: 'users icon',
        } as LinkItem),
      (features.scheduling &&
        ({
          path: '/dashboard/properties/details/' + this.store.property.id + '/schedule',
          text: t('Schedule'),
          icon: 'calendar alternate outline icon',
        } as LinkItem)) ||
        undefined,
    ].filter(isLinkItem);

    const rightLink =
      (features.offers &&
        features.bookings && {
          path: '/dashboard/properties/details/' + this.store.property.id + '/booking',
          text: t('Create a booking'),
          icon: 'in cart icon',
        }) ||
      undefined;

    return (
      <DashboardTemplate
        title={tt(this.store.property.name)}
        icon="map pin icon"
        rightTab={rightLink}
        tabs={links}
        store={this.store}
      >
        {this.props.children}
      </DashboardTemplate>
    );
  }
}

export const PropertyDetailsTemplate = withRouter(PropertyDetailsTemplateClass);
