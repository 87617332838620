import Lodash from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import * as React from 'react';
import {
  Alert,
  Consume,
  DefaultPredicate,
  DeviceProvider,
  GiftCardTypeDocument,
  GiftCardTypeResource,
  IDevice,
  LimitationsDocument,
  LocaleContext,
  MobxComponent,
  ObservingComponent,
  OrganizationDocument,
  SearchableList,
  SearchableListStore,
  SearchListBar,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { checkAccountActivation } from '../../activationHelper';
import { GiftCardDetails } from './details';

export class HandleGiftCardTypes extends ObservingComponent<
  { addTabHandler: any },
  SearchableListStore<GiftCardTypeDocument>
> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @observable private _searchableListStore: SearchableListStore<GiftCardTypeDocument>;

  componentDefaultStore() {
    const store = new SearchableListStore<GiftCardTypeDocument>();
    new GiftCardTypeResource()
      .findBasedOnLimitations({ organizations: [this.globals.session.currentOrganizationId] } as LimitationsDocument)
      .then((results) => {
        runInAction(() => {
          store.predicate = (text, item: GiftCardTypeDocument) => {
            return DefaultPredicate(text, item.title.toString());
          };
          store.sortByFields = ['title'];
          store.items = results;
          store.showCreateNewItemButton = true;
        });
      })
      .catch((err) => {
        console.log(err);
      });

    return store;
  }

  private onDeleteItem = async (document: GiftCardTypeDocument) => {
    const { t } = this._locale;
    try {
      await new GiftCardTypeResource().delete(document._id);
      runInAction(() => Lodash.remove(this.store.items, (item) => item._id == document._id));
    } catch (err) {
      Alert.show('', t('Something went wrong, try again.'), 'error');
    }
  };

  render() {
    const { t } = this._locale;
    return (
      <div>
        <div style={{ padding: '2em' }}>
          <h2 className="ui header">
            {t('components.dashboard.giftCards.handleGiftCardTypes.headerOne')}{' '}
            {this.globals.session.currentOrganization.name}
            <div className="sub header">{t('components.dashboard.giftCards.handleGiftCardTypes.headerTwo')}</div>
          </h2>
          <div className="ui large fluid transparent left icon input">
            <i className="search icon" />
            <SearchListBar
              store={this.store}
              placeholder={t('components.dashboard.giftCards.handleGiftCardTypes.inputOne')}
            />
          </div>
          <div className="ui divider" style={{ marginBottom: '2em' }} />
          <SearchableList
            className={`ui ${this.device.size === 'desktop' ? 'three' : 'two'} columns stackable grid`}
            template={GiftCardTypeItem}
            createNewItemTemplate={CreateNewGiftCardTypeItem}
            store={this.store}
            showDefaultSearchBar={false}
            additionalTemplateProps={{ onDeleteItem: this.onDeleteItem }}
          />
        </div>
      </div>
    );
  }
}

const GiftCardTypeItem = (props: { item: GiftCardTypeDocument; onDeleteItem: Function }) => {
  const giftCard = props.item;

  return (
    <div className="column" key={giftCard.id}>
      <GiftCardDetails giftCard={giftCard} onDeleteItem={props.onDeleteItem} />
    </div>
  );
};

export class CreateNewGiftCardTypeItem extends MobxComponent<{
  addItemToList: (giftCard: GiftCardTypeDocument) => void;
}> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private createNewGiftCard() {
    return (new GiftCardTypeResource().createDocument({}) as any)._doc;
  }

  @observable private _isCreating = false;
  private _giftCard = this.createNewGiftCard();

  @computed private get segmentHeight() {
    return this.device.size === 'desktop' ? { height: '15.8vw' } : { height: '22.2vw' };
  }

  @computed private get button() {
    return (
      <div className="ui fluid raised center aligned segment" style={Lodash.merge(this.segmentHeight, { padding: 0 })}>
        <button
          className="ui basic button"
          style={{ height: '100%', width: '100%' }}
          onClick={() => (this._isCreating = !this._isCreating)}
        >
          <i className="huge plus icon" style={{ marginRight: 0 }} />
        </button>
      </div>
    );
  }

  @computed private get form() {
    return <GiftCardDetails isEditing giftCard={this._giftCard} onFinished={this.onFinished} onEnded={this.onEnded} />;
  }

  @action private onFinished = () => {
    this.props.addItemToList(this._giftCard);
    this._giftCard = this.createNewGiftCard();
    this._isCreating = false;
  };

  @action private onEnded = () => {
    this._isCreating = false;
  };

  render() {
    const { t } = this._locale;
    // Verify that the organization is activated, if not activated we cant create events
    const organization = this.globals.session.currentOrganization as OrganizationDocument;

    if (checkAccountActivation(organization)) {
      return (
        <div className="column" key={-1}>
          {this._isCreating ? this.form : this.button}
        </div>
      );
    }

    // Not an activated account
    return (
      <div className="column">
        <div className="ui icon warning message">
          <i className="exclamation circle icon"></i>
          <div className="content">
            <div className="header">{t('components.dashboard.giftCards.handleGiftCardTypes.notActivatedTitle')}</div>
            <p>
              {t('However, before you can create activities...')}
              <br />
              <br />
              <button
                className="ui orange right aligned button"
                onClick={() => this.globals.session.navigateTo('/dashboard')}
              >
                {t('Activate my account')}
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
