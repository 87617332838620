import { computed } from 'mobx';
import Moment from 'moment-timezone';
import * as React from 'react';
import { BookingInfoItem } from '../../../components/calendar/views/bookingInfoItem';
import {
  ActivityOccuranceDocument,
  Alert,
  BookingDocument,
  BookingResource,
  Consume,
  DeviceProvider,
  Field,
  IDevice,
  LocaleContext,
  MobxComponent,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { PropertyStore } from '../../dashboard/properties/propertyStore';
import { CalendarStore } from '../store';
import OccuranceDropdown from './occuranceDropdown';

export interface MoveBookingProps {
  store: CalendarStore;
  booking: BookingDocument;
  oldOccurance: ActivityOccuranceDocument;
  newOccurance: ActivityOccuranceDocument;
  onCompleted: Function;
  onEnded: Function;
}
export class MoveBooking extends MobxComponent<MoveBookingProps> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @computed private get isSameOccurance(): boolean {
    return this.props.newOccurance.id == this.props.oldOccurance.id;
  }

  @computed private get isPastOccurance(): boolean {
    return Moment().isAfter(this.props.newOccurance.start);
  }

  @computed private get isAllowedToMoveBookingToOccurance(): boolean {
    return this.props.newOccurance.organization === this.globals.session.currentOrganizationId;
  }

  @computed private get newOccuranceAvailableSlots(): number {
    return this.props.newOccurance.visitorCapacity - this.props.newOccurance.bookedVisitors;
  }

  @computed private get isExceedingAvailableSlots(): boolean {
    return this.props.booking.bookedVisitors > this.newOccuranceAvailableSlots;
  }

  private get activityDropdownForNonDesktop() {
    const { t } = this._locale;
    const { size } = this.device;
    let propertyId = '';
    const inPropertyCalendar = location.pathname.includes('properties');

    if (inPropertyCalendar) {
      propertyId = PropertyStore.Instance.property.id;
    }

    if (size !== 'desktop') {
      return (
        <Field style={{ marginTop: '1em' }} label={t('Select an activity')}>
          <div style={{ marginTop: '3px' }}></div>
          <OccuranceDropdown
            propertyOccurancesOnly={inPropertyCalendar}
            propertyId={propertyId}
            store={this.props.store}
          />
        </Field>
      );
    }
  }

  private get dynamicContent() {
    const { t } = this._locale;
    const { size } = this.device;
    // Display select other activity message
    if (this.isSameOccurance) {
      return (
        <div className="ui message">
          <p>{size === 'desktop' ? t('Choose a time in the schedule...') : t('Select an activity in the list...')}</p>
        </div>
      );
    }

    // Display select future activity message
    if (this.isPastOccurance) {
      return (
        <div className="ui orange message">
          <h4 className="header">
            {t('components.calendar.views.moveBooking.messageTwo')} <i className="ui calendar icon" />
          </h4>
          <p>{t('components.calendar.views.moveBooking.messageThree')}</p>
        </div>
      );
    }

    // Display the move button
    if (this.isAllowedToMoveBookingToOccurance) {
      return (
        <>
          {this.isExceedingAvailableSlots && (
            // Display info message that moving booking will cause overpopulated occurance
            <div className="ui orange message">
              <h4 className="header">
                {t('Not enough available slots')} <i className="exclamation triangle icon"></i>
              </h4>
              <p>{t('If you choose to move the booking...')}</p>
              <p>{t('Slots left on activity') + this.newOccuranceAvailableSlots}</p>
            </div>
          )}
          <button
            className="ui fluid basic button"
            onClick={this.onMoveBooking}
            style={{ marginTop: '2em', marginBottom: '1em' }}
          >
            <i className="send icon" /> {t('components.calendar.views.moveBooking.messageFour')}
          </button>
        </>
      );
    }

    // Display not allowed to move booking message
    return (
      <div className="ui message">
        <p>{t('You are not able to move bookings...')}</p>
      </div>
    );
  }

  private onMoveBooking = () => {
    const { t } = this._locale;
    new BookingResource()
      .moveBooking(this.props.booking.id, this.props.oldOccurance, this.props.newOccurance as any)
      .then(() => {
        Alert.show(
          t('components.calendar.views.moveBooking.alertOne'),
          t('components.calendar.views.moveBooking.alertTwo'),
          'success',
        );
        this.props.onCompleted();
      })
      .catch(() => {
        Alert.show(
          t('components.calendar.views.moveBooking.alertThree'),
          t('components.calendar.views.moveBooking.alertFour'),
          'error',
        );
      });
  };

  render() {
    const { t } = this._locale;

    return (
      <div>
        <h3 className="ui header">{t('components.calendar.views.moveBooking.messageFive')}</h3>
        <BookingInfoItem store={this.props.store} hideMoveButton booking={this.props.booking} />
        <div style={{ height: '1em' }} />
        {this.activityDropdownForNonDesktop}
        <div style={{ height: '1em' }}></div>
        {this.dynamicContent}
        <button className="ui orange fluid button" onClick={() => this.props.onEnded()}>
          {t('Cancel')}
        </button>
      </div>
    );
  }
}
