import { reverse, sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import {
  BookingDocument,
  BookingPriceCategoryDocument,
  DeviceProvider,
  InventoryDocument,
  InventoryEntityDocument,
  InventoryEntityResource,
  InventoryStatusDocument,
  InventoryStatusResource,
  Link,
  PlaceDocument,
  PlaceResource,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { BookingsSelectorSegment } from './segments/bookingSelectorSegment';
import { ConfirmButtonSegment } from './segments/confirmButtonSegment';
import { ConfirmMultipleButtonSegment } from './segments/confirmMultipleButtonSegment';
import { DestinationSegment } from './segments/destinationSegment';
import { Header } from './segments/header';
import { InventoryItemInformationSegment } from './segments/inventoryItemTableSegment';
import { StatusButtonsSegment } from './segments/statusButtonsSegments';

interface EntityStatusResponse {
  futureBookings: BookingDocument[];
  entity: InventoryEntityDocument;
  inventory: InventoryDocument;
}

export const InventoryItemStatusForm = () => {
  const params = new URLSearchParams(location.search);
  const externalId = params.get('externalId');
  const device = useContext(DeviceProvider.Context);
  const { t } = useLocale();
  const [tempItemStatusData, setTempItemStatusData] = useState<InventoryStatusDocument>();
  const [entityData, setItemEntity] = useState<InventoryEntityDocument>();
  const [inventoryData, setInventory] = useState<InventoryDocument>();
  const [bookings, setBookings] = useState<BookingDocument[]>();
  const [currentlyBooked, setCurrentlyBooked] = useState<BookingDocument>();
  const [newlyBooked, setNewlyBooked] = useState<BookingDocument>();
  const [places, setPlaces] = useState<PlaceDocument[]>();
  const [updating, setUpdating] = useState(false);
  const [finished, setFinished] = useState(false);
  const [disableCheckin, setDisableCheckin] = useState<boolean>(false);

  const [error, setError] = useState<Error>();

  const inventoryStatusResource = new InventoryStatusResource();
  const inventoryEntityResource = new InventoryEntityResource();

  const isBookingSelected = Boolean(tempItemStatusData?.booking);

  const getCurrentStatus = () => {
    if (!entityData) return;
    if (!tempItemStatusData) return entityData.lastStatus;
    return tempItemStatusData;
  };

  useEffect(() => {
    const findBookingRelatedToStatus = (bookingId: string, bookings: BookingDocument[]) => {
      const booking = bookings.find(({ id }) => id === bookingId);
      setCurrentlyBooked(booking);
    };

    const fetchInventoryData = async () => {
      try {
        if (externalId) {
          const { futureBookings, entity, inventory }: EntityStatusResponse =
            await inventoryEntityResource.getBookingsSpecificToEntity(externalId);

          if (entity.lastStatus.booking) {
            findBookingRelatedToStatus(entity.lastStatus.booking._id.toString(), futureBookings);
          }

          setItemEntity(entity);
          setInventory(inventory);
          setBookings(futureBookings);
        }
      } catch (err) {
        setError(err);
        console.log(err);
      }
    };
    fetchInventoryData();
  }, [externalId]);

  useEffect(() => {
    (async () => {
      const places = await new PlaceResource().find({ organization: entityData?.organization });
      setPlaces(places);
    })();
  }, [entityData]);

  /** Limits the ability to check in based on the amount of booked inventory items that match the entity, only considers "unavailable" statuses as booked. */
  const limitsCheckoutsBasedOnBooking = async (booking: BookingDocument) => {
    const bookingStatuses = await inventoryStatusResource.findAndPopulateInventoryEntities({ booking: booking._id });

    // Get the latest statuses that are set to unavailable
    const latestStatuses: Array<{ inventory: string; status: InventoryStatusDocument }> = [];
    for (const status of bookingStatuses) {
      const entity = status.inventoryEntity as unknown as InventoryEntityDocument;
      const isInLastestStatuses = latestStatuses.some((s) => s.inventory === entity.id);
      if (!isInLastestStatuses) {
        const latestStatus = reverse(
          sortBy(
            bookingStatuses.filter((s) => (s.inventoryEntity as any).id === entity.id),
            (s) => s.createdAt,
          ),
        )[0];
        if (latestStatus.status === 'in-use') {
          latestStatuses.push({ inventory: String(entity.inventory), status: latestStatus });
        }
      }
    }

    // Combine tickets for categories connected to the same inventory
    const combinedCategories: BookingPriceCategoryDocument[] = [];
    for (const priceCategory of booking.priceCategories) {
      if (priceCategory.inventory === entityData?.inventory) {
        const combinedCategory = combinedCategories.find(
          (c) => String(c.inventory) === String(priceCategory.inventory),
        );
        if (!combinedCategory) {
          combinedCategories.push({ ...priceCategory });
        } else {
          combinedCategory.tickets += priceCategory.tickets;
        }
      }
    }

    // Check if user is about to checkout more than needed
    for (const priceCategory of combinedCategories) {
      for (const booked of latestStatuses) {
        if (booked.inventory === String(priceCategory.inventory)) {
          const filteredByInventory = latestStatuses.filter(
            (status) => status.inventory === String(priceCategory.inventory),
          );
          if (filteredByInventory.length >= priceCategory.tickets) {
            setDisableCheckin(true);
            return false;
          }
        }
      }
    }

    setDisableCheckin(false);
    return true;
  };

  const confirmStatusUpdate = async () => {
    try {
      if (tempItemStatusData) {
        setUpdating(true);
        await inventoryStatusResource.updateDocument(tempItemStatusData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setUpdating(false);
      setFinished(true);
    }
  };

  const setSelectedBooking = async (booking: BookingDocument) => {
    const canCheckin = await limitsCheckoutsBasedOnBooking(booking);
    if (canCheckin) {
      setNewlyBooked(booking);
      handleFormChange(booking.id, 'booking');
    }
  };

  const handleFormChange = async (value: string | PlaceDocument, key: string) => {
    const statusDocumentCopy = { ...getCurrentStatus() };
    statusDocumentCopy[key] = value;
    setTempItemStatusData(statusDocumentCopy as InventoryStatusDocument);
  };

  if (error) {
    const forbidden = error.message === 'Access denied';
    return (
      <div style={containerStyle}>
        <div className="ui segment" style={segmentStyle(device.size === 'mobile')}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>
            <h1 className="ui center aligned header">
              {forbidden ? '403' : '401'}
              <div className="sub center aligned header">
                {t('You do not have permission to')}
                <br />
                {t("change this item's status")}
              </div>
              {forbidden ? null : (
                <div className="sub center aligned header" style={{ paddingTop: '1rem' }}>
                  {t('Log in')} <Link to="/log-in">{t('here')}</Link>
                </div>
              )}
            </h1>
          </div>
        </div>
      </div>
    );
  }

  if (!entityData || !externalId || !bookings) {
    return (
      <div style={containerStyle}>
        <div className="ui segment" style={segmentStyle(device.size === 'mobile')}>
          <p>{'Preparing ...'}</p>
          <div className="ui active inverted dimmer">
            <div className="ui loader"></div>
          </div>
        </div>
      </div>
    );
  }

  if (finished) {
    return (
      <div style={containerStyle}>
        <div style={{ color: 'white', width: device.size === 'mobile' ? '20rem' : '30rem' }}>
          <Header finished={finished} entityData={entityData} />
          <InventoryItemInformationSegment
            places={places}
            bookingNumber={newlyBooked?.number || currentlyBooked?.number || undefined}
            entity={entityData}
            groupTitle={!inventoryData?.title ? t('None') : inventoryData?.title}
            updatedStatus={tempItemStatusData}
          />
          {entityData.isTemporary ? (
            <ConfirmMultipleButtonSegment places={places} tempEntity={entityData} updatedStatus={tempItemStatusData} />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <div style={{ color: 'white' }}>
        <Header finished={finished} entityData={entityData} />
        <div style={segmentStyle(device.size === 'mobile')}>
          <div style={informationStyle(device.size === 'mobile', 'right')}>
            <InventoryItemInformationSegment
              places={places}
              entity={entityData}
              groupTitle={!inventoryData?.title ? 'none' : inventoryData?.title}
              bookingNumber={currentlyBooked ? currentlyBooked.number : undefined}
            />
            <BookingsSelectorSegment
              isTemporary={entityData.isTemporary}
              places={places}
              entity={entityData}
              currentBooking={currentlyBooked}
              bookings={bookings}
              onSelectBooking={setSelectedBooking}
            />
          </div>
          <div style={informationStyle(device.size === 'mobile', 'left')}>
            <DestinationSegment
              currentStatus={getCurrentStatus()}
              places={places}
              onDestinationChange={(value) => {
                handleFormChange(value, 'location');
              }}
            />
            <StatusButtonsSegment
              isTemporary={entityData.isTemporary}
              currentStatus={getCurrentStatus()}
              onChange={(value) => handleFormChange(value, 'status')}
            />
            <ConfirmButtonSegment
              itemStatusData={getCurrentStatus()}
              disabled={disableCheckin}
              updating={updating}
              isBookingSelected={isBookingSelected}
              onConfirmStatusUpdate={confirmStatusUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  display: 'flex',
  minHeight: '16rem',
  minWidth: '15rem',
  maxWidth: '80rem',
  overflowY: 'auto',
  marginBottom: '2rem',
};

const informationStyle = (isMobile: boolean, position: string): React.CSSProperties => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  marginLeft: position === 'left' && !isMobile ? '0.5rem' : '',
  marginRight: position === 'right' && !isMobile ? '0.5rem' : '',
  width: '24.5rem',
});

const segmentStyle = (isMobile: boolean): React.CSSProperties => ({
  flex: 1,
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
});

/**
 * Helper function to render the place document from the location ID
 * @param places
 * @param entity
 * @param status
 * @returns PlaceDocument
 */
export const renderPlace = (
  places: PlaceDocument[] | undefined,
  entity?: InventoryEntityDocument | undefined,
  status?: InventoryStatusDocument | undefined,
) => {
  const renderFromStatus = (status) => {
    return places?.find((place) => place.id == status?.location);
  };

  const renderFromEntity = (entity) => {
    return places?.find((place) => place.id == entity.lastStatus.location);
  };

  return status ? renderFromStatus(status) : renderFromEntity(entity);
};
