import Lodash from 'lodash';
import { action, computed, observable } from 'mobx';
import * as React from 'react';
import Shortid from 'shortid';
import { Store } from '../../stores';
import { ObservingComponent } from './observingComponent';

/** The default timeout before a search is performed, in milliseconds. */
const DEFAULT_TIMEOUT = 200;

/** The default item template. */
export const DefaultItemTemplate = (props) => <div>{props.item.toString()}</div>;
export const DefaultCreateNewItemTemplate = (props) => (
  <div className="ui fluid blue button">
    <i className="large plus icon" />
  </div>
);

export class SearchListBar extends ObservingComponent<
  { className?: string; placeholder?: string },
  SearchableListStore<any>
> {
  componentDefaultStore() {
    return new SearchableListStore();
  }

  render() {
    return (
      <input
        value={this.store.text}
        className={'ui input ' + this.props.className}
        type="text"
        placeholder={this.props.placeholder}
        onChange={this.store.searchHandler}
      />
    );
  }
}

// The default predicate to use if none is specified. This predicate basically
// searches for items containing all words (separated by spaces) in the search
// text.
export const DefaultPredicate = (text, item) => {
  if (text === undefined || text.length == 0) {
    // No search text means we pass all elements through the filter.
    return true;
  }

  if (!item) {
    // Falsy items never pass through the filter.
    return false;
  }

  const words: string = text.match(/(".*?"|\S+)/g);

  // If the item contains any of the entered words, we consider it a match.
  for (let i = 0; i < words.length; i++) {
    let word = words[i];

    // Ignore empty words.
    if (word.length == 0) {
      continue;
    }

    // Remove citation: "multi word" --> multi word
    if (word[0] == '"' && word[word.length - 1] == '"') {
      word = word.slice(1, -1);
    }

    if (item.toString().toLowerCase().indexOf(word.toLowerCase()) < 0) {
      // The word we're looking for couldn't be found in this item, so
      // filter it out.
      return false;
    }
  }

  // Match found - the tested item contained all require words.
  return true;
};

// Provides an interactive, searchable list of items.
export class SearchableList extends ObservingComponent<
  {
    className?: string;
    wrapper?: any;
    predicate?: any;
    timeout?: number;
    items?: any[];
    template?: any;
    additionalTemplateProps?: any;
    createNewItemTemplate?: any;
    showDefaultSearchBar?: boolean;
  },
  SearchableListStore<any>
> {
  static get defaultProps() {
    return {
      showDefaultSearchBar: true,
      items: [],
      store: undefined,
    };
  }

  private addItemToList = (item: any) => {
    this.store.items.push(item);
  };

  componentDefaultStore() {
    return new SearchableListStore(this.props.items, this.props.predicate, this.props.timeout);
  }

  componentDidMount() {
    // this.domElement.visibility({
    //     once: false,
    //     observeChanges: true,
    //     // TODO: The event is only fired once, tho if you scroll fast it works some times!!?!?!?!
    //     onBottomVisible: () => {
    //         // loads a max of 5 times
    //         console.log("Bottom!!!")
    //         this.store.displayNextChuckOfItems()
    //     }
    // } as any )
  }

  render() {
    // TODO: Allow the client to specify a template for the entire list.
    const template = this.props.template || DefaultItemTemplate;
    let items = Lodash.map(this.store.displayedItems, (item) =>
      React.createElement(
        template,
        Lodash.merge({ key: Shortid.generate(), item: item }, this.props.additionalTemplateProps),
      ),
    );

    // Show add button as first item in the list
    if (this.store.showCreateNewItemButton) {
      const createNewItemTemplate = this.props.createNewItemTemplate || DefaultCreateNewItemTemplate;
      const createNewItemButton = React.createElement(createNewItemTemplate, {
        key: -1,
        addItemToList: this.addItemToList,
      });
      items = Lodash.union<any>([createNewItemButton], items);
    }

    const defaultSearchBar = this.props.showDefaultSearchBar ? (
      <div className="ui fluid small action input">
        <SearchListBar store={this.store} />
        <div className="ui small icon button">
          <i className="search icon" />
        </div>
      </div>
    ) : undefined;

    const Wrapper = this.props.wrapper
      ? this.props.wrapper
      : (props) => {
          return (
            <div style={props.style}>
              {props.searchBar}
              <div className={this.props.className}>{props.children}</div>
            </div>
          );
        };

    const loadMoreItemsButton = this.store.isAllItemsDisplayed ? undefined : (
      <button
        className="ui large fluid blue button"
        style={{ marginTop: '2em' }}
        onClick={this.store.displayNextChuckOfItems}
      >
        Visa fler...
      </button>
    );

    return (
      <div>
        <Wrapper style={{ minHeight: '10em' }} searchBar={defaultSearchBar}>
          {items}
        </Wrapper>
        <div id="bottomOfList" />
        {loadMoreItemsButton}
      </div>
    );
  }
}

export class SearchableListStore<T> extends Store {
  searchTimer: any;
  @observable predicate: (text, item: T) => boolean;
  @observable showCreateNewItemButton: boolean;
  timeout: number;
  chunkSize: number;
  sortByFields: string[];
  reverseSort: boolean;
  @observable nrOfChunksToDisplay: number;
  @observable text: string;
  @observable items: T[];

  @computed private get filteredItems(): T[] {
    return Lodash.filter(this.items, (item) => this.predicate(this.text, item));
  }
  @computed public get sortedItems(): T[] {
    const sortedItems = Lodash.sortBy(this.filteredItems, this.sortByFields);
    return this.reverseSort ? Lodash.reverse(sortedItems) : sortedItems;
  }
  @computed public get displayedItems(): T[] {
    return this.sortedItems.slice(0, this.nrOfChunksToDisplay * this.chunkSize);
  }
  @computed public get isAllItemsDisplayed(): boolean {
    return this.displayedItems.length == this.filteredItems.length;
  }

  constructor(
    items?: T[],
    predicate?: any,
    onCreateNewItem?: any,
    timeout?: number,
    chunkSize?: number,
    sortByFields?: string[],
  ) {
    super();
    this.timeout = timeout || DEFAULT_TIMEOUT;
    this.chunkSize = chunkSize || 10;
    this.predicate = predicate || DefaultPredicate;
    this.showCreateNewItemButton = !!onCreateNewItem;
    this.text = '';
    this.items = items || [];
    this.nrOfChunksToDisplay = 1;
    this.sortByFields = sortByFields || [];
    this.reverseSort = false;
  }

  @action searchHandler = (event) => {
    this.nrOfChunksToDisplay = 1;
    this.text = event.target.value;
  };

  @action displayNextChuckOfItems = () => {
    this.nrOfChunksToDisplay += 1;
  };
}
