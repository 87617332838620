import * as React from 'react';
import { Field, Fields, Input, Rules } from '../../../../../../_dependencies';
import { useLocale } from '../../../../../../_locales';
import { PersonParams } from './personsForm';

interface Props {
  person: PersonParams;
  onTitleChanged: (v: string) => void;
  onPhoneChanged: (v: string) => void;
}

export const RepresentativeFields = (props: Props) => {
  const { t } = useLocale();
  const { relationship, phone } = props.person;
  // Make sure that the current person is a representative or director
  // Should be updated to look at requirements instead.
  if (!relationship?.representative && !relationship?.director) {
    return null;
  }
  return (
    <div className="ui segment">
      <h3>{t('Additional information')}</h3>
      <Fields>
        <Field label={t('Working title')} width="eight wide">
          <Input
            rules={[Rules.NotEmpty()]}
            name="jobtitle"
            value={relationship?.title}
            onChange={props.onTitleChanged}
          />
        </Field>
        {relationship?.representative && (
          <Field label={t('Phone number')} width="eight wide">
            <Input
              label="+46"
              rules={[Rules.NotEmpty(), Rules.IsOnlyDigits()]}
              name="phone"
              value={(phone || '').replace(/^\+46/, '')}
              onChange={props.onPhoneChanged}
            />
          </Field>
        )}
      </Fields>
    </div>
  );
};
