import * as React from 'react';
import { Consume, KosmicComponent, LocaleContext } from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { Maybe } from '../copyFromAH/maybe';
import { MarketplaceCartContext } from '../marketplace.cart.context';
import { PaymentComponent, PaymentMethod } from '../movedFromAH/paymentComponent';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';
import { OrderStepTemplate } from './orderStepTemplate';

export class PaymentStep extends KosmicComponent<{}> {
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  @Consume(WidgetDataProvider.Context)
  private widgetData: IWidgetData;

  @Consume(MarketplaceCartContext)
  private marketplaceCart: MarketplaceCartContext;

  componentDidMount() {
    this.widgetData.verifyStepNavigation('payment');
  }

  private get paymentOptionsForBookings(): PaymentMethod[] {
    const paymentOptions: PaymentMethod[] = ['CreditCard', 'GiftCard'];
    if (this.widgetData.allowsBookingWithoutPayment()) {
      paymentOptions.push('SkipPayment');
    }
    return paymentOptions;
  }

  private get paymentOptionsExcludingGiftcard(): PaymentMethod[] {
    return this.paymentOptionsForBookings.filter((opt) => opt !== 'GiftCard');
  }

  private async skipPaymentAndGoToConfirmation() {
    await this.marketplaceCart.placeOrderWithoutPayment(this.marketplaceCart.cart);
    this.widgetData.gotoStep('confirmation');
  }

  paymentHeader = (t) =>
    this.paymentOptionsExcludingGiftcard.length > 1
      ? t('Select a payment method')
      : t(this.paymentOptionsExcludingGiftcard[0]);

  renderSkipButtonForLoggedInUsers() {
    const { t } = this._locale;
    /* TODO: should we make it work with bookings also? */
    if (this.marketplaceCart.areThereCartBookingsInCart) return null;
    return (
      <Maybe if="organization.manager" and={this.widgetData.allowSpecialSignedInPriviliges}>
        <div className="ui segment">
          <p>{t('widget.orderSteps.paymentStep.skipPaymentYouCan')}</p>
          <button className="ui fluid blue button" onClick={this.skipPaymentAndGoToConfirmation.bind(this)}>
            {t('widget.orderSteps.paymentStep.skipPayment')}
          </button>
        </div>
      </Maybe>
    );
  }

  render() {
    const { t } = this._locale;

    if (!this.marketplaceCart.customer) return <></>;

    return (
      <OrderStepTemplate items={this.marketplaceCart.cart}>
        <div>
          {this.renderSkipButtonForLoggedInUsers()}
          <PaymentComponent
            activePaymentMethods={this.paymentOptionsForBookings}
            cart={this.marketplaceCart.cart}
            amountAlreadyPaid={0}
            onSaved={() => {
              this.widgetData.gotoStep('confirmation');
            }}
            placeOrderWithoutPayment={this.marketplaceCart.placeOrderWithoutPayment.bind(this)}
            placeOrderWithManualPayment={this.marketplaceCart.placeOrderWithManualPayment.bind(this)}
            placeOrderWithCardPayment={this.marketplaceCart.placeOrderWithCardPayment.bind(this)}
            customer={this.marketplaceCart.customer}
            header={this.paymentHeader(t)}
          />
        </div>
      </OrderStepTemplate>
    );
  }
}
