import { RRule } from 'rrule';
import { Types, Schema } from 'mongoose';
import { MongooseDocument, MongooseResource, AbstractUserDocument } from '../_dependencies';
import { Decode, Validate } from '../_dependencies';
import { PropertyDocument } from './property.resource';
import SchemaNames from '../schemas/names';

// Aliases
type RRuleString = string;

/** Representation of the schedule document in mongo database */
export interface ScheduleDocument extends MongooseDocument {
  user: AbstractUserDocument;
  property: PropertyDocument;
  date: Date;
  rRule: RRuleString;
  originScheduleTemplate?: Types.ObjectId;
  isException?: boolean;
  /** Virual field decoded from rRule */ rRuleObject: RRule;
  /** Virual field decoded from rRule */ endTime: Date;
}

/** Representation a generated time from an Rrule object */
export interface ScheduleInstance {
  id?: number;
  startTime: Date;
  endTime: Date;
  property?: PropertyDocument;
  isEditing?: boolean;
  isConflictingWithExistingTime?: boolean;
  isConflictingWithExistingOccurance?: boolean;
  isPossiblyConflictingWithExistingScheduleInstance?: boolean;
  isNew?: boolean;
  disableWholeScheuleInstance?: boolean;
  originScheduleInstance?: ScheduleInstance;
  exceptionScheduleDocument?: ScheduleDocument;
  originScheduleDocument?: ScheduleDocument;
  /** Might not always exist even if its an exception */ isException?: boolean;
}

export class ScheduleResource extends MongooseResource<ScheduleDocument> {
  constructor() {
    super();
    this.setName(SchemaNames.Schedule);

    this.setSchema({
      user: { type: Schema.Types.ObjectId, ref: SchemaNames.Default, required: true },
      property: { type: Schema.Types.ObjectId, ref: SchemaNames.Property, required: true },
      date: { type: Date, required: true },
      rRule: { type: String, required: true },
      originScheduleTemplate: { type: Schema.Types.ObjectId, ref: SchemaNames.Schedule, required: false },
      isException: { type: Boolean, required: false },
    });

    this.addVirtualField('endTime', (document) => {
      const valid = Validate(document.rRule);
      return valid ? Decode(document.rRule).endTime : undefined;
    });

    this.addVirtualField('rRuleObject', (document) => {
      const valid = Validate(document.rRule);
      return valid ? Decode(document.rRule).rule : undefined;
    });
  }
}
