/**
 * @module SubscriptionResource
 * Frontend module to communicate with
 * SubscriptionReceiver
 */
import Stripe from 'stripe';

import { Resource, Document } from '../_dependencies';

export type SubscriptionObject = Stripe.Subscription;
export type UpdateSubscriptionObject = Stripe.SubscriptionUpdateParams;
export type DeletedSubsscriptionObject = Stripe.SubscriptionDeleteParams;
export type SubscriptionItemObject = Stripe.SubscriptionItem;

type Subscription = Document;

/**
 * @class SubscriptionResource
 * TODO Document this
 */
export class SubscriptionResource extends Resource {
  constructor() {
    super();
    this.setName('stripesubscription');
  }

  /**
   * @method createSubscription
   * Creates a new stripe subscription
   * @param items is an array of subscription items with target plans
   * @return promise of a newly created subscription object
   */
  public createSubscription(items: Array<SubscriptionItemObject>, cardCountryCode) {
    return this.sendRequest<SubscriptionObject>('/create', 'post', { items, cardCountryCode });
  }

  /**
   * @method retrieveSubscription
   * Retrieves an existing stripe subscription
   * @param subscriptionId is an id referencing a stripe subscription
   * @return promise of a subscription object
   */
  public retrieveSubscription(subscriptionId: string) {
    return this.sendRequest<SubscriptionObject>('/retrieve', 'post', { subscriptionId });
  }

  /**
   * @method updateSubscription
   * Updates an existing stripe subscription
   * @param subscriptionId is an id referencing a stripe subscription
   * @param values is an object containing the changes to subscription
   * @return promise of an updated subscription object
   */
  public updateSubscription(subscriptionId: string, values: UpdateSubscriptionObject) {
    return this.sendRequest<SubscriptionObject>('/update', 'post', { subscriptionId, values });
  }

  /**
   * @method cancelSubscription
   * Cancel an existing stripe subscription
   * @param subscriptionId is an id referencing a stripe subscription
   * @return promise of a canceled subscription object
   */
  public cancelSubscription(subscriptionId: string) {
    return this.sendRequest<SubscriptionObject>('/cancel', 'post', { subscriptionId });
  }

  /**
   * @method retrieveSubscriptionPlanDetails
   * Retreive an existing stripe subscription plan
   * @param planId is an id referencing a stripe subscription plan
   * @return promise of subscription plan object
   */
  public retrieveSubscriptionPlanDetails() {
    return this.sendRequest<any>('/retrievePlan', 'post', {});
  }
}
