import SchemaNames from '../schemas/names';
import { MongooseDocument, MongooseResource } from '../_dependencies';

export interface ErrorDocument extends MongooseDocument {
  name: string;
  message: string;
  stack: string | undefined;
  device: string;
  os: string;
  browser: string;
  isWrappedApp: boolean;
  isMobileBrowser: boolean;
  userAgent: string;
}

export class ErrorResource extends MongooseResource<ErrorDocument> {
  /** This resource is meant to store thrown errors from the ErrorBoundary component */

  constructor() {
    super();

    this.setName(SchemaNames.Error);

    this.setSchema({
      name: { type: String, required: true },
      message: { type: String, required: true },
      stack: { type: String, required: false },
      device: { type: String, required: true },
      os: { type: String, required: true },
      browser: { type: String, required: true },
      isWrappedApp: { type: Boolean, required: true },
      isMobileBrowser: { type: Boolean, required: true },
      userAgent: { type: String, required: true },
    });
  }
}
