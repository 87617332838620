import * as React from 'react';
import {
  Consume,
  DeviceProvider,
  IDevice,
  LocaleContext,
  MobxComponent,
  OrganizationDocument,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import DashboardTemplate from '../dashboardTemplate';

export { GiftCardDetails } from './details';
export { HandleGiftCardTypes } from './handleGiftCardTypes';
export { ListOfSoldGiftCards } from './listOfSoldCards';
export { SellGiftCardView } from './sell';

export class GiftCardTemplate extends MobxComponent<{}> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  render() {
    const { t } = this._locale;
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { features } = organization.flags;
    if (!features.giftcards) {
      this.globals.session.navigateTo('/dashboard');
      return null;
    }

    const links = [
      {
        path: '/dashboard/giftcards',
        text: t('components.dashboard.giftCards.index.handleGiftCards'),
      },
      {
        path: '/dashboard/giftcards/sold',
        text: t('Sold giftcards'),
        icon: 'money icon',
      },
    ];
    const rightLink = {
      path: '/dashboard/giftcards/sell',
      text: t('Sell a giftcard'),
      icon: 'in cart icon',
    };
    return (
      <DashboardTemplate title={t('Giftcard')} icon="gift icon" tabs={links} rightTab={rightLink}>
        {this.props.children}
      </DashboardTemplate>
    );
  }
}
