import React from 'react';
import {
  ActivityTypeDocument,
  Consume,
  DestinationResource,
  DeviceProvider,
  Field,
  IDevice,
  KosmicComponent,
  LocaleContext,
  OrganizationDocument,
  PeriodPicker,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import ActivityDropdown from './ActivityDropdown';
import { OrganizationDropdown } from './organizationDropdown';

export interface SearchParams {
  query: string;
  onlyCrossSelling: boolean;
  organization?: OrganizationDocument;
  activityList?: ActivityTypeDocument[];
}

interface Props {
  fromDate?: Date;
  toDate?: Date;
  /** Decides if all bookings that are shown are deleted or not */
  showOnlyDeletedBookings?: boolean;
  showRequestBookings?: boolean;
  showUninvoicedBookings?: boolean;
  /** Triggers statechange to get only deleted bookings */
  setShowOnlyDeletedBookings: (showDeletedBookings: boolean) => void;
  setShowRequestBookings: (showRequestBookings: boolean) => void;
  setShowUninvoicedBookings: (showUninvoicedBookings: boolean) => void;
  filter: (searchParams: SearchParams) => void;
  onNewPeriod: (fromDate?: Date, toDate?: Date) => void;
  hideAdditionalFilters?: boolean;
  /** Only displays bookings made on other organizations */
  bookingsOnlyMadeOnOtherOrganizations?: boolean;
}

interface State {
  searchParams: SearchParams;
  isLoading: boolean;
  isPartOfDestination: boolean;
}

export class BookingListFilter extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;
  private searchTimer: NodeJS.Timeout;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  constructor(props: Props) {
    super(props);
    this.state = {
      isPartOfDestination: false,
      searchParams: {
        query: '',
        onlyCrossSelling: false,
        organization: undefined,
        activityList: undefined,
      },
      isLoading: false,
    };
  }

  async componentDidMount() {
    const dResource = new DestinationResource();
    const isPartOfDestination = await dResource.isPartOfDestination(this.globals.session.currentOrganizationId);
    this.setState({ isPartOfDestination });
  }

  private startSearchCountdown = () => {
    this.setState({ isLoading: true });
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.props.filter(this.state.searchParams);
      this.setState({ isLoading: false });
    }, 400);
  };

  private get freeTextFilter() {
    const { t } = this._locale;
    const inputClassName = this.state.isLoading
      ? 'ui large fluid transparent left icon input loading'
      : 'ui large fluid transparent left icon input';

    return (
      <div style={{ display: 'flex', marginBottom: '1em' }}>
        <div style={{ flex: '0 1 50%' }} className={inputClassName}>
          <i className="search icon" />
          <input
            type="text"
            placeholder={t('Search...')}
            value={this.state.searchParams.query}
            onChange={(e) => {
              const searchParams = { ...this.state.searchParams };
              searchParams.query = e.target.value;
              this.setState({ searchParams: searchParams }, this.startSearchCountdown);
            }}
          />
        </div>
      </div>
    );
  }

  private get crossSellingFilterCheckbox() {
    const { t } = this._locale;
    return (
      <div className="ui header">
        <div className="ui toggle checkbox">
          <input
            type="checkbox"
            name="crossSelling"
            checked={this.state.searchParams.onlyCrossSelling}
            onChange={() => {
              const searchParams = { ...this.state.searchParams };
              searchParams.onlyCrossSelling = !searchParams.onlyCrossSelling;
              this.setState({ searchParams }, () => this.props.filter(this.state.searchParams));
            }}
          />
          <label>{t('showOnlyCrossSelling')}</label>
        </div>
      </div>
    );
  }

  /** Toggle for showing only nonDeleted- or only deleted bookings  */
  private get isDeletedCheckbox() {
    const { t } = this._locale;
    return (
      <div>
        <div className="ui toggle checkbox">
          <input
            type="checkbox"
            name="deleted"
            checked={this.props.showOnlyDeletedBookings}
            onChange={() => this.props.setShowOnlyDeletedBookings(!this.props.showOnlyDeletedBookings)}
          />
          <label>{t('Show deleted bookings')}</label>
        </div>
      </div>
    );
  }

  /** Toggle for showing booking requests  */
  private get isBookingRequestCheckbox() {
    const { t } = this._locale;
    return (
      <div style={this.device.size !== 'mobile' ? { marginLeft: '1rem' } : { marginTop: '1rem' }}>
        <div className="ui toggle checkbox">
          <input
            type="checkbox"
            name="bookingRequest"
            checked={this.props.showRequestBookings}
            onChange={() => this.props.setShowRequestBookings(!this.props.showRequestBookings)}
          />
          <label>{t('Show booking requests')}</label>
        </div>
      </div>
    );
  }

  /** Toggle unInvoicedBokings  */
  private get unInvoicedCheckbox() {
    const { t } = this._locale;
    return (
      <div style={this.device.size !== 'mobile' ? { marginLeft: '1rem' } : { marginTop: '1rem' }}>
        <div className="ui toggle checkbox">
          <input
            type="checkbox"
            name="uninvoiced"
            checked={this.props.showUninvoicedBookings}
            onChange={() => this.props.setShowUninvoicedBookings(!this.props.showUninvoicedBookings)}
          />
          <label>{t('Show uninvoiced bookings')}</label>
        </div>
      </div>
    );
  }

  private get datesSelector() {
    const { t } = this._locale;
    const { fromDate, toDate, onNewPeriod } = this.props;
    return (
      <Field style={datePickerContainer}>
        <PeriodPicker
          defaultStartValue={fromDate}
          defaultEndValue={toDate}
          startName={t('fromDate')}
          endName={t('To date')}
          startId="from"
          endId="to"
          type="date"
          startPlaceHolder={t('Choose date')}
          endPlaceHolder={t('Choose date')}
          icon="calendar"
          style={{ marginBottom: '1em' }}
          horizontal
          horizontalIcon="arrow right"
          onChange={onNewPeriod}
        ></PeriodPicker>
      </Field>
    );
  }

  private handleSelectOrganization = (selectedOrganization) => {
    const searchParams = { ...this.state.searchParams };
    searchParams.organization = selectedOrganization;
    this.setState({ searchParams }, () => this.props.filter(this.state.searchParams));
  };

  private get organizationDropdown() {
    const { t } = this._locale;
    if (this.state.isPartOfDestination) {
      return (
        <Field style={{ marginTop: '1em' }} label={t('destination-partner')}>
          <div style={{ marginTop: '3px' }}></div>
          <OrganizationDropdown
            organization={this.state.searchParams.organization}
            isDisabled={!this.state.searchParams.onlyCrossSelling}
            onSelect={this.handleSelectOrganization}
          />
        </Field>
      );
    }
  }

  private get activityDropdown() {
    const { t } = this._locale;

    return (
      <Field
        style={{ marginTop: '1em', marginLeft: this.device.size !== 'mobile' ? '2rem' : ' ' }}
        label={t('Activity') + ' (' + t('leave-empty-for-all') + ')'}
      >
        <div style={{ marginTop: '3px' }}></div>
        <ActivityDropdown
          listAllActivitiesInDestination={this.props.bookingsOnlyMadeOnOtherOrganizations}
          multiple
          organizationId={this.globals.session.currentOrganizationId}
          onSelect={(activities) => {
            const searchParams = { ...this.state.searchParams };
            searchParams.activityList = activities;
            this.setState({ searchParams }, () => this.props.filter(this.state.searchParams));
          }}
        />
      </Field>
    );
  }

  private get crossSellingFilters() {
    if (!this.state.isPartOfDestination || this.props.hideAdditionalFilters) return null;

    return (
      <div className="ui message">
        {this.crossSellingFilterCheckbox}
        {this.organizationDropdown}
      </div>
    );
  }

  public render() {
    return (
      <>
        {this.freeTextFilter}
        <div style={{ borderTop: 'solid lightgrey 1px' }}>
          <div style={{ display: this.device.size !== 'mobile' ? 'flex' : '' }}>
            {this.datesSelector}
            {this.activityDropdown}
          </div>
          <div
            style={
              this.device.size !== 'mobile'
                ? { display: 'flex', alignItems: 'center', marginTop: '1rem' }
                : { marginTop: '1rem' }
            }
          >
            {this.isDeletedCheckbox}
            {this.isBookingRequestCheckbox}
            {this.unInvoicedCheckbox}
          </div>
          {this.crossSellingFilters}
        </div>
      </>
    );
  }
}

const datePickerContainer: React.CSSProperties = {
  display: 'flex',
  marginTop: '1rem',
};
