import React, { useContext, useState } from 'react';
import {
  Checkbox,
  Field,
  getRescheduleInsurance,
  Input,
  PriceCategoryDocument,
  AccessoryDocument,
  Rules,
  DeviceProvider,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface Props {
  categories: PriceCategoryDocument[];
  onPriceCategoriesChange: (key: 'priceCategories', value: PriceCategoryDocument[]) => void;
}

export const RescheduleInsuranceFields = ({ categories, onPriceCategoriesChange }: Props) => {
  const { t } = useLocale();
  const device = useContext(DeviceProvider.Context);

  const rescheduleInsuranceCategory = getRescheduleInsurance(categories);

  const rescheduleInsuranceIndex = categories.findIndex((category) => category == rescheduleInsuranceCategory);

  const addOrRemoveRescheduleInsurance = () => {
    const nextCategories = [...categories];
    if (rescheduleInsuranceCategory) {
      nextCategories.splice(rescheduleInsuranceIndex, 1);
    } else {
      const newInsuranceCategory: AccessoryDocument<PriceCategoryDocument> = {
        name: { sv: 'Ombokningsförsäkring', en: 'Reschedule insurance', de: 'Umbuchungsschutz' },
        price: undefined as unknown as number,
        groupMultiplier: 0,
        taxRate: 0,
        rescheduleInsurance: {
          validInDays: undefined as unknown as number,
          lastRescheduleTime: undefined as unknown as number,
        },
      };
      nextCategories.push(newInsuranceCategory);
    }
    onPriceCategoriesChange('priceCategories', nextCategories);
  };

  const changePriceCategory = (index: number, priceCategory: PriceCategoryDocument) => {
    if (index === -1) return;
    const nextCategories = [...categories];
    nextCategories[index] = priceCategory;
    onPriceCategoriesChange('priceCategories', nextCategories);
  };

  const rescheduleInsuranceField = () => {
    if (!rescheduleInsuranceCategory) return null;
    const price = rescheduleInsuranceCategory.price ? String(rescheduleInsuranceCategory.price) : '';
    const daysInAdvance = rescheduleInsuranceCategory.rescheduleInsurance.lastRescheduleTime
      ? String(rescheduleInsuranceCategory.rescheduleInsurance.lastRescheduleTime)
      : '';
    const daysValid = rescheduleInsuranceCategory.rescheduleInsurance.validInDays
      ? String(rescheduleInsuranceCategory.rescheduleInsurance.validInDays)
      : '';
    const feePerPerson = rescheduleInsuranceCategory.rescheduleInsurance.isFeePerPerson
      ? rescheduleInsuranceCategory.rescheduleInsurance.isFeePerPerson
      : false;
    return (
      <div className="ui tiny header" style={{ marginTop: '.5rem' }}>
        <p className="sub header">{t('Specify below how long the credit code should be valid...')}</p>
        <div
          style={{
            padding: 0,
            margin: 0,
            display: 'flex',
            flexDirection: device.size === 'mobile' ? 'column' : 'row',
            columnGap: '.5rem',
          }}
        >
          <Field style={{ margin: '1em 0 0 0', flex: 1 }}>
            <p style={{ marginBottom: '.5rem' }}> {t('Credit code validity')}</p>
            <Input
              name="RescheduleInsuranceCreditValidity"
              value={daysValid}
              onChange={(value) => {
                const editedInsuranceCategory: AccessoryDocument<PriceCategoryDocument> = {
                  ...rescheduleInsuranceCategory,
                  rescheduleInsurance: {
                    ...rescheduleInsuranceCategory.rescheduleInsurance,
                    validInDays: parseInt(value),
                  },
                };
                changePriceCategory(rescheduleInsuranceIndex, editedInsuranceCategory);
              }}
              placeholder={t('Validity')}
              rules={[Rules.NotEmpty(), Rules.IsIntegerOneOrHigherElseEmpty()]}
              rightLabel={t('days')}
              inputStyle={{ width: '6rem' }}
            />
          </Field>
          <Field style={{ margin: '1em 0 0 0', flex: 1 }}>
            <p style={{ marginBottom: '.5rem' }}> {t('Latest reschedule')}</p>
            <Input
              name="RescheduleInsurance"
              value={daysInAdvance}
              onChange={(daysInAdvance) => {
                const editedInsuranceCategory: AccessoryDocument<PriceCategoryDocument> = {
                  ...rescheduleInsuranceCategory,
                  rescheduleInsurance: {
                    ...rescheduleInsuranceCategory.rescheduleInsurance,
                    lastRescheduleTime: parseInt(daysInAdvance),
                  },
                };
                changePriceCategory(rescheduleInsuranceIndex, editedInsuranceCategory);
              }}
              placeholder={t('Latest reschedule')}
              rules={[Rules.NotEmpty(), Rules.IsIntegerOneOrHigherElseEmpty()]}
              rightLabel={t('hours')}
              inputStyle={{ width: '6rem' }}
            />
          </Field>
        </div>
        <div
          style={{
            padding: 0,
            margin: 0,
            display: 'flex',
            flexDirection: device.size === 'mobile' ? 'column' : 'row',
            columnGap: '.5rem',
            alignItems: device.size === 'mobile' ? 'normal' : 'flex-end',
          }}
        >
          <Field style={{ margin: '1em 0 0 0', flex: 1 }}>
            <p style={{ marginBottom: '.5rem' }}> {t('Price')}</p>
            <Input
              name="RescheduleInsurancePrice"
              value={price}
              onChange={(value) =>
                changePriceCategory(rescheduleInsuranceIndex, {
                  ...rescheduleInsuranceCategory,
                  price: parseInt(value),
                })
              }
              placeholder={t('Price')}
              rules={[Rules.NotEmpty(), Rules.IsIntegerOneOrHigherElseEmpty()]}
              rightLabel={t('kr-SEK')}
              inputStyle={{ width: '6rem' }}
            />
          </Field>
          <Field style={{ margin: device.size === 'mobile' ? '1em 0 0 0' : '1em' }}>
            <Checkbox
              label={t('Price per person')}
              checked={feePerPerson}
              onCheckedOrUncheked={() => {
                const editedInsuranceCategory: AccessoryDocument<PriceCategoryDocument> = {
                  ...rescheduleInsuranceCategory,
                  rescheduleInsurance: {
                    ...rescheduleInsuranceCategory.rescheduleInsurance,
                    isFeePerPerson: !feePerPerson,
                  },
                };
                changePriceCategory(rescheduleInsuranceIndex, editedInsuranceCategory);
              }}
            />
          </Field>
        </div>
      </div>
    );
  };

  return (
    <div className="ui segment" style={{ marginTop: '2rem' }}>
      <div className="ui tiny header" style={{ marginBottom: 0 }}>
        <Checkbox
          toggle
          checked={Boolean(rescheduleInsuranceCategory)}
          onCheckedOrUncheked={addOrRemoveRescheduleInsurance}
          label={t('Add reschedule insurance')}
          style={{ marginBottom: '.5rem' }}
        />
        <p className="sub header">{t('If a customer chooses to buy a reschedule insurance...')}</p>
      </div>
      {rescheduleInsuranceField()}
    </div>
  );
};
