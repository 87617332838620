import { Types } from 'mongoose';
import * as React from 'react';
import { MobxComponent, UserRoleType } from '../_dependencies';

interface Props {
  if?: UserRoleType;
  and?: boolean;
  or?: boolean;
  property?: Types.ObjectId;
  strict?: boolean;
}

export class Maybe extends MobxComponent<Props> {
  private get isAuthenticated() {
    const isUserAuthentcated = this.props.if
      ? this.globals.session.currentUser &&
        this.globals.session.userHasRole(
          {
            type: this.props.if,
            property: this.props.property,
            organization: this.globals.session.currentOrganizationId,
          },
          !!this.props.strict,
        )
      : true;

    const andStatement = this.props.and === undefined ? true : this.props.and;
    const orStatement = this.props.or;

    return (isUserAuthentcated && andStatement) || orStatement;
  }

  render() {
    if (this.isAuthenticated) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    }

    return null;
  }
}
