import * as React from 'react';

import {
  OrganizationDocument,
  BookingDocument,
  OrganizationResource,
  Consume,
  LocaleContext,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';

interface Props {
  booking: BookingDocument;
}

interface State {
  originatingOrg?: OrganizationDocument;
  type: 'bookedFromOther' | 'bookedFromMe';
}

export class CrossSelling extends React.Component<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  constructor(props) {
    super(props);

    this.state = {
      originatingOrg: undefined,
      type: 'bookedFromOther',
    };
  }

  async componentDidMount() {
    const crossSellingProperty = this.props.booking.crossSellingProperty;
    if (crossSellingProperty) {
      const orgResource = new OrganizationResource();
      const foundOrg = await orgResource.find({ _id: crossSellingProperty.organization });
      this.setState({
        originatingOrg: foundOrg[0],
        type: 'bookedFromOther',
      });
    }
  }

  render() {
    const { t, tt } = this._locale;
    const crossSellingProperty = this.props.booking.crossSellingProperty;

    if (!crossSellingProperty || !this.state.originatingOrg) {
      return '';
    }

    return (
      <div className="ui list">
        <div className="item" style={{ marginBottom: '0rem' }}>
          <div className="content">
            <img
              style={{ margin: '0 .6rem 0 -0.15rem' }}
              src={'/static/commons/svg/crossSellingIconDark.svg'}
              alt="cross-selling"
            />
            <b>{t('destination-partner')}</b>
          </div>
        </div>
        <div className="item">
          <i className="briefcase icon" />
          <div className="content">
            {t('Organization') + ': '}
            {this.state.originatingOrg.name}
          </div>
        </div>
        <div className="item">
          <i className="map pin icon" />
          <div className="content">
            {t('Property') + ': '}
            {tt(crossSellingProperty.name)}
          </div>
        </div>
        <div className="item">
          <i className="envelope outline icon" />
          <div className="content">
            {t('Email') + ': '}
            {crossSellingProperty.email}
          </div>
        </div>
        <div className="item">
          <i className="phone icon" />
          <div className="content">
            {t('Phone') + ': '}
            {crossSellingProperty.phoneNo}
          </div>
        </div>
      </div>
    );
  }
}
