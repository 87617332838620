import { Resource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { ActivityOccuranceDocument } from './activityOccurance.resource';
import { PropertyDocument } from './property.resource';
import { UserDocument } from './user.resource';

export class MailResource extends Resource {
  constructor() {
    super();
    this.setName(SchemaNames.Mail);
  }

  sendBookingConfirmationEmail(
    bookingId: string,
    paymentLink?: string,
    fromActor?: boolean,
    bookingRequestPaid?: boolean,
  ) {
    return this.sendRequest<boolean>('/sendBookingConfirmationEmail', 'post', {
      bookingId,
      paymentLink,
      fromActor,
      bookingRequestPaid,
    });
  }

  sendBookingRejectionEmail(bookingId: string) {
    return this.sendRequest<boolean>('/sendBookingRejectionEmail', 'post', { bookingId });
  }

  sendGiftCardConfirmationEmail(giftCardId: string) {
    return this.sendRequest<boolean>('/sendGiftCardConfirmationEmail', 'post', { giftCardId: giftCardId });
  }

  sendFindCuramusianEmail(form: any) {
    return this.sendRequest<boolean>('/sendFindCuramusianEmail', 'post', { form });
  }

  sendActivityChangedEmailToEmployees(originalActivity: ActivityOccuranceDocument) {
    return this.sendRequest<boolean>('/sendActivityChangedEmailToEmployees', 'post', { originalActivity });
  }

  sendWorkInvitationToEmployeesRequest(
    employees: UserDocument[],
    activity: ActivityOccuranceDocument,
    property: PropertyDocument,
    organizationName: string,
  ) {
    return this.sendRequest<boolean>('/sendWorkInvitationToEmployeesRequest', 'post', {
      employees,
      activity,
      property,
      organizationName,
    });
  }

  sendWorkInvitationConfirmationEmail(employeeId: string, activityId: string) {
    return this.sendRequest<boolean>('/sendWorkInvitaionConfirmationEmail', 'post', { employeeId, activityId });
  }

  sendContactMailFromCustomer(
    firstname: string,
    lastname: string,
    mailAddress: string,
    subject: string,
    message: string,
    toMail: string,
  ) {
    return this.sendRequest<boolean>('/sendContactMailFromCustomer', 'post', {
      firstname,
      lastname,
      mailAddress,
      subject,
      message,
      toMail,
    });
  }

  sendPaymentLinkEmail(bookingId: string, link: string) {
    return this.sendRequest<boolean>('/sendPaymentLink', 'post', { bookingId, link });
  }

  sendWelcomeEmailToCustomer(bookingId: string) {
    return this.sendRequest<boolean>('/sendWelcomeEmailToCustomer', 'post', { bookingId });
  }

  sendThankYouEmailToCustomer(bookingId: string) {
    return this.sendRequest<boolean>('/sendThankYouEmailToCustomer', 'post', { bookingId });
  }

  sendActivityHasBeenChangedToBookedCustomers(occuranceId: string) {
    return this.sendRequest<boolean>('/sendActivityHasBeenChangedToBookedCustomers', 'post', { occuranceId });
  }
}
