import { Schema, Types } from 'mongoose';

import SchemaNames from './names';

export interface LimitationsDocument {
  organizations: Types.ObjectId[];
  properties: Types.ObjectId[];
  activityTypes: Types.ObjectId[];
}

export var LimitationsSchema = new Schema(
  {
    organizations: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: false }],
    properties: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Property, required: false }],
    activityTypes: [{ type: Schema.Types.ObjectId, ref: SchemaNames.ActivityType, required: false }],
  },
  { _id: false },
);
