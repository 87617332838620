import Lodash from 'lodash';
import * as Mobx from 'mobx';
import Mongoose from 'mongoose';

import { MongooseDocument } from '../documents';
import { Resource } from './_abstractResource';

/** A mongoose resource */
export abstract class MongooseResource<D extends MongooseDocument> extends Resource {
  /** Typeguard attribute */ protected mongooseResourceIdentifier = true;
  /** Mongoose schema */ public schema: Mongoose.Schema;
  /** Indicates the parent if this resource is a subtype of another mongoose resource */ public parent: string;

  _populatedFields: any[] = [];

  constructor() {
    super();
    this.schema = new Mongoose.Schema();

    // Include virtuals when mongoose documents are converted to objects
    this.schema.set('toObject', { virtuals: true });
    this.schema.set('toJSON', { virtuals: true });
    this.schema.set('timestamps', true);
  }

  /** Must be given a valid mongoose schema definition */
  protected setSchema(schemaDefinition: Mongoose.SchemaDefinition) {
    this.schema.add(schemaDefinition);
  }

  /** Set fields that should always be populated */
  protected populateField(definition: any) {
    this._populatedFields.push(definition);
  }

  /** Adds a virtual path to the mongoose schema */
  protected addVirtualField(path: string, getter: (schema: D) => any, setter?: (schema: D, value: any) => void) {
    // TODO: This seems to be less than working when returing an object through res.send
    let field = this.schema.virtual(path);
    field = field.get(function () {
      return getter(this);
    });
    if (setter) {
      field = field.set(function (value) {
        return setter(this, value);
      });
    }
  }

  /** Make this resource a subtype of the resource with the given name */
  protected setParentResource(name: string) {
    this.parent = name;
    //this.schema.set('discriminatorKey', '_type');
  }

  /** Returns a new resource document, it is not quarantied to be valid */
  public createDocument(object: Object): D {
    if (MODULE_ENVIRONMENT == 'node') {
      return object as D; // TODO: this should be improved, on server side we cant create documents without accessing the model
    }

    // Remove mobx observable, also clones the object
    object = Mobx.isObservable(object) ? Mobx.toJS(object) : object;

    // TODO: remove all extendedObservables as well!

    // Reset all populated fields to ObjectIds
    for (const key in object) {
      // So far I've found that _id is also present in the mongoose _doc and $__ keys, could there be other keys?
      if (object[key] && object[key]._id && key != '_doc' && key != '$__') {
        object[key] = object[key]._id;
      }
    }

    const isNew = object['isNew'];
    const document = new (Mongoose as any).Document(object, this.schema) as D;
    document.isNew = isNew || true;
    return document;
  }

  /** Validates a given object as a valid document conforming to this resource's schema */
  public validateDocument(document: MongooseDocument): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      document.validate((err) => {
        if (err) {
          Lodash.each(err.errors, (error) => {
            console.error("Document couldn't be validated", error.message);
          });
          return reject(err);
        } else return resolve();
      });
    });
  }

  // Request methods
  get(id: String | Mongoose.Types.ObjectId, passwordResetToken?: string) {
    // TODO: ugly solution to auth problem
    return this.sendRequest<D>('/get', 'post', { id: id, resetToken: passwordResetToken });
  }
  delete(
    id: String | Mongoose.Types.ObjectId,
    options: { /** permanently remove the document record from the database */ removeRecord?: boolean } = {},
  ) {
    return this.sendRequest<boolean>('/delete', 'post', { id, ...options });
  }
  restore(id: String | Mongoose.Types.ObjectId) {
    return this.sendRequest<boolean>('/restore', 'post', { id });
  }

  find(
    query: Object,
    fieldsToPopulate?: PopulateQuery[],
    virtualFieldsToPopulate?: object[],
    passwordResetToken?: string,
  ) {
    // TODO: passwordResetToken = ugly solution to auth problem
    return this.sendRequest<D[]>('/find', 'post', {
      query,
      fieldsToPopulate,
      virtualFieldsToPopulate,
      resetToken: passwordResetToken,
    });
  }

  // TODO: Remove updateById since updateDocument does the same and is used all over the place
  updateById(id: String | Mongoose.Types.ObjectId, updateObject: Partial<D>) {
    return this.sendRequest<boolean>('/updateById', 'post', { id, updateObject });
  }
  updateDocument(document: D) {
    return new Promise<string>((resolve, reject) => {
      if (!document.validate) {
        document = this.createDocument(document);
      }

      return this.validateDocument(document)
        .then(() => resolve(this.sendRequest<string>('/update', 'post', { document })))
        .catch(reject);
    });
  }
}
