import Lodash from 'lodash';
import * as MobxReact from 'mobx-react';
import Moment from 'moment-timezone';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import { ProvisionedDebugFunctionality } from '../../provision/debug';
import { DeviceManager } from '../../stores/deviceManager';
import { SessionManager } from '../../stores/sessionManager';

/** Default properties for all components */
export interface DefaultComponentProps {
  style?: React.CSSProperties;
  className?: string;
  //children?: React.React, TODO: Typing children is broken in later react versions, readd if neccessary
  /** Only available on route components */ location?: any;
}

/**
 * The base component for all other components in the submodule
 */
export abstract class Component<Props, State = {}> extends React.Component<Props & DefaultComponentProps, State> {
  /** The DOM/JQuery node for this component when mounted */
  protected get domElement() {
    return $(ReactDom.findDOMNode(this) as Element);
  }
}

/**
 * The observing base component for all other components in the submodule
 */
@MobxReact.observer
export class MobxComponent<Props, State = {}> extends Component<Props, State> {
  protected get globals() {
    // TODO: when rendering without all providers, like for example when rendering gift card previews in bumblebook
    // these keys in context will be undefined - how do we handle this? Typings would be optimal

    return {
      device: this.context['device'] as DeviceManager,
      session: this.context['session'] as SessionManager,
    };
  }

  protected formatDate(date: Date, format: string) {
    return Moment(date).tz('Europe/Stockholm').format(format);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // NOTE: This implementation removes mobx 'highly optimized' implementation but fixes alot of problems with reacy-router, keep?
    return true;
  }
  static extendContextTypes(contextTypes) {
    return Lodash.merge(contextTypes, MobxComponent.contextTypes);
  }
  static contextTypes = {
    device: PropTypes.object,
    session: PropTypes.object,
    debug: PropTypes.object,
    intl: PropTypes.object,
  };
}

/** This is used for typeguarding between the prototype of mobxcomponents, and other components. */
(MobxComponent.prototype as any).isAMobxComponent = true;

/**
 * Brand New MobxComponent, now 100% mobx free!
 */
export class KosmicComponent<Props, State = {}> extends Component<Props, State> {
  /**
   * Await state is a wrapper around Component.setState
   * which makes it a async call.
   */
  protected awaitState<K extends keyof State>(state: Pick<State, K> | State): Promise<State> {
    return new Promise((resolve) => {
      this.setState(state, () => {
        resolve(this.state);
      });
    });
  }

  protected get globals() {
    // TODO: when rendering without all providers, like for example when rendering gift card previews in bumblebook
    // these keys in context will be undefined - how do we handle this? Typings would be optimal
    return {
      session: this.context['session'] as SessionManager,
    };
  }
  protected formatDate(date: Date, format: string) {
    return Moment(date).tz('Europe/Stockholm').format(format);
  }
  static extendContextTypes(contextTypes) {
    return Lodash.merge(contextTypes, MobxComponent.contextTypes);
  }
  static contextTypes = {
    device: PropTypes.object,
    session: PropTypes.object,
    debug: PropTypes.object,
    intl: PropTypes.object,
  };
}
