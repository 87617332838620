import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  BookingPriceCategoryDocument,
  DeviceProvider,
  IDevice,
  MarketplaceLibrary as Lib,
  OrganizationDocument,
  SubmitButton,
  UserDocument,
  getTickets,
  visitors,
} from './_dependencies';
import { useLocale } from './_locales';
import { DatalayerContext } from './datalayer.context';
import { usePrevious } from './hooks/usePrevious';
import { MarketplaceCartContext } from './marketplace.cart.context';
import { WidgetDataContext } from './widgetDataProvider';
import { StepId } from './widgetStep';

type TStyleProp = {
  styles: ReturnType<typeof generateStyles>;
};

type TCartButtonProps = TStyleProp & {
  cartRef: (el?: HTMLElement | null) => void;
  cartLength: number;
  cartDisabled: boolean;
  sendBeginCheckoutEvent: (cart: Lib.CartItem[], organization: OrganizationDocument) => void;
  gotoStep: (id: StepId, isLastStep?: boolean | undefined) => Promise<void>;
};
const CartButton = ({
  cartRef,
  cartDisabled,
  cartLength,
  gotoStep,
  styles,
  sendBeginCheckoutEvent,
}: TCartButtonProps) => {
  const { cart } = useContext(MarketplaceCartContext);
  const { selectedOrganization } = useContext(WidgetDataContext);
  const onClick = () => {
    sendBeginCheckoutEvent(cart, selectedOrganization);
    gotoStep('customerInfo');
  };
  return (
    <div style={styles.cartButton.wrapper}>
      <button
        className={`${cartDisabled ? 'disabled' : ''} big circular ui icon button`}
        style={styles.cartButton.button}
        onClick={onClick}
        ref={cartRef}
      >
        {!cartDisabled && (
          <div style={styles.cartButton.indicator} className="circular ui red label">
            {cartLength}
          </div>
        )}
        <i className="shopping cart icon"></i>
      </button>
    </div>
  );
};

type TAddToCartButtonProps = TStyleProp & {
  addDisabled: boolean;
  shouldRenderAddToCartButton: boolean;
  addLabel: string;
  addBookingProduct: (priceCategories: BookingPriceCategoryDocument[]) => void;
  selectedPriceCategories: BookingPriceCategoryDocument[];
  deviceSize: IDevice['size'];
};

// TODO: tillfällig lösning nedan (med useEffect och lokalt state) för att lösa issue #2089. Kan tas bort om vi hittar en bättre lösning på problemet.
const AddToCartButton = ({
  shouldRenderAddToCartButton,
  addDisabled = false,
  addLabel,
  addBookingProduct,
  selectedPriceCategories,
  styles,
  deviceSize,
}: TAddToCartButtonProps) => {
  const [_, setPriceCategories] = useState<BookingPriceCategoryDocument[]>();

  useEffect(() => {
    setPriceCategories(selectedPriceCategories);
  }, [selectedPriceCategories]);

  if (!shouldRenderAddToCartButton) return null;
  if (deviceSize === 'desktop') {
    return (
      <SubmitButton
        onClick={() => addBookingProduct(selectedPriceCategories)}
        style={styles.addButton.regular.button}
        disabled={addDisabled}
        className={`green button`}
      >
        {addLabel} <i className="cart plus right icon" />
      </SubmitButton>
    );
  }
  return (
    <div style={styles.addButton.circular.wrapper}>
      <span style={styles.addButton.circular.label}>{addLabel}</span>
      <button
        className={`${addDisabled ? 'disabled' : ''} green big circular ui icon button`}
        style={styles.addButton.circular.button}
        onClick={() => addBookingProduct(selectedPriceCategories)}
      >
        <i className="cart plus icon"></i>
      </button>
    </div>
  );
};

type TBackButtonProps = TStyleProp & {
  backDisabled: boolean;
  shouldRenderBackButton: boolean;
  deselectActivity: () => void;
};
const BackButton = ({ backDisabled, deselectActivity, shouldRenderBackButton, styles }: TBackButtonProps) => {
  if (!shouldRenderBackButton) return null;
  return (
    <div style={styles.backButton.wrapper}>
      <button
        className={`${backDisabled ? 'disabled' : ''} big circular ui icon button`}
        style={styles.backButton.button}
        onClick={deselectActivity}
      >
        <i className="chevron left icon"></i>
      </button>
    </div>
  );
};

const ActionButtonsBlock: FC<TStyleProp> = ({ children, styles }) => {
  return <div style={styles.buttonsBlock}>{children}</div>;
};

const ButtonGroup: FC<TStyleProp> = ({ children, styles }) => {
  return <div style={styles.buttonGroup}>{children}</div>;
};

interface IProps {
  /** Describes if cart buttons should be rendered at all  */ shouldRender: boolean;
  /** The current logged in user */ user: UserDocument | undefined;
}

/**
 * This component displays and manages cart action buttons
 */
const WithCartActionButtons: FC<IProps> = ({ children, shouldRender, user }) => {
  /** Contexts */
  const {
    gotoStep,
    selectedPriceCategories,
    selectedStepId,
    selectedPurchaseType,
    selectedActivity,
    allowBookingVisitorsFreely,
    ...widget
  } = useContext(WidgetDataContext);
  const { cart, numberOfVisitorsForOccurance, ...marketplace } = useContext(MarketplaceCartContext);
  const device = useContext(DeviceProvider.Context);

  /** Hooks */
  const { t } = useLocale();
  const cartBtn = useRef<HTMLElement | undefined | null>();
  const prevCartLength = usePrevious(cart.length);
  const { sendAddToCartEvent, sendBeginCheckoutEvent } = useContext(DatalayerContext);

  /** Cart button options */
  const cartLength = cart.length;
  const cartDisabled = cartLength <= 0;
  const animationType = device.size === 'mobile' ? 'pulse' : 'tada';
  const animationDuration = animationType === 'tada' ? 600 : 300;

  /** Back button options */
  const backDisabled = !selectedActivity;
  const shouldRenderBackButton =
    device.size === 'mobile' && selectedPurchaseType === 'bookings' && selectedStepId !== 'activityShowcase';
  const deselectActivity = () => widget.setState({ selectedActivity: undefined, selectedPriceCategories: [] });

  /** Add button options */
  const deviceSize = device.size;
  const addDisabled: boolean = (() => {
    const visitorIsSelected = Boolean(getTickets(selectedPriceCategories).length);
    if (widget.bookingRequest && visitorIsSelected) {
      return false;
    }
    const minimumVisitorsForActivity = selectedActivity?.minVisitors || 0;
    const availableVistors = selectedActivity?.availableVisitors || 0;
    const numberOfVisitorsSelected: number = selectedPriceCategories.reduce((sum, pc) => sum + visitors(pc), 0);
    const selectedLessThanMinimum = numberOfVisitorsSelected < minimumVisitorsForActivity;
    const selectedAndCartMoreThanAvailable =
      numberOfVisitorsSelected + numberOfVisitorsForOccurance(selectedActivity?.id) > availableVistors;
    if (allowBookingVisitorsFreely) {
      return !visitorIsSelected;
    }

    return !visitorIsSelected || selectedLessThanMinimum || selectedAndCartMoreThanAvailable;
  })();
  const shouldRenderAddToCartButton = selectedPurchaseType === 'bookings' && selectedStepId === 'activitySelection';
  //Code below used when selectedStepId were used to make a bookingRequest in separate files, remove when booking request feature merges to main
  // (selectedPurchaseType === 'bookingRequest' && selectedStepId === 'bookingRequestSelection');

  const addLabel: string = (() => {
    if (!selectedActivity) return t('Select activity');
    if (addDisabled) return t('Select tickets');

    return t('Add to cart');
  })();
  const addBookingProduct = (priceCategories: BookingPriceCategoryDocument[] | undefined) => {
    marketplace.placeItemInCart({ priceCategories, user });
    if (selectedActivity && priceCategories) {
      sendAddToCartEvent(selectedActivity, priceCategories);
    }
  };

  /** styling collection */
  const styles = generateStyles(device.size, shouldRenderAddToCartButton, widget.backgroundColor);

  /** Manages cart button animation on cart button */
  useEffect(() => {
    if (!cart.length || !cartBtn.current || !prevCartLength) return;
    if (cartLength <= prevCartLength) return;
    $(cartBtn.current).transition({
      animation: animationType,
      duration: animationDuration,
    });
  }, [cart.length, prevCartLength, cartBtn.current, animationType, animationDuration]);

  if (!shouldRender) return <div style={{ marginTop: '1rem' }}>{children}</div>;

  return (
    <div style={{ padding: '.5rem 0', position: 'relative' }}>
      <ActionButtonsBlock {...{ styles }}>
        <ButtonGroup {...{ styles }}>
          <CartButton
            cartRef={(el) => (cartBtn.current = el)}
            {...{ cartLength, cartDisabled, gotoStep, styles, sendBeginCheckoutEvent }}
          />
          <AddToCartButton
            {...{
              addDisabled,
              shouldRenderAddToCartButton,
              addLabel,
              addBookingProduct,
              selectedPriceCategories,
              styles,
              deviceSize,
            }}
          />
        </ButtonGroup>
        <BackButton {...{ backDisabled, deselectActivity, shouldRenderBackButton, styles }} />
      </ActionButtonsBlock>
      <div style={styles.contentWrapper}>{children}</div>
    </div>
  );
};

const generateStyles = (size: IDevice['size'], addButton: boolean, widgetBg: string) =>
  ({
    buttonsBlock: {
      position: 'sticky',
      top: 'calc(100% - 6.5rem)',
      height: '6.5rem',
      paddingBottom: '1rem',
      zIndex: 6,
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      background: `${widgetBg}CC`,
      boxShadow: `0 -15px 15px ${widgetBg}CC`,
    } as React.CSSProperties,
    buttonGroup: {
      display: 'flex',
      flexDirection: `${size === 'mobile' ? 'column' : 'row-reverse'}`,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    } as React.CSSProperties,
    cartButton: {
      wrapper: {
        margin: '0 1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: `${size === 'desktop' ? 'center' : ''}`,
      } as React.CSSProperties,
      button: {
        position: 'relative',
        border: '1px solid rgba(0,0,0,.03)',
        boxShadow: '6px 6px 8px -4px rgb(0 0 0 / 25%)',
      } as React.CSSProperties,
      indicator: {
        position: 'absolute',
        top: 0,
        left: '-1em',
        fontSize: 11,
        zIndex: 1,
      } as React.CSSProperties,
    },
    addButton: {
      regular: {
        button: {
          alignSelf: 'center',
        } as React.CSSProperties,
      },
      circular: {
        wrapper: {
          margin: '.5rem 1rem 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        } as React.CSSProperties,
        button: {
          position: 'relative',
          border: '1px solid rgba(0,0,0,.03)',
          boxShadow: '6px 6px 8px -4px rgb(0 0 0 / 25%)',
        } as React.CSSProperties,
        label: {
          fontSize: '1.1em',
          padding: '0 1em 0 0',
          textShadow: `0px 0px 3px ${widgetBg}`,
        } as React.CSSProperties,
      },
    },
    backButton: {
      wrapper: {
        margin: '0 1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      } as React.CSSProperties,
      button: {
        position: 'relative',
        border: '1px solid rgba(0,0,0,.03)',
        boxShadow: 'rgb(0 0 0 / 25%) -6px 6px 8px -4px',
      } as React.CSSProperties,
    },
    contentWrapper: {
      marginTop: '-6rem',
      marginBottom: `${size === 'mobile' ? (addButton ? '9.5rem' : '6.5rem') : '6rem'}`,
    },
  } as const);

export default WithCartActionButtons;
