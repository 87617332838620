import { computed } from 'mobx';
import * as React from 'react';
import { AvatarDropdownField } from '../../components/dashboard/employees/avatarDropdownField';
import {
  Checkbox,
  Consume,
  Field,
  Fields,
  Input,
  LocaleContext,
  MobxComponent,
  Platforms,
  Rules,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { AdminStore } from './store';

export class UserFormContent extends MobxComponent<{ title?: string; uploadFormElementId: string }> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @computed private get emailOrPasswordContent() {
    const { t } = this._locale;
    const emailContent = (
      <div className="ui message" style={{ marginBottom: '1em' }}>
        <h4>{t('components.admin.userFormContent.messageOne')}</h4>
        <p>{t('components.admin.userFormContent.messageTwo')}</p>
      </div>
    );

    const passwordContent = (
      <Fields width={16} style={{ paddingTop: '1.4em' }}>
        <Field label={t('password')} style={{ paddingBottom: '1em' }} width={8}>
          <Input
            type="password"
            name="Lösenord1"
            icon="privacy"
            rules={[Rules.MinimumLength(6, t('components.admin.userFormContent.passwordInputOne'))]}
            className="required"
          />
        </Field>
        <Field label="Upprepa lösenord" style={{ paddingBottom: '1em' }} width={8}>
          <Input
            type="password"
            name="Lösenord2"
            icon="privacy"
            rules={[
              Rules.TheSameAs(
                t('components.admin.userFormContent.passwordInputTwo'),
                t('components.admin.userFormContent.passwordInputThree'),
              ),
            ]}
            className="required"
          />
        </Field>
      </Fields>
    );

    // If users manage their own stripe activation a password can not be activated here
    if (Platforms.features.accountsCanBeActivatedFromDashboard) {
      return <div>{emailContent}</div>;
    } else {
      return (
        <div>
          <Checkbox
            checked={!AdminStore.sendPasswordResetEmail}
            onCheckedOrUncheked={() => (AdminStore.sendPasswordResetEmail = !AdminStore.sendPasswordResetEmail)}
          >
            {t('components.admin.userFormContent.checkbox')}
          </Checkbox>
          {AdminStore.sendPasswordResetEmail ? emailContent : passwordContent}
        </div>
      );
    }
  }

  render() {
    const { t } = this._locale;

    return (
      <div>
        {this.props.title ? <h3>{this.props.title}</h3> : undefined}
        <Field label={t('First name')}>
          <Input
            name="Förnamn"
            value={AdminStore.userDocument.firstname}
            onChange={(value) => (AdminStore.userDocument.firstname = value)}
            rules={[Rules.NotEmpty()]}
          />
        </Field>
        <Field label={t('Last name')}>
          <Input
            name="Efternamn"
            value={AdminStore.userDocument.lastname}
            onChange={(value) => (AdminStore.userDocument.lastname = value)}
            rules={[Rules.NotEmpty()]}
          />
        </Field>
        <Field label={t('Email')}>
          <Input
            name="E-post"
            placeholder="mail@domain.com"
            value={AdminStore.userDocument.username}
            onChange={(value) => (AdminStore.userDocument.username = value)}
            icon="at"
            rules={[Rules.NotEmpty(), Rules.IsEmailOrEmpty()]}
          />
        </Field>
        <AvatarDropdownField
          modifyOnChange
          user={AdminStore.userDocument}
          uploadFormElementId={this.props.uploadFormElementId}
        />
        <br />
        {this.emailOrPasswordContent}
        <div style={{ height: '1em' }} />
      </div>
    );
  }
}
