import * as React from 'react';
import { Consume, DeviceProvider, ErrorResource, IDevice, LocaleContext } from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';

interface Props {
  compact?: boolean;
}
interface State {
  hasError: boolean;
  nrOfErrors: number;
}

export class ErrorBoundary extends React.Component<Props, State> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      nrOfErrors: 0,
    };
  }

  private onReloadContentClicked = () => this.setState({ hasError: false });
  private onReloadAppClicked = () => location.reload();

  private get actionSegment() {
    const { t } = this._locale;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {this.state.nrOfErrors < 3 && (
          <button
            className={'ui button ' + (this.props.compact ? 'small' : '')}
            onClick={this.onReloadContentClicked}
            style={{ marginBottom: '0.2em' }}
          >
            {t('reload-content')}
          </button>
        )}
        <button
          className={'ui inverted red button ' + (this.props.compact ? 'small' : '')}
          onClick={this.onReloadAppClicked}
          style={{ marginBottom: '0.2em' }}
        >
          {t('reload-app')}
        </button>
      </div>
    );
  }

  public async componentDidCatch(error: Error, info) {
    // Display fallback UI
    this.setState({ hasError: true, nrOfErrors: this.state.nrOfErrors + 1 });

    // Save error to database
    const errorResource = new ErrorResource();
    const errorDocument = errorResource.createDocument({});

    errorDocument.name = error.name;
    errorDocument.message = error.message;
    errorDocument.stack = error.stack;
    errorDocument.device = this.device.size;
    errorDocument.os = this.device.os;
    errorDocument.browser = this.device.browser;
    errorDocument.isWrappedApp = this.device.isWrappedApp;
    errorDocument.isMobileBrowser = this.device.isMobileBrowser;
    errorDocument.userAgent = this.device.userAgent;

    await errorResource.updateDocument(errorDocument);
  }

  public render() {
    const { t } = this._locale;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="ui raised segment" style={this.props.compact ? {} : { padding: '2em' }}>
            <div className={'ui icon header ' + (this.props.compact ? 'small' : 'large')}>
              <i className="bug icon"></i>
              <div className="content">{t('could-not-load-content')}</div>
            </div>
            {this.actionSegment}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
