import Moment from 'moment-timezone';
import * as React from 'react';
import { useContext } from 'react';
import { ActivityOccuranceDocument } from '../_dependencies';
import { useLocale } from '../_locales';
import { DatalayerContext } from '../datalayer.context';
import { PopulatedOccurence } from './activitySelectionView';

interface props {
  occurance: PopulatedOccurence;
  onSelection: (event: ActivityOccuranceDocument) => void;
  isSelected?: boolean;
  isTmeTicket?: boolean;
}

/**Creates and event item, displaying start time and availability. */

const ActivityItem: React.FC<props> = ({ occurance, onSelection, isSelected, isTmeTicket }) => {
  const { t } = useLocale();
  const { sendItemSelectedEvent } = useContext(DatalayerContext);

  const onClick = () => {
    if (occurance.isFullyBooked) return;
    sendItemSelectedEvent(occurance);
    onSelection(occurance as unknown as ActivityOccuranceDocument);
  };

  const renderAvailableVisitors = () => {
    return occurance.isFullyBooked
      ? `${t('Fully booked')}`
      : `${occurance.availableVisitors} ${t('Available (plural)').toLowerCase()}`;
  };

  return (
    <div
      className={`ui card button ${occurance.isFullyBooked ? 'disabled' : isSelected ? 'primary' : ''}`}
      style={{
        padding: '5px',
        height: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 0,
        fontSize: '0.9em',
        paddingLeft: '10px',
      }}
      onClick={onClick}
    >
      <div
        style={
          isTmeTicket ? { textAlign: 'center', whiteSpace: 'nowrap' } : { textAlign: 'left', whiteSpace: 'nowrap' }
        }
      >
        <p style={{ fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif" }}>
          {isTmeTicket ? (
            <>{t('Choose')}</>
          ) : (
            <>
              <strong>{t('Start:')} </strong>
              {Moment(occurance.start).tz('Europe/Stockholm').tz('Europe/Stockholm').format('HH:mm')}
            </>
          )}
        </p>
      </div>
      {!isTmeTicket && (
        <div style={{ textAlign: 'left', whiteSpace: 'nowrap', marginLeft: '-4px' }}>
          <p>
            <i className="male icon" />
            {renderAvailableVisitors()}
          </p>
        </div>
      )}
    </div>
  );
};

export default ActivityItem;
