import { capitalize } from 'lodash';
import Moment from 'moment-timezone';
import React from 'react';
import {
  Consume,
  DeviceProvider,
  IDevice,
  KosmicComponent,
  MarketplaceLibrary as Lib,
  LocaleContext,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { MarketplaceCartContext } from '../marketplace.cart.context';
import { BookingItemTable } from '../productTables/bookingItemTable';
import { GiftcardItemTable } from '../productTables/giftcardItemTable';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';

// Temp type for weekday translations
type Weekdays = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

interface Props {
  items: Lib.CartItem[];
}

/**
 * This component is a template that wraps every step
 * after the selection step in the booking widget
 */
export class OrderStepTemplate extends KosmicComponent<Props> {
  @Consume(WidgetDataProvider.Context)
  private widget: IWidgetData;

  @Consume(MarketplaceCartContext)
  private marketplaceCart: MarketplaceCartContext;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  /**
   * Temporary fix for translating weekdays in moment, should be replaced with a better solution in the future
   * @param date The date to translate from
   * @param format The general moment format (string)
   * @returns eg "Söndag 15 aug 2021" where "Söndag" gets translated
   */
  private getTranslatableMomentDate(date: Date, format: string) {
    const { t } = this._locale;
    const weekday = capitalize(Moment(date).locale('en').format(`dddd`)) as Weekdays;
    return capitalize(Moment(date).format(`[${t(weekday)}] ${format}`));
  }

  private renderIcon(icon: string) {
    return (
      <td className="collapsing" style={{ padding: '0 1rem', backgroundColor: 'rgba(34,36,38,.1)' }}>
        <strong>
          <i style={{ margin: '0' }} className={`${icon} icon`} />
        </strong>
      </td>
    );
  }

  render() {
    const marginStyle = this.device.size == 'mobile' ? { marginLeft: '-14px', marginRight: '-14px' } : {};
    const { t } = this._locale;
    const { cartItemIsCartBooking, cartItemIsCartGiftcard } = this.marketplaceCart;
    const { items } = this.props;
    const hasTmeTickets = items
      .filter((i) => cartItemIsCartBooking(i))
      ?.some((b: Lib.CartBooking) => b.occurance.originatingActivity.isTmeTicket);

    if (!items.length) return null;

    return (
      <div className="ui stackable grid">
        <div className="eight wide column">
          <div style={marginStyle}>
            {this.widget.bookingRequest ? <h2>{t('Booking request')}</h2> : <h2>{t('Order overview')}</h2>}
            {items.map((item, index) => {
              switch (true) {
                case cartItemIsCartBooking(item):
                  return (
                    <BookingItemTable
                      key={`booking.${item.number}-${index}`}
                      renderIcon={this.renderIcon}
                      translatableDate={this.getTranslatableMomentDate.bind(this)}
                      booking={item as Lib.CartBooking}
                    />
                  );
                case cartItemIsCartGiftcard(item):
                  return (
                    <GiftcardItemTable
                      showToggle={this.widget.selectedStepId === 'customerInfo'}
                      key={`giftcard.${item.number}-${index}`}
                      renderIcon={this.renderIcon}
                      translatableDate={this.getTranslatableMomentDate.bind(this)}
                      giftcard={item as Lib.CartGiftcard}
                    />
                  );
                default:
                  return <></>;
              }
            })}
          </div>
        </div>
        <div className="eight wide column">
          <div style={marginStyle}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
