import { PaymentResource } from '../resources';
import { loadStripe } from '@stripe/stripe-js';

function makeStripeInstanceFactory() {
  let publishableKey: string | null = null;

  return async function getStripeInstance() {
    // Check if publishableKey has been loaded
    if (publishableKey == null) {
      const response = await new PaymentResource().getStripePublishableKey();
      publishableKey = response.key;
    }

    // Load a stripe instance
    const stripe = await loadStripe(publishableKey);
    if (stripe == null) {
      throw new Error('Could not load stripe instance');
    }

    return stripe;
  };
}

export const getStripeInstance = makeStripeInstanceFactory();
