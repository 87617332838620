import React, { createContext, useContext } from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  BookingDocument,
  BookingPriceCategoryDocument,
  BookingResource,
  GiftCardDocument,
  GiftCardTypeDocument,
  MarketplaceLibrary as Lib,
  MarketplaceOrder,
  OrganizationDocument,
  OrganizationResource,
  calculateTotalPrice,
  tt,
} from './_dependencies';
import { PopulatedOccurence } from './selectionSteps/activitySelectionView';

export interface DatalayerContext {
  sendItemSelectedEvent: (occurance: PopulatedOccurence) => void;
  sendAddToCartEvent: (
    selectedActivity: ActivityOccuranceDocument,
    priceCategories: BookingPriceCategoryDocument[],
  ) => void;
  sendAddGiftcardToCartEvent: (card: GiftCardTypeDocument) => void;
  sendBeginCheckoutEvent: (cart: Lib.CartItem[], organization: OrganizationDocument) => void;
  sendRemoveFromCartEvent: (item: Lib.CartItem, organization: OrganizationDocument) => void;
  sendAddShippingInfoEvent: (cart: Lib.CartItem[], organization: OrganizationDocument) => void;
  sendPurchaseEvent: (order: MarketplaceOrder, cartItems: (BookingDocument | GiftCardDocument)[]) => void;
}

interface AnalyticsItem {
  item_id: string;
  item_name: string;
}

export const DatalayerContext = createContext<DatalayerContext>({} as any);
export const useDatalayer = () => useContext(DatalayerContext);
declare global {
  interface Window {
    dataLayer: any;
  }
}
export const DatalayerProvider = ({ children }) => {
  const cartItemIsCartBooking = (item: Lib.CartItem): item is Lib.CartBooking => {
    return BookingResource.isDocument(item);
  };

  const organizationHasDatalayer = async (organizationId) => {
    const organization = await new OrganizationResource().getUnrestrictedOrg(organizationId);
    if (organization.flags.features.googleAnalyticsDatalayer) {
      return true;
    } else {
      return false;
    }
  };

  const getOrganization = async (item: BookingDocument | GiftCardDocument) => {
    if (isBookingDocument(item)) {
      const occurance = await new ActivityOccuranceResource().get(item.occurance);
      return occurance.organization;
    } else {
      return item.limitations.organizations[0];
    }
  };

  const isBookingDocument = (obj: any | BookingDocument): obj is BookingDocument => {
    return BookingResource.isDocument(obj);
  };

  const getEcommerceObject = async (cartItems: (BookingDocument | GiftCardDocument)[]) => {
    const items: AnalyticsItem[] = [];
    let value = 0;
    for (const item of cartItems) {
      if (isBookingDocument(item)) {
        const occurance = await new ActivityOccuranceResource().get(item.occurance);
        const product = {
          item_id: occurance.id,
          item_name: tt(occurance.title, 'sv'),
        };
        items.push(product);
        value += item.totalPrice;
      } else {
        const product = {
          item_id: item.id,
          item_name: tt(item.title, 'sv'),
        };
        items.push(product);
        if (item.value) {
          value += item.value;
        }
      }
    }
    const ecommerce = {
      currency: 'SEK',
      value: value,
      payment_type: 'Credit card',
      items: items,
    };
    return ecommerce;
  };

  const getAnalyticsData = (cart: Lib.CartItem[]) => {
    let totalSum = 0;
    let cartItems: AnalyticsItem[] = [];

    cart.forEach((item) => {
      if (cartItemIsCartBooking(item)) {
        totalSum += item.totalPrice;
        cartItems.push({ item_id: item._id.toString(), item_name: tt(item.occurance.title, 'sv') });
      } else {
        if (item.value != undefined) {
          totalSum += item.value;
        }
        cartItems.push({ item_id: item._id.toString(), item_name: tt(item.title, 'sv') });
      }
    });
    return { totalSum: totalSum, items: cartItems };
  };

  /**
   * Sends a "select_item" event to dataLayer (Google analytics) if organization has a datalayer-flag
   */

  const sendItemSelectedEvent = async (occurance: PopulatedOccurence) => {
    if (await organizationHasDatalayer(occurance.organization)) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: [
            {
              item_id: occurance.id,
              item_name: tt(occurance.title, 'sv'),
            },
          ],
        },
      });
    }
  };

  /**
   * Sends an "add_to_cart" event to dataLayer (Google analytics) if organization has a datalayer-flag
   */

  const sendAddToCartEvent = async (
    selectedActivity: ActivityOccuranceDocument,
    priceCategories: BookingPriceCategoryDocument[],
  ) => {
    if (await organizationHasDatalayer(selectedActivity.organization)) {
      const totalPrice = calculateTotalPrice(priceCategories);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'SEK',
          value: totalPrice,
          items: [
            {
              item_id: selectedActivity.id,
              item_name: tt(selectedActivity.title, 'sv'),
            },
          ],
        },
      });
    }
  };

  /**
   * Sends an "add_to_cart" event for giftcard to dataLayer (Google analytics) if organization has a datalayer-flag
   */

  const sendAddGiftcardToCartEvent = async (card: GiftCardTypeDocument) => {
    if (await organizationHasDatalayer(card.limitations.organizations[0])) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'SEK',
          value: card.value,
          items: [
            {
              item_id: card.id,
              item_name: tt(card.title, 'sv'),
            },
          ],
        },
      });
    }
  };

  /**
   * Sends a "begin_checkout" event to dataLayer (Google analytics) if organization has a datalayer-flag
   */

  const sendBeginCheckoutEvent = (cart: Lib.CartItem[], organization: OrganizationDocument) => {
    const { googleAnalyticsDatalayer } = organization.flags.features;
    if (googleAnalyticsDatalayer) {
      const data = getAnalyticsData(cart);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          currency: 'SEK',
          value: data.totalSum,
          items: data.items,
        },
      });
    }
  };

  /**
   * Sends a "remove_from_cart" event to dataLayer (Google analytics) if organization has a datalayer-flag
   */

  const sendRemoveFromCartEvent = (item: Lib.CartItem, organization: OrganizationDocument) => {
    const { googleAnalyticsDatalayer } = organization.flags.features;
    if (googleAnalyticsDatalayer) {
      let price;
      let items;
      if (cartItemIsCartBooking(item)) {
        price = calculateTotalPrice(item.priceCategories);
        items = {
          item_id: item.occurance.id,
          item_name: tt(item.occurance.title, 'sv'),
        };
      } else {
        if (item.value != undefined) {
          price = item.value;
        }
        items = {
          item_id: item.id,
          item_name: tt(item.title, 'sv'),
        };
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'SEK',
          value: price,
          items: [items],
        },
      });
    }
  };

  /**
   * Sends an "add_shipping_info" event to dataLayer (Google analytics) if organization has a datalayer-flag
   */

  const sendAddShippingInfoEvent = (cart: Lib.CartItem[], organization: OrganizationDocument) => {
    const { googleAnalyticsDatalayer } = organization.flags.features;
    if (googleAnalyticsDatalayer) {
      const data = getAnalyticsData(cart);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: {
          currency: 'SEK',
          value: data.totalSum,
          items: data.items,
        },
      });
    }
  };

  /**
   * Sends a "purchase" event to dataLayer (Google analytics) if organization has a datalayer-flag
   */

  const sendPurchaseEvent = async (order: MarketplaceOrder, cartItems: (BookingDocument | GiftCardDocument)[]) => {
    const organizationId = await getOrganization(cartItems[0]);
    if (await organizationHasDatalayer(String(organizationId))) {
      const ecommerce = await getEcommerceObject(cartItems);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: ecommerce,
      });
    }
  };

  return (
    <DatalayerContext.Provider
      value={{
        sendItemSelectedEvent,
        sendAddToCartEvent,
        sendAddGiftcardToCartEvent,
        sendBeginCheckoutEvent,
        sendRemoveFromCartEvent,
        sendAddShippingInfoEvent,
        sendPurchaseEvent,
      }}
    >
      {children}
    </DatalayerContext.Provider>
  );
};
