import { Schema, Types } from 'mongoose';

export interface RequestedStaffDocument {
  user: Types.ObjectId;
  status: 'waiting' | 'denied';
}

export const RequestedStaffSchema = new Schema(
  {
    user: { type: Schema.Types.ObjectId, required: true },
    status: { type: String, required: true },
  },
  { _id: false },
);
