import { Fetcher } from './utilities';

type CreatePaymentRefundRequest = {
  paymentId: string;
  reason: RefundReason;
  amount?: number;
  metadata?: { [key: string]: any };
};

type CreatePaymentRefundResponse = {
  amountRefunded: number;
  refund: string;
};

export type RefundReason = 'Cancel' | 'Cancel partially' | 'Refund' | 'Refund partially' | 'Fradulent';

type CreateProductRefundRequest = {
  externalId: string;
  reason: RefundReason;
  amount?: number;
  metadata?: { [key: string]: any };
};

export type CreateProductRefundResponse = {
  amountRefunded: number;
  refunds: string[];
};

type CreatePaymentRefundFn = (params: CreatePaymentRefundRequest) => Promise<CreatePaymentRefundResponse>;
type CreateProductRefundFn = (params: CreateProductRefundRequest) => Promise<CreateProductRefundResponse>;

export type RefundApi = {
  refundPayment: CreatePaymentRefundFn;
  refundProduct: CreateProductRefundFn;
};

export const Refund = (fetcher: Fetcher): RefundApi => {
  const refundPayment: CreatePaymentRefundFn = async (params) => {
    const res = await fetcher('/function/create-refund-for-payment', {
      method: 'POST',
      body: JSON.stringify(params),
    });
    const data: CreatePaymentRefundResponse = await res.json();
    switch (res.status) {
      case 201:
        return data;
      default:
        throw data;
    }
  };

  const refundProduct: CreateProductRefundFn = async (params) => {
    const res = await fetcher('/function/create-refund-for-product', {
      method: 'POST',
      body: JSON.stringify(params),
    });
    const data: CreateProductRefundResponse = await res.json();
    switch (res.status) {
      case 201:
        return data;
      default:
        throw data;
    }
  };

  return {
    refundPayment,
    refundProduct,
  };
};
