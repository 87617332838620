import * as React from 'react';
import { Marker } from 'react-google-maps';
import { KosmicComponent, LocationDocument } from '../../_dependencies';

interface Props {
  userPosition?: LocationDocument;
}

export class UserMarker extends KosmicComponent<Props> {
  props: Props;

  private get userMarker() {
    if (!this.props.userPosition) return null;

    return (
      <Marker
        zIndex={1}
        position={this.props.userPosition}
        icon={{ url: '/static/commons/svg/user-marker.svg', scale: 1, anchor: new google.maps.Point(24, 24) }}
      />
    );
  }

  public render() {
    return this.userMarker;
  }
}
