import * as React from 'react';

import { Alert } from './alert';
import { CookieConsentBanner } from '../legal/cookieConsentBanner';

export class KosmicTemplate extends React.Component<any, any> {
  render() {
    return (
      <div className="pushable">
        <Alert />
        <div id="scroll-container" className="pusher">
          <CookieConsentBanner />
          {this.props.children}
        </div>
      </div>
    );
  }
}
