import React from 'react';
import { Button, InventoryEntityDocument } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { drawHTMLForPrinting } from './drawHtmlForPrinting';

type Props = {
  inventoryEntities?: InventoryEntityDocument[];
  mobile: boolean;
  tablet: boolean;
  disabled?: boolean;
};

const QrCodeButton: React.FC<Props> = ({ inventoryEntities, mobile, tablet, disabled }) => {
  const { t } = useLocale();
  const print = () => {
    if (inventoryEntities) {
      let a = window.open('', '_blank');
      drawHTMLForPrinting(a, inventoryEntities, mobile, tablet);
      a?.document.close();
      a?.print();
      a?.close();
    }
  };
  return (
    <Button {...{ disabled }} className="left floated" onClick={() => print()}>
      {mobile ? (
        <>
          <i className="qrcode icon" />
          QR
        </>
      ) : (
        <>
          <i className="qrcode icon" />
          {t('QR-codes')}
        </>
      )}
    </Button>
  );
};

export default QrCodeButton;
