import { Types } from 'mongoose';
import { Resource } from '../_dependencies';
import {
  AppendEventResponse,
  CardActionHandler,
  CardDetails,
  CartGiftcard,
  CreateProductRefundResponse,
  CreateProductResponse,
  ManualDetails,
  OrderInformation,
  ProductInformationResponse,
  RefundReason,
} from '../libraries/marketplace';
import {
  Discount,
  DiscountDryrunResponse,
  DiscountUsageResponse,
  Giftcard,
  GiftcardDryrunResponse,
  GiftcardStatusResponse,
} from '../libraries/marketplace/payments.types';
import { TranslatableText } from '../schemas';
import SchemaNames from '../schemas/names';
import { BookingDocument } from './booking.resource';
import { GiftCardDocument } from './giftCard.resource';

// lines 28 to 80 move from marketplace.context
/**
 * A simplification of a marketplace order
 */
export type MarketplaceOrder = {
  /** A reference to the order in the marketplace API */
  id?: string;
  /** The products in the marketplace order */
  products: OverviewProduct[];
  /** An array of active giftcards used for the order */
  giftcards: Giftcard[];
  discount?: Discount;
};

export type MarketplaceOrderWithId = {
  /** A reference to the order in the marketplace API */
  orderId?: string;
  /** The products in the marketplace order */
  products: OverviewProduct[];
  /** An array of active giftcards used for the order */
  giftcards: Giftcard[];
  discount?: Discount;
};

/**
 * A giftcard used for a specific product
 * Is only used to display product values after applied giftcards, eg. how much would an applied giftcard impact a product cost?
 */
export type AppliedGiftcard = {
  /** The external reference used for giftcards in marketplace (giftcard.number) */
  number: string;
  /** The value applied to the product */
  appliedValue: number;
};
/**
 * A discount used for a specific product
 * Is only used to display product values after applied discount,
 */
export type AppliedDiscount = {
  /** The organizationId associated with the discount code (discount.organization) */
  organization: Types.ObjectId;
  /** The external reference used for discounts in marketplace (discount.code) */
  code: string;
  /** The value applied to the product */
  appliedValue: number;
};

/**
 * A product in the marketplace order.
 * Can be a booking or a giftcard.
 */
export type OverviewProduct = {
  /** The marketplace reference to the product object */
  id: string;
  /** The AH reference to the product object */
  externalId: string;
  /** The value of the product */
  value: number;
  /** If any giftcards have been applied to the product, they are saved here */
  appliedGiftcards: AppliedGiftcard[];
  /** If any discount have been applied to the product, it's saved here */
  appliedDiscount?: AppliedDiscount;
};

export type ProductPaymentInfo = {
  amountPaidManually: number;
  amountPaidWithGiftCard: number;
  amountPaidWithStripe: number;
  application_fee: number;
  productValue: number | undefined;
  legacy: boolean;
};

type PlaceOrderWithCardDetails = {
  items: (BookingDocument | GiftCardDocument)[];
  amount: number;
  orderId: string | undefined;
  productId: string | undefined;
  giftCards: Array<string>;
  discount?: Discount | undefined;
  cardDetails: CardDetails | undefined;
};

type PlaceOrderWithManualDetails = {
  items: (BookingDocument | GiftCardDocument)[];
  amount: number;
  orderId: string | undefined;
  productId: string | undefined;
  giftCards: Array<string>;
  manualDetails: ManualDetails | undefined;
};

type PlaceOrderWithPaymentDetails = PlaceOrderWithCardDetails | PlaceOrderWithManualDetails;

export type DiscountResponse = {
  orderId: string;
  discount: {
    percentage: number;
  };
};

export class MarketplaceResource extends Resource {
  constructor() {
    super();
    this.setName(SchemaNames.Marketplace);
  }
  /**
   * Creates and returns a new order id.
   */
  public async createOrder(): Promise<string> {
    const { orderId } = (await this.sendRequest('/orders/create', 'post', {})) as any;
    return orderId;
  }

  /**
   *  Removes an order in the marketplace API
   */
  public async removeOrder(orderId: string): Promise<unknown> {
    return this.sendRequest('/orders/remove', 'delete', { orderId });
  }

  /**
   * Creates and appends a booking to an existing order.
   */
  public async createBookingProduct(
    orderId: string,
    booking: BookingDocument,
    organizationId: string,
    offerId?: string,
    termsOfUse?: TranslatableText | string,
  ): Promise<CreateProductResponse> {
    return await this.sendRequest('/products/create', 'post', {
      orderId,
      product: {
        id: booking.id,
        totalPrice: booking.totalPrice,
      },
      organizationId,
      offerId: offerId,
      termsOfUse: termsOfUse,
    });
  }

  public async createGiftcardProduct(
    orderId: string,
    giftcard: CartGiftcard,
    organizationId: string,
    giftCardId: string,
  ): Promise<CreateProductResponse> {
    return await this.sendRequest('/products/create', 'post', {
      orderId,
      product: {
        id: giftcard.id,
        totalPrice: giftcard.value,
      },
      organizationId,
      offerId: giftCardId,
    });
  }

  public async createManualPayment(externalId: string, amount: number) {
    return await this.sendRequest('/payments/manual', 'post', {
      type: 'manual',
      externalId,
      amount,
    });
  }
  /**
   * Gets payment status for product
   */
  public async getPaidAmountForProduct(externalId: string): Promise<ProductPaymentInfo> {
    return await this.sendRequest(`/products/status`, 'get', { externalId });
  }

  /**
   * Gets product document for product
   */
  public async getInformationForProduct(externalId: string): Promise<ProductInformationResponse> {
    return await this.sendRequest(`/products/information`, 'get', { externalId });
  }

  public async orderStatus(orderId: string): Promise<OrderInformation> {
    return await this.sendRequest('/orders/status', 'get', { orderId });
  }

  /**
   * Updates a product through an event
   */
  public async updateProduct(
    externalId: string,
    value: number,
    description: string,
    metadata?: { [key: string]: any },
  ): Promise<AppendEventResponse> {
    return await this.sendRequest('/products/update', 'post', {
      externalId,
      value,
      description,
      metadata,
    });
  }

  /**
   * Updates a product metadata from widget
   */
  public async updateProductUserMetadata(
    externalId: string,
    description: string,
    metadata?: { [key: string]: any },
  ): Promise<AppendEventResponse> {
    return await this.sendRequest('/products/updateProductUserMetadata', 'post', {
      externalId,
      description,
      metadata,
    });
  }

  /**
   * Creates a refund for a card payment
   */
  public async createProductRefund(
    externalId: string,
    amount: number,
    reason: RefundReason,
    metadata?: { [key: string]: any },
  ): Promise<CreateProductRefundResponse> {
    return await this.sendRequest('/refunds/create', 'post', {
      type: 'product',
      externalId,
      amount,
      reason,
      metadata,
    });
  }

  /**
   * Creates a dryrun for giftcard payment
   */
  public async giftcardProductAllocation(orderId: string, giftcards: Giftcard[]): Promise<GiftcardDryrunResponse> {
    return await this.sendRequest('/payments/dryrun', 'post', {
      type: 'giftcard',
      orderId,
      giftcards,
    });
  }

  /**
   * Creates a "dryrun" for discount code
   */
  public async discountProductAllocation(orderId: string, discount: Discount): Promise<DiscountDryrunResponse> {
    return await this.sendRequest('/payments/dryrun', 'post', {
      type: 'discount',
      orderId,
      discount,
    });
  }

  /**
   * Retrieves the current payment status for a giftcard
   */
  public async getGiftcardPaymentStatus(externalId: string): Promise<GiftcardStatusResponse> {
    return await this.sendRequest('/payments/status', 'get', { type: 'giftcard', externalId });
  }

  //Not used so commenting out.
  /**
   * Rollback payments
   */
  /*  public async rollbackPayments(orderId: string, paymentIds: string[]): Promise<RollbackPaymentsResponse> {
    return await this.sendRequest('/payments/remove', 'delete', { type: 'rollback', orderId, paymentIds });
  } */

  /**
   * Pays for a product either with giftcards or a credit card
   */
  public async placeOrderWithPayment(
    /** Payment and product details */
    details: PlaceOrderWithPaymentDetails,
    /** Any client-side action handler for handling security measurments by the card provider (i.e. 3d secure) */
    cardActionHandler?: CardActionHandler,
  ): Promise<void> {
    const firstRequest = await this.sendRequest<any>('/product/pay', 'post', details);

    if (firstRequest.requiresAction && firstRequest.intentSecret) {
      if (!cardActionHandler) {
        throw new Error('Card Action Handler is missing but required for finishing payment');
      }
      const stripeResult = await cardActionHandler(firstRequest.intentSecret);
      if (stripeResult.error) {
        throw { code: 0, raw: stripeResult.error };
      } else {
        await this.sendRequest<any>('/product/pay', 'post', {
          ...details,
          paymentIntentIdToConfirm: stripeResult.paymentIntent!.id,
        });
      }
    }
  }

  public async getOrganizationsDiscountUsage(
    organizationId: string,
    discountCode: string,
  ): Promise<DiscountUsageResponse> {
    return await this.sendRequest('/organizations/discountUsage', 'post', { id: organizationId, discountCode });
  }
}
