import React, { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../../contexts/notification.context';
import {
  Consume,
  LocaleContext,
  MobxComponent,
  NavLink,
  NotificationDocument,
  OrganizationDocument,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { ErrorBoundary } from '../errorBoundary';
import { Maybe } from '../maybe';
import CurrentUserSidebarItem from './currenctUserSidebarItem';
import { FeatureLockIcon } from './featureLockIcon';
import { PropertiesContext, PropertiesContextValue } from './properties/propertiesProvider';
import { PropertyStore } from './properties/propertyStore';

interface Props {
  isSidebarOpen: boolean;
  closeSidebar: () => void;
}

interface MenuItems {
  icon: string;
  name: string;
  link: string;
}

export class DashboardSidebar extends MobxComponent<Props, {}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(PropertiesContext)
  private properties: PropertiesContextValue;

  handlePressedPropertyItem = (e: MouseEvent) => {
    if (
      this.globals.session.currentLocation.pathname.indexOf('/dashboard/properties') !== -1 &&
      this.globals.session.currentLocation.pathname.indexOf('/dashboard/properties/places') === -1 &&
      (this.globals.session.currentLocation.pathname.indexOf('properties/new') !== -1 || // Adding a new property
        PropertyStore.Instance.hasProperty()) // Viewing a propery
    ) {
      // Do not navigate instead, toggle the property sidebar
      e.preventDefault();
      this.properties.setIsSidebarOpen(!this.properties.isSidebarOpen);
    }
  };

  renderLinks() {
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { features } = organization.flags;

    const compact = this.globals.device.height < 760;
    const isMobile = this.globals.device.isMobileSize;
    const { closeSidebar: handleSlide } = this.props;
    const { t } = this._locale;

    const handlePressedPropertyItemMobile = (e) => {
      this.handlePressedPropertyItem(e);
      handleSlide();
    };

    const handlePopUpMenu = (menuItems: MenuItems[]) => {
      return (
        <MenuPopUp compact={compact} icon="handshake" menuItems={menuItems}>
          {t('Vouchers')}
        </MenuPopUp>
      );
    };

    return (
      <>
        <SidebarItem
          isMobile={isMobile}
          onClick={handleSlide}
          link="/dashboard/schedule"
          icon="calendar
	                outline"
          disabled={!(features.scheduling && features.bookings)}
          compact={compact}
        >
          {t('Schedules')}
        </SidebarItem>
        <Maybe if={'organization.manager'}>
          <SidebarItem
            isMobile={isMobile}
            onClick={handleSlide}
            link="/dashboard/offers"
            icon="star outline"
            disabled={!features.offers}
            compact={compact}
          >
            {t('Offers')}
          </SidebarItem>
        </Maybe>
        <Maybe if={'property.manager'}>
          <SidebarItem
            isMobile={isMobile}
            onClick={handlePressedPropertyItemMobile}
            link="/dashboard/properties"
            icon="map pin"
            disabled={!features.properties}
            compact={compact}
          >
            {t('Properties')}
          </SidebarItem>
        </Maybe>
        <Maybe if={'property.manager'}>
          <SidebarItem
            isMobile={isMobile}
            onClick={handleSlide}
            link="/dashboard/bookings"
            icon="ticket"
            disabled={!features.bookings}
            compact={compact}
          >
            {t('Bookings')}
          </SidebarItem>
        </Maybe>
        <Maybe if={'property.manager'}>
          <SidebarItem
            isMobile={isMobile}
            onClick={handleSlide}
            link="/dashboard/notifications"
            icon="bell"
            compact={compact}
            notificationIndicator
          >
            {t('Notifications')}
          </SidebarItem>
        </Maybe>
        <Maybe if={'organization.manager'}>
          <SidebarItem
            isMobile={isMobile}
            onClick={handleSlide}
            link="/dashboard/employees"
            icon="users"
            compact={compact}
          >
            {t('Personnel')}
          </SidebarItem>
        </Maybe>
        <Maybe if={'property.manager'}>
          {isMobile ? (
            <>
              <SidebarItem
                isMobile
                onClick={handleSlide}
                link="/dashboard/creditcodes"
                icon="recycle"
                compact={compact}
              >
                {t('Credit codes')}
              </SidebarItem>
              <SidebarItem isMobile onClick={handleSlide} link="/dashboard/discounts" icon="cut" compact={compact}>
                {t('Discounts')}
              </SidebarItem>
              <SidebarItem
                isMobile
                onClick={handleSlide}
                link="/dashboard/giftcards"
                icon="gift"
                disabled={!features.giftcards}
                compact={compact}
              >
                {t('Giftcards')}
              </SidebarItem>
            </>
          ) : (
            handlePopUpMenu([
              {
                link: 'creditcodes',
                name: t('Credit codes'),
                icon: 'recycle',
              },
              {
                link: 'discounts',
                name: t('Discounts'),
                icon: 'cut',
              },
              {
                link: 'giftcards',
                name: t('Giftcards'),
                icon: 'gift',
              },
            ])
          )}
        </Maybe>
        <Maybe if={'property.manager'}>
          <SidebarItem
            isMobile={isMobile}
            onClick={handleSlide}
            link="/dashboard/widget"
            icon="cube"
            disabled={!features.widgets}
            compact={compact}
          >
            {t('Widgets')}
          </SidebarItem>
        </Maybe>
      </>
    );
  }

  render() {
    const imageSize = this.globals.device.height < 740 ? 'tiny' : 'small';
    const isMobile = this.globals.device.isMobileSize;
    const { isSidebarOpen, closeSidebar: handleSlide } = this.props;

    if (!isMobile) {
      return (
        <div
          className="ui inverted vertical left visible thin overlay labeled icon attached menu"
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '10em',
          }}
        >
          <ErrorBoundary compact>
            <div style={{ flex: '1 1 auto', height: '100%', display: 'flex', flexDirection: 'column' }}>
              <NavLink exact to="/dashboard" className="item header" style={{ padding: '1rem 1.6rem' }}>
                <img className={'ui centered ' + imageSize + ' image'} src="/static/platform/img/logo_inverted.png" />
              </NavLink>
              {this.renderLinks()}
              <CurrentUserSidebarItem />
            </div>
          </ErrorBoundary>
        </div>
      );
    }

    return (
      <div className="ui vertical inverted labeled inline icon thin menu" style={sidebarStyle(isSidebarOpen)}>
        <ErrorBoundary compact>
          <i className="ui big inverted close icon" style={iconStyle} onClick={handleSlide}></i>
          {this.renderLinks()}
          <div onClick={handleSlide}>
            <CurrentUserSidebarItem />
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

/**
 * Small helper component for rendering items in the sidebar
 */
function SidebarItem(props: {
  /** Target link */
  link: string;
  /** Optional onClick event to trigger */
  onClick?: MouseEventHandler;
  /** Optional Semantic UI Icon to use */
  icon?: string;
  /** Compact or larger rendering */
  compact?: boolean;
  /** React children */
  children?: React.ReactNode;
  /** Optional style for mobile */
  style?: React.CSSProperties;
  disabled?: boolean;
  isMobile?: boolean;
  notificationIndicator?: boolean;
}) {
  const { notifications } = useContext(NotificationContext);
  const [unreadNotifications, setUnreadNotifications] = useState<NotificationDocument[]>([]);

  useEffect(() => {
    const unread = notifications.filter((n) => n.read === false);
    setUnreadNotifications(unread);
  }, [notifications]);

  if (props.disabled) {
    return (
      <span style={props.isMobile ? linkStyleMobile : linkStyle} className="item disabled">
        {props.icon && (!props.compact || props.isMobile) && <i className={props.icon + ' icon'} />}
        {props.compact ? <b>{props.children}</b> : props.children}
        <FeatureLockIcon
          id="sidebar-feature-icon"
          feature={false}
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        />
      </span>
    );
  }
  return (
    <NavLink
      to={props.link}
      style={props.isMobile ? linkStyleMobile : linkStyle}
      activeClassName="active"
      className="item"
      onClick={props.onClick}
    >
      {props.icon && (!props.compact || props.isMobile) && <i className={props.icon + ' icon'} />}
      {props.notificationIndicator && unreadNotifications.length > 0 && (
        <div
          style={{ position: 'absolute', top: '25%', right: !props.compact ? 40 : 5, fontSize: 10 }}
          className="circular ui red label"
        >
          {unreadNotifications.length}
        </div>
      )}
      {props.compact ? <b>{props.children}</b> : props.children}
    </NavLink>
  );
}

/**
 * Small component to create the popup menu with links.
 */
function MenuPopUp(props: {
  compact?: boolean;
  icon?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  menuItems: MenuItems[];
}) {
  const [menuActive, setActive] = useState<boolean>(false);

  const links = () => {
    return (
      <div className="ui" style={popUpStyle}>
        {props.menuItems.map((menuItem, index) => {
          return (
            <NavLink
              key={`menu-item-${index}`}
              to={`/dashboard/${menuItem.link}`}
              activeClassName="active"
              className="item"
              style={{ width: '100%' }}
            >
              <div style={{ display: 'flex' }}>
                {props.compact ? (
                  <h5>{menuItem.name}</h5>
                ) : (
                  <h4>
                    <i className={menuItem.icon + ' icon'} />
                    {menuItem.name}
                  </h4>
                )}
              </div>
            </NavLink>
          );
        })}
      </div>
    );
  };

  return (
    <div
      style={linkStyle}
      className={menuActive ? 'item active' : 'item'}
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={() => setActive(true)}
    >
      {props.icon && !props.compact && <i className={props.icon + ' icon'} />}
      {props.compact ? <b>{props.children}</b> : props.children}
      {menuActive ? links() : null}
    </div>
  );
}

const sidebarStyle = (isSidebarOpen: boolean): React.CSSProperties => ({
  width: isSidebarOpen ? '100%' : 0,
  height: '100%',
  position: 'fixed',
  zIndex: 1100,
  top: 0,
  left: 0,
  overflowX: 'hidden',
  transition: 'all 0.5s',
  margin: 0,
  paddingTop: '2.5em',
  borderRadius: '0',
});

const iconStyle: React.CSSProperties = {
  position: 'absolute',
  top: 2,
  right: 0,
  zIndex: 600,
};

const linkStyleMobile: React.CSSProperties = {
  padding: '1em 2em',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
};

const linkStyle: React.CSSProperties = {
  padding: 0,
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
};

const popUpStyle: React.CSSProperties = {
  backgroundColor: 'rgba(0,0,0,.87)',
  position: 'fixed',
  height: `auto`,
  left: '9.95em',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
};
