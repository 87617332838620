import * as React from 'react';
import { Checkbox, GlobalsContext, Input, OrganizationDocument, OrganizationResource } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';

export const SettingsSegment = () => {
  const { t } = useLocale();
  const { session } = React.useContext(GlobalsContext);
  const {
    notificationEmail: currentNotificationEmail,
    replyToEmail: currentReplyToEmail,
    flags: currentFlags,
  } = session.currentOrganization as OrganizationDocument;

  const [notificationEmail, setNotificationEmail] = React.useState(currentNotificationEmail);
  const [replyToEmail, setReplyToEmail] = React.useState(currentReplyToEmail);
  const [flags, setFlags] = React.useState({ ...currentFlags });

  const [updatingNotificationEmail, setUpdatingNotificationEmail] = React.useState(false);
  const [updatingReplyToEmail, setUpdatingReplyToEmail] = React.useState(false);

  React.useEffect(() => void (session.currentOrganization.notificationEmail = notificationEmail), [notificationEmail]);
  React.useEffect(() => void (session.currentOrganization.replyToEmail = replyToEmail), [replyToEmail]);
  React.useEffect(() => void (session.currentOrganization.flags = flags), [flags]);

  useDelayedUpdate(setUpdatingReplyToEmail, [replyToEmail]);
  useDelayedUpdate(setUpdatingNotificationEmail, [notificationEmail]);
  useDelayedUpdate((v: boolean) => undefined, [flags]);

  return (
    <div className="ui green segment">
      <h3 className="ui header">
        {t('Email notification')}
        <div className="sub header">{t('components.dashboard.home.segments.settingsSegment.subHeaderOne')}</div>
      </h3>
      <Input
        fluid
        loading={updatingNotificationEmail}
        name=""
        icon="at"
        value={notificationEmail}
        onChange={(value) => setNotificationEmail(value)}
      />

      <h3 className="ui header">
        {t('components.dashboard.home.segments.settingsSegment.headerTwo')}
        <div className="sub header">{t('components.dashboard.home.segments.settingsSegment.subHeaderTwo')}</div>
      </h3>
      <Input
        fluid
        loading={updatingReplyToEmail}
        name=""
        icon="at"
        value={replyToEmail}
        onChange={(value) => setReplyToEmail(value)}
      />

      <h3 className="ui header">
        {t('Settings')}
        <div className="sub header">{t('Here you can view other account settings')}</div>
      </h3>

      <div style={{ display: 'grid', gridColumn: '1fr', gap: '.5rem' }}>
        <Checkbox
          checked={flags.hideWeatherInWidget}
          onCheckedOrUncheked={(v) => setFlags({ ...flags, hideWeatherInWidget: v })}
          label={t('Hide the weather segment of the widget')}
        />

        <Checkbox
          checked={flags.orgNameAsMailSender}
          onCheckedOrUncheked={(v) => setFlags({ ...flags, orgNameAsMailSender: v })}
          label={t('Use your own organizations name as sender in emails')}
        />
      </div>
    </div>
  );
};

const useDelayedUpdate = (updatingSetter: React.Dispatch<React.SetStateAction<Boolean>>, deps: any[]) => {
  const { session } = React.useContext(GlobalsContext);
  React.useEffect(() => {
    let canceled = false;
    const id = setTimeout(() => {
      updatingSetter(true);
      new OrganizationResource().updateDocument(session.currentOrganization).then(() => {
        if (canceled) {
          return;
        }
        session.forceUpdate();
        updatingSetter(false);
      });
    }, 1000);

    return () => {
      canceled = true;
      clearTimeout(id);
    };
  }, deps);
};
