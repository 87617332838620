import React, { CSSProperties } from 'react';
import { MobxComponent } from './abstractComponent';
import ShortId from 'shortid';

interface Props {
  toggle?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  largeLabel?: boolean;
  label?: React.ReactNode;
  onCheckedOrUncheked: (checked: boolean) => void;
  checkedChildBoxes?: CheckedChildBoxes;
  disabled?: boolean;
}

export class Checkbox extends MobxComponent<Props> {
  componentDidMount() {
    const { checked, defaultChecked } = this.props;
    const checkboxElement = this.domElement.find('.ui.checkbox');

    checkboxElement.checkbox({
      onChecked: () => this.props.onCheckedOrUncheked(true),
      onUnchecked: () => this.props.onCheckedOrUncheked(false),
    });
    if (checked || defaultChecked) {
      checkboxElement.checkbox('set checked');
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { checkedChildBoxes, checked } = this.props;
    const checkboxElement = this.domElement.find('.ui.checkbox');

    if (prevProps.checkedChildBoxes != checkedChildBoxes) {
      if (checkedChildBoxes == 'all') {
        checkboxElement.checkbox('set checked');
      } else if (checkedChildBoxes == 'none') {
        checkboxElement.checkbox('set unchecked');
      } else {
        checkboxElement.checkbox('set indeterminate');
      }
    }

    if (prevProps.checked != checked) {
      if (checked) {
        checkboxElement.checkbox('set checked');
      } else {
        checkboxElement.checkbox('set unchecked');
      }
    }
  }

  render() {
    const { toggle, disabled, largeLabel, label, children, style } = this.props;

    const labelStyle = {
      fontSize: largeLabel ? '1.1rem' : undefined,
      paddingLeft: toggle ? '50px' : undefined,
    };

    // TODO: Add name to props instead of this...
    const id = label?.toString() || ShortId.generate();

    const checkboxElement = (
      <div className={`ui ${toggle ? 'toggle' : ''} checkbox`} style={!toggle ? style : undefined}>
        <input id={id} disabled={disabled} type="checkbox" />
        <label htmlFor={id} style={labelStyle}>
          {!toggle && (label || children)}
        </label>
      </div>
    );

    if (!toggle) return <div>{checkboxElement}</div>;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...style,
        }}
      >
        <label>{label || children}</label>
        {checkboxElement}
      </div>
    );
  }
}
export type CheckedChildBoxes = 'all' | 'some' | 'none';
