import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeDocument,
  ActivityTypeResource,
  AppliedDiscount,
  AppliedGiftcard,
  BookingDocument,
  BookingResource,
  DiscountDocument,
  DiscountError,
  GiftCardDocument,
  GiftCardResource,
  MarketplaceLibrary as Lib,
  MarketplaceOrder,
  MarketplaceResource,
  OverviewProduct,
} from './_dependencies';
import { DatalayerContext } from './datalayer.context';

export type ItemInOrder = BookingDocument | GiftCardDocument | Lib.CartItem;

export type MarketplaceContext = {
  order: MarketplaceOrder;
  leftToPay: number;
  leftToPayAfterDiscount: number;
  discountTotal: number;
  createMarketplaceOrder: <T extends ItemInOrder>(products: T[]) => Promise<T[]>;
  removeMarketplaceOrder: () => Promise<void>;
  setMarketplaceOrder: (order: MarketplaceOrder) => void;
  clearMarketplaceOrder: () => void;
  createManualOrderWithBooking: (booking: BookingDocument, org: string) => Promise<string>;
  cartItemIsCartBooking: (item: Lib.CartItem) => item is Lib.CartBooking;
  cartItemIsCartGiftcard: (item: Lib.CartItem) => item is Lib.CartGiftcard;
  getGiftcardAppliedValue: (giftcardNumber: string) => number;
  applyNewGiftcard: (giftcardNumber: string) => Promise<Error | void>;
  applyNewDiscount: (discount: DiscountDocument) => Promise<Error | void>;
  removeAppliedGiftcards: (giftcardNumbers: string[]) => void;
  removeAppliedDiscount: () => void;
  getGiftcardPaymentStatus: (giftcardNumber: string) => Promise<Error | Lib.GiftcardStatusResponse>;
  placeOrderWithoutPayment: (cart: Lib.CartItem[]) => Promise<void>;
  placeOrderWithManualPayment: (orderItems: Lib.CartItem[], productId?: string) => Promise<any>;
  placeOrderWithCardPayment: (
    orderItems: Lib.CartItem[],
    cardDetails: Lib.CardDetails | undefined,
    cardActionHandler: Lib.CardActionHandler,
    productId?: string,
  ) => Promise<any>;
  addProductManually: (product: Lib.ProductDocument) => void;
  orderInformation: Lib.OrderInformation | null;
  /**
   * A list of payments that are relevant to the current marketplace context order.
   * I.E. only includes payments that can match a completed payments partition to a product
   * in the current order.
   */
  completedRelevantPayments: Lib.PaymentDocument[];
  alreadyPaid: number;
};

export const MarketplaceContext = React.createContext<MarketplaceContext>({} as any);

/**
 * The initial state for the order state
 */
const INITIAL_ORDER = {
  id: undefined,
  products: [],
  giftcards: [],
  discount: undefined,
};

export interface MarketplaceProps {}

export const MarketplaceProvider: FC<MarketplaceProps> = ({ children }) => {
  /**
   * Context resources
   */
  const marketplaceRes = new MarketplaceResource();
  const giftCardResource = new GiftCardResource();

  /**
   * Context states
   */
  const [order, setOrder] = useState<MarketplaceOrder>(INITIAL_ORDER);
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [orderInformation, setOrderInformation] = useState<Lib.OrderInformation | null>(null);
  const { sendPurchaseEvent } = useContext(DatalayerContext);

  const refetchOrderInformation = () => {
    setRefetchToggle(!refetchToggle);
  };

  /**
   * Total cost of all order products without reductions
   */
  const totalOrderCost = order.products.reduce((sum: number, prod: OverviewProduct) => {
    return sum + prod.value;
  }, 0);

  const completedRelevantPayments = React.useMemo(() => {
    const paymentIsApplicable = (payment: Lib.PaymentDocument): boolean => {
      if (payment.status !== 'completed') return false;
      return Boolean(
        payment.partitions.find((partition) =>
          order.products.map((product) => product.id).includes(partition.productId),
        ),
      );
    };

    const completedPayments = orderInformation?.payments.filter(paymentIsApplicable) || [];
    return completedPayments;
  }, [order, orderInformation]);

  const alreadyPaid = React.useMemo(() => {
    return completedRelevantPayments.reduce((acc, payment) => {
      const partition = payment.partitions.find((partition) =>
        order.products.map((product) => product.id).includes(partition.productId),
      );

      if (partition) {
        return acc + partition.value;
      }
      return acc;
    }, 0);
  }, [completedRelevantPayments]);

  /**
   * Array with applied giftcards
   */
  const usedCards = order.products.reduce((acc, prod: OverviewProduct) => {
    const giftcards = prod.appliedGiftcards.filter((card) => card.number);
    return giftcards.length !== 0 ? [...acc, ...giftcards] : acc;
  }, [] as AppliedGiftcard[]);

  /**
   * Total value of applied giftcards
   */
  const reductionFromGiftcards = usedCards.reduce((sum: number, card: AppliedGiftcard) => {
    return sum + card.appliedValue;
  }, 0);

  /**
   * Simple function for getting the total applied value for an applied giftcard
   * @param giftcard Giftcard whose value we want to be informed about
   * @returns The applied value of the giftcard or 0
   */
  const getGiftcardAppliedValue = (giftcardNumber: string): number => {
    return order.products.reduce((sum, prod) => {
      const targetGiftcard = prod.appliedGiftcards.find((gift) => gift.number === giftcardNumber);
      if (targetGiftcard) return sum + targetGiftcard.appliedValue;
      return sum;
    }, 0);
  };

  /**
   * Only used for graphically communicating total after previous payments and dryrunning payments for giftcards
   */
  const leftToPay = React.useMemo(
    () => totalOrderCost - alreadyPaid - reductionFromGiftcards,
    [totalOrderCost, alreadyPaid, reductionFromGiftcards],
  );

  const discountTotal = order.products.reduce((sum, item) => sum + (item.appliedDiscount?.appliedValue ?? 0), 0);

  const leftToPayAfterDiscount = React.useMemo(
    () => leftToPay + discountTotal, // discountTotal is a negative value
    [leftToPay, discountTotal],
  );

  useEffect(() => {
    // Ignore this effect if there is no order
    if (order.id == undefined) {
      return;
    }
    // fetches order information from marketplace API and sets it in state
    (async () => {
      try {
        const info = await new MarketplaceResource().orderStatus(order.id!);
        setOrderInformation(info);
      } catch (err) {
        // NOTE: How do we handle this error ? Or do we even need to
        console.warn(err);
      }
    })();
  }, [order, setOrderInformation, refetchToggle]);

  /**
   * Function for applying a discount to the marketplace order
   * @param discount Discount to be applied
   */
  async function applyNewDiscount(discount: DiscountDocument) {
    // Cancel if no order has been started

    if (!order.id) {
      throw new DiscountError('no_order_created', 'No order has been created yet');
    }

    if (order.discount) {
      throw new DiscountError('discount_already_applied', 'Discount already applied');
    }

    // Limits the products to those that are in the offer Limitations if product comes from a partially paid order.
    if (discount.offerLimitations?.length) {
      order.products.find((prod) => {
        for (const product of orderInformation!.products) {
          if (prod.externalId === product.externalId) {
            if (
              !discount.offerLimitations?.includes(String(product.offerId)) &&
              orderInformation?.paymentStatus === 'Partially paid'
            ) {
              throw new DiscountError('discount_limited', "Discount can't be used on this product");
            }
            product.events.forEach((event) => {
              if (event.description === 'Discount') {
                throw new DiscountError('discount_already_applied', 'Discount already applied');
              }
            });
          }
        }
      });
    }

    const newDiscount: Lib.Discount = {
      code: discount.code,
      organizationId: discount.organization,
      percentage: discount.discountPercentage,
      offerLimitations: discount.offerLimitations,
    };

    // Remove all giftcards that has been applied to products and calculate values from clean products (if there are giftcards)
    const giftcardHistory = [...order.giftcards];
    // Remove giftcard from history state aswell as from overview
    const updateOrderOverview: OverviewProduct[] = order.products.map((prod: OverviewProduct) => {
      return { ...prod, appliedGiftcards: [] as AppliedGiftcard[] };
    });

    // calculate discount partitions
    const result = await marketplaceRes.discountProductAllocation(order.id, newDiscount);
    if (!Lib.isDiscountDryrunResponse(result)) {
      throw new DiscountError('could_not_apply_discount', 'Discount dryrun failed');
    }

    // sort discount partitions by product
    const sortedByProduct: AppliedDiscount[] = [];
    result.allocations.forEach((discount) => {
      if (discount.type !== 'discount')
        throw new DiscountError(null, 'Dry run did not return allocations of type "discount" when expected to.');
      discount.discountPartitions.forEach((product) => {
        sortedByProduct[product.productId] = { number: discount.externalId, appliedValue: product.value };
      });
    });

    // apply discount partitions to products
    updateOrderOverview.forEach((product) => {
      product.appliedDiscount = sortedByProduct[product.id];
    });

    const { products, giftcards } = await calculateGiftcards(
      updateOrderOverview,
      giftcardHistory,
      undefined,
      newDiscount,
    );

    // Set products, recalculated giftcards and discounts to history
    setOrder((prevOrder) => ({ ...prevOrder, products, giftcards, discount: newDiscount }));
  }

  async function calculateGiftcards(
    products: OverviewProduct[],
    giftcards: Lib.Giftcard[],
    giftcard?: GiftCardDocument,
    discount?: Lib.Discount,
  ) {
    if (!order.id) throw new Error('No orderId when calculating giftcards');

    if (!giftcards.length && !giftcard) return { products, giftcards };

    const giftcardHistory = [...giftcards];
    if (giftcard) {
      giftcardHistory.push({
        externalId: giftcard.number,
        applicableTo: giftcard.limitations.organizations,
        amount: giftcard.value || 0,
      });
    }

    // If discount, discount is added to giftcard history to reflect correct product total on checkout
    if (discount) {
      const { code, organizationId } = discount;
      const appliedValue = products.reduce((sum, item) => sum + (item.appliedDiscount?.appliedValue ?? 0), 0);
      giftcardHistory.unshift({ externalId: code, amount: (appliedValue / 100) * -1, applicableTo: [organizationId] });
    }

    // calculate giftcard partitions from all used giftcards
    const result = await marketplaceRes.giftcardProductAllocation(order.id, giftcardHistory);
    if (!Lib.isGiftcardDryrunResponse(result)) {
      throw new Error('Could not apply giftcard');
    }

    // sort giftcard partitions by product
    const sortedByProduct: AppliedGiftcard[] = [];
    result.allocations.forEach((giftcard) => {
      giftcard.giftcardPartitions.forEach((product) => {
        const allocations = sortedByProduct[product.productId] || [];
        if (giftcard.externalId === discount?.code) return;
        allocations.push({ number: giftcard.externalId, appliedValue: product.value });
        sortedByProduct[product.productId] = allocations;
      });
    });

    // apply giftcard partitions to products
    const clonedOrderProducts = [...products];
    clonedOrderProducts.forEach((product) => {
      if (sortedByProduct[product.id] != null) {
        product.appliedGiftcards = sortedByProduct[product.id];
      } else {
        product.appliedGiftcards = [];
      }
    });

    const filteredGiftcards = giftcardHistory.filter((item) => item.externalId !== discount?.code);

    // remove discount from giftcardhistory if it exists
    return { products: clonedOrderProducts, giftcards: filteredGiftcards };
  }

  /**
   * Function for applying a giftcard to the marketplace order
   * NOTE: This does not equal an actual payment, just presents what will happen graphically
   * @param giftcardNumber number for Giftcard to be applied
   */
  async function applyNewGiftcard(giftcardNumber: string) {
    // Cancel if no order has been started
    if (!order.id) {
      throw new Error('No order has been created yet');
    }

    const giftcard = await giftCardResource.getGiftcardByNumber(giftcardNumber);
    if (!giftcard) {
      throw new Error('Could not find Giftcards');
    }

    // Cancel if giftcard already is in usage
    const isInUsage = order.giftcards.find((card) => card.externalId === giftcard.number);
    if (isInUsage) {
      throw new Error('Giftcard already applied');
    }

    // Cancel if giftcard is marked as inactive
    if (giftcard.usesLeft < 1) {
      throw new Error('Giftcard has no uses left');
    }
    const { products, giftcards } = await calculateGiftcards(
      [...order.products],
      [...order.giftcards],
      giftcard,
      order.discount,
    );
    // Set products and giftcards to history
    setOrder((prevOrder) => ({ ...prevOrder, products: products, giftcards: giftcards }));
  }

  /**
   * Function for removing an applied discount from the order state
   */
  function removeAppliedDiscount() {
    const updateOrderOverview = order.products.map((prod: OverviewProduct) => ({
      ...prod,
      appliedDiscount: undefined,
    }));
    setOrder((prevOrder) => ({ ...prevOrder, products: updateOrderOverview, discount: undefined }));
  }

  /**
   * Function for removing an applied giftcard from the order state
   * @param giftcardNumber Number of giftcard to be removed
   */
  function removeAppliedGiftcards(giftcardNumbers: string[]) {
    // Remove giftcard from history state aswell as from overview
    const updateOrderOverview = order.products.map((prod: OverviewProduct) => {
      const updatedCards = prod.appliedGiftcards.filter((card) => !giftcardNumbers.includes(card.number));
      return { ...prod, appliedGiftcards: updatedCards };
    });
    const cloneActiveGiftcards = [...order.giftcards];
    const updatedGiftcards = cloneActiveGiftcards.filter((gift) => !giftcardNumbers.includes(gift.externalId));

    // Updates the state
    setOrder((prevOrder) => ({ ...prevOrder, products: updateOrderOverview, giftcards: updatedGiftcards }));
  }

  /**
   * Function for finding out payments made with a giftcard in marketplace API
   * @param giftcardNumber the giftcard number
   * @returns summary of giftcard usage in marketplace API
   */
  async function getGiftcardPaymentStatus(giftcardNumber: string) {
    const result = await marketplaceRes.getGiftcardPaymentStatus(giftcardNumber);
    if (!Lib.isGiftcardStatusResponse(result)) {
      return new Error('Could not fetch giftcard payment status');
    }
    return result;
  }

  /**
   * Creates an order with a single booking, passed in as an argument
   * @param booking The booking that needs to be created in the marketplace API
   * @param organizationId Organization number for org that manages activity
   * @returns reference to the created product in marketplace
   */
  async function createManualOrderWithBooking(booking: BookingDocument, organizationId: string) {
    const occurance = await new ActivityOccuranceResource().find({ _id: booking.occurance });
    const offer = await new ActivityTypeResource().find({ _id: occurance[0].originatingActivity });
    const id = await marketplaceRes.createOrder();
    const res = await marketplaceRes.createBookingProduct(id, booking, organizationId, offer[0].id);
    if (!Lib.isCreateProductResponse(res)) {
      throw new Error('TODO: HANDLE THIS');
    }
    return res.productId;
  }

  /**
   * Creates an order in the marketplace
   */
  async function createMarketplaceOrder<T extends ItemInOrder>(cart: T[]): Promise<T[]> {
    // Create order in marketplace
    const id = await marketplaceRes.createOrder();
    if (!id) throw new Error('Order could not be created');

    // Create products and attach them to the order.
    // Also updates the cart with marketplace product references
    const products: OverviewProduct[] = [];
    let updatedCart: T[] = [];
    if (cart.length) {
      updatedCart = await Promise.all(
        cart.map(async (booking) => {
          if (BookingResource.isDocument(booking)) {
            const occurance = booking.occurance as unknown as ActivityOccuranceDocument;
            const offer = occurance.originatingActivity as unknown as ActivityTypeDocument;

            const res = await marketplaceRes.createBookingProduct(
              id,
              booking,
              booking.organization.toString(),
              offer.id,
            );

            const product = createNewOverviewProduct(res, booking.id);
            products.push(product);
            return { ...booking, marketplaceRef: res.productId };
          }
          if (GiftCardResource.isDocument(booking)) {
            const res = await marketplaceRes.createGiftcardProduct(
              id,
              booking,
              booking.limitations.organizations[0].toString(),
              booking.id,
            );
            const product = createNewOverviewProduct(res, booking.id);
            products.push(product);
            return { ...booking, marketplaceRef: res.productId };
          }
          return booking;
        }),
      );
    }
    const newOrder = { ...order, id, products };
    setOrder(newOrder);
    return updatedCart;
  }

  /**
   * Used to populate the state if preexisting order is available
   * @param order
   */
  function setMarketplaceOrder(order: MarketplaceOrder) {
    setOrder(order);
  }

  function clearMarketplaceOrder() {
    setOrder(INITIAL_ORDER);
  }

  /**
   * Clears the order from marketplace API and context, allows for new orders to be assembled
   */
  async function removeMarketplaceOrder() {
    const id = order.id;
    if (!id) throw new Error('[Marketplace Context] No order id to remove from exists in context');
    await marketplaceRes.removeOrder(id);
    setOrder(INITIAL_ORDER);
  }

  /**
   * Builds simple overview products
   * @param productRes The marketplace response when creating a product in marketplace API
   * @param externalId The reference to the document in AH
   * @returns A simple overview product
   */
  function createNewOverviewProduct(productRes: Lib.CreateProductResponse, externalId: string): OverviewProduct {
    return {
      id: productRes.productId,
      externalId,
      value: productRes.value,
      appliedGiftcards: [],
    };
  }

  function addProductManually(product: Lib.ProductDocument) {
    if (order.id === undefined) {
      throw new Error('TODO: HANDLE THIS');
    }
    setOrder((prevOrder) => ({
      ...prevOrder,
      products: [
        ...prevOrder.products,
        {
          ...product,
          id: product._id,
          appliedGiftcards: [] as AppliedGiftcard[],
        },
      ],
    }));
  }

  /**
   * This typeguards the item passed in as either an instance of a booking or a giftcard
   * @param item A cart item in the marketplace context cart
   * @returns boolean for if the item passed in is a booking
   */
  function cartItemIsCartBooking(item: Lib.CartItem): item is Lib.CartBooking {
    return BookingResource.isDocument(item);
  }

  /**
   * This typeguards the item passed in as either an instance of a booking or a giftcard
   * @param item A cart item in the marketplace context cart
   * @returns boolean for if the item passed in is a giftcard
   */
  function cartItemIsCartGiftcard(item: Lib.CartItem): item is Lib.CartGiftcard {
    return !cartItemIsCartBooking(item);
  }

  /**
   * Turns cart items into product documents
   * @param cart The cart in marketplace cart context
   * @returns The marketplace cart as an array of bookingdocs and giftcarddocs
   */
  function cartItemsToDocuments(cart: Required<Lib.CartItem>[]): (BookingDocument | GiftCardDocument)[] {
    const updatedItems = cart.map((item) => {
      if (cartItemIsCartGiftcard(item)) {
        return item;
      }
      return {
        ...item,
        occurance: item.occurance._id,
      };
    });
    return updatedItems;
  }

  /**
   * Places order without payment
   */
  async function placeOrderWithoutPayment(cart: Required<Lib.CartItem>[]) {
    const updatedCartItems = cartItemsToDocuments(cart);
    try {
      await Promise.all(
        updatedCartItems.map(async (item) => {
          if (BookingResource.isDocument(item)) {
            if (item.requested && !item.crossSellingProperty) {
              await new BookingResource().skipPaymentStepUpdate(item);
              return;
            }
            await new BookingResource().updateDocument(item);
          } else {
            await new GiftCardResource().updateDocument(item);
          }
        }),
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function placeOrderWithManualPayment(cart: Required<Lib.CartItem>[], productId = undefined) {
    // Get all entered giftcards
    const giftCards = order.giftcards.map((card) => card.externalId);
    // Turn cart items into regular document types
    const updatedCartItems = cartItemsToDocuments(cart);
    // Get manual cost after applied giftcard values
    const manualDetails = order.products.map((item) => {
      const appliedGiftcardValue = item.appliedGiftcards.reduce((sum, giftcard) => sum + giftcard.appliedValue, 0);
      const manualCost = item.value - appliedGiftcardValue;
      return {
        productId: item.externalId,
        amount: manualCost,
      };
    });
    try {
      await marketplaceRes.placeOrderWithPayment({
        orderId: order.id,
        items: updatedCartItems,
        amount: totalOrderCost - alreadyPaid,
        giftCards,
        manualDetails,
        productId,
      });
      refetchOrderInformation();
    } catch (err) {
      console.log(err);
    }
  }

  const placeOrderWithCardPayment = useCallback(
    async (
      cart: Required<Lib.CartItem>[],
      cardDetails: Lib.CardDetails | undefined,
      cardActionHandler: Lib.CardActionHandler,
      productId = undefined,
    ) => {
      // Get all entered giftcards
      const giftCards = order.giftcards.map((card) => card.externalId);

      // Turn cart items into regular document types
      const updatedCartItems = cartItemsToDocuments(cart);

      // Pay for the product using any active giftcards and entered
      // credit card details
      await marketplaceRes.placeOrderWithPayment(
        {
          orderId: order.id,
          items: updatedCartItems,
          amount: totalOrderCost - alreadyPaid,
          discount: order.discount,
          giftCards,
          cardDetails,
          productId,
        },
        cardActionHandler,
      );
      refetchOrderInformation();
      sendPurchaseEvent(order, updatedCartItems);
    },
    [order.id, leftToPay, order.giftcards, order.discount],
  );

  const state = {
    order,
    leftToPay,
    leftToPayAfterDiscount,
    discountTotal,
    createMarketplaceOrder,
    removeMarketplaceOrder,
    setMarketplaceOrder,
    clearMarketplaceOrder,
    createManualOrderWithBooking,
    cartItemIsCartBooking,
    cartItemIsCartGiftcard,
    getGiftcardAppliedValue,
    applyNewGiftcard,
    applyNewDiscount,
    removeAppliedDiscount,
    removeAppliedGiftcards,
    getGiftcardPaymentStatus,
    placeOrderWithCardPayment,
    placeOrderWithManualPayment,
    placeOrderWithoutPayment,
    addProductManually,
    orderInformation,
    completedRelevantPayments,
    alreadyPaid,
  };

  return <MarketplaceContext.Provider value={state}>{children}</MarketplaceContext.Provider>;
};
