import Stripe from 'stripe';
import * as MarketplaceLibrary from '../libraries/marketplace';
import SchemaNames from '../schemas/names';
import { MongooseDocument, MongooseResource } from '../_dependencies';

export interface AgreementDocument extends MongooseDocument {
  connectedStripeAccountId: string;
  stripeAccountMail: string;

  // TODO: Remove unused field
  secretStripeKey: string;
  // TODO: Remove unused field
  publishableStripeKey: string;

  // TODO: Remove unused field
  /** Used to enable Subscription's from a connected account */ customerId?: string;
  // TODO: Remove unused field
  /** The subscription plan for new members */ planId?: string;

  /** Fixed service fee on each payment (commonly 3kr) */ fixedCharge?: number;
  /** Percentage service fee on each payment (commonly 4%) */ percentageCharge: number;
  /** Percentage service fee on each non european payment (commonly 6%) */ nonEuropeanPercentageCharge: number;
  /** When total price is below the agrement.limit this fee is used instead */ belowLimitCharge: number;
  /** Used together with the agrement.belowLimitCharge property */ limit: number;
}

export class AgreementResource extends MongooseResource<AgreementDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Agreement);

    this.setSchema({
      connectedStripeAccountId: { type: String, required: true, immutable: true },
      stripeAccountMail: { type: String, required: false, immutable: true },
      percentageCharge: { type: Number, required: true, immutable: true },
      nonEuropeanPercentageCharge: { type: Number, required: true, immutable: true },
      belowLimitCharge: { type: Number, required: true, immutable: true },
      limit: { type: Number, required: true, immutable: true },
      fixedCharge: { type: Number, required: false, immutable: true },
      monthlyMinimumCharge: { type: Number, required: false, immutable: true },
    });
  }

  /**
   * Builds a fee structure that is meant to be used with the marketplace api.
   * @param agreement The agreement document
   * @returns defined fees for an agreement
   */
  public toFees(agreement: AgreementDocument): Partial<MarketplaceLibrary.ProductFees> {
    return {
      fixed: agreement.fixedCharge,
      percent: agreement.percentageCharge / 100,
      percentNonEu: agreement.nonEuropeanPercentageCharge / 100,
    };
  }

  /**
   * Constructs an agreement document that can be saved to an organization
   * @param account An account created in Stripe
   * @returns The agreement document
   */
  public constructAgreementDocument(account: Stripe.Account) {
    return new AgreementResource().createDocument({
      connectedStripeAccountId: account.id,
      fixedCharge: 0,
      percentageCharge: 4,
      nonEuropeanPercentageCharge: 6,
      monthlyMinimumCharge: 795,
      limit: 100,
      belowLimitCharge: 4,
    });
  }
}
