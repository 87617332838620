import Lodash from 'lodash';
import Moment from 'moment-timezone';
import { Types } from 'mongoose';
import React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeDocument,
  AvailableActivityTimeSlot,
  AvailableActivityTimeSlotResource,
  AvailableOfferOnPropertyResource,
  BookingDocument,
  BookingPriceCategoryDocument,
  Consume,
  CustomerDocument,
  DeviceProvider,
  GiftCardDocument,
  GiftCardResource,
  GiftCardTypeDocument,
  GiftCardTypeResource,
  IDevice,
  IStateSetter,
  MarketplaceLibrary as Lib,
  MailResource,
  OrganizationDocument,
  OrganizationResource,
  Platforms,
  PropertyDocument,
  PropertyResource,
  Provider,
} from './_dependencies';

import { RouteComponentProps } from 'react-router';
import { ShowcaseMap } from './showcase';
import { StepId, WidgetStepStatus } from './widgetStep';

/** Props for initialize the widget state */
export interface IWidgetChangableDataProps {
  /** Optional shadowroot used instead of document. Only accessable when widget is loaded with webcomponent */
  root?: ShadowRoot;
  history: RouteComponentProps['history'];
  /** Defines widget background color */
  backgroundColor: string;
  /** The selected initial purchase type of the widget, defaults to "bookings" */
  initialPurchaseType?: IWidgetProvidedData['selectedPurchaseType'];
  /** Passes a pre-selected activity occurence to the widget, i.e. a selected activity from a search view */
  initialActivity?: ActivityOccuranceDocument;
  /** Passes a pre-selected gift card type to the widget, for when the wanted gift card type is already known */
  initialGiftCardType?: GiftCardTypeDocument;

  /** The selected organization for this widget - organization must always be passed */
  organization: OrganizationDocument;

  /** Indicates that the signed in user should not be given special priviliges or be logged as the creator of a purchase - most likely wanted wen accessed from an iframe */
  ignoreSignedInUser?: boolean;
  /** Allows custom google analytics data to be transmittet on widget step updates */
  specialGoogleAnalyticsEnabled?: boolean;

  /** Optional fixed purchase type of the widget, purchase type will be not be changable */
  fixedPurchaseType?: IWidgetProvidedData['selectedPurchaseType'];
  /** Any preselected property for this widget */
  fixedProperty?: PropertyDocument;
  /** Optionally passed to allow the user to first select a fixed activity from a showcase view */
  fixedShowcase?: ShowcaseMap;
  /** Optionally fixes the widget to a specific activity type, for when the wanted activity type is already known - it will not be changable from inside the widget */
  fixedOffer?: ActivityTypeDocument;
}

/** State of the Widget that is changable from consumers (public state) */
interface IWidgetChangableData extends IStateSetter<IWidgetChangableData> {
  /** The currently selected gift card type */
  selectedGiftCardType: GiftCardTypeDocument | undefined;
  /** The currently selected date for the order process */
  selectedDate: Date;
  /** The currently selected activity occurance for the order process */
  selectedActivity: ActivityOccuranceDocument | undefined;
  /** Indicates that the value of the giftcard being purchased should be hidden to the customer */
  hideGiftCardValue: boolean;
  /** The selected property the widget is using, either fetched from the selected occurance or fixed by the widget props */
  selectedProperty: PropertyDocument | undefined;
  /** Either the originating activity type of any selected activity occurance */
  selectedActivityType: ActivityTypeDocument | undefined;
  selectedPriceCategories: BookingPriceCategoryDocument[] | undefined;
  /** Any selected activity type from the showcase */
  selectedPrimaryActivityFromShowcase: ActivityTypeDocument | undefined;
  /** If visitors should be able to be set freely */
  allowBookingVisitorsFreely: boolean;
  // Booking request or buy
  bookingRequest: boolean;
}

/** State of the Widget that is only readable from consumers, and can't be changed using the public setState */
interface IWidgetProvidedData {
  /** Indicates that the widget is not ready for rendering */
  isloading: boolean;
  /** Indicates that the widget is fetching available dates for the calendar month */
  isFetchingData: boolean;
  /** Indicates that the widget has been reset, this prevents initial selections from being read more than once  */
  isReset: boolean;
  /** The currently active step id */
  selectedStepId: StepId;
  /** Go to step with id. Marks previous step as completed  */
  gotoStep: (id: StepId, isLastStep?: boolean) => Promise<void>;
  /** Go to the last active step */
  gotoLastActiveStep: () => Promise<void>;
  /** Resets the widget to its original state, optionally switching the purchase type */
  resetWidget: (purchaseType?: IWidgetProvidedData['selectedPurchaseType']) => void;
  /** Fetches an activity type from a given id */
  fetchActivityType: (activityTypeId: string) => void;
  /** Sends a bookings confirmation email, returns the customers email adress */
  sendBookingConfirmationEmail: (booking: Lib.CartBooking, customer: CustomerDocument) => Promise<string>;
  /** Sends a gift card confirmation email, returns the customers email adress */
  sendGiftCardConfirmationEmail: (giftcard: Lib.CartGiftcard, customer: CustomerDocument) => Promise<string>;
  setSelectedPriceCategories: (cat: BookingPriceCategoryDocument[]) => void;
  /** Returns wheter or not a certain date is disabled */
  isDateDisabled: (date: Date) => boolean;
  /** Refetches fulltbooked and available dates on month change */
  refetchBookableDatesByMonth: (month: string) => void;

  setSelectedActivityOccurance: (activityOccurance?: ActivityOccuranceDocument) => void;
  /** Indicates if the current organization allows booking without payment */
  allowsBookingWithoutPayment: () => boolean;
  /** Determines if the widget can switch between purchasing giftcards and bookings */
  allowsSwitchingPurchaseType: () => boolean;
  /** Clears the widget selected item state on confirm so that route state of the widget can be handled */
  clearSelectedItemOnConfirm: () => void;
  /** Indicates that the widget allows some special priviliges for a signed in user, such as skiping the payment step */
  allowSpecialSignedInPriviliges: boolean;
  /** All available steps and their current status */
  steps: Map<StepId, WidgetStepStatus>;
  /** All stepRoutes */
  stepRoutes: Readonly<{ [K in StepId]: string }>;
  /** The first step route */
  lastActiveStep: () => { id: StepId; route: string };
  /** If the current step is disabled */
  verifyStepNavigation: (id: StepId) => void;
  /** Possible customer info saved between purchases / widget resets */
  savedCustomerInfo: CustomerDocument | undefined;
  /** The purchase widget can either be configured to purchase giftcards or booking activities */
  selectedPurchaseType: 'giftcards' | 'bookings';
  /** The selected organization or the selected organization of the property being used */
  selectedOrganization: OrganizationDocument;
  /** The currently selected booking object created in the order process */
  selectedBooking: BookingDocument | undefined;
  /** The currently selected giftcard object created in the order process */
  selectedGiftCard: GiftCardDocument | undefined;
  /** The currently selected price categories during the order process */
  selectedPriceCategories: BookingPriceCategoryDocument[];
  /** List of stringified dates with activities that can be booked */
  availableDates: string[];
  /** List of stringified dates with activities that are fully booked */
  fullyBookedDates: string[];
  /** List of available activity types on the selected property */
  availableActivityTypes: ActivityTypeDocument[];
  /** List of available types of giftcards on the selected property */
  availableGiftCardTypes: GiftCardTypeDocument[];
  /** List of available activity occurances on the selected date */
  availableActivities: ActivityOccuranceDocument[];
  /** List of available activity time slots on the selected date */
  availableActivityTimeSlots: { [startTime: string]: AvailableActivityTimeSlot[] };
  /** Optional shadowroot used instead of document. Only accessable when widget is loaded with webcomponent */
  root: ShadowRoot | null;
  /** Any existing showcase information */
  activityShowcase: ShowcaseMap | undefined;
  /** Exposes the widget background color */
  backgroundColor: string;
  // Booking request or buy
  bookingRequest: boolean;
}

/** State of widget visible for consumers */
export type IWidgetData = IWidgetProvidedData & IWidgetChangableData;

/* Context! */
export const WidgetDataContext = React.createContext<IWidgetData>({} as any);

export class WidgetDataProvider extends Provider<IWidgetData, IWidgetChangableDataProps> {
  //#region Internal provider logic

  /** Underlying context object */
  public static Context = WidgetDataContext;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  /** Declares the context to use */
  protected use() {
    return WidgetDataProvider.Context;
  }

  /** The determined selection steps based on the current platform features and widget purchase mode */
  protected get selectionSteps(): StepId[] {
    const selectionSteps: StepId[] = [];

    const shouldShowShowCase = this.state && this.state.activityShowcase;
    const shouldShowGiftCards =
      Platforms.features.giftCards &&
      (this.state
        ? this.state.selectedPurchaseType == 'giftcards'
        : this.props.initialPurchaseType == 'giftcards' || this.props.fixedPurchaseType == 'giftcards'); // Only check state if its been initialized

    // IS making a booking request
    // const shouldShowBookingRequestComponent = this.state ? this.state.selectedPurchaseType == 'bookingRequest' : false;

    // IS purchasing a gift card
    if (shouldShowGiftCards) {
      selectionSteps.push('giftcardSelection');
    }

    // if (shouldShowBookingRequestComponent) {
    //   selectionSteps.push('bookingRequestSelection');
    // }

    // IS purchasing an activity
    else {
      // Should show the show case first
      if (shouldShowShowCase) {
        selectionSteps.push('activityShowcase');
      }

      // Type of next/first step
      if (Platforms.features.offersCanBeAssignedToEmployees) {
        // We can skip the activity type selection step
        if (this.state.selectedActivityType) {
          selectionSteps.push('timeSelection');
        } else {
          selectionSteps.push('activityTypeSelection');
          selectionSteps.push('timeSelection');
        }
      } else {
        selectionSteps.push('activitySelection');
      }
    }

    // ALWAYS return giftcard/activity selection steps
    return selectionSteps;
  }

  /** The determined list of step ids available of this widget, must be state independent */
  protected get availableSteps(): StepId[] {
    return [...this.selectionSteps, 'customerInfo', 'payment', 'confirmation'];
  }

  protected get stepRoutes(): Readonly<{ [K in StepId]: string }> {
    return {
      unintialized: '',
      activitySelection: '/aktiviteter',
      activityShowcase: '/monter',
      activityTypeSelection: '/aktivitetstyp',
      timeSelection: '/val-av-tid',
      giftcardSelection: '/presentkort',
      customerInfo: '/kontaktuppgifter',
      payment: '/betalning',
      confirmation: '/bekraeftelse',
    };
  }

  /** Sets the inital state of the widget */
  protected initialState(): Required<IWidgetData> {
    // Determine if the widget has been reset or if this is the first initialization
    const isReset = this.state && this.state.isReset;

    // Determine the correct values of the purchase type, current property and organization
    const selectedPurchaseType =
      (this.state && this.state.selectedPurchaseType) ||
      this.props.fixedPurchaseType ||
      this.props.initialPurchaseType ||
      'bookings';
    const selectedProperty = this.props.fixedProperty || undefined;
    let selectedOrganization = this.props.organization;

    // Make sure that the selected organization matches the selected property
    if (selectedProperty) {
      // Organization typeguard
      const isOrganizationDocument = function (org: any): org is OrganizationDocument {
        return org._id != undefined;
      };

      // Make sure the selected organization is "populated"
      const organization: OrganizationDocument | string = selectedProperty.organization as any;

      if (isOrganizationDocument(organization)) {
        selectedOrganization = organization;
      } else if (selectedOrganization.id != organization) {
        throw new Error(
          '[WidgetDataProvider] The widget was initialized with a mismatch between property and organization',
        );
      }
    }

    // Pass data from props to state
    // and allow some data to be preserved between widget resets by checking the current state
    const selectedActivity = (!isReset && this.props.initialActivity) || undefined;
    const selectedDate = selectedActivity ? Moment(selectedActivity.start).toDate() : new Date();
    const selectedActivityType = this.props.fixedOffer || (this.state && this.state.selectedActivityType);
    const selectedGiftCardType = (!isReset && this.props.initialGiftCardType) || undefined;
    const savedCustomerInfo =
      this.state && this.state.selectedBooking
        ? this.state.selectedBooking.customer
        : this.state && this.state.selectedGiftCard
        ? this.state.selectedGiftCard.customer
        : undefined;

    return {
      // Public methods
      setState: this.updateState.bind(this),
      gotoStep: this.goToStep.bind(this),
      gotoLastActiveStep: this.goToLastActiveStep.bind(this),
      fetchActivityType: this.fetchActivityType.bind(this),
      sendBookingConfirmationEmail: this.sendBookingConfirmationEmail.bind(this),
      sendGiftCardConfirmationEmail: this.sendGiftCardConfirmationEmail.bind(this),
      resetWidget: this.resetWidget.bind(this),
      setSelectedPriceCategories: this.setSelectedPriceCategories.bind(this),
      clearSelectedItemOnConfirm: this.clearSelectedItemOnConfirm.bind(this),
      setSelectedActivityOccurance: this.setSelectedActivityOccurance.bind(this),

      // Status checks
      isReset: isReset,
      isDateDisabled: this.isDateDisabled.bind(this),
      refetchBookableDatesByMonth: this.refetchBookableDatesByMonth.bind(this),
      hideGiftCardValue: false,
      allowsSwitchingPurchaseType: this.allowsSwitchingPurchaseType.bind(this),
      allowsBookingWithoutPayment: this.allowsBookingWithoutPayment.bind(this),
      allowSpecialSignedInPriviliges: !this.props.ignoreSignedInUser,
      allowBookingVisitorsFreely: false,
      bookingRequest: false,

      // Computed information
      // NOTE: the shortang of combining key & value when they are the
      // same is not used here, as if the value (selectedActivity) was undefined the key was not
      // included and the previous value was kept when the provider was reset
      selectedPurchaseType: selectedPurchaseType,
      selectedOrganization: selectedOrganization,
      selectedProperty: selectedProperty,
      savedCustomerInfo: savedCustomerInfo,
      selectedActivity: selectedActivity,
      selectedActivityType: selectedActivityType,
      selectedGiftCardType: selectedGiftCardType,
      selectedDate: selectedDate,

      // Steps and status
      selectedStepId: 'unintialized',
      verifyStepNavigation: this.verifyStepNavigation.bind(this),
      steps: this.calculateStepData(),
      stepRoutes: this.stepRoutes,
      lastActiveStep: this.lastActiveStepIdAndRoute.bind(this),
      isloading: false,
      isFetchingData: true,

      // Order information
      selectedGiftCard: undefined,
      selectedBooking: undefined,
      selectedPriceCategories: [],

      // Activity Showcase specific
      activityShowcase: this.props.fixedShowcase,
      selectedPrimaryActivityFromShowcase: undefined,

      // Calculated information
      availableActivityTypes: [],
      availableDates: [],
      fullyBookedDates: [],
      availableActivities: [],
      availableActivityTimeSlots: {},
      availableGiftCardTypes: [],
      root: this.props.root || null,
      backgroundColor: this.props.backgroundColor,
    };
  }

  /**
   * This overloads the normal setState function with additional functionality,
   * making sure the state is consistent and that needed caluclations are done
   * using minimum perfomance
   */
  protected async updateState(newState: Partial<IWidgetData>, callback?: () => void) {
    await this.setState({ isloading: true });

    // Make sure we always keep the fixed PurchaseType
    if (newState.selectedPurchaseType && this.props.fixedPurchaseType) {
      // Is this supposed to be a comparison??
      newState.selectedPurchaseType == this.props.fixedPurchaseType;
    }

    // Make sure that the selected organization matches the selected property
    if (newState.selectedProperty) {
      // Organization typeguard
      const isOrganizationDocument = function (org: any): org is OrganizationDocument {
        return org.admins != undefined;
      };

      // Make sure the selected organization is "populated"
      let organization: OrganizationDocument | string = newState.selectedProperty.organization as any;

      if (!isOrganizationDocument(organization)) {
        organization = await new OrganizationResource().getUnrestrictedOrg(organization);
      }

      newState.selectedOrganization = organization as any;
    }

    // Update the list selectable activity types
    if (newState.selectedStepId && newState.selectedStepId == 'activityTypeSelection') {
      const availableActivityTypes = await this.calculateAvailableActivityTypes();
      await this.setState({
        availableActivityTypes,
      });
    }

    // Update the list of bookable dates
    if (
      newState.selectedStepId &&
      (newState.selectedStepId == 'timeSelection' || newState.selectedStepId == 'activitySelection')
    ) {
      const { availableDates, fullyBookedDates } = await this.calculateAvailableBookableDates();

      // if we don't find any availableDates on the current month, we check 2 months ahead
      if (!availableDates.length) {
        for (const m of [1, 2]) {
          const { availableDates, fullyBookedDates } = await this.calculateAvailableBookableDates(
            Moment().add(m, 'month').format('MMMM YYYY'),
          );

          if (!availableDates.length) {
            continue;
          } else {
            newState.availableDates = availableDates;
            newState.fullyBookedDates = fullyBookedDates;
            break;
          }
        }
      } else {
        newState.availableDates = availableDates;
        newState.fullyBookedDates = fullyBookedDates;
      }

      newState.isFetchingData = false;

      // Select first bookable date closest to today, or keep the selected date if its available
      if (
        !Lodash.includes(newState.availableDates, Moment(this.state.selectedDate).format('YYMMDD')) &&
        newState.availableDates
      ) {
        newState.selectedDate = Moment(newState.availableDates[0], 'YYMMDD').toDate();
      } else {
        newState.selectedDate = this.state.selectedDate;
      }
    }

    // Update the list selectable gift card types
    //TODO: Maybe make a custom funtion that only selects dates for the disabled version... ????
    if (newState.selectedStepId && newState.selectedStepId == 'giftcardSelection') {
      const availableGiftCardTypes = await this.calculateAvailableGiftCardTypes();
      await this.setState({
        availableGiftCardTypes,
      });
    }

    // Make sure that everything is saved in the database when moving to the confirmation step
    if (newState.selectedStepId && newState.selectedStepId == 'confirmation' && !this.state.bookingRequest) {
      if (this.state.selectedActivity && this.state.selectedPurchaseType == 'bookings') {
        await this.updateActivityOccurance();
      }
      if (this.state.selectedGiftCard && this.state.selectedPurchaseType == 'giftcards' && !this.state.bookingRequest) {
        await this.updateGiftCardDocument();
      }
    }

    // Update the list of selectable activies or start times
    // either when the selected date has changed or when the list of available dates is about to change
    if (
      newState.selectedDate &&
      (!Moment(newState.selectedDate).isSame(this.state.selectedDate, 'day') || newState.availableDates)
    ) {
      // Deselect the current activity and set the state so the
      // new available activities will show correct values
      newState.selectedActivity = undefined;

      // Fetch new bookable items
      if (Platforms.features.offersCanBeAssignedToEmployees) {
        newState.availableActivityTimeSlots = await this.calculateAvailableScheduleTimes(newState.selectedDate);
      } else {
        newState.availableActivities = await this.calculateAvailableActivites(newState.selectedDate);

        // Select the first activity if not in mobile
        if (this.device.size !== 'mobile') {
          let firstAvailableActivity: ActivityOccuranceDocument | undefined;

          for (const activity of newState.availableActivities) {
            if (firstAvailableActivity) break;
            if (activity.isFullyBooked) continue;
            firstAvailableActivity = activity;
          }

          newState.selectedActivity = firstAvailableActivity;
        }
      }
    }

    // Update the selected activity type so that it always matches any selected activity
    if (newState.selectedActivity) {
      newState.selectedActivityType = newState.selectedActivity.originatingActivity as any; // NOTE: assumes populated data
    }
    // Update the selected property so that it always matches any selected activity
    if (newState.selectedActivity && newState.selectedActivity.property != this.state.selectedProperty?._id) {
      newState.selectedProperty = await new PropertyResource().get(newState.selectedActivity.property);
    }

    // Make sure we don't change from any fixed property/activityTypes
    if (
      this.props.fixedProperty &&
      newState.selectedProperty &&
      this.props.fixedProperty.id != newState.selectedProperty.id
    ) {
      throw new Error('[WidgetDataProvider] Tried to switch from the fixed property');
    }
    if (
      this.props.fixedOffer &&
      newState.selectedActivityType &&
      this.props.fixedOffer.id != newState.selectedActivityType.id
    ) {
      throw new Error('[WidgetDataProvider] Tried to switch from the fixed activity type (offer)');
    }

    await this.setState({ isloading: false, ...(newState as any) }, callback);
  }

  //#endregion

  //#region public status checks

  private allowsBookingWithoutPayment() {
    return this.state.selectedOrganization && this.state.selectedOrganization.allowBookingsWithoutPayment;
  }

  private allowsSwitchingPurchaseType() {
    // Only allow switching if the property has been passed and is not a virtual document
    return !this.props.fixedPurchaseType && Platforms.features.giftCards && this.props.fixedProperty;
  }

  private isDateDisabled(date: Date) {
    // NOTE: We must use the original timezone here, otherwise it fucks up the comparision
    return !Lodash(this.state.availableDates).includes(Moment(date).format('YYMMDD'));
  }

  private async refetchBookableDatesByMonth(month: string) {
    this.setState({ isFetchingData: true });
    const { availableDates, fullyBookedDates } = await this.calculateAvailableBookableDates(month);
    this.setState({ availableDates, fullyBookedDates, isFetchingData: false });
  }

  //#endregion

  //#region public methods

  public setSelectedPriceCategories(cat: BookingPriceCategoryDocument[]) {
    this.setState({
      ...this.state,
      selectedPriceCategories: cat,
    });
  }

  public setSelectedActivityOccurance(activityOccurance: ActivityOccuranceDocument) {
    this.setState({
      ...this.state,
      selectedActivity: activityOccurance,
      selectedActivityType: activityOccurance.originatingActivity as unknown as ActivityTypeDocument,
    });
  }

  public async goToStep(nextStepId: StepId, preventScrollIntoView = true) {
    const { selectedStepId, isReset } = this.state;

    // Skip the initial selection of either gift cards or activities if one is already preselected when the widget mounts
    if (!isReset && selectedStepId == 'unintialized') {
      if (nextStepId == 'giftcardSelection' && this.state.selectedGiftCardType) {
        nextStepId = 'customerInfo';
      }
    }

    // Calculate the new step statuses
    const newSteps = this.calculateStepData();
    const nextStep = newSteps.get(nextStepId) as WidgetStepStatus;

    // we're basing completion based from navigation order. Not actally checking if the step has been completed.
    // I guess this was nice when reloading the window reset all data, but now customer info and products get saved.
    // My idea is having the stepcomponents decide if they're ready, and for every progress step you make, we recheck if everything's in order.

    for (const [id, step] of newSteps) {
      if (nextStepId == 'confirmation') {
        step.active = id == 'confirmation';
        step.disabled = false;
        step.completed = true;
      } else {
        if (step.order < nextStep.order) {
          step.active = false;
          step.completed = true;
          step.disabled = false;
        }
        if (step.order == nextStep.order) {
          step.active = true;
          step.completed = false;
          step.disabled = false;
        }
        if (step.order > nextStep.order) {
          // NOTE: we could do more calculations here looking at the current state and allowing future steps to be reselected
          step.active = false;
          step.disabled = true;
          step.completed = false;
        }
      }
    }

    try {
      await this.updateState({ selectedStepId: nextStepId, steps: newSteps });
    } catch (error) {
      console.log(error);
    }

    // After step has selected in state, navigate to route
    this.props.history.push(this.stepRoutes[nextStepId]);

    if (preventScrollIntoView) return;

    // Scroll the widget to the top when the step is changed, when its embeded on another site,
    // that is if this.state.root exists
    if (this.state.root !== null && this.state.root.firstElementChild !== null) {
      this.state.root.firstElementChild.scrollIntoView({
        block: 'start',
      });
    }
  }

  public lastActiveStepIdAndRoute() {
    const wantedStep = {
      id: this.availableSteps[0],
      route: this.stepRoutes[this.availableSteps[0]],
    };
    for (const [id, step] of this.state.steps) {
      if (step.disabled) continue;
      wantedStep.id = id;
      wantedStep.route = step.route;
    }
    return wantedStep;
  }

  public clearSelectedItemOnConfirm() {
    this.setState({
      ...this.state,
      selectedActivity: undefined,
      selectedGiftCardType: undefined,
    });
  }

  public async goToLastActiveStep() {
    const step = this.lastActiveStepIdAndRoute();
    await this.goToStep(step.id, true);
  }

  public verifyStepNavigation(id: StepId) {
    const currentStep = this.state.steps.get(id);
    if (!currentStep || currentStep.disabled) this.goToLastActiveStep();
  }

  private fetchActivityType(selectedActivityTypeId: string): void {
    const selectedActivityType = Lodash.find(
      this.state.availableActivityTypes,
      ({ id }) => id == selectedActivityTypeId,
    );
    this.updateState({ selectedActivityType });
  }

  private async sendBookingConfirmationEmail(booking: Lib.CartBooking, customer: CustomerDocument): Promise<string> {
    await new MailResource().sendBookingConfirmationEmail(booking.id);
    return customer.email;
  }

  private async sendGiftCardConfirmationEmail(giftcard: Lib.CartGiftcard, customer: CustomerDocument): Promise<string> {
    // Note: should we pass email to function instead of infering it from booking?
    await new MailResource().sendGiftCardConfirmationEmail(giftcard.id);
    return customer.email;
  }

  private async resetWidget(purchaseType?: IWidgetProvidedData['selectedPurchaseType']) {
    if (purchaseType) {
      await this.setState({ selectedPurchaseType: purchaseType });
    }

    const newState = this.initialState();

    await this.updateState({ ...newState, isReset: true });

    // "remount" the provider
    this.componentDidMount();
  }

  //#endregion

  //#region state calculations

  /** Makes sure that an occurance that might only exist on the client is saved in the database */
  private async updateActivityOccurance() {
    // Do nothing if no selected acitivty exists or its already saved
    if (!this.state.selectedActivity || !this.state.selectedActivity.isNew) {
      return;
    }

    if (!Platforms.features.offersCanBeAssignedToEmployees) {
      throw new Error(
        'Tried to save a new occurance to the database during a purchase without employee assignments being enabled',
      );
    }

    if (!this.state.selectedBooking) {
      throw new Error('Tried to save a new occurance to the database without first creating a booking');
    }

    // Save the occurance document in the database
    const savedOccuranceId = await new ActivityOccuranceResource().updateDocument(this.state.selectedActivity);

    // Set the working staff, this ensurs that emails will be sent out correctly
    await new ActivityOccuranceResource().setWorkingStaff(savedOccuranceId, this.state.selectedActivity.workingStaff);

    // Make sure that the selected booking has a correct reference to the saved occurance
    this.state.selectedBooking.occurance = new Types.ObjectId(savedOccuranceId);
  }

  /** Makes sure that a gift card that might only exist on the client is saved in the database */
  private async updateGiftCardDocument() {
    // Do nothing if no selected acitivty exists or its already saved
    if (!this.state.selectedGiftCard || !this.state.selectedGiftCard.isNew) {
      return;
    }

    // Save the occurance document in the database
    await new GiftCardResource().updateDocument(this.state.selectedGiftCard);
  }

  /** Calculates what activies types can be booked from this property */
  private async calculateAvailableActivityTypes(): Promise<ActivityTypeDocument[]> {
    if (Platforms.features.offersCanBeAssignedToEmployees) {
      if (!this.state.selectedProperty) {
        throw new Error(
          "[WidgetDataProvider] Can't calculate available activity types from a property without it being set",
        );
      }
      const availableOffers = await new AvailableOfferOnPropertyResource().populateOffersOnAvailableOfferOnProperty(
        String(this.state.selectedProperty._id),
      );

      // Return only unique activity types
      return Lodash(availableOffers)
        .map(({ offer }) => offer as any as ActivityTypeDocument)
        .uniqBy('id')
        .value(); // NOTE: assumes data will be populated
    }
    return []; // NOTE: this might need to be updated
  }

  /** Calculates what types of gift cards can be purchased from this property */
  private async calculateAvailableGiftCardTypes(): Promise<GiftCardTypeDocument[]> {
    if (Platforms.features.giftCards) {
      return await new GiftCardTypeResource().findBasedOnLimitations({
        organizations: [this.state.selectedOrganization._id],
      });
    }
    return [];
  }

  /** Calculates what options to send when fetching either available activity occurances or pickable dates */
  private async calculateOptionsForWhatsAvailable() {
    const options = {
      properties: this.props.fixedProperty ? [this.props.fixedProperty._id] : [],
      activityTypes: this.props.fixedOffer ? [this.props.fixedOffer._id] : [],
    };

    // Add any selected activity type, and included activityTypes, from the showcaseMap if it is set for the widget
    if (this.state.activityShowcase && this.state.selectedPrimaryActivityFromShowcase) {
      const { primaryActivity, included } = this.state.activityShowcase.get(
        this.state.selectedPrimaryActivityFromShowcase.id,
      )!;
      options.activityTypes = [...options.activityTypes, primaryActivity._id, ...included];
    }

    return options;
  }

  /** Calculates what activies can be booked, needs to be updateded when the selected date changes */
  private async calculateAvailableActivites(selectedDate: Date | string): Promise<ActivityOccuranceDocument[]> {
    /*     const date = selectedDate != 'Invalid Date' ? selectedDate : Moment().toDate(); */
    let periodStart = Moment(selectedDate).startOf('day').toDate();
    let periodEnd = Moment(selectedDate).endOf('day').toDate();
    const options = await this.calculateOptionsForWhatsAvailable();

    let events = await new ActivityOccuranceResource().findBookableActivities(periodStart, periodEnd, options);

    // Show only events for the selected date
    events = events.filter((event: ActivityOccuranceDocument) => Moment(event.start).isSame(selectedDate, 'day'));

    // Show only events that have remaining visitor capacity
    events = events.filter((event: ActivityOccuranceDocument) => event.visitorCapacity);

    // Show only events that match the fixed offer if fixedOffer is set
    events = events.filter((event: ActivityOccuranceDocument) => {
      const activityTypeId = (event.originatingActivity as any as ActivityTypeDocument)._id;
      return this.props.fixedOffer ? activityTypeId === this.props.fixedOffer._id : true;
    });

    // Show only events after current time if today
    const isToday = Moment().isSame(this.state.selectedDate, 'day');

    if (!this.state.selectedActivityType?.isTmeTicket) {
      events = isToday
        ? Lodash.filter(events, (event) => Moment(event.bookingClosesAtDate).isSameOrAfter(new Date()))
        : events;

      // Show only events where the booking is still open
      events = Lodash.filter(events, (event: ActivityOccuranceDocument) =>
        Moment().isBefore(Moment(event.bookingClosesAtDate || event.start)),
      );
    }

    // Sort events by start time
    events = Lodash.sortBy(events, (event) => event.start);

    return events;
  }

  /** Calculates what start times are available, needs to be updateded when the selected date changes */
  private async calculateAvailableScheduleTimes(
    selectedDate: Date,
  ): Promise<{ [startTime: string]: AvailableActivityTimeSlot[] }> {
    if (!Platforms.features.offersCanBeAssignedToEmployees) {
      throw new Error(
        '[WidgetDataProvider] Tried to get bookable times from a platform not supporting employee schedules',
      );
    }
    if (!this.state.selectedActivityType) {
      throw new Error('[WidgetDataProvider] Tried to get bookable times without a defined activity type');
    }
    if (!this.state.selectedProperty) {
      throw new Error('[WidgetDataProvider] Tried to get bookable times without any selected property');
    }

    // Get preparation duration and booking closing time to appended to start date in search
    const { bookingClosesBeforeEventInHours, preparationDuration, cleanupDuration, duration } =
      this.state.selectedActivityType;

    // If the start date is today, trim to the current time of day
    const start = Moment(selectedDate).isSame(Moment(), 'day')
      ? Moment()
          .add(preparationDuration || 0, 'minutes')
          .add(bookingClosesBeforeEventInHours || 0, 'hours')
          .toDate()
      : Moment(selectedDate).startOf('day').toDate();

    const end = Moment(selectedDate)
      .endOf('day')
      .subtract(duration, 'minutes')
      .subtract(cleanupDuration, 'minutes')
      .toDate();

    // Fetches available time slots
    const timeSlots = await new AvailableActivityTimeSlotResource().find({
      offer: this.state.selectedActivityType._id,
      property: this.state.selectedProperty._id,
      start: { $gte: start, $lte: end },
    });

    // Create and return a dictionary with unique start times as keys containing the time slots for each employee that can be assigned at that time
    return Lodash.groupBy(timeSlots, ({ start }) => Moment(start).startOf('minute').format());
  }

  /** Calculates what dats are available for booking */
  private async calculateAvailableBookableDates(month?: string) {
    const options = await this.calculateOptionsForWhatsAvailable();
    // in case there is no selectedActivityType (on mobile), isTmeTicket is based on the organization
    let newOptions = {
      ...options,
      month: month,
      isTmeTicket: this.props.organization.flags.features.tmeTickets,
    };
    return await new ActivityOccuranceResource().datesWithEventsThatCanBeBooked(newOptions);
  }

  /** Calculate the current step configuration for the widget */
  protected calculateStepData(): IWidgetData['steps'] {
    const steps: IWidgetData['steps'] = new Map();
    for (const order in this.availableSteps) {
      const id = this.availableSteps[order];
      const route = this.stepRoutes[id];
      steps.set(id, { id, order: Number(order), disabled: true, completed: false, active: false, route });
    }
    steps.set('unintialized', {
      id: 'unintialized',
      order: this.availableSteps.length,
      disabled: true,
      completed: false,
      active: false,
      route: this.stepRoutes.unintialized,
    });
    return steps;
  }

  //#endregion

  componentDidMount() {
    // Go to the first available step, this initializes the widget
    this.goToStep(this.availableSteps[0]);
  }
}
