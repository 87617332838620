import React from 'react';
import { LocationDocument, AddressDocument, Field, Input, Rules, Accordion } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { CoordinateSelector } from '../../../../common/coordinateSelector';

interface ILocationFieldProps {
  location?: LocationDocument;
  address?: AddressDocument;
  setOfferLocationOrAddress: (key: 'location' | 'address', value?: LocationDocument | AddressDocument) => void;
}

export const LocationFields = ({ location, address, setOfferLocationOrAddress }: ILocationFieldProps) => {
  const { t } = useLocale();
  const shownAddress = address || ({} as AddressDocument);

  const updateOfferAddress = (address?: AddressDocument) => {
    setOfferLocationOrAddress('address', address);
  };

  const onLocationClick = (pos?: LocationDocument) => {
    setOfferLocationOrAddress('location', pos);
  };

  return (
    <>
      <Accordion
        title={
          <h4 className="ui header">
            <i className="dropdown icon" /> {t('Add location')}
          </h4>
        }
      >
        <Field>
          <div className="ui message" style={{ marginTop: 0 }}>
            <p className="content">{t('Here you can add an exact...')}</p>
          </div>
        </Field>
        <CoordinateSelector
          location={location}
          onChange={onLocationClick}
          loadingElement={<div style={{ height: 400 }} />}
          containerElement={<div style={{ height: 400 }} />}
          mapElement={<div style={{ height: 400 }} />}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDNeWxgti3E_OsTgxut6uFb4lBvV1a3dAY&v=3.exp&libraries=geometry,drawing,places"
        />
        {location && (
          <div
            className="ui fluid danger button"
            style={{ marginTop: '0.5em' }}
            onClick={() => onLocationClick(undefined)}
          >
            {t('Remove the set location')}
          </div>
        )}
        <div style={{ height: '1.5em' }} />
      </Accordion>
      <Accordion
        title={
          <h4 className="ui header">
            <i className="dropdown icon" /> {t('Add address')}
          </h4>
        }
      >
        <Field>
          <div className="ui message" style={{ marginTop: 0 }}>
            <p className="content">{t('You can also add an alternative...')}</p>
          </div>
        </Field>
        <Field>
          <Input
            name="Adress"
            value={shownAddress.row1 || ''}
            placeholder={t('Address')}
            onChange={(value) => updateOfferAddress({ ...shownAddress, row1: value })}
          />
        </Field>
        <div className="ui grid" style={{ marginTop: 0, marginBottom: '1em' }}>
          <Field width={7}>
            <Input
              name="Postnummer"
              optional
              value={shownAddress.postNr || ''}
              placeholder={t('Zip code')}
              rules={[Rules.ExactLength(5), Rules.IsAnInteger()]}
              onChange={(value) => updateOfferAddress({ ...shownAddress, postNr: value })}
            />
          </Field>
          <Field width={9}>
            <Input
              name="Postort"
              value={shownAddress.postOrt || ''}
              placeholder={t('City')}
              onChange={(value) => updateOfferAddress({ ...shownAddress, postOrt: value })}
            />
          </Field>
        </div>
      </Accordion>
    </>
  );
};
