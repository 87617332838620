import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { BadgeImageResource } from './badgeImage.resource';

export interface BadgeDocument extends MongooseDocument {
  label: string;
  description: string;
  imageId: string;
  imageUrl: string;
}

export class BadgeResource extends MongooseResource<BadgeDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Badge);

    this.setSchema({
      label: { type: String, required: true },
      imageId: { type: String, required: true },
      description: { type: String, required: true },
    });

    const badgeImageResource = new BadgeImageResource();
    this.addVirtualField('imageUrl', (document) => {
      return badgeImageResource.fileUrl(document.imageId, 'png');
    });
  }
}
