import { action, computed } from 'mobx';
import * as React from 'react';
import { FormMember } from './form';

/** Default properties for button components */
export interface ButtonProps {
  /** Called when the button is pressed */ onClick?: () => void;
}

/**
 * A button element
 */
export class Button extends FormMember<ButtonProps> {
  render() {
    const className =
      'ui button ' +
      (this.props.className ? this.props.className : '') +
      (this.loading || this.props.loading ? ' loading' : '') +
      (this.disabled || this.props.disabled ? ' disabled' : '');

    return (
      <div style={this.props.style} className={className} onClick={this.props.onClick}>
        {this.props.children}
      </div>
    );
  }
}

/**
 * A action button performs an action and is the mean time locked from furtherPresses
 */
export class ActionButton extends FormMember<{
  /** Called when the button is pressed */ onClick?: (
    resolve: (value?: unknown) => void,
    reject: (error: Error) => void,
  ) => void;
}> {
  /** Indicates if currently performing a blocking action */
  @computed public get loading(): boolean {
    return this._loading || (!!this.form && this.form.loading);
  }

  @action
  private performAction = async (event): Promise<void> => {
    this._loading = true;
    await new Promise((resolve, reject) => {
      if (this.props.onClick) {
        this.props.onClick(resolve, reject);
      }
    }).catch((err) => {
      this.domElement.transition('shake');
    });
    this._loading = false;
  };

  render() {
    const className =
      'ui button ' +
      (this.props.className ? this.props.className : '') +
      (this.loading || this.props.loading ? ' loading' : '') +
      (this.disabled || this.props.disabled ? ' disabled' : '');

    return (
      <div style={this.props.style} className={className} onClick={this.performAction}>
        {this.props.children}
      </div>
    );
  }
}

/**
 * A button element that automaticly submit a form
 */
export class SubmitButton extends Button {
  /** Indicates if currently performing a blocking action */
  @computed public get loading(): boolean {
    return this._loading || (!!this.form && this.form.loading);
  }

  /** Indicates if currently disabled from futher changes */
  @computed public get disabled(): boolean {
    return this._disabled || (!!this.form && (this.form.disabled || this.form.loading || !this.form.valid));
  }

  render() {
    const className =
      'ui submit button ' +
      (this.props.className ? this.props.className : '') +
      (this.loading || this.props.loading ? ' loading' : '') +
      (this.disabled || this.props.disabled ? ' disabled' : '');

    return (
      <div style={this.props.style} className={className} onClick={this.props.onClick}>
        {this.props.children}
      </div>
    );
  }
}
