import Lodash from 'lodash';
import { computed } from 'mobx';
import * as React from 'react';
import { Maybe } from '../../../components/maybe';
import { Consume, Link, LocaleContext, MobxComponent, Platforms, UserDocument } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { PropertyStore } from '../properties/propertyStore';

export interface EmployeeItemProps {
  item: UserDocument;
  store: PropertyStore;
  isEditMode: boolean;
  addPropertyCrew: (item: UserDocument) => void;
  removePropertyCrew: (item: UserDocument) => void;
  addPropertyManager: (item: UserDocument) => void;
  removePropertyManager: (item: UserDocument) => void;
}

export class EmployeeItem extends MobxComponent<EmployeeItemProps> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @computed private get isCrew() {
    return Lodash.includes(this.props.store.property.crew, this.props.item._id);
  }
  @computed private get isManager() {
    return Lodash.includes(this.props.store.property.managers, this.props.item._id);
  }

  @computed private get crewButton() {
    const { t } = this._locale;
    if (this.isCrew) {
      return (
        <button
          className="ui green labeled tiny icon button"
          style={{ width: '190px' }}
          onClick={() => this.props.removePropertyCrew(this.props.item)}
        >
          <i className="checkmark icon" />
          {t('components.dashboard.employees.employeeItem.personnelList')}
        </button>
      );
    } else {
      return (
        <button
          className="ui green basic labeled tiny icon button"
          style={{ width: '190px' }}
          onClick={() => this.props.addPropertyCrew(this.props.item)}
        >
          <i className="plus icon" />
          {t('Add to personnel list')}
        </button>
      );
    }
  }

  @computed private get managerButton() {
    const { t } = this._locale;
    if (this.isManager) {
      return (
        <Maybe if="organization.manager">
          <button
            className="ui yellow labeled tiny icon button"
            style={{ width: '190px' }}
            onClick={() => this.props.removePropertyManager(this.props.item)}
          >
            <i className="star icon" />
            {t('components.dashboard.employees.employeeItem.placeResponsible')}
          </button>
        </Maybe>
      );
    } else if (this.isCrew) {
      return (
        <Maybe if="organization.manager">
          <button
            className="ui grey basic tiny labeled tiny icon button"
            style={{ width: '190px' }}
            onClick={() => this.props.addPropertyManager(this.props.item)}
          >
            <i className="star icon" />
            {t('components.dashboard.employees.employeeItem.makeResponsible')}
          </button>
        </Maybe>
      );
    } else {
      return <span />;
    }
  }

  @computed private get propertyManagerLabel() {
    const { t, tt } = this._locale;
    return PropertyStore.Instance.hasProperty() &&
      Lodash.includes(PropertyStore.Instance.property.managers, this.props.item._id) ? (
      <div className="meta">
        {t('components.dashboard.employees.employeeItem.responsibleProperty')}{' '}
        {tt(PropertyStore.Instance.property.name)}&nbsp;
        <i className="yellow star icon" />
      </div>
    ) : (
      <span />
    );
  }

  @computed private get editButtonsItem() {
    if (this.props.isEditMode) {
      return (
        <div className="right floated item">
          {this.crewButton}
          <div style={{ height: '0.5em' }} />
          {this.managerButton}
        </div>
      );
    }
  }

  @computed private get manageScheduleButton() {
    const { t } = this._locale;
    if (Platforms.features.offersCanBeAssignedToEmployees) {
      if (!this.props.isEditMode) {
        return (
          <div className="right floated item" style={{ marginRight: '2em', marginTop: '1em' }}>
            <Link
              className="ui primary basic button"
              to={
                '/dashboard/properties/details/' +
                this.props.store.property.id +
                '/employees/manageSchedule/' +
                this.props.item.id
              }
            >
              {t('components.dashboard.employees.employeeItem.manageSchedule')}
            </Link>
          </div>
        );
      }
    }
  }

  @computed private get phoneNumber() {
    if (this.props.item.phoneNumber) {
      return (
        <div className="description">
          <i className="ui call icon" />
          {this.props.item.phoneNumber}
        </div>
      );
    }
  }

  public render() {
    return (
      <div className="item" key={this.props.item.id}>
        <div className="ui circular floating tiny image">
          <img src={this.props.item.imageUrl} />
        </div>
        <div className="middle aligned content">
          <a className="header">
            {this.props.item.firstname} {this.props.item.lastname}
          </a>
          <div className="description">
            {this.propertyManagerLabel}
            <i className="ui mail outline icon" /> {this.props.item.username}
          </div>
          {this.phoneNumber}
        </div>
        {this.manageScheduleButton}
        {this.editButtonsItem}
      </div>
    );
  }
}
