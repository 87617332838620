import { MongooseDocument, MongooseResource } from '../_dependencies';
import { Schema, Types } from 'mongoose';
import SchemaNames from '../schemas/names';

/** Note: This document/resource is used when references to automatic mails are saved in the "automatic-mailings" collection */

/** The type of mail that has been sent for a document */
export type AutomaticMailingType = 'thankyou' | 'welcome';

/** Only "booking" document calls for automatic mails to be sent (for now) */
export type AutomaticMailingRefType = 'booking';

export interface AutomaticMailingDocument extends MongooseDocument {
  /** A reference to the document that called for an automatic mail to be sent */ ref: Types.ObjectId;
  /** The document type for the document in "ref" */ refType: AutomaticMailingRefType;
  /** The recipient of an automatic mailing */ sentTo: string;
  /** The type of mail that has been sent */ type: AutomaticMailingType;
}

export class AutomaticMailingResource extends MongooseResource<AutomaticMailingDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.AutomaticMailing);

    this.setSchema({
      ref: { type: Schema.Types.ObjectId },
      refType: { type: String },
      sentTo: { type: String },
      type: { type: String },
    });
  }

  /** Is used to manually create an AutomaticMailingDocument */
  /** NOTE: Will not save the document to the "automatic-mailings" collection, use "updateAutomaticMailing" for that */
  createAutomaticMailing(document: AutomaticMailingDocument): AutomaticMailingDocument {
    return this.createDocument(document);
  }

  /** Is used to save and/or update a manually created AutomaticMailingDocument to the "automatic-mailings" collection */
  /** Can be used to block the AutomaticMailingsService from automatically sending a mail of a specific type */
  updateAutomaticMailing(document: AutomaticMailingDocument) {
    return this.updateDocument(document);
  }
}
