import moment from 'moment-timezone';
import React from 'react';
import { Table } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { GroupedEntity, SubItem } from './inventoryLocationList';

interface Props {
  item: GroupedEntity;
  outgoing: boolean;
  mobile?: boolean;
}

export const InventoryLocationSubTable = ({ item, outgoing, mobile }: Props) => {
  const { t, tt } = useLocale();

  const renderTime = (date: Date) => {
    return moment(date).format('LT');
  };

  return (
    <tr style={{ backgroundColor: 'rgba(0,0,0,.05)' }}>
      <td colSpan={3}>
        <h4>{tt(item.title)}</h4>
        <div style={{ margin: mobile ? '0em -1em 0em -1em' : '' }}>
          <Table
            className={'ui small basic grey unstackable table'}
            minWidth={'10rem'}
            dataList={item.entityDetails}
            headers={[
              { title: t('Quantity'), sortBy: 'quantity' },
              {
                title: t('Location'),
                sortBy: !outgoing ? tt('endPlace.name') : tt('startPlace.name'),
              },
              {
                title: t('At'),
                sortBy: !outgoing ? 'endTime' : 'startTime',
              },
              { title: t('Booking'), sortBy: 'bookingNumber' },
              { title: t('Customer'), sortBy: 'customer.firstname' && ('customer.lastname' as any) },
              ...(!mobile ? [{ title: t('Notes'), sortBy: 'bookingNotes' }] : []),
            ]}
            renderRow={(subItem: SubItem) => {
              return (
                <tr key={`subItem-${subItem.id}`} style={{ backgroundColor: 'white' }}>
                  <td>
                    <div>{subItem.quantity}</div>
                  </td>
                  <td>
                    {!outgoing ? (
                      <div>
                        <i className={'ui long arrow alternate left icon'} />
                        {tt(subItem.endPlace?.name)}
                      </div>
                    ) : (
                      <div>
                        <i className={'ui long arrow alternate right icon'} />
                        {tt(subItem.startPlace?.name)}
                      </div>
                    )}
                  </td>
                  <td>
                    {outgoing ? <div>{renderTime(subItem.endTime)}</div> : <div>{renderTime(subItem.startTime)}</div>}
                  </td>
                  <td>
                    <div>{subItem.bookingNumber}</div>
                  </td>
                  <td>
                    <div>
                      {subItem.customer.firstname} {subItem.customer.lastname}
                    </div>
                  </td>
                  {!mobile ? (
                    <td>
                      <div>{!subItem.bookingNotes ? '-' : subItem.bookingNotes}</div>
                    </td>
                  ) : null}
                </tr>
              );
            }}
          />
        </div>
      </td>
    </tr>
  );
};
