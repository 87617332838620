import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DeviceProvider,
  Field,
  Form,
  Input,
  InventoryEntityDocument,
  InventoryStatusDocument,
  PlaceDocument,
  Rules,
  SubmitButton,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { DeleteButton } from '../../../deleteButton';

interface Props {
  places?: PlaceDocument[];
  entity: InventoryEntityDocument;
  creatingItem?: boolean;
  thisItemStatus: InventoryStatusDocument;
  deleteItem: (entityId: string, statusId: string) => void;
  cancelItem: () => void;
  handleItemFormSubmit: (
    updatedEntity: InventoryEntityDocument,
    updatedStatus: InventoryStatusDocument,
    reject,
  ) => void;
}

export const InventoryItemForm = ({
  entity,
  creatingItem,
  thisItemStatus,
  places,
  deleteItem,
  cancelItem,
  handleItemFormSubmit,
}: Props) => {
  const checkIfEntity = () => {
    if (!tempEntity) return entity;
    return tempEntity;
  };

  const [tempEntity, setTempEntity] = useState<InventoryEntityDocument>();
  const { t, tt } = useLocale();
  const device = React.useContext(DeviceProvider.Context);
  const mobile = device.size === 'mobile';

  const generateUrlLink = () => {
    const path = '/inventory/item';
    const query = `externalId=${entity.id}`;
    return `${path}?${query}`;
  };

  const handleSubmit = (_, reject) => {
    handleItemFormSubmit(tempEntity as InventoryEntityDocument, thisItemStatus as InventoryStatusDocument, reject);
  };

  const handleFormChange = (value: string, key: string) => {
    const entityCopy = { ...checkIfEntity() };
    entityCopy[key] = value;
    setTempEntity(entityCopy as InventoryEntityDocument);
  };

  const renderLocationFromPlaces = (place: PlaceDocument) => {
    return places?.filter((p) => p.id == String(place))[0];
  };

  const deleteButton = () => {
    return (
      <div style={{ width: '100%' }}>
        <DeleteButton
          fluid
          disabled={false}
          deleting={false}
          onConfirmed={() => {
            deleteItem(checkIfEntity().id, thisItemStatus.id);
          }}
          confirmationText={t('Are you sure?')}
        >
          {t('Remove')}
        </DeleteButton>
      </div>
    );
  };

  const cancelButton = () => {
    return (
      <div className="ui segment fluid button" onClick={cancelItem}>
        <i className="remove icon" /> {t('Cancel')}
      </div>
    );
  };

  const linkButton = () => {
    return (
      <Link
        className={`ui segment fluid ${creatingItem ? 'disabled' : ''} button`}
        to={generateUrlLink()}
        target="_blank"
      >
        <i className="edit icon" />
        {t('Edit status')}
      </Link>
    );
  };

  const itemInfo = () => {
    return (
      <div style={{ paddingLeft: '1rem' }}>
        <p>
          <i className="tag icon" />
          {t('Title')}: {entity.title}
        </p>
        <p>
          <i className="question circle outline icon" />
          {t('Status')}: {t(thisItemStatus.status)}
        </p>
        <p>
          <i className="map pin icon" />
          {t('Location')}: {thisItemStatus.location ? tt(renderLocationFromPlaces(thisItemStatus.location)?.name) : '-'}
        </p>
      </div>
    );
  };

  const form = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <Field label={t('Title')}>
          <Input
            style={{ paddingBottom: '0.5em' }}
            value={checkIfEntity().title || ''}
            name="title"
            rules={[Rules.NotEmpty()]}
            icon="tag"
            onChange={(value) => {
              handleFormChange(value, 'title');
            }}
          />
        </Field>
        <SubmitButton className="fluid green">{creatingItem ? t('Create') : t('Save changes')}</SubmitButton>
      </Form>
    );
  };

  if (mobile) {
    return (
      <tr>
        <td colSpan={4}>
          <div className="ui fluid card" style={{ width: '100%', padding: '1em' }}>
            <div style={{ width: '100%' }}>
              <div style={{ width: '100%' }}>
                {!creatingItem ? <h4>{'Item information'}</h4> : null}
                {!creatingItem ? itemInfo() : null}
              </div>
              <div className="ui divider" />
              <h3>{creatingItem ? 'Create Item' : entity.title}</h3>
              {form()}
              {linkButton()}
              {!creatingItem ? deleteButton() : null}
              <div style={{ padding: '-20px' }}>
                <div className="ui divider" />
                {cancelButton()}
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td colSpan={5}>
        <div
          className="ui basic card"
          style={{
            width: '100%',
            padding: '1em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          <div style={{ width: '50%', paddingRight: '1em' }}>
            <h3>{creatingItem ? 'Create item' : entity.title}</h3>
            {form()}
            {linkButton()}
            {!creatingItem ? deleteButton() : null}
            <div style={{ padding: '-20px' }}>
              <div className="ui divider" />
              {cancelButton()}
            </div>
          </div>
          <div className="ui vertical divider">+</div>
          <div style={{ width: '50%', padding: '1em 0em 1em 2em' }}>
            {!creatingItem ? <h4>{t('Item information')}</h4> : null}
            {!creatingItem ? itemInfo() : null}
          </div>
        </div>
      </td>
    </tr>
  );
};
