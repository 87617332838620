import {
  CreateCardPaymentFn,
  CreateGiftcardResponse,
  CreateCardResponse,
  CreateGiftcardPaymentFn,
  CreateManualPaymentFn,
  CreateManualResponse,
} from './payments.types';
import { Fetcher } from './utilities';

export const cardCreate =
  (fetcher: Fetcher): CreateCardPaymentFn =>
  async (params) => {
    const res = await fetcher('/function/create-card-payment', {
      method: 'POST',
      body: JSON.stringify(params),
    });
    const data: CreateCardResponse = await res.json();
    switch (res.status) {
      case 201:
        return data;
      case 202:
        return { ...data, requiresAction: true };
      default:
        // TODO: THIS NEEDS TO HAVE MORE EXPLICIT ERRORS
        throw data;
    }
  };

export const manualCreate =
  (fetcher: Fetcher): CreateManualPaymentFn =>
  async (params) => {
    const res = await fetcher('/function/create-manual-payment', {
      method: 'POST',
      body: JSON.stringify(params),
    });
    const data: CreateManualResponse = await res.json();
    switch (res.status) {
      case 201:
        return data;
      default:
        throw data;
    }
  };

export const giftcardCreate =
  (fetcher: Fetcher): CreateGiftcardPaymentFn =>
  async (params) => {
    const res = await fetcher('/function/create-giftcard-payment', {
      method: 'POST',
      body: JSON.stringify(params),
    });
    const data: CreateGiftcardResponse = await res.json();
    switch (res.status) {
      case 201:
        return data;
      default:
        throw data;
    }
  };
