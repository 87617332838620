export interface DiscountError extends Error {
  type: string | null;
  message: string;
}

export class DiscountError extends Error {
  constructor(type: string | null, message: string) {
    super();
    this.type = type || 'unknown_type';
    this.message = message || '';
  }
}
