import { Types } from 'mongoose';
import React, { useEffect, useState } from 'react';
import {
  DestinationResource,
  Dropdown,
  Rules,
  DropdownItem,
  ActivityTypeDocument,
  ActivityTypeResource,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';

interface Props {
  onSelect: (pickedActivity: ActivityTypeDocument[] | undefined) => void;
  organizationId: Types.ObjectId;
  multiple?: boolean;
  notEmpty?: boolean;
  /** True if activityDropdown should list all activityTypes from other organizations in destination */
  listAllActivitiesInDestination?: boolean;
}

const ActivityDropdown = ({ onSelect, organizationId, multiple, notEmpty, listAllActivitiesInDestination }: Props) => {
  const { t, tt } = useLocale();

  const [activities, setActivities] = useState<ActivityTypeDocument[]>([]);

  async function handleSetActivities() {
    try {
      // If listAllActivitiesInDestination is true, all activities in destination except from logged in organization is listed
      if (listAllActivitiesInDestination) {
        const allActivitiesInDestination = await new DestinationResource().getActivityTypesInDestination(
          organizationId,
        );
        const activitiesFromOtherOrgs = allActivitiesInDestination.filter((activity) => {
          return activity.organization != organizationId;
        });
        setActivities(activitiesFromOtherOrgs);
        return;
      }

      const activities = await new ActivityTypeResource().find({ organization: organizationId });
      setActivities(activities);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    handleSetActivities();
  }, []);

  const handleOnSelect = (selection: string) => {
    if (!selection) {
      onSelect(undefined);
      return;
    }

    const selectedActivitiesAsIds = selection.split(',');

    const selectedActivities: ActivityTypeDocument[] = (selectedActivitiesAsIds as string[]).map((activityId) => {
      return activities.find((activity) => {
        return activity.id == activityId;
      });
    }) as ActivityTypeDocument[];

    onSelect(selectedActivities);
  };

  const sortedActivities = () => activities.sort((a, b) => (a.title > b.title ? 1 : -1));

  return (
    <Dropdown
      searchable
      key="activityTypeDropdown"
      name="Välj ett erbjudande"
      placeholder={t('choose-type-of-offer')}
      loading={false}
      rules={notEmpty ? [Rules.NotEmpty(t('must-select-offer'))] : undefined}
      onChange={handleOnSelect}
      multiple={multiple}
      defaultValue="all"
    >
      {sortedActivities().map((activityType) => (
        <DropdownItem key={activityType.id} value={activityType.id}>
          {tt(activityType.title)}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};
export default ActivityDropdown;
