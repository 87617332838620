import React, { useContext, useState } from 'react';
import { OffersContext } from '../../../../../contexts/offers.context';
import { useLocale } from '../../../../../_locales';
import { AvailableVisitors } from '../availableVisitors/availableVisitors';
import { ActivityCategoryField } from '../fields/activityCategoryField';
import { DurationFields } from '../fields/durationFields';
import { MinimumNumberOfVisitorsField } from '../fields/minimumNumberOfVisitorsField';
import { OfferDescriptionField } from '../fields/offerDescriptionField';
import { OfferTitleField } from '../fields/offerTitleField';
import { OfferFormSubmitButton } from './formSubmitButton';

interface Props {
  onNavigateToTab: (path: string) => void;
}

export const MainTab = ({ onNavigateToTab }: Props) => {
  const { t } = useLocale();
  const { selectedOffer, updateSelectedOffer } = useContext(OffersContext);
  const [displayInventory, setDisplayInventory] = useState<boolean>(Boolean(selectedOffer?.inventories?.length));
  if (!selectedOffer) return null;

  const visitorFromCapacity: number = selectedOffer ? selectedOffer.visitorCapacity : 0;

  const visitorsFromInventory: number = (() => {
    if (!selectedOffer?.inventories || !selectedOffer?.inventories.length) return 0;
    return selectedOffer.inventories[0].quantity;
  })();
  const availableVisitors: number = displayInventory ? visitorsFromInventory : visitorFromCapacity;

  const toggleUseInventory = () => {
    setDisplayInventory(!displayInventory);
  };

  return (
    <div style={{ marginTop: '1em' }}>
      <OfferTitleField title={selectedOffer.title} setOfferTitle={updateSelectedOffer} />
      <OfferDescriptionField description={selectedOffer.description} setOfferDescription={updateSelectedOffer} />
      <ActivityCategoryField tags={selectedOffer.categoryTags} setOfferTags={updateSelectedOffer} />
      <DurationFields initialDuration={selectedOffer.duration} setOfferDuration={updateSelectedOffer} />
      <AvailableVisitors
        visitorCapacity={selectedOffer.visitorCapacity}
        displayInventory={displayInventory}
        toggleDisplayInventory={toggleUseInventory}
        inventories={selectedOffer.inventories}
        updateSelectedOffer={updateSelectedOffer}
        priceCategories={selectedOffer.priceCategories}
      />
      <MinimumNumberOfVisitorsField
        minimumVisitors={selectedOffer.minVisitors}
        availableVisitors={availableVisitors}
        displayInventory={displayInventory}
        setOfferMinimumVisitors={updateSelectedOffer}
      />
      <OfferFormSubmitButton
        onClick={() => onNavigateToTab('/pricing')}
        isSubmit={!selectedOffer.isNew}
        nextTabName={t('Pricing')}
      />
    </div>
  );
};
