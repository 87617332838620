import * as React from 'react';
import {
  Consume,
  CustomerDocument,
  KosmicComponent,
  LocaleContext,
  SubmitButton,
  UserRoleDocument,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { DatalayerContext } from '../datalayer.context';
import { MarketplaceCartContext } from '../marketplace.cart.context';
import { CustomerForm } from '../movedFromAH/customerForm';
import { SessionContext } from '../session.context';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';
import { OrderStepTemplate } from './orderStepTemplate';

interface State {
  booking: boolean;
  termsOfUse: boolean;
}

export class CustomerInfoStep extends KosmicComponent<any, State> {
  constructor(props: State) {
    super(props);
    this.state = {
      booking: false,
      termsOfUse: false,
    };
  }

  @Consume(WidgetDataProvider.Context)
  private widgetData: IWidgetData;

  @Consume(MarketplaceCartContext)
  private marketplaceCart: MarketplaceCartContext;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  @Consume(SessionContext)
  private session: SessionContext;

  @Consume(DatalayerContext)
  private datalayer: DatalayerContext;

  componentDidMount() {
    this.widgetData.verifyStepNavigation('customerInfo');
  }

  private async handleOnSubmit(customer: CustomerDocument, message: string, resolve, reject) {
    this.datalayer.sendAddShippingInfoEvent(this.marketplaceCart.cart, this.widgetData.selectedOrganization);
    this.marketplaceCart.setCustomerInfoToState({ ...customer, message });
    if (this.marketplaceCart.order.id) {
      await this.marketplaceCart.removeMarketplaceOrder();
    }
    await this.marketplaceCart.createMarketplaceOrder();
    await this.widgetData.gotoStep('payment');
  }

  private get customer() {
    // Reset customer info when logged in as mangager
    if (this.session.userHasRole({ type: 'property.manager' } as UserRoleDocument)) {
      return undefined;
    }
    // Autofill customer info otherwise
    return this.marketplaceCart.customer;
  }

  changeBookingState = () => {
    if (!this.state.booking) {
      this.setState({ booking: true });
    } else {
      this.setState({ booking: false });
    }
  };

  changeTermsState = () => {
    if (!this.state.termsOfUse) {
      this.setState({ termsOfUse: true });
    } else {
      this.setState({ termsOfUse: false });
    }
  };

  private renderBookingRules() {
    const { t } = this._locale;
    return (
      <div style={{ marginTop: '15px', marginBottom: '15px' }}>
        <div className="ui checkbox">
          <input
            type="checkbox"
            name="bookingRules"
            onClick={() => {
              this.changeBookingState();
            }}
          />
          <label>{t('I accept the booking conditions')}</label>
        </div>
      </div>
    );
  }

  private renderTermsOfUse() {
    const { t } = this._locale;
    return (
      <div style={{ marginTop: '10px', marginBottom: '15px' }}>
        <div className="ui checkbox">
          <input
            type="checkbox"
            name="termsOfUse"
            onClick={() => {
              this.changeTermsState();
            }}
          />
          <label>{t('I accept the terms of use')}</label>
        </div>
      </div>
    );
  }

  private toPaymentButton(disableSettings) {
    const { t } = this._locale;
    return (
      <SubmitButton className="fluid green" disabled={disableSettings}>
        {t('To payment')} <i className="arrow circle right icon" />
      </SubmitButton>
    );
  }

  private renderSubmitButton() {
    const { t, tt } = this._locale;
    if (this.widgetData.selectedPurchaseType == 'giftcards') {
      return (
        <SubmitButton className="fluid green">
          {t('To payment')}&nbsp;
          <i className="arrow circle right icon" />
        </SubmitButton>
      );
    } else {
      const { selectedActivityType } = this.widgetData;

      if (selectedActivityType) {
        if (tt(selectedActivityType.bookingRules!) && !tt(selectedActivityType.termsOfUse!))
          return (
            <>
              {this.renderBookingRules()}
              {this.toPaymentButton(!this.state.booking)}
            </>
          );

        if (tt(selectedActivityType.termsOfUse!) && !tt(selectedActivityType.bookingRules!)) {
          return (
            <>
              {this.renderTermsOfUse()}
              {this.toPaymentButton(!this.state.termsOfUse)}
            </>
          );
        }

        if (tt(selectedActivityType.termsOfUse!) && tt(selectedActivityType.bookingRules!)) {
          return (
            <>
              {this.renderBookingRules()}
              {this.renderTermsOfUse()}
              {this.toPaymentButton(!this.state.booking || !this.state.termsOfUse)}
            </>
          );
        }
      }
    }
  }

  render() {
    const shouldShowCustomMessage = this.marketplaceCart.areThereCartBookingsInCart;
    const { t } = this._locale;
    return (
      <OrderStepTemplate items={this.marketplaceCart.cart}>
        <div>
          <h4>{t('Fill in your contact information')}</h4>
          <div className="ui segment">
            <CustomerForm
              customer={this.customer}
              showMessageInput={shouldShowCustomMessage}
              submitButton={this.renderSubmitButton()}
              onSubmit={this.handleOnSubmit.bind(this)}
            />
          </div>
        </div>
      </OrderStepTemplate>
    );
  }
}
