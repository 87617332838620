import React, { FC } from 'react';
import Moment from 'moment-timezone';

interface Props {
  day: Date;
}

/** Creates a sticky header and a list container for a single day in the agenda view */
export const AgendaDaySection: FC<Props> = ({ day, children }) => {
  const isToday = Moment().isSame(day, 'day');

  return (
    <div>
      <div
        className={isToday ? 'rbc-today' : 'rbc-background'}
        style={{
          position: 'sticky',
          top: 0,
          padding: '.5rem',
          zIndex: 100,
          borderBottom: '1px solid #ddd',
          color: isToday ? 'darkorange' : 'black',
        }}
      >
        <b style={{ fontSize: '0.8rem' }}>{Moment(day).format('dddd, D MMM').toUpperCase()}</b>
      </div>

      <div className="ui divided selection list" style={{ padding: '0 .5rem 1rem', marginTop: '.5rem' }}>
        {children}
      </div>
    </div>
  );
};
