export type ManualDetails = {
  productId: string;
  amount: number;
}[];

export const isManualDetails = (obj: any | ManualDetails): obj is ManualDetails => {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    typeof obj[0] === 'object' &&
    typeof obj[0].productId === 'string' &&
    typeof obj[0].amount === 'number'
  );
};
