import Moment from 'moment-timezone';
import { Types } from 'mongoose';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  ActivityTypeDocument,
  ActivityTypeResource,
  Consume,
  GiftCardTypeResource,
  KosmicComponent,
  LocaleContext,
  OrganizationDocument,
  OrganizationResource,
  Platforms,
  PropertyResource,
} from './_dependencies';
import { WidgetLocaleContext } from './_locales';
import { BookingProcessView } from './bookingProcessView';
import { ErrorBoundary } from './copyFromAH/errorBoundary';
import { LanguageToggleIcons } from './copyFromAH/languageToggleIcons';

import { DatalayerProvider } from './datalayer.context';
import { MarketplaceCartProvider } from './marketplace.cart.context';
import { MarketplaceProvider } from './marketplace.context';
import type { ShowcaseMap, ShowcaseString } from './showcase';
import { WeatherProvider } from './weather/weatherContext';
import {
  IWidgetChangableDataProps as DataProviderProps,
  IWidgetChangableDataProps,
  WidgetDataProvider,
} from './widgetDataProvider';

export interface Props {
  /** Indicates that the wrapper is supposed to be viewed from inside a html iframe */
  framed?: boolean;
  /** Fixed the widget to a given purchase type */
  fixedType?: DataProviderProps['fixedPurchaseType'];
  /** Starts the widget in a given purchase type */
  initalType?: DataProviderProps['initialPurchaseType'];
  /** Starts the widget fixed to a given activity type */
  initialActivity?: DataProviderProps['initialActivity'];
  /** The property id to use this widget on - either an organization id or property id must be passed */
  propertyId?: string;
  /** Starts the widget with to a given activity occurance selected */
  organizationId?: string;
  /** Optional preselected giftcard type to pass to the widget */
  giftCardTypeId?: string;
  /** Optional shadowroot used instead of document. Only accessable when widget is loaded with webcomponent */
  root?: ShadowRoot;
  /** Optionaly locks the widget to a single activity type  */
  fixedOffer?: ActivityTypeDocument;
  /**
   * If this string is passed a showcaseMap will be created and passed to the widget, shows an activity showcase before
   * starting then normal booking process
   */
  showcaseString?: ShowcaseString;
  /** Declared widget background color */
  backgroundColor?: string;
}

interface ExtendedProps extends Props, RouteComponentProps {}

interface State {
  /** Indicates whenever the widget is ready for rendering */
  isLoading: boolean;
  /** Widget Data Provider properties to pass when rendering */
  providerProps?: IWidgetChangableDataProps;
}

/**
 * A wrapper around the widget that adds data provder props depending on
 * the wanted type of purchase, viewing mode and other functionality wanted
 * from the actual widget
 */
export class Widget extends KosmicComponent<ExtendedProps, State> {
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  constructor(props: ExtendedProps) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    // Update the document with the "widget" css class
    if (this.props.framed) {
      document.body.classList.add('widget'); // add a "widget" class to the entire body
    }

    // Resolve the provider Props and stop loading after mount
    this.resolveProviderProps();
  }

  UNSAFE_componentWillMount() {
    // Update the document with the "widget" css class removed
    if (this.props.framed) {
      document.body.classList.remove('widget'); // remove the "widget" class to the entire body
    }
  }

  /** Resolves passed props to initial value to give to the widget data provider */
  async resolveProviderProps(propsToUpdateFrom?: ExtendedProps): Promise<void> {
    await this.setState({ isLoading: true });
    try {
      const props = propsToUpdateFrom || this.props;
      const { propertyId, organizationId, giftCardTypeId, showcaseString } = props;

      // Pass relevant properties along
      const providerProps: Partial<IWidgetChangableDataProps> = {
        root: props.root,
        history: props.history,
        initialPurchaseType: props.initalType,
        fixedPurchaseType: props.fixedType,
        initialActivity: props.initialActivity,
        ignoreSignedInUser: props.framed,
        specialGoogleAnalyticsEnabled: true,
        fixedOffer: props.fixedOffer,
        backgroundColor: props.backgroundColor || '#FCFCFC',
      };

      // Expand any Showcase information given
      if (showcaseString && showcaseString.length) {
        const resource = new ActivityTypeResource();
        const showcaseMap: ShowcaseMap = new Map();
        const items = showcaseString.split(';');

        for (const item of items) {
          const [id, included] = item.split(':');
          showcaseMap.set(id, {
            primaryActivity: await resource.get(id),
            included: included
              .split(',')
              .filter((stringId) => stringId.length)
              .map((stringId) => new Types.ObjectId(stringId)),
          });
        }
        providerProps.fixedShowcase = showcaseMap;

        // Load the organizationId from the first activity type in the selection
        // NOTE: not having a fixed organization in the widget is a breaking change
        for (const [id, _] of showcaseMap) {
          const organizationId = (await new ActivityTypeResource().get(id)).organization;
          providerProps.organization = await new OrganizationResource().getUnrestrictedOrg(organizationId);
          providerProps.fixedPurchaseType = 'bookings'; // NOTE: make sure that giftcards are hidden, as they are only for this organization
          break;
        }
      }

      // Fetches the selected organization
      if (organizationId) {
        providerProps.organization = await new OrganizationResource().getUnrestrictedOrg(organizationId);
      }

      // Fetches the passed property document
      if (propertyId && propertyId.length) {
        const foundProperty = await new PropertyResource().getPropertyWithPopulatedOrg(propertyId);
        if (foundProperty) {
          providerProps.fixedProperty = foundProperty;
          providerProps.organization = foundProperty.organization as unknown as OrganizationDocument;
        }
      }

      // Check that property and organization definitly has ben set
      if (!providerProps.fixedProperty && !providerProps.organization) {
        throw new Error('[WidgetWrapper] either a property or organization is needed to render the purchase widget');
      }

      // Fetches any preselected giftcard type
      if (giftCardTypeId) {
        providerProps.initialGiftCardType = await new GiftCardTypeResource().get(giftCardTypeId);
      }

      // Save the resulting provider properties to state
      await this.setState({ isLoading: false, providerProps: providerProps as IWidgetChangableDataProps });
    } catch (err) {
      console.error(err);
    }
  }

  private renderAttribution() {
    const { t } = this._locale;
    if (this.props.framed) {
      return (
        <div style={{ paddingTop: '3em' }}>
          {t('widget.widgetWrapper.attribution')}
          &nbsp;
          <a href={MODULE_PUBLIC_URL} target="blank">
            {Platforms.platformName}
          </a>
          <span style={{ float: 'right' }}>
            <i className="copyright icon" />
            &nbsp;{Moment().format('YYYY')}
          </span>
        </div>
      );
    }
  }

  private hashgen(str: string, seed = 1337): string {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString();
  }

  private get localStorageKeygen() {
    if (!this.state.providerProps) return this.hashgen('generic-ah-cart');
    const {
      organization,
      initialActivity,
      initialGiftCardType,
      initialPurchaseType,
      fixedOffer,
      fixedProperty,
      fixedPurchaseType,
    } = this.state.providerProps;
    const keyParts: string[] = [
      organization.id,
      initialActivity?.id || 'nonInitAct',
      initialGiftCardType?.id || 'nonInitGift',
      initialPurchaseType || 'nonInitPur',
      fixedOffer?.id || 'nonFixOff',
      fixedProperty?.id || 'nonFixProp',
      fixedPurchaseType || 'nonFixPur',
      this.props.showcaseString || 'nonFixShow',
    ];
    return this.hashgen(keyParts.join('-'));
  }

  private get localStorageRequestKeygen() {
    return this.hashgen('generic-ah-request-cart');
  }

  private get initialItem() {
    if (this.state.providerProps?.initialGiftCardType) {
      return { giftcardType: this.state.providerProps?.initialGiftCardType };
    }
  }

  public render() {
    if (this.state.isLoading || !this.state.providerProps) {
      return null;
    }

    return (
      <DatalayerProvider>
        <WidgetDataProvider {...this.state.providerProps}>
          <MarketplaceProvider>
            <MarketplaceCartProvider
              initialItem={this.initialItem}
              localStorageKeygen={this.localStorageKeygen}
              localStorageRequestKeygen={this.localStorageRequestKeygen}
              ignoreSignedInUser={this.state.providerProps.ignoreSignedInUser}
            >
              <WeatherProvider shouldBeHidden={this.state.providerProps.organization.flags.hideWeatherInWidget}>
                <div style={{ padding: '4px', display: 'flex', justifyContent: 'center' }}>
                  <div style={{ flex: 1, maxWidth: '80rem' }}>
                    <LanguageToggleIcons />
                    <ErrorBoundary>
                      <BookingProcessView />
                    </ErrorBoundary>
                    {this.renderAttribution()}
                  </div>
                </div>
              </WeatherProvider>
            </MarketplaceCartProvider>
          </MarketplaceProvider>
        </WidgetDataProvider>
      </DatalayerProvider>
    );
  }
}

export const WidgetWrapper = withRouter(Widget);
