import Lodash from 'lodash';
import { Types } from 'mongoose';
import React from 'react';
import ReactRouter from 'react-router';
import {
  ActivityOccuranceResource,
  ActivityTypeDocument,
  ActivityTypeResource,
  AvailableOfferOnPropertyResource,
  Consume,
  DeviceProvider,
  IDevice,
  LocaleContext,
  MobxComponent,
  OrganizationDocument,
  Platforms,
  PropertyDocument,
  PropertyResource,
  PurchaseWidget,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { Badge } from '../../../components/common';
import { BadgeProvider, IBadge } from '../../../contexts/badge.context';
import { ActivitiesSearchProvider, IActivitySearchData } from './activitySearchProvider';
import { CategoryCheckboxes } from './categoryCheckboxes';

interface State {
  isLoading: boolean;
  availableCategories: string[];
  selectedProperty?: PropertyDocument;
  availableActivityTypes: ActivityTypeDocument[];
  selectedActivityType?: ActivityTypeDocument;
  employees: { imageUrl: string; name: string }[];
  selectedEmployee?: string;
}

export class PropertyDetailsView extends MobxComponent<{ match: ReactRouter.match<{ id: string }> }, State> {
  state: State = {
    isLoading: true,
    availableCategories: [],
    availableActivityTypes: [],
    employees: [],
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(BadgeProvider.Context)
  private badges: IBadge;

  @Consume(ActivitiesSearchProvider.Context)
  private searchData: Readonly<IActivitySearchData>;

  private get selectedProperty() {
    if (!this.state.selectedProperty) {
      throw new Error('[PropertyDetailsView] Unable to render the details view without a selected property');
    }
    return this.state.selectedProperty;
  }

  private async selectActivityTypeFromId(offerId?: string | Types.ObjectId) {
    // Get a offer id from the passed value, or from the originatingActivityType of any selected occurance
    const id =
      this.searchData.selectedActivity && ((this.searchData.selectedActivity.originatingActivity as any).id as string); // NOTE: assumes populated data

    if (this.selectedProperty && id) {
      // Find the originating activity in the booking store
      const offer = Lodash.find(this.state.availableActivityTypes, (offer) => offer.id == id.toString());

      // Set the offer in state
      await this.setState({ selectedActivityType: offer });
    }
  }

  /** Returns the available activity types filtered by selected categories */
  private get filteredActivityTypes() {
    if (!this.searchData.categories.length) {
      return this.state.availableActivityTypes;
    }
    return Lodash.filter(
      this.state.availableActivityTypes,
      (activity) =>
        Lodash.intersection(activity ? activity.categoryTags || [] : [], this.searchData.categories).length > 0,
    );
  }

  public async componentDidMount() {
    // Fetch the selected property
    const property = await new PropertyResource().getPropertyWithPopulatedOrg(this.props.match.params.id);

    if (property) {
      await this.setState({ selectedProperty: property });
    }
    // Determine the available activity types and categories on this property
    // ...when using employee scheduling
    if (Platforms.features.offersCanBeAssignedToEmployees) {
      const availableActivityTypesByEmployee =
        await new AvailableOfferOnPropertyResource().populateOffersOnAvailableOfferOnProperty(
          String(this.selectedProperty._id),
        );

      const availableActivityTypes = Lodash(availableActivityTypesByEmployee)
        .map(({ offer }) => offer as any as ActivityTypeDocument)
        .uniqBy('id')
        .value(); // Picks offers and removes duplicates NOTE: assumes data has been populated
      const availableCategories = Lodash.uniq(
        Lodash.flatMap(availableActivityTypes, (activity) => (activity ? activity.categoryTags || [] : [])),
      );
      await this.setState({ availableActivityTypes, availableCategories });
    }
    // ..when using occurances in the database
    else {
      const upcomingOccurances = await new ActivityOccuranceResource().find({
        start: { $gte: new Date() },
        property: this.selectedProperty._id,
      });
      const uniqueActivityTypeIds = Lodash(upcomingOccurances)
        .map(({ originatingActivity }) => originatingActivity!.toString())
        .uniq()
        .value();
      const availableActivityTypes = await new ActivityTypeResource().find({
        _id: { $in: uniqueActivityTypeIds },
      });
      const availableCategories = Lodash.uniq(
        Lodash.flatMap(availableActivityTypes, (activity) => (activity ? activity.categoryTags || [] : [])),
      );
      await this.setState({ availableActivityTypes, availableCategories });
    }

    // Animate
    this.domElement.transition({ animation: 'fade in', duration: 1000 });
    if (this.device.size !== 'mobile') {
      this.domElement.find('.ui.fluid.image').transition({ animation: 'scale in', duration: 1000 });
    }

    // Set the preselected activity type
    await this.selectActivityTypeFromId();

    // Loading is completed
    await this.setState({ isLoading: false });
  }

  private renderCategoryItems() {
    const { availableCategories } = this.state;
    const { t } = this._locale;
    if (availableCategories && availableCategories.length) {
      return <CategoryCheckboxes availableCategories={this.state.availableCategories} alignLeft />;
    }
    return (
      <div>
        <p>
          <i>{t('No available categories for this property')}.</i>
        </p>
      </div>
    );
  }

  private renderSocialMediaLinks() {
    if (this.selectedProperty.socialMedia) {
      const links: JSX.Element[] = [];
      if (this.selectedProperty.socialMedia.facebook) {
        links.push(
          <a target="_blank" href={this.selectedProperty.socialMedia.facebook} rel="noreferrer">
            <i
              className="facebook square grey large icon"
              style={{
                marginRight: '10px',
                fontSize: '2.5em',
                textDecoration: 'none',
                textAlign: 'left',
              }}
            ></i>
          </a>,
        );
      }
      if (this.selectedProperty.socialMedia.instagram) {
        links.push(
          <a target="_blank" href={this.selectedProperty.socialMedia.instagram} rel="noreferrer">
            <i
              className="instagram grey large icon"
              style={{
                marginRight: '10px',
                fontSize: '2.5em',
                textDecoration: 'none',
                textAlign: 'left',
              }}
            ></i>
          </a>,
        );
      }
      return <React.Fragment>{links}</React.Fragment>;
    }
  }

  private renderActivityTypeItems() {
    const { t, tt } = this._locale;
    // Add all activity type items as buttons
    const items = Lodash.map(this.filteredActivityTypes, (offer) => {
      const buttonStyle =
        this.state.selectedActivityType && this.state.selectedActivityType.id == offer.id ? ' blue' : ' basic';
      const buttonColor =
        this.state.selectedActivityType && this.state.selectedActivityType.id == offer.id
          ? Platforms.primaryColor
          : 'transparent';
      const buttonCallback = () => this.setState({ selectedActivityType: offer });

      return (
        <button
          key={offer.id}
          className={'ui circular small button' + buttonStyle}
          onClick={buttonCallback}
          style={{ marginBottom: '0.5em', backgroundColor: buttonColor, whiteSpace: 'nowrap' }}
        >
          {tt(offer.title)}
        </button>
      );
    });

    // If the currently selected activity type would be excluded, add it manually to the start of the list {
    if (
      this.state.selectedActivityType &&
      !Lodash.includes(this.filteredActivityTypes, this.state.selectedActivityType)
    ) {
      items.splice(
        0,
        0,
        <button
          key={this.state.selectedActivityType.id}
          className={'ui circular small blue button'}
          style={{
            marginBottom: '0.5em',
            backgroundColor: Platforms.primaryColor,
            whiteSpace: 'nowrap',
          }}
        >
          {tt(this.state.selectedActivityType.title)}
        </button>,
      );
    }

    // Add a button for viewing all available activity types
    // this button is not available when using employee scheduling
    if (!Platforms.features.offersCanBeAssignedToEmployees) {
      const buttonStyle = !this.state.selectedActivityType ? ' blue' : ' basic';
      const buttonColor = !this.state.selectedActivityType ? Platforms.primaryColor : 'transparent';

      items.splice(
        0,
        0,
        <button
          key="default"
          className={'ui circular small button ' + buttonStyle}
          onClick={() => {
            this.setState({ selectedActivityType: undefined });
          }}
          style={{ marginBottom: '0.5em', backgroundColor: buttonColor }}
        >
          {t('all')}
        </button>,
      );
    }

    return items;
  }

  private renderPersonnelItems() {
    const { t } = this._locale;
    if (!this.state.employees) {
      return <div className="ui inline active loader" />;
    }

    return (
      <React.Fragment>
        <h2 style={{ fontWeight: 400 }}>
          3. {t('components.customer.activities.propertyDetailsView.AktivityTitleTwo')}
        </h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {Lodash.map(this.state.employees, (employee) => {
            const isSelected = this.state.selectedEmployee == employee.name;
            return (
              <div
                key={employee.imageUrl + employee.name}
                style={{ position: 'relative', marginRight: '0.5em' }}
                onClick={() => this.setState({ selectedEmployee: employee.name })}
              >
                {this.renderCheckIcon(isSelected)}
                <div
                  className="ui tiny bordered circular image"
                  style={{ background: isSelected ? Platforms.primaryColor : 'transparent' }}
                >
                  <img src={employee.imageUrl} />
                </div>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '1em',
                    color: isSelected ? Platforms.primaryColor : 'grey',
                    fontWeight: isSelected ? 400 : 300,
                  }}
                >
                  {employee.name}
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  private renderCheckIcon(isSelected: boolean) {
    if (!isSelected) {
      return <span />;
    }
    return (
      <i
        style={{
          position: 'absolute',
          marginTop: '0',
          left: '56px',
          color: Platforms.primaryColor,
          backgroundColor: 'white',
          borderRadius: '100%',
          zIndex: 10,
        }}
        className={this.device.size === 'mobile' ? 'check large circle icon' : 'check large circle icon'}
      />
    );
  }

  private get hasPurchaseFeature(): boolean {
    const organization: string | OrganizationDocument = this.selectedProperty.organization as any;
    if (typeof organization == 'string') {
      console.error('Organization is not populated');
      return false;
    }
    const { features } = organization.flags;
    return features.bookings || features.giftcards;
  }

  private renderWidget() {
    // When using employee schedules the widget can only render with a selected activity type
    const ready = !Platforms.features.offersCanBeAssignedToEmployees || this.state.selectedActivityType;

    if (ready && this.hasPurchaseFeature) {
      return (
        <React.Fragment>
          <PurchaseWidget
            key={this.state.selectedActivityType ? this.state.selectedActivityType.id : 'allactivities'}
            propertyId={this.selectedProperty.id}
            fixedOffer={this.state.selectedActivityType}
            initialActivity={this.searchData.selectedActivity}
          />
        </React.Fragment>
      );
    } else {
      return <div style={{ height: '3em' }} />;
    }
  }

  private renderBadges() {
    const organization = this.selectedProperty.organization as any as OrganizationDocument; // NOTE: assumes populated data
    return this.badges.filter(organization).map((badge) => <Badge badge={badge} key={badge.id} />);
  }
  private renderPhoneItem() {
    const property = this.selectedProperty;
    if (property.phoneNo) {
      return (
        <div className="item">
          <i className="phone icon"></i>
          <div className="content">
            <a href={'tel:' + property.phoneNo}>{property.phoneNo}</a>
          </div>
        </div>
      );
    }
  }
  private renderMailItem() {
    const property = this.selectedProperty;
    if (property.email) {
      return (
        <div className="item">
          <i className="mail icon"></i>
          <div className="content">
            <a href={'mailto:' + property.email}>{property.email}</a>
          </div>
        </div>
      );
    }
  }
  private renderWebsiteItem() {
    const property = this.selectedProperty;
    if (property.website) {
      const trimmedWebsiteAddress = property.website.replace('http://', '').replace('https://', '');
      return (
        <div className="item">
          <i className="world icon"></i>
          <div className="content">
            <a href={property.website}>{trimmedWebsiteAddress}</a>
          </div>
        </div>
      );
    }
  }
  private renderAddressItem() {
    const address = this.selectedProperty.address;
    if (address && address.row1 && address.postNr && address.postOrt && address.country) {
      return (
        <div className="item">
          <i className="map signs icon"></i>
          <div className="content">
            <span>
              {address!.row1}
              <br />
              {address!.postNr + ' ' + Lodash.capitalize(address!.postOrt)}
              <br />
              {address!.country}
            </span>
          </div>
        </div>
      );
    }
  }

  render() {
    const { t, tt } = this._locale;
    if (this.state.isLoading) {
      return <div className="ui active inline centered large loader" />;
    }

    const imageStyle =
      this.device.size === 'mobile' ? { marginLeft: '-1em', marginRight: '-1em', marginTop: '-1em' } : {};
    const property = this.selectedProperty;

    return (
      <div>
        <div className="ui container" style={{ paddingBottom: '50px' }}>
          <div className="ui mobile reversed stackable two column grid">
            <div className="column">
              <div>
                <h1>{tt(property.name)}</h1>
                <p style={{ fontSize: '1.3em' }}>{tt(property.description)}</p>
                <div style={{ height: '0.5em' }} />
                <div className="ui relaxed big list">
                  {this.renderPhoneItem()}
                  {this.renderMailItem()}
                  {this.renderWebsiteItem()}
                  {this.renderAddressItem()}
                </div>
                <div className="ui grid">
                  <div className="ui column">{this.renderSocialMediaLinks()}</div>
                </div>
                <div className="ui grid">
                  <div className="row" style={{ alignItems: 'flex-start' }}>
                    {this.renderBadges()}
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              {/* TODO: is there a better way to remove the padding on mobile? */}
              <div style={imageStyle}>
                <img className="ui fluid image" src={property.imageUrl} />
              </div>
            </div>
          </div>

          <div className="ui divider" style={{ marginTop: '5em' }} />

          {this.hasPurchaseFeature && (
            <>
              <h2 style={{ fontWeight: 400 }}>
                1. {t('components.customer.activities.propertyDetailsView.CategoryTitle')}
              </h2>
              {this.renderCategoryItems()}

              <div className="ui divider" style={{ marginTop: '2em' }} />
              <h2 style={{ fontWeight: 400 }}>
                2. {t('components.customer.activities.propertyDetailsView.AktivityTitleOne')}
              </h2>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>{this.renderActivityTypeItems()}</div>
              <div className="ui divider" style={{ marginTop: '3em' }} />
              {/** this.renderPersonnelItems()  TODO: Not working right not - should be able to select an employee on bbloom? */}
              <div style={{ marginBottom: '3em' }} />
              {this.renderWidget()}
              <div style={{ marginBottom: '3em' }} />
            </>
          )}
        </div>
      </div>
    );
  }
}
