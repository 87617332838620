import React, { createContext, FC, useContext, useState } from 'react';
import {
  DestinationDocument,
  DestinationResource,
  GlobalsContext,
  PlaceDocument,
  PlaceResource,
  PropertyDocument,
  PropertyResource,
} from '../../../_dependencies';

export interface PropertiesContextValue {
  isLoading: boolean;
  properties: PropertyDocument[];
  places: PlaceDocument[];
  destinations: DestinationDocument[];
  isSidebarOpen: boolean;
  setIsSidebarOpen: (open: boolean) => void;
  fetchAllData: () => void;
  fetchProperties: () => void;
  fetchPlaces: () => void;
}

export const PropertiesContext = createContext<PropertiesContextValue>({
  isLoading: false,
  properties: [],
  places: [],
  destinations: [],
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
  fetchAllData: () => {},
  fetchProperties: () => {},
  fetchPlaces: () => {},
});

export const PropertiesProvider: FC = ({ children }) => {
  const { session } = useContext(GlobalsContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState<PropertyDocument[]>([]);
  const [places, setPlaces] = useState<PlaceDocument[]>([]);
  const [destinations, setDestinations] = useState<DestinationDocument[]>([]);

  const fetchAllData = async () => {
    setIsLoading(true);
    await fetchPlaces();
    const properties = await fetchProperties();
    await getDestinationProperties(properties);
    setIsLoading(false);
  };

  const fetchPlaces = async () => {
    const places = await new PlaceResource().find({ organization: session.currentOrganizationId });
    setPlaces(places);
  };

  const fetchProperties = async () => {
    const properties = await new PropertyResource().getAdministratedProperties();
    setProperties(properties);
    return properties;
  };

  const getDestinationProperties = async (properties: PropertyDocument[]) => {
    const destinations = await new DestinationResource().find({
      connectedProperties: { $in: properties.map((p) => p.id) },
    });

    const removeOwnProperties = (destinations: DestinationDocument[]) => {
      return destinations.map(({ connectedProperties, ...destination }) => ({
        ...destination,
        connectedProperties: connectedProperties.filter((p1) => !properties.some((p2) => p2.id === p1.id)),
      })) as DestinationDocument[];
    };

    setDestinations(removeOwnProperties(destinations));
  };

  return (
    <PropertiesContext.Provider
      value={{
        isLoading,
        properties,
        places,
        destinations,
        isSidebarOpen,
        setIsSidebarOpen,
        fetchAllData,
        fetchProperties,
        fetchPlaces,
      }}
    >
      {children}
    </PropertiesContext.Provider>
  );
};

export const useProperties = () => useContext(PropertiesContext);
