import Lodash from 'lodash';
import { computed, observable } from 'mobx';
import * as React from 'react';
import { DeleteButton } from '../../../components/deleteButton';
import { Consume, LocaleContext, MobxComponent, UserDocument } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { EmployeeForm } from './form';

export interface EmployeeAlternativeItemProps {
  item: UserDocument;
  onDeleteItem?: (employee: UserDocument) => void;
  maxWidth?: string;
}

export class EmployeeCard extends MobxComponent<EmployeeAlternativeItemProps> {
  @observable private _isEditing = false;
  @observable private _isDeleting = false;

  private handleOnDeleteEmployee = () => {
    if (this.props.onDeleteItem) {
      this.props.onDeleteItem(this.props.item);
    }
    this._isDeleting = true;
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @computed private get isCurrentUser() {
    return this.props.item.id == this.globals.session.currentUser?.id;
  }

  @computed private get content() {
    if (this._isEditing) {
      return (
        <div className="content">
          <EmployeeForm employee={this.props.item} onSaved={() => (this._isEditing = false)} />
        </div>
      );
    }
    return this.employeeInfo;
  }

  private get cardImage() {
    if (this._isEditing) {
      return undefined;
    }
    return (
      <div className="ui image">
        <img src={this.props.item.imageUrl} />
      </div>
    );
  }

  @computed private get employeeInfo() {
    return (
      <div className="content">
        <h4 className="header">
          {this.props.item.firstname} {this.props.item.lastname}
        </h4>
        <div className="description" style={{ paddingBottom: '5px' }}>
          <i className="ui mail icon" /> {this.props.item.username}
        </div>
        {this.props.item.phoneNumber ? (
          <div className="description" style={{ paddingBottom: '5px' }}>
            <i className="ui call icon" /> {this.props.item.phoneNumber}
          </div>
        ) : null}
        {this.props.item.note ? (
          <div className="description" style={{ paddingBottom: '5px', display: 'flex' }}>
            <i className="ui edit icon" />{' '}
            <div style={{ whiteSpace: 'pre-line', paddingLeft: '3px' }}>{this.props.item.note}</div>
          </div>
        ) : null}
        {this.mailNotificationInfo}
      </div>
    );
  }

  @computed private get mailNotificationInfo() {
    const { t } = this._locale;
    if (!this.isCurrentUser) {
      return undefined;
    }

    const icon = this.props.item.sendEmailNotifications ? 'alarm' : 'alarm mute';
    const text = this.props.item.sendEmailNotifications
      ? t('components.dashboard.employees.employeeCard.mailWorkHours')
      : t('components.dashboard.employees.employeeCard.noMailWorkHours');
    return (
      <div className="description">
        <i className={'ui ' + icon + ' icon'} /> {text}
      </div>
    );
  }

  @computed private get deleteButton() {
    if (!this._isEditing || this.isCurrentUser) {
      return undefined;
    }

    return (
      <div className="content">
        <DeleteButton fluid deleting={this._isDeleting} onConfirmed={this.handleOnDeleteEmployee}></DeleteButton>
      </div>
    );
  }

  @computed private get editButton() {
    const { t } = this._locale;
    const basic = !this._isEditing ? 'basic' : '';
    const style = this._isEditing
      ? { minHeight: '52px', maxHeight: '52px', paddingTop: 0 }
      : { minHeight: '66px', maxHeight: '66px' };

    return (
      <div className="content" style={Lodash.merge(style, { border: 'none' })}>
        <button className={'ui fluid ' + basic + ' button'} onClick={() => (this._isEditing = !this._isEditing)}>
          {this._isEditing ? t('Cancel') : t('Edit')}
        </button>
      </div>
    );
  }

  public render() {
    return (
      <div className="ui centered card" key={this.props.item.id} style={{ maxWidth: this.props.maxWidth }}>
        {this.cardImage}
        {this.content}
        {this.deleteButton}
        {this.editButton}
      </div>
    );
  }
}
