import React, { CSSProperties } from 'react';
import { Consume, LocaleContext, Platforms } from '../../../adventure-hero/_dependencies';
import { AHLocaleContext } from '../../../adventure-hero/_locales';

class PriceSection extends React.Component {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  checkboxIcon = () => {
    return <i style={iconStyle} className="large check circle outline icon"></i>;
  };

  render() {
    const { t } = this._locale;
    return (
      <div>
        <div style={{ marginTop: '5rem', marginBottom: '3rem' }}>
          <h2 style={headlineStyle}>{t('Plans')}</h2>
        </div>
        <div className="ui grid center aligned container">
          <div className="ui stackable center aligned three column grid">
            {/* START */}
            <div className="fifteen wide five wide computer column">
              <div style={columnStyle}>
                <h3 style={titleStyle}>{t('Start')}</h3>
                <p style={priceTextStyle}>1 100 {t('kr-SEK')}</p>
                <p style={yearMonthStyle}>/ {t('Year excl. VAT')}</p>
                <p style={percentageTextStyle}>5,5% {t('fee per online booking')}*</p>
                <p style={{ textAlign: 'left', marginTop: '-15px' }}>
                  <i>
                    {t('This plan is suitable for... up to')} 200 000 {t('kr-SEK')}.
                  </i>
                </p>
                <div className="ui divider" style={dividerStyle}></div>
                <h3 style={{ textAlign: 'left' }}>{t('Includes')}:</h3>
                <ul style={{ ...listStyle, marginTop: '25px' }}>
                  <li>
                    {this.checkboxIcon()}
                    {t('Bookings and payments online')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Personnel management tools')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Selling and using own gift cards')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Data backups')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Technical support')}
                  </li>
                  {/* <li>{this.checkboxIcon()}{t("Marketing through AdventureHero.se...")}</li> */}
                </ul>
              </div>
            </div>

            {/* STANDARD */}
            <div className="fifteen wide five wide computer column">
              <div style={columnStyleCenter}>
                <h3 style={titleStyle}>{t('Standard')}</h3>
                <p style={priceTextStyle}>5 500 {t('kr-SEK')}</p>
                <p style={yearMonthStyle}>/ {t('Year excl. VAT')}</p>
                <p style={{ ...percentageTextStyle, marginTop: '20px' }}>4,5% {t('fee per online booking')}*</p>
                <p style={{ textAlign: 'left', marginTop: '-15px' }}>
                  <i>
                    {t('This plan is suitable for... more than')} 200 000 {t('kr-SEK')}.
                  </i>
                </p>
                <div className="ui divider" style={dividerStyle}></div>
                <h3 style={{ textAlign: 'left' }}>{t('Includes')}:</h3>
                <ul style={{ ...listStyle, marginTop: '25px' }}>
                  <li>
                    {this.checkboxIcon()}
                    {t('Bookings and payments online')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Personnel management tools')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Selling and using own gift cards')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Data backups')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Technical support')}
                  </li>
                  {/* <li>{this.checkboxIcon()}{t("Marketing through AdventureHero.se...")}</li> */}
                </ul>
              </div>
            </div>

            {/* EXPANDERA */}
            <div className="fifteen wide five wide computer column">
              <div style={columnStyle}>
                <h3 style={titleStyle}>{t('Expand')}</h3>
                <p style={priceTextStyle}>11 000 {t('kr-SEK')}</p>
                <p style={yearMonthStyle}>/ {t('Year excl. VAT')}</p>
                <p style={{ ...percentageTextStyle, marginTop: '20px' }}>3,5% {t('fee per online booking')}*</p>
                <p style={{ textAlign: 'left', marginTop: '-15px' }}>
                  <i>
                    {t('This plan is suitable for... more than')} 2 000 000 {t('kr-SEK')}.
                  </i>
                </p>
                <div className="ui divider" style={dividerStyle}></div>
                <h3 style={{ textAlign: 'left' }}>{t('Includes')}:</h3>
                <ul style={{ ...listStyle, marginTop: '10px' }}>
                  <li>
                    {this.checkboxIcon()}
                    {t('Bookings and payments online')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Personnel management tools')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Selling and using own gift cards')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Data backups')}
                  </li>
                  <li>
                    {this.checkboxIcon()}
                    {t('Technical support')}
                  </li>
                  {/* <li>{this.checkboxIcon()}{t("Marketing through AdventureHero.se...")}</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '2rem', padding: '1rem' }}>
          <p style={{ marginTop: '3rem', marginRight: '3rem', textAlign: 'center' }}>
            <i>{t('Card purchases below SEK 100...')}</i>
          </p>
        </div>
      </div>
    );
  }
}

export default PriceSection;

const iconStyle: CSSProperties = {
  color: Platforms.primaryColor,
  marginTop: '-4px',
  marginLeft: '-10px',
};

const columnStyle: CSSProperties = {
  background: '#eee',
  padding: '3rem',
  margin: '0.5rem',
  height: '40rem',
  minWidth: '20rem',
  zIndex: -1,
  borderRadius: '0.6rem',
};

const columnStyleCenter: CSSProperties = {
  background: '#eee',
  padding: '2.8rem',
  margin: '0.5rem',
  height: '40rem',
  minWidth: '20rem',
  zIndex: 10,
  boxShadow: '2px 2px 12px #CCC',
  transform: 'scale(1.07)',
  borderRadius: '0.4rem',
  border: '1px solid rgba(255, 255, 255, 0.5)',
};

const headlineStyle: CSSProperties = {
  textAlign: 'center',
  color: 'black',
  fontSize: '2rem',
};

const priceTextStyle: CSSProperties = {
  marginTop: '-15px',
  color: Platforms.primaryColor,
  lineHeight: '2.4rem',
  fontSize: '2.4rem',
  fontWeight: 100,
};

const percentageTextStyle: CSSProperties = {
  marginTop: '-5px',
  marginBottom: '30px',
  lineHeight: '1.8rem',
  fontSize: '1.1rem',
  fontWeight: 'bold',
};

const titleStyle: CSSProperties = {
  transform: 'translateY(-30px)',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  margin: '2rem 0.5rem 0 0',
};

const yearMonthStyle: CSSProperties = {
  marginTop: '-35px',
  lineHeight: '25px',
};

const dividerStyle: CSSProperties = {
  marginTop: '2rem',
};

const listStyle: CSSProperties = {
  textAlign: 'left',
  paddingLeft: '1rem',
  listStyle: 'none',
  textIndent: '-1.4rem',
  display: 'grid',
  gap: '12px',
};
