import SchemaNames from '../schemas/names';
import { GridFsDocument, GridFsResource } from '../_dependencies';

export type ActivityImageDocument = GridFsDocument;

export class ActivityImageResource extends GridFsResource<ActivityImageDocument> {
  constructor() {
    super();
    this.setName(SchemaNames.ActivityImage);
  }
}
