import Lodash, { minBy } from 'lodash';
import Moment from 'moment-timezone';
import * as React from 'react';
import {
  ActivityTypeDocument,
  Consume,
  LocaleContext,
  MobxComponent,
  PropertyDocument,
  ActivityTagsFormatter,
  getTickets,
} from './../_dependencies';
import { WidgetLocaleContext } from './../_locales';

interface Props {
  property?: PropertyDocument;
  activityType: ActivityTypeDocument;
  userPosition?: Coordinates;
  inWidget?: boolean;
  onSelectedActivity?: (activity: ActivityTypeDocument) => void;
}

export class ActivityCard extends MobxComponent<Props> {
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  private get labelTags() {
    return Lodash.map(this.props.activityType.categoryTags || [], (categoryTag) => {
      const formattedTag = ActivityTagsFormatter.getFormattedTag(categoryTag, this._locale.locale);
      if (!formattedTag) {
        return <span key={categoryTag} />;
      }
      return (
        <div key={categoryTag} className="ui label">
          {formattedTag}
        </div>
      );
    });
  }

  private get leftSideLabels() {
    return (
      <div style={{ position: 'absolute', left: '1rem', bottom: '.7rem' }}>
        {this.visitorCapacityLabel}
        {this.categoryLabels}
      </div>
    );
  }

  private get categoryLabels() {
    if (!this.labelTags.length || this.props.inWidget) return null;
    return (
      <div className="item">
        <div className="ui medium black labels">{this.labelTags}</div>
      </div>
    );
  }

  private get visitorCapacityLabel() {
    const activityType = this.props.activityType;
    return (
      <div className="item">
        <div className="ui medium labels">
          <div style={{ color: 'black' }} className="ui label">
            <i className="user icon" />
            {(activityType.minVisitors || '1') + ' - ' + activityType.visitorCapacity}
          </div>
        </div>
      </div>
    );
  }

  private get imageUrl() {
    const activityType = this.props.activityType;

    if (activityType.imageUrl) {
      return this.props.inWidget ? `${MODULE_PUBLIC_URL}${activityType.imageUrl}` : activityType.imageUrl;
    }
    if (this.props.property) {
      return this.props.inWidget ? `${MODULE_PUBLIC_URL}${this.props.property.imageUrl}` : this.props.property.imageUrl;
    }
  }

  private get price() {
    const { t } = this._locale;
    const { activityType } = this.props;

    // Get the ticket categories only
    const ticketCategories = getTickets(activityType.priceCategories);

    // Select the least pricy value from price categories
    let price = minBy(ticketCategories.map(({ price }) => price)) || 0;

    // add the baseprice to the value
    price += activityType.basePrice;

    return (
      <>
        {t('From')} {price} {t('SEK')}
      </>
    );
  }

  private handleCardClicked = async () => {
    const { inWidget, property, activityType, onSelectedActivity } = this.props;
    if (inWidget) {
      onSelectedActivity && onSelectedActivity(activityType);
    } else if (property) {
      this.globals.session.navigateTo('/activities/booking/' + property.id + '/' + activityType.id);
    }
  };

  private degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  private distanceBetweenCoordinates(userPosition, activityPosition) {
    const lon2 = activityPosition.location[0];
    let lat2 = activityPosition.location[1];
    const lon1 = userPosition.longitude;
    let lat1 = userPosition.latitude;
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return Math.round(earthRadiusKm * c);
  }

  private get renderDistance() {
    const { t } = this._locale;

    const aboutDistanceLabel = t('approx');
    const kmDistanceLabel = t('km from you');

    if (!this.props.userPosition) {
      return null;
    }
    const distance = this.distanceBetweenCoordinates(this.props.userPosition, this.props.property);
    return (
      <>
        {aboutDistanceLabel} {distance} {kmDistanceLabel}
      </>
    );
  }

  private get newActivityLabel() {
    const { t } = this._locale;
    return (
      <div className="item" style={{ position: 'absolute', left: '1rem', top: '1rem' }}>
        <div className="ui medium labels">
          <div className="ui label">{t('new')}</div>
        </div>
      </div>
    );
  }

  private get renderNewActivityLabel() {
    const createdAt = Moment(this.props.activityType.createdAt);
    const lastDate = createdAt.clone().add(20, 'days');
    const isNew = Moment().isBetween(createdAt, lastDate);

    if (!isNew || this.props.inWidget) return null;
    return this.newActivityLabel;
  }

  private get renderDescription() {
    const { tt } = this._locale;
    return (
      <p
        style={{
          margin: '10px 0',
          height: '48px',
          fontSize: '14px',
          fontFamily: 'Arial',
          lineHeight: '16px',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {tt(this.props.activityType.description)}
      </p>
    );
  }

  render() {
    const { tt } = this._locale;
    return (
      <div className="ui column" style={this.props.style}>
        <div className="ui fluid link card" onClick={this.handleCardClicked}>
          <div className="image" style={{ position: 'relative' }}>
            <img src={this.imageUrl} title={'Boka ' + tt(this.props.activityType.title)} />
            {this.leftSideLabels}
            {this.renderNewActivityLabel}
          </div>
          <div className="content">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: '1.2rem', fontWeight: 600 }}>{tt(this.props.activityType.title)}</span>
              <span style={{ color: 'grey', fontWeight: 400 }}>{tt(this.props.property?.name)}</span>
            </div>
            {this.renderDescription}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: 'grey',
                fontWeight: 400,
                fontSize: '0.9em',
              }}
            >
              <span>{this.price}</span>
              <span>{this.renderDistance}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
