import * as React from 'react';
import {
  LocaleContext,
  Dropdown,
  DropdownItem,
  DestinationResource,
  OrganizationDocument,
  GlobalsContext,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';

interface Props {
  isDisabled: boolean;
  organization?: OrganizationDocument;
  onSelect: (organization: OrganizationDocument) => void;
}

export function OrganizationDropdown(props: Props) {
  const _locale: AHLocaleContext = React.useContext(LocaleContext);
  const globals = React.useContext(GlobalsContext);
  const [organizations, setOrganizations] = React.useState<OrganizationDocument[]>([]);
  const { t } = _locale;

  React.useEffect(() => {
    let isMounted = true;
    const getResources = async () => {
      const dResource = new DestinationResource();
      const destinationOrganizations = await dResource.getOrganizationsInDestination(
        globals.session.currentOrganizationId,
      );
      setOrganizations(destinationOrganizations);
    };
    if (isMounted) {
      getResources();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const dropdownItems = () => {
    const { t } = _locale;
    if (organizations) {
      const dropDownItems: JSX.Element[] = organizations.map((organization) => {
        return (
          <DropdownItem key={organization.id} value={organization.id}>
            {organization.name}
          </DropdownItem>
        );
      });
      dropDownItems.unshift(
        <DropdownItem key="all" value="all">
          {t('all')}
        </DropdownItem>,
      );
      return dropDownItems;
    }
    return null;
  };

  const onSelect = (organizationId) => {
    const selectedOrganization = organizations.find(
      (organization) => organization.id === organizationId,
    ) as OrganizationDocument;
    props.onSelect(selectedOrganization);
  };

  return (
    <Dropdown
      disabled={props.isDisabled}
      onChange={(e) => onSelect(e)}
      name="organizationSelection"
      placeholder={t('destination-partner')}
      value={props.organization?.id || 'all'}
    >
      {dropdownItems()}
    </Dropdown>
  );
}
