import React, { useContext, useState } from 'react';
import { Redirect, DeviceProvider, GiftCardDocument } from '../../_dependencies';
import { Link } from 'react-router-dom';
import { LanguageToggleIcons } from '../languageToggleIcons';
import { RescheduleConfirmation } from './rescheduleConfirmation';
import { RescheduleForm } from './rescheduleForm';

export const RescheduleLinkComponent = () => {
  const params = new URLSearchParams(location.search);
  const externalId = params.get('id');
  const creditCodeId = params.get('voucher');
  const device = useContext(DeviceProvider.Context);
  const [isCreditCodeActivated, setIsCreditCodeActivated] = useState<boolean>(Boolean(creditCodeId));
  const [creditCode, setCreditCode] = useState<GiftCardDocument>();

  const saveCreditCodeDetails = (creditCodeDetails: GiftCardDocument) => {
    setCreditCode(creditCodeDetails);
    setIsCreditCodeActivated(true);
  };

  if (externalId == null && creditCodeId == null) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div style={headerStyle(device.size === 'mobile')}>
        <Link style={{ cursor: 'pointer', color: '#29BB9C', textDecoration: 'none' }} to="/activities">
          <img className="ui small image" src="/static/platform/img/logo.png" />
        </Link>
        <LanguageToggleIcons style={flagStyle(device.size === 'mobile')} />
      </div>
      <div
        style={{
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isCreditCodeActivated ? (
          <RescheduleConfirmation creditCode={creditCode} saveCreditCodeDetails={saveCreditCodeDetails} />
        ) : (
          <RescheduleForm saveCreditCodeDetails={saveCreditCodeDetails} />
        )}
      </div>
    </>
  );
};

const headerStyle = (isMobile: boolean): React.CSSProperties => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: isMobile ? 'center' : 'flex-start',
  width: '100%',
  margin: '1rem',
  flexDirection: isMobile ? 'column-reverse' : 'row',
});

const flagStyle = (isMobile: boolean): React.CSSProperties => ({
  display: 'flex',
  marginRight: '2rem',
  justifyContent: 'flex-end',
  width: isMobile ? '100%' : 'auto',
});
