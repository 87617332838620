import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { TokenResult } from '@stripe/stripe-js';
import React, { useContext, useEffect, useRef } from 'react';
import { Field, Form, MarketplaceLibrary as Lib, SubmitButton, getStripeInstance } from '../_dependencies';
import { useLocale } from '../_locales';
import { MarketplaceContext } from '../marketplace.context';
import { WidgetDataContext } from '../widgetDataProvider';
import { PaymentTotalComponent } from './paymentTotalComponent';

interface Props {
  onSubmit?: (resolve: () => void, reject: () => void, token?: TokenResult) => void;
  cart: Lib.CartItem[];
}

export function PaymentMethodComponent(props: Props & { apiKey: string }) {
  const { leftToPayAfterDiscount } = useContext(MarketplaceContext);
  const disabled = leftToPayAfterDiscount <= 0;
  if (customElements && customElements.get('adventurehero-widget')) {
    return <SlottedPaymentComponent {...{ ...props, disabled }} />;
  }

  const stripePromise = getStripeInstance();

  return (
    <Elements stripe={stripePromise}>
      <CardPaymentComponent {...{ ...props, disabled }} />
    </Elements>
  );
}

function CardPaymentComponent(props: Props & { disabled: boolean }) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useLocale();
  const { disabled } = props;

  async function onSubmit(_, resolve: () => void, reject: () => void) {
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement && !disabled) {
      // TODO: Handle this
      return;
    }
    let tokenResult: TokenResult | undefined;
    if (!disabled && cardNumberElement) {
      tokenResult = await stripe.createToken(cardNumberElement);
    }

    props.onSubmit && props.onSubmit(resolve, reject, tokenResult);
  }

  return (
    <Form onSubmit={onSubmit}>
      {!disabled && (
        <>
          <Field label={t('Card number')}>
            <div className={`ui segment`} style={asInput}>
              <CardNumberElement options={createOptions()} />
            </div>
          </Field>
          <Field label={t('Expiration date')}>
            <div className={`ui segment`} style={asInput}>
              <CardExpiryElement options={createOptions()} />
            </div>
          </Field>
          <Field label={t('CVC')}>
            <div className={`ui segment`} style={asInput}>
              <CardCvcElement options={createOptions()} />
            </div>
          </Field>
        </>
      )}
      <PaymentTotalComponent items={props.cart} />
      <SubmitButton className="fluid green">{t('Complete purchase')}</SubmitButton>
    </Form>
  );
}

function SlottedPaymentComponent(props: Props & { apiKey: string; disabled: boolean }) {
  const widgetData = useContext(WidgetDataContext);
  const { t } = useLocale();
  const { disabled } = props;

  // These handlers are updated each request
  const stripeSubmitSuccessHandler = useRef<(data: any) => void>(() => {
    console.warn('No stripe-submit-successful hook set.');
  });
  const stripeSubmitFailedHandler = useRef<(err: any) => void>(() => {
    console.warn('No stripe-submit-failed hook set.');
  });

  // Add events handlers in the shadow dom
  useEffect(() => {
    if (widgetData.root == null) {
      throw new Error('No shadow root found');
    }
    widgetData.root.addEventListener('stripe-submit-successful', (data) => stripeSubmitSuccessHandler.current(data));
    widgetData.root.addEventListener('stripe-submit-failed', (err) => stripeSubmitFailedHandler.current(err));

    const event = new CustomEvent('mount-stripe', {
      detail: props.apiKey,
    });
    widgetData.root.dispatchEvent(event);
  }, []);

  function onSubmit(_, resolve, reject) {
    // Set the current event handlers, avoids multible events being registerd
    stripeSubmitSuccessHandler.current = ({ detail: response }: any) => {
      const token = disabled ? undefined : response;
      props.onSubmit && props.onSubmit(resolve, reject, token);
    };
    stripeSubmitFailedHandler.current = (error: any) => {
      // TODO Show some kind of error message
      console.error(error);
      reject();
    };

    // Dispatch submit stripe event
    if (widgetData.root == null) {
      throw new Error('No shadow root found');
    }
    const event = new CustomEvent('submit-stripe');
    widgetData.root.dispatchEvent(event);
  }

  return (
    <Form onSubmit={onSubmit.bind(this)}>
      {!disabled && (
        <>
          <Field label={t('Card number')}>
            <div className={`ui segment`} style={asInput}>
              <slot name="widget-card-number"></slot>
            </div>
          </Field>
          <Field label={t('Expiration date')}>
            <div className={`ui segment`} style={asInput}>
              <slot name="widget-card-expiry"></slot>
            </div>
          </Field>
          <Field label={t('CVC')}>
            <div className={`ui segment`} style={asInput}>
              <slot name="widget-card-cvc"></slot>
            </div>
          </Field>
        </>
      )}
      <PaymentTotalComponent items={props.cart} />
      <SubmitButton className="fluid green">{t('Complete purchase')}</SubmitButton>
    </Form>
  );
}

const asInput: React.CSSProperties = {
  margin: 0,
  padding: '0.75em 1em',
  boxShadow: 'none',
};

const createOptions = (padding?: string) => {
  return {
    style: {
      base: {
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        ...(padding ? { padding } : {}),
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};
