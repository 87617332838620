import React, { useEffect, useState } from 'react';
import {
  BookingDocument,
  Checkbox,
  getAccessories,
  getTickets,
  MarketplaceResource,
  PaymentResource,
  visitors,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';

interface Props {
  booking: BookingDocument;
}

export const PrintableBookingRow = ({ booking }: Props) => {
  const { t, tt } = useLocale();
  const { id, bookedVisitors, customer, priceCategories, totalPrice } = booking;
  const [isLoading, setisLoading] = useState(true);
  const [paid, setPaid] = useState(0);
  const [actualTotalPrice, setActualTotalPrice] = useState(0);
  const leftToPay = actualTotalPrice - paid;

  useEffect(() => {
    (async () => {
      // For marketplace payments
      const marketplaceInfo = await new MarketplaceResource().getPaidAmountForProduct(id);
      const {
        amountPaidManually: marketManually,
        amountPaidWithGiftCard: marketGiftcard,
        amountPaidWithStripe: marketStripe,
        productValue,
      } = marketplaceInfo;

      // For legacy payments (pre-marketplace)
      // Note, this should be built into the marketplace resource backend logic
      const legacyPaymentInfo = await new PaymentResource().getPaidAmountOfBooking(id);
      const {
        amountPaidManually: legacyManually,
        amountPaidWithGiftCard: legacyGiftcard,
        amountPaidWithStripe: legacyStripe,
      } = legacyPaymentInfo;

      // Add all possible payment and set to state
      const manuallyPaid = legacyManually + marketManually;
      const giftcardPaid = legacyGiftcard + marketGiftcard;
      const stripePaid = legacyStripe + marketStripe;

      setPaid(manuallyPaid + giftcardPaid + stripePaid);
      setActualTotalPrice(productValue ?? totalPrice);
      setisLoading(false);
    })();
  }, []);

  const categoriesCount = getTickets(priceCategories)
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map(({ name, ...pc }) => visitors(pc) + tt(name)[0].toLowerCase())
    .join('');

  const accessoriesCount = getAccessories(priceCategories)
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map(({ name, ...pc }) => pc.tickets + tt(name)[0].toLowerCase())
    .join('');

  const getPaymentStatus = () => {
    if (isLoading) return <i className="ui inline active mini loader" />;
    if (leftToPay <= 0) return t('Paid').toUpperCase();
    return `${leftToPay} ${t('SEK')}`;
  };

  return (
    <div style={{ display: 'flex', marginBottom: '.5rem', columnGap: '.5rem' }}>
      <span style={{ flex: 1, minWidth: '3rem' }}>
        {bookedVisitors} {t('pcs')}
      </span>
      <span style={{ flex: 3 }}>
        {customer.firstname} {customer.lastname}
      </span>

      <span style={{ flex: 2 }}>{categoriesCount}</span>
      <span style={{ flex: 2 }}>{accessoriesCount}</span>
      <span
        style={{
          flex: 2,
          whiteSpace: 'nowrap',
          display: 'flex',
          gap: '.5rem',
          justifyContent: 'flex-end',
        }}
      >
        {getPaymentStatus()}
        <Checkbox onCheckedOrUncheked={() => {}} style={{ marginRight: '-.5rem' }} />
      </span>
    </div>
  );
};
