import * as React from 'react';
import type Stripe from 'stripe';
import { globalT } from '../../../../../_locales';

export function stripeErrorToComponent(err: Stripe.Account.Requirements.Error): JSX.Element | null {
  const { code } = err;
  const t = globalT;

  switch (code) {
    case 'verification_document_corrupt':
    case 'verification_document_failed_copy':
    case 'verification_document_failed_greyscale':
    case 'verification_document_incomplete':
    case 'verification_document_not_readable':
    case 'verification_document_not_uploaded':
    case 'verification_document_missing_back':
    case 'verification_document_missing_front':
    case 'verification_document_too_large':
      // The upload failed due to a problem with the file itself.
      // Ask your user to provide a new file that meets these requirements:
      // - Color image(8000x8000px or smaller)
      // - 10MB or less
      // - For identity documents, can be JPG or PNG format
      // - Fro address or legal entity documents, can be JPG, PNG or PDF format
      return <span>{t('The document could not be read by Stripe...')}</span>;
    case 'verification_document_country_not_supported':
    case 'verification_document_invalid':
    case 'verification_document_type_not_supported':
      // The provided file is not an accetable form of ID form a supported country.
      // Ask your user to provide a new file that meets requirements. For a list
      // see https://stripe.com/docs/connect/identity-verification-api#acceptable-verification-documents
      return <span>{t('The document is not valid in Sweden...')}</span>;
    case 'verification_failed_other':
    case 'verification_document_failed_other':
      // Your team may contact Stripe to learn more about why identification failed.
      return <span>{t('Unknown error. Please contact Adventure Hero')}</span>;
    case 'verification_document_expired':
      // The document is expired. If it’s an identity document, its expiration date
      // must be after the date the document was submitted. If it’s an address document,
      // the issue date must be within the last six months.
      return <span>{t('The document has expired...')}</span>;
    case 'verification_failed_keyed_identity':
      // The name on the account couldn't be verified.
      // Ask your user to verify that they have provided their full legal name and
      // to also provide a photo ID matching that name.
      return <span>{t('The name on the document does not match the name...')}</span>;
    case 'verification_document_name_mismatch':
    case 'verification_document_dob_mismatch':
    case 'verification_document_address_mismatch':
    case 'verification_document_id_number_mismatch':
    case 'verification_document_photo_mismatch':
      // The information on the ID document doesn't match the information provided by the
      // user. Ask your user to verify and correct the provided information on the account.
      return <span>{t('The information that your have entered does not match...')}</span>;
    case 'verification_document_fraudulent':
    case 'verification_document_manipulated':
      // The document might have been altered so it could not be verified.
      // Your team may contact Stripe to learn more.
      return <span>{t('The document has beed modified in some way...')}</span>;
    case 'verification_failed_keyed_match':
    case 'verification_failed_document_match':
      // The information on the account couldn't be verified. Your user can either upload a
      // document to confirm their account details,
      // or update their information on their account.
      return <span>{t('Could not verify the document...')}</span>;
    case 'verification_failed_id_number_match':
    case 'verification_failed_name_match':
    case 'verification_failed_address_match':
      /* The information on the document doesn't match the information provided by the */
      /* user. Ask your user to verify and correct the provided information on the account, */
      /* or upload a document with information that matches the account. */
      // FIXME This matches even when user has not submitted any indentification....
      return <span>{t('The information that your have entered does not match...')}</span>;
    case 'verification_document_address_missing':
    case 'verification_document_id_number_missing':
    case 'verification_document_name_missing':
      /* The uploaded document is missing a required field. Ask your user to upload another */
      /* document that contains the missing field. */
      return <span>{t('The docuemnt is missing important information...')}</span>;
  }

  // Default case
  if (MODULE_RUNTIME_TIER != 'master') {
    console.debug(`DEBUG: ${err.code} saknas`);
  }
  return <span>{t('Unknown error. Please contact Adventure Hero')}</span>;
}
