import Lodash from 'lodash';
import { toJS } from 'mobx';
import { Types } from 'mongoose';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Alert,
  GlobalsContext,
  OrganizationDocument,
  OrganizationResource,
  SessionContext,
  Table,
  UserDocument,
  UserResource,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import OrganizationInfoRow from './tableRow';

const Organizations = () => {
  const [organizations, setOrganizations] = useState<OrganizationDocument[]>([]);
  const { t } = useLocale();
  const session = useContext(SessionContext);
  const globals = useContext(GlobalsContext);

  const suspendOrganization = async (organizationId: Types.ObjectId) => {
    try {
      if (await new OrganizationResource().suspendOrganization(organizationId)) {
        setOrganizations((prevState) => {
          const nextState = Lodash.merge({}, prevState);
          const organization = Lodash.find(nextState || [], (organization) => organization._id === organizationId);
          if (organization) {
            organization.isSuspended = true;
          }
          return nextState;
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const unSuspendOrganization = async (organizationId: Types.ObjectId) => {
    try {
      if (await new OrganizationResource().unSuspendOrganization(organizationId)) {
        setOrganizations((prevState) => {
          const nextState = Lodash.merge({}, prevState);
          const organization = Lodash.find(nextState || [], (organization) => organization._id === organizationId);
          if (organization) {
            organization.isSuspended = false;
          }
          return nextState;
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const organizations = await new OrganizationResource().getPopulatedAdminsAndAgreements();
        setOrganizations(organizations);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const loginAsAccount = async (organizationId: Types.ObjectId): Promise<void> => {
    const resource = new UserResource();
    const user = toJS(globals.session.currentUser) as UserDocument;
    const systemOwner = user.roles.find((role) => role.type == 'system.owner');
    const orgManager = user.roles.find((role) => role.type == 'organization.manager');

    try {
      // Make sure org manager and system owner roles exists
      // TODO: Is the orgmanager role really need or can it be created ?
      if (!orgManager || !systemOwner) throw new Error('Missing roles');

      if (!systemOwner.originalOrganization) {
        // Populate systemOwner with originalOrganization
        systemOwner.originalOrganization = orgManager.organization;
      }

      // Populate orgManager.organization with new org id
      orgManager.organization = organizationId;
      user.organization = organizationId;

      await resource.updateDocument(resource.createDocument(user));
      const organization = await new OrganizationResource().get(organizationId);
      user.organization = organization as any;

      // Authenticate with new documents
      globals.session.authenticateWithDocument(user, organization);
      session.authenticate(); // Also update the new session context
      globals.session.navigateTo('/dashboard');
    } catch (error) {
      Alert.show(t('components.admin.organizations.index.loginAccountWarning'));
      console.error('Error trying to log in as account', error);
    }
  };

  const logBackToOriginalAccount = async (): Promise<void> => {
    const resource = new UserResource();
    const user = toJS(globals.session.currentUser) as UserDocument;
    const systemOwner = user.roles.find((role) => role.type == 'system.owner');
    const orgManager = user.roles.find((role) => role.type == 'organization.manager');

    try {
      // Make sure org manager and system owner roles exists
      // NOTE: Is the orgmanager role really need or can it be created ?
      if (!orgManager || !systemOwner) throw new Error('Missing roles');
      // Check for original org
      if (!systemOwner.originalOrganization) throw new Error('No original organization to log back in to');

      orgManager.organization = systemOwner.originalOrganization;
      await resource.updateDocument(resource.createDocument(user));
      const organization = await new OrganizationResource().get(systemOwner.originalOrganization);

      user.organization = organization as any;
      // Authenticate with new documents
      globals.session.authenticateWithDocument(user, organization);
    } catch (error) {
      Alert.show(t('components.admin.organizations.index.loginAccountWarning'));
      console.error('Error trying to logout from account', error);
    }
  };

  return (
    <div style={{ padding: '1em' }}>
      <h1 style={{ padding: '1em 1em 0em 0em', float: 'right' }}>
        {t('Organizations')}&nbsp;
        <i className="university icon" />
      </h1>
      <div className="ui content" style={{ paddingTop: '6em' }}>
        <Table
          initSortColumn="name"
          dataList={organizations}
          headers={[
            {
              title: <i className="ui newspaper outline icon" />,
            },
            {
              title: t('Organization'),
              sortBy: 'name',
            },
            {
              title: t('Activation'),
            },
            {
              title: t('Bookings'),
              defaultSortDirection: 'desc',
            },
            {
              title: t('Badges'),
            },
            {
              title: '', // Empty header for user actions
            },
            {
              title: '', // Empty header for login as
            },
          ]}
          renderRow={(organization) => (
            <OrganizationInfoRow
              key={String(organization._id)}
              organization={organization}
              suspendOrganization={suspendOrganization}
              unSuspendOrganization={unSuspendOrganization}
              loginAsAccount={loginAsAccount}
              logoutFromAccount={logBackToOriginalAccount}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Organizations;
