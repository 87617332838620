import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';

import { PropertyImageResource } from './propertyImage.resource';

import { LocationDocument, LocationSchema } from '../schemas';
import SchemaNames from '../schemas/names';
import { TranslatableText, TranslatableTextSchema } from '../schemas/translatableText';

export interface PlaceDocument extends MongooseDocument {
  organization: Types.ObjectId;
  imageId: string;
  imageUrl: string;
  name: TranslatableText;
  description: TranslatableText;
  location: LocationDocument;
  tag?: string;
}

export class PlaceResource extends MongooseResource<PlaceDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Place);

    this.setSchema({
      organization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: true },
      name: { type: TranslatableTextSchema, required: true },
      description: { type: TranslatableTextSchema, required: true },
      imageId: { type: String, required: true },
      location: { type: LocationSchema, required: true },
      tag: { type: String, required: false },
    });

    this.addVirtualField('imageUrl', (document) =>
      document.imageId
        ? new PropertyImageResource().fileUrl(document.imageId, 'png')
        : '/static/platform/img/default_property.png',
    );
  }
}
