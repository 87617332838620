import React from 'react';
import { Input } from '../../../../_dependencies';
interface Props {
  placeholder: string;
  getSearchQuery: (query: string | undefined) => void;
}

export const InventorySearchComponent = ({ getSearchQuery, placeholder }: Props) => {
  return (
    <>
      <Input
        name="search"
        className="large fluid transparent"
        onChange={getSearchQuery}
        style={{ margin: '1rem 0rem 1rem 0rem' }}
        type="text"
        placeholder={placeholder}
        icon="search"
      />
      <div style={{ borderTop: 'solid lightgrey 1px' }} />
    </>
  );
};
