import { observer } from 'mobx-react';
import {
  ActivityOccuranceDocument,
  CalendarOccuranceDocument,
  NewActivityOccuranceDocument,
} from '../../../_dependencies';
import {
  ActivityEventComponentCollection,
  BookedActivityEventComponentCollection,
  DefaultEventComponentCollection,
  NewEventComponentCollection,
} from '../dashboardEventCollections';
import { CalendarStore } from '../store';

export class EventComponentFactory {
  private readonly store: CalendarStore;

  private readonly _collections = {
    new: new NewEventComponentCollection(),
    default: new DefaultEventComponentCollection(),
    booked: new BookedActivityEventComponentCollection(),
    activityWithBookingDetails: new ActivityEventComponentCollection(),
  };

  private chooseEventComponentSet(event: CalendarOccuranceDocument): DefaultEventComponentCollection {
    if (this.isANewOccuranceDocument(event)) {
      return this._collections.new;
    }

    if (this.isAnActivityOccuranceDocument(event)) {
      if (this.isABookedActivityOccuranceDocument(event)) {
        return this._collections.booked;
      }

      return this._collections.activityWithBookingDetails;
    }

    return this._collections.default;
  }

  private isANewOccuranceDocument(event: CalendarOccuranceDocument): event is NewActivityOccuranceDocument {
    return 'isNew' in event && event.isNew == true;
  }

  private isAnActivityOccuranceDocument(event: CalendarOccuranceDocument): event is ActivityOccuranceDocument {
    return 'isAnActivityOccurance' in event;
  }

  private isABookedActivityOccuranceDocument(event: CalendarOccuranceDocument): event is ActivityOccuranceDocument {
    return (
      this.isAnActivityOccuranceDocument(event) && 'bookings' in event && event.bookings && event.bookings.length > 0
    );
  }

  constructor(store: CalendarStore) {
    this.store = store;
  }

  public getEventProps = (event: CalendarOccuranceDocument, start: Date, end: Date, isSelected: boolean) => {
    return this.chooseEventComponentSet(event).getEventProperties(event, isSelected);
  };

  public selectedEventDetailsComponent = observer((props: { event: CalendarOccuranceDocument }) => {
    return this.chooseEventComponentSet(props.event).getEventDetailsComponent({
      store: this.store,
      event: props.event,
      title: props.event.title || '',
      selected: true,
    });
  });

  public eventComponent = observer((props: { event: CalendarOccuranceDocument; title: string }) => {
    return this.chooseEventComponentSet(props.event).getEventComponent(this.store.view, {
      store: this.store,
      event: props.event,
      title: props.title,
      selected: this.store.isEventSelected(props.event),
    });
  });
}
