import { runInAction } from 'mobx';
import Moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import * as ShortId from 'shortid';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeDocument,
  Alert,
  BookingDocument,
  BookingResource,
  DatePicker,
  Field,
  Form,
  GlobalsContext,
  PriceCategoryDocument,
  Rules,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { NotificationContext } from '../../../contexts/notification.context';
import { OffersContext } from '../../../contexts/offers.context';
import ActivityDropdown from '../../dashboard/bookings/filtering/ActivityDropdown';
import { PropertyStore } from '../../dashboard/properties/propertyStore';
import { CalendarStore } from '../store';
import { ManualBookingForm } from './manualBookingForm';

interface Props {
  event: ActivityOccuranceDocument;
  store: CalendarStore;
}

const BookingRequestForm = ({ event, store }: Props) => {
  const { t, tt } = useLocale();
  const { formatPriceCategoryList } = useContext(OffersContext);

  const { createBookingRequestNotification } = useContext(NotificationContext);
  const globals = useContext(GlobalsContext);

  const [selectedActivityType, setSelectedActivityType] = useState<ActivityTypeDocument>();

  const handleUpdatedStartTime = (start: Date) => {
    // Generate new start and end
    const h = Moment(start).hour();
    const min = Moment(start).minute();
    const newStart = Moment(event.start).hours(h).minutes(min).toDate();
    const newEnd = Moment(newStart).add(event.duration, 'minutes').toDate();

    event.end = newEnd;

    if (Moment(event.start).diff(newStart, 'minutes')) {
      event.start = newStart;
    }
  };

  useEffect(() => {
    if (selectedActivityType) {
      runInAction(() => {
        event.end = Moment(event.start).add(selectedActivityType.duration, 'minutes').toDate();
        event.priceCategories = selectedActivityType.priceCategories;
        event.visitorCapacity = selectedActivityType.visitorCapacity;
      });
    }
  }, [selectedActivityType, event.start, event.end]);

  const createBookingRequest = async (booking: BookingDocument, resolve, reject) => {
    const occuranceRequest = new ActivityOccuranceResource().createDocument(event);

    const priceCategoriesWithId: { id: string; priceCategory: PriceCategoryDocument }[] = [];

    if (selectedActivityType?.priceCategories.length) {
      const priceCategoriesFromProps: PriceCategoryDocument[] = selectedActivityType.priceCategories;
      priceCategoriesFromProps.forEach((category) => {
        priceCategoriesWithId.push({ id: ShortId.generate(), priceCategory: { ...category } });
      });
    }

    // populate the requested occurance with information
    if (selectedActivityType) {
      occuranceRequest.organization = selectedActivityType.organization;
      occuranceRequest.property = PropertyStore.Instance.property._id;
      occuranceRequest.originatingActivity = selectedActivityType._id;
      occuranceRequest.title = tt(selectedActivityType.title);
      occuranceRequest.visitorCapacity = selectedActivityType.visitorCapacity;
      occuranceRequest.minVisitors = selectedActivityType.minVisitors;
      occuranceRequest.neededStaffing = selectedActivityType.neededStaffing;
      occuranceRequest.start = event.start;
      occuranceRequest.end = event.end;
      occuranceRequest.bookingClosesAtDate = Moment(occuranceRequest.start)
        .subtract(selectedActivityType.bookingClosesBeforeEventInHours || 0, 'hours')
        .toDate();
      occuranceRequest.refundableUntilInHours = selectedActivityType.refundableUntilInHours;
      occuranceRequest.preparationStart = Moment(occuranceRequest.start)
        .subtract(selectedActivityType.preparationDuration || 0, 'minutes')
        .toDate();
      occuranceRequest.cleanupEnd = Moment(occuranceRequest.end)
        .add(selectedActivityType.cleanupDuration || 0, 'minutes')
        .toDate();
      occuranceRequest.priceCategories = formatPriceCategoryList(priceCategoriesWithId);
      occuranceRequest.bookingCapacity = selectedActivityType.bookingCapacity;

      // Save the new activity event

      try {
        await new ActivityOccuranceResource().updateDocument(
          new ActivityOccuranceResource().createDocument(occuranceRequest),
        );

        booking.organization = selectedActivityType.organization;
        await new BookingResource().updateDocument(booking);

        const document = await new BookingResource().get(booking._id);
        event.bookings ? event.bookings.push(document) : (event.bookings = [document]);

        await createBookingRequestNotification(globals.session.currentOrganizationId, booking.occurance);

        store.reload();
        return resolve();
      } catch (err) {
        Alert.show(t('booking-request-creation.message.error'), t('Error'), 'error');
        return reject(err);
      }
    }
  };

  // Since ManualBookingForm always returns an array we just pick out the first index
  const handleSetSelectedActivityType = (a: ActivityTypeDocument[]) => setSelectedActivityType(a[0]);

  return (
    <div>
      <h3>{t('create-booking-request')}</h3>
      <Form onSubmit={(_, resolve) => resolve && resolve()}>
        <Field label={t('Start')}>
          <DatePicker
            name="Start"
            type="time"
            icon="wait"
            defaultValue={event.start}
            rules={[Rules.NotEmpty('Välj en starttid')]}
            onChange={handleUpdatedStartTime}
            placeholder={t('Time')}
          />
        </Field>

        <Field label={t('Choose offer')}>
          <ActivityDropdown
            notEmpty
            onSelect={handleSetSelectedActivityType}
            organizationId={PropertyStore.Instance.property.organization}
          />
        </Field>
      </Form>
      {selectedActivityType && (
        <div>
          <span>
            <i className="ui ticket icon" /> {selectedActivityType.visitorCapacity} {t('available slots')}
            <br />
          </span>
          <span>
            <i className="ui hourglass full icon" /> {selectedActivityType.duration} {t('minutes')}
            <br />
          </span>
          <div style={{ marginTop: '3rem' }}>
            <h4>{t('enter-booking-info')}</h4>
            <ManualBookingForm
              requested
              submitButtonText={t('create-booking-request')}
              event={event}
              store={store}
              onSubmit={createBookingRequest}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingRequestForm;
