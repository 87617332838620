import Lodash from 'lodash';
import { computed, observable } from 'mobx';
import React from 'react';
import { MobxComponent, ActivityOccuranceDocument, UserDocument } from '../../../../_dependencies';
import { List } from './list';

export type AssignedEmployeesListActionType = 'remove-selected-from-working';
interface CommonProps {
  propertyEmployees: UserDocument[];
  event: ActivityOccuranceDocument;
}

export abstract class AbstractEmployeesList<Props> extends MobxComponent<Props & CommonProps> {
  @observable protected employeeSelectedForAction: UserDocument[] = [];
  protected abstract get filteredEmployeesList(): UserDocument[];
  protected abstract get emptyListMessage(): string;

  @computed protected get isAnyEmployeeSelected(): boolean {
    return !!this.employeeSelectedForAction.length;
  }

  @computed protected get listComponent() {
    return (
      <List
        employees={this.filteredEmployeesList}
        event={this.props.event}
        selected={this.employeeSelectedForAction}
        emptyListMessage={this.emptyListMessage}
        onSelected={this.onEmployeeSelected}
        onDeselected={this.onEmployeeDeselected}
      />
    );
  }

  protected onEmployeeSelected = (employee: UserDocument) => this.employeeSelectedForAction.push(employee);
  protected onEmployeeDeselected = (employee: UserDocument) =>
    Lodash.remove(this.employeeSelectedForAction, (staff) => staff == employee);

  UNSAFE_componentWillReceiveProps() {
    this.employeeSelectedForAction = [];
  }
}
