import React from 'react';
import { ErrorBoundary } from '../../../components/errorBoundary';
import { Map as MapComponent } from '../../../components/map';
import {
  ActivityTypeDocument,
  Button,
  CarouselMasthead,
  Consume,
  DeviceProvider,
  IDevice,
  KosmicComponent,
  LocaleContext,
  Platforms,
  PropertyActivityTypeTuple,
  PropertyDocument,
  ActivityCard,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { LanguageToggleIcons } from '../../languageToggleIcons';
import { ActivitiesSearchBar } from './activitiesSearchBar';
import { ActivitiesSearchProvider, IActivitySearchData } from './activitySearchProvider';

interface State {
  showMap: boolean;
  lastCollectedCardIndex: number;
  geoLocationPosition?: Coordinates;
  renderLocationDialog: boolean;
}

export class ActivitiesShowcase extends KosmicComponent<{ isFreelance?: boolean }, State> {
  state: State = {
    lastCollectedCardIndex: 0,
    showMap: false,
    renderLocationDialog: false,
  };

  private readonly pagingIncrement: number = 15;

  @Consume(ActivitiesSearchProvider.Context)
  private searchData: Readonly<IActivitySearchData>;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get geoLocationStatus() {
    return async () => {
      if (navigator.permissions) {
        const result = await navigator.permissions.query({ name: 'geolocation' });
        return result.state;
      }
    };
  }

  private get visibleProperties() {
    const { foundProperties } = this.searchData;
    return foundProperties.slice(0, Math.min(foundProperties.length || this.state.lastCollectedCardIndex));
  }

  private get visibleActivityOccurances() {
    const { foundOccurences } = this.searchData;
    return foundOccurences.slice(0, Math.min(foundOccurences.length || this.state.lastCollectedCardIndex));
  }

  private get hasPrintedAllCards() {
    if (Platforms.features.offersCanBeAssignedToEmployees) {
      return this.state.lastCollectedCardIndex > this.visibleProperties.length;
    } else {
      return this.state.lastCollectedCardIndex > this.visibleActivityOccurances.length;
    }
  }

  private async loadMoreCards() {
    this.setState({ lastCollectedCardIndex: this.state.lastCollectedCardIndex + this.pagingIncrement });
  }

  private renderGeoLocationDialog() {
    const { t } = this._locale;

    if (this.state.renderLocationDialog) {
      return (
        <div className="ui segment" style={absolute}>
          <span style={{ marginBottom: '.5rem', textAlign: 'center' }}>
            <i className="location arrow icon" />
            {t('Do you want to share your location?')}
          </span>
          <div style={geolocateButtonWrapper}>
            <Button onClick={() => this.setState({ renderLocationDialog: false })} style={{ marginRight: '1rem' }}>
              {t('No thanks')}
            </Button>
            <Button onClick={() => this.acceptGeoLocation()} className="green">
              {t('Accept')}
            </Button>
          </div>
        </div>
      );
    }
  }

  private acceptGeoLocation() {
    this.setState({ renderLocationDialog: false });

    // We can manage devices that doesn't have support here
    if (navigator.permissions) {
      this.getGeoLocationFromUser();
    }
  }

  private getGeoLocationFromUser() {
    navigator.geolocation.getCurrentPosition((location) => {
      this.setState({ geoLocationPosition: location.coords });
    });
  }

  async componentDidMount() {
    $('#showcase-container').visibility({
      context: $('#scroll-container'),
      once: false,
      observeChanges: true,
      offset: 600,
      onBottomVisible: this.loadMoreCards.bind(this),
      error: (err: Error | string) => console.log(err),
    } as any);

    const permissionStatus = await this.geoLocationStatus();
    if (permissionStatus === 'prompt') {
      this.setState({ renderLocationDialog: true });
    }

    if (permissionStatus === 'granted') {
      this.getGeoLocationFromUser();
    }
  }

  private renderLoadMoreCardsButton() {
    const { t } = this._locale;
    if (this.hasPrintedAllCards) {
      return null;
    }

    if (this.device.size === 'mobile') {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '1.5em' }}>
          <button className="ui green button" style={{ alignSelf: 'center' }} onClick={this.loadMoreCards.bind(this)}>
            {t('components.customer.activities.activitiesShowcase.loadMoreCardsButton')}
            <i className="arrow circle down icon" style={{ margin: 0, marginLeft: '5px' }} />
          </button>
        </div>
      );
    }
  }

  private renderNoCardsAvailableMessage() {
    const { t } = this._locale;
    return (
      <div className="ui one column centered grid">
        <div className="column">
          <h3 className="ui center aligned header" style={{ marginTop: '4em', marginBottom: '4em' }}>
            {t('components.customer.activities.activitiesShowcase.textOne')}
            <div className="sub header">{t('components.customer.activities.activitiesShowcase.textTwo')}</div>
          </h3>
        </div>
      </div>
    );
  }

  private renderCards() {
    const { propertyActivityPairs, isLoading } = this.searchData;

    if (!propertyActivityPairs.length && !isLoading) {
      return this.renderNoCardsAvailableMessage();
    }

    return propertyActivityPairs.map(({ property, activityType }) => (
      <ActivityCard
        userPosition={this.state.geoLocationPosition}
        key={`${property.id}${activityType.id}`}
        property={property}
        activityType={activityType}
      />
    ));
  }

  private renderCardsAndMap() {
    const { size, width } = this.device;
    if (size === 'desktop') {
      return (
        <div id="search-container" style={{ minHeight: '50vh' }} className="ui two column grid">
          <div
            id="activities-container"
            className="ui ten wide blurring column"
            style={{ paddingLeft: '3em', marginBottom: '3em' }}
          >
            <div className={`ui stackable ${width > 1700 ? 'three' : 'two'} column grid`}>{this.renderCards()}</div>
          </div>
          <div id="map-container" style={mapContainer} className="ui six wide column">
            <ErrorBoundary compact>
              <MapComponent
                userLocation={this.state.geoLocationPosition}
                propertyActivityMap={this.propertyActivityMap}
              />
              {this.renderGeoLocationDialog()}
            </ErrorBoundary>
          </div>
        </div>
      );
    }

    // Mobile
    if (this.state.showMap) {
      return (
        <div style={{ height: '100vh' }} className="ui grid">
          <div style={{ height: '100vh', padding: 0 }} className="ui column">
            <ErrorBoundary compact>
              <MapComponent propertyActivityMap={this.propertyActivityMap} />
            </ErrorBoundary>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ margin: '0px' }} className="ui grid">
          <div
            id="activities-container"
            className="ui column"
            style={{ paddingLeft: '2em', paddingRight: '2em', marginBottom: '3em' }}
          >
            <div className="ui stackable doubling three column grid">
              {this.renderCards()}
              {this.renderLoadMoreCardsButton()}
            </div>
          </div>
        </div>
      );
    }
  }

  get propertyActivityMap() {
    const reducer = (acc: PropertyActivtyMapAccumulator, v: PropertyActivityTypeTuple) => {
      if (acc.has(v.property.id)) {
        const item = acc.get(v.property.id)!;
        item.activityTypes.push(v.activityType);
        acc.set(v.property.id, item);
      } else {
        acc.set(v.property.id, {
          property: v.property,
          activityTypes: [v.activityType],
        });
      }
      return acc;
    };
    const reducedValuesMap = this.searchData.propertyActivityPairs.reduce(
      reducer,
      new Map() as PropertyActivtyMapAccumulator,
    );

    return Array.from(reducedValuesMap.values());
  }

  render() {
    const { size } = this.device;
    if (MODULE_ENVIRONMENT == 'node') {
      return <div className="ui active inline centered large loader" />;
    }

    return (
      <>
        <div style={{ position: 'relative' }}>
          {this.device.size === 'mobile' ? (
            <img className="ui fluid image" src="/static/platform/img/header_mobile.jpg" />
          ) : (
            <>
              {this.device.size === 'tablet' && (
                <div style={flagContainerStyle}>
                  <LanguageToggleIcons />
                </div>
              )}
              <CarouselMasthead
                imgList={Platforms.mastheadImageUrls}
                autoplaySpeed={3500}
                speed={1000}
                slidesToShow={1}
                slidesToScroll={1}
                centerMode
                centerPadding={'0px'}
                autoplay
                fade
                infinite
                arrows={false}
                draggable={false}
                pauseOnFocus={false}
                pauseOnHover={false}
                imageHeight="100vh"
                imageFilter="grayscale(50%)"
                logoUrl="/static/platform/img/logo_inverted.png"
                logoStyle={logoStyle}
              />
            </>
          )}
          <ActivitiesSearchBar searchData={this.searchData} />
        </div>

        <div id="showcase-container" style={{ marginTop: size == 'desktop' ? '4rem' : '3rem' }}>
          {this.renderCardsAndMap()}
        </div>
      </>
    );
  }
}

const mapContainer: React.CSSProperties = {
  height: '94vh',
  marginBottom: '1em',
  position: 'sticky',
  top: '5rem',
};
const absolute: React.CSSProperties = {
  position: 'absolute',
  zIndex: 75,
  top: '6rem',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 'calc(100% - 8rem)',
  maxWidth: '42rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
const geolocateButtonWrapper: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
};
const logoStyle: React.CSSProperties = {
  position: 'absolute',
  top: '8rem',
  left: '5%',
  width: '15rem',
  zIndex: 11,
};
const flagContainerStyle: React.CSSProperties = {
  position: 'absolute',
  top: '7rem',
  right: '2rem',
  zIndex: 10,
};

type PropertyActivtyMapAccumulator = Map<string, { property: PropertyDocument; activityTypes: ActivityTypeDocument[] }>;
