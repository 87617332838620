import Lodash from 'lodash';
import { action, observable, runInAction } from 'mobx';
import * as React from 'react';
import {
  ActivityOccuranceResource,
  Consume,
  DestinationResource,
  DeviceProvider,
  Dropdown,
  DropdownItem,
  IDevice,
  LocaleContext,
  ObservingComponent,
  OrganizationDocument,
  UserDocument,
  UserResource,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { Calendar, CalendarSidebar, CalendarStore } from '../calendar';
import DashboardTemplate from './dashboardTemplate';

export class ScheduleIndex extends ObservingComponent<{}, CalendarStore> {
  @observable private _employeesList: UserDocument[] = [];
  @observable private _selectedSchedule = 'allOccurances'; // default show all occurances..
  @observable private partOfDestination = false;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get title(): string {
    const { t } = this._locale;
    const scheduleString = t('Schedule');
    if (this._selectedSchedule == 'allOccurances') {
      if (this.globals.session.userHasRole({ type: 'property.manager' })) {
        return t('components.dashboard.schedule.overviewOfPersonnels');
      }
      return (this.globals.session.currentUser as UserDocument).genetiveFirstname + ' ' + scheduleString;
    }
    return Lodash(this._employeesList).find({ id: this._selectedSchedule })?.genetiveFirstname + ' ' + scheduleString;
  }

  private loadAllEmployees = () => {
    // allways query employees by organization
    let employeeQuery = {
      'roles.organization': this.globals.session.currentOrganizationId,
    };
    // if user is property manager then find employees within that/those properties only
    if (
      !this.globals.session.userHasRole({ type: 'organization.manager' }, true) &&
      this.globals.session.userHasRole({ type: 'property.manager' }, true)
    ) {
      employeeQuery = Lodash.merge(employeeQuery, {
        'roles.property': {
          $in: Lodash.map(this.globals.session.currentUser?.roles, (role) => role.property),
        },
      });
    }

    new UserResource().find(employeeQuery).then((users) => {
      runInAction(() => {
        this._employeesList = Lodash.sortBy(users, (user) => user.name);
      });
    });
  };

  @action private onEmployeeDropdownChanged = (employeeId: string) => {
    this.store.filterCalenderByEmployee = employeeId;
    this._selectedSchedule = employeeId;
  };

  private get employeeDropdown() {
    if (!this.globals.session.userHasRole({ type: 'property.manager' })) {
      return undefined;
    }

    const { t } = this._locale;

    const employeeDropdownItems = Lodash.map(this._employeesList, (employee) => (
      <DropdownItem key={employee.id} value={employee.id}>
        <img className="ui avatar image" src={employee.imageUrl} />
        {employee.name}
      </DropdownItem>
    ));

    return (
      <div style={{ paddingBottom: '1em' }}>
        <h4>{t('components.dashboard.schedule.headerOne')}</h4>
        <Dropdown fluid searchable name="user" value={this._selectedSchedule} onChange={this.onEmployeeDropdownChanged}>
          <DropdownItem value="allOccurances">
            <img className="ui avatar image" src="/static/commons/avatars/default.jpg" />
            {t('components.dashboard.schedule.headerTwo')}
          </DropdownItem>
          {employeeDropdownItems}
        </Dropdown>
      </div>
    );
  }

  async isInDestination() {
    const isCrosSelling = await new DestinationResource().isPartOfDestination(
      this.globals.session.currentOrganizationId,
    );
    this.partOfDestination = isCrosSelling;
  }

  componentDidMount() {
    if (this.globals.session.userHasRole({ type: 'property.manager' })) {
      this.loadAllEmployees();
      this.isInDestination();
    }
  }

  componentDefaultStore() {
    return new CalendarStore({
      isTimeSelectable: false,
      resource: new ActivityOccuranceResource(),
      defaultView: 'week',
      endpoint: new ActivityOccuranceResource().getOrganizationAndCrossSellingActivities,
      showPropertyForSelectedEvent: true,
    });
  }

  render() {
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { features } = organization.flags;
    if (!features.bookings || !features.scheduling) {
      this.globals.session.navigateTo('/dashboard');
      return null;
    }
    const { size } = this.device;

    return (
      <DashboardTemplate title={this.title} icon="calendar outline icon">
        <div style={{ height: '-webkit-fit-content' }}>
          {size == 'desktop' ? (
            <div className="ui grid" style={{ margin: 0 }}>
              <div className="eleven wide column" style={{ paddingRight: 0 }}>
                <Calendar store={this.store} />
              </div>
              <div className="five wide column" style={{ padding: 0 }}>
                <CalendarSidebar store={this.store} showCrossSellingFilter={this.partOfDestination}>
                  {this.employeeDropdown}
                </CalendarSidebar>
              </div>
            </div>
          ) : (
            <div className="ui grid" style={{ margin: 0 }}>
              <div className="sixteen wide column" style={{ padding: 0 }}>
                <CalendarSidebar store={this.store} showCrossSellingFilter={this.partOfDestination}>
                  {this.employeeDropdown}
                </CalendarSidebar>
              </div>
              <div className="sixteen wide column" style={{ padding: size === 'mobile' ? 0 : undefined }}>
                <Calendar store={this.store} />
              </div>
            </div>
          )}
        </div>
      </DashboardTemplate>
    );
  }
}
