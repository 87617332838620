//NOTE: Do NOT use any capital letters in schema names
enum SchemaNames {
  Accessability = 'accessability',
  ActivityImage = 'activityimage',
  ActivityOccurance = 'activityoccurance',
  ActivityType = 'activitytype',
  Agreement = 'agreement',
  AvailableActivityTimeSlot = 'available-activity-time-slot',
  AvailableOfferOnProperty = 'available-offers-on-property',
  Booking = 'booking',
  Badge = 'badge',
  BadgeImage = 'badgeimage',
  Curamusian = 'curamusian',
  Default = 'default',
  Destination = 'destination',
  Discount = 'discount',
  EmployeeAssignment = 'employee-assignment',
  Error = 'thrown-error',
  GiftCard = 'giftcard',
  GiftCardCharge = 'giftcardcharge',
  GiftCardImage = 'giftcardimage',
  GiftCardType = 'giftcardtype',
  Mail = 'mail',
  Notification = 'notification',
  Occurance = 'occurance',
  Organization = 'organization',
  Payment = 'payment',
  Place = 'place',
  Property = 'property',
  PropertyImage = 'propertyimage',
  Reservation = 'reservation',
  Schedule = 'schedule',
  ScheduledTimeSlot = 'scheduled-time-slot',
  ServiceMessage = 'service-message',
  User = 'user',
  UserAvatar = 'user-avatar',
  Marketplace = 'marketplace',
  Inventory = 'inventory',
  InventoryEntity = 'inventoryentity',
  InventoryStatus = 'inventorystatus',
  InventoryTransfer = 'inventorytransfer',
  AutomaticMailing = 'automatic-mailing',
  Tickets = 'tickets',
}
//NOTE: Do NOT use any capital letters in schema names
export default SchemaNames;
