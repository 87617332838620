import * as React from 'react';
import { Button, Consume, DeviceProvider, IDevice, Link, LocaleContext, MobxComponent } from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { NavigationBar, NavigationItem } from '../navigation';
import { CustomerFooter } from './footer';
import { CustomerTopMenu } from './topMenu';

export class CustomerTemplate extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  private get topScreenContent() {
    const { t } = this._locale;
    if (this.device.size === 'mobile') {
      return (
        <div style={mobileTopDivStyle}>
          <Link to="/dashboard">
            <button className={'ui tiny button'} style={{ marginRight: '0.5rem' }}>
              {' '}
              {this.globals.session.currentUser ? t('Go to dashboard') : t('Log in')}{' '}
            </button>
          </Link>
          {!this.globals.session.currentUser && (
            <Link to="/sign-up">
              <button className={'ui tiny primary button'}> {t('Join us')} </button>
            </Link>
          )}
        </div>
      );
    }

    const { pathname } = this.globals.session.currentLocation;

    const linkClassname = (path: string) => (pathname.startsWith(path) ? 'item active' : 'item');

    return (
      <div>
        <CustomerTopMenu>
          <div className="ui green secondary pointing menu" style={menuStyle}>
            <Link to="/activities" className={linkClassname('/activities')} style={linkStyle}>
              {t('Activities')}
            </Link>
            <Link to="/properties" className={linkClassname('/properties')} style={linkStyle}>
              {t('Properties')}
            </Link>
            <Link to="/giftcards" className={linkClassname('/giftcards')} style={linkStyle}>
              {t('Giftcard')}
            </Link>
            <Link to="/dashboard" className={linkClassname('/dashboard')} style={linkStyle}>
              {t('Log in')}
            </Link>
            <Link to="/sign-up" className="ui small primary button" style={buttonLinkStyle}>
              {t('Join us')}
            </Link>
          </div>
        </CustomerTopMenu>

        {!pathname.startsWith('/activities') && <div style={{ marginTop: '10rem' }} />}
      </div>
    );
  }

  private get navigationBarItems(): JSX.Element[] {
    const { t } = this._locale;
    return [
      <NavigationItem link="/activities" icon="search" title={t('components.customer.template.linkSix')} key="0" />,
      <NavigationItem link="/properties" icon="map pin" title={t('Properties')} key="1" />,
      <NavigationItem link="/giftcards" icon="gift" title={t('components.customer.template.linkSeven')} key="2" />,
      <NavigationItem link="/about" icon="child" title={t('About us')} key="3" />,
    ];
  }

  render() {
    return (
      <NavigationBar position="bottom" items={this.navigationBarItems} mobile>
        {this.topScreenContent}
        {this.props.children}
        <CustomerFooter />
      </NavigationBar>
    );
  }
}

const menuStyle: React.CSSProperties = {
  alignItems: 'center',
  borderBottomColor: 'transparent',
};

const linkStyle: React.CSSProperties = {
  textTransform: 'uppercase',
};

const buttonLinkStyle: React.CSSProperties = {
  textTransform: 'uppercase',
  marginLeft: '1rem',
  whiteSpace: 'nowrap',
};

const mobileTopDivStyle: React.CSSProperties = {
  position: 'fixed',
  top: '1rem',
  right: '1rem',
  zIndex: 100,
};
