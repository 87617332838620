import React, { useEffect, useRef, useState } from 'react';
import { Rules, TextFormatRule, TranslatableText, useValidation } from '../_dependencies';
import { useLocale } from '../_locales';

type Props = {
  name: string;
  placeholder?: TranslatableText | string;
  icon?: string;
  label?: string;
  value?: TranslatableText | string;
  onChange: (object: TranslatableText) => void;
  onLocaleChange?: (locale: Locale) => void;
  textarea?: boolean;
  rules?: TextFormatRule[];
};

const defaultObject = (defaultText?: string | TranslatableText): TranslatableText => {
  if (typeof defaultText === 'string') {
    return {
      sv: defaultText,
      en: '',
      de: '',
    };
  }

  return (
    defaultText || {
      sv: '',
      en: '',
      de: '',
    }
  );
};

/**
 * TranslatableTextField renders a field that is meant to be used
 * to create or edit @type {TranslatableText}.
 */
export const TranslatableTextField = (props: Props) => {
  const { t } = useLocale();
  const rules = props.rules || [];
  const maxLengthRule = props.textarea ? Rules.MaximumLength(2000) : Rules.MaximumLength(100);

  const addValueSv = useValidation(
    `${props.name}-sv`,
    ...rules,
    Rules.NotEmpty(t('You must have a swedish translation')),
    maxLengthRule,
  );
  const addValueEn = useValidation(`${props.name}-en`, ...rules, maxLengthRule);
  const addValueDe = useValidation(`${props.name}-de`, ...rules, maxLengthRule);
  const [object, _setObject] = useState(defaultObject(props.value));
  const [locale, setLocale] = useState<keyof TranslatableText>('sv');
  const dropdown = useRef<HTMLDivElement>(null);
  const styles = generateStyles(props.textarea);
  const placeholder = (typeof props.placeholder === 'object' ? props.placeholder[locale] : props.placeholder) || '';

  // Sets object state. Also calls on change for controlled use and
  // updates the form context with new values for uncontrolled use.
  const setObject = (object: TranslatableText) => {
    props.onChange(object);
    _setObject(object);
    addValueSv(object['sv']);
    addValueEn(object['en']);
    addValueDe(object['de']);
  };

  // Sync local value state with props
  useEffect(() => {
    _setObject(defaultObject(props.value));
  }, [props.value]);

  // Create / recreate the Semantic UI dropdown from ref.
  // This also makes sure that the locale state is updated
  // when the dropdown changes.
  useEffect(() => {
    if (dropdown.current) {
      $(dropdown.current).dropdown({
        onChange: (value) => {
          setLocale(value);
          props.onLocaleChange && props.onLocaleChange(value);
        },
      });
    }
  }, [dropdown]);

  // Translations for placeholders
  const translations = {
    sv: t('Swedish'),
    en: t('English'),
    de: t('German'),
  };

  // List of all flags that should be used
  const flags = Object.keys(object).map((locale) => {
    const label = props.textarea ? translations[locale] : locale;
    return (
      <div data-value={locale} key={`flag.${locale}`} style={styles.flags.wrapper}>
        <img
          loading="lazy"
          src={`https://adventurehero.se/static/platform/img/flags/${locale}.png`}
          alt={translations[locale]}
          className="ui mini image"
          style={styles.flags.image}
        />
        <span>{label}</span>
      </div>
    );
  });

  // List of dropdown items that the user can choose from
  // when creating the translations.
  const dropdownItems = flags.map((flag) => (
    <div
      className={!object.sv.length && flag.props['data-value'] !== 'sv' ? `item disabled` : `item`}
      key={flag.props['data-value']}
      data-value={flag.props['data-value']}
    >
      {flag}
    </div>
  ));

  const inputElement = (
    <input
      type="text"
      name={`${props.name}-${locale}`}
      placeholder={`${placeholder} (${translations[locale]})`}
      value={object[locale]}
      onChange={(e) => setObject({ ...object, [locale]: e.target.value })}
    />
  );

  const textAreaElement = (
    <textarea
      style={styles.textArea}
      name={`${props.name}-${locale}`}
      placeholder={`${placeholder} (${translations[locale]})`}
      onChange={(e) => setObject({ ...object, [locale]: e.target.value })}
      value={object[locale]}
    />
  );

  return (
    <div className="ui field">
      {props.label && <label>{props.label}</label>}
      <div className={`ui ${props.icon ? 'right icon' : ''} left labeled input`} style={styles.inputWrapper}>
        <div ref={dropdown} className="ui dropdown label" style={styles.dropDown}>
          <div className="text">{flags[0]}</div>
          <i className="dropdown icon"></i>
          <div className="menu">{dropdownItems}</div>
        </div>
        {props.textarea ? textAreaElement : inputElement}
        {props.icon && <i className={`ui ${props.icon}`} />}
        <input type="text" readOnly name={`${props.name}-sv`} value={object['sv']} style={styles.hiddenInput} />
      </div>
    </div>
  );
};

const generateStyles = (textarea: Props['textarea']) => ({
  inputWrapper: {
    position: 'relative',
  } as React.CSSProperties,
  flags: {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    } as React.CSSProperties,
    image: {
      marginRight: '.6em',
      width: '2.5em',
    } as React.CSSProperties,
  },
  dropDown: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: textarea ? 'space-between' : undefined,
    alignSelf: textarea ? 'flex-start' : undefined,
    position: textarea ? 'absolute' : undefined,
    right: textarea ? 0 : undefined,
    left: textarea ? 0 : undefined,
    height: textarea ? '2.2rem' : 'auto',
    borderTopRightRadius: textarea ? '.28rem' : undefined,
    borderBottomLeftRadius: textarea ? '0' : undefined,
    fontSize: '.9em',
  } as React.CSSProperties,
  hiddenInput: {
    display: 'none',
  } as React.CSSProperties,
  textArea: {
    paddingTop: '2.8rem',
  } as React.CSSProperties,
});
