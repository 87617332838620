import { TranslatableText } from '../schemas';

/** Get default message for giftcard mail */
export function getGiftCardMailDefaultMessage(link: string): TranslatableText {
  return {
    sv: `Hoppas vi ses snart och kom ihåg att ta med presentkortet eller presentkortsnumret. Det går också bra att använda presentkortet när du gör en bokning via vår hemsida eller via ${link}.`,
    de: `Wir hoffen, Sie bald zu sehen und bitte denken Sie daran, Ihre Geschenkkarte oder Geschenkkartencode mitzubringen. Sie können die Geschenkkarte auch bei einer Reservierung über unsere Website oder über ${link}.`,
    en: `We hope to see you soon and please remember to bring your gift card or gift card code. You can also use the gift card when making a reservation through our website or through ${link}.`,
  };
}

export function getGiftCardMailExtra(giftCardNumber: string, locale: Locale): string {
  return {
    sv: `Presentkortsnumret: ${giftCardNumber}`,
    de: `Gutscheincode: ${giftCardNumber}`,
    en: `Giftcard number: ${giftCardNumber}`,
  }[locale];
}

export function getGiftCardTitle(firstname: string, locale: Locale): string {
  const messages = getMessages(locale);
  return {
    sv: `Hej ${firstname + '. ' + messages.subject}`,
    en: `Hi ${firstname + '. ' + messages.subject}`,
    de: `Hallo ${firstname + '. ' + messages.subject}`,
  }[locale];
}

export interface Messages {
  subject: string;
  short: string;
  link: string;
  alt: string;
  instructions: string;
}
export function getMessages(locale: Locale): Messages {
  switch (locale) {
    case 'sv':
      return {
        subject: `Ha så kul med ditt nya presentkort!`,
        short: `Ditt presentkortsnummer är`,
        link: `Visa presentkortet`,
        alt: `Presentkortet kunde inte visas`,
        instructions: `Om presentkortet inte kunde visas kan du öppna det i en webbläsare genom att klicka på knappen nedan.`,
      };
    case 'de':
      return {
        subject: `Genießen Sie Ihre Geschenkkarte!`,
        short: `Ihre Geschenkkartennummer ist`,
        link: `Zeige meine Geschenkkarte im Browser`,
        alt: `Die Geschenkkarte konnte nicht angezeigt werden. Bitte folgen Sie stattdessen dem Link des Browsers`,
        instructions: `Wenn die Geschenkkarte nicht angezeigt werden konnte, können Sie sie in einem Browser öffnen, indem Sie auf die Schaltfläche unten klicken.`,
      };
    default:
    case 'en':
      return {
        subject: `Enjoy your gift card!`,
        short: `Your gift card number is`,
        link: `Show my gift card in the browser`,
        alt: `The gift card could not be displayed, please follow the browser link instead`,
        instructions: `If the gift card could not be displayed, you can open it in a browser by clicking the button below.`,
      };
  }
}
