export * from './accessability.resource';
export * from './activityImage.resource';
export * from './activityOccurance.resource';
export * from './activityType.resource';
export * from './agreement.resource';
export * from './automaticMailing.resource';
export * from './availableActivityTimeSlot.resource';
export * from './availableOfferOnProperty.resource';
export * from './badge.resource';
export * from './badgeImage.resource';
export * from './booking.resource';
export * from './calendarOccurance.resource';
export * from './destination.resource';
export * from './discount.resource';
export * from './employeeAssignment.resource';
export * from './error.resource';
export * from './europeanCountryCodes';
export * from './giftCard.resource';
export * from './giftCardCharge.resource';
export * from './giftCardImage.resource';
export * from './giftCardType.resource';
export * from './inventory.resource';
export * from './inventoryEntity.resource';
export * from './inventoryStatus.resource';
export * from './inventoryTransfer.resource';
export * from './mail.resource';
export * from './mail.templates';
export * from './marketplace.resource';
export * from './notification.resource';
export * from './organization.resource';
export * from './payment.resource';
export * from './place.resource';
export * from './platforms';
export * from './property.resource';
export * from './propertyImage.resource';
export * from './reservation.resource';
export * from './schedule.resource';
export * from './scheduleTimeSlot.resource';
export * from './serviceMessage.resource';
export * from './subscription.stripe.resource';
export * from './ticket.resource';
export * from './user.resource';
export * from './userAvatar.resource';
