import * as React from 'react';
import { Consume, LocaleContext, MobxComponent, OrganizationDocument } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { checkAccountActivation } from '../../activationHelper';
import { Maybe } from '../../maybe';

export class AccountActivationPrompt extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  render() {
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const isAccountActivated = checkAccountActivation(organization);
    const { t } = this._locale;
    return (
      <Maybe if="organization.manager" and={!isAccountActivated}>
        <div style={{ padding: '2em' }}>
          <div className="ui large icon warning message" onClick={() => this.globals.session.navigateTo('/dashboard')}>
            <i className="exclamation circle icon"></i>
            <div className="content">
              <div className="header">{t('components.dashboard.prompts.accountActivationPrompt.title')}</div>
              <p>{t('However, before you can create activities...')}</p>
            </div>
            <button className="ui orange button" style={{ margin: '1em', whiteSpace: 'nowrap' }}>
              {t('Activate my account')}
            </button>
          </div>
        </div>
      </Maybe>
    );
  }
}
