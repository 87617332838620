import Moment from 'moment-timezone';
import Qr from 'qr-image';
import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import {
  Consume,
  GiftCardDocument,
  KosmicComponent,
  LimitationsDocument,
  LocaleContext,
  LocaleProvider,
  OrganizationDocument,
  OrganizationResource,
  TranslatableText,
  URL,
} from './_dependencies';
import { GiftCardPreviewLocaleContext, giftCardPreviewCollection } from './_locales';
export interface GiftCardPreviewProps {
  className?: string;
  withoutIdentification?: boolean;
  giftCard: {
    number?: string;
    title: string | TranslatableText;
    value?: number;
    imageUrl?: string;
    giftCardNumber?: string;
    validityDurationInDays?: number;
    expirationDate?: Date;
    hideValue?: boolean;
    limitations: LimitationsDocument;
    showOrganization?: boolean;
  };
  customImageUrl?: string;
  /** Set to true if overlay another image */ overlay?: boolean;
  hideValue?: boolean;
}

interface State {
  organization?: OrganizationDocument;
}

export class GiftCardPreview extends KosmicComponent<GiftCardPreviewProps, State> {
  @Consume(LocaleContext)
  private _locale: GiftCardPreviewLocaleContext;

  constructor(props: GiftCardPreviewProps) {
    super(props);

    this.state = {
      organization: undefined,
    };
  }

  async componentDidMount() {
    if (!this.props.giftCard.limitations) return;
    const organization = await new OrganizationResource().getUnrestrictedOrg(
      (this.props.giftCard.limitations.organizations[0] as unknown as OrganizationDocument)._id,
    );
    this.setState({
      organization: organization,
    });
  }

  private get hideValue(): boolean {
    if (this.props.hideValue === false) {
      return false;
    }
    return !!this.props.hideValue || !!this.props.giftCard.hideValue;
  }
  private get yTitle(): number {
    return this.hideValue ? 150 : 121;
  }
  private get yDate(): number {
    return this.hideValue ? 195 : 225;
  }
  private get dateString() {
    const { t } = this._locale;
    const preText = t('giftCardPreview.dataOne');
    const endText = t('giftCardPreview.dataOneEnd');
    return (
      preText +
      ' ' +
      (this.props.giftCard.expirationDate
        ? Moment(this.props.giftCard.expirationDate).tz('Europe/Stockholm').format('YYYY-MM-DD')
        : this.props.giftCard.validityDurationInDays
        ? Moment()
            .add(Moment.duration(this.props.giftCard.validityDurationInDays, 'days'))
            .tz('Europe/Stockholm')
            .format('YYYY-MM-DD')
        : endText)
    );
  }
  private get numberString() {
    const { t } = this._locale;
    return this.props.giftCard.number || t('giftCardPreview.dataTwo');
  }
  private get valueString() {
    const { t } = this._locale;
    return this.props.giftCard.value ? this.props.giftCard.value + ' ' + t('SEK') : '';
  }

  private get organisationString() {
    const { t } = this._locale;
    return this.props.giftCard.showOrganization && this.state.organization
      ? `${t('Giftcard is valid at')} ${this.state.organization.name}`
      : '';
  }

  private get titleFontSize() {
    if (!this.props.giftCard.title) return;
    const { tt } = this._locale;
    const title = tt(this.props.giftCard.title);

    if (title.length < 10) {
      return 50;
    } else if (title.length < 20) {
      return 40;
    } else if (title.length < 33) {
      return 34;
    } else {
      return 26;
    }
  }

  private get valueFontSize() {
    if (!this.props.giftCard) return;
    const { tt } = this._locale;
    const title = tt(this.props.giftCard.title);

    if (title.length < 25) {
      return 36;
    } else if (title.length < 33) {
      return 32;
    } else {
      return 26;
    }
  }

  private get qrCodeSquare() {
    if (this.props.withoutIdentification) {
      return undefined;
    }

    if (this.props.giftCard.number) {
      const html = Qr.imageSync(this.props.giftCard.number, { type: 'svg' });
      return (
        <svg x={668 - 64 - 10} y="10" viewBox="0 0 64 64" width="64" height="64">
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          <g
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </svg>
      );
    }
    return <rect x={668 - 64 - 7} y="13" width="58" height="58" fill="white" />;
  }

  private get giftCardNumber() {
    if (this.props.withoutIdentification) {
      return undefined;
    }

    return (
      <g style={{ overflow: 'hidden' }} textAnchor="start" fontFamily="Georgia, serif" fontSize="16" fontWeight="900">
        <text x="10" y="25" stroke="white" strokeWidth={4} textLength="30%">
          {this.numberString}
        </text>
        <text x="10" y="25" stroke="black" strokeWidth={1} textLength="30%">
          {this.numberString}
        </text>
      </g>
    );
  }

  private get backgroundImage() {
    if (!this.props.overlay) {
      const src = URL(
        this.props.customImageUrl || this.props.giftCard.imageUrl || '/static/platform/img/default_giftcard.png',
      );
      return <image x="0" y="0" width="668" height="302" xlinkHref={src} />;
    }
    return undefined;
  }

  private get titleAsSvgText() {
    const { tt } = this._locale;

    return (
      <g
        style={{ overflow: 'hidden' }}
        textAnchor="middle"
        fontFamily="Verdana"
        fontSize={this.titleFontSize}
        fontWeight="900"
      >
        <text x="334" y={this.yTitle} stroke="white" strokeWidth={5}>
          {tt(this.props.giftCard?.title)}
        </text>
        <text x="334" y={this.yTitle} stroke="black" strokeWidth={1}>
          {tt(this.props.giftCard?.title)}
        </text>
      </g>
    );
  }

  private get valueAsSvgText() {
    if (this.hideValue) {
      return undefined;
    }
    return (
      <g
        style={{ overflow: 'hidden' }}
        textAnchor="middle"
        fontFamily="Verdana"
        fontSize={this.valueFontSize}
        fontWeight="900"
      >
        <text x="334" y="181" stroke="white" strokeWidth={4}>
          {this.valueString}
        </text>
        <text x="334" y="181" stroke="black" strokeWidth={1}>
          {this.valueString}
        </text>
      </g>
    );
  }

  private get dateAsSvgText() {
    return (
      <g style={{ overflow: 'hidden' }} textAnchor="middle" fontFamily="Verdana" fontSize={20} fontWeight="900">
        <text x="334" y={this.yDate} stroke="white" strokeWidth={4}>
          {this.dateString}
        </text>
        <text x="334" y={this.yDate} stroke="black" strokeWidth={1}>
          {this.dateString}
        </text>
      </g>
    );
  }

  private get organizationDetails() {
    return (
      <g style={{ overflow: 'hidden' }} textAnchor="middle" fontFamily="Verdana" fontSize={20} fontWeight="900">
        <text x="334" y={this.yDate + 30} stroke="white" strokeWidth={4}>
          {this.organisationString}
        </text>
        <text x="334" y={this.yDate + 30} stroke="black" strokeWidth={1}>
          {this.organisationString}
        </text>
      </g>
    );
  }

  render() {
    return (
      <svg width="100%" height={this.props.overlay ? '100%' : undefined} viewBox="0 0 668 302">
        {this.backgroundImage}
        {this.giftCardNumber}
        {this.qrCodeSquare}
        {this.titleAsSvgText}
        {this.valueAsSvgText}
        {this.dateAsSvgText}
        {this.organizationDetails}
      </svg>
    );
  }

  /** Overloads globals, for session and device is not available when rending gift cards */
  protected get globals() {
    return {} as any;
  }
}

/** Renders the given gift card to an SVG string */
export function renderGiftCardPreviewOverlay(giftCard: GiftCardDocument, initialLocale: Locale) {
  return ReactDOMServer.renderToStaticMarkup(
    <LocaleProvider initialLocale={initialLocale} collection={giftCardPreviewCollection} supportedLocales={[]}>
      <GiftCardPreview overlay giftCard={giftCard} />
    </LocaleProvider>,
  );
}
