import { computed, observable } from 'mobx';
import * as React from 'react';
import * as ReactRouter from 'react-router';
import {
  Alert,
  Consume,
  Field,
  Fields,
  Form,
  Input,
  LocaleContext,
  MobxComponent,
  Platforms,
  Rules,
  SubmitButton,
  UserResource,
} from '../_dependencies';
import { AHLocaleContext } from '../_locales';

export class ResetPasswordView extends MobxComponent<{
  match: ReactRouter.match<{ userId: string; resetToken: string }>;
}> {
  @observable private _passwordResetDone = false;
  @observable private _username;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  componentDidMount() {
    new UserResource()
      .getUserWithResetToken(this.props.match.params.userId, this.props.match.params.resetToken)
      .then((user) => {
        this._username = user.username;
      })
      .catch((err) => {
        this.globals.session.navigateTo('/dashboard');
      });
  }

  async onSubmit(values, resolve, reject) {
    const { t } = this._locale;

    await this.globals.session.deauthenticate();

    new UserResource()
      .changePassword(this.props.match.params.userId, values['Lösenord1'], this.props.match.params.resetToken)
      .then((result) => {
        this._passwordResetDone = true;
        resolve();
        this.globals.session.authenticate(result.mail, values['Lösenord1']);
        return null;
      })
      .catch((err: Error) => {
        if (err.message == 'Unauthorized') {
          Alert.show(t('reset-password.unauthorized'), t('components.resetPasswordView.alertTwo'), 'warning');
        } else {
          Alert.show(
            t('components.resetPasswordView.alertThree'),
            t('components.resetPasswordView.alertFour'),
            'error',
          );
        }
        reject(err);
      });
  }

  private get titleAndMessage() {
    const { t } = this._locale;
    if (this.props.match.params.resetToken == 'forced') {
      return (
        <div>
          <h2>{t('components.resetPasswordView.messageOne')}</h2>
          <p>{t('components.resetPasswordView.messageTwo')}</p>
        </div>
      );
    } else {
      return (
        <div>
          <h2>{t('components.resetPasswordView.messageThree')}</h2>
          <p>{t('components.resetPasswordView.messageFour')}</p>
        </div>
      );
    }
  }

  @computed private get formComponent() {
    const { t } = this._locale;
    if (!this._username) {
      return <span />;
    }

    return (
      <Form onSubmit={this.onSubmit.bind(this)}>
        <Input type="hidden" name="username" disabled value={this._username} />
        {this.titleAndMessage}
        <div style={{ height: '2em' }} />
        <Fields width={16}>
          <Field label={t('components.resetPasswordView.newPassword')} style={{ paddingBottom: '1em' }} width={8}>
            <Input
              type="password"
              name="Lösenord1"
              icon="privacy"
              rules={[Rules.MinimumLength(6, 'Var snäll och ange ett giltligt lösenord, på minst 6 tecken')]}
              className="required"
            />
          </Field>
          <Field label={t('components.resetPasswordView.repeatPassword')} style={{ paddingBottom: '1em' }} width={8}>
            <Input
              type="password"
              name="Lösenord2"
              icon="privacy"
              rules={[Rules.TheSameAs('Lösenord1', 'Lösenorden matchar inte varandra')]}
              className="required"
            />
          </Field>
        </Fields>
        <SubmitButton className="fluid green">{t('components.resetPasswordView.buttonSaveNewPassword')}</SubmitButton>
      </Form>
    );
  }

  @computed private get completedMessageComponent() {
    const { t } = this._locale;
    return (
      <div className="ui message">
        <h2>{t('components.resetPasswordView.messageFive')}</h2>
        <p>
          {t('components.resetPasswordView.messgageSix')}{' '}
          <a style={{ cursor: 'pointer' }} onClick={this.redirectAfterResetPassword}>
            {Platforms.platformName}
          </a>{' '}
          {t('components.resetPasswordView.messgageSixEnd')}
        </p>
      </div>
    );
  }

  private redirectAfterResetPassword = () => {
    this.globals.session.navigateTo('/dashboard');
  };

  render() {
    return (
      <div className="ui one column centered grid">
        <div className="column" style={{ maxWidth: '700px' }}>
          <div className="ui basic segment" style={{ marginTop: '2em', padding: '1em' }}>
            <img className="ui centered small image" src="/static/platform/img/logo.png" />
            <div style={{ height: '4em' }} />
            {this._passwordResetDone ? this.completedMessageComponent : this.formComponent}
          </div>
        </div>
      </div>
    );
  }
}
