import { GridFsDocument, GridFsResource } from '../_dependencies';
import SchemaNames from '../schemas/names';

export type GiftCardImageDocument = GridFsDocument;

export class GiftCardImageResource extends GridFsResource<GiftCardImageDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.GiftCardImage);
  }
}
