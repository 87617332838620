import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  Consume,
  Dropdown,
  DropdownItem,
  Field,
  ImageUploadForm,
  Input,
  LocaleContext,
  MobxComponent,
  Rules,
  UserAvatarResource,
  UserDocument,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';

// TODO: this.props.user.avatar = selection as onChange
interface State {
  uploadedImageId?: string;
  showUploadForm?: boolean;
  selection?: string;
}

export class AvatarDropdownField extends MobxComponent<
  { modifyOnChange?: boolean; user: UserDocument; uploadFormElementId: string; onChange?: (selection: string) => any },
  State
> {
  state: State = {};

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get customImageId(): string | undefined {
    if (this.state.uploadedImageId && this.state.uploadedImageId.length) {
      return this.state.uploadedImageId;
    }
    if (this.props.user.imageId) {
      return this.props.user.imageId;
    }
  }

  private get customImageUrl(): string | undefined {
    if (this.customImageId) {
      return new UserAvatarResource().fileUrl(this.customImageId);
    }
  }

  private handleSelection(selection: string) {
    this.setState({ selection, showUploadForm: selection == 'custom' }, () => {
      this.props.onChange ? this.props.onChange(selection) : (this.props.user.avatar = selection);
      if (this.props.modifyOnChange) {
        this.props.user.avatar = selection;
        this.props.user.imageId = selection == 'uploaded' ? this.customImageId : '';
      }
    });
  }

  private handleCustomAvatarUploaded(imageId, imageUrl, values, resolve, reject) {
    this.setState({ selection: 'uploaded', uploadedImageId: imageId, showUploadForm: false }, () => resolve());
  }

  renderUploadForm() {
    const { user, uploadFormElementId } = this.props;
    const { t } = this._locale;

    if (this.state.showUploadForm) {
      return ReactDOM.createPortal(
        <React.Fragment>
          <h5>{t('components.dashboard.employees.avatarDropdownField.uploadPictureHeader')}</h5>
          <ImageUploadForm
            defaultImage={user.imageUrl}
            showPreview
            startingStep="select"
            onImagedSaved={this.handleCustomAvatarUploaded.bind(this)}
            width={600}
            height={600}
            resource={new UserAvatarResource()}
            saveImageOnLastStep
          />
        </React.Fragment>,
        $(`#${uploadFormElementId}`)[0],
      );
    }
  }

  renderCustomImageDropdownItem() {
    const { t } = this._locale;
    if (this.customImageId) {
      return (
        <React.Fragment>
          <DropdownItem value="uploaded">
            <img className="ui avatar image" src={this.customImageUrl} />
            <b>{t('components.dashboard.employees.avatarDropdownField.myCustomAvatar')}</b>
          </DropdownItem>
          <div className="divider" />
        </React.Fragment>
      );
    }
    return null;
  }

  renderCustomImageHiddenInput() {
    if (this.customImageId) {
      return <Input type="hidden" name="UploadedAvatarImageId" value={this.customImageId} />;
    }
    return null;
  }

  render() {
    const { t } = this._locale;
    const { user } = this.props;

    return (
      <React.Fragment>
        {this.renderUploadForm()}
        {this.renderCustomImageHiddenInput()}
        <Field label={t('components.dashboard.employees.avatarDropdownField.avatarLabel')}>
          <Dropdown
            name="Visningsbild"
            placeholder={t('components.dashboard.employees.avatarDropdownField.avatarSelect')}
            className="fluid"
            rules={[
              Rules.NotEmpty(),
              Rules.NotExactly(
                'custom',
                false,
                t('components.dashboard.employees.avatarDropdownField.pleaseFinishUploading'),
              ),
            ]}
            value={
              this.state.showUploadForm
                ? 'custom'
                : this.state.selection || (this.customImageId ? 'uploaded' : user.avatar)
            }
            onChange={(value) => this.handleSelection(value)}
          >
            <div className="divider" />
            {this.renderCustomImageDropdownItem()}
            <DropdownItem value="custom">
              <i className="plus icon" />
              <b>{t('components.dashboard.employees.avatarDropdownField.newCustomAvatar')}</b>
            </DropdownItem>
            <div className="divider" />
            <DropdownItem value="default">
              <img className="ui avatar image" src="/static/commons/avatars/default.jpg" />
              <i>{t('components.dashboard.employees.avatarDropdownField.noOwnPicture')}</i>
            </DropdownItem>
            <DropdownItem value="alex">
              <img className="ui avatar image" src="/static/commons/avatars/alex.jpg" />
              Alex
            </DropdownItem>
            <DropdownItem value="alliston">
              <img className="uivatar image" src="/static/commons/avatars/alliston.jpg" />
              Alliston
            </DropdownItem>
            <DropdownItem value="harry">
              <img className="ui avatar image" src="/static/commons/avatars/harry.jpg" /> Harry
            </DropdownItem>
            <DropdownItem value="janet">
              <img className="ui avatar image" src="/static/commons/avatars/janet.jpg" />
              Janet
            </DropdownItem>
            <DropdownItem value="jimbo">
              <img className="ui avatar image" src="/static/commons/avatars/jimbo.jpg" />
              Jimbo
            </DropdownItem>
            <DropdownItem value="joe">
              <img className="ui avatar image" src="/static/commons/avatars/joe.jpg" />
              Joe
            </DropdownItem>
            <DropdownItem value="ken">
              <img className="ui avatar image" src="/static/commons/avatars/ken.jpg" />
              Ken
            </DropdownItem>
            <DropdownItem value="maria">
              <img className="ui avatar image" src="/static/commons/avatars/maria.jpg" />
              Maria
            </DropdownItem>
            <DropdownItem value="monica">
              <img className="ui avatar image" src="/static/commons/avatars/monica.jpg" />
              Monica
            </DropdownItem>
            <DropdownItem value="pete">
              <img className="ui avatar image" src="/static/commons/avatars/pete.jpg" />
              Pete
            </DropdownItem>
            <DropdownItem value="sheena">
              <img className="ui avatar image" src="/static/commons/avatars/sheena.jpg" />
              Sheena
            </DropdownItem>
            <DropdownItem value="toby">
              <img className="ui avatar image" src="/static/commons/avatars/toby.jpg" />
              Toby
            </DropdownItem>
          </Dropdown>
        </Field>
      </React.Fragment>
    );
  }
}
