import { action, observable } from 'mobx';
import Moment from 'moment-timezone';
import { Types } from 'mongoose';
import React from 'react';
import {
  Accordion,
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeDocument,
  Alert,
  Consume,
  DatePicker,
  Field,
  Form,
  Input,
  Link,
  LocaleContext,
  MobxComponent,
  PriceCategoryDocument,
  Rules,
  SubmitButton,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { PropertyStore } from '../../../components/dashboard/properties/propertyStore';
import { CalendarStore } from '../../calendar';
import ActivityDropdown from '../../dashboard/bookings/filtering/ActivityDropdown';
import { CreatePriceCategoriesFields } from '../../dashboard/offers/form/createPriceCategories/createPriceCategoriesFields';

interface Props {
  event: ActivityOccuranceDocument;
  store: CalendarStore;
}

interface State {
  priceCategories: PriceCategoryDocument[];
  title: string;
  visitorCapacity: number;
  minVisitors?: number;
  neededStaffing?: number;
  bookingClosesAtDate?: number;
}

export class CreateNewActivityForm extends MobxComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  constructor(props) {
    super(props);
    this.state = {
      priceCategories: [{ name: { sv: '' }, price: '' as unknown as number }] as PriceCategoryDocument[],
      title: '',
      visitorCapacity: 0,
    };
  }

  @observable private _selectedActivityType: ActivityTypeDocument;

  createANewActivityOccurance = (values: { [id: string]: any }, resolve, reject) => {
    const { t, tt } = this._locale;
    const { start } = this.props.event;
    const document = new ActivityOccuranceResource().createDocument(this.props.event);

    document.organization = this.globals.session.currentOrganizationId;
    document.property = PropertyStore.Instance.property._id;
    document.originatingActivity = this._selectedActivityType._id;
    document.title = tt(this.state.title);
    document.visitorCapacity = this.state.visitorCapacity;
    document.minVisitors = this.state.minVisitors;
    document.neededStaffing = this.state.neededStaffing;
    document.start = start;
    document.end = Moment(start).add(values['Tid i minuter'], 'minutes').toDate();
    document.bookingClosesAtDate = Moment(document.start)
      .subtract(this.state.bookingClosesAtDate || 0, 'hours')
      .toDate();
    document.refundableUntilInHours = values['Återbatalbart tills'];
    document.preparationStart = Moment(document.start)
      .subtract(this._selectedActivityType.preparationDuration || 0, 'minutes')
      .toDate();
    document.cleanupEnd = Moment(document.end)
      .add(this._selectedActivityType.cleanupDuration || 0, 'minutes')
      .toDate();
    document.priceCategories = this.state.priceCategories;
    document.bookingCapacity = this._selectedActivityType.bookingCapacity;
    document.inventories = this._selectedActivityType.inventories?.map((i) => i._id);

    verifyRoute(document.route);
    //verifyRoute function above allows for the non-null assertion 'p!' below.
    document.route = this._selectedActivityType.route?.map((p) => p!);

    document.isPublished = false;

    // Save the new acitivty event
    new ActivityOccuranceResource()
      .updateDocument(new ActivityOccuranceResource().createDocument(document))
      .then(() => {
        const title = t('activity-created.title');
        const message = this.state.title + ' ' + t('is scheduled. Remember to publish...');
        Alert.show(message, title, 'success');
        this.props.store.reload();
        return resolve();
      })
      .catch((err) => {
        Alert.show(t(`Could not add activity. If you think it is...`), t('Error'), 'error');
        return reject(err);
      });
  };

  @action
  private handleActivityTypeSelection = (selection?: ActivityTypeDocument[]) => {
    const { tt } = this._locale;
    if (!selection) {
      return;
    }

    // Select first (should be ok)
    this._selectedActivityType = selection[0];

    // Update input values
    this.setState({
      title: tt(this._selectedActivityType.title),
      visitorCapacity: this._selectedActivityType.visitorCapacity,
      minVisitors: this._selectedActivityType.minVisitors,
      neededStaffing: this._selectedActivityType.neededStaffing,
    });
    this.props.event.duration = this._selectedActivityType.duration;
    this.props.event.bookingClosesAtDate = Moment(this.props.event.start)
      .subtract(this._selectedActivityType.bookingClosesBeforeEventInHours || 0, 'hours')
      .toDate();
    this.props.event.refundableUntilInHours = this._selectedActivityType.refundableUntilInHours;
    this.props.event.preparationStart = Moment(this.props.event.start)
      .subtract(this._selectedActivityType.preparationDuration || 0, 'minutes')
      .toDate();
    this.props.event.cleanupEnd = Moment(this.props.event.end)
      .add(this._selectedActivityType.cleanupDuration || 0, 'minutes')
      .toDate();
    if (this._selectedActivityType.priceCategories.length) {
      const newPriceCategories: PriceCategoryDocument[] = [];
      this._selectedActivityType.priceCategories.forEach((category) => {
        newPriceCategories.push({ ...category });
      });
      this.setState({
        priceCategories: newPriceCategories,
      });
    }
  };

  @action
  private handleUpdatedEventPeriod = (start: Date) => {
    const { event } = this.props;
    // Generate new start and end
    const h = Moment(start).hour();
    const min = Moment(start).minute();
    const newStart = Moment(event.start).hours(h).minutes(min).toDate();
    const newEnd = Moment(newStart).add(event.duration, 'minutes').toDate();

    // Update event accordingly
    event.end = newEnd;

    // Without this mobx causes a loop. Needs to be ironed out by not mutating props
    if (Moment(event.start).diff(newStart, 'minutes')) {
      event.start = newStart;
    }
  };

  @action
  private handleUpdatedDuration = (duration: string) => {
    this.props.event.duration = Number(duration);
  };

  private get getBookingClosingTime() {
    return this._selectedActivityType?.bookingClosesBeforeEventInHours?.toString();
  }

  private setStatePriceCategories = (key: 'priceCategories', value: PriceCategoryDocument[]) => {
    this.setState({ [key]: value });
  };

  private get isUsingInventory() {
    return this.state.priceCategories.some((pc) => pc.inventory);
  }

  render() {
    const { t } = this._locale;
    return (
      <Form onSubmit={this.createANewActivityOccurance}>
        <Field label={t('Choose offer')}>
          <ActivityDropdown
            notEmpty
            onSelect={this.handleActivityTypeSelection}
            organizationId={PropertyStore.Instance.property.organization}
          />
        </Field>

        <Link to="/dashboard/offers/new" style={{ float: 'right' }}>
          {t('Create new offer')}
        </Link>
        <div style={{ height: '2em' }} />

        <Field label={t('Start')}>
          <DatePicker
            name="Start"
            type="time"
            icon="wait"
            defaultValue={this.props.event.start}
            rules={[Rules.NotEmpty('Välj en starttid')]}
            onChange={this.handleUpdatedEventPeriod}
          />
        </Field>

        <Field label={t('Time in minutes')}>
          <Input
            name="Tid i minuter"
            key="Tid i minuter"
            rules={[Rules.NotEmpty(), Rules.IsAnInteger(t('The time must be specified in minutes'))]}
            value={String(this.props.event.duration)}
            onChange={this.handleUpdatedDuration}
            icon="hourglass full"
          />
        </Field>
        <div style={{ height: '2em' }} />

        <Accordion
          key={this._selectedActivityType?.id}
          title={
            <span>
              <i className="dropdown icon"></i>
              {t('More settings')}
            </span>
          }
        >
          <Field label={t('Name')}>
            <Input
              name="Namn"
              rules={[Rules.NotEmpty(t('Fill in name of activity'))]}
              value={this.state.title}
              onChange={(value) => this.setState({ title: value })}
            />
          </Field>
          {!this.isUsingInventory && (
            <Field label={t('Number of slots for visitors')}>
              <Input
                disabled={this.isUsingInventory}
                name="Antal platser för besökande"
                value={String(this.state.visitorCapacity || 10)}
                rules={[Rules.NotEmpty(), Rules.IsAnInteger()]}
                icon="users"
                onChange={(value) => this.setState({ visitorCapacity: Number(value) })}
              />
            </Field>
          )}
          <Field label={t('Minimum number of visitors')}>
            <Input
              name="Minsta antal besökande"
              placeholder={t('Minimum number of visitors')}
              value={String(this.state.minVisitors || '')}
              rules={[Rules.IsIntegerOneOrHigherElseEmpty()]}
              icon="users"
              onChange={(value) => this.setState({ minVisitors: Number(value) })}
            />
          </Field>
          <Field label={t('Personnel slots')}>
            <Input
              name="Personalantal"
              icon="users"
              value={String(this.state.neededStaffing || '')}
              rules={[Rules.IsEmptyOrInteger()]}
              placeholder={t('(optional)')}
              onChange={(value) => this.setState({ neededStaffing: Number(value) })}
            />
          </Field>
          <Field label={t('Booking closes before scheduled appointment, hours')}>
            <Input
              name="Bokning stängs"
              icon="ticket"
              value={this.state.bookingClosesAtDate?.toString() || this.getBookingClosingTime}
              rules={[Rules.IsIntegerOneOrHigherElseEmpty()]}
              placeholder={t('(Optional)')}
              onChange={(value) =>
                this.setState({
                  bookingClosesAtDate: Number(value),
                })
              }
            />
          </Field>
          <CreatePriceCategoriesFields
            key={this._selectedActivityType?.id || ''}
            categories={this.state.priceCategories}
            onPriceCategoriesChange={this.setStatePriceCategories}
            isUsingInventory={this._selectedActivityType?.useInventory}
          />
        </Accordion>
        <SubmitButton className={'fluid green'} style={{ marginTop: '2em' }}>
          {t('Save activity')}
        </SubmitButton>
      </Form>
    );
  }
}

/** This function enforces that if a route exists, then every 'place' needs to be defined. Undefined breakpoints in the 
route is allowed within the activity document to create a more consistent flow in the activity creation form. In the 
form we rely on the dropdown menu rule 'not empty'. However when scheduling the activity zero tolerance is imposed to avoid 
ambiguity and continuous type checking. */
function verifyRoute(route: Types.ObjectId[] | undefined) {
  if (!route) return;
  route.forEach((i) => {
    if (!i) throw new Error('Trying to schedule activity with incomplete route array.');
  });
  return;
}
