import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { RRule } from 'rrule';
import {
  Consume,
  Encode,
  LocaleContext,
  PropertyDocument,
  PropertyResource,
  ScheduleDocument,
  ScheduleResource,
  UserDocument,
  UserResource,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';
import { ScheduleContent, ScheduleProps } from './interfaces';

const ScheduleContext: React.Context<Partial<ScheduleContent>> = React.createContext({});

export function ScheduleConsumer(Component: new (props: any) => React.Component<ScheduleProps>) {
  return function (props: any) {
    return (
      <ScheduleContext.Consumer>
        {(scheduleContent) => <Component {...props} scheduleContent={scheduleContent} />}
      </ScheduleContext.Consumer>
    );
  } as any;
}

interface Props {}

class ScheduleProviderImplementation extends React.Component<
  Props & RouteComponentProps<{ propertyId: string; userId: string }>,
  ScheduleContent & { stateIsReady: boolean }
> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  constructor(props) {
    super(props);
    this.state = {
      stateIsReady: false,
      schedules: [],
      createNewSchedule: this.createNewSchedule.bind(this),
      addSchedule: this.addSchedule.bind(this),
      removeSchedule: this.removeSchedule.bind(this),
      setEditSchedule: this.setEditSchedule.bind(this),
      setNewScheduleTemplate: this.setNewScheduleTemplate.bind(this),
      setConflictExists: this.setConflictExists.bind(this),
    } as any;
  }

  //Save to DB
  private async addSchedule(schedule: ScheduleDocument) {
    try {
      const scheduleRecource = new ScheduleResource();

      await scheduleRecource.updateDocument(schedule);
      const newSchedules: ScheduleDocument[] = await this.fetchNewSchedules();

      this.setState({
        schedules: newSchedules,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  private async removeSchedule(scheduleToBeDeleted: ScheduleDocument) {
    try {
      const scheduleResource = new ScheduleResource();

      await scheduleResource.delete(scheduleToBeDeleted._id);
      const exceptionsToBeDeleted = await scheduleResource.find({ originScheduleTemplate: scheduleToBeDeleted._id });

      for (const exception of exceptionsToBeDeleted) {
        await scheduleResource.delete(exception._id);
      }

      const newSchedules: ScheduleDocument[] = await this.fetchNewSchedules();
      this.setState({
        schedules: newSchedules,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  private setEditSchedule(editSchedule: ScheduleDocument, isCreatingNew?: boolean, editingScheduleId?: string): void {
    this.setState({ editSchedule, isCreatingNew, editingScheduleId });
  }

  private setNewScheduleTemplate(newScheduleTemplate: ScheduleDocument): void {
    this.setState({ newScheduleTemplate });
  }

  private createNewSchedule(
    startDate: Date,
    endDate: Date,
    startTime: Date,
    endTime: Date,
    monthList?: number[],
    dayList?: number[],
    originScheduleDocument?: ScheduleDocument,
  ) {
    try {
      const recurStart: Date = startDate;
      recurStart.setHours(startTime.getHours());
      recurStart.setMinutes(startTime.getMinutes());

      const rRuleSchedule = new RRule({
        freq: RRule.WEEKLY,
        dtstart: recurStart,
        until: endDate,
        bymonth: monthList,
        byweekday: dayList,
      });

      // Create template
      const schedule = new ScheduleResource().createDocument({
        user: this.state.user,
        property: this.state.originProperty,
        date: recurStart,
        rRule: Encode({ rule: rRuleSchedule, endTime: endTime }),
        isException: !!originScheduleDocument,
        originScheduleTemplate: originScheduleDocument ? originScheduleDocument._id : undefined,
      });
      return schedule;
    } catch (err) {
      console.error(err);
    }
  }

  public async setConflictExists(conflictExists: boolean) {
    await this.setState({
      conflictExists,
    });
  }

  private async fetchNewSchedules(user?: UserDocument) {
    let schedules: ScheduleDocument[] = [];
    try {
      schedules = await new ScheduleResource().find({ user: user ? user : this.state.user }, ['property']); //DEPRECATED SO LEAVING THIS FOR NOW.
    } catch (err) {
      console.error(err);
    }
    return schedules;
  }

  async UNSAFE_componentWillMount() {
    const { match } = this.props;
    if (match.params.userId) {
      const user: UserDocument = await new UserResource().get(match.params.userId);
      const originProperty: PropertyDocument = await new PropertyResource().get(match.params.propertyId);
      const schedules = await this.fetchNewSchedules(user);
      //const bookings = await new BookingResource().find({})

      this.setState({
        schedules: schedules || [],
        user: user,
        originProperty,
        stateIsReady: true,
      });
    }
  }

  public render() {
    const { t } = this._locale;
    if (!this.state.stateIsReady) {
      return (
        <div>
          <div className="ui active inverted dimmer">
            <div className="ui text loader">{t('Loading schedule...')}</div>
          </div>
          <p></p>
        </div>
      );
    }
    return <ScheduleContext.Provider value={this.state}>{this.props.children}</ScheduleContext.Provider>;
  }
}

export const ScheduleProvider = withRouter(ScheduleProviderImplementation);
