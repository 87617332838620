import Lodash from 'lodash';
import { computed, observable } from 'mobx';
import Moment from 'moment-timezone';
import * as React from 'react';
import { ActivityOccuranceResource, Consume, LocaleContext, UserDocument } from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { AbstractEmployeesList } from './abstractEmployeesList';

export type PropertyEmployeesListActionType = 'send-invite' | 'set-as-working';
interface Props {
  onActionTriggered(type: PropertyEmployeesListActionType, employeeSelectedForAction: UserDocument[]);
}

export class PropertyEmployeesList extends AbstractEmployeesList<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @observable private foundDoubleBooking = false;

  /* returns the remaining property employees */
  @computed protected get filteredEmployeesList() {
    return Lodash.filter(
      this.props.propertyEmployees,
      (employee) => !Lodash.includes(this.props.event.workingStaff, employee._id),
    );
  }
  @computed protected get emptyListMessage() {
    const { t } = this._locale;
    return t('Personnel list is empty');
  }

  private triggerSendIntiveToAllAction = () => this.props.onActionTriggered('send-invite', this.filteredEmployeesList);
  private triggerSendIntiveToSelectedAction = () =>
    this.props.onActionTriggered('send-invite', this.employeeSelectedForAction);
  private triggerSetSelectedAsWorkingAction = () =>
    this.props.onActionTriggered('set-as-working', this.employeeSelectedForAction);

  @computed private get isToManyEmployeesSeleted() {
    const neededEmployees = this.props.event.neededStaffing || 0;
    return this.props.event.workingStaff.length + this.employeeSelectedForAction.length > neededEmployees;
  }

  @computed private get isEmployeeDoubleBooked() {
    const { start, end } = this.props.event;
    this.checkEmployeeOccurances(this.employeeSelectedForAction, start, end).then((result) => {
      this.foundDoubleBooking = result;
    });

    return this.foundDoubleBooking;
  }

  async checkEmployeeOccurances(employees: UserDocument[], selectedEventStart: Date, selectedEventEnd: Date) {
    const end = Moment().toDate();

    const selectedIds: string[] = [];
    // Get selected employees id´s
    for (const employee of employees) {
      if (typeof employee === 'object') {
        selectedIds.push(employee.id);
      }
    }

    const selectedEmployeesOccurances = await new ActivityOccuranceResource().find({
      workingStaff: {
        $in: selectedIds,
      },
      end: { $gte: end },
    });

    // Check if any of those occurances is at the same time as selected occurance
    for (const occurance of selectedEmployeesOccurances) {
      if (
        Moment(occurance.start).toDate() <= Moment(selectedEventEnd).toDate() &&
        Moment(occurance.end).toDate() >= Moment(selectedEventStart).toDate()
      ) {
        return true;
      }
    }
    return false;
  }

  @computed private get toManyVolunteersMessage() {
    const { t } = this._locale;
    if (this.isAnyEmployeeSelected) {
      if (this.isToManyEmployeesSeleted) {
        return <div className="ui fluid orange message">{t('If you schedule the selected personnel...')}</div>;
      }
    }
  }

  @computed private get isEmployeeDoubleBookedMessage() {
    const { t } = this._locale;
    if (this.isAnyEmployeeSelected) {
      if (this.isEmployeeDoubleBooked) {
        return <div className="ui fluid orange message">{t('This choice will doublebook an employee')}</div>;
      }
    }
  }

  @computed private get actions() {
    const { t } = this._locale;
    if (this.isAnyEmployeeSelected) {
      return (
        <div>
          <button className="ui small fluid icon button" onClick={this.triggerSetSelectedAsWorkingAction}>
            <i className="checkmark icon" /> {t('Schedule selected personnel')}
          </button>
          <div style={{ height: '5px' }} />
          <button className="ui small fluid basic icon button" onClick={this.triggerSendIntiveToSelectedAction}>
            <i className="send icon" /> {t('Send job request to selected personnel')}
          </button>
        </div>
      );
    } else if (this.filteredEmployeesList.length) {
      const allPersonnelButton = (
        <button className="ui small fluid icon basic button" onClick={this.triggerSendIntiveToAllAction}>
          <i className="send icon" /> {t('Send job request to all personnel')}
        </button>
      );

      return allPersonnelButton;
    }
  }

  render() {
    const { t } = this._locale;
    return (
      <div>
        <div className="ui horizontal divider">{t('Personnel list')}</div>
        {this.listComponent}
        {this.toManyVolunteersMessage}
        {this.isEmployeeDoubleBookedMessage}
        {this.actions}
      </div>
    );
  }
}
