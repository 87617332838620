import * as React from 'react';
import { Alert, Consume, Dropdown, DropdownItem, KosmicComponent, LocaleContext } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { PropertyDropdown } from '../../common/property/propertydropdown';

interface State {
  /** Currently selected property id */
  selectedPropertyID?: string;
  /** Locks the new widget to either bookings or giftcards */
  featureLock: 'none' | 'bookings' | 'giftcards';
  /** Background color to use on the widget */
  bgColor: string;
}

/**
 * The modern widget selection view allows the
 * current user to copy the javascript code for a given property.
 */
export class PropertyWidgetSelectionView extends KosmicComponent<{}, State> {
  state: State = {
    featureLock: 'none',
    bgColor: '#fcfcfc', // NOTE: The default bg colors for new widgets are set to the standard color on Adventure Hero
  };
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  /** Triggered when user changes property in dropdown. */
  private onPropertyChanged = (selectedPropertyID: string) => this.setState({ selectedPropertyID });

  createWidgetTemplate(propertyId: string): string {
    const { featureLock, bgColor } = this.state;
    const widgetType = featureLock == 'none' ? '' : ` type="${featureLock}" `;
    const widgetColor = !bgColor.length ? '' : ` bg-color="${bgColor}" `;
    return `<adventurehero-widget property="${propertyId}"${widgetType}${widgetColor} locale="sv"/>`;
  }

  createHeadTemplate(): string {
    return `<script src="${window.location.origin}/v3/widget"></script>`;
  }

  public renderEditor(content: string) {
    const { t } = this._locale;
    const onClick = () => {
      try {
        const tempInput = $('<input>');
        $('body').append(tempInput);
        tempInput.val(content).select();
        document.execCommand('copy');
        tempInput.remove();
        Alert.show(t('copy-successful'));
      } catch (err) {
        Alert.show(t('copy-error'), '', 'error');
      }
    };
    return (
      <div
        id="editor"
        style={{
          marginTop: '1rem',
          marginBottom: '2rem',
          backgroundColor: '#EAEAEA',
          maxWidth: 800,
        }}
      >
        <div className="ui icon message" style={{ cursor: 'pointer' }} onClick={onClick}>
          <i className="copy icon"></i>
          <div className="content">
            <div className="header">{t('components.dashboard.widgets.modernSelectionView.contentHeader')}</div>
            <p onClick={(e) => e.stopPropagation()} style={{ cursor: 'text' }}>
              {content}
            </p>
          </div>
        </div>
      </div>
    );
  }

  public render() {
    const { selectedPropertyID } = this.state;
    const { t } = this._locale;
    return (
      <div className="ui basic segment" style={{ padding: '2em', paddingTop: 0 }}>
        <p
          style={{
            whiteSpace: 'pre-wrap',
            lineHeight: 1.5,
            fontSize: '1.2em',
          }}
        >
          {t('Use this widget to display all available...')}
        </p>
        <h4 className="ui header" style={{ marginBottom: '0.5em' }}>
          {t('Select property')}
        </h4>

        <PropertyDropdown onChange={this.onPropertyChanged.bind(this)} value={selectedPropertyID} />

        <div style={{ height: '2em' }} />
        {selectedPropertyID && (
          <div>
            <h4 className="ui header" style={{ marginBottom: '0.5em' }}>
              {t('Choose widget type')}
            </h4>
            <p>{t('Choose what type of widget you want to create')}</p>
            <Dropdown
              name="featureLock"
              value={this.state.featureLock}
              onChange={(v: State['featureLock']) => this.setState({ featureLock: v })}
            >
              <DropdownItem value="none">{t('Standard')}</DropdownItem>
              <DropdownItem value="bookings">{t('Bookings')}</DropdownItem>
              <DropdownItem value="giftcards">{t('Giftcards')}</DropdownItem>
            </Dropdown>
            <div style={{ marginBottom: '1em' }} />
            <h5 className="ui header" style={{ marginBottom: '0.5em' }}>
              {t('Widget Background Color')}
            </h5>
            <p>{t('Choose the background color of the widget')}</p>
            <input
              name="bgColor"
              type="color"
              value={this.state.bgColor}
              onChange={(e) => this.setState({ bgColor: e.target.value })}
            />
            <div style={{ marginBottom: '1em' }} />
            <p>{t('components.dashboard.widgets.modernSelectionView.headElement')}</p>
            {this.renderEditor(this.createHeadTemplate())}
            <p>{t('components.dashboard.widgets.modernSelectionView.widgetElement')}</p>
            {this.renderEditor(this.createWidgetTemplate(selectedPropertyID))}
          </div>
        )}
      </div>
    );
  }
}
