import React from 'react';

interface Props {
  title: string;
  sub: string;
}

export const Header = ({ title, sub }: Props) => {
  return (
    <h2 className="ui header">
      {title}
      <div className="sub header">{sub}</div>
    </h2>
  );
};
