import React from 'react';
import ReactSlider from 'react-slider';

export const Slider = ReactSlider as (props: {
  max: number;
  min: number;
  step: number;
  defaultValue: number;
  onChange: (value: number) => any;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => JSX.Element;
