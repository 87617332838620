import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';

export interface EmployeeAssignmentDocument extends MongooseDocument {
  employee: Types.ObjectId;
  offer: Types.ObjectId;
}

export class EmployeeAssignmentResource extends MongooseResource<EmployeeAssignmentDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.EmployeeAssignment);

    this.setSchema({
      employee: { type: Schema.Types.ObjectId, ref: SchemaNames.Default, required: true },
      offer: { type: Schema.Types.ObjectId, ref: SchemaNames.ActivityType, required: true },
    });

    // Adds a custom scheme making sure that no duplicate assigments can exist
    this.schema.index({ employee: 1, offer: 1 }, { unique: true });
  }
}
