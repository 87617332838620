import React from 'react';
import { InventoryItemStatusForm } from './inventoryItemStatusForm';

export const InventoryItemStatusPage = () => {
  return (
    <div
      style={{
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundImage: 'url(/static/platform/img/new-login-image.jpg)',
        backgroundPosition: 'center',
      }}
    >
      <InventoryItemStatusForm />
    </div>
  );
};
