import * as React from 'react';
import { KosmicComponent, LocaleContext, Consume } from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';

export class PrivacyInformationPopup extends KosmicComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  initializeHelpers() {
    const element = this.domElement.find('#stripe-helper');
    element && element.popup({ position: 'bottom right' });
  }

  componentDidMount() {
    this.initializeHelpers();
  }

  render() {
    const { t } = this._locale;
    return (
      <div style={{ float: 'right' }}>
        <span id="stripe-helper">
          <i className="question circle outline icon" />
          <i className="stripe icon" />
        </span>
        <div className="ui popup">
          <div className="header">{t('Processing of information')}</div>
          <div className="content">{t('The request to collect information...')}</div>
        </div>
      </div>
    );
  }
}
