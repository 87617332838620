import * as React from 'react';
import { useContext } from 'react';
import { GlobalsContext, LocaleContext, OrganizationDocument } from '../../../_dependencies';
import DashboardTemplate from '../dashboardTemplate';

export const DiscountsTemplate = (props) => {
  const { t } = useContext(LocaleContext);
  const organization = useContext(GlobalsContext);
  const currentOrganization = organization.session.currentOrganization as OrganizationDocument;
  const { features } = currentOrganization.flags;

  /* if (!features.coupons) {
        organization.session.navigateTo('/dashboard')
        return null
    } */

  const links = [
    {
      path: '/dashboard/discounts',
      text: t('Handle discounts'),
      icon: 'cut alternate icon',
    },
  ];

  return (
    <DashboardTemplate title={t('Discounts')} icon="cut alternate icon" tabs={links}>
      {props.children}
    </DashboardTemplate>
  );
};
