import Lodash from 'lodash';
import { action, observable, runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
  Accordion,
  AddressDocument,
  Alert,
  AreaDocument,
  Consume,
  Field,
  ImageUploadForm,
  Input,
  LocaleContext,
  MobxComponent,
  OrganizationDocument,
  PropertyDocument,
  PropertyImageResource,
  PropertyResource,
  Rules,
  SocialMediaDocument,
  Store,
  SubmitButton,
  TranslatableText,
  TranslatableTextField,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { CoordinateSelector } from '../../common/coordinateSelector';

export interface FormProps {
  property: PropertyDocument;
  onSubmit: (property: PropertyDocument, resolve: Function, reject: Function) => void;
  isEditing?: boolean;
}

@observer
export class PropertyForm extends MobxComponent<FormProps> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;
  private _store = FormStore.Instance;
  private _verificationTimer: any;

  @observable isLoading = false;
  @observable isSubmitButtonLoading = false;
  @observable invalidPropertyName = false;
  @observable facebookLink: string | undefined = undefined;
  @observable instagramLink: string | undefined = undefined;

  @observable nameCollisions: { [Property in keyof TranslatableText]-?: boolean } | undefined = undefined;

  private get optionalText() {
    const { t } = this._locale;
    return t('(optional)');
  }

  @action private onSubmit = (imageId, imageUrl, values, resolve, reject) => {
    this.setHttpToUrl();

    this._store.property.socialMedia = {
      facebook: this.facebookLink,
      instagram: this.instagramLink,
    } as SocialMediaDocument;
    this._store.property.imageId = imageId ? imageId : this.props.property.imageId;
    this._store.property.imageUrl = imageUrl ? imageUrl : this.props.property.imageUrl;
    this.props.onSubmit(this._store.property, resolve, reject);
  };

  private setHttpToUrl() {
    const regExp = /^https?:\/\/.+/;
    if (this._store.property.website && !regExp.test(this._store.property.website))
      this._store.property.website = 'http://' + this._store.property.website;
    if (this.facebookLink && !regExp.test(this.facebookLink)) this.facebookLink = 'http://' + this.facebookLink;
    if (this.instagramLink && !regExp.test(this.instagramLink)) this.instagramLink = 'http://' + this.instagramLink;
  }

  private get imageUploadHelpText() {
    const { t } = this._locale;
    return (
      <h3 className="ui header">
        {!this.props.isEditing
          ? t('components.dashboard.properties.form.FirstChoosePropertyPicture')
          : t('components.dashboard.properties.form.choosePropertyPicture')}
        <div className="sub header">
          {!this.props.isEditing ? <span>{t('components.dashboard.properties.form.pictureView')}&nbsp;</span> : ''}
          {t('components.dashboard.properties.form.recommendedPicSize')}
        </div>
      </h3>
    );
  }

  private updateCoordinates = () => {
    const { t } = this._locale;
    this.isLoading = true;
    new PropertyResource()
      .getLocation(this._store.property.address!)
      .then((res) => {
        runInAction(() => {
          if (res.lat && res.lng) {
            this._store.property.location[0] = res.lng;
            this._store.property.location[1] = res.lat;
            Alert.show(
              t('components.dashboard.properties.form.sucessUpdateCoordinateMessage'),
              t('components.dashboard.properties.form.successLocationUpdateMessage'),
              'success',
            );
          } else {
            Alert.show(
              t('components.dashboard.properties.form.badAddressMessage'),
              t('components.dashboard.properties.form.badAddressDescription'),
              'error',
            );
          }
          this.isLoading = false;
        });
      })
      .catch((err) => err);
  };

  private get locationSection() {
    const property = this._store.property;

    const { t } = this._locale;

    return (
      <div className="ui message">
        <Accordion
          title={
            <span>
              <h4>
                <i className="dropdown icon"></i>
                {t('Adjust geographical location')}
              </h4>
            </span>
          }
        >
          {t('components.dashboard.properties.form.locationMessage')}
          <div>
            <div
              className={'ui button ' + (this.isLoading ? 'loading' : '')}
              onClick={this.updateCoordinates}
              style={{ marginTop: '10px' }}
            >
              {t('components.dashboard.properties.form.updateCoordinates')}
            </div>
            <div className="ui divider" />
          </div>
          <div id="coordinate-selector" style={{ position: 'relative', height: 400 }}>
            <CoordinateSelector
              location={{
                lat: property.location[1],
                lng: property.location[0],
              }}
              onChange={(pos) => {
                property.location[1] = pos.lat;
                property.location[0] = pos.lng;
              }}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDNeWxgti3E_OsTgxut6uFb4lBvV1a3dAY&v=3.exp&libraries=geometry,drawing,places"
            />
          </div>
        </Accordion>
      </div>
    );
  }

  private handleAreasChanged = (value: string) => {
    this._store.property.freelancerAreas = value.split(',').map((areaString): AreaDocument => {
      const trimmedAreaString = Lodash.trim(areaString);
      const isZipcode = !!trimmedAreaString.match(/^[0-9]{5}.*/);
      return isZipcode ? { zipCode: trimmedAreaString } : { city: trimmedAreaString };
    });
  };

  private get addressAndLocation() {
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { t } = this._locale;
    if (organization.isFreelancer) {
      const areas = this._store.property.freelancerAreas;
      const commaSepartedAreas = Lodash(areas)
        .map((area) => area.city || area.zipCode)
        .join(', ');
      return (
        <React.Fragment>
          <div className="ui message">
            <h4 className="header">{t('components.dashboard.properties.form.areasLabel')}</h4>
            {t('components.dashboard.properties.form.areasInfo')}
            <div style={{ height: '1em' }} />
            <Field>
              <Input
                name="Områden"
                value={commaSepartedAreas}
                placeholder={t('components.dashboard.properties.form.areaHolder')}
                onChange={this.handleAreasChanged}
                rules={[Rules.NotEmpty()]}
              />
            </Field>
          </div>
        </React.Fragment>
      );
    }

    const address = this._store.property.address as AddressDocument;
    return (
      <React.Fragment>
        <Field label={t('Address')}>
          <Input
            name="Gatuadress"
            value={address.row1}
            onChange={(value) => (address.row1 = value)}
            icon="map outline"
            rules={[Rules.NotEmpty()]}
          />
        </Field>
        <Field label={t('Zip code')}>
          <Input
            name="Postnummer"
            value={address.postNr}
            onChange={(value) => (address.postNr = value)}
            placeholder={t('components.dashboard.properties.form.xxxxxx')}
            rules={[Rules.IsOnlyDigits(), Rules.ExactLength(5)]}
          />
        </Field>
        <Field label={t('City')}>
          <Input
            name="Postort"
            value={address.postOrt}
            onChange={(value) => (address.postOrt = value)}
            placeholder={t('components.dashboard.properties.form.holderCity')}
            rules={[Rules.NotEmpty()]}
          />
        </Field>
        <Field label={t('components.dashboard.properties.form.country')}>
          <Input
            name="Land"
            value={address.country}
            onChange={(value) => (address.country = value)}
            placeholder={t('components.dashboard.properties.form.holderCountry')}
            rules={[Rules.NotEmpty()]}
          />
        </Field>
        {this.props.isEditing && this.locationSection}
      </React.Fragment>
    );
  }

  private async handlePropertyNameChange(value: TranslatableText | string) {
    this.isSubmitButtonLoading = true;

    if (this._verificationTimer) {
      clearTimeout(this._verificationTimer);
      this._verificationTimer = undefined;
    }
    this._verificationTimer = setTimeout(async () => {
      const res = await new PropertyResource().checkPropertyNameAvailability(value, this._store.property);
      const containsCollision = () => {
        for (const locale in res) {
          if (res[locale]) {
            return true;
          }
        }
        return false;
      };

      this.invalidPropertyName = containsCollision();
      this.nameCollisions = res;
      this._verificationTimer = undefined;
      this.isSubmitButtonLoading = false;
    }, 500);

    this._store.property.name = value;
  }

  private get propertyNameIconFeedback() {
    if (this.invalidPropertyName) {
      return 'red ban icon';
    }
  }

  private get propertyNameTextFeedback() {
    const { t } = this._locale;
    if (this.invalidPropertyName) {
      const languages = {
        sv: t('Swedish'),
        en: t('English'),
        de: t('German'),
      };

      return (
        <p style={{ color: 'red', marginTop: '10px' }}>
          {t('components.dashboard.properties.form.propertyNameExists')}
          <ul>
            {Object.keys(this.nameCollisions || {})
              .filter((x) => this.nameCollisions && this.nameCollisions[x])
              .map((x) => (
                <li>{languages[x]}</li>
              ))}
          </ul>
        </p>
      );
    }
  }

  UNSAFE_componentWillMount() {
    this._store.property = toJS(this.props.property); // Deep clone
    if (!this._store.property.location) {
      this._store.property.location = [0, 0];
    }
  }

  componentDidMount() {
    if (this._store.property.socialMedia && this._store.property.socialMedia.facebook)
      this.facebookLink = this._store.property.socialMedia.facebook;
    if (this._store.property.socialMedia && this._store.property.socialMedia.instagram)
      this.instagramLink = this._store.property.socialMedia.instagram;
  }

  render() {
    if (!this._store.property) {
      return <span />;
    }
    const property = this._store.property;
    const { t, locale } = this._locale;

    return (
      <ImageUploadForm
        defaultImage={property.imageUrl || '/static/platform/img/default_activity.jpg'}
        onImagedSaved={this.onSubmit}
        width={650}
        height={400}
        resource={new PropertyImageResource()}
        startingStep={this.props.isEditing ? 'done' : 'select'}
        helpText={this.imageUploadHelpText}
        showPreview
      >
        <div style={{ height: '2em' }} />
        <TranslatableTextField
          name="Anläggningens namn"
          label={t('components.dashboard.properties.form.propertyName')}
          icon={this.propertyNameIconFeedback}
          value={property.name}
          onChange={(value) => {
            this.handlePropertyNameChange(value);
          }}
          placeholder={t('Max 40 characters')}
        />
        {this.propertyNameTextFeedback}
        <TranslatableTextField
          textarea
          name="Beskrivning"
          label={t('Description')}
          value={property.description}
          onChange={(value) => (property.description = value)}
          placeholder={t('Describe your property for customers')}
        />
        {this.addressAndLocation}
        <Field label={t('Phone')}>
          <Input
            name="Telefon"
            value={property.phoneNo}
            onChange={(value) => (property.phoneNo = value)}
            icon="call"
            rules={[Rules.NotEmpty()]}
          />
        </Field>
        <Field label={t('Email')}>
          <Input
            name="Email"
            placeholder="mail@domain.com"
            value={property.email}
            onChange={(value) => (property.email = value)}
            icon="mail"
            rules={[Rules.IsEmailOrEmpty()]}
          />
        </Field>
        <Field label={t('components.dashboard.properties.form.webbplats')}>
          <Input
            name="Webbplats"
            value={property.website}
            onChange={(value) => (property.website = value)}
            icon="world"
            placeholder={this.optionalText}
            rules={this._store.property.website && this._store.property.website.length > 0 ? [Rules.IsAnUrl()] : []}
          />
        </Field>
        <Field label={t('components.dashboard.properties.form.facebook')}>
          <Input
            name="Facebook"
            value={this.facebookLink}
            onChange={(value) => (this.facebookLink = value)}
            icon="facebook"
            placeholder={this.optionalText}
            rules={this.facebookLink && this.facebookLink.length > 0 ? [Rules.IsAnUrl()] : []}
          />
        </Field>
        <Field label={t('components.dashboard.properties.form.Instagram')}>
          <Input
            name="Instagram"
            value={this.instagramLink}
            onChange={(value) => (this.instagramLink = value)}
            icon="instagram"
            placeholder={this.optionalText}
            rules={this.instagramLink && this.instagramLink.length > 0 ? [Rules.IsAnUrl()] : []}
          />
        </Field>
        <SubmitButton
          className={'fluid green'}
          loading={this.isSubmitButtonLoading}
          disabled={this.invalidPropertyName}
        >
          {t('Save property')}
        </SubmitButton>
      </ImageUploadForm>
    );
  }
}

class FormStore extends Store {
  @observable public property: PropertyDocument;

  private static _instance: FormStore;
  public static get Instance() {
    if (!this._instance) {
      this._instance = new this();
    }
    return this._instance;
  }
}
