import { Schema, Types } from 'mongoose';
import { OrganizationDocument } from '.';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { BookingDocument } from './booking.resource';
import { InventoryEntityDocument } from './inventoryEntity.resource';
import { PlaceDocument } from './place.resource';
import { UserDocument } from './user.resource';

export interface InventoryTransfer {
  entities: InventoryEntityDocument[];
  assignedPickupers: UserDocument[];
  date: Date;
  from: PlaceDocument;
  to: PlaceDocument;
  bookingId?: Types.ObjectId;
  organization: OrganizationDocument;
}

export type InventoryTransferDocument = InventoryTransfer & MongooseDocument;

export class InventoryTransferResource extends MongooseResource<InventoryTransferDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.InventoryTransfer);

    this.setSchema({
      entities: [{ type: Schema.Types.ObjectId, ref: SchemaNames.InventoryEntity, required: true }],
      assignedPickupers: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Default, required: false }],
      date: { type: Date, required: true },
      from: { type: Schema.Types.ObjectId, ref: SchemaNames.Place, required: true },
      to: { type: Schema.Types.ObjectId, ref: SchemaNames.Place, required: true },
      bookingId: { type: Schema.Types.ObjectId, ref: SchemaNames.Booking, required: false },
      organization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: true },
    });

    const autoPopulateAll = function (next) {
      this.populate('entities');
      this.populate('from');
      this.populate('to');
      next();
    };

    this.schema.pre('findOne', autoPopulateAll).pre('find', autoPopulateAll);
  }

  deleteBagTransfers(booking: BookingDocument) {
    return this.sendRequest<any>('/deleteBagTransfers', 'post', { id: booking._id });
  }

  findAndPopulateTransferWithBookingAndPickuppers(query: object) {
    return this.sendRequest<InventoryTransferDocument[]>('/findAndPopulateTransferWithBookingAndPickuppers', 'post', {
      query,
    });
  }
}
