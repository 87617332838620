import Moment from 'moment-timezone';
import React from 'react';
import { Consume, DeviceProvider, IDevice, ObservingComponent } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { CalendarStore } from '../store';
import { EventComponentFactory } from './eventComponentFactory';
import { View } from './types';

export abstract class CalendarPeerComponent<Props> extends ObservingComponent<Props, CalendarStore> {
  @Consume(DeviceProvider.Context)
  protected device: IDevice;

  private _eventComponentFactory: EventComponentFactory;

  protected get eventComponentFactory(): EventComponentFactory {
    this._eventComponentFactory = this._eventComponentFactory || new EventComponentFactory(this.store);
    return this._eventComponentFactory;
  }

  componentDefaultStore() {
    return new CalendarStore();
  }

  protected viewSelectionBar(t: AHLocaleContext['t']) {
    let nrOfItems: string | number = this.store.allowedViews.length;

    switch (nrOfItems) {
      case 1:
        nrOfItems = 'one items';
        break;
      case 2:
        nrOfItems = 'two items';
        break;
      case 3:
        nrOfItems = 'three items';
        break;
      case 4:
        nrOfItems = 'four items';
        break;
      default:
        nrOfItems = '';
    }

    const translationKey = (view: View) => {
      switch (view) {
        case 'agenda':
          return 'components.calendar.store.agenda';
        case 'day':
          return 'components.calendar.store.day';
        case 'month':
          return 'components.calendar.store.month';
        case 'week':
          return 'components.calendar.store.week';
      }
    };

    // Don't include the week view on extra small screens
    const views = this.store.allowedViews.filter((v) => this.device.size !== 'mobile' || v !== 'week');

    return (
      <div className={'ui mini ' + nrOfItems + ' orange secondary menu item'} style={{ margin: 0 }}>
        {views.map((view) => {
          return (
            <a
              key={view}
              className={view == this.store.view ? 'active item' : 'item'}
              onClick={() => this.store.changeView(view)}
              style={{ margin: 0 }}
            >
              {t(translationKey(view))}
            </a>
          );
        })}
      </div>
    );
  }

  protected label(t: AHLocaleContext['t']): React.ReactNode {
    switch (this.store.view) {
      case 'agenda':
        return (
          <h3 style={{ marginBottom: '.4rem' }}>
            {t('components.calendar.utils.calendarPeerComponent.list')}{' '}
            {Moment(this.store.date).tz('Europe/Stockholm').format('Do MMM YYYY')}
          </h3>
        );
      case 'month':
        return (
          <h3 style={{ marginBottom: '.4rem' }}>
            {Moment(this.store.date).tz('Europe/Stockholm').format('MMMM YYYY')}
          </h3>
        );
      case 'week':
        const from = Moment(this.store.firstDisplayedDate).tz('Europe/Stockholm').format('MMM D');
        const to =
          Moment(this.store.firstDisplayedDate).month() != Moment(this.store.lastDisplayedDate).month()
            ? Moment(this.store.lastDisplayedDate).tz('Europe/Stockholm').format('MMM D')
            : Moment(this.store.lastDisplayedDate).tz('Europe/Stockholm').format('D');
        return (
          <span>
            <h3 style={{ marginBottom: '.4rem' }}>{from + ' - ' + to}</h3>
            <p>
              <b>
                {t('components.calendar.utils.calendarPeerComponent.week')}:{' '}
                {Moment(this.store.date).tz('Europe/Stockholm').format('W')}
              </b>
            </p>
          </span>
        );

      case 'day':
        return (
          <span>
            <h3 style={{ marginBottom: '.4rem' }}>
              {Moment(this.store.date).tz('Europe/Stockholm').format('DD MMM YYYY')}
            </h3>
            <p>
              <b>{Moment(this.store.date).tz('Europe/Stockholm').format('dddd')}</b>
            </p>
          </span>
        );
    }
  }
}
