import React, { useContext } from 'react';
import { AccessoryDocument, BookingPriceCategoryDocument, MarketplaceLibrary as Lib } from '../_dependencies';
import { useLocale } from '../_locales';
import { MarketplaceContext } from '../marketplace.context';

interface Props {
  items: Lib.CartItem[];
}

export const PaymentTotalComponent = ({ items }: Props) => {
  const {
    order,
    removeAppliedGiftcards,
    removeAppliedDiscount,
    getGiftcardAppliedValue,
    leftToPay,
    leftToPayAfterDiscount,
    discountTotal,
    alreadyPaid,
    cartItemIsCartBooking,
    completedRelevantPayments,
  } = useContext(MarketplaceContext);
  const { t, tt } = useLocale();

  // If the order overview does not contain any products
  if (!order.products.length || !items.length) {
    return <div className="ui active centered inline loader"></div>;
  }

  const matchingOrderProduct = (item: Lib.CartItem) => {
    return order.products.find((prod) => prod.externalId == item.id);
  };

  const checkIfAppliedDiscount = (item: Lib.CartBooking) => {
    const product = order.products.find((i) => item.id === i.externalId);
    return product?.appliedDiscount ? true : false;
  };

  const itemHasDiscountLimitation = (item: Lib.CartBooking) => {
    if (!order.discount) return;
    if (checkIfAppliedDiscount(item)) {
      const totalPrice = item.totalPrice;
      return (
        <div className="sub header">
          <span style={{ paddingLeft: '1em', fontSize: '.9em', color: 'rgba(0,0,0,.7)' }}>
            {`${t('After discount')}: ${totalPrice - order.discount.percentage * totalPrice} ${t('kr-SEK')}`}
          </span>
        </div>
      );
    }
  };

  const renderItemTitleOfDiscount = (item: Lib.CartBooking) => {
    if (!order.discount) return;
    if (checkIfAppliedDiscount(item)) {
      return <div>{`(${t('Discount')} ${order.discount.code} ${t('Applied')})`}</div>;
    }
  };

  const renderBookingPriceBlock = (item: Lib.CartBooking) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <strong>{tt(item.occurance.title)}</strong>
          {renderItemTitleOfDiscount(item)}
        </div>
        {item.priceCategories.map((pc: AccessoryDocument<BookingPriceCategoryDocument>, index) => (
          <div className="sub header" key={`pricing-${index}`} style={{ paddingLeft: '1em' }}>
            {pc.basePrice || pc.rescheduleInsurance ? (
              <span>{tt(pc.name)}</span>
            ) : (
              <span>
                {pc.tickets} {t('pcs')} {tt(pc.name)}
              </span>
            )}
            {pc.rescheduleInsurance?.isFeePerPerson ? (
              <span>
                : {pc.price * (pc.rescheduleInsurance.personsPaidFor || 0)} {t('kr-SEK')}
              </span>
            ) : (
              <span>
                : {pc.price} {t('kr-SEK')}
              </span>
            )}
          </div>
        ))}
        {itemHasDiscountLimitation(item)}
      </>
    );
  };

  const renderGiftcardPriceBlock = (item: Lib.CartGiftcard) => {
    return (
      <>
        <strong>{tt(item.title)}</strong>
        <div className="sub header" style={{ paddingLeft: '1em' }}>
          <span>
            {t('Giftcard')}: {item.value} {t('SEK')}
          </span>
        </div>
      </>
    );
  };

  const renderAppliedGiftcards = (item: Lib.CartItem) => {
    const product = matchingOrderProduct(item);
    if (!product) return null;
    return (
      <>
        {product.appliedGiftcards.map((card, index) => (
          <div className="item" style={{ border: 'none' }} key={`gift-${card.number}-${index}`}>
            <div className="content" style={{ paddingLeft: '1em', fontSize: '.9em', color: 'rgba(0,0,0,.7)' }}>
              {t('Applied voucher')}: {card.number}
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderAppliedDiscount = () => {
    if (!order.discount) return;
    return (
      <div className="sub header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ color: 'rgba(0,0,0,.7)' }}>
          {t('Applied discount')} {order.discount.code}: {Math.trunc(order.discount.percentage * 100)}%
        </span>
        <a style={{ cursor: 'pointer' }} onClick={() => removeAppliedDiscount()}>
          {t('Remove')}
        </a>
      </div>
    );
  };

  const renderAlreadyPaid = () => {
    return (
      <>
        {completedRelevantPayments.length ? (
          <div className="sub header">
            {t('Already paid')}: {(alreadyPaid / 100).toFixed(0)} {t('SEK')}
          </div>
        ) : null}
      </>
    );
  };

  const total = () => {
    return (
      <p style={{ margin: '0em 0 .5em 0' }}>
        {!discountTotal ? (
          <>
            <strong>{t('Total')}:</strong>
            {` ${leftToPay / 100} ${t('SEK')}`}
          </>
        ) : (
          <>
            <strong>{t('Total after discount')}:</strong>
            {` ${leftToPayAfterDiscount / 100} ${t('SEK')}`}
          </>
        )}
      </p>
    );
  };

  return (
    <div>
      <h4 className="ui header">
        <p style={{ margin: '0 0 .3em 0' }}>{`${t('Products')}:`}</p>
      </h4>
      {items.map((item, index) => (
        <div key={`cart-item-${index}`} style={{ margin: 0 }}>
          {cartItemIsCartBooking(item) ? renderBookingPriceBlock(item) : renderGiftcardPriceBlock(item)}
          <div className="ui middle aligned divided list" style={{ margin: '0 0 .8em 0' }}>
            {renderAppliedGiftcards(item)}
          </div>
        </div>
      ))}
      {renderAlreadyPaid()}
      {order.giftcards.map((giftcard) => (
        <div className="sub header" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ color: 'rgba(0,0,0,.7)' }}>
            {t('Voucher')} {giftcard.externalId}: -{getGiftcardAppliedValue(giftcard.externalId) / 100} {t('SEK')}
          </span>
          <a style={{ cursor: 'pointer' }} onClick={() => removeAppliedGiftcards([giftcard.externalId])}>
            {t('Remove')}
          </a>
        </div>
      ))}
      {renderAppliedDiscount()}
      {total()}
    </div>
  );
};
