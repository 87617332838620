/**
 * Takes as a generic type parameter an object with
 * { locale : string | null } key-value pairs.
 * @returns a method that can used to define a translation set for the given type parameter
 */
export const generateTranslationsFunction = <T extends Partial<Record<Locale, string | null>>>() => {
  return function translations(set: T) {
    return set;
  };
};
