import { Store } from '../_dependencies';

/**
 * This is a solid way to have our stores, but it is important that extended stores are used logically
 * and in the same way. Maybe in the future we should move some base functionallity here.
 */
export class SingletonStore extends Store {
  private static _instance: SingletonStore;
  public static get Instance() {
    if (!this._instance) {
      this._instance = new this();
    }
    return this._instance;
  }
}

/**
 * EXTEND LIKE THIS:
 *
 * export class NewStore extends SingletonStore {
 *
 *    // Code goes here!!
 *
 * }
 * export const newStore = NewStore.Instance as NewStore
 *
 *
 * IMPORT NEW STORES LIKE THIS:
 *
 * import { newStore as NewStore } from "<path>"
 */
