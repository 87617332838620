import * as React from 'react';
import {
  DeviceProvider,
  IDevice,
  Consume,
  Field,
  Form,
  Input,
  KosmicComponent,
  LocaleContext,
  Rules,
  SubmitButton,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { Link } from 'react-router-dom';

interface Props {
  handleLogin: (event: React.FormEvent<HTMLFormElement>) => void;
  handleResetPassword: any;
  showMailSentMessage: boolean;
  showResetPasswordBox: boolean;
  isLoading: boolean;
  fullWidth?: boolean;
  titleColor?: string;
}

export class LoginForm extends KosmicComponent<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;
  constructor(props: Props) {
    super(props);
  }
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  componentDidMount() {
    const { t } = this._locale;
    // Add form validation
    ($('LoginForm').form as any)({
      fields: {
        email: {
          identifier: t('Email'),
          rules: [
            {
              type: 'empty',
              prompt: t('You must enter your email'),
            },
            {
              type: 'email',
              prompt: t('Please enter a valid email'),
            },
          ],
        },
        password: {
          identifier: t('password'),
          rules: [
            {
              type: 'empty',
              prompt: t('You must enter your password'),
            },
            {
              type: 'length[6]',
              prompt: t('Your password must be at least six letters long'),
            },
          ],
        },
      },
    });
  }

  private get resetPasswordBox() {
    const { t } = this._locale;
    const form = (
      <div>
        <p style={{ minHeight: '58px', color: 'white' }}>{t('Fill in your email address and we will send...')}</p>
        <Form onSubmit={this.props.handleResetPassword}>
          <Field>
            <Input
              name="Email"
              icon="at"
              className="fluid"
              placeholder={t('Email')}
              rules={[Rules.NotEmpty(t('Please enter your email')), Rules.IsEmailOrEmpty()]}
            />
          </Field>
          <SubmitButton className="large fluid green" style={{ fontSize: '1rem' }}>
            {t('Send me an email')}
          </SubmitButton>
        </Form>
      </div>
    );

    const message = (
      <div className="ui message" style={{ margin: '20px 0 0 0' }}>
        <h3>{t('Email has been sent')}</h3>
        <p>{t('Now just follow the link in the email to reset your password')}</p>
      </div>
    );

    return (
      <div
        className="ui fluid basic segment"
        style={{ minHeight: '193px', margin: '0 auto 0 auto', paddingBottom: '0', textAlign: 'center' }}
      >
        {this.props.showMailSentMessage ? message : form}
      </div>
    );
  }

  private get loginBox() {
    const { t } = this._locale;

    const desktopLogo: JSX.Element = (
      <img className="ui centered small image" style={logoStyle} src="/static/platform/img/logo.png" />
    );
    const mobileLogo: JSX.Element = (
      <img className="ui centered small image" style={logoStyleMobile} src="/static/platform/img/logo.png" />
    );

    return (
      <div id="LoginBox" className="ui fluid basic segment" style={{ margin: '0 auto 0 auto' }}>
        {this.device.size != 'mobile' ? (
          <Link style={{ cursor: 'pointer', color: '#29BB9C', textDecoration: 'none' }} to="/activities">
            {desktopLogo}
          </Link>
        ) : undefined}
        {this.device.size == 'mobile' ? <Link to="/">{mobileLogo}</Link> : undefined}
        <form onSubmit={this.props.handleLogin} id="LoginForm" className="ui fluid large form">
          <div className="ui sixteen wide column" style={{ textAlign: 'center', marginBottom: '2em' }}>
            <h2 style={{ color: this.props.titleColor }}>{t('Log in')}</h2>
          </div>
          <div className="field">
            <div className="ui left icon fluid input">
              <i className="user icon"></i>
              <input id="LoginEmail" type="text" name="email" placeholder={t('Email')} />
            </div>
          </div>
          <div className="field">
            <div className="ui left icon fluid input">
              <i className="lock icon"></i>
              <input id="LoginPassword" type="password" name="password" placeholder={t('password')} />
            </div>
          </div>
          <button
            type="submit"
            className={'ui fluid large green button ' + (this.props.isLoading ? 'loading disabled' : '')}
            style={{ fontSize: '1rem', backgroundColor: '#29BB9C' }}
          >
            {t('login-as-member')}
          </button>
          <div className="ui error message"></div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: '100%' }}>{this.props.showResetPasswordBox ? this.resetPasswordBox : this.loginBox}</div>
    );
  }
}

const logoStyle: React.CSSProperties = {
  marginTop: '-10rem',
  height: 'auto',
};

const logoStyleMobile: React.CSSProperties = {
  marginTop: '-1.5rem',
  marginBottom: '1em',
  height: 'auto',
  width: '30%',
};
