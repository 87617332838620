import { action, observable } from 'mobx';
import * as React from 'react';
import { OffersContext } from '../../../../contexts/offers.context';
import {
  ActivityTypeResource,
  Consume,
  Field,
  Form,
  LocaleContext,
  MobxComponent,
  TranslatableText,
  TranslatableTextField,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';

export class BookingRulesForm extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(OffersContext)
  private _offers: OffersContext;

  private get selectedOffer() {
    return this._offers.selectedOffer;
  }

  @observable private _updating = false;
  private _updateTimer: any;

  @action
  private updateRules = (textValue: TranslatableText) => {
    if (!this.selectedOffer) return;
    this.selectedOffer.bookingRules = {
      sv: textValue.sv || '',
      en: textValue.en || '',
      de: textValue.de || '',
    } as TranslatableText;

    if (this._updateTimer) {
      clearTimeout(this._updateTimer);
    }
    this._updateTimer = setTimeout(async () => {
      this._updating = true;
      if (this.selectedOffer) {
        await new ActivityTypeResource().updateBookingRules(this.selectedOffer.id, this.selectedOffer.bookingRules!);
      }

      this._updating = false;
    }, 1000);
  };

  componentWillUnmount() {
    clearTimeout(this._updateTimer);
  }

  render() {
    const { t } = this._locale;
    if (!this.selectedOffer) {
      return <span />;
    }
    return (
      <Form>
        <Field label={t('components.dashboard.offers.details.bookingRulesForm.editBookingRules')}>
          <p>{t('components.dashboard.offers.details.bookingRulesForm.descriptionRules')}</p>
          <div className={'ui basic ' + (this._updating ? 'loading' : '') + ' segment'} style={{ padding: 0 }}>
            <TranslatableTextField
              textarea
              name="Booking rules"
              value={this.selectedOffer.bookingRules}
              placeholder={new ActivityTypeResource().standardBookingRules}
              onChange={this.updateRules}
            />
          </div>
        </Field>
      </Form>
    );
  }
}
