import { useContext } from 'react';
import {
  generateTranslationCollection,
  generateTranslationsFunction,
  LocaleContext,
  LocaleContextState,
} from './_dependencies';

const translations = generateTranslationsFunction<{
  sv: string;
  en: string | null;
  de: string | null;
}>();

export const { collection: giftCardPreviewCollection } = generateTranslationCollection({
  'could-not-load-content': translations({
    sv: 'Det gick inte att läsa in innehållet, vi beklagar besväret.',
    en: 'Could not load content, we are sorry for the inconvenience.',
    de: 'Der Inhalt konnte nicht geladen werden, wir entschuldigen uns für die Unannehmlichkeiten.',
  }),
  'Giftcard is valid at': translations({
    sv: `Presentkortet gäller hos`,
    en: `Giftcard is valid at`,
    de: 'Die Geschenkkarte ist gültig am',
  }),
  'giftCardPreview.dataOneEnd': translations({
    sv: `DATUM`,
    en: `Date`,
    de: `Datum`,
  }),
  'giftCardPreview.dataOne': translations({
    sv: `Värdebeviset är giltigt till och med`,
    en: `The voucher is valid until`,
    de: `Gutscheinkarte gültig bis`,
  }),
  'giftCardPreview.dataTwo': translations({
    sv: `PRESENTKORTSNUMMER`,
    en: `Giftcard number`,
    de: `Gutscheincode`,
  }),
  SEK: translations({
    sv: `kr`,
    en: `SEK`,
    de: `SEK`,
  }),
});

export type GiftCardPreviewLocaleContext = LocaleContextState<typeof giftCardPreviewCollection>;

export const useLocale = () => useContext<GiftCardPreviewLocaleContext>(LocaleContext);
