import { GridFsDocument, GridFsResource } from '../_dependencies';
import SchemaNames from '../schemas/names';

export type BadgeImageDocument = GridFsDocument;

export class BadgeImageResource extends GridFsResource<BadgeImageDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.BadgeImage);
  }
}
