import Lodash from 'lodash';
import Moment from 'moment-timezone';
import { Schema, Types } from 'mongoose';

import { AbstractUserDocument } from '../_dependencies';
import { PriceCategoryDocument, PriceCategorySchema, visitors } from '../schemas/priceCategory';
import { RequestedStaffDocument, RequestedStaffSchema } from '../schemas/requestedStaff';
import { ActivityTypeDocument } from './activityType.resource';
import { BookingDocument } from './booking.resource';
import { CalendarOccuranceDocument, CalendarOccuranceResource } from './calendarOccurance.resource';
import { ReservationResource } from './reservation.resource';

import SchemaNames from '../schemas/names';
import { PropertyDocument } from './property.resource';

/** Request options when an update of the working staff on an activity occurance */
export interface ISetWorkingStaffRequest {
  /** Id of the occurance to update */
  occuranceId: string | Types.ObjectId;
  /** A finished list of employees that should be set as the working staff, will override the current lsit of specified */
  wantedWorkingStaff?: Types.ObjectId[] | string[] | AbstractUserDocument[];
  /** A list of employees that should be added to the current list of assigned employees */
  addedStaff: Types.ObjectId[] | string[] | AbstractUserDocument[];
  /** A list of employees that should be removed from the current list of assigned employees, if they exist */
  removedStaff?: Types.ObjectId[] | string[] | AbstractUserDocument[];
  /** A list of employees that should be removed requestedStaff list and added to workingStaff */
  requestedStaff?: RequestedStaffDocument[];
}
export interface ActivityOccuranceDocument extends CalendarOccuranceDocument {
  organization: Types.ObjectId;
  property: Types.ObjectId;
  originatingActivity: Types.ObjectId;
  priceCategories: PriceCategoryDocument[];
  visitorCapacity: number;
  minVisitors?: number;
  neededStaffing?: number;
  workingStaff: Types.ObjectId[];
  requestedStaff: RequestedStaffDocument[];
  notes: string;
  bookingCapacity?: number;
  isPublished?: boolean;
  inventories?: Types.ObjectId[];
  route?: Types.ObjectId[];
  refundableUntilInHours?: number;

  /** The time at which this occurance can no longer be booked */ bookingClosesAtDate?: Date;
  /** The start time when preperations for this occurance must begin */ preparationStart?: Date;
  /** The end time when cleanup for this occurance will be completed */ cleanupEnd?: Date;
  /** VIRTUAL FIELD The time at which a booking on this occurance can still be refunded */ refundableUntil?: Date;
  /** VIRTUAL FIELD Bookings, can be found and populated with the find method */ bookings: BookingDocument[];
  /** VIRTUAL FIELD Number of visitors booked */ bookedVisitors: number;
  /** VIRTUAL FIELD Number of visitors that can be booked */ availableVisitors: number;
  /** VIRTUAL FIELD Check if all visitor spots has been taken */ isFullyBooked: boolean;
  /** VIRTUAL FIELD Checks if the occurance has a booking that is requested */ hasRequestedBooking: boolean;
  /** VIRTUAL FIELD Number of visitors that can be booked based on inventory */ availableInventory?: number;
  /** VIRTUAL FIELD Checks if there are any customer requested bookings */ hasCustomerRequestedBooking: boolean;
}

export interface NewActivityOccuranceDocument extends ActivityOccuranceDocument {
  isANewCalendarEntry: boolean;
}

export class ActivityOccuranceResource extends CalendarOccuranceResource<ActivityOccuranceDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.ActivityOccurance);
    this.setParentResource(SchemaNames.Occurance);

    this.setSchema({
      organization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: true },
      property: { type: Schema.Types.ObjectId, ref: SchemaNames.Property, required: true },
      originatingActivity: { type: Schema.Types.ObjectId, ref: SchemaNames.ActivityType, required: true },
      priceCategories: [PriceCategorySchema],
      visitorCapacity: { type: Number, required: true },
      bookingCapacity: { type: Number, required: false },
      minVisitors: { type: Number, required: false },
      neededStaffing: { type: Number, required: false },
      workingStaff: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Default }],
      requestedStaff: [RequestedStaffSchema],
      bookingClosesAtDate: { type: Date, required: false },
      preparationStart: { type: Date, required: false },
      cleanupEnd: { type: Date, required: false },
      notes: { type: String, required: false },
      refundableUntilInHours: { type: Number, required: false },
      isPublished: { type: Boolean, required: false },
      inventories: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Inventory, required: false }],
      route: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Place, required: false }],
      __t: { type: String, required: true, default: 'activityoccurance' }, //For some reason this field doesn't get set automatically after the api port... temp fix.
    });

    // TODO: handle migrations - can be made into issue
    // bookingClosesAtDate, preparationStart, cleanupEnd should really really be required, but we have
    // no good pattern for handling migrations in the database; and since existing occurances all vary in these values no
    // easy migration can be made.
    // This is a constant problem in our code base and should be addressed with a genereall solution.

    this.addVirtualField('refundableUntil', (document) => {
      return Moment(document.start)
        .subtract(document.refundableUntilInHours || 0, 'hours')
        .toDate();
    });

    this.addVirtualField('isAnActivityOccurance', (document) => true);

    this.addVirtualField('bookedVisitors', (document) => {
      return Lodash.sum(
        Lodash.map(document.bookings as BookingDocument[], (booking) => {
          return Lodash.sumBy(booking.priceCategories || [], (pc) => {
            // If we have a requested booking and no cross selling property, we don't count the booking as it's pending
            if (booking.requested && !booking.crossSellingProperty && !pc.availableInventory) return 0;
            return visitors(pc);
          });
        }),
      );
    });
    this.addVirtualField('availableVisitors', (document) => {
      // If we have a bookingCapacity, check to make sure that the maximum amount
      // of bookings have not yet been reached.
      if (document.bookingCapacity && document.bookings?.length >= document.bookingCapacity) {
        return 0;
      }

      // When an inventory is connected to an offer
      if (document.availableInventory) {
        return Math.max(document.availableInventory, 0);
      }

      // When an inventory is connected to price categories
      if (document.priceCategories.some((pc) => pc.inventory)) {
        const inventoryCategories = document.priceCategories.filter((pc) => pc.inventory);

        const uniqueInventoryCategories = [
          ...new Map(inventoryCategories.map((item) => [String(item.inventory), item])).values(),
        ];

        return uniqueInventoryCategories.reduce(
          (prev, category) => (prev += (category.availableInventory || 0) * category.groupMultiplier),
          0,
        );
      }

      // Simple case of visitorCapacity
      return Math.max((document.visitorCapacity || 0) - document.bookedVisitors, 0);
    });
    this.addVirtualField('isFullyBooked', (document) => {
      return !document.availableVisitors || document.availableVisitors < (document.minVisitors || 0);
    });
    this.schema.virtual('bookings', {
      ref: SchemaNames.Booking,
      localField: '_id',
      foreignField: 'occurance',
    });

    this.addVirtualField('hasRequestedBooking', (document) => {
      return document.bookings?.some((b) => b.requested);
    });

    this.addVirtualField('hasCustomerRequestedBooking', (document) => {
      return document.bookings?.some((b) => b.requested && !b.crossSellingProperty);
    });
  }

  findReservations(id: string) {
    return new ReservationResource().find({ occurance: id });
  }

  batchCreateOccurances(
    activityType: ActivityTypeDocument,
    propertyIds: Types.ObjectId[],
    startTimes: Date[],
    publishTimes: boolean,
  ) {
    return this.sendRequest<boolean>('/batchcreate', 'post', { activityType, propertyIds, startTimes, publishTimes });
  }

  createBookingRequests(occurances: ActivityOccuranceDocument[]) {
    return this.sendRequest<boolean>('/createBookingRequests', 'post', { occurances });
  }

  findBookableActivities(
    periodStart: Date,
    periodEnd: Date,
    options: { properties?: Types.ObjectId[]; activityTypes?: Types.ObjectId[] } = {},
  ) {
    return this.sendRequest<ActivityOccuranceDocument[]>('/findBookableActivities', 'post', {
      periodStart,
      periodEnd,
      options,
    });
  }

  datesWithEventsThatCanBeBooked(
    options: { properties?: Types.ObjectId[]; activityTypes?: Types.ObjectId[]; month?: string } = {},
  ) {
    return this.sendRequest<{ availableDates: string[]; fullyBookedDates: string[] }>(
      '/datesWithEventsThatCanBeBooked_v2',
      'post',
      { options },
    );
  }

  deleteOccurances(ids: string[]) {
    return this.sendRequest<boolean>('/deleteOccurances', 'post', { ids });
  }

  publishOccurances(ids: string[], publish: boolean) {
    return this.sendRequest<boolean>('/publishOccurances', 'post', { ids, publish });
  }

  setWorkingStaff(
    occuranceId: ISetWorkingStaffRequest['occuranceId'],
    wantedWorkingStaff: ISetWorkingStaffRequest['wantedWorkingStaff'],
  ) {
    return this.sendRequest<Types.ObjectId[]>('/setWorkingStaff', 'post', {
      occuranceId,
      wantedWorkingStaff,
    } as ISetWorkingStaffRequest);
  }

  updateWorkingStaff(
    occuranceId: ISetWorkingStaffRequest['occuranceId'],
    addedStaff: ISetWorkingStaffRequest['addedStaff'],
    removedStaff: ISetWorkingStaffRequest['removedStaff'],
  ) {
    return this.sendRequest<Types.ObjectId[]>('/setWorkingStaff', 'post', {
      occuranceId,
      addedStaff,
      removedStaff,
    } as ISetWorkingStaffRequest);
  }

  setRequestedStaff(occuranceId: Types.ObjectId, employees: String[]) {
    return this.sendRequest<RequestedStaffDocument[]>('/setRequestedStaff', 'post', { id: occuranceId, employees });
  }

  updateNotes(id: string, notes: string) {
    return this.sendRequest<boolean>('/updateNotes', 'post', { id: id, notes: notes });
  }

  assignEmployeeToActivity(id: string, employeeId: string) {
    return this.sendRequest<any>('/assignEmployeeToActivity', 'post', { id: id, employeeId: employeeId });
  }

  getOrganizationAndCrossSellingActivities = (startDate: Date, endDate: Date) => {
    return this.sendRequest<any>('/getOrganizationAndCrossSellingActivities', 'post', { startDate, endDate });
  };

  getPropertyOccurances = (startDate: Date, endDate: Date, propertyId?: String) => {
    return this.sendRequest<any>('/getPropertyOccurances', 'post', { startDate, endDate, property: propertyId });
  };

  getWithPopulatedProperty = (id: Types.ObjectId) => {
    return this.sendRequest<PopulatedOccurance>('/getWithPopulatedProperty', 'post', { id });
  };

  getWithPopulatedBookings = (id: Types.ObjectId) => {
    return this.sendRequest<ActivityOccuranceDocument>('/getWithPopulatedBookings', 'post', { id });
  };

  findWithPopulatedBookings = (query: object) => {
    return this.sendRequest<ActivityOccuranceDocument[]>('/findWithPopulatedBookings', 'post', { query });
  };
}

type PopulatedOccurance = Omit<ActivityOccuranceDocument, 'property'> & {
  property: PropertyDocument;
};
