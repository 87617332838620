import React, { FC } from 'react';
import { HashRouter } from 'react-router-dom';
import { SessionProvider } from './session.context';
import { WidgetWrapper, Props } from './widgetWrapper';

export const Widget: FC<Props> = (props) => {
  return (
    <HashRouter>
      <SessionProvider>
        <WidgetWrapper {...props} />
      </SessionProvider>
    </HashRouter>
  );
};
