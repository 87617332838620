import * as React from 'react';
import { formatDuration, LocaleContext } from '../_dependencies';

interface Props {
  title: string;
  duration: number;
  isTmeTicket?: boolean;
  //Låser children till en mer specifik komponent likt activityItem (acticity card används inte till andra element)
  children: React.ReactElement<Props>[];
}

/**Wrapping card for event start times. */
const ActivityCard: React.FC<Props> = ({ title, duration, children, isTmeTicket }) => {
  const { t, locale } = React.useContext(LocaleContext);

  return (
    <div className={`ui fluid  compact card`}>
      <div className="ui compact grid content">
        <div style={{ margin: 0, padding: 0, textAlign: 'center', display: 'flex', alignItems: 'center' }}>
          <h4 className={'ui header'} style={{ textAlign: 'center' }}>
            {title}
          </h4>
        </div>
        <div className="row">
          <div
            className="six wide column"
            style={{ marginLeft: 0, padding: 0, marginTop: '-10px', whiteSpace: 'nowrap' }}
          >
            {isTmeTicket ? <strong>{t('Validity')}: </strong> : <strong>{t('Time')}: </strong>}
            {formatDuration(duration, locale)}
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'grid',
            gridGap: '10px',
            gridTemplateColumns: `repeat(auto-fill, minmax(110px, 6fr) )`,
            padding: '0',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
