import Fuse from 'fuse.js';
import Lodash from 'lodash';
import Moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import {
  ActivityOccuranceDocument,
  BookingDocument,
  BookingResource,
  DestinationResource,
  DeviceProvider,
  GlobalsContext,
  MarketplaceResource,
  PaymentResource,
  ProductPaymentInfo,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { BookingItem } from './bookingItem';
import { Cards } from './cards';
import { SearchParams } from './filtering';
import StatisticsGenerator from './statistics/index';
import { Table } from './table';

const INCREMENT_SHOWING_N = 10;

// If value is null, that means a fetch has been issued
export type PaymentInfoMap = { [id: string]: ProductPaymentInfo | null };

export interface BookingContext {
  bookings: Array<BookingDocument>;
  paymentInfo: PaymentInfoMap;
  deletePaymentInfoForId: (id: string) => void;
}

export const BookingContext: React.Context<BookingContext> = React.createContext({} as any);

interface Props {
  findBookings: (options: {
    fromDate?: Date;
    toDate?: Date;
    getDeletedBookings?: boolean;
    getRequestBookings?: boolean;
  }) => Promise<[BookingDocument]>;
  renderBookingList: (
    setSearchParams: (searchParams: SearchParams) => void,
    setPeriod: (fromDate?: Date | undefined, toDate?: Date | undefined) => void,
    setShowOnlyDeletedBookings: (showOnlyDeletedBookings: boolean) => void,
    setShowRequestBookings: (showOnlyRequestBookings: boolean) => void,
    setShowUninvoicedBookings: (showUninvoicedBookings: boolean) => void,
    showRequestBookings?: boolean,
    showOnlyDeletedBookings?: boolean,
    showUninvoicedBookings?: boolean,
    toDate?: Date,
    fromDate?: Date,
  ) => JSX.Element;
  renderHeader: () => JSX.Element;
  renderCrossSellingButtons?: (
    isPartOfDestination: boolean,
    showStatisticsPdf: boolean,
    invoiceLoader: boolean,
    filteredBookings: BookingDocument[],
    generatePdf: () => void,
    markAsInvoiced: (invoiced: boolean, bookingList: BookingDocument[]) => void,
  ) => JSX.Element | null;
}
export const HandleBookingsComponent = ({
  findBookings,
  renderBookingList,
  renderHeader,
  renderCrossSellingButtons,
}: Props) => {
  const device = React.useContext(DeviceProvider.Context);
  const { t, tt } = useLocale();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    query: '',
    onlyCrossSelling: false,
    organization: undefined,
    activityList: undefined,
  });
  const [fromDate, setFromDate] = useState<Date | undefined>(Moment().subtract(1, 'month').toDate());
  const [toDate, setToDate] = useState<Date | undefined>(new Date());

  const [showDeletedBookings, setShowDeletedBookings] = useState<boolean>();
  const [showRequestBookings, setShowRequestBookings] = useState<boolean>();
  const [showUninvoicedBookings, setShowUninvoicedBookings] = useState<boolean>();

  const [showingN, setShowingN] = useState(INCREMENT_SHOWING_N);
  const [bookings, setBookings] = useState<BookingDocument[]>([]);
  const [deletedBookingsToBeInvoiced, setDeletedBookingsToBeInvoiced] = useState<BookingDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfoMap>({});
  const [isPartOfDestination, setIsPartOfDestination] = useState<boolean>(false);
  const [invoiceLoader, setInvoiceLoader] = useState<boolean>(false);
  const [formEdited, setFormEdited] = useState<boolean>(false);
  const [showStatisticsPdf, setShowStatisticsPdf] = useState<boolean>(false);

  const globals = React.useContext(GlobalsContext);

  const filteredBookings = React.useMemo(() => filterBookings(bookings), [bookings, searchParams]);
  const filteredDeletedBookingsToBeInvoiced = React.useMemo(
    () => filterBookings(deletedBookingsToBeInvoiced),
    [deletedBookingsToBeInvoiced, searchParams],
  );

  const setPaymentInfoForId = (id: string, value: ProductPaymentInfo | null) => {
    setPaymentInfo((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const deletePaymentInfoForId = (id: string) => {
    const newPaymentInfo = { ...paymentInfo };
    delete newPaymentInfo[id];
    setPaymentInfo(newPaymentInfo);
  };

  // TODO Handle error
  const fetchPaymentInfoForId = async (id: string) => {
    try {
      const legacyInfo = await new PaymentResource().getPaidAmountOfBooking(id);
      const marketplaceInfo = await new MarketplaceResource().getPaidAmountForProduct(id);
      const info: typeof marketplaceInfo = {
        amountPaidManually: legacyInfo.amountPaidManually + marketplaceInfo.amountPaidManually,
        amountPaidWithGiftCard: legacyInfo.amountPaidWithGiftCard + marketplaceInfo.amountPaidWithGiftCard,
        amountPaidWithStripe: legacyInfo.amountPaidWithStripe + marketplaceInfo.amountPaidWithStripe,
        application_fee: legacyInfo.application_fee || marketplaceInfo.application_fee,
        productValue: marketplaceInfo.productValue,
        legacy: marketplaceInfo.legacy,
      };
      setPaymentInfoForId(id, info);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!filteredBookings) return;
    for (let i = 0; i < filteredBookings.length; i++) {
      fetchPaymentInfoForId(filteredBookings[i].id);
      if (i >= showingN) {
        break;
      }
    }
    setFormEdited(false);
  }, [filteredBookings, showingN, formEdited]);

  // Fetch more effect
  useEffect(() => {
    if (!toDate) return;
    async function fetchBookings() {
      setLoading(true);
      const findOpts = {
        fromDate,
        toDate,
        getDeletedBookings: showDeletedBookings,
        getRequestBookings: showRequestBookings,
        getUninvoicedBookings: showUninvoicedBookings,
      };
      const bookings = await findBookings(findOpts);
      const deletedBookingsForInvoice = await new BookingResource().getDeletedCrossSellingBookingsToInvoice(findOpts);
      setDeletedBookingsToBeInvoiced(deletedBookingsForInvoice);
      setBookings(bookings);
      setLoading(false);
    }
    fetchBookings();
  }, [
    toDate,
    fromDate,
    showDeletedBookings,
    showRequestBookings,
    showUninvoicedBookings,
    setBookings,
    setDeletedBookingsToBeInvoiced,
  ]);

  // Sets isCrossSelling
  useEffect(() => {
    (async function checkIsCrossSelling() {
      const dResource = new DestinationResource();
      const isPart = await dResource.isPartOfDestination(globals.session.currentOrganizationId);
      setIsPartOfDestination(isPart);
    })();
  }, []);

  const bookingEdited = (boolean: boolean) => {
    setFormEdited(boolean);
  };

  function handleTranslatableText(bookings: BookingDocument[]) {
    const fuse = new Fuse(bookings, {
      threshold: 0.4,
      minMatchCharLength: 2,
      keys: [
        'message',
        'number',
        'notes',
        'totalPrice',
        'createdAt',
        'crossSellingProperty.name',
        'customer.firstname',
        'customer.lastname',
        'customer.businessName',
        'customer.email',
        'occurance.start',
        'occurance.title',
        'occurance.property.name',
      ],
      getFn: (document, path: string[]) =>
        tt(path.reduce((object, path) => (object ? object[path] : object), document)),
    });

    return fuse.search(searchParams.query).map((fuseDocument) => fuseDocument.item);
  }

  // #region Filter bookings function
  function filterBookings(bookings: BookingDocument[]) {
    setShowingN(INCREMENT_SHOWING_N);
    let filteredBookinglist: BookingDocument[] = bookings;

    // FILTER FREETEXT QUERY
    if (searchParams.query) {
      filteredBookinglist = handleTranslatableText(bookings);
    }

    // FILTER ONLY CROSSELLING
    if (searchParams.onlyCrossSelling) {
      filteredBookinglist = filteredBookinglist.filter((booking) => {
        return booking.crossSellingProperty;
      });

      // FILTER BY ORGANIZATION
      if (searchParams.organization) {
        filteredBookinglist = filterOrganization(filteredBookinglist);
      }
    }

    // FILTER BY ACTIVITIES
    if (searchParams.activityList) {
      filteredBookinglist = filterActivities(filteredBookinglist);
    }

    return filteredBookinglist;
  }
  // #endregion

  // Filter a list of booking by the set organization in searchParams
  function filterOrganization(bookingList: BookingDocument[]) {
    return bookingList.filter((booking) => {
      return booking.crossSellingProperty?.organization == searchParams.organization!._id;
    });
  }

  // Filter a list of booking by the set activityType in searchParams
  function filterActivities(bookingList) {
    return bookingList.filter((booking) => {
      return searchParams.activityList?.find((activity) => {
        // originatingActivity is does not exist if occurance for booking is deleted
        if (!booking.occurance) {
          return false;
        }
        return (booking.occurance as any as ActivityOccuranceDocument).originatingActivity == activity._id;
      });
    });
  }

  const onBookingRemoved = (doc: BookingDocument) => {
    setBookings((prevState) => prevState.filter((x) => x.id != doc.id));
  };

  const generatePdf = () => {
    setShowStatisticsPdf(!showStatisticsPdf);
  };

  const markBookingInvoiced = async (invoiced: boolean, bookingList: BookingDocument[]) => {
    setInvoiceLoader(true);

    const allBookings = Lodash.cloneDeep(bookings);
    const bookingsToUpdate = Lodash.cloneDeep(bookingList);

    const bookingResource = new BookingResource();

    const updateCalls: Promise<string>[] = [];

    bookingsToUpdate.forEach((booking) => {
      if (booking.crossSellingProperty) {
        booking.isInvoiced = invoiced;
        updateCalls.push(bookingResource.updateDocument(Lodash.cloneDeep(booking)));
      }
    });

    await Promise.all(updateCalls);

    const updatedBookingList = allBookings.map((originatingBooking) => {
      const foundUpdatedBooking = bookingsToUpdate.find((updatedBooking) => originatingBooking.id == updatedBooking.id);
      if (foundUpdatedBooking) {
        return foundUpdatedBooking;
      }
      return originatingBooking;
    });

    setBookings(updatedBookingList);

    setInvoiceLoader(false);
  };

  const renderList = () => {
    const bookingList = filteredBookings.slice(0, showingN);

    const mobileList: JSX.Element = (
      <Cards>
        {bookingList.map((x) => (
          <BookingItem
            onRemoved={onBookingRemoved}
            onMarkInvoiced={markBookingInvoiced}
            booking={x}
            paymentInfo={paymentInfo[x.id]}
            key={x.id}
            edited={bookingEdited}
          />
        ))}
      </Cards>
    );

    const desktopList: JSX.Element = (
      <Table>
        {bookingList.map((x) => (
          <BookingItem
            onRemoved={onBookingRemoved}
            onMarkInvoiced={markBookingInvoiced}
            booking={x}
            paymentInfo={paymentInfo[x.id]}
            key={x.id}
            edited={bookingEdited}
          />
        ))}
      </Table>
    );

    return (
      <BookingContext.Provider
        value={{
          bookings,
          paymentInfo,
          deletePaymentInfoForId,
        }}
      >
        {!loading && (device.size == 'desktop' ? desktopList : mobileList)}
        {!filteredBookings.length && !loading && <h3>{t('no-bookings-found')}...</h3>}
      </BookingContext.Provider>
    );
  };

  const renderLoadMoreBtn = () => {
    if (!loading && filteredBookings.length > showingN) {
      return (
        <button className="fluid ui button" onClick={() => setShowingN(showingN + INCREMENT_SHOWING_N)}>
          {t('Show more')}
        </button>
      );
    }
  };

  const renderStatistics = () => {
    return (
      <StatisticsGenerator
        bookings={[...filteredBookings, ...filteredDeletedBookingsToBeInvoiced]}
        fromDate={fromDate}
        toDate={toDate}
      />
    );
  };

  const renderLoader = () => {
    if (loading) {
      return <div className="large ui inline centered active loader" />;
    }
  };

  //stop this from running if the locale changes
  const setPeriod = (fromDate?: Date, toDate?: Date) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const toggleDeletedBookings = (showDeletedBookings: boolean) => {
    setShowDeletedBookings(showDeletedBookings);
  };

  const toggleRequestBookings = (showRequestBookings: boolean) => {
    setShowRequestBookings(showRequestBookings);
  };

  const toggleUninvoicedBookings = (showUninvoicedBookings: boolean) => {
    setShowUninvoicedBookings(showUninvoicedBookings);
  };

  return (
    <div className="ui basic segment" style={{ padding: '2em' }}>
      {renderHeader()}
      {renderBookingList(
        setSearchParams,
        setPeriod,
        toggleDeletedBookings,
        toggleRequestBookings,
        toggleUninvoicedBookings,
        showRequestBookings,
        showDeletedBookings,
        showUninvoicedBookings,
        toDate,
        fromDate,
      )}
      {renderCrossSellingButtons &&
        renderCrossSellingButtons(
          isPartOfDestination,
          showStatisticsPdf,
          invoiceLoader,
          filteredBookings,
          generatePdf,
          markBookingInvoiced,
        )}
      <div className="ui divider" style={{ marginBottom: '2em' }} />
      {showStatisticsPdf ? renderStatistics() : renderList()}
      {!showStatisticsPdf && renderLoadMoreBtn()}
      {renderLoader()}
    </div>
  );
};
