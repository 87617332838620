import Lodash from 'lodash';
import { computed } from 'mobx';
import * as React from 'react';
import { Consume, LocaleContext, UserDocument } from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { AbstractEmployeesList } from './abstractEmployeesList';

export type AssignedEmployeesListActionType = 'remove-selected-from-working';
interface Props {
  onActionTriggered(type: AssignedEmployeesListActionType, usersSelectedForAction: UserDocument[]): void;
}

export class AssignedEmployeesList extends AbstractEmployeesList<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  /* Returns the employees assigned to work */
  @computed protected get filteredEmployeesList() {
    return Lodash.filter(this.props.propertyEmployees, (employee) =>
      Lodash.includes(this.props.event.workingStaff, employee._id),
    );
  }
  @computed protected get emptyListMessage() {
    const { t } = this._locale;
    return t('components.calendar.views.assignEmployees.assignedEmployeesList.messageOne');
  }

  private triggerRemoveSelectedFromListAction = () =>
    this.props.onActionTriggered('remove-selected-from-working', this.employeeSelectedForAction);

  private get actions() {
    const { t } = this._locale;
    if (this.isAnyEmployeeSelected) {
      return (
        <button className="ui small fluid icon button" onClick={this.triggerRemoveSelectedFromListAction}>
          <i className="remove icon" />{' '}
          {t('components.calendar.views.assignEmployees.assignedEmployeesList.instructionOne')}
        </button>
      );
    }
  }

  render() {
    const { t } = this._locale;
    return (
      <div>
        <div className="ui horizontal divider">{t('Scheduled personnel')}</div>
        {this.listComponent}
        {this.actions}
      </div>
    );
  }
}
