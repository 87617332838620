import {
  OrderId,
  PaymentDocument,
  PaymentPartition,
  ProductDocument,
  ProductEvent,
  ProductFees,
  ProductId,
} from './models';
import { Fetcher } from './utilities';

type CreateProductParams = {
  value: number;
  externalId: string;
  externalOrgId: string;
  offerId?: string;
  ownedBy?: string;
  fees?: Partial<ProductFees>;
};

export const isCreateProductResponse = (obj: any | CreateProductResponse): obj is CreateProductResponse => {
  return (
    obj !== null &&
    typeof obj.productId === 'string' &&
    typeof obj.description === 'string' &&
    typeof obj.value === 'number'
  );
};

export type CreateProductResponse = ProductEvent & {
  productId: ProductId;
};

type AppendEventParams = {
  description: string;
  value: number;
  metadata?: { [key: string]: any };
};

export type AppendEventResponse = ProductEvent & {
  productId: ProductId;
  newProductValue: number;
};

export type ExtendedPartition = PaymentPartition & {
  type: 'stripe' | 'refund' | 'giftcard' | 'manual';
  status: 'incomplete' | 'completed' | 'denied';
};

export type ProductStatusResponse = {
  paymentStatus: PaymentStatus;
  paidAmount: number;
  productValue: number;
  partitions: ExtendedPartition[];
};

export type PaymentStatus = 'Partially paid' | 'Not paid' | 'Fully paid' | 'Over paid' | 'Processing transfers';

export type ProductInformationResponse = {
  product: ProductDocument;
  payments: PaymentDocument[];
  orderId: string;
};

type CreateProductFn = (id: OrderId, params: CreateProductParams) => Promise<CreateProductResponse>;
type AppendEventFn = (externalId: string, params: AppendEventParams) => Promise<AppendEventResponse>;
type RemoveProductFn = (externalId: string) => Promise<'Product was removed'>;
type ProductStatusFn = (externalId: string) => Promise<ProductStatusResponse>;
type ProductInformationFn = (externalId: string) => Promise<ProductInformationResponse>;
type ProductInformationBatchFn = (externalIds: string[]) => Promise<ProductInformationResponse[]>;

export type ProductApi = {
  /** Creates a product and attaches it to an existing order */
  create: CreateProductFn;
  /** Creates an event that updates an existing product */
  update: AppendEventFn;
  /** Removes an existing product */
  remove: RemoveProductFn;
  /** Retieves the payment status for an existing product */
  status: ProductStatusFn;
  /** Retieves the product document for an existing product */
  information: ProductInformationFn;
  /** Retieves multiple product documents from a list of products ids */
  informationBatch: ProductInformationBatchFn;
};

export const Product = (fetcher: Fetcher): ProductApi => {
  const create: CreateProductFn = async (id, params) => {
    const res = await fetcher('/function/append-product-to-order', {
      method: 'POST',
      body: JSON.stringify({
        orderId: id,
        product: params,
      }),
    });
    const data: CreateProductResponse = await res.json();
    switch (res.status) {
      case 200:
        return data;
      default:
        throw data;
    }
  };
  const update: AppendEventFn = async (id, params) => {
    const res = await fetcher('/function/append-event-to-product', {
      method: 'POST',
      body: JSON.stringify({
        externalId: id,
        customEvent: params,
      }),
    });
    const data: AppendEventResponse = await res.json();
    switch (res.status) {
      case 200:
        return data;
      default:
        throw data;
    }
  };
  const remove: RemoveProductFn = async (id) => {
    const res = await fetcher(`/function/remove-product?externalId=${id}`, {
      method: 'DELETE',
    });
    const data: {} = await res.json();
    switch (res.status) {
      case 200:
        return 'Product was removed';
      default:
        throw data;
    }
  };

  const status: ProductStatusFn = async (id) => {
    const res = await fetcher(`/function/retrieve-product-payment-status?externalId=${id}`, {
      method: 'GET',
    });
    const data: ProductStatusResponse = await res.json();
    switch (res.status) {
      case 200:
        return data;
      default:
        throw data;
    }
  };

  const information: ProductInformationFn = async (id) => {
    const res = await fetcher(`/function/retrieve-product-information?externalId=${id}`, {
      method: 'GET',
    });
    const data: ProductInformationResponse = await res.json();
    switch (res.status) {
      case 200:
        return data;
      default:
        throw data;
    }
  };
  const informationBatch: ProductInformationBatchFn = async (externalIds) => {
    const res = await fetcher(`/function/retrieve-product-information-batch`, {
      method: 'POST',
      body: JSON.stringify({ externalIds }),
    });
    const data: ProductInformationResponse[] = await res.json();
    switch (res.status) {
      case 200:
        return data;
      default:
        throw data;
    }
  };

  return {
    status,
    create,
    update,
    remove,
    information,
    informationBatch,
  };
};
