import { OrderDocument, OrderId } from './models';
import { Fetcher } from './utilities';

//#region aliases
type PaymentStatus = 'Not paid' | 'Fully paid' | 'Partially paid';
//#endregion

export type OrderInformation = OrderDocument & {
  paymentStatus: PaymentStatus;
};

type CreateOrderFn = () => Promise<OrderId>;
type OrderStatusFn = (id: OrderId) => Promise<OrderInformation>;
type RemoveOrderFn = (id: OrderId) => Promise<'Order deleted'>;

export type OrderApi = {
  /** Creates a new order object. */
  create: CreateOrderFn;
  /** Retrieves an existing order. */
  status: OrderStatusFn;
  /** Removes an existing order. */
  remove: RemoveOrderFn;
};

export const Order = (fetcher: Fetcher): OrderApi => {
  const create: CreateOrderFn = async () => {
    const res = await fetcher('/function/create-order', {
      method: 'POST',
    });
    const data: { orderId: OrderId } = await res.json();
    switch (res.status) {
      case 201:
      case 200:
        return data.orderId;
      default:
        throw data;
    }
  };
  const status: OrderStatusFn = async (id) => {
    const res = await fetcher(`/function/retrieve-order-information?orderId=${id}`, {
      method: 'GET',
    });
    const data: OrderInformation = await res.json();
    switch (res.status) {
      case 200:
        return data;
      default:
        throw data;
    }
  };
  const remove: RemoveOrderFn = async (id) => {
    const res = await fetcher(`/function/remove-order?orderId=${id}`, {
      method: 'DELETE',
    });
    const data: { message: 'Order deleted' } = await res.json();
    switch (res.status) {
      case 200:
        return data.message;
      default:
        throw data;
    }
  };

  return {
    create,
    status,
    remove,
  };
};
