import React, { FC, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import {
  DestinationDocument,
  DeviceProvider,
  GlobalsContext,
  Link,
  PlaceDocument,
  PropertyDocument,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { ErrorBoundary } from '../../errorBoundary';
import { Maybe } from '../../maybe';
import { AccountActivationPrompt } from '../prompts/accountActivationPrompt';
import { useProperties } from './propertiesProvider';
import { PropertyStore } from './propertyStore';

export const PropertiesTemplate: FC = ({ children }) => {
  const { t, tt } = useLocale();
  const { session } = useContext(GlobalsContext);
  const history = useHistory();
  const { size } = useContext(DeviceProvider.Context);
  const { isLoading, properties, places, destinations, isSidebarOpen, setIsSidebarOpen, fetchAllData } =
    useProperties();
  const store = PropertyStore.Instance;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchAllData();
    initJquerySidebar();

    return () => setIsSidebarOpen(false);
  }, [session.currentOrganization]);

  useEffect(() => {
    if (!ref.current) return;
    if (isSidebarOpen) {
      $(ref.current).sidebar('show');
    } else {
      $(ref.current).sidebar('hide');
    }
  }, [isSidebarOpen]);

  const initJquerySidebar = () => {
    if (!ref.current) return;
    const component = $(ref.current);
    component.sidebar({
      context: component.parent().first(),
      closable: store.hasProperty(),
      dimPage: false,
      transition: 'push',
      mobileTransition: 'push',
    });
  };

  const getPropertyLinks = (properties: PropertyDocument[]) => {
    return properties.map((property) => {
      const hideSidebarAndSetProperty = () => {
        setIsSidebarOpen(false);
        store.setProperty(property);
      };

      return (
        <Link
          key={tt(property.name)}
          to={`/dashboard/properties/details/${property.id}/schedule`}
          onClick={hideSidebarAndSetProperty}
          className="item"
        >
          <div className="ui fluid button">{tt(property.name)}</div>
        </Link>
      );
    });
  };

  const getPlaceLinks = (places: PlaceDocument[]) => {
    return places.map((place) => {
      const hideSidebarOnMobile = () => size === 'mobile' && setIsSidebarOpen(false);

      return (
        <Link
          key={tt(place.name)}
          to={`/dashboard/properties/places/${place.id}`}
          className="item"
          onClick={hideSidebarOnMobile}
        >
          <div className="ui fluid button">{tt(place.name)}</div>
        </Link>
      );
    });
  };

  if (!session.currentOrganization.flags.features.properties) {
    history.replace('/dashboard');
    return null;
  }

  return (
    <ErrorBoundary>
      <div className="pushable">
        <div className="ui vertical left sidebar menu" style={{ display: 'block', minHeight: '100vh' }} ref={ref}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100%',
            }}
          >
            <div className="header item">
              <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('Your properties')}
                <Maybe if="organization.manager">
                  <Link to="/dashboard/properties/new" style={{ textDecoration: 'none' }}>
                    <i className="plus primary icon" />
                  </Link>
                </Maybe>
              </span>
              <div className="menu">
                {isLoading ? <div className="ui active centered inline loader" /> : getPropertyLinks(properties)}
              </div>
            </div>

            {destinations.length >= 0 &&
              destinations.map((destination: DestinationDocument) => (
                <div className="header item" key={destination.name}>
                  <span>
                    {t('destination')} {destination.name}
                  </span>
                  <div className="menu">
                    {isLoading ? (
                      <div className="ui active centered inline loader" />
                    ) : (
                      getPropertyLinks(destination.connectedProperties)
                    )}
                  </div>
                </div>
              ))}

            <div className="header item" style={{ flex: 1 }}>
              <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('Places')}
                <Maybe if="organization.manager">
                  <Link to="/dashboard/properties/places" style={{ textDecoration: 'none' }}>
                    <i className="plus primary icon" />
                  </Link>
                </Maybe>
              </span>
              <div className="menu">
                {isLoading ? <div className="ui active centered inline loader" /> : getPlaceLinks(places)}
              </div>
            </div>

            <div className="item">
              <Link to="/dashboard/properties/info" className="ui fluid basic button">
                Visa information
              </Link>
            </div>
          </div>
        </div>
        <div className="pusher">
          <AccountActivationPrompt />
          {children}
        </div>
      </div>
    </ErrorBoundary>
  );
};
