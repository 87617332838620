import Lodash from 'lodash';
import Moment from 'moment-timezone';
import { Types } from 'mongoose';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  BookingDocument,
  NotificationResource,
  OrganizationDocument,
  OrganizationResource,
  PropertyDocument,
  PropertyResource,
} from '../resources';
import { BookingPriceCategoryDocument, visitors } from '../schemas';
import { tt } from '../utilities';

interface BookingCreateOptions {
  booking: BookingDocument;
  organization?: OrganizationDocument;
  locale: Locale;
}

export const sendBookingCreatedNotification = async ({ booking, organization, locale }: BookingCreateOptions) => {
  type PopulatedOccurance = Omit<ActivityOccuranceDocument, 'property'> & {
    property: PropertyDocument;
  };
  const occurance = await new ActivityOccuranceResource().getWithPopulatedProperty(booking.occurance);
  if (!occurance) {
    throw new Error(`No occurance was found that matches id ${booking.occurance}`);
  }
  const resource = new NotificationResource();
  const notification = resource.createNotification(
    occurance.property._id,
    `/dashboard/schedule?date=${Moment(occurance.start)
      .tz('Europe/Stockholm')
      .format('YYMMDD')}&view=week&occuranceId=${occurance._id}&bookingId=${booking._id}`,
    {
      translationKey: organization?.name
        ? 'Booking was created by'
        : 'Booking request was created by one of your customers',
      data: organization?.name || '',
    },
    {
      icon: 'star outline icon',
      content: {
        translationKey: 'activity',
        data: tt(occurance.title, locale),
      },
    },
    {
      icon: 'map pin icon',
      content: {
        translationKey: 'components.dashboard.bookings.bookingItem.bookingInfo.contentOne',
        data: tt(occurance.property.name, locale),
      },
      link: `/dashboard/properties/details/${occurance.property._id}`,
    },
    {
      icon: 'wait icon',
      content: {
        translationKey: 'Start time',
        data: Moment(occurance.start).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm'),
      },
    },
    {
      icon: 'user icon',
      content: {
        translationKey: 'Customer',
        data: `${booking.customer.firstname} ${booking.customer.lastname}`,
      },
    },
  );
  await resource.updateDocument(notification);
};

export const createBookingRemovedNotification = async (
  booking: BookingDocument,
  userOrganization: OrganizationDocument,
  locale: Locale,
) => {
  // Make sure that the booking has a crossSellingProperty
  if (booking.crossSellingProperty === undefined) {
    throw new Error('Cannot send a booking removed notification when crossSellingProperty is undefined');
  }
  const occurance = booking.occurance as unknown as ActivityOccuranceDocument;
  const property = occurance.property as unknown as PropertyDocument;
  const organization = await new OrganizationResource().get(occurance.organization);
  if (!organization) {
    throw new Error(`No organization with id ${occurance.organization} was found`);
  }

  const getNotificationSenderAndRecipient = async (crossSellingProperty: Types.ObjectId) => {
    // If crossSellingProperty is within user organization, send notification to the activity owner
    const myProperties = await new PropertyResource().getAdministratedProperties();
    const myPropertiesIds = myProperties.map((p) => p._id);
    if (myPropertiesIds.includes(crossSellingProperty)) {
      return {
        recipient: property._id,
        sender: userOrganization.name,
      };
    }
    return {
      recipient: crossSellingProperty,
      sender: organization.name,
    };
  };

  const NotificationSenderAndRecipient = await getNotificationSenderAndRecipient(booking.crossSellingProperty._id);

  const resource = new NotificationResource();

  const notification = resource.createNotification(
    NotificationSenderAndRecipient.recipient,
    `/dashboard/schedule?date=${Moment(occurance.start).tz('Europe/Stockholm').format('YYMMDD')}&view=week`,
    {
      translationKey: 'Booking was removed by',
      data: NotificationSenderAndRecipient.sender,
    },

    {
      icon: 'star outline icon',
      content: {
        translationKey: 'activity',
        data: tt(occurance.title, locale),
      },
    },
    {
      icon: 'map pin icon',
      content: {
        translationKey: 'components.dashboard.bookings.bookingItem.bookingInfo.contentOne',
        data: tt(property.name, locale),
      },
      link: `/dashboard/properties/details/${property._id}`,
    },
    {
      icon: 'wait icon',
      content: {
        translationKey: 'Start time',
        data: Moment(occurance.start).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm'),
      },
    },
    {
      icon: 'user icon',
      content: {
        translationKey: 'Customer',
        data: `${booking.customer.firstname} ${booking.customer.lastname}`,
      },
    },
  );
  await resource.updateDocument(notification);
};

export const createBookingChangedVisitorsNotification = async (
  booking: BookingDocument,
  userOrganization: OrganizationDocument,
  currentPriceCategories: BookingPriceCategoryDocument[],
  locale: Locale,
) => {
  // Make sure that the booking has a crossSellingProperty
  if (booking.crossSellingProperty === undefined) {
    throw new Error('Cannot send a booking removed notification when crossSellingProperty is undefined');
  }
  const newAmountOfVisitors = Lodash.sumBy(currentPriceCategories || [], (pc) => visitors(pc));
  const occurance = booking.occurance as unknown as ActivityOccuranceDocument;
  const property = occurance.property as unknown as PropertyDocument;
  const organization = await new OrganizationResource().get(occurance.organization);
  if (!organization) {
    throw new Error(`No organization with id ${occurance.organization} was found`);
  }

  const getNotificationSenderAndRecipient = async (crossSellingProperty: Types.ObjectId) => {
    // If crossSellingProperty is within user organization, send notification to the activity owner
    const myProperties = await new PropertyResource().getAdministratedProperties();
    const myPropertiesIds = myProperties.map((p) => p._id);
    if (myPropertiesIds.includes(crossSellingProperty)) {
      return {
        recipient: property._id,
        sender: userOrganization.name,
      };
    }
    return {
      recipient: crossSellingProperty,
      sender: organization.name,
    };
  };

  const NotificationSenderAndRecipient = await getNotificationSenderAndRecipient(booking.crossSellingProperty._id);

  const resource = new NotificationResource();

  const notification = resource.createNotification(
    NotificationSenderAndRecipient.recipient,
    `/dashboard/schedule?date=${Moment(occurance.start).tz('Europe/Stockholm').format('YYMMDD')}&view=week`,
    {
      translationKey: 'Booking amount of visitors was changed by',
      data: NotificationSenderAndRecipient.sender,
    },

    {
      icon: 'star outline icon',
      content: {
        translationKey: 'activity',
        data: tt(occurance.title, locale),
      },
    },
    {
      icon: 'map pin icon',
      content: {
        translationKey: 'components.dashboard.bookings.bookingItem.bookingInfo.contentOne',
        data: tt(property.name, locale),
      },
      link: `/dashboard/properties/details/${property._id}`,
    },
    {
      icon: 'wait icon',
      content: {
        translationKey: 'Start time',
        data: Moment(occurance.start).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm'),
      },
    },
    {
      icon: 'user icon',
      content: {
        translationKey: 'Customer',
        data: `${booking.customer.firstname} ${booking.customer.lastname}`,
      },
    },
    {
      icon: 'exchange alternate icon',
      content: {
        translationKey: 'Change:',
        data: `${booking.bookedVisitors} → ${newAmountOfVisitors}`,
      },
    },
  );
  await resource.updateDocument(notification);
};
