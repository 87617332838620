import Lodash from 'lodash';
import * as React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { ActivityTypeDocument, PropertyDocument, LocationDocument } from '../../_dependencies';
import { Markers } from './markers';
import { UserMarker } from './userMarker';

interface Props {
  propertyActivityMap: { property: PropertyDocument; activityTypes: ActivityTypeDocument[] }[];
  selectedProperty: PropertyDocument | undefined;
  mapLocation: LocationDocument;
  onRef: (ref) => void;
  onZoomChanged: () => void;
  onOpenCard: (property: PropertyDocument) => void;
  userPosition?: Coordinates;
}

class GoogleMapsWrapper extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    const currentProperties = Lodash.map(this.props.propertyActivityMap, (v) => v.property.id);
    const nextProperties = Lodash.map(nextProps.propertyActivityMap, (v) => v.property.id);
    const currentMapLocation = this.props.mapLocation;
    const nextMapLocation = nextProps.mapLocation;
    const currentUserPosition = this.props.userPosition;
    const nextUserPosition = nextProps.userPosition;

    // User comparison
    const differentUserPosition = !Lodash.isEqual(currentUserPosition, nextUserPosition);

    // Map comparison
    const differentMap = !Lodash.isEqual(currentMapLocation, nextMapLocation);

    // Properties comparison
    const differentProperties = !Lodash.isEqual(currentProperties, nextProperties);

    return differentMap || differentProperties || differentUserPosition;
  }

  render() {
    let userPosition: LocationDocument | undefined = undefined;
    if (this.props.userPosition) {
      userPosition = { lat: this.props.userPosition.latitude, lng: this.props.userPosition.longitude };
    }

    return (
      <GoogleMap
        defaultZoom={4}
        defaultCenter={this.props.mapLocation}
        center={this.props.mapLocation}
        options={{ scrollwheel: false }}
        onZoomChanged={this.props.onZoomChanged}
        ref={this.props.onRef}
      >
        <MarkerClusterer averageCenter enableRetinaIcons gridSize={52}>
          <Markers
            selectedProperty={this.props.selectedProperty}
            onOpenCard={this.props.onOpenCard}
            propertyActivityMap={this.props.propertyActivityMap || []}
          />
        </MarkerClusterer>
        <UserMarker userPosition={userPosition} />
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(GoogleMapsWrapper));
