import { action, computed, observable } from 'mobx';
import { PropertyDocument } from '../../_dependencies';
import { SingletonStore } from '../singletonStore';

class MapStore extends SingletonStore {
  @observable private _selectedProperty?: PropertyDocument;
  @observable private _currentOccuranceId?: string;

  @computed public get selectedProperty() {
    return this._selectedProperty;
  }

  @computed public get currenctOccuranceId() {
    return this._currentOccuranceId;
  }

  @action public selectProperty(property: PropertyDocument, currentOccuranceId?: string) {
    this._selectedProperty = property;
    this._currentOccuranceId = currentOccuranceId;
  }

  @action public deselectProperty() {
    this._selectedProperty = undefined;
    this._currentOccuranceId = undefined;
  }

  public isPropertySelected(property: PropertyDocument) {
    return this._selectedProperty && this._selectedProperty.id == property.id;
  }
}

export default MapStore.Instance as MapStore;
