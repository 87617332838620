import * as LocalStorage from 'local-storage'; // TODO: Can we trust that local storage always exists?
import Lodash from 'lodash';
import { action, autorun, computed, extendObservable, observable, runInAction, toJS } from 'mobx';
import Moment from 'moment-timezone';
import { Types } from 'mongoose';
import { SlotInfo } from 'react-big-calendar';
import {
  ActivityOccuranceDocument,
  ActivityTypeResource,
  Alert,
  BookingDocument,
  CalendarOccuranceDocument,
  CalendarOccuranceResource,
  OrganizationDocument,
  PropertyDocument,
  Store,
  UserDocument,
  UserResource,
  UserRoleDocument,
  tt,
} from '../../_dependencies';
import { globalCurrentLocale, globalT } from '../../_locales';
import { checkAccountActivation } from '../activationHelper';
import { View } from './utils/types';

export type CalendarContentMode = 'Personalöversikt' | 'Bokningsöversikt';

export interface CalendarStoreOptions {
  disabled?: boolean;
  filter?: any;
  resource?: CalendarOccuranceResource<CalendarOccuranceDocument>;
  endpoint?: any;
  defaultDate?: Date;
  defaultView?: View;
  allowedViews?: View[];
  isTimeSelectable?: boolean;
  showPropertyForSelectedEvent?: boolean;
  forcedDate?: Date;
  forcedView?: View;
  nrOfAgendaDays?: number;
  property?: PropertyDocument;
}

export class CalendarStore extends Store {
  @observable public isTimeSelectable: boolean;
  @observable public isTmeTicket: boolean | undefined;
  @observable private _employees: UserDocument[] = [];
  @observable private _hasOutdatedData: boolean;
  @observable private _selectedEvent: CalendarOccuranceDocument;
  @observable private _view: View;
  @observable public _downloadedEvents: CalendarOccuranceDocument[];
  @observable private _firstDownloadedDate?: Date;
  @observable private _lastDownloadedDate?: Date;
  @observable private _showPropertyForSelectedEvent: boolean;

  private _resource: CalendarOccuranceResource<CalendarOccuranceDocument>;
  private _endpoint: (start: Date, end: Date, propertyId?: Types.ObjectId) => Promise<ActivityOccuranceDocument[]>;
  private _nrOfAgendaDays: number;
  private _extendedEventFilter: any;
  private _activityTypeResource: ActivityTypeResource;

  @observable public disabled: boolean;
  @observable public allowedViews: View[];
  @observable public contentMode: CalendarContentMode;
  @observable public _date: Date;
  @observable public showBookedActivitiesOnly: boolean;
  @observable public showCrossSellingActivitesOnly: boolean;
  @observable public showOnlyRequestedBookings: boolean;
  @observable public filterCalenderByActivity: string;
  @observable public filterCalenderByEmployee = 'allOccurances';

  @observable private _isInMoveBookingProcess: boolean;
  @observable private _bookingToBeMoved?: BookingDocument;
  @observable private _occuranceToMoveBookingFrom?: ActivityOccuranceDocument;
  @observable private _property?: PropertyDocument;

  public set extendedEventFilter(filter: Object) {
    // TODO: Do the filtering clientside for better user experience!
    this._extendedEventFilter = filter;
    this._hasOutdatedData = true;
  }

  public get isInMoveBookingProcess() {
    return this._isInMoveBookingProcess;
  }

  public get bookingToBeMoved() {
    return this._bookingToBeMoved;
  }

  @action public beginMoveBookingProcess = (booking: BookingDocument) => {
    this.extendedEventFilter = {
      originatingActivity: (this.selectedEvent as ActivityOccuranceDocument).originatingActivity,
    };
    this.isTimeSelectable = false;
    this._bookingToBeMoved = booking;
    this._occuranceToMoveBookingFrom = this.selectedEvent as any;
    this._isInMoveBookingProcess = true;
  };

  @action public checkIfOccuranceIsTmeTicket = async () => {
    const originatingActivity = (this.selectedEvent as ActivityOccuranceDocument)?.originatingActivity;
    this._activityTypeResource
      ?.find({ _id: originatingActivity })
      .then((res) => (this.isTmeTicket = res[0].isTmeTicket));
  };

  @action public endMoveBookingProcess = () => {
    this.extendedEventFilter = {};
    this.isTimeSelectable = true;
    this._bookingToBeMoved = undefined;
    this._occuranceToMoveBookingFrom = undefined;
    this._isInMoveBookingProcess = false;
  };

  @computed public get occuranceToMoveBookingFrom() {
    return this._occuranceToMoveBookingFrom;
  }

  @computed public get usersInOrganization() {
    return toJS(this._employees);
  }

  @computed public get view(): View {
    return this._view;
  }

  @computed public get date(): Date {
    return this._date;
  }

  @computed public get selectedEvent(): CalendarOccuranceDocument {
    return this._selectedEvent;
  }

  @computed public get loading(): boolean {
    return !this.disabled && (this.isWithoutData || this.hasOutdatedData);
  }

  @computed public get displayedEvents() {
    const eventIsDisplayed = (event: CalendarOccuranceDocument): boolean => {
      const start = Moment(event.start);
      const end = Moment(event.end);
      return (
        start.isBetween(this.firstDisplayedDate, this.lastDisplayedDate, undefined, '[]') ||
        end.isBetween(this.firstDisplayedDate, this.lastDisplayedDate, undefined, '[]')
      );
    };

    let events = Lodash.filter(this._downloadedEvents, eventIsDisplayed);
    if (!!this.selectedEvent && !Lodash.includes(events, this.selectedEvent) && eventIsDisplayed(this.selectedEvent)) {
      events.push(this.selectedEvent);
    }

    if (this.showBookedActivitiesOnly) {
      events = events.filter((event) => {
        const activity = event as ActivityOccuranceDocument;
        return activity.bookedVisitors;
      });
    }

    if (this.showOnlyRequestedBookings) {
      events = events.filter((event) => {
        const activity = event as ActivityOccuranceDocument;
        return activity.hasCustomerRequestedBooking;
      });
    }

    if (this.showCrossSellingActivitesOnly) {
      events = events.filter((event) => {
        const activity = event as ActivityOccuranceDocument;
        return activity.organization !== this.globals.session.currentOrganizationId;
      });
    }
    if (this.filterCalenderByActivity && this.filterCalenderByActivity !== 'all') {
      events = events.filter((event) => tt(event.title, globalCurrentLocale()) === this.filterCalenderByActivity);
    }
    if (this.filterCalenderByEmployee && this.filterCalenderByEmployee !== 'allOccurances') {
      events = events.filter((event) => {
        const activity = event as ActivityOccuranceDocument;
        const employees = activity.workingStaff.map(String);
        return employees.includes(this.filterCalenderByEmployee);
      });
    }

    return events;
  }

  @computed public get firstDisplayedDate() {
    switch (this._view) {
      case 'agenda':
        return Moment(this._date).startOf('day').toDate();
      default:
        return Moment(this._date).startOf(this._view).toDate();
    }
  }

  @computed public get lastDisplayedDate() {
    switch (this._view) {
      case 'agenda':
        return Moment(this._date)
          .add(this._nrOfAgendaDays - 1, 'days')
          .endOf('day')
          .toDate();
      default:
        return Moment(this._date).endOf(this._view).toDate();
    }
  }

  public get wordings() {
    return {
      allDay: globalT('components.calendar.store.allDay'),
      previous: '<',
      next: '>',
      today: globalT('components.calendar.store.today'),
      month: globalT('components.calendar.store.month'),
      week: globalT('components.calendar.store.week'),
      day: globalT('components.calendar.store.day'),
      agenda: globalT('components.calendar.store.agenda'),
      date: globalT('Date'),
      time: globalT('Time'),
      event: globalT('Activity'),
      showMore: (remainingEvents) => remainingEvents + ' ' + globalT('components.calendar.store.toOccurrences'),
    };
  }

  @action public reload = () => {
    this._hasOutdatedData = true;
  };

  @action public navigateToToday = () => {
    this.navigateToDate(new Date());
  };

  @action public navigateToDate = (date: Date) => {
    this._date = date;
    this.updateStoredViewAndDate();
  };

  @action public navigateForward = () => {
    switch (this._view) {
      case 'agenda':
        this.navigateToDate(Moment(this._date).add(1, 'day').toDate());
        break;
      default:
        this.navigateToDate(Moment(this._date).add(1, this._view).toDate());
    }
  };

  @action public navigateBackward = () => {
    switch (this._view) {
      case 'agenda':
        this.navigateToDate(Moment(this._date).subtract(1, 'day').toDate());
        break;
      default:
        this.navigateToDate(Moment(this._date).subtract(1, this._view).toDate());
    }
  };

  @action public changeView = (view: View) => {
    this._view = view;
    this.updateStoredViewAndDate();

    // Switch to a view containing the selected event
    if (this._selectedEvent) {
      const selectedEventMoment = Moment(this._selectedEvent.start);

      if (selectedEventMoment.isBetween(this.firstDisplayedDate, this.lastDisplayedDate, undefined, '[]')) {
        // ...unless we have manually navigated away from it
        this.navigateToDate(selectedEventMoment.toDate());
      }
    }
  };

  @action public setEvents = (value: CalendarOccuranceDocument[]) => {
    this._downloadedEvents = value;
  };

  @action public setProperty = (value: PropertyDocument) => {
    this._property = value;
  };

  @action public reset = () => {
    this._firstDownloadedDate = undefined;
    this._lastDownloadedDate = undefined;
    this._downloadedEvents = [];
    this.deselectEvent();
  };

  @action public selectEvent = (event: CalendarOccuranceDocument) => {
    if (this._selectedEvent && this._selectedEvent === event) {
      return;
    }

    // Makes the selected events start and end properties observable
    extendObservable(event, {
      start: event.start,
      end: event.end,
      isNew: event.isNew,
      priceCategories: (event as ActivityOccuranceDocument).priceCategories,
    });

    if (!this._downloadedEvents || !Lodash.includes(this._downloadedEvents, event)) {
      this._downloadedEvents = this._downloadedEvents || [];
      this._downloadedEvents.push(event);
    }

    // Remove new events from downloaded events
    if (
      this._selectedEvent &&
      this._selectedEvent.isNew &&
      Lodash.includes(this._downloadedEvents || [], this._selectedEvent)
    ) {
      this.removeFromDowloadedEvents(this._selectedEvent);
    }

    this._selectedEvent = event;
  };

  @action public deselectEvent = () => {
    // Remove new events from downloaded events
    if (
      this._selectedEvent &&
      this._selectedEvent.isNew &&
      Lodash.includes(this._downloadedEvents || [], this._selectedEvent)
    ) {
      this.removeFromDowloadedEvents(this._selectedEvent);
    }

    this._selectedEvent = undefined as any;
    if (this._isInMoveBookingProcess) {
      this.endMoveBookingProcess();
    }
  };

  // TODO: remove this when documents have isDeleted
  @action public removeFromDowloadedEvents = (event: CalendarOccuranceDocument) => {
    Lodash.remove(this._downloadedEvents, event);
  };

  @action public handleTimeSelection = (selectedTime: Pick<SlotInfo, 'start' | 'end'>) => {
    const selectionDifferenceInDays = Moment(selectedTime.end).diff(Moment(selectedTime.start), 'days');

    // If we are in the month view we will want to move to eiher the day or week view
    if (this._view == 'month') {
      // Ignore selections that goes between different weeks
      if (Moment(selectedTime.start).week() != Moment(selectedTime.end).week()) {
        return;
      }
      this.changeView(selectionDifferenceInDays > 0 ? 'week' : 'day');
      this.navigateToDate(new Date(selectedTime.start));
      return;
    }

    // Verify that the organization is activated, if not activated we cant create events
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    if (!checkAccountActivation(organization)) {
      return;
    }

    // Otherwise we want to add a new event...

    // Ignore selections thats over a day long
    if (selectionDifferenceInDays > 0) {
      return;
    }

    // Ingore historic dates
    if (Moment(selectedTime.start).isBefore(Moment(), 'day')) {
      Alert.show(
        globalT('components.calendar.store.labelAlertMessage') + Moment(selectedTime.start).fromNow(),
        globalT('notification.too-late'),
      );
      return;
    }

    // Add a new event
    const event = this._resource.createDocument({
      title: '',
      start: selectedTime.start,
      end: selectedTime.end,
    });
    this.selectEvent(event);
  };

  public isEventSelected = (event: CalendarOccuranceDocument): boolean => {
    return !!this._selectedEvent && this._selectedEvent == event;
  };

  public isUserAllowedToEditEvent = (): boolean => {
    const activity = this.selectedEvent as ActivityOccuranceDocument;
    const property = this._property || (activity.property as unknown as PropertyDocument);

    const role: UserRoleDocument = {
      type: 'property.manager',
      property: property._id,
      organization: property.organization,
    };
    return this.globals.session.userHasRole(role);
  };

  constructor(options?: CalendarStoreOptions) {
    super();
    options = options || {};

    // Set default options
    this.contentMode =
      MODULE_ENVIRONMENT != 'node' &&
      this.globals.session.currentOrganization &&
      (this.globals.session.currentOrganization as OrganizationDocument).experimentalSövdeCalendar
        ? 'Personalöversikt'
        : 'Bokningsöversikt';

    this.isTimeSelectable = typeof options.isTimeSelectable !== 'undefined' ? options.isTimeSelectable : true;
    this.checkIfOccuranceIsTmeTicket();
    this._view = options.forcedView || this.StoredView || options.defaultView || 'month';
    this._date = options.forcedDate || this.StoredDate || options.defaultDate || new Date();
    this.disabled = options.disabled || false;
    this._resource = options.resource || new CalendarOccuranceResource();
    this._activityTypeResource = new ActivityTypeResource();
    this._endpoint = options.endpoint || null;
    this.allowedViews = options.allowedViews || ['month', 'week', 'day', 'agenda'];
    this._showPropertyForSelectedEvent = !!options.showPropertyForSelectedEvent;
    this._nrOfAgendaDays = options.nrOfAgendaDays || 31;
    this._property = options.property;

    // Automaticaly fetch events
    autorun(() => {
      if (this.loading) {
        // Fetch the occurances for complete months
        const startDate = Moment(this.firstDisplayedDate).startOf('month').toDate();
        const endDate = Moment(this.lastDisplayedDate).endOf('month').toDate();
        this._endpoint(startDate, endDate, this._property?._id)
          .then((results) => {
            runInAction(() => {
              this._downloadedEvents = results;
              this._firstDownloadedDate = startDate;
              this._lastDownloadedDate = endDate;
              this._hasOutdatedData = false;

              // Select previously selected event if any
              if (this._selectedEvent) {
                const previousEvent = Lodash.find(
                  this._downloadedEvents,
                  (event) => event.id == this._selectedEvent.id,
                );
                if (previousEvent) {
                  this.selectEvent(previousEvent);
                }
              }
            });
          })
          .then(() => {
            if (
              this.globals &&
              this.globals.session.userHasRole({
                type: 'organization.volunteer',
                organization: this.globals.session.currentOrganizationId,
              })
            ) {
              new UserResource()
                .find({ 'roles.organization': this.globals.session.currentOrganizationId })
                .then((users) => {
                  this._employees = users;
                });
            } else {
              this._employees = [];
            }
          });
      }
    });
  }

  @computed private get isWithoutData() {
    return !this._downloadedEvents && (!this._lastDownloadedDate || !this._firstDownloadedDate);
  }

  @computed private get hasOutdatedData() {
    // todo: remove ! markes with proper checks
    return (
      this._hasOutdatedData ||
      this.lastDisplayedDate > this._lastDownloadedDate! ||
      this.firstDisplayedDate < this._firstDownloadedDate!
    );
  }

  /**
   * Reads the view from the URL or from localstorage
   * TODO: everything with 'window' should be moved to a store, like session or device
   */
  private get StoredDate(): Date | undefined {
    if (MODULE_ENVIRONMENT == 'node') {
      return undefined;
    }
    if (!!this.URLSearchParamsData.date && this.URLSearchParamsData.date.length) {
      return Moment(this.URLSearchParamsData.date, 'YYMMDD').toDate();
    }
    if (!!LocalStorage.get('calendarDate') && LocalStorage.get('calendarDate').length) {
      return Moment(LocalStorage.get('calendarDate'), 'YYMMDD').toDate();
    }

    return undefined;
  }

  /**
   * Reads the date from the URL or from localstorage
   * TODO: everything with 'window' should be moved to a store, like session or device
   */
  private get StoredView(): View | undefined {
    if (MODULE_ENVIRONMENT == 'node') {
      return undefined;
    }
    if (!!this.URLSearchParamsData.view && this.URLSearchParamsData.view.length) {
      return this.URLSearchParamsData.view;
    }
    if (!!LocalStorage.get('calendarView') && LocalStorage.get('calendarView').length) {
      return LocalStorage.get('calendarView');
    }

    return undefined;
  }

  // TODO: everything with 'window' should be moved to a store, like session or device
  private updateStoredViewAndDate() {
    if (MODULE_ENVIRONMENT == 'node' || !this.globals.session.currentHistory) {
      return;
    }

    this.globals.session.setURLSearchParams({
      date: Moment(this.date).tz('Europe/Stockholm').format('YYMMDD'),
      view: this.view,
    });
    LocalStorage.set('calendarView', this.view);
    LocalStorage.set('calendarDate', Moment(this.date).tz('Europe/Stockholm').format('YYMMDD'));
  }

  private get URLSearchParamsData(): { date: string | null; view: View | null } {
    if (MODULE_ENVIRONMENT == 'node' || !this.globals.session.currentHistory) {
      return {} as any;
    }
    const currentParams = this.globals.session.getUrlSearchParams();

    return { date: currentParams.get('date'), view: currentParams.get('view') as View };
  }
}
