import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Component,
  Consume,
  LocaleContext,
  MobxComponent,
  NavLink,
  Platforms,
  Route,
  Switch,
} from '../../_dependencies';

import { NewOrganizationForm } from './newOrganizationForm';
import Organizations from './organizations';
import { PropertyLocationUpdater } from './propertyLocationUpdater';

import { AHLocaleContext } from '../../_locales';
import { WidgetTestingPage } from '../../components/admin/widgetTestPage';
import CurrentUserSidebarItem from '../../components/dashboard/currenctUserSidebarItem';
import { BadgeProvider } from '../../contexts/badge.context';
import { UserInfoView } from '../userInfoView';
import { BadgesView } from './badges';

class AdminTemplate extends MobxComponent<RouteComponentProps<{}>> {
  private _sidebarElement: any;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  componentDidUpdate() {
    this._sidebarElement = this.domElement.children().first();
    this._sidebarElement.sidebar({ context: this.domElement });
  }
  componentDidMount() {
    this.componentDidUpdate();
  }

  render() {
    const { t } = this._locale;
    const { match } = this.props;
    return (
      <div className="pushable">
        <div
          className="ui inverted vertical left visible thin overlay sidebar labeled icon menu"
          style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column', width: '10rem' }}
        >
          <div style={{ flex: '1 1 auto' }}>
            <NavLink exact to={match.url} className="item header">
              <h3 className="ui inverted header" style={{ padding: '0.7em 0' }}>
                {Platforms.platformName}
                <div className="sub header">{t('components.admin.index.header')}</div>
              </h3>
            </NavLink>
            <NavLink to={`${match.url}/organizations`} activeClassName="active" className="item">
              <i className="university icon" />
              {t('Organizations')}
            </NavLink>
            <NavLink to={`${match.url}/badges`} activeClassName="active" className="item">
              <i className="trophy icon" />
              {t('Badges')}
            </NavLink>
            <NavLink to={`${match.url}/new-organization`} activeClassName="active" className="item">
              <i className="large icons">
                <i className="university icon" />
                <i className="inverted corner add icon" />
              </i>
              <div style={{ paddingTop: '5px' }}>{t('components.admin.index.navLink3')}</div>
            </NavLink>
            <NavLink to={`${match.url}/new-user`} activeClassName="active" className="item">
              <i className="user add icon" />
              {t('components.admin.index.navlink4')}
            </NavLink>
            <NavLink to={`${match.url}/widget-testing`} activeClassName="active" className="item">
              <i className="cube icon" />
              {t('Test the Widget')}
            </NavLink>
          </div>
          <CurrentUserSidebarItem>
            {/* TODO: Denna vy ska vara en lista till de bolag man kan administera */}
            <NavLink exact to="/dashboard" className="ui fluid tiny inverted basic button">
              {this.globals.session.currentOrganization.name}
            </NavLink>
          </CurrentUserSidebarItem>
        </div>
        <div className="pusher" style={{ left: '10rem' }}>
          <BadgeProvider>
            <Switch>
              <Route exact path={`${match.path}/`} component={AdminIndex} />
              <Route path={`${match.path}/organizations`} component={Organizations} />
              <Route path={`${match.path}/user`} component={UserInfoView} />
              <Route path={`${match.path}/locationUpdate`} component={PropertyLocationUpdater} />
              <Route path={`${match.path}/badges`} component={BadgesView} />
              <Route path={`${match.path}/new-organization`} component={NewOrganizationForm} />
              <Route path={`${match.path}/widget-testing`} component={WidgetTestingPage} />

              {/* TODO: Add the follow route when statistics view has been created */}
              {/* <Route path={`${match.path}/statistics`} component={Statistics} /> */}
              {/* <Route path={`${match.path}/newsletters`} component={Newsletters} /> */}
            </Switch>
          </BadgeProvider>
        </div>
      </div>
    );
  }
}

class AdminIndex extends Component<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  render() {
    const { t } = this._locale;
    return (
      <div style={{ padding: '1em' }}>
        <h1 style={{ padding: '1em 1em 0em 0em', float: 'right' }}>
          {t('components.admin.index.title')}&nbsp;
          <i className="settings icon" />
        </h1>
        <div className="ui content" style={{ paddingTop: '6em' }}>
          <div className="ui large fluid icon message">
            <i className="wrench icon"></i>
            <div className="content">
              <div className="header">{t('components.admin.index.underConstructionMessageTitle')}</div>
              <p>{t('components.admin.index.underConstructionMessageDescription')}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminTemplate);
