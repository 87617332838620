import * as React from 'react';
import { Consume, KosmicComponent, LocaleContext } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { PropertyDropdown } from '../../common/property/propertydropdown';
import { CopyToClipboardButton } from '../../_copyToClipboardButton';

interface State {
  /** Currently selected property id */
  selectedPropertyID?: string;
  /** Incidates that GiftCard should be the first view shown in the widget */
  selectedGiftCardsAtStart: boolean;
}

/**
 * The classic widget selection view allows the
 * current user to copy the classic iframe code for a given property.
 */
export class ClassicWidgetSelectionView extends KosmicComponent<{}, State> {
  state: State = {
    selectedGiftCardsAtStart: false,
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  /** Triggered when user changes property in dropdown. */
  private onPropertyChanged = (selectedPropertyID: string) => this.setState({ selectedPropertyID });

  /** Creates and returns the code needed for the iframe widget */
  createIframeTemplate(propertyId: string, startOnGiftCards: boolean): string {
    const organizationId = this.globals.session.currentOrganizationId;
    return [
      '<iframe frameborder="0"',
      '\twidth="100%"',
      '\theight="800px"',
      `\tsrc="${window.location.origin}/widget/${startOnGiftCards ? 'giftcards/' + organizationId : propertyId}">`,
      '\t\t<p>Tyvärr har din webbläsare har inte stöd för att visa bokningsvyn</p>',
      '</iframe>',
    ].join('\n');
  }

  public render() {
    const { selectedPropertyID } = this.state;
    const { t } = this._locale;
    return (
      <div className="ui basic segment" style={{ padding: '2em', paddingTop: 0 }}>
        <p>
          {t('The classic widget that works...')}
          <br></br>
          {t('iFrame technology is used to...')}
        </p>
        <div style={{ height: '2em' }} />

        <h4 className="ui header" style={{ marginBottom: '0.5em' }}>
          {t('Select property')}
        </h4>
        <PropertyDropdown onChange={this.onPropertyChanged.bind(this)} value={selectedPropertyID} />
        <div style={{ height: '2em' }} />
        <h4 className="ui header" style={{ marginBottom: '0.5em' }}>
          <div className="ui readonly toggle checkbox">
            <input
              type="checkbox"
              name="public"
              checked={this.state.selectedGiftCardsAtStart}
              onChange={() => this.setState({ selectedGiftCardsAtStart: !this.state.selectedGiftCardsAtStart })}
            />
            <label>{t('components.dashboard.widgets.classicWidgetSelectionView.startInGiftCards')}</label>
          </div>
        </h4>
        <div style={{ height: '2em' }} />

        {selectedPropertyID && (
          <div>
            <div
              id="editor"
              style={{
                margin: '2rem',
                fontSize: '0.8em',
                fontWeight: 400,
                backgroundColor: '#EAEAEA',
                maxWidth: 800,
              }}
            >
              <div className="ui message">
                <pre>
                  {this.createIframeTemplate(this.state.selectedPropertyID!, this.state.selectedGiftCardsAtStart)}
                </pre>
              </div>
            </div>
            <CopyToClipboardButton
              buttonText={t('components.dashboard.widgets.classicWidgetSelectionView.copyToClipboard')}
              size="medium"
              textToCopy={this.createIframeTemplate(
                this.state.selectedPropertyID!,
                this.state.selectedGiftCardsAtStart,
              )}
              color="primary"
              style={{ marginBottom: '0.7rem' }}
            />
          </div>
        )}
      </div>
    );
  }
}
