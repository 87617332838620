import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { OffersContext } from '../../../../contexts/offers.context';
import {
  ActivityTypeDocument,
  AddressDocument,
  BookingPriceCategoryDocument,
  CustomerDocument,
  Field,
  Form,
  Platforms,
  TranslatableText,
  TranslatableTextField,
  tt,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { Iframe } from '../../../iFrame';

export const BookingConfirmationForm = () => {
  const { t } = useLocale();
  const { selectedOffer, saveNewBookingConfirmationMessage, updateSelectedOffer } = useContext(OffersContext);

  const [updatingMessage, setUpdatingMessage] = useState(false);
  const [mailPreview, setMailPreview] = useState<{ subject: string; html: string }>({ subject: '', html: '' });
  const [locale, setLocale] = useState<Locale>('sv');

  const messageTimer = useRef<NodeJS.Timeout>();

  const updateMessages = (textValue: TranslatableText) => {
    if (!selectedOffer) return;

    updateSelectedOffer('bookingConfirmationMessage', textValue);

    if (messageTimer.current) {
      clearTimeout(messageTimer.current);
    }

    messageTimer.current = setTimeout(async () => {
      setUpdatingMessage(true);
      if (selectedOffer) {
        await saveNewBookingConfirmationMessage(textValue);
      }
      setUpdatingMessage(false);
    }, 1000);
  };

  // Clear timer if demount
  useEffect(
    () => () => {
      if (messageTimer.current) {
        clearTimeout(messageTimer.current);
      }
    },
    [],
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('/api/v1/mail/render', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            type: 'confirmation',
            props: {
              booking: getBookingMock(
                locale,
                getActivityTypeDocumentMock(selectedOffer?.title, selectedOffer?.bookingConfirmationMessage),
              ),
              strictLocale: true,
            },
          }),
        });
        const result = (await response.json()) as { subject: string; html: string };
        setMailPreview(result);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [locale, selectedOffer?.title, selectedOffer?.bookingConfirmationMessage]);

  if (!selectedOffer) return null;

  return (
    <Form>
      <Field>
        <p>{t('Edit your greetings to the customer')}</p>
        <div className={'ui basic ' + (updatingMessage ? 'loading' : '') + ' segment'} style={{ padding: 0 }}>
          <TranslatableTextField
            textarea
            name="Din hälsning till kunden"
            value={selectedOffer.bookingConfirmationMessage}
            placeholder={t('Hi, thank you for you booking...')}
            onChange={updateMessages}
            onLocaleChange={setLocale}
          />
        </div>
      </Field>
      <Field>
        <p>{t('components.dashboard.offers.details.bookingConfirmationForm.preshowBooking')}</p>
        <div className="ui raised segment">
          <p>
            <b>
              {t('Subject')}: {t('Booking confirmation')} {t('For').toLowerCase()} {tt(selectedOffer.title, locale)} -{' '}
              {'[Din anläggning]'}
            </b>
            <b style={{ float: 'right' }}>
              {t('From')} {Platforms.platformName}
            </b>
          </p>
          <div className="ui fluid divider" />

          <Iframe content={mailPreview.html} />
        </div>
      </Field>
    </Form>
  );
};

const getBookingMock = (locale: Locale, activityType: ActivityTypeDocument) => ({
  number: '12345678',
  customer: { ...mockCustomerDocument, locale } as CustomerDocument,
  totalPrice: 15000,
  priceCategories: priceCategoriesMock,
  occurance: getOccuranceMock(activityType),
});

const addressDocumentMock: AddressDocument = {
  row1: 'Vintergatan 1',
  postNr: '12345',
  postOrt: 'Universum',
  country: '',
};

const mockPropertyDocument = {
  id: '',
  email: '[Din mail]',
  name: { sv: '[Din anläggning]', en: '[Din anläggning]', de: '[Din anläggning]' },
  phoneNo: '[Ditt telefonnummer]',
  address: addressDocumentMock,
};

const mockCustomerDocument: CustomerDocument = {
  firstname: 'Tindra',
  lastname: 'Forslind',
  email: 'tindra@forslind.com',
  phoneNr: '070 123 1234',
};

const priceCategoriesMock: BookingPriceCategoryDocument[] = [
  {
    name: { sv: 'Barn', en: 'Kids', de: 'Kids' },
    price: 99,
    tickets: 1,
    groupMultiplier: 1,
  },
  {
    name: { sv: 'Vuxen', en: 'Adult', de: 'Adult' },
    price: 149,
    tickets: 2,
    groupMultiplier: 1,
  },
];

const getOccuranceMock = (activityType: ActivityTypeDocument) => ({
  originatingActivity: activityType,
  property: mockPropertyDocument,
  start: moment(),
  end: moment().add(2, 'hours'),
});

const getActivityTypeDocumentMock = (
  title?: TranslatableText | string,
  confirmationMessage?: TranslatableText | string,
) =>
  ({
    title: title,
    address: addressDocumentMock,
    bookingConfirmationMessage: confirmationMessage,
  } as ActivityTypeDocument);
