import Moment from 'moment';
import React, { useContext } from 'react';
import { NavLink, NotificationDocument, Transition } from '../../../_dependencies';
import { isKeyOfAHLocaleContext, useLocale } from '../../../_locales';
import { NotificationContext } from '../../../contexts/notification.context';

interface Props {
  notification: NotificationDocument;
}

const NotificationCard = ({ notification }: Props) => {
  const { t } = useLocale();

  const { isLoading, toggleReadUnread, deleteNotification } = useContext(NotificationContext);

  const assertTranslation = (key: string): string => {
    if (isKeyOfAHLocaleContext(key)) {
      return t(key);
    }
    return t('Unknown notification');
  };

  const visitLink = () => {
    toggleReadUnread(notification, true);
    location.href = notification.link;
  };

  return (
    <Transition animation="pulse" duration={300}>
      <div
        style={{ display: 'flex', flexWrap: 'wrap' }}
        className={`ui icon ${!notification.read ? 'info' : ''} message`}
      >
        <i onClick={() => deleteNotification(notification)} className="close icon"></i>
        <i className={isLoading ? 'notched circle loading icon' : 'inbox icon'}></i>

        <div className="content" style={{ flex: 1 }}>
          <div className="header">
            {`${assertTranslation(notification.title.translationKey)} ${notification.title.data}`}
          </div>
          <div className="meta" style={{ fontSize: 12 }}>
            {Moment(notification.createdAt).tz('Europe/Stockholm').format('YY-MM-DD HH:mm')}
          </div>
          <div className="ui list">
            {notification.body?.map((item, index) => (
              <div key={index} className="item">
                <i className={item.icon}></i>
                {item.link ? (
                  <div className="content teal">
                    {/* // TODO: How to use semantic colors? */}
                    <NavLink style={{ color: '#276E85' }} to={item.link}>
                      {`${assertTranslation(item.content.translationKey)}: ${item.content.data}`}
                    </NavLink>
                  </div>
                ) : (
                  <div className="content">{`${assertTranslation(item.content.translationKey)}: ${
                    item.content.data
                  }`}</div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="ui bottom segment right floated">
          <button
            onClick={visitLink}
            className={`ui ${notification.read ? 'basic' : ''} teal button`}
            style={{ whiteSpace: 'nowrap' }}
          >
            {t('Go to calendar')}
          </button>
          <button
            onClick={() => toggleReadUnread(notification)}
            className={`ui ${notification.read ? 'basic' : ''} black button`}
            style={{ whiteSpace: 'nowrap' }}
          >
            {notification.read ? t('Mark as unread') : t('Mark as read')}
          </button>
        </div>
      </div>
    </Transition>
  );
};

export default NotificationCard;
