import { Types } from 'mongoose';
import { OrderId, PaymentId, PaymentPartition } from './models';

// #region Generic types
type CreateCard = {
  action: 'create';
  token: string;
  orderId: OrderId;
  amount?: number;
  metadata?: { [key: string]: string };
  receiptEmail?: string;
  description?: string;
  /** If a product needs to be specifically targeted by a payment, pass in a reference to the product (either marketplaceRef or product id) */
  productId?: string;
  discount?: Discount;
};
type ConfirmCard = {
  action: 'confirm';
  intent: string;
};
type CardType = { type: 'card' };
export type Giftcard = {
  amount: number;
  externalId: string; //Giftcard Code
  applicableTo: Types.ObjectId[] | string[];
};
export type Discount = {
  code: string; //Discount code
  organizationId: Types.ObjectId;
  percentage: number;
  offerLimitations?: string[];
};
// #endregion Generic types

// #region Param types
export type CreateCardParams = (CardType & CreateCard) | (CardType & ConfirmCard);
export type CreateCardResponse = {
  paymentId: string;

  //#region 202 Accepted = Requires 3d secure
  intentSecret?: string;
  requiresAction?: boolean;
  //#endregion 202 Accepted
};

export type CreateManualParams = {
  type: 'manual';
  /** Product to pay needs to be referenced by productId or externalId */
  productId?: string;
  externalId?: string;
  amount: number;
};
export type CreateManualResponse = {
  paymentId: PaymentId;
};

export type CreateGiftcardParams = {
  type: 'giftcard';
  orderId: OrderId;
  giftcard: Giftcard;
};
export type CreateGiftcardResponse = {
  paymentId: PaymentId;
  amountLeft?: number;
  partitions?: PaymentPartition[];
};
export type CreatePaymentParams = {
  card: [CreateCardParams, CreateCardResponse];
  manual: [CreateManualParams, CreateManualResponse];
  giftcard: [CreateGiftcardParams, CreateGiftcardResponse];
};

export type GiftcardStatusParams = {
  type: 'giftcard';
  externalId: string;
};
export type DiscountStatusParams = {
  type: 'discount';
  externalId: string;
};

export const isGiftcardStatusResponse = (obj: any | GiftcardStatusResponse): obj is GiftcardStatusResponse => {
  return (
    obj !== null &&
    typeof obj.externalId === 'string' &&
    Array.isArray(obj.payments) &&
    obj.payments.every((pay) => typeof pay === 'string') &&
    typeof obj.amountUsed === 'number'
  );
};
export type GiftcardStatusResponse = {
  externalId: string;
  payments: string[]; //paymentId[]
  amountUsed: number;
};
export type DiscountStatusResponse = {
  externalId: string;
  payments: string[]; //paymentId[]
  amountUsed: number;
};
export type StatusParams = {
  giftcard: [GiftcardStatusParams, GiftcardStatusResponse];
  discount: [DiscountStatusParams, DiscountStatusResponse];
};

export type GiftcardDryrunParams = {
  type: 'giftcard';
  orderId: OrderId;
  giftcards: Giftcard[];
};

export type DiscountDryrunParams = {
  type: 'discount';
  orderId: OrderId;
  discount: {
    code: string; //refers to the discount code
    organizationId: Types.ObjectId;
    percentage: number; //number between 0-1
    offerLimitations?: string[];
  };
};

export const isGiftcardDryrunResponse = (obj: any | GiftcardDryrunResponse): obj is GiftcardDryrunResponse => {
  return obj !== null && Array.isArray(obj.allocations);
};
export const isDiscountDryrunResponse = (obj: any | DiscountDryrunResponse): obj is DiscountDryrunResponse => {
  return obj !== null && Array.isArray(obj.allocations);
};
export type GiftcardDryrunResponse = {
  allocations: giftcardAllocationInformation[];
};

export type DiscountDryrunResponse = {
  allocations: discountAllocationInformation[];
  error?: string;
};

type discountAllocationInformation = {
  type: 'discount';
  externalId: string;
  discountPartitions: DryPaymentPartition[];
};

type giftcardAllocationInformation = {
  type: 'giftCard';
  externalId: string;
  giftcardPartitions: DryPaymentPartition[];
};

export type DryPaymentPartition = Omit<PaymentPartition, 'timestamp'>;

export type DryrunParams = {
  giftcard: [GiftcardDryrunParams, GiftcardDryrunResponse];
  discount: [DiscountDryrunParams, DiscountDryrunResponse];
};

export type RollbackPaymentsParams = {
  type: 'rollback';
  orderId: OrderId;
  paymentIds: PaymentId[];
};

export type DiscountUsageResponse = {
  numberOfUses: number;
};

export type RollbackPaymentsResponse = {
  deleteCount: number;
  documents: PaymentId[];
};

export type RemoveParams = {
  rollback: [RollbackPaymentsParams, RollbackPaymentsResponse];
};
// #endregion Param types

// #region Function types
export type CreateCardPaymentFn = (params: CreatePaymentParams['card'][0]) => Promise<CreatePaymentParams['card'][1]>;
export type CreateGiftcardPaymentFn = (
  params: CreatePaymentParams['giftcard'][0],
) => Promise<CreatePaymentParams['giftcard'][1]>;
export type CreateManualPaymentFn = (
  params: CreatePaymentParams['manual'][0],
) => Promise<CreatePaymentParams['manual'][1]>;
export type CreatePaymentFn = CreateCardPaymentFn & CreateGiftcardPaymentFn & CreateManualPaymentFn;

export type RetrieveGiftcardStatusFn = (params: StatusParams['giftcard'][0]) => Promise<StatusParams['giftcard'][1]>;
export type PaymentStatusFn = RetrieveGiftcardStatusFn;

export type DryrunGiftcardPaymentFn = (params: DryrunParams['giftcard'][0]) => Promise<DryrunParams['giftcard'][1]>;
export type DryrunDiscountPaymentFn = (params: DryrunParams['discount'][0]) => Promise<DryrunParams['discount'][1]>;

export type DryrunPaymentFn = DryrunGiftcardPaymentFn | DryrunDiscountPaymentFn;

export type RollbackPaymentsFn = (params: RemoveParams['rollback'][0]) => Promise<RemoveParams['rollback'][1]>;
export type RemovePaymentFn = RollbackPaymentsFn;

// #endregion Function types
