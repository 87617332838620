import * as React from 'react';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useLocale } from '../../../_locales';
import { PurchaseWidget } from '../../../_dependencies';
import { LanguageToggleIcons } from '../../languageToggleIcons';
import { PageNotFound } from '../../pageNotFound';
import PropertyShowcase from '../activities/propertyShowcase';

type Props = RouteComponentProps<any>;

/**
 * A special page for displaying all properties for an organization (currently ekerum only).
 * This page is intended for stationary screens like an iPad where adventures can book them selfs.
 **/
export function Ekerum(props: Props) {
  const { t } = useLocale();

  const { path, url } = props.match;
  return (
    <div className="ui container">
      <div style={navbar}>
        <Link to={url} style={header}>
          <img className="ui image" src="/static/platform/img/ekerum_logo.jpg" />
        </Link>

        <Switch>
          <Route path={path + '/widget'}>
            <Link to={url} style={header}>
              <h2>
                <i className="ui left arrow icon" />
                {t('components.customer.custom.ekerum.goBack')}
              </h2>
            </Link>
          </Route>
        </Switch>

        <LanguageToggleIcons />
      </div>
      <Switch>
        <Route exact path={path}>
          <PropertyShowcase organization="5c1ca70daf2b500000ec7e27" />
        </Route>
        <Route
          path={path + '/widget/:property'}
          render={({ match }) => (
            <PurchaseWidget initalType="bookings" propertyId={match.params.property} framed={false} />
          )}
        />
        <PageNotFound />
      </Switch>
    </div>
  );
}

const navbar: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '1em 0',
};

const header: React.CSSProperties = {
  textDecoration: 'none',
  color: 'black',
};
