import * as React from 'react';
import WidgetInformation from './widgetInformation';
import AccountHeader from './createAccountHeader';
import OurPaymentsInfoSection from './ourPaymentsInfoSection';
import AboutAHSection from './aboutAHSection';
import Statistics from './statisticsSection';
import PriceSection from './prices';
import CreateAccountFooter from './createAccountFooter';
import { ScrollHelper } from '../scrollHelper';

function CreateAccountPage() {
  React.useEffect(() => {
    ScrollHelper.scrollToTop();
  }, []);

  return (
    <div>
      <AccountHeader />
      <AboutAHSection />
      <WidgetInformation />
      <OurPaymentsInfoSection />
      <Statistics />
      <PriceSection />
      <CreateAccountFooter />
    </div>
  );
}

export default CreateAccountPage;
