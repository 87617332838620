import * as React from 'react';
import { Accordion, BookingDocument, Component, Consume, LocaleContext } from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';

export class CustomerInfo extends Component<{ booking: BookingDocument; mobileView: boolean }> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get addressListItem() {
    const { address } = this.props.booking.customer;
    if (address && !address.isEmpty) {
      const altAddress = address.row2 ? (
        <span>
          alt: {address.row2}
          <br />
        </span>
      ) : undefined;
      return (
        <div className="item">
          <i className="map outline icon" />
          <div className="content">
            <p>
              <span>{address.row1}</span>
              <br />
              {altAddress}
              <span>{address.postNr + ' ' + address.postOrt}</span>
            </p>
          </div>
        </div>
      );
    }
  }

  private get infoList() {
    const { t } = this._locale;
    const booking = this.props.booking;
    const bookingMessageFromCustomer = !booking.message ? undefined : (
      <div className="item" style={{ marginTop: '1em' }}>
        <i className="talk icon" />
        <div className="content">
          <b>{t('Message from the customer')}</b>
        </div>
        <div className="ui yellow message" style={{ marginTop: '3px' }}>
          {booking.message}
        </div>
      </div>
    );

    const bookingMessageToCustomer = !booking.messageToCustomer ? undefined : (
      <div className="item" style={{ marginTop: '1em' }}>
        <i className="talk outline icon" />
        <div className="content">
          <b>{t('Message to the customer')}</b>
        </div>
        <div className="ui message" style={{ marginTop: '3px' }}>
          {booking.messageToCustomer}
        </div>
      </div>
    );

    if (this.props.mobileView) {
      return (
        <div>
          <Accordion
            compact
            title={
              <span style={{ marginLeft: '-2px' }}>
                <i className="ticket icon" />
                <b>
                  {booking.customer.firstname} {booking.customer.lastname}{' '}
                  {booking.customer.businessName ? '(' + booking.customer.businessName + ')' : ''}
                </b>
                <i className="dropdown icon" />
              </span>
            }
          >
            <div className="ui list">
              <div className="item">
                <i className="at icon" />
                <div className="content">{booking.customer.email}</div>
              </div>
              <div className="item">
                <i className="call icon" />
                <div className="content">{booking.customer.phoneNr}</div>
              </div>
              <div className="item">
                <i className="map outline icon" />
                <div className="content">{this.addressListItem}</div>
              </div>
            </div>
          </Accordion>
          <div className="ui list">
            {bookingMessageFromCustomer}
            {bookingMessageToCustomer}
          </div>
        </div>
      );
    }

    return (
      <div className="ui list">
        <div className="item">
          <i className="ticket icon" />
          <div className="content">
            <b>
              {booking.customer.firstname} {booking.customer.lastname}{' '}
              {booking.customer.businessName ? '(' + booking.customer.businessName + ')' : ''}
            </b>
          </div>
        </div>
        <div className="item">
          <i className="at icon" />
          <div className="content">{booking.customer.email}</div>
        </div>
        <div className="item">
          <i className="call icon" />
          <div className="content">{booking.customer.phoneNr}</div>
        </div>
        {this.addressListItem}
        {bookingMessageFromCustomer}
        {bookingMessageToCustomer}
      </div>
    );
  }

  render() {
    return this.infoList;
  }
}
