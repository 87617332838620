import { default as React, useContext } from 'react';
import Shortid from 'shortid';
import { formatDuration, getAccessories, getTickets } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { OffersContext } from '../../../../contexts/offers.context';
import { ActivityTagsFormatter } from './../../../../_dependencies';

export const OfferPreviewCard = () => {
  const { t, tt, locale } = useLocale();
  const { selectedOffer } = useContext(OffersContext);

  if (!selectedOffer) return null;

  const tickets = getTickets(selectedOffer.priceCategories, true);
  const accessories = getAccessories(selectedOffer.priceCategories, true);
  let language: string;

  return (
    <div>
      <img
        className="ui fluid image"
        src={selectedOffer.imageUrl || '/static/platform/img/default_activity.jpg'}
        style={{ maxWidth: '22rem' }}
      />
      <div className="ui large relaxed list">
        <div className="item">
          <div className="header" style={{ marginBottom: '0.5em' }}>
            {t('Description')}
          </div>
          <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {tt(selectedOffer.description)}
          </p>
        </div>
        <br />
        <div className="item">
          <b>{t('offers.details.bookingTime')}</b> {formatDuration(selectedOffer.duration, locale)}
        </div>
        <div className="item">
          <b>{t('Max visitors')}: </b>
          {selectedOffer.inventories?.length || selectedOffer.useInventory
            ? t('Calculated based of connected inventory')
            : selectedOffer.visitorCapacity + '' + t('pcs')}
        </div>

        <div className="item">
          <b>{t('booking-request')}: </b>
          {selectedOffer.fixedBookingRequest ? t('Activated') : t('Not activated')}
        </div>
        {/* {selectedOffer.fixedBookingRequest && (
          <div className="item">
            <b> {t('Minimum charge at booking request')}: </b>
            <span>
              {selectedOffer.fixedBookingRequestMinimumCharge && selectedOffer.fixedBookingRequestMinimumCharge} SEK
            </span>
          </div>
        )} */}

        {/* <div className="item">
          <b>{t('Flexible booking request')}: </b>
          {selectedOffer.flexibleBookingRequest ? t('Activated') : t('Not activated')}
        </div>
        {selectedOffer.flexibleBookingRequest && (
          <div className="item">
            <b> {t('Message to customer at flexible booking request')}: </b>
            {selectedOffer.flexibleBookingRequestMessage ? tt(selectedOffer.flexibleBookingRequestMessage) : null}
          </div>
        )} */}

        {!selectedOffer.minVisitors ? (
          <span />
        ) : (
          <div className="item">
            <b>{t('Min visitors per booking')}</b> {selectedOffer.minVisitors} {t('Amount')}
          </div>
        )}
        {!selectedOffer.neededStaffing ? (
          <span />
        ) : (
          <div className="item">
            <b>{t('offers.details.personnelNumber')}</b> {selectedOffer.neededStaffing} {t('pcs')}
          </div>
        )}
        {!selectedOffer.bookingClosesBeforeEventInHours ? (
          <span />
        ) : (
          <div className="item">
            <b>{t('offers.details.bookingClose')}</b> {selectedOffer.bookingClosesBeforeEventInHours}{' '}
            {t('offers.details.valueFive')}
          </div>
        )}
        {!selectedOffer.refundableUntilInHours ? (
          <span />
        ) : (
          <div className="item">
            <b>{t('Refundable until')}:</b> {selectedOffer.refundableUntilInHours} {t('offers.details.valueFive')}
          </div>
        )}
        {!selectedOffer.address ? (
          <span />
        ) : (
          <div className="item">
            <b>{t('offers.details.place')}</b> {selectedOffer.address.row1}, {selectedOffer.address.postNr}{' '}
            {selectedOffer.address.postOrt}
          </div>
        )}
        <br />
        <div className="item">
          <div className="header" style={{ marginBottom: '0.5em' }}>
            {t('Activity categories')}
          </div>
          <div className="ui large labels">
            {(selectedOffer.categoryTags || []).map((categoryTag) => {
              const formattedTag = ActivityTagsFormatter.getFormattedTag(categoryTag, useLocale().locale);
              if (!formattedTag) {
                return <span key={categoryTag} />;
              }
              return (
                <div key={categoryTag} className="ui label">
                  {formattedTag}
                </div>
              );
            })}
          </div>
        </div>
        <div className="item">
          <div className="header" style={{ marginBottom: '0.5em' }}>
            {t('Price categories')}
          </div>
          <div className="ui large blue tag labels">
            {tickets.map((priceCategory) => (
              <div className="ui label" key={`price-category-${Shortid.generate()}`}>
                {tt(priceCategory.name)}
                <div className="detail">
                  {priceCategory.price} {t('SEK')}
                </div>
                {priceCategory.groupMultiplier ? (
                  <div className="detail">
                    {priceCategory.groupMultiplier}
                    <i className="user icon" style={{ margin: 0 }} />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        {accessories.length > 0 && (
          <div className="item">
            <div className="header" style={{ marginBottom: '0.5em' }}>
              {t('Accessories')}
            </div>
            <div className="ui large green tag labels">
              {accessories.map((priceCategory) => (
                <div className="ui label" key={`price-category-${Shortid.generate()}`}>
                  {tt(priceCategory.name)}
                  <div className="detail">
                    {priceCategory.price} {t('SEK')}
                  </div>
                  {priceCategory.groupMultiplier ? (
                    <div className="detail">
                      {priceCategory.groupMultiplier}
                      <i className="user icon" style={{ margin: 0 }} />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
