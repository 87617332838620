import * as React from 'react';
import {
  Consume,
  DeviceProvider,
  GiftCardPreview,
  GiftCardTypeDocument,
  IDevice,
  KosmicComponent,
  LocaleContext,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { DatalayerContext } from '../datalayer.context';
import { MarketplaceCartContext } from '../marketplace.cart.context';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';

export class GiftCardSelectionView extends KosmicComponent<{}> {
  @Consume(WidgetDataProvider.Context)
  protected widgetData: IWidgetData;

  @Consume(MarketplaceCartContext)
  private marketplaceCart: MarketplaceCartContext;

  @Consume(DeviceProvider.Context)
  protected device: IDevice;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  @Consume(DatalayerContext)
  private datalayer: DatalayerContext;

  componentDidMount() {
    this.widgetData.verifyStepNavigation('giftcardSelection');
  }

  /** Renders a list of selectable giftcards */
  protected renderListOfSelectableItems() {
    const { t } = this._locale;
    const cards = this.widgetData.availableGiftCardTypes;

    if (cards.length) {
      return (
        <div>
          <div className="ui three doubling stackable cards">
            {cards.map((card: GiftCardTypeDocument) => (
              <div
                className="ui fluid link card"
                key={card.id}
                onClick={async () => {
                  this.marketplaceCart.placeItemInCart({ giftcardType: card });
                  this.datalayer.sendAddGiftcardToCartEvent(card);
                }}
              >
                <GiftCardPreview key={card.id} giftCard={card} />
              </div>
            ))}
          </div>
        </div>
      );
    }

    // Is loading data
    if (this.widgetData.isloading) {
      return <div className="ui active inline centered loader" />;
    }

    // No gift cards available
    if (!this.widgetData.availableGiftCardTypes.length) {
      return (
        <p style={{ marginTop: '2em', textAlign: 'center' }}>
          {t('widget.selectionSteps.giftcardSelectionView.noGiftCardsAtOrganization')}&nbsp;
          {this.widgetData.selectedOrganization.name}.
        </p>
      );
    }
  }

  private renderLinkToBookingView() {
    const { t } = this._locale;
    if (this.widgetData.allowsSwitchingPurchaseType()) {
      return (
        <div style={{ paddingTop: '3em', paddingBottom: '3em' }}>
          <div className="ui message" style={this.device.size == 'mobile' ? { marginTop: '5em' } : {}}>
            <div className="ui header">
              {t('Would you like to book your adventure directly instead?')}
              <div className="sub header" style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}>
                {t('See available adventures at')}&nbsp;
                {this.widgetData.selectedOrganization.name}
              </div>
            </div>
            <br />
            <button onClick={() => this.widgetData.resetWidget('bookings')} className="ui fluid primary button">
              <i className="ticket icon" /> {t('Book an experience')}
            </button>
          </div>
        </div>
      );
    }
  }

  public render() {
    const { t } = this._locale;
    return (
      <div>
        <h2 className="ui primary header">
          <div className="content">
            {t('widget.selectionSteps.giftcardSelectionView.mainHeader')}
            <div className="sub header">{t('widget.selectionSteps.giftcardSelectionView.mainSubHeader')}</div>
          </div>
        </h2>
        {this.renderListOfSelectableItems()}
        {this.renderLinkToBookingView()}
      </div>
    );
  }
}
