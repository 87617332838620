import { Tfunc } from '../../../../_locales';

// NOTE: This file contains undocumented utitliy functions related to event creation / rendering
// Should be looked at if the "details for" function is refactored

export const validFields = [
  'firstname',
  'lastname',
  'email',
  'phoneNr',
  'address.row1',
  'address.row2',
  'address.postOrt',
  'address.postNr',
  'address.country',
] as const;

export type ValidField = typeof validFields[number];

export function getValueForPath<T>(path: string, from: T): string | undefined {
  const parts = path.split('.');
  const value: string = parts
    .reduce((acc, x) => {
      return acc[x];
    }, from)
    .toString();
  return value.length ? value : undefined;
}

export function recursiveDiff<T>(from: T, to: T, ns?: string): string[] {
  const changedFields: string[] = [];
  for (const field in from) {
    const path = ns ? `${ns}.${field}` : field;
    if (typeof from[field] === 'object') {
      const fields = recursiveDiff(from[field], to[field], path);
      changedFields.push(...fields);
      continue;
    }
    if (from[field] !== to[field]) {
      changedFields.push(path);
    }
  }
  return changedFields;
}

export const translateCustomerDocumentField = (t: Tfunc, field: string): string | undefined => {
  if (!validFields.includes(field as ValidField)) {
    return;
  }
  return t(field as ValidField);
};
