import * as Mobx from 'mobx';
import { MobxComponent } from './abstractComponent';

export abstract class ObservingComponent<Props, Store, State = {}> extends MobxComponent<
  Props & {
    store?: Store;
  },
  State
> {
  private _store: Store;

  static get defaultProps() {
    return {
      store: undefined,
    };
  }

  protected abstract componentDefaultStore(): Store;

  protected get store(): Store {
    if (!this._store) {
      this._store = this.props.store! || this.componentDefaultStore();
      if (!Mobx.isObservable(this._store)) {
        this._store = Mobx.observable(this._store);
      }
    }
    return this._store;
  }

  protected set store(value) {
    if (!Mobx.isObservable(value)) {
      this._store = Mobx.observable(this._store);
    } else {
      this._store = value;
    }
  }

  componentIsConstructed(): void {}

  constructor(props, context) {
    super(props, context);
    props.store;
    this.componentIsConstructed();
  }
}
