import { utils } from 'xlsx/types';
import {
  DryrunGiftcardPaymentFn,
  DryrunDiscountPaymentFn,
  GiftcardDryrunResponse,
  DiscountDryrunResponse,
} from './payments.types';
import { Fetcher } from './utilities';
import { DiscountError } from '../..';

export const giftcardDryrun =
  (fetcher: Fetcher): DryrunGiftcardPaymentFn =>
  async ({ orderId, giftcards }) => {
    const query = { orderId, giftcards };
    const queryString = JSON.stringify(query);
    const encode = Buffer.from(queryString).toString('base64');

    const res = await fetcher(`/function/retrieve-giftcard-value-allocation?data=${encode}`, {
      method: 'GET',
    });
    const data: GiftcardDryrunResponse = await res.json();
    switch (res.status) {
      case 200:
        return data;
      default:
        throw data;
    }
  };

export const discountDryrun =
  (fetcher: Fetcher): DryrunDiscountPaymentFn =>
  async ({ orderId, discount }) => {
    const body = { orderId, discount };

    const res = await fetcher(`/function/retrieve-discount-value-allocation`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const data: DiscountDryrunResponse = await res.json();
    switch (res.status) {
      case 200:
        data?.allocations.forEach((a) => (a.type = 'discount'));
        return data;
      default:
        throw new DiscountError('could_not_apply_discount', data.error!);
    }
  };
