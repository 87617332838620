import React, { useContext } from 'react';
import { useLocale } from '../../../_locales';
import NotificationCard from './notificationCard';
import { Transition } from '../../../_dependencies';
import { NotificationContext } from '../../../contexts/notification.context';
import DashboardTemplate from '../dashboardTemplate';

const NotificationsView = () => {
  const { notifications, isLoading } = useContext(NotificationContext);

  const { t } = useLocale();

  const renderNotifications = () => {
    if (!notifications.length && isLoading) {
      return <div className="ui basic loading segment" style={{ minHeight: '20rem' }} />;
    }

    const AllSetMessage = (
      <Transition in animation="scale" duration={300}>
        <h2 className="ui icon header" style={{ margin: '6rem 0' }}>
          <i className="coffee icon"></i>
          <div className="content">
            {t('No unread notifications')}
            <div className="sub header">{t('Relax and enjoy a cup of koffee')}</div>
          </div>
        </h2>
      </Transition>
    );

    if (notifications.length <= 0) {
      return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>{AllSetMessage}</div>;
    }
    const unreadNotifications = notifications.filter((n) => !n.read);
    const readNotifications = notifications.filter((n) => n.read);

    return (
      <div className="ui basic segment">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {unreadNotifications.length <= 0 ? AllSetMessage : null}
          {unreadNotifications.map((n) => (
            <NotificationCard key={n.id} notification={n} />
          ))}
        </div>
        <div style={{ margin: '4rem 0 2rem' }} className="ui horizontal divider">
          {t('Already read notifications')}
        </div>
        <div>
          {readNotifications.map((n) => (
            <NotificationCard key={n.id} notification={n} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <DashboardTemplate title={t('Notifications')} icon="bell outline icon">
      {renderNotifications()}
    </DashboardTemplate>
  );
};

export default NotificationsView;
