import Moment from 'moment-timezone';
import React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { ActivityOccuranceResource, Consume, LocaleContext, ObservingComponent } from '../../_dependencies';
import { CalendarStore } from './store';
import { AgendaView } from './agenda/agendaView';
import { EventComponentFactory } from './utils/eventComponentFactory';
import { AHLocaleContext } from '../../_locales';

interface Props {
  className?: string;
}

interface State {
  minTime: Date;
}

export class Calendar extends ObservingComponent<Props, CalendarStore, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private _eventComponentFactory;
  private _scrollTop = 240;

  constructor(props: Props) {
    super(props, undefined);

    const minTime = new Date();
    minTime.setHours(6, 0, 0);
    this.state = { minTime };
  }

  private get eventComponentFactory() {
    this._eventComponentFactory = this._eventComponentFactory || new EventComponentFactory(this.store);
    return this._eventComponentFactory;
  }

  componentDefaultStore() {
    return new CalendarStore({ resource: new ActivityOccuranceResource() });
  }

  // NOTE: Does this do anything?
  componentDidUpdate() {
    if (this.store.view == 'week' || this.store.view == 'day') {
      this.domElement.find('.rbc-time-content').scrollTop(this._scrollTop);
      this.domElement.find('.rbc-time-content').scroll(() => {
        this._scrollTop = this.domElement.find('.rbc-time-content').scrollTop() || 0;
      });
    }
  }

  render() {
    const localizer = momentLocalizer(Moment);

    const calendar = (
      <BigCalendar
        localizer={localizer}
        className={this.props.className}
        toolbar={false}
        popup={false}
        view={this.store.view}
        date={this.store.date}
        events={this.store.displayedEvents}
        showAllEvents
        messages={this.store.wordings}
        formats={{
          dayFormat: (date) => Moment(date).tz('Europe/Stockholm').format('ddd D MMM'),
          eventTimeRangeFormat: (date) =>
            Moment(date.start).tz('Europe/Stockholm').format('HH:mm') +
            ' — ' +
            Moment(date.end).tz('Europe/Stockholm').format('HH:mm'),
          selectRangeFormat: (date) =>
            Moment(date.start).tz('Europe/Stockholm').format('HH:mm') +
            ' — ' +
            Moment(date.end).tz('Europe/Stockholm').format('HH:mm'),
        }}
        min={this.state.minTime}
        timeslots={2}
        views={{
          week: true,
          month: true,
          day: true,
          agenda: AgendaView,
        }}
        components={{
          day: { event: this.eventComponentFactory.eventComponent },
          week: { event: this.eventComponentFactory.eventComponent },
          month: { event: this.eventComponentFactory.eventComponent },
        }}
        eventPropGetter={this.eventComponentFactory.getEventProps}
        tooltipAccessor={(event) => this._locale.tt(event.title)}
        onNavigate={this.store.navigateToDate}
        onView={this.store.changeView}
        startAccessor={(event) => new Date(event.start)}
        endAccessor={(event) => new Date(event.end)}
        allDayAccessor={() => false}
        onSelectEvent={this.store.selectEvent}
        onSelectSlot={this.store.handleTimeSelection}
        selectable={this.store.isTimeSelectable}
      />
    );

    if (this.store.loading) {
      return <div className="ui basic loading disabled segment">{calendar}</div>;
    }

    return calendar;
  }
}
