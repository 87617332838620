import * as React from 'react';
import { getStripeInstance } from '../../../../../_dependencies';
import { Stripe } from '@stripe/stripe-js';
import type StripeTypes from 'stripe';
import {
  Field,
  Form,
  Input,
  KosmicComponent,
  OrganizationResource,
  Rules,
  SubmitButton,
  UserDocument,
  Consume,
  LocaleContext,
} from '../../../../../_dependencies';
import { PrivacyInformationPopup } from './privacyInformationPopup';
import { AHLocaleContext } from '../../../../../_locales';

interface Props {
  nextStep: () => void;
  account: StripeTypes.Account;
  key?: string;
}

interface State {
  currency: string;
  accountType: 'company' | 'individual';
  accountHolderName: string;
  iban: string;
  initialized: boolean;
  error: string;
}

export class ExternalAccountsForm extends KosmicComponent<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private static stripe: Stripe;
  private ibanContainerRef = React.createRef<HTMLDivElement>();
  private ibanElement: any;

  public state: State = {
    currency: 'SEK',
    accountType: 'company',
    accountHolderName: '',
    iban: '',
    initialized: false,
    error: '',
  };

  async componentDidMount() {
    if (!ExternalAccountsForm.stripe) ExternalAccountsForm.stripe = await getStripeInstance();

    const elementsObject = ExternalAccountsForm.stripe.elements();
    const { firstname, lastname } = this.globals.session.currentUser as UserDocument;

    this.ibanElement = elementsObject.create('iban', { supportedCountries: ['SEPA'] });
    this.ibanElement.on('change', () => this.setState({ error: '' }));
    this.setState({ accountHolderName: `${firstname} ${lastname}`, initialized: true });
  }

  onSubmit = async (values: any, reject: any, resolve: any) => {
    try {
      const ibanToken = await ExternalAccountsForm.stripe.createToken(this.ibanElement, {
        currency: this.state.currency,
        account_holder_name: values.name,
        account_holder_type: this.state.accountType,
      });

      if (this.ibanElement.invalid || ibanToken.error) {
        this.setState({
          error: ibanToken.error?.message || 'Iban is not valid',
        });
        return resolve();
      }

      const resource = new OrganizationResource();
      await resource.createExternalAccount({ ibanToken });

      resolve();
      this.props.nextStep();
    } catch (err) {
      this.setState({
        error: err.message || 'Iban is not valid',
      });
      reject(err);
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.initialized && !prevState.initialized) {
      this.ibanElement.mount(this.ibanContainerRef.current);
    }
  }

  render() {
    const { t } = this._locale;
    if (!this.state.initialized) {
      return (
        <div style={{ height: 100 }}>
          <div className="ui active inverted dimmer">
            <div className="ui large text loader">{t('Getting ready')}</div>
          </div>
        </div>
      );
    }

    return (
      <main>
        <Form onSubmit={this.onSubmit}>
          <h1>
            {t('Bank account')}
            <PrivacyInformationPopup />
          </h1>
          <Field label={t('Name of account holder')}>
            <Input name="name" type="text" defaultValue={this.state.accountHolderName} rules={[Rules.NotEmpty()]} />
          </Field>
          <Field label={t('IBAN number')}>
            <div style={styles.iban} ref={this.ibanContainerRef}></div>
          </Field>
          {this.state.error && (
            <div className="ui negative message">
              <p>{this.state.error}</p>
            </div>
          )}

          <SubmitButton className="primary">{t('Next step')}</SubmitButton>
        </Form>
      </main>
    );
  }
}

const styles = {
  iban: {
    border: '1px solid #cce2ff',
    borderRadius: '5px',
    padding: '9.5px',
    paddingRight: '14px',
    paddingLeft: '14px',
  },
};
