import React, { useContext } from 'react';
import {
  BookingPriceCategoryDocument,
  getAccessories,
  getTickets,
  LocaleContext,
  visitors,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import Shortid from 'shortid';

interface Props {
  priceCategories: BookingPriceCategoryDocument[];
  t: AHLocaleContext['t'];
}

const TicketsTables = ({ priceCategories, t }: Props) => {
  const allVisitors = getTickets(priceCategories);
  const visitorsCount = allVisitors.reduce((sum, pc) => sum + visitors(pc), 0);
  const accessories = getAccessories(priceCategories);

  return (
    <div style={{ marginBottom: '1em' }}>
      <b>
        <i className="male icon" /> {t('Participants')} <i>({visitorsCount})</i>
      </b>
      <Table priceCategories={allVisitors} t={t} />
      <b>
        <i className="tags icon" /> {t('Accessories')}
      </b>
      <Table priceCategories={accessories} t={t} />
    </div>
  );
};

function Table(props: { priceCategories: BookingPriceCategoryDocument[]; t: AHLocaleContext['t'] }) {
  const { tt } = useContext(LocaleContext);
  return (
    <table className="ui very basic small celled table" style={{ marginTop: '.4em' }}>
      <tbody>
        {props.priceCategories.map(({ name, groupMultiplier, tickets, basePrice }) => {
          if (basePrice) return;
          return (
            <tr key={`price-categories-${Shortid.generate()}`}>
              <td>
                {tt(name)}{' '}
                {groupMultiplier ? (
                  <i>
                    ({props.t('valid for')} {groupMultiplier} {props.t('people')})
                  </i>
                ) : null}
              </td>
              <td style={{ width: '2.5rem' }}>{tickets}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TicketsTables;
