import React from 'react';
import { Button, InventoryStatusDocument } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { smallerContainerStyle } from './destinationSegment';

interface Props {
  updating: boolean;
  isBookingSelected: boolean;
  onConfirmStatusUpdate: () => void;
  disabled: boolean;
  itemStatusData?: InventoryStatusDocument;
}

export const ConfirmButtonSegment = ({
  updating,
  isBookingSelected,
  disabled,
  onConfirmStatusUpdate,
  itemStatusData,
}: Props) => {
  const { t } = useLocale();

  const disableSaveButton = () => {
    if (!itemStatusData?.location) {
      return true;
    } else if (
      (!isBookingSelected && itemStatusData?.status === 'in-use') ||
      (disabled && itemStatusData?.status === 'in-use')
    ) {
      return true;
    } else if (
      (!isBookingSelected && itemStatusData?.status === 'unavailable') ||
      itemStatusData?.status === 'available'
    ) {
      return false;
    }
  };

  return (
    <div style={smallerContainerStyle}>
      <Button
        loading={updating}
        disabled={disableSaveButton()}
        className={disabled && itemStatusData?.status === 'in-use' ? 'icon fluid red' : 'fluid green'}
        style={{ padding: '1em' }}
        onClick={onConfirmStatusUpdate}
      >
        {disabled && itemStatusData?.status === 'in-use' ? <i className={`white exclamation icon`} /> : null}
        {disabled && itemStatusData?.status === 'in-use' ? t('Fully rented out') : t('Save')}
      </Button>
    </div>
  );
};
