import { capitalize, groupBy } from 'lodash';
import React, { useState } from 'react';
import Moment from 'moment-timezone';
import { ActivityOccuranceDocument, Button } from '../../../_dependencies';
import { Overlay } from '../../overlay';
import { useLocale } from '../../../_locales';
import { CalendarStore } from '../store';
import { PrintableBookingRow } from './printableBookingRow';

interface Props {
  store: CalendarStore;
}

export const PrintableDayView = ({ store }: Props) => {
  const { t, tt } = useLocale();
  const [showDayView, setShowDayOverlay] = useState(false);
  const dateString = Moment(store.date).format('Do MMM');

  let activities = store.displayedEvents as ActivityOccuranceDocument[];
  activities = activities
    .filter(({ bookings }) => bookings)
    .filter(({ start, bookings }) => Moment(start).isSame(store.date, 'day') && bookings.length);

  const activityGroups = groupBy(activities, ({ title }) => tt(title));

  return (
    <>
      <Button className="fluid tiny icon blue" style={{ marginTop: '1em' }} onClick={() => setShowDayOverlay(true)}>
        <i className="print icon" /> {t('Open print view for')} {dateString}
      </Button>

      {showDayView && (
        <Overlay fullscreen>
          <div
            className="ui basic segment"
            style={{ background: 'white', minHeight: '100%' }}
            onClick={() => setShowDayOverlay(false)}
          >
            <div style={{ padding: '0 .5rem', position: 'relative' }}>
              <h2 className="ui header" style={{ padding: '1rem 0 .5rem' }}>
                {Moment(store.date).format('dddd D/M').toUpperCase()}
              </h2>
              <img
                className="ui image"
                src="/static/platform/img/logo.png"
                style={{ width: '5rem', height: '5rem', position: 'absolute', right: 0, top: 0 }}
              />
            </div>
            <span
              className="no-print"
              style={{ padding: '1.5rem', position: 'fixed', fontSize: '1.2rem', bottom: 0, right: 0 }}
            >
              {t('Click anywhere in the view to close it')}
            </span>
            <div>
              {Object.values(activityGroups).map((activities) => {
                const sortedActivites = activities.sort((a, b) => (a.start < b.start ? -1 : 1));
                const { id, title } = sortedActivites[0];
                return (
                  <div key={id} style={{ marginTop: '1rem' }}>
                    <div style={{ display: 'flex', columnGap: '.5rem', padding: '0 .5rem' }}>
                      <b style={{ flex: 1, minWidth: '3rem', whiteSpace: 'nowrap', marginRight: '4.6rem' }}>
                        {tt(title)}
                      </b>
                      <b style={{ flex: 3 }}></b>
                      <b style={{ flex: 2 }}>{capitalize(t('visitors'))}</b>
                      <b style={{ flex: 2 }}>{t('Accessories')}</b>
                      <b style={{ flex: 2 }}></b>
                    </div>
                    <div className="ui divider" style={{ margin: '.5rem 0 0' }} />
                    <div className="ui divided list" style={{ margin: 0 }}>
                      {sortedActivites.map(({ id, start, bookings }) => (
                        <div key={id} className="item" style={{ display: 'flex', padding: '.5rem' }}>
                          <span>{Moment(start).format('HH:mm')}</span>

                          <div style={{ marginLeft: '2rem', flex: 1 }}>
                            {bookings.map((b) => (
                              <PrintableBookingRow key={b.id} booking={b} />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Overlay>
      )}
    </>
  );
};
