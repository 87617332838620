import { Schema, Types } from 'mongoose';

// Here you go @lyret: Use this as a base for the new payment receiver 💪🏽

export type ChargeType = 'Manual' | 'GiftCard' | 'Stripe';
export interface ChargeDocument {
  /** Specifies the type of the charge */ type: ChargeType;
}

export interface StripeChargeDocument extends ChargeDocument {
  /** The id of the stripe charge */ stripeChargeId: string;
}

// interface GiftCardChargeDocument extends ChargeDocument TODO: removed by lyret during refactoring, never used anywhere
// {
// 	/** The id of the giftcard charge */ giftCardChargeId: Types.ObjectId
// }

export interface ManualChargeDocument extends ChargeDocument {
  /** The amount of the manual charge */ amount: number;
}

const ChargeSchemaa = new Schema(
  {
    type: { type: String, required: true },
    stripeChargeId: { type: String, required: false },
    giftCardChargeId: { type: Schema.Types.ObjectId, ref: 'giftcardcharge', required: false },
    amount: { type: Number, required: false },
  },
  { _id: false },
);

export const ChargeSchema = ChargeSchemaa;
