import React, { useContext } from 'react';
import { DeviceProvider, InventoryEntityDocument } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { LanguageToggleIcons } from '../../../../languageToggleIcons';

interface Props {
  finished: boolean;
  entityData: InventoryEntityDocument;
}

export const Header = ({ finished, entityData }: Props) => {
  const { t } = useLocale();
  const device = useContext(DeviceProvider.Context);

  return (
    <div style={{ color: 'white' }}>
      <img className="ui centered small image" style={logoStyle} src="/static/platform/img/logo.png" />
      <h2 style={headerStyle(device.size === 'mobile')}>
        <span style={{ margin: '.5rem 0' }}>{finished ? `${t('Status updated')}!` : entityData.title}</span>
        <LanguageToggleIcons style={{ marginRight: '-.4rem' }} />
      </h2>
    </div>
  );
};

const logoStyle: React.CSSProperties = {
  margin: '2rem auto',
};

const headerStyle = (isMobile: boolean): React.CSSProperties => ({
  textShadow: '1px 1px 5px #444',
  margin: '0 .5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: isMobile ? 'column-reverse' : 'row',
  textAlign: 'center',
});
