import moment from 'moment';
import { BookingDocument, MarketplaceResource } from '../../../../_dependencies';
import { EventMetadataType, createPaymentEvent } from '../events';

export type BookingEvent = {
  timestamp: Date;
  description: string;
  value: number;
  metadata?: { [key: string]: any };
};

export type FormattedEventData = {
  [key: string]: [
    {
      fromNow: string;
      time: string;
      description: string;
      value: number;
      metadata: any;
      key: number;
    },
  ];
};

export type DiscountEvent = Omit<BookingEvent, 'metadata description'> & {
  description: 'Discount';
  metadata: {
    applied_discount_code: string;
    applied_discount_percentage: string;
    applied_discount_id: string;
    key: 'discount_applied';
  };
};

const formatData = (events: BookingEvent[]) => {
  const data: FormattedEventData = {};
  events.forEach(({ timestamp, description, value, metadata }, index) => {
    const date = moment(timestamp);
    const dateStr = date.format('DD MMM YYYY');
    const list = data[dateStr] || [];
    list.push({
      fromNow: date.fromNow(),
      time: moment(date).format('LT'),
      description,
      value,
      metadata,
      key: index,
    });
    data[dateStr] = list;
  });
  return data;
};

export const handleBookingHistoryData = async (booking: BookingDocument) => {
  const resource = new MarketplaceResource();
  const data = await resource.getInformationForProduct(booking.id.toString());
  if (data) {
    const modifiedPayment = data.payments
      .map((pay) => ({
        ...pay.partitions[0],
        description: 'payment',
        status: pay.status,
        metadata: createPaymentEvent(pay.type),
      }))
      .filter((pay) => pay.status === 'completed');
    const allEvents = [...data.product.events, ...modifiedPayment];
    const sortedEvents = allEvents.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
    const formattedData = formatData(sortedEvents);
    return formattedData;
  }
};

const isDiscountEvent = (obj: any | DiscountEvent): obj is DiscountEvent => {
  return obj !== null && obj.description === 'Discount';
};

/** Takes in event history data and returns the event based on the event description */
export const getEventParam = (history: FormattedEventData | undefined): DiscountEvent | undefined => {
  if (!history) return undefined;
  let eventToReturn: DiscountEvent | undefined = undefined;
  Object.keys(history).map((date) => {
    return history[date].map((event) => {
      if (isDiscountEvent(event)) {
        eventToReturn = event;
      }
    });
  });
  return eventToReturn;
};

/**
 * Function that checks a booking for specific event types in its event history.
 */
export const eventFetcher = async (booking: BookingDocument, eventTypes: string[]) => {
  const history = await handleBookingHistoryData(booking);
  const response = {
    confirmed: false,
    rejected: false,
  };
  const bookingEvents: EventMetadataType[] = [];

  if (history) {
    Object.keys(history).map((date) => {
      history[date].map((event) => {
        if (event.metadata) {
          if (eventTypes.includes(event.metadata.key) && event.metadata.success) {
            bookingEvents.push(event.metadata);
          }
        }
      });
    });
  }

  const typesInEvents: string[] = bookingEvents.map((event) => event.key);

  if (typesInEvents[0] === 'booking_confirmed') {
    response.confirmed = true;
  }
  if (typesInEvents[0] === 'booking_rejected') {
    response.rejected = true;
  }

  return response;
};
