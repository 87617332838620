import { GridFsDocument, GridFsResource } from '../_dependencies';

import SchemaNames from '../schemas/names';

export type PropertyImageDocument = GridFsDocument;

export class PropertyImageResource extends GridFsResource<PropertyImageDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.PropertyImage);
  }
}
