import * as React from 'react';
import {
  Alert,
  Consume,
  LocaleContext,
  MobxComponent,
  OrganizationDocument,
  PropertyDocument,
  PropertyResource,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { PropertyForm } from './form';
import { PropertiesContext, PropertiesContextValue } from './propertiesProvider';

export class AddNewPropertyForm extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(PropertiesContext)
  private properties: PropertiesContextValue;

  createProperty = (property: PropertyDocument, resolve, reject) => {
    const { t } = this._locale;
    const propertyResource = new PropertyResource();
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const getLocationPromise = () =>
      new Promise<void>((resolve) =>
        propertyResource.getLocation(property.address!).then((location) => {
          if (location.lat !== null && location.lng !== null) {
            property.location[0] = location.lng;
            property.location[1] = location.lat;
          }
          resolve();
        }),
      );

    const firstPromise: Promise<any> = organization.isFreelancer ? Promise.resolve() : getLocationPromise();
    firstPromise
      .then(() => propertyResource.updateDocument(property))
      .then(() => {
        Alert.show('', t('components.dashboard.properties.new.uniktid'), 'success');
        this.globals.session.navigateTo(`/dashboard/properties/details/${property.id}`);
        this.properties.fetchProperties();
        return resolve();
      })
      .catch((err) => {
        Alert.show(
          t('components.dashboard.properties.new.errorCreateProperty'),
          t('components.dashboard.properties.new.propertyNotSaved'),
          'error',
        );
        return reject();
      });
  };

  private get newProperty() {
    const property = new PropertyResource().createDocument({});
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    property.organization = organization._id;
    if (!organization.isFreelancer) {
      property.address = {
        row1: '',
        row2: '',
        postOrt: '',
        postNr: '',
        country: 'Sweden',
      };
    }
    return property;
  }

  render() {
    const { t } = this._locale;
    return (
      <div style={{ padding: '20px' }}>
        <h2 className="ui header">
          <i className="icons" style={{ paddingTop: '0.1em' }}>
            <i className="map pin icon" />
            <i className="large corner plus icon" />
          </i>
          <div className="content">{t('Add a property')}</div>
        </h2>
        <div style={{ minWidth: '200px', maxWidth: '500px' }}>
          <PropertyForm property={this.newProperty} onSubmit={this.createProperty} />
        </div>
      </div>
    );
  }
}
