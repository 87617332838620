import React from 'react';
import { ActivityOccuranceDocument, MobxComponent, UserDocument } from '../../../../_dependencies';

interface Props {
  employee: UserDocument;
  event: ActivityOccuranceDocument;
  isSelected: boolean;
  onSelected: (employee: UserDocument) => void;
  onDeselected: (employee: UserDocument) => void;
}
export class ListItem extends MobxComponent<Props> {
  handleClicked = () => {
    if (this.props.isSelected) {
      this.props.onDeselected(this.props.employee);
    } else {
      this.props.onSelected(this.props.employee);
    }
  };

  statusIcon = () => {
    const { event, employee } = this.props;
    const requestSentAndWaiting = event.requestedStaff.some(
      (s) => String(s.user) === employee.id && s.status === 'waiting',
    );
    const requestSentAndDenied = event.requestedStaff.some(
      (s) => String(s.user) === employee.id && s.status === 'denied',
    );
    const requestSentAndAccepted = event.workingStaff.some((s) => String(s) === employee.id);

    if (requestSentAndAccepted) {
      return <i className="ui green checkmark icon" />;
    }
    if (requestSentAndWaiting) {
      return <i className="ui send icon" />;
    }
    if (requestSentAndDenied) {
      return <i className="ui red thumbs down icon" />;
    }
  };

  render() {
    const { employee } = this.props;
    return (
      <div
        key={employee.id}
        className={'item ' + (this.props.isSelected ? 'active' : '')}
        onClick={this.handleClicked}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <img className="ui avatar image" src={employee.imageUrl} />
        <div className="content">
          <div className="header">{employee.name}</div>
        </div>
        <div style={{ marginLeft: 'auto' }}>{this.statusIcon()}</div>
      </div>
    );
  }
}
