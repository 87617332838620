import Lodash from 'lodash';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  DeviceProvider,
  Link,
  OrganizationDocument,
  Platforms,
  PropertyDocument,
  PropertyResource,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
interface Props extends RouteComponentProps<any> {
  /** When an organization id is passed the widget is displayed instead of the property details page, when clicking on a card. */
  organization?: string;
}

const PropertyShowcaseView = (props: React.PropsWithChildren<Props>) => {
  const { t } = useLocale();
  const [isLoading, setIsLoading] = React.useState(true);
  const domElement = React.useRef<HTMLDivElement>(null);
  const [availableProperties, setAvailableProperties] = React.useState<PropertyDocument[]>([]);
  const device = React.useContext(DeviceProvider.Context);

  React.useEffect(() => {
    if (domElement.current) {
      $(domElement.current).find('.ui.center.aligned.header').transition({ animation: 'fade in', duration: 1000 });
      $(domElement.current)
        .find('.ui.fluid.link.card')
        .transition({ animation: 'fade in', duration: 1000, interval: 100 });
    }
  });

  React.useEffect(() => {
    new PropertyResource().getPropertiesWithPopulatedOrgs(props.organization).then((properties) => {
      setAvailableProperties(
        properties.filter((x) => {
          if (!x.organization) return false;
          return !(x.organization as any as OrganizationDocument).isSuspended;
        }),
      );
      setIsLoading(false);
    });
  }, []);

  if (props.children) {
    return <div>{props.children}</div>;
  }

  const containerStyle = device.size === 'mobile' ? { marginTop: '4rem' } : {};
  return (
    <div ref={domElement} className="ui container" style={containerStyle}>
      <div style={{ height: 0 }} /> {/* activates top margin for header below  */}
      <h1 className="ui center aligned header">
        {t('components.customer.activities.propertyShowcase.headerOne')}
        <span style={{ color: Platforms.primaryColor }}> {t('Properties')}</span>
        <div className="sub header">{t('components.customer.activities.propertyShowcase.headerTwo')}</div>
      </h1>
      <div style={{ height: '2em' }} />
      <div
        className="ui doubling stackable three column grid"
        style={device.size === 'mobile' ? { minHeight: '10em' } : {}}
      >
        {!isLoading ? (
          <PropertyList organization={props.organization} properties={availableProperties} />
        ) : (
          <div className="ui active loader" />
        )}
      </div>
      <div style={{ height: '4em' }} />
    </div>
  );
};

type PropertyListProps = {
  properties: PropertyDocument[];
  organization?: string;
};

const PropertyList = withRouter((props: PropertyListProps & RouteComponentProps<any>) => {
  const { tt } = useLocale();
  const linkUrl = (property: PropertyDocument) =>
    props.organization ? `${props.match.path}/widget/${property._id}` : `/properties/details/${property._id}`;

  const sortByDate = (a: PropertyDocument, b: PropertyDocument): number => {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    return 0;
  };

  return (
    <>
      {props.properties.sort(sortByDate).map((property) => (
        <Link to={linkUrl(property)} key={property.id} className="column">
          <div className="ui fluid link card transition hidden">
            <div className="image">
              {' '}
              <img src={property.imageUrl} alt={'Boka ' + property.name} title={'Boka ' + property.name} />
            </div>
            <div className="content">
              <h4 className="header" style={{ fontWeight: 400, fontSize: '1.2em' }}>
                <div className="sub header" style={{ fontSize: '0.85em' }}>
                  {Lodash.toUpper(property.address ? property.address.postOrt : '')}
                </div>
                {tt(property.name)}
              </h4>
            </div>
          </div>
        </Link>
      ))}
    </>
  );
});

export default withRouter(PropertyShowcaseView);
