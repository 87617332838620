import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { DeviceProvider } from '../../_dependencies';
import { useLocale } from '../../_locales';
import { SignupForm } from '../authentication/signupForm';
import { SliderSection } from '../authentication/sliderSection';
import { LanguageToggleIcons } from '../languageToggleIcons';

function CommercialColumn() {
  const device = useContext(DeviceProvider.Context);
  if (device.size == 'mobile') {
    return null;
  }

  return (
    <div
      className="ui eight wide column"
      style={{ padding: 0, minHeight: '100vh', display: 'flex', alignItems: 'center' }}
    >
      <div
        className="polygon"
        style={{
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          position: 'absolute',
        }}
      >
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <polygon points="100,100 100,0 10,0, 0,100" opacity="1" style={{ fill: 'rgb(252, 252, 252)' }} />
        </svg>
      </div>
      <SliderComponent />
    </div>
  );
}

const SliderComponent = () => {
  const { t } = useLocale();

  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    speed: 1000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    pauseOnHover: false,
    pauseOnFocus: false,
  };

  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <div style={flagContainer}>
        <LanguageToggleIcons />
      </div>
      <Slider {...settings} style={{ width: '100%' }}>
        <div key="1">
          <SliderSection
            title={t('Make your activities visible')}
            description={t('Both on adventurehero.se and on your own website.')}
            img="/static/platform/img/mac1.png"
          />
        </div>
        <div key="2">
          <SliderSection
            title={t('Receive bookings')}
            description={t('Let your customers book themselves. Get paid right away.')}
            img="/static/platform/img/mac3.png"
          />
        </div>
        <div key="3">
          <SliderSection
            title={t('Schedule your staff')}
            description={t('Plan what activities your staff will work on.')}
            img="/static/platform/img/mac2.png"
          />
        </div>
      </Slider>
      <a
        href="#register-start"
        style={{ display: 'flex', flexDirection: 'column', zIndex: 10, marginTop: '5rem', textAlign: 'center' }}
      >
        <h4>{t('Read more about our service')}</h4>
        <div>
          <i className="angle double down icon" style={{ fontSize: '3rem' }}></i>
        </div>
      </a>
    </div>
  );
};

function SignUpColumn() {
  const device = useContext(DeviceProvider.Context);
  const { t } = useLocale();

  const containerClassName = device.size == 'mobile' ? 'ui sixteen wide column' : 'ui eight wide column';
  return (
    <div
      className={containerClassName}
      style={{
        minHeight: '100%',
        padding: device.size === 'mobile' ? '0px' : '6em 2em 10em 2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundImage: 'url(/static/platform/img/hero-bg-cut.jpg)',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          top: 0,
          left: 0,
          width: device.size == 'mobile' ? '100vw' : '60vw',
          height: device.size != 'mobile' ? '100%' : '',
          position: 'absolute',
          backgroundSize: 'cover',
          backgroundImage: 'url(/static/platform/img/hero-bg-cut.jpg)',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div style={device.size !== 'mobile' ? authenticationContainer : authenticationContainerMobile}>
        {device.size === 'mobile' ? (
          <div style={flagContainerMobile}>
            <LanguageToggleIcons />
          </div>
        ) : null}

        <div className="column">
          <SignupForm textColor="white" titleColor="white" formLocation="Top" />
          <div className="ui fluid grid" style={{ width: '100%', margin: 0 }}>
            <div className="ui eight wide column" style={{ padding: '1em' }}>
              <Link
                to="/dashboard"
                className="noselect"
                style={{ cursor: 'pointer', color: '#29BB9C', textDecoration: 'none' }}
              >
                {t('Log in')}
              </Link>
            </div>
            <div className="ui eight wide column" style={{ textAlign: 'right', padding: '1em' }}>
              <a
                className="noselect"
                style={{ cursor: 'pointer', color: '#29BB9C', textDecoration: 'none' }}
                target="_blank"
                href="https://www.hiveandfive.se/policies/privacy?ref=www.adventurehero.se"
                rel="noreferrer"
              >
                {t('See terms')}
              </a>
            </div>
          </div>
        </div>
        <div>
          {device.size === 'mobile' ? (
            <a
              href="#register-start"
              style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', textAlign: 'center' }}
            >
              <p>{t('Read more about our service')}</p>
              <div>
                <i className="angle double down icon" style={{ fontSize: '2rem' }}></i>
              </div>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function AccountHeader() {
  const device = useContext(DeviceProvider.Context);

  return (
    <div
      className="ui grid"
      style={{
        margin: 0,
        display: 'flex',
        minHeight: '100vh',
      }}
    >
      <SignUpColumn />
      <CommercialColumn />
    </div>
  );
}

export default AccountHeader;

const authenticationContainer: React.CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  padding: '1em',
  borderRadius: '5px',
  width: 'calc(100% - 4em)',
  maxWidth: '400px',
  transform: 'translateY(10%)',
};

const authenticationContainerMobile: React.CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  color: 'white',
  padding: '1em',
  width: '100%',
  minHeight: '100vh',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const flagContainer: React.CSSProperties = {
  position: 'absolute',
  right: '20px',
  top: '20px',
  zIndex: 2,
};

const flagContainerMobile: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '2em',
};
