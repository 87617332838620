import React from 'react';
import { BookingDocument, BookingResource } from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { Header } from './Header';
import { BookingListFilter, SearchParams } from './filtering';
import { HandleBookingsComponent } from './handleBookings';

export const HandleBookingsPage = () => {
  const { t } = useLocale();
  const title = t('Manage your bookings');
  const sub = t('You can search for and manage your bookings here...');

  /** Collects all bookings from filter. If getDeletedBookings is true, only deleted bookings is collected */
  const fetchBookings = async (options: {
    fromDate?: Date;
    toDate?: Date;
    getDeletedBookings?: boolean;
    getRequestBookings?: boolean;
  }) => {
    return await new BookingResource().findOrganizationBookings(options);
  };

  const renderBookingList = (
    setSearchParams: (searchParams: SearchParams) => void,
    setPeriod: (fromDate?: Date | undefined, toDate?: Date | undefined) => void,
    setShowOnlyDeletedBookings: (showOnlyDeletedBookings: boolean) => void,
    setShowRequestBookings: (showRequestBookings: boolean) => void,
    setShowUninvoicedBookings: (showUninvoicedBookings: boolean) => void,
    showRequestBookings?: boolean,
    showOnlyDeletedBookings?: boolean,
    showUninvoicedBookings?: boolean,
    toDate?: Date,
    fromDate?: Date,
  ) => {
    return (
      <BookingListFilter
        toDate={toDate}
        fromDate={fromDate}
        filter={setSearchParams}
        onNewPeriod={setPeriod}
        setShowOnlyDeletedBookings={setShowOnlyDeletedBookings}
        setShowRequestBookings={setShowRequestBookings}
        setShowUninvoicedBookings={setShowUninvoicedBookings}
        showRequestBookings={showRequestBookings}
        showOnlyDeletedBookings={showOnlyDeletedBookings}
        showUninvoicedBookings={showUninvoicedBookings}
      />
    );
  };

  const renderHeader = () => <Header title={title} sub={sub} />;

  const renderCrossSellingButtons = (
    isPartOfDestination: boolean,
    showStats: boolean,
    invoiceLoader: boolean,
    bookings: BookingDocument[],
    generatePdf: () => void,
    markAsInvoiced: (invoiced: boolean, bookingList: BookingDocument[]) => void,
  ) => {
    if (!isPartOfDestination) return null;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        {renderGeneratePdfBtn(showStats, generatePdf)}
        {renderMarkAsInvoicedBtn(showStats, invoiceLoader, bookings, markAsInvoiced)}
      </div>
    );
  };

  const renderGeneratePdfBtn = (showStats: boolean, generatePdf: () => void) => {
    const buttonText = showStats ? t('Back to booking list') : t('Generate invoice documentation');
    return (
      <button style={{ marginTop: '1em' }} className="ui primary button" onClick={generatePdf}>
        {showStats ? <i className="icon arrow left"></i> : undefined}
        {buttonText}
      </button>
    );
  };

  const renderMarkAsInvoicedBtn = (
    showStats: boolean,
    invoiceLoader: boolean,
    bookings: BookingDocument[],
    markAsInvoiced: (invoiced: boolean, bookingList: BookingDocument[]) => void,
  ) => {
    const bookingsToBeInvoiced = !!bookings.find((booking) => !booking.isInvoiced);

    if (showStats) {
      if (bookingsToBeInvoiced) {
        return (
          <div>
            {invoiceLoader && <div style={{ marginRight: '1em' }} className="ui active inline loader"></div>}
            <button
              style={{ marginTop: '1em' }}
              className="ui blue button"
              onClick={() => markAsInvoiced(true, bookings)}
            >
              <i className="icon pencil alternate"></i>
              {t('Mark bookings as invoiced')}
            </button>
          </div>
        );
      } else {
        return (
          <div style={{ marginTop: '1em', fontSize: 15, marginRight: '1em' }}>
            <i className="icon green check"></i>
            {t('All bookings invoiced')}
          </div>
        );
      }
    }
  };

  return (
    <HandleBookingsComponent
      findBookings={fetchBookings}
      renderBookingList={renderBookingList}
      renderHeader={renderHeader}
      renderCrossSellingButtons={renderCrossSellingButtons}
    />
  );
};
