import * as React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router';
import { ErrorBoundary } from '../../../components/errorBoundary';
import { OffersContext } from '../../../contexts/offers.context';
import { ActivityTypeDocument, Consume, KosmicComponent, LocaleContext } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import OfferForm from './form/OfferForm';

export class NewOfferView extends KosmicComponent<RouteComponentProps<{}>> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(OffersContext)
  private _offers: OffersContext;

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this._offers.createNewOffer();
  }

  private navigateToOfferDetails = (offer: ActivityTypeDocument) => {
    const { history, match } = this.props;
    history.push(getParentUrl(match.url) + '/details/' + offer.id);
  };

  render() {
    const { t } = this._locale;
    if (!this._offers.selectedOffer?.isNew) return null;
    return (
      <div style={{ padding: '2em' }}>
        <h2 className="ui header">
          <i className="icons" style={{ paddingTop: '0.1em' }}>
            <i className="star outline icon" />
            <i className="corner plus icon" />
          </i>
          <div className="content">
            &nbsp;{t('components.dashboard.offers.new.addOffer')} {this.globals.session.currentOrganization.name}
          </div>
        </h2>
        <br />
        <ErrorBoundary>
          <div style={{ maxWidth: '600px' }}>
            <OfferForm onSave={this.navigateToOfferDetails} />
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

/** Trims off the last directory of the url */
const getParentUrl = (url: string) => {
  return url.substring(0, url.lastIndexOf('/'));
};
