import { History } from 'history';
import * as LocalStorage from 'local-storage'; // TODO: Can we trust that local storage always exists?
import Lodash from 'lodash';
import { autorun, extendObservable, observable } from 'mobx';
import * as Mongoose from 'mongoose';

import { DefaultUserDocument, DefaultUserResource } from '../_dependencies';
import { Store } from './_abstractStore';

export class SessionManager extends Store {
  private _version = '-2'; // This string can be used to invalidate local storage when making major changes TODO: make nicer or automatic
  private _identifier: string;
  private _userResource: DefaultUserResource = new DefaultUserResource();
  private _serverUser: DefaultUserDocument | undefined;
  private _history: History;

  @observable private _inMemoryStore: {
    user: DefaultUserDocument | undefined;
    organization: any;
  };

  /** The current organization id if authenticated */
  get currentOrganizationId(): Mongoose.Types.ObjectId {
    if (this.currentOrganization && this.currentOrganization._id) {
      return this.currentOrganization._id;
    }
    if (this.currentOrganization && !this.currentOrganization._id) {
      return this.currentOrganization;
    }
    return 'missing' as any;
  }

  get currentLocation(): Location {
    if (window) {
      return Lodash.merge({}, window.location, this._history.location) as any;
    }
    return this._history.location as any;
  }

  get currentHistory() {
    return this._history;
  }
  set currentHistory(value) {
    this._history = value;
  }

  get currentOrganization(): { _id: Mongoose.Types.ObjectId; id: string; name: string; admins: any } & any {
    return this.currentUser ? this.currentUser.organization : (undefined as any);
  }

  /** The current user object if authenticated */
  get currentUser(): DefaultUserDocument | undefined {
    return this._serverUser || this._inMemoryStore.user;
  }

  public forceUpdate() {
    LocalStorage.set(this._identifier, this._inMemoryStore);
  }

  public setCustomUserResource(resource: any) {
    this._userResource = resource;
  }

  constructor(options: { history: History; identifier: string; serverUser?: DefaultUserDocument }) {
    super();

    this._serverUser = options.serverUser;
    this._history = options.history;
    this._identifier = options.identifier + this._version;

    this._inMemoryStore = {
      user: undefined as any,
      organization: undefined as any,
    };
    this._inMemoryStore = LocalStorage.get(this._identifier) || this._inMemoryStore;

    extendObservable(this._inMemoryStore, {
      user: this._inMemoryStore.user,
      organization: this._inMemoryStore.organization,
    });

    autorun(() => {
      LocalStorage.set(this._identifier, this._inMemoryStore);
    });
  }

  /**
   * Checks if The current user has the required access and return true/false, false if not authenticated
   *  Use strict to ignore hierarchy
   */
  userHasRole(requiredRole: any, strict?: boolean): boolean {
    if (MODULE_ENVIRONMENT == 'node') {
      return !!this._serverUser && this._userResource.documentHasRole(this._serverUser, requiredRole, strict);
    } else {
      if (!this._inMemoryStore.user) {
        return false;
      }
      return this._userResource.documentHasRole(this._inMemoryStore.user, requiredRole, strict);
    }
  }

  /** Validates an ongoing authentication session, checks against a user role if specified */
  validateAuthentication(requiredRole?: string): Promise<boolean> {
    if (MODULE_ENVIRONMENT == 'node') {
      return new Promise<boolean>((resolve, reject) => {
        if (this._serverUser) {
          return requiredRole && !this.userHasRole(requiredRole, false)
            ? reject(new Error('Roles does not match'))
            : (resolve as any)(); // NOTE: while refactoring added 'as any'
        } else reject(new Error('Not signed in'));
      });
    } else {
      if (!this.currentUser) {
        return Promise.reject(new Error('No local userdata'));
      }
      const query = this.getUrlSearchParams();
      const username = query.get('username') || undefined;
      const password = query.get('password') || undefined;
      return this._userResource
        .validateAuthentication(requiredRole, username, password)
        .then((value) => {
          return Promise.resolve(value); //TODO: error handling?
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
  }

  /** Also validates an ongoing authentication session, but only checks against the server, removes the local storage if it fails */
  async isAuthenticated(): Promise<boolean> {
    if (MODULE_ENVIRONMENT == 'node') {
      throw new Error('dont');
    } else {
      // TODO: REMOVE...
      return true;
    }
  }
  authenticateWithDocument(user: DefaultUserDocument, org?: any): boolean {
    if (!user) return false;
    this._inMemoryStore.user = user;
    if (org) {
      this._inMemoryStore.organization = org;
    }
    this.forceUpdate();
    return true;
  }

  /** Begins an authenticated session as a user, optionally redirects to a given path to a when done */
  authenticate(username: string, password: string, path?: string): Promise<DefaultUserDocument> {
    if (this._serverUser) {
      return Promise.resolve(this._serverUser);
    } else {
      return this._userResource
        .login({ username: username, password: password })
        .then((user) => {
          this._inMemoryStore.user = user;
          this.forceUpdate();
          if (user && path && this._history) {
            this._history.push(path);
          }

          return user;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
  }

  /** Navigates to the route at the given path  */
  navigateTo(path: string): void {
    return this._history ? this._history.push(path) : undefined;
  }

  /** Navigates to the route at the given path without updating the history  */
  changePathTo(path: string): void {
    return this._history ? this._history.replace(path) : undefined;
  }

  /** Navigates to the previous route  */
  navigateBack(): void {
    return this._history ? this._history.goBack() : undefined;
  }

  /** Destroy any ongoing authenticated session, optionally redirects to a given path afterwards */
  deauthenticate(path?: string): Promise<boolean> {
    if (MODULE_ENVIRONMENT == 'node') {
      delete this._serverUser;
      return Promise.resolve(true);
    }

    const promise = !this.currentUser ? Promise.resolve(true) : this._userResource.logout();

    promise
      .catch((err) => {
        // If the request fails we assumes it was because of a 401 error, and we are already signed out
        return true;
      })
      .then((success) => {
        if (success) {
          delete this._inMemoryStore.user;
          delete this._inMemoryStore.organization;
          this.forceUpdate();

          if (path) {
            // TODO: This redirect exists because the modal acts wierd (locks up) when page not refreshes after logout.
            window.location.href = '/';
            //this.navigateTo(path)
          }
        }
      });
    return promise;
  }

  public setURLSearchParams(params: object | URLSearchParams) {
    if (MODULE_ENVIRONMENT == 'node' || !this._history) {
      return;
    }

    let first = true;
    let pathname = this._history.location.pathname;

    Lodash.forEach(params, (value: any, key: string) => {
      pathname = `${pathname}${first ? '?' : '&'}${key}=${value.toString()}`;
      first = false;
    });

    window.history.replaceState(null, pathname, pathname);
    // this._history.replace({ pathname: window.location.pathname, search: new URLSearchParams(params as any).toString() }) // This sadly does not work
  }

  public getUrlSearchParams(): URLSearchParams {
    if (MODULE_ENVIRONMENT == 'node' || !this._history) {
      return new URLSearchParams();
    }

    return new URLSearchParams(this._history.location.search);
  }
}
