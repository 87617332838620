import { isEqual, range, sumBy } from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';
import { DropdownField } from '.';
import {
  ActivityOccuranceDocument,
  BookingPriceCategoryDocument,
  DropdownItem,
  Field,
  Input,
  PriceCategoryDocument,
  Rules,
  getTickets,
  tt,
  visitors,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { usePrevious } from '../../../hooks/usePrevious';
import { SessionContext } from '../../../session.context';
import { combinePriceCategories } from '../selectPriceCategoryHelperFunctions';

interface Props {
  occurance: ActivityOccuranceDocument;
  selectedPriceCategories: BookingPriceCategoryDocument[];
  bookedPriceCategories?: BookingPriceCategoryDocument[];
  alreadyReservedVisitors?: number;
  setVisitorsFreely: boolean;
  onChange: (tickets: number, priceCategoy: PriceCategoryDocument) => void;
  disabled: boolean;
  isTmeTicket: boolean | undefined;
  isBookingRequest?: boolean;
}

/** Common rule for all fields */
export const ruleToSelectOneVisitor = (selectedPriceCategories: BookingPriceCategoryDocument[]) => {
  const { t } = useLocale();
  return Rules.CustomRule({
    validator: () => selectedPriceCategories.some((pc) => visitors(pc) > 0),
    prompt: t('You must choose at least one visitor'),
  });
};

/** Common rule for all fields */
export const ruleToSelectMoreThanMinVisitors = (
  selectedPriceCategories: BookingPriceCategoryDocument[],
  minVisitors,
  setVisitorsFreely,
) => {
  const { t } = useLocale();
  return Rules.CustomRule({
    validator: () => {
      const selectedVisitors = sumBy(selectedPriceCategories, (pc) => visitors(pc));
      return !minVisitors || setVisitorsFreely || selectedVisitors >= minVisitors;
    },
    prompt: `${t('In order to make a booking...')} ${minVisitors} ${t('visitors')}`,
  });
};

export const PriceCategoryDropdowns = ({
  occurance,
  selectedPriceCategories,
  bookedPriceCategories,
  alreadyReservedVisitors = 0,
  setVisitorsFreely,
  onChange,
  disabled,
  isTmeTicket,
  isBookingRequest,
}: Props) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const prevSelectedPriceCategories = usePrevious(selectedPriceCategories);
  const session = useContext(SessionContext);
  const { t } = useLocale();

  const { property, organization, availableVisitors, priceCategories, minVisitors } = occurance;
  const notManager = !session.userHasRole({ type: 'property.manager', property, organization });

  useEffect(() => {
    // Revalidate form after selectedPriceCategories changes since our custom rule above
    // isn't a pure function and react is slower than Jquery...
    const hasChanged = !isEqual(selectedPriceCategories, prevSelectedPriceCategories);
    const categories = selectedPriceCategories.filter(({ basePrice }) => !basePrice);
    if (inputRef.current && categories.length && hasChanged) {
      $(inputRef.current).closest('.form').form('validate form');
    }
  }, [selectedPriceCategories]);

  const selectedNrOfVisitors = sumBy(selectedPriceCategories, (pc) => visitors(pc));
  const allCategories = combinePriceCategories(priceCategories, bookedPriceCategories);
  const tickets = getTickets(allCategories);
  const isUsingInventory = tickets.some((t) => t.inventory);

  return (
    <>
      <Field
        label={
          <span style={{ fontSize: '1.4rem', fontWeight: 'normal' }}>
            {bookedPriceCategories
              ? t('Edit tickets')
              : isTmeTicket
              ? t('Choose number of participants')
              : t('Select tickets')}
          </span>
        }
      >
        {tickets.map((priceCategory, index) => {
          if (priceCategory.isHidden && notManager) return null;

          if (isUsingInventory) {
            // TODO: add support for alreadyReservedVistors, needs more data than just a number though.
            const avilableInventory = priceCategory.availableInventory || 0;
            const selectedCategory = selectedPriceCategories.find(
              ({ name, price, groupMultiplier }) =>
                isEqual(name, priceCategory.name) &&
                price === priceCategory.price &&
                groupMultiplier === priceCategory.groupMultiplier,
            );

            // Include already selected visitors when editing price categories
            const selectedTickets = selectedCategory?.tickets || 0;
            const realAvailableInventory = avilableInventory + priceCategory.tickets;

            const selectedCategoriesWithSameInventory = selectedPriceCategories.filter(
              ({ inventory, name }) =>
                inventory === priceCategory.inventory && tt(name, 'sv') !== tt(priceCategory.name, 'sv'),
            );
            const selectedTicketsInOtherCategories = sumBy(
              selectedCategoriesWithSameInventory,
              ({ tickets }) => tickets || 0,
            );

            const canSelectMore = realAvailableInventory - selectedTickets - selectedTicketsInOtherCategories > 0;

            const isInceaseButtonEnabled = setVisitorsFreely || canSelectMore;

            const dropdownItems = range(realAvailableInventory - selectedTicketsInOtherCategories + 1).map((i) => (
              <DropdownItem key={i} value={i} />
            ));

            return (
              <DropdownField
                key={'priceCategory' + tt(priceCategory.name, 'sv')}
                name={'priceCategory' + index}
                selectedTickets={selectedTickets}
                onChange={(tickets) => onChange(Number(tickets), priceCategory)}
                isInceaseButtonEnabled={isInceaseButtonEnabled}
                setVisitorsFreely={setVisitorsFreely}
                priceCategory={priceCategory}
                dropdownItems={dropdownItems}
                disabled={disabled}
              />
            );
          }

          // Calculate needed values
          let groupMultiplier = priceCategory.groupMultiplier || 1;
          const selectedCategory = selectedPriceCategories.find(
            ({ name, price, groupMultiplier }) =>
              isEqual(name, priceCategory.name) &&
              price === priceCategory.price &&
              groupMultiplier === priceCategory.groupMultiplier,
          );
          // Include already selected visitors when editing price categories
          const selectedTickets = selectedCategory?.tickets || 0;
          const realAvailableVisitors = availableVisitors + sumBy(bookedPriceCategories, (pc) => visitors(pc));

          // Makes dropdown list maximum 100 but still renders a number if a higher number was selected
          // by clicking the plus button
          const maxDropdownItems = Number(selectedTickets) < 99 ? 100 : selectedTickets + 1;
          if (groupMultiplier > maxDropdownItems) groupMultiplier = maxDropdownItems;
          const allOtherSelectedCategories = selectedPriceCategories.filter(
            ({ name }) => tt(name, 'sv') !== tt(priceCategory.name, 'sv'),
          );
          const selectedVisitorsInOtherCategories = sumBy(allOtherSelectedCategories, (pc) => visitors(pc));

          const availableToBook = Math.floor(
            (realAvailableVisitors - selectedVisitorsInOtherCategories - alreadyReservedVisitors) / groupMultiplier,
          );

          const canSelectMore =
            realAvailableVisitors - selectedNrOfVisitors - alreadyReservedVisitors >= groupMultiplier;

          const isInceaseButtonEnabled = setVisitorsFreely || canSelectMore;

          let dropdownItems = range(availableToBook + 1).map((i) => <DropdownItem key={i} value={i} />);

          // Add defaultPriceCategory tickets as a selectable item if needed
          if (selectedCategory && availableToBook < selectedTickets) {
            dropdownItems.push(<DropdownItem key={selectedCategory.tickets} value={selectedCategory.tickets} />);
          }

          // Limit dropdownitems for performance
          if (dropdownItems.length > maxDropdownItems) dropdownItems = dropdownItems.slice(undefined, maxDropdownItems);

          return (
            <DropdownField
              key={'priceCategory' + tt(priceCategory.name, 'sv')}
              name={'priceCategory' + index}
              selectedTickets={selectedTickets}
              onChange={(tickets) => onChange(Number(tickets), priceCategory)}
              isInceaseButtonEnabled={isInceaseButtonEnabled}
              setVisitorsFreely={setVisitorsFreely}
              priceCategory={priceCategory}
              dropdownItems={dropdownItems}
              disabled={disabled}
            />
          );
        })}

        {!disabled && (
          <div ref={inputRef}>
            <Input
              name="hiddenInput"
              className="mini"
              type="hidden"
              rules={[
                ruleToSelectMoreThanMinVisitors(selectedPriceCategories, minVisitors, setVisitorsFreely),
                ruleToSelectOneVisitor(selectedPriceCategories),
              ]}
            />
          </div>
        )}
      </Field>
    </>
  );
};
