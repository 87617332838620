import * as React from 'react';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';
import { Consume, DeviceProvider, IDevice, KosmicComponent, LocaleContext, LocationDocument } from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { SHMHIWeatherConverter } from './weatherConverter';

type WeatherData = { temperature: number; symbol: number; validTime: Date };

export const WeatherContext = React.createContext({
  callback: (location: LocationDocument, __: Date) => void '',
  currentWeather: null as null | WeatherData,
});
export interface WeatherContext {
  callback: (location: LocationDocument, date: Date) => void;
  currentWeather?: WeatherData;
  shouldBeHidden?: boolean;
}

interface State {
  currentWeather: null | WeatherData;
}

interface Props {
  shouldBeHidden?: boolean;
}

export class WeatherProvider extends React.Component<Props, State> {
  state: State = {
    currentWeather: null,
  };

  private converter: ReturnType<typeof SHMHIWeatherConverter>;

  private urlFor = (location: LocationDocument) =>
    `https://opendata-download-metfcst.smhi.se/api/category/pmp3g/version/2/geotype/point/lon/${location.lng.toString()}/lat/${location.lat.toString()}/data.json`;

  private callback = (location: LocationDocument, date: Date) => {
    const url = this.urlFor(location);
    if (!this.converter) {
      this.fetchWeatherData(url, date);
    } else {
      const currentWeather = this.converter(date);
      this.setState({ currentWeather });
    }
  };

  public async fetchWeatherData(url: string, target: Date) {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const timeSeries = data.timeSeries;

      this.converter = SHMHIWeatherConverter(timeSeries);
      const currentWeather = this.converter(target);
      this.setState({ currentWeather });
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const value = {
      callback: this.callback.bind(this),
      currentWeather: this.state.currentWeather,
      shouldBeHidden: this.props.shouldBeHidden,
    };
    return <WeatherContext.Provider value={value}>{this.props.children}</WeatherContext.Provider>;
  }
}

// Render the current weather
export class WeatherComponent extends KosmicComponent<{}> {
  @Consume(DeviceProvider.Context)
  protected device: IDevice;

  @Consume(WeatherContext)
  private weather: WeatherContext;

  @Consume(WidgetDataProvider.Context)
  protected widgetData: IWidgetData;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    date: {
      fontWeight: 200,
      fontSize: '0.7em',
    },
  };

  setWeatherLocation = () => {
    //NOTE: When using the new activity showcase, weather location might never be fetched
    if (!(this.widgetData.selectedProperty && this.widgetData.selectedProperty.location)) {
      return;
    }

    const date = new Date(
      (() => {
        return this.widgetData.selectedActivity ? this.widgetData.selectedActivity.start : this.widgetData.selectedDate;
      })(),
    );

    const [longitude, latitude] = this.widgetData.selectedProperty.location;
    const [lng, lat] = [Math.floor(longitude), Math.floor(latitude)];

    this.weather.callback({ lat, lng }, date);
  };

  componentDidMount() {
    this.setWeatherLocation();
  }

  componentDidUpdate() {
    this.setWeatherLocation();
  }

  render() {
    if (this.weather.currentWeather == null || this.weather.shouldBeHidden) {
      return null;
    }
    const { t } = this._locale;
    return (
      <div className="ui icon message" style={{ marginTop: 0 }}>
        <img className="icon" src={`/static/commons/weather_icons/${this.weather.currentWeather.symbol}.png`} />
        <div className="ui header">
          {t('widget.weather-forecast')}
          <div className="sub header">{this.weather.currentWeather.temperature} C</div>
        </div>
      </div>
    );
  }
}
