import React from 'react';
import { Field, Input, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface IBookingClosesFieldProps {
  bookingClosesTime?: number;
  setOfferBookingCloseTime: (key: 'bookingClosesBeforeEventInHours', value?: number) => void;
}

export const BookingClosesField = ({ bookingClosesTime, setOfferBookingCloseTime }: IBookingClosesFieldProps) => {
  const { t } = useLocale();
  const closingTime = bookingClosesTime ? String(bookingClosesTime) : '';

  const updateTime = (value?: string) => {
    if (!value) return setOfferBookingCloseTime('bookingClosesBeforeEventInHours', undefined);
    return setOfferBookingCloseTime('bookingClosesBeforeEventInHours', parseInt(value));
  };

  return (
    <Field label={t('Booking closes before scheduled appointment, hours')}>
      <Input
        name="Bokning stängs"
        icon="hourglass full"
        value={closingTime}
        onChange={updateTime}
        rules={[Rules.IsIntegerOneOrHigherElseEmpty()]}
        placeholder={t('(Optional)')}
      />
    </Field>
  );
};
