import React, { FC } from 'react';
import { useLocale } from '../../../_locales';
import DashboardTemplate from '../dashboardTemplate';

export const CreditCodeTemplate: FC = (props) => {
  const { t } = useLocale();

  const links = [{ path: '/dashboard/creditcodes', text: t('Generated credit codes') }];

  return (
    <DashboardTemplate title={t('Credit codes')} icon="recycle icon" tabs={links}>
      {props.children}
    </DashboardTemplate>
  );
};
