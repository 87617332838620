import Lodash from 'lodash';
import { action, autorun, computed, IReactionDisposer, observable, ObservableMap, runInAction } from 'mobx';
import Moment from 'moment-timezone';
import * as React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  Alert,
  Button,
  Checkbox,
  CheckedChildBoxes,
  Consume,
  LocaleContext,
  MobxComponent,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { DeleteButton } from '../../../../components/deleteButton';
import { OffersContext } from '../../../../contexts/offers.context';

interface ActivitySelection {
  occurance: ActivityOccuranceDocument;
  checked: boolean;
}

export class ListOfOfferOccurances extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(OffersContext)
  private _offers: OffersContext;

  @observable private _occurancesAndIfChecked: ObservableMap<{
    occurance: ActivityOccuranceDocument;
    checked: boolean;
  }> = new ObservableMap<{
    occurance: ActivityOccuranceDocument;
    checked: boolean;
  }>({});
  @observable private _loading = false;
  @observable private _isLoading = false;

  private _nrOfPreviouslyCheckedChildBoxes = 0;
  private _autorunDisposer: IReactionDisposer;

  componentDidMount() {
    this._autorunDisposer = autorun(this.reloadOffers);
  }

  componentDidUpdate() {
    if (this.nrOfCheckedChildBoxes && !this._nrOfPreviouslyCheckedChildBoxes) {
      this.domElement.find('.confirmationButton').transition('jiggle');
    }
    this._nrOfPreviouslyCheckedChildBoxes = this.nrOfCheckedChildBoxes;
  }

  componentWillUnmount() {
    this._autorunDisposer();
  }

  public reloadOffers = () => {
    const { selectedOffer } = this._offers;
    if (selectedOffer) {
      this._loading = true;
      return new ActivityOccuranceResource()
        .findWithPopulatedBookings({
          originatingActivity: selectedOffer,
          start: { $gte: Moment().startOf('day').toDate() },
        })
        .then((results) => {
          runInAction(() => {
            this._occurancesAndIfChecked.clear();
            Lodash.each(results, (occurance) =>
              this._occurancesAndIfChecked.set(occurance.id, {
                occurance: occurance,
                checked: false,
              }),
            );
            this._loading = false;
          });
        });
    }
  };

  get checkedOccurances() {
    const checkedOccurances: ActivityOccuranceDocument[] = [];
    this._occurancesAndIfChecked.forEach((value: ActivitySelection) => {
      if (value.checked) {
        checkedOccurances.push(value.occurance);
      }
    });
    return checkedOccurances;
  }

  private handleOnDeleteOccurances = async () => {
    const { t } = this._locale;
    const occurancesToDelete = this.checkedOccurances.map((o) => o.id);

    this._isLoading = true;
    try {
      await new ActivityOccuranceResource().deleteOccurances(occurancesToDelete);
      Alert.show(t('listOfOfferOccurances.removedTimes'), t('listOfOfferOccurances.greatMessage'), 'success');
      runInAction(() => {
        this._isLoading = false;
        for (const occurance of occurancesToDelete) {
          this._occurancesAndIfChecked.delete(occurance);
        }
      });
    } catch (error) {
      Alert.show(t('listOfOfferOccurances.noTimes'), t('listOfOfferOccurances.oopsMessage'), 'error');
    }
  };

  updatePublishStatus = async (isPublished: boolean) => {
    const { t } = this._locale;
    const checkedOccurances = this.checkedOccurances;
    const ids = checkedOccurances.map((o) => o.id);
    this._isLoading = true;
    try {
      await new ActivityOccuranceResource().publishOccurances(ids, isPublished);
      runInAction(() => {
        this._isLoading = false;
        for (const occurance of checkedOccurances) {
          const updatedOccurance = { ...occurance, isPublished } as ActivityOccuranceDocument;
          this._occurancesAndIfChecked.set(occurance.id, { occurance: updatedOccurance, checked: true });
        }
      });
    } catch (error) {
      Alert.show(t('listOfOfferOccurances.couldNotPublish'));
    }
  };

  @action private handleParentCheckboxChecked = (checked: boolean) => {
    Lodash.each(this._occurancesAndIfChecked.keys(), (occuranceId) => {
      // Lets not check activities with bookings!
      const bookings = (this._occurancesAndIfChecked.get(occuranceId) as any).occurance.bookings;
      if (!bookings || !bookings.length) {
        (this._occurancesAndIfChecked.get(occuranceId) as any).checked = checked;
      }
    });
  };

  @computed private get nrOfCheckedChildBoxes(): number {
    return Lodash.sumBy(this._occurancesAndIfChecked.values(), (value: ActivitySelection) => (value.checked ? 1 : 0));
  }

  @computed private get nrOfChildBoxes(): number {
    return Lodash.sumBy(this._occurancesAndIfChecked.values(), (value: ActivitySelection) =>
      !value.occurance.bookings || !value.occurance.bookings.length ? 1 : 0,
    );
  }

  @computed public get checkedChildBoxesStatus(): CheckedChildBoxes {
    if (!this.nrOfCheckedChildBoxes) {
      return 'none';
    } else if (this.nrOfCheckedChildBoxes == this.nrOfChildBoxes) {
      return 'all';
    } else {
      return 'some';
    }
  }

  @computed private get tableBody() {
    const { t, tt } = this._locale;
    const addCheckBox = (occurance: ActivityOccuranceDocument, checked: boolean) => {
      if (occurance.bookings && occurance.bookings.length) {
        return undefined;
      }
      return (
        <Checkbox
          checked={checked}
          onCheckedOrUncheked={(checked) => ((this._occurancesAndIfChecked.get(occurance.id) as any).checked = checked)}
        />
      );
    };

    const sortedOccurances = Lodash.sortBy(
      this._occurancesAndIfChecked.values(),
      (value: { occurance: ActivityOccuranceDocument }) => value.occurance.start,
    );
    return Lodash.map(sortedOccurances, ({ occurance, checked }: ActivitySelection) => (
      <tr key={occurance.id}>
        <td>
          <i className="calendar check icon" style={!occurance.isPublished ? { color: 'transparent' } : {}} />
          {Moment(occurance.start).tz('Europe/Stockholm').format('YYYY-MM-DD')}
          &nbsp;&nbsp;&nbsp;
          {Moment(occurance.start).tz('Europe/Stockholm').format('HH:mm')}
        </td>
        <td>{tt((occurance.property as any).name)}</td>
        <td>
          {occurance.bookings ? occurance.bookings.length : 0} {t('pcs')}
        </td>
        <td>
          {!this.globals.device.isDesktopSize ? (
            <span>
              {occurance.availableVisitors} {t('left')}
            </span>
          ) : (
            <span>
              {occurance.availableVisitors} {t('of')} {occurance.visitorCapacity} {t('slots left')}
            </span>
          )}
        </td>
        <td>{occurance.duration} min</td>
        <td>{addCheckBox(occurance, checked)}</td>
      </tr>
    ));
  }

  @computed private get deleteButton() {
    const { t } = this._locale;

    return (
      <DeleteButton
        style={{ margin: '1rem' }}
        disabled={this.checkedChildBoxesStatus == 'none'}
        deleting={this._isLoading}
        onConfirmed={this.handleOnDeleteOccurances}
        confirmationText={t('Are you sure?')}
      >
        {t('listOfOfferOccurances.removexTimes')}
      </DeleteButton>
    );
  }
  @computed private get publishButton() {
    const { t } = this._locale;
    const nothingSelected = this.checkedChildBoxesStatus == 'none';
    const disabled = nothingSelected || this._isLoading;
    const noPublishedTimeSelected = this.checkedOccurances.some((o) => !o.isPublished);

    if (noPublishedTimeSelected || nothingSelected) {
      return (
        <Button
          className="primary"
          disabled={disabled}
          onClick={() => this.updatePublishStatus(true)}
          style={verticalButtonMargin}
        >
          <i className="calendar check icon" />
          {t('Publish marked times')}
        </Button>
      );
    }

    return (
      <Button disabled={disabled} onClick={() => this.updatePublishStatus(false)} style={verticalButtonMargin}>
        <i className="calendar outline icon" />
        {t('Unpublish marked times')}
      </Button>
    );
  }

  @computed private get checkBoxForTableHeader() {
    // if we cant find one occurance that has no bookings, we dont need the checkbox, return undefined...
    if (
      !Lodash.find(
        this._occurancesAndIfChecked.values(),
        (value: ActivitySelection) => !value.occurance.bookings || !value.occurance.bookings.length,
      )
    ) {
      return undefined;
    }

    return (
      <Checkbox
        onCheckedOrUncheked={this.handleParentCheckboxChecked}
        checkedChildBoxes={this.checkedChildBoxesStatus}
      />
    );
  }

  render() {
    const { t, tt } = this._locale;
    const { selectedOffer } = this._offers;
    if (!selectedOffer) {
      return <span />;
    }
    if (this._loading) {
      return <div className="ui centered inline large loader" />;
    }
    if (!this._occurancesAndIfChecked.values().length) {
      return (
        <div style={{ textAlign: 'center' }}>
          <b>
            {t('There are no planned start times for')} {tt(selectedOffer.title)}
          </b>
        </div>
      );
    }

    return (
      <div>
        <h3 className="sticky" style={headerStyle}>
          <span style={{ flex: 1 }}>{t('Upcoming start times')}</span>
          <div style={headerButtonGroupStyle}>
            {this.publishButton}
            {this.deleteButton}
          </div>
        </h3>
        <div className={'ui basic segment' + (this._isLoading ? ' loading' : '')}>
          <table className="ui very basic compact table">
            <thead>
              <tr className="sticky padded" style={{ background: 'white' }}>
                <th>
                  <i className="calendar outline icon" />
                  {t('Start time')}
                </th>
                <th>
                  <i className="map pin icon" />
                  {t('Property')}
                </th>
                <th colSpan={2}>
                  <i className="ticket icon" />
                  {t('Bookings and slots')}
                </th>
                <th>
                  <i className="hourglass full icon" />
                  {t('Duration')}
                </th>
                <th>{this.checkBoxForTableHeader}</th>
              </tr>
            </thead>
            <tbody>{this.tableBody}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

const headerStyle: React.CSSProperties = {
  paddingTop: '.5em',
  paddingBottom: '1.2em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const headerButtonGroupStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  alignItems: 'center',
};

const verticalButtonMargin: React.CSSProperties = {
  marginTop: '.5rem',
  marginBottom: '.5rem',
};
