import * as React from 'react';
import { Field, Fields, Input, Rules } from '../../../../../../_dependencies';
import { useLocale } from '../../../../../../_locales';
import { PersonParams } from './personsForm';

interface Props {
  person: PersonParams;
  onPersonChanged: (person: PersonParams) => void;
}

export const PersonFields = (props: Props) => {
  const { person } = props;
  const { t } = useLocale();

  const getBirthdayStringFrom = (person: PersonParams) => {
    if (person.dob) {
      const { year, month, day } = person.dob;
      const yearString = isNaN(year) ? '' : String(year);
      const monthString = isNaN(month) ? '' : month < 10 ? '0' + month : String(month);
      const dayString = isNaN(day) ? '' : day < 10 ? '0' + day : String(day);

      return yearString + monthString + dayString;
    }
  };

  return (
    <>
      <Fields>
        <Field label={t('First name')} width="eight wide">
          <Input
            rules={[Rules.NotEmpty()]}
            name="firstname"
            value={person.first_name || ''}
            onChange={(value) => {
              const updatedPerson = { ...person, first_name: value };
              props.onPersonChanged(updatedPerson);
            }}
          />
        </Field>
        <Field label={t('Last name')} width="eight wide">
          <Input
            rules={[Rules.NotEmpty()]}
            name="lastname"
            value={person.last_name || ''}
            onChange={(value) => {
              const updatedPerson = { ...person, last_name: value };
              props.onPersonChanged(updatedPerson);
            }}
          />
        </Field>
        <Field label={t('Date of birth')} width="eight wide">
          <Input
            rules={[Rules.NotEmpty(), Rules.ExactLength(8)]}
            name="dob"
            defaultValue={getBirthdayStringFrom(person)}
            onChange={(value) => {
              const year = value.substr(0, 4);
              const month = value.substr(4, 2);
              const day = value.substr(6, 2);

              const updatedPerson = {
                ...person,
                dob: {
                  year: parseInt(year),
                  month: parseInt(month),
                  day: parseInt(day),
                },
              };
              props.onPersonChanged(updatedPerson);
            }}
          />
        </Field>
      </Fields>
      <Field label={t('Email')} width="sixteen wide">
        <Input
          rules={[Rules.NotEmpty(), Rules.IsEmailOrEmpty()]}
          name="email"
          defaultValue={person.email}
          onChange={(email) => {
            const updatedPerson = { ...person, email };
            props.onPersonChanged(updatedPerson);
          }}
        />
      </Field>
    </>
  );
};
