import { Stripe, TokenResult, StripeError } from '@stripe/stripe-js';
import * as React from 'react';
import {
  Consume,
  InlineErrorMessage,
  LocaleContext,
  MobxComponent,
  PaymentResource,
  getStripeInstance,
  MarketplaceLibrary as Lib,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { PaymentMethodComponent } from './cardPaymentComponent';

// @see https://stripe.com/docs/declines/codes
type DeclineCode =
  | 'authentication_required'
  | 'approve_with_id'
  | 'call_issuer'
  | 'card_not_supported'
  | 'card_velocity_exceeded'
  | 'currency_not_supported'
  | 'do_not_honor'
  | 'do_not_try_again'
  | 'duplicate_transaction'
  | 'expired_card'
  | 'fraudulent'
  | 'generic_decline'
  | 'generic_decline'
  | 'incorrect_number'
  | 'incorrect_cvc'
  | 'incorrect_pin'
  | 'incorrect_zip'
  | 'insufficient_funds'
  | 'invalid_account'
  | 'invalid_amount'
  | 'invalid_cvc'
  | 'invalid_expiry_year'
  | 'invalid_number'
  | 'invalid_pin'
  | 'issuer_not_available'
  | 'lost_card'
  | 'merchant_blacklist'
  | 'generic_decline'
  | 'new_account_information_available'
  | 'no_action_taken'
  | 'not_permitted'
  | 'offline_pin_required'
  | 'online_or_offline_pin_required'
  | 'pickup_card'
  | 'pin_try_exceeded'
  | 'processing_error'
  | 'reenter_transaction'
  | 'restricted_card'
  | 'revocation_of_all_authorizations'
  | 'revocation_of_authorization'
  | 'security_violation'
  | 'service_not_allowed'
  | 'stolen_card'
  | 'stop_payment_order'
  | 'testmode_decline'
  | 'transaction_not_allowed'
  | 'try_again_later'
  | 'withdrawal_count_limit_exceeded';

interface StripeCardError extends StripeError {
  decline_code: DeclineCode;
}

function isStripeCardError(err: StripeError): err is StripeCardError {
  return err.code == 'card_declined' && 'decline_code' in err;
}

interface Props {
  onSubmit: (resolve, reject, errorCallback, token?: string, cardCountryCode?: string) => void;
  submitButton?: JSX.Element;
  info?: JSX.Element;
  customerFullName?: string;
  hideTitle?: boolean;
  cart: Lib.CartItem[];
}
interface State {
  apiKey?: string;
}
/**
 * NOTE: This is the only component used by the widget module that is
 * placed in the components folder. This is why it uses Platforms.URL for the image src
 * TODO: It should be refactored to be more general, most likely it does not need to render the logotype
 */
export class StripeComponent extends MobxComponent<Props, State> {
  private static stripe: Stripe;
  public state: State = {};
  _errorComponent: InlineErrorMessage;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  async componentDidMount() {
    const result = await new PaymentResource().getStripePublishableKey();
    if (StripeComponent.stripe == undefined) {
      StripeComponent.stripe = await getStripeInstance();
    }
    this.setState({ apiKey: result.key });
  }

  private displayErrorMessage(error: { code: 0 | 1 | 2 | 3; message?: string; availableVisitors?: number; raw?: any }) {
    // NOTE: this assumes that the tryPayment method on the server actually returns an error in the documented format
    /**
     * If the try payment fails server side an error field will be included in response with the following format, its still a failing response
     * "error": {
     *  "code": 0,1,2 depending on the type of error
     *  "raw": any raw/unexpected errors thrown on the server
     *  "message": any explicit given message from the server side function,
     *  "availableVisitors": the number of available visitors remaining, if code is 2
     * }
     *
     * Code 0 indicates that an unexcpected error was thrown, will include a raw error
     * Code 1 will be thrown with a message (no raw error) and means indicates invalid PaymentIntent status
     * Code 2 indiciates that the number of available visitors have changed since the attempt started,
     *        message and availableVisitors will be set with the number of visitors actually remaining, no raw error.
     */

    const { raw } = error;
    const { t } = this._locale;
    if (error?.code === 3) {
      let errorText = t('Something went wrong on the server! Please try again later');
      switch (error.message) {
        case 'No associated activities for this ticket':
          errorText = t('No associated activities found for this ticket');
          break;
        case 'Ticket already exists!':
          errorText = t('Ticket already exists');
          break;
        default:
          errorText;
      }

      return this._errorComponent.show(errorText);
    }
    if (error.code === 2) {
      this._errorComponent.show(
        t('components.payment.stripeComponent.double'),
        <>{t('components.payment.stripeComponent.doubleMessage')}</>,
      );
    } else if (raw && raw.type && raw.code && raw.message) {
      // Handle card related stripe error
      // @see https://stripe.com/docs/declines/codes
      if (isStripeCardError(raw)) {
        let textNode: React.ReactNode;
        switch (raw.decline_code) {
          case 'do_not_honor':
            textNode = t('stripe-errors.do-not-honor');
            break;

          case 'incorrect_pin':
          case 'incorrect_cvc':
          case 'incorrect_zip':
          case 'invalid_cvc':
          case 'invalid_expiry_year':
          case 'invalid_number':
          case 'invalid_pin':
            textNode = t('stripe-errors.invalid-card');
            break;

          case 'expired_card':
            textNode = t('stripe-errors.expired-card');
            break;
          case 'insufficient_funds':
            textNode = t('stripe-errors.insufficient-funds');
            break;
          default:
            textNode = raw.message;
            break;
        }
        this._errorComponent.show(undefined, textNode);
      } else {
        // Stripe error
        this._errorComponent.show(undefined, raw.message);
      }
    } else {
      // Uncaught platform error

      this._errorComponent.show(t('Payment could not be processed'), t('Payment failed, please make sure...'));
    }
  }

  private handleOnSubmit = (resolve: () => void, reject: (err: any) => void, result?: TokenResult) => {
    if (result && result.error) {
      // TODO: Needs a bit of TLC
      this._errorComponent.show(undefined, result.error.message);
      return reject(result.error);
    }
    this.props.onSubmit(
      resolve,
      reject,
      (err) => this.displayErrorMessage(err), // TODO: Needs a bit of TLC
      result?.token.id,
      result?.token.card!.country!,
    );
  };

  render() {
    if (!this.state.apiKey) {
      return null;
    }

    return (
      <div>
        <InlineErrorMessage
          ref={(component) => {
            this._errorComponent = component as any;
          }}
        />
        <PaymentMethodComponent cart={this.props.cart} apiKey={this.state.apiKey} onSubmit={this.handleOnSubmit} />
      </div>
    );
  }
}
