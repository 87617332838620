import React, { useContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { DeviceProvider, GlobalsContext } from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { Maybe } from '../../maybe';
import { useProperties } from './propertiesProvider';
import { PropertyStore } from './propertyStore';

export { PropertyBookingView } from './booking';
export { PropertyCalendarView } from './calendar';
export { PropertyDetailsTemplate, PropertyDetailsView, PropertySettingsView } from './details';
export { EmployeeScheduleView } from './employeeSchedule';
export { PropertyEditEmployeesView, PropertyEmployeesView } from './employeesView';
export { AddNewPropertyForm } from './new';
export { PropertiesTemplate } from './template';

export const PropertiesIndex = () => {
  const { t } = useLocale();
  const { session } = useContext(GlobalsContext);
  const { size } = useContext(DeviceProvider.Context);
  const { isSidebarOpen, setIsSidebarOpen } = useProperties();

  useEffect(() => {
    setIsSidebarOpen(true);
  }, []);

  return (
    <div
      style={{ padding: '1rem', marginRight: size == 'mobile' && !isSidebarOpen ? 0 : '18.5rem', maxWidth: '80rem' }}
    >
      <div className="ui segment">
        <h2 className="ui header">{t('Properties')}</h2>
        <p>{t('Propeties are a central part of...')}</p>
        <p>{t('In many cases, it is enough to...')}</p>
        <Maybe if="organization.manager">
          <Link className="ui primary button" to="/dashboard/properties/new">
            {t('Add a property')}
          </Link>
        </Maybe>
      </div>

      <div className="ui segment">
        <h2 className="ui header">{t('Places')}</h2>
        <p>{t('Create places that are relevant to...')}</p>
        <p>{t('Examples of places can be...')}</p>
        <Maybe if="organization.manager">
          <Link className="ui button" to="/dashboard/properties/places">
            {t('Add a place')}
          </Link>
        </Maybe>
      </div>

      <div className="ui segment">
        <h2 className="ui header">{t('Destinations')}</h2>
        <p>{t('Destinations is a feature that allows...')}</p>
        <p>{t('Do you want to create or...')}</p>
      </div>
    </div>
  );
};

/** Handles redirecting to correct page */
export const PropertiesRedirect = () => {
  if (PropertyStore.Instance.hasProperty()) {
    return <Redirect to={`/dashboard/properties/details/${PropertyStore.Instance.property.id}/schedule`} />;
  }
  return <Redirect to="/dashboard/properties/info" />;
};
