import React from 'react';
import { Dropdown, DropdownItem, Field, Icon, PlaceDocument, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { BreakPoint } from './routeCreatorField';

interface Props {
  icon: Icon;
  breakPoint: BreakPoint;
  title: string;
  places: PlaceDocument[];
  style?: 'secondary' | 'primary';
  indexPosition: number;
  onBreakPointPlaceChanged: (indexPosition: number, placeId: string) => void;
  addingRoutes: boolean;
}

const SelectRouteBreakpointPlaceInput: React.FC<Props> = ({
  icon,
  places,
  title,
  style = 'primary',
  indexPosition,
  onBreakPointPlaceChanged,
  breakPoint,
  addingRoutes,
}) => {
  const { t, tt } = useLocale();

  return (
    <Field>
      {style === 'primary' && <h4 style={{ textAlign: 'left' }}>{title}</h4>}
      {style === 'secondary' && <h5 style={{ textAlign: 'left' }}>{title}</h5>}
      <Dropdown
        icon={icon}
        fluid
        searchable
        name="Places"
        placeholder={t('Select a place')}
        rules={addingRoutes ? [Rules.NotEmpty()] : []}
        value={breakPoint.place?.id || ''}
        onChange={(placeId) => {
          onBreakPointPlaceChanged(indexPosition, placeId);
        }}
      >
        {places?.map((p) => (
          <DropdownItem key={`Place-${p.id}`} value={p.id}>
            {tt(p.name)}
          </DropdownItem>
        ))}
      </Dropdown>
    </Field>
  );
};

export default SelectRouteBreakpointPlaceInput;
