import React from 'react';

interface Props {
  style?: React.CSSProperties;
}
export const Cards: React.FC<Props> = ({ children, style }) => {
  return (
    <div className="ui cards" style={style}>
      {children}
    </div>
  );
};
