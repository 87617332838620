import React, { useEffect, useState } from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeResource,
  BookingDocument,
  BookingResource,
  ConfirmationButton,
  GiftCardDocument,
  MarketplaceLibrary as Lib,
  MarketplaceResource,
  getRescheduleInsurance,
} from '../../_dependencies';
import { useLocale } from '../../_locales';
import { RescheduleBookingInfo } from './rescheduleBookingInfo';

interface Props {
  saveCreditCodeDetails: (creditCode: GiftCardDocument) => void;
}

export const RescheduleForm = ({ saveCreditCodeDetails }: Props) => {
  const params = new URLSearchParams(location.search);
  const externalId = params.get('id');
  const { t } = useLocale();

  const [paidAmount, setPaidAmount] = useState<number>();
  const [productInfo, setProductInfo] = useState<Lib.ProductInformationResponse>();
  const [booking, setBooking] = useState<Lib.CartBooking>();
  const [error, setError] = useState<any>();
  const [isRescheduleAvailable, setIsRescheduleAvailable] = useState<boolean>();
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [discountCode, setDiscountCode] = useState<string | undefined>();
  const [giftcardCode, setGiftcardCode] = useState<string>('');
  const [giftcardValue, setGiftcardValue] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<string>('');

  const bookingResource = new BookingResource();
  const occuranceResource = new ActivityOccuranceResource();
  const activityTypeResource = new ActivityTypeResource();
  const marketplaceResource = new MarketplaceResource();
  const _setProductInfo = (_productInfo: Lib.ProductInformationResponse) => {
    const productInfo = { ..._productInfo };
    const product = productInfo.product;
    Object.defineProperty(product, 'value', {
      get: function (this: typeof product): number {
        return this.events.reduce((acc, event) => acc + event.value, 0);
      },
    });
    productInfo.product = product;
    setProductInfo(productInfo);
  };

  useEffect(() => {
    setDiscountPercentage(productInfo?.product?.events[1]?.metadata?.applied_discount_percentage);
    setDiscountCode(productInfo?.product?.events[1]?.metadata?.applied_discount_code);
    if (productInfo?.payments[0].externalId != undefined) {
      setGiftcardCode(productInfo?.payments[0].externalId);
    }
    if (productInfo?.payments[0].value != undefined) {
      setGiftcardValue(productInfo?.payments[0].value);
    }
    if (productInfo?.payments[0].type != undefined) {
      setPaymentType(productInfo?.payments[0].type);
    }
  }, [productInfo]);

  useEffect(() => {
    if (!externalId || productInfo) {
      return;
    }
    const getProductInfo = () =>
      marketplaceResource
        .getInformationForProduct(externalId)
        .then(_setProductInfo)
        .catch(() => setError('kunde inte hämta information'));
    getProductInfo();
  }, [productInfo]);

  useEffect(() => {
    if (!externalId) return;
    const getBooking = async () =>
      await bookingResource
        .getCustomerBooking(externalId)
        .then(async (res) => await attachPopulatedOccurence(res))
        .catch(() => setError('kunde inte hämta bokning'));
    getBooking();
  }, []);

  async function attachActivityType(occ: ActivityOccuranceDocument) {
    if (!occ.originatingActivity) return;
    const activityType = await activityTypeResource
      .get(occ.originatingActivity)
      .then((res) => res)
      .catch(() => setError('kunde inte hämta aktivitet'));
    if (!activityType) return;
    return { ...occ, originatingActivity: activityType };
  }

  async function attachPopulatedOccurence(booking: BookingDocument): Promise<Lib.CartBooking | undefined> {
    if (!booking) {
      setError('bokningen finns inte');
      return;
    }
    const occ = await occuranceResource
      .get(booking.occurance)
      .then((res) => attachActivityType(res))
      .catch(() => setError('kunde inte hämta aktivitet'));
    if (!occ) return;
    setBookingToState({
      ...booking,
      occurance: occ,
    } as Lib.CartBooking);
  }

  function setBookingToState(booking: Lib.CartBooking) {
    setBooking(booking);
    const insurance = getRescheduleInsurance(booking.priceCategories);
    const { lastRescheduleTime } = insurance!.rescheduleInsurance;
    setIsRescheduleAvailable(
      new Date(booking.occurance.start).getTime() - lastRescheduleTime * 3600000 > new Date().getTime(),
    );
  }

  useEffect(() => {
    if (!externalId) return;
    const getProductStatus = () =>
      new MarketplaceResource()
        .getPaidAmountForProduct(externalId)
        .then((x) => {
          setPaidAmount(x.amountPaidManually + x.amountPaidWithStripe + x.amountPaidWithGiftCard);
        })
        .catch(() => setError('kunde inte hämta betalning'));
    getProductStatus();
  }, []);

  const cancelBookingAndGetCreditCode = async (bookingId: string) => {
    try {
      const response = await fetch('/api/v1/reschedule?id=' + bookingId);
      const newCreditCode = await response.text();
      return JSON.parse(newCreditCode);
    } catch (err) {
      console.log(err);
    }
  };
  if (error) {
    return <h4> {t('The booking could not be found...')}</h4>;
  } else if (paidAmount === undefined || !booking || !productInfo || !booking.occurance.originatingActivity) {
    return (
      <div style={containerStyle}>
        <p>{'Preparing ...'}</p>
        <div className="ui active inverted dimmer">
          <div className="ui loader"></div>
        </div>
      </div>
    );
  }

  const handleSubmit = async () => {
    const creditCode = await cancelBookingAndGetCreditCode(String(booking._id));
    if (creditCode.id) {
      saveCreditCodeDetails(creditCode);
      params.delete('id');
      params.append('voucher', creditCode.id);
      history.replaceState(null, '', '?' + params);
    }
  };

  const insurance = getRescheduleInsurance(booking.priceCategories);
  const { lastRescheduleTime } = insurance!.rescheduleInsurance;

  return (
    <div style={containerStyle}>
      <h2 style={{ width: '100%', textAlign: 'left' }}>{t('Reschedule your activity')}</h2>
      <RescheduleBookingInfo
        paidAmount={paidAmount}
        booking={booking}
        discountPercentage={discountPercentage}
        discountCode={discountCode}
        giftcardCode={giftcardCode}
        giftcardValue={giftcardValue}
        paymentType={paymentType}
      />
      {paidAmount === 0 ? (
        <h4>{t('This booking is not paid...')}</h4>
      ) : isRescheduleAvailable ? (
        <h4>{t('Can be rescheduled at least...').replace('###', lastRescheduleTime.toString())}</h4>
      ) : (
        <h4>{t('Last reschedule time has passed...')}</h4>
      )}
      <ConfirmationButton
        disabled={!isRescheduleAvailable || paidAmount === 0}
        className={'fluid green button'}
        label={<div>{t('Cancel booking and receive your credit code')}</div>}
        activeButton={
          <button
            className={'ui fluid green button'}
            type="button"
            onClick={(e) => {
              handleSubmit();
              e.stopPropagation();
            }}
          >
            {t('Are you sure?')}
          </button>
        }
      />
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '16rem',
  minWidth: '16rem',
  maxWidth: '52rem',
  marginBottom: '1rem',
  padding: '0 1rem',
};
