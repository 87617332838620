import React from 'react';
import Moment from 'moment-timezone';
import { PDFPreview } from './pdfPreview';
import {
  BookingDocument,
  KosmicComponent,
  ActivityOccuranceDocument,
  ActivityTypeDocument,
} from '../../../../_dependencies';

export interface StatisticsRow {
  bookingNumber: string;
  price: number;
  date: Date;
  vatRate: number;
  vatAmount: number;
  referenceNr?: string;
}

interface Props {
  bookings: BookingDocument[];
  fromDate?: Date;
  toDate?: Date;
}

interface State {
  statistics: StatisticsRow[];
}

export default class StatisticsGenerator extends KosmicComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      statistics: [],
    };
  }

  componentDidMount() {
    const statisticRows = this.generateStatisticRows(this.props.bookings);
    this.setState({ statistics: statisticRows });
  }

  private generateStatisticRows(bookings: BookingDocument[]) {
    const statisticRows: StatisticsRow[] = bookings.map((booking) => {
      const occurance = booking.occurance as any as ActivityOccuranceDocument;
      const activity = occurance.originatingActivity! as any as ActivityTypeDocument;

      const createdAtDate = new Date(booking.createdAt);
      const taxRate = activity.taxRate;
      const vatAmount = booking.totalPrice - booking.totalPrice / (taxRate + 1);

      return {
        bookingNumber: booking.number,
        price: booking.totalPrice,
        date: createdAtDate,
        vatAmount,
        vatRate: taxRate * 100,
        referenceNr: booking.optionalReferenceNumber,
      };
    });

    return statisticRows.sort((a, b) => Moment.utc(a.date).diff(Moment.utc(b.date)));
  }

  public render() {
    const { bookings, fromDate, toDate } = this.props;
    return (
      <PDFPreview
        bookings={bookings}
        fromDate={fromDate}
        toDate={toDate}
        generateStatisticRows={this.generateStatisticRows}
      />
    );
  }
}
