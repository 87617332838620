import Lodash from 'lodash';
import * as React from 'react';
import { BadgeProvider, IBadge } from '../../../../contexts';
import { BadgeDocument, Consume, LocaleContext, MobxComponent, OrganizationDocument } from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { Badge } from '../../../common';

interface IState {
  badges?: BadgeDocument[];
}

export class BadgeSegment extends MobxComponent<{}, IState> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  public state: IState = {};

  @Consume(BadgeProvider.Context)
  private badges: IBadge;

  public async componentDidMount() {
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const badges = await this.badges.get();

    const myBadges = Lodash.filter(badges, (badge) => organization.badges.indexOf(badge.id) !== -1);
    await this.setState({ badges: myBadges });
  }

  private get myBadges() {
    const { t } = this._locale;
    if (!this.state.badges?.length) {
      return t('components.dashboard.home.segments.badgeSegment.noBadges');
    }
    return this.state.badges.map((badge) => <Badge badge={badge} key={badge.id} />);
  }

  public render() {
    const { t } = this._locale;
    return (
      <div className="ui green segment">
        <h3 className="ui aligned header">
          {t('components.dashboard.home.segments.badgeSegment.headerBadges')}
          <div className="sub header">{t('components.dashboard.home.segments.badgeSegment.subHeaderBadges')}</div>
        </h3>
        <div
          className="ui content"
          style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'flex-start' }}
        >
          {this.state.badges ? (
            this.myBadges
          ) : (
            <div style={{ margin: '1em' }} className="ui centered loader inline active"></div>
          )}
        </div>
      </div>
    );
  }
}
