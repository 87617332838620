import { includes } from 'lodash';
import { default as Moment } from 'moment-timezone';
import { ObjectId } from 'mongodb';
import { Types } from 'mongoose';
import * as React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeDocument,
  ActivityTypeResource,
  BookingPriceCategoryDocument,
  Button,
  DeviceProvider,
  GiftCardTypeResource,
  InventoryDocument,
  InventoryResource,
  PriceCategoryDocument,
  UserDocument,
} from '../_dependencies';
import { useLocale } from '../_locales';
import { GlobalsContext } from '../copyFromAH/globals.context';
import { MarketplaceCartContext } from '../marketplace.cart.context';
import { DatePicker } from '../movedFromAH/_dateTimePickers';
import { WidgetDataProvider } from '../widgetDataProvider';
import ActivityCard from './activityCard';
import ActivityItem from './activityItem';
import { SelectionDetailsView } from './selectionDetailsView';
import { parseToEnglish, selectionListContainsTmeTicket } from './utils';

type Props = RouteComponentProps;

export type PopulatedOccurence = Omit<ActivityOccuranceDocument, 'originatingActivity'> & {
  originatingActivity: ActivityTypeDocument;
};

const ActivitySelection = ({}: Props) => {
  const [hasGiftCard, setHasGiftCard] = React.useState(false);
  const [monthString, setMonthString] = React.useState('');
  const [selectedActivityForBookingRequest, setSelectedActivityForBookingRequest] =
    React.useState<ActivityOccuranceDocument>();

  const globals = React.useContext(GlobalsContext);
  const widgetData = React.useContext(WidgetDataProvider.Context);
  const device = React.useContext(DeviceProvider.Context);
  const { t, tt } = useLocale();
  const marketplaceCart = React.useContext(MarketplaceCartContext);
  const [isBookingRequest, setIsBookingRequest] = React.useState(widgetData.bookingRequest);

  useEffect(() => {
    //If we nagivate back to selection, we want to set the selected activity to the one that was selected before
    if (widgetData.bookingRequest && !selectedActivityForBookingRequest) {
      setSelectedActivityForBookingRequest(widgetData.selectedActivity);
    }

    (async () => {
      widgetData.verifyStepNavigation('activitySelection');
      const organizationId = widgetData.selectedOrganization.id;
      const giftCards = await new GiftCardTypeResource().find({ 'limitations.organizations': organizationId });
      if (giftCards.length) {
        setHasGiftCard(true);
      }
      setMonthString(parseToEnglish(getMonthText()));
    })();
  }, []);

  useEffect(() => {
    if (widgetData.selectedOrganization.flags.features.tmeTickets) fetchAndUpdateDates();
  }, [widgetData.selectedOrganization]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAndUpdateDates();
    }, 1000);
    return () => clearInterval(interval);
  }, [monthString]);

  useEffect(() => {
    if (!selectedActivityForBookingRequest) return;
    widgetData.setSelectedActivityOccurance(selectedActivityForBookingRequest);
  }, [selectedActivityForBookingRequest]);

  useEffect(() => {
    widgetData.bookingRequest = isBookingRequest;
  }, [isBookingRequest]);

  const getMonthText = () => {
    if (!document) return '';
    const customElementNode: any = ReactDOM.findDOMNode(document.querySelector('adventurehero-widget-internal'));

    if (customElementNode && customElementNode.shadowRoot) {
      const shadowRoot = customElementNode.shadowRoot;
      return shadowRoot.querySelector('thead span.link')?.textContent || '';
    }

    return document.querySelector('thead span.link')?.textContent || '';
  };

  const fetchAndUpdateDates = () => {
    const currentMonthString = getMonthText();
    const parsedDateString = parseToEnglish(currentMonthString);

    if (!parsedDateString || parsedDateString === monthString) return;

    setMonthString(parsedDateString);
    widgetData.refetchBookableDatesByMonth(parsedDateString);
  };

  /** Renders a list of selectable activities */
  const renderListOfSelectableItems = () => {
    const events = widgetData.availableActivities as unknown as PopulatedOccurence[];
    const remappedEvents = getEventsRemappedForRendering(events);

    if (widgetData.bookingRequest) return;

    if (events.length) {
      return (
        <div>
          <p>
            <strong>{t('Selected date')}: </strong>
            {Moment(widgetData.selectedDate).tz('Europe/Stockholm').format('YYYY-MM-DD')}
          </p>
          <div style={{ maxHeight: '34rem', overflowY: 'auto', padding: '1px' }}>
            {Object.keys(remappedEvents).map((k) => (
              <ActivityCard
                key={k}
                title={tt(remappedEvents[k][0].title)}
                duration={remappedEvents[k][0].duration}
                isTmeTicket={remappedEvents[k][0].originatingActivity.isTmeTicket}
              >
                {remappedEvents[k].map((event: PopulatedOccurence) => (
                  <ActivityItem
                    key={event.id}
                    occurance={event}
                    isSelected={widgetData.selectedActivity && event.id == widgetData.selectedActivity.id}
                    isTmeTicket={event.originatingActivity.isTmeTicket}
                    onSelection={(occ) => {
                      widgetData.setSelectedActivityOccurance(occ);
                    }}
                  />
                ))}
              </ActivityCard>
            ))}
          </div>
        </div>
      );
    }

    // Is loading data
    if (widgetData.isloading || widgetData.isFetchingData) {
      return <div className="ui active inline centered loader" />;
    }

    // No dates available
    if (!widgetData.availableDates.length) {
      return <p style={{ marginTop: '2em', textAlign: 'center' }}>{t('widget-missing-activities')}</p>;
    }

    // No activites available
    let label = Moment(widgetData.selectedDate).calendar();
    if (includes(label, ' kl')) {
      label = label.substring(0, label.indexOf('kl'));
    } else if (includes(label, ':')) {
      label = label.substring(0, label.indexOf(':') - 2);
    }

    return (
      <p style={{ marginTop: '2em', textAlign: 'center' }}>
        {t('There are no available activities')} {label}
        <br />
        <br />
        {t('Try to choose another day')}
      </p>
    );
  };

  /** Remaps to new data object for rendering purposes. Key = Title and duration. Value = array, with all different start times of that event that day  */
  const getEventsRemappedForRendering = (input: PopulatedOccurence[]) => {
    const remappedEvents: { [key: string]: PopulatedOccurence[] } = {};
    input.forEach((e) => {
      const key = tt(e.title) + '_' + e.duration;
      if (remappedEvents[key] == null) {
        remappedEvents[key] = [];
      }
      remappedEvents[key].push(e);
    });
    return remappedEvents;
  };

  const renderLinkToGiftCardView = () => {
    if (widgetData.allowsSwitchingPurchaseType() && hasGiftCard) {
      return (
        <div style={{ marginTop: '2em', marginBottom: '3em' }}>
          <div className="ui divider" />
          <div className="ui message">
            <div className="ui header">
              {t('Do you want to buy a gift card?')}
              <div className="sub header" style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}>
                {t('See the available gift cards at')} {widgetData.selectedOrganization.name}
              </div>
            </div>
            <br />
            <button onClick={() => widgetData.resetWidget('giftcards')} className="ui fluid primary button">
              <i className="gift icon" /> {t('Buy a gift card')}
            </button>
          </div>
        </div>
      );
    }
  };

  const createSelectedActivityForBookingRequest = async () => {
    if (widgetData.selectedActivity && widgetData.selectedActivityType) {
      let newOccurance = new ActivityOccuranceResource().createDocument({
        ...widgetData.selectedActivity,
        bookings: [],
        _id: new Types.ObjectId(),
      });

      const activityType = await new ActivityTypeResource().find({
        _id: widgetData.selectedActivity.originatingActivity,
      });
      const doc = {
        ...newOccurance,
        bookingCapacity: widgetData.selectedActivity.bookingCapacity,
        bookedVisitors: widgetData.selectedActivity.bookedVisitors,
        availableVisitors: widgetData.selectedActivity.availableVisitors,
        priceCategories: widgetData.selectedActivity.priceCategories,
        end: Moment(newOccurance.start).add(activityType[0].duration, 'minutes').toDate(),
        originatingActivity: (activityType as unknown as ActivityTypeDocument)[0],
        isPublished: false,
        id: String(newOccurance._id),
      } as ActivityOccuranceDocument;

      setSelectedActivityForBookingRequest(doc);
    }
  };

  // Updates relevant data on the booking request occurance
  const updateBookingRequestOccurance = async (start: Date) => {
    if (!selectedActivityForBookingRequest) return;
    const occState = selectedActivityForBookingRequest;
    const end = Moment(start).add(occState?.duration, 'minutes').toDate();
    let priceCategories: PriceCategoryDocument[] = occState?.priceCategories;
    let availableVisitors = occState?.availableVisitors;
    let bookedVisitors = occState?.bookedVisitors;
    let isFullyBooked = occState?.isFullyBooked;
    const periodStart = Moment(start).subtract(occState?.duration, 'minutes').toDate();

    // We find all bookable occurances within the duration period
    const occurancesInDateRange = await new ActivityOccuranceResource().findBookableActivities(periodStart, end, {
      activityTypes: [(occState.originatingActivity as unknown as ActivityOccuranceDocument)._id],
    });

    // If the occurance uses an inventory, we need to check if there are any available inventory
    if (widgetData.selectedActivityType?.useInventory && occState) {
      // If we don't find any, we get the inventories for the activity and set the available inventories
      if (!occurancesInDateRange.length) {
        const inventoryIds = occState.priceCategories.map((cat) => cat.inventory).filter(Boolean) as ObjectId[];
        const inventories = await new InventoryResource().findOccurranceInventories({ _id: { $in: inventoryIds } });

        let newAvailableVisitors = 0;

        priceCategories = priceCategories.map((cat) => {
          if (!cat.inventory) return cat;
          const inventory = inventories.find((inv) => inv._id == cat.inventory) as InventoryDocument;
          const availableInventory = inventory.usesAdvancedSetting ? inventory.entities.length : inventory.quantity;

          newAvailableVisitors += availableInventory * cat.groupMultiplier;

          return {
            ...cat,
            availableInventory: availableInventory,
          };
        });

        availableVisitors = newAvailableVisitors;
        bookedVisitors = 0;
        isFullyBooked = false;
      } else {
        // Or we use the available inventory from the first occurance we find
        priceCategories = occurancesInDateRange[0].priceCategories;
      }
    }

    // If we don't use inventory, we take th availableVisitors and bookedVisitors from the first occurances.
    // So customer can't overbook the activity when making a request
    if (occurancesInDateRange.length) {
      availableVisitors = occurancesInDateRange[0].availableVisitors;
      bookedVisitors = occurancesInDateRange[0].bookedVisitors;
    }

    setSelectedActivityForBookingRequest((prev: ActivityOccuranceDocument) => {
      return {
        ...prev,
        availableVisitors,
        bookedVisitors,
        priceCategories,
        end,
        start,
        isFullyBooked,
      } as ActivityOccuranceDocument;
    });
  };

  const renderLinkToBookingRequestView = () => {
    if (
      widgetData.selectedActivityType?.fixedBookingRequest ||
      widgetData.selectedActivityType?.flexibleBookingRequest
    ) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ marginBottom: '1rem' }}>
            {widgetData.bookingRequest ? t('Back to scheduled times.') : t('Are there no times that suit you?')}
          </div>
          <Button
            onClick={() => {
              setIsBookingRequest(!isBookingRequest);
              createSelectedActivityForBookingRequest();
            }}
            className="ui teal button"
            style={{ marginBottom: '1rem' }}
          >
            {widgetData.bookingRequest ? t('To scheduled times') : t('Make a booking request')}
          </Button>
        </div>
      );
    }
  };

  // private async onOrderDetailsSubmitTemplate(orderSum: number, priceCategories: PriceCategoryDocument[], values?: {[key: string]: string}) {
  //   await this.widgetData.createReservation(orderSum, priceCategories);
  //   await this.widgetData.gotoStep("customerInfo");
  // }

  // private onOrderDetails = async (orderSum: number, priceCategories: PriceCategoryDocument[]) => {
  //   await this.onOrderDetailsSubmitTemplate(orderSum, priceCategories)
  // }

  const renderSelectionDetailsView = () => {
    if (!widgetData.selectedActivity) {
      return (
        <div className="ui active inverted dimmer">
          <div className="ui loader"></div>
        </div>
      );
    }
    return (
      <SelectionDetailsView
        selectedActivity={widgetData.selectedActivity ? widgetData.selectedActivity : selectedActivityForBookingRequest}
        isTmeTicket={widgetData?.selectedActivityType && widgetData.selectedActivityType.isTmeTicket}
        onOrderDetails={async (priceCategories: BookingPriceCategoryDocument[]) => {
          marketplaceCart.placeItemInCart({
            priceCategories,
            user: globals.session.currentUser as UserDocument,
          });
        }}
        alreadyReservedVisitors={
          widgetData.bookingRequest
            ? marketplaceCart.numberOfVisitorsForOccurance(widgetData.selectedActivity?.id)
            : undefined
        }
        onDeselection={() => {
          widgetData.setState({
            selectedActivity: widgetData.bookingRequest ? selectedActivityForBookingRequest : undefined,
          });
        }}
        isBookingRequest={widgetData.bookingRequest}
      />
    );
  };

  const renderCalendar = () => {
    if (widgetData.bookingRequest) {
      return (
        <div>
          <DatePicker
            name={'Day'}
            inline={true}
            minDate={new Date()}
            defaultValue={widgetData.selectedDate}
            onChange={(value: Date) => {
              widgetData.setState({ selectedDate: value, bookingRequest: true });
              updateBookingRequestOccurance(value);
            }}
          />{' '}
          <div style={{ marginTop: '1rem' }}>
            {widgetData.selectedDate && t('Selected time') + ': ' + widgetData.bookingRequest
              ? Moment(widgetData.selectedActivity?.start).tz('Europe/Stockholm').format('YYYY-MM-DD, HH:mm')
              : Moment(widgetData.selectedDate).tz('Europe/Stockholm').format('YYYY-MM-DD, HH:mm')}
          </div>
        </div>
      );
    } else {
      return (
        <div className="ui basic segment" style={{ padding: 0 }}>
          {widgetData.isFetchingData && (
            <div className="ui active inverted dimmer">
              <div className="ui text loader">{t('Loading available dates')}</div>
            </div>
          )}

          <DatePicker
            name="Dag"
            type="date"
            className="ui calendar booking date picker adjacent"
            inline={true}
            closable={false}
            minDate={new Date()}
            showToday={!widgetData.isDateDisabled(new Date())}
            onChange={(value: Date) => {
              widgetData.setState({ selectedDate: value });
            }}
            defaultValue={widgetData.selectedDate}
            isDateDisabled={widgetData.isDateDisabled}
            formatter={{
              cell: (cell, date, options) => {
                const stringDate = Moment(date).format('YYMMDD');
                const isFullyBooked = widgetData.fullyBookedDates.includes(stringDate);
                if (isFullyBooked && !options.adjacent) {
                  cell[0].classList.add('fully-booked');
                }
              },
            }}
          />
        </div>
      );
    }
  };

  const renderExplanationView = () => {
    if (widgetData.bookingRequest) return;
    return (
      <div style={{ display: 'flex', gap: '2rem', margin: '1rem 0' }}>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          <div className="box fully-booked" />
          <span>{t('Fully booked')}</span>
        </div>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          <div className="box can-be-booked" />
          <span>{t('Available (plural)')}</span>
        </div>
      </div>
    );
  };

  const renderSelectActivityView = () => {
    if (device.size == 'mobile' && widgetData.isloading) {
      return <div className="ui active inline centered loader" />;
    }
    const containsTmeTicket = selectionListContainsTmeTicket(
      widgetData.selectedActivityType,
      widgetData.availableActivities,
    );

    return (
      <div>
        <h2 className="ui primary header">
          <div className="content" style={{ width: '100%' }}>
            {containsTmeTicket ? <p>{t('Book your ticket')}</p> : <p>{t('Book your activity')}</p>}
            <div className="sub header">
              {containsTmeTicket ? (
                t('Choose the date on which your ticket will take effect:')
              ) : (
                <>{renderLinkToBookingRequestView()}</>
              )}{' '}
            </div>
          </div>
        </h2>
        {renderCalendar()}
        {renderExplanationView()}
        {renderListOfSelectableItems()}
        {renderLinkToGiftCardView()}
      </div>
    );
  };

  if (device.size == 'mobile') {
    if (widgetData.selectedActivity) {
      return renderSelectionDetailsView();
    } else {
      return renderSelectActivityView();
    }
  }

  return (
    <div className="ui grid">
      <div className="eight wide column">{renderSelectActivityView()}</div>
      <div className="eight wide column">{renderSelectionDetailsView()}</div>
    </div>
  );
};

export const ActivitySelectionView = withRouter(ActivitySelection);
