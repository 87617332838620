import * as React from 'react';
import Lodash from 'lodash';
import {
  MobxComponent,
  Consume,
  DeviceProvider,
  IDevice,
  Platforms,
  ActivityTagsFormatter,
  LocaleContext,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';

import { ActivitiesSearchProvider, IActivitySearchData } from './activitySearchProvider';

interface Props {
  availableCategories?: string[];
  alignLeft?: boolean;
}
export class CategoryIcons extends MobxComponent<Props> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(ActivitiesSearchProvider.Context)
  private searchData: Readonly<IActivitySearchData>;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get checkIcon() {
    return (
      <i
        className="check large circle icon"
        style={{
          position: 'absolute',
          marginTop: '0',
          left: '46px',
          color: Platforms.primaryColor,
          backgroundColor: 'white',
          borderRadius: '100%',
        }}
      />
    );
  }

  private renderCategoryCheckboxItems() {
    // Only show availableCategories if applied
    const tags =
      this.props.availableCategories && this.props.availableCategories.length
        ? Lodash.intersection(ActivityTagsFormatter.activityCategoryTags, this.props.availableCategories)
        : ActivityTagsFormatter.activityCategoryTags;

    return Lodash.map(tags, (tag) => {
      const formattedTag = ActivityTagsFormatter.getFormattedTag(tag, this._locale.locale);

      const checked = Lodash.includes(this.searchData.categories, tag);

      const selectedIconUrl = ActivityTagsFormatter.getSelectedIconUrl(tag);

      return (
        <div
          key={tag}
          style={{
            position: 'relative',
            padding: this.device.size === 'mobile' ? '5px' : '',
            width: '70px',
            marginRight: this.props.alignLeft ? '1em' : '0',
          }}
        >
          <img className={'ui fluid image'} style={{ textAlign: 'center' }} src={selectedIconUrl}></img>
          {checked ? this.checkIcon : <span></span>}
          <p
            style={{
              filter: 'drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px)',
              fontWeight: 400,
              marginTop: '4px',
              textAlign: 'center',
              color: Platforms.primaryColor,
              fontSize: '1em',
            }}
          >
            {formattedTag}
          </p>
        </div>
      );
    });
  }

  render() {
    return <div style={checkboxesContainerStyle(this.props.alignLeft)}>{this.renderCategoryCheckboxItems()}</div>;
  }
}

const checkboxesContainerStyle = (alignLeft): React.CSSProperties => {
  return {
    maxWidth: '80rem',
    width: '100%',
    padding: '1rem',
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: alignLeft ? 'flex-start' : 'space-evenly',
  };
};
