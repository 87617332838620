import { Schema } from 'mongoose';

export interface RescheduleInsuranceDocument {
  /** Measured in days and is used when creating the reschedule code */ validInDays: number;
  /** Measured in hours before the activity start */ lastRescheduleTime: number;
  /** Shows if reschedule insurance fee is calculated per person or per booking */ isFeePerPerson?: boolean;
  /** Shows how many people the insurance fee should be calculated for */ personsPaidFor?: number;
}

export const RescheduleInsuranceSchema = new Schema(
  {
    validInDays: { type: Number, required: true },
    lastRescheduleTime: { type: Number, required: true },
    isFeePerPerson: { type: Boolean, required: false },
    personsPaidFor: { type: Number, required: false },
  },
  { _id: false },
);
