// vim:foldmethod=marker:foldmarker=#region,#endregion

import React, { useEffect, useState } from 'react';
import {
  ActivityOccuranceDocument,
  BookingDocument,
  BookingResource,
  Form,
  Platforms,
  ProductPaymentInfo,
  TextArea,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { DiscountEvent, eventFetcher } from './handleBookingHistoryDataHelpers';

const eventTypes = ['booking_confirmed', 'booking_rejected'];

type Props = {
  paymentInfo: ProductPaymentInfo;
  booking: BookingDocument;
  hidePaymentStatus?: boolean;
  discountInfo: DiscountEvent | undefined;
  isSendingMail: boolean;
};

export const StatusInfo = ({ booking, hidePaymentStatus, paymentInfo, discountInfo, isSendingMail }: Props) => {
  const { t } = useLocale();
  const [savingNotes, setSavingNotes] = React.useState(false);
  const [notesText, setNotesText] = React.useState(booking.notes);
  const iconRef = React.useRef<HTMLElement>(null);
  const [bookingRequestStatus, setBookingRequestStatus] = useState<{ confirmed: boolean; rejected: boolean }>({
    confirmed: false,
    rejected: false,
  });
  const fullyBooked =
    (booking.occurance as unknown as ActivityOccuranceDocument).availableVisitors < booking.bookedVisitors;

  const discountAmount = (discountInfo?.value || 0) / 100;
  const bookingValue = paymentInfo?.productValue ?? booking.totalPrice;

  const sum = (acc: number, v: number) => acc + v;

  const paidAmount = paymentInfo
    ? [paymentInfo.amountPaidManually, paymentInfo.amountPaidWithGiftCard, paymentInfo.amountPaidWithStripe].reduce(
        sum,
        0,
      )
    : undefined;

  useEffect(() => {
    (async () => {
      const events = await eventFetcher(booking, eventTypes);
      setBookingRequestStatus(events);
    })();
  }, [booking, isSendingMail]);

  React.useEffect(() => {
    if (!paymentInfo) return;
    if (paymentInfo === null || !paymentInfo.legacy || iconRef.current === null) {
      return;
    }
    $(iconRef.current).popup();
  }, [paymentInfo, iconRef]);

  // #region Notes update effect
  React.useEffect(() => {
    let canceled = false;
    const id = setTimeout(() => {
      if (canceled || booking.notes === notesText) {
        return;
      }
      setSavingNotes(true);
      new BookingResource().updateNotes(booking.id, notesText).then(() => {
        if (canceled) {
          return;
        }
        setSavingNotes(false);
      });
    }, 1000);
    return () => {
      canceled = true;
      clearTimeout(id);
    };
  }, [notesText]);
  // #endregion

  const color = (() => {
    if (paidAmount == undefined) return undefined;
    if (paidAmount == 0 && bookingValue) return 'red';
    if (paidAmount >= bookingValue) return 'green';
    return 'yellow';
  })();

  const message = (() => {
    if (paidAmount == undefined) return undefined;
    if (paidAmount == 0 && bookingValue) return t('Not paid');
    if (paidAmount >= bookingValue) return t('Fully paid');
    return t('Partly paid');
  })();

  const requestText = () => {
    if (bookingRequestStatus.confirmed || Number(paidAmount) >= bookingValue) {
      return t('Booking request confirmed');
    } else if (fullyBooked) {
      return t('Fully booked');
    } else if (bookingRequestStatus.rejected) {
      return t('Booking request rejected');
    }
    return t('Booking request');
  };

  const requestStyle = () => {
    let color = 'olive';

    if (bookingRequestStatus.confirmed || Number(paidAmount) >= bookingValue) {
      color = 'green';
    } else if (fullyBooked || bookingRequestStatus.rejected) {
      color = 'red';
    }

    return `ui ${color} tag label`;
  };

  const paymentStatus =
    paidAmount == undefined ? (
      <div className={'ui active tiny inline loader'} />
    ) : (
      <span className={`ui large ${color} basic label`} style={{ borderWidth: 0, padding: 0 }}>
        {message}
      </span>
    );

  //#region paymentDetails function
  const paymentDetails = (() => {
    if (!paymentInfo) {
      return undefined;
    }

    const onlinePayment = paymentInfo.amountPaidWithStripe ? (
      <div className="item" style={{ marginLeft: '25px' }}>
        {Math.floor(paymentInfo.amountPaidWithStripe)} {Platforms.currencyISO}{' '}
        {t('components.dashboard.bookings.bookingItem.statusInfo.payOnline')}
      </div>
    ) : undefined;

    const giftCardPayment = paymentInfo.amountPaidWithGiftCard ? (
      <div className="item" style={{ marginLeft: '25px' }}>
        {Math.floor(paymentInfo.amountPaidWithGiftCard)} {Platforms.currencyISO} {t('from voucher')}
      </div>
    ) : undefined;

    const manualPayment = paymentInfo.amountPaidManually ? (
      <div className="item" style={{ marginLeft: '25px' }}>
        {Math.floor(paymentInfo.amountPaidManually)} {Platforms.currencyISO}{' '}
        {t('components.dashboard.bookings.bookingItem.statusInfo.payManual')}
      </div>
    ) : undefined;

    const discountedAmount = discountInfo ? (
      <div className="item" style={{ marginLeft: '25px' }}>
        {Math.floor(discountAmount)} {Platforms.currencyISO} {t('From discount code')} (
        {discountInfo.metadata.applied_discount_code})
      </div>
    ) : undefined;

    const paidAmount =
      paymentInfo.amountPaidWithStripe + paymentInfo.amountPaidWithGiftCard + paymentInfo.amountPaidManually;

    const paymentLeft =
      paidAmount > 0 && paidAmount < bookingValue ? (
        <div className="item" style={{ marginLeft: '25px' }}>
          {Math.floor(bookingValue - paidAmount)} {Platforms.currencyISO}{' '}
          {t('components.dashboard.bookings.bookingItem.statusInfo.payLeft')}
        </div>
      ) : undefined;

    if (!onlinePayment && !giftCardPayment && !manualPayment && !paymentLeft && !discountedAmount) {
      return undefined;
    }

    return (
      <div className="content">
        <div className="ui list" style={{ paddingTop: '5px' }}>
          {discountedAmount}
          {onlinePayment}
          {giftCardPayment}
          {manualPayment}
          {paymentLeft}
        </div>
      </div>
    );
  })();
  //#endregion

  return (
    <div className="ui list">
      <div className="item">
        {'requested' in booking && !booking.crossSellingProperty && (
          <a style={{ marginLeft: '-1rem', marginBottom: '0.5rem' }} className={requestStyle()}>
            <div className="content">{requestText()}</div>
          </a>
        )}
        {paymentInfo?.legacy ? (
          <i
            ref={iconRef}
            className="orange middle aligned question circle outline icon"
            data-content={t('This booking is old')}
          />
        ) : (
          <i className="middle aligned bookmark icon" />
        )}
        <div className="content">
          <b>
            {`${t('Booking number')}:`} {booking.number}
          </b>
        </div>
      </div>
      {!hidePaymentStatus ? (
        <div className="item">
          <i className="middle aligned credit card icon" />
          <div className="content">
            {t('Payment status')}: {paymentStatus}
          </div>
          {paymentDetails}
        </div>
      ) : null}
      {booking.crossSellingProperty && (
        <div className="item">
          <i className="middle aligned file alternate outline icon" />
          <div className="content">
            {t('Invoice') + ': '}
            {booking.isInvoiced ? (
              <span className="ui large green basic label" style={{ borderWidth: 0, padding: 0 }}>
                {t('Sent')}
              </span>
            ) : (
              <span className="ui large red basic label" style={{ borderWidth: 0, padding: 0 }}>
                {t('Not sent')}
              </span>
            )}
          </div>
        </div>
      )}
      {(booking as any).deleted ? (
        <div className="item">
          <i className="middle aligned trash alternate icon" />
          <div className="content">
            <b style={{ color: 'red' }}>{t('This booking is deleted')}</b>
          </div>
        </div>
      ) : null}
      <div className="item" style={{ marginTop: '1em' }}>
        <i className="info circle icon" />
        <div className="content">
          <b>{t('components.dashboard.bookings.bookingItem.statusInfo.personalNote')}</b>
        </div>
        <Form>
          <div className={'ui basic ' + (savingNotes ? 'loading' : '') + ' segment'} style={{ padding: 0 }}>
            <TextArea
              name="notes"
              rows={2}
              value={notesText || booking.notes}
              onChange={(v) => setNotesText(v)}
              style={{ marginTop: '3px' }}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
