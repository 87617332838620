import React, { Component } from 'react';
import {
  AccessoryDocument,
  Consume,
  DeviceProvider,
  Field,
  IDevice,
  Input,
  LocaleContext,
  PriceCategoryDocument,
  Rules,
  TranslatableText,
  TranslatableTextField,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';
import { TaxRateFields } from '../fields/taxRateFields';

interface Props {
  priceCategory: AccessoryDocument<PriceCategoryDocument>;
  trueIndex: number;
  nrOfRows: number;
  rowNumber: number;
  changePriceCategory: (index: number, priceCategory: PriceCategoryDocument) => void;
  removePriceCategory: (index: number) => void;
}

export class AccessoryRow extends Component<Props, {}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(DeviceProvider.Context)
  private _device: IDevice;

  private get nameRules() {
    const { t } = this._locale;
    const startRow = this.props.rowNumber - 1;

    const rules = [Rules.NotEmpty(t('Name the category'))];
    for (let i = startRow; i >= 0; i--) {
      rules.push(Rules.NotTheSameAs('accessoryName' + i + '-sv'));
    }
    return rules;
  }

  private get actionIcons() {
    const { t } = this._locale;
    const { changePriceCategory, priceCategory, trueIndex } = this.props;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '2.4rem' }}>
        <i
          className="ui red trash icon"
          style={{ cursor: 'pointer', marginBottom: 4 }}
          onClick={() => this.props.removePriceCategory(trueIndex)}
        />
        <div
          data-tooltip={priceCategory.isHidden ? t('Only visible to you') : t('Visible to your customers')}
          data-position="left center"
        >
          <i
            className={`ui ${priceCategory.isHidden ? 'grey' : 'green'} eye icon`}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const updatedPriceCategory = { ...priceCategory, isHidden: !priceCategory.isHidden };
              changePriceCategory(trueIndex, updatedPriceCategory);
            }}
          />
        </div>
      </div>
    );
  }

  private renderLabel(label: string) {
    const { rowNumber } = this.props;
    const { size } = this._device;

    if (rowNumber !== 0 && size !== 'mobile') return null;

    return (
      <div className="row field" style={{ marginBottom: 0, padding: '0.5rem 0' }}>
        <Field style={{ visibility: label ? 'visible' : 'hidden' }}>{label || 'x'}</Field>
      </div>
    );
  }
  private manageInputChanges = (
    key: keyof AccessoryDocument<PriceCategoryDocument>,
    value: string | TranslatableText,
    valueType: 'number' | 'string' | 'TranslatableText',
  ) => {
    if (typeof value === 'string' && valueType === 'number' && typeof parseFloat(value) !== 'number') return;
    let v;
    if (typeof value === 'string' && valueType === 'number') {
      v = value ? parseFloat(value) : undefined;
    } else {
      v = value;
    }
    const { changePriceCategory, priceCategory, trueIndex } = this.props;
    const update = { ...priceCategory, [key]: v };
    changePriceCategory(trueIndex, update);
  };

  render() {
    const { t } = this._locale;
    const { priceCategory } = this.props;
    const { name, price } = priceCategory;
    const categoryPrice = String(price ?? '');

    return (
      <div className="row field" style={{ padding: 0, margin: 0, display: 'flex', columnGap: '.5rem' }}>
        <Field style={{ flex: 1 }}>
          {this.renderLabel(t('Name'))}
          <TranslatableTextField
            name={'accessoryName' + this.props.rowNumber}
            value={name}
            onChange={(value) => this.manageInputChanges('name', value, 'TranslatableText')}
            placeholder={t('Name')}
            rules={this.nameRules}
          />
        </Field>
        <div style={{ width: 'auto', flex: 1, display: 'flex', gap: '.5rem' }}>
          <Field style={{ flex: 1 }}>
            {this.renderLabel(t('Price'))}
            <Input
              onChange={(value) => this.manageInputChanges('price', value, 'number')}
              value={categoryPrice}
              rules={[Rules.NotEmpty(t('Set a price')), Rules.IsANumber(false, t('Set the price in SEK'))]}
              name={'accessoryPrice' + this.props.rowNumber}
              placeholder={t('Price')}
              rightLabel={t('kr-SEK')}
              inputStyle={{ width: '6rem', flex: 1 }}
            />
          </Field>
          <div style={{ flex: 0 }}>
            {this.renderLabel(t('VAT-Rate') + ' (%)')}
            <TaxRateFields
              hideLabel
              padding=".85rem 1rem"
              taxRate={priceCategory.taxRate}
              setTaxRate={(_, value) => this.manageInputChanges('taxRate', String(value), 'number')}
            />
          </div>
          <div style={{ flex: 0 }}>
            {this.renderLabel('')}
            {this.actionIcons}
          </div>
        </div>
      </div>
    );
  }
}
