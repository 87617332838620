import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
  ActivityImageResource,
  ActivityTypeDocument,
  ActivityTypeResource,
  Alert,
  AutomaticMailDocument,
  GlobalsContext,
  GlobalsProvider,
  LocaleContext,
  PlaceDocument,
  PlaceResource,
  PriceCategoryDocument,
  TranslatableText,
} from '../_dependencies';

export type UpdateSelectedOfferFunction = <T extends keyof ActivityTypeDocument, V extends ActivityTypeDocument[T]>(
  key: T,
  value: V,
) => void;

export interface OffersContext {
  loadedOffers: Map<string, ActivityTypeDocument>;
  selectedOffer: ActivityTypeDocument | undefined;
  places: PlaceDocument[];

  unloadOffer: (id: string, callback: () => void) => void;
  deselectOffer: () => void;
  loadOffer: (id: string) => Promise<ActivityTypeDocument | undefined>;
  resetSelectedOffer: () => void;
  updateSelectedOffer: UpdateSelectedOfferFunction;
  createNewOffer: () => void;
  retrieveOfferById: (id: string) => Promise<ActivityTypeDocument | undefined>;
  saveSelectedOffer: (imageId?: string) => Promise<ActivityTypeDocument>;
  saveNewBookingConfirmationMessage: (value: TranslatableText | string) => Promise<void>;
  saveNewThankYouMailMessage: (value: AutomaticMailDocument) => Promise<void>;
  saveNewWelcomeMailMessage: (value: AutomaticMailDocument) => Promise<void>;
  formatPriceCategoryList: (
    priceCategories: { id: string; priceCategory: PriceCategoryDocument }[],
  ) => PriceCategoryDocument[];
}

interface Props {
  children: ReactNode;
}

export const OffersContext = React.createContext<OffersContext>({} as any);

const OffersProvider = (props: Props) => {
  const global = useContext(GlobalsProvider.Context);
  const [loadedOffers, setLoadedOffers] = useState<Map<string, ActivityTypeDocument>>(new Map());
  const [selectedOffer, setSelectedOffer] = useState<ActivityTypeDocument>();
  const [places, setPlaces] = useState<PlaceDocument[]>([]);
  const activityResource = new ActivityTypeResource();
  const unalteredSelectedOffer = selectedOffer ? loadedOffers.get(selectedOffer.id) : undefined;
  const { t } = useContext(LocaleContext);
  const globals = useContext(GlobalsContext);

  useEffect(() => {
    (async () => {
      setPlaces(await new PlaceResource().find({ organization: globals.session.currentOrganizationId }));
    })();
  }, []);

  const unloadOffer = (id: string, callback: () => void) => {
    loadedOffers.delete(id);
    setLoadedOffers(loadedOffers);
    if (selectedOffer && selectedOffer.id === id) {
      deselectOffer();
    }
    return callback();
  };

  const deselectOffer = () => setSelectedOffer(undefined);

  const loadOffer = (id: string) => {
    if (loadedOffers.has(id)) {
      const offer = loadedOffers.get(id);
      selectOffer(offer);
      return Promise.resolve(offer);
    }
    const promise = activityResource.get(id);
    promise
      .then((result) => {
        loadedOffers.set(id, result);
        setLoadedOffers(loadedOffers);
        selectOffer(result);
      })
      .catch((err) => console.error(err));

    return promise;
  };

  const selectOffer = (offer: ActivityTypeDocument | undefined) => {
    if (!offer) return;
    if (!offer.isNew) {
      loadedOffers.set(offer.id, offer);
    }
    setSelectedOffer(offer);
  };

  const createNewOffer = () => {
    selectOffer(activityResource.createDocument({}));
  };

  function updateSelectedOffer<K extends keyof ActivityTypeDocument, V extends ActivityTypeDocument[K]>(
    key: K,
    value: V,
  ) {
    setSelectedOffer(
      (selectedOffer) =>
        ({
          ...selectedOffer,
          [key]: value,
        } as ActivityTypeDocument),
    );
  }

  const retrieveOfferById = (id: string) => {
    if (selectedOffer && selectedOffer.id === id) {
      return Promise.resolve(undefined);
    }
    return loadOffer(id);
  };

  const formatPriceCategoryList = (priceCategories: { id: string; priceCategory: PriceCategoryDocument }[]) => {
    return priceCategories.map((cat) => cat.priceCategory);
  };

  const resetSelectedOffer = () => {
    if (!selectedOffer) return;
    if (!unalteredSelectedOffer) throw new Error('[OFFERS CONTEXT] Could not reset original selected offer');
    setSelectedOffer(unalteredSelectedOffer);
  };

  const saveSelectedOffer = async (imageId?: string, useInventory?: boolean) => {
    if (!selectedOffer) throw new Error('[OFFERS CONTEXT] Save selected offer failed - no offer selected');
    const imageResource = new ActivityImageResource();
    const orgId = global.session.currentOrganizationId;
    const offer = selectedOffer;
    if (!offer.organization) {
      offer.organization = orgId;
    }

    // If imageId exists and is new, we need to save the image
    if (imageId && imageId !== offer.imageId) {
      offer.imageId = imageId || '';
      offer.imageUrl = offer.imageId
        ? imageResource.fileUrl(offer.imageId, 'jpg')
        : '/static/platform/img/default_activity.jpg';
    }

    // If offer uses inventory and visitorCapacity is undefined we need to give "visitorCapacity" a value as it is required in the schema.
    // Use the value from the selected inventory for now (what we did before refactored these parts).
    // NOTE: visitorCapacity should probably not be required in the future
    if (offer.inventories?.length) {
      offer.visitorCapacity = offer.inventories[0].quantity || 0;
    }

    //Checks route IDs, if they do not match any of the place ID's, error is thrown.
    if (offer.route && places) {
      if (offer.route.length) {
        for (const placeId of offer.route) {
          const selectedPlaceExists = places.find((place) => String(place._id) == String(placeId));
          if (!selectedPlaceExists) {
            Alert.show(t('All route points must be assigned'), t('oops'), 'error');
            throw new Error('[OFFERS CONTEXT] Not all routes have an assigned place');
          }
        }
      }
    }

    // Check if user selected to use inventory on priceCategories but try to save priceCategories without inventory
    if (offer.useInventory) {
      const noAccessories = offer.priceCategories.filter((pc) => pc.groupMultiplier != 0);

      if (!noAccessories.every((pc) => pc.inventory)) {
        Alert.show(t('All pricecategories must have an assigned inventory'), t('oops'), 'error');
        throw new Error('[OFFERS CONTEXT] Not all pricecategories have assigned inventory');
      }
    }

    try {
      let documentId: string;
      if (offer.isNew) {
        documentId = await activityResource.updateDocument(offer);
      } else {
        await activityResource.updateDocumentAndOccurances(offer);
        documentId = offer.id;
      }
      offer.isNew = false;
      overwriteOfferInLoadedOffers(documentId, offer);
      return offer;
    } catch (err) {
      throw err as Error;
    }
  };

  /**
   * This function updates and saves a single property directly on the document
   * and the new document replaces the old one stored in the loadedOffers map aswell.
   * I does not alter the selectedOffer because this function is always called after at timer
   * and selectedOffer needs to be altered immediately on change. (Separate issue)
   */
  const saveNewBookingConfirmationMessage = async (value: TranslatableText | string) => {
    if (!selectedOffer || !unalteredSelectedOffer) return;
    const update = await activityResource.updateBookingConfirmationMessage(selectedOffer.id, value);
    const newOffer = { ...unalteredSelectedOffer } as ActivityTypeDocument;
    newOffer.bookingConfirmationMessage = value;
    if (update) overwriteOfferInLoadedOffers(newOffer.id, newOffer);
  };

  /**
   * This function updates and saves a single property directly on the document
   * and the new document replaces the old one stored in the loadedOffers map aswell.
   * I does not alter the selectedOffer because this function is always called after at timer
   * and selectedOffer needs to be altered immediately on change. (Separate issue)
   */
  const saveNewThankYouMailMessage = async (value: AutomaticMailDocument) => {
    if (!selectedOffer || !unalteredSelectedOffer) return;
    const update = await activityResource.updateThankYouMailOptions(selectedOffer.id, value);
    const newOffer = { ...unalteredSelectedOffer } as ActivityTypeDocument;
    newOffer.thankYouMail = value;
    if (update) overwriteOfferInLoadedOffers(newOffer.id, newOffer);
  };

  /**
   * This function updates and saves a single property directly on the document
   * and the new document replaces the old one stored in the loadedOffers map aswell.
   * I does not alter the selectedOffer because this function is always called after at timer
   * and selectedOffer needs to be altered immediately on change. (Separate issue)
   */
  const saveNewWelcomeMailMessage = async (value: AutomaticMailDocument) => {
    if (!selectedOffer || !unalteredSelectedOffer) return;
    const update = await activityResource.updateWelcomeMailOptions(selectedOffer.id, value);
    const newOffer = { ...unalteredSelectedOffer } as ActivityTypeDocument;
    newOffer.welcomeMail = value;
    if (update) overwriteOfferInLoadedOffers(newOffer.id, newOffer);
  };

  const overwriteOfferInLoadedOffers = (id: string, offer: ActivityTypeDocument) => {
    loadedOffers.set(id, offer);
  };

  /**
   * This effect looks at the URL and if the URL targets a new offer that has been loaded should select that offer.
   * This ensures that the selected offer matches the URL.
   * In order for this effect to select the offer, it needs to have been loaded already by retrieveOfferById (should always be the case).
   */
  useEffect(() => {
    const { pathname } = global.session.currentLocation;
    if (!pathname.includes('/dashboard/offers/details/')) return;
    const id = pathname.replace('/dashboard/offers/details/', '');
    const offer = loadedOffers.get(id);
    if (!offer) return;
    setSelectedOffer(offer);
  }, [global.session.currentLocation.pathname]);

  return (
    <OffersContext.Provider
      value={{
        places,
        loadedOffers,
        selectedOffer,
        unloadOffer,
        deselectOffer,
        loadOffer,
        resetSelectedOffer,
        updateSelectedOffer,
        createNewOffer,
        retrieveOfferById,
        saveSelectedOffer,
        saveNewBookingConfirmationMessage,
        saveNewThankYouMailMessage,
        saveNewWelcomeMailMessage,
        formatPriceCategoryList,
      }}
    >
      {props.children}
    </OffersContext.Provider>
  );
};

export default OffersProvider;
