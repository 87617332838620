import { HookNextFunction, Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import { AddressDocument, AddressSchema } from '../schemas/address';
import { AutomaticMailDocument, AutomaticMailSchema } from '../schemas/automaticMail';
import { LocationDocument, LocationSchema } from '../schemas/location';
import SchemaNames from '../schemas/names';
import { PriceCategoryDocument, PriceCategorySchema } from '../schemas/priceCategory';
import { TranslatableText } from '../schemas/translatableText';
import { ActivityImageResource } from './activityImage.resource';
import { InventoryDocument } from './inventory.resource';
import { Platforms, TaxRate } from './platforms';
import { PropertyDocument } from './property.resource';

export type ActivityTypeSearchModes = 'year' | 'month' | 'date' | 'time';
export type PropertyActivityTypeTuple = { property: PropertyDocument; activityType: ActivityTypeDocument };

/** Parameters for performing an activity type search */
export interface ActivityTypeSearchSettings {
  searchStartTime: Date;
  categories: any;
  freeText: string;
  pickerMode: ActivityTypeSearchModes;
  startAfterDate: Date;
  startBeforeDate: Date;
}

export interface ActivityTypeDocument extends MongooseDocument {
  organization: Types.ObjectId;
  title: TranslatableText | string;
  description: TranslatableText | string;
  /** Activity duration in minutes */
  duration: number;
  /** Activity preparation in minutes */
  preparationDuration?: number;
  /** Activity cleanup in minutes */
  cleanupDuration?: number;
  /** The time in in minutes before an occurance of this type should no longer be bookable */
  bookingClosesBeforeEventInHours?: number;
  refundableUntilInHours?: number;
  priceCategories: PriceCategoryDocument[];
  taxRate: TaxRate;
  visitorCapacity: number;
  minVisitors?: number;
  neededStaffing?: number;
  fixedBookingRequest?: boolean;
  flexibleBookingRequest?: boolean;
  flexibleBookingRequestMessage?: TranslatableText | string;
  fixedBookingRequestMinimumCharge?: number;
  /** A customized message injected into the bosoking confirmation */
  bookingConfirmationMessage?: TranslatableText | string;
  /** A customizable mail for pre-activity communication */
  welcomeMail?: AutomaticMailDocument;
  /** A customizable mail for post-activity communication */
  thankYouMail?: AutomaticMailDocument;
  bookingRules?: TranslatableText | string;
  termsOfUse?: TranslatableText;
  messageToEmployees?: string;
  address?: AddressDocument;
  location?: LocationDocument;
  categoryTags?: string[];
  isCostumerBookingDisabled?: boolean;
  bookingCapacity?: number;
  imageId?: string;
  inventories?: InventoryDocument[];
  route?: Types.ObjectId[];
  /** New way, using inventories on priceCategories */ useInventory?: boolean;
  /** Archived documents can still be populated */ isArchived?: boolean;
  /** VIRTUAL FIELD */ imageUrl: string;
  /** VIRTUAL FIELD Returns basePrice */ basePrice: number;
  /** For the tme project, to be able to associate a ticket to other activityTypes */ associatedActivities?: Types.ObjectId[];
  /** Boolean to identify Tme ticket */ isTmeTicket?: boolean;
}

export class ActivityTypeResource extends MongooseResource<ActivityTypeDocument> {
  /**
   * Typeguard for veryfining is the passed object is an Document or an ObjectId
   * needs to look for a field that exists only in the document
   */
  static isDocument(data: any): data is ActivityTypeDocument {
    return data.title != undefined;
  }

  constructor() {
    super();

    this.setName(SchemaNames.ActivityType);

    this.setSchema({
      organization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: true },
      title: { type: Schema.Types.Mixed },
      description: { type: Schema.Types.Mixed },
      priceCategories: [PriceCategorySchema],
      taxRate: { type: Number, required: true, default: Platforms.taxRates[0] },
      visitorCapacity: { type: Number, required: true },
      address: { type: AddressSchema, required: false },
      associatedActivities: { type: [Schema.Types.ObjectId], required: false },
      bookingCapacity: { type: Number, required: false },
      bookingClosesBeforeEventInHours: { type: Number, required: false },
      bookingConfirmationMessage: { type: Schema.Types.Mixed },
      bookingRules: { type: Schema.Types.Mixed },
      termsOfUse: { type: Schema.Types.Mixed },
      categoryTags: [String],
      cleanupDuration: Number /** Activity cleanup in minutes */,
      duration: Number /** Activity duration in minutes */,
      imageId: { type: String, required: false },
      isArchived: { type: Boolean, required: false },
      isCostumerBookingDisabled: { type: Boolean, required: false },
      isEmployeeSchedulingEnabled: { type: Boolean, required: false },
      inventories: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Inventory, required: false }],
      route: { type: [Schema.Types.ObjectId], ref: SchemaNames.Place, required: false },
      useInventory: { type: Boolean, required: false },
      isTmeTicket: { type: Boolean, required: false },
      location: { type: LocationSchema, required: false },
      messageToEmployees: String,
      minVisitors: { type: Number, required: false },
      neededStaffing: { type: Number, required: false },
      fixedBookingRequest: { type: Boolean, required: false },
      flexibleBookingRequest: { type: Boolean, required: false },
      flexibleBookingRequestMessage: { type: Schema.Types.Mixed, required: false },
      fixedBookingRequestMinimumCharge: { type: Number, required: false },
      preparationDuration: Number /** Activity preparation in minutes */,
      refundableUntilInHours: { type: Number, required: false },
      thankYouMail: AutomaticMailSchema,
      welcomeMail: AutomaticMailSchema,
    });

    this.addVirtualField('imageUrl', (document) =>
      document.imageId
        ? new ActivityImageResource().fileUrl(document.imageId, 'png')
        : '/static/platform/img/default_activity.jpg',
    );
    this.addVirtualField('basePrice', (document) => {
      const basePriceCategory = document.priceCategories?.find((categories) => categories?.basePrice);
      return basePriceCategory?.price || 0;
    });

    const autoPopulateInventories = function (this: ActivityTypeDocument, next: HookNextFunction) {
      this.populate('inventories');
      next();
    };

    this.schema.pre('findOne', autoPopulateInventories).pre('find', autoPopulateInventories);
  }

  public get standardBookingRules() {
    return '';
  }

  public get standardTermsOfUse() {
    return '';
  }

  updateBookingConfirmationMessage(id: string, message: TranslatableText | string) {
    return this.sendRequest<boolean>('/updateBookingConfirmationMessage', 'post', { id, message });
  }

  updateWelcomeMailOptions(id: string, updatedMail: AutomaticMailDocument) {
    return this.sendRequest<boolean>('/updateWelcomeMailOptions', 'post', { id, updatedMail });
  }

  updateThankYouMailOptions(id: string, updatedMail: AutomaticMailDocument) {
    return this.sendRequest<boolean>('/updateThankYouMailOptions', 'post', { id, updatedMail });
  }

  updateBookingRules(id: string, rules: TranslatableText | string) {
    return this.sendRequest<boolean>('/updateBookingRules', 'post', { id, rules });
  }

  updateTermsOfUse(id: string, terms: TranslatableText | string) {
    return this.sendRequest<boolean>('/updateTermsOfUse', 'post', { id, terms });
  }

  updateMessageToEmployees(id: string, message: string) {
    return this.sendRequest<boolean>('/updateMessageToEmployees', 'post', { id, message });
  }

  updateDocumentAndOccurances(document: ActivityTypeDocument) {
    return this.sendRequest<string>('/updateDocumentAndOccurances', 'post', { document });
  }

  findPropertiesWithAvailableOccurances(
    searchStartTime: Date,
    categories: string[],
    freeText: string,
    pickerMode: String,
  ) {
    return this.sendRequest<PropertyActivityTypeTuple[]>('/findPropertiesWithAvailableOccurances', 'post', {
      searchStartTime,
      categories,
      freeText,
      pickerMode,
    });
  }
}
