import { Schema } from 'mongoose';

/** NOTE: This is meant to be used in general where clients are going to be able to add translations to their own content, eg. Activity titles etc. */
/** It has not yet been implemented to its full potential and is only used partially in automatic mails. */
/** Partial in the sense that only the "sv" property is used for now */

export interface TranslatableText {
  /** Swedish translation of the text */ sv: string;
  /** English translation of the text */ en?: string;
  /** German translation of the text */ de?: string;
}

const TranslatableText = new Schema(
  {
    sv: { type: String, required: false, default: '' },
    en: { type: String, required: false, default: '' },
    de: { type: String, required: false, default: '' },
  },
  { _id: false },
);

export const TranslatableTextSchema = TranslatableText;
