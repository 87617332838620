// vim:foldmethod=marker:foldmarker=#region,#endregion

import { cloneDeep } from 'lodash';
import Moment from 'moment-timezone';
import * as React from 'react';
import { useEffect } from 'react';
import {
  ActivityOccuranceDocument,
  Alert,
  BookingDocument,
  BookingPriceCategoryDocument,
  BookingResource,
  ConfirmationButton,
  CustomerDocument,
  CustomerForm,
  DeviceProvider,
  GlobalsContext,
  InventoryEntityResource,
  InventoryTransferResource,
  MailResource,
  MarketplaceResource,
  Modal,
  PaymentResource,
  Platforms,
  createBookingChangedVisitorsNotification,
  createBookingRemovedNotification,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { DeleteButton } from '../../../../components/deleteButton';
import {
  createCustomerUpdatedEvent,
  createPriceCategoriesUpdatedEvent,
  createdBookingConfirmedEvent,
  createdBookingRejectedEvent,
  createdMailEvent,
} from '../events';
import { BookingContext } from '../handleBookings';
import { BookingHistory } from './bookingHistory';
import { BookingInfo } from './bookingInfo';
import { CrossSelling } from './crossSelling';
import { CustomerInfo } from './customerInfo';
import { DeleteBookingForm } from './deleteBookingForm';
import { EditBookingForm } from './editBookingForm';
import {
  DiscountEvent,
  FormattedEventData,
  getEventParam,
  handleBookingHistoryData,
} from './handleBookingHistoryDataHelpers';
import { StatusInfo } from './statusInfo';

type Props = {
  paymentInfo: any | null;
  booking: BookingDocument;
  onRemoved: (doc: BookingDocument) => void;
  onMarkInvoiced: (invoiced: boolean, bookings: BookingDocument[]) => void;
  edited: (boolean: boolean) => void;
};

export const BookingItem = ({ booking, onRemoved, onMarkInvoiced, paymentInfo, edited }: Props) => {
  const device = React.useContext(DeviceProvider.Context);
  const bookings = React.useContext(BookingContext);
  const globals = React.useContext(GlobalsContext);
  const resource = new BookingResource();
  const { t, locale } = useLocale();

  const [isSendingMail, setIsSendingMail] = React.useState(false);
  const [isSendingPaymentLink, setIsSendingPaymentLink] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isEditingVisitors, setIsEditingVisitors] = React.useState(false);
  const [isEditingCustomer, setIsEditingCustomer] = React.useState(false);
  const [isAddingPayment, setIsAddingPayment] = React.useState(false);
  const [isShowingHistory, setIsShowingHistory] = React.useState(false);
  const [showActionButtons, setShowActionButtons] = React.useState(false);
  const [showMailButtons, setShowMailButtons] = React.useState(false);
  const [history, setHistory] = React.useState<FormattedEventData>();
  const [discountInfo, setDiscountInfo] = React.useState<DiscountEvent>();
  const modal = React.useRef<Modal | null>(null);

  useEffect(() => {
    if (!booking) return;
    const fetchHistory = async () => {
      const response = await handleBookingHistoryData(booking);
      setDiscountInfo(getEventParam(response));
      setHistory(response);
    };
    fetchHistory();
  }, [booking, isSendingMail]);

  const discountAmount = (discountInfo?.value || 0) / 100;

  const sum = (acc: number, v: number) => acc + v;

  const paidAmount = paymentInfo
    ? [paymentInfo.amountPaidManually, paymentInfo.amountPaidWithGiftCard, paymentInfo.amountPaidWithStripe].reduce(
        sum,
        0,
      )
    : undefined;

  // #region "computed"

  const occuranceDocument = booking.occurance as any as ActivityOccuranceDocument;
  const isBookingPaid = (paidAmount || 0) >= (booking.totalPrice || 0) + (discountAmount || 0);
  /** If booking is deleted or has a starttime later than now, the booking is old */
  const isOldBooking =
    !(booking as any).deleted && occuranceDocument ? Moment().isAfter(occuranceDocument.start) : true;
  const isBusy = isEditingCustomer || isEditingVisitors || isDeleting || isAddingPayment || isShowingHistory;
  const isOrganizationBooking = globals.session.currentOrganizationId === booking.organization;

  // #endregion

  // #region Functions

  const onSubmitCustomerForm = async (
    customer: CustomerDocument,
    message: string,
    resolve: Function,
    reject: Function,
  ) => {
    try {
      const clonedBooking = cloneDeep(booking);

      clonedBooking.customer = customer;
      clonedBooking.message = message;

      await resource.updateDocument(clonedBooking);
      if (!BookingResource.isLegacy(booking)) {
        // Create a marketplace event if all is successful from -> to
        const metadataEvent = createCustomerUpdatedEvent(
          globals.session.currentUser!.id,
          booking.customer,
          clonedBooking.customer,
        );
        await new MarketplaceResource().updateProduct(booking.id, 0, 'Booking information was updated', metadataEvent);
      }

      // TODO: Not good to manipulate props directly
      booking.customer = customer;
      booking.message = message;

      setIsEditingCustomer(false);
      setShowActionButtons(false);
      return resolve();
    } catch (err) {
      console.log(err);

      Alert.show(t('Sorry it was not possible...'), t('Oops, something went wrong'), 'error');
      console.error(err);
      return reject(err);
    }
  };
  const onSubmitEditBookingForm = async (
    selectedPriceCategories: BookingPriceCategoryDocument[],
    totalPrice: number,
    refundAmount: number,
    resolve: Function,
    reject: Function,
  ) => {
    try {
      if (!BookingResource.isLegacy(booking)) {
        // Create a marketplace event if product is MKTPLACE
        const metadataEvent = createPriceCategoriesUpdatedEvent(
          globals.session.currentUser!.id,
          booking.priceCategories,
          selectedPriceCategories,
        );
        await new MarketplaceResource().updateProduct(
          booking.id,
          totalPrice - booking.totalPrice,
          'Number of visitors was changed',
          metadataEvent,
        );
      }

      // Creates a refund in MKT SDK
      if (refundAmount > 0 && !BookingResource.isLegacy(booking)) {
        const marketplaceResource = new MarketplaceResource();
        const reason = refundAmount == booking.totalPrice ? 'Refund' : 'Refund partially';
        const refund = await marketplaceResource.createProductRefund(booking.id, refundAmount, reason, {
          refundInitiatedBy: globals.session.currentUser?.id,
        });
        if (!refund) throw new Error('oopsie');
      }
      const err = await resource.updateVisitors(
        booking.id,
        selectedPriceCategories,
        totalPrice,
        refundAmount,
        !BookingResource.isLegacy(booking),
      );
      const deltaCost = totalPrice - (booking.totalPrice || 0);
      const messageRefundPart1 = t('It has been refunded');
      const messageRefundPart2 = t('to the customer');
      const messageCostPart1 = t('Remember to charge for the increased cost');
      const message =
        refundAmount > 0
          ? [`${messageRefundPart1} ${refundAmount} ${Platforms.currency} ${messageRefundPart2}`]
          : deltaCost > 0
          ? [`${messageCostPart1} (${deltaCost} ${Platforms.currency}) 🤑.`]
          : [t('No refund has been created.')];

      const mailEvent = createdMailEvent(
        globals.session.currentUser!.id,
        booking.customer.email,
        true,
        'changed_booking',
      );
      switch (err) {
        case BookingResource.ErrorCodes.MailWasNotSend:
          mailEvent.success = false;
          message.push(t('Unfortunately it was not possible to send the e-mail to the customer.'));
          break;
      }

      if (!BookingResource.isLegacy(booking)) {
        // Adds a mail event to the booking history
        await new MarketplaceResource().updateProduct(booking.id, 0, 'Number of visitors was changed', mailEvent);
      }

      if (booking.crossSellingProperty) {
        try {
          await createBookingChangedVisitorsNotification(
            booking,
            globals.session.currentOrganization,
            selectedPriceCategories,
            locale,
          );
        } catch (err) {
          console.error(err);
        }
      }

      if (deltaCost > 0 && modal.current !== null) {
        modal.current.show();
      } else {
        Alert.show(message.join(' '), t('Booking has been saved'), 'success');
      }

      booking.priceCategories = selectedPriceCategories;
      booking.totalPrice = totalPrice;

      setIsEditingVisitors(false);
      setShowActionButtons(false);

      bookings.deletePaymentInfoForId(booking.id);
      edited(true);
      return resolve();
    } catch (err) {
      Alert.show(t('Unfortunately it was not possible to save the booking.'), t('Oops, something went wrong'), 'error');
      console.error(err);
      return reject();
    }
  };
  const onSubmitDeleteBookingForm = async (refundAmount: number, resolve?: Function, reject?: Function) => {
    try {
      // Perform a refund request in marketplace
      if (!BookingResource.isLegacy(booking) && refundAmount > 0) {
        const marketplaceResource = new MarketplaceResource();
        const reason = refundAmount == booking.totalPrice ? 'Cancel' : 'Cancel partially';
        const refund = await marketplaceResource.createProductRefund(booking.id, refundAmount, reason, {
          refundInitiatedBy: globals.session.currentUser?.id,
        });
        if (!refund) throw new Error('No refund could be made...');
      }
      // If successful, remove booking in booking resource
      const err = await resource.deleteBooking(booking.id, refundAmount, !BookingResource.isLegacy(booking));

      await new InventoryEntityResource().deleteEntitiesAndStatuses(booking);
      await new InventoryTransferResource().deleteBagTransfers(booking);

      const message =
        refundAmount > 0
          ? [`${refundAmount} ${Platforms.currency} ${t('has been refunded to customer.')}`]
          : [t('No refund has been created.')];

      switch (err) {
        case BookingResource.ErrorCodes.MailWasNotSend:
          message.push(t('Unfortunately it was not possible to send the e-mail to the customer.'));
          break;
      }

      // Send a nofification to the original booking organization when
      // the booking is being removed.
      if (booking.crossSellingProperty) {
        try {
          await createBookingRemovedNotification(booking, globals.session.currentOrganization, locale);
        } catch (err) {
          console.error(err);
        }
      }

      Alert.show(message.join(' '), t('Booking has been removed'), 'success');

      onRemoved(booking);
      if (resolve) {
        resolve();
      }
      return;
    } catch (err) {
      Alert.show(t('Unfortunately somthing went wrong when the booking was removed.'), t('Oops..'), 'error');
      console.error(err);
      if (reject) {
        reject(err);
      }
      return;
    }
  };

  const onEditButtonClicked = () => {
    setIsEditingCustomer(false);
    setIsEditingVisitors(false);
    setIsDeleting(false);
    setIsAddingPayment(false);
    setIsShowingHistory(false);
    setShowMailButtons(false);

    if (showActionButtons) {
      setShowActionButtons(false);
      return;
    }

    setShowActionButtons(true);
    if (isOldBooking) {
      const texts = {
        text: t('The booking you are going to edit has already taken place...'),
        title: t('The booking is old.'),
      };
      Alert.show(texts.text, texts.title, 'warning');
    }
  };

  const onMailButtonClicked = () => {
    setIsEditingCustomer(false);
    setIsEditingVisitors(false);
    setIsDeleting(false);
    setIsAddingPayment(false);
    setIsShowingHistory(false);
    setShowActionButtons(false);

    if (showMailButtons) {
      setShowMailButtons(false);
      return;
    }

    setShowMailButtons(true);
  };

  const sendConfirmationMail = async (confirmBookingRequest: boolean) => {
    setIsSendingMail(true);

    const origin = location.origin;
    const path = '/payment/product';
    const query = `externalId=${booking.id}`;
    const link = `${origin}${path}?${query}`;

    const mailEvent = createdMailEvent(
      globals.session.currentUser!.id,
      booking.customer.email,
      true,
      'booking_confirmation',
    );

    if ('requested' in booking && !booking.crossSellingProperty && confirmBookingRequest) {
      const confirmBookingRequestMetadata = createdBookingConfirmedEvent(globals.session.currentUser!.id, true);
      await new MarketplaceResource().updateProduct(
        booking.id,
        0,
        'Confirmed booking request',
        confirmBookingRequestMetadata,
      );
    }

    try {
      booking.requested = false;
      await new BookingResource().updateDocument(booking);
      await new MailResource().sendBookingConfirmationEmail(
        booking.id,
        booking.requested && !booking.crossSellingProperty && confirmBookingRequest ? link : undefined,
        confirmBookingRequest,
      );
      Alert.show(t('The mail has been sent to the customer.'), t('The confirmation is sent.'), 'success');
    } catch (err) {
      mailEvent.success = false;
      Alert.show(t('Unfortunately it was not possible to send the e-mail...'), t('Something went wrong'), 'error');
    } finally {
      // Adds a mail event to the booking history
      await new MarketplaceResource().updateProduct(booking.id, 0, 'Number of visitors was changed', mailEvent);
      setIsSendingMail(false);
      setShowActionButtons(false);
    }
  };

  // TODO: This should be sent using email to the specified customer email address
  const generatePaymentLink = async () => {
    const origin = location.origin;
    const path = '/payment/product';
    const query = `externalId=${booking.id}`;
    const link = `${origin}${path}?${query}`;
    const metadata = createdMailEvent(globals.session.currentUser!.id, booking.customer.email, true, 'payment_link');
    setIsSendingPaymentLink(true);
    try {
      await new MailResource().sendPaymentLinkEmail(booking.id, link);
      Alert.show(t('The mail has been sent to the customer.'), t('The payment link was sent'), 'success');
    } catch (err) {
      metadata.success = false;
      Alert.show(t('Unfortunately it was not possible to send the e-mail...'), t('Something went wrong'), 'error');
    } finally {
      new MarketplaceResource().updateProduct(booking.id, 0, '', metadata);
      setIsSendingPaymentLink(false);
      setShowActionButtons(false);
    }
  };

  const rejectBookingRequest = async () => {
    setIsSendingMail(true);
    const mailMetadata = createdMailEvent(
      globals.session.currentUser!.id,
      booking.customer.email,
      true,
      'booking_rejected',
    );

    const rejectionMetadata = createdBookingRejectedEvent(globals.session.currentUser!.id, true);

    try {
      booking.requested = true;
      await new BookingResource().updateDocument(booking);
      await new MailResource().sendBookingRejectionEmail(booking.id);
      Alert.show(t('The mail has been sent to the customer.'), t('The booking has been rejected'), 'success');
    } catch (err) {
      console.log(err);
      mailMetadata.success = false;
      Alert.show(t('Unfortunately it was not possible to send the e-mail...'), t('Something went wrong'), 'error');
    } finally {
      await new MarketplaceResource().updateProduct(booking.id, 0, '', mailMetadata);
      await new MarketplaceResource().updateProduct(booking.id, 0, 'Rejected booking request', rejectionMetadata);
    }
    setIsSendingMail(false);
  };

  const sendWelcomeEmail = async () => {
    setIsSendingMail(true);
    const mailEvent = createdMailEvent(globals.session.currentUser!.id, booking.customer.email, true, 'welcome_mail');
    try {
      await new MailResource().sendWelcomeEmailToCustomer(booking.id);
      Alert.show(t('The mail has been sent to the customer.'), t('Welcome mail sent'), 'success');
    } catch (err) {
      Alert.show(t('Unfortunately it was not possible to send the e-mail...'), t('Something went wrong'), 'error');
    } finally {
      // Adds a mail event to the booking history
      await new MarketplaceResource().updateProduct(booking.id, 0, '', mailEvent);
      setIsSendingMail(false);
    }
  };

  const sendThankYouEmail = async () => {
    setIsSendingMail(true);
    const mailEvent = createdMailEvent(globals.session.currentUser!.id, booking.customer.email, true, 'thankyou_mail');
    try {
      await new MailResource().sendThankYouEmailToCustomer(booking.id);
      Alert.show(t('The mail has been sent to the customer.'), t('Thank you mail sent'), 'success');
    } catch (err) {
      Alert.show(t('Unfortunately it was not possible to send the e-mail...'), t('Something went wrong'), 'error');
    } finally {
      // Adds a mail event to the booking history
      await new MarketplaceResource().updateProduct(booking.id, 0, '', mailEvent);
      setIsSendingMail(false);
    }
  };

  const renderSetInvoicedBtn = () => {
    if (booking.crossSellingProperty && isOrganizationBooking) {
      return (
        <button
          className="ui fluid basic button"
          onClick={() => onMarkInvoiced(!booking.isInvoiced, [booking])}
          style={{ marginBottom: '1em' }}
        >
          <i className="ui file alternate outline icon" />
          {!booking.isInvoiced ? t('Mark as invoiced') : t('Mark as uninvoiced')}
        </button>
      );
    }
  };

  // #endregion

  // #region Render functions

  async function handleMarkAsManuallyPaid() {
    if (paymentInfo === null) {
      return;
    }
    edited(true);
    setIsAddingPayment(true);

    const leftToBePaid = booking.totalPrice - paidAmount;

    if (paymentInfo.legacy) {
      const resource = new PaymentResource();
      await resource.addManualPaymentToBooking(booking.id, leftToBePaid);
    } else {
      const resource = new MarketplaceResource();
      await resource.createManualPayment(booking.id, leftToBePaid);
    }

    bookings.deletePaymentInfoForId(booking.id);
    setIsAddingPayment(false);
  }

  const renderMailButtons = () => {
    if (!showMailButtons || isBusy) {
      return undefined;
    }

    return (
      <div>
        {!booking.crossSellingProperty && (
          <button
            className={
              'ui fluid basic button' +
              (paymentInfo?.legacy || isBookingPaid ? ' disabled' : '') +
              (isSendingPaymentLink ? ' loading disabled' : '')
            }
            style={{ marginBottom: '1em' }}
            onClick={generatePaymentLink}
          >
            <i className="credit card icon" /> {t('Send payment link')}
          </button>
        )}

        <button
          className={'ui fluid basic ' + (isSendingMail ? 'loading disabled' : '') + ' button'}
          onClick={() => sendConfirmationMail(false)}
          style={{ marginBottom: '1em' }}
        >
          <i className="ui thumbs up outline icon" />
          {t('Send confirmation to the customer.')}
        </button>

        <button
          className={'ui fluid basic ' + (isSendingMail ? 'loading disabled' : '') + ' button'}
          onClick={() => sendWelcomeEmail()}
          style={{ marginBottom: '1em' }}
        >
          <i className="ui hand paper outline icon" /> {t('Send welcome mail')}
        </button>

        <button
          className={'ui fluid basic ' + (isSendingMail ? 'loading disabled' : '') + ' button'}
          onClick={() => sendThankYouEmail()}
          style={{ marginBottom: '1em' }}
        >
          <i className="ui handshake outline icon" /> {t('Send thank you mail')}
        </button>
      </div>
    );
  };

  /** Shows the refund-content if its a non-crossSelling booking, otherwise it gets removed directly (crossSelling bookings does not support refunds) */
  const onDeleteConfirmed = () => {
    if (!booking.crossSellingProperty) {
      setIsDeleting(true);
      return;
    }
    onSubmitDeleteBookingForm(0);
  };

  const renderActionButtons = () => {
    if (!showActionButtons || isBusy) {
      return undefined;
    }

    return (
      <div>
        {!booking.crossSellingProperty ? (
          <ConfirmationButton
            disabled={isBookingPaid || paymentInfo === null}
            loading={isAddingPayment}
            stopPropagation
            timeout={2000}
            className="ui fluid basic button"
            style={{ marginBottom: '1em' }}
            label={
              <>
                <i className="ui checkmark icon" /> {t('Mark as paid')}
              </>
            }
            activeButton={
              <button
                className={
                  'ui fluid basic button' +
                  (isAddingPayment || isBookingPaid || paymentInfo === null ? ' disabled' : '')
                }
                style={{ margin: '0 0 1em 0' }}
                onClick={handleMarkAsManuallyPaid}
              >
                {t('Are you sure?')}
              </button>
            }
          ></ConfirmationButton>
        ) : null}

        {renderSetInvoicedBtn()}

        {'requested' in booking && !booking.crossSellingProperty && (
          <>
            <button
              className={
                'ui fluid basic button' +
                (paymentInfo?.legacy || isBookingPaid ? ' disabled' : '') +
                (isSendingPaymentLink ? ' loading disabled' : '')
              }
              style={{ marginBottom: '1em' }}
              onClick={rejectBookingRequest}
            >
              <i className="close icon" /> {t('Reject booking request')}
            </button>
            {(booking.occurance as unknown as ActivityOccuranceDocument).availableVisitors > booking.bookedVisitors && (
              <button
                className={
                  'ui fluid basic button' +
                  (paymentInfo?.legacy || isBookingPaid ? ' disabled' : '') +
                  (isSendingPaymentLink ? ' loading disabled' : '')
                }
                style={{ marginBottom: '1em' }}
                onClick={() => sendConfirmationMail(true)}
              >
                <i className="thumbs up outline icon" /> {t('Confirm and send payment link')}
              </button>
            )}
          </>
        )}

        <button
          className="ui fluid basic button"
          onClick={() => setIsEditingCustomer(true)}
          style={{ marginBottom: '1em' }}
        >
          <i className="ui edit icon" /> {t('Change customer contact information.')}
        </button>

        <button
          className="ui fluid basic button"
          onClick={() => setIsEditingVisitors(true)}
          style={{ marginBottom: '1em' }}
        >
          <i className="ui edit icon" /> {t('Change number')}
        </button>

        <div>
          <DeleteButton
            fluid
            disabled={isDeleting}
            deleting={isDeleting}
            onConfirmed={onDeleteConfirmed}
            confirmationText={t('Are you really sure?')}
          >
            {t('Remove booking')}
          </DeleteButton>
          <div style={{ height: '1em' }} />
        </div>
      </div>
    );
  };

  const renderEditArea = () => {
    return (
      <div style={{ marginTop: '5px' }}>
        {renderActionButtons()}
        <button className="ui fluid basic button" onClick={onEditButtonClicked} style={{ margin: '0 0 5px 0' }}>
          {showActionButtons ? t('Cancel') : t('Manage booking')}
        </button>
      </div>
    );
  };

  const renderMailArea = () => {
    return (
      <div style={{ marginTop: '5px' }}>
        {renderMailButtons()}
        <button className="ui fluid basic button" onClick={onMailButtonClicked} style={{ margin: '0 0 5px 0' }}>
          {showMailButtons ? t('Cancel') : t('Manage mailings')}
        </button>
      </div>
    );
  };

  function CustomerFormView() {
    return (
      <CustomerForm
        showMessageInput
        customer={booking.customer}
        message={booking.message}
        header={<h3>{t('Edit customer information')}</h3>}
        onSubmit={onSubmitCustomerForm}
      />
    );
  }

  function EditBookingFormView() {
    return (
      <EditBookingForm
        booking={booking}
        amountPayedOnline={paymentInfo?.amountPaidWithStripe || 0}
        onSubmit={onSubmitEditBookingForm}
      />
    );
  }

  function DeleteBookingFormView() {
    return (
      <DeleteBookingForm
        booking={booking}
        amountPayedOnline={paymentInfo?.amountPaidWithStripe || 0}
        onSubmit={onSubmitDeleteBookingForm}
      />
    );
  }

  const renderDesktopView = () => {
    return (
      <tr>
        <td>
          {renderSendPaymentLinkModal()}
          <StatusInfo
            hidePaymentStatus={!!booking.crossSellingProperty}
            booking={booking}
            paymentInfo={paymentInfo}
            discountInfo={discountInfo}
            isSendingMail={isSendingMail}
          />
          <div style={{ height: '1em' }} />
          {isDeleting ? <DeleteBookingFormView /> : undefined}
          {!showMailButtons && !(booking as any).deleted && renderEditArea()}
          {!showActionButtons && !(booking as any).deleted && renderMailArea()}
          {showActionButtons || showMailButtons
            ? null
            : device.size === 'desktop' && (
                <button
                  className={`ui fluid basic button ${paymentInfo?.legacy && 'disabled'}`}
                  onClick={() => (isShowingHistory ? setIsShowingHistory(false) : setIsShowingHistory(true))}
                  style={{ marginBottom: '1em' }}
                >
                  <i className="ui clock outline icon" />{' '}
                  {isShowingHistory ? t('Hide booking history') : t('Show booking history')}
                </button>
              )}
        </td>
        <td>{isEditingCustomer ? <CustomerFormView /> : <CustomerInfo booking={booking} mobileView={false} />}</td>
        <td
          colSpan={isShowingHistory ? 2 : 1}
          style={{
            padding: isShowingHistory ? '0px' : '11px',
            verticalAlign: isShowingHistory ? 'top' : 'inherit',
            backgroundColor: isShowingHistory ? '#F8F8F9' : 'inherit',
          }}
        >
          {isEditingVisitors ? (
            <EditBookingFormView />
          ) : isShowingHistory ? (
            <BookingHistory history={history} />
          ) : (
            <BookingInfo booking={booking} />
          )}
        </td>
        {!isShowingHistory && (
          <td>
            <CrossSelling booking={booking} />
          </td>
        )}
      </tr>
    );
  };

  const renderSendPaymentLinkModal = () => {
    if ((booking as any).deleted) return null;

    const close = () => modal.current?.hide();
    const send = () => {
      close();
      generatePaymentLink();
    };

    return (
      <Modal size="small" header={t('Do you want to send a payment link')} ref={modal} closable>
        <div className="content">
          <div className="description">
            <p style={{ marginBottom: '2em' }}>{t('Payment link description')}</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <button onClick={send} style={{ flex: 1 }} className="ui green button">
              {t('Send payment link')}
            </button>
            <button onClick={close} style={{ flex: 1 }} className="ui button">
              {t("Don't send")}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderMobileView = () => {
    const View = () => {
      if (isEditingCustomer) {
        return <CustomerFormView />;
      }
      if (isEditingVisitors) {
        return <EditBookingFormView />;
      }
      if (isDeleting) {
        return <DeleteBookingFormView />;
      }
      return null;
    };

    return (
      <div className="ui fluid card">
        {renderSendPaymentLinkModal()}
        <div className="content">
          <StatusInfo
            hidePaymentStatus={!!booking.crossSellingProperty}
            booking={booking}
            paymentInfo={paymentInfo}
            discountInfo={discountInfo}
            isSendingMail={isSendingMail}
          />
          <BookingInfo booking={booking} />
          <CustomerInfo booking={booking} mobileView={true} />
          <CrossSelling booking={booking} />
        </div>
        <div className="extra content">
          <View />
          {!showMailButtons && renderEditArea()}
          {!showActionButtons && renderMailArea()}
        </div>
      </div>
    );
  };

  // #endregion
  return device.size == 'desktop' ? renderDesktopView() : renderMobileView();
};
