import Lodash from 'lodash';
import { action, computed, observable } from 'mobx';
import * as React from 'react';
import {
  Accordion,
  Alert,
  BookingDocument,
  BookingResource,
  Consume,
  DeviceProvider,
  Field,
  Form,
  IDevice,
  InventoryEntityResource,
  InventoryTransferResource,
  LocaleContext,
  MailResource,
  MarketplaceResource,
  MobxComponent,
  PaymentResource,
  Platforms,
  ProductPaymentInfo,
  TextArea,
  UserRole,
  createBookingRemovedNotification,
  visitors,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { Maybe } from '../../../components/maybe';
import { DeleteBookingForm } from '../../dashboard/bookings/bookingItem/deleteBookingForm';
import { eventFetcher } from '../../dashboard/bookings/bookingItem/handleBookingHistoryDataHelpers';
import {
  createdBookingConfirmedEvent,
  createdBookingRejectedEvent,
  createdMailEvent,
} from '../../dashboard/bookings/events';
import { CalendarStore } from '../store';
import TicketsTables from './ticketsTables';
interface Props {
  booking: BookingDocument;
  onMoveBooking?: (booking: BookingDocument) => void;
  hideMoveButton?: boolean;
  store: CalendarStore;
}

interface State {
  loading: boolean;
  fullyPaid: boolean;
  confirmed: boolean;
  rejected: boolean;
}

export class BookingInfoItem extends MobxComponent<Props, State> {
  state = {
    loading: false,
    fullyPaid: false,
    confirmed: false,
    rejected: false,
  };

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @observable private _savingNoteField = false;
  @observable private _paymentInfo: ProductPaymentInfo;
  @observable private _notesText: string;
  @observable private _shouldDelete = false;
  private _eventTypes = ['booking_confirmed', 'booking_rejected'];

  private _noteUpdateTimer;

  componentDidMount() {
    try {
      (async () => {
        const legacyInfo = await new PaymentResource().getPaidAmountOfBooking(this.props.booking.id);
        const marketplaceInfo = await new MarketplaceResource().getPaidAmountForProduct(this.props.booking.id);
        const paymentInfo: typeof marketplaceInfo = {
          amountPaidManually: legacyInfo.amountPaidManually + marketplaceInfo.amountPaidManually,
          amountPaidWithGiftCard: legacyInfo.amountPaidWithGiftCard + marketplaceInfo.amountPaidWithGiftCard,
          amountPaidWithStripe: legacyInfo.amountPaidWithStripe + marketplaceInfo.amountPaidWithStripe,
          application_fee: legacyInfo.application_fee || marketplaceInfo.application_fee,
          legacy: marketplaceInfo.legacy,
          productValue: marketplaceInfo.productValue,
        };
        this._paymentInfo = paymentInfo;

        if ('requested' in this.props.booking && !this.props.booking.crossSellingProperty) {
          this.updateEventInformation();
        }
      })();
    } catch (err) {
      console.error('could not load payment', err);
    }
  }

  componentDidUpdate(prevProps: any, prevState: Readonly<State>): void {
    if (prevState.loading !== this.state.loading) {
      if ('requested' in this.props.booking && !this.props.booking.crossSellingProperty) {
        this.updateEventInformation();
      }
    }
    return;
  }

  private updateEventInformation = async () => {
    const events = await eventFetcher(this.props.booking, this._eventTypes);
    this.setState({ confirmed: events.confirmed, rejected: events.rejected });
  };

  @action
  private handleNoteField = (textValue) => {
    this.props.booking.notes = textValue;
    this._notesText = textValue;
    if (this._noteUpdateTimer) {
      clearTimeout(this._noteUpdateTimer);
    }
    this._noteUpdateTimer = setTimeout(() => {
      this._savingNoteField = true;
      new BookingResource().updateNotes(this.props.booking.id, textValue).then((res) => {
        this._savingNoteField = false;
      });
    }, 1000);
  };

  private onMoveBooking = () => {
    if (this.props.onMoveBooking) {
      this.props.onMoveBooking(this.props.booking);
    }
  };

  private onConfirm = async (send: { payment_link: boolean; booking_confirmation: boolean }) => {
    this.setState({ loading: true });
    const { t } = this._locale;
    const { booking } = this.props;
    const link = `${location.origin}${'/payment/product'}?externalId=${booking.id}`;

    const mailMetadata = createdMailEvent(
      this.globals.session.currentUser!.id,
      booking.customer.email,
      true,
      'booking_confirmation',
    );

    const confirmationMetadata = createdBookingConfirmedEvent(this.globals.session.currentUser!.id, true);

    try {
      booking.requested = false;
      await new BookingResource().updateDocument(booking);
      await new MailResource().sendBookingConfirmationEmail(booking.id, send.payment_link ? link : undefined, true);
      Alert.show(t('The mail has been sent to the customer.'), t('The confirmation is sent.'), 'success');
      this.props.store.reload();
    } catch (err) {
      mailMetadata.success = false;
      confirmationMetadata.success = false;
      Alert.show(t('Unfortunately it was not possible to send the e-mail...'), t('Something went wrong'), 'error');
    } finally {
      // Adds a events to the booking history
      await new MarketplaceResource().updateProduct(booking.id, 0, '', mailMetadata);
      await new MarketplaceResource().updateProduct(booking.id, 0, 'Confirmed booking request', confirmationMetadata);
    }
    this.setState({ loading: false });
  };

  private onReject = async () => {
    this.setState({ loading: true });
    const { t } = this._locale;
    const { booking } = this.props;
    const mailMetadata = createdMailEvent(
      this.globals.session.currentUser!.id,
      booking.customer.email,
      true,
      'booking_rejected',
    );

    const rejectionMetadata = createdBookingRejectedEvent(this.globals.session.currentUser!.id, true);

    try {
      booking.requested = true;
      await new BookingResource().updateDocument(booking);
      await new MailResource().sendBookingRejectionEmail(booking.id);
      Alert.show(t('The mail has been sent to the customer.'), t('The booking has been rejected'), 'success');
      this.props.store.reload();
    } catch (err) {
      mailMetadata.success = false;
      Alert.show(t('Unfortunately it was not possible to send the e-mail...'), t('Something went wrong'), 'error');
    } finally {
      await new MarketplaceResource().updateProduct(booking.id, 0, '', mailMetadata);
      await new MarketplaceResource().updateProduct(booking.id, 0, 'Rejected booking request', rejectionMetadata);
    }
    this.setState({ loading: false });
  };

  private get nrOfVisitors() {
    return Lodash.sumBy(this.props.booking.priceCategories, (pc) => visitors(pc));
  }

  private get messageFromCustomer() {
    const { message } = this.props.booking;
    const { t } = this._locale;
    if (message?.length) {
      return (
        <div className="ui fluid yellow message" style={{ marginTop: 0 }}>
          {message}
        </div>
      );
    }

    return <p style={{ color: '#555', paddingLeft: '1.8rem' }}>{t('No message written')}</p>;
  }

  private get messageToCustomer() {
    const { messageToCustomer } = this.props.booking;
    const { t } = this._locale;
    if (messageToCustomer?.length) {
      return (
        <div className="ui fluid message" style={{ marginTop: 0 }}>
          {messageToCustomer}
        </div>
      );
    }

    return <p style={{ color: '#555', paddingLeft: '1.8rem' }}>{t('No message written')}</p>;
  }

  @computed private get paymentStatus() {
    const { t } = this._locale;
    if (this._paymentInfo == undefined) {
      return <div className={'ui active tiny inline loader'} />;
    }

    let message: string;
    let color: string;
    const amountPaid =
      this._paymentInfo.amountPaidWithStripe +
      this._paymentInfo.amountPaidWithGiftCard +
      this._paymentInfo.amountPaidManually;

    const productValue = this._paymentInfo.productValue ?? this.props.booking.totalPrice;

    if (amountPaid == 0 && productValue) {
      message = t('Not paid');
      color = 'red';
    } else if (amountPaid >= productValue) {
      this.setState({ fullyPaid: true });
      message = t('Fully paid');
      color = 'green';
    } else {
      message = t('Partly paid');
      color = 'yellow';
    }

    return (
      <span className={`ui large ${color} basic label`} style={{ borderWidth: 0, padding: 0 }}>
        {message}
      </span>
    );
  }

  @computed private get paymentStatusInfo() {
    const { t } = this._locale;
    if (!this._paymentInfo) {
      return undefined;
    }

    const onlinePayment = this._paymentInfo.amountPaidWithStripe ? (
      <p>
        {this._paymentInfo.amountPaidWithStripe} {t('components.calendar.views.bookingInfoItem.infoOne')}
      </p>
    ) : undefined;

    const giftCardPayment = this._paymentInfo.amountPaidWithGiftCard ? (
      <p>
        {this._paymentInfo.amountPaidWithGiftCard} {t('components.calendar.views.bookingInfoItem.infoTwo')}
      </p>
    ) : undefined;

    const manualPayment = this._paymentInfo.amountPaidManually ? (
      <p>
        {this._paymentInfo.amountPaidManually} {t('components.calendar.views.bookingInfoItem.infoThree')}
      </p>
    ) : undefined;

    const paidAmount =
      this._paymentInfo.amountPaidWithStripe +
      this._paymentInfo.amountPaidWithGiftCard +
      this._paymentInfo.amountPaidManually;
    const paymentLeft =
      paidAmount > 0 && paidAmount < (this.props.booking.totalPrice || 0) ? (
        <p>
          {(this.props.booking.totalPrice || 0) - paidAmount} {t('components.calendar.views.bookingInfoItem.infoFour')}
        </p>
      ) : undefined;

    return (
      <div>
        {onlinePayment}
        {giftCardPayment}
        {manualPayment}
        {paymentLeft}
      </div>
    );
  }

  @computed private get moveButton() {
    const { t } = this._locale;
    const notManager = !this.globals.session.userHasRole({ type: 'property.manager' } as UserRole);
    if (this.props.hideMoveButton || notManager) {
      return null;
    }

    return (
      <button className="ui fluid basic button" onClick={this.onMoveBooking} style={{ margin: '10px 0 15px 0' }}>
        <i className="ui send icon" />
        {t('components.calendar.views.bookingInfoItem.buttonOne')}
      </button>
    );
  }

  @computed private get confirmAndSendPaymentLinkButton() {
    const { t } = this._locale;
    const { fullyPaid, confirmed, rejected } = this.state;
    const notManager = !this.globals.session.userHasRole({ type: 'property.manager' } as UserRole);
    if (this.props.hideMoveButton || notManager) {
      return null;
    }

    const sending = () => {
      let sendLink = true;
      let sendConfirmation = true;

      if (fullyPaid) {
        sendLink = false;
      }

      return { payment_link: sendLink, booking_confirmation: sendConfirmation };
    };

    return (
      <button
        className={`ui fluid basic button ${this.state && this.state.loading ? 'loading' : ''}`}
        onClick={() => this.onConfirm(sending())}
        style={{ margin: '10px 0 15px 0' }}
      >
        <i className="ui check icon" />
        {confirmed && !fullyPaid
          ? t('Resend confirmation and payment link')
          : rejected
          ? t('Confirm and send payment link')
          : fullyPaid && confirmed
          ? t('Resend confirmation')
          : t('Confirm and send payment link')}
      </button>
    );
  }

  @computed private get rejectButton() {
    const { t } = this._locale;
    const notManager = !this.globals.session.userHasRole({ type: 'property.manager' } as UserRole);
    if (this.props.hideMoveButton || notManager) {
      return null;
    }

    return (
      <button
        className={`ui fluid basic ${this.state && this.state.loading ? 'loading' : ''} button`}
        onClick={this.onReject}
        style={{ margin: '10px 0 15px 0' }}
      >
        <i className="ui close icon" />
        {this.state.rejected ? t('Resend rejection mail') : t('Reject booking request')}
      </button>
    );
  }

  private get deleteButton() {
    const { t } = this._locale;
    return (
      <button
        onClick={() => (this._shouldDelete = true)}
        className="ui fluid basic red button"
        style={{ margin: '10px 0 15px 0' }}
      >
        <i className="ui trash icon" />
        {t('Remove booking')}
      </button>
    );
  }

  private _onSubmitDeleteBookingForm = async (refundAmount: number, resolve?: Function, reject?: Function) => {
    const { t, locale } = this._locale;
    const resource = new BookingResource();
    const booking = this.props.booking;
    try {
      // Perform a refund request in marketplace
      if (!BookingResource.isLegacy(booking) && refundAmount > 0) {
        const marketplaceResource = new MarketplaceResource();
        const reason = refundAmount == booking.totalPrice ? 'Cancel' : 'Cancel partially';
        const refund = await marketplaceResource.createProductRefund(booking.id, refundAmount, reason, {
          refundInitiatedBy: this.globals.session.currentUser?.id,
        });
        if (!refund) throw new Error('No refund could be made...');
      }
      // If successful, remove booking in booking resource
      const err = await resource.deleteBooking(booking.id, refundAmount, !BookingResource.isLegacy(booking));

      await new InventoryEntityResource().deleteEntitiesAndStatuses(booking);
      await new InventoryTransferResource().deleteBagTransfers(booking);

      const message =
        refundAmount > 0
          ? [`${refundAmount} ${Platforms.currency} ${t('has been refunded to customer.')}`]
          : [t('No refund has been created.')];

      switch (err) {
        case BookingResource.ErrorCodes.MailWasNotSend:
          message.push(t('Unfortunately it was not possible to send the e-mail to the customer.'));
          break;
      }

      // Send a nofification to the original booking organization when
      // the booking is being removed.
      if (booking.crossSellingProperty) {
        try {
          await createBookingRemovedNotification(booking, this.globals.session.currentOrganization, locale);
        } catch (err) {
          console.error(err);
        }
      }

      Alert.show(message.join(' '), t('Booking has been removed'), 'success');

      this.props.store.reload();

      if (resolve) {
        resolve();
      }
      return;
    } catch (err) {
      Alert.show(t('Unfortunately somthing went wrong when the booking was removed.'), t('Oops..'), 'error');
      console.error(err);
      if (reject) {
        reject(err);
      }
      return;
    }
  };

  render() {
    const { t, tt } = this._locale;
    const { booking, store } = this.props;
    const { confirmed, rejected, fullyPaid } = this.state;

    // We don't allow the actors to confirm fully booked occurances.
    const fullyBooked =
      store.selectedEvent.isFullyBooked || store.selectedEvent.availableVisitors < booking.bookedVisitors;

    const requestCheck =
      'requested' in this.props.booking && !this.props.booking.crossSellingProperty && (confirmed || rejected);

    return (
      <div
        className={`ui fluid ${requestCheck ? (confirmed || fullyPaid ? 'green' : 'red') : 'purple'} card`}
        style={{ paddingLeft: '10px', paddingRight: '10px' }}
      >
        <Accordion
          title={
            <h5>
              <i className="ticket icon" /> {booking.customer.firstname} {booking.customer.lastname}{' '}
              {booking.customer.businessName ? '(' + booking.customer.businessName + ')' : ''}
              <span style={{ float: 'right' }}>
                {booking.crossSellingProperty && (
                  <img
                    style={{ paddingRight: '4px' }}
                    src={'/static/commons/svg/crossSellingIconDark.svg'}
                    alt="cross-selling"
                  />
                )}
                <i className="male icon" /> {this.nrOfVisitors}
              </span>
            </h5>
          }
        >
          {requestCheck && (
            <a
              style={{ marginBottom: '1rem' }}
              className={`ui ${confirmed ? 'green' : fullyBooked ? 'red' : 'red'} ribbon label`}
            >
              {confirmed || fullyPaid ? t('Confirmed') : fullyBooked ? t('Fully booked') : t('Rejected')}
            </a>
          )}

          <h5 style={{ marginTop: 0 }}>
            <i className="bookmark icon" /> <b>{booking.number}</b>
          </h5>

          {booking.crossSellingProperty && (
            <h5 style={{ marginTop: 0 }}>
              <img
                style={{ paddingRight: '4px' }}
                src={'/static/commons/svg/crossSellingIconDark.svg'}
                alt="cross-selling"
              />
              <b>{tt(booking.crossSellingProperty.name)}</b>
            </h5>
          )}

          <h5 style={{ marginTop: 0, marginBottom: '.4rem' }}>
            <i className="talk icon" />
            &nbsp;{t('Message from the customer')}
          </h5>
          {this.messageFromCustomer}

          <Maybe if="property.manager">
            <div>
              <h5 style={{ marginTop: 0 }}>
                <i className="money icon" /> {`${t('Price')}:`}
                &nbsp;&nbsp;&nbsp;{booking.totalPrice} {t('SEK')}
              </h5>
              <h5 style={{ marginTop: 0 }}>
                <i className="credit card alternate icon" /> {t('Payment status')}
                &nbsp;&nbsp;&nbsp;{this.paymentStatus}
              </h5>
              <div>{this.paymentStatusInfo}</div>
            </div>
          </Maybe>

          <h5 style={{ marginTop: 0, marginBottom: '.4rem' }}>
            <i className="info circle icon" />
            &nbsp;{t('Notes')}
          </h5>
          <Form>
            <Field>
              <div
                className={'ui basic ' + (this._savingNoteField ? 'loading' : '') + ' segment'}
                style={{ padding: 0 }}
              >
                <TextArea
                  name="comments"
                  value={this._notesText || booking.notes}
                  placeholder={t('components.calendar.views.bookingInfoItem.messageSeven')}
                  onChange={this.handleNoteField}
                />
              </div>
            </Field>
          </Form>

          <h5 style={{ marginTop: 0, marginBottom: '.4rem' }}>
            <i className="talk outline icon" />
            &nbsp;{t('Message to the customer')}
          </h5>
          {this.messageToCustomer}

          <TicketsTables priceCategories={booking.priceCategories} t={t} />

          <h5 style={{ marginTop: 0 }}>
            <i className="talk outline icon" />
            &nbsp;{t('components.calendar.views.bookingInfoItem.iconTwo')}
          </h5>
          {booking.customer.address && !booking.customer.address.isEmpty && (
            <p>
              {booking.customer.address.row1}
              {booking.customer.address.row2 ? (
                <span>
                  <br />
                  {booking.customer.address.row2}&nbsp;
                </span>
              ) : (
                ' '
              )}
              {booking.customer.address.postNr}&nbsp;{booking.customer.address.postOrt}
            </p>
          )}
          <a className="ui mini basic fluid labeled icon button" href={'mailto:' + booking.customer.email}>
            <i className="envelope icon" />
            {booking.customer.email}
          </a>
          {booking.customer.phoneNr && (
            <a className="ui mini basic fluid labeled icon button" href={'tel:' + booking.customer.phoneNr}>
              <i className="phone icon" />
              {booking.customer.phoneNr}
            </a>
          )}
          <div style={{ height: '15px' }} />
          {this._shouldDelete ? (
            <DeleteBookingForm
              booking={this.props.booking}
              amountPayedOnline={this._paymentInfo.amountPaidWithStripe || 0}
              onSubmit={this._onSubmitDeleteBookingForm}
            />
          ) : (
            <>
              {store.isUserAllowedToEditEvent() &&
                'requested' in booking &&
                !booking.crossSellingProperty &&
                !fullyBooked &&
                this.confirmAndSendPaymentLinkButton}
              {store.isUserAllowedToEditEvent() &&
                'requested' in booking &&
                !booking.crossSellingProperty &&
                this.rejectButton}
              {store.isUserAllowedToEditEvent() && this.moveButton}
              {store.isUserAllowedToEditEvent() && this.deleteButton}
            </>
          )}
        </Accordion>
      </div>
    );
  }
}
