import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  DeviceProvider,
  GlobalsContext,
  InventoryDocument,
  InventoryEntityDocument,
  InventoryEntityResource,
  InventoryResource,
  InventoryStatusDocument,
  InventoryStatusResource,
  PlaceDocument,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import QrCodeButton from '../qr/qrCodeButton';
import { InventoryDetailsRow } from './inventoryDetailsRow';
import { InventoryInnerTable } from './inventoryInnerTable';
import { InventoryItemForm } from './inventoryItemForm';

interface Props {
  places?: PlaceDocument[];
  inventory: InventoryDocument;
  setInventoryAmount: (length: number) => void;
}

export const AdvancedIventoriesTableRow = ({ inventory, setInventoryAmount, places }: Props) => {
  const { t } = useLocale();
  const [_, setEditingItem] = useState<boolean>(false);
  const [creatingItem, setCreatingItem] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [inventoryEntities, setInventoryEntities] = useState<InventoryEntityDocument[]>([]);
  const inventoryStatusResource = new InventoryStatusResource();
  const inventoryEntityResource = new InventoryEntityResource();
  const globals = useContext(GlobalsContext);
  const device = useContext(DeviceProvider.Context);
  const mobile = device.size === 'mobile';
  const tablet = device.size === 'tablet';

  const populateInventoryData = async () => {
    const entities = await new InventoryResource().getInventoryEntities(inventory.id);
    if (entities.length) {
      setInventoryEntities(entities);
    } else {
      const newInventoryEntities = await new InventoryEntityResource().find({ inventory: inventory.id });
      setInventoryEntities(newInventoryEntities as InventoryEntityDocument[]);
    }
  };

  useEffect(() => {
    populateInventoryData();
  }, [inventory]);

  useEffect(() => {
    setInventoryAmount(inventoryEntities.length);
  }, [inventoryEntities]);

  const handleItemFormSubmit = async (entity: InventoryEntityDocument, status: InventoryStatusDocument, reject) => {
    try {
      if (entity) {
        const updatingEntity = await inventoryEntityResource.find({ _id: entity._id });
        if (!updatingEntity.length) {
          //Create the entity and status documents
          await inventoryEntityResource.updateDocument(entity);
          await inventoryStatusResource.updateDocument(status);
        }
        await inventoryEntityResource.updateDocument(entity);
      }
      setActiveItem(null);
      setCreatingItem(false);
      setEditingItem(false);
      populateInventoryData();
    } catch (err) {
      console.log(err);
      reject();
    }
  };

  const deleteItem = async (entityId: string, statusId: string) => {
    try {
      await inventoryStatusResource.delete(statusId);
      await inventoryEntityResource.delete(entityId);
      setActiveItem(null);
      setEditingItem(false);
      populateInventoryData();
    } catch (err) {
      console.log(err);
    }
  };

  //Deletes placeholder inventory item when create is cancelled
  const deleteEmpty = () => {
    if (creatingItem) {
      const [_, ...rest] = inventoryEntities;
      setInventoryEntities(rest);
      setCreatingItem(false);
    }
  };

  const cancelItem = () => {
    deleteEmpty();
    setActiveItem(null);
  };

  const onEditClicked = (id: string) => {
    setEditingItem(true);
    deleteEmpty();
    setActiveItem(id);
  };

  const newTempInventoryItem = () => {
    deleteEmpty();
    setCreatingItem(true);
    const newEntity = inventoryEntityResource.createDocument({
      organization: globals.session.currentOrganization,
      title: '',
      inventory: inventory.id,
    });
    setInventoryEntities((prev) => [newEntity, ...prev]);
    setActiveItem(newEntity.id);
  };

  const newInventoryStatus = (item: InventoryEntityDocument) => {
    const newStatus = inventoryStatusResource.createDocument({
      status: 'available',
      inventoryEntity: item.id,
    });
    return newStatus;
  };

  const renderForm = (item: InventoryEntityDocument) => {
    const thisItemStatus = creatingItem ? newInventoryStatus(item) : item.lastStatus;
    return (
      <InventoryItemForm
        places={places}
        key={item.id}
        entity={item}
        creatingItem={creatingItem}
        thisItemStatus={thisItemStatus}
        deleteItem={deleteItem}
        cancelItem={cancelItem}
        handleItemFormSubmit={handleItemFormSubmit}
      />
    );
  };

  const renderInnerTableRow = (item: InventoryEntityDocument) => {
    return item.id === activeItem ? (
      renderForm(item)
    ) : (
      <InventoryDetailsRow places={places} item={item} onEditClicked={onEditClicked} />
    );
  };

  return (
    <tr className="center aligned" style={{ backgroundColor: 'rgba(0,0,0,.05)' }}>
      <td colSpan={6}>
        <div>
          <QrCodeButton {...{ inventoryEntities, mobile, tablet }} />
          <Button className="right floated" disabled={creatingItem} onClick={newTempInventoryItem}>
            {mobile ? (
              <>
                <i className="plus icon" />
                New
              </>
            ) : (
              <>
                <i className="plus icon" />
                {t('New')}
              </>
            )}
          </Button>
        </div>
        <div style={{ margin: '3.5rem 0 0.5rem 0' }}>
          <InventoryInnerTable
            inventoryEntities={inventoryEntities}
            renderInnerTableRow={renderInnerTableRow}
            isEditable={true}
          />
        </div>
      </td>
    </tr>
  );
};
