import Lodash from 'lodash';
import { action, computed } from 'mobx';
import Moment from 'moment-timezone';
import React from 'react';
import {
  Accordion,
  ActivityOccuranceDocument,
  BookingPriceCategoryDocument,
  Consume,
  DeviceProvider,
  IDevice,
  LocaleContext,
  MarketplaceProvider,
  OrganizationDocument,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { Maybe } from '../../maybe';
import { CalendarEventComponentProperties, CalendarEventProperties, View } from '../utils/types';
import { AssignEmployees } from '../views/assignEmployees';
import { CreateManualBookingForm } from '../views/createManualBookingView';
import { EditActivityForm } from '../views/editActivityForm';
import { MoveBooking } from '../views/moveBooking';
import { PublishButton } from '../views/publishButton';
import TicketsTables from '../views/ticketsTables';
import {
  DefaultEventComponent,
  DefaultEventComponentCollection,
  DefaultEventDetailsComponent,
} from './defaultEventComponentCollection';

export class ActivityEventComponentCollection extends DefaultEventComponentCollection {
  getEventProperties(event: ActivityOccuranceDocument, isSelected: boolean): CalendarEventProperties {
    return super.getEventProperties(event, isSelected);
  }

  getEventComponent(view: View, props: any) {
    switch (view) {
      case 'month':
        return <ActivityEventMonthComponent {...props} />;
      default:
        return <ActivityEventComponent {...props} />;
    }
  }

  getEventDetailsComponent(props: CalendarEventComponentProperties<any>) {
    return <ActivityEventDetailsComponent {...props} />;
  }
}

export class ActivityEventComponent extends DefaultEventComponent<ActivityOccuranceDocument> {
  protected get color(): string {
    return this.props.selected ? 'orange' : 'teal';
  }

  protected get borderStyle() {
    if (this.props.event.isPublished) return {};
    return { border: '2.4px dashed white' };
  }

  protected get content() {
    const { event } = this.props;
    return (
      <LocaleContext.Consumer>
        {({ t, tt }: AHLocaleContext) => (
          <div className="ui content" style={{ textAlign: 'left' }}>
            <h5 className="ui inverted header">
              <span style={{ fontSize: '0.75em' }}>{tt(event.title)}</span>
              <div className="sub header" style={{ fontSize: '0.7em' }}>
                {Moment(event.start).tz('Europe/Stockholm').format('HH:mm')} &mdash;{' '}
                {Moment(event.end).tz('Europe/Stockholm').format('HH:mm')}
              </div>
            </h5>
            <p style={{ fontSize: '0.8em', margin: 0 }}>
              {event.availableVisitors} {t('Available (plural)').toLowerCase()}
            </p>{' '}
            <p style={{ fontSize: '0.8em', margin: 0 }}>
              {event.neededStaffing
                ? `${event.workingStaff.length} / ${event.neededStaffing} `
                : `${event.workingStaff.length}`}{' '}
              {t('planned employees')}
            </p>
          </div>
        )}
      </LocaleContext.Consumer>
    );
  }
}

export class ActivityEventMonthComponent extends ActivityEventComponent {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  protected get borderStyle() {
    if (this.props.event.isPublished) return {};
    if (this.props.event.hasCustomerRequestedBooking) return {};
    return { border: '1.6px dashed white' };
  }

  protected get content() {
    const { event } = this.props;
    return (
      <div
        className="ui"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'hidden',
          fontSize: this.device.size == 'desktop' ? '1rem' : '0.8rem',
        }}
      >
        <span style={{ marginRight: '2px' }}>
          {Moment(this.props.event.start).tz('Europe/Stockholm').format('HH:mm')}
        </span>
        <span>
          {event.availableVisitors}
          {this.device.size !== 'mobile' && '/' + event.visitorCapacity}
        </span>
      </div>
    );
  }
}

export class ActivityEventDetailsComponent extends DefaultEventDetailsComponent<ActivityOccuranceDocument> {
  componentDidUpdate = () => {
    this.props.store.checkIfOccuranceIsTmeTicket();
  };

  componentDidMount = () => {
    this.props.store.checkIfOccuranceIsTmeTicket();
  };

  @Consume(LocaleContext)
  protected _locale: AHLocaleContext;

  forceRefreshComponent = () => {
    this.forceUpdate();
  };

  @action private onFinishMoveBooking = () => {
    Lodash.remove(this.props.event.bookings, (booking) => booking.id == (this.props.store.bookingToBeMoved as any).id);
    this.props.store.endMoveBookingProcess();
  };

  @action private onEndMoveBooking = () => {
    this.props.store.endMoveBookingProcess();
  };

  @computed protected get bookingInformation() {
    return (
      <LocaleContext.Consumer>
        {({ t }: AHLocaleContext) => {
          const event = this.props.store.selectedEvent as ActivityOccuranceDocument;
          const textSingle = t('Booking');
          const textMultiple = t('Bookings');
          if (event.bookings && event.bookings.length) {
            const output = event.bookings.length == 1 ? textSingle : textMultiple;
            return (
              <b>
                <i className="ticket icon" />
                {event.bookings.length} {output.toLowerCase()}
                <br />
              </b>
            );
          }
          return <span />;
        }}
      </LocaleContext.Consumer>
    );
  }

  @computed protected get visitorsInformation() {
    return (
      <LocaleContext.Consumer>
        {({ t }: AHLocaleContext) => {
          const event = this.props.store.selectedEvent as ActivityOccuranceDocument;
          const { availableVisitors, visitorCapacity } = event;

          const visitorsText =
            availableVisitors <= 0
              ? `${t('Fully booked')} (${availableVisitors}/${visitorCapacity} ${t('slots')})`
              : `${availableVisitors} ${t('of')} ${visitorCapacity} ${t('available slots')}`;

          return (
            <b>
              <i className="male icon" />
              &nbsp;{visitorsText}
              <br />
            </b>
          );
        }}
      </LocaleContext.Consumer>
    );
  }

  @computed protected get propertyInformation() {
    const { tt } = this._locale;
    const property = (this.props.store.selectedEvent as any).property as any;
    if (property && property.name) {
      return (
        <span>
          <i className="ui map pin icon" /> {tt(property.name)}
          <br />
        </span>
      );
    }
    return undefined;
  }

  private get ticketsTables() {
    return (
      <LocaleContext.Consumer>
        {({ t, tt }: AHLocaleContext) => {
          const event = this.props.store.selectedEvent as ActivityOccuranceDocument;
          if (!event.bookings?.length) return <span />;

          const joinedPriceCategories: Record<string, BookingPriceCategoryDocument> = {};
          event.bookings.forEach((booking) => {
            booking.priceCategories.forEach((category) => {
              if (!(tt(category.name) in joinedPriceCategories)) {
                joinedPriceCategories[tt(category.name)] = {
                  ...category,
                  tickets: 0,
                };
              }
              joinedPriceCategories[tt(category.name)].tickets += category.tickets;
            });
          });

          return <TicketsTables priceCategories={Object.values(joinedPriceCategories)} t={t} />;
        }}
      </LocaleContext.Consumer>
    );
  }

  @computed private get moveBookingContent() {
    const booking = this.props.store.bookingToBeMoved as any;
    const oldOccurance = this.props.store.occuranceToMoveBookingFrom as any;
    const selectedEvent = this.props.store.selectedEvent as any;

    return (
      <MoveBooking
        store={this.props.store}
        booking={booking}
        oldOccurance={oldOccurance}
        newOccurance={selectedEvent}
        onCompleted={this.onFinishMoveBooking}
        onEnded={this.onEndMoveBooking}
      />
    );
  }

  @computed private get assignEmployees() {
    return (
      <LocaleContext.Consumer>
        {({ t }: AHLocaleContext) => {
          const event = this.props.store.selectedEvent as ActivityOccuranceDocument;
          if ((this.globals.session.currentOrganization as OrganizationDocument).experimentalSövdeCalendar) {
            return (
              <div>
                <AssignEmployees event={event} forceRefresh={this.forceRefreshComponent} />
                <div className="ui fluid divider" />
                <div style={{ height: '2em' }} />
              </div>
            );
          }
          return (
            <Accordion
              fluid
              title={
                <a className="ui compact small basic fluid button" style={{ textAlign: 'left' }}>
                  <i className="dropdown icon" /> {t('Manage personnel')}
                </a>
              }
            >
              <AssignEmployees event={event} forceRefresh={this.forceRefreshComponent} />
              <div className="ui fluid divider" />
              <div style={{ height: '2em' }} />
            </Accordion>
          );
        }}
      </LocaleContext.Consumer>
    );
  }

  private get activityContent() {
    const { event, store } = this.props;
    const { isUserAllowedToEditEvent } = store;
    const { isPublished } = event;
    const isTmeTicket = this.props.store.isTmeTicket;

    return (
      <MarketplaceProvider>
        <LocaleContext.Consumer>
          {({ t }: AHLocaleContext) => {
            return (
              <div>
                <p>
                  {this.propertyInformation}
                  {!this.props.event.hasCustomerRequestedBooking && this.visitorsInformation}
                  <b>
                    <i className="users icon" />
                    &nbsp;
                    {event.neededStaffing
                      ? `${event.workingStaff.length} av ${event.neededStaffing} `
                      : `${event.workingStaff.length}`}{' '}
                    {t('planned employees')}
                    <br />
                  </b>
                  {this.bookingInformation}
                </p>
                {this.ticketsTables}
                <Maybe if="property.manager">
                  <div>
                    {isUserAllowedToEditEvent() && this.assignEmployees}
                    {!isTmeTicket && (
                      <Accordion
                        key={event.bookings.length}
                        fluid
                        title={
                          <a className="ui compact small basic fluid button" style={{ textAlign: 'left' }}>
                            <i className="dropdown icon" /> {t('Add a booking')}
                          </a>
                        }
                      >
                        <div style={{ height: '10px' }} />
                        <CreateManualBookingForm event={event} store={this.props.store} />
                        <div className="ui fluid divider" />
                        <div style={{ height: '50px' }} />
                      </Accordion>
                    )}
                    {isUserAllowedToEditEvent() && (
                      <Accordion
                        key={String(event.updatedAt)}
                        fluid
                        title={
                          <a className="ui compact small basic fluid button" style={{ textAlign: 'left' }}>
                            <i className="dropdown icon" /> {t('Edit')}
                          </a>
                        }
                      >
                        <EditActivityForm
                          key={event.id}
                          event={event}
                          store={this.props.store}
                          isPublished={isPublished}
                        />
                      </Accordion>
                    )}
                    {isUserAllowedToEditEvent() && !event.hasCustomerRequestedBooking && (
                      <PublishButton event={event} />
                    )}
                  </div>
                </Maybe>
              </div>
            );
          }}
        </LocaleContext.Consumer>
      </MarketplaceProvider>
    );
  }

  render() {
    return (
      <DefaultEventDetailsComponent {...(this.props as any)}>
        {this.props.children
          ? this.props.children
          : this.props.store.isInMoveBookingProcess
          ? this.moveBookingContent
          : this.activityContent}
      </DefaultEventDetailsComponent>
    );
  }
}
