import * as React from 'react';
import { useContext } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { DeviceProvider, Route, Step, Steps, Switch } from './_dependencies';
import { useLocale } from './_locales';
import { Maybe } from './copyFromAH/maybe';
import { ConfirmationStep, CustomerInfoStep, PaymentStep } from './orderSteps';
import { ActivitySelectionView, ActivityTypeSelectionView } from './selectionSteps';
import { ActivityShowcaseView } from './selectionSteps/activityShowcaseView';
import { GiftCardSelectionView } from './selectionSteps/giftcardSelectionView';
import { SessionContext } from './session.context';
import { WidgetDataProvider } from './widgetDataProvider';
import { WidgetServiceMessages } from './widgetServiceMessages';
import { StepId } from './widgetStep';
import WithCartActionButtons from './withCartActionButtons';

type Props = RouteComponentProps<{}>;

const BookingProcessViewClass = (props: Props) => {
  const widgetData = useContext(WidgetDataProvider.Context);
  const device = useContext(DeviceProvider.Context);
  const { t } = useLocale();
  const session = useContext(SessionContext);

  /**
   * We need this check in case the user doesn't use navigation to navigate back to a previous step.
   * We check the stepRoutes against the location pathname and if they match we go to that step
   * This fixes a lot of bugs with the cart and add to cart action buttons
   */
  /* useEffect(() => {
    const { pathname } = props.history.location;
    //If we reach the confirmation view and navigate back.
    if (pathname !== '/bekraeftelse') {
      if (!widgetData.selectedActivity && widgetData.selectedPurchaseType !== 'giftcards')
        widgetData.gotoStep('activitySelection');
      if (!widgetData.selectedGiftCardType && widgetData.selectedPurchaseType !== 'bookings')
        widgetData.gotoStep('giftcardSelection');
    }

    for (const step in widgetData.stepRoutes) {
      if (widgetData.stepRoutes[step] === pathname) {
        widgetData.gotoStep(step as StepId);
        return;
      }
    }
  }, [props.history.location.pathname]); */

  const renderWidgetStepContent = () => {
    const { stepRoutes, lastActiveStep } = widgetData;
    return (
      <Switch>
        <Route exact path={stepRoutes.activitySelection} component={ActivitySelectionView} />
        <Route exact path={stepRoutes.activityShowcase} component={ActivityShowcaseView} />
        <Route exact path={stepRoutes.activityTypeSelection} component={ActivityTypeSelectionView} />
        <Route exact path={stepRoutes.giftcardSelection} component={GiftCardSelectionView} />
        <Route exact path={stepRoutes.customerInfo} component={CustomerInfoStep} />
        <Route exact path={stepRoutes.payment} component={PaymentStep} />
        <Route exact path={stepRoutes.confirmation} component={ConfirmationStep} />
        <Redirect to={lastActiveStep().route} />
      </Switch>
    );
  };

  const renderNavigationBar = () => {
    // Hide the navigation bar on the activities showcase
    if (widgetData.selectedStepId == 'activityShowcase') {
      return null;
    }

    const isMobile = device.size == 'mobile';
    const steps = widgetData.steps;

    const stepsData: {
      title: any;
      icon: string;
      step: StepId;
      completed: boolean;
      disabled: boolean;
      active: boolean;
      route: string;
    }[] = [];

    const stepTitles = {
      activityShowcase: t('Select activity'),
      activityTypeSelection: t('Select activity'),
      giftcardSelection: t('Choose giftcard'),
      // bookingRequestSelection: t('Select time'),
      timeSelection: t('Select time'),
      activitySelection: t('Select time'),
      customerInfo: t('Contact'),
      payment: t('Payment'),
      confirmation: t('Confirmation'),
    };

    const stepIcons = {
      activityShowcase: 'left arrow',
      activityTypeSelection: 'star outline',
      activitySelection: 'wait',
      timeSelection: 'wait',
      giftcardSelection: 'wait',
      // bookingRequestSelection: 'wait',
      customerInfo: 'comments outline',
      payment: 'payment',
      confirmation: 'ticket',
    };

    for (const [id, step] of steps.entries()) {
      if (id == 'unintialized') {
        continue;
      }
      stepsData.push({
        title: stepTitles[id],
        icon: stepIcons[id],
        step: id,
        active: step.active,
        completed: step.completed,
        disabled: step.disabled,
        route: step.route,
      });
    }

    return (
      <Steps className={`${stepsData.length >= 5 ? 'five' : 'four'} unstackable mini top attached`}>
        {stepsData.map((stepData, index) => (
          <Step
            key={index}
            title={isMobile ? '' : stepData.title}
            isMobile={isMobile}
            icon={stepData.icon}
            onClick={() => widgetData.gotoStep(stepData.step)}
            active={stepData.active}
            completed={stepData.completed}
            disabled={stepData.disabled}
          />
        ))}
      </Steps>
    );
  };

  const renderActionButtons =
    widgetData.selectedStepId === 'activitySelection' ||
    widgetData.selectedStepId === 'giftcardSelection' ||
    widgetData.selectedStepId === 'activityShowcase' ||
    props.history.location.pathname === '/presentkort';

  return (
    <div style={{ padding: '.1rem' }}>
      <Maybe if="organization.manager" and={widgetData.allowSpecialSignedInPriviliges}>
        <div className="ui primary message" style={{ marginTop: '1em' }}>
          {t('widget.widgetContent.messageForAdmins')}
        </div>
      </Maybe>
      <WidgetServiceMessages />
      {renderNavigationBar()}
      <WithCartActionButtons user={session.currentUser} shouldRender={renderActionButtons}>
        {renderWidgetStepContent()}
      </WithCartActionButtons>
    </div>
  );
};

export const BookingProcessView = withRouter(BookingProcessViewClass);
