import Moment from 'moment-timezone';
import { Schema, Types } from 'mongoose';
import SchemaNames from '../schemas/names';
import { PriceCategoryDocument } from '../schemas/priceCategory';
import { MongooseDocument, MongooseResource } from '../_dependencies';

export interface ReservationDocument extends MongooseDocument {
  occurance: Types.ObjectId;
  expires: Date;
  reservedPriceCategories?: PriceCategoryDocument[];
}

export class ReservationResource extends MongooseResource<ReservationDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Reservation);

    this.setSchema({
      occurance: { type: Schema.Types.ObjectId, ref: SchemaNames.ActivityOccurance, required: true },
      expires: { type: Date, default: () => Moment().add(1, 'h').toDate() },
      reservedPriceCategories: [],
    });
  }
}
