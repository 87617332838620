import * as React from 'react';
import Stripe from 'stripe';
import {
  Field,
  Form,
  KosmicComponent,
  OrganizationDocument,
  OrganizationResource,
  SubmitButton,
  Consume,
  LocaleContext,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';

interface Props {
  nextStep: () => void;
  account: Stripe.Account;
}

interface State {
  checked: boolean;
}

export class TermsOfServiceForm extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  constructor(props: Props) {
    super(props);
    this.state = { checked: false };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = async (values, resolve, reject) => {
    try {
      if (!this.state.checked) {
        throw new Error('Checkbox is not checked');
      }
      const resource = new OrganizationResource();
      await resource.acceptToa();
      this.props.nextStep();
      resolve();
    } catch (err) {
      reject(err);
    }
  };

  render() {
    const { t } = this._locale;
    return (
      <Form onSubmit={this.onSubmit}>
        <h1>{t('Terms of service')}</h1>
        <p style={{ fontSize: '0.9em' }}>{t('By activating your account you agree...')}</p>
        <p>
          <a
            target="_blank"
            href="https://www.hiveandfive.se/policies/privacy?ref=www.adventurehero.se"
            rel="noopener noreferrer"
          >
            {t("Adventure Hero's terms of service")}
          </a>
        </p>
        <p>
          <a target="_blank" href="https://stripe.com/en-se/connect-account/legal" rel="noopener noreferrer">
            {t("Stripe's terms of service")}
          </a>
        </p>
        <Field style={{ marginTop: '2rem' }}>
          <Checkbox onChecked={(value) => this.setState({ checked: value })} />
        </Field>
        <SubmitButton className="primary">{t('Activate account')}</SubmitButton>
      </Form>
    );
  }
}

class Checkbox extends React.Component<{ onChecked: (v: boolean) => void }> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  state = {
    checked: false,
  };
  render() {
    const { t } = this._locale;
    const { checked } = this.state;
    return (
      <div className="ui checkbox">
        <input
          type="checkbox"
          checked={checked}
          name="acceptance"
          onClick={() => {
            this.setState({ checked: !checked });
            this.props.onChecked(!checked);
          }}
        />
        <label>{t('I accept the terms of service')}</label>
      </div>
    );
  }
}
