import { action, computed, observable, ObservableMap } from 'mobx';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { MobxComponent } from '../legacy/abstractComponent';
import { FormatRule } from './rules';

interface FormProps {
  /** A method called when the form is complete, valid and should be processed, contains two callbacks that can be used to confirm success or failure of the action */
  onSubmit?: (values: { [id: string]: any }, resolve?: (result?: any) => any, reject?: (error: any) => any) => void;
  /** Indicates if this form is currently performing a blocking action */ loading?: boolean;
  /** Indicates if this form is currently disabled from futher input */ disabled?: boolean;
}

interface State {
  onlyValidate: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  isValid: boolean;
}

type FormContext = {
  addValidationRules: (inputName: string, rules: FormatRule[], optionalField: boolean) => void;
  removeValidationRules: (inputName: string) => void;
  addValue: (inputName: string, value: any) => void;
  removeValue: (inputName: string) => void;
};

export const FormContext = createContext<FormContext>({
  addValidationRules: () => {},
  removeValidationRules: () => {},
  addValue: () => {},
  removeValue: () => {},
});

export const useValidation = (inputName: string, ...rules: FormatRule[]) => {
  const { removeValidationRules, removeValue, addValidationRules, addValue } = useContext(FormContext);

  // Remove all values from the form context when
  // the component unmounts.
  useEffect(() => {
    return () => removeValue(inputName);
  }, []);

  // Add and remove form validation fields to the form context
  useEffect(() => {
    if (rules.length < 1) return;

    addValidationRules(inputName, rules, false);
    return () => removeValidationRules(inputName);
  }, [inputName, rules]);

  return useCallback(
    (value: any) => {
      addValue(inputName, value);
    },
    [inputName],
  );
};

interface ValidationFields {
  identifier: string;
  rules: FormatRule[];
  optional: boolean;
}

/**
 * A Form component is used to wrap changable components (inputs)
 * and get a lot of information from the user all at once
 */
export class Form extends MobxComponent<FormProps, State> {
  public state: State = {
    onlyValidate: false,
    isDisabled: false,
    isLoading: false,
    isValid: true,
  };

  static childContextTypes: React.ValidationMap<any> = { form: PropTypes.instanceOf(Form) };
  protected getChildContext = (): { form: Form } => ({ form: this });
  private validationResolver?: (valid: boolean) => void;

  private _validationFields: { [name: string]: ValidationFields } = {};
  @observable private _values: ObservableMap<any> = new ObservableMap();

  /** Returns all values in this form as an observable map */
  @computed public get values(): ObservableMap<any> {
    return this._values;
  }

  /** Indicates if this form is currently performing an blocking action */
  public get loading(): boolean {
    return this.state.isLoading;
  }

  /** Indicates if this form is disabled from futher input */
  public get disabled(): boolean {
    return this.state.isDisabled;
  }

  /** Returns truthfully if this form is valid or not */
  public get valid(): boolean {
    return this.state.isValid;
  }

  /** Adds a value to the form, usually called internaly from input components that are members of this form */
  public addValue = (name: string, value: any): void => {
    this._values.set(name, value);
  };

  /** Removes a value from the form, usually called internaly from input components that are members of this form */
  public removeValue = (name: string): void => {
    this._values.delete(name);
  };

  /** Adds a validation rule to the form, usually called internaly from input components that are members of this form */
  public addValidationRules = (inputName: string, rules: FormatRule[], optionalField: boolean): void => {
    this._validationFields[inputName] = {
      identifier: inputName,
      rules: rules,
      optional: optionalField,
    };

    // Reinitiaze form validation
    this.initializeFormValidation();
  };

  /** Remove a validation rule from this form, usually called internaly from input components that are members of this form */
  public removeValidationRules = (inputName: string): void => {
    delete this._validationFields[inputName];

    // Reinitiaze form validation
    this.initializeFormValidation();
  };

  /** Clears all inputs in the form */
  public clearValues = () => {
    this._values.clear();
    this.domElement.form('clear' as any);
  };

  /** Validates the form fields without submitting the form */
  public validate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.validationResolver = resolve;
      this.setState({ onlyValidate: true });
    });
  }

  /** Called when the form is sucessfully validated and should be submited */
  @action
  private submitValidatedForm = async (event: React.FormEvent<HTMLFormElement>, fields) => {
    if (this.domElement.form('is valid') && !(this.loading || this.disabled) && !!this.props.onSubmit) {
      this.setState({ isLoading: true });

      await new Promise((resolve, reject) => {
        if (this.props.onSubmit) {
          this.props.onSubmit(this.values.toJS(), resolve, reject);
        }
      }).catch((err) => {
        this.domElement.transition('shake');
      });
      this.setState({ isLoading: false });
    }

    return this.handleSubmitEvent(event);
  };

  /** Called when the form is validated unsuccessfully */
  @action
  private handleInvalidForm = () => {
    this.focusInvalidInput();
    // this.domElement.transition('shake');
    this.setState({ isValid: false, onlyValidate: false });
    return this.handleSubmitEvent();
  };

  /** Called when the form has found errors in the fields */
  private focusInvalidInput = () => {
    try {
      const errors = $(this.domElement.form).find('.field.error');
      if (!errors.length) {
        return;
      }
      for (const error of errors) {
        // Opens closest accordion to error. Checks ancestors to determine correct accordion
        $(error).closest('.accordion').accordion('open', 0);
      }
      // Finds fields that has errors in jQuery. Could not use semantic "field is valid" as it only targeted direct field children...
      // Center and focus first input error as soon as thread is idle
      window.setTimeout(function () {
        try {
          const input = $(errors[0]).find('input')[0];
          input.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
          input.focus();
        } catch (err) {
          console.warn('[KOSMIC/FORM - focus + scroll to error]', err);
        }
      }, 0);
    } catch (err) {
      console.warn('[KOSMIC/FORM - open closest accordion]', err);
    }
  };

  /** Called on all form submition events, prevents unwanted submits and page reloads */
  private handleSubmitEvent = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    return false;
  };

  /** Used to initialize and update the form validation and semantic ui goodness */
  private initializeFormValidation() {
    (this.domElement.form('destroy' as any).form as any)({
      inline: true,
      transition: 'fade in',
      revalidate: true,
      keyboardShortcuts: true,
      fields: this._validationFields,
      onSuccess: (event: React.FormEvent<HTMLFormElement>, fields) => {
        if (this.state.onlyValidate && this.validationResolver) {
          this.setState({ onlyValidate: false, isValid: true });
          this.validationResolver(this.valid);
          delete this.validationResolver;
        }

        if (!event) return;
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isValid: true }, () => {
          this.submitValidatedForm(event, fields);
        });
      },
      onFailure: this.handleInvalidForm,
      onValid: () => this.setState({ isValid: this.domElement.form('is valid') }),
      onInvalid: () => this.setState({ isValid: false }),
    });
  }

  componentDidMount() {
    this.initializeFormValidation();
  }

  componentDidUpdate(_: FormProps, prevState: State) {
    this.initializeFormValidation();

    // Prevent possible jquery vs react race conditions by calling form validate from here...
    if (this.state.onlyValidate && !prevState.onlyValidate) {
      this.domElement.form('validate form');
    }
  }

  render() {
    const className =
      'ui form ' +
      (this.props.className ? this.props.className : '') +
      (this.disabled || this.loading || this.props.disabled || this.props.loading ? ' disabled' : '');

    return (
      <form style={this.props.style} onSubmit={this.handleSubmitEvent} className={className}>
        <FormContext.Provider
          value={{
            addValidationRules: this.addValidationRules.bind(this),
            removeValidationRules: this.removeValidationRules.bind(this),
            addValue: this.addValue.bind(this),
            removeValue: this.removeValue.bind(this),
          }}
        >
          {this.props.children}
        </FormContext.Provider>
      </form>
    );
  }
}

/** Default properties for form member components */
export interface FormMemberProps {
  /** A list of format rules that applies to this component */ rules?: FormatRule[];
  /** Indicates if this component is currently performing an blocking action */ loading?: boolean;
  /** Indicates if this component is currently disabled from futher input */ disabled?: boolean;
}

/**
 * A abstract component for components that can interact with an encapsulating form
 */
export abstract class FormMember<Props> extends MobxComponent<FormMemberProps & Props> {
  // Form members gets their reference to their form component from the react context
  static contextTypes = MobxComponent.extendContextTypes({ form: PropTypes.instanceOf(Form) });

  /** Indicates if currently performing a blocking action */
  @computed public get loading(): boolean {
    return this._loading;
  }

  /** Sets whenever this component is loading or not */
  @observable protected _loading: boolean;

  /** Indicates if currently disabled from futher changes */
  @computed public get disabled(): boolean {
    const res = true;

    return this._disabled || (!!this.form && (this.form.disabled || this.form.loading));
  }

  /** Sets whenever this component is disabled or not */
  @observable protected _disabled: boolean;

  /** Returns the form component that this button is a member of, if any */
  protected get form(): Form | undefined {
    if (this.context && (this.context as any).form) {
      return (this.context as any).form;
    }
    return undefined;
  }
}
