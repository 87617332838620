import Cookie from 'js-cookie';
import Languages from 'languages';
import LocaleEmoji from 'locale-emoji';
import Moment from 'moment-timezone';
import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { TranslatableText, tt as translateText } from './_dependencies';

export interface LocaleProviderProps<Collection extends Record<string, any>> {
  children: ReactNode;

  /** Known supported locales */
  supportedLocales: Locale[];
  /** The default/initial locale to use if unable to determine the user preference */
  initialLocale?: Locale;

  /** The collection to translate text with */
  collection: Collection;
}

/** Returned by getLocaleInfo to provid info about a given locale */
type LocaleInfo = {
  locale: Locale;
  name: string;
  englishName: string;
  flag: string;
};

export interface LocaleContextState<T extends Record<string, any>> {
  locale: Locale;
  setLocale: (locale: Locale) => void;
  getLocaleInfo: (locale?: Locale) => LocaleInfo;
  supportedLocales: Locale[];
  t: (id: keyof T) => string;
  tt: (text: string | TranslatableText | undefined) => string;
}

// Initial state used by the context.
const initialState: LocaleContextState<any> = {
  locale: 'en',
  supportedLocales: [],
  setLocale: (_: Locale) => {
    throw new Error('LocaleProvider not mounted');
  },
  getLocaleInfo: () => {
    throw new Error('LocaleProvider not mounted');
  },
  t: (_id: any) => {
    throw new Error('LocaleProvider not mounted');
  },
  tt: (_text: any) => {
    throw new Error('LocaleProvider not mounted');
  },
};
export const LocaleContext = createContext(initialState);

/**
 * Creates a provider that exposes and keeps track of locale data
 * on the client.
 */
export function LocaleProvider<T extends Record<string, any>>(props: LocaleProviderProps<T>) {
  // Try to dermine the clients language based on
  // the navigator object.
  const navigatorLanguage = ((): Locale | undefined => {
    // Make sure that the navigator object exists.
    // Neeeded due to SSR.
    if (typeof navigator == 'undefined') {
      return undefined;
    }

    type LanguageProperties = {
      browserLanguage?: string;
      systemLanguage?: string;
      userLanguage?: string;
    };

    type ExtendedNavigator = Navigator & LanguageProperties;

    const { language, browserLanguage, systemLanguage, userLanguage } = navigator as ExtendedNavigator;

    return (language || browserLanguage || systemLanguage || userLanguage) as Locale | undefined;
  })();

  // Try to dermine the language based from local storage
  // if client side
  const localStorageLocale = ((): Locale | undefined => {
    // Make sure that local storage exists in the current environemnt.
    if (typeof localStorage == 'undefined') {
      return undefined;
    }
    return JSON.parse(localStorage.getItem('language') || 'null');
  })();

  const navigatorLocale = navigatorLanguage?.split('-').shift() as Locale;
  const defaultLocale = props.initialLocale || navigatorLocale || initialState.locale;

  const [locale, setLocale] = useState(localStorageLocale || defaultLocale);

  // Moment needs to have it's locale updated during rendering, hence useMemo
  useMemo(() => {
    Moment.locale(locale);
  }, [locale]);

  // Save locale to cookie and LS whenever it changes
  useEffect(() => {
    Cookie.set('locale', locale);
    // Save to local storage if running in the browser
    if (MODULE_ENVIRONMENT == 'browser') {
      localStorage.setItem('language', JSON.stringify(locale));
    }
  }, [locale]);

  const getLocaleInfo = (value?: Locale): LocaleInfo => {
    const flag = LocaleEmoji(value || locale);
    const info = Languages.getLanguageInfo(value || locale);
    return {
      locale: value || locale,
      name: info.nativeName || '',
      englishName: info.name || '',
      flag: flag || '',
    };
  };

  /**
   * Translate
   * translates static content
   */
  const t = (id: keyof T) => {
    const translation = props.collection[id];
    const lang = locale;
    if (!translation) throw new Error(`Missing translation for: ${id.toString()}`);
    return translation[lang];
  };

  /**
   * Used to dynamically translate a TranslatableText object into a string based on the locale.
   * It even handles string and undefined values and will return an empty string if undefined was passed.
   **/
  const tt = (text: string | TranslatableText) => translateText(text, locale);

  const context: LocaleContextState<T> = {
    locale,
    setLocale,
    getLocaleInfo,
    t,
    tt,
    supportedLocales: props.supportedLocales,
  };

  return <LocaleContext.Provider value={context}>{props.children}</LocaleContext.Provider>;
}
