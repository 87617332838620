import { Schema } from 'mongoose';

import { AddressDocument, AddressSchema } from './address';

export interface CustomerDocument {
  firstname: string; //Fields must be optional if a
  lastname: string;
  businessName?: string;
  email: string;
  phoneNr?: string;
  address?: AddressDocument;
  /** VIRUAL FIELD */ fullname?: string;
  locale?: Locale;
}

// CustomerDocument type guard
export function isCustomerDocument(obj: any): obj is CustomerDocument {
  return (<CustomerDocument>obj).email !== undefined;
}

const CustomerSchemaa = new Schema(
  {
    firstname: String,
    lastname: String,
    businessName: String,
    email: String,
    phoneNr: { type: String, required: false },
    address: AddressSchema,
    locale: String,
  },
  { _id: false },
);
CustomerSchemaa.virtual('fullname').get(function () {
  return this.firstname + ' ' + this.lastname;
});

export const CustomerSchema = CustomerSchemaa;
