import Moment from 'moment-timezone';
import * as React from 'react';
import { Button, Component, Consume, Link, LocaleContext } from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { LanguageToggleIcons } from '../languageToggleIcons';

/** Customer footer Component */
export class CustomerFooter extends Component<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private renderFlags() {
    const { t } = this._locale;
    return (
      <div>
        <div className="header item">{t('components.customer.footer.headerFlags')}</div>
        <LanguageToggleIcons />
      </div>
    );
  }

  render() {
    const { t } = this._locale;
    return (
      <div>
        <div className="ui inverted vertical footer segment" style={{ backgroundColor: '#333333' }}>
          <div className="ui container">
            <div className="ui inverted divided equal height stackable grid">
              <div className="eight wide tablet computer only column">
                <div className="ui inverted list">
                  <div className="header item">
                    <h3>
                      {t('components.customer.footer.headerOne')}
                      <br />
                      {t('components.customer.footer.headerTwo')}
                    </h3>
                  </div>
                  <div className="item">
                    <Link to="/sign-up">
                      <Button className="primary">{t('Join us')}</Button>
                    </Link>
                  </div>
                </div>
                {this.renderFlags()}
              </div>
              <div className="eight wide mobile only column">
                <h4 className="ui inverted header">
                  {t('components.customer.footer.headerOne')}
                  <br />
                  {t('components.customer.footer.headerTwo')}
                </h4>
                {this.renderFlags()}
              </div>
              <div className="eight wide column">
                <h4 className="ui inverted header">{t('components.customer.footer.headerFour')}</h4>
                <div className="ui two column grid">
                  <div className="column">
                    <div className="ui inverted list">
                      <div className="item" />
                      <div className="item">
                        <i className="write icon"></i>
                        <div className="content">
                          <a href="http://blogg.adventurehero.se/" target="_blank" rel="noreferrer">
                            {' '}
                            <b>{t('components.customer.footer.blog')}</b>
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <i className="facebook icon"></i>
                        <div className="content">
                          <a href="https://www.facebook.com/adventurehero.se/" target="_blank" rel="noreferrer">
                            {' '}
                            <b>Facebook</b>
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <i className="instagram icon"></i>
                        <div className="content">
                          <a href="https://www.instagram.com/adventurehero_go/" target="_blank" rel="noreferrer">
                            {' '}
                            <b>Instagram</b>
                          </a>
                        </div>
                      </div>
                      <div className="item" />
                      <div className="item" />
                      <div className="item">
                        <i className="mail icon"></i>
                        <div className="content">
                          <a href="mailto:info@adventurehero.se">info@adventurehero.se</a>
                        </div>
                      </div>
                      <div className="item">
                        <i className="history icon"></i>
                        <div className="content">08:00 - 17:00</div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="ui inverted list">
                      <div className="item" />
                      <div className="item">{t('components.customer.footer.itemEtt')}</div>
                      <div className="item">Skaraborgsvägen 3A</div>
                      <div className="item">506 30 Borås</div>
                      <div className="item" />
                      <div className="item">{t('components.customer.footer.itemTwo')}</div>
                      <div className="item">BOX 8000</div>
                      <div className="item">501 18 Borås</div>
                    </div>
                    <div className="ui inverted list">
                      <div className="item" />
                      <div className="item">{t('components.customer.footer.itemGDPR')}</div>
                      <div className="item">
                        <a
                          href="https://www.hiveandfive.se/policies/privacy?ref=www.adventurehero.se"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('components.customer.footer.itemGDPRLink')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ui black inverted vertical footer segment">
          <div className="ui container" style={{ textAlign: 'center' }}>
            <img
              className="ui fluid centered image"
              src="/static/platform/img/logo_inverted.png"
              style={{ maxWidth: '100px' }}
            />
            <br />
            <div className="item">Copyright © {Moment().format('YYYY')} All Rights Reserved by Adventure Hero</div>
            <div className="item">
              {t('components.customer.footer.descriptionSix')}{' '}
              <a href="http://www.hiveandfive.se" target="_blank" rel="noreferrer">
                @HiveAndFive
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
