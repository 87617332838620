import React, { useEffect, useState } from 'react';
import { ActivityOccuranceDocument, PropertyResource, PropertyDocument } from '../../_dependencies';

interface Props {
  event: ActivityOccuranceDocument;
}

const CrossSellingIcon = ({ event }: Props) => {
  const [show, setShow] = useState(false);

  const showIcon = async () => {
    try {
      const hasCrossSellingProperty = event.bookings.some((booking) => booking.crossSellingProperty);

      if (!hasCrossSellingProperty) return;

      const propertyList = await new PropertyResource().getAdministratedProperties();
      const propertyIds = propertyList.map((property) => property.id);
      const property = event.property as unknown as PropertyDocument;

      event.bookings.forEach((booking) => {
        if (booking.crossSellingProperty) {
          if (
            propertyIds.includes(String(booking.crossSellingProperty.id)) ||
            propertyIds.includes(String(property.id))
          ) {
            setShow(true);
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    showIcon();
  }, []);

  return show ? <img src={'/static/commons/svg/crossSellingIconLight.svg'} alt="cross-selling" /> : null;
};

export default CrossSellingIcon;
