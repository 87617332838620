// Entry point for the marketplace-function client library

import {
  ActivityOccuranceDocument,
  ActivityTypeDocument,
  BookingDocument,
  CustomerDocument,
  GiftCardDocument,
} from '../..';
import { Order, OrderApi } from './orders';
import { Payment, PaymentApi } from './payments';
import { Product, ProductApi } from './products';
import { Refund, RefundApi } from './refund';
import { makeFetcher } from './utilities';

export * from './cardDetails';
export * from './manualDetails';
export * from './models';
export * from './orders';
export * from './payments';
export * from './payments.types';
export * from './products';
export * from './refund';

type MarketplaceApi = {
  orders: OrderApi;
  products: ProductApi;
  payments: PaymentApi;
  refunds: RefundApi;
  // coupons
};

export type CartItem = CartBooking | CartGiftcard;

export type CartGiftcard = Omit<GiftCardDocument, 'customer'> & {
  customer?: CustomerDocument;
};

/**
 * Optional fields
 * - customer (BookingDocument)
 *
 * Populated fields
 * - occurance (BookingDocument)
 * - originatingActivity (ActivityOccuranceDocument)
 */
export type CartBooking = Omit<BookingDocument, 'occurance' | 'customer'> & {
  occurance: PopulatedOccurence;
  customer?: CustomerDocument;
};

export type PopulatedOccurence = Omit<ActivityOccuranceDocument, 'originatingActivity'> & {
  originatingActivity: ActivityTypeDocument;
};

export const Marketplace = (): MarketplaceApi => {
  const apiKey = process.env.MARKETPLACE_KEY || 'cc1LK3ZPZ1XjOAhs';
  const mode = MODULE_RUNTIME_TIER == 'master' ? 'prod' : 'dev';

  const fetcher = makeFetcher(apiKey, mode);
  return {
    orders: Order(fetcher),
    products: Product(fetcher),
    payments: Payment(fetcher),
    refunds: Refund(fetcher),
  };
};
