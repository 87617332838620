import { range } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DropdownField } from '.';
import { BookingPriceCategoryDocument, DropdownItem, Field, PriceCategoryDocument } from '../../../_dependencies';
import { useLocale } from '../../../_locales';

interface Props {
  selectedPriceCategories: BookingPriceCategoryDocument[];
  bookedPriceCategories?: BookingPriceCategoryDocument[];
  handleClientBelongingsPriceCategories: PriceCategoryDocument[];
  onChange: (tickets: number, priceCategoy: PriceCategoryDocument) => void;
  disabled: boolean;
}

export const HandleClientBelongingsDropdowns = ({
  selectedPriceCategories,
  handleClientBelongingsPriceCategories,
  onChange,
  disabled,
  bookedPriceCategories,
}: Props) => {
  const { t } = useLocale();
  const [clientBelongingPriceCategory, setClientBelongingPriceCategory] = useState<PriceCategoryDocument | undefined>();

  useEffect(() => {
    //Currently only supports on clientBelongingsPriceCategory at a time.
    setClientBelongingPriceCategory(handleClientBelongingsPriceCategories[0]);
  }, []);

  const dropdownItems = range(100).map((i) => <DropdownItem key={i} value={i} />);

  if (!clientBelongingPriceCategory) return null;

  return (
    <Field
      style={{ marginTop: '1.5rem' }}
      label={
        <>
          <span style={{ fontSize: '1.4rem', fontWeight: 'normal' }}>
            {bookedPriceCategories ? t('Edit bags') : t('Add bags')}
          </span>
          <br></br>
          <span style={{ fontSize: '1rem', fontWeight: 'normal', lineHeight: '2rem' }}>
            {t('We take care of your bags and transport them for you during the journey.')}
          </span>
        </>
      }
    >
      <DropdownField
        name={'bag'}
        selectedTickets={getSelectedTickets(selectedPriceCategories, clientBelongingPriceCategory) || 0}
        onChange={(ticket) => onChange(Number(ticket), clientBelongingPriceCategory)}
        isInceaseButtonEnabled={true}
        setVisitorsFreely={false}
        priceCategory={{ price: 0, name: t('Number of bags'), groupMultiplier: 0 }}
        dropdownItems={dropdownItems}
        disabled={disabled}
        overrideSubtitleLabel={null}
      />
    </Field>
  );
};

function getSelectedTickets(
  selectedPriceCategories: BookingPriceCategoryDocument[],
  clientBelongingPriceCategory: PriceCategoryDocument,
): number | undefined {
  return selectedPriceCategories.find((p) => p.id === clientBelongingPriceCategory.id)?.tickets;
}
