import Lodash from 'lodash';
import * as React from 'react';
import { Consume, DeviceProvider, IDevice, KosmicComponent } from './_dependencies';
import { IWidgetData, WidgetDataProvider } from './widgetDataProvider';
import { ServiceMessageDocument, ServiceMessageResource } from './_dependencies';
import { globalCurrentLocale } from './_locales';

interface State {
  /** Contains any loaded widget service messages */
  messages: ServiceMessageDocument[];
}

/** Used to display service messages in the widget */
export class WidgetServiceMessages extends KosmicComponent<{}, State> {
  @Consume(WidgetDataProvider.Context)
  private widgetData: IWidgetData;

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  public state: State = {
    messages: [],
  };
  private getTrailingClassName(severity: number) {
    switch (severity) {
      case 0:
        return 'info';
      case 1:
        return 'info large';
      case 2:
        return 'info big';
      case 3:
        return 'info huge';
      case 4:
        return 'warning';
      case 5:
        return 'warning large';
      case 6:
        return 'warning big';
      case 7:
        return 'warning huge';
      case 8:
        return 'negative';
      case 9:
        return 'negative large';
      case 10:
        return 'negative big';
      case 11:
        return 'negative huge';
      default:
        return 'info';
    }
  }

  private getTranslationFromMessage = (message: ServiceMessageDocument) => {
    if (globalCurrentLocale() && message.translations) {
      return message.translations[globalCurrentLocale()] || message.html;
    }
    return message.html;
  };

  getWidgetVersion(widgetVersion: number): number {
    if (this.widgetData.root) {
      return 3;
    } else if (MODULE_IS_EMBEDDABLE) {
      return 2;
    } else {
      try {
        if (window.self !== window.top) {
          return 1;
        }
      } catch {
        return 1;
      }
    }
    return 0;
  }

  isVisibleInWidget(widgetVersion: number): boolean {
    const widget = this.getWidgetVersion(widgetVersion);
    if (widget > widgetVersion && widget != 0) {
      return false;
    }
    return true;
  }

  private filterMessage = (message: ServiceMessageDocument) => {
    const { when, widget } = message;

    if (!when.length) {
      return true;
    }

    if (!this.isVisibleInWidget(widget)) {
      return false;
    }

    const targets: string[] = [];
    if (this.device) {
      targets.push(this.device.os.toLowerCase());
      targets.push(this.device.browser.toLowerCase());
      if (this.device.isMobileBrowser) {
        targets.push('mobile');
      }
    }

    if (this.widgetData) {
      if (this.widgetData.selectedOrganization) {
        targets.push(this.widgetData.selectedOrganization.id);
      }
      if (this.widgetData.selectedProperty) {
        targets.push(this.widgetData.selectedProperty.id);
      }
      if (this.widgetData.selectedActivityType) {
        targets.push(this.widgetData.selectedActivityType.id);
      }
      if (this.widgetData.selectedGiftCardType) {
        targets.push(this.widgetData.selectedGiftCardType.id);
      }
    }
    const difference = Lodash.difference(when, targets);
    return difference.length == 0;
  };

  public async componentDidMount() {
    const messages = await new ServiceMessageResource().find({ widget: true, hidden: false });
    this.setState({
      messages,
    });
  }

  render() {
    let { messages } = this.state;

    messages = messages.filter(this.filterMessage);

    if (!messages.length) {
      return null;
    }

    return (
      <div style={{ padding: '1em' }}>
        {messages.map((message) => {
          const __html = this.getTranslationFromMessage(message);

          const className = 'ui floating icon message ' + this.getTrailingClassName(message.severity);
          return (
            <div className={className} key={message.id}>
              <i className="info circle icon"></i>
              <div className="content" dangerouslySetInnerHTML={{ __html }} />
            </div>
          );
        })}
      </div>
    );
  }
}
