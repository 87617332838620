import { action, computed, observable } from 'mobx';
import * as React from 'react';
import { GridFsDocument, GridFsResource } from '../../_dependencies';
import { MobxComponent, ProgressBar } from '../legacy';
import { Form } from './form';

/**
 * A File Form component is used to upload single or multiple files from a client to the database,
 * they are not compitable with other forms
 */
interface Props {
  /** The grid fs resource to upload the file to */ resource: GridFsResource<GridFsDocument>;
  /** Indicates if this form is currently performing a blocking action */ loading?: boolean;
  /** Indicates if this form is currently disabled from futher input */ disabled?: boolean;
  /** Use this function to save additional data when the file has been saved */ onFileSaved?: (
    fileId,
    values,
    resolve,
    reject,
  ) => void;
  /** This prop can be used to add a file instead of using the built in functions */ fileToUpload?: {
    fileName: string;
    blob: Blob;
  };
  /** if this props is true the on submit will be called when fileToUpload is set, this wont work with form content, like input fields... */ submitAndUploadImage?: boolean;
  /** A ref to the form that can be used to trigger functions like validate and more */ formRef?: React.Ref<Form>;
}
export class FileUploadForm extends MobxComponent<Props> {
  @observable private _loading = false;
  @observable private _disabled = false;
  @observable private _uploading: number;
  @observable private _uploadProgress: number;
  private _fileUpload: HTMLInputElement | null;
  private _progressBar: ProgressBar | null;

  componentDidUpdate(prevProps: Props) {
    if (this.props.submitAndUploadImage && !prevProps.submitAndUploadImage) {
      this.submitForm(
        {},
        () => {},
        () => {},
      ); // TODO: this wont work with form content, like input fields...
    }
  }

  /** Indicates if this form is currently performing an blocking action */
  public get loading(): boolean {
    return this.uploading || this._loading || !!this.props.loading;
  }

  /** Indicates if this form is disabled from futher input */
  public get disabled(): boolean {
    return this._disabled || !!this.props.disabled;
  }

  /** Indicates if this form is performing an upload */
  @computed public get uploading(): boolean {
    return !!this._uploadProgress && !(this._uploadProgress <= 0 || this._uploadProgress >= 100);
  }

  /** Called when the form is sucessfully validated and ok be submited */
  @action
  private submitForm = async (values, resolve, reject) => {
    if (this.domElement.form('is valid') && !(this.loading || this.disabled)) {
      this._loading = true;

      const formData = new FormData(this.domElement[0] as HTMLFormElement);
      if (this.props.fileToUpload) {
        formData.append('file', this.props.fileToUpload.blob, this.props.fileToUpload.fileName);
      }

      // TODO: only try to upload if there is a file to upload!
      await this.props.resource.upload(formData, this.handleProgress).then(
        (result) => {
          if (this.props.onFileSaved) {
            this.props.onFileSaved(result, values, resolve, reject);
          }
          return null;
        },
        (err) => {
          console.error('FAIL', err);
          reject(err);
        },
      );
      this._loading = false;
    }
  };

  /** Called when the selected file is changed, TODO: file validation can be done here */
  private handleChangeEvent = () => {
    //const file = this._fileUpload.files[0]
  };

  /** TODO */
  @action private handleProgress = (loaded: number, total: number) => {
    if (this._progressBar) {
      this._progressBar.setTotal(total);
      this._progressBar.setAmount(loaded);
    }
  };

  /** Renders a file input, or any supplied child components */
  private get contents(): React.ReactNode {
    if (this.props.children) {
      return this.props.children;
    }

    if (this.loading) {
      return (
        <ProgressBar
          ref={(ref) => (this._progressBar = ref)}
          messages={{
            percent: 'Lol at, {percent} %',
          }}
        />
      );
    }
    return (
      <span>
        <input type="file" name="file" onChange={this.handleChangeEvent} ref={(ref) => (this._fileUpload = ref)} />
        <br />
        <input type="submit" value="upload" />
      </span>
    );
  }

  render() {
    return (
      <Form
        ref={this.props.formRef}
        style={this.props.style}
        className={this.props.className}
        disabled={this.disabled}
        loading={this.loading}
        onSubmit={this.submitForm}
      >
        {this.contents}
      </Form>
    );
  }
}
