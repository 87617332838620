import React, { useContext } from 'react';
import {
  DeviceProvider,
  InventoryEntityDocument,
  InventoryStatusDocument,
  PlaceDocument,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { renderPlace } from '../inventoryItemStatusForm';
interface inventoryItemProps {
  places?: PlaceDocument[];
  entity: InventoryEntityDocument;
  groupTitle?: string;
  updatedStatus?: InventoryStatusDocument;
  bookingNumber?: string;
}

export const InventoryItemInformationSegment = ({
  updatedStatus,
  entity,
  groupTitle,
  bookingNumber,
  places,
}: inventoryItemProps) => {
  const { t, tt } = useLocale();
  const device = useContext(DeviceProvider.Context);

  const renderIcon = (icon: string) => {
    return (
      <td className="collapsing" style={{ padding: '0 1rem', backgroundColor: 'rgba(34,36,38,.1)' }}>
        <strong>
          <i style={{ margin: '0' }} className={`${icon} icon`} />
        </strong>
      </td>
    );
  };

  if (!entity || !groupTitle) {
    return (
      <div className="ui segment" style={segmentStyle(device.size === 'mobile')}>
        <p>{'Preparing ...'}</p>
        <div className="ui active inverted dimmer">
          <div className="ui loader"></div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ marginBottom: '1rem', backgroundColor: '#FFF', borderRadius: '0.5em' }}>
      <table
        className="ui very basic unstackable celled table"
        style={{ border: '1px solid rgba(34,36,38,.1)', padding: '0 .5rem 0 0', borderRadius: '0.5em' }}
      >
        <tbody>
          <tr>
            {renderIcon('tag')}
            <td>
              <strong style={{ fontSize: '1.03em' }}>{t('Title')}: </strong>
              {entity.title}
            </td>
          </tr>
          <tr>
            {renderIcon('question circle outline')}
            <td>
              <strong>{t('Status')}: </strong>
              {updatedStatus ? t(updatedStatus.status) : t(entity.lastStatus.status)}
            </td>
          </tr>
          <tr>
            {renderIcon('map pin')}
            <td>
              <strong>{t('Location')}: </strong>
              {tt(renderPlace(places, entity, updatedStatus)?.name) || 'none'}
            </td>
          </tr>
          <tr>
            {renderIcon('shopping bag')}
            <td>
              <strong>{t('Inventory')}: </strong>
              {groupTitle}
            </td>
          </tr>
          {!bookingNumber || entity.lastStatus.status === 'available' ? null : (
            <tr>
              {renderIcon('bookmark')}
              <td>
                <strong>{t('Booking')} nr: </strong>
                {bookingNumber}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const segmentStyle = (isMobile: boolean): React.CSSProperties => ({
  flex: 1,
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
});
