import * as Mongoose from 'mongoose';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserDocument, UserResource } from './_dependencies';

export interface DefaultUserDocument extends UserDocument {
  organization: any;
  roles: any[];
}
export interface SessionContext {
  authenticate: () => void;
  currentUser: DefaultUserDocument | undefined;
  userHasRole: (requiredRole: any, strict?: boolean, serverUser?: UserDocument) => boolean;
  getCurrentOrganizationId: () => Mongoose.Types.ObjectId;
}

interface Props extends RouteComponentProps {
  children: React.ReactNode;
}

export const SessionContext = createContext<SessionContext>({} as any);

const Session = (props: Props) => {
  const [currentUser, setCurrentUser] = useState<DefaultUserDocument>();
  const userResource = new UserResource();

  const fetchLoggedInUser = useCallback(async () => {
    const user = await userResource.getCurrentUser();
    setCurrentUser(user || undefined);
  }, []);

  useEffect(() => {
    fetchLoggedInUser();
  }, []);

  const authenticate = () => fetchLoggedInUser();

  const userHasRole = (requiredRole: any, strict?: boolean, serverUser?: UserDocument): boolean => {
    if (MODULE_ENVIRONMENT == 'node') {
      return !!serverUser && userResource.documentHasRole(serverUser, requiredRole, strict);
    } else {
      if (!currentUser) {
        return false;
      }
      return userResource.documentHasRole(currentUser, requiredRole, strict);
    }
  };

  const getCurrentOrganizationId = (): Mongoose.Types.ObjectId => {
    if (currentUser?.organization && currentUser?.organization._id) {
      return currentUser?.organization._id;
    }
    if (currentUser?.organization && !currentUser?.organization._id) {
      return currentUser?.organization;
    }
    return 'missing' as any;
  };

  return (
    <SessionContext.Provider
      value={{
        authenticate,
        currentUser,
        userHasRole,
        getCurrentOrganizationId,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export const SessionProvider = withRouter(Session);
export const useSession = () => useContext(SessionContext);
