import { ActivityOccuranceDocument, ActivityTypeDocument } from '../_dependencies';
import { PopulatedOccurence } from './activitySelectionView';

export const selectionListContainsTmeTicket = (
  selectedActivityType: ActivityTypeDocument | undefined,
  availableActivities: ActivityOccuranceDocument[] | undefined,
) => {
  if (selectedActivityType) {
    return selectedActivityType.isTmeTicket;
  } else {
    const activities = availableActivities as unknown as PopulatedOccurence[];
    if (activities.length) {
      return activities[0].originatingActivity.isTmeTicket;
    }
  }
};

export const parseToEnglish = (dateString: string) => {
  const monthYear = dateString.split(' ');
  const month = monthYear[0];
  const year = monthYear[1];

  if (month === 'januari' || month === 'Januar') return `January ${year}`;
  if (month === 'febrauri' || month === 'Februar') return `February ${year}`;
  if (month === 'mars' || month === 'März') return `March ${year}`;
  if (month === 'april') return `April ${year}`;
  if (month === 'maj' || month === 'Mai') return `May ${year}`;
  if (month === 'juni' || month === 'Juni') return `June ${year}`;
  if (month === 'juli' || month === 'Juli') return `July ${year}`;
  if (month === 'augusti') return `August ${year}`;
  if (month === 'september') return `September ${year}`;
  if (month === 'oktober' || month === 'Oktober') return `October ${year}`;
  if (month === 'november') return `November ${year}`;
  if (month === 'december' || month === 'Dezember') return `December ${year}`;

  return dateString;
};
