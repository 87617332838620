import * as React from 'react';
interface Props {
  isLoading: boolean;
  alwaysLoad?: boolean;
  color: string;
  time: number;
  nrOfDotts?: number;
  width?: string;
}
interface DotProps extends Props {
  delay: number;
}

interface State {
  isLoading: boolean;
  timeout?: any;
}

class Dot extends React.Component<DotProps> {
  el: HTMLDivElement;
  componentDidMount() {
    if (!('animate' in this.el)) return;
    this.el.animate(
      [
        { opacity: 0.2, transform: 'scale(.1)' },
        { opacity: 1, transform: 'scale(1)' },
        { opacity: 0.2, transform: 'scale(.1)' },
      ],
      {
        duration: this.props.time,
        iterations: Infinity,
        easing: 'ease-in-out',
        delay: (this.props.time / (this.props.nrOfDotts || 5)) * this.props.delay,
      },
    );
  }

  render() {
    return (
      <div
        ref={(el) => (this.el = el!)}
        style={{
          opacity: 0,
          background: this.props.color,
          borderRadius: '100%',
          width: `calc(100% / ${this.props.nrOfDotts || 5})`,
          paddingTop: `calc(100% / ${this.props.nrOfDotts || 5})`,
        }}
      />
    );
  }
}
export class Loader extends React.Component<Props, State> {
  state: State = {
    isLoading: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isLoading && this.props.isLoading) {
      this.setState({ isLoading: true });
      clearTimeout(this.state.timeout);
    }
    if (prevProps.isLoading && !this.props.isLoading) {
      const timeout = setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1000);
      this.setState({ timeout });
    }
  }

  fade() {
    if (!this.props.isLoading) return false;
    return this.state.isLoading;
  }

  render() {
    const generateDotts = () => {
      const dotts: string[] = [];
      for (let i = 0; i < (this.props.nrOfDotts || 5); i++) dotts.push('');
      return dotts.map((x, i) => <Dot key={i} {...this.props} delay={i} />);
    };

    const containerStyle: React.CSSProperties = this.props.alwaysLoad
      ? dottsContainerStyle(this.props.width)
      : { ...dottsContainerStyle, ...fadeContainer(this.state.isLoading) };

    return <div style={containerStyle}>{generateDotts()}</div>;
  }
}

const dottsContainerStyle = (width: string | undefined) => {
  return {
    width: width ? width : '100%',
    padding: '.5rem',
    borderRadius: '50rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  };
};

const fadeContainer = (fade: boolean) => {
  return {
    display: 'flex',
    transition: '.3s ease',
    opacity: fade ? 1 : 0,
  };
};
