import { Schema } from 'mongoose';

export interface AreaDocument {
  city?: string;
  zipCode?: string;
  mapArea?: any; //TODO: add via google maps in the future...
}

export const AreaSchema = new Schema(
  {
    city: { type: String, required: false },
    zipCode: { type: String, required: false },
  },
  { _id: false },
);
