import Moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { CalendarStore } from '..';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  CalendarOccuranceDocument,
  Dropdown,
  DropdownItem,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';

interface Props {
  store: CalendarStore;
  propertyOccurancesOnly: boolean;
  propertyId?: string;
}

const OccuranceDropdown = ({ propertyOccurancesOnly, propertyId, store }: Props) => {
  const { t } = useLocale();

  const [occurances, setOccurances] = useState<ActivityOccuranceDocument[]>([]);

  async function getOccurances() {
    try {
      let occurances: ActivityOccuranceDocument[] = [];
      const start = Moment().toDate();
      const end = Moment().add(1, 'years').toDate();

      if (propertyOccurancesOnly) {
        occurances = await new ActivityOccuranceResource().getPropertyOccurances(start, end, propertyId);
      } else {
        occurances = await new ActivityOccuranceResource().getOrganizationAndCrossSellingActivities(start, end);
      }

      setOccurances(occurances);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getOccurances();
  }, []);

  const handleOnSelect = (selection: string) => {
    if (!selection) {
      return;
    }

    const occurance = occurances.find((occurance) => occurance.id === selection);
    store.selectEvent(occurance as CalendarOccuranceDocument);
  };

  const sortedOccurances = () => occurances.sort((a, b) => (a.start > b.start ? 1 : -1));

  return (
    <Dropdown
      searchable
      fluid
      key="occuranceDropdown"
      name="Välj en aktivitet"
      placeholder={t('choose-type-of-offer')}
      loading={false}
      onChange={handleOnSelect}
      value={store.selectedEvent.id}
    >
      {sortedOccurances().map((occurance) => {
        const date = Moment(occurance.start).format('DD/MM-YYYY hh:mm');
        return (
          <DropdownItem key={occurance.id} value={occurance.id}>
            {`${date} ${occurance.title} (${occurance.availableVisitors} ${t('slots left')})`}
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
};
export default OccuranceDropdown;
