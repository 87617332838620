import Moment from 'moment-timezone';
import * as React from 'react';

import { CalendarOccuranceDocument, Consume, LocaleContext, MobxComponent } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';

import {
  CalendarEventComponentProperties,
  CalendarEventProperties,
  EventComponentCollectionInterface,
  View,
} from '../utils/types';

export class DefaultEventComponentCollection implements EventComponentCollectionInterface<CalendarOccuranceDocument> {
  getEventProperties(event: CalendarOccuranceDocument, isSelected: boolean): CalendarEventProperties {
    const results: CalendarEventProperties = {
      style: {
        background: 'white',
        color: 'black',
        wordWrap: 'break-word',
        padding: '0px !important',
        marginTop: '0em !important',
        marginLeft: '1px !important',
      },
      className: 'ui fluid link card',
    };

    if (isSelected) {
      results.style.zIndex = 10;
      results.className += 'ui raised fluid link card';
    }

    return results;
  }

  getEventComponent(view: View, props: CalendarEventComponentProperties<any>) {
    return <DefaultEventComponent {...props} />;
  }

  getEventDetailsComponent(props: CalendarEventComponentProperties<any>) {
    return <DefaultEventDetailsComponent {...props} />;
  }
}

export class DefaultEventComponent<T extends CalendarOccuranceDocument> extends MobxComponent<
  CalendarEventComponentProperties<T>
> {
  private get isOldEvent() {
    return Moment(this.props.event.start).isBefore(new Date());
  }

  protected get color(): string {
    return 'orange';
  }

  private get defaultStyle(): React.CSSProperties {
    return {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      padding: '2px',
      minHeight: '18px',
    };
  }

  protected get borderStyle(): React.CSSProperties {
    return {};
  }

  protected get content() {
    return (
      <div className="ui content" style={{ textAlign: 'left', padding: '0.4em' }}>
        <h5 className="ui inverted header">
          <div className="sub header" style={{ fontSize: '0.85em' }}>
            {Moment(this.props.event.start).tz('Europe/Stockholm').format('HH:mm')}&mdash;{' '}
            {Moment(this.props.event.end).tz('Europe/Stockholm').format('HH:mm')}
          </div>
        </h5>
      </div>
    );
  }

  render() {
    const color =
      this.isOldEvent && !this.props.selected
        ? 'tertiary'
        : this.props.event.hasCustomerRequestedBooking && !this.props.selected
        ? 'olive'
        : this.color;

    return (
      <div className={'ui ' + color + ' inverted segment'} style={{ ...this.defaultStyle, ...this.borderStyle }}>
        {this.content}
      </div>
    );
  }
}

export class DefaultEventDetailsComponent<T extends CalendarOccuranceDocument> extends MobxComponent<
  CalendarEventComponentProperties<T>
> {
  @Consume(LocaleContext)
  protected _locale: AHLocaleContext;

  render() {
    const { t, tt } = this._locale;
    const { selectedEvent } = this.props.store;

    return (
      <div className="ui content">
        {selectedEvent.hasCustomerRequestedBooking && (
          <a style={{ marginBottom: '1rem' }} className="ui olive ribbon label">
            {t('Booking request')}
          </a>
        )}
        <div className="ui grid" style={{ minHeight: '200px' }}>
          <div className="twelve wide column">
            <h2 className="ui orange header" style={{ float: 'left' }}>
              {tt(selectedEvent.title)}
              <div className="sub header">
                <span style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                  {Moment(selectedEvent.start).tz('Europe/Stockholm').format('HH:mm')}
                  &nbsp;&mdash;&nbsp;{Moment(selectedEvent.end).tz('Europe/Stockholm').format('HH:mm')}
                </span>
                <span style={{ whiteSpace: 'nowrap', paddingLeft: '1em', textAlign: 'right' }}>
                  {Moment(selectedEvent.start).tz('Europe/Stockholm').format('ddd DD/MM')}
                </span>
              </div>
            </h2>
          </div>
          <div className="four wide column">
            <button
              onClick={this.props.store.deselectEvent}
              className="ui tiny compact circular orange icon button"
              style={{ float: 'right', width: '30px', height: '30px' }}
            >
              <i className="close icon" />
            </button>
          </div>
          <div className="sixteen wide column">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
