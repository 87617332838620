import * as React from 'react';
import type Stripe from 'stripe';
import { Field, Fields, Input, Rules } from '../../../../../../_dependencies';
import { useLocale } from '../../../../../../_locales';
import { PersonParams } from './personsForm';

interface Props {
  person: PersonParams;
  onUpdatedAddress: (address: Stripe.PersonCreateParams.Address) => void;
}

export const AddressFields = (props: Props) => {
  const { relationship, address } = props.person;
  const { t } = useLocale();

  if (relationship?.owner || relationship?.executive || relationship?.representative) {
    return (
      <div className="ui segment">
        <h3>{t("The person's address")}</h3>
        <Fields>
          <Field label={t('Street address')} width="eight wide">
            <Input
              rules={[Rules.NotEmpty()]}
              name="street"
              value={address?.line1}
              onChange={(value) => {
                const updatedAddress = { ...address, line1: value };
                props.onUpdatedAddress(updatedAddress);
              }}
            />
          </Field>
          <Field label={t('City')} width="four wide">
            <Input
              rules={[Rules.NotEmpty()]}
              name="city"
              value={address!.city!}
              onChange={(value) => {
                const updatedAddress = { ...address, city: value };
                props.onUpdatedAddress(updatedAddress);
              }}
            />
          </Field>
          <Field label={t('Postal code')} width="four wide">
            <Input
              rules={[Rules.NotEmpty(), Rules.ExactLength(5)]}
              name="postalcode"
              value={address!.postal_code!}
              onChange={(value) => {
                const updatedAddress = { ...address, postal_code: value };
                props.onUpdatedAddress(updatedAddress);
              }}
            />
          </Field>
        </Fields>
      </div>
    );
  } else {
    return null;
  }
};
