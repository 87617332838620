import { toUpper } from 'lodash';
import Moment from 'moment-timezone';
import React, { useContext } from 'react';
import Shortid from 'shortid';
import {
  AccessoryDocument,
  ActivityTypeResource,
  AddressDocument,
  BookingPriceCategoryDocument,
  BookingResource,
  formatDuration,
  MarketplaceLibrary as Lib,
} from '../_dependencies';
import { useLocale } from '../_locales';
import { DatalayerContext } from '../datalayer.context';
import { MarketplaceCartContext } from '../marketplace.cart.context';
import { MarketplaceContext } from '../marketplace.context';
import { WidgetDataContext } from '../widgetDataProvider';
interface BookingProps {
  booking: Lib.CartBooking;
  translatableDate: (date: Date, format: string) => string;
  renderIcon: (icon: string) => JSX.Element;
}

/**
 * This presents a booking activity as a product in the widget purchase process
 */
export const BookingItemTable = ({ booking, translatableDate, renderIcon }: BookingProps) => {
  const { removeItemFromCart } = useContext(MarketplaceCartContext);
  const { selectedStepId } = useContext(WidgetDataContext);
  const { t, tt, locale } = useLocale();
  const { occurance, totalPrice, bookedVisitors } = booking;
  const { originatingActivity } = occurance;
  const { address, isTmeTicket, bookingRules, termsOfUse } = originatingActivity;
  const rules: string = bookingRules ? tt(bookingRules) : new ActivityTypeResource().standardBookingRules;
  const terms: string = termsOfUse ? tt(termsOfUse) : new ActivityTypeResource().standardTermsOfUse;
  const marketplace = useContext(MarketplaceContext);
  const { sendRemoveFromCartEvent } = useContext(DatalayerContext);

  if (!occurance) return null;

  const renderRulesParagraph = (bookingRules: string) => {
    if (!bookingRules || !bookingRules.length) return null;
    return (
      <tr>
        <td className="collapsing" style={{ padding: '0 1rem', backgroundColor: 'rgba(34,36,38,.1)' }}>
          <strong>
            <i className="icons" style={{ margin: '0' }}>
              <i className="file text outline icon" title="file" />
              <i className="corner checkmark icon" title="checkmark" />
            </i>
          </strong>
        </td>
        <td style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <strong>{t('Booking conditions')}</strong>
            {bookingRules.split('\n').map((ruleDescription, index) => (
              <>
                <br />
                <span style={{ fontSize: '.9em' }} key={`widget-rules-${index}`}>
                  {ruleDescription}
                </span>
              </>
            ))}
          </div>
        </td>
      </tr>
    );
  };

  const renderTermsOfUseParagraph = (termsOfUse: string) => {
    if (!termsOfUse || !termsOfUse.length) return null;
    return (
      <tr>
        <td className="collapsing" style={{ padding: '0 1rem', backgroundColor: 'rgba(34,36,38,.1)' }}>
          <strong>
            <i className="icons" style={{ margin: '0' }}>
              <i className="file text outline icon" title="file" />
              <i className="corner checkmark icon" title="checkmark" />
            </i>
          </strong>
        </td>
        <td style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <strong>{t('Terms of use')}</strong>
            {termsOfUse.split('\n').map((termsDescription, index) => (
              <>
                <br />
                <span style={{ fontSize: '.9em' }} key={`widget-rules-${index}`}>
                  {termsDescription}
                </span>
              </>
            ))}
          </div>
        </td>
      </tr>
    );
  };
  const renderDiscountParagraph = () => {
    const discountInfo = marketplace.orderInformation?.products[0]?.events[1]?.metadata;
    if (discountInfo?.applied_discount_code != undefined) {
      const discountPercentage = discountInfo?.applied_discount_percentage * 100;
      const discountCode = discountInfo?.applied_discount_code;

      return (
        <tr>
          {renderIcon('percent')}
          <td style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <strong>
                {t('Price after discount')} ({t('code')} {discountCode}: {Math.trunc(discountPercentage)}%):
              </strong>{' '}
              {totalPrice - (totalPrice * discountPercentage) / 100 + ' '} {t('SEK')}
            </div>
          </td>
        </tr>
      );
    } else {
      return null;
    }
  };

  const renderAddressRow = (address: AddressDocument) => {
    return (
      <tr>
        {renderIcon('map signs')}
        <td>
          <strong>{t('Location')}</strong>
          <br />
          {`${address.row1}, ${address.postNr} ${toUpper(address.postOrt)}`}
        </td>
      </tr>
    );
  };

  const renderRemoveItem = () => {
    const { selectedOrganization } = useContext(WidgetDataContext);
    if (selectedStepId !== 'customerInfo') return null;
    const removeFromCart = () => {
      sendRemoveFromCartEvent(booking, selectedOrganization);
      removeItemFromCart(booking);
    };
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => removeFromCart()}>
        <a>{t('Remove')}</a>
        <i className="trash icon" style={{ padding: '0 .5em' }} />
      </div>
    );
  };

  return (
    <div style={{ marginBottom: '1rem', backgroundColor: '#FFF' }}>
      <table
        className="ui very basic unstackable celled table"
        style={{ border: '1px solid rgba(34,36,38,.1)', padding: '0 .5rem 0 0' }}
      >
        <tbody>
          <tr>
            {renderIcon('star outline')}
            <td>
              <strong style={{ fontSize: '1.03em' }}>
                {isTmeTicket ? t('Ticket') : t('Activity')}: {tt(occurance.title)}
              </strong>
            </td>
          </tr>
          <tr>
            {renderIcon('wait')}
            <td>
              <strong>{`${t('Duration')}: ${formatDuration(occurance.duration, locale)}`}</strong>
              <br />
              {isTmeTicket && <>{translatableDate(occurance.start, `DD MMM YYYY`)}</>}

              {!isTmeTicket && (
                <>
                  {translatableDate(occurance.start, `DD MMM YYYY`)} {', ' + t('from') + ' '}
                  {Moment(occurance.start).tz('Europe/Stockholm').format('HH:mm')}
                </>
              )}
            </td>
          </tr>
          {address && renderAddressRow(address)}
          <tr>
            {renderIcon('ticket')}
            <td>
              <strong>{t('Pricing info')}</strong>
              <br />
              {BookingResource.isDocument(booking) &&
                booking.priceCategories.map((priceCategory: AccessoryDocument<BookingPriceCategoryDocument>) => (
                  <div key={`price-category-${Shortid.generate()}` + priceCategory.tickets}>
                    {priceCategory.basePrice || priceCategory.rescheduleInsurance ? (
                      <span>{tt(priceCategory.name)}</span>
                    ) : (
                      <span>
                        {priceCategory.tickets} {t('pcs')} {tt(priceCategory.name)}
                      </span>
                    )}
                    <span>
                      {' '}
                      -{' '}
                      {priceCategory.rescheduleInsurance?.isFeePerPerson
                        ? priceCategory.price * bookedVisitors
                        : priceCategory.price}{' '}
                      {t('SEK')}
                    </span>
                  </div>
                ))}
            </td>
          </tr>
          <tr>
            {renderIcon('users')}
            <td>
              <strong>{`${t('Number of visitors')}: `}</strong>
              {bookedVisitors} {t('pcs')}
            </td>
          </tr>
          {renderRulesParagraph(rules)}
          {renderTermsOfUseParagraph(terms)}
          <tr>
            {renderIcon('money')}
            <td style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>{`${t('Unit price')}: `}</strong>
                {totalPrice} {t('SEK')}
              </div>

              {renderRemoveItem()}
            </td>
          </tr>
          {renderDiscountParagraph()}
        </tbody>
      </table>
    </div>
  );
};
