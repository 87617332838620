import moment from 'moment';
import { Types } from 'mongoose';
import React, { useEffect, useState } from 'react';
import { TicketResource, TicketUsageLog, TranslatableText } from '../../_dependencies';
import { useLocale } from '../../_locales';
import { LanguageToggleIcons } from '../languageToggleIcons';

interface TicketActivity {
  activityId: Types.ObjectId;
  activityName: string | TranslatableText;
  used?: boolean;
  usedOn?: Date;
}

interface TicketPriceCategory {
  name: string | TranslatableText;
  tickets: number;
}

function TicketPage() {
  const params = new URLSearchParams(location.search);
  const ticketId = params.get('id');
  const { t, tt } = useLocale();

  const [activities, setActivities] = useState<TicketActivity[]>([]);
  const [priceCategories, setPriceCategories] = useState<TicketPriceCategory[]>([]);
  const [validPeriodStart, setValidPeriodStart] = useState<Date>();
  const [validPeriodEnd, setValidPeriodEnd] = useState<Date>();
  const [openModal, setOpenModal] = useState(false);
  const [clickedActivityName, setClickedActivityName] = useState<string | TranslatableText>();
  const [clickedActivityId, setClickedActivityId] = useState<Types.ObjectId>();
  const [confirmationCheckIn, setConfirmationCheckIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [today] = useState(new Date());
  const [isActive, setIsActive] = useState(false);
  const [ticketTitle, setTicketTitle] = useState<string | TranslatableText>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!ticketId) return;

    (async () => {
      let data = {
        ticket_id: ticketId,
        api_key: 'password',
      };

      const ticket = await new TicketResource().getTicket(data);

      if (ticket) {
        setActivities(formatActivityData(ticket.validActivities, ticket.usageLog));
        setValidPeriodStart(ticket.startTime);
        setValidPeriodEnd(ticket.endTime);
        setPriceCategories(ticket.priceCategories);
        setTicketTitle(ticket.title);
        setLoading(false);
      }
    })();
  }, [ticketId]);

  // Kontrollera om biljetten är aktiv just nu
  useEffect(() => {
    if (validPeriodEnd != undefined) {
      if (moment(today).isAfter(validPeriodStart) && moment(today).isBefore(validPeriodEnd)) {
        setIsActive(true);
      }
    }
  }, [validPeriodStart, validPeriodEnd]);

  // Formatera datum utan tid
  function formatDate(dateFromApi: any) {
    let formattadDate = moment(dateFromApi).format('ll');
    return formattadDate;
  }

  // Formatera datum med tid
  function formatDateWithTime(dateFromApi: any) {
    let formattadDate = moment(dateFromApi).format('DD MMM, HH:mm');
    return formattadDate;
  }

  // Lägger in användningen på varje aktivitet för att enklare kunna rendera ut aktivitetskort
  const formatActivityData = (validActivities: TicketActivity[], usageLog: TicketUsageLog[] | undefined) => {
    if (usageLog) {
      let activitiesWithUsage: TicketActivity[] = [];
      validActivities.forEach((activity) => {
        let used = usageLog.find((usedActivity) => usedActivity.activityId === activity.activityId);
        if (used) {
          const usedActivity = { ...activity, used: true, usedOn: used.usedOn };
          activitiesWithUsage.push(usedActivity);
        } else activitiesWithUsage.push(activity);
      });
      return activitiesWithUsage;
    }
    return validActivities;
  };

  async function useTicket(activityId: Types.ObjectId) {
    let data = {
      ticket_id: ticketId,
      api_key: 'password',
      activity_id: activityId,
    };

    const useTicket = await new TicketResource().useTicket(data);

    if (useTicket.usageLog) {
      setActivities(formatActivityData(useTicket.validActivities, useTicket.usageLog));
      setConfirmationCheckIn(true);
      setOpenModal(false);
    } else {
      setErrorMessage(true);
      setOpenModal(false);
    }
  }

  function openActivityCheckIn(activity: TicketActivity) {
    setOpenModal(!openModal);
    setClickedActivityName(tt(activity.activityName));
    setClickedActivityId(activity.activityId);
  }

  function checkIn() {
    useTicket(clickedActivityId!);
  }

  if (loading) return <div className="ui active loader" />;
  return (
    <>
      <div style={openModal || confirmationCheckIn ? { backgroundColor: '#D9D9D9' } : {}}>
        <header style={{ ...header }}>
          <LanguageToggleIcons />
        </header>
        <main style={{ textAlign: 'center', minHeight: '83vh', maxHeight: 'fit-content' }}>
          <h1 style={{ color: '#232B69' }}>{tt(ticketTitle)}</h1>
          <p style={{ fontSize: '18px', marginBottom: '5px' }}>{t('Valid for')}:</p>
          {priceCategories.map((priceCategory, index) => {
            return (
              <p key={index} style={{ fontSize: '18px', marginBottom: '0.2rem' }}>
                {tt(priceCategory.name)}: {priceCategory.tickets} st
              </p>
            );
          })}
          {!isActive && <p style={{ marginTop: '15px', marginBottom: 0 }}>{t('Valid during the period:')}</p>}
          <p style={{ fontSize: '18px' }}>
            {formatDate(validPeriodStart)} - {formatDate(moment(validPeriodEnd).subtract(2, 'hours'))}
          </p>
          <p style={{ fontSize: '18px', marginBottom: '5px', color: '#232B69' }}>
            {t('NOTE: Each entry must be checked in...')}
          </p>
          <section style={{ ...ticketSection }}>
            {errorMessage && (
              <div
                style={{
                  backgroundColor: '#f1f1f1',
                  marginLeft: '30px',
                  marginRight: '30px',
                  padding: '10px',
                  border: '1px solid red',
                }}
              >
                <p>
                  {t(
                    'Unfortunately, you cannot check in the desired activity, please check the validity of the ticket. Also note that each activity can only be checked in once.',
                  )}
                </p>
                <button
                  className="ui primary button"
                  onClick={() => {
                    setErrorMessage(false);
                  }}
                  style={{ ...button }}
                >
                  {t('Close')}
                </button>
              </div>
            )}
            {activities.map((activity) => {
              const { activityId, activityName, used, usedOn } = activity;
              return (
                <button
                  key={activityId.toString()}
                  style={isActive && !used ? { ...activityToCheckIn } : { ...activityToCheckInDisabled }}
                  onClick={() => {
                    openActivityCheckIn(activity);
                  }}
                  disabled={!isActive || used}
                >
                  <p>
                    {!isActive && <i className="lock icon"></i>}
                    {tt(activityName)}
                  </p>
                  {usedOn && (
                    <p>
                      <i className="check circle icon" title="Check" style={{ color: '#F8ACB8' }} />{' '}
                      {formatDateWithTime(usedOn)}
                    </p>
                  )}
                </button>
              );
            })}
            {openModal && (
              <section style={{ ...checkInSection }}>
                <h2>
                  {t('Check-in at')} {clickedActivityName}?
                </h2>
                <h3>{t('NOTE: The entry must be checked in by staff at the activity.')}</h3>
                <h3 style={{ marginBottom: '50px' }}>{t('Check-in cannot be undone.')}</h3>

                <button className="ui button" style={{ ...button }} onClick={checkIn}>
                  {t('Use ticket')}
                </button>
                <button
                  className="ui primary button"
                  style={{ ...button, backgroundColor: '#232B69' }}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  {t('Cancel')}
                </button>
              </section>
            )}
            {confirmationCheckIn && (
              <section
                style={{
                  ...checkInSection,
                }}
              >
                <button
                  style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    border: 'none',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => {
                    setConfirmationCheckIn(false);
                  }}
                >
                  <i className="close icon" title="Close" style={{ fontSize: '38px', backgroundColor: '#FFF' }} />
                </button>
                <h2> {t('Check-in completed.')}</h2>
                <h3>{clickedActivityName}</h3>
                <i
                  className="check circle icon"
                  title="Check"
                  style={{ color: '#232B69', fontSize: '96px', marginTop: '50px' }}
                />
              </section>
            )}
          </section>
        </main>
        <footer style={{ textAlign: 'center', height: '10vh' }}>Powered by Adventure Hero</footer>
      </div>
    </>
  );
}

const header: React.CSSProperties = {
  textAlign: 'right',
  marginRight: '20px',
  paddingTop: '20px',
  height: '7vh',
};

const ticketSection: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const activityToCheckIn: React.CSSProperties = {
  backgroundColor: '#232B69',
  color: '#FFF',
  minHeight: '100px',
  maxHeight: 'fit-content',
  width: '160px',
  margin: '10px',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '18px',
  padding: '5px',
  wordBreak: 'break-word',
  border: 'none',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

const activityToCheckInDisabled: React.CSSProperties = {
  backgroundColor: '#4D5157',
  color: '#FFF',
  minHeight: '100px',
  maxHeight: 'fit-content',
  width: '160px',
  margin: '10px',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '18px',
  padding: '5px',
  wordBreak: 'break-word',
  border: 'none',
  flexWrap: 'wrap',
};

const checkInSection: React.CSSProperties = {
  zIndex: 1,
  width: '300px',
  height: '500px',
  position: 'absolute',
  top: '10vh',
  backgroundColor: '#fff',
  borderRadius: '10px',
  padding: '20px',
  paddingTop: '50px',
};

const button: React.CSSProperties = {
  display: 'block',
  margin: '0 auto',
  marginTop: '25px',
  width: '160px',
  height: '38px',
};

export default TicketPage;
