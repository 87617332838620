import Lodash from 'lodash';

export function getCountryCode(countryName: string) {
  const countryCodeObject = Lodash.find(euCountryCodes, (country) => country.name == countryName);
  return countryCodeObject ? countryCodeObject.countryCode : undefined;
}

export const euCountryCodes = [
  {
    countryCode: 'AD',
    name: 'Andorra',
  },
  {
    countryCode: 'AT',
    name: 'Austria',
  },
  {
    countryCode: 'BE',
    name: 'Belgium',
  },
  {
    countryCode: 'BG',
    name: 'Bulgaria',
  },
  {
    countryCode: 'HR',
    name: 'Croatia',
  },
  {
    countryCode: 'CY',
    name: 'Cyprus',
  },
  {
    countryCode: 'CZ',
    name: 'Czech Republic',
  },
  {
    countryCode: 'DK',
    name: 'Denmark',
  },
  {
    countryCode: 'EE',
    name: 'Estonia',
  },
  {
    countryCode: 'FO',
    name: 'Faroe Islands',
  },
  {
    countryCode: 'FI',
    name: 'Finland',
  },
  {
    countryCode: 'FR',
    name: 'France',
  },
  {
    countryCode: 'DE',
    name: 'Germany',
  },
  {
    countryCode: 'GI',
    name: 'Gibraltar',
  },
  {
    countryCode: 'GR',
    name: 'Greece',
  },
  {
    countryCode: 'GL',
    name: 'Greenland',
  },
  {
    countryCode: 'GG',
    name: 'Guernsey',
  },
  {
    countryCode: 'VA',
    name: 'Holy See (Vatican City State)',
  },
  {
    countryCode: 'HU',
    name: 'Hungary',
  },
  {
    countryCode: 'IS',
    name: 'Iceland',
  },
  {
    countryCode: 'IE',
    name: 'Ireland',
  },
  {
    countryCode: 'IM',
    name: 'Isle of Man',
  },
  {
    countryCode: 'IT',
    name: 'Italy',
  },
  {
    countryCode: 'JE',
    name: 'Jersey',
  },
  {
    countryCode: 'LV',
    name: 'Latvia',
  },
  {
    countryCode: 'LI',
    name: 'Liechtenstein',
  },
  {
    countryCode: 'LT',
    name: 'Lithuania',
  },
  {
    countryCode: 'LU',
    name: 'Luxembourg',
  },
  {
    countryCode: 'MT',
    name: 'Malta',
  },
  {
    countryCode: 'MC',
    name: 'Monaco',
  },
  {
    countryCode: 'NL',
    name: 'Netherlands',
  },
  {
    countryCode: 'NO',
    name: 'Norway',
  },
  {
    countryCode: 'PL',
    name: 'Poland',
  },
  {
    countryCode: 'PT',
    name: 'Portugal',
  },
  {
    countryCode: 'RO',
    name: 'Romania',
  },
  {
    countryCode: 'RS',
    name: 'Serbia',
  },
  {
    countryCode: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    countryCode: 'SM',
    name: 'San Marino',
  },
  {
    countryCode: 'SK',
    name: 'Slovakia',
  },
  {
    countryCode: 'SI',
    name: 'Slovenia',
  },
  {
    countryCode: 'ES',
    name: 'Spain',
  },
  {
    countryCode: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    countryCode: 'SE',
    name: 'Sweden',
  },
  {
    countryCode: 'CH',
    name: 'Switzerland',
  },
  {
    countryCode: 'GB',
    name: 'United Kingdom',
  },
];
