import Moment from 'moment-timezone';
import { Types } from 'mongoose';
import React from 'react';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import {
  Accordion,
  ActivityOccuranceResource,
  ActivityTypeResource,
  Alert,
  Consume,
  LocaleContext,
  MobxComponent,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { DeleteButton } from '../../../../components/deleteButton';
import { ErrorBoundary } from '../../../../components/errorBoundary';
import { Maybe } from '../../../../components/maybe';
import { OffersContext } from '../../../../contexts/offers.context';
import OfferForm from '../form/OfferForm';
import OfferSchedulingSection from './OfferSchedulingSection';
import { BookingConfirmationForm } from './bookingConfirmationForm';
import { BookingRulesForm } from './bookingRulesForm';
import { DeleteModal } from './deleteModal';
import { ListOfOfferOccurances } from './listOfOfferOccurances';
import { MessageToEmployeesForm } from './messageToEmployeesForm';
import { OfferPreviewCard } from './previewCard';
import { TermsOfUseForm } from './termsOfUseForm';
import ThankYouMessageForm from './thankYouMessageForm';
import WelcomeMessageForm from './welcomeMessageForm';

export { ActivityOccurancesGeneratorForm } from './activityOccurancesGeneratorForm';
export { BookingConfirmationForm } from './bookingConfirmationForm';
export { BookingRulesForm } from './bookingRulesForm';
export { ListOfOfferOccurances } from './listOfOfferOccurances';
export { MessageToEmployeesForm } from './messageToEmployeesForm';

export class OfferDetails extends MobxComponent<RouteComponentProps<{ id: string }>> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(OffersContext)
  private _offers: OffersContext;

  private get selectedOffer() {
    return this._offers.selectedOffer;
  }

  private _offersList: ListOfOfferOccurances | null | undefined;
  private isDeleting: boolean;
  private deleteModal: DeleteModal;

  componentDidMount() {
    const { retrieveOfferById } = this._offers;
    retrieveOfferById(this.props.match.params.id);
  }

  componentWillUnmount() {
    const { deselectOffer } = this._offers;
    deselectOffer();
  }

  private createPeriodForOffer = (
    startOfPeriod: Date,
    endOfPeriod: Date,
    startTimes: Date[],
    propertyIds: Types.ObjectId[],
    publishTimes: boolean,
    customDuration: number,
    customVisitorCapacity: number,
    customMinVisitors: number,
    customNeededStaffing: number,
    customBookingClosesBeforeHours: number,
    customRefundableUntilHours: number,
    callback: Function,
  ) => {
    const occuranceResource = new ActivityOccuranceResource();
    const typeDocument = new ActivityTypeResource().createDocument(this.selectedOffer!);
    const { t } = this._locale;

    // Add custom values to the activity type
    typeDocument.duration = customDuration;
    typeDocument.visitorCapacity = customVisitorCapacity;
    typeDocument.minVisitors = customMinVisitors;
    typeDocument.neededStaffing = customNeededStaffing;
    typeDocument.bookingClosesBeforeEventInHours = customBookingClosesBeforeHours;
    typeDocument.refundableUntilInHours = customRefundableUntilHours;

    occuranceResource
      .batchCreateOccurances(typeDocument, propertyIds, startTimes, publishTimes)
      .catch((err) => {
        Alert.show(t('offers.details.wrongStartTimes'), t('We were not able to save your changes...'), 'error');
        return callback();
      })
      .then((result) => {
        Alert.show(
          '' +
            startTimes.length +
            ' ' +
            t('offers.details.newStartTimes') +
            ' ' +
            Moment(startOfPeriod).tz('Europe/Stockholm').format('ll') +
            ' ' +
            t('offers.details.and') +
            ' ' +
            Moment(endOfPeriod).tz('Europe/Stockholm').format('ll'),
          t('offers.details.scheduled'),
          'success',
        );
        callback();

        if (this._offersList) {
          return this._offersList.reloadOffers();
        }
      });
  };

  private onOfferSaved = () => {
    this._offersList?.reloadOffers();
    const { history, match } = this.props;
    history.push(match.url);
  };

  private onDeleteOffer() {
    this.deleteModal.showModal();
  }

  private get deleteButton() {
    const { t } = this._locale;
    return (
      <Maybe if="organization.manager">
        <div>
          <div style={{ height: '4em' }} />
          <div className="ui divider" />
          <div className="ui center aligned basic segment">
            <DeleteButton
              deleting={this.isDeleting}
              onConfirmed={this.onDeleteOffer.bind(this)}
              confirmationText={t('Are you sure?')}
            >
              {t('offers.details.removeOffer')}
            </DeleteButton>
          </div>
          <DeleteModal ref={(node) => (this.deleteModal = node!)} />
          <div />
        </div>
      </Maybe>
    );
  }

  private sendStatusText(status?: boolean) {
    const { t } = this._locale;
    const { width } = this.globals.device;

    if (width < 500)
      return (
        <p style={{ fontWeight: 'normal', fontSize: '.8em' }}>
          {status ? t('(Will be sent automatically)') : t('(Will not be sent)')}
        </p>
      );
    return (
      <span style={{ fontWeight: 'normal', marginLeft: '.6em', fontSize: '.95em' }}>
        {status ? t('(Will be sent automatically)') : t('(Will not be sent)')}
      </span>
    );
  }

  private editableMailContent(editText: string, shouldBeSent?: boolean) {
    const { width } = this.globals.device;
    if (width < 500)
      return (
        <>
          <button style={{ float: 'right' }} className="ui compact tiny basic button">
            <i className="dropdown icon" style={{ width: '15px' }} /> {width < 500 ? '' : editText}
          </button>
          {this.sendStatusText(shouldBeSent)}
        </>
      );
    return (
      <>
        {this.sendStatusText(shouldBeSent)}
        <button style={{ float: 'right' }} className="ui compact tiny basic button">
          <i className="dropdown icon" style={{ width: '15px' }} /> {width < 500 ? '' : editText}
        </button>
      </>
    );
  }

  sideFeatures() {
    const { t } = this._locale;
    const { selectedOffer } = this._offers;
    const { width } = this.globals.device;
    return (
      <div>
        <div style={{ margin: '0 0 2rem' }} className="ui horizontal divider">
          {t('Manage mailings')}
        </div>

        <Accordion
          fluid
          title={
            <span>
              <h3 className="ui header">
                {t('Booking confirmation')}
                <button style={{ float: 'right' }} className="ui compact tiny basic button">
                  <i className="dropdown icon" style={{ width: '15px' }} /> {width < 500 ? '' : t('Edit')}
                </button>
                <div className="sub header" style={{ marginTop: '10px' }}>
                  {width < 500 ? '' : t('You can choose how your offers booking...')}
                </div>
              </h3>
            </span>
          }
        >
          <BookingConfirmationForm />
        </Accordion>
        <div style={{ height: '1em' }} />

        <Accordion
          fluid
          title={
            <span>
              <h3 className="ui header">
                {t('Welcome message')}
                {this.editableMailContent(t('Edit'), selectedOffer?.welcomeMail?.shouldBeSent)}
                <div className="sub header" style={{ marginTop: '10px' }}>
                  {width < 500 ? '' : t('You can choose how your offers welcome email will look like')}
                </div>
              </h3>
            </span>
          }
        >
          <WelcomeMessageForm />
        </Accordion>
        <div style={{ height: '1em' }} />

        <Accordion
          fluid
          title={
            <span>
              <h3 className="ui header">
                {t('Thank you message')}
                {this.editableMailContent(t('Edit'), selectedOffer?.thankYouMail?.shouldBeSent)}
                <div className="sub header" style={{ marginTop: '10px' }}>
                  {width < 500 ? '' : t('You can choose how your offers thank you email will look like')}
                </div>
              </h3>
            </span>
          }
        >
          <ThankYouMessageForm />
        </Accordion>
        <div style={{ height: '1em' }} />

        <Accordion
          fluid
          title={
            <span>
              <h3 className="ui header">
                {t('Edit message to staff')}
                <button style={{ float: 'right' }} className="ui compact tiny basic button">
                  <i className="dropdown icon" style={{ width: '15px' }} /> {width < 500 ? '' : t('Edit')}
                </button>
                <div className="sub header" style={{ marginTop: '10px' }}>
                  {width < 500 ? '' : t('You can specify a message that is...')}
                </div>
              </h3>
            </span>
          }
        >
          <MessageToEmployeesForm />
        </Accordion>
        <div style={{ height: '1em' }} />
        <div className="ui fluid divider" />

        <Accordion
          fluid
          title={
            <span>
              <h3 className="ui header">
                {t('Booking conditions')}
                <button style={{ float: 'right' }} className="ui compact tiny basic button">
                  <i className="dropdown icon" /> {t('Edit')}
                </button>
                <div className="sub header" style={{ marginTop: '10px' }}>
                  {t('offers.details.specialRulesAdd')}
                </div>
              </h3>
            </span>
          }
        >
          <BookingRulesForm />
        </Accordion>
        <Accordion
          fluid
          title={
            <span>
              <h3 className="ui header">
                {t('Terms of use')}
                <button style={{ float: 'right' }} className="ui compact tiny basic button">
                  <i className="dropdown icon" /> {t('Edit')}
                </button>
                <div className="sub header" style={{ marginTop: '10px' }}>
                  {t('offers.details.specialRulesAdd')}
                </div>
              </h3>
            </span>
          }
        >
          <TermsOfUseForm />
        </Accordion>
        <div style={{ height: '1em' }} />

        <OfferSchedulingSection offer={this.selectedOffer!} periodGeneratingAction={this.createPeriodForOffer} />
      </div>
    );
  }

  render() {
    const { path, url } = this.props.match;
    const { t, tt } = this._locale;
    const { resetSelectedOffer } = this._offers;
    const { isDesktopSize, isMobileSize } = this.globals.device;
    if (!this.selectedOffer) return null;

    const style = () => ({
      container: {
        padding: isMobileSize ? '1rem 0' : '2rem',
        width: '1400',
      },
      content: `${!isDesktopSize ? 'sixteen' : 'eight'} wide column`,
      sideFeatures: `${!isDesktopSize ? 'sixteen' : 'eight'} wide column`,
    });

    if (!this.selectedOffer.title) {
      return <span />;
    }

    return (
      <div className="ui container" style={style().container}>
        <h1>{tt(this.selectedOffer.title)}</h1>
        <div className="ui stackable grid">
          <div className={style().content}>
            <Switch>
              <Route exact path={path}>
                <OfferPreviewCard />
                <Link to={url + '/edit'} className="ui fluid button" style={{ marginTop: '1rem' }}>
                  <i className="write icon" /> {t('Edit')}
                </Link>
              </Route>
              <Route path={path + '/edit'}>
                <ErrorBoundary compact>
                  <OfferForm onSave={this.onOfferSaved} />
                </ErrorBoundary>
                <Link to={url} className="ui fluid button" onClick={resetSelectedOffer} style={{ marginTop: '1rem' }}>
                  <i className="remove icon" /> {t('Stop editing')}
                </Link>
              </Route>
            </Switch>
          </div>
          <div className={style().sideFeatures}>{this.sideFeatures()}</div>
        </div>
        <div style={{ height: '5em' }} />
        <ListOfOfferOccurances
          ref={(instance) => {
            if (!this._offersList) {
              this._offersList = instance;
            }
          }}
        />
        {this.deleteButton}
      </div>
    );
  }
}
