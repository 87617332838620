import React, { useContext, useEffect, useState } from 'react';
import {
  DestinationDocument,
  Field,
  GlobalsContext,
  OrganizationResource,
  PlaceDocument,
  PlaceResource,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { OffersContext } from '../../../../../contexts/offers.context';
import { BookingRequestFields } from '../createPriceCategories/bookingRequestFields';
import { LocationFields } from '../fields/LocationFields';
import { BookingClosesField } from '../fields/bookingClosesField';
import { IsTicketField } from '../fields/isTicketField';
import { MaximumNumberOfBookingsField } from '../fields/maximumNumberOfBookingsField';
import { PersonnelFields } from '../fields/personnelFields';
import { PreparationAndCleanupFields } from '../fields/preparationAndCleanupFields';
import { RefundableUntilField } from '../fields/refundableUntilField';
import RouteCreatorField from '../fields/routeCreatorField';
import { OfferFormSubmitButton } from './formSubmitButton';

export const SettingsTab = () => {
  const { t } = useLocale();
  const { selectedOffer, updateSelectedOffer } = useContext(OffersContext);

  if (!selectedOffer) return null;
  const globalContext = useContext(GlobalsContext);
  const { features } = globalContext.session.currentOrganization.flags;
  const [destinationsAvailable, setDestinationsAvailable] = useState<DestinationDocument[] | undefined>(undefined);
  const [places, setPlaces] = useState<PlaceDocument[]>([]);

  useEffect(() => {
    if (features.tmeTickets) {
      new OrganizationResource()
        .getDestinationsForOrganization(globalContext.session.currentOrganization._id)
        .then((res) => setDestinationsAvailable(res));
    }

    if (features.routes) {
      new PlaceResource().find({ organization: globalContext.session.currentOrganizationId }).then((res) => {
        setPlaces(res);
      });
    }
  }, []);

  return (
    <div>
      <PersonnelFields neededStaffing={selectedOffer.neededStaffing} setOfferNeededStaffing={updateSelectedOffer} />
      <MaximumNumberOfBookingsField
        bookingCapacity={selectedOffer.bookingCapacity}
        setOfferBookingCapacity={updateSelectedOffer}
      />
      <BookingClosesField
        bookingClosesTime={selectedOffer.bookingClosesBeforeEventInHours}
        setOfferBookingCloseTime={updateSelectedOffer}
      />
      <RefundableUntilField
        org={selectedOffer.organization}
        refundableUntil={selectedOffer.refundableUntilInHours}
        setOfferRefundableUntil={updateSelectedOffer}
      />

      {!features.tmeTickets && (
        <BookingRequestFields
          fixedBookingRequest={selectedOffer.fixedBookingRequest}
          flexibleBookingRequest={selectedOffer.flexibleBookingRequest}
          flexibleBookingRequestMessage={selectedOffer.flexibleBookingRequestMessage}
          fixedBookingRequestMinimumCharge={selectedOffer.fixedBookingRequestMinimumCharge}
          setOfferBookingRequestRules={updateSelectedOffer}
          useInventory={selectedOffer.useInventory}
        />
      )}

      <PreparationAndCleanupFields
        preparationDuration={selectedOffer.preparationDuration}
        cleanupDuration={selectedOffer.cleanupDuration}
        setOfferPrepOrCleanDuration={updateSelectedOffer}
      />
      <br />
      {destinationsAvailable && (
        <IsTicketField
          destinations={destinationsAvailable}
          isTmeTicket={selectedOffer.isTmeTicket}
          activitiesBoundToTicket={selectedOffer.associatedActivities}
          updateOffer={updateSelectedOffer}
        />
      )}
      <br />
      <Field label={t('Locationsettings')}></Field>
      <LocationFields
        setOfferLocationOrAddress={updateSelectedOffer}
        address={selectedOffer.address}
        location={selectedOffer.location}
      />
      {features.routes && (
        <RouteCreatorField selectedOffer={selectedOffer} updateSelectedOffer={updateSelectedOffer} places={places} />
      )}

      <OfferFormSubmitButton isSubmit onClick={() => {}} nextTabName="" />
    </div>
  );
};
