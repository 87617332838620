import React from 'react';
import Moment from 'moment-timezone';
import {
  ActivityOccuranceDocument,
  Checkbox,
  getRescheduleInsurance,
  PriceCategoryDocument,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';

interface Props {
  checked: boolean;
  onToggle: () => void;
  occurance: ActivityOccuranceDocument;
}

export const RescheduleInsuranceField = ({ checked, onToggle, occurance }: Props) => {
  const { t } = useLocale();
  const accessory = getRescheduleInsurance(occurance.priceCategories);

  if (!accessory) return null;

  const { lastRescheduleTime, validInDays, isFeePerPerson } = accessory.rescheduleInsurance;
  const isBeforeLastRescheduleTime = Moment().isBefore(Moment(occurance.start).subtract(lastRescheduleTime, 'hours'));

  if (!isBeforeLastRescheduleTime) return null;

  const lastRescheduleTimeText = t('The rebooking must take place...').replace('###', lastRescheduleTime.toString());
  const validInDaysText = t('When rebooking, you will receive...').replace('###', validInDays.toString());

  return (
    <>
      <div className="ui divider" />

      <div style={insuranceFieldWrapperStyle}>
        <i className="ui big umbrella icon" />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h3 style={{ marginBottom: '0.5rem' }}>
            {t('Reschedule insurance')} - {accessory.price} {t('kr-SEK')} {isFeePerPerson && t('per person')}
          </h3>
          <i style={{ fontSize: '0.8rem' }}>{lastRescheduleTimeText}</i>
          <i style={{ fontSize: '0.8rem' }}>{validInDaysText}</i>
        </div>

        <Checkbox
          toggle
          style={{ marginLeft: 'auto', paddingLeft: '.5rem' }}
          checked={checked}
          onCheckedOrUncheked={onToggle}
        />
      </div>
    </>
  );
};

const insuranceFieldWrapperStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: '.5rem 0 .5rem 0',
};
