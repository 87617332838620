import Lodash from 'lodash';
import { runInAction } from 'mobx';
import React from 'react';
import {
  Component,
  Consume,
  DefaultPredicate,
  DeviceProvider,
  IDevice,
  LocaleContext,
  ObservingComponent,
  SearchableList,
  SearchableListStore,
  SearchListBar,
  UserDocument,
  UserResource,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import DashBoardTemplate from '../dashboardTemplate';
import { EmployeeAlternativeItemProps, EmployeeCard } from './employeeCard';

export { EmployeeCard } from './employeeCard';
export { AddNewEmployeeForm } from './new';

export class EmployeesIndex extends ObservingComponent<{}, SearchableListStore<UserDocument>> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private _userResource = new UserResource();

  // TODO: use this to delete item util detail view has been added
  private onDeleteItem = (user: UserDocument) => {
    this._userResource.delete(user.id).then(() => {
      runInAction(() => Lodash.remove(this.store.items, (item) => item.id == user.id));
    });
  };

  private get additionalTemplateProps() {
    const props = {
      onDeleteItem: this.onDeleteItem,
    } as EmployeeAlternativeItemProps;
    return props;
  }

  componentDefaultStore() {
    const store = new SearchableListStore<UserDocument>();

    const systemOwnerFilter = (employee: UserDocument) => {
      const systemOwner = employee.roles.find((r) => r.type == 'system.owner');
      if (systemOwner == null) return true;

      if (systemOwner.originalOrganization == this.globals.session.currentOrganizationId) return true;

      return false;
    };

    this._userResource
      .find({ organization: this.globals.session.currentOrganizationId })
      .then((employees: UserDocument[]) => {
        // Replace current user with session object for proper update across the system
        const currentUser = this.globals.session.currentUser as UserDocument;
        store.items = employees
          .filter(systemOwnerFilter)
          .map((employee) => (employee._id === currentUser._id ? currentUser : employee));
        store.sortByFields = ['firstname', 'lastname'];
      });

    store.predicate = (text, item: UserDocument) => {
      const employeeInfo = item.firstname.toString() + item.lastname.toString() + item.username.toString();

      return DefaultPredicate(text, employeeInfo);
    };
    return store;
  }

  render() {
    const { t } = this._locale;
    return (
      <div style={{ padding: '2em' }}>
        <h2>
          {t('components.dashboard.employees.index.allEmployees')} {this.globals.session.currentOrganization.name}
        </h2>
        <div className="ui large fluid transparent left icon input">
          <i className="search icon" />
          <SearchListBar store={this.store} placeholder={t('components.dashboard.employees.index.findEmployee')} />
        </div>
        <div style={{ marginBottom: '3em' }} />
        <SearchableList
          className={`ui ${this.device.size === 'desktop' ? 'four' : 'three'} doubling stackable cards`}
          template={EmployeeCard}
          additionalTemplateProps={this.additionalTemplateProps}
          store={this.store}
          showDefaultSearchBar={false}
        />
      </div>
    );
  }
}

export class EmployeesTemplate extends Component<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  render() {
    const { t } = this._locale;
    const links = [
      {
        path: '/dashboard/employees',
        text: t('Personnel list'),
      },
    ];
    const rightLink = {
      path: '/dashboard/employees/new',
      text: t('components.dashboard.employees.index.addEmployee'),
      icon: 'plus icon',
    };
    return (
      <DashBoardTemplate title={t('Personnel')} icon="users icon" tabs={links} rightTab={rightLink}>
        {this.props.children}
      </DashBoardTemplate>
    );
  }
}
