import Fuse from 'fuse.js';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import React, { ChangeEventHandler, useContext, useEffect, useState } from 'react';
import {
  DeviceProvider,
  GlobalsContext,
  InventoryTransferDocument,
  InventoryTransferResource,
  Table,
  UserDocument,
  UserResource,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { CalendarDropdown } from '../inputs /calendarDropdown';
import { InventorySearchComponent } from '../inputs /inventorySearchBar';
import { PersonnelDropdown } from '../inputs /personnelDropdown';
import { PrintTransfersButton } from '../inputs /printTransfersButton';
import { InventoryTransferDetailsRow } from './inventoryTransferDetailsRow';

export const InventoryTransferList = () => {
  const { t } = useLocale();
  const [inventoryTransfers, setInventoryTransfers] = useState<InventoryTransferDocument[]>([]);
  const [selectedTransfers, setSelectedTransfers] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [date, setDate] = useState<Date>();
  const [personnel, setAvailablePersonnel] = useState<UserDocument[]>();
  const [globalCheckBox, setGlobalCheckBox] = useState<boolean>(false);
  const [updatingPersonnel, setUpdatingPersonnel] = useState<boolean>(false);

  const globals = useContext(GlobalsContext);
  const device = useContext(DeviceProvider.Context);
  const mobile = device.size === 'mobile';

  useEffect(() => {
    if (date) {
      (async () => {
        const startDate = moment(date).startOf('day');
        const endOfDay = moment(startDate).add(1, 'days');

        try {
          const transfers = (await new InventoryTransferResource().findAndPopulateTransferWithBookingAndPickuppers({
            organization: globals.session.currentOrganizationId,
            date: { $gte: startDate.toDate(), $lt: endOfDay.toDate() },
          })) as InventoryTransferDocument[];

          setInventoryTransfers(transfers);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [date, updatingPersonnel]);

  useEffect(() => {
    (async () => {
      const response = await new UserResource().retrieveEmployeesByOrganization(
        String(globals.session.currentOrganizationId),
      );
      setAvailablePersonnel(response);
    })();
  }, []);

  useEffect(() => {
    setSelectedTransfers([]);
    setGlobalCheckBox(false);
  }, [date]);

  const assignPersonnal = async (person: UserDocument) => {
    for (const transfer of selectedTransfers) {
      const transferToUpdate = cloneDeep(inventoryTransfers.find((item) => item.id == transfer));

      if (transferToUpdate) {
        const { assignedPickupers } = transferToUpdate;
        const personAlreadyInList = assignedPickupers.find((p) => p.id == person.id);

        if (personAlreadyInList) {
          continue;
        } else {
          setUpdatingPersonnel(true);
          assignedPickupers.push(person);
          await new InventoryTransferResource().updateDocument(transferToUpdate);
          setUpdatingPersonnel(false);
        }
      }
    }
  };

  const removeAssignedPerson = async (person: UserDocument, transferId: string) => {
    const transferToUpdate = cloneDeep(inventoryTransfers.find((item) => item.id == transferId));

    if (transferToUpdate) {
      setUpdatingPersonnel(true);
      const { assignedPickupers } = transferToUpdate;
      transferToUpdate.assignedPickupers = assignedPickupers.filter((p) => p.id != person.id);
      await new InventoryTransferResource().updateDocument(transferToUpdate);
      setUpdatingPersonnel(false);
    }
  };

  const fuse = new Fuse(inventoryTransfers, {
    threshold: 0.4,
    keys: [
      { name: 'bookingId.customer.firstname', weight: 1 },
      { name: 'bookingId.customer.lastname', weight: 1 },
      { name: 'from.name.sv', weight: 2 },
      { name: 'from.name.en', weight: 2 },
      { name: 'from.name.de', weight: 2 },
      { name: 'to.name.sv', weight: 2 },
      { name: 'to.name.en', weight: 2 },
      { name: 'to.name.de', weight: 2 },
      { name: 'date', weight: 2 },
      { name: 'assignedPickupers.firstname', weight: 1 },
      { name: 'bookingId.number', weight: 1 },
    ],
  });

  const onToggleSelectItem = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedTransfers((prev) => [...prev, id]);
      return;
    }
    if (!isChecked) {
      const updatedState = selectedTransfers.filter((e) => e !== id);
      setSelectedTransfers(updatedState);
    }
  };

  const selectAllItems: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      setSelectedTransfers(inventoryTransfers?.map((e) => e.id));
    } else {
      setSelectedTransfers([]);
    }
  };

  const filteredInventoryTransfers = searchQuery
    ? fuse.search(searchQuery).map(({ item }) => item)
    : inventoryTransfers;

  const listOptions = () => {
    return (
      <>
        <h2>
          {t('All transfers for')} {globals.session.currentOrganization.name}
        </h2>
        <InventorySearchComponent
          placeholder={t('Filter by transfer name, personnel, booking number, from and to dates...')}
          getSearchQuery={(query: string) => setSearchQuery(query)}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            padding: '1.25em 0em 1.25em 0em',
          }}
        >
          <div style={{ width: mobile ? '9em' : '15em' }}>
            <CalendarDropdown mobile={mobile} getSelectedDate={(date: Date) => setDate(date)} />
          </div>
          <PersonnelDropdown
            personnel={personnel}
            selectedTransfers={selectedTransfers}
            assignPersonal={assignPersonnal}
          />
        </div>
      </>
    );
  };

  const renderGlobalCheckBox = () => {
    return (
      <input
        className="no-print"
        type="checkbox"
        checked={globalCheckBox}
        onChange={(e) => {
          selectAllItems(e);
          globalCheckBox ? setGlobalCheckBox(false) : setGlobalCheckBox(true);
        }}
      />
    );
  };

  return (
    <div style={{ padding: '2em' }}>
      {listOptions()}
      <div
        id={'printable-area'}
        className={!filteredInventoryTransfers ? 'ui active inline loader' : ' '}
        style={{ margin: mobile ? '0em -2em 0em -2em' : '' }}
      >
        {filteredInventoryTransfers && (
          <Table<InventoryTransferDocument>
            className={'ui striped unstackable table selectable'}
            minWidth={'10rem'}
            dataList={filteredInventoryTransfers}
            selectedElements={selectedTransfers}
            headers={[
              { title: t('Customer'), sortBy: 'title' },
              { title: t('Booking'), sortBy: 'bookingId.number' as any },
              { title: t('Bags'), sortBy: 'entities' },
              { title: t('Date'), sortBy: 'date' },
              { title: t('From'), sortBy: 'date.from.name.sv' },
              { title: t('To'), sortBy: 'date.to.name.sv' },
              { title: t('Personnel'), sortBy: 'assignedPickupers.firstname' },
              { title: renderGlobalCheckBox() }, //Global checkbox for selecting / deselecting all items
            ]}
            footers={[
              {
                title: <PrintTransfersButton transfers={filteredInventoryTransfers} />,
                colSpan: 8,
                float: 'right',
              },
            ]}
            renderRow={(item: InventoryTransferDocument, isSelected: boolean) => {
              return (
                <InventoryTransferDetailsRow
                  personnel={personnel}
                  item={item}
                  isSelected={isSelected}
                  key={item.id}
                  isSelectable
                  onToggleSelect={onToggleSelectItem}
                  removeAssignedPerson={removeAssignedPerson}
                />
              );
            }}
          ></Table>
        )}
      </div>
    </div>
  );
};
