import React from 'react';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';
import { ActivityCard } from '../movedFromAH/activityCard';
import { ActivityTypeDocument, Consume, KosmicComponent } from '../_dependencies';

interface Props {}

interface State {
  isLoading: boolean;
}

export class ActivityShowcaseView extends KosmicComponent<Props, State> {
  @Consume(WidgetDataProvider.Context)
  private widgetData: IWidgetData;

  constructor(props: Props) {
    super(props);
    this.state = { isLoading: false };
  }

  componentDidMount() {
    this.widgetData.verifyStepNavigation('activityShowcase');
  }

  private onSelectedActivity = (selectedActivity: ActivityTypeDocument) => {
    const selectedPrimaryActivityFromShowcase = this.widgetData.activityShowcase!.get(
      selectedActivity.id,
    )!.primaryActivity;

    if (selectedPrimaryActivityFromShowcase) {
      this.setState({ isLoading: true });
      this.widgetData.setState(
        {
          selectedPrimaryActivityFromShowcase,
        },
        () => {
          this.widgetData.gotoStep('activitySelection');
        },
      );
    }
  };

  public render() {
    const { isLoading } = this.state;
    const { activityShowcase } = this.widgetData;

    if (!activityShowcase) {
      throw new Error('[WIDGET] Should not be able to access this view without a set activityShowcase!');
    }

    return (
      <div className={'ui basic' + (isLoading ? ' loading ' : ' ') + 'segment'} style={responsiveGridContainer}>
        {Array.from(activityShowcase.values()).map((showcaseEntry) => {
          return (
            <ActivityCard
              key={showcaseEntry.primaryActivity.id}
              inWidget
              onSelectedActivity={this.onSelectedActivity}
              activityType={showcaseEntry.primaryActivity}
            />
          );
        })}
      </div>
    );
  }
}

const responsiveGridContainer: React.CSSProperties = {
  display: 'grid',
  gap: '1rem',
  padding: 0,
  gridTemplateColumns: `repeat(
      auto-fit,
      minmax(
        clamp(25% - 1rem, 300px, 100%),
        1fr
      )
    )`,
};
