import Lodash from 'lodash';
import { Schema, Types } from 'mongoose';
import { AbstractUserDocument, AbstractUserResource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { UserRoleDocument } from '../schemas/userRole';
import { UserAvatarResource } from './userAvatar.resource';
export { UserRoleDocument as UserRole } from '../schemas/userRole';

export interface UserDocument extends AbstractUserDocument {
  organization: Types.ObjectId;
  roles: UserRoleDocument[];
  firstname: string;
  lastname: string;
  /** Selected avatar, used instead of imageId when imageId is undefined */ avatar?: string;
  /** Id of custom uploaded avatar image */ imageId?: string;
  /** Image to this users selected image or selected avatar, always usable */ imageUrl: string;
  /** TODO: returns genetive name based on swedish rules */ genetiveFirstname: string;
  name: string;
  /** TODO: should be moved to userRole, property.crew */ sendEmailNotifications?: boolean;
  phoneNumber?: string;
  note?: string;
  isSubscribedForNewsletters?: boolean;
  isSubscribedForCustomerNewsletters?: boolean;
}

export class UserResource extends AbstractUserResource<UserDocument> {
  constructor() {
    super();

    this.restrictAccess();
    // this.setParentResource(SchemaNames.Default);
    this.setName(SchemaNames.Default);

    this.setSchema({
      organization: { type: Schema.Types.ObjectId, ref: 'organization', required: false },
      firstname: { type: String },
      lastname: { type: String, required: true },
      avatar: { type: String },
      imageId: { type: String, required: false },
      roles: [Schema.Types.Mixed],
      username: { type: String, unique: true, lowercase: true, trim: true },
      sendEmailNotifications: { type: Boolean, required: false },
      phoneNumber: { type: String, required: false },
      note: { type: String, required: false },
      isSubscribedForNewsletters: { type: Boolean, required: false },
      isSubscribedForCustomerNewsletters: { type: Boolean, required: false },
    });

    // Virtual fields
    const userAvatarResource = new UserAvatarResource();

    // Organization
    // FOR ADDING SUPPORT TO CUSTOMERACCOUNTS
    // this.addVirtualField('organization', (document) => {
    //   const role: any = Lodash.find(document.roles, (role) => role.organization);
    //   return role ? role.organization : undefined;
    // });

    // Image Url
    this.addVirtualField('imageUrl', (document) =>
      document.imageId
        ? userAvatarResource.fileUrl(document.imageId, 'png')
        : '/static/commons/avatars/' + (document.avatar ? document.avatar : 'default') + '.jpg',
    );

    // Name variations
    this.addVirtualField('name', (document) => {
      return (document.firstname ? document.firstname + ' ' : '') + document.lastname;
    });
    this.addVirtualField('genetiveFirstname', (document) => {
      const lastChar = document.firstname ? document.firstname.substr(document.firstname.length - 1) : '';
      return document.firstname + (lastChar == 's' || lastChar == 'x' || lastChar == 'z' ? '' : 's');
    });
  }

  /** Checks if this user resource has the asked for role */
  public documentHasRole(document: UserDocument, role: UserRoleDocument, strict?: boolean): boolean {
    let found = false;
    if (document) {
      Lodash.forEach(document.roles, (userRole) => {
        found = this.doesRolesMatch(userRole, role, !!strict);
        return !found; // continue iteration if not found
      });
    }

    return found;
  }

  /** Checks that a given role validates against a searched for requestedRole */
  private doesRolesMatch(userRole: UserRoleDocument, requiredRole: UserRoleDocument, strict: boolean): boolean {
    // False, when strict and the privliges are not of the same type
    if (strict && requiredRole.type != userRole.type) {
      return false;
    }

    // System owner
    if (userRole.type == 'system.owner') {
      return true;
    } else if (requiredRole.type == 'system.owner') {
      return false;
    }

    const matchingOrganization =
      !requiredRole.organization ||
      (!!requiredRole.organization &&
        !!userRole.organization &&
        requiredRole.organization.toString() == userRole.organization.toString());
    const matchingProperties =
      !requiredRole.property ||
      (!!requiredRole.property &&
        !!userRole.property &&
        requiredRole.property.toString() == userRole.property.toString());

    // Organization manager
    if (userRole.type == 'organization.manager') {
      return matchingOrganization;
    } else if (requiredRole.type == 'organization.manager') {
      return false;
    }

    // Property manager
    if (userRole.type == 'property.manager') {
      return matchingOrganization && matchingProperties;
    } else if (requiredRole.type == 'property.manager') {
      return false;
    }

    // Property crew
    if (userRole.type == 'property.crew') {
      return matchingOrganization && matchingProperties;
    } else if (requiredRole.type == 'property.crew') {
      return false;
    }

    // Organization employee
    if (userRole.type == 'organization.employee') {
      return matchingOrganization;
    } else if (requiredRole.type == 'organization.employee') {
      return false;
    }

    // Organization volunteer
    if (userRole.type == 'organization.volunteer') {
      return matchingOrganization;
    } else if (requiredRole.type == 'organization.volunteer') {
      return false;
    }

    // Customer
    if (userRole.type == 'customer') {
      return true;
    }

    // Default clause
    return false;
  }

  retrieveEmployeesByOrganization(organizationId: string) {
    return this.sendRequest<UserDocument[]>('/retrieveEmployeesByOrganization', 'post', { organizationId });
  }

  retrieveEmployeesByProperty(propertyId: string) {
    return this.sendRequest<{ avatar: string; name: string }[]>('/retrieveEmployeesByProperty', 'post', { propertyId });
  }

  getCurrentUser() {
    return this.sendRequest<UserDocument>('/getCurrentUser', 'get', {});
  }

  // Sends a request to signupService to check if new users orgName and username already exists
  async checkIfUserAndOrgExists(username: string, orgName: string) {
    const response = await fetch('/api/signup?username=' + username + '&orgname=' + orgName);
    const userExists = await response.json();
    return userExists;
  }

  async getUserWithResetToken(userId: string, passwordResetToken: string) {
    return this.sendRequest<UserDocument>('/getUserWithResetToken', 'post', {
      id: userId,
      passwordResetToken: passwordResetToken,
    });
  }
}
