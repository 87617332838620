import * as React from 'react';
import * as ReactDom from 'react-dom';

// Defines the various alert message types.
export type AlertType = 'error' | 'info' | 'success' | 'warning';

// Contains information about an alert message.
export interface AlertInfo {
  text: React.ReactNode;
  title: React.ReactNode;
  type: AlertType;
}

// Alert utility class for displaying alerts. This class will revert
// to the default alert() Javascript method if the custom alerts
// cannot be displayed properly.
export class Alert extends React.Component<{}, AlertInfo> {
  // Indicates whether an alert is currently being displayed.
  busy = false;

  // A JQuery reference to the component itself.
  component: JQuery;

  // The Alert singleton instance.
  static instance: Alert;

  // The queue of alerts to display.
  queue: AlertInfo[] = [];

  closeTimer: NodeJS.Timeout;

  constructor(props, context) {
    super(props, context);
    this.state = {} as AlertInfo;
  }

  close = () => {
    clearTimeout(this.closeTimer);
    Alert.instance.component.sidebar('hide');
  };

  componentDidMount() {
    Alert.instance = this;
    this.configureAlert();
  }

  // Not sure if this is needed.
  componentDidUpdate() {
    this.configureAlert();
  }

  configureAlert() {
    this.component = $(ReactDom.findDOMNode(this) as any as JQuery<HTMLElement>);

    this.component.sidebar({
      closable: false,
      context: this.component.parent().first(),
      dimPage: false,
      transition: 'overlay',

      onHidden: () => {
        this.busy = false;
        Alert.instance.showNext();
      },
    });
  }

  render() {
    return (
      <div className="top sidebar ui push" style={{ background: 'transparent', border: 'none', boxShadow: 'none' }}>
        <div
          className={'ui small message ' + this.state.type}
          style={{
            boxShadow: '2px 2px 18px -4px rgba(0,0,0,0.8)',
            margin: '1rem',
            marginLeft: 'auto',
            width: 'calc(100% - 2rem)',
            maxWidth: '30rem',
          }}
        >
          <i className="close icon" onClick={this.close}></i>
          <h3 className="header">{this.state.title}</h3>
          <p>{this.state.text}</p>
        </div>
      </div>
    );
  }

  static show(text: React.ReactNode, title: React.ReactNode = '', type: AlertType = 'info') {
    const inst = Alert.instance;

    if (!inst) {
      console.warn('No alert instance exists - using default alert');
      alert(text);
      return;
    }

    inst.queue.push({
      text: text,
      title: title,
      type: type,
    });

    inst.showNext();
  }

  showNext() {
    if (this.busy || !this.queue.length) {
      return;
    }
    const nextAlert = this.queue.shift() as AlertInfo;

    let timeout = 0;
    if (nextAlert.type === 'success') timeout = 5000;
    if (nextAlert.type === 'info') timeout = 10000;
    if (nextAlert.type === 'warning') timeout = 20000;
    if (nextAlert.type === 'error') timeout = 60000;
    this.closeTimer = setTimeout(() => {
      Alert.instance.component.sidebar('hide');
    }, timeout);

    this.busy = true;
    this.setState(nextAlert);
    this.component.sidebar('toggle');
  }
}
