import Moment from 'moment-timezone';

/** Formats an input in minutes to a text string */
export function formatDuration(totalMinutes: number, locale: Locale, short?: boolean): string {
  let result = '';

  const duration = Moment.duration(totalMinutes, 'minutes');

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let daySuffix = short ? 'd' : days == 1 ? 'dag' : 'dagar';
  let hourSuffix = short ? 'h' : hours == 1 ? 'timme' : 'timmar';
  let minuteSuffix = short ? 'min' : minutes == 1 ? 'minut' : 'minuter';
  let secondsSuffix = short ? 's' : seconds == 1 ? 'sekund' : 'sekunder';

  // TODO: Should be translated properly
  if (locale == 'de') {
    daySuffix = short ? 'T' : days == 1 ? 'Tag' : 'Tagen';
    hourSuffix = short ? 'h' : hours == 1 ? 'Stunde' : 'Stunden';
    minuteSuffix = short ? 'min' : minutes == 1 ? 'Minuten' : 'Minuten';
    secondsSuffix = short ? 's' : seconds == 1 ? 'Sekunden' : 'Sekunden';
  } else if (locale == 'en') {
    daySuffix = short ? 'd' : days == 1 ? 'day' : 'days';
    hourSuffix = short ? 'h' : hours == 1 ? 'hour' : 'hours';
    minuteSuffix = short ? 'min' : minutes == 1 ? 'minute' : 'minutes';
    secondsSuffix = short ? 's' : seconds == 1 ? 'second' : 'seconds';
  }

  // Build the string
  if (days > 0) {
    result += `${days} ${daySuffix} `;
  }
  if (hours > 0) {
    result += `${hours} ${hourSuffix} `;
  }
  if (minutes > 0) {
    result += `${minutes} ${minuteSuffix} `;
  }

  if (!result.length && seconds > 0) {
    result += `${seconds} ${secondsSuffix} `;
  }
  return result.trim();
}
