import { findKey, keys, merge } from 'lodash';

export interface Tag {
  nameSv: string;
  nameEn: string;
  nameDe: string;
  selectedIconUrl: string;
  unSelectedIconUrl: string;
}

export const activityCategoryTags = {
  speed: {
    nameSv: 'Fartfyllt',
    nameEn: 'Speed',
    nameDe: 'Schwung',
    unSelectedIconUrl: '/static/platform/img/icons/speed-unselected.png',
    selectedIconUrl: '/static/platform/img/icons/speed-selected.png',
  },
  climb: {
    nameSv: 'Klättring',
    nameEn: 'Climbing',
    nameDe: 'Kletterei',
    unSelectedIconUrl: '/static/platform/img/icons/climbing-unselected.png',
    selectedIconUrl: '/static/platform/img/icons/climbing-selected.png',
  },
  water: {
    nameSv: 'Vatten',
    nameEn: 'Water',
    nameDe: 'Wasser',
    unSelectedIconUrl: '/static/platform/img/icons/watersport-unselected.png',
    selectedIconUrl: '/static/platform/img/icons/watersport-selected.png',
  },
  sky: {
    nameSv: 'I luften',
    nameEn: 'Sky',
    nameDe: 'In der luft',
    unSelectedIconUrl: '/static/platform/img/icons/in-air-unselected.png',
    selectedIconUrl: '/static/platform/img/icons/in-air-selected.png',
  },
  expedition: {
    nameSv: 'Utflykt',
    nameEn: 'Expedition',
    nameDe: 'Ausflug',
    unSelectedIconUrl: '/static/platform/img/icons/expedition-unselected.png',
    selectedIconUrl: '/static/platform/img/icons/expedition-selected.png',
  },
  precision: {
    nameSv: 'Precision',
    nameEn: 'Precision',
    nameDe: 'Präzision',
    unSelectedIconUrl: '/static/platform/img/icons/precision-unselected.png',
    selectedIconUrl: '/static/platform/img/icons/precision-selected.png',
  },
  team: {
    nameSv: 'Lagsport',
    nameEn: 'Team',
    nameDe: 'Team-sport',
    unSelectedIconUrl: '/static/platform/img/icons/teamsport-unselected.png',
    selectedIconUrl: '/static/platform/img/icons/teamsport-selected.png',
  },
  relax: {
    nameSv: 'Avkoppling',
    nameEn: 'Relaxation',
    nameDe: 'Entspannung',
    unSelectedIconUrl: '/static/platform/img/icons/relaxing-unselected.png',
    selectedIconUrl: '/static/platform/img/icons/relaxing-selected.png',
  },
};

/** This method gets an available tag in the correct translation from activity category tags */
export const GET_FORMATTED_TAG = (tagId: string, locale: Locale) => {
  const tag = activityCategoryTags[tagId];
  if (!tag) {
    return '';
  }

  switch (locale) {
    case 'sv':
      return tag.nameSv;
    case 'en':
      return tag.nameEn;
    case 'de':
      return tag.nameDe;
    default:
      return tag.nameEn;
  }
};

export class ActivityTagsFormatter {
  protected _activityCategoryTags: { [id: string]: Tag };

  static get allTags() {
    return merge({}, activityCategoryTags);
  }

  static getFormattedTag = (tagId: string, locale: Locale): string => {
    return GET_FORMATTED_TAG(tagId, locale);
  };

  static getFormattedTags = (tagIds: string[], locale: Locale): string[] => {
    return tagIds
      .filter((tagId) => ActivityTagsFormatter.allTags[tagId])
      .map((tagId) => {
        let formattedTag = '';
        switch (locale) {
          case 'sv':
            formattedTag = ActivityTagsFormatter.allTags[tagId].nameSv;
            break;
          case 'en':
            formattedTag = ActivityTagsFormatter.allTags[tagId].nameEn;
            break;
          case 'de':
            formattedTag = ActivityTagsFormatter.allTags[tagId].nameDe;
            break;
          default:
            formattedTag = ActivityTagsFormatter.allTags[tagId].nameEn;
            break;
        }
        return formattedTag;
      });
  };

  static getTagIds = (formattedTags: string[]): string[] => {
    return formattedTags
      .map((formattedTag) =>
        findKey(
          ActivityTagsFormatter.allTags,
          (tag: Tag) => formattedTag === tag.nameSv || formattedTag === tag.nameEn || formattedTag === tag.nameDe,
        ),
      )
      .filter(Boolean) as string[];
  };

  static get activityCategoryTags(): string[] {
    return keys(activityCategoryTags);
  }

  static getUnselectedIconUrl = (tagId: string) => {
    return ActivityTagsFormatter.allTags[tagId].unSelectedIconUrl;
  };

  static getSelectedIconUrl = (tagId: string) => {
    return ActivityTagsFormatter.allTags[tagId].selectedIconUrl;
  };
}
