import { isEqual, range } from 'lodash';
import React, { useContext } from 'react';
import { DropdownField } from '.';
import { SessionContext } from '../../../session.context';
import {
  ActivityOccuranceDocument,
  BookingPriceCategoryDocument,
  DropdownItem,
  Field,
  getAccessories,
  PriceCategoryDocument,
  tt,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { combinePriceCategories } from '../selectPriceCategoryHelperFunctions';

interface Props {
  occurance: ActivityOccuranceDocument;
  selectedPriceCategories: BookingPriceCategoryDocument[];
  bookedPriceCategories?: BookingPriceCategoryDocument[];
  alreadyReservedVisitors?: number;
  setVisitorsFreely: boolean;
  onChange: (tickets: number, priceCategoy: PriceCategoryDocument) => void;
  disabled: boolean;
}

export const AccessoryDropdowns = ({
  occurance,
  selectedPriceCategories,
  setVisitorsFreely,
  onChange,
  disabled,
  bookedPriceCategories,
}: Props) => {
  const session = useContext(SessionContext);
  const { t } = useLocale();

  const { property, organization, priceCategories } = occurance;
  const notManager = !session.userHasRole({ type: 'property.manager', property, organization });
  const allCategories = combinePriceCategories(priceCategories, bookedPriceCategories);
  const accessories = getAccessories(allCategories);

  if (!accessories.length) return null;

  return (
    <Field
      style={{ marginTop: '1.5rem' }}
      label={
        <span style={{ fontSize: '1.4rem', fontWeight: 'normal' }}>
          {bookedPriceCategories ? t('Edit accessories') : t('Add accessories')}
        </span>
      }
    >
      {accessories.map((priceCategory, index) => {
        if (priceCategory.isHidden && notManager) return null;

        const selectedCategory = selectedPriceCategories.find(
          ({ name, price }) => isEqual(name, priceCategory.name) && price === priceCategory.price,
        );
        const selectedTickets = selectedCategory?.tickets || 0;
        const dropdownItems = range(100).map((i) => <DropdownItem key={i} value={i} />);

        return (
          <DropdownField
            key={'accessory' + tt(priceCategory.name, 'sv')}
            name={'accessory' + index}
            selectedTickets={selectedTickets}
            onChange={(tickets) => onChange(Number(tickets), priceCategory)}
            isInceaseButtonEnabled={true}
            setVisitorsFreely={setVisitorsFreely}
            priceCategory={priceCategory}
            dropdownItems={dropdownItems}
            disabled={disabled}
          />
        );
      })}
    </Field>
  );
};
