import React, { useState, useContext } from 'react';
import {
  DeviceProvider,
  DiscountDocument,
  DiscountTimeLimit,
  Alert,
  LocaleContext,
  DiscountUsages,
} from '../../../_dependencies';
import { DiscountForm } from './discountForm';
import { getDiscountPercentage, getDiscountUsageLimit } from './discountHandlers';

interface Props {
  discountUsage: DiscountUsages | undefined;
  discount: DiscountDocument;
  canEdit: boolean;
  saveDiscount?: () => void;
  deleteDiscount: (discountId: string) => void;
  handleDates: (timeLimits: DiscountTimeLimit[], key: string) => any;
  getDiscountStatus: (discount: DiscountDocument) => string;
}

export const DiscountDetails = ({
  handleDates,
  getDiscountStatus,
  deleteDiscount,
  discount,
  canEdit,
  discountUsage,
}: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(false);
  const device = React.useContext(DeviceProvider.Context);
  const mobile = device.size === 'mobile';
  const { t } = useContext(LocaleContext);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    Alert.show(t('Discount code copied!'), text);
  };

  const handleRowClicked = () => {
    if (isEditDisabled || !canEdit) return;
    setIsEditing(true);
  };

  function finishedEditing() {
    setIsEditing(false);
  }

  if (isEditing) {
    return (
      <DiscountForm
        deleteDiscount={deleteDiscount}
        discountUsage={discountUsage}
        discount={discount}
        finishedEditing={finishedEditing}
        handleDates={handleDates}
        getDiscountStatus={getDiscountStatus}
      />
    );
  }

  if (mobile) {
    return (
      <tr className="center aligned" onClick={() => handleRowClicked()}>
        <td className="left aligned">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <i className={`circle icon ${getDiscountStatus(discount)}`} />
            </div>
            <div>
              <h5 className="ui header">{discount.code}</h5>
            </div>
          </div>
        </td>
        <td className="center aligned" style={{ fontSize: '0.8em' }}>
          {getDiscountPercentage(discount.discountPercentage)}%
        </td>
        <td className="center aligned" style={{ fontSize: '0.8em', whiteSpace: 'nowrap' }}>
          {handleDates(discount.timeLimits, 'end')}
        </td>
        <td className="right aligned">
          <div className="ui icon button">
            <i
              style={{ margin: 'auto' }}
              className={'icon clipboard blue outline'}
              onMouseOver={() => setIsEditDisabled(true)}
              onMouseLeave={() => setIsEditDisabled(false)}
              onClick={() => copyToClipboard(discount.code)}
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      <tr className="center aligned" onClick={() => handleRowClicked()}>
        <td className="left aligned" style={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle' }}>
          <div
            data-tooltip={t('Copy Code')}
            data-position={'top left'}
            data-variation={'basic'}
            style={{ cursor: 'grab' }}
          >
            <i
              style={{ paddingRight: '1.5em' }}
              className={'blue icon clipboard outline'}
              onMouseOver={() => setIsEditDisabled(true)}
              onMouseLeave={() => setIsEditDisabled(false)}
              onClick={() => copyToClipboard(discount.code)}
            />
          </div>
          <h5 className="ui header" style={{ cursor: 'pointer', margin: '0' }}>
            {discount.code}
          </h5>
        </td>
        <td className="left aligned">{getDiscountPercentage(discount.discountPercentage) + '%'}</td>
        <td className="left aligned">
          {getDiscountUsageLimit(discount.usageLimit, discountUsage?.numberOfUses) ? (
            getDiscountUsageLimit(discount.usageLimit, discountUsage?.numberOfUses)
          ) : (
            <span>&#8734;</span>
          )}
        </td>
        <td className="left aligned">{handleDates(discount.timeLimits, 'end')}</td>
        <td className="left aligned">
          <i className={`circle icon ${getDiscountStatus(discount)}`} />
        </td>
      </tr>
    </>
  );
};
