import { RRule } from 'rrule';

// Endtime key used in encoding and decoding
const ENDTIME = 'ENDTIME';

/**
 * @interface RuleObject
 * The object used when encoding and
 * decoding rrule strings */
export interface RuleObject {
  rule: RRule;
  endTime: Date;
}

/**
 * @function Encode
 * Used to encode a rrule string with an extra key of endtime
 * used to calculate duration of scheduled time.
 * @returns {String} The encoded string for storage */
export function Encode(ruleObj: RuleObject): string {
  const { rule, endTime } = ruleObj;
  return `${rule.toString()};${ENDTIME}=${endTime.toString()}`;
}

/**
 * @function Decode
 * Used to decode a encoded string and extract the RRule object and
 * the endtime in seperate objects
 * @returns The RuleObject with the seperated information */
export function Decode(ruleStr: string): RuleObject {
  const index = ruleStr.indexOf(`;${ENDTIME}=`);
  const rruleStr = ruleStr.slice(0, index);
  const dateStr = ruleStr.slice(index + `;${ENDTIME}=`.length);
  return {
    rule: RRule.fromString(rruleStr),
    endTime: new Date(dateStr),
  };
}

/**
 * @function Validate
 * Checks a ruleString and makes sure that it is a valid
 * decodable string
 * @returns true if valid, else false
 */
export function Validate(ruleStr: string): boolean {
  const index = ruleStr.indexOf(`;${ENDTIME}=`);
  const rruleStr = ruleStr.slice(0, index);
  try {
    const rule = RRule.fromString(rruleStr);
    if (!rule) throw new Error();
    return true;
  } catch {
    return false;
  }
}
