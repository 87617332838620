import Lodash from 'lodash';
import * as React from 'react';
import { GoogleMap } from 'react-google-maps';
import { ActivityTypeDocument, MobxComponent, PropertyDocument } from '../../_dependencies';
import GoogleMapsWrapper from './googleMapsWrapper';
import Store from './store';

interface Props {
  propertyActivityMap: { property: PropertyDocument; activityTypes: ActivityTypeDocument[] }[];
  userLocation?: Coordinates;
}

interface State {
  newMapLocation?: { longitude: number; latitude: number };
}

export class Map extends MobxComponent<Props, State> {
  state: State = {};

  private googleMap: GoogleMap;
  private mapNeedsToUpdateBounds: boolean;
  private mapApiLoader: any;

  private get defaultCenter() {
    return { lat: 58.28365, lng: 12.28864 };
  }
  private zoomChanged = () => Store.deselectProperty();

  private openCard = (property: PropertyDocument) => {
    if (Store.isPropertySelected(property)) {
      this.setState({ newMapLocation: undefined });
      Store.deselectProperty();
    } else {
      Store.selectProperty(property, property.occurences ? property.occurences[0].id : undefined);
      const [longitude, latitude] = property.location;
      this.setState({ newMapLocation: { longitude, latitude } });

      // Pan the camera to account for the property card
      const cardHeightOffset = -220; // <- should probably be fetched
      this.googleMap.panBy(0, cardHeightOffset);
    }
  };

  private get mapLocation() {
    if (!this.googleMap) {
      return this.defaultCenter;
    }
    // Return current map location if no property is selected
    if (!Store.selectedProperty) {
      const center = this.googleMap.getCenter();
      return { lat: center.lat(), lng: center.lng() };
    }
    const location = { lat: Store.selectedProperty.location[1], lng: Store.selectedProperty.location[0] };

    return location;
  }

  private findMapBounds() {
    let lgnMin = Number.MAX_VALUE;
    let lgnMax = Number.MIN_VALUE;
    let latMin = Number.MAX_VALUE;
    let latMax = Number.MIN_VALUE;
    if (this.state.newMapLocation) {
      const { latitude, longitude } = this.state.newMapLocation;
      lgnMin = longitude - 0.1;
      lgnMax = longitude + 0.1;
      latMin = latitude - 0.1;
      latMax = latitude + 0.1;
      return { lgnMin, lgnMax, latMin, latMax };
    }

    Lodash(this.props.propertyActivityMap!)
      .map((x) => x.property)
      .each(({ location }) => {
        if (location && location.length === 2) {
          const [lng, lat] = location;

          // Checking LONG
          if (lng && lng < lgnMin) {
            lgnMin = location[0];
          }
          if (lng && lng > lgnMax) {
            lgnMax = location[0];
          }

          // Checking LAT
          if (lat && lat < latMin) {
            latMin = location[1];
          }
          if (lat && lat > latMax) {
            latMax = location[1];
          }
        }
      });

    // Zoom out a bit when only one property
    if (this.props.propertyActivityMap!.length == 1) {
      lgnMin -= 0.01;
      lgnMax += 0.01;
      latMin -= 0.01;
      latMax += 0.01;
    }

    return { lgnMin, lgnMax, latMin, latMax };
  }

  private updateMapBounds = () => {
    // Make sure that no property is selected
    Store.deselectProperty();

    // Update bounds
    const mapBounds = this.findMapBounds();

    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(mapBounds.latMin, mapBounds.lgnMin),
      new google.maps.LatLng(mapBounds.latMax, mapBounds.lgnMax),
    );
    this.googleMap.fitBounds(bounds);
    this.mapNeedsToUpdateBounds = false;
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!Lodash.isEqual(nextProps.userLocation, this.props.userLocation)) {
      this.setState({ newMapLocation: nextProps.userLocation });
    }
    const currentProperties = Lodash.map(this.props.propertyActivityMap, (v) => v.property.id);
    const nextProperties = Lodash.map(nextProps.propertyActivityMap, (v) => v.property.id);
    if (!Lodash.isEqual(currentProperties, nextProperties)) {
      this.mapNeedsToUpdateBounds = true;
    } else {
      this.mapNeedsToUpdateBounds = false;
    }
    if (!Lodash.isEqual(nextProps.userLocation, this.props.userLocation)) {
      this.mapNeedsToUpdateBounds = true;
    }
  }

  componentDidUpdate() {
    if (typeof google !== 'undefined' && this.mapNeedsToUpdateBounds) {
      this.updateMapBounds();
    } else if (this.mapNeedsToUpdateBounds) {
      clearInterval(this.mapApiLoader);
      this.mapApiLoader = setInterval(() => {
        if (typeof google !== 'undefined') {
          clearInterval(this.mapApiLoader);
          this.updateMapBounds();
        }
      }, 100);
    }
  }

  render() {
    return (
      <GoogleMapsWrapper
        propertyActivityMap={this.props.propertyActivityMap}
        selectedProperty={Store.selectedProperty}
        mapLocation={this.mapLocation}
        onOpenCard={this.openCard}
        onZoomChanged={this.zoomChanged}
        onRef={(googleMap) => (this.googleMap = googleMap)}
        userPosition={this.props.userLocation}
        /* HOC props */
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDNeWxgti3E_OsTgxut6uFb4lBvV1a3dAY&v=3.exp&libraries=geometry,drawing,places"
      />
    );
  }
}
