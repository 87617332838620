import React from 'react';
import { InventoryStatusDocument, PlaceDocument } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { LocationDropdown } from '../../inputs /locationDropdown';
import { renderPlace } from '../inventoryItemStatusForm';

interface Props {
  currentStatus?: InventoryStatusDocument;
  places?: PlaceDocument[];
  onDestinationChange: (place: PlaceDocument) => void;
}

export const DestinationSegment = ({ onDestinationChange, places, currentStatus }: Props) => {
  const { t, tt } = useLocale();

  return (
    <div style={smallerContainerStyle}>
      <h4 style={{ color: 'black', paddingBottom: '0.05em' }}>
        <strong>{t('Set the items location')}</strong>
      </h4>
      <LocationDropdown
        resetOption={false}
        placeHolder={currentStatus?.location ? tt(renderPlace(places, undefined, currentStatus)?.name) : t('Location')}
        locations={places}
        setPlace={(place: PlaceDocument) => {
          onDestinationChange(place);
        }}
      />
    </div>
  );
};

export const smallerContainerStyle: React.CSSProperties = {
  marginBottom: '1rem',
  backgroundColor: '#FFF',
  borderRadius: '0.5em',
  padding: '1em',
};
