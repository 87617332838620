import React from 'react';
import { useLocale } from '../../../../_locales';

interface Props {
  calendarShowing: 'payouts' | 'statistics';
  toggleCalendarEventHandler: () => void;
}

const ToggleCalendar = ({ calendarShowing, toggleCalendarEventHandler }: Props): JSX.Element => {
  const { t } = useLocale();

  return (
    <div style={{ marginBottom: '-20px' }}>
      <button style={buttonStyle} onClick={toggleCalendarEventHandler} className="ui primare button">
        {t('Click here')}{' '}
      </button>
      <span style={{ display: 'inline-block' }}> </span>
      <p style={{ display: 'inline-block', margin: 0 }}>
        {calendarShowing === 'payouts'
          ? t('if you want to get access to Borås ticket statistics.')
          : t('to retrieve information about payouts sent to your bank account.')}
      </p>{' '}
    </div>
  );
};

export default ToggleCalendar;

const buttonStyle: React.CSSProperties = {
  display: 'inline-block',
  background: 'none',
  color: 'rgb(41, 187, 156)',
  border: 'none',
  padding: 0,
  font: 'inherit',
  cursor: 'pointer',
  outline: 'inherit',
};
