import { Schema } from 'mongoose';

/** A location from the database */
export interface LocationDocument {
  /** Location latitude */ lat: number;
  /** Location longitude */ lng: number;
}

/** Database schema for location documents */
export const LocationSchema = new Schema(
  {
    lat: { type: Number, required: true },
    lng: { type: Number, required: true },
  },
  { _id: false },
);

/*
NOTE: Location documents are currently stored in an inefficent way for lookups in
the database (i.e. getting all locations in a given area), this is the optimized
way of storing lat,long in the database and is also used in the property resource.
Property resource is older than LocationDocument and if possible the types should be merged
to optimize searching/storage space. To not sacrifice code-compilability a virtual get/set
can be used

type: location: [long: number, lat: number];
schema: { type: [Number], index: '2dsphere', default: []}

converter from this to the current LocationDocument, used in weather context:
const [longitude, latitude] = property.location;
*/
