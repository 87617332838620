import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Consume, KosmicComponent, LocaleContext, OrganizationDocument } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import DashboardTemplate from '../dashboardTemplate';
import { ClassicWidgetSelectionView } from './_classicWidgetSelectionView';
import { PropertyWidgetSelectionView } from './_propertyWidgetSelectionView';
import { ShowcaseWidgetSelectionView } from './_showcaseWidgetSelectionView';

/**
 * The widgets section renders a common templates, including
 * tabs and subroutes for navigating around the section of the
 * dashboard dedicated to maneging embeddable widgets
 */
export class WidgetsSection extends KosmicComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  render() {
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    const { features } = organization.flags;
    const { t } = this._locale;

    if (!features.widgets) {
      this.globals.session.navigateTo('/dashboard');
      return null;
    }

    const tabs: DashboardTemplate['props']['tabs'] = [
      {
        path: '/dashboard/widget/property',
        text: t('Widget for your property'),
        icon: 'map pin icon',
      },
      {
        path: '/dashboard/widget/showcase',
        text: t('Widget showcasing your offers'),
        icon: 'star outline icon',
      },
    ];

    // NOTE: the classic iFrame widget is still usable, but not available for new customers to add to their site
    if (this.globals.session.userHasRole('system.owner')) {
      tabs.push({
        path: '/dashboard/widget/classic',
        text: t('Classic Widget (only for platform owner)'),
        icon: 'cube icon',
      });
    }

    return (
      <DashboardTemplate title={t('Widgets')} icon="cube icon" tabs={tabs}>
        <div style={{ padding: '2em' }}>
          <h2 className="ui header">
            {t('components.dashboard.widgets.widgetsSection.createWidget')}
            <div className="sub header">
              <div style={{ height: '0.5em' }} />
              {t('components.dashboard.widgets.widgetsSection.createWidgetDescription')}
            </div>
          </h2>
        </div>
        <Switch>
          <Route exact path="/dashboard/widget/property" component={PropertyWidgetSelectionView} />
          <Route exact path="/dashboard/widget/showcase" component={ShowcaseWidgetSelectionView} />
          <Route exact path="/dashboard/widget/classic" component={ClassicWidgetSelectionView} />
          <Redirect to="/dashboard/widget/property" />
        </Switch>
        {this.props.children}
      </DashboardTemplate>
    );
  }
}
