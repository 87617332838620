import React, { useContext } from 'react';
import { OffersContext } from '../../../../../contexts/offers.context';
import { ActivityTypeDocument, Button, Field, Input, PriceCategoryDocument, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { InventoriesToggle } from '../createPriceCategories/inventoriesToggle';
import { InventoryDropdown } from './inventoryDropdown';

interface IAvailableVisitorsProps {
  visitorCapacity: number;
  displayInventory: boolean;
  toggleDisplayInventory: () => void;
  inventories: ActivityTypeDocument['inventories'];
  updateSelectedOffer: OffersContext['updateSelectedOffer'];
  priceCategories: PriceCategoryDocument[];
}

export const AvailableVisitors = ({
  visitorCapacity,
  displayInventory,
  toggleDisplayInventory,
  inventories = [],
  updateSelectedOffer,
  priceCategories,
}: IAvailableVisitorsProps) => {
  const { t } = useLocale();
  const capacity = visitorCapacity ? visitorCapacity : '';
  const { selectedOffer } = useContext(OffersContext);

  const inventoryInput = (value) => {
    if (value === 'nothing-selected') {
      updateSelectedOffer('inventories', undefined);
    } else {
      updateSelectedOffer('inventories', [value]);
    }
  };

  const updateVisitorCapacity = (value?: string) => {
    if (!value) return updateSelectedOffer('visitorCapacity', 0);
    return updateSelectedOffer('visitorCapacity', parseInt(value));
  };

  return displayInventory ? (
    <div className="ui grid" style={{ marginTop: '1em', marginBottom: '1em' }}>
      <Field width={9} label={t('Select inventory')}>
        <InventoryDropdown onChange={inventoryInput} inventory={inventories} />
      </Field>
      <Field label={' '} width={7} style={{ padding: '0 1em', marginBottom: '0' }}>
        <Button
          className="fluid basic"
          onClick={() => {
            inventoryInput('nothing-selected');
            toggleDisplayInventory();
          }}
        >
          {t("Don't use inventory")}
        </Button>
      </Field>
    </div>
  ) : (
    <div className="ui grid" style={{ marginTop: '1em', marginBottom: '1em', flexDirection: 'column' }}>
      <div style={{ padding: '1em' }}>
        <InventoriesToggle priceCategories={priceCategories} />
      </div>
      {!selectedOffer?.useInventory && (
        <Field label={t('Amount available')} width={9} style={{ padding: '0 1em', marginBottom: '0' }}>
          <Input
            disabled={selectedOffer?.isTmeTicket}
            name="Antal platser för besökande"
            icon="male"
            value={selectedOffer?.isTmeTicket ? String(10000) : String(capacity)}
            placeholder={t('Number of visitors who can book the activity')}
            onChange={updateVisitorCapacity}
            rules={[
              Rules.NotEmpty(t('Fill in the number of slots available for visitors')),
              Rules.IsAnInteger(t('You must enter a number')),
            ]}
          />
        </Field>
      )}
    </div>
  );
};
