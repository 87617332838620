import Fuse from 'fuse.js';
import Moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import {
  GiftCardDocument,
  GiftCardResource,
  GlobalsContext,
  Input,
  MarketplaceResource,
  Table,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { Header } from '../bookings/Header';

interface CreditCodeWithAvailability extends GiftCardDocument {
  isStillAvailable: boolean;
  valueLeft: number;
}

export function CreditCodesListView() {
  const { t } = useLocale();
  const { session } = useContext(GlobalsContext);
  const [creditCodes, setCreditCodes] = useState<CreditCodeWithAvailability[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * Calculates the status for the code.
   * This operation should probably be moved to the resource in the future.
   * Logic modeled after similar behavior in soldGiftCardsStore.ts
   * @param creditCode The document
   * @returns true if creditcode is unused/not expired, false otherwise
   */
  const fetchPaymentStatusesAndUpdatedValues = async (
    creditCode: GiftCardDocument,
  ): Promise<CreditCodeWithAvailability> => {
    const isExpired = Moment().isAfter(creditCode.expirationDate, 'day');
    const hasUsesLeft = Boolean(creditCode.usesLeft);
    let valueLeft: number;
    try {
      // Try finding usage in marketplace
      const { amountUsed } = await new MarketplaceResource().getGiftcardPaymentStatus(creditCode.number);
      valueLeft = (creditCode.value || 0) - amountUsed / 100;
    } catch {
      valueLeft = creditCode.value || 0;
    }
    const availability = !isExpired && hasUsesLeft && Boolean(valueLeft);
    return { ...creditCode, valueLeft: valueLeft, isStillAvailable: availability } as CreditCodeWithAvailability;
  };

  useEffect(() => {
    (async () => {
      const creditCodes = await new GiftCardResource().find({
        'limitations.organizations': session.currentOrganizationId,
        voucher: { $exists: true },
      });
      const codesWithAvailability = await Promise.all(
        creditCodes.map((code) => fetchPaymentStatusesAndUpdatedValues(code)),
      );
      setCreditCodes(codesWithAvailability);
      setIsLoading(false);
    })();
  }, []);

  const fuse = new Fuse(creditCodes, {
    threshold: 0.4,
    keys: ['number', 'customer.firstname', 'customer.lastname', 'customer.businessName', 'customer.email'],
  });

  const filteredCreditCodes = searchQuery ? fuse.search(searchQuery).map(({ item }) => item) : creditCodes;

  return (
    <div style={{ padding: '2em' }}>
      <Header title={t('Credit codes')} sub={t('Below is a list of all credit codes...')} />
      <div className={'ui basic segment ' + (isLoading ? 'loading' : '')} style={{ padding: '1rem 0 0 0' }}>
        <Input
          className="large fluid transparent"
          placeholder="Sök efter kod, namn eller email"
          name="search"
          icon="search"
          value={searchQuery}
          onChange={setSearchQuery}
        />
        <div className="ui divider" style={{ marginBottom: '2em' }} />

        <Table
          dataList={filteredCreditCodes}
          headers={[
            { title: t('Credit code') },
            { title: t('Value (left)') },
            { title: t('Customer') },
            { title: t('Email') },
            { title: t('Valid') },
            { title: t('Status') },
          ]}
          renderRow={(creditCode) => (
            <tr key={creditCode.id}>
              <td>{creditCode.number}</td>
              <td>
                {creditCode.value}
                {t('kr-SEK')} ({creditCode.valueLeft}
                {t('kr-SEK')})
              </td>
              <td>
                {creditCode.customer.firstname} {creditCode.customer.lastname}{' '}
                {creditCode.customer.businessName ? '(' + creditCode.customer.businessName + ')' : ''}
              </td>
              <td>{creditCode.customer.email}</td>
              <td>{Moment(creditCode.expirationDate).format('YYYY-MM-DD')}</td>
              <td>{creditCode.isStillAvailable ? '🟢' : '🔴'}</td>
            </tr>
          )}
        />
      </div>
    </div>
  );
}
