import Lodash from 'lodash';
import * as React from 'react';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';
import { Consume, Dropdown, DropdownItem, KosmicComponent, LocaleContext, SubmitButton } from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';

export class ActivityTypeSelectionView extends KosmicComponent<{}> {
  @Consume(WidgetDataProvider.Context)
  private widgetData: IWidgetData;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  componentDidMount() {
    this.widgetData.verifyStepNavigation('activityTypeSelection');
  }

  private renderSelectActivityType() {
    const { t, tt } = this._locale;
    const { selectedActivityType } = this.widgetData;
    const activityHeaderText = t('Activity');
    const activitySubHeaderText = t('widget.selectionSteps.activityTypeSelectionView.subHeaderOne');

    if (this.widgetData.isloading && !this.widgetData.availableActivityTypes.length) {
      return (
        <div>
          <div className="ui one column grid">
            <div className="ui sixteen wide column">
              <h2 className="ui primary header">
                <div className="content">
                  {activityHeaderText}
                  <div className="sub header">{activitySubHeaderText}</div>
                </div>
              </h2>
            </div>
          </div>
          <div className="ui one column grid">
            <div className="ui sixteen wide column">
              <div className="ui active loader"></div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h2 className="ui primary header">
          <div className="content">
            {activityHeaderText}
            <div className="sub header">{activitySubHeaderText}</div>
          </div>
        </h2>
        <Dropdown
          name="Anläggning"
          fluid
          placeholder={t('widget.selectionSteps.activityTypeSelectionView.dropdown')}
          value={selectedActivityType && selectedActivityType.id}
          onChange={this.widgetData.fetchActivityType}
        >
          {Lodash.map(this.widgetData.availableActivityTypes, (offer) => {
            return (
              <DropdownItem value={offer.id} key={offer.id}>
                <h5>{tt(offer.title)}</h5>
              </DropdownItem>
            );
          })}
        </Dropdown>
      </div>
    );
  }

  private renderActivityDescription() {
    const { selectedActivityType } = this.widgetData;
    const { t, tt } = this._locale;

    if (selectedActivityType) {
      return (
        <React.Fragment>
          <div className="ui one column grid">
            <div className="ui sixteen wide column">
              <h2 className="ui primary header">
                <div className="content">
                  {tt(selectedActivityType.title)}
                  <div className="sub header" style={{ marginTop: '1.5em', whiteSpace: 'pre-line' }}>
                    {tt(selectedActivityType.description)}
                  </div>
                </div>
              </h2>
            </div>
          </div>

          <div className="ui one column grid" style={{}}>
            <div className="ui sixteen wide column" style={{ paddingTop: '0' }}>
              <img className="ui fluid image" src={selectedActivityType.imageUrl}></img>
            </div>
          </div>

          <div className="ui one column grid">
            <div className="ui sixteen wide column">
              <SubmitButton
                onClick={() => this.widgetData.gotoStep('timeSelection')}
                className="large right floated green button"
              >
                {t('Continue')} <i className="arrow circle right icon" />
              </SubmitButton>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  public render() {
    return (
      <div className="ui container">
        <div className="ui grid">
          <div className="eight wide column">{this.renderSelectActivityType()}</div>
          <div className="eight wide column">{this.renderActivityDescription()}</div>
        </div>
      </div>
    );
  }
}
