import { useContext } from 'react';
import {
  generateTranslationCollection,
  generateTranslationsFunction,
  giftCardPreviewCollection,
  LocaleContext,
  LocaleContextState,
} from './_dependencies';

const translations = generateTranslationsFunction<{
  sv: string;
  en: string | null;
  de: string | null;
}>();

export const { globalCurrentLocale, collection: ahWidgetCollection } = generateTranslationCollection({
  'kr-SEK': translations({
    sv: 'kr',
    en: 'SEK',
    de: 'SEK',
  }),
  'Pricing info': translations({
    sv: 'Prisinformation',
    en: 'Pricing info',
    de: 'Preisinformationen',
  }),
  products: translations({
    sv: 'produkter',
    en: 'products',
    de: 'Produkte',
  }),
  for: translations({
    sv: `för`,
    en: `for`,
    de: `für`,
  }),
  each: translations({
    sv: `styck`,
    en: `each`,
    de: `für`,
  }),
  pcs: translations({
    sv: `st`,
    en: `pcs`,
    de: `st`,
  }),
  days: translations({
    sv: `dagar`,
    en: `days`,
    de: `Tage`,
  }),

  'The activity has a base price of': translations({
    sv: 'Aktiviteten har ett grundpris på',
    en: 'The activity has a base price of',
    de: 'Die Aktivität hat einen Grundpreis von',
  }),
  'Please enter number between 1 and 100': translations({
    sv: 'Ange ett tal mellan 1 och 100',
    en: 'Please enter a number between 1 and 100',
    de: 'Bitte geben Sie eine Zahl zwischen 1 und 100 ein',
  }),
  'Enter base price': translations({
    sv: 'Ange grundpris',
    en: 'Enter base price',
    de: 'Grundpreis eingeben',
  }),
  'Add check in luggage': translations({
    sv: 'Lägg till check-in baggage',
    en: 'Add check in luggage',
    de: 'Aufgabegepäck hinzufüge',
  }),
  'add-base-price': translations({
    sv: 'Lägg till ett grundpris',
    en: 'Add a base price',
    de: 'Fügen Sie Basispreis hinzu',
  }),
  'remove-base-price': translations({
    sv: 'Ta bort grundpriset',
    en: 'Remove base price',
    de: 'Grundpreis entfernen',
  }),
  'Reschedule insurance': translations({
    sv: 'Ombokningsförsäkring',
    en: 'Reschedule insurance',
    de: 'Umbuchungsschutz',
  }),
  'Reschedule insurance price': translations({
    sv: 'Pris för ombokningsförsäkring',
    en: 'Reschedule insurance price',
    de: 'Umbuchungsschutzpreis',
  }),
  'Add reschedule insurance': translations({
    sv: 'Lägg till ombokningsförsäkring',
    en: 'Add reschedule insurance',
    de: 'Fügen Sie Umbuchungsschutz hinzu',
  }),
  'per person': translations({
    sv: 'per person',
    en: 'per person',
    de: 'pro Person',
  }),
  'If a customer chooses to buy a reschedule insurance...': translations({
    sv: 'Om en kund väljer att köpa en ombokningsförsäkring innebär det att kunden själv kan avboka aktiviteten och då få en tillgodokod på samma värde. Koden kan sedan användas för att boka en ny aktivitet hos dig.',
    en: 'If a customer chooses to buy a reschedule insurance, this means that the customer can cancel the activity and receive a voucher code of the same value. The code can then be used to book a new activity with you.',
    de: 'Wenn sich ein Kunde für den Abschluss einer Umbuchungsversicherung entscheidet, bedeutet dies, dass der Kunde die Aktivität stornieren und einen Gutscheincode im gleichen Wert erhalten kann. Der Code kann dann verwendet werden, um eine neue Aktivität bei Ihnen zu buchen.',
  }),
  'Specify below how long the credit code should be valid...': translations({
    sv: 'Nedan anger du hur länge tillgodokoden ska vara giltig, hur tätt inpå aktiviteten kunden får omboka,  samt vad det kostar att köpa en ombokningsförsäkring. ',
    en: 'Specify below how long the credit code should be valid, how close to the activity the customer may rebook, and what it costs to buy a reschedule insurance.',
    de: 'Geben Sie unten an, wie lange der Guthabencode gültig sein soll, wie nah an der Aktivität der Kunde umbuchen darf und was der Abschluss einer Umbuchungsversicherung kostet.',
  }),
  'Credit code validity': translations({
    sv: 'Giltighetstid för tillgodokod',
    en: 'Credit code validity',
    de: 'Gültigkeit des Kreditcodes',
  }),
  Validity: translations({
    sv: 'Giltighetstid',
    en: 'Validity',
    de: 'Gültigkeit',
  }),
  'Latest reschedule': translations({
    sv: 'Senaste ombokning',
    en: 'Latest reschedule',
    de: 'Letzte Verschiebung',
  }),
  'Paid in total': translations({
    sv: 'Totalt betalt',
    en: 'Paid in total',
    de: 'Insgesamt bezahlt',
  }),
  'Of which ### SEK paid': translations({
    sv: 'Varav ### kr betalt',
    en: 'Of which ### SEK paid',
    de: 'Davon ### SEK bezahlt',
  }),
  'Reschedule your activity': translations({
    sv: 'Påbörja din ombokning',
    en: 'Reschedule your activity',
    de: 'Planen Sie Ihre Aktivität neu',
  }),
  'Cancel booking and receive your credit code': translations({
    sv: 'Avboka och få din tillgodokod',
    en: 'Cancel booking and receive your credit code',
    de: 'Buchung stornieren und Guthabencode erhalten',
  }),
  'Your booking has been cancelled and you can now reschedule': translations({
    sv: 'Aktiviteten är nu avbokad och du kan boka en ny tid',
    en: 'Your booking has been cancelled and you can now reschedule',
    de: 'Ihre Buchung wurde storniert und Sie können jetzt umbuchen',
  }),
  'Book a new activity now': translations({
    sv: 'Boka en ny tid nu',
    en: 'Book a new activity now',
    de: 'Jetzt eine neue Aktivität buchen',
  }),
  'Your credit code has also been sent to...': translations({
    ///### is for replacing the text with another value
    sv: 'Du har även fått din tillgodokod utskickad till ### om du vill boka vid ett annat tillfälle. Koden anger du sedan när du är vid betalsteget, och den kan användas vid fler tillfällen om den nya bokningen inte uppnår kodens värde.',
    en: 'Your credit code has also been sent to ### if you want to complete your booking at another time. The code is entered at the payment stage, and it can be used on several occasions if the new booking does not reach the value of the code.',
    de: 'Ihr Guthabencode wurde auch an ### gesendet, wenn Sie Ihre Buchung zu einem anderen Zeitpunkt abschließen möchten. Der Code wird in der Zahlungsphase eingegeben und kann mehrmals verwendet werden, wenn die neue Buchung den Wert des Codes nicht erreicht.',
  }),
  'Your credit code is:': translations({
    sv: 'Din tillgodokod är:',
    en: 'Your credit code is:',
    de: 'Ihr Kreditcode lautet:',
  }),
  'The code is a voucher for...': translations({
    ///### is for replacing the text with another value
    sv: 'Koden är ett värdebevis på ### kr och är giltig till',
    en: 'The code is a voucher for ### SEK and is valid until',
    de: 'Der Code ist ein Gutschein von ### SEK und ist gültig bis',
  }),
  'The credit code could not be found.': translations({
    sv: 'Tillgodokoden kunde inte hittas.',
    en: 'The credit code could not be found.',
    de: 'Der Kreditcode konnte nicht gefunden werden.',
  }),
  'Your credit code will have a value...': translations({
    sv: 'Din tillgodokod kommer få ett värde av ### kr',
    en: 'Your credit code will have a value of ### SEK',
    de: 'Ihr Kreditcode hat einen Wert von ### SEK',
  }),
  'Can be rescheduled at least...': translations({
    ///### is for replacing the text with another value
    sv: 'Kan ombokas senast ### timmar innan aktivitetens starttid',
    en: 'Can be rescheduled at least ### hours before the activity start time',
    de: 'Die Umbuchung muss ### Stunden vor der geplanten Aktivität erfolgen',
  }),
  'Last reschedule time has passed...': translations({
    ///### is for replacing the text with another value
    sv: 'Sista avbokningstid har passerat och aktiviteten kan inte längre ombokas.',
    en: 'Last reschedule time has passed. The activity cannot be rescheduled.',
    de: 'Die letzte Umbuchungszeit ist abgelaufen. Die Aktivität kann nicht verschoben werden.',
  }),
  'The booking could not be found...': translations({
    sv: 'Bokningen kunde inte hittas. Detta kan bero på att bokningen redan har blivit avbokad och tillgodokod har skickats ut.',
    en: 'The booking could not be found. This might be because it has already been cancelled and a credit code has been issued.',
    de: 'Die Buchung konnte nicht gefunden werden. Dies kann daran liegen, dass es bereits storniert und ein Guthabencode ausgestellt wurde.',
  }),
  'The rebooking must take place...': translations({
    ///### is for replacing the text with another value
    sv: 'Ombokningen måste ske ### timmar innan aktiviteten är schemalagd',
    en: 'The rebooking must take place ### hours before the activity is scheduled',
    de: 'Die Umbuchung muss ### Stunden vor der geplanten Aktivität erfolgen',
  }),
  'When rebooking, you will receive...': translations({
    ///### is for replacing the text with another value
    sv: 'Vid ombokning får du en kod som är giltig i ### dagar.',
    en: 'When rebooking, you will receive a code that is valid for ### days.',
    de: 'Bei einer Umbuchung erhalten Sie einen Code, der ### Tage gültig ist.',
  }),
  'stripe-errors.do-not-honor': translations({
    sv: `Kortet har nekats av din bank. 
Kontakta banken för mer information.
    
Banken kan ha reserverat ett belopp för betalningen
men detta är endast en reservation! Beloppet kommer inte att dras
från ditt konto.`,
    en: `The card has been denied by your bank.
Contact your bank for more information.

The bank may have created a reservation for the payment but this is
only a reservation! The amount will not be withdrawn from your account`,
    de: `Die Karte wurde von Ihrer Bank abgelehnt.
Kontaktieren Sie die Bank für weitere Informationen.

Möglicherweise hat die Bank einen Betrag für die Zahlung reserviert
aber das ist nur eine reservierung! Der Betrag wird nicht abgezogen
von Ihrem Konto.`,
  }),
  'stripe-errors.invalid-card': translations({
    sv: 'Kortuppgifterna du angett är inte korrekta. Se över uppgifterna och prova igen.',
    en: 'The card details that you provided are not correct.',
    de: 'Die von Ihnen eingegebenen Kartendaten sind falsch. Überprüfen Sie die Informationen und versuchen Sie es erneut.',
  }),

  'stripe-errors.expired-card': translations({
    sv: 'Kortet har gått ut. Prova med ett annat kort.',
    en: 'The credit card has expired, please try another card.',
    de: 'Die Karte ist abgelaufen. Versuchen Sie es mit einer anderen Karte.',
  }),

  'stripe-errors.insufficient-funds': translations({
    sv: 'Det finns inte tillräckligt med pengar på kortet.',
    en: 'There is not enough money on the card.',
    de: 'Es ist nicht genug Geld auf der Karte.',
  }),
  'reload-content': translations({
    sv: 'Ladda om innehållet',
    en: 'Reload content',
    de: 'Inhalt neu laden',
  }),
  'reload-app': translations({
    sv: 'Ladda om appen',
    en: 'Reload app',
    de: 'App neu laden',
  }),
  'widget.weather-forecast': translations({
    sv: 'Väderprognos',
    en: 'Weather forecast',
    de: 'Wettervorhersage',
  }),
  'Select a payment method': translations({
    sv: 'Välj betalsätt',
    en: 'Select a payment method',
    de: 'Zahlungsart auswählen',
  }),
  'Select time': translations({
    sv: 'Välj en tid',
    en: 'Select time',
    de: 'Uhrzeit wählen',
  }),
  'Selected time': translations({
    sv: 'Vald tid',
    en: 'Selected time',
    de: 'gewählte Zeit',
  }),
  'Select activity': translations({
    sv: 'Välj aktivitet',
    en: 'Select activity',
    de: 'Aktivität wählen',
  }),
  'Choose giftcard': translations({
    sv: 'Välj presentkort',
    en: 'Choose giftcard',
    de: 'Geschenkkarte wählen',
  }),
  Contact: translations({
    sv: 'Kontakt',
    en: 'Contact',
    de: 'Kontakt',
  }),
  'widget-missing-activities': translations({
    sv: `Det finns inga bokningsbara aktiviteter!`,
    en: `There are no available activities!`,
    de: `Es gibt keine buchbaren Anwendungen!`,
  }),
  Continue: translations({
    sv: `Gå vidare`,
    en: `Continue`,
    de: `Weiter`,
  }),
  'To payment': translations({
    sv: `Till betalning`,
    en: `To payment`,
    de: `Jetzt kaufen`,
  }),
  'I accept the booking conditions': translations({
    sv: `Jag godkänner bokningsvillkoren`,
    en: `I accept the booking conditions`,
    de: `Ich akzeptiere die Buchungsbedingungen`,
  }),
  'I accept the terms of use': translations({
    sv: `Jag godkänner användarvillkoren`,
    en: `I accept the terms of use`,
    de: `Ich akzeptiere die Nutzungsbedingungen`,
  }),
  approx: translations({
    sv: `Ca.`,
    en: `About`,
    de: `Ca.`,
  }),
  'km from you': translations({
    sv: 'km från dig',
    en: 'km from you',
    de: 'km von Ihnen entfernt',
  }),
  'widget.widgetWrapper.attribution': translations({
    sv: `Bokningen sker via`,
    en: `The booking is made via`,
    de: `Die Buchung erfolgt über`,
  }),
  Book: translations({
    sv: `Boka`,
    en: `Book`,
    de: `Buchen`,
  }),
  new: translations({
    sv: `NYHET`,
    en: `NEW`,
    de: `NEUHEIT`,
  }),
  'See the available gift cards at': translations({
    sv: `Se dom tillgängliga presentkorten på`,
    en: `See the available gift cards at`,
    de: `Sehen Sie die verfügbaren Geschenkgutscheine`,
  }),
  'Selected date': translations({
    sv: `Valt datum`,
    en: `Selected date`,
    de: `Ausgewähltes Datum`,
  }),
  'Buy a gift card': translations({
    sv: `Köp ett presentkort`,
    en: `Buy a gift card`,
    de: `Geschenkkarte kaufen`,
  }),
  'Book your activity': translations({
    sv: `Boka din upplevelse`,
    en: `Book your activity`,
    de: `Buche deine Erlebnis`,
  }),
  'Book your ticket': translations({
    sv: `Boka din biljett`,
    en: `Book your ticket`,
    de: `Buche deine Ticket`,
  }),
  'Book an experience': translations({
    sv: `Boka en upplevelse`,
    en: `Book an experience`,
    de: `Buchen Sie eine Erfahrung`,
  }),
  Payment: translations({
    sv: `Betala`,
    en: `Payment`,
    de: `Bezahlung`,
  }),
  Activity: translations({
    sv: `Aktivitet`,
    en: `Activity`,
    de: `Aktivität`,
  }),
  'To scheduled times': translations({
    sv: `Till schemalagda tider`,
    en: `To scheduled times`,
    de: `Zu festgelegten Zeiten`,
  }),
  'Back to scheduled times.': translations({
    sv: `Tillbaka till schemalagda tider.`,
    en: `Back to scheduled times.`,
    de: `Gibt es keine Zeiten, die Ihnen passen?`,
  }),
  'Are there no times that suit you?': translations({
    sv: `Finns inga tider som passar dig?`,
    en: `Are there no times that suit you?`,
    de: `Zurück zu den geplanten Zeiten.`,
  }),
  'Make a booking request': translations({
    sv: `Gör en bokningsförfrågan`,
    en: `Make a booking request`,
    de: `Machen Sie eine Buchungsanfrage`,
  }),
  'You can send a request to book an activity outside scheduled times.': translations({
    sv: `Här kan du skicka en förfrågan om att boka en tid utanför schemalagda tider. Arrangören återkommer till dig via mail med svar.`,
    en: `You can send a request to book an activity outside scheduled times. The organizer will get back to you by email.`,
    de: `Sie können eine Anfrage senden, um eine Aktivität außerhalb der geplanten Zeiten zu buchen. Der Veranstalter meldet sich per E-Mail bei Ihnen.`,
  }),
  'Book a scheduled activity instead': translations({
    sv: `Boka en schemalagd tid istället`,
    en: `Book a scheduled activity instead`,
    de: `Buchen Sie stattdessen eine geplante Aktivität`,
  }),
  'Message from the organizer': translations({
    sv: `Meddelande från arrangören`,
    en: `Message from the organizer`,
    de: `Nachricht vom Veranstalter`,
  }),
  'Choose the date on which your ticket will take effect:': translations({
    sv: `Välj vilket datum som din biljett ska börja gälla:`,
    en: `Choose the date on which your ticket will take effect:`,
    de: `Wählen Sie das Datum, an dem Ihr Ticket gültig ist:`,
  }),
  To: translations({
    sv: `Till`,
    en: `To`,
    de: `Zu`,
  }),
  from: translations({
    sv: `från`,
    en: `from`,
    de: `von`,
  }),
  to: translations({
    sv: `till`,
    en: `to`,
    de: `zu`,
  }),
  'Booking conditions': translations({
    sv: `Bokningsvillkor`,
    en: `Booking conditions`,
    de: `Buchungsbedingungen`,
  }),
  'Terms of use': translations({
    sv: `Användarvillkor`,
    en: `Terms of use`,
    de: `Nutzungsbedingungen`,
  }),
  'widget.orderSteps.orderStepTemplate.hideGiftCardValueButton': translations({
    sv: `Göm presentkortets värde`,
    en: `Hide the gift card's value`,
    de: `Verbergen Sie den Wert der Geschenkkarte`,
  }),
  'There are no available activities': translations({
    sv: `Det finns inga bokningsbara aktiviteter`,
    en: `There are no available activities`,
    de: `Es gibt keine buchbaren Anwendungen`,
  }),
  'Fill in your contact information': translations({
    sv: `Fyll i dina kontaktuppgifter`,
    en: `Fill in your contact information`,
    de: `Gib deine Kontaktdaten ein`,
  }),
  'widget.orderSteps.paymentStep.headerThree': translations({
    sv: `Välj betalsätt`,
    en: `Choose payment`,
    de: `Zahlungsmethode wählen`,
  }),
  'Start:': translations({
    sv: `Start:`,
    en: `Start:`,
    de: `Start:`,
  }),
  'Show calendar': translations({
    sv: `Visa kalender`,
    en: `Show calendar`,
    de: `Kalender anzeigen`,
  }),
  'widget.selectionSteps.selectionDetailsView.activityDate': translations({
    sv: `Datum: `,
    en: `Date:`,
    de: `Datum:`,
  }),
  'widget.orderSteps.confirmationStep.giftCardConfirmationFailed': translations({
    sv: `Tyvärr kunde inte presentkortet skickas till din mail, var snäll och spara ditt presentkortsnummer och kontakta supporten så skickar vi ett presentkort till dig 🙏`,
    en: `Unfortunately, the gift card could not be sent to your mail, please save your gift card number and contact the support and we will send you a gift card 🙏`,
    de: `Leider konnte die Geschenkkarte nicht an Ihre E-Mail gesendet werden. Bitte speichern Sie Ihre Geschenkkartennummer und kontaktieren Sie den Support`,
  }),
  'This is your giftcard number': translations({
    sv: `Det här är ditt presentkortsnummer:`,
    en: `This is your giftcard number:`,
    de: `Dies ist Ihre Geschenkkartennummer:`,
  }),
  'These are your giftcard numbers': translations({
    sv: 'Dessa är dina presentkortsnummer:',
    en: 'These are your giftcard numbers:',
    de: 'Das sind deine Geschenkkartennummern:',
  }),
  'widget.orderSteps.orderStepTemplate.hideGiftCardValueDescription': translations({
    sv: `Du kan välja att gömma presentkortets värde. Om du gömmer värdet så kommer det aldrig att synas för personen som ska få presentkortet, lycka till 😉`,
    en: `You can choose to hide the gift card's value. If you hide the value, it will never be visible to the person who will receive the gift card, good luck 😉`,
    de: `Sie können den Wert der Geschenkkarte ausblenden. Wenn Sie den Wert verbergen, ist er für die Person, die die Geschenkkarte erhält, niemals sichtbar. Viel Glück 😉`,
  }),
  'widget.selectionSteps.giftcardSelectionView.noGiftCardsAtOrganization': translations({
    sv: `Det finns tyvärr inga presentkort att köpa från`,
    en: `Unfortunately, there are no gift cards to buy at`,
    de: `Leider gibt es keine Geschenkkarten zum Kaufen`,
  }),
  'Choose number of participants': translations({
    sv: `Välj antal deltagare`,
    en: `Choose number of participants`,
    de: `Wählen Sie, wie viele Teilnehmer`,
  }),
  'Select tickets': translations({
    sv: `Välj biljetter`,
    en: `Select tickets`,
    de: `Auswählen`,
  }),
  'Add accessories': translations({
    sv: `Lägg till tillbehör`,
    en: `Add accessories`,
    de: `Add accessories`,
  }),
  'Edit accessories': translations({
    sv: `Redigera tillbehör`,
    en: `Edit accessories`,
    de: `Zubehör bearbeiten`,
  }),
  'Add bags': translations({
    sv: `Lägg till väskor`,
    en: `Add bags`,
    de: `Taschen hinzufüge`,
  }),
  'Edit bags': translations({
    sv: `Redigera väskor`,
    en: `Edit bags`,
    de: `Taschen bearbeiten`,
  }),
  'Number of bags': translations({
    sv: 'Antal väskor',
    en: 'Number of bags',
    de: 'Anzahl Taschen',
  }),
  'We take care of your bags and transport them for you during the journey.': translations({
    sv: 'Vi tar hand om dina väskor och transporterar dem åt dig under resans gång.',
    en: 'We take care of your bags and transport them for you during the journey.',
    de: 'Wir kümmern uns um Ihr Gepäck und transportieren es während der Reise für Sie.',
  }),
  'Edit tickets': translations({
    sv: `Redigera besökare`,
    en: `Edit visitors`,
    de: `Karten bearbeiten`,
  }),
  'widget.orderSteps.orderStepTemplate.showGiftCardValueButton': translations({
    sv: `Visa presentkortets värde`,
    en: `Show the gift card's value`,
    de: `Zeigen Sie den Wert der Geschenkkarte`,
  }),
  'widget.widgetContent.messageForAdmins': translations({
    sv: `⚠️ Eftersom du är inloggad har du tillgång till extra alternativ i köpesprocessen`,
    en: `⚠️ Because you are logged in, you have access to additional options below`,
    de: `⚠️ Da Sie angemeldet sind, haben Sie im Kaufprozess Zugriff auf zusätzliche Optionen`,
  }),
  'Do you want to buy a gift card?': translations({
    sv: `Vill du köpa ett presentkort?`,
    en: `Do you want to buy a gift card?`,
    de: `Möchtest du eine Gutscheinkarte kaufen?`,
  }),
  Confirmation: translations({
    sv: `Bekräftelse`,
    en: `Confirmation`,
    de: `Bestätigung`,
  }),
  'Unfortunately something went wrong when we tried...': translations({
    sv: `Tyvärr gick något fel när vi skulle skicka dig din bekräftelse, var god spara alla identifierande artikelnummer innan du stänger sidan`,
    en: `Unfortunately something went wrong when we tried sending you your confirmation mail, please save any article numbers before closing the page`,
    de: `Leider ist etwas schief gelaufen, als wir versucht haben, Ihnen Ihre Bestätigungsmail zu senden. Bitte speichern Sie alle Artikelnummern, bevor Sie die Seite schließen`,
  }),
  'widget.selectionSteps.activityTypeSelectionView.dropdown': translations({
    sv: `Välj aktivitet:`,
    en: `Select activity:`,
    de: `Aktivität auswählen:`,
  }),
  'widget.selectionSteps.activityTypeSelectionView.subHeaderOne': translations({
    sv: `Välj vilken aktivitet du vill boka:`,
    en: `Choose which activity to book:`,
    de: `Wählen Sie aus, welche Aktivität Sie buchen möchten:`,
  }),
  Time: translations({
    sv: `Tid`,
    en: `Time`,
    de: `Zeit`,
  }),
  'widget.orderSteps.orderStepTemplate.hideGiftCardValueHeader': translations({
    sv: `Vill du överraska lite extra?`,
    en: `Want to surprise a little extra?`,
    de: `Möchten Sie ein bisschen mehr überraschen?`,
  }),
  Location: translations({
    sv: `Plats`,
    en: `Location`,
    de: `Platz`,
  }),
  Amount: translations({
    sv: `Antal`,
    en: `Amount`,
    de: `Anzahl`,
  }),
  'Fully booked': translations({
    sv: `Fullbokat`,
    en: `Fully booked`,
    de: `Ausgebucht`,
  }),
  'Fully rented out': translations({
    sv: `Fullt uthyrd`,
    en: `Fully rented out`,
    de: `Voll vermietet`,
  }),
  Booked: translations({
    sv: 'Bokade',
    en: 'Booked',
    de: 'Gebucht',
  }),
  'Valid for': translations({
    sv: 'Gäller för',
    en: 'Valid for',
    de: 'Gültig für',
  }),
  Price: translations({
    sv: `Pris`,
    en: `Price`,
    de: `Preis`,
  }),
  Name: translations({
    sv: 'Namn',
    en: 'Name',
    de: 'Name',
  }),
  'Thank you so much for your purchase!': translations({
    sv: `Tack så mycket för ditt köp!`,
    en: `Thank you so much for your purchase!`,
    de: `Vielen Dank für Ihren Einkauf!`,
  }),
  'Booking request sent!': translations({
    sv: `Bokningsförfrågan har skickats!`,
    en: `Booking request sent!`,
    de: `Buchungsanfrage gesendet!`,
  }),
  'Make another booking request or purchase': translations({
    sv: `Gör en till bokningsförfrågan eller köp`,
    en: `Make another booking request or purchase`,
    de: `Machen Sie eine weitere Buchungsanfrage oder kaufen Sie`,
  }),
  'Please note that you will receive one email per ticket.': translations({
    sv: `Vänligen notera att du kommer få ett email per biljett.`,
    en: `Please note that you will receive one email per ticket.`,
    de: `Bitte beachten Sie, dass Sie pro Ticket eine E-Mail erhalten.`,
  }),
  'Selected gift card': translations({
    sv: `Valt presentkort:`,
    en: `Selected gift card:`,
    de: `Ausgewählte Geschenkkarte:`,
  }),
  'Try to choose another day': translations({
    sv: `Prova att välja en annan dag 🙂`,
    en: `Try to choose another day 🙂`,
    de: `Bitte wähle einen anderen Tag`,
  }),
  'Do you accept the booking terms?': translations({
    sv: `Godkänner du bokningsvillkoren?`,
    en: `Do you accept the booking terms?`,
    de: `Akzeptieren Sie die Buchungsbedingungen?`,
  }),
  'widget.orderSteps.paymentStep.skipPayment': translations({
    sv: `Hoppa över betalsteget`,
    en: `Skip the payment step`,
    de: `Überspringen Sie den Zahlungsschritt`,
  }),
  'Confirmation of purchase been sent to the email address': translations({
    sv: `Det har skickats bekräftelse om köp till epostadressen`,
    en: `Confirmation of purchase been sent to the email address`,
    de: `Kaufbestätigung wurde an die E-Mail-Adresse gesendet`,
  }),
  'Your ticket has been sent to the email address': translations({
    sv: `Din biljett har skickats till epostadressen`,
    en: `Your ticket has been sent to the email address`,
    de: `Ihre Ticket wurde an die E-Mail-Adresse gesendet`,
  }),
  'Confirmation of your booking request has been sent to': translations({
    sv: `Bekräftelse på din bokningsförfrågan har skickats till`,
    en: `Confirmation of your booking request has been sent to`,
    de: `Die Bestätigung Ihrer Buchungsanfrage wurde an gesendet`,
  }),
  'The organizers will get back to you shortly': translations({
    sv: `Arrangörerna återkommer till dig inom kort`,
    en: `The organizers will get back to you shortly`,
    de: `Die Organisatoren werden sich in Kürze bei Ihnen melden`,
  }),
  'Click on the link in the email to add your ticket to the Borås app.': translations({
    sv: `Klicka på länken i mailet för att lägga till biljetten i Borås-appen.`,
    en: `Click on the link in the email to add your ticket to the Borås app.`,
    de: `Klicken Sie auf den Link in der E-Mail, um Ihr Ticket zur Borås-App hinzufügen.`,
  }),
  'Click on the link in the email to open the ticket in your browser...': translations({
    sv: `Klicka på länken i mailet för att öppna biljetten i din webbläsare. Visa biljetten i webbläsaren för personalen för att checka in på de olika aktiviteterna.`,
    en: `Click on the link in the email to open the ticket in your browser. Show your ticket in the browser to the staff to check in at the attractions.`,
    de: `Klicken Sie auf den Link in der E-Mail, um das Ticket in Ihrem Browser zu öffnen. Zeigen Sie dem Personal das Ticket im Browser, um sich für die verschiedenen Aktivitäten anzumelden.`,
  }),
  'Order overview': translations({
    sv: `Orderöversikt`,
    en: `Order overview`,
    de: `Bestellübersicht`,
  }),
  'Total price': translations({
    sv: `Totalpris`,
    en: `Total price`,
    de: `Gesamtpreis`,
  }),
  'Unit price': translations({
    sv: 'À pris',
    en: 'Unit price',
    de: 'Einzelpreis',
  }),
  From: translations({
    sv: `Från`,
    en: `From`,
    de: `Von`,
  }),
  'See available adventures at': translations({
    sv: `Se tillgängliga äventyr på`,
    en: `See available adventures at`,
    de: `Sehen Sie die verfügbaren Abenteuer an`,
  }),
  'Make another purchase': translations({
    sv: `Gör ett till köp`,
    en: `Make another purchase`,
    de: `Einen weiteren Einkauf tätigen`,
  }),
  'widget.selectionSteps.giftcardSelectionView.mainHeader': translations({
    sv: `Köp ett presentkort till någon du tycker om`,
    en: `Buy a gift card for someone you like`,
    de: `Kaufen Sie einen Geschenkgutschein für jemanden, den Sie mögen`,
  }),
  'widget.orderSteps.confirmationStep.giftcardMessage': translations({
    sv: `Presentkortet har skickats till din angivna epostadress`,
    en: `The gift card has been sent to your specified email address`,
    de: `Die Geschenkkarte wurde an Ihre angegebene E-Mail-Adresse gesendet`,
  }),
  'widget.selectionSteps.giftcardSelectionView.mainSubHeader': translations({
    sv: `Välj vilket kort du vill köpa från listan`,
    en: `Select which card you want to buy from the list`,
    de: `Wählen Sie aus der Liste die Karte aus, die Sie kaufen möchten`,
  }),
  'Here is your booking number': translations({
    sv: `Här är ditt bokningsnummer:`,
    en: `Here is your booking number:`,
    de: `Hier ist Ihre Buchungsnummer:`,
  }),
  'These are your booking numbers': translations({
    sv: 'Dessa är dina bokningsnummer:',
    en: 'These are your booking numbers:',
    de: 'Das sind Ihre Buchungsnummern:',
  }),
  slots: translations({
    sv: `platser`,
    en: `slots`,
    de: `Schlüssel`,
  }),
  'Available (plural)': translations({
    sv: `Lediga`,
    en: `Available`,
    de: `Freie`,
  }),
  'Chosen activity': translations({
    sv: `Vald aktivitet`,
    en: `Chosen activity`,
    de: `Ausgewählte Anwendung`,
  }),
  'Chosen ticket': translations({
    sv: `Vald biljett`,
    en: `Chosen ticket`,
    de: `Ausgewählte Ticket`,
  }),
  'widget.orderSteps.paymentStep.skipPaymentYouCan': translations({
    sv: `Eftersom du är inloggad har du möjlighet att lösa betalningen manuellt senare:`,
    en: `Because you are logged in, you have the option to manually settle the payment later:`,
    de: `Da Sie angemeldet sind, können Sie die Zahlung später manuell abrechnen:`,
  }),
  Start: translations({
    sv: `Start`,
    en: `Start`,
    de: `Start`,
  }),
  'Would you like to book your adventure directly instead?': translations({
    sv: `Vill du boka ditt äventyr direkt istället?`,
    en: `Would you like to book your adventure directly instead?`,
    de: `Möchten Sie Ihr Abenteuer stattdessen direkt buchen?`,
  }),
  'Hope you have a wonderful experience! 😊': translations({
    sv: `Hoppas ni får en underbar upplevelse! 😊`,
    en: `Hope you have a wonderful experience! 😊`,
    de: `Ich hoffe, Sie haben eine wundervolle Erfahrung! 😊`,
  }),
  'Customer Info': translations({
    sv: `Kunduppgifter`,
    en: `Customer Info`,
    de: 'Kundeninformation',
  }),
  'White days either contains no activities or are fully booked': translations({
    sv: `Dagar som är vita har antingen ingen ledig tid eller är fullbokade`,
    en: `White days either contains no activities or are fully booked`,
    de: `Weiße Tage haben entweder keine geplanten Zeiten oder sind ausgebucht`,
  }),
  Phone: translations({
    sv: `Telefon`,
    en: `Phone`,
    de: 'Telefon',
  }),
  Comment: translations({
    sv: `Kommentar`,
    en: `Comment`,
    de: 'Kommentar',
  }),
  Email: translations({
    sv: `Email`,
    en: `Email`,
    de: 'Email',
  }),
  'Are you really sure?': translations({
    sv: `Är du verkligen säker?`,
    en: `Are you really sure?`,
    de: `Bist du dir wirklich sicher?`,
  }),
  'components.payment.index.errorMessage2': translations({
    sv: `Någonting gick fel.. Var snäll och ladda om sidan och försök igen`,
    en: `Something went wrong .. Please reload the page and try again`,
    de: `Etwas ist schief gelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut`,
  }),
  'components.payment.index.headerTwo': translations({
    sv: `Kostnadsfri bokning`,
    en: `Free booking`,
    de: `Kostenlose Buchung`,
  }),
  'Confirm booking request': translations({
    sv: `Bekräfta bokningsförfrågan`,
    en: `Confirm booking request`,
    de: `Buchungsanfrage bestätigen`,
  }),
  'Make booking request': translations({
    sv: `Gör bokningsförfrågan`,
    en: `Make booking request`,
    de: `Buchungsanfrage stellen`,
  }),
  'components.payment.manualPaymentComponent.headerOne': translations({
    sv: `Kvar att betala:`,
    en: `Amount left to pay:`,
    de: `Restbetrag:`,
  }),
  'components.payment.index.buttonTwo': translations({
    sv: `Kortbetalning`,
    en: `Online payment`,
    de: `Online-Zahlung`,
  }),
  'components.payment.index.subHeaderTwo': translations({
    sv: `Slutför din bokning via bokningsknappen nedan.`,
    en: `Complete your booking via the booking button below.`,
    de: `Schließen Sie Ihre Buchung über den unten stehenden Buchungsbutton ab.`,
  }),
  'To make your booking request...': translations({
    sv: `För att göra din bokningsförfrågan, klicka på knappen nedan. Arrangörerna kommer sedan att kontakta dig för att bekräfta din förfrågan.`,
    en: `To make your booking request, click the button bellow. The organizers will then contact you to confirm your request.`,
    de: `Um Ihre Buchungsanfrage zu stellen, klicken Sie bitte auf den untenstehenden Button. Die Organisatoren werden sich dann mit Ihnen in Verbindung setzen, um Ihre Anfrage zu bestätigen.`,
  }),
  'components.payment.index.buttonFour': translations({
    sv: `Betala på plats`,
    en: `Pay at the venue`,
    de: `Barzahlung`,
  }),
  'components.payment.index.buttonOne': translations({
    sv: `Manuell betalning`,
    en: `Manual payment`,
    de: `Manuelle Zahlung`,
  }),
  'components.payment.skipPaymentComponent.headerTwo': translations({
    sv: `Slutför din bokning via bokningsknappen nedan. Kom ihåg att ta med plånboken och gärna ett glatt humör 😇`,
    en: `Complete your booking via the booking button below.`,
    de: `Schließe deine Buchung über den Buchungsbutton ab.`,
  }),
  'components.payment.manualPaymentComponent.headerThree': translations({
    sv: `Summan kommer att läggas till i bokningen som manuellt betald.`,
    en: `The sum will be added to the booking as manually paid.`,
    de: `Die Summe wird der Buchung als manuell bezahlt hinzugefügt.`,
  }),
  'Giftcard code': translations({
    sv: `Presentkortskod`,
    en: `Giftcard code`,
    de: `Gutscheinkarte`,
  }),
  'Unfortunately, the voucher has expired and is no longer valid': translations({
    sv: `Tyvärr har värdebeviset gått ut och är inte längre giltigt`,
    en: `Unfortunately, the voucher has expired and is no longer valid`,
    de: `Leider ist die Gutscheine abgelaufen und nicht mehr gültig.`,
  }),
  'Unfortunately, the value of the voucher is only': translations({
    sv: `Tyvärr är värdet på värdebeviset endast`,
    en: `Unfortunately, the value of the voucher is only`,
    de: `Leider ist der Wert der Gutscheine nur`,
  }),
  'Please try again': translations({
    sv: `Var god försök igen`,
    en: `Please try again`,
    de: `Bitte versuche es erneut!`,
  }),
  'Fill in your voucher code': translations({
    sv: `Fyll i din värdebeviskod`,
    en: `Fill in your voucher code`,
    de: `Gutscheincode coupon code`,
  }),
  'components.payment.skipPaymentComponent.headerOne': translations({
    sv: `Betala kontant`,
    en: `Pay in cash`,
    de: `Barzahlung`,
  }),
  'Payment failed, please make sure...': translations({
    sv: `Betalningen lyckades inte, var god och se till att ditt kort är giltigt och har tillräckligt med pengar.`,
    en: `Payment failed, please make sure your card is valid and enough funds are available.`,
    de: `Zahlung fehlgeschlagen, bitte stellen Sie sicher, dass Ihre Karte gültig ist und über genügend Geld verfügt.`,
  }),
  'No voucher was found with the given code...': translations({
    sv: `Inget värdebevis kunde hittas med den angivna koden, var god kontrollera att koden är rätt och försök igen`,
    en: `No voucher was found with the given code, please check the code and try again.`,
    de: `Keine Gutscheine mit dem angegebenen Code gefunden. Bitte überprüfe, ob der Code korrekt ist und versuche es erneut.`,
  }),
  'components.payment.manualPaymentComponent.labelOne': translations({
    sv: `Lägg till manuell betalning`,
    en: `add manual payment`,
    de: `Füge manuelle Bezahlung hinzu`,
  }),
  'Voucher not valid at': translations({
    sv: `Värdebeviset inte giltigt hos`,
    en: `Voucher not valid at`,
    de: `Gutscheine nicht gültig bei`,
  }),
  'Invalid voucher': translations({
    sv: `Ogiltigt värdebevis`,
    en: `Invalid voucher`,
    de: `Ungültige Gutscheine`,
  }),
  'Used vouchers': translations({
    sv: `Använt värdebevis`,
    en: `Used vouchers`,
    de: `Gebrauchte Gutscheine`,
  }),
  'Voucher is no longer valid': translations({
    sv: `Ogiltigt värdebevis, datum passerat`,
    en: `Voucher is no longer valid`,
    de: `Gutscheine abgelaufen`,
  }),
  'The voucher is already used and not valid.': translations({
    sv: `Värdebeviset har redan används och är inte lägre giltigt`,
    en: `The voucher is already used and not valid.`,
    de: `Die Gutscheine wurde bereits verwendet und ist nicht mehr gültig.`,
  }),
  'Sorry, we could not process your payment due...': translations({
    sv: `Tyvärr gick det inte att genomföra din betalning på grund av ett tekniskt fel, var snäll och försök igen`,
    en: `Sorry, we could not process your payment due to a technical error. Please try again`,
    de: `Entschuldige, du konntest deine Zahlung aufgrund eines technischen Fehlers nicht ausführen. Bitte versuche es erneut.`,
  }),
  'The voucher could not be used...': translations({
    sv: `Värdebeviset kunde inte användas, var god och se till att ditt värdebevis är giltigt för aktiviteten, inte har löpt ut och har pengar kvar.`,
    en: `The voucher could not be used, please make sure your voucher is valid for the activity, has not expired and has money left.`,
    de: `Die Gutscheine konnte nicht verwendet werden. Bitte stellen Sie sicher, dass Ihre Gutscheine für die Aktivität gültig ist, nicht abgelaufen ist und noch Geld übrig ist.`,
  }),
  'Not a valid voucher': translations({
    sv: `Ogiltigt värdebevis`,
    en: `Not a valid voucher`,
    de: `Ungültige Gutscheine`,
  }),
  'Please try again with another or change your booking': translations({
    sv: `kr, var god försök igen med ett annat eller ändra din bokning`,
    en: `Please try again with another or change your booking`,
    de: `Bitte versuchen Sie es erneut mit einem anderen oder ändere deine Buchung`,
  }),
  'Voucher value is too low': translations({
    sv: `Värdebevisets saldo är för lågt`,
    en: `Voucher value is too low`,
    de: `Gutscheinwert ist zu niedrig`,
  }),
  'Payment could not be processed': translations({
    sv: `Betalningen kunde inte slutföras`,
    en: `Payment could not be processed`,
    de: `Zahlung konnte nicht verarbeitet werden`,
  }),
  'Found no matching organizations': translations({
    sv: `Ingen match mot en organisation`,
    en: `Found no matching organizations`,
    de: `Keine Übereinstimmung mit der Organisation`,
  }),
  ', which will be on your receipt...': translations({
    sv: `, vilket kommer att stå på ditt kvitto. Dina kortuppgifter hanteras säkert via vår samarbetspartner`,
    en: `, which will be on your receipt. Your credit card details is managed securely through our partner`,
    de: `, was auf deiner Quittung steht. Ihre Kreditkarteninformationen werden sicher über unseren Partner verwaltet`,
  }),
  'Use voucher': translations({
    sv: `Använd värdebevis`,
    en: `Use voucher`,
    de: `Verwenden Sie Gutscheine`,
  }),
  'Discount not valid at': translations({
    sv: `Rabattkoden inte giltig hos`,
    en: `Discount not valid at`,
    de: `Rabatt nicht gültig bei`,
  }),
  'Discount code not valid at this time': translations({
    sv: `Rabattkoden är inte giltig just nu`,
    en: `Discount code not valid at this time`,
    de: `Rabattcode derzeit nicht gültig`,
  }),
  'Unfortunately, the discount has expired and is no longer valid or is not valid at this time': translations({
    sv: `Tyvärr har rabatten gått ut och är inte längre giltig eller inte giltig för närvarande`,
    en: `Unfortunately, the discount has expired and is no longer valid or is not valid at this time`,
    de: `Der Rabatt ist leider abgelaufen und gilt nicht mehr bzw. derzeit nicht`,
  }),
  'Discount code is not valid': translations({
    sv: `Rabattkoden är inte giltig`,
    en: `Discount code is not valid`,
    de: `Discount code is not valid`,
  }),
  'Discount code cannot be verified': translations({
    sv: `Rabattkoden kan inte verifieras`,
    en: `Discount code cannot be verified`,
    de: `Discount code cannot be verified`,
  }),
  'Booking request': translations({
    sv: 'Bokningsförfrågan',
    en: 'Booking request',
    de: 'Buchungsanfrage',
  }),
  'Discount code was not found': translations({
    sv: `Rabattkoden hittades ej`,
    en: `Discount code was not found`,
    de: `Discount code was not found`,
  }),
  "Can't use more than one discount": translations({
    sv: `Kan inte använda mer än en rabattkod`,
    en: `Can't use more than one discount`,
    de: `Can't use more than one discount`,
  }),
  'Unfortunately, the discount does not exist': translations({
    sv: `Tyvärr rabatten finns ej`,
    en: `Unfortunately, the discount does not exist`,
    de: `Unfortunately, the discount does not exist`,
  }),
  'Unfortunately, the discount has no more uses left': translations({
    sv: `Tyvärr har rabattkodenen inga användningar kvar`,
    en: `Unfortunately, the discount has no more uses left`,
    de: `Unfortunately, the discount has no more uses left`,
  }),
  'Could not apply discount': translations({
    sv: `Kunde inte tillämpa rabattkod`,
    en: `Could not apply discount`,
    de: `Rabatt konnte nicht angewendet werden`,
  }),
  'Your booking is managed by': translations({
    sv: `Din bokning sker via`,
    en: `Your booking is managed by`,
    de: `Deine Buchung erfolgt über`,
  }),
  'Card number': translations({
    sv: `Kortnummer`,
    en: `Card number`,
    de: 'Kartennummer',
  }),
  'components.payment.stripeComponent.double': translations({
    sv: `Det är många som bokar nu!`,
    en: `There are many bookings at the moment!`,
    de: 'Es gibt viele, die jetzt buchen!',
  }),
  CVC: translations({
    sv: `CVC`,
    en: `CVC`,
    de: `CVC`,
  }),
  'Expiration date': translations({
    sv: `Utgångsdatum`,
    en: `Expiration date`,
    de: 'Ablaufdatum der Karte',
  }),
  'components.payment.stripeComponent.doubleMessage': translations({
    sv: `Någon har hunnit före dig, var god ändra din bokning och försök igen.`,
    en: `Unfortunately someone booked before you, please change your booking and try again.`,
    de: 'Jemand hatte vor Ihnen Zeit, bitte ändern Sie Ihre Buchung und versuchen Sie es erneut.',
  }),
  'Complete purchase': translations({
    sv: `Slutför köp`,
    en: `Complete purchase`,
    de: 'Kaufabwicklung abschließen',
  }),
  'components.payment.stripeComponent.doubleExtraInfo': translations({
    sv: `Antal platser kvar: `,
    en: `Number of slots left:`,
    de: 'Anzahl der noch freien Plätze:',
  }),
  'There is a booking fee...': translations({
    sv: `Vid kortbetalningar tas det ut en bokningsavgift som ej betalas tillbaka vid avbokning. Denna avgift kan variera för olika kortföretag.`,
    en: 'There is a booking fee for card payments which will not be refunded on cancellation of the booking. The fee amount can vary depending on your card issuer',
    de: 'Bei Kartenzahlungen wird eine Buchungsgebühr erhoben, die bei Stornierung nicht zurückerstattet wird. Diese Gebühr kann für verschiedene Kartenunternehmen variieren.',
  }),
  'components.addressFields.streetAlt': translations({
    sv: `Adress alt.`,
    en: `Address alt.`,
    de: `Adresse alt.`,
  }),
  Address: translations({
    sv: `Adress`,
    en: `Address`,
    de: `Adresse`,
  }),
  City: translations({
    sv: `Stad`,
    en: `City`,
    de: `Ort`,
  }),
  'Zip code': translations({
    sv: `Postnummer`,
    en: `Zip code`,
    de: `Postleitzahl`,
  }),
  'Set the price in SEK': translations({
    sv: `Ange ett pris i hela kronor`,
    en: `Set the price in SEK`,
    de: `Gib einen Preis in ganzen SEK ein`,
  }),
  'You must choose at least one visitor': translations({
    sv: `Du måste välja minst en besökare`,
    en: `You must choose at least one visitor`,
    de: `Sie müssen mindestens einen Besucher auswählen`,
  }),
  'Book visitors freely': translations({
    sv: `Boka fritt antal`,
    en: `Book visitors freely`,
    de: `Zusätzliche Anzahl buchen`,
  }),
  'Custom total price': translations({
    sv: `Anpassat totalpris`,
    en: `Custom total price`,
    de: `Angepasster Gesamtpreis`,
  }),
  'Administrator settings': translations({
    sv: `Administratörsinställningar`,
    en: `Administrator settings`,
    de: `Administratoreinstellungen`,
  }),
  visitors: translations({
    sv: `besökare`,
    en: `visitors`,
    de: `Besucher`,
  }),
  'Previous price': translations({
    sv: `Tidigare pris`,
    en: `Previous price`,
    de: `Alter Preis`,
  }),
  'Price per person': translations({
    sv: `Pris per person`,
    en: `Price per person`,
    de: `Preis pro Person`,
  }),
  'New price': translations({
    sv: `Nytt pris`,
    en: `New price`,
    de: `Neuer Preis`,
  }),
  'Include baseprice': translations({
    sv: `Inkludera grundpriset`,
    en: `Include baseprice`,
    de: `Beinhaltet den Grundpreis`,
  }),
  'components.priceCategories.selectPriceCategoriesFields.promptOne': translations({
    sv: `Var snäll och ange ett giltligt nummer`,
    en: `Please fill in a valid number`,
    de: `Bitte gib eine gültige Nummer ein.`,
  }),
  'Set a price': translations({
    sv: `Ange ett pris`,
    en: `Set a price`,
    de: `Gib einen Preis ein`,
  }),
  'In order to book you must select at least': translations({
    sv: `För att kunna boka måste du välja minst`,
    en: `In order to book you must select at least`,
    de: `Um zu buchen musst du mindestens auswählen`,
  }),
  'Price categories': translations({
    sv: `Priser`,
    en: `Prices`,
    de: `Prices`,
  }),
  'Name the category': translations({
    sv: `Ange ett namn för kategorin`,
    en: `Name the category`,
    de: `Benenne die Kategorie`,
  }),
  'Only visible to you': translations({
    sv: `Endast synlig för dig`,
    en: `Only visible to you`,
    de: `Nur für dich sichtbar`,
  }),
  'Visible to your customers': translations({
    sv: `Synlig för dina kunder`,
    en: `Visible to your customers`,
    de: `Sichtbar für Ihre Kunden`,
  }),
  people: translations({
    sv: `personer`,
    en: `people`,
    de: 'Menschen',
  }),
  'Number of visitors': translations({
    sv: `Antal besökare`,
    en: `Number of visitors`,
    de: 'Besucherzahl',
  }),
  'Number of tickets': translations({
    sv: `Antal biljetter`,
    en: `Number of tickets`,
    de: 'Anzahl der Tickets',
  }),
  'Number of articles': translations({
    sv: `Antal artiklar`,
    en: `Number of articles`,
    de: 'Artikelanzahl',
  }),

  'Group number must be 1 or larger': translations({
    sv: `Gruppantalet måste vara 1 eller större`,
    en: `Group number must be 1 or larger`,
    de: `Die Gruppennummer muss 1 oder größer sein`,
  }),
  'Enter a number of how many...': translations({
    sv: `Ange med en siffra hur många personer som kategorin gäller för`,
    en: `Enter a number of how many people the category applies to`,
    de: 'Geben Sie mit einer Zahl an, für wie viele Personen die Kategorie gilt',
  }),
  Accessories: translations({
    sv: `Tillbehör`,
    en: `Accessories`,
    de: 'Zubehör',
  }),
  '(optional)': translations({
    sv: `(valfritt)`,
    en: `(optional)`,
    de: `(Optional)`,
  }),
  'In order to make a booking...': translations({
    sv: `För att kunna göra en bokning måste du välja minst`,
    en: `In order to make a booking, you must choose at least`,
    de: 'Um eine Buchung vornehmen zu können, müssen Sie mindestens auswählen',
  }),
  Now: translations({
    sv: `Nu`,
    en: `Now`,
    de: `Jetzt`,
  }),
  'kosmic.components.legacy.form.dateTimePickers.sunday': translations({
    sv: `Sön`,
    en: `Sun`,
    de: `So`,
  }),
  'kosmic.components.legacy.form.dateTimePickers.tuesday': translations({
    sv: `Tis`,
    en: `Tue`,
    de: `Di`,
  }),
  'kosmic.components.legacy.form.dateTimePickers.saturday': translations({
    sv: `Lör`,
    en: `Sat`,
    de: `Sa`,
  }),
  'kosmic.components.legacy.form.dateTimePickers.thirsday': translations({
    sv: `Tor`,
    en: `Thu`,
    de: `Do`,
  }),
  'kosmic.components.legacy.form.dateTimePickers.wednesday': translations({
    sv: `Ons`,
    en: `Wed`,
    de: `Mi`,
  }),
  'kosmic.components.legacy.form.dateTimePickers.friday': translations({
    sv: `Fre`,
    en: `Fri`,
    de: `Fr`,
  }),
  'This a multi activity ticket and can only be scheduled as a full day activity.': translations({
    sv: `Detta är en biljett för flera aktiviteter och kan endast schemaläggas som en heldagsaktivitet.`,
    en: `This a multi activity ticket and can only be scheduled as a full day activity.`,
    de: `Dies ist ein Ticket für mehrere Aktivitäten und kann nur als ganztägige Aktivität geplant werden.`,
  }),
  'kosmic.components.legacy.form.dateTimePickers.monday': translations({
    sv: `Mån`,
    en: `Mon`,
    de: `Mo`,
  }),
  Today: translations({
    sv: `Idag`,
    en: `Today`,
    de: `Heute`,
  }),
  'Message for organizer': translations({
    sv: `Meddelande till arrangören`,
    en: `Message for organizer`,
    de: `Nachricht an den Veranstalter`,
  }),
  'Save changes': translations({
    sv: `Spara ändringar`,
    en: `Save changes`,
    de: `Änderungen speichern`,
  }),
  'You can leave a private message (optional)': translations({
    sv: `Du kan lämna ett personligt meddelande till oss här (valfritt)`,
    en: `You can leave a private message (optional)`,
    de: `Sie können hier eine persönliche Nachricht hinterlassen (Optional).`,
  }),
  'Last name': translations({
    sv: `Efternamn`,
    en: `Last name`,
    de: `Nachname`,
  }),
  'Email address': translations({
    sv: `Epost adress`,
    en: `Email address`,
    de: `E-Mail-Adresse`,
  }),
  'First name': translations({
    sv: `Förnamn`,
    en: `First name`,
    de: `Vorname`,
  }),
  /**
   * Non-regular translation from state string
   */
  CreditCard: translations({
    sv: 'Kortbetalning',
    en: 'Card payment',
    de: 'Kartenzahlung',
  }),
  /**
   * Non-regular translation from state string
   */
  GiftCard: translations({
    sv: 'Presentkortsbetalning',
    en: 'Giftcard payment',
    de: 'Geschenkkartenzahlung',
  }),
  /**
   * Non-regular translation from state string
   */
  ManualPayment: translations({
    sv: 'Manuell betalning',
    en: 'Manual payment',
    de: 'Manuelle Zahlung',
  }),
  /**
   * Non-regular translation from state string
   */
  SkipPayment: translations({
    sv: 'Hoppa över betalning',
    en: 'Skip payment',
    de: 'Zahlung überspringen',
  }),
  Products: translations({
    sv: 'Produkter',
    en: 'Products',
    de: 'Produkte',
  }),
  Giftcard: translations({
    sv: 'Presentkort',
    en: 'Giftcard',
    de: 'Geschenkkarten',
  }),
  Remove: translations({
    sv: 'Ta bort',
    en: 'Remove',
    de: 'Entfernen',
  }),
  'Left to pay': translations({
    sv: 'Kvar att betala',
    en: 'Amount left',
    de: 'Noch zu zahlen',
  }),
  Total: translations({
    sv: 'Total kostnad',
    en: 'Total',
    de: 'Gesamtkosten',
  }),
  'Already paid': translations({
    sv: 'Redan betalt',
    en: 'Already paid',
    de: 'Bereits bezahlt',
  }),
  'Open print view for': translations({
    sv: `Öppna utskriftsvy för`,
    en: `Open print view for`,
    de: `Druckansicht öffnen für`,
  }),
  'Add to cart': translations({
    sv: 'Lägg i kundvagn',
    en: 'Add to cart',
    de: 'im Einkaufswagen',
  }),
  'Add booking for': translations({
    sv: 'Lägg till bokning för',
    en: 'Add booking for',
    de: 'Buchung für',
  }),
  person: translations({
    sv: 'person',
    en: 'person',
    de: 'Person',
  }),
  'Giftcard value': translations({
    sv: 'Presentkortets värde',
    en: 'Giftcard value',
    de: 'Der Wert der Geschenkkarte',
  }),
  'Hide value': translations({
    sv: 'Dölj värdet',
    en: 'Hide value',
    de: 'Wert ausblenden',
  }),
  'Show value': translations({
    sv: 'Visa värdet',
    en: 'Show value',
    de: 'Wert anzeigen',
  }),
  'The value will be displayed to the recipient': translations({
    sv: 'Värdet kommer att visas för mottagaren',
    en: 'The value will be displayed to the recipient',
    de: 'Der Wert wird dem Empfänger angezeigt',
  }),
  'The value will not be presented to the recipient': translations({
    sv: 'Värdet kommer inte att visas för mottagaren',
    en: 'The value will not be presented to the recipient',
    de: 'Der Wert wird dem Empfänger nicht angezeigt',
  }),
  Monday: translations({
    sv: 'Måndag',
    en: 'Monday',
    de: 'Montag',
  }),
  'No associated activities found for this ticket': translations({
    sv: 'Inga associerade aktiviteter hittades för denna biljett',
    en: 'No associated activities found for this ticket',
    de: 'No associated activities found for this ticket',
  }),
  'Ticket already exists': translations({
    sv: 'Biljetten redan finns',
    en: 'Ticket already exists',
    de: 'Ticket already exists',
  }),
  'Something went wrong on the server! Please try again later': translations({
    sv: 'Något gick fel på servern! Vänligen försök igen senare',
    en: 'Something went wrong on the server! Please try again later',
    de: 'Something went wrong on the server! Please try again later',
  }),
  Tuesday: translations({
    sv: 'Tisdag',
    en: 'Tuesday',
    de: 'Dienstag',
  }),
  Wednesday: translations({
    sv: 'Onsdag',
    en: 'Wednesday',
    de: 'Mittwoch',
  }),
  Thursday: translations({
    sv: 'Torsdag',
    en: 'Thursday',
    de: 'Donnerstag',
  }),
  Friday: translations({
    sv: 'Fredag',
    en: 'Friday',
    de: 'Freitag',
  }),
  Saturday: translations({
    sv: 'Lördag',
    en: 'Saturday',
    de: 'Samstag',
  }),
  Sunday: translations({
    sv: 'Söndag',
    en: 'Sunday',
    de: 'Sonntag',
  }),
  Duration: translations({
    sv: 'Längd i tid',
    en: 'Duration',
    de: 'Dauer',
  }),
  'Close preview': translations({
    sv: 'Stäng förhandsvisning',
    en: 'Close preview',
    de: 'Vorschau schließen',
  }),
  'See preview': translations({
    sv: 'Se förhandsvisning',
    en: 'See preview',
    de: 'Vorschau ansehen',
  }),
  'Applied voucher': translations({
    sv: 'Applicerat värdebevis',
    en: 'Applied voucher',
    de: 'Angewandte Gutscheine',
  }),
  Voucher: translations({
    sv: 'Värdebevis',
    en: 'Voucher',
    de: 'Gutscheine',
  }),
  'A voucher can be a...': translations({
    sv: 'Ett värdebevis kan vara ett presentkort, en tillgodokod eller en rabattkupong',
    en: 'A voucher can be a gift card, a credit code or a discount coupon',
    de: 'Ein Gutschein kann eine Geschenkkarte, eine Kreditcode oder ein Rabattgutschein sein',
  }),
  'Voucher code': translations({
    sv: 'Värdebeviskod',
    en: 'Voucher code',
    de: 'Gutscheincode',
  }),
  'You must have a swedish translation': translations({
    sv: 'Du måste ha en svensk översättning',
    en: 'You must have a swedish translation',
    de: 'Sie müssen eine schwedische Übersetzung haben',
  }),
  Swedish: translations({
    sv: 'Svenska',
    en: 'Swedish',
    de: 'Schwedisch',
  }),
  English: translations({
    sv: 'Engelska',
    en: 'English',
    de: 'Englisch',
  }),
  German: translations({
    sv: 'Tyska',
    en: 'German',
    de: 'Deutsch',
  }),
  'Total after discount': translations({
    sv: 'Totalt efter rabatt',
    en: 'Total after discount',
    de: 'Insgesamt nach rabatt',
  }),
  'Applied discount': translations({
    sv: 'Tillämpad rabatt',
    en: 'Applied discount',
    de: 'Angewandter Rabatt',
  }),
  'After discount': translations({
    sv: 'Efter rabatt',
    en: 'After discount',
    de: 'Nach abzug von rabatten',
  }),
  Applied: translations({
    sv: 'tillämpad',
    en: 'applied',
    de: 'angewandter',
  }),
  Discount: translations({
    sv: 'Rabatt',
    en: 'Discount',
    de: 'Rabatt',
  }),
  discount: translations({
    sv: 'rabatt',
    en: 'discount',
    de: 'rabatt',
  }),
  'Discount code used': translations({
    sv: 'Använd rabattkod',
    en: 'Discount code used',
    de: 'Rabattcode verwenden',
  }),
  'Price after': translations({
    sv: 'Pris efter',
    en: 'Price after',
    de: 'Preis nach',
  }),
  'Price after discount': translations({
    sv: 'Pris efter rabatt',
    en: 'Price after discount',
    de: 'Preis nach Rabatt',
  }),
  code: translations({
    sv: 'kod',
    en: 'code',
    de: 'Code',
  }),
  Advanced: translations({
    sv: 'Avancerad',
    en: 'Advanced',
    de: 'Fortschrittlich',
  }),
  'Advanced options': translations({
    sv: 'Avancerade inställningar',
    en: 'Advanced options',
    de: 'Erweiterte Optionen',
  }),
  Title: translations({
    sv: 'Titel',
    en: 'Title',
    de: 'Titel',
  }),
  New: translations({
    sv: 'Ny',
    en: 'New',
    de: 'Neu',
  }),
  'QR-codes': translations({
    sv: 'QR-koder',
    en: 'QR-codes',
    de: 'QR-codes',
  }),
  'Item information': translations({
    sv: 'Artikelinformation',
    en: 'Item information',
    de: 'Gegenstand information',
  }),
  'Status updated': translations({
    sv: 'Status uppdaterad',
    en: 'Status updated',
    de: 'Zustand aktualisiert',
  }),
  'Set the items location': translations({
    sv: 'Ange objektets plats',
    en: 'Set the items location',
    de: 'Legen Sie den Standort des Elements fest',
  }),
  Choose: translations({
    sv: 'Välj',
    en: 'Choose',
    de: 'Wählen',
  }),
  Ticket: translations({
    sv: 'Biljett',
    en: 'Ticket',
    de: 'Ticket',
  }),
  'Select a place': translations({
    sv: 'Välj en plats',
    en: 'Select a place',
    de: 'Wählen sie einen ort aus',
  }),
  Route: translations({
    sv: 'Rutt',
    en: 'Route',
    de: 'Route',
  }),
  'Loading available dates': translations({
    sv: 'Hämtar tillgängliga tider',
    en: 'Loading available dates',
    de: 'Verfügbare Daten werden geladen',
  }),
  'Business name': translations({
    sv: 'Företagsnamn',
    en: 'Business name',
    de: 'Firmenname',
  }),
  optional: translations({
    sv: 'valfritt',
    en: 'optional',
    de: 'Optional',
  }),
  ...giftCardPreviewCollection,
});

export type WidgetLocaleContext = LocaleContextState<typeof ahWidgetCollection>;

export const useLocale = () => useContext<WidgetLocaleContext>(LocaleContext);

/** Translation function that can be passed to utility functions */
export type TranslationFunction = WidgetLocaleContext['t'];
