import Lodash from 'lodash';
import { Schema } from 'mongoose';

import { UserResource } from './_userResource';
import { UserDocument } from '../documents';

export interface DefaultUserDocument extends UserDocument {
  organization?: any;
  roles: any[];
}

export class DefaultUserResource extends UserResource<DefaultUserDocument> {
  constructor() {
    super();

    this.setName('default');
    this.setSchema({
      organization: { type: Schema.Types.ObjectId, ref: 'organization', required: false },
    });
  }

  /** Checks if this user resource has the asked for role */
  public documentHasRole(document: UserDocument, role: string, strict?: boolean): boolean {
    return Lodash.includes(document.roles, role);
  }
}
