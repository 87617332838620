import Moment from 'moment-timezone';
import React from 'react';
import {
  ActivityOccuranceDocument,
  Alert,
  BookingDocument,
  BookingResource,
  Checkbox,
  Consume,
  CustomerDocument,
  LocaleContext,
  MailResource,
  MarketplaceContext,
  MarketplaceResource,
  MobxComponent,
  Modal,
  UserDocument,
  sendBookingCreatedNotification,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { CalendarStore } from '../store';
import { CustomerSearchForm } from './customerSearchForm';
import { ManualBookingForm } from './manualBookingForm';

interface Props {
  event: ActivityOccuranceDocument;
  store: CalendarStore;
}

interface State {
  selectedCustomer?: CustomerDocument;
  shouldSendConfirmationMail: boolean;
  shouldIncludePaymentLink: boolean;
}
export class CreateManualBookingForm extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldSendConfirmationMail: true,
      shouldIncludePaymentLink: true,
    };
  }

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(MarketplaceContext)
  private marketplace: MarketplaceContext;

  private modal: Modal;

  private createBooking = async (booking: BookingDocument, resolve, reject) => {
    const { t, locale } = this._locale;

    try {
      const marketplaceRef = await this.marketplace.createManualOrderWithBooking(
        booking,
        booking.organization.toString(),
      );
      await new BookingResource().updateDocument({ ...booking, marketplaceRef } as BookingDocument);

      this.props.store.reload();

      if (this.state.shouldSendConfirmationMail) {
        let link: string | undefined;
        if (this.state.shouldIncludePaymentLink) {
          const origin = location.origin;
          const path = '/payment/product';
          const query = `externalId=${booking.id}`;
          link = `${origin}${path}?${query}`;
        }

        new MailResource().sendBookingConfirmationEmail(booking.id, link);
      }

      // Create a creation details event describing how this booking was made
      // NOTE: this uses the marketplace resource directly, also email events are not
      // added here.
      // Suggestion: fix so that marketplace events are handled in a central location (module)
      // and update all references in refactoring
      const currentUser = (this.globals?.session?.currentUser || null) as UserDocument | null;

      await new MarketplaceResource().updateProduct(booking.id, 0, 'Booking source information', {
        key: 'creation_details',
        userEmail: currentUser?.username,
        userName: currentUser ? `${currentUser.firstname} ${currentUser.lastname}` : undefined,
      });

      // Send a booking creation notification if cross selling
      if (booking.crossSellingProperty) {
        await sendBookingCreatedNotification({
          booking,
          organization: this.globals.session.currentOrganization,
          locale,
        });
      }
      const { fullname } = booking.customer;
      const date = Moment(this.props.event.start).format('D MMM - HH:mm');
      const text = `${fullname} ${t('is now booked at the')} ${date}`;
      Alert.show(text, t('Booking created'), 'success');
      return resolve();
    } catch (err) {
      Alert.show(t('booking-creation.error.message'), t('Error'), 'error');
      return reject(err);
    }
  };

  private populateCustomerFields = (customer: CustomerDocument) => {
    this.setState({ selectedCustomer: customer });
    this.modal.hide();
  };

  private refundDeadline = () => {
    const { t } = this._locale;
    if (
      !this.props.store.isUserAllowedToEditEvent() &&
      !Moment(this.props.event.refundableUntil).isSame(this.props.event.start)
    ) {
      return (
        <div style={{ marginBottom: '1em' }}>
          <p>
            {t('Latest time for free cancellation')}
            {':  '}
            <span>
              <b>{Moment(this.props.event.refundableUntil).format('ddd D MMM HH:mm')}</b>
            </span>
          </p>
        </div>
      );
    }
  };

  private toggleShouldSendMail = () => {
    this.setState((prev) => ({
      shouldSendConfirmationMail: !prev.shouldSendConfirmationMail,
    }));
  };
  private toggleIncludePaymentLink = () => {
    this.setState((prev) => ({
      shouldIncludePaymentLink: !prev.shouldIncludePaymentLink,
    }));
  };

  render() {
    const { t } = this._locale;

    return (
      <div>
        <Modal size="small" header={t('Find customer')} closable={true} ref={(node) => (this.modal = node!)}>
          <div style={{ padding: '2em' }}>
            <CustomerSearchForm
              onSelectedCustomer={this.populateCustomerFields}
              contentUpdate={() => this.modal.refresh()}
            />
          </div>
        </Modal>

        <button
          style={{ marginBottom: '1em' }}
          className="ui fluid small primary button"
          onClick={() => this.modal.show()}
        >
          {t('Find customer')}
        </button>

        <ManualBookingForm
          store={this.props.store}
          submitButtonText={t('Add booking')}
          onSubmit={this.createBooking}
          event={this.props.event}
          selectedCustomer={this.state.selectedCustomer}
        >
          <Checkbox
            label={t('Send confirmation mail to customer')}
            checked={this.state.shouldSendConfirmationMail}
            onCheckedOrUncheked={this.toggleShouldSendMail}
            style={{ margin: '1rem 0', display: 'block' }}
          />
          <Checkbox
            label={t('Include a payment link in the mail')}
            checked={this.state.shouldIncludePaymentLink}
            onCheckedOrUncheked={this.toggleIncludePaymentLink}
            style={{ marginBottom: '2rem', display: 'block' }}
          />
          {this.refundDeadline()}
        </ManualBookingForm>
      </div>
    );
  }
}
