import React, { useContext, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { OffersContext } from '../../../../contexts/offers.context';
import { ActivityImageResource, ActivityTypeDocument, Alert, Form, ImageUploadForm } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { FormTabsMenu } from './tabs/formTabsMenu';
import { MainTab } from './tabs/mainTab';
import { PricingTab } from './tabs/pricingTab';
import { SettingsTab } from './tabs/settingsTab';
import { UpdateModal } from './updateModal';

interface Props {
  onSave: (offer: ActivityTypeDocument) => void;
}

const OfferForm = ({ onSave }: Props) => {
  const { t } = useLocale();
  const history = useHistory();
  const { pathname } = useLocation();
  const { url, path } = useRouteMatch();
  const { selectedOffer, saveSelectedOffer } = useContext(OffersContext);
  const [newImageId, setNewImageId] = useState('');
  const isEdit = Boolean(!selectedOffer?.isNew);
  const updateModalRef = useRef<UpdateModal>(null);
  const formRef = useRef<Form>(null);
  const isMainActive = !pathname.endsWith('pricing') && !pathname.endsWith('settings');

  if (!selectedOffer) return null;

  const handleFormSubmit = async (imageId: string, _imageId, _values, resolve, reject) => {
    if (isEdit) {
      setNewImageId(imageId);
      updateModalRef.current?.showModal();
      resolve();
      return;
    }

    await saveOffer(imageId);
    resolve();
  };

  const saveOffer = async (imageId: string = newImageId) => {
    try {
      await saveSelectedOffer(imageId);
      Alert.show(t('Both the offer and all...'), t('Offer was saved'), 'success');
      onSave(selectedOffer);
    } catch (err) {
      console.error(err);
      Alert.show(t('Could not be created'), t('Could not be saved'), 'error');
    }
  };

  const imageUploadHelpText = (
    <h3 className="ui header">
      {t('Choose an image for your offer')}
      <div className="sub header">{t('Recommended size for the image is 668x302 pixels')}</div>
    </h3>
  );

  const tryNavigateToTab = async (tabPath: string) => {
    if (await formRef.current?.validate()) {
      history.push(url + tabPath);
    }
  };

  return (
    <div style={{ minWidth: '200px' }}>
      <FormTabsMenu onNavigateToTab={tryNavigateToTab} />
      <ImageUploadForm
        defaultImage={selectedOffer.imageUrl || '/static/platform/img/default_activity.jpg'}
        onImagedSaved={handleFormSubmit}
        width={650}
        height={400}
        resource={new ActivityImageResource()}
        startingStep={'done'}
        helpText={imageUploadHelpText}
        showSkipButton={!isEdit}
        showPreview
        previewMaxWidth={'22rem'}
        hideUI={!isMainActive}
        formRef={formRef}
        previewFlexDirection="column"
      >
        <Switch>
          <Route exact path={path}>
            <MainTab onNavigateToTab={tryNavigateToTab} />
          </Route>
          <Route path={path + '/pricing'}>
            <PricingTab onNavigateToTab={tryNavigateToTab} />
          </Route>
          <Route path={path + '/settings'} component={SettingsTab} />
        </Switch>
      </ImageUploadForm>
      <UpdateModal ref={updateModalRef} onAccept={saveOffer} />
    </div>
  );
};

export default OfferForm;
