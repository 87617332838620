import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  ActivityDeleteResponses,
  Button,
  DeviceProvider,
  GlobalsContext,
  InventoryDocument,
  InventoryEntityDocument,
  InventoryResource,
  Modal,
  PlaceDocument,
  PlaceResource,
  Table,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
import { InventorySearchComponent } from '../inputs /inventorySearchBar';
import QrCodeButton from '../qr/qrCodeButton';
import { DeleteInventoryModalContent } from './deleteInventoryModal';
import InventoryReadonlyList from './inventoryReadonlyList';
import { InputTableRow as InventoryTableRow } from './inventoryTableRow';

export const InventoryTableView = () => {
  const { t } = useLocale();
  const [editId, setEditId] = useState<string>('');
  const [loading, setloading] = useState<boolean>(false);
  const [inventories, setInventories] = useState<InventoryDocument[]>([]);
  const [creatingNewInventory, setCreatingNewInventory] = useState<boolean>(false);
  const [attachedActivities, setAttachedActivities] = useState<ActivityDeleteResponses>();
  const [deletingInventory, setDeletingInventory] = useState<boolean>(false);
  const [bagsListView, setBagsListView] = useState<boolean>(false);
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [entitiesToPrint, setEntitiesToPrint] = useState<InventoryEntityDocument[]>([]);
  const [places, setPlaces] = useState<PlaceDocument[]>();
  const device = useContext(DeviceProvider.Context);
  const globals = useContext(GlobalsContext);
  const mobile = device.size === 'mobile';
  const tablet = device.size === 'tablet';
  const modal = React.useRef<Modal | null>(null);
  const { features } = globals.session.currentOrganization.flags;

  const handleRowClicked = (inventory: InventoryDocument) => {
    deleteEmpty();
    getInventories();
    setEditId(inventory.id);
  };

  const deleteEmpty = () => {
    //TODO: if navigating to another row when creating a new inventory, and you've created some inventory items.
    //Those items should be deleted when you click away from the row without saving.
    if (creatingNewInventory) {
      const [_, ...rest] = inventories;
      setInventories(inventories);
      setCreatingNewInventory(false);
    }
  };

  useEffect(() => {
    getInventories();
    getPlaces();
    setloading(false);
  }, []);

  const saveInventoryDocument = async (
    title: string,
    quantity: number,
    advancedOptions: boolean,
    groupMultiplier: number,
    timeToAvailable: number,
  ) => {
    const resource = new InventoryResource();
    const doc = resource.createDocument({
      _id: editId === 'new' ? undefined : editId,
      organization: globals.session.currentOrganizationId,
      title,
      quantity,
      usesAdvancedSetting: advancedOptions,
      groupMultiplier,
      timeToAvailable,
    });
    await resource.updateDocument(doc);
    getInventories();
    setEditId('');
    setCreatingNewInventory(false);
  };

  const getInventories = async () => {
    const inventories = await new InventoryResource().findWithPopulatedEntities();
    setInventories(inventories);
  };

  const getPlaces = async () => {
    const places = await new PlaceResource().find({ organization: globals.session.currentOrganizationId });
    setPlaces(places);
  };

  const showNewInventoryRow = () => {
    const resource = new InventoryResource();
    const inventory = resource.createDocument({ id: 'new', title: '', quantity: 0, entities: [] });
    setInventories((prevState) => [inventory, ...prevState]);
    setEditId(inventory.id);
    setCreatingNewInventory(true);
  };

  const deleteInventoryDocument = async () => {
    setDeletingInventory(true);
    if (creatingNewInventory) {
      const newArray = inventories.filter((x) => x.id !== editId);
      setInventories(newArray);
      setEditId('');
      setCreatingNewInventory(false);
      setDeletingInventory(false);
      return;
    }

    const attachedActivities: ActivityDeleteResponses = await new InventoryResource().checkAndDeleteInventory(editId);

    if (attachedActivities?.occurances?.length || attachedActivities?.activityTypes?.length) {
      setDeletingInventory(false);
      modal.current?.show();
      setAttachedActivities(attachedActivities);
    } else {
      setEditId('');
      setDeletingInventory(false);
      getInventories();
    }
  };

  return (
    <div style={{ padding: '2em' }}>
      <h2>
        {t('all-inventories')} {globals.session.currentOrganization.name}
      </h2>
      <p>{t('Here u can create inventories...')}</p>
      <p>{t('The system will calculate...')}</p>

      {bagsListView ? (
        <InventorySearchComponent
          placeholder={t('Filter by inventory name, location or booking number...')}
          getSearchQuery={(query: string) => setSearchQuery(query)}
        />
      ) : null}

      <div
        className={'ui basic segment ' + (loading || deletingInventory ? 'loading' : '')}
        style={{ padding: '0em', paddingTop: '1em' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1rem',
            justifyContent: features.handleClientBelongings ? 'space-between' : 'flex-end',
          }}
        >
          {features.handleClientBelongings ? (
            <div className="ui toggle checkbox">
              <input
                type="checkbox"
                name="public"
                onChange={() => {
                  !bagsListView ? setBagsListView(true) : setBagsListView(false);
                }}
              />
              <label>{t('Bags')}</label>
            </div>
          ) : null}

          <Button
            className={'primary'}
            disabled={creatingNewInventory}
            onClick={showNewInventoryRow}
            style={{ display: bagsListView ? 'none' : ' ' }}
          >
            {t('New inventory')}
          </Button>
          <div style={{ display: !bagsListView ? 'none' : ' ' }}>
            <QrCodeButton
              disabled={selectedEntities.length < 1}
              inventoryEntities={getInventoriesToPrint(selectedEntities, entitiesToPrint)}
              {...{ tablet, mobile }}
            />
          </div>
        </div>

        <Modal closable header={`${t("You can't delete this inventory")}!`} size="tiny" ref={modal}>
          <DeleteInventoryModalContent attachedActivities={attachedActivities} />
        </Modal>

        {!bagsListView ? (
          <div style={{ margin: mobile ? '0em -2em 0em -2em' : '' }}>
            <Table
              minWidth="52rem"
              initSortColumn="id"
              initSortDirection="desc"
              headers={[
                { title: t('inventories'), sortBy: 'title' },
                { title: t('Amount'), sortBy: 'quantity' },
                { title: t('Valid for'), sortBy: 'groupMultiplier' },
                { title: t('Reset time'), sortBy: 'timeToAvailable' },
                { title: t('Advanced'), width: 'one', sortBy: 'usesAdvancedSetting' },
                { title: '', width: 'one' },
              ]}
              dataList={inventories}
              renderRow={(doc) => {
                return doc.id === editId ? (
                  <InventoryTableRow
                    places={places}
                    key={editId}
                    onChange={saveInventoryDocument}
                    onDelete={deleteInventoryDocument}
                    inventoryDocument={doc}
                    creatingNewInventory={creatingNewInventory}
                  />
                ) : (
                  <tr key={doc.id} className="center aligned" onClick={() => handleRowClicked(doc)}>
                    <td className="left aligned">
                      <h4 className="ui header" style={{ cursor: 'pointer' }}>
                        {doc.title}
                      </h4>
                    </td>
                    <td className="left aligned">{doc.usesAdvancedSetting ? doc.entities.length : doc.quantity}</td>
                    <td className="left aligned">{doc.groupMultiplier}</td>
                    <td className="left aligned">{doc.timeToAvailable}</td>
                    <td className="left aligned">
                      {doc.usesAdvancedSetting ? (
                        <i className="checkmark green icon" />
                      ) : (
                        <i className="close red icon" />
                      )}
                    </td>

                    <td className="right aligned">
                      <i className="edit icon" />
                    </td>
                  </tr>
                );
              }}
            />
          </div>
        ) : (
          <InventoryReadonlyList
            places={places}
            setEntities={(entities) => setEntitiesToPrint(entities)}
            getSelectedEntities={(selected) => setSelectedEntities(selected)}
            searchQuery={searchQuery}
          />
        )}
      </div>
    </div>
  );
};

function getInventoriesToPrint(
  selectedEntities: string[],
  inventoryEntities: InventoryEntityDocument[],
): InventoryEntityDocument[] {
  return inventoryEntities.filter((e) => selectedEntities.includes(e.id));
}
