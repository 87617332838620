import { Types } from 'mongoose';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  BookingResource,
  GlobalsContext,
  OrganizationDocument,
  OrganizationResource,
  UserDocument,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { getRequirementGroups } from '../../dashboard/home/segments';
import { BadgesDropdown } from './badgeDropdown';

/** Simple css styles */
const noWrap: React.CSSProperties = { whiteSpace: 'nowrap' };

interface Props {
  organization: OrganizationDocument;
  suspendOrganization: (organizationId: Types.ObjectId) => Promise<void>;
  unSuspendOrganization: (organizationId: Types.ObjectId) => Promise<void>;
  loginAsAccount: (organizationId: Types.ObjectId) => Promise<void>;
  logoutFromAccount: () => Promise<void>;
}

const OrganizationInfoRow = ({
  organization,
  suspendOrganization,
  unSuspendOrganization,
  loginAsAccount,
  logoutFromAccount,
}: Props) => {
  const [showButtonLoader, setShowButtonLoader] = useState<boolean>(false);
  const [amountOfBookings, setBookingAmount] = useState<number>();
  const [orgStatus, setOrgstatus] = useState<string>();
  const { t } = useLocale();
  const globals = useContext(GlobalsContext);

  useEffect(() => {
    (async () => {
      const bookingResult = await new BookingResource().getNumberOfBookingForOrganization(organization._id);
      setBookingAmount(bookingResult.nrOfBookings);

      if (organization.isSuspended) {
        setOrgstatus('suspended');
      } else if (!organization.isVerified) {
        setOrgstatus('not-verified');
      } else {
        const account = await new OrganizationResource().getStripeAccount(organization);
        if (!account) {
          setOrgstatus('no-agreement');
        } else {
          const requirementGroups = getRequirementGroups(account);
          if (requirementGroups.length) {
            setOrgstatus('requirements');
          } else {
            setOrgstatus('activated');
          }
        }
      }
    })();
  }, [organization]);

  const activationProgressLabel = (): JSX.Element => {
    switch (orgStatus) {
      case 'suspended':
        return (
          <div className="ui red label" style={noWrap}>
            <i className="ban icon"></i>
            {t('components.admin.organizations.tableRow.suspend')}
          </div>
        );
      case 'not-verified':
        return (
          <div className="ui grey label" style={noWrap}>
            <i className="mail icon"></i>
            {t('components.admin.organizations.tableRow.verification')}
          </div>
        );
      case 'activated':
        return (
          <div className="ui green label" style={noWrap}>
            <i className="checkmark icon"></i>
            {t('components.admin.organizations.tableRow.activated')}
          </div>
        );
      case 'requirements':
        return (
          <div className="ui yellow label" style={noWrap}>
            <i className="stripe icon"></i>
            {t('components.admin.organizations.tableRow.requirements')}
          </div>
        );
      case 'no-agreement':
        return (
          <div className="ui red label" style={noWrap}>
            <i className="exclamation icon"></i>
            {t('components.admin.organizations.tableRow.no-agreement')}
          </div>
        );
      default:
        return <div className="ui mini active inline loader" />;
    }
  };

  const admin = (): UserDocument | undefined => {
    if (organization.admins && organization.admins.length) {
      return organization.admins[0] as any as UserDocument;
    }
  };

  const accountInfo = () => {
    return (
      <h4 className="ui header">
        {organization.name}
        {admin() && <div className="sub header">{admin()?.username}</div>}
      </h4>
    );
  };

  const suspend = async () => {
    setShowButtonLoader(true);
    await suspendOrganization(organization._id);
    setShowButtonLoader(false);
  };

  const unSuspend = async () => {
    setShowButtonLoader(true);
    await unSuspendOrganization(organization._id);
    setShowButtonLoader(false);
  };

  const updateFeatures = async (features: OrganizationDocument['flags']['features']) => {
    setShowButtonLoader(true);
    await new OrganizationResource().updateFeatureFlags(organization._id, features);
    setShowButtonLoader(false);
  };

  const loginAccount = async () => {
    setShowButtonLoader(true);
    await loginAsAccount(organization._id);
    setShowButtonLoader(false);
  };

  const logoutAccount = async () => {
    setShowButtonLoader(true);
    await logoutFromAccount();
    setShowButtonLoader(false);
  };

  const actionButtons = () => {
    if (orgStatus === 'suspended') {
      return (
        <button className={'ui green small button ' + (showButtonLoader ? 'loading' : '')} onClick={unSuspend}>
          {t('Activate account')}
        </button>
      );
    }
    return (
      <button className={'ui inverted red small button ' + (showButtonLoader ? 'loading' : '')} onClick={suspend}>
        {t('components.admin.organizations.tableRow.suspendAccount')}
      </button>
    );
  };

  const bookingAmount = () => {
    if (!amountOfBookings && amountOfBookings !== 0) {
      return <div className="ui mini active inline loader" />;
    }

    return amountOfBookings;
  };

  const newsletter = () => {
    if (admin() && admin()?.isSubscribedForNewsletters) {
      return <i className="check icon" />;
    }
  };

  const loginAs = () => {
    const user = globals.session.currentUser as UserDocument;
    const systemOwner = user.roles.find((role) => role.type == 'system.owner');
    const currentorgId = globals.session.currentOrganizationId.toString();
    const orgId = organization.id;

    if (systemOwner && systemOwner.originalOrganization && orgId == systemOwner.originalOrganization.toString()) {
      return null;
    }

    if (orgId == currentorgId) {
      return (
        <button
          className={'ui inverted green small button fluid ' + (showButtonLoader ? 'loading' : '')}
          onClick={logoutAccount}
        >
          {t('components.admin.organizations.tableRow.logoutAs')}
        </button>
      );
    }

    return (
      <button className={'ui green small button fluid ' + (showButtonLoader ? 'loading' : '')} onClick={loginAccount}>
        {t('components.admin.organizations.tableRow.loginAs')}
      </button>
    );
  };

  const badges = () => {
    return <BadgesDropdown organization={organization} />;
  };

  const editButton = () => {
    const onClick = () => {
      $(`#modal-${organization.id}`).modal('show');
    };
    return (
      <button className="ui yellow small button fluid" onClick={onClick}>
        {t('Handle')}
      </button>
    );
  };

  return (
    <>
      <OrganizationEditModal
        organization={organization}
        extraAction={actionButtons()}
        updateFeatures={updateFeatures.bind(this)}
      />
      <tr>
        <td>{newsletter()}</td>
        <td>{accountInfo()}</td>
        <td>{activationProgressLabel()}</td>
        <td>{bookingAmount()}</td>
        <td>{badges()}</td>
        <td>{editButton()}</td>
        <td>{loginAs()}</td>
      </tr>
    </>
  );
};

type OrganizationEditModalProps = {
  organization: OrganizationDocument;
  extraAction: JSX.Element;
  updateFeatures: (features: OrganizationDocument['flags']['features']) => void;
};

const OrganizationEditModal = ({ organization, extraAction, updateFeatures }: OrganizationEditModalProps) => {
  const { t } = useLocale();
  const [features, setFeatures] = React.useState(
    organization.flags.features || {
      scheduling: true,
      offers: true,
      bookings: true,
      giftcards: true,
      widgets: true,
      properties: true,
    },
  );

  return (
    <div id={`modal-${organization.id}`} className="ui modal">
      <i className="close icon" />
      <div className="header">{organization.name}</div>
      <div className="content">
        <h4>Funktionsflaggor</h4>
        {Object.keys(features).map((feature) => (
          <React.Fragment key={feature}>
            <div className="ui checkbox">
              <input
                type="checkbox"
                checked={features[feature]}
                onChange={() => {
                  setFeatures((state) => {
                    const newState = { ...state, [feature]: !state[feature] };
                    updateFeatures(newState);
                    return newState;
                  });
                }}
              />
              <label>{feature}</label>
            </div>
            <br />
          </React.Fragment>
        ))}
      </div>
      <div className="actions">
        {extraAction}
        <div className="ui cancel button">{t('Close')}</div>
      </div>
    </div>
  );
};

export default OrganizationInfoRow;
