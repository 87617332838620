import React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  Button,
  Consume,
  LocaleContext,
  MobxComponent,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';

interface Props {
  event: ActivityOccuranceDocument;
}

export class PublishButton extends MobxComponent<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  toggleIsPublished = async () => {
    const { event } = this.props;
    event.isPublished = !event.isPublished;
    const resource = new ActivityOccuranceResource();
    await resource.updateDocument(this.props.event);
  };

  render() {
    const { t } = this._locale;
    const { isPublished } = this.props.event;

    return (
      <Button
        className={`small fluid ${isPublished ? 'basic' : 'primary'}`}
        style={{ marginTop: '1rem' }}
        onClick={this.toggleIsPublished}
      >
        {isPublished ? t('Unpublish') : t('Publish activity')}
      </Button>
    );
  }
}
