import { useContext } from 'react';
import {
  generateTranslationsFunction,
  generateTranslationCollection,
  LocaleContextState,
  LocaleContext,
} from './_dependencies';

const translations = generateTranslationsFunction<{
  sv: string;
  en: string | null;
  de: string | null;
}>();

export const { globalCurrentLocale, collection: kosmicTranslationCollection } = generateTranslationCollection({
  Save: translations({
    sv: `Spara`,
    en: `Save`,
    de: `Speichern`,
  }),
  'kosmic.components.legacy.form.imageForm.notAnImage': translations({
    sv: `Ogiltig bild!`,
    en: `Not valid picture!`,
    de: `Ungültiges Bild!`,
  }),
  'kosmic.components.legal.cookieConsentBanner.message': translations({
    sv: `Vi använder webbkakor för bästa möjliga upplevelse. Om du fortsätter innebär det att du accepterar att kakor används.`,
    en: `We use cookies for the best possible experience. If you continue, it means that you accept the use of cookies.`,
    de: `Wir verwenden Cookies für das bestmögliche Erlebnis. Wenn Sie fortfahren, bedeutet dies, dass Sie die Verwendung von Cookies akzeptieren.`,
  }),
  'kosmic.components.legacy.copyToClipboardButton.copyErrorTextRetry': translations({
    sv: `Var snäll och testa i en annan webbläsare.`,
    en: `Please try another browser.`,
    de: `Bitte versuchen Sie es mit einem anderen Browser.`,
  }),
  'kosmic.components.legacy.form.imageForm.orClickHere': translations({
    sv: `eller klicka här`,
    en: `or click here`,
    de: `oder klicke hier`,
  }),
  'kosmic.components.legacy.copyToClipboardButton.copyErrorTextManually': translations({
    sv: `Var god markera texten och kopiera manuellt.`,
    en: `Please mark the text and copy manually.`,
    de: `Bitte markieren Sie den Text und kopieren Sie ihn manuell.`,
  }),
  'kosmic.components.legacy.form.imageForm.dragAndDropImage': translations({
    sv: `Dra hit och släpp en bild!`,
    en: `Drag and drop to upload a picture!`,
    de: `Ziehe das Bild hierhin`,
  }),
  'kosmic.components.legacy.form.imageForm.dropImage': translations({
    sv: `släpp bilden...`,
    en: `drop to upload picture…`,
    de: `gib das Bild frei …`,
  }),
  Cancel: translations({
    sv: `Avbryt`,
    en: `Cancel`,
    de: `Abbrechen`,
  }),
  'kosmic.components.legacy.form.imageForm.selectImage': translations({
    sv: `Välj ny bild`,
    en: `Choose new picture`,
    de: `Wähle ein neues Bild`,
  }),
  'kosmic.components.legacy.copyToClipboardButton.copyErrorTitle': translations({
    sv: `Kunde inte kopiera`,
    en: `Could not copy`,
    de: `Konnte nicht kopieren`,
  }),
  'Read more about the team behind Adventure': translations({
    sv: `Läs mer om skaparna till Adventure`,
    en: `Read more about the team behind Adventure`,
    de: `Lesen Sie mehr über das Team hinter Adventure`,
  }),
  'kosmic.components.legacy.form.imageForm.continue': translations({
    sv: `Gå vidare`,
    en: `Continue`,
    de: `Weiter`,
  }),
});

export type KosmicLocaleContext = LocaleContextState<typeof kosmicTranslationCollection>;

export const useLocale = () => useContext<KosmicLocaleContext>(LocaleContext);
