import React, { useState } from 'react';
import { PlaceDocument } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';

interface Props {
  placeHolder: string;
  locations?: PlaceDocument[];
  setPlace: (place: PlaceDocument | null) => void;
  resetOption?: boolean;
}

export const LocationDropdown = ({ placeHolder, locations, resetOption, setPlace }: Props) => {
  const [selected, setPlaceholder] = useState<string | undefined>(undefined);
  const { t, tt } = useLocale();

  return (
    <div className="ui compact fluid menu" style={{ marginRight: '1rem' }}>
      <div className={`ui simple fluid dropdown item`}>
        <i className="map outline icon" />
        <div style={{ width: '100%' }}>{!selected ? placeHolder : selected}</div>
        <i className="dropdown icon" />
        <div className="menu">
          {resetOption ? (
            <div
              className="item"
              onClick={() => {
                setPlace(null), setPlaceholder(placeHolder);
              }}
            >
              {t('Reset')}
            </div>
          ) : null}
          {locations?.map((place) => (
            <div
              className="item"
              key={place.id}
              onClick={() => {
                setPlace(place), setPlaceholder(tt(place.name));
              }}
            >
              {tt(place.name)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
