import * as React from 'react';

interface Props {
  title: string;
  img: string;
  description: string;
}

export function SliderSection(props: Props) {
  return (
    <div style={rootStyle}>
      <img src={props.img} alt="" style={imgStyle} />
      <h2 style={{ fontSize: '2rem', color: '29BB9C' }}>{props.title}</h2>
      <p style={textStyle}>{props.description}</p>
    </div>
  );
}

const rootStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

const imgStyle: React.CSSProperties = {
  width: '80%',
  maxWidth: '50rem',
  objectFit: 'cover',
  margin: '2rem',
};

const textStyle: React.CSSProperties = {
  fontSize: '1.3rem',
  width: '70%',
  maxWidth: '40rem',
  textAlign: 'center',
};
