import Cookie from 'js-cookie';
import { InferTranslationSetOf } from './_types';

/**
 * FIXME: Change to collection instance that can be passed directly to the LocaleProvider, so that default and supported locales are sync'ed
 * FIXME: also fix that Platform does not also has a supported locales
 * @param collection A collection of mapped id to available translations set
 * @returns A method that can be used to get the actual translated string for a id and possible locale
 */
export const generateTranslationCollection = <
  Collection extends Record<string, any>,
  TranslationSet = InferTranslationSetOf<Collection>,
>(
  collection: Collection,
) => {
  const defaultLocale: Locale = 'sv'; // FIXME: fixed to sv
  const supportedLocales: Array<Locale> = ['sv', 'en', 'de']; // FIXME: fixed

  // Internal method that returns the translated string
  const getTranslatedString = (locale: keyof TranslationSet, id: keyof Collection) => {
    try {
      const translation = collection[id];
      const result = translation[locale] || translation[defaultLocale];
      if (!result) {
        throw new Error('no translation found');
      }
      return result;
    } catch {
      console.error(`Unable get the translation for ${id} with locale ${locale} or default locale ${defaultLocale}`);
    }
  };

  return {
    /**
     * Returns the translation for the localization set
     * at the given id for the given locale.
     */
    getTranslatedString,
    /**
     * Returns the translation for the localization set
     * at the given id for the current locale in the cookie 'locale'.
     * @deprecated
     */
    globalT: (id: keyof Collection) => {
      const locale: keyof TranslationSet = Cookie.get('locale') || defaultLocale;
      return getTranslatedString(locale, id);
    },
    /**
     * Returns the current locale from cookies
     * @deprecated
     */
    globalCurrentLocale: () => {
      const cookieLocale = Cookie.get('locale') as keyof TranslationSet | undefined;
      return cookieLocale || defaultLocale;
    },
    /**
     * The complete collection of all translations
     */
    collection,
    /**
     * The default locale to use
     */
    defaultLocale,
    /**
     * The supported locales
     */
    supportedLocales,
  };
};
