import * as React from 'react';
import { IStateSetter } from './interfaces';
import { Provider } from './provider';

export interface IDevice extends IStateSetter<IDevice> {
  /**
   * Allows us to detect if this is a "wapp", that is
   * a web app loaded in a thin react-native application or similar.
   * This allows the user interface to be changed for this specific mode
   */
  isWrappedApp: boolean;
  /**
   * Detects using the user-agent string if the client is using a mobile browser
   */
  isMobileBrowser: boolean;
  /** Detects the operating system used */
  os: 'windows-phone' | 'android' | 'ios' | 'mac' | 'windows' | 'linux' | 'unix' | 'unknown';
  /** Detects the type of browser used */
  browser: 'opera' | 'firefox' | 'safari' | 'chrome' | 'edge' | 'ie' | 'unknown';
  /** The user-agent string of the client */
  userAgent: string;
  /** The estimated screen size of the client device */
  size: 'mobile' | 'tablet' | 'desktop';
  /** True when screen is wider than 1600px */
  width: number;
}

export interface DeviceProviderProps {
  /**
   * Triggers if this is a "wapp", that is
   * a web app loaded in a thin react-native application or similar.
   * This allows the user interface to be changed for this specific mode
   */
  isWrappedApp?: IDevice['isWrappedApp'];

  /** The initial estimated width visible to the client, most important for server side rendering */
  initialWidth?: number;
  /** The initial estimated heigth visible to the client, most important for server side rendering */
  initialHeigth?: number;
}

/**
 * Provides the properties related to the current clients device, e.g. hardware and browser settings
 * and the visible screen size
 */
export class DeviceProvider extends Provider<IDevice, DeviceProviderProps> {
  /** Declares the context to use */
  public static Context = React.createContext<IDevice>({} as any);

  /** Exposes the context to use */
  protected use(): React.Context<IDevice> {
    return DeviceProvider.Context;
  }

  /** Sets the initial state of the provider */
  protected initialState(): IDevice {
    // Set the correct user agent
    const userAgent =
      MODULE_ENVIRONMENT == 'browser' ? navigator.userAgent || navigator.vendor || (window as any).opera : '';

    // Determine if this is a mobile browser
    const isMobileBrowser =
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        userAgent,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        userAgent.substr(0, 4),
      );

    // Pass the correct client type setting from props
    const isWrappedApp = this.props.isWrappedApp || false;

    return {
      setState: this.setState.bind(this),
      userAgent,
      isMobileBrowser,
      browser: this.getBrowser(userAgent),
      os: this.getOSFromUserAgent(userAgent),
      isWrappedApp,
      size: this.calculateViewableSize(),
      width: this.getWidth(),
    };
  }

  private getOSFromUserAgent(userAgent: string): IDevice['os'] {
    try {
      if (/windows phone/i.test(userAgent)) {
        return 'windows-phone'; // Windows Phone must come first because its UA also contains "Android"
      }
      if (/android/i.test(userAgent)) {
        return 'android';
      }
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'ios';
      }
      if (navigator.appVersion.indexOf('Win') != -1) {
        return 'windows';
      }
      if (navigator.appVersion.indexOf('Mac') != -1) {
        return 'mac';
      }
      if (navigator.appVersion.indexOf('X11') != -1) {
        return 'unix';
      }
      if (navigator.appVersion.indexOf('Linux') != -1) {
        return 'linux';
      }
      return 'unknown';
    } catch {
      return 'unknown';
    }
  }

  private getBrowser(userAgent: string): IDevice['browser'] {
    try {
      // Opera 8.0+
      //@ts-ignore
      if ((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
        return 'opera';
      }

      // Firefox 1.0+
      //@ts-ignore
      if (typeof InstallTrigger !== 'undefined') {
        return 'firefox';
      }

      // Internet Explorer 6-11 & Edge
      //@ts-ignore
      if (/*@cc_on!@*/ false || !!document.documentMode) {
        //@ts-ignore
        if (window.StyleMedia) {
          return 'edge';
        }
        return 'ie';
      }

      // Chrome 1 - 79 & Edge (based on chromium) detection
      if (
        (/Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor)) ||
        (!!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime))
      ) {
        return 'chrome';
      }

      // Safari 3.0+ "[object HTMLElementConstructor]"
      if (
        //@ts-ignore
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === '[object SafariRemoteNotification]';
          //@ts-ignore
        })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
      ) {
        return 'safari';
      }

      return 'unknown';
    } catch {
      return 'unknown';
    }
  }

  private getWidth() {
    let width = this.props.initialWidth || 0;
    //let heigth = this.props.initialHeigth || 0;

    // Get the correct values for the clients visible width and heigth depending on the current environment
    if (MODULE_ENVIRONMENT != 'node') {
      if (window.innerWidth) {
        width = window.innerWidth;
        //heigth = window.innerHeight;
      } else if (document.documentElement && document.documentElement.clientWidth) {
        width = document.documentElement.clientWidth;
        //heigth = document.documentElement.clientHeight;
      } else if (document.body && document.body.clientWidth) {
        width = document.body.clientWidth;
        //heigth = document.body.clientWidth;
      }
    }
    return width;
  }

  /** Calculates the next vieable area for the client and updates the state, this is called automatically when the window is resized */
  private calculateViewableSize(): IDevice['size'] {
    const width = this.getWidth();
    // Determnies the estimated screen size of the client device based on: https://www.w3schools.com/browsers/browsers_display.asp
    if (width >= 1366) {
      return 'desktop';
    } else if (width >= 768) {
      return 'tablet';
    } else {
      return 'mobile';
    }
  }

  componentDidMount() {
    $(window).resize(() =>
      this.setState({
        size: this.calculateViewableSize(),
        width: this.getWidth(),
      }),
    );
  }
}
