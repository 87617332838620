import React from 'react';
import shortid from 'shortid';
import { AccessoryDocument, Field, getAccessories, PriceCategoryDocument, TaxRate } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';
import { AccessoryRow } from './accessoryRow';

interface Props {
  categories: PriceCategoryDocument[];
  onPriceCategoriesChange: (key: 'priceCategories', value: PriceCategoryDocument[]) => void;
  defaultTaxRate: TaxRate;
}

export const CreateAccessoriesFields = ({ categories, onPriceCategoriesChange, defaultTaxRate }: Props) => {
  const { t } = useLocale();
  const relevantCategories = getAccessories(categories);
  const findCategoryIndex = (cat: PriceCategoryDocument) => categories.findIndex((category) => category == cat);

  const addPriceCategory = (newCategory: AccessoryDocument<PriceCategoryDocument>) => {
    const newCategories = [...categories, newCategory];
    onPriceCategoriesChange('priceCategories', newCategories);
  };

  const removePriceCategory = (index: number) => {
    if (index === -1) return;
    const nextCategories = [...categories];
    nextCategories.splice(index, 1);
    onPriceCategoriesChange('priceCategories', nextCategories);
  };

  const changePriceCategory = (index: number, priceCategory: PriceCategoryDocument) => {
    if (index === -1) return;
    const nextCategories = [...categories];
    nextCategories[index] = priceCategory;
    onPriceCategoriesChange('priceCategories', nextCategories);
  };

  return (
    <Field label={t('Accessories')}>
      {relevantCategories.length > 0 && (
        <div className="ui column grid" style={{ margin: 0 }}>
          {relevantCategories.map((category, index) => {
            // Since price category documents does no have an id we generate
            // a temporary id so react knows how to handle deletes correctly.
            category.id = category.id || shortid.generate();
            return (
              <AccessoryRow
                key={category.id}
                priceCategory={category}
                trueIndex={findCategoryIndex(category)}
                nrOfRows={relevantCategories.length}
                rowNumber={index}
                changePriceCategory={changePriceCategory}
                removePriceCategory={removePriceCategory}
              />
            );
          })}
        </div>
      )}
      <div className="ui column">
        <div
          onClick={() =>
            addPriceCategory({
              name: '',
              price: undefined as unknown as number,
              groupMultiplier: 0,
              taxRate: defaultTaxRate,
            })
          }
          className="ui basic fluid icon button"
        >
          <i className="plus icon" /> {t('Add an accessory')}
        </div>
      </div>
    </Field>
  );
};
