import { DocumentToObjectOptions, Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { BookingDocument } from './booking.resource';
import { InventoryStatusDocument } from './inventoryStatus.resource';
export interface InventoryEntity {
  organization: Types.ObjectId;
  inventory?: Types.ObjectId;
  title: string;
  /** Virtual field */ isTemporary?: boolean;
  /** Virtual field calculated in receiver as a post hook */ lastStatus: InventoryStatusDocument;
}

export type InventoryEntityDocument = InventoryEntity & MongooseDocument;
export class InventoryEntityResource extends MongooseResource<InventoryEntityDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.InventoryEntity);

    this.setSchema({
      organization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization },
      inventory: { type: Schema.Types.ObjectId, ref: SchemaNames.Inventory },
      title: { type: String, required: true },
    });

    /*
     * When we work with fields created in post hooks it's super important
     * that toObject and toJSON preserves those fields.
     */
    this.schema.set('toObject', this.toObjectOptions);
    this.schema.set('toJSON', this.toObjectOptions);

    this.addVirtualField('isTemporary', (doc) => Boolean(!doc.inventory));
  }

  private toObjectOptions: DocumentToObjectOptions = {
    virtuals: true,
    transform: function (doc: InventoryEntityDocument, ret: InventoryEntity) {
      ret.lastStatus = doc.lastStatus;
      return ret;
    },
  };

  getBookingsSpecificToEntity(entityId: string) {
    return this.sendRequest<any>('/getBookingsSpecificToEntity', 'post', { id: entityId });
  }

  deleteEntitiesAndStatuses(booking: BookingDocument) {
    return this.sendRequest<any>('/deleteEntitiesAndStatuses', 'post', { id: booking._id });
  }

  findWithPopulatedLastStatusLocation(query: object) {
    return this.sendRequest<any>('/findWithPopulatedLastStatusLocation', 'post', { query });
  }
}
