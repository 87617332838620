import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  Alert,
  Field,
  ImageUploadForm,
  Input,
  PlaceDocument,
  PlaceResource,
  PropertyImageResource,
  Rules,
  SessionContext,
  SubmitButton,
  TranslatableTextField,
} from '../../../_dependencies';
import { useLocale } from '../../../_locales';
import { CoordinateSelector } from '../../common/coordinateSelector';
import { useProperties } from './propertiesProvider';

export const PlaceForm = () => {
  const { t, tt } = useLocale();
  const history = useHistory();
  const { setIsSidebarOpen } = useProperties();
  const session = useContext(SessionContext);
  const params = useParams<{ id: string }>();
  const [place, setPlace] = useState<PlaceDocument>();
  const [hasChanged, setHasChanged] = useState(false);
  const { fetchPlaces } = useProperties();
  const isEditing = Boolean(params.id);

  useEffect(() => {
    setIsSidebarOpen(true);
  });

  useEffect(() => {
    setHasChanged(false);

    if (!params.id) {
      setPlace({
        imageUrl: '',
        imageId: '',
        name: { sv: '' },
        description: { sv: '' },
        location: {
          lat: 57.72665301967661,
          lng: 12.95128273692986,
        },
      } as PlaceDocument);
      return;
    }

    (async () => {
      const place = await new PlaceResource().get(params.id);
      setPlace(place);
    })();
  }, [params.id]);

  function setPlaceField<K extends keyof PlaceDocument, V extends PlaceDocument[K]>(key: K, value: V) {
    setPlace(
      (place) =>
        ({
          ...place!,
          [key]: value,
        } as PlaceDocument),
    );
    setHasChanged(true);
  }

  const save = async (imageId = place?.imageId, imageUrl, values, resolve, reject) => {
    try {
      const organization = session.getCurrentOrganizationId();
      const resource = new PlaceResource();
      const doc = resource.createDocument({ ...place, organization, imageId });
      await resource.updateDocument(doc);
      Alert.show('Platsen har sparats', undefined, 'success');
      resolve();
      setHasChanged(false);
      fetchPlaces();
      if (!isEditing) {
        history.replace(`/dashboard/properties/places/${doc.id}`);
      }
    } catch (error) {
      Alert.show('Var god försök igen', 'Platsen kunde inte sparas', 'error');
      console.error(error);
      reject();
    }
  };

  if (!place) return null;

  return (
    <div key={place.id} style={{ padding: '20px' }}>
      <h2 className="ui header">
        <i className="map pin icon" />
        <div className="content">{isEditing ? tt(place.name) : t('Add a place')}</div>
      </h2>
      <div style={{ minWidth: '300px', maxWidth: '600px' }}>
        <ImageUploadForm
          defaultImage={place.imageUrl || '/static/platform/img/default_activity.jpg'}
          onImagedSaved={save}
          onNewStep={(step) => step === 'crop' && setHasChanged(true)}
          width={650}
          height={400}
          resource={new PropertyImageResource()}
          startingStep={isEditing ? 'done' : 'select'}
          helpText={
            <h3 className="ui header">
              {!isEditing
                ? t('components.dashboard.properties.form.FirstChoosePropertyPicture')
                : t('components.dashboard.properties.form.choosePropertyPicture')}
              <div className="sub header">
                {!isEditing && <span>{t('components.dashboard.properties.form.pictureView')}&nbsp;</span>}
                {t('components.dashboard.properties.form.recommendedPicSize')}
              </div>
            </h3>
          }
          showPreview
        >
          <div style={{ height: '2em' }} />
          <TranslatableTextField
            name="Namn"
            label={t('Name')}
            value={place.name}
            onChange={(value) => setPlaceField('name', value)}
            placeholder={t('Max 40 characters')}
            rules={[Rules.NotEmpty()]}
          />
          <TranslatableTextField
            textarea
            name="Beskrivning"
            label={t('Description')}
            value={place.description}
            onChange={(value) => setPlaceField('description', value)}
            placeholder={t('Describe this place to your customers')}
            rules={[Rules.NotEmpty()]}
          />
          <Field label={t('Geographical location')}>
            <div
              className="ui segment"
              id="coordinate-selector"
              style={{ position: 'relative', height: 400, margin: 0, padding: '.5rem', boxShadow: 'none' }}
            >
              <CoordinateSelector
                location={place.location}
                onChange={(value) => setPlaceField('location', value)}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDNeWxgti3E_OsTgxut6uFb4lBvV1a3dAY&v=3.exp&libraries=geometry,drawing,places"
              />
            </div>
          </Field>

          <Field label={t('Tag')}>
            <Input name="Tag" value={place.tag} onChange={(value) => setPlaceField('tag', value)} />
          </Field>

          <SubmitButton disabled={!hasChanged} className={'fluid green'}>
            {hasChanged ? t('Save place') : 'Det finns inget att spara'}
          </SubmitButton>
        </ImageUploadForm>
      </div>
    </div>
  );
};
