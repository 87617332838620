import Moment from 'moment-timezone';
import React, { FC, useContext, useEffect, useState } from 'react';
import ShortId from 'shortid';
import {
  BookingPriceCategoryDocument,
  BookingResource,
  CustomerDocument,
  GiftCardResource,
  GiftCardTypeDocument,
  MarketplaceLibrary as Lib,
  MarketplaceOrder,
  UserDocument,
  visitors,
} from './_dependencies';
import { usePrevious } from './hooks/usePrevious';
import { MarketplaceContext, MarketplaceProps } from './marketplace.context';
import { IWidgetChangableDataProps, WidgetDataContext } from './widgetDataProvider';

export type MarketplaceCartContext = {
  cart: Lib.CartItem[];
  purchasedItems: Lib.CartItem[];
  areThereCartBookingsInCart: boolean;
  numberOfVisitorsForOccurance: (id?: string) => number;
  cartItemIsCartBooking: (item: Lib.CartItem) => item is Lib.CartBooking;
  cartItemIsCartGiftcard: (item: Lib.CartItem) => item is Lib.CartGiftcard;
  placeItemInCart: (opts: ItemOptions) => void;
  removeItemFromCart: (item: Lib.CartItem) => void;
  toggleHideGiftcardValue: (giftcard: Lib.CartGiftcard) => void;
  createMarketplaceOrder: () => Promise<void>;
  removeMarketplaceOrder: () => Promise<void>;
  placeOrderWithoutPayment: (cart: Lib.CartItem[]) => Promise<void>;
  placeOrderWithManualPayment: (cart: Lib.CartItem[]) => Promise<void>;
  placeOrderWithCardPayment: (
    cart: Lib.CartItem[],
    cardDetails: Lib.CardDetails | undefined,
    cardActionHandler: Lib.CardActionHandler,
  ) => Promise<any>;
  customer?: CustomerInfo;
  setCustomerInfoToState: (customer: CustomerInfo) => void;
  order: MarketplaceOrder;
};

interface MarketplaceCartProps extends MarketplaceProps {
  ignoreSignedInUser: IWidgetChangableDataProps['ignoreSignedInUser'];
  /** A unique key generated from org/widget specific parameters */
  localStorageKeygen: string;
  /** A unique key generated from org/widget specific parameters */
  localStorageRequestKeygen: string;
  initialItem?: ItemOptions;
}

/**
 * This context work sort of like a decorator for the marketplace context
 */
export const MarketplaceCartContext = React.createContext<MarketplaceCartContext>({} as any);

export const MarketplaceCartProvider: FC<MarketplaceCartProps> = ({
  children,
  ignoreSignedInUser,
  localStorageKeygen,
  localStorageRequestKeygen,
  initialItem,
}) => {
  /**
   * Context resources
   */
  const widget = useContext(WidgetDataContext);
  const { order, setMarketplaceOrder, clearMarketplaceOrder, removeMarketplaceOrder, ...marketplaceContext } =
    useContext(MarketplaceContext);

  /** Context states */
  const [cart, setCart] = useState<Lib.CartItem[]>([]);
  const prevCart = usePrevious(cart);
  const [customer, setCustomer] = useState<CustomerInfo>();
  /** This state is used in confirmation step to display purchased items */
  const [purchasedItems, setPurchasedItems] = useState<Lib.CartItem[]>([]);
  const cartBookings = cart.filter((item) => cartItemIsCartBooking(item)) as Lib.CartBooking[];
  const [bookingRequestData, setRequestData] = useState<LocalStorageCart>({
    order: { products: [], giftcards: [] },
    cart: [],
    expires: 0,
  });

  /**
   * Sets the initial state of the cart and order from localstorage or from the initialItem prop
   */
  useEffect(() => {
    const now = new Date().getTime();
    const data: LocalStorageCart = JSON.parse(localStorage.getItem(localStorageKeygen) || '{}');

    //This Handles booking request localstorage state.
    const requestData: LocalStorageCart = JSON.parse(localStorage.getItem(localStorageRequestKeygen) || '{}');

    if (Object.keys(requestData).length) {
      window.localStorage.setItem(localStorageRequestKeygen, JSON.stringify(requestData));
    } else {
      window.localStorage.setItem(localStorageRequestKeygen, JSON.stringify(bookingRequestData));
    }

    if (!requestData.expires || (requestData.expires && requestData.expires < now)) {
      localStorage.removeItem(localStorageRequestKeygen);
    }

    if (!data.expires || (data.expires && data.expires < now)) {
      localStorage.removeItem(localStorageKeygen);
      if (initialItem) {
        placeItemInCart(initialItem);
      } else {
        setCart([]);
      }
      return;
    }
    if (data.order && data.order.id) {
      setMarketplaceOrder(data.order);
    }
    setCart(data.cart);
  }, []);

  //Switches the cart based on if the widget is in booking request mode or not.
  useEffect(() => {
    if (widget.selectedPurchaseType == 'giftcards') return;
    let data: LocalStorageCart = {} as LocalStorageCart;
    if (widget.bookingRequest) {
      data = JSON.parse(localStorage.getItem(localStorageRequestKeygen) || '{}');
    } else {
      data = JSON.parse(localStorage.getItem(localStorageKeygen) || '{}');
    }

    if (!Object.keys(data).length) {
      setCart([]);
      return;
    }
    setCart(data.cart);
  }, [widget.bookingRequest]);

  /**
   * Updates the cart with data from local storage, can go to request or normal cart
   */
  useEffect(() => {
    const saveItem = {
      order: order,
      cart: cart,
      expires: new Date().getTime() + TIME_TO_LIVE,
    };
    if (widget.bookingRequest) {
      window.localStorage.setItem(localStorageRequestKeygen, JSON.stringify(saveItem));
    } else {
      window.localStorage.setItem(localStorageKeygen, JSON.stringify(saveItem));
    }
  }, [cart, order]);

  // Note: Really suboptimal way to manage an empty cart in customer info step.
  // This should be managed by widgetDataProvider as it is the controller of the widget process.
  // Would like input on how we can implement this behaviour there instead.
  function navigateBackToSelection() {
    if (widget.selectedPurchaseType === 'bookings') {
      widget.gotoStep('activitySelection');
    } else {
      widget.gotoStep('giftcardSelection');
    }
  }

  /**
   * Used for placing a booking item in the cart
   */
  function placeBookingInCart(priceCategories: Required<ItemOptions>['priceCategories'], user: ItemOptions['user']) {
    const { selectedActivity } = widget;
    if (!selectedActivity)
      throw new Error('[Marketplace Cart Context] Tried to place booking item in cart without an activity selected');

    // We are ready to create a product and place it in the context
    const bookingResource = new BookingResource();

    // Create the document
    const bookingDocument = bookingResource.createDocument({
      occurance: selectedActivity.id,
      organization: selectedActivity.organization,
      priceCategories,
      number: ShortId.generate(),
      user: !ignoreSignedInUser && user ? user._id : undefined,
      requested: widget.bookingRequest ? true : undefined,
    });

    const updatedItem = {
      ...bookingDocument,
      occurance: selectedActivity,
    } as unknown as Lib.CartBooking;

    setCart((prevState) => [...prevState, updatedItem]);
  }

  /**
   * This places a product in the context and saves it in front end.
   */
  function placeItemInCart({ priceCategories, giftcardType, user }: ItemOptions) {
    try {
      if (!widget) throw new Error('[Marketplace Cart Context] Widget not yet initialized');

      if (widget.selectedPurchaseType === 'bookings') {
        if (!priceCategories)
          throw new Error('[Marketplace Cart Context] Tried to place booking item in cart without price categories');
        placeBookingInCart(priceCategories, user);
      }

      if (widget.selectedPurchaseType === 'giftcards') {
        if (!giftcardType)
          throw new Error('[Marketplace Cart Context] Tried to place giftcard item in cart without giftcard type');
        placeGiftcardItemInCart(giftcardType, user);
      }
    } catch (err) {
      console.log('Ran into an error', err);
    }
  }

  /**
   * Places a giftcard item in the cart
   * @param giftcardType A giftcard type document
   * @param user current logged in user [optional]
   */
  function placeGiftcardItemInCart(
    { id, _id, ...giftcard }: Required<ItemOptions>['giftcardType'],
    user: ItemOptions['user'],
  ) {
    // Create giftcard document
    const expirationDate = Moment().add(Moment.duration(giftcard.validityDurationInDays, 'd')).toDate();
    const giftcardDocument = new GiftCardResource().createDocument({
      number: ShortId.generate(),
      title: giftcard.title,
      value: giftcard.value,
      usesLeft: giftcard.nrOfUses ?? 1,
      limitations: giftcard.limitations,
      expirationDate,
      imageId: giftcard.imageId,
      thumbnailId: giftcard.thumbnailId,
      manuallyPaid: false,
      hideValue: false,
      giftCardType: _id,
      createdBy: !ignoreSignedInUser && user ? user._id : undefined,
    });

    // Set card in cart
    setCart((prevState) => [...prevState, giftcardDocument]);
  }

  /**
   * Removes an item from the cart
   * @param item The product to be removed
   */
  function removeItemFromCart(item: Lib.CartItem) {
    setCart((cart) => cart.filter((it) => it.number !== item.number));
  }

  function clearCartAndOrder() {
    setCart([]);
    clearMarketplaceOrder();
    localStorage.removeItem(localStorageKeygen);
    localStorage.removeItem(localStorageRequestKeygen);
  }

  // Navigate from customerInfo if cart is empty
  useEffect(() => {
    if (widget.selectedStepId !== 'customerInfo') return;
    if (!cart.length && prevCart?.length) {
      navigateBackToSelection();
    }
  }, [cart, prevCart, widget.selectedStepId]);

  /**
   * Toggles the hideValue field for a passed in giftcard
   * @param giftcard The giftcard to be altered
   */
  function toggleHideGiftcardValue(giftcard: Lib.CartGiftcard) {
    const item = cart.find((item: Lib.CartItem) => item.number === giftcard.number);

    if (!item || !cartItemIsCartGiftcard(item))
      throw new Error('[Marketplace Cart Context] Could not find giftcard to alter');

    // Prepare the new giftcard
    const alteredGiftcard: Lib.CartGiftcard = { ...item, hideValue: !item.hideValue };

    // Update the cart with the new item
    const cartCopy = [...cart];
    cartCopy.splice(cart.indexOf(item), 1, alteredGiftcard);

    if (cart == cartCopy) throw new Error('[Marketplace Cart Context] Could not update cart when altering giftcard');

    // Set the updated cart in state
    setCart(cartCopy);
  }

  /**
   * Attaches the customer state to all products in the cart
   * @param cart The cart in marketplace cart context
   * @returns The items with customer information and message attached
   */
  function attachCustomerToCartItems(cart: Lib.CartItem[]): Lib.CartItem[] {
    if (!customer) throw new Error("[Marketplace Cart Context] Couldn't attach non-existing customer to cart items");
    const { message, ...customerDoc } = customer;
    return cart.map((item) => {
      if (cartItemIsCartBooking(item)) {
        return {
          ...item,
          customer: customerDoc,
          message,
        };
      } else {
        return {
          ...item,
          customer: customerDoc,
        };
      }
    });
  }

  /**
   * Places order without payment, used if "skip payment"
   * @param cart The items to be purchased
   */
  async function placeOrderWithoutPayment(cart: Lib.CartItem[]) {
    const cartItemsWithCustomer = attachCustomerToCartItems(cart);
    try {
      await marketplaceContext.placeOrderWithoutPayment(cartItemsWithCustomer);
      setPurchasedItems(cartItemsWithCustomer);
      clearCartAndOrder();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Places order with manual payment, used if "manual payment"
   * @param cart The items to be purchased
   */
  async function placeOrderWithManualPayment(cart: Lib.CartItem[]) {
    const cartItemsWithCustomer = attachCustomerToCartItems(cart);
    try {
      await marketplaceContext.placeOrderWithManualPayment(cartItemsWithCustomer);
      setPurchasedItems(cartItemsWithCustomer);
      clearCartAndOrder();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Places order with payment.
   * Hooks into marketplace place order with payment function
   * @param cart The cart in marketplace cart context
   * @param cardDetails card details created for payment
   * @param cardActionHandler 3dsecure tool from stripe
   */
  async function placeOrderWithCardPayment(
    cart: Lib.CartItem[],
    cardDetails: Lib.CardDetails | undefined,
    cardActionHandler: Lib.CardActionHandler,
  ) {
    const cartItemsWithCustomer = attachCustomerToCartItems(cart);
    await marketplaceContext.placeOrderWithCardPayment(cartItemsWithCustomer, cardDetails, cardActionHandler);
    setPurchasedItems(cart);
    clearCartAndOrder();
  }

  /**
   * Creates a marketplace order from cart items
   * Hooks into marketplace order creation function
   */
  async function createMarketplaceOrder() {
    const updatedCartItems = await marketplaceContext.createMarketplaceOrder(cart);
    setCart(updatedCartItems);
  }

  /**
   * Simple check that returns true if there are products that are bookings in the cart
   * @returns true if there are bookings, false otherwise
   */
  const areThereCartBookingsInCart = !!cart.find((item) => cartItemIsCartBooking(item));

  /**
   * This typeguards the item passed in as either an instance of a booking or a giftcard
   * @param item A cart item in the marketplace context cart
   * @returns boolean for if the item passed in is a booking
   */
  function cartItemIsCartBooking(item: Lib.CartItem): item is Lib.CartBooking {
    return marketplaceContext.cartItemIsCartBooking(item);
  }

  /**
   * This typeguards the item passed in as either an instance of a booking or a giftcard
   * @param item A cart item in the marketplace context cart
   * @returns boolean for if the item passed in is a giftcard
   */
  function cartItemIsCartGiftcard(item: Lib.CartItem): item is Lib.CartGiftcard {
    return marketplaceContext.cartItemIsCartGiftcard(item);
  }

  /**
   * Is used to append a customer document for every item in the cart.
   * @param info CustomerInfo
   */
  function setCustomerInfoToState(customer: CustomerInfo) {
    setCustomer(customer);
  }

  function numberOfVisitorsforBooking(booking: Lib.CartBooking) {
    return booking.priceCategories.reduce((sum, pc) => sum + visitors(pc), 0);
  }

  /**
   * In order to find out how many visitors there are in the cart for an unique occurance
   * @param id An id for occurance to find number of visitors from
   */
  function numberOfVisitorsForOccurance(occuranceId?: string) {
    if (!cartBookings.length || !occuranceId) return 0;
    const matchingBookings = cartBookings.filter((item) => item.occurance.id === occuranceId);
    return matchingBookings.reduce((sum, item) => sum + numberOfVisitorsforBooking(item), 0);
  }

  const state = {
    cart,
    purchasedItems,
    areThereCartBookingsInCart,
    numberOfVisitorsForOccurance,
    placeItemInCart,
    removeItemFromCart,
    cartItemIsCartBooking,
    cartItemIsCartGiftcard,
    toggleHideGiftcardValue,
    placeOrderWithCardPayment,
    placeOrderWithManualPayment,
    placeOrderWithoutPayment,
    customer,
    setCustomerInfoToState,
    order: order,
    createMarketplaceOrder,
    removeMarketplaceOrder: removeMarketplaceOrder,
  };

  return <MarketplaceCartContext.Provider value={state}>{children}</MarketplaceCartContext.Provider>;
};

/**
 * Declares expiry date (from last made update) for cart in local storage
 * Expires in 10 hours
 */
const TIME_TO_LIVE = 1000 * 60 * 60 * 10;

type LocalStorageCart = {
  order: MarketplaceOrder;
  cart: Lib.CartItem[];
  expires: number;
};

/**
 * Options for placing an item in the cart
 */
type ItemOptions = {
  /** Pricing data needed for booking items */
  priceCategories?: BookingPriceCategoryDocument[];
  /** General data needed for giftcard items */
  giftcardType?: GiftCardTypeDocument;
  /** The current session user */
  user?: UserDocument;
};

/**
 * Extended customer document
 */
export type CustomerInfo = CustomerDocument & {
  message: string;
};
