import * as React from 'react';
import { OffersContext } from '../../../../contexts/offers.context';
import { ActivityTypeResource, Consume, Field, Form, LocaleContext, TextArea } from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';

interface State {
  updating: boolean;
  updateTimer?: any;
}

interface Props {}

export class MessageToEmployeesForm extends React.Component<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(OffersContext)
  private _offers: OffersContext;

  private get selectedOffer() {
    return this._offers.selectedOffer;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      updating: false,
    };
  }

  private updateMessage = (textValue) => {
    if (!this.selectedOffer) return;
    this.selectedOffer.messageToEmployees = textValue;

    if (this.state.updateTimer) {
      clearTimeout(this.state.updateTimer);
    }
    this.setState({
      updateTimer: setTimeout(async () => {
        this.setState({
          updating: true,
        });
        if (this.selectedOffer) {
          await new ActivityTypeResource().updateMessageToEmployees(
            this.selectedOffer.id,
            this.selectedOffer.messageToEmployees || '',
          );
        }
        this.setState({
          updating: false,
        });
      }, 1000),
    });
  };

  componentWillUnmount() {
    clearTimeout(this.state.updateTimer);
  }

  render() {
    const { t } = this._locale;
    if (!this.selectedOffer) {
      return <span />;
    }
    return (
      <Form>
        <Field label={t('Edit message to staff')}>
          <div className={'ui basic ' + (this.state.updating ? 'loading' : '') + ' segment'} style={{ padding: 0 }}>
            <TextArea
              name="Ditt meddelande till personalen:"
              className="raised"
              value={this.selectedOffer.messageToEmployees}
              onChange={this.updateMessage}
            />
          </div>
        </Field>
      </Form>
    );
  }
}
