import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GiftCardDocument, GiftCardResource } from '../../_dependencies';
import { useLocale } from '../../_locales';

interface Props {
  creditCode?: GiftCardDocument;
  saveCreditCodeDetails: (creditCode: GiftCardDocument) => void;
}
export const RescheduleConfirmation = ({ creditCode, saveCreditCodeDetails }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const params = new URLSearchParams(location.search);
  const creditCodeId = params.get('voucher');
  const { t } = useLocale();

  const getCreditCode = async () => {
    if (creditCodeId) {
      const newCreditCode = await new GiftCardResource().getGiftcardByNumber(creditCodeId);

      if (newCreditCode) {
        saveCreditCodeDetails(newCreditCode);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (creditCode == undefined) {
      getCreditCode();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <div className="ui basic segment loading" style={containerStyle} />;
  } else {
    return (
      <div style={containerStyle}>
        {creditCode ? (
          <div className="ui segment" style={{ flex: 1, margin: '.5rem' }}>
            <h2 style={{ margin: '.5rem 0', width: '100%', textAlign: 'center' }}>
              {t('Your booking has been cancelled and you can now reschedule')}
            </h2>
            {t('Your credit code has also been sent to...').replace('###', creditCode.customer.email)}
            <h4>
              {t('Your credit code is:')} {creditCode.number}
            </h4>
            {t('The code is a voucher for...').replace('###', creditCode.value!.toString())}{' '}
            {creditCode.expirationDate.toString().slice(0, 10)}
            <Link className="item" to={`/properties/details/${creditCode.limitations.properties[0]}`}>
              <button className="ui fluid large primary button" style={{ marginTop: '1rem' }}>
                {t('Book a new activity now')}
              </button>
            </Link>
          </div>
        ) : (
          <h4>{t('The credit code could not be found.')}</h4>
        )}
      </div>
    );
  }
};

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '16rem',
  minWidth: '16rem',
  maxWidth: '52rem',
  marginBottom: '2rem',
};
