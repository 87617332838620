import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';

import { CustomerDocument, CustomerSchema } from './../schemas/customer';
import { LimitationsDocument, LimitationsSchema } from './../schemas/limitations';
import { PriceCategoryDocument, PriceCategorySchema } from './../schemas/priceCategory';
import { GiftCardImageResource } from './giftCardImage.resource';

import SchemaNames from '../schemas/names';
import { TranslatableText } from '../schemas/translatableText';

export interface GiftCardDocument extends MongooseDocument {
  number: string;
  title: string | TranslatableText;
  value?: number;
  hideValue?: boolean;
  priceCategoies?: PriceCategoryDocument[];
  usesLeft: number;
  expirationDate: Date;
  customer: CustomerDocument;
  stripeCharges: string[];
  giftCardCharges: Types.ObjectId[];
  manuallyPaid?: boolean;
  limitations: LimitationsDocument;
  giftCardType?: Types.ObjectId;
  imageId?: string;
  thumbnailId?: string;
  marketplaceRef?: string; // A marketplace reference to the product id
  /** A reference to the user signed in when the gift card was created */
  createdBy?: Types.ObjectId;
  /** Indicates that this is a voucher, which is created when a reschedule insurance is used and should not be presented as a normal giftcard */
  voucher?: {
    originatingBookingId: string;
  };

  /** VIRUAL FIELD */ imageUrl?: string;
}

export class GiftCardResource extends MongooseResource<GiftCardDocument> {
  /**
   * Typeguard for veryfining is the passed object is an Document or an ObjectId
   * needs to look for a field that exists only in the document
   */
  static isDocument(data: any): data is GiftCardDocument {
    return data?.number != undefined && data?.limitations != undefined; // Limitations is needed to differente bookings from giftcards
  }

  constructor() {
    super();

    this.setName(SchemaNames.GiftCard);

    this.setSchema({
      number: { type: String, required: true, unique: true },
      title: { type: Schema.Types.Mixed, required: true },
      value: { type: Number, required: false },
      hideValue: { type: Boolean, required: false },
      priceCategoies: [PriceCategorySchema],
      usesLeft: { type: Number, required: true },
      expirationDate: { type: Date, required: true },
      customer: { type: CustomerSchema, required: true },
      stripeCharges: [String],
      giftCardCharges: [{ type: Schema.Types.ObjectId, ref: SchemaNames.GiftCardCharge, required: true }],
      manuallyPaid: { type: Boolean, required: false },
      limitations: { type: LimitationsSchema, required: true },
      giftCardType: { type: Schema.Types.ObjectId, ref: SchemaNames.GiftCardType, required: false },
      imageId: { type: String, required: false },
      thumbnailId: { type: String, required: false },
      marketplaceRef: { type: String, required: false },
      createdBy: { type: Schema.Types.ObjectId, ref: SchemaNames.Default, required: false },
      voucher: { type: Object, required: false },
    });

    this.addVirtualField('imageUrl', (document) =>
      document.imageId
        ? new GiftCardImageResource().fileUrl(document.imageId, 'jpg')
        : '/static/platform/img/default_giftcard.png',
    );
    this.addVirtualField('thumbnailUrl', (document) =>
      document.thumbnailId
        ? new GiftCardImageResource().fileUrl(document.thumbnailId, 'jpg')
        : '/static/platform/img/default_giftcard.png',
    );
  }

  async getGiftcardByNumber(number: string) {
    return this.sendRequest<GiftCardDocument>('/getByNumber', 'post', { number });
  }

  async findWithPopulatedCreatedBy(query: object) {
    return this.sendRequest<GiftCardDocument[]>('/findWithPopulatedCreatedBy', 'post', { query });
  }
}
