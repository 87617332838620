import React from 'react';
import { Checkbox, PriceCategoryDocument } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface Props {
  updateSelectedOffer: (key: 'priceCategories', value: PriceCategoryDocument[]) => void;
  addOrRemoveCheckInLuggage: () => void;
  checked: boolean;
}

export const AddClientBelongingsField = ({ checked, addOrRemoveCheckInLuggage }: Props) => {
  const { t } = useLocale();

  return (
    <div className="ui segment" style={{ marginTop: '2rem' }}>
      <div className="ui tiny header">
        <Checkbox
          toggle
          {...{ checked }}
          onCheckedOrUncheked={addOrRemoveCheckInLuggage}
          label={t('Add check in luggage')}
          style={{ marginBottom: '.5rem' }}
        />
        <p className="sub header">{t('Lets your visitors indicate how many bags...')}</p>
      </div>
    </div>
  );
};
