import Lodash from 'lodash';
import { Moment } from 'moment';
import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import { ChargeDocument } from '../schemas/charge';
import { CustomerDocument, CustomerSchema } from '../schemas/customer';
import SchemaNames from '../schemas/names';
import { AccessoryDocument, BookingPriceCategoryDocument, PriceCategorySchema } from '../schemas/priceCategory';
import { ActivityOccuranceDocument } from './activityOccurance.resource';
import { PropertyDocument } from './property.resource';

export interface BookingDocument extends MongooseDocument {
  organization: Types.ObjectId;
  user?: Types.ObjectId;
  number: string;
  occurance: Types.ObjectId;
  customer: CustomerDocument;
  priceCategories: BookingPriceCategoryDocument[];
  /** Private notes that only personnel can see in the dahsboard */
  notes: string;
  /** A message that the customer left when they created a booking */
  message: string;
  /** A message for the personnel to write when they created a booking */
  messageToCustomer: string;
  stripeCharges: string[];
  stripeRefunds: string[]; // TODO: Remove refunds and only use the charge since the refund is connected to the charge..
  giftCardCharges: Types.ObjectId[];
  manualCharges: ChargeDocument[]; // TODO: Move all charges to this field and rename the field to "charges"
  crossSellingProperty?: PropertyDocument;
  optionalReferenceNumber?: string;
  requested?: boolean;
  isInvoiced?: boolean;
  /** A reference to the marketplace product id */ marketplaceRef?: string;
  /** VIRTUAL FIELD!!!! */ totalPrice: number;
  /** VIRTUAL FIELD Number of visitors booked */ bookedVisitors: number;
  /** EXTRA FIELD!!!! */ paymentStatusString?: string;
}

/** Returns the total price from a collection of price categories */
export function calculateTotalPrice<T extends BookingPriceCategoryDocument>(
  priceCategories?: (AccessoryDocument<T> | T)[],
  isPricePerTicket?: boolean,
) {
  let totalPrice = 0;
  (priceCategories || []).forEach((pc) => {
    if ('rescheduleInsurance' in pc && pc.rescheduleInsurance?.isFeePerPerson) {
      totalPrice += pc.price * (pc.rescheduleInsurance.personsPaidFor || 0);
    } else if (isPricePerTicket && !pc.basePrice) {
      totalPrice += pc.price * pc.tickets;
    } else {
      totalPrice += pc.price;
    }
  });
  return totalPrice;
}

export class BookingResource extends MongooseResource<BookingDocument> {
  public static ErrorCodes = {
    CouldNotFindBooking: '1000',
    MissingData: '1001',
    CouldNotCreateRefund: '1002',
    RefundedButNotSaved: '1003',
    MailWasNotSend: '1004',
  };

  /**
   * Typeguard for veryfining is the passed object is an Document or an ObjectId
   * needs to look for a field that exists only in the document
   */
  static isDocument(data: any): data is BookingDocument {
    return data?.number != undefined && !data?.limitations; // Limitations is needed to differentiate bookings from giftcards
  }

  /**
   * Returns true if the booking was created before the implementation
   * of the marketplace service.
   * @param document The document to test
   */
  static isLegacy(document: BookingDocument): boolean {
    return !document.marketplaceRef;
  }

  /**
   * Can be used to calculate the total price for a booking document in case the virtual "totalPrice" is out of reach.
   * The passed in document needs to have price categories in order to be able to calculate a proper value, otherwise returns 0
   */
  static getTotalPrice(document: Pick<BookingDocument, 'priceCategories'>): number {
    if (!document.priceCategories) return 0;
    return calculateTotalPrice(document.priceCategories);
  }

  constructor() {
    super();

    this.setName(SchemaNames.Booking);

    this.setSchema({
      number: String,
      occurance: { type: Schema.Types.ObjectId, ref: SchemaNames.ActivityOccurance, required: true },
      organization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: true },
      user: { type: Schema.Types.ObjectId, ref: SchemaNames.Default, required: false },
      customer: CustomerSchema,
      priceCategories: [PriceCategorySchema],
      message: String,
      notes: String,
      messageToCustomer: { type: String, required: false },
      stripeCharges: [String],
      stripeRefunds: [String], // TODO: Remove refunds and only use the charge since the refund is connected to the charge..
      giftCardCharges: [{ type: Schema.Types.ObjectId, ref: SchemaNames.GiftCardCharge }],
      manualCharges: [Schema.Types.Mixed], // TODO: Move all charges to this field and rename the field to "charges"
      crossSellingProperty: { type: Schema.Types.ObjectId, ref: SchemaNames.Property, required: false },
      optionalReferenceNumber: String,
      requested: Boolean,
      isInvoiced: Boolean,
      marketplaceRef: { type: String, required: false },
    });

    this.addVirtualField('bookedVisitors', (document) => {
      return Lodash.sumBy(document.priceCategories || [], (pc) => pc.tickets * pc.groupMultiplier);
    });

    this.addVirtualField('totalPrice', (document) => {
      return calculateTotalPrice(document.priceCategories);
    });

    const autoPopulateProperty = function (next) {
      this.populate('crossSellingProperty');
      next();
    };

    this.schema.pre('findOne', autoPopulateProperty).pre('find', autoPopulateProperty);
  }

  updateNotes(id: string, notes: string) {
    return this.sendRequest<boolean>('/updateNotes', 'post', { id, notes });
  }

  getNumberOfBookingForOrganization(organization: Types.ObjectId) {
    return this.sendRequest<{ nrOfBookings: number }>('/getNumberOfBookingForOrganization', 'post', { organization });
  }

  updateVisitors(
    id: string,
    priceCategories: BookingPriceCategoryDocument[],
    totalPrice: number,
    refundAmount: number,
    v2 = false,
  ) {
    return this.sendRequest<string>(`${v2 ? '/v2' : ''}/updateVisitors`, 'post', {
      id: id,
      priceCategories: priceCategories,
      totalPrice: totalPrice,
      refundAmount: refundAmount,
    });
  }

  deleteBooking(id: string, refundAmount: number, v2 = false) {
    return this.sendRequest<string>(`${v2 ? '/v2' : ''}/deleteBooking`, 'post', { id: id, refundAmount });
  }

  moveBooking(id: string, oldOccurance: ActivityOccuranceDocument, newOccurance: ActivityOccuranceDocument) {
    return this.sendRequest<boolean>('/moveBooking', 'post', {
      id: id,
      oldOccurance: oldOccurance,
      newOccurance: newOccurance,
    });
  }

  findUserBookings(user: string) {
    return this.sendRequest<Array<BookingDocument>>('/findUserBookings', 'post', { user });
  }

  async findOrganizationBookings(
    options: { fromDate?: Date; toDate?: Date; getDeletedBookings?: boolean; getRequestBookings?: boolean } = {},
  ) {
    return await this.sendRequest<[BookingDocument]>('/findOrganizationBookings', 'post', { ...options });
  }

  async findCrossSellingBookings(
    options: { fromDate?: Date; toDate?: Date; getDeletedBookings?: boolean; getRequestBookings?: boolean } = {},
  ) {
    return await this.sendRequest<[BookingDocument]>('/findOrganizationBookings', 'post', {
      ...{ crossSelling: true, ...options },
    });
  }

  getBookingsWithOccuranceAndActivityPopulated(bookings: Types.ObjectId[], collectWithDeletedBookings?: boolean) {
    return this.sendRequest<BookingDocument[]>('/getBookingsWithOccuranceAndActivityPopulated', 'post', {
      bookings,
      collectWithDeletedBookings,
    });
  }

  getFilteredCustomerList(queryString: string) {
    return this.sendRequest<CustomerDocument[]>('/getFilteredCustomerList', 'post', { queryString });
  }

  getDeletedCrossSellingBookingsToInvoice(options: { fromDate?: Date; toDate?: Date } = {}) {
    return this.sendRequest<BookingDocument[]>('/getDeletedCrossSellingBookingsToInvoice', 'post', { ...options });
  }

  getBookingsByOccuranceDates(date: Moment, travelStatus: boolean) {
    return this.sendRequest<BookingDocument[]>('/getBookingsByOccuranceDates', 'post', { date, travelStatus });
  }

  getCustomerBooking(id: string) {
    return this.sendRequest<BookingDocument>('/getCustomerBooking', 'post', { id });
  }

  skipPaymentStepUpdate(document: BookingDocument) {
    return this.sendRequest<BookingDocument>('/skipPaymentStepUpdate', 'post', { document });
  }
}
