import React from 'react';
import Moment, { unitOfTime } from 'moment-timezone';
import { NavigateAction, stringOrDate, ViewProps } from 'react-big-calendar';
import { AgendaEventItem } from './agendaEventItem';
import { ActivityOccuranceDocument, CalendarOccuranceDocument, TranslatableText } from '../../../_dependencies';
import { AgendaDaySection } from './agendaDaySection';

type Props = Omit<ViewProps<ActivityOccuranceDocument>, 'accessors'> & { accessors: Accessors };

/** A custom calendar agenda view that renders sticky sections for each calendar day */
export const AgendaView = ({ accessors, length, date, events = [], onSelectEvent }: Props) => {
  const end = Moment(date).add(length, 'day').toDate();
  const days = getRange(date, end, 'day');
  events = events.filter((event) => inRange(event, date, end, accessors));
  events.sort((a, b) => +accessors.start(a) - +accessors.start(b));

  return (
    <div>
      {days.map((day) => {
        const start = Moment(day).startOf('day').toDate();
        const end = Moment(day).endOf('day').toDate();
        const dayEvents = events.filter((event) => inRange(event, start, end, accessors));

        return (
          <AgendaDaySection day={day}>
            {dayEvents.map((event) => (
              <AgendaEventItem day={day} event={event} onClick={onSelectEvent} />
            ))}
          </AgendaDaySection>
        );
      })}
    </div>
  );
};

// These static methods has to be defined but aren't being used i think...
AgendaView.title = (): string => '';
AgendaView.navigate = (date: Date, _: NavigateAction): Date => date;

/** Returns a range of dates from a period and a unit */
function getRange(start: stringOrDate, end: stringOrDate, unit: unitOfTime.Base = 'day'): Date[] {
  let current = new Date(start);
  const days: Date[] = [];
  while (Moment(current).isSameOrBefore(end, unit)) {
    days.push(current);
    current = Moment(current).add(1, unit).toDate();
  }
  return days;
}

/** Checks if an event is within a range, useful for filtering out events */
function inRange(event: ActivityOccuranceDocument, start: stringOrDate, end: stringOrDate, accessors: Accessors) {
  const eStart = Moment(accessors.start(event)).startOf('day');
  const eEnd = accessors.end(event);
  const startsBeforeEnd = Moment(eStart).isSameOrBefore(end, 'day');
  const endsAfterStart = !Moment(eStart).isSame(eEnd, 'minutes')
    ? Moment(eEnd).isAfter(start, 'minutes')
    : Moment(eEnd).isSameOrAfter(start, 'minutes');
  return startsBeforeEnd && endsAfterStart;
}

export interface Accessors {
  allDay: (event: CalendarOccuranceDocument) => boolean;
  title: ((event: CalendarOccuranceDocument) => TranslatableText) | undefined;
  tooltip: (event: CalendarOccuranceDocument) => string;
  start: (event: CalendarOccuranceDocument) => Date;
  end: (event: CalendarOccuranceDocument) => Date;
}
