import * as React from 'react';
import * as ReactRouter from 'react-router';
import { KosmicComponent, PurchaseWidget } from '../../_dependencies';

export class GiftCardPurchaseView extends KosmicComponent<{
  match: ReactRouter.match<{ organization: string; giftcardtype?: string }>;
}> {
  render() {
    return (
      <div className="ui container" style={{ padding: '30px 0px 50px 0px' }}>
        <PurchaseWidget
          fixedType="giftcards"
          organizationId={this.props.match.params.organization}
          giftCardTypeId={this.props.match.params.giftcardtype}
        />
      </div>
    );
  }
}
