import { Schema, Types } from 'mongoose';
import { InventoryEntityDocument } from '.';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { BookingDocument } from './booking.resource';
import { PlaceDocument } from './place.resource';

export type InventoryStatusType = 'available' | 'unavailable' | 'in-use';
export type TemporaryInventoryStatusType = 'checked-in' | 'checked-out';

export interface InventoryStatusDocument extends MongooseDocument {
  inventoryEntity: Types.ObjectId | InventoryEntityDocument;
  status: InventoryStatusType | TemporaryInventoryStatusType;
  location?: PlaceDocument;
  booking?: BookingDocument;
}

export class InventoryStatusResource extends MongooseResource<InventoryStatusDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.InventoryStatus);

    this.setSchema({
      inventoryEntity: { type: Schema.Types.ObjectId, ref: SchemaNames.InventoryEntity, required: true },
      status: { type: String, required: true },
      location: { type: Schema.Types.ObjectId, ref: SchemaNames.Place, required: false },
      booking: { type: Schema.Types.ObjectId, ref: SchemaNames.Booking, required: false },
    });

    const autoPopulateBooking = function (next) {
      this.populate('booking');
      next();
    };

    this.schema.pre('findOne', autoPopulateBooking).pre('find', autoPopulateBooking);
  }

  findAndPopulateInventoryEntities(query: object) {
    return this.sendRequest<InventoryStatusDocument[]>('/findAndPopulateInventoryEntities', 'post', { query });
  }
}
