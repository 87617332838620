import Lodash from 'lodash';
import * as React from 'react';
import { BadgeProvider } from '../../contexts';
import {
  Consume,
  LocaleContext,
  MobxComponent,
  NavLink,
  OrganizationDocument,
  UserDocument,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { ErrorBoundary } from '../errorBoundary';
import { Maybe } from '../maybe';
import { AccountActivationPrompt } from './prompts/accountActivationPrompt';
import { DashboardSidebar } from './sidebar';

interface State {
  isSidebarOpen: boolean;
}
export class DashboardTemplate extends MobxComponent<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = { isSidebarOpen: false };
    this.openSidebar = this.openSidebar.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
  }

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  openSidebar() {
    this.setState({ isSidebarOpen: true });
  }

  closeSidebar() {
    this.setState({ isSidebarOpen: false });
  }
  sidebarElement: any;

  private get systemOwnerIsOnDiffrentAccount() {
    const user = this.globals.session.currentUser as UserDocument;
    const sysOwner = user.roles.find((role) => role.type == 'system.owner');
    const orgManager = user.roles.find((role) => role.type == 'organization.manager');

    if (!sysOwner || !orgManager || !sysOwner.originalOrganization) {
      return false;
    }

    return orgManager.organization != sysOwner.originalOrganization;
  }

  private goToOrganizations() {
    this.globals.session.navigateTo('/admin/organizations');
  }

  private get diffrentAccountBanner() {
    const { t } = this._locale;
    if (!this.systemOwnerIsOnDiffrentAccount) {
      return null;
    }
    const organization = this.globals.session.currentOrganization as OrganizationDocument;
    return (
      <div className="ui warning message">
        <div className="header">
          {t('components.dashboard.index.loggedInAsWarningHeader')}
          <strong>{organization.name}</strong>
          <button style={{ float: 'right' }} className="ui warning button" onClick={this.goToOrganizations.bind(this)}>
            {t('components.dashboard.index.changeOrganizations')}
          </button>
        </div>
        {t('components.dashboard.index.loggedInAsWarning')}
      </div>
    );
  }

  componentDidUpdate() {
    this.sidebarElement = this.domElement.children().first();
    this.sidebarElement.sidebar({ context: this.domElement });
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  get mobileHeader() {
    const { isMobileSize } = this.globals.device;
    if (!isMobileSize) return null;

    return (
      <div className="ui inverted segment" style={headerStyle}>
        <i className="ui big inverted bars icon" style={iconStyle} onClick={this.openSidebar}></i>
        <NavLink style={{ margin: '5px 0' }} exact to="/dashboard">
          <img className={'ui centered mini image'} src="/static/platform/img/advtHero_logo_mini_invert.png" />
        </NavLink>
        <div></div>
      </div>
    );
  }

  render() {
    const { isMobileSize } = this.globals.device;
    return (
      <div className="pushable" style={{ overflow: 'hidden' }}>
        <ErrorBoundary>
          <BadgeProvider>
            <DashboardSidebar isSidebarOpen={this.state.isSidebarOpen} closeSidebar={this.closeSidebar} />
            {this.mobileHeader}
            <div
              className="pusher"
              style={{
                minHeight: 'unset',
                left: !isMobileSize ? '10rem' : 0,
                marginTop: isMobileSize ? '3em' : undefined,
              }}
            >
              {this.diffrentAccountBanner}

              <Maybe
                if="organization.manager"
                and={
                  !Lodash.includes(this.globals.session.currentLocation.pathname, 'properties') &&
                  this.globals.session.currentLocation.pathname != '/dashboard'
                }
              >
                <AccountActivationPrompt />
              </Maybe>
              {this.props.children}
            </div>
          </BadgeProvider>
        </ErrorBoundary>
      </div>
    );
  }
}

const iconStyle: React.CSSProperties = {
  cursor: 'pointer',
  position: 'absolute',
  left: 3,
  zIndex: 2,
};
const headerStyle: React.CSSProperties = {
  margin: 0,
  padding: 0,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 0,
  zIndex: 1000,
  position: 'fixed',
  width: '100%',
};
