import * as Mongoose from 'mongoose';
import Lodash from 'lodash';

import { GridFsDocument } from '../documents';
import { Resource } from './_abstractResource';

/** A file storage resource  */
export abstract class GridFsResource<D extends GridFsDocument> extends Resource {
  /** Typeguard attribute */ protected gridFsResourceIdentifier = true;

  public fileUrl = (id: string, extension?: string) => {
    return this.path + this.name + '/file/' + id + (extension ? '.' + extension : '');
  };

  upload(formData: FormData, progressHandler?: (loaded: number, total: number) => void) {
    return this.sendUploadRequest<Mongoose.Types.ObjectId>('/write', formData, progressHandler);
    // TODO: should return a yield method for tracking upload progress?
  }

  /** Sends a request containing a file that should be uploaded */
  protected sendUploadRequest<V>(
    endpoint: string,
    formData: FormData,
    progressHandler?: (loaded: number, total: number) => void,
  ): Promise<V> {
    console.log('Uploading a file to ' + this.path + this.name + endpoint);
    return new Promise<V>((resolve, reject) => {
      $.ajax({
        url: this.path + this.name + endpoint,
        method: 'post',
        dataType: 'json',
        contentType: false,
        processData: false,
        data: formData,
        success: (data) => resolve(data),
        error: (error, errorMessage, statusText) => {
          // Handle empty responses
          if (
            error.status == 200 &&
            errorMessage == 'parsererror' &&
            (error.responseText === undefined || !error.responseText.length)
          ) {
            return resolve(undefined as any); // NOTE: while refactoring added 'as any'
          }
          // Reject errors
          if (error.responseJSON) {
            const rejectReason = Lodash.merge(new Error(errorMessage), error.responseJSON);
            return reject(rejectReason);
          }
          if (error.responseText) {
            return reject(new Error(error.responseText));
          }
          return reject(new Error(errorMessage));
        },
        // For handling the progress of the upload
        xhr: () => {
          const myXhr = $.ajaxSettings?.xhr!();
          if (myXhr.upload && progressHandler != undefined) {
            myXhr.upload.addEventListener(
              'progress',
              (e) => {
                if (e.lengthComputable) {
                  progressHandler(e.loaded, e.total);
                }
              },
              false,
            );
          }
          return myXhr;
        },
      }); // TODO: error handling? // TODO: common operations should be refactored into a new method
    });
  }
}
