import { MongooseDocument, MongooseResource } from '../_dependencies';
import { Schema, Types } from 'mongoose';
import SchemaNames from '../schemas/names';
import { PropertyDocument } from './property.resource';
import { OrganizationDocument } from './organization.resource';
import { ActivityTypeDocument } from './activityType.resource';

export interface DestinationDocument extends MongooseDocument {
  name: string;
  connectedProperties: PropertyDocument[];
  pendingInvited: string[];
}

export class DestinationResource extends MongooseResource<DestinationDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Destination);

    this.setSchema({
      name: { type: String, required: true, unique: true },
      connectedProperties: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Property }],
      pendingInvites: [{ type: String }],
    });

    const autoPopulateProperties = function (next) {
      this.populate('connectedProperties');
      next();
    };

    this.schema.pre('findOne', autoPopulateProperties).pre('find', autoPopulateProperties);
  }

  isPartOfDestination(organizationId: Types.ObjectId) {
    return this.sendRequest<boolean>('/isPartOfDestination', 'post', { organizationId });
  }

  getPropertiesInDestination(organizationId: Types.ObjectId) {
    return this.sendRequest<PropertyDocument[]>('/getPropertiesInDestination', 'post', { organizationId });
  }

  getOrganizationsInDestination(organizationId: Types.ObjectId) {
    return this.sendRequest<OrganizationDocument[]>('/getOrganizationsInDestination', 'post', { organizationId });
  }

  getActivityTypesInDestination(organizationId: Types.ObjectId) {
    return this.sendRequest<ActivityTypeDocument[]>('/getActivityTypesInDestination', 'post', { organizationId });
  }
}
