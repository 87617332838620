import Lodash from 'lodash';
import Moment from 'moment-timezone';
import * as React from 'react';
import { Consume, LocaleContext, MobxComponent } from '../../../../../_dependencies';
import { AHLocaleContext, globalCurrentLocale } from '../../../../../_locales';

interface Props {
  dataList: { isChecked: boolean; isDisabled: boolean }[];
  byMonth?: boolean | undefined;
  onChanged: (index: number, byMonth: boolean) => any;
}

export class CheckboxDayMonthFilter extends MobxComponent<Props, {}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  constructor(props) {
    super(props);
  }

  private get header() {
    const { t } = this._locale;
    if (this.props.byMonth) {
      return t('components.dashboard.properties.employeeSchedule.scheduleCreator.checkboxPicker.chooseMonths');
    } else {
      return t('components.dashboard.properties.employeeSchedule.scheduleCreator.checkboxPicker.chooseWeekDays');
    }
  }

  /** @method toWeekday
   *  Takes a number and converts it to a day of the week string.
   *  Example: 0 == Monday, 1 == Tuesday etc */
  private getDayName(weekdayIndex: number): string {
    let dayName = Lodash(Moment.weekdays(weekdayIndex + 1).toString()).upperFirst();
    if (globalCurrentLocale() == 'de') {
      dayName = dayName.substr(0, 2);
    } else {
      dayName = dayName.substr(0, 3);
    }
    return dayName;
  }

  /** @method toWeekday
   *  Takes a number and converts it to a day of the week string.
   *  Example: 0 == Monday, 1 == Tuesday etc */
  private getMonthName(monthIndex: number): string {
    let monthName = Lodash(Moment.months(monthIndex).toString()).upperFirst();
    monthName = monthName.substr(0, 3);
    return monthName;
  }

  private get contentList() {
    if (this.props.byMonth) {
      const monthList: string[] = [];
      for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
        monthList.push(this.getMonthName(monthIndex));
      }
      return monthList;
    } else {
      const weekDayList: string[] = [];
      for (let weekDayIndex = 0; weekDayIndex < 7; weekDayIndex++) {
        weekDayList.push(this.getDayName(weekDayIndex));
      }
      return weekDayList;
    }
  }

  private printCheckboxes() {
    return (
      <React.Fragment>
        {this.contentList.map((timeDescription, index) => {
          const isChecked: boolean = this.props.dataList[index].isChecked;
          const isDisabled: boolean = this.props.dataList[index].isDisabled;
          return (
            <div key={index} className="ui checkbox" style={{ marginRight: '1em', marginTop: '7px' }}>
              <input
                type="checkbox"
                checked={isDisabled ? false : isChecked}
                disabled={isDisabled}
                onChange={this.props.onChanged.bind(this, index, this.props.byMonth)}
              />
              <label>{timeDescription}</label>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <h5 style={{ marginBottom: '7px' }}>{this.header}</h5>
        {this.printCheckboxes()}
      </React.Fragment>
    );
  }
}
