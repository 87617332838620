import Lodash from 'lodash';
import { observable } from 'mobx';
import Moment from 'moment-timezone';
import { Types } from 'mongoose';
import * as React from 'react';
import { OffersContext } from '../../../../contexts/offers.context';
import {
  Checkbox,
  Consume,
  Dropdown,
  DropdownItem,
  Field,
  Form,
  Input,
  LocaleContext,
  MobxComponent,
  PeriodPicker,
  PropertyDocument,
  PropertyResource,
  Rules,
  SubmitButton,
  WeekDayTimePickerField,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';

type Repition = 'every week' | 'every second week';

type Props = {
  isTmeTicket?: boolean;
  onSubmit: (
    periodStart: Date,
    periodEnd: Date,
    dateOccurances: Date[],
    propertyIds: Types.ObjectId[],
    publishTimes: boolean,
    customDuration: number,
    customVisitorCapacity: number,
    customMinVisitor: number,
    customNeededStaffing: number,
    customBookingClosesBeforeHours: number,
    customRefundableUntilHours: number,
    callback: Function,
  ) => void;
};

export class ActivityOccurancesGeneratorForm extends MobxComponent<Props> {
  @observable private _propertyDocuments: PropertyDocument[];
  @observable private _formComponentKey = 1;
  @observable private _repition: Repition = 'every week';
  @observable private _publishTimes = true;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @Consume(OffersContext)
  private _offers: OffersContext;

  prepareOnSubmit = (values, resolve, reject) => {
    const weekDictionary: { [key: string]: string[] } = {};
    for (let i = 0; i < 7; i++) {
      const timesString = values['weekday' + i];
      weekDictionary[i] = timesString == undefined || timesString == '' ? [] : timesString.split(',');
    }

    const startMoment = Moment(values['1337start']).startOf('day');
    const endMoment = Moment(values['1337end']);
    const dateOccurances = this.createDateOccurances(startMoment, endMoment, weekDictionary);

    const propertyIds = Lodash.split(values['Anläggningar'], ',') as any[];
    const callback = () => {
      // Resolve and clear the form when successfull
      this._formComponentKey++; // NOTE: WeekDayTimePicker did not respond well to form.clearValues, this forces a new form component to be rendered instead
      resolve();
    };

    //TME ticket overrides some variables to save a booking with TME ticket config
    const capacity: number = this.props.isTmeTicket ? 10000 : values['Antal platser för besökande'];
    const canBeBookedUntil: number = this.props.isTmeTicket ? -19 : Number(values['Bokning stängs']);

    return this.props.onSubmit(
      startMoment.toDate(),
      endMoment.toDate(),
      dateOccurances,
      propertyIds,
      this._publishTimes,
      values['Tid i minuter'],
      capacity,
      values['Minsta antal besökande'],
      values['Personalantal'],
      canBeBookedUntil,
      Number(values['Återbetalbart tills']),
      callback,
    );
  };

  createDateOccurances = (startMoment, endMoment, timeOccurances): any[] => {
    const startWeek = startMoment.isoWeek();
    const nrOfDays = endMoment.diff(startMoment, 'days') + 1; // include last day
    const dateOccurances = [] as Date[];
    Lodash.times(nrOfDays, (dayOffsetFromStart) => {
      const day = Moment(startMoment).add(dayOffsetFromStart, 'days');

      //We need the next day to see if there is an hour more or less due to daylight savings
      const nextDay = Moment(day).add(1, 'day').startOf('day');
      const daylightSavingsHours = nextDay.diff(day, 'hours');

      if (this._repition == 'every second week') {
        const weekDiff = day.isoWeek() - startWeek;
        if (weekDiff % 2 == 1) {
          return true; // Skip this date
        }
      }

      // NOTE: Uses ISO weekday for locale independent day number, 1 is monday, so isWeekday()-1 matches the array
      Lodash.each(timeOccurances[day.isoWeekday() - 1], (time) => {
        let hours = time;

        if (!this.props.isTmeTicket) {
          if (daylightSavingsHours > 24) {
            //An hour was being automatically added/subtracted by momentJS due to daylight savings
            //If winter savings, we add an hour, else we subtract an hour for summer daylight savings.
            hours = Moment.duration(time).add(1, 'h');
          } else if (daylightSavingsHours < 24) {
            hours = Moment.duration(time).subtract(1, 'h');
          }
        }

        const startTime = Moment(day).add(hours, 'hours');
        dateOccurances.push(startTime.toDate());
      });
    });

    return dateOccurances;
  };

  componentDidMount() {
    new PropertyResource().getAdministratedProperties().then((result) => (this._propertyDocuments = result));
  }

  render() {
    const { t, tt } = this._locale;
    const { selectedOffer } = this._offers;

    if (!selectedOffer) {
      return <span />;
    }
    return (
      <Form onSubmit={this.prepareOnSubmit} key={String(this._formComponentKey)}>
        <Field label={t('Properties')}>
          <Dropdown
            fluid
            multiple
            name="Anläggningar"
            placeholder={t('activityOccurancesGeneratorForm.ChooseActivtyPark')}
            rules={[Rules.NotEmpty(t('activityOccurancesGeneratorForm.emptyMessage'))]}
          >
            {Lodash.map(this._propertyDocuments, (propDoc: PropertyDocument) => (
              <DropdownItem key={propDoc.id} value={propDoc.id}>
                {tt(propDoc.name)}
              </DropdownItem>
            ))}
          </Dropdown>
        </Field>
        <Field label={t('activityOccurancesGeneratorForm.repetition')}>
          <div className="ui fluid basic small buttons">
            <div
              className={'ui ' + (this._repition == 'every week' ? 'active ' : '') + 'button'}
              onClick={() => (this._repition = 'every week')}
            >
              {t('activityOccurancesGeneratorForm.everyWeek')}
            </div>
            <div
              className={'ui ' + (this._repition == 'every second week' ? 'active ' : '') + 'button'}
              onClick={() => (this._repition = 'every second week')}
            >
              {t('activityOccurancesGeneratorForm.everySecondWeek')}
            </div>
            {/* TODO: For "Varje månad" we need a MonthPicker instead of the WeekDayTimePickerField */}
          </div>
        </Field>
        <PeriodPicker
          startMode="month"
          type="date"
          icon="calendar"
          startName={t('activityOccurancesGeneratorForm.start')}
          endName={t('activityOccurancesGeneratorForm.end')}
          startId="1337start"
          endId="1337end"
          startPlaceHolder={t('activityOccurancesGeneratorForm.chooseStartDate')}
          endPlaceHolder={t('activityOccurancesGeneratorForm.chooseEndDate')}
        />
        <WeekDayTimePickerField
          onlyFullDay={this.props.isTmeTicket}
          label={t(
            this.props.isTmeTicket
              ? 'activityOccurancesGeneratorForm.chooseDays'
              : 'activityOccurancesGeneratorForm.chooseDatesAndTime',
          )}
          name="weekday"
          rules={[Rules.NotEmpty(t('activityOccurancesGeneratorForm.chooseAtleastOneTime'))]}
        />
        <div className="ui message" style={{ marginTop: '2rem' }}>
          <div className="header" style={{ marginBottom: '0.5rem' }}>
            <i className="ui calendar check icon" />
            {t('activityOccurancesGeneratorForm.publishHeader')}
            <Checkbox
              toggle
              style={{ float: 'right' }}
              checked={this._publishTimes}
              onCheckedOrUncheked={(checked) => (this._publishTimes = checked)}
            />
          </div>
          <p>{t('Published times are available for your customers...')}</p>
        </div>
        <SubmitButton className="fluid green" style={{ marginTop: '1em', marginBottom: '2em' }}>
          {t('activityOccurancesGeneratorForm.schedulePeriod')}
        </SubmitButton>

        <div className="ui divider" />
        <h3 className="ui centered header">
          <div className="content">
            <div className="sub header">{t('The new appointments will be scheduled with the following values:')}</div>
          </div>
        </h3>
        <Field label={t('Time in minutes')}>
          <Input
            name="Tid i minuter"
            defaultValue={String(selectedOffer.duration)}
            rules={[
              Rules.NotEmpty(t('activityOccurancesGeneratorForm.fillInTime')),
              Rules.IsAnInteger(t('activityOccurancesGeneratorForm.fillTimeLength')),
            ]}
            icon="hourglass full"
          />
        </Field>
        <Field label={t('Number of slots for visitors')}>
          <Input
            disabled={selectedOffer.useInventory || selectedOffer.isTmeTicket}
            name="Antal platser för besökande"
            icon="ticket"
            defaultValue={
              selectedOffer.useInventory
                ? t('Calculated based of connected inventory')
                : String(selectedOffer.visitorCapacity)
            }
            rules={[
              Rules.NotEmpty(t('Fill in the number of slots available for visitors')),
              Rules.IsAnInteger(t('You must enter a number')),
            ]}
          />
        </Field>
        <Field label={t('Minimum number of visitors')}>
          <Input
            name="Minsta antal besökande"
            placeholder={t('per booking (optional)')}
            icon="ticket"
            defaultValue={String(selectedOffer.minVisitors || '')}
            rules={[Rules.IsIntegerOneOrHigherElseEmpty()]}
          />
        </Field>
        <Field label={t('Personnel slots')}>
          <Input
            name="Personalantal"
            icon="users"
            defaultValue={String(selectedOffer.neededStaffing || '')}
            rules={[Rules.IsEmptyOrInteger(t('You must enter a number'))]}
            placeholder={t('activityOccurancesGeneratorForm.fillInResponsible')}
          />
        </Field>
        <Field label={t('Booking closes before scheduled appointment, hours')}>
          <Input
            name="Bokning stängs"
            icon="hourglass full"
            defaultValue={String(selectedOffer.bookingClosesBeforeEventInHours || '')}
            rules={[Rules.IsIntegerOneOrHigherElseEmpty()]}
            placeholder={t('(Optional)')}
          />
        </Field>
      </Form>
    );
  }
}
