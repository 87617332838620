import Lodash from 'lodash';
import Moment from 'moment-timezone';
import * as React from 'react';
import { Consume, DeviceProvider, IDevice, MobxComponent } from '../../../../../_dependencies';
import { AdvancedDatePicker, AdvancedDatePickerType } from '../../../../advancedDatePicker';

interface Props {
  icon?: string;
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  onlyFutureDates: boolean;
  date: Date;
  type: AdvancedDatePickerType;
  enabledTypes: AdvancedDatePickerType[];
  onChanged: (date: Date, type: AdvancedDatePickerType) => any;
}

export class ScheduleDateTimePicker extends MobxComponent<Props, {}> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  constructor(props) {
    super(props);
  }

  private isInitialized = false;

  private onDateChanged = (date: Date, type: AdvancedDatePickerType) => {
    if (this.isInitialized) {
      this.props.onChanged(date, type);
    } else {
      this.isInitialized = true;
    }
  };

  private periodText(date: Date, type: AdvancedDatePickerType) {
    switch (type) {
      case 'month':
        return Lodash.startCase(Moment(date).tz('Europe/Stockholm').format('MMMM YYYY'));
      case 'date':
        return Lodash.startCase(Moment(date).tz('Europe/Stockholm').format('D MMMM YYYY'));
      case 'time':
        return Moment(date).tz('Europe/Stockholm').format('HH:mm ').split(' ').map(Lodash.capitalize).join(' ');
    }
  }

  private calendar(
    onlyFutureDates: boolean,
    type: AdvancedDatePickerType,
    date: Date,
    enabledTypes: AdvancedDatePickerType[],
  ) {
    return (
      <AdvancedDatePicker
        style={{ backgroundColor: 'white', zIndex: 1 }}
        onlyFutureDates={onlyFutureDates}
        autoSelectedNextType
        enabledTypes={enabledTypes}
        type={type}
        date={date}
        onChanged={this.onDateChanged.bind(this)}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
      />
    );
  }

  private dateTimePicker(
    icon: string,
    onlyFutureDates: boolean,
    date: Date,
    type: AdvancedDatePickerType,
    enabledTypes: AdvancedDatePickerType[],
  ) {
    return (
      <React.Fragment>
        <div
          className={'ui button schedulePopup' + (this.props.disabled ? ' disabled' : '')}
          style={{
            display: 'flex',
            backgroundColor: 'white',
            borderWidth: '1px',
            borderColor: 'darkGrey',
            borderStyle: 'solid',
            borderRadius: '3px',
            margin: 0,
          }}
        >
          <i style={{ marginLeft: '10px', marginRight: '10px' }} className={icon + ' grey icon'}></i>
          <span style={{ flexGrow: 1 }}>{this.periodText(date, type)}</span>
          <i style={{ marginRight: '10px' }} className="dropdown icon"></i>
        </div>
        <div
          className="ui popup bottom center"
          style={{ maxWidth: this.device.size === 'mobile' ? '' : '500px', zIndex: 1 }}
        >
          <div className="ui one column">
            <div className="column">{this.calendar(onlyFutureDates, type, date, enabledTypes)}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    $('.schedulePopup').popup({
      on: 'click',
      popup: $('.popup.bottom.center'),
      position: 'bottom center',
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.dateTimePicker(
          this.props.icon || 'calendar',
          this.props.onlyFutureDates,
          this.props.date,
          this.props.type,
          this.props.enabledTypes,
        )}
      </React.Fragment>
    );
  }
}
