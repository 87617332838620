import Slider from 'react-slick';
import * as React from 'react';
import Lodash from 'lodash';

/** This is settings for the slick Carousel */
export interface CarouselSettings {
  arrows?: boolean;
  dots?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  fade?: boolean;
  speed?: number;
  infinite?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  draggable?: boolean;
  pauseOnHover?: boolean;
  pauseOnFocus?: boolean;
  centerMode?: boolean;
  centerPadding?: string;
}

interface Props extends CarouselSettings {
  imgList: string[];
  /** Logo to have in masthead */
  logoUrl?: string;
  /** Logo styling */
  logoStyle?: React.CSSProperties;
  /**  Set hight of carusel images*/
  imageHeight?: string;
  /** set custom filter on the carousel images */
  imageFilter?: string;
}

export class CarouselMasthead extends React.Component<Props, {}> {
  private renderLogo() {
    const { logoUrl, logoStyle } = this.props;
    if (!logoUrl) {
      return null;
    }

    return <img style={logoStyle} src={logoUrl} alt="" />;
  }

  private renderSlider() {
    if (MODULE_ENVIRONMENT == 'node') {
      if (this.props.imgList[0]) {
        return (
          <div>
            <img src={this.props.imgList[0]} alt="" style={{ width: '100%', objectFit: 'cover' }} />
          </div>
        );
      }
      return null;
    }

    return (
      <Slider {...this.props} style={{ height: 'auto', width: '100%' }} centerMode lazyload="progressive">
        {Lodash.map(this.props.imgList, (imageUrl, index) => {
          return (
            <div
              key={index}
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: 'url(' + imageUrl + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                filter: this.props.imageFilter,
              }}
            />
          );
        })}
      </Slider>
    );
  }

  render() {
    const carouselHeight = () => {
      if (this.props.imageHeight === ('' || undefined)) {
        return { padding: 0, border: 0 };
      }
      return { padding: 0, border: 0, height: this.props.imageHeight };
    };

    return (
      <div className="ui vertical masthead center aligned segment" style={carouselHeight()}>
        {this.renderLogo()}
        {this.renderSlider()}
      </div>
    );
  }
}
