import * as React from 'react';
import { Consume, LocaleContext, MobxComponent, UserResource } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { EmployeeForm } from './form';

export class AddNewEmployeeForm extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  navigateToList = () => this.globals.session.changePathTo('/dashboard/employees');

  render() {
    const { t } = this._locale;
    return (
      <div style={{ padding: '2em' }}>
        <h2 className="ui header">
          <i className="icons" style={{ paddingTop: '0.1em' }}>
            <i className="user icon" />
            <i className="corner plus icon" />
          </i>
          <div className="content">
            {t('components.dashboard.employees.new.header')} {this.globals.session.currentOrganization.name}
          </div>
        </h2>
        <br />
        <EmployeeForm employee={new UserResource().createDocument({})} onSaved={this.navigateToList} />
      </div>
    );
  }
}
