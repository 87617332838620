import Moment from 'moment-timezone';
import React from 'react';
import { AdvancedDatePickerType } from '../../../components/advancedDatePicker';
import MapStore from '../../../components/map/store';
import {
  ActivityOccuranceDocument,
  ActivityTypeResource,
  IStateSetter,
  PropertyActivityTypeTuple,
  PropertyDocument,
  Provider,
} from '../../../_dependencies';

export interface IActivitySearchData extends IStateSetter<Readonly<IActivitySearchData>> {
  /** The available date picker type when searching */
  datePickerType: AdvancedDatePickerType;
  /** The selected date searched for */
  currentDate: Date;
  /** Indicates that a search is currently being performed */
  isLoading: boolean;
  /** Specified activity type categories searched for */
  categories: string[];
  /** Specified city/area searched for */
  freeText: string;
  foundProperties: PropertyDocument[];
  /** List of found matching activity occurances */
  foundOccurences: ActivityOccuranceDocument[];
  /** Any selected activity from the search results */
  selectedActivity: ActivityOccuranceDocument | undefined;
  propertyActivityPairs: PropertyActivityTypeTuple[];
  search: () => void;
}

/** Provides data for searching for- and filtering activity occurances */
export class ActivitiesSearchProvider extends Provider<IActivitySearchData> {
  /** Declares the context to use */
  public static Context = React.createContext<IActivitySearchData>({} as any);

  /** Exposes the context to use */
  protected use(): React.Context<IActivitySearchData> {
    return ActivitiesSearchProvider.Context;
  }

  /** Declares the initial state */
  protected initialState(): IActivitySearchData {
    return {
      isLoading: false,
      datePickerType: this.initialCalendarType(),
      currentDate: new Date(),
      categories: [],
      freeText: '',
      setState: this.updateState.bind(this),
      foundOccurences: [],
      foundProperties: [],
      selectedActivity: undefined,
      propertyActivityPairs: [],
      search: this.search.bind(this),
    };
  }

  /** Returns the default calender picker type based on the current platform */
  private initialCalendarType(): AdvancedDatePickerType {
    return 'month';
  }

  async updateState(newState: Partial<IActivitySearchData>) {
    await this.setState(newState as IActivitySearchData);
  }

  private async search() {
    await this.setState({ isLoading: true });

    MapStore.deselectProperty();
    const propertyActivityPairs = await new ActivityTypeResource().findPropertiesWithAvailableOccurances(
      this.state.currentDate,
      this.state.categories,
      this.state.freeText,
      this.state.datePickerType,
    );

    await this.setState({ isLoading: false, propertyActivityPairs });
  }

  componentDidUpdate(_, prevState: IActivitySearchData) {
    if (
      !Moment(prevState.currentDate).isSame(this.state.currentDate, 'day') ||
      prevState.categories !== this.state.categories ||
      prevState.freeText !== this.state.freeText ||
      prevState.datePickerType !== this.state.datePickerType
    ) {
      this.search();
    }
  }
}
