import { Card, PaymentIntentResult } from '@stripe/stripe-js';

/** Identifies a debit/credit card that can be used to make payments */
export type CardDetails = {
  token: string;
  cardCountryCode: string;
  receiptEmail: string;
  description: string;
  paymentIntent?: string;
  metadata?: { [key: string]: string };
};

/** An action for resolving extra-security measurments by the credit card provider (i.e. 3d secure) on the client side  */
export type CardActionHandler = (intentSecret: string) => Promise<PaymentIntentResult>;

/** Checks if the given object is a credit card */
export const isCardDetails = (obj: any | Card): obj is CardDetails => {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    typeof obj.token === 'string' &&
    typeof obj.cardCountryCode === 'string' &&
    typeof obj.receiptEmail === 'string' &&
    typeof obj.description === 'string' &&
    (typeof obj.paymentIntent === 'undefined' || typeof obj.paymentIntent === 'string') &&
    (typeof obj.metadata === 'undefined' || (obj.metadata !== null && typeof obj.metadata === 'object'))
  );
};
