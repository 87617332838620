import { uniq } from 'lodash';
import * as React from 'react';
import Stripe from 'stripe';
import {
  Consume,
  KosmicComponent,
  LocaleContext,
  OrganizationResource,
  ProgressBar,
} from '../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../_locales';
import { CompanyForm } from './companyForm';
import { ExternalAccountsForm } from './externalAccountsForm';
import { PersonsForm } from './persons/personsForm';
import RequirementsInfo from './requirementsInfo';
import { TermsOfServiceForm } from './termsOfServiceForm';
import { VerificationForm } from './verificationForm';

interface Props {}
interface State {
  initialized: boolean;
  account?: Stripe.Account;
  requirementGroups: [number, string][];
  steps?: IterableIterator<[number, StepComponent]>;
  currentStep?: [number, StepComponent];
}

type StepComponentProps = { nextStep: () => void; account: Stripe.Account };
type StepComponent = React.ComponentClass<StepComponentProps> | React.StatelessComponent<StepComponentProps>;

// Components maps requirement groups to actual form component
// for data entry.
export const Components: { [key: string]: StepComponent } = {
  tos_acceptance: TermsOfServiceForm,
  company: CompanyForm,
  external_account: ExternalAccountsForm,
  relationship: PersonsForm,
};

/**
  StripeActivationSteps uses stripes account.requirements to
  automaticly display the steps needed for account activation.
 */
export class AccountActivationSegment extends KosmicComponent<Props, State> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  // Initialize default state
  public state: State = {
    initialized: false,
    requirementGroups: [],
  };

  /** Create all the steps from requirement groups.  */
  private *createSteps(): IterableIterator<[number, StepComponent]> {
    const { requirementGroups } = this.state;
    for (const group of requirementGroups) {
      yield [group[0], Components[group[1]]];
    }
  }

  private nextStep() {
    const { steps } = this.state;
    if (steps == null) {
      throw new Error(format('Tried to call next steps with no steps configured.'));
    }
    const { value: currentStep = null } = steps.next();
    this.setState({ currentStep, steps });
  }

  public componentDidMount() {
    this.fetchStripeData();
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (!this.state.initialized) {
      this.fetchStripeData();
    }

    if (this.state.currentStep !== prevState.currentStep) {
      window.scrollTo({ top: 0 });
    }

    if (this.state.currentStep == null && this.state.initialized) {
      this.setOrganizationAsActive();
    }
  }

  async setOrganizationAsActive() {
    const resource = new OrganizationResource();
    if (!this.globals.session.currentOrganization.isActivated) {
      this.globals.session.currentOrganization.isActivated = true;
      resource.updateDocument(this.globals.session.currentOrganization);
    }
  }

  async fetchStripeData(): Promise<void> {
    // Initialize component by getting the stripe account
    // of the currently logged in user.
    const resource = new OrganizationResource();
    const account = await resource.getStripeAccount(this.globals.session.currentOrganization);
    if (account) {
      const requirementGroups = getRequirementGroups(account);
      this.setState({ account, requirementGroups });

      // Create all the steps and load the first step.
      const steps = this.createSteps();
      const { value: currentStep = null } = steps.next();
      this.setState({ initialized: true, steps, currentStep });
    }
  }

  private get conditionMessage() {
    const { t } = this._locale;
    return (
      <div className="ui blue segment">
        <strong>{t('By activating your account, you understand and agree to the following conditions')};</strong>
        <div className="ui bulleted list">
          <div className="item">{t('You are entitled to a free trial period which...')}.</div>
          <div className="item">{t('When the test period has expired, contractual...')}.</div>
          <div className="item">{t('Sales of bookings and gift cards made via our...')}.</div>
        </div>
        <p>
          {t('To see more about handling personal data, please press')}
          <a
            type="mail"
            style={{ marginLeft: '4px' }}
            target="_blank"
            href="https://www.hiveandfive.se/policies/privacy?ref=www.adventurehero.se"
            rel="noreferrer"
          >
            {t('here')}.
          </a>
        </p>
      </div>
    );
  }

  public render(): React.ReactNode {
    const { t } = this._locale;
    if (!this.globals.session.currentOrganization.isVerified) {
      return <VerificationForm onDone={() => this.forceUpdate()} />;
    }

    const { initialized, account, currentStep, requirementGroups } = this.state;
    if (!initialized) {
      return (
        <ProgressBar defaultPercentage={99} className="active yellow" label={t('Fetching account information...')} />
      );
    }
    if (currentStep == null) {
      return <ProgressBar defaultPercentage={100} label={t('Your account is activated!')} />;
    }
    if (account == null) {
      throw new Error('Kunde inte hitta något stripe konto');
    }
    const stepIndex = currentStep[0];
    const Component = currentStep[1];
    const label = (() => {
      if (stepIndex == 0 && requirementGroups.length > 1) {
        return t('More information is needed in order...');
      }
      const stepsLeft = requirementGroups.length - stepIndex;
      return (
        <span>
          {stepsLeft} {t('steps left to activate your account')}
        </span>
      );
    })();

    return (
      <div>
        <ProgressBar
          total={requirementGroups.length + 1}
          defaultValue={stepIndex + 1}
          label={label}
          className={requirementGroups.length > 3 && stepIndex == 0 ? 'red' : 'yellow'}
        />
        <RequirementsInfo account={this.state.account} />
        <div className="ui red segment" style={{ minHeight: '8rem' }}>
          <Component nextStep={this.nextStep.bind(this)} account={account} />
          {this.conditionMessage}
        </div>
      </div>
    );
  }
}

export function getAllRequirements(account: Stripe.Account) {
  return uniq(
    (account.requirements?.currently_due || [])
      .concat(account.requirements?.past_due || [])
      .concat(account.future_requirements?.currently_due || [])
      .concat(account.future_requirements?.past_due || []),
  ).sort();
}

/**
 * Gets the requirement groups from the stripe account.
 * A requirement group is the property that belongs to the
 * top level object of the account.
 * Example: 'company.name' is the requirement, 'company' is the requirement group
 */
export function getRequirementGroups(account: Stripe.Account): [number, string][] {
  // Add requirements to a set to make sure that
  // all the values are unique.  We also split the
  // values so that we just get the group.
  // Example: "foo.bar" -> "foo"
  const requirements = new Set<string>();

  const allRequirements = getAllRequirements(account);
  allRequirements
    .map((r) => {
      const [group] = r.split('.');
      if (
        // Map person requirements to relationship component.
        /person_/.test(group) ||
        /directors/.test(group) ||
        /executives/.test(group) ||
        /representative/.test(group) ||
        /executives/.test(group) ||
        // Also map person related requirements to relationship component.
        /company.directors_provided/.test(r) ||
        /company.executives_provided/.test(r) ||
        /company.owners_provided/.test(r)
      ) {
        return 'relationship';
      }
      if (/business_profile/.test(group)) {
        return 'company';
      }
      return group;
    })
    .filter((x) => Components[x])
    .forEach((x) => requirements.add(x));

  return Array.from(requirements.values()).map((x, i) => [i, x] as [number, string]);
}

const format = (msg: string) => `[${AccountActivationSegment.name}] ${msg}`;
