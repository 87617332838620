import * as React from 'react';
import { Component, ConfirmationButton, Consume, LocaleContext } from '../_dependencies';
import { AHLocaleContext } from '../_locales';

interface Props {
  deleting: boolean;
  onConfirmed: Function;
  fluid?: boolean;
  disabled?: boolean;
  confirmationText?: string;
}

export class DeleteButton extends Component<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  public render() {
    const { deleting, disabled, fluid, children, confirmationText, onConfirmed } = this.props;
    const { t } = this._locale;
    return (
      <ConfirmationButton
        stopPropagation
        loading={deleting}
        disabled={deleting || disabled}
        timeout={2000}
        className={'basic ' + (fluid ? 'fluid ' : '')}
        label={
          <div>
            <i className="trash outline icon" />
            {children || t('remove')}
          </div>
        }
        activeButton={
          <button
            type="button"
            className={'ui inverted red ' + (fluid ? 'fluid ' : '') + (deleting ? 'loading disabled ' : '') + 'button'}
            onClick={(e) => {
              onConfirmed();
              e.stopPropagation();
            }}
          >
            {confirmationText || t('Are you sure?')}
          </button>
        }
      />
    );
  }
}
