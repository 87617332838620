import * as React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import {
  AccessabilityResource,
  Alert,
  Consume,
  DeviceProvider,
  IDevice,
  KosmicComponent,
  LocaleContext,
  UserDocument,
} from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';
import { LanguageToggleIcons } from '../languageToggleIcons';
import { LoginForm } from './loginForm';
import { SliderSection } from './sliderSection';

interface Props {
  successRedirect: string;
}

interface State {
  isLoading: boolean;
  showMailSentMessage: boolean;
  showResetPasswordBox: boolean;
  mode: 'login' | 'signup' | 'conditions';
}

export class AuthenticationPage extends KosmicComponent<Props, State> {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      showMailSentMessage: false,
      showResetPasswordBox: false,
      mode: 'login',
    };
  }

  handleLogin = (event) => {
    event.preventDefault();
    const formElement = $('LoginForm');

    formElement.form('validate form');
    if (formElement.form('is valid')) {
      this.setState({ isLoading: true });
      this.globals.session
        .authenticate(String($('#LoginEmail').val()), String($('#LoginPassword').val()))
        .catch((err) => console.warn(err))
        .then((user: UserDocument) => {
          if (!user) {
            // Failed login
            $('#LoginBox').transition('shake'); // Shake the login box
            this.setState({ isLoading: false });
          } else {
            this.globals.session.navigateTo('/dashboard');
            return null;
          }
        });
    } else {
      // Invalid form
      $('#LoginBox').transition('shake'); // Shake the login box
    }
  };

  componentDidMount() {
    if (this.state.showResetPasswordBox) {
      this.setState({ showMailSentMessage: false });
    }
  }

  private handleResetPassword = (values, resolve, reject) => {
    const { t } = this._locale;

    new AccessabilityResource()
      .initiatePasswordReset(values['Email'])
      .then(() => {
        this.setState({ showMailSentMessage: true });
        resolve();
      })
      .catch((err: Error) => {
        if (err.message == 'user-not-found') {
          Alert.show(t('Unfortunately, the specified email...'), t('User does not exist'), 'error');
        }
        reject(err);
      });
  };

  private get loginColumn() {
    const { t } = this._locale;
    return (
      <div className="column" style={{ width: '100%' }}>
        <LoginForm
          handleLogin={this.handleLogin}
          handleResetPassword={this.handleResetPassword}
          showMailSentMessage={this.state.showMailSentMessage}
          showResetPasswordBox={this.state.showResetPasswordBox}
          isLoading={this.state.isLoading}
          fullWidth={this.device.size != 'desktop'}
          titleColor="white"
        />

        <div className="ui fluid grid">
          <div className="ui eight wide column" style={{ padding: '2em' }}>
            {!this.state.showResetPasswordBox && (
              <Link
                to="/sign-up"
                className="noselect"
                style={{ cursor: 'pointer', color: '#29BB9C', textDecoration: 'none' }}
              >
                {t('sign-up')}
              </Link>
            )}
          </div>
          <div className="ui eight wide column" style={{ textAlign: 'right', padding: '2em' }}>
            <a
              className="noselect"
              style={{ cursor: 'pointer', color: '#29BB9C', textDecoration: 'none' }}
              onClick={() => this.setState({ showResetPasswordBox: !this.state.showResetPasswordBox })}
            >
              {this.state.showResetPasswordBox ? t('Log in') : t('Forgot your password?')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  private get commercialColumn() {
    if (this.device.size == 'mobile') {
      return undefined;
    }

    return (
      <div
        className="ui eight wide column"
        style={{ padding: 0, minHeight: '100vh', display: 'flex', alignItems: 'center' }}
      >
        <div
          className="polygon"
          style={{
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            position: 'absolute',
          }}
        >
          <svg
            preserveAspectRatio="none"
            viewBox="0 0 100 100"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <polygon points="100,100 100,0 10,0, 0,100" opacity="1" style={{ fill: 'rgb(252, 252, 252)' }} />
          </svg>
        </div>
        {this.slider}
      </div>
    );
  }

  private get slider() {
    const { t } = this._locale;

    const settings = {
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      speed: 1000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      pauseOnHover: false,
      pauseOnFocus: false,
    };

    return (
      <div
        style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <div style={flagContainer}>
          <LanguageToggleIcons />
        </div>
        <Slider {...settings} style={{ width: '100%' }}>
          <div key="1">
            <SliderSection
              title={t('Make your activities visible')}
              description={t('Both on adventurehero.se and on your own website.')}
              img="/static/platform/img/mac1.png"
            />
          </div>
          <div key="2">
            <SliderSection
              title={t('Receive bookings')}
              description={t('Let your customers book themselves. Get paid right away.')}
              img="/static/platform/img/mac3.png"
            />
          </div>
          <div key="3">
            <SliderSection
              title={t('Schedule your staff')}
              description={t('Plan what activities your staff will work on.')}
              img="/static/platform/img/mac2.png"
            />
          </div>
        </Slider>
      </div>
    );
  }

  private get authenticationColumn() {
    const containerClassName = this.device.size == 'mobile' ? 'ui sixteen wide column' : 'ui eight wide column';

    return (
      <div
        className={containerClassName}
        style={{
          minHeight: '100%',
          padding: this.device.size == 'mobile' ? '0px' : '6em 2em 10em 2em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            top: 0,
            left: 0,
            width: this.device.size == 'mobile' ? '100vw' : '60vw',
            height: '100%',
            minHeight: this.device.size != 'mobile' ? '100vh' : '',
            position: 'absolute',
            zIndex: -1,
            backgroundSize: 'cover',
            backgroundImage: 'url(/static/platform/img/hero-bg-cut.jpg)',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div
          style={
            this.device.size != 'mobile'
              ? { ...authenticationContainer, ...{ width: this.device.size == 'tablet' ? '100%' : '' } }
              : authenticationContainerMobile
          }
        >
          {this.device.size == 'mobile' ? (
            <>
              <div style={flagContainerMobile}>
                <LanguageToggleIcons />
              </div>
            </>
          ) : undefined}
          {this.loginColumn}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className="ui grid"
        style={{
          minHeight: this.device.size != 'mobile' ? '100vh' : '',
          margin: 0,
          display: 'flex',
          backgroundSize: 'cover',
          backgroundImage: this.device.size == 'mobile' ? 'url(/static/platform/img/hero-bg-cut.jpg)' : '',
          boxShadow: this.device.size == 'mobile' ? 'inset 0 0 0 100vmax rgba(0, 0, 0, .7)' : '',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {this.authenticationColumn}
        {this.commercialColumn}
      </div>
    );
  }
}

const authenticationContainer: React.CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  padding: '1em',
  borderRadius: '5px',
  width: 'calc(100% - 4em)',
  maxWidth: '400px',
  transform: 'translateY(10%)',
};

const authenticationContainerMobile: React.CSSProperties = {
  color: 'white',
  padding: '1em',
  width: '100%',
  maxWidth: '400px',
  minHeight: '100vh',
  margin: '0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const flagContainer: React.CSSProperties = {
  position: 'absolute',
  right: '20px',
  top: '20px',
  zIndex: 2,
};

const flagContainerMobile: React.CSSProperties = {
  position: 'absolute',
  right: '20px',
  top: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '3em',
};
