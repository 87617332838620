import Lodash from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { ActivityDeleteResponses, ActivityOccuranceDocument, ActivityTypeDocument } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';

interface Props {
  attachedActivities?: ActivityDeleteResponses;
}

export const DeleteInventoryModalContent = ({ attachedActivities }: Props) => {
  const { t, tt } = useLocale();
  const [lastActivityOccurance, setLastOccurance] = useState<ActivityOccuranceDocument>();
  const [_, setLastActivityType] = useState<ActivityTypeDocument>();

  useEffect(() => {
    if (attachedActivities?.occurances) {
      setLastOccurance(attachedActivities?.occurances[attachedActivities?.occurances.length - 1]);
    } else if (attachedActivities?.activityTypes) {
      setLastActivityType(attachedActivities?.activityTypes[attachedActivities?.activityTypes.length - 1]);
    }
  }, [attachedActivities]);

  const occurancesWithoutBookings = () => {
    if (attachedActivities?.occurances) {
      return Lodash.filter(
        attachedActivities?.occurances,
        (occurance) => !occurance.bookings || !occurance.bookings.length,
      );
    }
  };

  const numberOfBookedOccurances = () => {
    if (attachedActivities?.occurances) {
      return Lodash.filter(attachedActivities?.occurances, (occurance) => occurance.bookings.length);
    }
  };

  const listAcitivityTypesUsingInventory = () => {
    if (attachedActivities?.activityTypes) {
      return attachedActivities?.activityTypes.map((activity, i) => {
        return <div key={activity.id + 1}>{`${(i += 1)}. ` + tt(activity.title)}</div>;
      });
    }
  };

  if (attachedActivities?.occurances) {
    return (
      <div
        className={`ui basic segment`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0em 2em 2em 2em',
        }}
      >
        <p>
          {t('There are in total')}
          &nbsp;
          <b>{attachedActivities.occurances.length}</b>&nbsp;
          {t('upcoming activities with this inventory')}, &nbsp;
          <b>{occurancesWithoutBookings()?.length}</b>&nbsp;
          {t('do not have bookings')}. &nbsp;
          <b>{numberOfBookedOccurances()?.length}</b>&nbsp;
          {t('are booked')}.
        </p>
        <p>
          <i>{t('To delete this inventory...')}</i>&nbsp;
          <b>{moment(lastActivityOccurance?.end).format('DD-MM-YYYY hh:mm')}</b>&nbsp;
        </p>
      </div>
    );
  }

  if (attachedActivities?.activityTypes) {
    return (
      <div
        className={`ui basic segment`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0em 2em 2em 2em',
        }}
      >
        <p>
          {t('There are in total')}&nbsp;
          <b>{attachedActivities.activityTypes.length}</b>&nbsp;
          {t('activities using this inventory')},&nbsp;
          {t('to delete this inventory, you first need to remove or update the linked activities')}.&nbsp;
        </p>
        <p>
          <i>
            <b>{t('Linked activities')}:</b>&nbsp;
            {listAcitivityTypesUsingInventory()}&nbsp;
          </i>
        </p>
        {}
      </div>
    );
  }

  return <Fragment></Fragment>;
};
