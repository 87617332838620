import React from 'react';
import { CSSProperties } from 'react';
import { Consume, DeviceProvider, LocaleContext, IDevice } from '../../_dependencies';
import { AHLocaleContext } from '../../_locales';

class WidgetInformation extends React.Component {
  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  renderImage() {
    return this.device.size === 'mobile' ? (
      <img className="ui centered image" src="/static/platform/img/widget-mobile.png" style={{ width: '350px' }} />
    ) : (
      <img
        className="ui middle aligned right floated image"
        src="/static/platform/img/widget2.png"
        style={{ width: '460px' }}
      />
    );
  }

  private get textStyle(): CSSProperties {
    return this.device.size === 'mobile'
      ? {
          textAlign: 'center',
          color: 'white',
        }
      : {
          textAlign: 'left',
          color: 'white',
        };
  }

  private get columnStyle(): CSSProperties {
    return this.device.size === 'mobile'
      ? {
          display: 'flex',
          flexDirection: 'column-reverse',
        }
      : {
          display: 'flex',
          flexDirection: 'row',
        };
  }

  render() {
    const { t } = this._locale;
    return (
      <div style={containerStyle}>
        <div className="ui grid center aligned container" style={{ transform: 'skewY(3deg)' }}>
          <div className="ui stackable center aligned two column grid" style={this.columnStyle}>
            <div className="column" style={{ paddingTop: '3rem' }}>
              <h2 style={{ ...this.textStyle, fontSize: '2rem' }}>{t('Bookings and gift cards directly...')}</h2>
              <p style={this.textStyle}>{t('Thanks to our purchasing widget...')}</p>
            </div>
            <div className="column">{this.renderImage()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default WidgetInformation;

const containerStyle: CSSProperties = {
  backgroundColor: '#444',
  backgroundImage: 'url(/static/platform/img/texture-bg.png)',
  margin: '10rem 0',
  padding: '8rem 0',
  transformOrigin: 'top left',
  transform: 'skewY(-3deg)',
};
