import * as React from 'react';
import Stripe from 'stripe';
import { Field, Fields, KosmicComponent, Consume, LocaleContext } from '../../../../../../_dependencies';
import { AHLocaleContext } from '../../../../../../_locales';
import { FileUploadForm } from '../fileUploadForm';
import { PersonParams } from './personsForm';

interface Props {
  person: PersonParams;
  onVerificationChanged: (verification: Stripe.PersonUpdateParams.Verification) => void;
}

export class IdentificationFields extends KosmicComponent<Props> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  render() {
    const { t } = this._locale;
    const { person, onVerificationChanged } = this.props;
    const verification = Object.assign({}, person.verification);

    const document = verification.document || person.prevVerification?.document;
    const additionalDocument = verification.additional_document || person.prevVerification?.additional_document;

    if (person.prevVerification && person.prevVerification!.status == 'verified') {
      return null;
    }

    return (
      <div className="ui segment">
        <h3>
          {t('Identification')} <i id="identification-helper" className="question circle outline icon" />
          <div className="ui popup">
            <div className="header">{t('Identification document')}</div>
            <div className="content">{t('Your passport, drivers license or other ID...')}</div>
          </div>
        </h3>
        <Fields>
          <Field label={t('Personal identification')} width="sixteen wide">
            <FileUploadForm
              purpose="identity_document"
              onUpload={async (file) => {
                verification.document = { front: file.id };
                onVerificationChanged(verification);
              }}
              current={document?.front || null}
              width="sixteen"
              noPadding
            />
          </Field>
          <Field
            label={
              <span>
                {t('Confirming identification')}{' '}
                <i id="additional-document-helper" className="question circle outline icon" />
                <div className="ui popup">
                  <div className="header">{t('Proof of address')}</div>
                  <div className="content">{t('For example an invoice from your electricity company...')}</div>
                </div>
              </span>
            }
            width="sixteen wide"
          >
            <FileUploadForm
              purpose="additional_verification"
              onUpload={async (file) => {
                verification.additional_document = { front: file.id };
                onVerificationChanged(verification);
              }}
              current={additionalDocument?.front || null}
              width="sixteen"
              noPadding
            />
          </Field>
        </Fields>
      </div>
    );
  }
}
