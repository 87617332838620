import * as React from 'react';
import { ErrorBoundary } from '../../../components/errorBoundary';

export const ActivitiesTemplate: React.FC = ({ children }) => {
  return (
    <ErrorBoundary>
      <div style={{ minHeight: '50em' }}>{children}</div>
    </ErrorBoundary>
  );
};
