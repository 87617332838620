import { Schema } from 'mongoose';

export interface AddressDocument {
  row1: string;
  row2?: string;
  postOrt: string;
  postNr: string;
  country: string;
  /** VIRTUAL FIELD */ asString?: string;
  /** VIRTUAL FIELD */ isEmpty?: boolean;
}

const AddressSchemaa = new Schema(
  {
    row1: String,
    row2: String,
    postOrt: { type: String },
    postNr: { type: String },
    country: String,
  },
  { _id: false },
);
AddressSchemaa.virtual('asString').get(function () {
  return (
    `${this.row1},\n` +
    `${this.row2 ? 'alt: ' + this.row2 + ',\n' : ''}` +
    `${this.postNr} ${this.postOrt}, ${this.country}\n`
  );
});
AddressSchemaa.virtual('isEmpty').get(function () {
  return this.row1 || this.row2 || this.postNr || this.postOrt;
});

export const AddressSchema = AddressSchemaa;
