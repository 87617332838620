import React from 'react';
import {
  DeviceProvider,
  GlobalsProvider,
  KosmicTemplate,
  Platforms,
  PurchaseWidget,
  ReactModuleMainComponent,
  Redirect,
  Route,
  RouteWithHooks,
  SecureRoute,
  SessionProvider,
  Switch,
  UserResource,
  startReact,
} from './_dependencies';
import { collection } from './_locales';
import { AuthenticationPage, ResetPasswordView } from './components';
import AdminTemplate from './components/admin';
import TicketPage from './components/checkInTicket/ticketPage';
import CreateAccountPage from './components/createAccount/createAccountPage';
import {
  CustomerTemplate,
  GiftCardPurchaseView,
  GiftCardShowcase,
  PropertyDetailsView as PropertyDetailsViewForCustomers,
} from './components/customer';
import { AboutUs } from './components/customer/aboutUs';
import { ActivitiesTemplate } from './components/customer/activities';
import { ActivitiesBookingPage } from './components/customer/activities/activitiesBookingPage';
import { ActivitiesShowcase } from './components/customer/activities/activitiesShowcase';
import { ActivitiesSearchProvider } from './components/customer/activities/activitySearchProvider';
import PropertyShowcase from './components/customer/activities/propertyShowcase';
import { Ekerum } from './components/customer/custom/ekerum';
import { DashboardTemplate } from './components/dashboard';
import { BookingView, BookingsTemplate } from './components/dashboard/bookings';
import { HandleBookingsPage } from './components/dashboard/bookings/handleBookingsPage';
import { HandleDestinationBookingsPage } from './components/dashboard/bookings/handleDestinationBookingsPage';
import { CreditCodeTemplate } from './components/dashboard/creditCodes';
import { CreditCodesListView } from './components/dashboard/creditCodes/creditCodesListView';
import { DiscountsTemplate } from './components/dashboard/discounts';
import { HandleDiscounts } from './components/dashboard/discounts/handleDiscountsPage';
import { AddNewEmployeeForm, EmployeesIndex, EmployeesTemplate } from './components/dashboard/employees';
import {
  GiftCardTemplate,
  HandleGiftCardTypes,
  ListOfSoldGiftCards,
  SellGiftCardView,
} from './components/dashboard/giftCards';
import { DashboardIndex } from './components/dashboard/home';
import NotificationsView from './components/dashboard/notifications/notificationsView';
import { NewOfferView, OfferDetails, OffersIndex, OffersTemplate } from './components/dashboard/offers';
import { InventoryLocationList } from './components/dashboard/offers/inventoryTables/inventoryLocationList';
import { InventoryTableView } from './components/dashboard/offers/inventoryTables/inventoryTableView';
import { InventoryTransferList } from './components/dashboard/offers/inventoryTables/inventoryTransferList';
import { InventoryItemStatusPage } from './components/dashboard/offers/qr/InventoryItemStatusPage';
import {
  AddNewPropertyForm,
  EmployeeScheduleView,
  PropertiesIndex,
  PropertiesRedirect,
  PropertiesTemplate,
  PropertyBookingView,
  PropertyCalendarView,
  PropertyDetailsTemplate,
  PropertyDetailsView,
  PropertyEditEmployeesView,
  PropertyEmployeesView,
  PropertySettingsView,
} from './components/dashboard/properties';
import { ScheduleProvider } from './components/dashboard/properties/employeeSchedule/context';
import { PlaceForm } from './components/dashboard/properties/placeForm';
import { PropertiesProvider } from './components/dashboard/properties/propertiesProvider';
import { PropertyStore } from './components/dashboard/properties/propertyStore';
import { ScheduleIndex } from './components/dashboard/schedule';
import { WidgetsSection } from './components/dashboard/widgets';
import { ErrorBoundary } from './components/errorBoundary';
import { PageNotFound } from './components/pageNotFound';
import { PaymentLinkComponent } from './components/paymentLink';
import { RescheduleLinkComponent } from './components/reschedule';
import { UserInfoView } from './components/userInfoView';
import { WorkSignupView } from './components/workSignupView';
import { BadgeProvider } from './contexts';
import { ActivityStartTimesContext } from './contexts/activityStartTimes.context';
import NotificationProvider from './contexts/notification.context';
import OffersProvider from './contexts/offers.context';

// REACT BROWSER MODULE

/** Generate React Component is needed for SSR */
export { generateReactComponent } from './_dependencies';

/** Module Main Component */
export class MainComponent extends ReactModuleMainComponent {
  getUserResource() {
    return new UserResource();
  }

  componentDidMount() {
    document.title = 'Adventure Hero - Hitta din nästa upplevelse här. Boka Äventyrsklättring, Segway, paintball.';
  }

  protected get additionalRoutes(): JSX.Element[] {
    return [<Route path="/ekerum" component={Ekerum} key="0" />];
  }

  protected get customerTemplateComponent() {
    return CustomerTemplate;
  }

  protected get indexRedirect() {
    return <Redirect exact path="/" to="/activities" />;
  }

  protected overriddenActivitiesRoutes: JSX.Element;

  render() {
    const CustomerTemplate = this.customerTemplateComponent;

    return (
      <KosmicTemplate>
        <ProviderStack>
          <ErrorBoundary>
            <Switch>
              {this.indexRedirect}
              <Route
                path="/widget/giftcards/:organization"
                render={({ match }) => (
                  <PurchaseWidget fixedType="giftcards" organizationId={match.params.organization} framed={true} />
                )}
              />
              <Route
                path="/widget/:property"
                render={({ match }) => (
                  <PurchaseWidget initalType="bookings" propertyId={match.params.property} framed={true} />
                )}
              />

              {/* Admin sub-routes has been moved to AdminTemplate as per React Router 4 */}
              <SecureRoute path="/admin" restrictedTo="system.owner" redirectTo="/log-in" component={AdminTemplate} />

              <Route path="/log-in" component={AuthenticationPage} />
              <Route path="/sign-up" component={CreateAccountPage} />
              <Route path="/dashboard/resetpassword/:userId/:resetToken" component={ResetPasswordView} />

              <SecureRoute path="/dashboard" restrictedTo="organization.volunteer" redirectTo={'/log-in'}>
                <DashboardTemplate>
                  <RouteWithHooks
                    path="*"
                    onEnter={(replace) => {
                      if (this.globals.session.currentUser && this.globals.session.currentUser.forcePasswordReset) {
                        replace(`/dashboard/resetpassword/${this.globals.session.currentUser.id}/forced`);
                      }
                    }}
                  >
                    <Switch>
                      <RouteWithHooks
                        exact
                        path="/dashboard"
                        component={DashboardIndex}
                        onEnter={(replace: Function) => {
                          if (
                            !this.globals.session.userHasRole({
                              type: 'property.manager',
                              organization: this.globals.session.currentOrganizationId,
                            })
                          ) {
                            replace('/dashboard/schedule');
                          }
                        }}
                      />
                      <Route path="/dashboard/properties">
                        <PropertiesTemplate>
                          <Switch>
                            <Route exact path="/dashboard/properties" component={PropertiesRedirect} />
                            <Route path="/dashboard/properties/info" component={PropertiesIndex} />
                            <Route path="/dashboard/properties/new" component={AddNewPropertyForm} />
                            <Route path="/dashboard/properties/details/:id">
                              <PropertyDetailsTemplate>
                                <Switch>
                                  {/* TODO: How can we pass along the correct property to authorize properly, property managers can still access other properties via url */}
                                  <SecureRoute
                                    exact
                                    path="/dashboard/properties/details/:id"
                                    restrictedTo="property.manager"
                                    redirectTo="/dashboard"
                                    component={PropertyDetailsView}
                                  />
                                  <RouteWithHooks
                                    path="/dashboard/properties/details/:id/employees"
                                    onEnter={() => PropertyStore.Instance.UpdateEmployeeList()}
                                  >
                                    <Switch>
                                      <Route
                                        exact
                                        path="/dashboard/properties/details/:id/employees"
                                        component={PropertyEmployeesView}
                                      />
                                      <Route
                                        path="/dashboard/properties/details/:id/employees/edit"
                                        component={PropertyEditEmployeesView}
                                      />
                                      {Platforms.features.offersCanBeAssignedToEmployees ? (
                                        <Route
                                          path="/dashboard/properties/details/:propertyId/employees/manageSchedule/:userId"
                                          render={(props) => (
                                            <ScheduleProvider>
                                              <EmployeeScheduleView {...props} />
                                            </ScheduleProvider>
                                          )}
                                        />
                                      ) : null}
                                    </Switch>
                                  </RouteWithHooks>
                                  <Route
                                    path="/dashboard/properties/details/:id/schedule"
                                    render={(props) => <PropertyCalendarView {...props} />}
                                  />
                                  <Route
                                    path="/dashboard/properties/details/:id/booking"
                                    component={PropertyBookingView}
                                  />
                                  <Route
                                    path="/dashboard/properties/details/:id/settings"
                                    component={PropertySettingsView}
                                  />
                                </Switch>
                              </PropertyDetailsTemplate>
                            </Route>

                            <Route path="/dashboard/properties/places/:id" component={PlaceForm} />
                            <Route path="/dashboard/properties/places" component={PlaceForm} />
                          </Switch>
                        </PropertiesTemplate>
                      </Route>
                      <Route path="/dashboard/schedule" component={ScheduleIndex} />
                      <Route path="/dashboard/notifications" component={NotificationsView} />
                      <SecureRoute
                        path="/dashboard/employees"
                        restrictedTo="organization.manager"
                        redirectTo="/dashboard"
                      >
                        <EmployeesTemplate>
                          <Switch>
                            <Route exact path="/dashboard/employees" component={EmployeesIndex} />
                            <Route path="/dashboard/employees/new" component={AddNewEmployeeForm} />
                          </Switch>
                        </EmployeesTemplate>
                      </SecureRoute>
                      <SecureRoute path="/dashboard/offers" restrictedTo="organization.manager" redirectTo="/dashboard">
                        <OffersTemplate>
                          <Switch>
                            <Route exact path="/dashboard/offers" component={OffersIndex} />
                            <Route path="/dashboard/offers/details/:id" component={OfferDetails} />
                            <Route path="/dashboard/offers/new" component={NewOfferView} />
                            <Route path="/dashboard/offers/inventories" component={InventoryTableView} />
                            <Route path="/dashboard/offers/inventorylocationlist" component={InventoryLocationList} />
                            <Route path="/dashboard/offers/transferlist" component={InventoryTransferList} />
                          </Switch>
                        </OffersTemplate>
                      </SecureRoute>

                      <SecureRoute path="/dashboard/giftcards" restrictedTo="property.manager" redirectTo="/dashboard">
                        <GiftCardTemplate>
                          <Switch>
                            <Route exact path="/dashboard/giftcards" component={HandleGiftCardTypes} />
                            <Route path="/dashboard/giftcards/sold" component={ListOfSoldGiftCards} />
                            <Route path="/dashboard/giftcards/sell" component={SellGiftCardView} />
                          </Switch>
                        </GiftCardTemplate>
                      </SecureRoute>
                      <SecureRoute
                        path="/dashboard/creditcodes"
                        restrictedTo="property.manager"
                        redirectTo="/dashboard"
                      >
                        <CreditCodeTemplate>
                          <CreditCodesListView />
                        </CreditCodeTemplate>
                      </SecureRoute>
                      <SecureRoute path="/dashboard/discounts" restrictedTo="property.manager" redirectTo="/dashboard">
                        <DiscountsTemplate>
                          <Switch>
                            <Route exact path="/dashboard/discounts" component={HandleDiscounts} />
                          </Switch>
                        </DiscountsTemplate>
                      </SecureRoute>

                      <SecureRoute path="/dashboard/bookings" restrictedTo="property.manager" redirectTo="/dashboard">
                        <BookingsTemplate>
                          <Switch>
                            <Route exact path="/dashboard/bookings/sell" component={BookingView} />
                            <Route exact path="/dashboard/bookings" component={HandleBookingsPage} />
                            <Route
                              path="/dashboard/bookings/outsideOrganization"
                              component={HandleDestinationBookingsPage}
                            />
                          </Switch>
                        </BookingsTemplate>
                      </SecureRoute>
                      <SecureRoute path="/dashboard/widget" restrictedTo="property.manager" redirectTo="/dashboard">
                        <WidgetsSection />
                      </SecureRoute>
                      <Route path="/dashboard/user" component={UserInfoView} />
                    </Switch>
                  </RouteWithHooks>
                </DashboardTemplate>
              </SecureRoute>

              <Route path="/work/signup/:activityId/:employeeId/:status" component={WorkSignupView} />

              {this.additionalRoutes}
              <Route path="/payment/product" component={PaymentLinkComponent} />
              <Route path="/inventory/item" component={InventoryItemStatusPage} />
              <Route path="/reschedule" component={RescheduleLinkComponent} />
              <Route path="/ticket" component={TicketPage} />
              <Route path="/">
                <CustomerTemplate>
                  <Switch>
                    <Route exact path="/properties" component={PropertyShowcase} />
                    <Route exact path="/properties/details/:id" component={PropertyDetailsViewForCustomers} />
                    <Route path="/activities">
                      <ActivitiesTemplate>
                        <Switch>
                          <Redirect exact path="/activities" to="/activities/search" />
                          <Route path="/activities/search" component={ActivitiesShowcase} />
                          <Route
                            path="/activities/booking/:propertyId/:activitytypeId"
                            component={ActivitiesBookingPage}
                          />
                        </Switch>
                      </ActivitiesTemplate>
                    </Route>

                    <Route path="/giftcards" component={GiftCardShowcase} />
                    <Route path="/purchase/giftcard/:organization/:giftcardtype" component={GiftCardPurchaseView} />
                    <Route path="/purchase/giftcard/:organization" component={GiftCardPurchaseView} />
                    <Route path="/about" component={AboutUs} />

                    <Route path="*">
                      <PageNotFound />
                    </Route>
                  </Switch>
                </CustomerTemplate>
              </Route>
            </Switch>
          </ErrorBoundary>
        </ProviderStack>
      </KosmicTemplate>
    );
  }
}

const ProviderStack: React.FC = ({ children }) => {
  return (
    <DeviceProvider>
      <GlobalsProvider>
        <SessionProvider>
          <OffersProvider>
            <PropertiesProvider>
              <BadgeProvider>
                <ActivityStartTimesContext>
                  <ActivitiesSearchProvider>
                    <NotificationProvider>{children}</NotificationProvider>
                  </ActivitiesSearchProvider>
                </ActivityStartTimesContext>
              </BadgeProvider>
            </PropertiesProvider>
          </OffersProvider>
        </SessionProvider>
      </GlobalsProvider>
    </DeviceProvider>
  );
};

// Render React in the browser
startReact(MainComponent, collection);
