import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';

import { AddressDocument, AddressSchema } from '../schemas/address';
import { AreaDocument } from '../schemas/area';
import { LocationDocument } from '../schemas/location';
import { SocialMediaDocument, SocialMediaSchema } from '../schemas/socialMedia';
import { ActivityOccuranceDocument } from './activityOccurance.resource';
import { ActivityTypeDocument } from './activityType.resource';
import { PropertyImageResource } from './propertyImage.resource';

import SchemaNames from '../schemas/names';
import { TranslatableText } from '../schemas/translatableText';

export interface PropertyDocument extends MongooseDocument {
  organization: Types.ObjectId;
  name: TranslatableText | string;
  description: TranslatableText | string;
  managers: Types.ObjectId[];
  crew: Types.ObjectId[];
  address?: AddressDocument;
  email: string;
  phoneNo: string;
  website: string;
  /** [longitude, latitude], also @see ../schemas/location.ts */ location: [longitude: number, latitude: number];
  imageId?: string;
  socialMedia?: SocialMediaDocument;
  /** Currently used with BB freelancers  */ freelancerAreas: AreaDocument[];
  /** VIRTUAL FIELD*/ imageUrl: string;
  /** ADDITIONAL FIELD */ occurences?: ActivityOccuranceDocument[];
}

export class PropertyResource extends MongooseResource<PropertyDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Property);

    this.setSchema({
      organization: { type: Schema.Types.ObjectId, ref: SchemaNames.Organization, required: true },
      name: { type: Schema.Types.Mixed },
      description: { type: Schema.Types.Mixed },
      crew: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Default }],
      managers: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Default }],
      address: AddressSchema,
      location: { type: [Number], index: '2dsphere', default: [] },
      email: { type: String },
      phoneNo: { type: String },
      website: { type: String },
      imageId: { type: String, required: false },
      socialMedia: { type: SocialMediaSchema, required: false },
      freelancerAreas: [Schema.Types.Mixed],
    });

    this.addVirtualField('imageUrl', (document) =>
      document.imageId
        ? new PropertyImageResource().fileUrl(document.imageId, 'png')
        : '/static/platform/img/default_property.png',
    );
  }

  checkPropertyNameAvailability(
    text: TranslatableText | string,
    property: PropertyDocument,
  ): Promise<{ [Property in keyof TranslatableText]-?: boolean }> {
    return this.sendRequest('/checkPropertyNameAvailability', 'post', { text, property: property.id });
  }

  getAdministratedProperties() {
    return this.sendRequest<Array<PropertyDocument>>('/administrated', 'post', {});
  }

  getNumberOfPropertiesForOrganization(organization: Types.ObjectId) {
    return this.sendRequest<{ nrOfProperties: number }>('/propertyAmountOfOrganization', 'post', { organization });
  }

  getAvailableCities() {
    return this.sendRequest<Array<{ title: string; zipcode: string }>>('/getAvailableCities', 'post', {});
  }

  getAvailableAreas() {
    return this.sendRequest<Array<{ title: string; zipcode: string }>>('/getAvailableAreas', 'post', {});
  }

  findAvailableActivityTypes(propertyId: Types.ObjectId) {
    return this.sendRequest<Array<ActivityTypeDocument>>('/findAvailableActivityTypes', 'post', { propertyId });
  }

  getLocation(address: AddressDocument) {
    return this.sendRequest<LocationDocument>('/getLocation', 'post', { address });
  }

  updateAllLocations() {
    return this.sendRequest<{ count: number }>('/updateAllLocations', 'post', {});
  }

  getPropertyWithPopulatedOrg(id: string) {
    return this.sendRequest<PropertyDocument>('/' + id, 'get', {});
  }

  getPropertiesWithPopulatedOrgs(organizationId: string | undefined) {
    return this.sendRequest<PropertyDocument[]>('/getPropertiesWithPopulatedOrgs', 'post', { orgId: organizationId });
  }
}
