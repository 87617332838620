import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import {
  GlobalsContext,
  InventoryEntityDocument,
  InventoryStatusDocument,
  InventoryStatusType,
  PlaceDocument,
  TemporaryInventoryStatusType,
  UserDocument,
  UserResource,
} from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';

interface Props {
  places?: PlaceDocument[];
  item: InventoryEntityDocument;
  onEditClicked?: (id: string) => void;
  isReadonlyList?: Boolean;
  mobile?: boolean;
  /** Adds checkbox which can be tracked from the onToggleSelect function*/ isSelectable?: boolean;
  /** Will set the item as selected (checked) or not*/ isSelected?: boolean;
  /** Fired when item is selected or deselected*/ onToggleSelect?: (id: string, isChecked: boolean) => void;
}

export const InventoryDetailsRow = ({
  item,
  onEditClicked,
  isReadonlyList,
  isSelectable,
  onToggleSelect,
  isSelected,
  places,
  mobile,
}: Props) => {
  const { t, tt } = useLocale();
  const { session } = useContext(GlobalsContext);
  const checkBoxRef = useRef<HTMLInputElement>(null);
  const [availablePersonnel, setAvailablePersonnel] = useState<UserDocument[] | undefined>(undefined);
  const lastStatus: InventoryStatusDocument | undefined = item.lastStatus;

  const fetchAndUpdatePersonnelState = async () => {
    const personell: UserDocument[] = await fetchPersonnelFromOrganization(session.currentOrganization.id);
    setAvailablePersonnel(personell);
  };

  useEffect(() => {
    if (!availablePersonnel) fetchAndUpdatePersonnelState();
  }, [session.currentOrganization]);

  const onRowClicked = () => {
    if (onEditClicked) onEditClicked(item.id);
    if (isSelectable) checkBoxRef.current!.click();
  };

  const renderLocationFromPlaces = (place: PlaceDocument) => {
    return places?.filter((p) => p.id == String(place))[0];
  };

  if (!lastStatus) return <Fragment key={`row-${item.id}`} />;

  return (
    <tr
      key={item.id}
      onClick={onRowClicked}
      style={{
        cursor: isSelectable ? 'pointer' : 'auto',
      }}
    >
      <td>
        <div className={!lastStatus ? 'ui mini active inline loader' : ''}>{item.title}</div>
      </td>
      <td>
        <div className={!lastStatus ? 'ui mini active inline loader' : ''}>
          {lastStatus && (
            <div className={`ui small ${setStatusColors(lastStatus.status)} label`}>{t(lastStatus.status)}</div>
          )}
        </div>
      </td>
      <td>
        <div className={!lastStatus ? 'ui mini active inline loader' : ''}>
          {!lastStatus.location ? '-' : tt(renderLocationFromPlaces(lastStatus.location)?.name)}
        </div>
      </td>
      {onEditClicked && (
        <td className="right aligned">
          <i className="edit icon" style={{ cursor: 'grab' }} onClick={() => onEditClicked(item.id)} />
        </td>
      )}
      {isReadonlyList && (
        <>
          <td>
            <div className={!lastStatus ? 'ui mini active inline loader' : ''}>
              {lastStatus.booking?.number ? lastStatus.booking?.number : '-'}
            </div>
          </td>
          {!mobile ? (
            <td>
              <div className={!lastStatus ? 'ui mini active inline loader' : ''}>
                {lastStatus.booking?.notes || '-'}
              </div>
            </td>
          ) : null}
        </>
      )}
      {isSelectable && onToggleSelect && (
        <td className="right aligned">
          <input
            onClick={(e) => e.stopPropagation()}
            ref={checkBoxRef}
            onChange={(e) => onToggleSelect(item.id, e.target.checked)}
            checked={isSelected}
            type="checkbox"
          />
        </td>
      )}
    </tr>
  );
};

async function fetchPersonnelFromOrganization(id: string): Promise<UserDocument[]> {
  const resource = new UserResource();
  const response = await resource.retrieveEmployeesByOrganization(id);
  return response;
}

const setStatusColors = (status: InventoryStatusType | TemporaryInventoryStatusType): string => {
  return status === 'checked-in'
    ? 'green'
    : status === 'checked-out'
    ? 'orange'
    : status === 'available'
    ? 'green'
    : status === 'unavailable'
    ? 'red'
    : status === 'in-use'
    ? 'orange'
    : 'grey';
};
