import React, { useEffect, useState } from 'react';
import { Types } from 'mongoose';
import { DestinationResource, Field, Input, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface IRefundableUntilFieldProps {
  org: Types.ObjectId;
  refundableUntil?: number;
  setOfferRefundableUntil: (key: 'refundableUntilInHours', value: number | undefined) => void;
}

export const RefundableUntilField = ({ org, refundableUntil, setOfferRefundableUntil }: IRefundableUntilFieldProps) => {
  const { t } = useLocale();
  const refundableTime = refundableUntil ? String(refundableUntil) : '';
  const [isPartOfDestination, setIsPartOfDestination] = useState(false);

  const updateRefundableTime = (value: string) => {
    if (!value) return setOfferRefundableUntil('refundableUntilInHours', undefined);
    return setOfferRefundableUntil('refundableUntilInHours', parseInt(value));
  };

  useEffect(() => {
    (async () => {
      const isPartOfDestination = await new DestinationResource().isPartOfDestination(org);
      setIsPartOfDestination(isPartOfDestination);
    })();
  }, []);

  if (!isPartOfDestination) return null;

  return (
    <Field label={t('Cancel no later than X hours before for refund')}>
      <Input
        name="Återbetalbart tills"
        icon="money"
        value={refundableTime}
        onChange={updateRefundableTime}
        rules={[Rules.IsIntegerOneOrHigherElseEmpty()]}
        placeholder={t('(Optional)')}
      />
    </Field>
  );
};
