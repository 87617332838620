import { OrganizationDocument } from './organization.resource';

/**
 * TODO: Kosmic and Bumblebook is currenly interlinked
 * As the new external module functionality was written, access to the domain
 * of the platform needs to be accessed both from node and the browser, and the
 * most logical place to store this information is in this Platforms file, but it
 * does not exist in Kosmic...
 * * it should be standardized (does not need ALL the information from here)
 * * (preferably) it should be synced with the platform (server side) settings
 */

/** Constrained types for available tax rates in the system */
export type TaxRate = 0.25 | 0.12 | 0.06 | 0.07 | 0.19 | 0;
export type PlatformId = 'AH';

export interface PlatformVariables {
  platformId: PlatformId;
  platformName: string;
  /**
   * Indicates the primary domains this platform is accessible from
   * Used when dynamic routes are resolved
   */
  features: {
    /** Gift Cards can be purchased at this platform NOTE: not used at all locations yet */
    giftCards: boolean;
    offersCanBeAssignedToEmployees: boolean;
    accountsCanBeActivatedFromDashboard: boolean;
    accountsCanManageSubscriptionsFromDashboard: boolean;
  };
  currency: 'kr' | '€';
  /** A three-letter ISO currency used by the platform, this is used when talking to the stripe api */
  currencyISO: 'SEK' | 'EUR';
  /** locale to default to in cases where the platform is unavailable, such as in mail template. Should problably be removed when mail tempaltes is refactored next */
  preferedLocale: Locale;
  /** Should match the language set in the Stripe Dashboard for 'customer emails' */
  receiptLocale: Locale;
  mailDomain: string;
  primaryColor: string;
  secondaryColor: string;
  thirdColor: string;
  registrationContactMail: string;
  registrationContactPhone: string;
  /** The available tax rates on this platform, the default locale is the first one the the list */
  locales: Locale[];
  /** Available tax rates for the current platform, the default rate is the first one the the list */
  taxRates: TaxRate[];
  /** Url:s to images that will be shown in the masthead carousel */
  mastheadImageUrls: string[];
  stripeSubscriptionConfiguration?: BumblebookStripeSubscriptionConfiguration;
  /** The id of the organization document thats connected to the main stripe account, i.e. Hiveandfive  */
  primaryStripeOrganizationId?: string;
}

export interface BumblebookStripeSubscriptionConfiguration {
  /** Supported countries, all code related to Stripe accounts, charges, payouts must work with new countries */ countries: string[];
  /** Returns the account to connect new accounts to */ getTargetAccountForNewConnections: () => string;
  /** Returns a plan id for a new subscription */ getSubscriptionPlanforOrganization: (
    organization: OrganizationDocument,
  ) => string; // NOTE: Should be in the platform class if possible
}

/**
 * Platform constants
 * @deprecated
 */
export const Platforms: PlatformVariables = {
  platformId: 'AH',
  platformName: 'Adventure Hero',
  features: {
    giftCards: true,
    accountsCanBeActivatedFromDashboard: true,
    accountsCanManageSubscriptionsFromDashboard: false,
    offersCanBeAssignedToEmployees: false,
  },
  currency: 'kr',
  currencyISO: 'SEK',
  preferedLocale: 'sv',
  receiptLocale: 'en',
  mailDomain: 'adventurehero.se',
  primaryColor: '#29bb9c',
  secondaryColor: '#BBE8DF',
  thirdColor: '#A0A0A0',
  registrationContactMail: 'anett@hiveandfive.se',
  registrationContactPhone: '(+46) 0762 74 86 00',
  locales: ['sv', 'en', 'de'],
  taxRates: [0.25, 0.12, 0.06, 0],
  mastheadImageUrls: [
    '/static/platform/img/headerImages/header1.jpg',
    '/static/platform/img/headerImages/header2.jpg',
    '/static/platform/img/headerImages/header3.jpg',
    '/static/platform/img/headerImages/header4.jpg',
    '/static/platform/img/headerImages/header5.jpg',
    '/static/platform/img/headerImages/header6.jpg',
    '/static/platform/img/headerImages/header7.jpg',
  ],
  primaryStripeOrganizationId:
    MODULE_RUNTIME_TIER == 'master' ? '57cd9bc977c8f2cafe578cb9' : '57cd9bc977c8f2cafe578cb9',
};

// Utility methods...

/** This method takes an url and makes sure its formated correctly using when running embedded */
export const URL = (url: string) =>
  typeof MODULE_IS_EMBEDDABLE != 'undefined' && MODULE_IS_EMBEDDABLE ? MODULE_PUBLIC_URL + url : url;
