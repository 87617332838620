import { Types } from 'mongoose';
import React, { useEffect, useState } from 'react';
import { PlaceDocument, PlaceResource } from '../_dependencies';
import { useLocale } from '../_locales';

interface Props {
  route: Types.ObjectId[] | undefined;
}

const RouteInfo = ({ route }: Props) => {
  if (!route || !route.length) return null;
  const { t, tt } = useLocale();

  const [places, setPlaces] = useState<PlaceDocument[]>();

  useEffect(() => {
    fetchPlaces();
  }, []);

  const fetchPlaces = async () => {
    const promises = route.map(async (id) => {
      const resource = new PlaceResource();
      const place = await resource.find({ _id: id });

      return place[0];
    });

    const places = await Promise.all(promises);

    setPlaces(places);
  };

  return (
    <div>
      <strong>{t('Route')}:</strong>
      <div style={{ height: '0.2rem' }}></div>
      {places?.map((place, index, places) => (
        <div key={place.id + index}>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1em' }}>
            <i
              className={
                index === 0 ? 'flag outline icon' : index + 1 === places.length ? 'flag checkered icon' : 'point icon'
              }
            />
            <p>{tt(place.name)}</p>
          </div>
        </div>
      ))}
      <div style={{ height: '0.5rem' }}></div>
    </div>
  );
};

export default RouteInfo;
