import React from 'react';
import { Checkbox, Field, Input, PriceCategoryDocument, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface Props {
  categories: PriceCategoryDocument[];
  onPriceCategoriesChange: (key: 'priceCategories', value: PriceCategoryDocument[]) => void;
}

export const BasePriceFields = ({ categories, onPriceCategoriesChange }: Props) => {
  const { t } = useLocale();
  const basePriceCategoryIndex = categories.findIndex((cat) => cat.basePrice);
  const basePriceCategory = categories[basePriceCategoryIndex];

  const addOrRemoveBasePrice = () => {
    const nextCategories = [...categories];
    if (basePriceCategory) {
      nextCategories.splice(basePriceCategoryIndex, 1);
    } else {
      nextCategories.push({
        basePrice: true,
        name: { sv: 'Grundpris', en: 'Base price', de: 'Grundpreis' },
        price: undefined as unknown as number,
        groupMultiplier: 0,
      });
    }
    onPriceCategoriesChange('priceCategories', nextCategories);
  };

  const changePriceCategory = (index: number, priceCategory: PriceCategoryDocument) => {
    if (index === -1) return;
    const nextCategories = [...categories];
    nextCategories[index] = priceCategory;
    onPriceCategoriesChange('priceCategories', nextCategories);
  };

  const basePriceField = () => {
    if (!basePriceCategory) return null;
    const value = basePriceCategory.price ? String(basePriceCategory.price) : '';
    return (
      <Field style={{ marginTop: '1em' }}>
        <Input
          name="BasePrice"
          value={value}
          onChange={(value) =>
            changePriceCategory(basePriceCategoryIndex, {
              ...basePriceCategory,
              price: parseInt(value),
            })
          }
          placeholder={t('Enter base price')}
          rules={[Rules.NotEmpty(), Rules.IsIntegerOneOrHigherElseEmpty()]}
          rightLabel={t('kr-SEK')}
        />
      </Field>
    );
  };

  return (
    <div className="ui segment" style={{ marginTop: '2rem' }}>
      <div className="ui tiny header">
        <Checkbox
          toggle
          checked={Boolean(basePriceCategory)}
          onCheckedOrUncheked={addOrRemoveBasePrice}
          label={t('add-base-price')}
          style={{ marginBottom: '.5rem' }}
        />
        <p className="sub header">{t('The base price is a fixed fee...')}</p>
      </div>
      {basePriceField()}
    </div>
  );
};
