import * as React from 'react';
import { KosmicComponent } from '../_dependencies';

type GlobalsContext = KosmicComponent<any, any>['globals'];

export const GlobalsContext = React.createContext<GlobalsContext>({} as any);

export class GlobalsProvider extends KosmicComponent<{}> {
  public static Context = GlobalsContext;

  render() {
    return (
      <GlobalsProvider.Context.Provider value={this.globals}>{this.props.children}</GlobalsProvider.Context.Provider>
    );
  }
}
