import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';

type translationObject = {
  translationKey: string;
  data: string;
};
type BodyPart = {
  icon?: string;
  content: translationObject;
  link?: string;
};

export interface NotificationDocument extends MongooseDocument {
  read: boolean;
  title: translationObject;
  body?: BodyPart[];
  link: string;
  property: Types.ObjectId;
}

const TranslationSchema = new Schema(
  {
    translationKey: Schema.Types.Mixed,
    data: String,
  },
  { _id: false },
);

const NotificationBodySchema = new Schema(
  {
    icon: { type: String, required: false },
    content: TranslationSchema,
    link: { type: String, required: false },
  },
  { _id: false },
);

export class NotificationResource extends MongooseResource<NotificationDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.Notification);

    this.setSchema({
      read: { type: Boolean, default: false },
      title: TranslationSchema,
      body: { type: [NotificationBodySchema] },
      link: { type: String },
      property: { type: Schema.Types.ObjectId },
    });
  }

  createNotification(
    property: Types.ObjectId,
    link: string,
    title: translationObject,
    ...body: BodyPart[]
  ): NotificationDocument {
    return this.createDocument({
      read: false,
      property,
      title,
      body,
      link,
    });
  }
}
