import { Schema } from 'mongoose';

interface Features {
  scheduling: boolean;
  offers: boolean;
  properties: boolean;
  giftcards: boolean;
  bookings: boolean;
  widgets: boolean;
  discounts?: boolean;
  tmeTickets?: boolean;
  handleClientBelongings?: boolean;
  routes?: boolean;
  googleAnalyticsDatalayer?: boolean;
}

export interface OrganizationFlags {
  hideWeatherInWidget: boolean;
  orgNameAsMailSender: boolean;
  features: Features;
}

const FeaturesSchema = new Schema(
  {
    scheduling: { type: Boolean, required: true, default: true },
    offers: { type: Boolean, required: true, default: true },
    properties: { type: Boolean, required: true, default: true },
    giftcards: { type: Boolean, required: true, default: true },
    bookings: { type: Boolean, required: true, default: true },
    widgets: { type: Boolean, required: true, default: true },
    discounts: { type: Boolean, required: true, default: true },
    tmeTickets: { type: Boolean, required: true, default: false },
    handleClientBelongings: { type: Boolean, required: true, default: false },
    routes: { type: Boolean, required: true, default: true },
    googleAnalyticsDatalayer: { type: Boolean, required: true, default: false },
  },
  { _id: false },
);

export const OrganizationFlagsSchema = new Schema(
  {
    hideWeatherInWidget: { type: Boolean, required: true, default: false },
    orgNameAsMailSender: { type: Boolean, required: false, default: false },
    features: FeaturesSchema,
  },
  { _id: false },
);
