import { Schema, Types } from 'mongoose';
import type Stripe from 'stripe';
import { MongooseDocument, MongooseResource } from '../_dependencies';

import { AgreementDocument } from './agreement.resource';
import { UserDocument } from './user.resource';

import SchemaNames from '../schemas/names';
import { OrganizationFlags, OrganizationFlagsSchema } from '../schemas/organizationFlags';
import { ActivityTypeDocument } from './activityType.resource';
import { DestinationDocument } from './destination.resource';

export interface OrganizationDocument extends MongooseDocument {
  /** The name of the org */ name: string;
  /** NOTE: Needs documentation */ admins: Types.ObjectId[];
  /** NOTE: Needs documentation */ notificationEmail?: string;
  /** NOTE: Needs documentation */ replyToEmail?: string;
  /** A reference to the active org agreement document */ agreement?: Types.ObjectId;
  /** An array of previous, inactive agreements */ previousAgreements?: Types.ObjectId[];
  /** A reference to the next active org agreement document */ nextAgreement?: Types.ObjectId;
  /** A flag used if org allows bookings without online payment */ allowBookingsWithoutPayment: boolean;
  /** NOTE: Needs documentation */ experimentalSövdeCalendar: boolean;
  /** NOTE: Needs documentation */ flags: OrganizationFlags;
  /** For email verification */ verificationCode?: string;
  /** For email verification */ isVerified?: boolean;
  /** Activated with stripe */ isActivated?: boolean;
  /** Currently used on the BB platform */ isFreelancer?: boolean;
  /** For suspending the organization */ isSuspended?: boolean;
  /** Array of badge ids */ badges: Array<string>;
}

/**
 * This resource is used to manage organizations on relevant platforms
 */
export class OrganizationResource extends MongooseResource<OrganizationDocument> {
  /**
   * Typeguard for verifying is the passed object is an Document or an ObjectId
   * needs to look for a field that exists only in the document
   */
  static isDocument(data: any): data is OrganizationDocument {
    return data.name != undefined;
  }

  constructor() {
    super();

    this.setName(SchemaNames.Organization);

    this.setSchema({
      name: { type: String, required: true, unique: true },
      admins: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Default, required: true }],
      notificationEmail: { type: String, required: false },
      flags: {
        type: OrganizationFlagsSchema,
        default: {
          features: {
            scheduling: true,
            offers: true,
            widgets: true,
            bookings: true,
            giftcards: true,
            properties: true,
          },
        },
      },
      replyToEmail: { type: String, required: false },
      agreement: { type: Schema.Types.ObjectId, ref: SchemaNames.Agreement, required: false }, //NOTE: This should be required
      nextAgreement: { type: Schema.Types.ObjectId, ref: SchemaNames.Agreement, required: false },
      previousAgreements: [{ type: Schema.Types.ObjectId, ref: SchemaNames.Agreement, required: false }],
      allowBookingsWithoutPayment: { type: Boolean, required: true, default: false },
      experimentalSövdeCalendar: { type: Boolean, required: false },
      verificationCode: { type: String, required: false },
      isVerified: { type: Boolean, required: false },
      isActivated: { type: Boolean, required: false },
      isFreelancer: { type: Boolean, required: false },
      isSuspended: { type: Boolean, required: false },
      badges: [{ type: String, required: true, default: [] }],
    });
  }

  createNewAccount(
    organizationDocument: OrganizationDocument,
    userDocument: UserDocument,
    password: string,
    sendPasswordResetEmail: boolean,
  ) {
    return this.sendRequest<boolean>('/createNewAccount', 'post', {
      organizationDocument,
      userDocument,
      password,
      sendPasswordResetEmail,
    } as CreateNewAccountRequestObject);
  }

  getFilesForPerson(id: string): Promise<Stripe.File[]> {
    return this.sendRequest<any>('/stripe-person-files', 'get', { id });
  }

  createStripeFile(data: string, name: string, purpose = 'identity_document') {
    return this.sendRequest<Stripe.File>('/create-file', 'post', { data, name, purpose });
  }

  getStripeAccount(organization: OrganizationDocument): Promise<Stripe.Account | undefined> {
    return this.sendRequest<Stripe.Account | undefined>('/stripe-account', 'post', { organization });
  }

  acceptToa(): Promise<Stripe.Account> {
    return this.sendRequest<Stripe.Account>('/accept-stripe-toa', 'post', {});
  }

  createPerson(payload: Stripe.PersonCreateParams) {
    return this.sendRequest<Stripe.Person>('/create-stripe-person', 'post', { payload });
  }

  updatePerson(id: string, payload: Stripe.PersonUpdateParams) {
    return this.sendRequest<Stripe.Person>('/update-stripe-person', 'post', { id, payload });
  }

  updateAccount(payload: Stripe.AccountUpdateParams) {
    return this.sendRequest<Stripe.AccountUpdateParams>('/update-stripe-account', 'post', {
      payload,
    });
  }

  getStripePersonObjects() {
    return this.sendRequest<Stripe.Person[]>('/get-stripe-person', 'get', {});
  }

  verifyAccount(id: Types.ObjectId, verificationCode: string) {
    return this.sendRequest<AgreementDocument>('/verifyAccount', 'post', { id, verificationCode });
  }

  createExternalAccount(token: any) {
    return this.sendRequest<any>('/create-external-stripe-account', 'post', token);
  }

  isBankAccountConnectedToStripeAccount(agreementId: string) {
    return this.sendRequest<boolean>('/isBankAccountConnectedToStripeAccount', 'post', {
      agreementId,
    });
  }

  suspendOrganization(organizationId: Types.ObjectId) {
    return this.sendRequest<boolean>('/suspendOrganization', 'post', { id: organizationId });
  }

  unSuspendOrganization(organizationId: Types.ObjectId) {
    return this.sendRequest<boolean>('/unSuspendOrganization', 'post', { id: organizationId });
  }

  /**
   * Updates the feature flags on a organization. Requires system.owner role.
   * Returns the newly updated organization document.
   */
  updateFeatureFlags(orgId: Types.ObjectId, features: OrganizationDocument['flags']['features']) {
    return this.sendRequest<OrganizationDocument>('/updateFeatures', 'post', { orgId, features });
  }

  getDestinationsForOrganization(orgId: Types.ObjectId) {
    return this.sendRequest<DestinationDocument[]>('/getDestinationsForOrganization', 'post', { id: orgId });
  }

  getActivityTypesForOrganization(orgId: Types.ObjectId) {
    return this.sendRequest<ActivityTypeDocument[][]>('/getActivityTypesForOrganization', 'post', { id: orgId });
  }

  getPopulatedAdminsAndAgreements() {
    return this.sendRequest<OrganizationDocument[]>('/getPopulatedAdminsAndAgreements', 'get', {});
  }

  getUnrestrictedOrg(orgId: Types.ObjectId | string) {
    return this.sendRequest<OrganizationDocument>('/getUnrestrictedOrg', 'get', { id: orgId });
  }
}

export interface CreateNewAccountRequestObject {
  organizationDocument: OrganizationDocument;
  userDocument: UserDocument;
  password: string;
  sendPasswordResetEmail: boolean;
}
