import { capitalize } from 'lodash';
import Moment from 'moment-timezone';
import React, { CSSProperties } from 'react';
import { ActivityOccuranceDocument } from '../../../_dependencies';
import { useLocale } from '../../../_locales';

interface Props {
  day: Date;
  event: ActivityOccuranceDocument;
  onClick: (event: ActivityOccuranceDocument) => void;
}

/** Displays an activity in the agenda view */
export const AgendaEventItem = ({ day, event, onClick }: Props) => {
  const { t, tt } = useLocale();

  const timeRangeLabel = (event: ActivityOccuranceDocument) => {
    const { start, end } = event;

    let startDate = Moment(start).format('HH:mm');
    let endDate = Moment(end).format('HH:mm');

    startDate = Moment(start).format('HH:mm (D MMM)');
    startDate = capitalizeFirstLetters(startDate);

    endDate = Moment(end).format('HH:mm (D MMM)');
    endDate = capitalizeFirstLetters(endDate);

    return `${startDate} – ${endDate}`;
  };

  const labelColor = () => {
    if (!event.availableVisitors) return 'red';
    if (event.bookings.length) return 'orange';
    return 'teal';
  };
  const labelText = () => {
    if (!event.availableVisitors) return t('Fully booked');
    if (event.bookings.length) return t('Booked');
    return t('Available (plural)');
  };

  const style: CSSProperties = {
    borderTop: !event.isPublished ? '1px dashed lightgrey' : undefined,
    borderLeft: !event.isPublished ? '1px dashed lightgrey' : undefined,
    borderRight: !event.isPublished ? '1px dashed lightgrey' : undefined,
    background: event.hasRequestedBooking
      ? 'repeating-linear-gradient(45deg, #0000, #0000 5px, #0001 5px, #0001 10px)'
      : undefined,
  };

  return (
    <div className="item" onClick={() => onClick(event)} style={style}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '.4rem' }}>
        <b style={{ color: 'black', marginRight: '1rem' }}>{tt(event.title)}</b>
        <span style={{ whiteSpace: 'nowrap' }}>{timeRangeLabel(event)}</span>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', color: 'black' }}>
        <div style={{ display: 'flex', flex: 3 }}>
          <div className={`ui mini ${labelColor()} horizontal label`}>{labelText()}</div>
          {event.hasRequestedBooking ? (
            <div className={`ui mini grey horizontal label`} style={{ whiteSpace: 'nowrap' }}>
              {t('booking-request')}
            </div>
          ) : (
            !event.isPublished && (
              <div className={`ui mini grey horizontal label`} style={{ whiteSpace: 'nowrap' }}>
                {t('Not published')}
              </div>
            )
          )}
          {event.hasCustomerRequestedBooking && (
            <div className={`ui mini green horizontal label`} style={{ whiteSpace: 'nowrap' }}>
              {t('Booking request')}
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flex: 2, textAlign: 'right' }}>
          {event.neededStaffing && (
            <span style={{ flex: 1 }}>
              {event.workingStaff.length} <i className="ui users icon" />
            </span>
          )}
          <span style={{ flex: 1 }}>
            {event.bookings.length} <i className="ui ticket icon" />
          </span>
        </div>
        <div style={{ display: 'flex', flex: 2, textAlign: 'right' }}>
          <span style={{ flex: 1, textAlign: 'right' }}>
            {event.availableVisitors}/{event.visitorCapacity}
            {event.isPublished && ` ${t('Available (plural)').toLowerCase()}`}
          </span>
        </div>
      </div>
    </div>
  );
};

const capitalizeFirstLetters = (sentence: string) => sentence.split(' ').map(capitalize).join(' ');
