import React from 'react';
import { Platforms, Field, Input, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

type TUpdateableProperties = 'preparationDuration' | 'cleanupDuration';

interface IPreparationAndCleanupFieldsProps {
  preparationDuration?: number;
  cleanupDuration?: number;
  setOfferPrepOrCleanDuration: (key: TUpdateableProperties, value?: number) => void;
}

export const PreparationAndCleanupFields = ({
  preparationDuration,
  cleanupDuration,
  setOfferPrepOrCleanDuration,
}: IPreparationAndCleanupFieldsProps) => {
  const { t } = useLocale();
  const prepTime = preparationDuration ? String(preparationDuration) : '';
  const cleanTime = cleanupDuration ? String(cleanupDuration) : '';

  const updateOfferPrepOrClean = (key: TUpdateableProperties, value: string) => {
    if (!value) return setOfferPrepOrCleanDuration(key, undefined);
    return setOfferPrepOrCleanDuration(key, parseInt(value));
  };

  if (!Platforms.features.offersCanBeAssignedToEmployees) return null;

  return (
    <div className="ui grid" style={{ marginTop: '1em', marginBottom: '1em' }}>
      <Field label={t('Preparation time')} width={8} style={{ padding: '0 1em', marginBottom: '0' }}>
        <Input
          name="Föreberedelsetid"
          icon="hourglass start"
          defaultValue={prepTime}
          onChange={(value) => updateOfferPrepOrClean('preparationDuration', value)}
          rules={[Rules.IsEmptyOrInteger()]}
          placeholder={t('Enter time in hours')}
        />
      </Field>
      <Field label={t('Clean up time')} width={8} style={{ padding: '0 1em' }}>
        <Input
          name="Uppstädningstid"
          icon="hourglass end"
          defaultValue={cleanTime}
          onChange={(value) => updateOfferPrepOrClean('cleanupDuration', value)}
          rules={[Rules.IsEmptyOrInteger()]}
          placeholder={t('And minutes')}
        />
      </Field>
    </div>
  );
};
