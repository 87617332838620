import * as React from 'react';
import { Consume, LocaleContext, MobxComponent, PurchaseWidget } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { PropertyStore } from './propertyStore';

export class PropertyBookingView extends React.Component<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  render() {
    const { t } = this._locale;
    return (
      <div className="ui basic segment" style={{ padding: '2em' }}>
        <h2 className="ui header">
          {t('Create a booking')}
          <div className="sub header">{t('components.dashboard.properties.booking.messageBooking')}</div>
        </h2>
        <PropertyBookingViewContent />
      </div>
    );
  }
}

export class PropertyBookingViewContent extends MobxComponent<{}> {
  private _widgetURL = this.globals.session.currentLocation.origin + '/widget/' + PropertyStore.Instance.property.id; // TODO: everything with 'window' should be moved to a store, like session or device

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private get codeSnippet() {
    const { t } = this._locale;
    return (
      // This code has to be left aligned to get the correct indent.
      `<iframe frameborder="0"
                    width="100%"
                    height="800px"
                    src="` +
      this._widgetURL +
      `">
                <p>${t('components.dashboard.properties.booking.messageWebb')}</p>
            </iframe>`
    );
  }

  render() {
    return (
      <div>
        <div style={{ height: '1em' }} />
        <PurchaseWidget fixedType="bookings" propertyId={PropertyStore.Instance.property.id} />
      </div>
    );
  }
}
