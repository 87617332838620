import * as React from 'react';
import { Consume, LocaleContext, MobxComponent, Platforms, URL } from '../_dependencies';
import { AHLocaleContext } from '../_locales';

export class LanguageToggleIcons extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private onLangageIconClicked = (locale: Locale) => {
    const { setLocale } = this._locale;
    setLocale(locale);
  };

  public render() {
    return (
      <div style={this.props.style}>
        {Platforms.locales.map((locale) => (
          <img
            onClick={() => this.onLangageIconClicked(locale)}
            src={URL(`/static/platform/img/flags/${locale}.png`)}
            style={{
              ...languageIconStyle,
              opacity: this._locale.locale == locale ? 0.9 : 0.5,
            }}
            alt={locale}
            key={locale}
          />
        ))}
      </div>
    );
  }
}

const languageIconStyle: React.CSSProperties = {
  cursor: 'pointer',
  width: '2rem',
  marginRight: '0.5rem',
};
