import Qr from 'qr-image';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { InventoryEntityDocument } from '../../../../_dependencies';

export function drawHTMLForPrinting(
  a: Window | null,
  inventoryEntities: InventoryEntityDocument[],
  mobile: boolean,
  tablet: boolean,
) {
  a?.document.write('<body><html>');
  a?.document.write(
    '<div style="width:100vw; transform: translateY(-20px); display: grid; grid-template-columns: auto auto auto auto;">',
  );
  inventoryEntities.forEach((iE, i) => {
    const qRCode = qrCodeSquare(iE.id, mobile, tablet);
    const booking = iE.lastStatus.booking;
    a?.document.write(
      `<div style="
          display: flex; 
          justify-content: center;
          flex-direction: column; 
          align-items: center; 
          padding-top: 22px;
        ">
        <p style="margin: 0 !important; padding: 0 !important; font-family: Arial">
          <b>${iE.title}</b>
        </p>
        ${renderToString(qRCode)} 
        ${
          iE.isTemporary
            ? `
          <p style="margin: 0 !important; padding: 0 !important; font-family: Arial; font-size: 12.5px">
            <b>${booking?.number + ' ' + booking?.customer.lastname}</b>
          </p>
          `
            : ` `
        }
      </div>`,
    );
  });
  a?.document.write('</div></body></html>');
}

function qrCodeSquare(id: string, mobile: boolean, tablet: boolean) {
  const origin = location.origin;
  const path = '/inventory/item';
  const query = `externalId=${id}`;
  const link = `${origin}${path}?${query}`;
  const html = Qr.imageSync(link, { type: 'svg' });
  return (
    <svg width={'130'} height={'130'}>
      <rect x="0" y="0" width="100%" height="100%" fill="white" />
      <g
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </svg>
  );
}
