import Moment from 'moment-timezone';
import React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityTypeDocument,
  BookingPriceCategoryDocument,
  Consume,
  Form,
  KosmicComponent,
  LocaleContext,
  UserDocument,
  UserResource,
  formatDuration,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { SelectPriceCategoriesFields } from '../priceCategories/selectPriceCategoriesFields/selectPriceCategoriesFields';
import { WeatherComponent } from '../weather/weatherContext';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';
import RouteInfo from './routeInfo';

interface Props {
  selectedActivity?: ActivityOccuranceDocument;
  onDeselection: () => void;
  onOrderDetails: (priceCategories: BookingPriceCategoryDocument[]) => Promise<void>;
  alreadyReservedVisitors?: number;
  isTmeTicket?: boolean;
  isBookingRequest?: boolean;
}

export class SelectionDetailsView extends KosmicComponent<Props> {
  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  @Consume(WidgetDataProvider.Context)
  private widgetData: IWidgetData;

  private get selectedActivityType(): ActivityTypeDocument {
    const { selectedActivityType } = this.widgetData;
    return selectedActivityType || (this.props.selectedActivity?.originatingActivity as any as ActivityTypeDocument); // NOTE: assumes populated data
  }

  private get availableVisitors() {
    const { t } = this._locale;

    return {
      text: `${t('Available (plural)')}: `,
      count: this.props.selectedActivity?.availableVisitors,
    };
  }

  private get userIsAManager() {
    const currentUser = this.globals.session.currentUser as UserDocument;

    return (
      currentUser &&
      new UserResource().documentHasRole(currentUser, {
        type: 'property.manager',
        property: this.props.selectedActivity?.property,
        organization: this.props.selectedActivity?.organization,
      })
    );
  }

  private async onOrderDetailsSubmit(values, resolve, reject) {
    try {
      const { selectedPriceCategories } = this.widgetData;
      await this.props.onOrderDetails(selectedPriceCategories);
      resolve();
    } catch (err) {
      reject();
    }
  }

  private renderVisitorSelection() {
    if (this.props.selectedActivity?.availableVisitors || (this.userIsAManager && this.props.selectedActivity)) {
      return (
        <SelectPriceCategoriesFields
          key={this.props.selectedActivity?.id}
          occurance={this.props.selectedActivity}
          onChange={this.widgetData.setSelectedPriceCategories}
          widget={this.widgetData}
          alreadyReservedVisitors={this.props.alreadyReservedVisitors}
          isTmeTicket={this.props.isTmeTicket}
          isBookingRequest={this.props.isBookingRequest}
        />
      );
    }
  }

  private renderFormContent() {
    return (
      <div>
        {this.renderVisitorSelection()}
        <div style={{ height: '4rem' }} />
      </div>
    );
  }

  render() {
    const { selectedActivity, isTmeTicket, isBookingRequest } = this.props;
    const { t, tt, locale } = this._locale;

    return (
      <div style={{ flex: '1 1 auto' }}>
        <div className="ui horizontal divider" style={{ marginTop: '0' }}>
          {isTmeTicket ? <p>{t('Chosen ticket')}</p> : <p>{t('Chosen activity')}</p>}
        </div>
        <img className="ui fluid image" src={`${MODULE_PUBLIC_URL}${this.selectedActivityType.imageUrl}`} />
        <div style={{ height: '1em' }} />
        <Form onSubmit={this.onOrderDetailsSubmit.bind(this)}>
          <h3>{tt(selectedActivity?.title)}</h3>
          <p style={{ whiteSpace: 'pre-line' }}>{tt(this.selectedActivityType.description)}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
              {!isBookingRequest && (
                <p style={{ flexGrow: 1, minWidth: '150px' }}>
                  <strong>{t('widget.selectionSteps.selectionDetailsView.activityDate')}</strong>{' '}
                  {Moment(selectedActivity?.start).tz('Europe/Stockholm').format('YYYY-MM-DD, HH:mm')}
                </p>
              )}

              {!isTmeTicket && isBookingRequest && (
                <p style={{ flexGrow: 1, minWidth: '120px' }}>
                  <strong>{`${t('Start')}:`}</strong>{' '}
                  {Moment(selectedActivity?.start).tz('Europe/Stockholm').format('HH:mm')}
                </p>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
              <p style={{ flexGrow: 1, minWidth: '120px' }}>
                {isTmeTicket ? <strong>{`${t('Validity')}: `}</strong> : <strong>{`${t('Time')}: `}</strong>}
                {formatDuration(selectedActivity?.duration || 0, locale)}
              </p>

              {!isTmeTicket && !isBookingRequest && (
                <p style={{ flexGrow: 1, minWidth: '150px' }}>
                  <strong>{this.availableVisitors.text}</strong> {this.availableVisitors.count}
                </p>
              )}
            </div>
          </div>
          <div style={{ marginBottom: '1em' }}>
            <RouteInfo route={selectedActivity?.route} />
          </div>
          {!this.widgetData.bookingRequest && <WeatherComponent />}
          {this.renderFormContent()}
        </Form>
        {this.props.children}
      </div>
    );
  }
}
