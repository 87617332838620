import React from 'react';
import { useLocale } from '../../../_locales';

interface Props {
  style?: React.CSSProperties;
}
export const Table: React.FC<Props> = ({ children, style }) => {
  const { t } = useLocale();

  return (
    <table className="ui very basic celled table" style={style}>
      <thead>
        <tr>
          <th className="five wide">{t('Status')}</th>
          <th className="three wide">{t('Customer')}</th>
          <th className="four wide">{t('Booking')}</th>
          <th className="four wide">{t('Created by')}</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
