import Lodash from 'lodash';

/** An abstract bare-minumum resource */
export abstract class Resource {
  /** The name of this resource */ public name: string;
  /** The relative path that this resource is accessed from */ public path = '';

  private restrictedToTheseRoles: string[];

  public get restrictedToAuthenticatedUsers() {
    return !!this.restrictedToTheseRoles;
  }

  public validateAccess(user, ...roles: string[]) {
    if (this.restrictedToAuthenticatedUsers && !user) return false;

    const diff = Lodash.difference(this.restrictedToTheseRoles, roles);

    // Return true if any supplied role was in this resource "restrictedToTheseRoles" list, or the list was empty
    // to begin with
    return diff.length == 0 || diff.length < this.restrictedToTheseRoles.length;
  }

  constructor() {
    this.path = '/api/';
  }

  /** Sets the name of this resource */
  protected setName(name: string) {
    this.name = name.toLowerCase();
  }

  /** Makes this resource restricted to signed in users, optionally with the supplied roles */
  protected restrictAccess(...roles: string[]) {
    this.restrictedToTheseRoles = roles || [];
  }

  /** Adds a subroute to access this resource */
  protected addToPath(path: string) {
    // Adds all non-empty string to this resource's path
    path.split('/').forEach((section) => {
      if (section) {
        this.path = this.path + path + '/';
      }
    });
  }

  /** Sends a request for retriving a resource */
  protected sendRequest<V>(
    endpoint: string,
    method: 'get' | 'post' | 'delete' | 'put',
    data: { [key: string]: any },
  ): Promise<V> {
    // Ignore serverside // TODO: is this safe?
    if (MODULE_ENVIRONMENT != 'browser') {
      return Promise.resolve(undefined as any);
    }

    // Jsonify data body if neccessary
    if (method != 'get') {
      data = JSON.stringify(data) as any;
    }

    // Build the final url target, check if request is made from outside of the platform. (etc in an embedded ui module)
    // and appends a domain if neccessary
    const target = MODULE_PUBLIC_URL + this.path + this.name + endpoint;

    return new Promise<V>((resolve, reject) => {
      $.ajax({
        crossDomain: true,
        url: target,
        method: method,
        dataType: 'json',
        contentType: 'application/json',
        data: data,
        success: (data) => resolve(data),
        error: (error, errorMessage, statusText) => {
          // Handle empty responses
          if (
            error.status == 200 &&
            errorMessage == 'parsererror' &&
            (error.responseText === undefined || !error.responseText.length)
          ) {
            return resolve(undefined as any);
          }
          // Reject errors
          if (error.responseJSON) {
            const rejectReason = Lodash.merge(new Error(errorMessage), error.responseJSON);
            return reject(rejectReason);
          }
          if (error.responseText) {
            return reject(new Error(error.responseText));
          }
          return reject(new Error(errorMessage));
        },
      });
    }); // TODO: error handling?
  }
}
