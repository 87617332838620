import moment from 'moment';
import React from 'react';
import { BookingDocument, Button, InventoryTransferDocument } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';
interface Props {
  transfers?: InventoryTransferDocument[];
}

export const PrintTransfersButton = ({ transfers }: Props) => {
  const { t, tt } = useLocale();

  const print = () => {
    if (transfers) {
      let a = window.open('', '_blank');
      a?.document.write('<body><html>');
      a?.document.write(
        `<div style="padding-top: 1em; width:100vw; transform: translateY(-20px); font-family: Arial; ">`,
      );
      a?.document.write('<table style="width: 100%; border-collapse: collapse; font-size: 0.6em; margin: auto;">');
      a?.document.write(
        `<thead">
            <tr style="border: black 1px solid;">
              <td style="padding: 0.5em;"><b>${t('Customer')}</b></td>
              <td style="padding: 0.5em;"><b>${t('Booking')}</b></td>
              <td style="padding: 0.5em;"><b>${t('Bags')}</b></td>
              <td style="padding: 0.5em;"><b>${t('Date')}</b></td>
              <td style="padding: 0.5em;"><b>${t('From')}</b></td>
              <td style="padding: 0.5em;"><b>${t('To')}</b></td>
              <td style="padding: 0.5em;"><b>${t('Personnel')}</td>
            </tr>
        </thead>`,
      );
      a?.document.write('<tbody>');
      transfers.forEach((transfer, i) => {
        a?.document.write(`
        <tr style="border: black 1px solid;">
          <td style="border: 1px solid;">
          ${`${(transfer.bookingId as unknown as BookingDocument).customer.lastname}, ${
            (transfer.bookingId as unknown as BookingDocument).customer.firstname
          }`}
          </td>
          <td style="border: 1px solid; padding: 0.5em;">${(transfer.bookingId as any).number}</td>
          <td style="border: 1px solid; padding: 0.5em;">${transfer.entities.length}</td>
          <td style="border: 1px solid; padding: 0.5em;">${moment(transfer.date).format('DD-MM-YY')}</td>
          <td style="border: 1px solid; padding: 0.5em;">${tt(transfer.from.name)}</td>
          <td style="border: 1px solid; padding: 0.5em;">${tt(transfer.to.name)}</td>
          <td style="border: 1px solid; padding: 0.5em;">${transfer.assignedPickupers.map((p) => p.firstname)}</td>
        </tr>
      `);
      });
      a?.document.write('</tbody>');
      a?.document.write('</table>');
      a?.document.write('</div>');
      a?.document.write('</body></html>');
      a?.document.close();
      a?.print();
      a?.close();
    }
  };

  return (
    <Button disabled={!transfers} className={'primary no-print'} onClick={() => print()}>
      <i className="print icon" />
      {t('Print List')}
    </Button>
  );
};
