import { minBy } from 'lodash';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { ScrollHelper } from '../../../components/scrollHelper';
import {
  PurchaseWidget,
  Consume,
  DeviceProvider,
  IDevice,
  Link,
  PropertyResource,
  PropertyDocument,
  ActivityTypeResource,
  ActivityTypeDocument,
  LocaleContext,
  getTickets,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { CategoryIcons } from './categoryBookingPage';

interface State {
  activityType?: ActivityTypeDocument;
  property?: PropertyDocument;
}

interface Params {
  propertyId: string;
  activitytypeId: string;
}

export class ActivitiesBookingPage extends Component<RouteComponentProps<Params>> {
  state: State = {};

  @Consume(DeviceProvider.Context)
  private device: IDevice;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  async componentDidMount() {
    ScrollHelper.scrollToTop();
    await this.fetchActivityTypeDocument();
    await this.fetchPropertyDocument();
  }

  async fetchActivityTypeDocument() {
    const { activitytypeId } = this.props.match.params;
    const activityType = await new ActivityTypeResource().get(activitytypeId);
    this.setState({ activityType });
  }

  async fetchPropertyDocument() {
    const { propertyId } = this.props.match.params;
    const property = await new PropertyResource().get(propertyId);
    this.setState({ property });
  }

  private get fromPrice() {
    const { t } = this._locale;
    const { activityType } = this.state;
    if (!activityType) return null;

    // Get the ticket categories only
    const ticketCategories = getTickets(activityType.priceCategories);

    // Select the least pricy value from price categories
    let price = minBy(ticketCategories.map(({ price }) => price)) || 0;

    // add the baseprice to the value
    price += activityType.basePrice;

    return (
      <span>
        {t('From')} {price} {t('kr-SEK')}
      </span>
    );
  }

  render() {
    const { size } = this.device;
    const { activityType, property } = this.state;
    const { tt } = this._locale;
    if (!activityType || !property) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="ui active inline centered large loader"></div>
        </div>
      );
    }

    return (
      <div style={{ position: 'relative', flex: 1 }}>
        <div
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1)),
          url(${activityType.imageUrl})`,
            filter: 'blur(12px)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
            height: '60vh',
          }}
        ></div>
        <div className="ui container" style={{ marginTop: '-20vh' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: size == 'desktop' ? 'row' : 'column',
            }}
          >
            <div
              className="ui segment"
              style={{
                flexGrow: 1,
                padding: '2rem',
                maxWidth: size === 'desktop' ? '40rem' : undefined,
                minHeight: '30vh',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h1 className="ui big header">{tt(activityType.title)}</h1>
              <div className="ui sub header" style={{ marginTop: 0 }}>
                <Link to={`/properties/details/${property.id}`}>{tt(property.name)}</Link>
              </div>
              <div className="ui divider" />
              <p style={{ flex: 1 }}>{tt(activityType.description)}</p>
              <p style={{ color: 'grey' }}>{this.fromPrice}</p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexGrow: 1,
              }}
            >
              <div
                style={{
                  margin: '1rem 0',
                  display: 'flex',
                  width: '100%',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <CategoryIcons availableCategories={activityType.categoryTags} />
              </div>
            </div>
          </div>
          <div style={{ margin: '4rem 0' }}>
            <PurchaseWidget fixedOffer={activityType} propertyId={property.id} />
          </div>
        </div>
      </div>
    );
  }
}
