import { action, autorun, IReactionDisposer, observable, runInAction } from 'mobx';
import Moment from 'moment-timezone';
import * as React from 'react';
import {
  Alert,
  ConfirmationButton,
  Consume,
  DefaultPredicate,
  GiftCardDocument,
  GiftCardPreview,
  GiftCardResource,
  LocaleContext,
  MailResource,
  MobxComponent,
  ObservingComponent,
  SearchableList,
  SearchableListStore,
  SearchListBar,
  UserDocument,
} from '../../../_dependencies';
import { AHLocaleContext, useLocale } from '../../../_locales';
import { CopyToClipboardButton } from '../../_copyToClipboardButton';
import { DeleteButton } from '../../deleteButton';
import { Maybe } from '../../maybe';
import { SoldGiftCardItem, SoldGiftCardsStore } from './soldGiftCardsStore';

export class ListOfSoldGiftCards extends ObservingComponent<
  { addTabHandler: any },
  SearchableListStore<GiftCardDocument>
> {
  @observable private _loading = true;
  private _autorunDisposer?: IReactionDisposer;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  componentDefaultStore() {
    const store = new SearchableListStore<GiftCardDocument>();
    const { tt } = this._locale;

    new GiftCardResource()
      .findWithPopulatedCreatedBy({
        'limitations.organizations': this.globals.session.currentOrganizationId,
        voucher: { $exists: false },
      })
      .then((results) => {
        runInAction(() => {
          store.predicate = (text, item: GiftCardDocument) => {
            return DefaultPredicate(
              text,
              tt(item.title) +
                item.customer.firstname +
                item.customer.lastname +
                item.customer.email +
                item.value +
                item.number +
                item.expirationDate.toString() +
                (item.createdBy ? (item.createdBy as any as UserDocument).username.toString() : ''),
            );
          };
          store.sortByFields = ['createdAt'];
          store.reverseSort = true;
          SoldGiftCardsStore.loadGiftCards(results);
          this._autorunDisposer = autorun(() => {
            this.store.items = SoldGiftCardsStore.giftCards;
          });
          this._loading = false;
        });
      })
      .catch((err) => {
        console.error(err);
        this._loading = false;
      });

    return store;
  }

  componentWillUnmount() {
    if (this._autorunDisposer) {
      this._autorunDisposer();
    }
  }

  render() {
    const { t } = this._locale;
    return (
      <div style={{ padding: '2em' }}>
        <h2 className="ui header">
          {t('Sold giftcards')}
          <div className="sub header">{t('This page gives you an overview of giftcards sold...')}</div>
        </h2>
        <div className="ui large transparent fluid left icon input">
          <i className="search icon" />
          <SearchListBar store={this.store} placeholder={t('Search for giftcard numbers, customers or card names')} />
        </div>
        <div className="ui divider" style={{ marginBottom: '2em' }} />
        <SearchableList
          className="ui five doubling link cards"
          template={GiftCardItem}
          store={this.store}
          wrapper={GiftCardTable}
        />
        {this._loading ? <div className="large ui inline centered active loader" /> : undefined}
      </div>
    );
  }
}

const GiftCardTable = (props: { children: any }) => {
  const { t } = useLocale();
  return (
    <table className="ui very basic celled table">
      <thead>
        <tr>
          <th className="five wide">{t('Status')}</th>
          <th className="five wide">{t('Customer')}</th>
          <th className="six wide" colSpan={2}>
            {t('Giftcard')}
          </th>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  );
};

class GiftCardItem extends MobxComponent<{ item: GiftCardDocument }, { soldGiftCardItem?: SoldGiftCardItem }> {
  @observable private _deleting: boolean;
  @observable private _updating: boolean;
  @observable private _sendingMail: boolean;
  @observable private _editing: boolean;

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  public state: { soldGiftCardItem?: SoldGiftCardItem } = {};

  componentDidMount() {
    this.updateSoldItemStatus();
  }

  /** Fetches and calculates payment amount and other useful information */
  @action
  private async updateSoldItemStatus() {
    this._updating = true;
    const soldGiftCardItem = await SoldGiftCardsStore.getGiftCardInformation(this.props.item.id);
    this.setState({
      soldGiftCardItem,
    });
    this._updating = false;
  }

  private get paidAmount() {
    return this.state.soldGiftCardItem?.paidAmount;
  }

  private get usableValue() {
    return this.state.soldGiftCardItem?.usableValue;
  }

  private get isUsable() {
    return this.state.soldGiftCardItem?.isUsable;
  }

  private get isPayedFor() {
    return this.paidAmount && this.paidAmount >= (this.props.item.value || 0);
  }

  private get isExpired() {
    return this.state.soldGiftCardItem?.isExpired;
  }

  @action private handleOnMarkAsPaid = () => {
    const document = this.props.item;
    document.manuallyPaid = true;
    this._updating = true;
    new GiftCardResource().updateDocument(document).then(() => {
      this.updateSoldItemStatus();
    });
  };

  @action private handleOnMarkAsUsed = () => {
    const document = this.props.item;
    document.usesLeft = 0;
    this._updating = true;
    new GiftCardResource().updateDocument(document).then(() => {
      runInAction(() => {
        this.updateSoldItemStatus();
        this._editing = false;
      });
    });
  };

  @action private handleOnDelete = () => {
    const { t } = this._locale;
    this._deleting = true;
    new GiftCardResource()
      .delete(this.props.item.id)
      .then(() => {
        runInAction(() => {
          SoldGiftCardsStore.removeLoadedGiftCard(this.props.item.id);
          this._deleting = false;
          Alert.show(t('Giftcard deleted'), t('message-done'), 'success');
        });
      })
      .catch(() => Alert.show(t('Something went wrong when removing the giftcard'), t('Oops..'), 'error'));
  };

  @action private onSendConfirmationMailButtonClicked = async () => {
    const { t } = this._locale;
    this._sendingMail = true;
    await new MailResource()
      .sendGiftCardConfirmationEmail(this.props.item.id)
      .then(() => Alert.show(t('Giftcard was sent to customers email'), t('Sent!'), 'success'))
      .catch(() =>
        Alert.show(t('Unfortunately it was not possible to send the e-mail...'), t('Something went wrong'), 'error'),
      );

    runInAction(() => {
      this._sendingMail = false;
    });
  };

  private get paymentStatusMessage() {
    const { t } = this._locale;
    let message: string;
    let color: string;

    if (typeof this.paidAmount === 'undefined') {
      return <div className={'ui active tiny inline loader'} />;
    } else if (this.props.item.manuallyPaid) {
      message = t('Handled manually');
      color = 'yellow';
    } else if (this.isPayedFor) {
      message = t('Paid online');
      color = 'green';
    } else {
      message = t('Not paid');
      color = 'red';
    }

    return (
      <span className={`ui large ${color} basic label`} style={{ borderWidth: 0, padding: 0 }}>
        {message}
      </span>
    );
  }

  private get addressListItem() {
    const { address } = this.props.item.customer;
    if (address && !address.isEmpty) {
      const altAddress = address.row2 ? <span>alt: {address.row2}</span> : undefined;
      return (
        <div className="item">
          <i className="map outline icon" />
          <div className="content">
            <p>
              <span>{address.row1}</span>
              <br />
              {altAddress}
              <span>{address.postNr + ' ' + address.postOrt}</span>
            </p>
          </div>
        </div>
      );
    }
  }

  private get buttonConfirmationText() {
    const { t } = this._locale;
    return t('Are you sure?');
  }

  private get paymentAndRemoveButtons() {
    if (this.props.item.manuallyPaid || typeof this.paidAmount === 'undefined' || this.isPayedFor) {
      return undefined;
    }
    const { t } = this._locale;

    return (
      <div>
        <ConfirmationButton
          loading={this._deleting || this._updating}
          disabled={this._deleting || this._updating}
          timeout={2000}
          className="basic fluid"
          label={
            <div>
              <i className="checkmark icon" />
              {this.props.children || t('Mark as paid')}
            </div>
          }
          activeButton={
            <button
              type="button"
              className={
                'ui fluid ' +
                (this._updating ? 'loading disabled ' : '') +
                (this._deleting ? 'disabled ' : '') +
                'button'
              }
              onClick={this.handleOnMarkAsPaid}
            >
              {this.buttonConfirmationText}
            </button>
          }
        />
        <div style={{ height: '1em' }} />
        <DeleteButton
          disabled={this._updating}
          deleting={this._deleting}
          onConfirmed={this.handleOnDelete}
          confirmationText={t('Are you sure?')}
        >
          {t('Remove giftcard')}
        </DeleteButton>
        <div style={{ height: '1em' }} />
      </div>
    );
  }

  private get markAsUsedButton() {
    const { t } = this._locale;

    if (!this.isUsable) {
      return undefined;
    }

    return (
      <div style={{ paddingBottom: '1em' }}>
        <ConfirmationButton
          loading={this._deleting || this._updating}
          disabled={this._deleting || this._updating}
          timeout={2000}
          className="basic fluid"
          label={
            <div>
              <i className="checkmark icon" />
              {this.props.children || t('Mark as used')}
            </div>
          }
          activeButton={
            <button
              type="button"
              className={
                'ui fluid ' +
                (this._updating ? 'loading disabled ' : '') +
                (this._deleting ? 'disabled ' : '') +
                'button'
              }
              onClick={this.handleOnMarkAsUsed}
            >
              {this.buttonConfirmationText}
            </button>
          }
        />
      </div>
    );
  }

  private get sendConfirmationMailButton() {
    const { t } = this._locale;
    return (
      <button
        className={'ui fluid basic ' + (this._sendingMail ? 'loading disabled' : '') + ' button'}
        onClick={this.onSendConfirmationMailButtonClicked}
        style={{ marginBottom: '1em' }}
      >
        <i className="ui send icon" /> {t('Send a giftcard to customer')}
      </button>
    );
  }

  private get editArea() {
    if (!this._editing) {
      return undefined;
    }

    return (
      <div>
        {this.sendConfirmationMailButton}
        {this.markAsUsedButton}
        {this.paymentAndRemoveButtons}
      </div>
    );
  }

  private get editButton() {
    const { t } = this._locale;

    return (
      <Maybe if="organization.manager">
        <button
          className="ui basic fluid button"
          onClick={() => (this._editing = !this._editing)}
          style={{ minWidth: '100px' }}
        >
          {this._editing ? t('Cancel') : t('Show options')}
        </button>
      </Maybe>
    );
  }

  private get currentStatus() {
    const { t } = this._locale;

    if (this.isExpired) {
      return (
        <span>
          <i className="ui close icon" />
          <b>{t('Expired')}</b>
        </span>
      );
    } else if (!this.isUsable) {
      return (
        <span>
          <i className="ui calendar check icon" />
          <b>{t('Consumed')}</b>
        </span>
      );
    } else {
      return (
        <span>
          <i className="ui checkmark icon" />
          <b>{t('Valid')}</b>
        </span>
      );
    }
  }

  render() {
    const { t } = this._locale;
    const giftCard = this.props.item;
    const sellerItem = giftCard.createdBy ? (
      <div className="item">
        <i className="user icon" />
        <div className="content">
          <strong>{t('Sold by:')}</strong> {(giftCard.createdBy as any as UserDocument).username}
        </div>
      </div>
    ) : undefined;

    return (
      <tr>
        <td>
          <div className="ui list">
            <div className="item">
              <i className="bookmark icon" />
              <div className="content">
                <strong>
                  {t('Card number')}: {giftCard.number}
                </strong>
              </div>
            </div>
            <div className="item">
              <i className="flag outline icon" />
              <div className="content">Status: {this.currentStatus}</div>
            </div>
            <div className="item">
              <i className="money bill alternate outline icon" />
              <div className="content">
                {t('Current card value')}: {this.usableValue} kr{' '}
              </div>
            </div>
            <div className="item">
              <i className="credit card icon" />
              <div className="content">
                {`${t('Payment status')}: `} {this.paymentStatusMessage}
              </div>
            </div>
            <div className="item">
              <i className="wait icon" />
              <div className="content">
                {t('Expiration date')} {Moment(giftCard.expirationDate).format('YYYY-MM-DD')}
              </div>
            </div>

            {sellerItem}

            <div className="item">
              <div style={{ height: '1em' }} /> {/* Some space... */}
            </div>
            <div className="item">
              {this.editArea}
              {this.editButton}
            </div>
          </div>
          {/*//TODO: ANVÄNDNINGSSTATUS, HAR KORTET GÅTT UT? ANVÄNDS? VART I SÅ FALL, bör finnas länk till bokning imo ?*/}
        </td>
        <td>
          <div className="ui list">
            <div className="item">
              <i className="male icon" />
              <div className="content">
                {giftCard.customer.firstname} {giftCard.customer.lastname}
              </div>
            </div>
            <div className="item">
              <i className="at icon" />
              <div className="content">{giftCard.customer.email}</div>
            </div>
            <div className="item">
              <i className="call icon" />
              <div className="content">{giftCard.customer.phoneNr}</div>
            </div>
            {this.addressListItem}
          </div>
        </td>
        <td colSpan={2}>
          <GiftCardPreview giftCard={giftCard} hideValue={false} className="ui rounded image" />
          <div style={{ height: '0.5em' }} />
          <CopyToClipboardButton
            showInputField
            textToCopy={
              (window ? window.location.origin : '') + '/api/giftcardimage/preview/' + giftCard.number + '.jpg'
            }
          />
          {/* // TODO: everything with 'window' should be moved to a store, like session or device */}
        </td>
      </tr>
    );
  }
}
