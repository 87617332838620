import { findIndex } from 'lodash';
import * as React from 'react';
import { MobxComponent } from '../../../_dependencies';
import Store from './store';

export enum ErrorType {
  EmailInvalid,
  LicenseAgreement,
  Other,
  CredentialsInvalid,
  None,
}

export interface IError {
  type: ErrorType;
  message: string;
}

export const NoError: IError = { type: ErrorType.None, message: '' };

export class ErrorMessage extends MobxComponent<{ listenTo: ErrorType }, any> {
  conditionsMet(): IError | null {
    const errors = Store.modalErrors as IError[];
    const { listenTo } = this.props;
    const index = findIndex(errors, (error) => error.type == listenTo);

    return index !== -1 ? errors[index] : null;
  }
  updateParentField(instance: HTMLElement | null): void {
    if (instance && instance.parentElement) {
      const parent = instance.parentElement as HTMLElement;
      if (parent.classList.contains('field') && !parent.classList.contains('error')) {
        parent.classList.add('error');
      }
    }
  }
  render(): JSX.Element | null {
    const error = this.conditionsMet();
    if (error === null) return null;

    return (
      <div
        ref={this.updateParentField}
        className="ui basic center label pointing transition visible"
        style={{ color: '#9f3a38', borderColor: '#e0b4b4' }}
      >
        {error.message}
      </div>
    );
  }
}
