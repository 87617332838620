import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { IWidgetData } from '../..';
import {
  AccessoryDocument,
  ActivityOccuranceDocument,
  BookingPriceCategoryDocument,
  PriceCategoryDocument,
  findAndReturnClientBelongingPriceCategories,
  getBasePrice,
  getRescheduleInsurance,
  priceCategoriesHasClientBelongingPriceCategory,
  visitors,
} from '../../_dependencies';
import {
  AccessoryDropdowns,
  AdminSettingsBox,
  CustomTotalPriceCheckbox,
  HandleClientBelongingsDropdowns,
  IncludeBasePriceCheckbox,
  MinVisitorLabel,
  PriceCategoryDropdowns,
  PriceDetails,
  RescheduleInsuranceField,
  SetVisitorsFreelyCheckbox,
} from './components';
import { combinePriceCategories, getPriceCategoriesWithTotalPrice } from './selectPriceCategoryHelperFunctions';

interface Props {
  occurance: ActivityOccuranceDocument;
  bookedPriceCategories?: BookingPriceCategoryDocument[];
  onChange: (selectedPriceCategories: BookingPriceCategoryDocument[]) => void;
  isBookingRequest?: boolean;
  widget?: IWidgetData;
  alreadyReservedVisitors?: number;
  isTmeTicket?: boolean;
}

export const SelectPriceCategoriesFields = ({
  occurance,
  bookedPriceCategories,
  isBookingRequest,
  onChange,
  widget,
  alreadyReservedVisitors,
  isTmeTicket,
}: Props) => {
  const [selectedPriceCategories, setSelectedPriceCategories] = useState<BookingPriceCategoryDocument[]>([]);
  const [setVisitorsFreely, setSetVisitorsFreely] = useState(false);
  const [useCustomPrice, setUseCustomPrice] = useState(
    Boolean(bookedPriceCategories?.find(({ isCustom }) => isCustom)),
  );
  const [includeBasePrice, setIncludeBasePrice] = useState(
    Boolean(!bookedPriceCategories || getBasePrice(bookedPriceCategories)),
  );
  const [includeRescheduleInsurance, setIncludeRescheduleInsurance] = useState(
    Boolean(getRescheduleInsurance(bookedPriceCategories)),
  );

  const visitorsFreeToggleValue = widget ? widget.allowBookingVisitorsFreely : setVisitorsFreely;

  const domNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { priceCategories, availableVisitors } = occurance;
    let allCategories = combinePriceCategories(priceCategories, bookedPriceCategories);

    allCategories.forEach((priceCategory) => {
      let defaultValue: number;
      if (bookedPriceCategories) {
        defaultValue = priceCategory.tickets || 0;
      } else {
        // If only one category and one available slot can be booked, select it automagicaly
        defaultValue = priceCategories.length == 1 && availableVisitors == 1 ? 1 : 0;
      }

      if (priceCategory.basePrice && includeBasePrice) {
        setSelectedPriceCategories((prev) => {
          const categories = prev.filter(({ basePrice }) => !basePrice);
          categories.push({ ...priceCategory, tickets: 0 });
          return categories;
        });
      } else {
        handleDropdownChange(defaultValue, priceCategory, false);
      }
    });
  }, [occurance, occurance.priceCategories, bookedPriceCategories]);

  useEffect(() => {
    onChange(getPriceCategoriesWithTotalPrice(selectedPriceCategories));
  }, [selectedPriceCategories]);

  useEffect(() => {
    return () => {
      tryHideValidationMessage();
    };
  }, []);

  const tryHideValidationMessage = () => {
    // This hidden input field is located directly below the price category dropdowns
    if (!domNode.current) return;
    $(domNode.current).find('[name="hiddenInput"]').focusout();
  };

  const handleDropdownChange = (
    tickets: number,
    priceCategory: PriceCategoryDocument | BookingPriceCategoryDocument,
    shouldHideValidationMessage = true,
  ) => {
    setSelectedPriceCategories((prev) => {
      // Custom caterogies needs to be handled in a sperate way since it will
      // never match previous prices/groupMultipler and therefor create new objects.
      if ('isCustom' in priceCategory && priceCategory.isCustom) {
        const categories = prev.filter(({ name }) => !isEqual(name, priceCategory.name));
        categories.push({
          ...priceCategory,
          price: priceCategory.price,
          tickets,
        });
        if (shouldHideValidationMessage) {
          tryHideValidationMessage();
        }
        return categories;
      } else {
        // Remove the matching category and add a new one in it's place
        const categories = prev.filter(
          ({ name, price, groupMultiplier }) =>
            !isEqual(name, priceCategory.name) ||
            (isEqual(name, priceCategory.name) && price !== priceCategory.price) ||
            (isEqual(name, priceCategory.name) && groupMultiplier !== priceCategory.groupMultiplier),
        );
        if (tickets) {
          categories.push({
            ...priceCategory,
            price: priceCategory.price,
            tickets,
          });
        }
        if (shouldHideValidationMessage) {
          tryHideValidationMessage();
        }

        updateRescheduleInsurancePaidForField(categories);

        return categories;
      }
    });
  };

  const updateRescheduleInsurancePaidForField = (categories: BookingPriceCategoryDocument[]) => {
    // Only set personsPaidFor when creating a booking, not when editing
    if (!bookedPriceCategories) {
      let rescheduleCategory = getRescheduleInsurance(categories);
      if (rescheduleCategory?.rescheduleInsurance.isFeePerPerson) {
        rescheduleCategory.rescheduleInsurance.personsPaidFor = categories.reduce((sum, pc) => sum + visitors(pc), 0);
      }
    }
  };

  const toggleSetVisitorsFreely = () => {
    if (!widget) localSetVisitorsFreely();
    externalSetVisitorsFreely();
  };

  const localSetVisitorsFreely = () => {
    if (setVisitorsFreely) {
      // set all categories to zero visitors to avoid user errors
      occurance.priceCategories
        .filter(({ basePrice }) => !basePrice)
        .forEach((pc) => handleDropdownChange(0, pc, false));
    }
    setSetVisitorsFreely((prev) => !prev);
  };

  const externalSetVisitorsFreely = () => {
    if (!widget) return;
    if (widget.allowBookingVisitorsFreely) {
      // set all categories to zero visitors to avoid user errors
      occurance.priceCategories
        .filter(({ basePrice }) => !basePrice)
        .forEach((pc) => handleDropdownChange(0, pc, false));
    }
    widget.setState({ allowBookingVisitorsFreely: !widget.allowBookingVisitorsFreely });
  };

  const toggleUseCustomPrice = () => {
    setSelectedPriceCategories([]);
    if (useCustomPrice && includeBasePrice) {
      addBasePrice();
    }
    setUseCustomPrice((prev) => !prev);
  };

  const addBasePrice = () => {
    const basePriceCategory = getBasePrice(occurance.priceCategories);
    const selectedBasePriceCategory = getBasePrice(selectedPriceCategories);
    if (basePriceCategory && !selectedBasePriceCategory) {
      setSelectedPriceCategories((prev) => [...prev, { ...basePriceCategory, tickets: 0 }]);
    }
  };

  const removeBasePrice = () => {
    const categories = selectedPriceCategories.filter(({ basePrice }) => !basePrice);
    setSelectedPriceCategories(categories);
  };

  const toggleIncludeBasePrice = () => {
    includeBasePrice ? removeBasePrice() : addBasePrice();
    setIncludeBasePrice((prev) => !prev);
  };

  const addInsurance = () => {
    const rescheduleInsurance = getRescheduleInsurance(occurance.priceCategories);
    const selectedRescheduleInsurance = getRescheduleInsurance(selectedPriceCategories);

    if (rescheduleInsurance && !selectedRescheduleInsurance) {
      setSelectedPriceCategories((prev) => {
        const categories = [...prev, { ...rescheduleInsurance, tickets: 1 }];
        updateRescheduleInsurancePaidForField(categories);
        return categories;
      });
    }
  };

  const removeInsurance = () => {
    const categories = selectedPriceCategories.filter(
      (category: AccessoryDocument<BookingPriceCategoryDocument>) => !category.rescheduleInsurance,
    );
    setSelectedPriceCategories(categories);
  };

  const toggleIncludeRescheduleInsurance = () => {
    includeRescheduleInsurance ? removeInsurance() : addInsurance();
    setIncludeRescheduleInsurance((prev) => !prev);
  };

  return (
    <div ref={domNode}>
      <PriceCategoryDropdowns
        disabled={useCustomPrice}
        occurance={occurance}
        selectedPriceCategories={selectedPriceCategories}
        bookedPriceCategories={bookedPriceCategories}
        alreadyReservedVisitors={alreadyReservedVisitors}
        setVisitorsFreely={visitorsFreeToggleValue}
        onChange={handleDropdownChange}
        isTmeTicket={isTmeTicket}
        isBookingRequest={isBookingRequest}
      />
      <AccessoryDropdowns
        disabled={useCustomPrice}
        occurance={occurance}
        selectedPriceCategories={selectedPriceCategories}
        bookedPriceCategories={bookedPriceCategories}
        alreadyReservedVisitors={alreadyReservedVisitors}
        setVisitorsFreely={visitorsFreeToggleValue}
        onChange={handleDropdownChange}
      />
      {priceCategoriesHasClientBelongingPriceCategory(occurance.priceCategories) && (
        <HandleClientBelongingsDropdowns
          disabled={useCustomPrice}
          selectedPriceCategories={selectedPriceCategories}
          handleClientBelongingsPriceCategories={findAndReturnClientBelongingPriceCategories(occurance.priceCategories)}
          onChange={handleDropdownChange}
        />
      )}
      {!isBookingRequest && (
        <>
          <MinVisitorLabel occurance={occurance} />{' '}
          <AdminSettingsBox occurance={occurance}>
            <SetVisitorsFreelyCheckbox
              disabled={useCustomPrice}
              checked={visitorsFreeToggleValue}
              onToggle={toggleSetVisitorsFreely}
            />
            <IncludeBasePriceCheckbox
              hide={isBookingRequest}
              disabled={useCustomPrice}
              checked={includeBasePrice}
              onToggle={toggleIncludeBasePrice}
              priceCategories={occurance.priceCategories}
            />
            <CustomTotalPriceCheckbox
              hide={isBookingRequest}
              checked={useCustomPrice}
              onToggle={toggleUseCustomPrice}
            />
          </AdminSettingsBox>
          <RescheduleInsuranceField
            checked={includeRescheduleInsurance}
            onToggle={toggleIncludeRescheduleInsurance}
            occurance={occurance}
          />
        </>
      )}

      <div className="ui divider" />

      <PriceDetails
        useCustomPrice={useCustomPrice}
        selectedPriceCategories={selectedPriceCategories}
        defaultPriceCategories={bookedPriceCategories}
        onCustomCategoryChange={(category) => handleDropdownChange(category.tickets, category)}
      />
    </div>
  );
};
