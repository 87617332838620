import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  Field,
  getGiftCardMailDefaultMessage,
  getGiftCardMailExtra,
  getGiftCardTitle,
  GiftCardImageResource,
  GiftCardPreview,
  GiftCardTypeDocument,
  GiftCardTypeResource,
  GlobalsContext,
  ImageUploadForm,
  Input,
  LimitationsDocument,
  LocaleContext,
  Platforms,
  Rules,
  SubmitButton,
  TaxRate,
  TranslatableText,
  TranslatableTextField,
} from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';
import { DeleteButton } from '../../deleteButton';

interface Props {
  giftCard: GiftCardTypeDocument;
  finishedEditing?: Function;
  onDeleteItem?: Function;
}

export const GiftCardForm = ({ giftCard, finishedEditing, onDeleteItem }: Props) => {
  const [currentImageURL, setCurrentImageURL] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedTaxRate, setSelectedTaxRate] = useState<TaxRate>();
  const [imageUploadForm, setImageUploadForm] = useState<ImageUploadForm>();
  const [tempGiftCard, setTempGiftcard] = useState<GiftCardTypeDocument>(cloneDeep(giftCard));
  const resource = new GiftCardTypeResource();
  const { t, locale } = useContext<AHLocaleContext>(LocaleContext);
  const globals = useContext(GlobalsContext);

  const getSelectedTaxRate = () => {
    if (typeof selectedTaxRate === 'number') {
      return selectedTaxRate;
    }
    if (typeof giftCard.taxRate === 'number') {
      return giftCard.taxRate;
    }
    return Platforms.taxRates[0];
  };

  useEffect(() => {
    setCurrentImageURL(isEdit() && giftCard.imageUrl ? giftCard.imageUrl : '/static/platform/img/default_giftcard.png');
  }, []);

  useEffect(() => {
    setImageUploadForm(imageUploadForm);
  }, [imageUploadForm]);

  const isEdit = () => {
    return !!giftCard && !giftCard.isNew;
  };

  const onSubmit = (imageId: string | undefined) => {
    // TODO: if new image then remove old image from database!!
    // Save the image if we have a new one
    if (currentImageURL != giftCard.imageUrl) {
      giftCard.imageId = imageId || '';

      giftCard.imageUrl = giftCard.imageId
        ? new GiftCardImageResource().fileUrl(giftCard.imageId, 'jpg')
        : '/static/platform/img/default_giftcard.png';
    }

    if (tempGiftCard) {
      giftCard.title = tempGiftCard.title;
      giftCard.value = tempGiftCard.value;
      giftCard.validityDurationInDays = tempGiftCard.validityDurationInDays;
      giftCard.mailMessage = tempGiftCard.mailMessage;
      giftCard.taxRate = getSelectedTaxRate();
      giftCard.isNew = false;
      giftCard.limitations = { organizations: [] as any[] } as LimitationsDocument;
      giftCard.limitations.organizations.push(globals.session.currentOrganizationId);
      giftCard.taxRate = getSelectedTaxRate();
    }

    resource
      .updateDocument(giftCard)
      .then(() => {
        if (finishedEditing) {
          finishedEditing();
        }
        Alert.show(
          t('components.dashboard.giftCards.form.updateDocumentAlertMessage'),
          t('components.dashboard.giftCards.form.updateDocumentAlertTitle'),
          'success',
        );
      })
      .catch((err) => {
        Alert.show(
          t('We were not able to save your changes...'),
          t('components.dashboard.giftCards.form.failedUpdateDocumentAlertTitle'),
          'error',
        );
      });
  };

  const handleOnDeleteProperty = async () => {
    if (onDeleteItem) {
      setIsDeleting(true);
      onDeleteItem(giftCard);
      setIsDeleting(false);
    }
  };

  const taxRates = (
    <Field label={t('VAT-Rate')}>
      <div className="ui buttons">
        {Platforms.taxRates.map((rate: TaxRate, index: number) => (
          <div
            key={`available-taxrate-${index}`}
            className={'ui button ' + (getSelectedTaxRate() == rate ? 'primary' : '')}
            onClick={() => setSelectedTaxRate(rate)}
          >
            {Math.round(rate * 100)}%
          </div>
        ))}
      </div>
    </Field>
  );

  const deleteButton = () => {
    if (onDeleteItem) {
      return (
        <div>
          <DeleteButton
            fluid
            disabled={isDeleting}
            deleting={isDeleting}
            onConfirmed={handleOnDeleteProperty}
            confirmationText={t('Are you sure?')}
          >
            {t('Remove giftcard')}
          </DeleteButton>
          <div style={{ height: '1em' }} />
        </div>
      );
    }
  };

  const extraContent = () => {
    if (imageUploadForm && imageUploadForm._currentStep == 'select') {
      return (
        <div>
          <p style={{ marginTop: '1em', marginBottom: '1em' }}>
            <b>{t('components.dashboard.giftCards.form.choosePicture')}</b>
          </p>
          <img
            className="ui bordered rounded image button"
            style={{ padding: '8px' }}
            src="/static/platform/img/default_giftcard.png"
            onClick={defaultGiftCardImageSelected}
          />
        </div>
      );
    }
    return <span />;
  };

  const defaultGiftCardImageSelected = () => {
    imageUploadForm!._currentStep = 'done';
    setCurrentImageURL('/static/platform/img/default_giftcard.png');
  };

  const onNewImageUploadStep = (step: string) => {
    if (step == 'done' && imageUploadForm && imageUploadForm.fileUrl) {
      setCurrentImageURL(imageUploadForm.fileUrl);
    }
  };

  const imageUploadHelpText = (
    <h3 className="ui header">
      {t('components.dashboard.giftCards.form.chooseGiftcardPicture')}
      <div className="sub header">{t('components.dashboard.giftCards.form.pictureSizeMessage')}</div>
    </h3>
  );

  const handleFormChange = (value: string | number | TranslatableText, string: string) => {
    const giftCardCopy = Object.assign({}, tempGiftCard);
    giftCardCopy[string] = value;
    setTempGiftcard(giftCardCopy);
  };

  return (
    <div>
      <ImageUploadForm
        onImagedSaved={onSubmit}
        width={668}
        height={302}
        resource={new GiftCardImageResource()}
        startingStep={isEdit() ? 'done' : 'select'}
        onNewStep={onNewImageUploadStep}
        helpText={imageUploadHelpText}
        ref={(self) => {
          if (!imageUploadForm) {
            setImageUploadForm(self as any);
          }
        }}
      >
        <div style={{ marginTop: '1em' }} />
        <GiftCardPreview giftCard={tempGiftCard} customImageUrl={currentImageURL} />
        <div style={{ marginTop: '2em' }} />
        <Field label={t('components.dashboard.giftCards.form.giftcardName')}>
          <TranslatableTextField
            name="Presentkortets namn"
            label={t('Description')}
            value={tempGiftCard.title}
            onChange={(value) => {
              handleFormChange(value, 'title');
            }}
            placeholder={t('Max 40 characters')}
          />
        </Field>
        <Field label={t('components.dashboard.giftCards.form.valueLabel')}>
          <Input
            name="Värde"
            value={String(tempGiftCard.value || '')}
            onChange={(value) => handleFormChange(Number(value), 'value')}
            rules={[Rules.NotEmpty(), Rules.IsAnInteger()]}
            icon="money"
            placeholder={t('components.dashboard.giftCards.form.giftcardValue')}
          />
        </Field>
        <Field label={t('components.dashboard.giftCards.form.expireDays')}>
          <Input
            name="Giltighetstid i dagar"
            value={String(tempGiftCard.validityDurationInDays || '')}
            onChange={(value) => handleFormChange(value, 'validityDurationInDays')}
            icon="checked calendar"
            rules={[Rules.NotEmpty(), Rules.IsAnInteger(), Rules.MaximumLength(5)]}
            placeholder={t('components.dashboard.giftCards.form.questionExpireDate')}
          />
        </Field>
        <Field label={t('components.dashboard.giftCards.form.showOrganization')}>
          <Checkbox
            checked={giftCard.showOrganization}
            onCheckedOrUncheked={(checked) => {
              giftCard.showOrganization = checked;
            }}
          ></Checkbox>
        </Field>
        {taxRates}
        <Field label={t('components.dashboard.giftCards.form.emailMessage')}>
          <div className="ui segment" style={{ marginTop: 0 }}>
            <h4>{getGiftCardTitle('Tindra', locale)}</h4>
            <TranslatableTextField
              textarea
              name="Välkomst email"
              value={tempGiftCard.mailMessage}
              onChange={(value) => handleFormChange(value, 'mailMessage')}
              placeholder={getGiftCardMailDefaultMessage('Adventure Hero')}
            />
            <div style={{ height: '1em' }} />
            <p>{getGiftCardMailExtra('abcde1234', locale)}</p>
            <p>{t('components.dashboard.giftCards.form.pictureGiftcard')}</p>
          </div>
        </Field>

        {/*TODO: add limitations here*/}

        <SubmitButton className="fluid green" style={{ marginTop: '1em' }}>
          {t('Save')}
        </SubmitButton>
      </ImageUploadForm>
      {extraContent()}
      {deleteButton()}
    </div>
  );
};
