import React, { FC } from 'react';
import { SubmitButton } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface Props {
  isSubmit: boolean;
  nextTabName: string;
  onClick: () => void;
}

export const OfferFormSubmitButton: FC<Props> = ({ isSubmit, nextTabName, onClick }) => {
  const { t } = useLocale();
  return (
    <div style={{ marginTop: '2em' }}>
      {isSubmit ? (
        <SubmitButton className="fluid green">{t('Save offer')}</SubmitButton>
      ) : (
        <div className="ui fluid green button" onClick={onClick}>
          {t('Save and continue to')} {nextTabName}
        </div>
      )}
    </div>
  );
};
