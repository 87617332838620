import * as React from 'react';
import { Component } from './abstractComponent';

export class Step extends Component<{
  title?: string;
  description?: string;
  icon?: string;
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
  onClick?: any;
  isMobile?: boolean;
}> {
  private renderContent() {
    const { title, description, icon, completed, isMobile } = this.props;

    let iconName = completed ? 'green ' : '';
    iconName += completed && !isMobile ? 'checkmark' : icon;
    iconName += ' icon';

    return (
      <React.Fragment>
        <i className={iconName} style={{ fontSize: '20px', margin: isMobile ? '0px' : undefined }} />
        <div className="content" style={{ fontSize: '10px' }}>
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
      </React.Fragment>
    );
  }
  render() {
    const className =
      (this.props.className ? this.props.className : '') +
      (this.props.active ? ' active' : '') +
      (this.props.disabled ? ' disabled' : '') +
      ' step';

    const padding = this.props.isMobile ? { padding: '.5rem 0' } : {};

    return (
      <a className={className} onClick={this.props.onClick} style={padding}>
        {this.renderContent()}
      </a>
    );
  }
}

export class Steps extends Component<{}> {
  render() {
    return <div className={'ui ' + this.props.className + ' steps'}>{this.props.children}</div>;
  }
}
