import React from 'react';
import ReactDOM from 'react-dom';

interface Props {
  fullscreen?: boolean;
  onHide?: () => void;
}

/**
 * Can be used as an alternative to the semantic modal.
 * This overlay does not interfere with other content.
 */
export class Overlay extends React.Component<Props> {
  private overlay: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.overlay = document.createElement('div');
    this.overlay.className = 'custom-overlay';
  }

  componentDidMount() {
    document.body.appendChild(this.overlay);
  }

  componentWillUnmount() {
    document.body.removeChild(this.overlay);
  }

  get containingElement() {
    if (this.props.fullscreen) {
      return <div style={{ height: '100%' }}>{this.props.children}</div>;
    }
    return (
      <div style={{ height: '100%', padding: '1rem' }} onClick={this.props.onHide}>
        <div className="ui raised segment" onClick={(e) => e.stopPropagation()}>
          {this.props.children}
        </div>
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(this.containingElement, this.overlay);
  }
}
