import { Schema, Types } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';

import SchemaNames from '../schemas/names';

export interface GiftCardChargeDocument extends MongooseDocument {
  giftCardId: Types.ObjectId;
  bookingId: Types.ObjectId;
  amount: number;
}

export class GiftCardChargeResource extends MongooseResource<GiftCardChargeDocument> {
  constructor() {
    super();

    this.setName(SchemaNames.GiftCardCharge);

    this.setSchema({
      giftCardId: { type: Schema.Types.ObjectId, ref: SchemaNames.GiftCard, required: true },
      bookingId: { type: Schema.Types.ObjectId, ref: SchemaNames.Booking, required: true },
      amount: { type: Number, required: true },
    });
  }
}
