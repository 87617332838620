import fetch, { RequestInit, Response } from 'node-fetch';

export const makeFetcher = (apiKey: string, mode: 'dev' | 'prod') => {
  const BASE_URL = (() => {
    switch (mode) {
      case 'dev':
        return 'http://134.209.84.27:31112';
      case 'prod':
        return 'https://marketplace.adventurehero.se';
    }
  })();
  return async (path: string, init: RequestInit = {}): Promise<Response> => {
    const headers = {
      Authorization: apiKey,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return fetch(BASE_URL + path, {
      ...init,
      headers: {
        ...(init.headers || {}),
        ...headers,
      },
    });
  };
};

export type Fetcher = ReturnType<typeof makeFetcher>;
