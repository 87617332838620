import * as React from 'react';
import { AddressDocument, Dropdown, DropdownItem, Field, Input, Rules } from '../_dependencies';
import { useLocale } from '../_locales';

interface Props {
  address?: AddressDocument;
  onAddressChanged?: (address: AddressDocument) => void;
  showAlternativeAddressField?: boolean;
  hideCountryField?: boolean;
  showLabel?: boolean;
  hideInputIcons?: boolean;
  makeAllFieldsOptional?: boolean;
}

type AddressAction =
  | { type: 'set_street'; payload: string }
  | { type: 'set_street_alt'; payload: string }
  | { type: 'set_municipality'; payload: string }
  | { type: 'set_country'; payload: string }
  | { type: 'set_zipcode'; payload: string };

const reducer: React.Reducer<AddressDocument, AddressAction> = (state, { type, payload }) => {
  switch (type) {
    case 'set_street':
      return { ...state, row1: payload };
    case 'set_street_alt':
      return { ...state, row2: payload };
    case 'set_municipality':
      return { ...state, postOrt: payload };
    case 'set_country':
      return { ...state, country: payload };
    case 'set_zipcode':
      return { ...state, postNr: payload };
    default:
      return state;
  }
};

const defaultAddress: AddressDocument = {
  row1: '',
  row2: '',
  postOrt: '',
  country: '',
  postNr: '',
};

const actions = {
  setStreet: (street: string) => ({ type: 'set_street', payload: street } as AddressAction),
  setStreetAlt: (streetAlt: string) => ({ type: 'set_street_alt', payload: streetAlt } as AddressAction),
  setMunicipality: (municipality: string) => ({ type: 'set_municipality', payload: municipality } as AddressAction),
  setZipcode: (zipcode: string) => ({ type: 'set_zipcode', payload: zipcode } as AddressAction),
  setCountry: (country: string) => ({ type: 'set_country', payload: country } as AddressAction),
} as const;

export const AddressFields = ({
  hideInputIcons,
  makeAllFieldsOptional,
  showAlternativeAddressField,
  hideCountryField,
  showLabel,
  onAddressChanged,
  address = defaultAddress,
}: Props) => {
  const { t } = useLocale();
  const [state, dispatch] = React.useReducer(reducer, address);
  const { row1: street, row2: streetAlt, postOrt: municipality, postNr: zipcode, country } = state;

  React.useEffect(() => {
    if (onAddressChanged) {
      onAddressChanged(state);
    }
  }, [state]);

  const { setStreet, setStreetAlt, setMunicipality, setZipcode, setCountry } = actions;

  const texts = {
    adress: t('Address'),
    street: t('Address'),
    streetAlt: t('components.addressFields.streetAlt'),
    zipcode: t('Zip code'),
    municipality: t('City'),
  } as const;

  const conditionalIcon = (icon: Input['props']['icon']) => {
    return hideInputIcons ? undefined : icon;
  };

  return (
    <div>
      <Field label={showLabel ? texts.street : null}>
        <Input
          name="street"
          placeholder={texts.street}
          icon={conditionalIcon('map pin')}
          value={street}
          onChange={(v) => dispatch(setStreet(v))}
          rules={[Rules.NotEmpty()]}
          optional={makeAllFieldsOptional}
        />
      </Field>
      {showAlternativeAddressField ? (
        <Field>
          <Input
            name="streetAlt"
            placeholder={texts.streetAlt}
            icon={conditionalIcon('map signs')}
            value={streetAlt}
            onChange={(v) => dispatch(setStreetAlt(v))}
            rules={[Rules.NotEmpty()]}
            optional={makeAllFieldsOptional}
          />
        </Field>
      ) : undefined}
      <div className="ui grid" style={styles.container}>
        <Field width={7} style={styles.leftColumn}>
          <Input
            name="zipcode"
            placeholder={texts.zipcode}
            icon={conditionalIcon('map pin')}
            value={zipcode}
            onChange={(v) => dispatch(setZipcode(v))}
            rules={[Rules.NotEmpty()]}
            optional={makeAllFieldsOptional}
          />
        </Field>
        <Field width={9} style={styles.rightColumn}>
          <Input
            name="municipality"
            placeholder={texts.municipality}
            icon={conditionalIcon('map pin')}
            value={municipality}
            onChange={(v) => dispatch(setMunicipality(v))}
            rules={[Rules.NotEmpty()]}
            optional={makeAllFieldsOptional}
          />
        </Field>
      </div>
      {hideCountryField ? null : (
        <Field>
          <Dropdown
            name="country"
            icon="flag"
            value={country}
            onChange={(v) => dispatch(setCountry(v))}
            rules={[Rules.NotEmpty()]}
            optional={makeAllFieldsOptional}
          >
            <DropdownItem key={'Sweden'}>Sverige</DropdownItem>
          </Dropdown>
        </Field>
      )}
    </div>
  );
};

const styles = {
  container: {
    marginTop: 0,
    marginBottom: 0,
  },
  leftColumn: {
    paddingRight: '0.5em',
  },
  rightColumn: {
    paddingLeft: '0.5em',
  },
} as const;
