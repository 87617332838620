import * as React from 'react';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import type { MemoryHistory } from 'history';

import { LegacyProvider } from '../provision/_legacyProvider';
import { DebugProvider } from '../provision/debug';
import { DeviceManager } from '../stores/deviceManager';
import { SessionManager } from '../stores/sessionManager';

import { LocaleProvider, LocaleProviderProps, UserDocument } from '../_dependencies';
import { ReactModuleMainComponent } from './_mainComponent';

/**
 * Utility method that generates a wrapped version of the given
 * component will everything needed for a react module,
 * which mostly is legacy crap
 */
export const generateReactComponent = (
  MainComponent: new (props: ReactModuleSettings, context: any) => ReactModuleMainComponent,
  settings: ReactModuleSettings,
  options: {
    routeContext?: { url: string } & any;
    history?: MemoryHistory | undefined;
    user?: UserDocument | undefined;
    onPageUpdate?: () => void;
    translations: LocaleProviderProps<any>['collection'];
    deviceSize?: [width: number, height: number, userAgent: string] | undefined;
    deviceType?: DeviceManager['type'] | undefined;
    initialLocale: Locale;
    initialNow: Date;
  },
): JSX.Element => {
  // Add Session manager
  const session = new SessionManager({
    identifier: settings.name,

    // History can not be undefined, but the provider will fix it when its constructed
    // on the client side, on the server side both serverUser and history will be passed
    history: options.history as any,
    serverUser: options.user,
  });

  // Add Device manager, and set the correct initial size and type
  const device = new DeviceManager();

  if (options.deviceSize) {
    device.set(options.deviceSize[0], options.deviceSize[1], options.deviceSize[2]);
  }

  if (options.deviceType) {
    device.type = options.deviceType;
  }

  // Make sure that the basename does not include an initial / character
  let basename = settings.path;
  basename = basename.length >= 1 ? (basename.charAt(0) == '/' ? basename.slice(1) : basename) : '';

  // Create and return the component to render
  return (
    <DebugProvider release={settings.version}>
      <LocaleProvider
        collection={options.translations}
        initialLocale={options.initialLocale}
        supportedLocales={settings.locales}
      >
        {MODULE_ENVIRONMENT == 'node' ? (
          <StaticRouter
            location={options.routeContext ? options.routeContext.url : undefined}
            basename={basename}
            context={options.routeContext}
          >
            <LegacyProvider device={device} session={session}>
              <MainComponent {...settings} />
            </LegacyProvider>
          </StaticRouter>
        ) : (
          <BrowserRouter basename={basename}>
            <LegacyProvider device={device} session={session}>
              <MainComponent {...settings} />
            </LegacyProvider>
          </BrowserRouter>
        )}
      </LocaleProvider>
    </DebugProvider>
  );
};
