import React from 'react';
import { UserDocument } from '../../../../_dependencies';
import { useLocale } from '../../../../_locales';

interface Props {
  personnel?: UserDocument[];
  selectedTransfers: string[];
  assignPersonal: (person: UserDocument) => void;
}

export const PersonnelDropdown = ({ selectedTransfers, assignPersonal, personnel }: Props) => {
  const { t } = useLocale();
  return (
    <div>
      <div className="ui compact fluid menu">
        <div
          style={innerDropdownStyle}
          className={`ui simple dropdown item ${selectedTransfers.length < 1 ? 'disabled' : ''}`}
        >
          <i className="user circle icon" />
          <div style={{ width: '100%' }}>{t('Add personnel')}</div>
          <i className="dropdown icon" />
          <div className="menu">
            {personnel?.map((person, index) => (
              <div className="item" key={`person-${index}`} onClick={() => assignPersonal(person)}>
                <img className="ui avatar image" src={person.imageUrl} />
                {person.firstname} {person.lastname}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const innerDropdownStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
};
