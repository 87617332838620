import Moment from 'moment-timezone';
import React from 'react';
import {
  AccessoryDocument,
  BookingPriceCategoryDocument,
  getRescheduleInsurance,
  MarketplaceLibrary as Lib,
} from '../../_dependencies';
import { useLocale } from '../../_locales';

interface Props {
  paidAmount: number;
  booking: Lib.CartBooking;
  discountPercentage: number;
  discountCode: string | undefined;
  giftcardCode: string;
  giftcardValue: number;
  paymentType: string;
}
export const RescheduleBookingInfo = ({
  paidAmount,
  booking,
  discountPercentage,
  discountCode,
  giftcardCode,
  giftcardValue,
  paymentType,
}: Props) => {
  if (!booking) return null;
  const occurance = booking.occurance;
  const { t, tt } = useLocale();
  const rescheduleData = getRescheduleInsurance(booking.priceCategories);
  const rescheduleFee = rescheduleData?.price! * (rescheduleData?.rescheduleInsurance.personsPaidFor || 1);
  const creditCodeAmount = paidAmount - rescheduleFee;
  const totalPaidText = t('Of which ### SEK paid').replace('###', paidAmount.toString());
  const creditCodeValueText = t('Your credit code will have a value...').replace('###', creditCodeAmount.toString());

  return (
    <table
      className="ui very basic unstackable celled table"
      style={{ border: '1px solid rgba(34,36,38,.1)', padding: '0 .5rem 0 0' }}
    >
      <tbody>
        <tr>
          {renderIcon('star outline')}
          <td>
            <strong style={{ fontSize: '1.03em' }}>
              {t('Activity')}: {tt(occurance.title)}
            </strong>
          </td>
        </tr>
        <tr>
          {renderIcon('wait')}
          <td>
            <strong style={{ fontSize: '1.03em' }}>
              {t('Time')}: {Moment(occurance.start).format('LLLL')}
            </strong>
          </td>
        </tr>
        <tr>
          {renderIcon('ticket')}
          <td>
            <strong>{t('Pricing info')}</strong>
            <br />
            {booking.priceCategories.map((priceCategory: AccessoryDocument<BookingPriceCategoryDocument>) => (
              <div key={'price-category' + tt(priceCategory.name)}>
                {priceCategory.basePrice ? (
                  <span>{tt(priceCategory.name)}</span>
                ) : (
                  <span>
                    {priceCategory.tickets} {t('pcs')} {tt(priceCategory.name)}
                  </span>
                )}
                {priceCategory.rescheduleInsurance ? (
                  <span>
                    {' '}
                    - {rescheduleFee} {t('SEK')}
                  </span>
                ) : (
                  <span>
                    {' '}
                    - {priceCategory.price} {t('SEK')}
                  </span>
                )}
              </div>
            ))}
          </td>
        </tr>

        {discountPercentage != undefined && (
          <tr>
            {renderIcon('percent')}

            <td style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <span> {`${t('Discount')}: ${discountPercentage * 100}%`} </span>
                <span> {`${t('Discount code used')}: ${discountCode}`}</span>
              </div>
            </td>
          </tr>
        )}

        {paymentType != 'stripe' ? (
          <tr>
            {renderIcon('money bill alternate')}

            <td style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <span> {`${t('Voucher')}: `}</span>
                <span>
                  {`${t('Paid')}: `} {giftcardValue / 100} {`${t('from voucher')} `} {giftcardCode}
                </span>
              </div>
            </td>
          </tr>
        ) : (
          <></>
        )}

        <tr>
          {renderIcon('money')}
          <td style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <span>
                <strong>{t('Total')}: </strong>
                {!discountPercentage
                  ? booking.totalPrice
                  : booking.totalPrice - booking.totalPrice * discountPercentage}
                {''}
                {t('SEK')} ({totalPaidText})
              </span>
            </div>
          </td>
        </tr>

        {paidAmount > 0 && (
          <tr>
            {renderIcon('recycle icon')}
            <td style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <span>{creditCodeValueText}</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const renderIcon = (icon: string) => (
  <td className="collapsing" style={{ padding: '0 1rem', backgroundColor: 'rgba(34,36,38,.1)' }}>
    <strong>
      <i style={{ margin: '0' }} className={`${icon} icon`} />
    </strong>
  </td>
);
