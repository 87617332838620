import Lodash from 'lodash';
import { action, computed, observable, toJS } from 'mobx';
import Moment from 'moment-timezone';
import * as React from 'react';
import { CSVLink } from 'react-csv';
import * as xlsx from 'xlsx';
import { Alert, Consume, LocaleContext, MobxComponent, TicketResource } from '../../../../_dependencies';
import { AHLocaleContext, globalCurrentLocale } from '../../../../_locales';
import { AdvancedDatePicker, AdvancedDatePickerType } from '../../../advancedDatePicker';

export class TmeTicketsStatsSegment extends MobxComponent<{}> {
  @observable private _type: AdvancedDatePickerType = 'month';
  @observable private _date: Date = Moment().startOf('month').toDate();
  @observable private _isLoading: boolean;
  @observable private _csvData: String[][];

  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  @computed private get showMessage() {
    return this._csvData && this._csvData.length;
  }

  @computed private get fileName() {
    const prefix = ((locale) => {
      switch (locale) {
        case 'sv':
          return 'Statistik';
        case 'de':
          return 'Statistics';
        case 'en':
        default:
          return 'Statistics';
      }
    })(globalCurrentLocale());

    const fileEnding = '.xlsx';
    switch (this._type) {
      case 'year':
        return prefix + '_' + Moment(this._date).tz('Europe/Stockholm').format('YYYY') + fileEnding;
      case 'month':
        return (
          prefix + '_' + Lodash.startCase(Moment(this._date).tz('Europe/Stockholm').format('MMMM YYYY')) + fileEnding
        );
      case 'date':
        return (
          prefix + '_' + Lodash.startCase(Moment(this._date).tz('Europe/Stockholm').format('D MMMM YYYY')) + fileEnding
        );
      default:
        return prefix + fileEnding;
    }
  }

  @action private onChanged = (date: Date, type: AdvancedDatePickerType) => {
    this._date = date;
    this._type = type;
    this._csvData = undefined as any;
  };

  @action private getPayoutInfoForCSV = async () => {
    const { t } = this._locale;
    this._isLoading = true;

    const from = getFromDate(Moment(this._date).tz('Europe/Stockholm'), this._type);
    const to = getToDate(Moment(this._date).tz('Europe/Stockholm'), this._type);

    try {
      const sheets = await new TicketResource().getTicketStats({ from, to });
      this._isLoading = false;
      const workbook = xlsx.utils.book_new();

      Object.keys(sheets).forEach((k) => {
        if (sheets[k].data && sheets[k].title) {
          const worksheet = xlsx.utils.json_to_sheet(sheets[k].data);
          xlsx.utils.book_append_sheet(workbook, worksheet, sheets[k].title);
          worksheet['!cols'] = Array(10).fill({ width: 20 });
        }
      });

      if (Object.keys(workbook.Sheets).length > 0) {
        xlsx.writeFile(workbook, this.fileName!);
      } else {
        Alert.show(
          t('components.dashboard.home.segments.tmeTicketsStatsSegment.noTickets'),
          t('components.dashboard.home.segments.tmeTicketsStatsSegment.oops'),
          'error',
        );
      }
    } catch (error) {
      console.warn(error);
      Alert.show(
        t('components.dashboard.home.segments.tmeTicketsStatsSegment.errorFile'),
        t('components.dashboard.home.segments.tmeTicketsStatsSegment.oops'),
        'error',
      );
      this._isLoading = false;
    }
  };

  private get buttonText() {
    const { t } = this._locale;
    switch (this._type) {
      case 'year':
        return (
          <span>
            {t('Get statistics for')} {Moment(this._date).tz('Europe/Stockholm').format('YYYY')}
          </span>
        );
      case 'month':
        return (
          <span>
            {t('Get statistics for')} {Lodash.startCase(Moment(this._date).tz('Europe/Stockholm').format('MMMM YYYY'))}
          </span>
        );
      case 'date':
        return (
          <span>
            {t('Get statistics for')}{' '}
            {Lodash.startCase(Moment(this._date).tz('Europe/Stockholm').format('D MMMM YYYY'))}
          </span>
        );
    }
  }

  @computed private get message() {
    const { t } = this._locale;
    return (
      <div className="ui message" style={{ marginBottom: 0 }}>
        {t('components.dashboard.home.segments.tmeTicketsStatsSegment.errorMessageFile')}
        <CSVLink data={toJS(this._csvData)} filename={this.fileName}>
          {' '}
          {t('here')}
        </CSVLink>{' '}
        {t('components.dashboard.home.segments.tmeTicketsStatsSegment.alternativeRightClick')}
        <br />
      </div>
    );
  }

  @computed private get loader() {
    const { t } = this._locale;
    if (this._isLoading) {
      return (
        <div className="ui active inverted dimmer">
          <div className="ui indeterminate big text loader">
            {t('components.dashboard.home.segments.tmeTicketsStatsSegment.buildGetFile')}
          </div>
        </div>
      );
    }
  }

  private get statisticsSection() {
    const { t } = this._locale;
    return (
      <React.Fragment>
        <h3 className="ui header">
          {t('components.dashboard.home.segments.tmeTicketsStatsSegment.statistics')}
          <div className="sub header">
            {t('components.dashboard.home.segments.tmeTicketsStatsSegment.getInfoPayments')}
          </div>
        </h3>
        <AdvancedDatePicker
          enabledTypes={['year', 'month', 'date']}
          date={this._date}
          onChanged={this.onChanged}
          type={this._type}
        />
        <div style={{ height: '1em' }} />
        <button className="ui fluid large basic grey labeled icon button" onClick={this.getPayoutInfoForCSV}>
          <i className="teal download icon" />
          {this.buttonText}
        </button>

        {this.showMessage ? this.message : <span />}
        {this.loader}
      </React.Fragment>
    );
  }

  public render() {
    return (
      <div className="ui green segment" style={{ marginTop: '2em' }}>
        {this.statisticsSection}
        <br />
      </div>
    );
  }
}

function getUrl() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return 'http://localhost:1337/api/getTicketStats';
  } else {
    return 'https://adventurehero.se/api/getTicketStats';
  }
}

function getFromDate(fromDate: Moment.Moment, _type: string) {
  if (_type == 'year') {
    return fromDate.startOf('year');
  }
  return fromDate;
}

function getToDate(fromDate: Moment.Moment, _type: string) {
  switch (_type) {
    case 'year': {
      return fromDate.add(1, 'year');
    }
    case 'month': {
      return fromDate.add(1, 'month');
    }
    case 'date': {
      return fromDate.endOf('day');
    }
    default: {
      return fromDate.add(1, 'month');
    }
  }
}
