import { BookingPriceCategoryDocument, CustomerDocument } from '../../../../_dependencies';

/** A event that can be logged to a product in the Marketplace */
export type EventType<T extends EventMetadataType = EventMetadataType> = {
  /** Change of value from this event */
  value: number;
  /** Timestamp for the event */
  time: string;
  /** Description of this event */
  description: string;
  /** Metadata stored in this event */
  metadata: T;
};

/** Event details used when rendering the event to the UI */
export type EventDetails = {
  icon: string;
  title: string;
  description?: string;
};

/** Any possible event metadata */
export type EventMetadataType =
  | PriceCategoriesUpdatedEventMetadata
  | CustomerUpdatedEventMetadata
  | PaymentEventMetadata
  | MailCreationEventMetadata
  | CreationDetailsEventMetadata
  | DiscountEventMetadata
  | ConfirmedBookingRequestMetadata
  | RejectedBookingRequestMetadata;

/** Any possible value for the 'key' attribute of event metadata */
export type EventMetadataKey = EventMetadataType['key'];

/** All possible payment types */
const POSSIBLE_PAYMENT_TYPES = ['stripe', 'giftcard', 'refund', 'manual'] as const;

/** Classification, type, of a payment */
export type PaymentType = typeof POSSIBLE_PAYMENT_TYPES[number];

/** The current status of a payment */
export type PaymentStatus = 'completed' | 'denied' | 'incomplete';

/** All possible types of emails */
const POSSIBLE_MAIL_TYPES = [
  'booking_confirmation',
  'changed_booking',
  'payment_link',
  'welcome_mail',
  'thankyou_mail',
  'booking_request',
  'booking_rejected',
] as const;

/** Type of email that has been logged in an event */
export type MailType = typeof POSSIBLE_MAIL_TYPES[number];

//#region Event Metadata Types

/** Event metadata for a payment */
export type PaymentEventMetadata = {
  key: 'payment_instance_created';
  paymentOption: PaymentType;
};

/**Event metadata for a discount */
export type DiscountEventMetadata = {
  key: 'discount-applied';
  applied_discount_code: string;
  applied_discount_percentage: string;
};

/** Event metadata for a change of price categories */
export type PriceCategoriesUpdatedEventMetadata = {
  key: 'price_categories_updated';
  by: string;
  from: BookingPriceCategoryDocument[];
  to: BookingPriceCategoryDocument[];
};

/** Event metadata for a change of customer details */
export type CustomerUpdatedEventMetadata = {
  key: 'customer_updated';
  by: string;
  from: CustomerDocument;
  to: CustomerDocument;
};

/** Event metadata for a created email */
export type MailCreationEventMetadata = {
  key: 'created_mail';
  by: string;
  to: string;
  success: boolean;
  mailType: MailType;
};

/** Event metadata for confirmed booking request */
export type ConfirmedBookingRequestMetadata = {
  key: 'booking_confirmed';
  by: string;
  success: boolean;
};

/** Event metadata for rejected booking request */
export type RejectedBookingRequestMetadata = {
  key: 'booking_rejected';
  by: string;
  success: boolean;
};

/** Event metadata for source information when the product was created */
export type CreationDetailsEventMetadata = {
  key: 'creation_details';
  /** The signed in Users email, if any */
  userEmail?: string;
  /** The signed in Users first and lastname, if any */
  userName?: string;
  /** The URL address or identification of where the booking was created from, if available and not made by a signed in user */
  address?: string;
};

//#endregion
