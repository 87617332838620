import React, { useContext, useEffect, useState } from 'react';
import Lodash from 'lodash';
import {
  Alert,
  DeviceProvider,
  DiscountDocument,
  DiscountResource,
  DiscountTimeLimit,
  Field,
  Form,
  GlobalsContext,
  Input,
  LocaleContext,
  PeriodPicker,
  Rules,
  SubmitButton,
  DiscountUsages,
  ActivityTypeResource,
  Dropdown,
  DropdownItem,
  ActivityTypeDocument,
} from '../../../_dependencies';
import { DeleteButton } from '../../deleteButton';
import { getDiscountPercentage, getDiscountUsageLimit } from './discountHandlers';

interface Props {
  discountUsage?: DiscountUsages | undefined;
  discount: DiscountDocument;
  creatingDiscount?: boolean;
  finishedEditing: () => void;
  handleDates: (timeLimits: DiscountTimeLimit[], key: string) => any;
  getDiscountStatus: (discount: DiscountDocument) => string;
  deleteDiscount: (discountId: string) => void;
}

export const DiscountForm = ({
  discount,
  creatingDiscount,
  getDiscountStatus,
  handleDates,
  finishedEditing,
  discountUsage,
  deleteDiscount,
}: Props) => {
  const checkIfDiscount = () => {
    if (!tempDiscount) return discount;
    return tempDiscount;
  };
  const { t, tt } = useContext(LocaleContext);
  const resource = new DiscountResource();
  const device = React.useContext(DeviceProvider.Context);
  const mobile = device.size === 'mobile' ? true : false;
  const [tempDiscount, setTempDiscount] = useState<DiscountDocument>();
  const globals = useContext(GlobalsContext);
  const [fromDate] = useState<Date>(creatingDiscount ? new Date() : new Date(checkIfDiscount().timeLimits[0]['start']));
  const [toDate] = useState<Date>(
    creatingDiscount ? (undefined as unknown as Date) : new Date(checkIfDiscount().timeLimits[0]['end']),
  );
  const [activities, setActivities] = useState<ActivityTypeDocument[]>();

  useEffect(() => {
    new ActivityTypeResource()
      .find({ organization: globals.session.currentOrganizationId, isArchived: { $ne: true } })
      .then((activityTypes) => {
        setActivities(activityTypes as unknown as ActivityTypeDocument[]);
      });
  }, []);

  const handleSubmit = async (_, reject) => {
    const updating = await resource.find({ _id: discount._id });

    if (tempDiscount) {
      discount.code = tempDiscount.code.toUpperCase().replace(/ /g, '-');
      discount.discountPercentage = tempDiscount.discountPercentage;
      discount.usageLimit = tempDiscount.usageLimit;
      discount.timeLimits = tempDiscount.timeLimits;
      discount.organization = globals.session.currentOrganizationId;
      discount.offerLimitations = tempDiscount.offerLimitations;
    }

    if (!updating.length) {
      resource.createDiscount(discount);
    }
    resource
      .updateDiscount(discount)
      .then(() => {
        finishedEditing();
        Alert.show(t('Scroll down the list if you cant see it'), t('Discount saved'), 'success');
      })
      .catch((err) => {
        if (err.message === 'Discount code already exists') {
          Alert.show(t('Code already in use'), t('Could not save discount'), 'error');
          reject();
          return;
        }
        Alert.show(t('We were not able to save your changes...'), t('Could not save discount'), 'error');
        reject();
      });
  };

  const handleFormChange = (value: string | number | DiscountTimeLimit[] | any, key: string) => {
    if (key === 'offerLimitations') {
      const offerString = value;
      value = onOffersChanged(offerString);
    }

    if (key === 'discountPercentage' && typeof value === 'string') {
      value = parseFloat(value) / 100;
      value.toFixed(2);
    }

    const discountCopy = { ...checkIfDiscount() };
    discountCopy[key] = value;
    setTempDiscount(discountCopy as DiscountDocument);
  };

  const deleteButton = () => {
    return (
      <div style={{ width: '100%' }}>
        <DeleteButton
          fluid
          disabled={false}
          deleting={false}
          onConfirmed={() => {
            deleteDiscount(discount.id);
            finishedEditing();
          }}
          confirmationText={t('Are you sure?')}
        >
          {t('Delete discount code')}
        </DeleteButton>
      </div>
    );
  };

  const cancelButton = () => {
    return (
      <div className="ui segment fluid button" onClick={() => finishedEditing()}>
        <i className="remove icon" /> {t('Cancel')}
      </div>
    );
  };

  const onOffersChanged = (offers: string) => {
    if (!activities) return;
    const arr: string[] = [];
    if (offers) {
      Lodash.split(offers, ',').forEach((title) => {
        const offerId = activities.find((offer) => tt(offer.title) === title);
        if (offerId) {
          arr.push(offerId.id);
        }
      });
    }
    return arr;
  };

  const getTitlesFromOfferLimitations = () => {
    if (!checkIfDiscount().offerLimitations || !activities) return;
    const arr: string[] = [];
    for (const id of checkIfDiscount().offerLimitations!) {
      const offer = activities.find((offer) => offer.id === id);
      if (offer) {
        arr.push(tt(offer.title));
      }
    }
    return arr;
  };

  const offerDropdownItems = (() => {
    if (!activities) return;
    return activities.map((activity: ActivityTypeDocument) => {
      return (
        <DropdownItem key={activity.id} value={tt(activity.title)}>
          {tt(activity.title)}
        </DropdownItem>
      );
    });
  })();

  const discountInfo = () => {
    return (
      <div style={{ paddingLeft: '1rem' }}>
        <p>
          <i className="question circle outline icon"></i>
          {t('Status')}: <i className={`circle icon ${getDiscountStatus(discount)}`} />
        </p>
        <p>
          <i className="code icon"></i>
          {t('Code')}: <b>{discount.code}</b>
        </p>
        <p>
          <i className="money bill alternate outline icon"></i>
          {t('Discount')}: {getDiscountPercentage(discount.discountPercentage)}%
        </p>
        <p>
          <i className="cut icon"></i>
          {t('Uses')}:{' '}
          {getDiscountUsageLimit(discount.usageLimit, discountUsage?.numberOfUses) ? (
            getDiscountUsageLimit(discount.usageLimit, discountUsage?.numberOfUses)
          ) : (
            <span>&#8734;</span>
          )}
        </p>
        <p>
          <i className="calendar plus outline icon"></i>
          {t('fromDate')}: {handleDates(discount.timeLimits, 'start')}
        </p>
        <p>
          <i className="calendar minus outline icon"></i>
          {t('To date')}: {handleDates(discount.timeLimits, 'end')}
        </p>
      </div>
    );
  };

  const form = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <div style={{ paddingBottom: '2em' }}>
          <Field label={t('Code')}>
            <Input
              value={String(checkIfDiscount().code || '')}
              name="code"
              rules={[Rules.NotEmpty(), Rules.MaximumLength(12)]}
              icon="ticket"
              onChange={(value) => {
                handleFormChange(value, 'code');
              }}
            />
          </Field>
          <Field>
            <PeriodPicker
              defaultStartValue={fromDate}
              defaultEndValue={toDate}
              minDate={new Date()}
              startName={t('fromDate')}
              endName={t('To date')}
              startId="from"
              endId="to"
              type="date"
              startPlaceHolder={t('Choose date')}
              endPlaceHolder={t('Choose date')}
              icon="calendar"
              required
              horizontal={mobile ? false : true}
              onChange={(startDate, toDate) => {
                if (!startDate || !toDate) return;
                handleFormChange([{ start: startDate, end: toDate }], 'timeLimits');
              }}
            ></PeriodPicker>
          </Field>
          <Field label={t('Discount')}>
            <Input
              value={String(checkIfDiscount().discountPercentage * 100 || '0').split('.')[0]}
              type="Discount"
              name="discount"
              rules={[
                Rules.NotEmpty(),
                Rules.IsANumber(false),
                Rules.IsAnIntegerBetween(1, 100, t('Please enter number between 1 and 100')),
              ]}
              icon="percent"
              onChange={(value) => {
                handleFormChange(value, 'discountPercentage');
              }}
            />
          </Field>
          <Field label={t('Use limit (optional)')}>
            <Input
              type="number"
              value={String(checkIfDiscount().usageLimit || '')}
              name="usageLimit"
              icon="cut"
              rules={[Rules.IsEmptyOrInteger()]}
              onChange={(value) => {
                handleFormChange(value, 'usageLimit');
              }}
            />
          </Field>
          <Field label={t('Limit discount (optional)')}>
            <p>{t('If no offer selected, discount code can be applied to all offers')}</p>
            <Dropdown
              name="Kategori"
              fluid
              multiple
              searchable
              placeholder={t('Offers')}
              value={Lodash.join(getTitlesFromOfferLimitations() || '', ',') || ''}
              onChange={(string) => handleFormChange(string, 'offerLimitations')}
              loading={!activities ? true : false}
            >
              {offerDropdownItems}
            </Dropdown>
          </Field>
        </div>
        <SubmitButton className="fluid green">{creatingDiscount ? t('Create') : t('Save changes')}</SubmitButton>
      </Form>
    );
  };

  if (mobile) {
    return (
      <tr>
        <td colSpan={4}>
          <div className="ui fluid card" style={{ width: '100%', padding: '1em' }}>
            <div style={{ width: '100%' }}>
              <div style={{ width: '100%' }}>
                {!creatingDiscount ? <h4>{t('Discount Information')}</h4> : null}
                {!creatingDiscount ? discountInfo() : null}
              </div>
              <div className="ui divider" />
              <h3>{creatingDiscount ? t('Create discount') : discount.code}</h3>
              {form()}
              {!creatingDiscount ? deleteButton() : null}
              <div style={{ padding: '-20px' }}>
                <div className="ui divider" />
                {cancelButton()}
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td colSpan={5}>
        <div
          className="ui basic card"
          style={{
            width: '100%',
            padding: '1em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          <div style={{ width: '50%', paddingRight: '1em' }}>
            <h3>{creatingDiscount ? t('Create discount') : discount.code}</h3>
            {form()}
            {!creatingDiscount ? deleteButton() : null}
            <div style={{ padding: '-20px' }}>
              <div className="ui divider" />
              {cancelButton()}
            </div>
          </div>
          <div className="ui vertical divider">+</div>
          <div style={{ width: '50%', padding: '1em 0em 1em 2em' }}>
            {!creatingDiscount ? <h4>{t('Discount Information')}</h4> : null}
            {!creatingDiscount ? discountInfo() : null}
          </div>
        </div>
      </td>
    </tr>
  );
};
