import React from 'react';
import { Field, Input, Rules } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface IMinimumNumberOfVisitorsFieldProps {
  minimumVisitors?: number;
  availableVisitors: number;
  displayInventory: boolean;
  setOfferMinimumVisitors: (key: 'minVisitors', value?: number) => void;
}

export const MinimumNumberOfVisitorsField = ({
  minimumVisitors,
  availableVisitors,
  displayInventory,
  setOfferMinimumVisitors,
}: IMinimumNumberOfVisitorsFieldProps) => {
  const { t } = useLocale();
  const minVisitors = minimumVisitors ? String(minimumVisitors) : '';

  const minVisitorsPromptText: string = (() => {
    if (displayInventory) return t('selected inventory quantity');
    return t('Amount available');
  })();

  const updateMinVisitors = (value?: string) => {
    if (!value) return setOfferMinimumVisitors('minVisitors', undefined);
    return setOfferMinimumVisitors('minVisitors', parseInt(value));
  };

  return (
    <Field label={t('Minimum amount that must be booked')}>
      <Input
        name="Minsta antal besökande"
        icon="male"
        placeholder={t('per booking (optional)')}
        value={minVisitors}
        onChange={updateMinVisitors}
        optional
        rules={[
          Rules.IsAnIntegerBetween(
            0,
            availableVisitors,
            `${t("This value can't be greater than")} ${minVisitorsPromptText} (${availableVisitors})`,
          ),
        ]}
      />
    </Field>
  );
};
