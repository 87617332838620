import Lodash from 'lodash';
import { observable, runInAction } from 'mobx';
import * as React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  Alert,
  Consume,
  LocaleContext,
  MailResource,
  MobxComponent,
  OrganizationDocument,
  PropertyDocument,
  PropertyResource,
  UserDocument,
  UserResource,
} from '../../../../_dependencies';
import { AHLocaleContext } from '../../../../_locales';
import { AssignedEmployeesList, AssignedEmployeesListActionType } from './assignedEmployeesList';
import { PropertyEmployeesList, PropertyEmployeesListActionType } from './propertyEmployeesList';

export interface AssignEmployeesProps {
  event: ActivityOccuranceDocument;
  forceRefresh: () => void;
}
export class AssignEmployees extends MobxComponent<AssignEmployeesProps> {
  private property: PropertyDocument;
  @observable private propertyEmployeeList: UserDocument[];
  @observable private _loading: boolean;
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private handleAssignedEmplyeesListAction = (
    actionType: AssignedEmployeesListActionType,
    employees: UserDocument[],
  ) => {
    switch (actionType) {
      case 'remove-selected-from-working':
        return this.saveWorkingStaff([], employees);
    }
  };

  private handlePropertyEmplyeesListAction = (
    actionType: PropertyEmployeesListActionType,
    employees: UserDocument[],
  ) => {
    switch (actionType) {
      case 'set-as-working':
        return this.saveWorkingStaff(employees, []);
      case 'send-invite':
        return this.sendWorkInvitationToEmployees(employees);
    }
  };

  private sendWorkInvitationToEmployees = async (employees: UserDocument[]) => {
    this._loading = true;
    try {
      const orgName = (this.globals.session.currentOrganization as OrganizationDocument).name;
      await new MailResource().sendWorkInvitationToEmployeesRequest(
        employees,
        this.props.event,
        this.property,
        orgName,
      );

      const { _id } = this.props.event;
      const employeeIds = employees.map((e) => e.id);
      const requestedStaff = await new ActivityOccuranceResource().setRequestedStaff(_id, employeeIds);
      this.props.event.requestedStaff = requestedStaff;
    } catch (error) {
      console.error(error);
    }

    this._loading = false;
  };

  /* TODO: This method is left over from old functionality, though it stil servers the purpos of adding and removing. */
  private saveWorkingStaff = async (addToWorkingStaff: UserDocument[], removedFromWorkingStaff: UserDocument[]) => {
    const { t } = this._locale;
    // Save working staff and send mail about the changes
    this._loading = true;
    await new ActivityOccuranceResource()
      .updateWorkingStaff(this.props.event.id, addToWorkingStaff, removedFromWorkingStaff)
      .then((updatedWorkingStaff) => {
        runInAction(() => {
          // Remove old one by one, because mobx...
          Lodash.remove(this.props.event.workingStaff, () => true);
          // Add new one by one, because mobx...
          Lodash.each(updatedWorkingStaff, (employee) => this.props.event.workingStaff.push(employee));
        });
      })
      .catch((err) => {
        console.error(err);
        Alert.show(t('components.calendar.views.assignEmployees.index.alertOne'));
      });

    this._loading = false;
    this.props.forceRefresh();
  };

  private loadPropertyEmployeeList = async (property: any) => {
    const populatedProperty = this.props.event.property as any as PropertyDocument;
    if (populatedProperty.id) {
      const resource = new UserResource();
      this._loading = true;

      const employees: UserDocument[] = [];
      for (const id of populatedProperty.crew) {
        employees.push(await resource.get(id));
      }

      runInAction(() => {
        this.property = populatedProperty;
        this.propertyEmployeeList = employees.filter(Boolean);
      });
      runInAction(() => (this._loading = false));
    } else {
      this._loading = true;
      new PropertyResource()
        .get(property)
        .then((property: PropertyDocument) => {
          this.property = property;
          return new UserResource().find({
            _id: { $in: property.crew },
          });
        })
        .then((employees) => {
          runInAction(() => {
            this.propertyEmployeeList = Lodash.sortBy(
              employees,
              (employee: UserDocument) => employee.name,
            ) as UserDocument[];
            this._loading = false;
          });
        });
    }
  };
  componentDidMount() {
    this.loadPropertyEmployeeList(this.props.event.property);
  }

  componentDidUpdate(prevProps: AssignEmployeesProps) {
    // Only update when there is a new activityOccurance
    if (this.props.event.id != prevProps.event.id) {
      this.loadPropertyEmployeeList(this.props.event.property);
    }
  }

  render() {
    return (
      <div className={'ui basic segment ' + (this._loading ? 'loading' : '')} style={{ padding: 0 }}>
        <AssignedEmployeesList
          propertyEmployees={this.propertyEmployeeList}
          event={this.props.event}
          onActionTriggered={this.handleAssignedEmplyeesListAction}
        />
        <PropertyEmployeesList
          propertyEmployees={this.propertyEmployeeList}
          event={this.props.event}
          onActionTriggered={this.handlePropertyEmplyeesListAction}
        />
      </div>
    );
  }
}
