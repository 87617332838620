export * from './manualPaymentComponent';
export * from './payWithGiftCardComponent';
export * from './stripeComponent';

import { Stripe } from '@stripe/stripe-js';
import { computed, observable } from 'mobx';
import * as React from 'react';
import {
  ActivityOccuranceDocument,
  ActivityOccuranceResource,
  ActivityTypeResource,
  Alert,
  BookingDocument,
  BookingResource,
  Consume,
  CustomerDocument,
  DiscountDocument,
  DiscountError,
  DiscountResource,
  GiftCardDocument,
  GiftCardResource,
  GiftCardTypeResource,
  MarketplaceLibrary as Lib,
  LocaleContext,
  MailResource,
  MobxComponent,
  OrganizationResource,
  PaymentResource,
  Platforms,
  getStripeInstance,
  sendBookingCreatedNotification,
} from '../_dependencies';
import { WidgetLocaleContext } from '../_locales';
import { ErrorBoundary } from '../copyFromAH/errorBoundary';
import { MarketplaceCartContext } from '../marketplace.cart.context';
import { MarketplaceContext } from '../marketplace.context';
import { PayWithGiftCardComponent } from '../movedFromAH/payWithGiftCardComponent';
import { IWidgetData, WidgetDataProvider } from '../widgetDataProvider';
import { ManualPaymentComponent } from './manualPaymentComponent';
import { SkipPaymentComponent } from './skipPaymentComponent';
import { StripeComponent } from './stripeComponent';

export type PaymentMethod = 'ManualPayment' | 'GiftCard' | 'CreditCard' | 'SkipPayment';

// TODO: extend this component to handle all payments and calls to the server.
export interface PaymentComponentProps {
  /** Payment methods that should be active */ activePaymentMethods: PaymentMethod[];
  /** The items to complete the payment for */ cart: Lib.CartItem[];
  /** The amount that already has been paid */ amountAlreadyPaid: number;
  /** Adds a header to the component */ header?: string;
  /** Callback when payment has been resolved */ onSaved?: () => void;
  /** Callback when payment has been rejected */ onFailed?: () => void;
  /** Hides payment information section */ hideInfo?: boolean;
  /** Customer data for purchasing purposes */ customer: CustomerDocument;
  /** Declares that purchase is product specific, not for order */ productSpecific?: string;
  placeOrderWithoutPayment?: (cart: Lib.CartItem[]) => Promise<void>;
  placeOrderWithManualPayment?: (cart: Lib.CartItem[], productId?: string) => Promise<void>;
  placeOrderWithCardPayment: (
    cart: Lib.CartItem[] | (BookingDocument | GiftCardDocument)[],
    cardDetails: Lib.CardDetails | undefined,
    cardActionHandler: Lib.CardActionHandler,
    productId?: string,
  ) => Promise<any>;
}
export class PaymentComponent extends MobxComponent<PaymentComponentProps> {
  @observable private _currentPaymentMethod = this.props.activePaymentMethods[0];

  private static stripe: Stripe;

  @Consume(LocaleContext)
  private _locale: WidgetLocaleContext;

  @Consume(MarketplaceContext)
  private marketplace: MarketplaceContext;

  @Consume(MarketplaceCartContext)
  private marketplacecart: MarketplaceCartContext;

  @Consume(WidgetDataProvider.Context)
  protected widgetData: IWidgetData;

  /**
   * To do this, we need to have an order ready in marketplace
   * @param notYetIdentifiedCode
   * @param resolve
   * @param reject
   * @param errorCallback
   * @returns
   */
  private async onSubmitPayWithGiftCard(
    notYetIdentifiedCode: string,
    resolve: () => void,
    reject: (...args: any[]) => void,
    errorCallback: (err: Error) => void,
  ) {
    try {
      await (async () => {
        if (!this.props.cart.length) throw new Error('No items in cart');

        if (this.marketplace.order.id == undefined) {
          throw new Error('Cannot use a giftcard as a payment without an order');
        }

        const giftcard = await new GiftCardResource().getGiftcardByNumber(notYetIdentifiedCode);

        if (giftcard) {
          await this.marketplace.applyNewGiftcard(giftcard.number);
          return;
        }

        const discount = await new DiscountResource().getDiscountByCode(notYetIdentifiedCode.toUpperCase());

        if (discount) {
          // KNOWN BUG.
          // Depending on the order of the products, discounts that share the same discount code across
          // orgs will only apply to the first product.
          const applicableDiscount: DiscountDocument | undefined = this.checkDiscountOrganization(discount);
          if (applicableDiscount) {
            await this.marketplace.applyNewDiscount(applicableDiscount);
            return;
          } else {
            throw new DiscountError('', 'Discount can not be applied to this activity');
          }
        }

        if (!giftcard && !discount) throw new DiscountError('', 'Could not find Discount or Giftcard');
      })();
      if (Object.keys(this.widgetData).length !== 0) {
        this.widgetData.clearSelectedItemOnConfirm();
      }
      return resolve();
    } catch (err) {
      console.error(err);
      errorCallback(err);
      return reject(err);
    }
  }

  //Checks if discount organization matches any of the product organizations
  //This check is needed incase organizations create discounts with the same discount code.
  private checkDiscountOrganization = (discount: DiscountDocument) => {
    const cart = this.marketplacecart?.cart ? this.marketplacecart?.cart : this.props.cart;
    for (const cartItem of cart) {
      if (this.marketplace.cartItemIsCartBooking(cartItem)) {
        if (String(discount.organization) === String(cartItem.organization)) {
          return discount;
        } else {
          continue;
        }
      }
    }
  };

  private async buildPaymentDescription(product: Lib.CartItem, discount: Lib.Discount | undefined): Promise<string> {
    let chargeTitle = 'Okänd produkt',
      taxRate = 0,
      productTotal = 0;

    const { tt } = this._locale;
    // Find Booking details
    if (BookingResource.isDocument(product)) {
      const occurance = await new ActivityOccuranceResource().find({ _id: product.occurance });
      const actitityType = await new ActivityTypeResource().find({ _id: occurance[0].originatingActivity });
      chargeTitle = tt(occurance[0].title);
      taxRate = actitityType[0].taxRate;
      productTotal = product.totalPrice;
      //Manipulates the total with discount percentage
      if (discount) {
        if (
          discount.offerLimitations?.includes(occurance[0].originatingActivity as unknown as string) ||
          !discount.offerLimitations!.length
        ) {
          productTotal -= productTotal * discount.percentage;
        }
      }
    }

    // Find Giftcard details
    if (GiftCardResource.isDocument(product)) {
      chargeTitle = tt(product.title);
      productTotal = product.value ?? 0;

      if (product.giftCardType) {
        const giftCardType = await new GiftCardTypeResource().find({ _id: product.giftCardType });
        taxRate = giftCardType[0].taxRate;
      }
    }

    // Calculate values
    const taxAmount = productTotal - productTotal / (1 + taxRate);
    const prettyTaxRate = `${Math.round(taxRate * 100)}%`;
    const prettyTaxSum = `${taxAmount.toFixed(2)} ${Platforms.currency}`;

    // Return string to be added to the payment as description
    switch (Platforms.receiptLocale) {
      case 'sv':
        return `${chargeTitle} - varav ${prettyTaxRate} är moms ( ${prettyTaxSum.split('.').join(',')} )`;
      case 'de':
        return `${chargeTitle} - davon ist ${prettyTaxRate} VAT ( ${prettyTaxSum} )`;
      default:
        return `${chargeTitle} - of which ${prettyTaxRate} is VAT ( ${prettyTaxSum} )`;
    }
  }

  // /** Build a metadata object for a given product and return it. Used to add useful metadata to marketplace and stripe */
  private async buildPaymentMetadata(
    product: Lib.CartItem,
    index: number,
    discountId: undefined | string,
  ): Promise<{ [metakey: string]: string }> {
    // Create the metadata object so that it is always returnable
    let metadata: { [key: string]: string } = {};

    // Add relevant metadata in order of importance, if any step of this operation
    // fails for any reason the metadata object created so far is returned.
    try {
      // Add metadata for bookings directly from the product
      if (this.marketplace.cartItemIsCartBooking(product)) {
        // BOOKING Set the metadata fields
        metadata = {
          ...metadata,
          [`purchasedObject-${index}`]: 'booking',
          [`bookingId-${index}`]: product.id,
        };
        if (discountId) {
          metadata = {
            ...metadata,
            ['appliedDiscountId']: discountId,
          };
        }
      }
      // Add metadata for gift cards directly from the product
      else {
        metadata = {
          ...metadata,
          [`purchasedObject-${index}`]: 'giftCard',
          [`giftCardId-${index}`]: product.id,
          [`giftCardNumber-${index}`]: product.number,
        };
      }

      // Add organization information
      const organization = this.marketplace.cartItemIsCartBooking(product)
        ? product.organization
        : product.limitations.organizations[0]; // NOTE: the first limiting organization is the seller of the giftcard
      ('');

      const { name, id } = await new OrganizationResource().getUnrestrictedOrg(organization);
      metadata = {
        ...metadata,
        [`organizationName-${index}`]: name,
        [`organizationId-${index}`]: id,
      };

      // NOTE: Suggestions for more information that can be added:
      // How much is transfered to the organization / and how much we will keep
      // Tax rate of the purchase and what amount is tax (is available in the description on stripe)
      // Activity name and customer name/email (is available in the description on stripe)
    } catch (err) {
      // Handle errors
      console.error('Error building the metadata object for a purchase:', err);
    } finally {
      // Always return the resulting metadata
      return metadata;
    }
  }

  private async onSubmitAddManualPayment(resolve, reject, errorCallback) {
    const { placeOrderWithManualPayment, cart, productSpecific } = this.props;
    if (!placeOrderWithManualPayment)
      throw new Error('[PaymentComponent] Manual payment functionality not initialized');

    try {
      await placeOrderWithManualPayment(cart, productSpecific);
      // Finished
      this.props.onSaved && this.props.onSaved();

      if (Object.keys(this.widgetData).length !== 0) {
        this.widgetData.clearSelectedItemOnConfirm();
      }
      return resolve();
    } catch (err) {
      errorCallback(err);
      return reject(err);
    }

    // Is this legacy flow really necessary now?
    // const promise = Promise.all(
    //   this.props.cart.map((item) => {
    //     let productValue;
    //     if (this.marketplace.cartItemIsCartBooking(item)) {
    //       productValue = item.totalPrice;
    //     } else {
    //       // FIXME: How come giftcards may have no value??
    //       productValue = item.value;
    //     }
    //     if (BookingResource.isDocument(item)) {
    //       if (BookingResource.isLegacy(item)) {
    //         return new PaymentResource().addManualPaymentToBooking(item.id, productValue);
    //       }
    //     }
    //   })
    // );

    // promise
    //   .then((result) => {
    //     if (this.props.onSaved) {
    //       this.props.onSaved();
    //     }
    //     return resolve();
    //   })
    //   .catch((err) => {
    //     if (this.props.onFailed) {
    //       this.props.onFailed();
    //     }
    //     return reject(err);
    //   });
  }

  private async handleStripeComponentOnSubmit(resolve, reject, errorCallback, stripeToken, cardCountryCode) {
    try {
      const { cart, productSpecific, customer } = this.props;
      const cardActionHandler: Lib.CardActionHandler = PaymentComponent.stripe.handleCardAction;

      // Build payment information
      let payment: Lib.CardDetails | undefined;
      if (stripeToken && cardCountryCode) {
        let metadata: { [metakey: string]: string } = {};
        let discountId: string | undefined = undefined;
        const descriptions = await Promise.all(
          cart.map(async (item, index) => {
            const description = await this.buildPaymentDescription(item, this.marketplace.order.discount);
            if (this.marketplace.order.discount) {
              const discount = await new DiscountResource().getDiscountByCode(this.marketplace.order.discount?.code);
              discountId = discount.id;
            }
            const newMetadata = await this.buildPaymentMetadata(item, index, discountId);
            metadata = { ...metadata, ...newMetadata };
            return description;
          }),
        );

        payment = {
          token: stripeToken,
          cardCountryCode,
          receiptEmail: customer.email,
          description: descriptions.join(` | `),
          metadata,
        };
      }

      /**
       * The purchasing model is a way of paying in marketplace.
       * This will perform a card payment of the order and will save the bookings and giftcards in the process
       */
      await this.props.placeOrderWithCardPayment(cart, payment, cardActionHandler, productSpecific);

      /**
       * If the booking is a booking request we sent a notification to the organizer that the customer has paid for the booking
       */
      for (const booking of cart) {
        if (BookingResource.isDocument(booking) && booking.requested && !booking.crossSellingProperty) {
          await new MailResource().sendBookingConfirmationEmail(booking.id, undefined, undefined, true);
        }
      }

      // Finished
      this.props.onSaved && this.props.onSaved();

      if (Object.keys(this.widgetData).length !== 0) {
        this.widgetData.clearSelectedItemOnConfirm();
      }
      return resolve();
    } catch (err) {
      console.log(err);
      errorCallback(err);
      return reject(err);
    }
  }

  async componentDidMount() {
    const result = await new PaymentResource().getStripePublishableKey();
    if (PaymentComponent.stripe == undefined) {
      PaymentComponent.stripe = await getStripeInstance();
    }

    this.setState({ apiKey: result.key });
  }

  private async skipPaymentStepAndContinue() {
    const { t, locale } = this._locale;
    const { onSaved, placeOrderWithoutPayment, cart, customer } = this.props;
    try {
      if (!placeOrderWithoutPayment) throw new Error('[Payment Component] skip payment function not declared');
      await placeOrderWithoutPayment(cart);

      if (this.widgetData.bookingRequest) {
        const occuranceRequests = cart.map(
          (cartItem: Lib.CartBooking) => cartItem.occurance,
        ) as unknown as ActivityOccuranceDocument[];

        await new ActivityOccuranceResource().createBookingRequests(occuranceRequests);

        Promise.all(
          cart.map(async (cartItem: Lib.CartBooking) => {
            sendBookingCreatedNotification({
              booking: { ...cartItem, occurance: cartItem.occurance._id, customer },
              locale,
            });
          }),
        );
      }

      if (Object.keys(this.widgetData).length !== 0) {
        this.widgetData.clearSelectedItemOnConfirm();
      }

      onSaved && onSaved();
    } catch (err) {
      Alert.show(t('components.payment.index.errorMessage2'), 'Error', 'error');
    }
  }

  @computed private get confirmBookingRequest() {
    const { t } = this._locale;

    return (
      <div className="ui segment">
        <h4 className="ui header">
          {t('Confirm booking request')}
          <div className="sub header">{t('To make your booking request...')}</div>
        </h4>
        <div style={{ height: '1em' }} />
        <button className="ui fluid green button" onClick={this.skipPaymentStepAndContinue.bind(this)}>
          {t('Make booking request')}
        </button>
      </div>
    );
  }

  @computed private get paymentComponent() {
    switch (this._currentPaymentMethod) {
      case 'ManualPayment':
        return (
          <ManualPaymentComponent
            cart={this.props.cart}
            totalPrice={this.totalPrice}
            amountPayed={this.props.amountAlreadyPaid}
            onSubmit={this.onSubmitAddManualPayment.bind(this)}
          />
        );
      case 'CreditCard':
        return (
          <StripeComponent
            cart={this.props.cart}
            customerFullName={this.props.customer.fullname}
            onSubmit={this.handleStripeComponentOnSubmit.bind(this)}
          />
        );
      case 'SkipPayment':
        return <SkipPaymentComponent onContinue={this.skipPaymentStepAndContinue.bind(this)} />;
    }
  }

  @computed private get manualPaymentButton() {
    const { t } = this._locale;
    return (
      <button
        key="manualpayment"
        className={`ui ${this._currentPaymentMethod == 'ManualPayment' ? 'active' : ''} button`}
        onClick={() => (this._currentPaymentMethod = 'ManualPayment')}
        style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}
      >
        {t('components.payment.index.buttonOne')}
      </button>
    );
  }
  @computed private get creditCardButton() {
    const { t } = this._locale;
    return (
      <button
        key="creditcard"
        className={`ui ${this._currentPaymentMethod == 'CreditCard' ? 'active' : ''} button`}
        onClick={() => (this._currentPaymentMethod = 'CreditCard')}
        style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}
      >
        {t('components.payment.index.buttonTwo')}
      </button>
    );
  }
  @computed private get skipPaymentButton() {
    const { t } = this._locale;
    return (
      <button
        key="skippayment"
        className={`ui ${this._currentPaymentMethod == 'SkipPayment' ? 'active' : ''} button`}
        onClick={() => (this._currentPaymentMethod = 'SkipPayment')}
        style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}
      >
        {t('components.payment.index.buttonFour')}
      </button>
    );
  }

  @computed private get paymentMethodButtons() {
    const buttons: JSX.Element[] = [];
    this.props.activePaymentMethods.forEach((paymentMethod) => {
      switch (paymentMethod) {
        case 'ManualPayment':
          buttons.push(this.manualPaymentButton);
          break;
        case 'CreditCard':
          buttons.push(this.creditCardButton);
          break;
        case 'SkipPayment':
          buttons.push(this.skipPaymentButton);
          break;
      }
    });
    return buttons;
  }

  private get info() {
    const { t } = this._locale;

    return (
      <div className="ui fluid grey basic segment" style={{ textAlign: 'center' }}>
        <img className="ui tiny centered image" src={`${MODULE_PUBLIC_URL}/static/platform/img/logo.png`} />
        <br />
        {t('Your booking is managed by')}
        &nbsp;
        <a href={'http://www.adventurehero.se'} target="blank">
          <strong>{Platforms.platformName}</strong>
        </a>
        {t(', which will be on your receipt...')}
        &nbsp;
        <a href="http://stripe/about" target="blank">
          <i className="ui large grey stripe icon" />
        </a>
        .
        <br />
        {t('There is a booking fee...')}
      </div>
    );
  }

  private get totalPrice(): number {
    return this.props.cart.reduce(
      (sum, item) => (this.marketplace.cartItemIsCartBooking(item) ? item.totalPrice + sum : item.value! + sum),
      0,
    );
  }

  private get paymentMethodContent() {
    if (this.marketplace.leftToPay <= 0) return null;

    if (this.paymentMethodButtons.length < 2 && !this.props.header) return null;

    return (
      <>
        <h3 className="ui header" style={{ marginTop: 0 }}>
          {this.props.header}
        </h3>
        {this.paymentMethodButtons.length >= 2 && (
          <div className="ui tiny fluid basic buttons">{this.paymentMethodButtons}</div>
        )}
      </>
    );
  }

  private get mainContent() {
    // Todo: behöver kontrollera så att det inte finns med ett köp utöver bokningsförfrågan
    if (this.widgetData.bookingRequest) {
      return this.confirmBookingRequest;
    }
    return (
      <div>
        {this.paymentMethodContent}
        {this.props.activePaymentMethods.includes('GiftCard') && (
          <div className="ui segment">
            <PayWithGiftCardComponent
              propertyName={'anläggningen'}
              onSubmit={this.onSubmitPayWithGiftCard.bind(this)}
            />
          </div>
        )}
        <div className="ui segment">
          {this.paymentComponent}
          {!this.props.hideInfo && this.info}
          <div style={{ height: '0.5em' }} />
        </div>
      </div>
    );
  }

  render() {
    return <ErrorBoundary>{this.mainContent}</ErrorBoundary>;
  }
}
