import React from 'react';
import Lodash from 'lodash';
import {
  ActivityTagsFormatter,
  CustomRule,
  Dropdown,
  DropdownItem,
  Field,
  FormatRule,
  Rules,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface IActivityCategoryFieldProps {
  tags?: string[];
  setOfferTags: (key: 'categoryTags', value: string[]) => void;
}

export const ActivityCategoryField = ({ tags, setOfferTags }: IActivityCategoryFieldProps) => {
  const { t, locale } = useLocale();

  const maxCategoriesRule: FormatRule = (() => {
    return CustomRule({
      validator: (value) => Lodash.split(value, ',').length <= 3,
      prompt: t('You can choose a maximum of 3 categories'),
    });
  })();

  const onCategoriesChanged = (commaSeparatedCategories: string) => {
    const categories = Lodash.split(commaSeparatedCategories, ',');
    setOfferTags('categoryTags', categories);
  };

  const categoryDropdownItems: JSX.Element[] = (() => {
    return Lodash.map(ActivityTagsFormatter.activityCategoryTags, (categoryTag) => {
      const formattedTag = ActivityTagsFormatter.getFormattedTag(categoryTag, locale);
      return (
        <DropdownItem key={categoryTag} value={categoryTag}>
          {formattedTag}
        </DropdownItem>
      );
    });
  })();

  return (
    <Field label={t('Activity categories')}>
      <Dropdown
        name="Kategori"
        fluid
        multiple
        searchable
        placeholder={t('Choose a category for your offer')}
        rules={[Rules.NotEmpty(), maxCategoriesRule]}
        value={Lodash.join(tags || '', ',')}
        onChange={onCategoriesChanged}
      >
        {categoryDropdownItems}
      </Dropdown>
    </Field>
  );
};
