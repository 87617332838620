import React, { useContext } from 'react';
import {
  Checkbox,
  DeviceProvider,
  // Field,
  // Rules,
  TranslatableText,
} from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface Props {
  fixedBookingRequest?: boolean;
  flexibleBookingRequest?: boolean;
  flexibleBookingRequestMessage?: TranslatableText | string;
  fixedBookingRequestMinimumCharge?: number;
  useInventory?: boolean;
  setOfferBookingRequestRules: (
    key:
      | 'fixedBookingRequest'
      | 'flexibleBookingRequest'
      | 'flexibleBookingRequestMessage'
      | 'fixedBookingRequestMinimumCharge',
    value?: boolean | TranslatableText | number,
  ) => void;
}

export const BookingRequestFields = ({
  fixedBookingRequest,
  flexibleBookingRequest,
  // flexibleBookingRequestMessage,
  // fixedBookingRequestMinimumCharge,
  setOfferBookingRequestRules,
  useInventory,
}: Props) => {
  const { t } = useLocale();
  const device = useContext(DeviceProvider.Context);

  const addOrRemoveFixedBookingRequestOption = (fixedRequest?: boolean) => {
    setOfferBookingRequestRules('fixedBookingRequest', fixedRequest);
  };

  // const addOrRemoveFlexibleBookingRequestOption = (openRequest?: boolean) => {
  //   setOfferBookingRequestRules('flexibleBookingRequest', openRequest);
  // };

  // const addOrRemoveBookingRequestMessage = (textValue?: TranslatableText) => {
  //   setOfferBookingRequestRules(
  //     'flexibleBookingRequestMessage',
  //     (flexibleBookingRequestMessage = {
  //       sv: textValue?.sv || '',
  //       en: textValue?.en || '',
  //       de: textValue?.de || '',
  //     } as TranslatableText),
  //   );
  // };

  // const addOrRemoveBookingRequestMinimumCharge = (fixedMessage?: number) => {
  //   setOfferBookingRequestRules('fixedBookingRequestMinimumCharge', fixedMessage);
  // };

  return (
    <div className="ui segment" style={{ marginTop: '2rem' }}>
      <div className="ui tiny header" style={{ marginBottom: 0 }}>
        <Checkbox
          toggle
          checked={fixedBookingRequest}
          onCheckedOrUncheked={addOrRemoveFixedBookingRequestOption}
          label={t('Enable the option for customers to make a booking request')}
          style={{ marginBottom: '.5rem' }}
          disabled={flexibleBookingRequest}
        />
        <p className="sub header">{t('By activating this, a customer can create a booking request...')}</p>
      </div>
      {/* {fixedBookingRequest && (
        <Field style={{ margin: '1em 0 0 0', flex: 1 }} label={t('Enter the minimum charge for a booking request')}>
          <Input
            name="fixedBookingRequestMinimumCharge"
            value={String(fixedBookingRequestMinimumCharge || '')}
            onChange={(FixedBokingValue) => addOrRemoveBookingRequestMinimumCharge(+FixedBokingValue)}
            placeholder={t('(Optional)')}
            rules={[Rules.IsEmptyOrInteger()]}
          />
        </Field>
      )} */}
      {/* <div className="ui tiny header" style={{ marginTop: '1.5rem' }}>
        <div
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          <div className="ui tiny header" style={{ marginBottom: 0 }}>
            <Checkbox
              toggle
              onCheckedOrUncheked={addOrRemoveFlexibleBookingRequestOption}
              checked={flexibleBookingRequest}
              label={t('Enable the option for customers to make a flexible booking request')}
              style={{ marginBottom: '.5rem' }}
              disabled={fixedBookingRequest}
            />
            <p className="sub header">
              {t('By activating this, the customers booking request is created using text only...')}
            </p>
          </div>
          {flexibleBookingRequest && (
            <Field style={{ margin: '1em 0 0 0', flex: 1 }}>
              <TranslatableTextField
                textarea
                name="BookingRequestMessage"
                value={flexibleBookingRequestMessage}
                onChange={(flexibleBookingValue) => addOrRemoveBookingRequestMessage(flexibleBookingValue)}
                placeholder={t('Write a message to the customer')}
                rules={[Rules.NotEmpty()]}
              />
            </Field>
          )}
        </div>
      </div> */}
    </div>
  );
};
