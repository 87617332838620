/** A semantic ui width option */
export type Width =
  | number
  | 'one wide'
  | 'two wide'
  | 'three wide'
  | 'four wide'
  | 'five wide'
  | 'six wide'
  | 'seven wide'
  | 'eight wide'
  | 'nine wide'
  | 'ten wide'
  | 'eleven wide'
  | 'twelve wide'
  | 'thirteen wide'
  | 'fourteen wide'
  | 'fifthteen wide'
  | 'sixteen wide';

/** Function that return the correct string for a width in semantic ui */
export function formatWidth(width: Width): string {
  if (typeof width === 'string') {
    return width;
  }

  switch (width) {
    case 1:
      return 'one wide';
    case 2:
      return 'two wide';
    case 3:
      return 'three wide';
    case 4:
      return 'four wide';
    case 5:
      return 'five wide';
    case 6:
      return 'six wide';
    case 7:
      return 'seven wide';
    case 8:
      return 'eight wide';
    case 9:
      return 'nine wide';
    case 10:
      return 'ten wide';
    case 11:
      return 'eleven wide';
    case 12:
      return 'twelve wide';
    case 13:
      return 'thirteen wide';
    case 14:
      return 'fourteen wide';
    case 15:
      return 'fifthteen wide';
    case 16:
      return 'sixteen wide';
    default:
      console.warn('A width must be a between one and sixteen');
      return '';
  }
}
