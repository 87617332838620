import Moment from 'moment-timezone';
import { Schema } from 'mongoose';
import { MongooseDocument, MongooseResource } from '../_dependencies';
import SchemaNames from '../schemas/names';
import { TranslatableText } from '../schemas/translatableText';

export interface CalendarOccuranceDocument extends MongooseDocument {
  title: TranslatableText | string;
  start: Date;
  end: Date;
  allDay?: boolean;
  hasCustomerRequestedBooking?: boolean;
  isFullyBooked?: boolean;
  availableVisitors: number;
  /** VIRTUAL FIELD Activity duration in minutes */ duration: number;
}

export class CalendarOccuranceResource<D extends CalendarOccuranceDocument> extends MongooseResource<D> {
  constructor() {
    super();

    this.setName(SchemaNames.Occurance);

    this.setSchema({
      title: { type: Schema.Types.Mixed },
      start: { type: Date, required: true },
      end: { type: Date, required: true },
      allDay: { type: Boolean, default: false },
    });

    this.addVirtualField(
      'duration',
      (document) => Moment(document.end).diff(Moment(document.start), 'minutes'),
      (document, value) => (document.end = Moment(document.start).add(value, 'minutes').toDate()),
    );
  }
}
