import * as React from 'react';

import { Component } from './abstractComponent';

/** Semantic UI Progress Bar */
export class ProgressBar extends Component<{
  className?: string;

  /** Can be set to either to display progress as percent or ratio */ mode?: 'percent' | 'ratio';

  /** Sets the default percentage */ defaultPercentage?: number;
  /** Sets the default value, when total is specified, this is used to calculate a ratio of the total, with percent this should be the overall percent */ defaultValue?: number;
  /** Setting a total value will make each call to increment get closer to this total (i.e. 1/20, 2/20 etc) */ total?: number;
  /** Decimal point precision for calculated progress */ precision?: number;

  /** You can customize the messages shown inside the progress bar by specifying these templated texts, inside the messages the following templates can be used:
   *  {percent} - Current percentage
   *  {value} - Current value
   *  {total} - Total value
   *  {left} - actual value left to total, or the percentage left depending on progress bar mode
   */
  messages?: {
    /** Message shown when mode is percent */
    percent?: string;
    /** Message shown when mode is ratio */
    ratio?: string;
    /** Message shown while active */
    active?: string;
    /** Message shown when successfull */
    success?: string;
    /** Message shown when a warning occured */
    warning?: string;
    /** Message shown when a error occured */
    error?: string;
  };
  /** Content shown beneath the progress bar inside a div.label */ label?: React.ReactNode;

  /** Callback on percentage change */ onChange?: (percentage: number, value: number, total: number) => any;
  /** Callback on success state */ onSuccess?: (total: number) => any;
  /** Callback on active state */ onActive?: (value: number, total: number) => any;
  /** Callback on error state */ onError?: (value: number, total: number) => any;
  /** Callback on warning state */ onWarning?: (value: number, total: number) => any;
}> {
  private initializeProgress() {
    (this.domElement as any).progress({
      percent: this.props.defaultPercentage,
      value: this.props.defaultValue,
      total: this.props.total,
      label: this.props.mode,
      precision: this.props.precision,
      onChange: this.props.onChange,
      onSuccess: this.props.onSuccess,
      onError: this.props.onError,
      onWarning: this.props.onWarning,
      text: this.props.messages,
    });
  }

  componentDidMount() {
    this.initializeProgress();
  }

  componentDidUpdate() {
    this.initializeProgress();
  }

  public setPercent = (percent: number) => {
    (this.domElement as any).progress('set percent', percent);
  };

  public setAmount = (amount: number) => {
    (this.domElement as any).progress('set progress', amount);
  };

  public setTotal = (total: number) => {
    (this.domElement as any).progress('set total', total);
  };

  public increment = (amount = 1) => {
    (this.domElement as any).progress('increment', amount);
  };

  public decrement = (amount = 1) => {
    (this.domElement as any).progress('decrement', amount);
  };

  public complete = () => {
    (this.domElement as any).progress('complete');
  };

  public reset = () => {
    (this.domElement as any).progress('reset');
  };

  render() {
    const className = 'ui ' + (this.props.className || '') + ' progress';

    return (
      <div className={className} style={this.props.style || {}}>
        <div className="bar">{this.props.messages ? <div className="progress" /> : undefined}</div>
        {this.props.label ? <div className="label">{this.props.label}</div> : undefined}
      </div>
    );
  }
}
