import React, { useContext, useEffect } from 'react';
import { OffersContext } from '../../../../../contexts/offers.context';
import { Checkbox, PriceCategoryDocument } from '../../../../../_dependencies';
import { useLocale } from '../../../../../_locales';

interface Props {
  priceCategories: PriceCategoryDocument[];
}

export const InventoriesToggle = ({ priceCategories }: Props) => {
  const { t } = useLocale();
  const { selectedOffer, updateSelectedOffer } = useContext(OffersContext);

  useEffect(() => {
    if (!selectedOffer?.useInventory) {
      priceCategories.forEach((pc) => {
        delete pc.inventory;
      });
    }
  }, [selectedOffer?.useInventory]);

  const handleToggle = () => {
    updateSelectedOffer('useInventory', !selectedOffer?.useInventory);
    updateSelectedOffer('visitorCapacity', 10);
  };

  return (
    <div className="ui segment">
      <div className="ui tiny header">
        <Checkbox
          toggle
          checked={selectedOffer?.useInventory}
          onCheckedOrUncheked={handleToggle}
          label={t('Use inventory')}
          style={{ marginBottom: '.5rem' }}
        />
        <p className="sub header">{t('Assign your inventory to pricecategories on...')}</p>
      </div>
    </div>
  );
};
