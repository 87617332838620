import * as React from 'react';
import { Consume, LocaleContext, MobxComponent, PurchaseWidget } from '../../../_dependencies';
import { AHLocaleContext } from '../../../_locales';

export class SellGiftCardView extends MobxComponent<{}> {
  @Consume(LocaleContext)
  private _locale: AHLocaleContext;

  private _widgetURL =
    this.globals.session.currentLocation.origin +
    '/widget/giftcards/' +
    this.globals.session.currentOrganizationId.toString(); // TODO: everything with 'window' should be moved to a store, like session or device

  private get codeSnippet() {
    const { t } = this._locale;
    return (
      // This code has to be left aligned to get the correct indent.
      `<iframe frameborder="0"
        width="100%"
        height="800px"
        src="` +
      this._widgetURL +
      `">
    <p>${t('components.dashboard.giftCards.sell.webbReaderNotSupported')}</p>
</iframe>`
    );
  }

  render() {
    const { t } = this._locale;
    return (
      <div className="ui basic segment" style={{ padding: '2em' }}>
        <h2 className="ui header">
          {t('components.dashboard.giftCards.sell.sellGiftcard')}
          <div className="sub header">{t('components.dashboard.giftCards.sell.sameViewHomePage')}</div>
        </h2>
        <div style={{ height: '1em' }} />
        <PurchaseWidget fixedType="giftcards" organizationId={this.globals.session.currentOrganizationId.toString()} />
      </div>
    );
  }
}
